import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Location } from 'history';
import { List, Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  GmbAccountApi,
  GmbCategoryListApi,
  GmbGroupApi,
  GmbInsightTotalListApi,
  GmbLocationApi,
  GmbReviewApi,
  GmbReviewDetailApi,
  GmbReviewDownloadApi,
  GmbReviewDownloadStatusCheckApi,
  GmbReviewReplyApi,
  GmbReviewStatusApi,
  ImportGBPStoreApi,
  ImportGBPStoreStatusApi,
} from 'ApiClient/GmbApi';
import { StoreConnectGmbApi } from 'ApiClient/StoreApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { GmbInsight } from 'models/Domain/GmbInsight';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { GmbReview, GmbReviewSearchCondition, ReviewDetail } from 'models/Domain/GmbReview';
import { Store } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { StoreActions } from 'modules/store/actions';
import { StoreDetailActions } from 'modules/storeDetail/actions';
import { getInitialGroup, getInitialStores } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { GmbActions } from '../actions';

import {
  createGmbReviewTemplate,
  deleteGmbReviewTemplate,
  getGmbReviewTemplateList,
  updateGmbReviewTemplate,
} from './review-template';

export default function* saga() {
  yield takeLatest(GmbActions.getGmbList, getGmbList);
  yield takeLatest(GmbActions.selectGmbAccount, selectGmbAccount);
  yield takeLatest(GmbActions.selectGmbGroup, selectGmbGroup);
  yield takeLatest(GmbActions.selectGmbLocation, selectGmbLocation);
  yield takeLatest(GmbActions.clearModalData, clearModalData);
  yield takeLatest(GmbActions.connectGmb, connectGmb);
  yield takeLatest(GmbActions.getGmbCategoryList, getGmbCategoryList);
  yield takeLatest(GmbActions.getInsightTotalList, getInsightTotalList);
  yield takeLatest(GmbActions.addGmbInsightSelectedStore, addGmbInsightSelectedStore);
  yield takeLatest(GmbActions.getReview, getReview);
  yield takeLatest(GmbActions.removeGmbAccount, removeGmbAccount);
  yield takeLatest(GmbActions.sendReviewReply, sendReviewReply);
  yield takeLatest(GmbActions.changeReviewStatus, changeReviewStatus);
  yield takeLatest(GmbActions.getGmbReviewTemplateList, getGmbReviewTemplateList);
  yield takeLatest(GmbActions.updateGmbReviewTemplate, updateGmbReviewTemplate);
  yield takeLatest(GmbActions.createGmbReviewTemplate, createGmbReviewTemplate);
  yield takeLatest(GmbActions.deleteGmbReviewTemplate, deleteGmbReviewTemplate);
  yield takeLatest(GmbActions.setSearchCondition, setSearchCondition);
  yield takeLatest(GmbActions.fetchReviews, fetchReviews);
  yield takeLatest(GmbActions.initializeReviewListPage, initializeReviewListPage);
  yield takeLatest(GmbActions.getReviewCsvDownload, getReviewCsvDownload);
  yield takeLatest(GmbActions.checkReviewCsvDownloadStatus, checkReviewCsvDownloadStatus);
  yield takeLatest(GmbActions.importStoresFromGbp, importStoresFromGbp);
  yield takeLatest(GmbActions.checkImportStoresFromGbpStatus, checkImportStoresFromGbpStatus);

  yield takeLatest(LOCATION_CHANGE, reviewListLocationChange);
}

function* getGmbList(action: ReturnType<typeof GmbActions.getGmbList>) {
  const response: YieldReturn<typeof GmbAccountApi.get> = yield GmbAccountApi.get();

  if (response.isSuccess) {
    yield put(GmbActions.setGmbList(response.data));
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィール 一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getGmbCategoryList(action: ReturnType<typeof GmbActions.getGmbList>) {
  const response: YieldReturn<typeof GmbCategoryListApi.get> = yield GmbCategoryListApi.get();

  if (response.isSuccess) {
    yield put(GmbActions.setGmbCategoryList(response.data));
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィール カテゴリーの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* selectGmbAccount(action: ReturnType<typeof GmbActions.selectGmbAccount>) {
  yield put(AppActions.setLoading(true));

  const accountId = action.payload;
  const response: YieldReturn<typeof GmbGroupApi.get> = yield GmbGroupApi.get(accountId);

  if (response.isSuccess) {
    yield put(GmbActions.setGroupList({ data: response.data.accounts, currentAccountId: accountId }));
  } else {
    // 権限がない場合Googleアカウントトップへ遷移
    if (response.error.response?.status === 403) {
      yield put(AppActions.moveTo(Path.gmb.index));
    }

    toast({
      type: 'error',
      title: 'Googleビジネスプロフィール グループ一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* selectGmbGroup(action: ReturnType<typeof GmbActions.selectGmbAccount>) {
  yield put(AppActions.setLoading(true));

  const groupName = action.payload;
  const accountId: string = yield select((state: State) => state.gmb.currentAccountId);
  const response: YieldReturn<typeof GmbLocationApi.get> = yield GmbLocationApi.get(accountId, groupName);

  if (response.isSuccess) {
    yield put(
      GmbActions.setLocationList({
        data: response.data.locations,
        currentGroupId: groupName,
        importedStores: response.data.imported_stores,
      }),
    );
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィール ロケーション一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* selectGmbLocation(action: ReturnType<typeof GmbActions.selectGmbAccount>) {
  yield put(AppActions.setLoading(true));

  const locationName = action.payload;
  yield put(GmbActions.setCurrentLocationId(locationName));

  yield put(AppActions.setLoading(false));
}

function* connectGmb() {
  yield put(AppActions.setLoading(true));

  const store: Store | null = yield select((state: State) => state.gmb.targetStore);
  const accountId: string = yield select((state: State) => state.gmb.currentAccountId);
  const locationId: string = yield select((state: State) => state.gmb.currentLocationId);

  const params = {
    account_id: accountId,
    location_id: locationId,
  };

  if (!store) {
    return;
  }

  const response: YieldReturn<typeof StoreConnectGmbApi.post> = yield StoreConnectGmbApi.post(store.id, params);

  if (response.isSuccess) {
    yield put(StoreActions.getStores());
    // FIXME 店舗個別ページ向けのデータ取得がここで呼ばれているのはおかしい
    yield put(StoreDetailActions.getStore(store.id));
    clearModalData();
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィール ロケーションとの紐付けに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

// 「店舗を比較」タブで表示するデータを取得する（旧API）
function* getInsightTotalList(action: ReturnType<typeof GmbActions.getInsightTotalList>) {
  const params = action.payload.requestParams;
  const response: YieldReturn<typeof GmbInsightTotalListApi.get> = yield GmbInsightTotalListApi.get(params);

  if (response.isSuccess) {
    const insight: GmbInsight = yield select((state: State) => state.gmb.insight);
    yield put(GmbActions.updateGmbInsight(insight.changeDataList(response.data)));
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィール インサイトの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* addGmbInsightSelectedStore(action: ReturnType<typeof GmbActions.addGmbInsightSelectedStore>) {
  const storeId = action.payload;
  const gmbInsight: GmbInsight = yield select((state) => state.gmb.insight);
  yield put(GmbActions.updateGmbInsight(gmbInsight.addSelectedStoreList(storeId)));
}

function* clearModalData() {
  yield put(GmbActions.clearTargetStore());
}

function* fetchReviews(action: ReturnType<typeof GmbActions.fetchReviews>) {
  yield put(GmbActions.setIsLoadingReviews(true));
  const searchCondition: GmbReviewSearchCondition = yield select((state) => state.gmb.reviewSearchCondition);
  const params = searchCondition.toRequestParams();
  const response: YieldReturn<typeof GmbReviewApi.get> = yield GmbReviewApi.get(params);

  if (response.isSuccess) {
    // 確定済みの検索条件を保持する
    yield put(GmbActions.setCommittedSearchCondition({ searchCondition }));
    yield put(GmbActions.setGmbReview(new GmbReview(response.data)));
  } else {
    toast({
      type: 'error',
      title: 'クチコミ一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(GmbActions.setIsLoadingReviews(false));
}

function* getReview(action: ReturnType<typeof GmbActions.getReview>) {
  yield put(GmbActions.setIsLoadingReview(true));
  // 取得中に前回の結果が表示されないように、一旦空の状態にしておく
  yield put(GmbActions.setReview(new ReviewDetail()));
  const response: YieldReturn<typeof GmbReviewDetailApi.get> = yield GmbReviewDetailApi.get(action.payload);

  if (response.isSuccess) {
    yield put(GmbActions.setReview(new ReviewDetail(response.data)));
  } else {
    if (response.status !== 403 && response.status !== 404) {
      toast({
        type: 'error',
        title: 'クチコミ詳細の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
    // 詳細ページを表示できないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.gmb.review));
  }

  yield put(GmbActions.setIsLoadingReview(false));
}

function* changeReviewStatus(action: ReturnType<typeof GmbActions.changeReviewStatus>) {
  yield put(AppActions.setLoading(true));
  const response: YieldReturn<typeof GmbReviewStatusApi.put> = yield GmbReviewStatusApi.put(action.payload);

  if (response.isSuccess) {
    yield put(GmbActions.getReview(action.payload.gmb_review_id));
    toast({
      type: 'success',
      title: 'クチコミのステータスを変更しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'クチコミのステータス変更に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* removeGmbAccount(action: ReturnType<typeof GmbActions.removeGmbAccount>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof GmbAccountApi.delete> = yield GmbAccountApi.delete(action.payload);

  if (response.isSuccess) {
    yield put(GmbActions.getGmbList());
    toast({
      type: 'success',
      title: 'アカウントの連携を解除しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'アカウント連携の解除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* sendReviewReply(action: ReturnType<typeof GmbActions.sendReviewReply>) {
  yield put(AppActions.setLoading(true));

  const currentUser: User = yield select((state) => state.app.currentUser);

  const params = { gmb_review_id: action.payload.reviewId, comment: action.payload.text, created_by: currentUser.id };
  const response: YieldReturn<typeof GmbReviewReplyApi.post> = yield GmbReviewReplyApi.post(params);

  if (response.isSuccess) {
    yield put(GmbActions.getReview(action.payload.reviewId));
    toast({
      type: 'success',
      title: '返信が完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'クチコミの返信に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* setSearchCondition(action: ReturnType<typeof GmbActions.setSearchCondition>) {
  const { searchCondition, updateLocation = true } = action.payload;

  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // updateLocationがtrueの場合、かつ既存とURLに差分がある場合、URLの更新を行う
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
  }
}

/**
 * URLからクチコミ一覧を更新する
 *
 * 本処理は、以下の２処理から呼ばれる
 * - initializeReviewListPage
 * - reviewListLocationChange
 *
 * 理由は、初期処理に必要な、currentUserに有効な値が入るのを待たねばならないため
 * ページ側処理で、initialize処理をトリガーしているため。
 * それ以外の場合は、URLの変更をトリガーに動く。
 */
function* updateReviewListByLocation() {
  const location: Location = yield select((state: RouterRootState) => state.router.location);

  // URLパラメータから検索条件を復元する
  let searchCondition = GmbReviewSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.store });
  searchCondition = searchCondition.setIn(['filter', 'store'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.filter.store,
    containsClosedStores: searchCondition.filter.show_closed_store,
  });

  const currentStoreIds = searchCondition.filter.store_ids;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  // URLと検索条件から生成されるURLが異なる場合（不要なパラメータがある場合など）、正しいURLにreplace
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // 検索条件のセットと、クチコミ一覧の取得
  yield put(GmbActions.setSearchCondition({ searchCondition, updateLocation: false }));
  yield put(GmbActions.fetchReviews());
}

// クチコミ一覧ページを初期化する
function* initializeReviewListPage(action: ReturnType<typeof GmbActions.initializeReviewListPage>) {
  yield put(GmbActions.setIsPreparedForReviewListPage(true));
  yield call(updateReviewListByLocation);
}

// クチコミ一覧ページにおけるURL（パラメータ）変更時の処理
function* reviewListLocationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // パスがクチコミ一覧ページ以外の場合は対象外
  if (location.pathname !== Path.gmb.review) {
    return;
  }

  // クチコミ一覧ページの初期処理が完了していない場合は何もしない
  const isPreparedForReviewListPage: boolean = yield select((state) => state.gmb.isPreparedForReviewListPage);
  if (!isPreparedForReviewListPage) {
    return;
  }

  yield call(updateReviewListByLocation);
}

function* getReviewCsvDownload(action: ReturnType<typeof GmbActions.getReviewCsvDownload>) {
  yield put(AppActions.setLoading(true));
  const csvDownload: ExecutionArnStatus = yield select((state) => state.gmb.csvDownload);
  const response: YieldReturn<typeof GmbReviewDownloadApi.post> = yield GmbReviewDownloadApi.post(action.payload);
  if (response.isSuccess) {
    yield put(GmbActions.setCsvDownload(csvDownload.setExecutionArn(response.data.executionArn)));
  } else {
    yield put(AppActions.setLoading(false));
    toast({
      type: 'error',
      title: 'ファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* checkReviewCsvDownloadStatus(action: ReturnType<typeof GmbActions.checkReviewCsvDownloadStatus>) {
  const csvDownload: ExecutionArnStatus = yield select((state) => state.gmb.csvDownload);
  if (csvDownload.executionArn) {
    const response: YieldReturn<typeof GmbReviewDownloadStatusCheckApi.post> =
      yield GmbReviewDownloadStatusCheckApi.post({ executionArn: csvDownload.executionArn });
    if (response.isSuccess) {
      if (response.data.status === 'RUNNING') {
        // do nothing
      } else if (response.data.status === 'SUCCEEDED') {
        const downloadUrl = response.data.download_url;
        if (downloadUrl) {
          window.location.assign(downloadUrl);
          toast({
            type: 'success',
            title: 'ファイルをダウンロードしました',
          });
        } else {
          toast({
            type: 'info',
            title: String(response.data.message),
            time: 10000,
          });
        }
        yield put(GmbActions.clearCsvDownload());
        yield clearInterval(action.payload);
        yield put(GmbActions.setIsOpenReviewDownloadModal(false));
        yield put(AppActions.setLoading(false));
      } else {
        yield put(GmbActions.clearCsvDownload());
        toast({
          type: 'error',
          title: String(response.data.message),
          description: '',
          time: 10000,
        });
        yield clearInterval(action.payload);
        yield put(AppActions.setLoading(false));
      }
    } else {
      yield put(AppActions.setLoading(false));
      toast({
        type: 'error',
        title: 'ファイルのダウンロードに失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
  }
}

function* importStoresFromGbp(action: ReturnType<typeof GmbActions.importStoresFromGbp>) {
  yield put(AppActions.setLoading(true));

  const accountIdStr: string = yield select((state: State) => state.gmb.currentAccountId);
  const accountId = parseInt(accountIdStr, 10);
  const locationList: List<GmbLocation> = yield select((state: State) => state.gmb.locationList);
  const locationIds = locationList
    .filter((location: GmbLocation) => action.payload.includes(location.name))
    .map((location: GmbLocation) => location.name)
    .toArray();

  const params = { account_id: accountId, location_ids: locationIds };

  const response: YieldReturn<typeof ImportGBPStoreApi.post> = yield ImportGBPStoreApi.post(params);

  if (response.isSuccess) {
    const { executionArn } = response.data;
    yield put(GmbActions.setImportStoresFromGbpStatus(new ExecutionArnStatus({ executionArn })));
    yield put(GmbActions.checkImportStoresFromGbpStatus());
  } else {
    toast({
      type: 'error',
      title: '店舗の取り込みの開始に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(AppActions.setLoading(false));
  }
}

function* checkImportStoresFromGbpStatus() {
  const importStoresFromGBPStatus: ExecutionArnStatus = yield select(
    (state: State) => state.gmb.importStoresFromGBPStatus,
  );
  const executionArn = importStoresFromGBPStatus.executionArn;

  if (!executionArn) {
    yield put(AppActions.setLoading(false));
    return;
  }

  const response: YieldReturn<typeof ImportGBPStoreStatusApi.post> = yield ImportGBPStoreStatusApi.post({
    executionArn,
  });

  if (response.isSuccess) {
    const { data } = response;
    if (data.status === 'RUNNING') {
      // 3秒後に再度チェックを行う
      yield delay(3000);
      yield put(GmbActions.checkImportStoresFromGbpStatus());
      return;
    } else {
      if (data.status === 'SUCCEEDED') {
        yield put(StoreActions.getStores());
        yield put(AppActions.moveTo(Path.store.index));
        toast({
          type: 'success',
          title: '店舗の取り込みが完了しました',
        });
      } else {
        // FAILED
        toast({
          type: 'error',
          title: '店舗の取り込みに失敗しました',
        });
      }
      yield put(GmbActions.clearImportStoresFromGbpStatus());
      yield put(AppActions.setLoading(false));
    }
  } else {
    toast({
      type: 'error',
      title: '店舗取り込みのステータス取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(AppActions.setLoading(false));
    return;
  }
}
