import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { Comment } from 'models/Domain/Comment';
import { FileContents } from 'models/Domain/FileContent';
import { OfferStatus } from 'models/Domain/Offer';
import { OfferImageComment } from 'models/Domain/OfferImageComment';
import { Stamp } from 'models/Domain/Stamp';
import { User, UserList } from 'models/Domain/User';
import { JSObject } from 'types/Common';

export class OfferTimeline extends Record<{
  type: 'status_change' | 'comment' | 'stamp' | 'image_comment';
  create_at: Dayjs;
  status: null | OfferStatus;
  user: User;
  comment: Comment;
  stamp: Stamp;
  offer_image_comment: OfferImageComment;
  read_users: UserList;
}>({
  type: 'comment',
  create_at: dayjs(),
  status: null,
  user: new User(),
  comment: new Comment(),
  stamp: new Stamp(),
  offer_image_comment: new OfferImageComment(),
  read_users: new UserList(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.create_at = dayjs.unix(params.create_at);
    params.comment = new Comment(params.comment);
    params.stamp = new Stamp(params.stamp);
    params.offer_image_comment = new OfferImageComment(params.offer_image_comment);
    params.user = new User(params.user);
    params.read_users = new UserList(params.read_users);
    super(params);
  }

  get isDone() {
    return this.status === 'done';
  }

  /** コメントの既読者のリストから引数のUserを省いたTimelineインスタンスを返す */
  exceptCurrentUserRead(user: User) {
    return this.update('read_users', (read_users) => read_users.getUserswithoutTarget(user));
  }

  isRead(user: User) {
    if (this.user.id === user.id) {
      return true;
    }
    return Boolean(this.read_users.list.find((read_user) => read_user.id === user.id));
  }
}

export class OfferTimelines extends Record<{
  list: List<OfferTimeline>;
}>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data.map((p) => new OfferTimeline(p)));
    super({ list });
  }

  sortedList() {
    return this.list.sortBy((timeline) => timeline.create_at.unix());
  }

  /** コメントの既読者のリストから引数のUserを省いて返す */
  getReadUsersExceptUser(index: number, user: User) {
    const timeline = this.sortedList().get(index) as OfferTimeline;
    return timeline.read_users.getUserswithoutTarget(user);
  }

  getFileContents(index: number) {
    const timeline = this.sortedList().get(index);
    if (!timeline) {
      return new FileContents();
    }
    if (timeline.type === 'comment') {
      return timeline.comment.content.files;
    }
    return new FileContents();
  }

  findLatestUnreadTimelineIndex(user: User) {
    const timelines = this.sortedList();
    return timelines.findIndex((timeline) => !timeline.isRead(user));
  }
}
