import { Record, Set } from 'immutable';

import { Stores } from 'models/Domain/Store';
import { JSObject } from 'types/Common';

type BulkEditStateType = 'none' | 'edit' | 'result';

export class BulkEditStoreState extends Record<{
  selectedStoreIds: Set<number>;
  bulkEditState: BulkEditStateType;
  isGmbSync: boolean;
  successStoreIds: number[];
  failureStoreIds: number[];
}>({
  selectedStoreIds: Set(),
  bulkEditState: 'none',
  isGmbSync: true,
  successStoreIds: [],
  failureStoreIds: [],
}) {
  changeSelectedStoreIds(storeId: number) {
    const { selectedStoreIds } = this;
    if (selectedStoreIds.includes(storeId)) {
      return this.set('selectedStoreIds', selectedStoreIds.delete(storeId));
    }
    return this.set('selectedStoreIds', selectedStoreIds.add(storeId));
  }

  selectAllStoreIds(stores: Stores) {
    const storeIds = stores.list.map((store) => store.id).toSet();
    return this.set('selectedStoreIds', this.selectedStoreIds.union(storeIds));
  }

  removeAllStoreIds() {
    return this.set('selectedStoreIds', this.selectedStoreIds.clear());
  }

  changeBulkEditState(bulkEditState: BulkEditStateType) {
    return this.set('bulkEditState', bulkEditState);
  }

  changeGmbSync(isGmbSync: boolean) {
    return this.set('isGmbSync', isGmbSync);
  }

  changeResultStoreIds(successStoreIds: number[], failureStoreIds: number[]) {
    return this.merge({ successStoreIds, failureStoreIds });
  }

  requestParams(stores: Stores) {
    const { selectedStoreIds } = this;

    const storeIds = selectedStoreIds.toArray();

    const params = {
      store_ids: storeIds,
      edit_params: {} as JSObject,
    };
    return params;
  }
}
