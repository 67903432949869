/**
 * パーセント表記のテキストを返す
 * @param value
 * @param fractionDigits
 */
export const getRateText = (value: number | null | undefined, fractionDigits = 2) =>
  value != null
    ? `${(value * 100).toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })}%`
    : 'ー';

/**
 * 3桁ごとにカンマ区切りのテキストを返す
 * @param value
 * @param fractionDigits
 */
export const getValueText = (value: number | null | undefined, fractionDigits = 0) =>
  value != null
    ? `${value.toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })}`
    : 'ー';
