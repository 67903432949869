import React from 'react';

import { Link } from 'components/atoms/Link';
import { Store } from 'models/Domain/Store';
import { Path } from 'routes';

import { StoreLink, StyledFirstCell } from '..';

type StoreCellProps = {
  isFirstRowInStore: boolean;
  store: Store;
};

export const StoreCell: React.FC<StoreCellProps> = ({ isFirstRowInStore, store }) => {
  if (!isFirstRowInStore) {
    return <StyledFirstCell first='false' />;
  }
  return (
    <StyledFirstCell first='true'>
      {store.code && <div>{store.code}</div>}
      <div>{store.name}</div>
      {store.branch && <div>{store.branch}</div>}
      <Link to={Path.store.detail.replace(':storeId', String(store.id))}>
        <StoreLink>[ 店舗詳細 ]</StoreLink>
      </Link>
    </StyledFirstCell>
  );
};
