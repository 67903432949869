import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Icon, Radio } from 'semantic-ui-react';
import styled from 'styled-components';

import 'semantic-ui-css/semantic.min.css';
import { Button } from 'components/atoms/Button';
import { COLOR } from 'style/color';

/**
 * 検索条件を指定するためのメニューで、「キャンセル」ボタンと「適用」ボタンをもつ
 *
 * @param className
 * @param icon デフォルト以外のアイコンを表示したい場合に指定する
 * @param children コンテンツ
 * @param value Triggerに表示するテキスト
 * @param onCancel キャンセルボタンを押したときの処理
 * @param onApply 適用ボタンを押したときの処理
 * @param onClose メニューを閉じたときの処理
 * @constructor
 */
export const SearchPullDown: React.FC<{
  className?: string;
  icon?: ReactNode;
  children: ReactNode;
  value: string;
  onCancel?: () => void;
  onApply?: () => void;
  onClose?: () => void;
}> = ({ className, icon, children, value, onCancel, onApply, onClose }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  // メニューを閉じる際の処理
  const closeMenu = useCallback(() => {
    setIsOpenMenu(false);
    onClose && onClose();
  }, [onClose]);

  // コンポーネント内、かつラベル部分をクリックした場合、開閉処理
  const handleOnClickTrigger = useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      if (isOpenMenu) {
        closeMenu();
        event.stopPropagation();
      } else {
        setIsOpenMenu(true);
      }
    },
    [isOpenMenu, closeMenu],
  );

  // コンポーネント外をクリックした場合、閉じる
  const handleOnClickOutsideMenu = useCallback(
    (event: Event) => {
      // クリックされたのがコンポーネント内の場合は、対象外
      if (event.target instanceof Node && wrapperRef?.current?.contains(event.target)) {
        return;
      }
      closeMenu();
    },
    [closeMenu],
  );

  // 開閉状態、ハンドラ（辿っていくとonClose）に変更があった場合のイベントリスナーの付け替え
  useEffect(() => {
    if (isOpenMenu) {
      // メニューを開いた場合
      document.addEventListener('click', handleOnClickOutsideMenu);
    } else {
      // メニューを閉じた場合
      document.removeEventListener('click', handleOnClickOutsideMenu);
    }
    return () => {
      // ハンドラが変更された際の付け替えのためのイベントリスナー削除
      document.removeEventListener('click', handleOnClickOutsideMenu);
    };
  }, [isOpenMenu, handleOnClickOutsideMenu]);

  const handleOnClickCancel = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      closeMenu();
      onCancel && onCancel();
      event.stopPropagation();
    },
    [closeMenu, onCancel],
  );

  const handleOnClickApply = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      closeMenu();
      onApply && onApply();
      event.stopPropagation();
    },
    [closeMenu, onApply],
  );

  return (
    <TriggerWrapper className={className} ref={wrapperRef} active={isOpenMenu} onClick={handleOnClickTrigger}>
      <Trigger active={isOpenMenu}>
        {icon ? icon : <DropdownIcon size='large' name='dropdown' />}
        <TriggerText>{value}</TriggerText>
      </Trigger>
      <SelectMenu active={isOpenMenu} onClick={(e) => e.stopPropagation()}>
        <Contents onClick={(e) => e.stopPropagation()}>{children}</Contents>
        <ActionContainer onClick={(e) => e.stopPropagation()}>
          <StyledButton onClick={(e) => handleOnClickCancel(e)}>キャンセル</StyledButton>
          <StyledButton onClick={(e) => handleOnClickApply(e)} priority='high'>
            適用
          </StyledButton>
        </ActionContainer>
      </SelectMenu>
    </TriggerWrapper>
  );
};

const TriggerWrapper = styled.div<{ active: boolean; error?: boolean }>`
  position: relative;
  border-radius: 8px;
  display: inline-block;
  ${(props) =>
    props.active &&
    `
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #05ccad;
    `}
  &:focus {
    outline: none;
  }
`;

const Trigger = styled.div<{ active: boolean }>`
  color: #393939;
  font-size: 16px;
  padding: 4px 6px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  ${(props) =>
    props.active &&
    `
      background: rgba(0, 0, 0, 0.05);
    `}
`;

const SelectMenu = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 10;
  top: 50px;
  left: 0;
  background-color: white;
  min-width: 280px;
  max-height: 360px;
  overflow-y: auto;
  border: 1px solid ${COLOR.GRAY};
  border-radius: 5px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.2);
  ${(props) => !props.active && 'display: none;'}
`;

const DropdownIcon = styled(Icon)`
  &&& {
    width: 16px;
    font-size: 16px;
    margin-bottom: 4px;
    margin-right: 15px;
  }
`;

const TriggerText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  padding: 8px 15px 8px 0;
`;

const ActionContainer = styled.div`
  display: flex;
  border-top: 1px solid #b5b5b5;
  justify-content: center;
  padding: 16px;
  > :not(:last-child) {
    margin-right: 24px;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    width: 112px;
    height: 48px;
    padding: 0;
  }
`;

const Contents = styled.div`
  padding: 16px;
  overflow-y: scroll;
  max-height: 276px;
`;

export const CustomRadio = styled(Radio)`
  &&& {
    width: 100%;
    input {
      &:checked ~ label:before {
        border: 1px solid ${COLOR.GREEN};
      }
      &:checked ~ label:after {
        background: ${COLOR.GREEN};
      }
      &:focus {
        &:checked ~ label:after {
          background: ${COLOR.GREEN};
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;
