import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

export const ImageCommentOrderIcon: React.FC<{ className?: string; order_num: number }> = ({
  className,
  order_num,
}) => {
  return <Wrapper className={className}>{order_num}</Wrapper>;
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.LIGHT_RED};
  border: solid 2px #f5f5f5;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-weight: bold;
  flex: none;
`;
