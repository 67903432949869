import styled from 'styled-components';

export const SectionWrapper = styled.div`
  margin-top: 32px;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const SectionTitle = styled.div`
  font-weight: bold;
`;
