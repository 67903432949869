import { List as ImmutableList } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { InstagramAccountApi, InstagramApi } from 'ApiClient/InstagramApi';
import { replaceWithOrganizationId } from 'helpers/router';
import { InstagramAccount, InstagramBusinessAccount } from 'models/Domain/instagram';
import { AppActions } from 'modules/app/actions';
import { InstagramActions } from 'modules/instagram/actions';
import { waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

export default function* saga() {
  yield takeLatest(InstagramActions.initializeRegisterPage, initializeRegisterPage);
  yield takeLatest(InstagramActions.initializeIndexPage, initializeIndexPage);
  yield takeLatest(InstagramActions.initializeEditPage, initializeEditPage);
  yield takeLatest(InstagramActions.deleteAccountForIndex, deleteAccountForIndex);
  yield takeLatest(InstagramActions.deleteAccountForDetail, deleteAccountForDetail);
  yield takeLatest(InstagramActions.createAccount, createAccount);
  yield takeLatest(InstagramActions.updateAccount, updateAccount);
  yield takeLatest(InstagramActions.registerAccounts, registerAccounts);
  yield takeLatest(InstagramActions.getInstagramAccounts, getInstagramAccounts);
}

function* initializeRegisterPage() {
  // 登録済みのInstagramアカウントのリストを取得する
  const response: YieldReturn<typeof InstagramApi.getList> = yield InstagramApi.getList();
  if (response.isSuccess) {
    yield put(
      // 登録済みのInstagramアカウントのうち、無効になっているものは除外する
      InstagramActions.setInstagramAccounts(
        ImmutableList(
          response.data.items.map((item) => InstagramAccount.fromJSON(item)).filter((account) => account.isValid),
        ),
      ),
    );
  } else {
    toast({
      type: 'error',
      title: 'インスタグラムアカウントの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(InstagramActions.setAccountsForRegister(ImmutableList()));
}

// Instagramアカウント一覧画面の初期設定
function* initializeIndexPage() {
  yield put(InstagramActions.setIsLoadingInstagramAccounts(true));

  const response: YieldReturn<typeof InstagramApi.getList> = yield InstagramApi.getList();
  if (response.isSuccess) {
    yield put(
      InstagramActions.setInstagramAccounts(
        ImmutableList(response.data.items.map((item) => InstagramAccount.fromJSON(item))),
      ),
    );
  } else {
    toast({
      type: 'error',
      title: 'インスタグラムアカウントの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(InstagramActions.setIsLoadingInstagramAccounts(false));
}

// Instagramアカウント編集画面の初期化
function* initializeEditPage(action: ReturnType<typeof InstagramActions.initializeEditPage>) {
  yield put(InstagramActions.setIsLoadingInstagramAccount(true));
  yield call(waitForUserAndStoresInitialized);

  const id = action.payload;
  const response: YieldReturn<typeof InstagramApi.get> = yield InstagramApi.get(id);
  if (response.isSuccess) {
    // アカウントが取得できないとき、200でresponse.dataにnullが返ってくる
    if (response.data) {
      yield put(InstagramActions.setInstagramAccount(InstagramAccount.fromJSON(response.data)));
    } else {
      // 編集ページに表示できるデータがないので、一覧ページに遷移する
      yield put(replaceWithOrganizationId(Path.instagram.index));
    }
  } else {
    if (response.status !== 403 && response.status !== 404) {
      toast({
        type: 'error',
        title: 'インスタグラムアカウントの取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
    // 編集ページに表示できるデータがないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.instagram.index));
  }

  yield put(InstagramActions.setIsLoadingInstagramAccount(false));
}

// Instagramアカウントの連携解除
function* deleteAccountForIndex(action: ReturnType<typeof InstagramActions.deleteAccountForIndex>) {
  yield put(InstagramActions.setIsLoadingInstagramAccounts(true));

  const id = action.payload;
  const response: YieldReturn<typeof InstagramApi.delete> = yield InstagramApi.delete(id);
  if (response.isSuccess) {
    toast({ type: 'success', title: 'Instagramアカウントの連携を解除しました' });
    // アカウント情報の再取得
    yield call(initializeIndexPage);
  } else {
    toast({
      type: 'error',
      title: 'インスタグラムアカウントの連携解除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(InstagramActions.setIsLoadingInstagramAccounts(false));
}

// Instagramアカウントの連携解除
function* deleteAccountForDetail(action: ReturnType<typeof InstagramActions.deleteAccountForDetail>) {
  yield put(InstagramActions.setIsLoadingInstagramAccount(true));

  const id = action.payload;
  const response: YieldReturn<typeof InstagramApi.delete> = yield InstagramApi.delete(id);
  if (response.isSuccess) {
    toast({ type: 'success', title: 'Instagramアカウントの連携を解除しました' });
    // アカウント一覧画面へ戻る
    yield put(AppActions.moveTo(Path.instagram.index));
  } else {
    toast({
      type: 'error',
      title: 'インスタグラムアカウントの連携解除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(InstagramActions.setIsLoadingInstagramAccount(false));
}

function* createAccount(action: ReturnType<typeof InstagramActions.createAccount>) {
  yield put(InstagramActions.setIsLoadingInstagramAccount(true));

  const params = action.payload.createParams;
  const response: YieldReturn<typeof InstagramApi.post> = yield InstagramApi.post(params);
  if (response.isSuccess) {
    toast({ type: 'success', title: 'Instagramアカウントを追加しました' });
    // 一覧画面に戻る
    yield put(AppActions.moveTo(Path.instagram.index));
  } else {
    toast({
      type: 'error',
      title: 'インスタグラムアカウントの追加に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(InstagramActions.setIsLoadingInstagramAccount(false));
}

function* updateAccount(action: ReturnType<typeof InstagramActions.updateAccount>) {
  yield put(InstagramActions.setIsLoadingInstagramAccount(true));

  const id = action.payload.id;
  const params = action.payload.updateParams;
  const response: YieldReturn<typeof InstagramApi.patch> = yield InstagramApi.patch(id, params);
  if (response.isSuccess) {
    toast({ type: 'success', title: 'Instagramアカウントを更新しました' });
    yield put(InstagramActions.setInstagramAccount(InstagramAccount.fromJSON(response.data)));
  } else {
    toast({
      type: 'error',
      title: 'インスタグラムアカウントの更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(InstagramActions.setIsLoadingInstagramAccount(false));
}

function* getInstagramAccounts(action: ReturnType<typeof InstagramActions.getInstagramAccounts>) {
  const { userId, userAccessToken } = action.payload;
  yield put(AppActions.setLoading(true));

  const executionArnResponse: YieldReturn<typeof InstagramAccountApi.getAccounts> = yield call(
    InstagramAccountApi.getAccounts,
    {
      fb_user_id: userId,
      fb_user_access_token: userAccessToken,
    },
  );

  let executionArn: string;
  if (executionArnResponse.isSuccess) {
    executionArn = executionArnResponse.data.executionArn;
  } else {
    toast({
      type: 'error',
      title: 'Instagramアカウントの取得に失敗しました',
      description: String(executionArnResponse.error.message),
      time: 10000,
    });

    yield put(AppActions.setLoading(false));

    return;
  }

  // 取得が完了しているか定期的に確認する
  let message = '';
  while (true) {
    const statusResponse: YieldReturn<typeof InstagramAccountApi.getStatus> = yield InstagramAccountApi.getStatus({
      executionArn,
    });
    if (statusResponse.isSuccess) {
      const data = statusResponse.data;
      if (data.status === 'RUNNING') {
        // 実行中なので、3秒後に再度チェックする
        yield delay(3000);
        continue;
      } else if (data.status === 'SUCCEEDED') {
        // レスポンスURLのファイルをダウンロードする
        const accountsResponse: YieldReturn<typeof InstagramAccountApi.getData> = yield InstagramAccountApi.getData(
          data.download_url,
        );
        if (accountsResponse.data) {
          yield put(AppActions.setLoading(false));
          const items = accountsResponse.data.items;
          const accounts = ImmutableList(items.map((item) => InstagramBusinessAccount.fromJSON(item)));
          yield put(InstagramActions.setAccountsForRegister(accounts));
          return accountsResponse.data;
        }
        break;
      } else {
        // 失敗したのでエラーメッセージを表示する
        message = data.message;
      }
    } else {
      message = statusResponse.error.message;
    }

    toast({
      type: 'error',
      title: 'Instagramアカウントの取得に失敗しました',
      description: String(message),
      time: 10000,
    });
    // 取得に失敗したのでループを抜ける
    break;
  }

  yield put(AppActions.setLoading(false));
}

function* registerAccounts(action: ReturnType<typeof InstagramActions.registerAccounts>) {
  const accounts = action.payload;
  yield put(AppActions.setLoading(true));
  // 連携がエラーになったアカウントが存在するか
  let hasError = false;
  // 詳細画面に遷移させるためにレスポンスのidを保持しておく
  let id: number | null = null;
  for (const account of accounts.toArray()) {
    const response: YieldReturn<typeof InstagramApi.post> = yield InstagramApi.post({
      ig_user_id: account.userId,
      ig_name: account.name,
      ig_username: account.userName,
      page_id: account.pageId,
      page_access_token: account.pageAccessToken,
      scopes: account.scopes.toArray(),
    });
    if (!response.isSuccess) {
      hasError = true;
      response.error.message;
    } else {
      id = response.data.id;
    }
  }
  if (hasError) {
    toast({
      type: 'error',
      title: 'Instagramアカウントの連携に失敗しました',
      time: 10000,
    });
  } else {
    toast({ type: 'success', title: 'Instagramアカウントを連携しました' });
  }
  yield put(AppActions.setLoading(false));
  // エラーになったアカウントがあるか、2つ以上のアカウントと連携した場合は一覧画面に遷移する
  // 連携したアカウントが１つのみの場合は、詳細画面に遷移する
  if (hasError || accounts.size > 1 || !id) {
    yield put(replaceWithOrganizationId(Path.instagram.index));
  } else {
    yield put(replaceWithOrganizationId(Path.instagram.edit.replace(':id', `${id}`)));
  }
}
