import React, { useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { AlbumImageStoreList } from 'components/pageComponents/AlbumImage/AlbumImageStoreList';
import { CategorySelectModal } from 'components/pageComponents/AlbumImage/CategorySelectModal';
import { ImageStatusEditModal } from 'components/pageComponents/AlbumImage/ImageStatusEditModal';
import { MainWrapper } from 'components/templates/MainWrapper';
import { createAlbumListIndexPath } from 'helpers/path';
import { getPageTitle } from 'helpers/utils';
import { AppActions } from 'modules/app/actions';
import { ImageActions } from 'modules/image/actions';
import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';

type Props = {
  match: { params: { imageId: string } };
};

export const AlbumImage = React.memo<Props>(
  ({
    match: {
      params: { imageId },
    },
  }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [categorySelectOpen, setCategorySelectOpen] = useState(false);
    const [submitGmbConnection, setSubmitGmbConnection] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const {
      detailImage: image,
      currentUser,
      hashtagList,
      editHashtagList,
      editCategory,
      stores,
    } = useSelector(
      (state) => ({
        detailImage: state.image.detailImage,
        currentUser: state.app.currentUser,
        hashtagList: state.image.hashtagList,
        editHashtagList: state.image.editHashtagList,
        editCategory: state.image.editCategory,
        stores: state.store.stores,
      }),
      shallowEqual,
    );

    const canUseProductCategory = currentUser.organization?.params.can_use_gbp_media_product_category ?? false;

    const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
    const imageActions = useMemo(() => bindActionCreators(ImageActions, dispatch), [dispatch]);

    useEffect(() => {
      imageActions.getImage(Number(imageId));
      imageActions.getHashtagList();

      return () => {
        setIsValidated(false);
      };
    }, [imageActions, imageId]);

    const loadImage = (src: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    };

    useEffect(() => {
      if (!image.url) {
        return;
      }
      const validateImage = async () => {
        try {
          const img = await loadImage(image.url);
          if (img === null) {
            return;
          }

          const messages = [];
          if (img.naturalWidth < 250) {
            messages.push('ファイルの幅は250px以上である必要があります');
          }
          if (img.naturalWidth > 10000) {
            messages.push('ファイルの幅が10,000pxを超えています');
          }
          if (img.naturalHeight < 250) {
            messages.push('ファイルの高さは250px以上である必要があります');
          }
          if (img.naturalHeight > 10000) {
            messages.push('ファイルの高さが10,000pxを超えています');
          }
          setErrorMessages(messages);
          setIsValidated(true);
        } catch (e) {
          console.log(e);
        }
      };
      validateImage();
    }, [image]);

    /** 写真の操作に関する権限を有しているか */
    const hasChangeAuthorityMultiStoresImage = image ? image.hasChangeAuthorityMultiStoresImage(currentUser) : false;

    /** 写真がGoogleビジネスプロフィールに連携できるかどうか */
    const canPublishToGmb = image ? image.canPublishToGmb(currentUser, stores) : false;

    /** GBP連携されている店舗が含まれているか */
    const hasPublishedGmbImage = image ? image.hasPublishedGmbImage(currentUser) : false;

    /** GBP連携/連携解除中の店舗が含まれているか */
    const hasInprogressGmbImage = image ? image.hasInprogressGmbImage(currentUser) : false;

    if (!image) return null;

    if (image.image_resource_id !== Number(imageId)) return null;

    const onClickDeleteButton = () => {
      if (window.confirm('写真を削除してよろしいですか？')) {
        imageActions.deleteImage(image.image_resource_id);
      }
    };

    const onClickDisconnectGmbButton = () => {
      if (window.confirm('Googleビジネスプロフィールから削除してよろしいですか？')) {
        imageActions.disconnectImages([image.image_resource_id]);
        imageActions.getImage(image.image_resource_id);
        setSubmitGmbConnection(true);
      }
    };

    if (!image.hasImageInfo) {
      return <></>;
    }

    return (
      <MainWrapper>
        <Helmet title={getPageTitle('写真詳細')} />
        <ImageContainer>
          <img src={image.url} />
        </ImageContainer>
        <ContentWrapper>
          <Container>
            <FlexWrapper>
              <ContainerTitle>STORECAST写真登録日</ContainerTitle>
              {hasChangeAuthorityMultiStoresImage && (
                <ModalTrigger onClick={() => setOpen(true)}>写真情報を修正する</ModalTrigger>
              )}
            </FlexWrapper>
            <ContainerText>{image.formatCreateAt}</ContainerText>
          </Container>
          {!image.tags.isEmpty() && (
            <Container>
              <ContainerTitle>
                紐づいているタグ
                <ContextHelp
                  header={'タグの説明'}
                  content={
                    'タグを追加すると、STORECASTでの写真検索で利用できます。Googleビジネスプロフィールには反映されません。'
                  }
                />
              </ContainerTitle>
              {image.tags.map((tag, index) => {
                return (
                  <Hashtag key={index} onClick={() => moveTo(createAlbumListIndexPath({ tag }))}>
                    #{tag}
                  </Hashtag>
                );
              })}
            </Container>
          )}
          {image.formatGmbCreateAt && (
            <Container>
              <ContainerTitle>Googleビジネスプロフィール写真掲載日</ContainerTitle>
              <ContainerText>{image.formatGmbCreateAt}</ContainerText>
            </Container>
          )}
          {image.categoryLabel && (
            <Container>
              <ContainerTitle>カテゴリー</ContainerTitle>
              <ContainerCategoryText>{image.categoryLabel}</ContainerCategoryText>
            </Container>
          )}
          <GmbConnectionContainer>
            {
              // 変更権限が存在しない場合は、GBP関連のアクションボタンを表示しない
              // ボタンを表示する条件は以下
              // "INPROGRESS"(GBPに掲載 or GBPから削除)の店舗がある場合、「Googleビジネスプロフィールへの連携処理実行中...」(無効化されたボタン)
              // "PUBLISHED"の店舗がある場合「Googleビジネスプロフィールから削除」
              // "INPROGRESS"および"PUBLISHED"の店舗がない場合「Googleビジネスプロフィールに掲載する」
              // 掲載および削除は、そのアクションを行ったらボタンが無効化され、同一ページ内での２度の実行は不可
              hasChangeAuthorityMultiStoresImage && (
                <>
                  {hasInprogressGmbImage ? (
                    <GmbConnectionButton disabled={true}>
                      Googleビジネスプロフィールへの連携処理実行中...
                    </GmbConnectionButton>
                  ) : (
                    <>
                      {hasPublishedGmbImage && (
                        <GmbConnectionButton onClick={onClickDisconnectGmbButton} disabled={submitGmbConnection}>
                          Googleビジネスプロフィールから削除する
                        </GmbConnectionButton>
                      )}
                      {canPublishToGmb && currentUser.isCompanyUser && (
                        <GmbConnectionButton
                          onClick={() => setCategorySelectOpen(true)}
                          disabled={submitGmbConnection || !isValidated || errorMessages.length > 0}
                        >
                          Googleビジネスプロフィールに掲載する
                        </GmbConnectionButton>
                      )}
                    </>
                  )}
                </>
              )
            }
          </GmbConnectionContainer>

          {/* GBP掲載のバリデーションがNGの場合、エラーメッセージを表示する */}
          {errorMessages.length > 0 && (
            <ValidationError>
              <ValidationErrorTitle>
                以下の理由で写真をGoogleビジネスプロフィールに掲載することができません。
                <br />
                写真を修正して、再度アップロードしてください。
              </ValidationErrorTitle>
              {errorMessages.map((error, index) => (
                <ErrorMessageWrapper key={index}>
                  <ErrorMessage key={index}>{error}</ErrorMessage>
                </ErrorMessageWrapper>
              ))}
            </ValidationError>
          )}

          <AlbumImageStoreList stores={stores} detailImage={image} />

          <DeleteWrapper>
            <NegativeButton
              priority='low'
              onClick={onClickDeleteButton}
              disabled={
                hasPublishedGmbImage ||
                hasInprogressGmbImage ||
                !hasChangeAuthorityMultiStoresImage ||
                submitGmbConnection
              }
            >
              <DeleteIcon name='trash alternate outline' />
              STORECASTから写真を削除する
            </NegativeButton>
          </DeleteWrapper>
        </ContentWrapper>
        <ImageStatusEditModal
          hashtagList={hashtagList}
          isOpen={open}
          setEditHashtagList={(v) => imageActions.setEditHashtagList(v.toArray())}
          setEditCategory={(v) => imageActions.setEditCategory(v)}
          editHashtagList={editHashtagList}
          onClose={() => {
            setOpen(false);
            const tags = image.tags.toArray();
            imageActions.setEditHashtagList(tags);
            if (image.gmbCategory) {
              imageActions.setEditCategory(image.gmbCategory);
            }
          }}
          onSubmit={() => {
            setOpen(false);
            imageActions.updateImageHashtagList();
            // カテゴリに変更がある場合のみカテゴリを更新する
            if (image.gmbCategory !== editCategory) {
              imageActions.updateImageCategory();
            }
          }}
          category={editCategory}
          currentCategory={image.gmbCategory}
          canUseProductCategory={canUseProductCategory}
        />
        <CategorySelectModal
          isOpen={categorySelectOpen}
          canUseProductCategory={canUseProductCategory}
          onClose={() => {
            setCategorySelectOpen(false);
          }}
          onSubmit={(category) => {
            setCategorySelectOpen(false);
            setSubmitGmbConnection(true);
            imageActions.uploadGmb({
              imageResourceIds: [image.image_resource_id],
              category,
            });
          }}
        />
      </MainWrapper>
    );
  },
);

const ImageContainer = styled.div`
  text-align: center;

  img {
    max-width: 100%;
    max-height: 640px;
  }
`;

const Container = styled.div`
  margin: 12px 0;
`;

const Label = css`
  display: inline-block;
  list-style: none;
  margin: 0 8px 8px 0;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 15px;
  color: ${COLOR.BLACK};
  background-color: ${COLOR.LIGHT_GRAY};
  line-height: 1.71;
  cursor: pointer;
`;

const Hashtag = styled.div`
  ${Label}
`;

const NegativeButton = styled(Button).attrs(() => ({ negative: true }))`
  &&& {
    display: flex;
  }
`;

const DeleteWrapper = styled.div`
  margin-top: 32px;
  color: #f00;
  font-size: 16px;
  font-weight: bold;
  ${Mixins.flex('center')}
  justify-content: center;
`;

const DeleteIcon = styled(Icon)`
  &&& {
    line-height: 1;
    height: auto;
  }
`;

const ContainerTitle = styled.div`
  font-weight: bold;
`;

const ContainerText = styled.div``;

const ContainerCategoryText = styled.div`
  ${Label}
`;

const GmbConnectionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const GmbConnectionButton = styled(Button).attrs(() => ({ priority: 'high' }))`
  &&& {
    width: auto;
    margin-left: auto;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ModalTrigger = styled.div`
  font-size: 12px;
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  max-width: 820px;
  margin: 0 auto;
  padding: 20px 18px;
`;

const ValidationError = styled.div`
  margin: 32px 0;
`;

const ValidationErrorTitle = styled.div`
  font-size: 14px;
  color: ${COLOR.ERROR};
  margin-bottom: 16px;
`;

const ErrorMessageWrapper = styled.div``;

const ErrorMessage = styled.div`
  display: list-item;
  list-style: inside;
  text-indent: 0.5em;
  font-size: 14px;
`;
