import React from 'react';

import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import { OfferType as ModelOfferType } from 'models/Domain/Offer';

type Props = {
  className?: string;
  type: ModelOfferType;
};

export const OfferTypeLabel: React.FC<Props> = ({ className, type }) => {
  return (
    <StyledOfferTypeLabel className={className} type={type}>
      {type === 'task' ? '依頼' : type === 'report' ? '報告' : 'その他'}
    </StyledOfferTypeLabel>
  );
};

const StyledOfferTypeLabel = styled(Label)<{ type?: ModelOfferType }>`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    white-space: nowrap;
    font-size: 13px;
    width: 63px;
    background-color: ${(props) =>
      props.type === 'task' ? '#FFFCA8' : props.type === 'report' ? '#9FCBFF' : '#E2E2E2'};
    border: solid 1px
      ${(props) => (props.type === 'task' ? '#FFFCA8' : props.type === 'report' ? '#9FCBFF' : '#E2E2E2')};
    border-radius: 50px;
  }
`;
