import React, { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import { MenuHelp as Help } from 'helpers/ContextHelp';
import { MAX_MENU_SECTION_NAME_LENGTH, MenuSection } from 'models/Domain/Menu/Menu';
import { COLOR } from 'style/color';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  section: MenuSection;
  onChange: (section: MenuSection) => void;
  onDelete?: () => void;
  isEdit: boolean;
};

type FormInputs = {
  name: string;
};

export const MenuSectionModal = React.memo<Props>(({ isOpen, onClose, section, onChange, onDelete, isEdit }) => {
  const {
    register,
    formState: { errors, isValid, isSubmitted, defaultValues },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<FormInputs>({
    mode: 'onSubmit',
    defaultValues: { name: section.name },
  });

  const handleOnDelete = useCallback(() => {
    if (section.items.size > 0 && !window.confirm('セクションにアイテムが含まれています。本当に削除しますか？')) return;
    onDelete && onDelete();
    onClose();
  }, [onDelete, onClose, section]);

  const onSubmit = useCallback(
    (data: FormInputs) => {
      const newSection = section.merge({ name: data.name });
      onChange(newSection);
      onClose();
    },
    [onChange, onClose, section],
  );

  const onError = useCallback(() => {
    window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
  }, []);

  useEffect(() => {
    register('name', {
      required: { value: true, message: 'セクション名は必須です' },
      maxLength: {
        value: MAX_MENU_SECTION_NAME_LENGTH,
        message: `セクション名は${MAX_MENU_SECTION_NAME_LENGTH}文字以内で入力してください`,
      },
    });
  }, [register]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title data-testid={'title'}>{isEdit ? 'メニューセクションの編集' : 'メニューセクションの追加'}</Title>
          <ContentWrapper>
            <Content>
              <FormContent name={'セクション名'} required informationText={Help.menuSectionName}>
                <StyledInput
                  name={'name'}
                  onChange={async (value) => {
                    setValue('name', value);
                    await trigger('name');
                  }}
                  defaultValue={defaultValues?.name}
                  error={isSubmitted && errors.name?.message}
                  placeholder={'（例）ランチ、ディナー、ドリンク'}
                  type={'text'}
                  data-testid={'nameInput'}
                />
                <StyledTextCount size={watch('name').length} maxSize={MAX_MENU_SECTION_NAME_LENGTH} />
              </FormContent>
            </Content>
          </ContentWrapper>
          <ButtonWrapper>
            {isEdit && (
              <DeleteButton onClick={handleOnDelete} data-testid={'deleteButton'}>
                削除
              </DeleteButton>
            )}
            <StyledButton onClick={onClose}>キャンセル</StyledButton>
            <StyledButton
              onClick={handleSubmit(onSubmit, onError)}
              priority={'high'}
              disabled={isSubmitted && !isValid}
              data-testid={'submitButton'}
            >
              適用
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
});

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Content = styled.div``;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const DeleteButton = styled(StyledButton).attrs({ priority: 'high', negative: true })`
  margin-right: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledInput = styled(Input)``;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;
