import { css } from 'styled-components';

// css mixin function (頻繁によく使いそうなcssを追加していく)
export const Mixins = {
  flex: (pos: string) => css`
    display: flex;
    align-items: ${pos};
  `,
  ellipsis: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
};
