import { Record } from 'immutable';

import { GmbTimePeriodString } from 'models/Domain/GmbLocation/GmbTimePeriodString';
import { JSObject } from 'types/Common';

import { DAYS_OF_WEEK, DayOfWeek, GmbTimePeriods } from './GmbTimePeriod';

export class GmbRegularHours extends Record<{
  sunday: GmbTimePeriods;
  monday: GmbTimePeriods;
  tuesday: GmbTimePeriods;
  wednesday: GmbTimePeriods;
  thursday: GmbTimePeriods;
  friday: GmbTimePeriods;
  saturday: GmbTimePeriods;
}>({
  sunday: new GmbTimePeriods(),
  monday: new GmbTimePeriods(),
  tuesday: new GmbTimePeriods(),
  wednesday: new GmbTimePeriods(),
  thursday: new GmbTimePeriods(),
  friday: new GmbTimePeriods(),
  saturday: new GmbTimePeriods(),
}) {
  constructor(data = []) {
    const params: JSObject = {};
    if (data) {
      DAYS_OF_WEEK.forEach((day: DayOfWeek) => {
        params[day.toLowerCase()] = new GmbTimePeriods(data.filter((param: JSObject) => param.openDay === day));
      });
    }

    super(params);
  }

  /** 一覧画面での表示 */
  get regularHoursForDisplay() {
    return [
      `日曜日 ${this.sunday.periodsForDisplay}`,
      `月曜日 ${this.monday.periodsForDisplay}`,
      `火曜日 ${this.tuesday.periodsForDisplay}`,
      `水曜日 ${this.wednesday.periodsForDisplay}`,
      `木曜日 ${this.thursday.periodsForDisplay}`,
      `金曜日 ${this.friday.periodsForDisplay}`,
      `土曜日 ${this.saturday.periodsForDisplay}`,
    ];
  }

  get validate() {
    const validate = [
      this.sunday.validate,
      this.monday.validate,
      this.tuesday.validate,
      this.wednesday.validate,
      this.thursday.validate,
      this.friday.validate,
      this.saturday.validate,
    ];

    const errors = validate.filter((day) => !day.isValid);
    const isValid = errors.length === 0;

    return {
      isValid,
      error: isValid ? '' : errors[0].error,
    };
  }

  get regularHoursForEdit() {
    interface ListType {
      type: DayOfWeek;
      name: string;
      gmbTimePeriods: GmbTimePeriods;
    }

    const result: ListType[] = [
      { type: 'SUNDAY', name: '日曜日', gmbTimePeriods: this.sunday },
      { type: 'MONDAY', name: '月曜日', gmbTimePeriods: this.monday },
      { type: 'TUESDAY', name: '火曜日', gmbTimePeriods: this.tuesday },
      { type: 'WEDNESDAY', name: '水曜日', gmbTimePeriods: this.wednesday },
      { type: 'THURSDAY', name: '木曜日', gmbTimePeriods: this.thursday },
      { type: 'FRIDAY', name: '金曜日', gmbTimePeriods: this.friday },
      { type: 'SATURDAY', name: '土曜日', gmbTimePeriods: this.saturday },
    ];
    return result;
  }

  get updateParams() {
    return {
      periods: [
        ...this.sunday.requestParams(),
        ...this.monday.requestParams(),
        ...this.tuesday.requestParams(),
        ...this.wednesday.requestParams(),
        ...this.thursday.requestParams(),
        ...this.friday.requestParams(),
        ...this.saturday.requestParams(),
      ],
    };
  }

  getPeriods(dayOfWeek: DayOfWeek) {
    return this.get(this.convertLowerCase(dayOfWeek));
  }

  changePeriods(dayOfWeek: DayOfWeek, periods: GmbTimePeriods) {
    return this.set(this.convertLowerCase(dayOfWeek), periods.changeDay(dayOfWeek));
  }

  toggleOpenDay(dayOfWeek: DayOfWeek, isOpen: boolean) {
    return isOpen ? this.changeOpenDay(dayOfWeek) : this.changeCloseDay(dayOfWeek);
  }

  changePeriod(dayOfWeek: DayOfWeek, index: number, openTime: GmbTimePeriodString, closeTime: GmbTimePeriodString) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods: GmbTimePeriods) =>
      periods.changePeriod(index, openTime, closeTime),
    );
  }

  removePeriod(dayOfWeek: DayOfWeek, index: number) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.removePeriod(index));
  }

  addOpenDay(dayOfWeek: DayOfWeek) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.addOpenDay(dayOfWeek));
  }

  convertLowerCase(dayOfWeek: string) {
    switch (dayOfWeek) {
      case 'SUNDAY':
        return 'sunday';
      case 'MONDAY':
        return 'monday';
      case 'TUESDAY':
        return 'tuesday';
      case 'WEDNESDAY':
        return 'wednesday';
      case 'THURSDAY':
        return 'thursday';
      case 'FRIDAY':
        return 'friday';
      case 'SATURDAY':
        return 'saturday';
      default:
        throw new TypeError();
    }
  }

  /** 曜日を営業日にする */
  private changeOpenDay(dayOfWeek: DayOfWeek) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.addOpenDay(dayOfWeek));
  }

  /** 曜日を定休日にする */
  private changeCloseDay(dayOfWeek: DayOfWeek) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.closeDay());
  }
}
