export default {
  REQUIRED_ERROR: '入力が必要です',
  INVALID_EMAIL_FORMAT: '半角英数字と@マークで入力してください',
  PAST_TIME_ERROR: '過去の日付は設定できません',
  PHONE_ERROR: '電話番号の形式が正しくありません',
  WEBSITE_URL_ERROR: 'ウェブサイトの形式が正しくありません。',
  OPEN_TIME_EMPTY_ERROR: '開店時間を入力してください',
  OPEN_TIME_INVALID_ERROR: '開店時間は00:00〜23:59を入力してください',
  CLOSE_TIME_EMPTY_ERROR: '閉店時間を入力してください',
  CLOSE_TIME_INVALID_ERROR: '閉店時間は00:00〜23:59を入力してください',
  CLOSE_TIME_BEFORE_OPEN_TIME_ERROR: '閉店時間は開店時間より後の時間を指定してください',
  PERIODS_EXCEEDS_24HOURS: '営業時間の合計が24時間を超えている日があります',
  DATE_EMPTY_ERROR: '日付を入力してください',
  SPECIAL_HOUR_PERIODS_EMPTY_ERROR: '営業時間を最低1つ入力してください',
  POSTAL_CODE_ERROR: '郵便番号の形式が正しくありません。',
  PRIMARY_CATEGORY_ERROR: 'メインカテゴリーを入力してください',
  PROFILE_DESCRIPTION_LENGTH_ERROR: 'ビジネス情報は750文字以内にしてください',
  PROFILE_DESCRIPTION_CONTAINS_URL_ERROR: 'ビジネス情報にURLを含めないでください',
  PROFILE_DESCRIPTION_CONTAINS_BRACKET_ERROR: 'ビジネス情報に<および>を含めないでください',
  ATTRIBUTE_SNS_URL_FACEBOOK_ERROR: 'https://www.facebook.com/{ユーザー名} 形式で入力してください',
  ATTRIBUTE_SNS_URL_INSTAGRAM_ERROR: 'https://www.instagram.com/{ユーザー名}/ 形式で入力してください',
  ATTRIBUTE_SNS_URL_LINKEDIN_ERROR:
    'https://www.linkedin.com/in/{ユーザー名} または https://www.linkedin.com/company/{ユーザー名} 形式で入力してください',
  ATTRIBUTE_SNS_URL_PINTEREST_ERROR: 'https://www.pinterest.com/{ユーザー名}/ 形式で入力してください',
  ATTRIBUTE_SNS_URL_TIKTOK_ERROR: 'https://www.tiktok.com/@{ユーザー名} 形式で入力してください',
  ATTRIBUTE_SNS_URL_TWITTER_ERROR:
    'https://x.com/{ユーザー名} または https://twitter.com/{ユーザー名} 形式で入力してください',
  ATTRIBUTE_SNS_URL_YOUTUBE_ERROR:
    'https://www.youtube.com/channel/{ユーザー名} または https://www.youtube.com/user/{ユーザー名} または https://www.youtube.com/@{ユーザー名} 形式で入力してください',
};
