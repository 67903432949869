import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Icon } from 'components/atoms/Icon';
import { DropdownMenu, DropdownMenuItem } from 'components/molecules/DropdownMenu';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import {
  SearchVolumeSearchResult,
  SearchVolumeSearchResultItem,
  SortCondition,
  SortKey as _SortKey,
} from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import { YearMonth } from 'models/Domain/YearMonth';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { SortOrder } from 'types/Common';

// 地域を除外する
type SortKey = Exclude<_SortKey, 'inputArea' | 'searchArea'>;

type Props = {
  className?: string;
  searchResult: SearchVolumeSearchResult;
  items: SearchVolumeSearchResult['items'];
  selectedKeywords: ImmutableSet<string>;
  onSelectKeyword: (keyword: string) => void;
  showSort: boolean;
};

const comparator = <S, T extends string | number | null | undefined>(
  a: S,
  b: S,
  getValue: (item: S) => T,
  sortOrder: SortOrder,
): number => {
  const valueA = getValue(a);
  const valueB = getValue(b);

  // nullの場合は、値のあるデータより後ろに表示する
  if (valueA === valueB) {
    return 0;
  } else if (valueA == null) {
    return 1;
  } else if (valueB == null) {
    return -1;
  }

  if (sortOrder === 'asc') {
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  } else {
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }
};

export const SearchVolumeDetailTable = React.memo<Props>(
  ({ className, selectedKeywords, searchResult, items, onSelectKeyword, showSort }) => {
    const [sortCondition, setSortCondition] = useState<SortCondition>(new SortCondition());

    // 検索条件の期間の終了月を使用する
    const latestMonth = searchResult.condition.period.endMonth;

    const sortedItems = useMemo(() => {
      let sortedItems = items;
      const { key: sortKey, order: sortOrder } = sortCondition;
      const { startMonth, endMonth } = searchResult.condition.period;
      switch (sortKey) {
        case 'searchWord':
          sortedItems = items.sort((a, b) => comparator(a, b, (item) => item.keyword, sortOrder));
          break;
        case 'average':
          sortedItems = items.sort((a, b) =>
            comparator(a, b, (item) => item.metrics.getAverage(startMonth, endMonth), sortOrder),
          );
          break;
        case 'latest':
          sortedItems = items.sort((a, b) =>
            comparator(a, b, (item) => item.metrics.getDataByMonth(latestMonth), sortOrder),
          );
          break;
        case 'momRatio':
          sortedItems = items.sort((a, b) =>
            comparator(a, b, (item) => item.metrics.getGrowthRatio(latestMonth, 1), sortOrder),
          );
          break;
        case 'threeMomRatio':
          sortedItems = items.sort((a, b) =>
            comparator(a, b, (item) => item.metrics.getGrowthRatio(latestMonth, 3), sortOrder),
          );
          break;
        case 'twelveMomRatio':
          sortedItems = items.sort((a, b) =>
            comparator(a, b, (item) => item.metrics.getGrowthRatio(latestMonth, 12), sortOrder),
          );
          break;
      }
      return sortedItems;
    }, [items, latestMonth, searchResult.condition.period, sortCondition]);

    const handleOnChangeSort = useCallback(
      (sortKey: SortKey) => {
        let newSortCondition: SortCondition;
        if (sortCondition.key === sortKey) {
          newSortCondition = new SortCondition({
            key: sortKey,
            order: sortCondition.order === 'desc' ? 'asc' : 'desc',
          });
        } else {
          switch (sortKey) {
            // sortKeyが変更されたとき、文字列系は昇順、数値系は降順で並び替える
            case 'searchWord':
              newSortCondition = new SortCondition({ key: sortKey, order: 'asc' });
              break;
            default:
              newSortCondition = new SortCondition({ key: sortKey, order: 'desc' });
          }
        }
        setSortCondition(newSortCondition);
      },
      [sortCondition],
    );

    return (
      <Wrapper className={className}>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <StyledTableHeaderCell>表示</StyledTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('searchWord')}>
                {showSort && <Sort sortKey={'searchWord'} sortCondition={sortCondition} />}
                検索ワード
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('average')}>
                {showSort && <Sort sortKey={'average'} sortCondition={sortCondition} />}
                月間平均検索数
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('latest')}>
                {showSort && <Sort sortKey={'latest'} sortCondition={sortCondition} />}
                {latestMonth.format()}
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('momRatio')}>
                {showSort && <Sort sortKey={'momRatio'} sortCondition={sortCondition} />}
                前月比
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('threeMomRatio')}>
                {showSort && <Sort sortKey={'threeMomRatio'} sortCondition={sortCondition} />}
                3ヶ月前比
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('twelveMomRatio')}>
                {showSort && <Sort sortKey={'twelveMomRatio'} sortCondition={sortCondition} />}
                前年同月比
              </StyledSortableTableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {sortedItems.map((item, index) => (
              <SearchVolumeTableRow
                key={index}
                item={item}
                isSelected={selectedKeywords.has(item.keyword)}
                condition={searchResult.condition}
                latestMonth={latestMonth}
                onSelectKeyword={onSelectKeyword}
              />
            ))}
          </TableBody>
        </Table>
      </Wrapper>
    );
  },
);

export const SearchVolumeTableRow = React.memo<{
  className?: string;
  item: SearchVolumeSearchResultItem;
  condition: SearchVolumeSearchResult['condition'];
  latestMonth: YearMonth;
  isSelected: boolean;
  onSelectKeyword: (keyword: string) => void;
}>(({ item, latestMonth, condition, onSelectKeyword, isSelected }) => {
  const { keyword, metrics } = item;
  const {
    area,
    period: { startMonth, endMonth },
  } = condition;

  const momRatio = metrics.getGrowthRatio(latestMonth, 1);
  const threeMomRatio = metrics.getGrowthRatio(latestMonth, 3);
  const twelveMomRatio = metrics.getGrowthRatio(latestMonth, 12);
  const average = metrics.getAverage(startMonth, endMonth);
  const areaName = area.name ?? '';

  const searchParams = new URLSearchParams();
  searchParams.set('an', areaName);
  searchParams.set('at', 'name');
  searchParams.set('sw', keyword);
  searchParams.set('sm', startMonth.toString());
  searchParams.set('em', endMonth.toString());

  const dropdownMenuItems: DropdownMenuItem[] = [
    {
      label: 'このキーワードで検索',
      link: `${Path.searchVolume.index}?${searchParams.toString()}`,
    },
  ];

  return (
    <StyledTableRow>
      <StyledTableCell>
        <CheckboxWrapper>
          <SmallCheckBox checked={isSelected} onChange={() => onSelectKeyword(keyword)} />
        </CheckboxWrapper>
      </StyledTableCell>
      <StyledTableCell>
        <KeywordWrapper>
          <Keyword>{keyword}</Keyword>
          <DropdownMenu
            trigger={
              <IconWrapper>
                <StyledIcon type='settings' />
              </IconWrapper>
            }
            items={dropdownMenuItems}
          />
        </KeywordWrapper>
      </StyledTableCell>
      <NumberCell>
        <Number value={average != null ? Math.floor(average) : null} />
        <SparklinesWrapper>
          <Sparklines
            style={{ verticalAlign: 'middle' }}
            data={metrics?.monthlySearchVolumes.map((item) => item.value ?? 0).toArray()}
            svgWidth={60}
            svgHeight={30}
            min={0}
          >
            <SparklinesLine style={{ strokeWidth: 3, stroke: '#458a6a', fill: '#E2F3EB', fillOpacity: 0.4 }} />
            <SparklinesReferenceLine type={'avg'} />
          </Sparklines>
        </SparklinesWrapper>
      </NumberCell>
      <NumberCell>
        <Number value={metrics?.getDataByMonth(latestMonth)} />
      </NumberCell>
      <NumberCell>
        <Diff value={momRatio}>
          <Rate value={momRatio} />
        </Diff>
      </NumberCell>
      <NumberCell>
        <Diff value={threeMomRatio}>
          <Rate value={threeMomRatio} />
        </Diff>
      </NumberCell>
      <NumberCell>
        <Diff value={twelveMomRatio}>
          <Rate value={twelveMomRatio} />
        </Diff>
      </NumberCell>
    </StyledTableRow>
  );
});

const Sort = React.memo<{ sortKey: SortKey; sortCondition: SortCondition }>(({ sortKey, sortCondition }) => {
  return (
    <SortWrapper>
      {sortKey === sortCondition?.key && <Triangle isSortOrderDesc={sortCondition.order === 'desc'} />}
    </SortWrapper>
  );
});

const Number = ({ value }: { value: number | null | undefined }) => {
  return <>{value == null ? 'ー' : Math.abs(value).toLocaleString()}</>;
};

const Rate = ({ value }: { value: number | null | undefined }) => {
  return (
    <>
      {value == null
        ? 'ー'
        : `${Math.abs(value * 100).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}%`}
    </>
  );
};

const Wrapper = styled.div``;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const Triangle = styled(SortTriangle)`
  display: inline-block;
  vertical-align: middle;
`;

const NumberCell = styled(StyledTableCell)`
  &&& {
    font-family: monospace;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
  }
`;

const SortWrapper = styled.div`
  display: inline-block;
  width: 16px;
`;

const SparklinesWrapper = styled.div`
  display: inline-block;
  width: 60px;
  margin-left: 8px;
`;

// valueが0より大きければ「↑」をつけて緑色、0より小さければ「↓」をつけて赤色、変化がなければ灰色で表示する
const Diff = styled.div.attrs<{ value: number | null | undefined }, { isUp: boolean; isDown: boolean }>(
  ({ value }) => ({
    isUp: value != null && value > 0,
    isDown: value != null && value < 0,
  }),
)<{
  value: number | null | undefined;
}>`
  color: ${({ isUp, isDown, value }) =>
    isUp ? COLOR.GREEN : isDown ? COLOR.RED : value != null ? COLOR.DARK_GRAY : COLOR.BLACK};
  &:after {
    content: '${({ isUp, isDown, value }) => (isUp ? '↑' : isDown ? '↓' : value != null ? '→' : '')}';
    margin-left: 4px;
  }
`;

const KeywordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Keyword = styled.div`
  font-weight: bold;
`;

export const CheckboxWrapper = styled.div`
  justify-content: center;
  display: flex;
`;

const IconWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
`;

const StyledTableRow = styled(TableRow)`
  ${StyledIcon} {
    opacity: 0;
  }
  &:hover {
    ${StyledIcon} {
      opacity: 1;
    }
  }
`;
