import { AccountSingleton } from 'models/Domain/AccountList';

export type StorageType = 'local' | 'session' | 'all';

/**
 * ストレージからデータを取得する
 * @param key 取得対象のキー
 * @param defaultValue データが存在しない場合のデフォルト値
 * @param options.storageType 対象のストレージタイプ (デフォルト: 'local)
 * @param options.isGlobal 全アカウント共通の設定か（デフォルト: false）
 * @returns
 */
export const getStorageItem = <T>(
  key: string,
  defaultValue: T,
  options: {
    storageType?: StorageType;
    isGlobal?: boolean;
  } = {},
): T => {
  const { storageType = 'local', isGlobal = false } = options;
  const localStorage = storageType === 'local' || storageType === 'all';
  const sessionStorage = storageType === 'session' || storageType === 'all';
  const storageKey = getStorageKey(key, isGlobal);

  try {
    if (sessionStorage) {
      const item = _getStorageItem(window.sessionStorage, storageKey);
      if (item !== null) {
        return JSON.parse(item);
      }
    }
  } catch (error) {
    // JSON形式が不正な場合
    console.log(error);
  }

  try {
    if (localStorage) {
      const item = _getStorageItem(window.localStorage, storageKey);
      if (item !== null) {
        return JSON.parse(item);
      }
    }
  } catch (error) {
    // JSON形式が不正な場合
    console.log(error);
  }

  return defaultValue;
};

const _getStorageItem = (storage: Storage, key: string) => {
  try {
    return storage.getItem(key);
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * ストレージにデータを保存する
 * @param key 取得対象のキー
 * @param value 保存する値
 * @param options.storageType 対象のストレージタイプ (デフォルト: 'local)
 * @param options.isGlobal 全アカウント共通の設定か（デフォルト: false）
 * @returns
 */ export const setStorageItem = (
  key: string,
  value: any,
  options: {
    storageType?: StorageType;
    isGlobal?: boolean;
  } = {},
) => {
  const { storageType = 'local', isGlobal = false } = options;
  const localStorage = storageType === 'local' || storageType === 'all';
  const sessionStorage = storageType === 'session' || storageType === 'all';
  const storageKey = getStorageKey(key, isGlobal);

  const valueStr = JSON.stringify(value);

  if (sessionStorage) {
    _setStorageItem(window.sessionStorage, storageKey, valueStr, true);
  }

  if (localStorage) {
    _setStorageItem(window.localStorage, storageKey, valueStr, sessionStorage === false);
  }
};

const _setStorageItem = (storage: Storage, key: string, value: string, sendEvent: boolean) => {
  try {
    const oldValue = _getStorageItem(storage, key);
    storage.setItem(key, value);

    if (sendEvent) {
      // 同一タブにstorageイベントが飛ばないので、自前でイベントを発行する
      window.dispatchEvent(new StorageEvent('storage', { key, oldValue, newValue: value, storageArea: storage }));
    }
  } catch (error) {
    // ストレージを利用できない環境など
    console.log(error);
  }
};

/**
 * ローカルストレージからデータを削除する
 * @param key 削除対象のキー
 * @param options.storageType 対象のストレージタイプ (デフォルト: 'local)
 * @param options.isGlobal 全アカウント共通の設定か（デフォルト: false）
 */
export const removeStorageItem = (
  key: string,
  options: {
    storageType?: StorageType;
    isGlobal?: boolean;
  } = {},
) => {
  const { storageType = 'local', isGlobal = false } = options;
  const localStorage = storageType === 'local' || storageType === 'all';
  const sessionStorage = storageType === 'session' || storageType === 'all';

  const storageKey = getStorageKey(key, isGlobal);
  if (sessionStorage) {
    _removeStorageItem(window.sessionStorage, storageKey);
  }
  if (localStorage) {
    _removeStorageItem(window.localStorage, storageKey);
  }
};

const _removeStorageItem = (storage: Storage, key: string) => {
  try {
    storage.removeItem(key);
  } catch (error) {
    // ローカルストレージを利用できない環境など
    console.log(error);
  }
};

/**
 * ストレージのkeyを取得する
 * isGlobalがfalseの場合は、アカウントの組織IDがついたkeyになる
 * @param key
 * @param isGlobal
 */
export const getStorageKey = (key: string, isGlobal: boolean) => {
  if (isGlobal) {
    return key;
  }
  const currentOrganizationId = AccountSingleton.getInstance().organizationId;
  return `${key}_${currentOrganizationId}`;
};
