import React, { useMemo } from 'react';

import { List } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { PullDown } from 'components/atoms/PullDown';
import { createHashtagText } from 'helpers/utils';
import { GbpImageCategory, getCategoryOptions, getSelectableImageCategories } from 'models/Domain/Image/Image';
import { COLOR } from 'style/color';

export const ImageStatusEditModal = React.memo<{
  isOpen: boolean;
  hashtagList: List<string>;
  editHashtagList: List<string>;
  category: GbpImageCategory | null;
  currentCategory: GbpImageCategory | null;
  onClose: () => void;
  onSubmit: () => void;
  setEditHashtagList: (v: List<string>) => void;
  setEditCategory: (v: GbpImageCategory) => void;
  canUseProductCategory: boolean;
}>(
  ({
    isOpen,
    onClose,
    onSubmit,
    hashtagList,
    editHashtagList,
    setEditHashtagList,
    setEditCategory,
    category,
    currentCategory,
    canUseProductCategory,
  }) => {
    const [additionalTags, setAdditionalTags] = React.useState<List<string>>(List());

    // APIの仕様で更新ができない「ロゴ」は選択不可にする
    const categoryOptions = useMemo(
      () =>
        getCategoryOptions({
          canUseProfile: false,
          canUseProduct: canUseProductCategory,
          currentCategory: currentCategory,
        }),
      [canUseProductCategory, currentCategory],
    );

    const options = hashtagList
      .merge(additionalTags)
      .map((hashtag) => ({ text: hashtag, value: hashtag }))
      .toArray();

    // 適用ボタンを押せるか
    // カテゴリに変更がある場合は、そのカテゴリが選択可能なカテゴリであること
    // カテゴリに変更がない場合は、更新されないので無視する
    const isValid =
      category && category !== currentCategory
        ? getSelectableImageCategories({
            canUseProfile: false,
            canUseProduct: canUseProductCategory,
            currentCategory: currentCategory,
          }).includes(category)
        : true;

    return (
      <Modal size='small' open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header>写真情報の変更</Modal.Header>
        <Modal.Content>
          <ContentTitle>タグ</ContentTitle>
          <ContentTitleDescription>
            ※タグを追加すると、STORECASTでの写真検索で利用できます。Googleビジネスプロフィールには反映されません。
          </ContentTitleDescription>
          <CustomPullDown
            multiple={true}
            closeOnChange={false}
            allowAdditions={true}
            value={editHashtagList.toArray()}
            options={options}
            placeholder='タグを選択もしくは作成してください'
            onChange={(v) => setEditHashtagList(List(v.map((value: string) => createHashtagText(value))))}
            onAddItem={(v) => {
              const value = createHashtagText(v);
              if (editHashtagList.indexOf(value) == -1) {
                setEditHashtagList(editHashtagList.push(value));
              }
              setAdditionalTags(additionalTags.push(value));
            }}
          />
          {category && (
            <>
              <ContentTitle>カテゴリー</ContentTitle>
              <CustomPullDown
                value={category}
                options={categoryOptions}
                onChange={(v) => setEditCategory(v)}
                placeholder={'カテゴリー'}
              />
              {!isValid && (
                <Message>
                  指定されたカテゴリーはSTORECASTから設定できません。
                  <br />
                  Googleビジネスプロフィールで設定を変更してください。
                </Message>
              )}
            </>
          )}
        </Modal.Content>
        <Buttons>
          <ButtonWrapper>
            <CloseButton onClick={() => onClose()}>キャンセル</CloseButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <ApplyButton priority='high' onClick={() => onSubmit()} disabled={!isValid}>
              適用
            </ApplyButton>
          </ButtonWrapper>
        </Buttons>
      </Modal>
    );
  },
);

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px;

  @media (max-width: 600px) {
    margin: 16px;
  }
`;

const ButtonWrapper = styled.div`
  width: calc(50% - 8px);
  margin-bottom: 12px;
`;

const ButtonCommonStyle = css`
  width: 100%;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const CustomPullDown = styled(PullDown)`
  &&& {
    margin-top: 16px;
  }
`;

const ContentTitle = styled.div`
  &:first-child {
    margin-top: 0px;
  }
  font-weight: bold;
  margin-top: 16px;
`;

const ContentTitleDescription = styled.div`
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  color: ${COLOR.DARK_GRAY};
`;

const Message = styled.div`
  color: ${COLOR.ERROR};
  padding: 8px 0;
`;
