import React from 'react';

import styled from 'styled-components';

import { ResizeImage } from './ResizeImage';

interface Props {
  className?: string;
  src?: string | null;
  size?: number;
}

export const Thumbnail = React.memo<Props>(({ className, src, size }) => {
  return (
    <Image className={className} src={src ? src : '/images/no_image.svg'} size={size} width={size ? size * 2 : size} />
  );
});

const Image = styled(ResizeImage)<{ size?: number }>`
  border-radius: 50%;
  width: ${(props) => (props.size ? `${props.size}px` : '40px')};
  height: ${(props) => (props.size ? `${props.size}px` : '40px')};
  object-fit: cover;
`;
