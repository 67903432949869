import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { GuideOverlay } from 'components/molecules/GuideOverlay';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import {
  PerformanceGraph,
  PerformanceGraphDataItem,
} from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAGraph/PerformanceGraph';
import { ComparisonGraphType, getDisplayDates } from 'helpers/graph';
import {
  GbpPerformanceMAGraphData,
  InteractionType,
  ReviewType,
  StatsType,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { AggregateMethod } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMASearchCondition';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { COLOR } from 'style/color';
import { AggregateUnit, assertNever } from 'types/Common';

import { Header } from './Header';
import { OverviewGraph, OverviewGraphDataItem } from './OverviewGraph';
import { ReviewGraph, ReviewGraphDataItem } from './ReviewGraph';

const graphTypes = ['overview', 'impressions', 'interactions', 'reviews'] as const;
export type GraphType = (typeof graphTypes)[number];
export const graphTypeLabel = {
  overview: '概要',
  impressions: 'ユーザー',
  interactions: 'インタラクション',
  reviews: 'クチコミ',
} satisfies Record<GraphType, string>;

// 積み上げグラフにするかのデフォルト値
const defaultIsStacked = {
  overview: false,
  impressions: true,
  interactions: false,
  reviews: false,
} as const satisfies Record<GraphType, boolean>;

type Props = {
  className?: string;
  isLoading: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs;
  comparisonEndDate: Dayjs;
  aggregateUnit: AggregateUnit;
  isEnabledComparison: boolean;
  graphData: GbpPerformanceMAGraphData;
  graphType: GraphType;
  displayStats: StatsType[];
  reviewType: ReviewType;
  interactionType: InteractionType;
  aggregateMethod: AggregateMethod;
  onChangeInteractionType: (interactionType: InteractionType) => void;
  onChangeReviewType: (reviewType: ReviewType) => void;
  onChangeAggregateMethod: (aggregateMethod: AggregateMethod) => void;
  onChangeActiveStats: (statsType: StatsType) => void;
  comparisonGraphType: ComparisonGraphType;
  setComparisonGraphType: (value: ComparisonGraphType) => void;
};

type InnerGraphProps = {
  composedGraphData: OverviewGraphDataItem[] | PerformanceGraphDataItem[] | ReviewGraphDataItem[];
  interactionType: InteractionType;
  showComparisonGraph: boolean;
  graphType: GraphType;
  isStacked: boolean;
  reviewType: ReviewType;
  height?: number;
  displayStats: StatsType[];
  aggregateMethod: AggregateMethod;
  onChangeActiveStats: (statsType: StatsType) => void;
  showInactiveLegend: boolean;
  primaryGraphSettings?: GraphSettings;
  secondaryGraphSettings?: GraphSettings;
};

const DEFAULT_GRAPH_HEIGHT = 360 as const;

export const InnerGraph = React.memo<InnerGraphProps>(
  ({
    composedGraphData,
    interactionType,
    showComparisonGraph,
    graphType,
    displayStats,
    isStacked,
    reviewType,
    height = DEFAULT_GRAPH_HEIGHT,
    aggregateMethod,
    onChangeActiveStats,
    showInactiveLegend,
    primaryGraphSettings = new GraphSettings(),
    secondaryGraphSettings = new GraphSettings(),
  }) => {
    switch (graphType) {
      case 'overview':
        return (
          <OverviewGraph
            graphData={composedGraphData as OverviewGraphDataItem[]}
            height={height}
            showComparisonGraph={showComparisonGraph}
            displayStats={displayStats}
            interactionType={interactionType}
            aggregateMethod={aggregateMethod}
            onChangeActiveStats={onChangeActiveStats}
            showInactiveLegend={showInactiveLegend}
            leftGraphSettings={primaryGraphSettings}
            rightGraphSettings={secondaryGraphSettings}
          />
        );
      case 'impressions':
      case 'interactions':
        return (
          <PerformanceGraph
            graphData={composedGraphData as PerformanceGraphDataItem[]}
            height={height}
            showComparisonGraph={showComparisonGraph}
            displayStats={displayStats}
            graphType={graphType}
            interactionType={interactionType}
            isStacked={isStacked}
            aggregateMethod={aggregateMethod}
            onChangeActiveStats={onChangeActiveStats}
            showInactiveLegend={showInactiveLegend}
            impressionGraphSettings={primaryGraphSettings}
            interactionGraphSettings={secondaryGraphSettings}
          />
        );
      case 'reviews':
        return (
          <ReviewGraph
            graphData={composedGraphData as ReviewGraphDataItem[]}
            height={height}
            showComparisonGraph={showComparisonGraph}
            displayStats={displayStats}
            reviewType={reviewType}
            aggregateMethod={aggregateMethod}
            onChangeActiveStats={onChangeActiveStats}
            showInactiveLegend={showInactiveLegend}
            reviewGraphSettings={primaryGraphSettings}
            ratingGraphSettings={secondaryGraphSettings}
          />
        );
      default:
        return assertNever(graphType);
    }
  },
);

export const GbpPerformanceMAGraph = React.memo<Props>(
  ({
    className,
    isLoading,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    isEnabledComparison,
    aggregateUnit,
    graphData,
    graphType,
    displayStats,
    interactionType,
    reviewType,
    aggregateMethod,
    onChangeInteractionType,
    onChangeReviewType,
    onChangeAggregateMethod,
    onChangeActiveStats,
    comparisonGraphType,
    setComparisonGraphType,
  }) => {
    // グラフを結合できるか
    const disableCombined = !startDate.isSame(comparisonEndDate.add(1, 'day'));
    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState<boolean>(false);
    // グラフ設定モーダル
    const [isOpenGraphSettingsModal, setIsOpenGraphSettingsModal] = useState(false);
    const [primaryGraphSettings, setPrimaryGraphSettings] = useState(new GraphSettings());
    const [secondaryGraphSettings, setSecondaryGraphSettings] = useState(new GraphSettings());

    // 積み上げグラフか
    const [isStacked, setIsStacked] = useState<boolean>(false);

    // 比較期間のグラフのタイプがcombinedの場合は、対象期間と比較期間のグラフを１つの期間に結合する
    const isCombined = comparisonGraphType === 'combined';
    // 比較期間を表示するのは、比較期間が有効かつ「重ねて表示」の場合
    const showComparisonGraph = isEnabledComparison && comparisonGraphType === 'separated';

    // グラフに表示する対象期間と比較期間の日付のリストを取得する
    const { targetDates, comparisonDates } = useMemo(
      () =>
        getDisplayDates(
          startDate,
          endDate,
          comparisonStartDate,
          comparisonEndDate,
          aggregateUnit,
          isCombined,
          isEnabledComparison,
        ),
      [aggregateUnit, comparisonEndDate, comparisonStartDate, endDate, isCombined, isEnabledComparison, startDate],
    );

    // rechartsに適用できる形に加工したグラフデータ
    const composedGraphData = useMemo(():
      | OverviewGraphDataItem[]
      | PerformanceGraphDataItem[]
      | ReviewGraphDataItem[] => {
      switch (graphType) {
        case 'overview':
          return graphData.getOverviewGraphData(targetDates, comparisonDates, interactionType, aggregateUnit);
        case 'impressions':
        case 'interactions':
          return graphData.getPerformanceGraphData(targetDates, comparisonDates, interactionType, aggregateUnit);
        case 'reviews':
          return graphData.getReviewGraphData(targetDates, comparisonDates, reviewType, aggregateUnit);
        default:
          return assertNever(graphType);
      }
    }, [graphType, graphData, targetDates, comparisonDates, interactionType, aggregateUnit, reviewType]);

    const handleOnChangeComparisonGraphType = useCallback(
      (comparisonGraphType: ComparisonGraphType) => {
        setComparisonGraphType(comparisonGraphType);
      },
      [setComparisonGraphType],
    );

    const handleOnChangeIsStacked = useCallback((isStacked: boolean) => {
      setIsStacked(isStacked);
    }, []);

    const graphLabels = useMemo(() => {
      switch (graphType) {
        case 'overview':
          return ['左軸', '右軸'];
        case 'impressions':
        case 'interactions':
          return ['ユーザー数', `インタラクション${interactionType === 'rate' ? '率' : '数'}`];
        case 'reviews':
          return ['クチコミ数', '評価'];
        default:
          return assertNever(graphType);
      }
    }, [graphType, interactionType]);

    useEffect(() => {
      setIsStacked(defaultIsStacked[graphType]);
    }, [graphType]);

    useEffect(() => {
      // 対象期間と比較期間が連続していない場合は、比較期間のグラフの種類をseparatedに変更する
      if (comparisonGraphType !== 'combined') {
        return;
      } else if (startDate.isSame(comparisonEndDate.add(1, 'day'))) {
        return;
      } else {
        setComparisonGraphType('separated');
      }
    }, [startDate, comparisonEndDate, comparisonGraphType, setComparisonGraphType]);

    return (
      <Wrapper className={className}>
        <Header
          graphType={graphType}
          isEnabledComparison={isEnabledComparison}
          disableCombined={disableCombined}
          isStacked={isStacked}
          reviewType={reviewType}
          comparisonGraphType={comparisonGraphType}
          interactionType={interactionType}
          aggregateMethod={aggregateMethod}
          onChangeComparisonGraphType={handleOnChangeComparisonGraphType}
          onChangeIsStacked={handleOnChangeIsStacked}
          onChangeInteractionType={onChangeInteractionType}
          onChangeReviewType={onChangeReviewType}
          onChangeAggregateMethod={onChangeAggregateMethod}
        />
        <GraphWrapper>
          <InnerGraph
            composedGraphData={composedGraphData}
            interactionType={interactionType}
            showComparisonGraph={showComparisonGraph}
            graphType={graphType}
            isStacked={isStacked}
            reviewType={reviewType}
            displayStats={displayStats}
            aggregateMethod={aggregateMethod}
            onChangeActiveStats={onChangeActiveStats}
            showInactiveLegend={true}
            primaryGraphSettings={primaryGraphSettings}
            secondaryGraphSettings={secondaryGraphSettings}
          />
          {isLoading && (
            <LoadingWrapper>
              <Loader active={isLoading} size={'big'} inline={true} />
            </LoadingWrapper>
          )}
          {displayStats.length === 0 && <GuideOverlay>表示するデータを選択してください</GuideOverlay>}
        </GraphWrapper>
        <ButtonWrapper>
          <StyledButton onClick={() => setIsOpenGraphSettingsModal(true)}>グラフの表示設定</StyledButton>
          <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
        </ButtonWrapper>
        <GraphDownloadModal
          isOpen={isOpenDownloadModal}
          fileName={graphType}
          onClose={() => setIsOpenDownloadModal(false)}
        >
          <InnerGraph
            composedGraphData={composedGraphData}
            interactionType={interactionType}
            showComparisonGraph={showComparisonGraph}
            graphType={graphType}
            isStacked={isStacked}
            displayStats={displayStats}
            reviewType={reviewType}
            aggregateMethod={aggregateMethod}
            onChangeActiveStats={onChangeActiveStats}
            showInactiveLegend={false}
            primaryGraphSettings={primaryGraphSettings}
            secondaryGraphSettings={secondaryGraphSettings}
          />
        </GraphDownloadModal>
        {isOpenGraphSettingsModal && (
          <GraphSettingsModal
            settings={primaryGraphSettings}
            secondarySettings={secondaryGraphSettings}
            primaryLabel={graphLabels[0]}
            secondaryLabel={graphLabels[1]}
            multiple={true}
            isOpen={isOpenGraphSettingsModal}
            onClose={() => setIsOpenGraphSettingsModal(false)}
            onChange={(primarySettings, secondarySettings) => {
              setPrimaryGraphSettings(primarySettings);
              if (secondarySettings) {
                setSecondaryGraphSettings(secondarySettings);
              }
            }}
          />
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const GraphWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button).attrs({ priority: 'low' })`
  font-size: 16px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
