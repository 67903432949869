import { List as ImmutableList, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { InstagramAccount, InstagramBusinessAccount } from 'models/Domain/instagram';

import { InstagramActions } from './actions';

export interface InstagramRecord {
  isLoading: boolean;
  isLoadingAccounts: boolean;
  isLoadingAccount: boolean;
  accountList: ImmutableList<InstagramAccount>;
  account: InstagramAccount;
  accountsForRegister: ImmutableList<InstagramBusinessAccount>;
}

export class InstagramState extends Record<InstagramRecord>({
  isLoading: true,
  isLoadingAccounts: false,
  isLoadingAccount: false,
  accountList: ImmutableList(),
  account: new InstagramAccount(),
  accountsForRegister: ImmutableList(),
}) {}

export const instagramReducer = reducerWithInitialState(new InstagramState())
  .case(InstagramActions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  })
  .case(InstagramActions.setIsLoadingInstagramAccounts, (state, payload) => {
    return state.set('isLoadingAccounts', payload);
  })
  .case(InstagramActions.setIsLoadingInstagramAccount, (state, payload) => {
    return state.set('isLoadingAccount', payload);
  })
  .case(InstagramActions.setInstagramAccounts, (state, payload) => {
    return state.set('accountList', payload);
  })
  .case(InstagramActions.setInstagramAccount, (state, payload) => {
    return state.set('account', payload);
  })
  .case(InstagramActions.setAccountsForRegister, (state, payload) => {
    return state.set('accountsForRegister', payload);
  });
