import { Dayjs } from 'dayjs';

/**
 * ２つの日付が同じかどうかを返す
 */
export const isSameDay = (day1: Dayjs, day2: Dayjs) => day1.isSame(day2, 'day');

/**
 * 2つの期間の開始日、終了日が同じかを返す
 * @param range1 期間1
 * @param range2 期間2
 * @returns
 */
export const isSameDateRange = (range1: [Dayjs, Dayjs], range2: [Dayjs, Dayjs]) =>
  isSameDay(range1[0], range2[0]) && isSameDay(range1[1], range2[1]);

/** 与えられた日付から見た「今週の開始日(月曜日)」を返す */
export const getCurrentWeekStartDay = (day: Dayjs) => day.subtract((day.day() + 6) % 7, 'day');
/** 与えられた日付から見た「今週の終了日(日曜日)」を返す */
export const getCurrentWeekEndDay = (day: Dayjs) => day.add((7 - day.day()) % 7, 'day');

/** 与えられた日付から見た「先週の開始日(月曜日)」を返す */
export const getLastWeekStartDay = (day: Dayjs) => day.subtract(((day.day() + 6) % 7) + 7, 'day');
/** 与えられた日付から見た「先週の終了日(日曜日)」を返す */
export const getLastWeekEndDay = (day: Dayjs) => day.subtract(((day.day() + 6) % 7) + 1, 'day');

/** 与えられた日付から見た「今月の開始日(1日)」を返す */
export const getCurrentMonthStartDay = (day: Dayjs) => day.set('date', 1);
/** 与えられた日付から見た「今月の終了日」を返す */
export const getCurrentMonthEndDay = (day: Dayjs) => day.add(1, 'month').set('date', 0); // ○月0日→前月末日

/** 与えられた日付から見た「先月の開始日(1日)」を返す */
export const getLastMonthStartDay = (day: Dayjs) => day.set('date', 1).subtract(1, 'month');
/** 与えられた日付から見た「先月の終了日」を返す */
export const getLastMonthEndDay = (day: Dayjs) => day.set('date', 0); // ○月0日→前月末日

/**
 * 与えられた日数の期間を返す
 * @param endDate 期間の最終日
 * @param days 期間の日数
 * @returns [期間の開始日, 期間の終了日]
 */
export const getDateRangeDays = (endDate: Dayjs, days: number): [Dayjs, Dayjs] => [
  endDate.subtract(days - 1, 'day'),
  endDate,
];

/**
 * 与えられた週数の期間を返す
 *
 * 週の開始日は月曜。
 * 期間の最終日が日曜日でない場合、最後の週は7日より少なくなる。
 * @param endDate 期間の最終日
 * @param weeks 期間の週数
 * @returns [期間の開始日, 期間の終了日]
 */
export const getDateRangeWeeks = (endDate: Dayjs, weeks: number): [Dayjs, Dayjs] => [
  getCurrentWeekStartDay(endDate).subtract(weeks - 1, 'week'),
  endDate,
];

/**
 * 与えられた月数の期間を返す
 *
 * 期間の最終日が月の最終日でない場合、最後の月はその月の日数より少なくなる。
 * @param endDate 期間の最終日
 * @param months 期間の月数
 * @returns [期間の開始日, 期間の終了日]
 */
export const getDateRangeMonths = (endDate: Dayjs, months: number): [Dayjs, Dayjs] => [
  getCurrentMonthStartDay(endDate).subtract(months - 1, 'month'),
  endDate,
];

/**
 * 基準日から見た前週を返す
 * @param date 基準日
 * @returns [期間の開始日, 期間の終了日]
 */
export const getDateRangeLastWeek = (date: Dayjs): [Dayjs, Dayjs] => [
  getLastWeekStartDay(date),
  getLastWeekEndDay(date),
];

/**
 * 基準日から見た前月を返す
 * @param date 基準日
 * @returns [期間の開始日, 期間の終了日]
 */
export const getDateRangeLastMonth = (date: Dayjs): [Dayjs, Dayjs] => [
  getLastMonthStartDay(date),
  getLastMonthEndDay(date),
];
