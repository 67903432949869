import actionCreatorFactory from 'typescript-fsa';

import { Competitors } from 'models/Domain/Competitor/Competitor';
import { GbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { Store } from 'models/Domain/Store';

import { EditItemType } from './reducers';

const actionCreator = actionCreatorFactory('StoreDetail');

export const StoreDetailActions = {
  getStore: actionCreator<number>('getStore'),
  setStoreForDetail: actionCreator<Store>('setStoreForDetail'),
  setStoreForEdit: actionCreator<Store>('setStoreForEdit'),
  resetStoreForEdit: actionCreator('resetStoreForEdit'),
  updateStoreOpenInfo: actionCreator('updateStoreOpenInfo'),
  updateStoreOpeningDate: actionCreator('updateStoreOpeningDate'),
  updateStoreCode: actionCreator('updateStoreCode'),
  updatePhone: actionCreator('updatePhone'),
  updateStoreWebsiteUrl: actionCreator('updateStoreWebsiteUrl'),
  updateStoreNameBranch: actionCreator('updateStoreName'),
  updateStoreRegularHours: actionCreator('updateStoreRegularHours'),
  updateStoreGmbSync: actionCreator('updateStoreGmbSync'),
  updateStoreSpecialHours: actionCreator('updateStoreSpecialHours'),
  updateMoreHours: actionCreator('updateMoreHours'),
  updateStoreAddress: actionCreator('updateStoreAddress'),
  updateStoreGmbCategories: actionCreator('updateStoreGmbCategories'),
  updateStoreGmbProfile: actionCreator('updateStoreGmbProfile'),
  updateStoreAttributes: actionCreator('updateStoreAttributes'),
  setAttributeMetadatas: actionCreator<GmbAttributeMetadatas>('setAttributeMetadatas'),
  setAvailableUrlTypes: actionCreator<GbpAvailableUrlTypes>('setAvailableUrlTypes'),
  removeGmbConnect: actionCreator('removeGmbConnect'),
  importGmbMapLatlng: actionCreator('importGmbMapLatlng'),
  importDiffGmbMapLatlng: actionCreator('importDiffGmbMapLatlng'),

  setRequireManualSettingItem: actionCreator<{ item: EditItemType; requireManualSetting: boolean }>(
    'setRequireManualSettingItem',
  ),
  setIsSuccessAddressValidation: actionCreator<boolean>('setAddressValidationSuccess'),
  deleteStore: actionCreator('deleteStore'),

  setCompetitors: actionCreator<Competitors>('setCompetitors'),
};
