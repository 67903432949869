import React, { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ProgressCircle } from 'components/molecules/ProgressCircle';
import { GbpChecklistItem } from 'models/Domain/GbpChecklist';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

import { GbpChecklistStoreTable } from './GbpChecklisStoreTable';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  item: GbpChecklistItem;
};

type FilterType = 'all' | 'done' | 'notDone';

export const GbpChecklistModal = React.memo<Props>(({ isOpen, onClose, item }) => {
  const [filter, setFilter] = useState<FilterType>('all');
  const filterOptions: { text: string; value: FilterType }[] = useMemo(() => {
    return [
      { text: `すべて（${item.status.total}店舗）`, value: 'all' },
      { text: `未達成（${item.status.notDoneCount}店舗）`, value: 'notDone' },
      { text: `達成（${item.status.doneCount}店舗）`, value: 'done' },
    ];
  }, [item.status]);
  const stores = useSelector((state: State) => state.store.stores);
  const user = useSelector((state: State) => state.app.currentUser);
  const filteredStores = useMemo(() => {
    switch (filter) {
      case 'all':
        return stores.filterStoresById(item.status.storeIds.toArray());
      case 'done':
        return stores.filterStoresById(item.status.done.toArray());
      case 'notDone':
        return stores.filterStoresById(item.status.notDone.toArray());
    }
  }, [filter, stores, item]);
  const editableStores = filteredStores.filterByUserRole(user);

  return (
    <StyledModal open={isOpen}>
      <ModalContent>
        <Wrapper>
          <Title>「{item.title}」の達成状況</Title>
          <ContentWrapper>
            <Header>
              <PullDownWrapper>
                <PullDownLabel>達成状況</PullDownLabel>
                <PullDown value={filter} options={filterOptions} onChange={setFilter} />
              </PullDownWrapper>
              <ProgressWrapper>
                {item.status.total > 0 && <ProgressCircle value={item.status.progress} />}
              </ProgressWrapper>
            </Header>
            <GbpChecklistStoreTable item={item} stores={filteredStores} editableStores={editableStores} />
            {filteredStores.list.isEmpty() && <Notification>条件に一致する店舗がありませんでした</Notification>}
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>閉じる</StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  height: 80%;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;

const PullDownLabel = styled.div``;

const PullDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProgressWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 32px;
`;

const Notification = styled.div`
  width: 100%;
  padding: 120px;
  text-align: center;
  color: ${COLOR.GRAY};
`;
