import styled from 'styled-components';

import { COLOR } from 'style/color';

export const OfferStatus = styled.div<{ value: 'done' | 'none' | string }>`
  min-width: 60px;
  height: 24px;
  border-radius: 12px;
  font-size: 13px;
  line-height: 1.846;
  background: ${({ value }) => (value === 'done' ? COLOR.GREEN : COLOR.LIGHT_GRAY)};
  color: ${({ value }) => (value === 'done' ? COLOR.WHITE : COLOR.BLACK)};

  &::after {
    content: '${({ value }) => (value === 'done' ? '完了' : value === 'none' ? '未完了' : value)}';

    display: block;
    width: 100%;
    text-align: center;
  }
`;
