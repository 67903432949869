import dayjs from 'dayjs';
import { List, Map, Record } from 'immutable';

import { JSObject } from 'types/Common';

import { CompetitorList } from './Competitor';

const STORE_KEY_DELIMITER = '###';

export class StoreKey extends Record<{
  storeName: string;
  placeId: string;
}>({
  storeName: '',
  placeId: '',
}) {
  get key() {
    return `${this.storeName}${STORE_KEY_DELIMITER}${this.placeId}`;
  }
}

export class MapSearchResultDetailItem extends Record<{
  spotName: string;
  placeId: string;
  rank: number;
  rankExcludeAds: number | null;
  evaluation: number | null;
  numberOfReviews: number | null;
  category: string | null;
  address: string | null;
  link: string | null;
  isAds: boolean;
}>({
  spotName: '',
  placeId: '',
  rank: 0,
  rankExcludeAds: null,
  evaluation: null,
  numberOfReviews: null,
  category: null,
  address: null,
  link: null,
  isAds: false,
}) {
  static fromJSON(data: JSObject = {}) {
    return new MapSearchResultDetailItem({
      spotName: data.spot_name,
      placeId: data.place_id,
      rank: data.rank,
      rankExcludeAds: data.rank_exclude_ads,
      evaluation: data.evaluation,
      numberOfReviews: data.number_of_reviews,
      category: data.category,
      address: data.address,
      link: data.link,
      isAds: data.is_ads,
    });
  }

  getStoreKey(): StoreKey {
    return new StoreKey({ storeName: this.spotName, placeId: this.placeId });
  }
}

/**
 * エリア・キーワードごとの検索結果詳細データ
 */
export class MapSearchResultDetail extends Record<{
  status: 'SUCCESS';
  ts: dayjs.Dayjs;
  condition: {
    areaName: string;
    searchWord: string;
  };
  result: {
    resultType: 'list' | 'direct' | 'no result' | 'bad query';
    mapUrl: string | null;
    hasAdsInFirstView: boolean;
    items: List<MapSearchResultDetailItem>;
    isSearched: boolean;
    isAmbiguous: boolean;
    candidatePlaces: List<MapSearchResultDetailItem>;
  };
}>({
  status: 'SUCCESS',
  ts: dayjs(),
  condition: {
    areaName: '',
    searchWord: '',
  },
  result: {
    resultType: 'list',
    mapUrl: '',
    hasAdsInFirstView: false,
    items: List(),
    isSearched: false,
    isAmbiguous: false,
    candidatePlaces: List(),
  },
}) {
  static fromJSON(data: JSObject = {}) {
    return new MapSearchResultDetail({
      status: data.status,
      ts: dayjs(data.ts),
      condition: {
        areaName: data.condition.area_name,
        searchWord: data.condition.search_word,
      },
      result: {
        resultType: data.result.result_type,
        mapUrl: data.result.map_url,
        hasAdsInFirstView: data.result.has_ads_in_first_view,
        items: List(data.result.items.map((item: any) => MapSearchResultDetailItem.fromJSON(item))),
        isSearched: data.result.is_searched ?? true,
        isAmbiguous: data.result.is_ambiguous ?? false,
        candidatePlaces: List(
          (data.result.candidate_places ?? []).map((item: any) => MapSearchResultDetailItem.fromJSON(item)),
        ),
      },
    });
  }

  /**
   * 検索実行時に指定地点とは別地点で検索が実行された場合の検索地点名
   */
  get replacedAreaName() {
    if (this.result.isAmbiguous && this.result.candidatePlaces.size > 0) {
      return this.result.candidatePlaces.get(0)?.spotName;
    }
    return undefined;
  }

  hasAdsDetailItem() {
    return this.result.items.find((item) => item.isAds) !== undefined;
  }

  getItemsByManagedStoreKeys(managedStoreKeys: List<StoreKey>) {
    return this.result.items.filter((item) => managedStoreKeys.contains(item.getStoreKey()) && !item.isAds);
  }

  getItemsByCompetitors(competitors: CompetitorList) {
    return this.result.items.filter((item) => competitors.contains(item.getStoreKey()) && !item.isAds);
  }

  getItemByStoreKey(storeKey: StoreKey) {
    return {
      itemNotAds: this.result.items.find((item) => item.getStoreKey().key === storeKey.key && !item.isAds),
      itemAds: this.result.items.find((item) => item.getStoreKey().key === storeKey.key && item.isAds),
    };
  }

  getDetailItemByStoreNameAndLink(storeName: string, link: string) {
    return this.result.items.find(
      (item: MapSearchResultDetailItem) => item.spotName === storeName && item.link === link,
    );
  }

  isCompleted() {
    return !!this.result.mapUrl;
  }
}

export class MapSearchResultDetailFailed extends Record<{
  status: 'FAILED';
}>({
  status: 'FAILED',
}) {}

export type MapSearchResultDetailCondition = { areaName: string; searchWord: string };

export type AverageRankTableData = {
  storeKey: StoreKey;
  rankedConditions: List<string>;
  rankedConditionsExcludeAds: List<string>;
  averageRank: number;
  averageRankExcludeAds: number;
  rankedAverageRank: number;
  rankedAverageRankExcludeAds: number | null;
};

export type SpotResultItem = {
  condition: MapSearchResultDetailCondition;
  item: MapSearchResultDetailItem | null;
};

export type RankComparisonTableData = {
  spotName: string;
  resultItems: List<SpotResultItem>;
};

const CONDITION_KEY_DELIMITER = '###z9hG4bK###'; // 絶対使われていないであろう文字列を利用する
export const convertToConditionKey = (condition: MapSearchResultDetailCondition) =>
  `${condition.areaName}${CONDITION_KEY_DELIMITER}${condition.searchWord}`;
export const convertFromConditionKey = (conditionKey: string): MapSearchResultDetailCondition => {
  const [areaName, searchWord] = conditionKey.split(CONDITION_KEY_DELIMITER, 2);
  return { areaName, searchWord };
};

export class MapSearchResultDetails extends Record<{
  conditions: List<MapSearchResultDetailCondition>;
  datas: Map<string, MapSearchResultDetail | MapSearchResultDetailFailed>;
}>({
  conditions: List(),
  datas: Map(),
}) {
  /**
   * 検索条件データを設定する
   */
  setConditions(conditions: List<MapSearchResultDetailCondition>) {
    return this.set('conditions', conditions);
  }

  /**
   * 詳細データを保存する
   */
  setDetail(condition: MapSearchResultDetailCondition, detail: MapSearchResultDetail | MapSearchResultDetailFailed) {
    return this.update('datas', (datas) => datas.set(convertToConditionKey(condition), detail));
  }

  /**
   * エリア名・検索ワードに対する詳細データがあるかを返す
   */
  hasDetail(condition: MapSearchResultDetailCondition) {
    return this.getDetail(condition) !== undefined;
  }

  /**
   * エリア名・検索ワードに対する詳細データを取得する
   */
  getDetail(condition: MapSearchResultDetailCondition) {
    return this.datas.get(convertToConditionKey(condition));
  }

  getConditions() {
    return this.conditions;
  }

  /**
   * データ取得が完了している数を返す
   */
  getCompletedCount() {
    return this.datas.filter((data) => data.status === 'SUCCESS' && data.isCompleted()).size;
  }

  getRanksByStoreKey(storeKey: StoreKey) {
    const ranks = this.datas
      .entrySeq()
      .map(([conditionKey, resultDetail]) => {
        const condition = convertFromConditionKey(conditionKey);
        if (resultDetail.status === 'FAILED') {
          return {
            status: resultDetail.status,
            condition: condition,
            item: undefined,
          };
        }

        return {
          condition: condition,
          detail: resultDetail,
          item: resultDetail.getItemByStoreKey(storeKey),
        };
      })
      .toArray();

    const item = ranks.find((rankItem) => rankItem.item?.itemNotAds || rankItem.item?.itemAds);
    const anyItem = item?.item?.itemNotAds ?? item?.item?.itemAds;
    const storeInfo = anyItem
      ? {
          storeKey: anyItem.getStoreKey(),
          evaluation: anyItem.evaluation,
          numberOfReviews: anyItem.numberOfReviews,
          category: anyItem.category,
          address: anyItem.address,
          link: anyItem.link,
        }
      : {};

    const hasAdsItem = !!ranks.find((rankItem) => rankItem.item?.itemAds);

    return { storeInfo, ranks, hasAdsItem };
  }

  getRanksByAreaKeyword(condition: { areaName: string; searchWord: string }) {
    const data = this.getDetail(condition);
    if (data && data.status === 'SUCCESS') {
      return data.result.items;
    } else {
      return undefined;
    }
  }

  getResultItems() {
    let result = List<List<MapSearchResultDetailItem>>();
    this.datas.valueSeq().forEach((value) => {
      if (value.status !== 'SUCCESS') {
        return;
      }
      result = result.push(value.result.items);
    });
    return result;
  }

  getStoreKeys() {
    return this.datas
      .valueSeq()
      .flatMap((value) => {
        if (value.status === 'SUCCESS') {
          return value.result.items.map((item) => item.getStoreKey());
        } else {
          return List<StoreKey>();
        }
      })
      .toSet()
      .toList();
  }

  getAverageRankTableData(): List<AverageRankTableData> {
    const conditionCount = this.datas.size;
    const MAX_RANK = 60;
    return this.getStoreKeys().map((storeKey): AverageRankTableData => {
      let rankedConditions = List<string>();
      let rankedConditionsExcludeAds = List<string>();
      let rankSum = 0;
      let rankSumExcludeAds = 0;
      this.datas.forEach((value, conditionKey) => {
        if (value.status !== 'SUCCESS') {
          return;
        }
        const sortedItems = value.result.items.sortBy((i) => i.rank);
        // storeKeyが一致するitemのうち、順位が高いものを取得
        const item = sortedItems.find((i) => i.getStoreKey().key === storeKey.key);
        if (item) {
          rankedConditions = rankedConditions.push(conditionKey);
          rankSum += item.rank;
        }

        // 広告ではないstoreKeyが一致するデータ
        const itemNotAds = sortedItems.find((i) => i.getStoreKey().key === storeKey.key && !i.isAds);
        if (itemNotAds) {
          rankedConditionsExcludeAds = rankedConditionsExcludeAds.push(conditionKey);
          rankSumExcludeAds += itemNotAds.rankExcludeAds ?? 0;
        }
      });
      const rankedConditionCount = rankedConditions.size;
      const rankedConditionExcludeAds = rankedConditionsExcludeAds.size;
      return {
        storeKey,
        // 60位以内に掲載されている検索条件数（広告を含む）
        rankedConditions,
        // 60位以内に掲載されている検索条件数（広告を除く）
        rankedConditionsExcludeAds,
        // 60位以内に含まれていない場合は、60位として計算した平均順位（広告を含む）
        averageRank: (rankSum + MAX_RANK * (conditionCount - rankedConditionCount)) / conditionCount,
        // 60位以内に含まれていない場合は、60位として計算した平均順位（広告を除く）
        averageRankExcludeAds:
          (rankSumExcludeAds + MAX_RANK * (conditionCount - rankedConditionExcludeAds)) / conditionCount,
        // 60位以内に掲載されたデータのみで計算した平均順位（広告を含む）
        rankedAverageRank: rankSum / rankedConditionCount,
        // 60位以内に掲載されたデータのみで計算した平均順位（広告を除く）
        rankedAverageRankExcludeAds: rankedConditionExcludeAds ? rankSumExcludeAds / rankedConditionExcludeAds : null,
      };
    });
  }

  getDetailItem(condition: MapSearchResultDetailCondition, spotName: string, link: string | null) {
    if (link === null) {
      return null;
    }
    const detail = this.getDetail(condition);
    if (detail === undefined || detail.status === 'FAILED') {
      return null;
    }
    const detailItem = detail.getDetailItemByStoreNameAndLink(spotName, link);
    return detailItem !== undefined ? detailItem : null;
  }

  getRankComparisonTableData(excludeAds: boolean): List<{
    condition: MapSearchResultDetailCondition;
    itemsByStoreName: {
      [key: string]: MapSearchResultDetailItem;
    };
  }> {
    return this.conditions
      .filter((condition) => this.getDetail(condition)?.status === 'SUCCESS')
      .map((condition) => ({
        condition,
        itemsByStoreName: (this.getDetail(condition) as MapSearchResultDetail).result.items // filterで絞っているので MapSearchResultDetail と扱って問題ない
          // excludeAdsがfalseの場合は全て、そうでない場合は広告でないもの
          .filter((item) => !excludeAds || item.isAds === false)
          .reduce(
            (current, value) => {
              current[value.spotName] = value;
              return current;
            },
            {} as { [key: string]: MapSearchResultDetailItem },
          ),
      }));
  }
}
