import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';

export const ErrorLabel = styled(Label)`
  &&& {
    opacity: 1;
    background: ${COLOR.WHITE};
    border: 1px solid ${COLOR.ERROR};
    color: ${COLOR.ERROR};
  }
`;
