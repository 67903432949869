import { Record, Set } from 'immutable';

import { Group } from 'types/Common';

const DEFAULT_GROUP = null;
const DEFAULT_STORE_IDS = Set<number>();
const DEFAULT_IS_ALL_STORES = true;
const DEFAULT_SHOW_CLOSED_STORES = false;
const DEFAULT_KEYWORD = '';

export class CompetitorRegisterSearchCondition extends Record<{
  group: Group;
  storeIds: Set<number>;
  isAllStores: boolean;
  showClosedStores: boolean;
  keyword: string;
}>({
  group: DEFAULT_GROUP,
  storeIds: DEFAULT_STORE_IDS,
  isAllStores: DEFAULT_IS_ALL_STORES,
  showClosedStores: DEFAULT_SHOW_CLOSED_STORES,
  keyword: DEFAULT_KEYWORD,
}) {
  setStoreIds(storeIds: Set<number>, isAllStores: boolean) {
    return this.merge({ storeIds, isAllStores });
  }

  toRequestParams() {
    return this.storeIds.map((storeId) => ({ store_id: storeId, keyword: this.keyword })).toArray();
  }

  // 検索可能な状態か
  get isValid(): boolean {
    return this.storeIds.size > 0 && !!this.keyword;
  }
}
