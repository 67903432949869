import { FaasApiClient } from 'ApiClient';

const faasApiClient = new FaasApiClient({});

export type MapSearchResultApiStartParams = {
  conditions: {
    area_names: string[];
    search_words: string[];
  };
};

export type MapSearchResultApiStartResponse = {
  executionArn: string;
};

export type MapSearchResultApiStatusParams = {
  executionArn: string;
};

export type MapSearchResultApiStatusResponse = {
  status: 'RUNNING' | 'SUCCEEDED';
  date: string;
  conditions: {
    area_names: string[];
    search_words: string[];
  };
  result: {
    items: {
      condition: {
        area_name: string;
        search_word: string;
      };
      url: string | null;
    }[];
  };
};

export class MapSearchResultApi {
  static endpoint = '/gmb/map_searchresults';

  /**
   * マップ検索結果取得の開始API
   * @param params
   * @returns
   */
  static start(params: MapSearchResultApiStartParams) {
    return faasApiClient.post<MapSearchResultApiStartResponse>(`${this.endpoint}/start`, params);
  }

  /**
   * マップ検索結果取得の状態確認API
   * @param params
   * @returns
   */
  static getResult(params: MapSearchResultApiStatusParams) {
    return faasApiClient.post<MapSearchResultApiStatusResponse>(`${this.endpoint}/status`, params);
  }
}
