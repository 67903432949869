import React, { useCallback, useEffect, useMemo } from 'react';

import { List, is } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { TagInput } from 'components/molecules/TagInput';
import { CompetitorFilter } from 'components/pageComponents/CompetitorRegister/CompetitorFilter';
import { CompetitorRegisterSearchCondition } from 'components/pageComponents/CompetitorRegister/CompetitorRegisterSearchCondition';
import { CompetitorSearchResult } from 'components/pageComponents/CompetitorRegister/CompetitorSearchResult';
import { Manual } from 'components/pageComponents/CompetitorRegister/Manual';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { Competitor } from 'models/Domain/Competitor/Competitor';
import { CompetitorRegisterActions } from 'modules/competitor/register/actions';
import { CompetitorRegisterSelectors } from 'modules/competitor/register/selectors';
import { Path } from 'routes';
import { COLOR } from 'style/color';

import { CompetitorSearchProgress } from '../pageComponents/CompetitorRegister/CompetitorSearchProgress';

export const CompetitorRegister = React.memo(() => {
  const dispatch = useDispatch();
  const { initializePage, searchCompetitors, setFilterWords, setTags, setSelectedCompetitors, bulkInsertCompetitors } =
    useMemo(() => bindActionCreators(CompetitorRegisterActions, dispatch), [dispatch]);

  const { isLoading, searchCondition, filterWords, tags, searchResults, selectedCompetitors, registeredCompetitors } =
    useSelector(CompetitorRegisterSelectors.selectState);
  const stores = useSelector((state) => state.store.stores);

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const handleOnChangeTags = useCallback(
    (values: string[]) => {
      setTags(List(values));
    },
    [setTags],
  );

  const handleOnSelectCompetitor = useCallback(
    (competitor: Competitor) => {
      let newSelectedCompetitors = selectedCompetitors;
      // storeIdとplaceIdが一致するものがあれば除外、なければ追加する
      if (selectedCompetitors.find((c) => is(c, competitor))) {
        newSelectedCompetitors = newSelectedCompetitors.filterNot((c) => is(c, competitor));
      } else {
        newSelectedCompetitors = newSelectedCompetitors.push(competitor);
      }
      setSelectedCompetitors(newSelectedCompetitors);
    },
    [selectedCompetitors, setSelectedCompetitors],
  );

  const handleOnClickRegister = useCallback(() => {
    if (tags.isEmpty() && !window.confirm('タグが未設定です。このまま登録してよろしいですか？')) {
      return;
    }
    bulkInsertCompetitors();
  }, [bulkInsertCompetitors, tags]);

  const handleOnCancel = useCallback(() => {
    if (
      selectedCompetitors.isEmpty() ||
      window.confirm(
        '変更を保存せずにページから離れようとしています。\n保存されていない内容は失われてしまいますが、よろしいですか？',
      )
    )
      dispatch(replaceWithOrganizationId(`${Path.competitors.index}`));
  }, [dispatch, selectedCompetitors]);

  return (
    <>
      <MainWrapper>
        <Helmet title={getPageTitle('競合店舗登録')} />
        <StickyHeader>
          <Title>競合店舗登録</Title>
        </StickyHeader>
        <WideBody>
          <Manual />
          <StepTitle>競合店舗を検索</StepTitle>
          <CompetitorRegisterSearchCondition
            isLoading={isLoading}
            committedSearchCondition={searchCondition}
            commitSearchCondition={searchCompetitors}
          />
          {isLoading && searchResults && (
            <ProgressContent>
              <CompetitorSearchProgress searchResults={searchResults} />
            </ProgressContent>
          )}
          {searchResults != null && (
            <>
              <Divider />
              <FilterContainer>
                <StepTitle>
                  競合店舗を選択
                  <ContextHelp content={'検索結果をキーワードで絞り込みできます'} />
                </StepTitle>
                <CompetitorFilter committedFilterWords={filterWords} commitFilterWords={setFilterWords} />
              </FilterContainer>
              <CompetitorSearchResult
                stores={stores}
                searchResults={searchResults}
                filterWords={filterWords}
                onSelectCompetitor={handleOnSelectCompetitor}
                selectedCompetitors={selectedCompetitors}
                registeredCompetitors={registeredCompetitors}
              />
              <Divider />
              <StepTitle>
                タグを設定
                <ContextHelp
                  content={'競合店舗の分類のために使用します。複数設定可能で、あとから店舗ごとに変更できます'}
                />
              </StepTitle>
              <TagInput placeholder={'タグ'} values={tags.toArray()} onChange={handleOnChangeTags} />
            </>
          )}
          <ButtonContainer>
            {!selectedCompetitors.isEmpty() && <CountLabel>{selectedCompetitors.size}店舗を選択中</CountLabel>}
            <StyledButton onClick={handleOnCancel}>キャンセル</StyledButton>
            <StyledButton
              priority={'high'}
              onClick={handleOnClickRegister}
              disabled={isLoading || selectedCompetitors.isEmpty()}
            >
              登録
            </StyledButton>
          </ButtonContainer>
        </WideBody>
      </MainWrapper>
    </>
  );
});

const StepTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 176px;
    height: 54px;
    padding: 0;
    align-self: end;
    margin-left: 16px;
    box-shadow: none;
  }
`;

const Divider = styled.hr`
  border-top: 1px solid ${COLOR.LIGHT_GRAY};
  border-bottom: none;
  margin: 20px 0;
`;

const FilterContainer = styled.div`
  margin-bottom: 32px;
`;

const ProgressContent = styled.div`
  margin-bottom: 32px;
`;

const CountLabel = styled.div`
  margin-right: auto;
`;
