import React, { useCallback, useEffect, useState } from 'react';

import { Set } from 'immutable';
import { Search } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { CompetitorRegisterSearchCondition as SearchCondition } from 'models/Domain/Competitor/CompetitorRegisterSearchCondition';
import { COLOR } from 'style/color';
import { Group } from 'types/Common';

type Props = {
  className?: string;
  isLoading: boolean;
  committedSearchCondition: SearchCondition;
  commitSearchCondition: (searchCondition: SearchCondition) => void;
};

export const CompetitorRegisterSearchCondition = React.memo<Props>(
  ({ className, commitSearchCondition, isLoading, committedSearchCondition }) => {
    const [searchCondition, setSearchCondition] = useState(committedSearchCondition);

    // committedSearchConditionが変更されたら更新する
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    const { group, storeIds, showClosedStores } = searchCondition;

    const handleChangeGroupStore = useCallback(
      (group: Group, storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
        const newSearchCondition = searchCondition.merge({
          group,
          storeIds,
          isAllStores,
          showClosedStores,
        });
        setSearchCondition(newSearchCondition);
      },
      [searchCondition, setSearchCondition],
    );

    const handleOnChangeKeyword = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchCondition = searchCondition.set('keyword', event.target.value);
        setSearchCondition(newSearchCondition);
      },
      [searchCondition, setSearchCondition],
    );

    const handleOnCommitSearchCondition = useCallback(() => {
      commitSearchCondition(searchCondition);
    }, [commitSearchCondition, searchCondition]);

    const handleOnKeyPress = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && searchCondition.isValid) {
          handleOnCommitSearchCondition();
        }
      },
      [handleOnCommitSearchCondition, searchCondition.isValid],
    );

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <GroupStoreSelect
            group={group}
            storeIds={storeIds}
            showClosedStores={showClosedStores}
            onChange={handleChangeGroupStore}
          />
          <ContentWrapper>
            <ContentLabel>キーワード</ContentLabel>
            <SearchField
              onSearchChange={handleOnChangeKeyword}
              input={{ fluid: true }}
              placeholder='競合店舗名・カテゴリなど'
              showNoResults={false}
              value={searchCondition.keyword}
              onKeyPress={handleOnKeyPress}
              icon={null}
            />
          </ContentWrapper>
          <ButtonContainer>
            <StyledButton
              priority={'high'}
              onClick={handleOnCommitSearchCondition}
              disabled={isLoading || !searchCondition.isValid}
            >
              検索
            </StyledButton>
          </ButtonContainer>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled(Card)`
  margin-bottom: 32px;
`;

const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const SearchField = styled(Search)`
  width: 100%;

  &&& {
    input {
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
      font-weight: bold;
      height: 33px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    align-self: end;
    margin-left: 8px;
    box-shadow: none;
  }
`;
