import React, { useCallback } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyFooter } from 'components/atoms/StickyFooter';
import { AppActions } from 'modules/app/actions';
import { BulkEditStoresActions } from 'modules/bulkEditStores/actions';
import { State } from 'modules/reducers';
import { StoreActions } from 'modules/store/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

type ComponentProps = {
  className?: string;
  selectedSize: number;
  onCancel: () => void;
};

/**
 * テーブルから店舗を選択したときに表示されるフッター
 */
export const SelectListFooter = React.memo<ComponentProps>(({ className, selectedSize, children, onCancel }) => {
  const dispatch = useDispatch();
  const { stores, bulkEditStoreState } = useSelector(
    (state: State) => ({
      stores: state.store.stores,
      bulkEditStoreState: state.store.bulkEditStoreState,
    }),
    shallowEqual,
  );

  const handleOnClickDownloadCsv = useCallback(() => {
    dispatch(StoreActions.downloadStoresCsv());
  }, [dispatch]);

  const handleOnClickStartBulkEditStores = useCallback(() => {
    const filteredStores = stores.filterStoresById(bulkEditStoreState.selectedStoreIds.toArray());
    dispatch(AppActions.moveTo(Path.store.bulkEdit));
    dispatch(BulkEditStoresActions.setStores({ stores: filteredStores }));
  }, [stores, bulkEditStoreState.selectedStoreIds, dispatch]);

  return (
    <StyledStickyFooter className={className}>
      <ChildWrapper>
        <CheckedStoreLabel>
          {selectedSize}
          店舗を選択中
        </CheckedStoreLabel>
        <CancelLabel onClick={onCancel}>全て解除</CancelLabel>
      </ChildWrapper>
      <ButtonWrapper>
        <CustomButton onClick={handleOnClickDownloadCsv}>CSVダウンロード</CustomButton>
        <CustomButton priority='high' onClick={handleOnClickStartBulkEditStores}>
          店舗情報を変更
        </CustomButton>
      </ButtonWrapper>
    </StyledStickyFooter>
  );
});

const StyledStickyFooter = styled(StickyFooter)`
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 320px; /* メニューの分は空ける */
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.2));

  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    /* 店舗を選択して実行するアクションがSP表示を想定していないのでPCでのみ表示する */
    display: none;
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const CheckedStoreLabel = styled.div`
  font-size: 16px;
  border-right: 1px solid ${COLOR.GRAY};
  margin-right: 16px;
  padding-right: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0;
`;

const CustomButton = styled(Button)`
  &&& {
    font-weight: bold;
    cursor: pointer;
    width: auto;
    font-size: 16px;
    margin-right: 11px;
    border-radius: 8px;
    padding: 12px 16px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &:before {
    white-space: pre;
  }
`;

const CancelLabel = styled.div`
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
`;
