import React from 'react';

import { Set as ImmutableSet } from 'immutable';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { DropdownMenu, DropdownMenuItem } from 'components/molecules/DropdownMenu';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { SearchKeywordHelp as Help } from 'helpers/ContextHelp';
import { convertRateToText } from 'helpers/utils';
import { KeywordTableData, getGrowthRate } from 'models/Domain/SearchKeyword/SearchKeyword';
import { Stores } from 'models/Domain/Store';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

// 合計行のグラフを表示中かどうか管理するラベル
export const SUMMARY_ROW = 'summary';
// 個別のグラフの表示・非表示を管理する識別子
export type GraphKey = typeof SUMMARY_ROW | number;

export type SortKey =
  | 'storeName'
  | 'storeCode'
  | 'avg'
  | 'count'
  | 'momRatio'
  | 'momDiff'
  | 'threeMomRatio'
  | 'yoyRatio';
export type SortOrder = 'asc' | 'desc';

type Props = {
  className?: string;
  searchWord: string; // 合計が選択されている場合は含むワード、それ以外は選択されたワード
  tableData: KeywordTableData | undefined;
  isLoading: boolean;
  sortKey: SortKey;
  sortOrder: SortOrder;
  stores: Stores;
  activeGraphs: ImmutableSet<GraphKey>; // 表示中のグラフ
  displaySearchVolumeLink: boolean; // 検索ボリュームへの動線を表示するかどうか
  onChangeSort: (sortKey: SortKey) => void;
  onToggleDisplayGraph: (key: GraphKey) => void; // グラフ表示チェックボックスが押された
};

/** 値を3桁ごとにカンマ区切りで表示 nullならば「ー」 */
const convertNumberToText = (value: number | null): string => {
  return value != null ? Math.abs(value).toLocaleString() : 'ー';
};

const DEFAULT_TARGET_MONTH_LABEL = '表示月';

/** 店舗ごとの流入キーワードデータの表 */
export const SearchKeywordsDetailTable = React.memo<Props>(
  ({
    searchWord,
    tableData,
    isLoading,
    sortKey,
    sortOrder,
    stores,
    activeGraphs,
    displaySearchVolumeLink,
    onChangeSort,
    onToggleDisplayGraph,
  }) => {
    const targetMonth = tableData?.targetMonth;
    const summary = tableData?.summary;
    const isSortOrderDesc = sortOrder === 'desc';

    // 合計行の比
    const summaryMomGrowthRate = summary ? getGrowthRate(summary.momRatio) : null;
    const summaryThreeMomGrowthRate = summary ? getGrowthRate(summary.threeMomRatio) : null;
    const summaryYoyGrowthRate = summary ? getGrowthRate(summary.yoyRatio) : null;

    // 表示月のラベル
    const targetMonthLabel = targetMonth ? targetMonth.format() : DEFAULT_TARGET_MONTH_LABEL;

    // キーワードが0件かつ、読み込み中の場合にLoader表示用のスペースを追加
    const showLoaderContainer = (!tableData || tableData?.items.size === 0) && isLoading;

    return (
      <Wrapper>
        <StyledTable>
          {/* ヘッダー部 */}
          <StyledTableHeader>
            <TableHeaderRow>
              <CheckBoxTableHeaderCell>表示</CheckBoxTableHeaderCell>
              <SortableTableHeaderCell style={{ textAlign: 'left' }} onClick={() => onChangeSort('storeName')}>
                <StoreFlexWrapper>
                  {sortKey === 'storeName' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  店舗名
                </StoreFlexWrapper>
              </SortableTableHeaderCell>
              <SortableTableHeaderCell style={{ textAlign: 'left' }} onClick={() => onChangeSort('storeCode')}>
                <StoreFlexWrapper>
                  {sortKey === 'storeCode' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  店舗コード
                </StoreFlexWrapper>
              </SortableTableHeaderCell>
              <SortableTableHeaderCell onClick={() => onChangeSort('avg')}>
                <FlexWrapper>
                  {sortKey === 'avg' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  月間平均検索数
                  <ContextHelp content={Help.avg} />
                </FlexWrapper>
              </SortableTableHeaderCell>
              <SortableTableHeaderCell onClick={() => onChangeSort('count')}>
                <FlexWrapper>
                  {sortKey === 'count' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  {targetMonthLabel}
                  <ContextHelp content={Help.count} />
                </FlexWrapper>
              </SortableTableHeaderCell>
              <SortableTableHeaderCell onClick={() => onChangeSort('momDiff')}>
                <FlexWrapper>
                  {sortKey === 'momDiff' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  前月差
                  <ContextHelp content={Help.momDiff} />
                </FlexWrapper>
              </SortableTableHeaderCell>
              <SortableTableHeaderCell onClick={() => onChangeSort('momRatio')}>
                <FlexWrapper>
                  {sortKey === 'momRatio' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  前月比
                  <ContextHelp content={Help.momRatio} />
                </FlexWrapper>
              </SortableTableHeaderCell>
              <SortableTableHeaderCell onClick={() => onChangeSort('threeMomRatio')}>
                <FlexWrapper>
                  {sortKey === 'threeMomRatio' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  3ヶ月前比
                  <ContextHelp content={Help.threeMomRatio} />
                </FlexWrapper>
              </SortableTableHeaderCell>
              <SortableTableHeaderCell onClick={() => onChangeSort('yoyRatio')}>
                <FlexWrapper>
                  {sortKey === 'yoyRatio' && <SortTriangle isSortOrderDesc={isSortOrderDesc} />}
                  前年同月比
                  <ContextHelp content={Help.yoyRatio} />
                </FlexWrapper>
              </SortableTableHeaderCell>
            </TableHeaderRow>
          </StyledTableHeader>
          <TableBody>
            {/* テーブルデータ (全店舗合算) 部 */}
            {summary && (
              <SummaryTableRow>
                <CheckBoxTableCell>
                  <MobileOnlyLabel>表示</MobileOnlyLabel>
                  <SmallCheckBox
                    checked={activeGraphs.has(SUMMARY_ROW)}
                    onChange={() => onToggleDisplayGraph(SUMMARY_ROW)}
                  />
                </CheckBoxTableCell>
                <StyledTableCell style={{ textAlign: 'left' }}>合計</StyledTableCell>
                <StyledTableCell></StyledTableCell> {/* 合計行は店舗コードなし */}
                <StyledTableCell>
                  <MobileOnlyLabel>月間平均検索数</MobileOnlyLabel>
                  <NumberValue>{convertNumberToText(summary.monthlyAverageCount)}</NumberValue>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>{targetMonthLabel}</MobileOnlyLabel>
                  <NumberValue>{convertNumberToText(summary.targetMonthCount)}</NumberValue>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>前月差</MobileOnlyLabel>
                  <Diff value={summary.momDiff}>{convertNumberToText(summary.momDiff)}</Diff>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>前月比</MobileOnlyLabel>
                  <Diff value={summaryMomGrowthRate}>{convertRateToText(summaryMomGrowthRate)}</Diff>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>3ヶ月前比</MobileOnlyLabel>
                  <Diff value={summaryThreeMomGrowthRate}>{convertRateToText(summaryThreeMomGrowthRate)}</Diff>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>前年同月比</MobileOnlyLabel>
                  <Diff value={summaryYoyGrowthRate}>{convertRateToText(summaryYoyGrowthRate)}</Diff>
                </StyledTableCell>
              </SummaryTableRow>
            )}
            {/* テーブルデータ (店舗ごと) 部 */}
            {tableData?.items.map((item, i) => {
              const { storeId, value } = item;

              // 店舗IDから店舗を取得
              const store = stores.map.get(storeId);
              if (!store) {
                return <></>;
              }

              // セルに表示する値を取得
              const { monthlyAverageCount, monthlyCountSeries, targetMonthCount } = value;
              const checked = activeGraphs.has(storeId); // 該当店舗のグラフが表示中か
              if (!targetMonth) {
                return <></>;
              }
              const momDiff = value.getMomDiff();
              const momGrowthRatio = value.getMomGrowthRate();
              const threeMomGrowthRatio = value.getThreeMomGrowthRate();
              const yoyGrowthRatio = value.getYoyGrowthRate();

              // 店舗名横のドロップダウンメニュー
              const searchParams = new URLSearchParams();
              searchParams.set('si', storeId.toString());
              searchParams.set('sw', searchWord);
              searchParams.set('sm', tableData?.startMonth.toString() || '');
              searchParams.set('em', tableData?.endMonth.toString() || '');
              const dropdownMenuItems: DropdownMenuItem[] = [];
              if (displaySearchVolumeLink) {
                dropdownMenuItems.push({
                  label: '検索ボリューム',
                  link: `${Path.searchVolume.index}?${searchParams.toString()}`,
                });
              }

              return (
                <StyledTableRow key={i}>
                  <CheckBoxTableCell>
                    <MobileOnlyLabel>表示</MobileOnlyLabel>
                    <SmallCheckBox checked={checked} onChange={() => onToggleDisplayGraph(storeId)} />
                  </CheckBoxTableCell>
                  <StyledTableCell style={{ textAlign: 'left' }}>
                    <MobileOnlyLabel>店舗名</MobileOnlyLabel>
                    {store.fullName}
                    {dropdownMenuItems.length > 0 && <DropdownMenu trigger={<StatsIcon />} items={dropdownMenuItems} />}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'left' }}>
                    <MobileOnlyLabel>店舗コード</MobileOnlyLabel>
                    {store.code}
                  </StyledTableCell>
                  <StyledTableCell>
                    <MobileOnlyLabel>月間平均検索数</MobileOnlyLabel>
                    <NumberValue>{convertNumberToText(monthlyAverageCount)}</NumberValue>
                    <SparklinesWrapper>
                      <Sparklines
                        style={{ verticalAlign: 'middle' }}
                        data={monthlyCountSeries.toArray()}
                        svgWidth={60}
                        svgHeight={30}
                        min={0}
                      >
                        <SparklinesLine
                          style={{ strokeWidth: 3, stroke: '#458a6a', fill: '#E2F3EB', fillOpacity: 0.4 }}
                        />
                        <SparklinesReferenceLine type={'avg'} />
                      </Sparklines>
                    </SparklinesWrapper>
                  </StyledTableCell>
                  <StyledTableCell>
                    <MobileOnlyLabel>{targetMonthLabel}</MobileOnlyLabel>
                    <NumberValue>{convertNumberToText(targetMonthCount)}</NumberValue>
                  </StyledTableCell>
                  <StyledTableCell>
                    <MobileOnlyLabel>前月差</MobileOnlyLabel>
                    <Diff value={momDiff}>{convertNumberToText(momDiff)}</Diff>
                  </StyledTableCell>
                  <StyledTableCell>
                    <MobileOnlyLabel>前月比</MobileOnlyLabel>
                    <Diff value={momGrowthRatio}>{convertRateToText(momGrowthRatio)}</Diff>
                  </StyledTableCell>
                  <StyledTableCell>
                    <MobileOnlyLabel>3ヶ月前比</MobileOnlyLabel>
                    <Diff value={threeMomGrowthRatio}>{convertRateToText(threeMomGrowthRatio)}</Diff>
                  </StyledTableCell>
                  <StyledTableCell>
                    <MobileOnlyLabel>前年同月比</MobileOnlyLabel>
                    <Diff value={yoyGrowthRatio}>{convertRateToText(yoyGrowthRatio)}</Diff>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </StyledTable>
        {/* 店舗が0件かつ、読み込み中でない場合のメッセージ */}
        {tableData?.items.size === 0 && !isLoading && (
          <Message>
            <MessageTitle>条件に一致する店舗が見つかりませんでした</MessageTitle>
            <MessageDetail>条件を変更して検索してください</MessageDetail>
          </Message>
        )}
        {/* 店舗が0件かつ、読み込み中の場合にLoader表示用のスペースを追加 */}
        {showLoaderContainer && <LoaderContainer />}
        {isLoading && (
          <LoadingWrapper>
            <StyledLoader active={isLoading} size='big' inline={true} />
          </LoadingWrapper>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
`;

const StyledTable = styled(Table)`
  &&& {
    border-collapse: separate;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
`;

/** グラフの表示・非表示のヘッダセル */
const CheckBoxTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    // モバイル幅では左寄せ
    @media (max-width: ${SIZE.MOBILE_TABLE}) {
      text-align: left;
    }
  }
`;

/** 合計行 */
const SummaryTableRow = styled(TableRow)`
  &&& {
    background: #f9fafb;
    ${TableCell} {
      border-bottom: 1px solid ${COLOR.LIGHT_GRAY};
    }
  }
`;

/** 行ホバー時に表示する他機能への動線アイコン */
const StatsIcon = styled(Icon).attrs({ type: 'assessment' })`
  width: 16px;
  height: 16px;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    font-weight: bold;
    @media (min-width: ${SIZE.MOBILE_TABLE}) {
      text-align: right;
    }
  }
`;

const StyledTableRow = styled(TableRow)`
  ${StatsIcon} {
    visibility: hidden;
  }

  :hover {
    ${StatsIcon} {
      visibility: visible;
    }
  }
`;

/** 店舗ごとデータのグラフの表示・非表示のセル */
const CheckBoxTableCell = styled(TableCell)`
  &&& {
    text-align: center;

    // モバイル幅では左寄せ
    @media (max-width: ${SIZE.MOBILE_TABLE}) {
      text-align: left;
    }
  }
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

/** 店舗が0件で読み込み中でない場合の表示 */
const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 200px;
`;

const MessageTitle = styled.div`
  font-weight: bold;
  color: ${COLOR.BLACK};
`;

const MessageDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MobileOnlyLabel = styled.div`
  display: inline-block;
  width: 100%;
  font-weight: bold;

  @media (min-width: ${SIZE.MOBILE_TABLE}) {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  height: 200px;
`;

const StyledLoader = styled(Loader)`
  &&&& {
    &:before {
      border-color: rgba(0, 0, 0, 0.1);
    }
    &:after {
      border-color: #767676 transparent transparent;
    }
  }
`;

const StoreFlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  @media (min-width: ${SIZE.MOBILE_TABLE}) {
    justify-content: flex-end;
  }
`;

const NumberValue = styled.span`
  font-family: monospace;
  font-weight: normal;
`;

// valueが0より大きければ「↑」をつけて緑色、0より小さければ「↓」をつけて赤色、変化がなければ灰色で表示する
const Diff = styled(NumberValue).attrs<{ value: number | null }, { isUp: boolean; isDown: boolean }>(({ value }) => ({
  isUp: value != null && value > 0,
  isDown: value != null && value < 0,
}))<{
  value: number | null;
}>`
  color: ${({ isUp, isDown }) => (isUp ? COLOR.GREEN : isDown ? COLOR.RED : COLOR.DARK_GRAY)};
  &:before {
    content: '${({ isUp, isDown }) => (isUp ? '↑' : isDown ? '↓' : '')}';
    margin-right: 4px;
  }
`;

const SparklinesWrapper = styled.div`
  display: inline-block;
  width: 60px;
  margin-left: 8px;
`;
