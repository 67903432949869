import demo from './env/demo';
import development from './env/development';
import local from './env/local';
import production from './env/production';
import staging from './env/staging';

const ENVIRONMENT = process.env.REACT_APP_CONFIG_ENV || 'development';

let config;
if (process.env.REACT_APP_CONFIG_ENV === 'production') {
  config = production;
} else if (process.env.REACT_APP_CONFIG_ENV === 'staging') {
  config = staging;
} else if (process.env.REACT_APP_CONFIG_ENV === 'demo') {
  config = demo;
} else if (process.env.REACT_APP_CONFIG_ENV === 'local') {
  config = local;
} else {
  config = development;
}

// デフォルトはdevelopmentで、指定された環境でconfigをmergeする
export default {
  ENVIRONMENT,
  ...config,
};
