import React from 'react';

import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Link } from 'components/atoms/Link';
import { GmbLocationDiffs } from 'models/Domain/GmbLocationDiffs';
import { Path } from 'routes';
import { COLOR } from 'style/color';

/** 店舗情報にGoogleビジネスプロフィールとの差分があることを示し、ユーザに確認を促す */
export const GmbLocationDiffsAlert: React.FC<{ gmbLocationDiffs: GmbLocationDiffs }> = ({ gmbLocationDiffs }) => {
  const groupStoreCount = gmbLocationDiffs.diffStoreCountsByGroup;
  return (
    <Alert type='caution'>
      <Alert.Title>
        Googleビジネスプロフィールと差分が発生しています。
        <br />
        <ConfirmationLink to={Path.gmb.locationUpdates}>こちら</ConfirmationLink>{' '}
        から確認して変更の要否を決定してください。
      </Alert.Title>
      <Alert.Section>
        <Alert.Header>差分内容</Alert.Header>
        <Alert.Content>
          <Content>
            対象店舗: <Value>{gmbLocationDiffs.numberOfStores}</Value>店舗
          </Content>
          <Content>
            (
            {groupStoreCount.has('基本情報') && (
              <Detail>
                基本情報: <Value>{groupStoreCount.get('基本情報')}</Value>店舗
              </Detail>
            )}
            {groupStoreCount.has('営業情報') && (
              <Detail>
                営業情報: <Value>{groupStoreCount.get('営業情報')}</Value>店舗
              </Detail>
            )}
            {groupStoreCount.has('属性情報') && (
              <Detail>
                属性情報: <Value>{groupStoreCount.get('属性情報')}</Value>店舗
              </Detail>
            )}
            {groupStoreCount.has('サービス情報') && (
              <Detail>
                サービス情報: <Value>{groupStoreCount.get('サービス情報')}</Value>店舗
              </Detail>
            )}
            )
          </Content>
        </Alert.Content>
      </Alert.Section>
    </Alert>
  );
};

const ConfirmationLink = styled(Link)`
  color: ${COLOR.GREEN};
  vertical-align: baseline;
  text-decoration: underline;
  &:hover {
    color: ${COLOR.GREEN};
    text-decoration: underline;
  }
`;

const Content = styled.span`
  & + & {
    margin-left: 8px;
  }
`;

const Detail = styled(Content)``;

const Value = styled.span`
  font-weight: bold;
`;
