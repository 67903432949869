import React, { useCallback } from 'react';

import { List as ImmutableList } from 'immutable';
import { Dropdown, Label, Popup, Icon as SemanticIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { InstagramAccount, InstagramPostTarget, PERMALINK_ACTION_URL } from 'models/Domain/instagram';
import { COLOR } from 'style/color';

// 投稿対象の店舗名が多い場合に、省略する閾値
const DISPLAY_STORE_NAMES_COUNT = 3;

type Props = {
  account: InstagramAccount;
  stores: Stores;
  storeLists: StoreLists;
  onClone: (index: number) => void;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  onChangeOrder: (index: number) => void;
};

export const InstagramPostTargetTable = React.memo<Props>(
  ({ account, stores, storeLists, onEdit, onClone, onDelete, onChangeOrder }) => {
    // 投稿対象の店舗列の表示文言の生成
    const targetStoresLabel = useCallback(
      (target: InstagramPostTarget) => {
        const groupIdsCount = target.groupIds?.size ?? 0;
        const storeIdsCount = target.storeIds?.size ?? 0;

        // グループが選択されている場合、グループ名
        if (groupIdsCount > 0 && storeIdsCount === 0) {
          return (
            <>
              <GroupLabel circular size={'small'}>
                グループ
              </GroupLabel>
              {target.groupIds
                ?.map((groupId) => storeLists.findStoreList(groupId)?.name)
                .filter((group) => group)
                .join('、')}
            </>
          );
        }
        // 店舗が選択されている場合、店舗名 (4店舗目以降省略)
        if (storeIdsCount > DISPLAY_STORE_NAMES_COUNT) {
          const storeNames = target.storeIds?.map((storeId) => stores.findStore(storeId)?.fullName) ?? ImmutableList();
          return (
            <div>
              {storeNames.slice(0, DISPLAY_STORE_NAMES_COUNT).join('、')}{' '}
              <Popup
                content={
                  <PopupContent>
                    {storeNames.map((name, index) => (
                      <div key={index}>{name}</div>
                    ))}
                  </PopupContent>
                }
                trigger={<PopupTrigger>{`他 ${storeIdsCount - DISPLAY_STORE_NAMES_COUNT} 店舗`}</PopupTrigger>}
              />
            </div>
          );
        }
        if (storeIdsCount > 0) {
          return target.storeIds
            ?.map((storeId) => stores.findStore(storeId)?.fullName)
            .filter((store) => store)
            .join('、');
        }
        // グループも店舗も選択されていない場合、全店舗
        return '全ての店舗';
      },
      [storeLists, stores],
    );

    const targetKeywordRuleLabel = useCallback((target: InstagramPostTarget) => {
      if (target.matchWords.isEmpty() && target.excludeWords.isEmpty()) {
        return <div>全ての投稿</div>;
      }
      return (
        <div>
          {target.matchWords.size > 0 && (
            <div>{`${target.matchWords.map((word) => `「${word}」`).join('')}を含む`}</div>
          )}
          {target.excludeWords.size > 0 && (
            <div>{`${target.excludeWords.map((word) => `「${word}」`).join('')}を含まない`}</div>
          )}
        </div>
      );
    }, []);

    const targetUrlLabel = useCallback((target: InstagramPostTarget) => {
      if (target.actionType === 'ACTION_TYPE_UNSPECIFIED' || target.actionUrl == null) {
        return <div>なし</div>;
      }
      if (target.actionUrl === PERMALINK_ACTION_URL) {
        return <div>投稿のURL</div>;
      }
      return (
        <div>
          <A href={target.actionUrl} target={'_blank'}>
            {target.actionUrl}
          </A>
        </div>
      );
    }, []);

    const showChangeOrderButton = account.postRule ? account.postRule.targets.size > 1 : false;

    return (
      <Wrapper showChangeOrderButton={showChangeOrderButton}>
        <StyledTable>
          <TableHeader>
            <TableHeaderRow>
              <SortHeaderCell />
              <StyledTableHeaderCell></StyledTableHeaderCell>
              <StyledTableHeaderCell>店舗・グループ</StyledTableHeaderCell>
              <StyledTableHeaderCell>条件</StyledTableHeaderCell>
              <StyledTableHeaderCell>URL</StyledTableHeaderCell>
              <MenuHeaderCell />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {account.postRule?.targets.map((target, index) => (
              <TableRow key={index}>
                <SortCell>
                  {index > 0 && (
                    <ChangeOrderButtonWrapper>
                      <ChangeOrderButton onClick={() => onChangeOrder(index)}>
                        <ChangeOrderIcon name={'exchange'} />
                      </ChangeOrderButton>
                    </ChangeOrderButtonWrapper>
                  )}
                </SortCell>
                <IndexTableCell>{`条件${index + 1}`}</IndexTableCell>
                <StyledTableCell>{targetStoresLabel(target)}</StyledTableCell>
                <StyledTableCell>{targetKeywordRuleLabel(target)}</StyledTableCell>
                <StyledTableCell>{targetUrlLabel(target)}</StyledTableCell>
                <MenuCell>
                  <Dropdown trigger={<SettingsIcon />} icon={null}>
                    <Dropdown.Menu direction={'left'}>
                      <ActionMenuItem onClick={() => onClone(index)}>
                        <ActionItemLabel>複製</ActionItemLabel>
                      </ActionMenuItem>
                      <ActionMenuItem onClick={() => onEdit(index)}>
                        <ActionItemLabel>編集</ActionItemLabel>
                      </ActionMenuItem>
                      <ActionMenuItem onClick={() => onDelete(index)}>
                        <ActionItemLabel>削除</ActionItemLabel>
                      </ActionMenuItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </MenuCell>
              </TableRow>
            ))}
            {!account.hasPostTarget && (
              <TableRow>
                <EmptyTableCell colSpan={4}>
                  投稿対象が設定されていません。
                  <br />
                  「投稿対象を追加」から投稿対象の店舗を追加してください。
                </EmptyTableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
        <Description>同時に複数の条件を満たす場合、上の条件で投稿されます</Description>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ showChangeOrderButton: boolean }>`
  margin-left: ${({ showChangeOrderButton }) => (showChangeOrderButton ? '24px' : '0')};
`;

const StyledTable = styled(Table)``;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    border-right: 1px solid ${COLOR.TABLE_BORDER};
    text-align: left;
  }
`;

const SortHeaderCell = styled(TableHeaderCell)`
  &&& {
    width: 0;
    max-width: 0;
    padding: 0;
  }
`;

const MenuHeaderCell = styled(TableHeaderCell)`
  width: 0;
`;

const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${COLOR.TABLE_BORDER};
  word-break: break-all;
  max-width: 200px;
`;

const GroupLabel = styled(Label)`
  &&& {
    margin-right: 4px;
  }
`;

const SortCell = styled(TableCell)`
  &&& {
    position: relative;
    width: 0;
    max-width: 0;
    padding: 0;
  }
`;

const MenuCell = styled(TableCell)`
  max-width: 32px;
  place-items: center;
`;

export const SettingsIcon = styled(Icon).attrs({
  type: 'settings',
})`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  margin-bottom: -4px;
`;

export const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

export const ActionItemLabel = styled.div<{ disabled?: boolean }>`
  padding: 4px 8px;
  font-size: 14px;
  color: ${({ disabled = false }) => (disabled ? COLOR.GRAY : COLOR.BLACK)};
`;

const EmptyTableCell = styled(TableCell)`
  &&& {
    color: gray;
    font-size: 13px;
    text-align: center;
    background-color: ${COLOR.WHITE};
  }
`;

const ChangeOrderButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.CHARCOAL_GRAY};
  position: absolute;
  top: -7px;
  left: -32px;
`;

const ChangeOrderButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    color: ${COLOR.CHARCOAL_GRAY};
  }
`;

const ChangeOrderIcon = styled(SemanticIcon)`
  &&& {
    line-height: 1;
    height: auto;
    transform: rotate(90deg);
  }
`;

const A = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const PopupTrigger = styled.span`
  display: inline-block;
  margin-left: 8px;
  text-decoration: underline;
  font-size: 12px;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;

const IndexTableCell = styled(StyledTableCell)`
  &&& {
    width: 72px;
  }
`;

const Description = styled.div`
  color: ${COLOR.DARK_GRAY};
  margin-top: 4px;
`;
