import { Record } from 'immutable';

import { JSObject } from 'types/Common';

interface LocationStateRecord {
  can_use_local_post: boolean;
  can_have_food_menus: boolean;
  gmb_location_is_suspended: boolean;
  gmb_location_is_duplicate: boolean;
  gmb_location_is_verified: boolean;
  gmb_location_is_exists: boolean;
  gmb_address_patch_failed: boolean;
}

/**
 * Googleビジネスプロフィールのロケーション情報の状態を保持
 * フロントから値を変更することはない
 */
export class LocationState extends Record<LocationStateRecord>({
  can_use_local_post: true,
  can_have_food_menus: false,
  gmb_location_is_suspended: false,
  gmb_location_is_duplicate: false,
  gmb_location_is_verified: true,
  gmb_location_is_exists: true,
  gmb_address_patch_failed: false,
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    return new LocationState(params);
  }

  get isGmbError() {
    return (
      !this.can_use_local_post ||
      this.gmb_location_is_suspended ||
      this.gmb_location_is_duplicate ||
      !this.gmb_location_is_verified ||
      !this.gmb_location_is_exists
    );
  }

  get canApplyAddressToGmb() {
    return !this.gmb_address_patch_failed;
  }

  get errorLabels() {
    const result = [];
    if (this.gmb_location_is_suspended) {
      result.push('停止中である');
    }
    if (this.gmb_location_is_duplicate) {
      result.push('重複している');
    }
    if (!this.gmb_location_is_verified) {
      result.push('オーナー確認が未完了である');
    }
    if (!this.gmb_location_is_exists) {
      result.push('見つからない');
    }
    return result;
  }
}
