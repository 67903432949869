import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Map } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Arrow } from 'components/atoms/Arrow';
import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { Prompt } from 'components/atoms/Prompt';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { Paging } from 'components/molecules/Paging';
import { SwitchContent } from 'components/molecules/SwitchContent';
import { InventoryFilter } from 'components/pageComponents/InventoryList/InventoryFilter';
import { InventoryListFooter } from 'components/pageComponents/InventoryList/InventoryListFooter';
import { InventoryTable } from 'components/pageComponents/InventoryList/InventoryTable';
import { ProductSearchConditionSummary } from 'components/pageComponents/InventoryList/ProductSearchConditionSummary';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { InventoryHelp } from 'helpers/ContextHelp';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useDisplayType } from 'hooks/useDisplayType';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useWindowSize } from 'hooks/useWindowSize';
import { Inventory } from 'models/Domain/Omo/Inventory';
import { ProductSearchCondition } from 'models/Domain/Omo/ProductSearchCondition';
import { InventoryActions } from 'modules/inventory/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

// 検索条件とテーブル以外の要素のだいたいの高さ
const HEIGHT_EXCLUDING_STICKY_ELEMENTS = 282;
// 検索条件とテーブルを固定表示する最小のテーブル領域の高さ
const MINIMUM_TABLE_HEIGHT = 400;

type Props = RouteComponentProps<{ storeId: string }>;

// 未保存の変更がある場合に、他のページに遷移しようとした場合のメッセージ
const promptMessage = 'ページから離れようとしています。\n保存されていない内容は失われてしまいますが、よろしいですか？';

/**
 * 店頭在庫一覧を表示するコンポーネント
 */
export const InventoryList = React.memo<Props>(
  ({
    match: {
      params: { storeId: storeIdStr = '' },
    },
  }) => {
    const storeId: number = parseInt(storeIdStr, 10);

    const dispatch = useDispatch();
    const {
      initializeInventoryList,
      setProductSearchCondition,
      setProductGroups,
      setEditedInventories,
      updateInventories,
      bulkUpdateInventories,
      changeInventory,
      addEditedInventory,
      removeEditedInventory,
      setIsUpdateExpirationExecuted,
    } = useMemo(() => bindActionCreators(InventoryActions, dispatch), [dispatch]);

    useEffect(() => {
      initializeInventoryList(storeId);
    }, [initializeInventoryList, storeId]);

    const currentUser = useSelector((state) => state.app.currentUser);
    const isLoadingInventorySummary = useSelector((state) => state.inventory.isLoadingInventorySummary);
    const isLoadingProducts = useSelector((state) => state.inventory.isLoadingProducts);
    // 在庫数のサマリー
    const inventorySummary = useSelector((state) => state.inventory.inventorySummary);
    // 商品グループごとに集約された商品情報
    const productGroups = useSelector((state) => state.inventory.productGroups);
    const committedProductGroups = useSelector((state) => state.inventory.committedProductGroups);
    const editedInventories = useSelector((state) => state.inventory.editedInventories);
    const storeSearchCondition = useSelector((state) => state.inventory.storeSearchCondition);
    const stores = useSelector((state) => state.store.stores);
    const selectedStore = stores.findStore(storeId);
    const hasError = editedInventories.some((inventory) => !inventory.isValid);

    // 表示中の店舗が店舗スタッフなら所属店舗、SVなら管理店舗に含まれていれば、編集可能
    const myStores = currentUser.isMemberUser
      ? currentUser.stores
      : currentUser.isSvUser
        ? currentUser.managing_stores
        : stores.list.map((store) => store.id);
    const isEditable = myStores.includes(storeId);

    const windowSize = useWindowSize();
    const stickyWrapperRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState(0);
    const handleResize = (entry: ResizeObserverEntry) => {
      // 検索コンポーネントなどの領域の高さ(可変)
      const clientHeight = entry.target.clientHeight;
      // テーブルの高さ(画面の高さから、ヘッダーや検索コンポーネントなどの高さを引いた値)
      const tableHeight = windowSize.height - clientHeight - HEIGHT_EXCLUDING_STICKY_ELEMENTS;
      setTableHeight(tableHeight);
    };
    useResizeObserver(stickyWrapperRef, handleResize);

    const onClickBackButton = useCallback(() => {
      dispatch(replaceWithOrganizationId(`${Path.omo.inventoryStoreList}?${storeSearchCondition.toURLSearchParams()}`));
    }, [dispatch, storeSearchCondition]);
    // 検索条件
    const searchCondition = useSelector((state) => state.inventory.productSearchCondition);
    // 反映済みの検索条件
    const committedSearchCondition = useSelector((state) => state.inventory.committedProductSearchCondition);

    // 有効期限の延長が実施されたか
    const isUpdateExpirationExecuted = useSelector((state) => state.inventory.isUpdateExpirationExecuted);
    // 「有効期限を一括で延長」をクリックできるかどうか
    const isDisabledBulkUpdateButton = useMemo(() => {
      if (isUpdateExpirationExecuted) {
        return true;
      }
      const availabilities = committedSearchCondition.filter.getAvailabilityTypes(currentUser.canUseOnDisplayToOrder);
      if (availabilities !== null && availabilities.size === 1 && availabilities.includes('null')) {
        return true;
      }
      return false;
    }, [currentUser.canUseOnDisplayToOrder, isUpdateExpirationExecuted, committedSearchCondition.filter]);

    // 検索条件とテーブルヘッダーを固定するか
    const enableStick = tableHeight >= MINIMUM_TABLE_HEIGHT;

    /**
     * 検索条件を変更する（検索するまで検索結果には反映されない）
     */
    const onChangeSearchCondition = useCallback(
      (searchCondition: ProductSearchCondition) => {
        // 絞り込み条件を変更したらページを1に戻す
        const updatedCondition = searchCondition.setPage(1);
        setProductSearchCondition({ searchCondition: updatedCondition, updateLocation: false });
      },
      [setProductSearchCondition],
    );

    /**
     * 検索条件を確定する（検索結果に反映する）
     */
    const onCommitSearchCondition = useCallback(() => {
      // 検索条件が変更され確定した場合、期限延長実行済みステータスを未実行状態にリセットする
      setIsUpdateExpirationExecuted(false);

      setProductSearchCondition({ searchCondition: searchCondition.setPage(1), updateLocation: true });
    }, [searchCondition, setIsUpdateExpirationExecuted, setProductSearchCondition]);

    /**
     * 有効期限ごとの商品数の数値部分がクリックされた
     * その有効期限の商品のみ検索する
     * @param expiration 有効期限ステータス
     */
    const onClickExpirationCount = useCallback(
      (expiration: 'valid' | 'expiring_soon' | 'expired') => {
        if (isLoadingProducts) {
          return; // 検索に負荷がかかるため検索の実行中は新しい検索を行わない
        }
        // 反映済みの検索条件から変更する
        setProductSearchCondition({
          searchCondition: committedSearchCondition
            .mergeIn(['filter', 'expiration'], {
              valid: expiration === 'valid',
              expiring_soon: expiration === 'expiring_soon',
              expired: expiration === 'expired',
            })
            .setIn(['pagination', 'page'], 1),
          updateLocation: true,
        });
      },
      [isLoadingProducts, setProductSearchCondition, committedSearchCondition],
    );

    /**
     * 「在庫情報のない商品を含めて検索する」ボタンが押された
     * 在庫情報の「データなし」をチェックして再検索を行う
     */
    const onClickShowAvailabilityNull = useCallback(() => {
      setProductSearchCondition({
        searchCondition: committedSearchCondition.update('filter', (filter) => filter.setAvailability('null', true)),
        updateLocation: true,
      });
    }, [setProductSearchCondition, committedSearchCondition]);

    // 反映済みの検索結果のページネーション情報
    const { pagination } = committedSearchCondition;

    /**
     * ページ条件を変更する
     */
    const changePage = useCallback(
      (page: number) => {
        // ページ条件の変更は確定済み検索条件から変更する
        const updatedCondition = committedSearchCondition.setPage(page);
        setProductSearchCondition({ searchCondition: updatedCondition, updateLocation: true });
        // 表示位置をページの一番上に
        window.scrollTo({ top: 0 });
      },
      [committedSearchCondition, setProductSearchCondition],
    );

    /**
     * 商品の在庫情報を変更する
     */
    const onChangeInventory = useCallback(
      (groupIndex: number, productIndex: number, inventory: Inventory) => {
        changeInventory({ groupIndex, productIndex, storeId, inventory });
      },
      [changeInventory, storeId],
    );

    const onClickBulkUpdateButton = useCallback(() => {
      const confirmMessage =
        editedInventories.size > 0
          ? '条件に一致するすべての在庫情報の有効期限を延長します。\n保存されていない内容は失われてしまいますが、よろしいですか？'
          : '検索条件に一致するすべての在庫情報の有効期限を延長してよろしいですか？';
      if (window.confirm(confirmMessage)) {
        bulkUpdateInventories(storeId);
      }
    }, [bulkUpdateInventories, editedInventories, storeId]);

    const saveProductGroups = useCallback(() => {
      updateInventories(storeId);
    }, [storeId, updateInventories]);
    const resetProductGroups = useCallback(() => {
      setProductGroups(committedProductGroups);
      setEditedInventories(Map<string, Inventory>());
    }, [committedProductGroups, setEditedInventories, setProductGroups]);

    const showAvailabilityNull = committedSearchCondition.filter.availability.get('null') ?? true;

    // SP表示に切り替わる横幅600px+メニューの横幅320pxで判定
    const displayType = useDisplayType('920px');

    return (
      <Wrapper>
        <Helmet title={getPageTitle(`${selectedStore ? `${selectedStore.fullName}の` : ''}店頭在庫`)} />
        <StickyHeader>
          <TitleContainer>
            <BackButton onClick={onClickBackButton}>
              <Arrow direction={'left'} color={'#393939'} length={16} weight={3} />
              <Title>{selectedStore?.fullName ?? '在庫一覧'}</Title>
            </BackButton>
          </TitleContainer>
          <Link to={Path.omo.inventoryStoreImport.replace(':storeId', String(storeId))}>
            <StyledButton>CSVでまとめて更新</StyledButton>
          </Link>
        </StickyHeader>
        <Body>
          {displayType.isMobile ? (
            <AlertWrapper>
              <Alert type={'info'}>
                <Alert.Title>このページはPCでの利用を推奨しております</Alert.Title>
                <Alert.Section>
                  <Alert.Content>
                    スマートフォンやタブレットなど狭い画面では正しく表示されない場合がありますのでご了承ください。
                  </Alert.Content>
                </Alert.Section>
              </Alert>
            </AlertWrapper>
          ) : (
            <ProductCounts>
              {/* 有効期限別の商品数 */}
              <ProductCount>
                <ProductCountTitle>
                  有効な商品数
                  <ContextHelpWrapper>
                    <ContextHelp content={InventoryHelp.valid} />
                  </ContextHelpWrapper>
                </ProductCountTitle>
                <Count onClick={() => onClickExpirationCount('valid')}>
                  {isLoadingInventorySummary ? 'ー' : inventorySummary.counts.valid.toLocaleString()}
                </Count>
              </ProductCount>
              <ProductCount>
                <ProductCountTitle>
                  期限切れ間近
                  <ContextHelpWrapper>
                    <ContextHelp content={InventoryHelp.expiringSoon} />
                  </ContextHelpWrapper>
                </ProductCountTitle>
                <Count onClick={() => onClickExpirationCount('expiring_soon')}>
                  {isLoadingInventorySummary ? 'ー' : inventorySummary.counts.expiringSoon.toLocaleString()}
                </Count>
              </ProductCount>
              <ProductCount>
                <ProductCountTitle>
                  有効期限切れ
                  <ContextHelpWrapper>
                    <ContextHelp content={InventoryHelp.expired} />
                  </ContextHelpWrapper>
                </ProductCountTitle>
                <Count onClick={() => onClickExpirationCount('expired')}>
                  {isLoadingInventorySummary ? 'ー' : inventorySummary.counts.expired.toLocaleString()}
                </Count>
              </ProductCount>
            </ProductCounts>
          )}
          {/* 商品の検索 */}
          <StickyWrapper ref={stickyWrapperRef} sticky={enableStick}>
            <SwitchContent
              openLabel='絞り込み条件を閉じる'
              closedLabel='絞り込み条件を変更する'
              closedContents={
                <ProductSearchConditionSummary
                  searchCondition={committedSearchCondition}
                  canUseOnDisplayToOrder={currentUser.canUseOnDisplayToOrder}
                />
              }
            >
              <InventoryFilter
                searchCondition={searchCondition}
                canUseOnDisplayToOrder={currentUser.canUseOnDisplayToOrder}
                isLoading={isLoadingProducts}
                onChangeSearchCondition={onChangeSearchCondition}
                onCommitSearchCondition={onCommitSearchCondition}
              />
            </SwitchContent>
            {isLoadingProducts || (
              <InventoryTableHeader>
                {/* 商品グループ件数表示 */}
                <InventoryCount>
                  {productGroups.pagination.total_count.toLocaleString()}件の検索結果
                  {productGroups.pagination.total_count > 0 && (
                    <>
                      （
                      {Math.min(
                        pagination.per_page * (pagination.page - 1) + 1,
                        productGroups.pagination.total_count,
                      ).toLocaleString()}
                      〜
                      {Math.min(
                        pagination.per_page * pagination.page,
                        productGroups.pagination.total_count,
                      ).toLocaleString()}
                      件目を表示中）
                    </>
                  )}
                </InventoryCount>
                {!isEditable || productGroups.items.isEmpty() || (
                  <BulkUpdateButton onClick={onClickBulkUpdateButton} disabled={isDisabledBulkUpdateButton}>
                    有効期限を一括で延長
                  </BulkUpdateButton>
                )}
              </InventoryTableHeader>
            )}
          </StickyWrapper>
          {isLoadingProducts ? (
            <Content>
              <LoadingWrapper>
                <Loader active={true} size={'big'} inline={true} />
              </LoadingWrapper>
            </Content>
          ) : (
            <Content>
              {/* 在庫一覧 */}
              {productGroups.pagination.total_count === 0 ? (
                <NoProductFound>
                  <NoProductFoundTitle>条件に一致する商品が見つかりませんでした</NoProductFoundTitle>
                  <NoProductFoundDetail>
                    <NoProductFoundText>
                      {showAvailabilityNull
                        ? '条件を変更して検索してください'
                        : '在庫情報を追加するには、在庫情報のない商品を含めて検索してください'}
                    </NoProductFoundText>
                    {showAvailabilityNull || (
                      <ShowNotOnlyInStock onClick={onClickShowAvailabilityNull}>
                        在庫情報のない商品を含めて検索する
                      </ShowNotOnlyInStock>
                    )}
                  </NoProductFoundDetail>
                </NoProductFound>
              ) : (
                <StyledInventoryTable
                  storeId={storeId}
                  isEditable={isEditable}
                  productGroups={productGroups}
                  committedProductGroups={committedProductGroups}
                  changeInventory={onChangeInventory}
                  addEditedInventory={addEditedInventory}
                  removeEditedInventory={removeEditedInventory}
                  canUseOnDisplayToOrder={currentUser.canUseOnDisplayToOrder}
                  tableHeight={enableStick ? `${tableHeight}px` : 'auto'}
                  editedInventories={editedInventories}
                />
              )}

              {/* ページネーション */}
              <PagingWrapper>
                <Paging
                  currentPage={committedSearchCondition.pagination.page}
                  viewContentSize={committedSearchCondition.pagination.per_page}
                  totalContentSize={productGroups.pagination.total_count}
                  onChangeNo={changePage}
                />
              </PagingWrapper>
            </Content>
          )}
          {isEditable && editedInventories.size > 0 && !isLoadingProducts && (
            <InventoryListFooter
              editedProducts={editedInventories.size}
              onSave={saveProductGroups}
              onCancel={resetProductGroups}
              hasError={hasError}
            />
          )}
        </Body>
        <Prompt when={editedInventories.size > 0} message={promptMessage} />
      </Wrapper>
    );
  },
);

const Wrapper = styled(MainWrapper)`
  padding: 0;
`;

const Body = styled(WideBody)`
  min-height: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    font-size: 14px;
  }
`;

// 有効期限別の商品数
const ProductCounts = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

// 有効期限別の商品数の単一の項目表示部分
const ProductCount = styled.div`
  padding: 0 22px;

  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: solid 1px ${COLOR.CHARCOAL_GRAY};
  }
`;

// 有効期限別の商品数の単一の項目のタイトル部分
const ProductCountTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const ContextHelpWrapper = styled.div`
  /* ツールチップのdivにもalign-items: centerをかける（うまく上下中央にならなかったため） */
  div {
    display: flex;
    align-items: center;
  }
`;

// 有効期限別の商品数の単一の項目の数値部分
const Count = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

const StickyWrapper = styled.div<{ sticky: boolean }>`
  background: ${COLOR.BACKGROUND};
  padding-top: 16px;
  margin-top: -16px; /* stickyのための余白の相殺 */
  position: ${({ sticky }) => (sticky ? 'sticky' : 'block')};
  top: 72px; /* headerの高さ */
  z-index: 20; /* テーブルヘッダー(15)より上に表示するように設定 */
`;

// 検索結果の件数表示
const InventoryCount = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: ${COLOR.CHARCOAL_GRAY};
`;

// 条件に一致する商品が見つからなかった場合の表示
const NoProductFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const NoProductFoundDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

// 条件に一致する商品が見つからなかった場合の表示のタイトル
const NoProductFoundTitle = styled.div`
  font-weight: bold;
  color: ${COLOR.BLACK};
`;

// 条件に一致する商品が見つからなかった場合の表示のテキスト
const NoProductFoundText = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${COLOR.DARK_GRAY};
`;

// 条件に一致する商品が見つからなかった場合に在庫情報のない商品を表示するボタン
const ShowNotOnlyInStock = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

// ページネーション部分
const PagingWrapper = styled.div`
  margin-top: 30px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
`;

const Content = styled.div`
  position: relative;
`;

const InventoryTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  position: sticky;
  top: 0;
`;

const BulkUpdateButton = styled(Button).attrs(() => ({ priority: 'low' }))`
  &&& {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    width: auto;
    padding: 0;
    height: 24px;
  }
`;

const AlertWrapper = styled.div`
  margin-bottom: 16px;
`;

const StyledInventoryTable = styled(InventoryTable)<{ tableHeight: string }>`
  /* 高さを指定しないとstickyが効かない */
  height: ${({ tableHeight }) => tableHeight};
`;
