import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

export class MapSearchRankConfigValidationErrorItem extends Record<{
  row: number;
  messages: List<string>;
}>({
  row: 0,
  messages: List(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankConfigValidationErrorItem({
      row: data.row,
      messages: List(data.messages),
    });
  }
}

export class MapSearchRankConfigValidationWarningItem extends Record<{
  row: number;
  messages: List<string>;
}>({
  row: 0,
  messages: List(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankConfigValidationWarningItem({
      row: data.row,
      messages: List(data.messages),
    });
  }
}

export class MapSearchRankConfigValidationError extends Record<{
  message: string;
  errors: List<MapSearchRankConfigValidationErrorItem>;
}>({
  message: '',
  errors: List(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankConfigValidationError({
      message: data.message,
      errors: List(data.errors.map((error: JSObject) => MapSearchRankConfigValidationErrorItem.fromJSON(error))),
    });
  }
}

export class MapSearchRankConfigValidationWarning extends Record<{
  message: string;
  warnings: List<MapSearchRankConfigValidationWarningItem>;
}>({
  message: '',
  warnings: List(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankConfigValidationWarning({
      message: data.message,
      warnings: List(
        data.warnings.map((warning: JSObject) => MapSearchRankConfigValidationWarningItem.fromJSON(warning)),
      ),
    });
  }
}

export class MapSearchRankConfigValidationResult extends Record<{
  message: string;
  totalSucceed: number;
  totalFailed: number;
  totalWarning: number;
  validationStatus: 'success' | 'failed';
  validationErrors: MapSearchRankConfigValidationError | null;
  validationWarnings: MapSearchRankConfigValidationWarning | null;
}>({
  message: '',
  totalSucceed: 0,
  totalWarning: 0,
  totalFailed: 0,
  validationStatus: 'success',
  validationErrors: new MapSearchRankConfigValidationError(),
  validationWarnings: new MapSearchRankConfigValidationWarning(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankConfigValidationResult({
      message: data.message,
      totalSucceed: data.total_succeed,
      totalWarning: data.total_warning,
      totalFailed: data.total_failed,
      validationStatus: data.validation_status,
      validationErrors: data.validation_errors
        ? MapSearchRankConfigValidationError.fromJSON(data.validation_errors)
        : null,
      validationWarnings: data.validation_warnings
        ? MapSearchRankConfigValidationWarning.fromJSON(data.validation_warnings)
        : null,
    });
  }

  get isSuccess() {
    return this.validationStatus === 'success';
  }
}
