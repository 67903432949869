import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { DataResetApi } from 'ApiClient/DataResetApi';
import { OrganizationApi } from 'ApiClient/OrganizationApi';
import { replaceWithOrganizationId } from 'helpers/router';
import { User } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

import { OrganizationActions as Actions } from './actions';

export default function* saga() {
  yield takeLatest(Actions.initializePage, initializePage);
  yield takeLatest(Actions.updateOrganization, updateOrganization);
  yield takeLatest(Actions.resetData, resetData);
}

function* initializePage() {
  yield call(waitForUserAndStoresInitialized);

  const currentUser: User = yield select((state: State) => state.app.currentUser);
  const organization = currentUser.organization;

  if (organization) {
    yield put(Actions.setOrganization(organization));
  } else {
    // 取得できない場合はエラーを表示して、トップページに飛ばす
    toast({
      type: 'error',
      title: '組織情報が取得できませんでした',
      time: 10000,
    });
    yield put(replaceWithOrganizationId(Path.top));
  }
}

function* updateOrganization(action: ReturnType<typeof Actions.updateOrganization>) {
  const organization = action.payload;
  if (!organization.id) {
    toast({
      type: 'error',
      title: '組織情報が更新できませんでした',
      description: '画面を再読み込みしてください',
      time: 10000,
    });
    return;
  }
  yield put(Actions.setIsLoading(true));
  const params = organization.toUpdateParams();
  const response: YieldReturn<typeof OrganizationApi.patch> = yield OrganizationApi.patch(organization.id, params);
  if (response.isSuccess) {
    // currentUserの組織情報を最新版に更新する
    yield put(Actions.setOrganization(organization));
    const currentUser: User = yield select((state: State) => state.app.currentUser);
    yield put(AppActions.setCurrentUser(currentUser.changeOrganization(organization)));
    toast({
      type: 'success',
      title: '組織情報の更新が完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: '組織情報が更新できませんでした',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(Actions.setIsLoading(false));
}

function* resetData() {
  const response: YieldReturn<typeof DataResetApi.reset> = yield DataResetApi.reset();
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'データのリセットを開始しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'データのリセットに失敗しました',
      time: 10000,
    });
  }
}
