import styled from 'styled-components';

import { COLOR } from 'style/color';

const _ChartTooltip = styled.div`
  border: 1px solid ${COLOR.DARK_GRAY};
  background: ${COLOR.WHITE};
  padding: 8px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  :not(:last-child) {
    margin-bottom: 2px;
  }
`;

const Title = styled.div<{ color?: string }>`
  font-weight: bold;
  font-size: 12px;
  color: ${({ color }) => color ?? COLOR.BLACK};
  margin-bottom: 2px;
`;

const Label = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? COLOR.BLACK};
  margin-right: 16px;
`;

const Value = styled.div<{ color?: string }>`
  font-weight: bold;
  font-size: 12px;
  /* GlobalStyleで強制されているので、!importantで上書き */
  font-family: monospace !important;
  color: ${({ color }) => color ?? COLOR.BLACK};
`;

type ChartTooltipType = typeof _ChartTooltip & {
  Item: typeof Item;
  Title: typeof Title;
  Label: typeof Label;
  Value: typeof Value;
};

const ChartTooltip: ChartTooltipType = _ChartTooltip as any;
ChartTooltip.Item = Item;
ChartTooltip.Title = Title;
ChartTooltip.Label = Label;
ChartTooltip.Value = Value;

export const ActiveCircle = styled.span<{ color?: string }>`
  margin-right: 4px;
  &:before {
    content: '●';
    color: ${({ color }) => color ?? 'white'};
  }
`;

export { ChartTooltip };
