import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

interface FileContentRecord {
  url: string;
  caption: string;
}

export class FileContent extends Record<FileContentRecord>({
  url: '',
  caption: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get isImage() {
    if (!this.mimeType) {
      return false;
    }
    return Boolean(this.mimeType.match(/^image\//));
  }

  get mimeType() {
    if (!this.url) {
      return undefined;
    }
    const result = this.url.match(/\.\w+$/);
    if (!result) {
      return undefined;
    }
    const extension = result[0].replace('.', '');
    switch (extension) {
      case 'pdf':
        return 'application/pdf';

      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return `image/${extension}`;

      case 'jpg':
        return `image/jpeg`;

      default:
        return undefined;
    }
  }

  get isPDF() {
    if (!this.mimeType) {
      return false;
    }
    return Boolean(this.mimeType === 'application/pdf');
  }

  requestParams() {
    const { url, caption } = this.toObject();
    const params = { url, caption };
    return params;
  }
}

interface FileContentsRecord {
  list: List<FileContent>;
}

export class FileContents extends Record<FileContentsRecord>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data.map((p) => new FileContent(p)));
    super({ list });
  }

  addFiles(urls: string[]) {
    const fileContents = urls.map((url) => new FileContent({ url }));
    return this.update('list', (files) => files.push(...fileContents));
  }

  deleteFile(index: number) {
    return this.update('list', (files) => files.delete(index));
  }

  changeFileCaption(index: number, caption: string) {
    return this.setIn(['list', index, 'caption'], caption);
  }

  requestParams() {
    return this.list.map((file) => file.requestParams()).toArray();
  }

  get pdfFiles() {
    return this.update('list', (list) => list.filter((file) => file.isPDF));
  }

  get imageFiles() {
    return this.update('list', (list) => list.filter((file) => !file.isPDF));
  }
}
