import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { SearchKeyword } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SearchKeywordSearchCondition } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';

import { SearchKeywordActions } from './action';

interface SearchKeywordRecord {
  searchKeyword: SearchKeyword;
  searchCondition: SearchKeywordSearchCondition;
  committedSearchCondition: SearchKeywordSearchCondition;
  isUpdatedExcludedKeywords: boolean;
  isPreparedPage: boolean;
  isInitializedSearchCondition: boolean;
  isLoadingGraphData: boolean;
  isLoadingTableData: boolean;
  isProcessingCsvDownload: boolean;
  csvDownload: ExecutionArnStatus;
}

export class SearchKeywordState extends Record<SearchKeywordRecord>({
  searchKeyword: new SearchKeyword(),
  searchCondition: new SearchKeywordSearchCondition(),
  committedSearchCondition: new SearchKeywordSearchCondition(),
  isUpdatedExcludedKeywords: false,
  isPreparedPage: false,
  isInitializedSearchCondition: false,
  isLoadingGraphData: false,
  isLoadingTableData: false,
  isProcessingCsvDownload: false,
  csvDownload: new ExecutionArnStatus(),
}) {}

export const searchKeywordReducer = reducerWithInitialState(new SearchKeywordState())
  .case(SearchKeywordActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload.searchCondition);
  })
  .case(SearchKeywordActions.setCommittedSearchCondition, (state, payload) => {
    return state.set('committedSearchCondition', payload);
  })
  .case(SearchKeywordActions.setSearchKeywordGraphData, (state, payload) => {
    return state.setIn(['searchKeyword', 'graphData'], payload);
  })
  .case(SearchKeywordActions.setSearchKeywordTableData, (state, payload) => {
    return state.setIn(['searchKeyword', 'tableData'], payload);
  })
  .case(SearchKeywordActions.setIsUpdatedExcludedKeywords, (state, payload) => {
    return state.set('isUpdatedExcludedKeywords', payload);
  })
  .case(SearchKeywordActions.setIsLoadingGraphData, (state, payload) => {
    return state.set('isLoadingGraphData', payload);
  })
  .case(SearchKeywordActions.setIsLoadingTableData, (state, payload) => {
    return state.set('isLoadingTableData', payload);
  })
  .case(SearchKeywordActions.clearSearchKeyword, (state, payload) => {
    return state.set('searchKeyword', new SearchKeyword());
  })
  .case(SearchKeywordActions.setIsPreparedPage, (state, payload) => {
    return state.set('isPreparedPage', payload);
  })
  .case(SearchKeywordActions.setIsProcessingCsvDownload, (state, payload) => {
    return state.set('isProcessingCsvDownload', payload);
  })
  .case(SearchKeywordActions.setCsvDownload, (state, payload) => {
    return state.set('csvDownload', payload);
  })
  .case(SearchKeywordActions.clearCsvDownload, (state, payload) => {
    return state.set('csvDownload', new ExecutionArnStatus());
  });
