import actionCreatorFactory from 'typescript-fsa';

import { OfferActivities } from 'models/Composite/OfferActivity';
import { OfferCommon } from 'models/Composite/OfferCommon';
import { OfferStores } from 'models/Composite/OfferStores';
import { Comment } from 'models/Domain/Comment';
import { OfferImageComment } from 'models/Domain/OfferImageComment';
import { StoreOfferFilter } from 'models/Other/StoreOfferFilter';
import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('Offer');

export const OfferActions = {
  getStoreOffers: actionCreator('getStoreOffers'),
  setStoreOffers: actionCreator<JSObject[]>('setStoreOffers'),
  getOffer: actionCreator<number>('getOffer'),
  setOffer: actionCreator<JSObject>('setOffer'),
  getEditOffer: actionCreator<number>('getEditOffer'),
  setEditOffer: actionCreator<JSObject>('setEditOffer'),
  getOfferTimeline: actionCreator<number>('getOfferTimeline'),
  setOfferTimeline: actionCreator<JSObject[]>('setOfferTimeline'),
  setNewComment: actionCreator<Comment>('setNewComment'),
  sendNewComment: actionCreator('sendNewComment'),
  clearNewComment: actionCreator('clearNewComment'),
  uploadNewCommentFile: actionCreator<File[]>('uploadNewCommentFile'),
  acceptTask: actionCreator('acceptTask'),
  getOfferActivity: actionCreator('getOfferActivity'),
  setOfferActivity: actionCreator<OfferActivities>('setOfferActivity'),
  getNextOfferActivity: actionCreator('getNextOfferActivity'),
  setNextOfferActivity: actionCreator<OfferActivities>('setNextOfferActivity'),
  sendOfferStamp: actionCreator<number>('sendOfferStamp'),
  sendOfferImageComment: actionCreator<OfferImageComment>('sendOfferImageComment'),
  readOffer: actionCreator<number>('readOffer'),
  changeNewOffer: actionCreator<OfferStores>('changeNewOffer'),
  changeEditOffer: actionCreator<OfferCommon>('changeEditOffer'),
  sendNewOffer: actionCreator('sendNewOffer'),
  updateEditOffer: actionCreator('updateEditOffer'),
  clearNewOffer: actionCreator('clearNewOffer'),
  clearEditOffer: actionCreator('clearEditOffer'),
  handleNewOfferAcceptedFiles: actionCreator<File[]>('handleNewOfferAcceptedFiles'),
  handleEditOfferAcceptedFiles: actionCreator<File[]>('handleEditOfferAcceptedFiles'),
  setStoreOfferFilter: actionCreator<StoreOfferFilter>('setStoreOfferFilter'),
  getStoreCompletedOffers: actionCreator('getStoreCompletedOffers'),
  setStoreCompletedOffers: actionCreator<JSObject[]>('setStoreCompletedOffers'),
  getStoreCompletedOfferAddRead: actionCreator<JSObject>('getStoreCompletedOfferAddRead'),
  setStoreCompletedOfferAddRead: actionCreator<JSObject>('setStoreCompletedOfferAddRead'),
};
