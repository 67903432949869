import { useCallback, useEffect } from 'react';

/**
 * ページから離れる前にブラウザの確認ダイアログを表示する
 *
 * IE以外はカスタムメッセージに対応していないため、本フックでもカスタムメッセージには未対応
 * https://developer.mozilla.org/ja/docs/Web/API/Window/beforeunload_event
 *
 * @param confirm 確認ダイアログを表示するかどうか
 */
export const useConfirmLeave = (confirm: boolean) => {
  const handleUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (confirm) {
        e.returnValue = 'confirm';
      }
    },
    [confirm],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload, false);
    return () => {
      window.removeEventListener('beforeunload', handleUnload, false);
    };
  }, [handleUnload]);
};
