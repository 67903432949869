import React, { useCallback } from 'react';

import dayjs from 'dayjs';
import { Set } from 'immutable';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { ErrorLabel } from 'components/atoms/Label';
import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { Stars } from 'components/atoms/Stars';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { FilterStatus, FilterStatusType, StarRating } from 'models/Domain/GmbReview';
import { COLOR } from 'style/color';

type GmbReviewFilterProps = {
  className?: string;
  filter: FilterStatus;
  onChangeFilter: (filter: FilterStatus) => void;
  onCommitFilter?: () => void;
  disableCommitButton?: boolean;
};

export const GmbReviewFilter: React.FC<GmbReviewFilterProps> = ({
  className,
  filter,
  onChangeFilter,
  onCommitFilter,
  disableCommitButton,
}) => {
  // 日付が不正な形式になっていないか
  const isValidDateRange = filter.isValidDateRange();

  /**
   * フィルターを変更する
   * @param key 変更するフィルターのキー
   * @param value 変更するフィルターの値
   */
  const handleChangeFilter = useCallback(
    <T extends keyof FilterStatusType>(key: T, value: FilterStatusType[T]) => {
      const updatedFilter = filter.set(key, value);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * 評価のフィルターを変更する
   * @param key 評価の値
   * @param value チェックon/off
   */
  const handleChangeRating = useCallback(
    (key: StarRating, value: boolean) => {
      const updatedFilter = filter.setRating(key, value);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * グループ、店舗のフィルターを変更する
   * @param group グループ
   * @param storeIds 店舗のID
   * @param isAllStores すべての店舗が選択されているか
   * @param showClosedStores 閉店店舗を表示するか
   */
  const handleChangeGroupStore = useCallback(
    (group: FilterStatus['store'], storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
      const updatedFilter = filter.merge({
        store: group,
        store_ids: storeIds,
        is_all_store_ids: isAllStores,
        show_closed_store: showClosedStores,
      });
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  const handleChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      const updatedFilter = filter
        .set('start_date', startDate ? dayjs(startDate) : null)
        .set('end_date', endDate ? dayjs(endDate) : null);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  return (
    <Card className={className}>
      <FilterWrapper>
        <GroupStoreSelect
          group={filter.store}
          storeIds={filter.store_ids}
          showClosedStores={filter.show_closed_store}
          onChange={handleChangeGroupStore}
        />
        <ContentWrapper>
          <ContentLabel>期間</ContentLabel>
          <StyledDateRangePicker
            startDate={filter.start_date?.toDate() ?? null}
            endDate={filter.end_date?.toDate() ?? null}
            onChange={handleChangeDateRange}
            startPlaceholderText='開始日:未指定'
            endPlaceholderText='終了日:未指定'
            isClearable
          />
          {!isValidDateRange && <ErrorLabel pointing>開始日は終了日より前の日付を指定してください</ErrorLabel>}
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>コメント</ContentLabel>
          <NormalMultiSelectBox value={filter.getCommentLabel()}>
            <SearchContent>
              <CheckLabel
                onChange={() => handleChangeFilter('has_comment', !filter.has_comment)}
                checked={filter.has_comment}
                label='コメントあり'
              />
            </SearchContent>
            <SearchContent>
              <CheckLabel
                onChange={() => handleChangeFilter('no_comment', !filter.no_comment)}
                checked={filter.no_comment}
                label='評価のみ'
              />
            </SearchContent>
          </NormalMultiSelectBox>
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>対応状態</ContentLabel>
          <NormalMultiSelectBox value={filter.getStatusLabel()}>
            <SearchContent>
              <CheckLabel
                onChange={() => handleChangeFilter('done', !filter.done)}
                checked={filter.done}
                label='対応済'
              />
            </SearchContent>
            <SearchContent>
              <CheckLabel
                onChange={() => handleChangeFilter('todo', !filter.todo)}
                checked={filter.todo}
                label='未対応'
              />
            </SearchContent>
            <SearchContent>
              <CheckLabel
                onChange={() => handleChangeFilter('not_replied', !filter.not_replied)}
                checked={filter.not_replied}
                label='対応しない'
              />
            </SearchContent>
          </NormalMultiSelectBox>
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>評価</ContentLabel>
          <NormalMultiSelectBox value={filter.getRatingLabel()}>
            {filter.ratings.map((rating) => (
              <CheckLabel
                onChange={() => handleChangeRating(rating.key, !rating.value)}
                checked={rating.value}
                key={rating.key}
                label={
                  <StarCheckLabelWrapper>
                    <StarCheckLabel checked={rating.value} />星{rating.key}つ
                    <FilterStars count={rating.key} />
                  </StarCheckLabelWrapper>
                }
              />
            ))}
          </NormalMultiSelectBox>
        </ContentWrapper>
        {!disableCommitButton && (
          <SearchButtonWrapper>
            <SearchButton priority='high' disabled={!filter.isValid()} onClick={onCommitFilter}>
              検索
            </SearchButton>
          </SearchButtonWrapper>
        )}
      </FilterWrapper>
    </Card>
  );
};

const FilterWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0px;
  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0px;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

const SearchContent = styled.div`
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const NormalMultiSelectBox = styled(MultiSelectBox)`
  width: 176px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FilterStars = styled(Stars)`
  display: inline-block;
`;

const StarCheckLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StarCheckLabel = styled(Checkbox)`
  &&& {
    font-size: 14px;
    color: #707070;
    margin: 0 8px;
  }
`;

const SearchButtonWrapper = styled(ContentWrapper)`
  margin-left: auto;
  padding-top: 28px;
`;

const SearchButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  width: 380px;
  @media (max-width: 774px) {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 101; /* StickyHeaderより上に表示されるように */
  }

  &&& {
    & input {
      padding-left: 8px;
      text-align: left;
    }
  }
`;
