import React from 'react';

import styled from 'styled-components';

import { useScrollDirection } from 'hooks/useScrollDirection';
import { COLOR } from 'style/color';

import { Arrow } from './Arrow';

export const StickyHeader = React.memo<{ className?: string; title?: string; children?: React.ReactNode }>(
  ({ className, title, children }) => {
    const isUp = useScrollDirection();

    if (title) {
      return (
        <Wrapper className={className} isShowMenu={isUp}>
          <Title>{title}</Title>
        </Wrapper>
      );
    }

    return (
      <Wrapper className={className} isShowMenu={isUp}>
        {children}
      </Wrapper>
    );
  },
);

export const Wrapper = styled.div<{ isShowMenu: boolean }>`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background-color: ${COLOR.LIGHT_GRAY};
  transition: all 0.2s;
  padding: 0 20px;
  @media (max-width: 600px) {
    top: ${(props) => (props.isShowMenu ? '60px' : '0')};
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding-right: 30px;
  white-space: nowrap;
  margin-left: 8px;
  @media (max-width: 600px) {
    font-size: 21px;
  }
`;

export const BackArrow = styled(Arrow).attrs({ direction: 'left', color: COLOR.BLACK, length: 16, weight: 3 })``;
