import React, { useMemo } from 'react';

import styled from 'styled-components';

import { ProductSearchCondition } from 'models/Domain/Omo/ProductSearchCondition';
import { Mixins } from 'style/mixin';

type Props = {
  className?: string;
  searchCondition: ProductSearchCondition;
  canUseOnDisplayToOrder: boolean;
};

/**
 * 店頭在庫一覧の検索条件を表示するコンポーネント
 */
export const ProductSearchConditionSummary: React.FC<Props> = ({
  className,
  searchCondition,
  canUseOnDisplayToOrder,
}) => {
  // 検索ワード
  const searchValue = searchCondition.filter.searchValue;

  // 有効期限
  const expiration = searchCondition.filter.getExpirationOptionLabel();

  // 並び順のラベル
  const sortTypeLabel = useMemo(() => {
    if (searchCondition.sort.key === 'create') {
      return searchCondition.sort.order === 'desc' ? '商品の追加日が新しい順' : '商品の追加日が古い順';
    }
    if (searchCondition.sort.key === 'update') {
      return searchCondition.sort.order === 'desc' ? '商品の更新日が新しい順' : '商品の更新日が古い順';
    }
    return '指定なし';
  }, [searchCondition]);

  // 在庫状況
  const availability = searchCondition.filter.getAvailabilityOptionLabel(canUseOnDisplayToOrder);

  return (
    <Wrapper className={className}>
      {searchValue && (
        <div>
          <span>検索ワード</span>: {searchValue}
        </div>
      )}
      <div>
        <span>有効期限</span>: {expiration}
      </div>
      <div>
        <span>店頭在庫状況</span>: {availability}
      </div>
      <div>
        <span>並び順</span>: {sortTypeLabel}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  ${Mixins.flex('center')}
  gap: 8px;
  border-radius: 5px;
  padding: 8px 16px;
  background: #fff;

  flex-direction: column;
  align-items: unset;

  span {
    font-weight: bold;
  }
`;
