// GmbApi/index.tsが大きくなりすぎてるので、他のクチコミ系APIも移植したい

import CONFIG from 'config';

import { LambdaFunctionApiClient } from './index';

const REVIEW_BASE_PATH = `/gmb/reviews`;

type ReviewType = 'comment_good' | 'comment_bad' | 'rating_good' | 'rating_bad';

export type ReviewReplyGenerateParams = {
  comment?: string;
  reviewer: string;
  review_type: ReviewType;
  message?: string;
};

export type ReviewReplyGenerateResponseItem = {
  body: string;
};

export type ReviewReplyGenerateResponse = {
  items: ReviewReplyGenerateResponseItem[];
};

export class ReviewReplyGenerateApi {
  static post(params: ReviewReplyGenerateParams) {
    const lambdaFunctionClient = new LambdaFunctionApiClient({
      endpoint: CONFIG.GENERATE_REVIEW_REPLY_ENDPOINT,
    });
    return lambdaFunctionClient.post<ReviewReplyGenerateResponse>(`${REVIEW_BASE_PATH}/reply/generate`, params);
  }
}
