import { useEffect, useState } from 'react';

/**
 * テキストを選択状態にしているか取得する
 */
export const useTextSelection = () => {
  const [isTextSelected, setIsTextSelected] = useState<boolean>(false);

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      setIsTextSelected(!!selection && !selection.isCollapsed);
    };

    document.addEventListener('mouseup', handleSelectionChange);

    return () => {
      document.removeEventListener('mouseup', handleSelectionChange);
    };
  }, []);

  return isTextSelected;
};
