import React from 'react';

import { List } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { CompetitorList } from 'models/Domain/MapCompetitorResearch/Competitor';
import { MapSearchResultDetailItem, StoreKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { COLOR } from 'style/color';

import { AreaKeywordRankTable } from './AreaKeywordRankTable';
import { AreaKeywordLabel } from './Common';

interface Props {
  isOpen: boolean;
  data: List<MapSearchResultDetailItem> | undefined;
  onBack: () => void;
  onClose: () => void;
  onSelectStore: (storeKey: StoreKey) => void;
  onChangeCompetitor: (storeKey: StoreKey) => void;
  condition: { areaName: string; searchWord: string };
  competitors: CompetitorList;
  managedStoreKeys: List<StoreKey>;
}

export const AreaKeywordRankModal = React.memo<Props>(
  ({ isOpen, data, onBack, onClose, onSelectStore, onChangeCompetitor, condition, competitors, managedStoreKeys }) => {
    return (
      <StyledModal open={isOpen}>
        <ModalContent>
          <Wrapper>
            <Label>
              <AreaKeywordLabel areaName={condition.areaName} searchWord={condition.searchWord} />
            </Label>
            <ContentWrapper>
              <AreaKeywordRankTable
                data={data}
                onSelectStore={onSelectStore}
                onChangeCompetitor={onChangeCompetitor}
                competitors={competitors}
                managedStoreKeys={managedStoreKeys}
              />
            </ContentWrapper>
            <ButtonWrapper>
              <StyledButton onClick={onBack}>戻る</StyledButton>
              <StyledButton onClick={onClose}>閉じる</StyledButton>
            </ButtonWrapper>
          </Wrapper>
        </ModalContent>
      </StyledModal>
    );
  },
);

const StyledModal = styled(Modal)`
  height: 80%;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

const Label = styled.label`
  color: ${COLOR.BLACK};
  display: block;
  margin: 8px 0 16px;
  font-size: 20px;
  font-weight: bold;
  > span {
    font-size: 16px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 14px 24px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 150px;
    padding: 14px 8px;
  }
`;
