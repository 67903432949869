import React, { useEffect } from 'react';

/**
 * リサイズされたら処理を実行する
 *
 * @param element リサイズされたことを検知する要素
 * @param callback リサイズ時に実行される処理
 */
export const useResizeObserver = (
  element: React.RefObject<Element>,
  callback: (entry: ResizeObserverEntry) => void,
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries[0]);
    });
    element.current && resizeObserver.observe(element.current);
    return (): void => {
      resizeObserver.disconnect();
    };
  }, [callback, element]);
};
