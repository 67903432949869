import React, { useCallback, useEffect, useState } from 'react';

import { Set } from 'immutable';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { CsvDownloadButton } from 'components/molecules/CsvDownloadButton';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { MapSearchRankConfigSearchCondition as SearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankConfigSearchCondition';
import { Group } from 'types/Common';

export type MapSearchRankSearchConditionProps = {
  className?: string;
  isCommitDisabled: boolean;
  committedSearchCondition: SearchCondition;
  commitSearchCondition: (condition: SearchCondition) => void;

  isProcessingCsvDownload: boolean;
  startCsvDownload: (searchCondition: SearchCondition) => void;
};

export const MapSearchRankConfigSearchCondition = React.memo<MapSearchRankSearchConditionProps>(
  ({
    className,
    isCommitDisabled,
    committedSearchCondition,
    commitSearchCondition,
    isProcessingCsvDownload,
    startCsvDownload,
  }) => {
    const [searchCondition, setSearchCondition] = useState<SearchCondition>(committedSearchCondition);

    // committedSearchConditionが変更されたら更新する
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    const {
      filter: { group, storeIds, showClosedStores },
    } = searchCondition;

    const handleChangeGroupStore = useCallback(
      (group: Group, storeIds: Set<number>, isAllStoreIds: boolean, showClosedStores: boolean) => {
        const newSearchCondition = searchCondition.mergeIn(['filter'], {
          group,
          storeIds,
          isAllStoreIds,
          showClosedStores,
        });
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    /**
     * 無効な設定も含めて検索するのオンオフ
     */
    const handleChangeContainsExcluded = useCallback(() => {
      const newSearchCondition = searchCondition.update('filter', (filter) =>
        filter.update('containsDisabled', (containsExcluded) => !containsExcluded),
      );
      setSearchCondition(newSearchCondition);
    }, [searchCondition]);

    const handleCommitSearchCondition = useCallback(() => {
      commitSearchCondition(searchCondition);
    }, [commitSearchCondition, searchCondition]);

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <GroupStoreSelect
            group={group}
            storeIds={storeIds}
            showClosedStores={showClosedStores}
            onChange={handleChangeGroupStore}
          />
          <FlexContent>
            <Label>検索対象</Label>
            <CheckboxInnerContent>
              <StyledCheckbox
                onChange={handleChangeContainsExcluded}
                checked={searchCondition.filter.containsDisabled}
                label='無効化されている設定を含めて検索する'
              />
            </CheckboxInnerContent>
          </FlexContent>
          <ButtonContainer>
            <StyledCsvDownloadButton
              label='この条件でCSVをダウンロード'
              disabled={isCommitDisabled || isProcessingCsvDownload}
              isProcessing={isProcessingCsvDownload}
              onClick={() => startCsvDownload(searchCondition)}
            />
            <CommitButton priority={'high'} onClick={handleCommitSearchCondition} disabled={isCommitDisabled}>
              検索
            </CommitButton>
          </ButtonContainer>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled(Card)`
  width: 100%;
  margin-bottom: 32px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  margin-left: auto;
  display: flex;
  align-items: flex-end;
`;

const StyledCsvDownloadButton = styled(CsvDownloadButton)`
  height: 32px;
`;

const CommitButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    margin-left: 8px;
    box-shadow: none;
  }
`;

const FlexContent = styled.div``;
const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 7px;
`;
const CheckboxInnerContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4px;
`;
const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 12px;
    color: #707070;
    cursor: pointer;
    margin-top: 4px;
  }
`;
