import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { createPagerData, createPagerDataWithoutFixed } from 'helpers/pager';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  currentPage: number; // 現在のページ
  viewContentSize: number; // 表示する要素の数
  totalContentSize: number; // 要素の合計数
  viewMaxPage?: number;
  fixedPage?: number;
  onChangeNo: (number: number) => void;
  showFixedPage?: boolean; // 固定ページを表示する
};

export const Paging = React.memo<Props>(
  ({
    className,
    viewContentSize,
    currentPage,
    totalContentSize,
    viewMaxPage = 5,
    fixedPage = 1,
    onChangeNo,
    showFixedPage = false,
  }) => {
    // ページの総数
    const totalPage = Math.ceil(totalContentSize / viewContentSize);

    const onPrevious = () => {
      if (currentPage === 1) return;
      onChangeNo(currentPage - 1);
    };
    const onNext = () => {
      if (currentPage === totalPage) return;
      onChangeNo(currentPage + 1);
    };
    const onNumber = (no: number) => {
      onChangeNo(no);
    };

    const pagerData = showFixedPage
      ? createPagerData({
          current: currentPage,
          total: totalPage,
          limit: viewMaxPage,
          fixed: fixedPage,
        })
      : createPagerDataWithoutFixed({
          current: currentPage,
          total: totalPage,
          limit: viewMaxPage,
        });

    const isEnabledPreviousPage = currentPage > 1;
    const isEnabledNextPage = totalContentSize > currentPage * viewContentSize;

    return (
      <Wrapper className={className}>
        <StyledButton active={false} onClick={() => onPrevious()} disabled={!isEnabledPreviousPage}>
          <BackwardIcon type='arrow_forward' />
        </StyledButton>

        {pagerData.map((pages, i) => (
          <React.Fragment key={i}>
            {pages.map((page) => (
              <StyledButton key={page} active={page === currentPage} onClick={() => onNumber(page)}>
                {page}
              </StyledButton>
            ))}
            {i < pagerData.length - 1 && <Point>…</Point>}
          </React.Fragment>
        ))}

        <StyledButton active={false} onClick={() => onNext()} disabled={!isEnabledNextPage}>
          <ForwardIcon type='arrow_forward' />
        </StyledButton>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ForwardIcon = styled(Icon)`
  &&& {
    color: ${COLOR.BLACK};
    height: 14px;
    width: 14px;
    padding: 0;
  }
`;

const BackwardIcon = styled(ForwardIcon)`
  transform: rotateY(180deg);
`;

const StyledButton = styled(Button)<{ active: boolean }>`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 4px;
    border: 1px solid ${(props) => (props.active ? `${COLOR.GREEN}` : `${COLOR.GRAY}`)};
    background-color: ${(props) => (props.active ? `${COLOR.GREEN}` : `${COLOR.WHITE}`)};
    color: ${(props) => (props.active ? `${COLOR.WHITE}` : `${COLOR.BLACK}`)};
    font-size: 13px;

    :hover {
      border-color: ${(props) => (props.active ? `${COLOR.DARK_GREEN}` : `${COLOR.GRAY}`)};
      background-color: ${(props) => (props.active ? `${COLOR.DARK_GREEN}` : `${COLOR.LIGHT_GREEN}`)};
    }

    :disabled {
      border: 1px solid ${(props) => (props.active ? `${COLOR.GREEN}` : `${COLOR.GRAY}`)};
      background-color: ${(props) => (props.active ? `${COLOR.WHITE}` : `${COLOR.WHITE}`)};
      opacity: 0.4;
    }
  }
`;

const Point = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;
