import React, { useCallback, useMemo, useState } from 'react';

import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { ExcludedKeywordCreateModal } from 'components/pageComponents/SearchKeywords/ExcludedKeywordCreateModal';
import { createExcludedKeyword, deleteExcludedKeyword } from 'components/pages/SearchKeywords';
import { ExcludedKeyword, ExcludedKeywords } from 'models/Domain/SearchKeyword/ExcludedKeyword';
import { COLOR } from 'style/color';

import { ExcludedKeywordDeleteModal } from './ExcludedKeywordDeleteModal';

interface Props {
  className?: string;
  excludedKeywords: ExcludedKeywords;
  setIsOpenExcludedKeywordsStickyFooter: (isUpdating: boolean) => void;
  isLoadingExcludedKeywordsList: boolean;
  isOpen?: boolean;
  onClose: () => void;
}

/**
 * 検索キーワード分析の除外設定を管理するモーダル
 */
export const ExcludedSearchKeywordsConfigModal: React.FC<Props> = ({
  className,
  excludedKeywords,
  setIsOpenExcludedKeywordsStickyFooter,
  isLoadingExcludedKeywordsList,
  isOpen,
  onClose,
}) => {
  // 新規追加モーダルを表示するかどうか
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const openCreateModal = useCallback(() => setIsOpenCreateModal(true), [setIsOpenCreateModal]);
  // 新規追加モーダルで入力中のキーワード
  const [keywordForCreate, setKeywordForCreate] = useState(new ExcludedKeyword());
  // 新規追加モーダルで入力したキーワードが登録可能かどうか
  const errorMessageForCreate = useMemo(
    () => keywordForCreate.validate(excludedKeywords),
    [excludedKeywords, keywordForCreate],
  );
  // 作成APIが実行中かどうか
  const [isRunningCreateApi, setIsRunningCreateApi] = useState<boolean>(false);
  // 新規追加モーダルで追加ボタンが押された
  // SWRを使わずaxiosでPOSTリクエストを送信
  const onCommitCreateModal = useCallback(async () => {
    // 作成実行
    setIsRunningCreateApi(true);
    await createExcludedKeyword(keywordForCreate);
    setIsRunningCreateApi(false);
    // 新規追加キーワード入力解除、新規追加モーダルを閉じる
    setKeywordForCreate(new ExcludedKeyword({ keyword: '', exactMatch: false }));
    setIsOpenCreateModal(false);
    setIsOpenExcludedKeywordsStickyFooter(true);
  }, [keywordForCreate, setIsOpenExcludedKeywordsStickyFooter]);
  // 新規追加モーダルでキャンセルボタンが押された
  const onCloseCreateModal = useCallback(() => {
    // 新規追加キーワード入力削除
    setKeywordForCreate(new ExcludedKeyword({ keyword: '', exactMatch: false }));
    setIsOpenCreateModal(false);
  }, [setIsOpenCreateModal, setKeywordForCreate]);

  // 削除確認中の除外キーワード
  const [selectingExcludedKeyword, setSelectingExcludedKeyword] = useState<ExcludedKeyword | null>(null);
  // 削除APIが実行中かどうか
  const [isRunningDeleteApi, setIsRunningDeleteApi] = useState<boolean>(false);
  // 削除確認モーダルを表示するかどうか
  const isOpenDeleteModal = useMemo(() => !!selectingExcludedKeyword, [selectingExcludedKeyword]);
  // 除外設定の削除確認モーダルで削除が押された
  // SWRを使わずaxiosでDELETEリクエストを送信
  const onCommitDeleteModal = useCallback(async () => {
    if (selectingExcludedKeyword === null) {
      return;
    }

    // 削除実行
    setIsRunningDeleteApi(true);
    await deleteExcludedKeyword(selectingExcludedKeyword?.id);
    setIsRunningDeleteApi(false);
    // 除外設定の選択状態解除
    setSelectingExcludedKeyword(null);
    setIsOpenExcludedKeywordsStickyFooter(true);
  }, [selectingExcludedKeyword, setSelectingExcludedKeyword, setIsOpenExcludedKeywordsStickyFooter]);
  // 除外設定の削除確認モーダルでキャンセルが押された
  // 選択中のキーワードを空にする
  const onCloseDeleteModal = useCallback(() => setSelectingExcludedKeyword(null), [setSelectingExcludedKeyword]);

  const isLoading = isLoadingExcludedKeywordsList || isRunningCreateApi || isRunningDeleteApi;

  return (
    <>
      <Modal className={className} open={isOpen}>
        <Wrapper>
          <Header>
            <Title>除外キーワード設定</Title>
            <CreateButton priority='high' onClick={openCreateModal}>
              新規追加
            </CreateButton>
          </Header>
          <Help>
            <Icon name='lightbulb outline' />
            除外キーワード設定は、組織内で共有されます。
          </Help>
          <StyledTable>
            <StyledTableHeader>
              <TableHeaderRow>
                <StyledTableHeaderCell width={11}>除外キーワード</StyledTableHeaderCell>
                <StyledTableHeaderCell width={3}>除外判定タイプ</StyledTableHeaderCell>
                <StyledTableHeaderCell width={2}></StyledTableHeaderCell>
              </TableHeaderRow>
            </StyledTableHeader>
            <TableBody>
              {excludedKeywords?.items.map((item: ExcludedKeyword) => {
                const { id, keyword, exactMatch } = item;
                return (
                  <TableRow key={id}>
                    <StyledTableCell>{keyword}</StyledTableCell>
                    <StyledTableCell>{exactMatch ? '完全一致' : '部分一致'}</StyledTableCell>
                    <StyledTableCell>
                      <DeleteButton priority='high' onClick={() => setSelectingExcludedKeyword(item)} negative>
                        削除
                      </DeleteButton>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
          {/* 除外キーワードが0件かつ、読み込み中でない場合のメッセージ */}
          {excludedKeywords?.items.isEmpty() && !isLoading && (
            <Message>
              <MessageTitle>常に適用する除外キーワードがまだ追加されていません</MessageTitle>
              <MessageDetail>新規追加ボタンから常に適用したい除外キーワードを追加してください</MessageDetail>
            </Message>
          )}
          {/* 除外キーワードが0件かつ、読み込み中の場合にLoader表示用のスペースを追加 */}
          {excludedKeywords?.items.isEmpty() && isLoading && <LoaderContainer />}
          {isLoading && (
            <LoaderWrapper>
              <StyledLoader active={isLoading} size='big' inline />
            </LoaderWrapper>
          )}
          <CloseButtonWrapper>
            <CloseButton onClick={onClose}>戻る</CloseButton>
          </CloseButtonWrapper>
        </Wrapper>
      </Modal>

      {/* 除外キーワード新規追加モーダル */}
      <ExcludedKeywordCreateModal
        isOpen={isOpenCreateModal}
        keyword={keywordForCreate.keyword}
        exactMatch={keywordForCreate.exactMatch}
        disabled={errorMessageForCreate !== ''}
        errorMessage={errorMessageForCreate}
        onChange={(keyword, exactMatch) => setKeywordForCreate(new ExcludedKeyword({ keyword, exactMatch }))}
        onCreate={onCommitCreateModal}
        onClose={onCloseCreateModal}
      />

      {/* 除外キーワードの削除確認モーダル */}
      {/* FIXME モーダルが一覧モーダルの上端寄りに表示される。本当は上下中央に表示したいがうまくcssが効かず一旦放置 */}
      <ExcludedKeywordDeleteModal
        isOpen={isOpenDeleteModal}
        excludedKeyword={selectingExcludedKeyword}
        onCommit={onCommitDeleteModal}
        onCancel={onCloseDeleteModal}
      ></ExcludedKeywordDeleteModal>
    </>
  );
};

const Wrapper = styled.div`
  margin: 32px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const CreateButton = styled(Button)`
  &&& {
    width: 150px;
  }
`;

const Help = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
`;

const StyledTable = styled(Table)`
  &&& {
    margin: 16px 0 32px;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  position: sticky;
  top: -16px; // ヘッダが画面上に固定された時に余白ができてしまうため上に詰める
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    font-size: 15px;
    height: 52px; // ヘッダ以外の行の高さと合わせる
    padding: 8px 10px;
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    height: 48px;
    font-size: 15px;
    padding: 8px 10px;
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 16px 0;
`;

const DeleteButton = styled(Button)`
  &&& {
    width: 80px;
    height: 32px;
    padding: 0;
    font-size: 14px;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 200px;
`;

const MessageTitle = styled.div`
  font-weight: bold;
  color: ${COLOR.BLACK};
`;

const MessageDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${COLOR.DARK_GRAY};
`;

const LoaderWrapper = styled.div`
  background: #f8f8f880;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledLoader = styled(Loader)`
  &&&& {
    &:before {
      border-color: rgba(0, 0, 0, 0.1);
    }
    &:after {
      border-color: #767676 transparent transparent;
    }
  }
`;

const LoaderContainer = styled.div`
  height: 200px;
`;

const CloseButton = styled(Button)`
  &&& {
    width: 150px;
  }
`;
