import { toast } from 'react-semantic-toasts';
import { put, select } from 'redux-saga/effects';

import { GmbReviewTemplateApi } from 'ApiClient/GmbApi';
import { GmbReviewTemplates } from 'models/Domain/GmbReviewTemplate';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';

import { GmbActions } from '../actions';

export function* getGmbReviewTemplateList(action: ReturnType<typeof GmbActions.getGmbReviewTemplateList>) {
  yield put(AppActions.setLoading(true));

  const organization_id: number = yield select((state: State) => state.app.currentUser.organization_id);
  const response: YieldReturn<typeof GmbReviewTemplateApi.get> = yield GmbReviewTemplateApi.get(organization_id);
  if (response.isSuccess) {
    yield put(GmbActions.setGmbReviewTemplateList(new GmbReviewTemplates(response.data)));
  } else {
    toast({
      type: 'error',
      title: 'テンプレートの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

export function* createGmbReviewTemplate(action: ReturnType<typeof GmbActions.createGmbReviewTemplate>) {
  yield put(AppActions.setLoading(true));

  const organization_id: number = yield select((state: State) => state.app.currentUser.organization_id);
  const response: YieldReturn<typeof GmbReviewTemplateApi.post> = yield GmbReviewTemplateApi.post(
    organization_id,
    action.payload.createParam(),
  );
  if (response.isSuccess) {
    yield put(GmbActions.getGmbReviewTemplateList());
  } else {
    toast({
      type: 'error',
      title: 'テンプレートの作成に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

export function* updateGmbReviewTemplate(action: ReturnType<typeof GmbActions.updateGmbReviewTemplate>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof GmbReviewTemplateApi.put> = yield GmbReviewTemplateApi.put(
    action.payload.template_id,
    action.payload.organization_id,
    action.payload.updateParam(),
  );

  if (response.isSuccess) {
    yield put(GmbActions.getGmbReviewTemplateList());
  } else {
    toast({
      type: 'error',
      title: 'テンプレートの更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

export function* deleteGmbReviewTemplate(action: ReturnType<typeof GmbActions.deleteGmbReviewTemplate>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof GmbReviewTemplateApi.delete> = yield GmbReviewTemplateApi.delete(
    action.payload.template_id,
    action.payload.organization_id,
  );

  if (response.isSuccess) {
    toast({ type: 'success', title: 'テンプレートを削除しました' });
    yield put(GmbActions.getGmbReviewTemplateList());
  } else {
    toast({
      type: 'error',
      title: 'テンプレートの削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}
