import React, { useCallback, useMemo } from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import {
  ColorCell,
  ColorHeaderCell,
  ShopIcon,
  StoreName,
} from 'components/pageComponents/MapCompetitorResearch/Common';
import { CompetitorList, MAX_COMPETITOR_SIZE } from 'models/Domain/MapCompetitorResearch/Competitor';
import { StoreKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';

interface Props {
  className?: string;
  onSelectStore: (storeKey: StoreKey) => void;
  onChangeCompetitor: (value: StoreKey) => void;
  competitors: CompetitorList;
  managedStoreKeys: List<StoreKey>;
}

export const ComparisonStoreTable = React.memo<Props>(
  ({ className, competitors, onChangeCompetitor, onSelectStore, managedStoreKeys }) => {
    // 比較店舗に設定されていない所属店舗のstoreKey
    const managedStoresKeysWithoutCompetitor = useMemo(() => {
      return managedStoreKeys.filter((storeKey) => !competitors.contains(storeKey));
    }, [competitors, managedStoreKeys]);

    return (
      <Table unstackable={true} className={className}>
        <TableHeader>
          <TableHeaderRow>
            <ColorHeaderCell />
            <StyledTableHeaderCell weight={1}>店舗名</StyledTableHeaderCell>
            <StyledTableHeaderCell style={{ width: '96px', textAlign: 'right' }}>
              {competitors.size} / {MAX_COMPETITOR_SIZE}
            </StyledTableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {competitors.list.map((store, index) => (
            <ComparisonStoreTableRow
              key={index}
              storeKey={store.getStoreKey()}
              color={store.color}
              onSelectStore={() => onSelectStore(store.getStoreKey())}
              onChangeCompetitor={() => onChangeCompetitor(store.getStoreKey())}
              isCompetitor={true}
              isManaged={managedStoreKeys.contains(store.getStoreKey())}
              disableAddCompetitor={competitors.size >= MAX_COMPETITOR_SIZE}
            />
          ))}
        </TableBody>
        {managedStoresKeysWithoutCompetitor.size > 0 && (
          <TableRow>
            <ManagedStoreHeader colSpan={3}>所属組織の店舗</ManagedStoreHeader>
          </TableRow>
        )}
        {managedStoresKeysWithoutCompetitor.map((storeKey, index) => (
          <ComparisonStoreTableRow
            key={index}
            storeKey={storeKey}
            color={null}
            onSelectStore={onSelectStore}
            onChangeCompetitor={onChangeCompetitor}
            isCompetitor={false}
            isManaged={true}
            disableAddCompetitor={competitors.size >= MAX_COMPETITOR_SIZE}
          />
        ))}
      </Table>
    );
  },
);

export const ComparisonStoreTableRow = React.memo<{
  storeKey: StoreKey;
  color: string | null;
  onChangeCompetitor: (storeKey: StoreKey) => void;
  onSelectStore: (storeKey: StoreKey) => void;
  isCompetitor: boolean;
  isManaged: boolean;
  disableAddCompetitor: boolean;
}>(({ storeKey, color, isCompetitor, isManaged, onChangeCompetitor, onSelectStore, disableAddCompetitor }) => {
  const handleOnSelectStore = useCallback(() => {
    onSelectStore(storeKey);
  }, [onSelectStore, storeKey]);

  const handleOnChangeCompetitor = useCallback(() => {
    onChangeCompetitor(storeKey);
  }, [onChangeCompetitor, storeKey]);

  return (
    <StyledTableRow>
      <ColorCell color={color} />
      <StyledTableCell>
        <StoreName onClick={handleOnSelectStore}>{storeKey.storeName}</StoreName>
        {isManaged && <ShopIcon />}
      </StyledTableCell>
      <MenuCell>
        <StyledButton
          priority={'high'}
          negative={isCompetitor}
          onClick={handleOnChangeCompetitor}
          disabled={!isCompetitor && disableAddCompetitor}
        >
          {isCompetitor ? '削除' : '追加'}
        </StyledButton>
      </MenuCell>
    </StyledTableRow>
  );
});

const StyledTableRow = styled(TableRow)``;

const StyledTableHeaderCell = styled(TableHeaderCell)``;

const StyledTableCell = styled(TableCell)``;

const MenuCell = styled(TableCell)`
  width: 96px;
`;

const ManagedStoreHeader = styled(TableCell)`
  background: #f9fafb;
  height: 42px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 80px;
    height: 32px;
    padding: 0;
    font-size: 14px;
  }
`;
