import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { MemoDisplaySettingModal } from 'components/pageComponents/Analysis/MemoDisplaySettingModal';
import { MemoModal } from 'components/pageComponents/Memo/MemoModal';
import { MemoTable } from 'components/pageComponents/Memo/MemoTable';
import { MemoDisplaySettings } from 'helpers/graph';
import { useHoldOn } from 'hooks/useHoldOn';
import { MemoList, Memo as MemoModel, MemoTagList } from 'models/Domain/Memo/Memo';
import { COLOR } from 'style/color';
import { assertNever } from 'types/Common';

// ページ上部に固定するテーブルのヘッダーが、使用箇所によってスタイルを変更する必要がある
type StickyPosition = 'Title' | 'Tab' | 'Modal';
const tableHeaderStyle = {
  // ページタイトルの下に表示する
  Title: {
    top: '72px',
    background: COLOR.BACKGROUND,
  },
  // タブの下に表示する
  Tab: {
    top: '142px',
    background: COLOR.BACKGROUND,
  },
  // モーダルに表示する
  Modal: {
    top: '0',
    background: COLOR.WHITE,
  },
} satisfies Record<StickyPosition, { top: string; background: string }>;

type Props = {
  // 施策メモ
  memoList: MemoList;
  // 選択中のメモID
  activeMemoIds: ImmutableSet<number>;
  // 選択中のメモIDを空に
  clearActiveMemoIds: () => void;
  // 選択可能なタグの一覧
  tags: MemoTagList;
  // メモの表示設定
  memoDisplaySettings: MemoDisplaySettings;
  // メモの表示設定が変更された
  setMemoDisplaySettings: (setting: MemoDisplaySettings) => void;
  // メモを保存するボタンが押された (新規作成時)
  onCreateMemo: (memo: MemoModel) => void;
  // メモを保存するボタンが押された (更新時)
  onUpdateMemo: (memo: MemoModel) => void;
  // メモを削除するボタンが押された
  onDeleteMemo: (memo: MemoModel) => void;
  // 施策メモを読み込み中か
  isLoadingMemoList: boolean;
  // 選択可能なタグを読み込み中か
  isLoadingMemoTagList: boolean;
  // テーブル上部のヘッダーの種類
  stickyPosition: StickyPosition;
};

/**
 * 分析画面用施策メモの表示設定つきテーブルコンポーネント
 *
 * @param memoList 施策メモ
 * @param activeMemoIds 選択中のメモID
 * @param clearActiveMemoIds 選択中のメモIDを空にする
 * @param tags 選択可能なタグの一覧
 * @param memoDisplaySettings 表示設定
 * @param setMemoDisplaySettings 表示設定が変更された
 * @param onCreateMemo メモを保存するボタンが押された (新規作成時)
 * @param onUpdateMemo メモを保存するボタンが押された (更新時)
 * @param onDeleteMemo メモを削除するボタンが押された
 * @param stickyPosition テーブル上部のヘッダーの種類
 */
export const AnalysisMemoTable = React.memo<Props>(
  ({
    memoList,
    activeMemoIds: _activeMemoIds,
    clearActiveMemoIds,
    tags,
    memoDisplaySettings,
    setMemoDisplaySettings,
    onCreateMemo,
    onUpdateMemo,
    onDeleteMemo,
    isLoadingMemoList,
    isLoadingMemoTagList,
    stickyPosition,
  }) => {
    // テーブルの開閉状態
    const [showTable, setShowTable] = useState<boolean>(false);
    // 表示設定モーダルの開閉状態
    const [isOpenMemoDisplaySettingModal, setIsOpenMemoDisplaySettingModal] = useState<boolean>(false);
    // メモの編集モーダルの開閉状態
    const [isOpenModal, setIsOpenModal] = useState(false);
    // 編集中のメモ
    const [selectedMemo, setSelectedMemo] = useState<MemoModel | null>(null);

    const isLoading = useHoldOn(isLoadingMemoList, 1000);

    // グラフで選択中のメモ
    const activeMemoIds = useMemo(() => {
      // グラフへの表示設定が「表示しない」の場合はアクティブなメモは空にする
      if (memoDisplaySettings.displayToGraph === 'HIDE') {
        return ImmutableSet();
      }
      return _activeMemoIds;
    }, [_activeMemoIds, memoDisplaySettings.displayToGraph]);

    // 選択中のメモが更新されたとき、テーブルを閉じていたら開く
    useEffect(() => {
      if (!activeMemoIds.isEmpty() && !showTable) {
        setShowTable(true);
      }
    }, [showTable, setShowTable, activeMemoIds]);

    // タグが更新される度に表示設定のタグでの絞り込みに存在しないものを消去
    useEffect(() => {
      // 読込中なら何もしない
      if (isLoadingMemoTagList) {
        return;
      }
      const currentTags = tags.items.map((item) => item.tag);
      if (!memoDisplaySettings.tags.toSet().isSubset(currentTags)) {
        setMemoDisplaySettings(
          memoDisplaySettings.update('tags', (tags) => tags.filter((tag) => currentTags.includes(tag))),
        );
      }
    }, [tags, memoDisplaySettings, setMemoDisplaySettings, isLoadingMemoTagList]);

    // 表示設定ボタンが押された
    const onClickMemoDisplaySetting = useCallback(() => {
      setIsOpenMemoDisplaySettingModal(true);
    }, [setIsOpenMemoDisplaySettingModal]);

    // 表示設定モーダルの閉じるボタンが押された
    const onCloseMemoDisplaySettingModal = useCallback(() => {
      setIsOpenMemoDisplaySettingModal(false);
    }, [setIsOpenMemoDisplaySettingModal]);

    // メモを作成ボタンが押された
    const onClickCreateMemo = useCallback(() => {
      // モーダルを作成モードにするため、編集中のメモがない状態にする
      setSelectedMemo(null);
      setIsOpenModal(true);
    }, [setIsOpenModal]);

    // テーブル内のメモの編集ボタンが押された
    const onSelectMemo = useCallback(
      (memo) => {
        setSelectedMemo(memo);
        setIsOpenModal(true);
      },
      [setSelectedMemo, setIsOpenModal],
    );

    // 施策メモの表示非表示を切り替えるボタンが押された
    const onClickToggleDisplayTable = useCallback(() => {
      // 表示から非表示へ切り替わる場合は、アクティブ表示をやめる
      if (showTable) {
        clearActiveMemoIds();
      }
      setShowTable(!showTable);
    }, [showTable, setShowTable, clearActiveMemoIds]);

    // メモの編集モーダルの閉じるボタンが押された
    const onCloseMemoModal = useCallback(() => {
      setIsOpenModal(false);
    }, [setIsOpenModal]);

    // 施策メモの表示非表示を切り替えるボタンのラベル
    const toggleDisplayTableLabel = useMemo(() => {
      return showTable ? '施策メモを閉じる' : '施策メモを一覧表示する';
    }, [showTable]);

    // タグの表示用文言
    const tagsLabel = useMemo(() => {
      if (memoDisplaySettings.tags.isEmpty()) {
        return 'すべて';
      }
      return memoDisplaySettings.tags.join('、');
    }, [memoDisplaySettings.tags]);

    // 「グラフへの表示」の表示用文言
    const displayToGraphLabel = useMemo(() => {
      switch (memoDisplaySettings.displayToGraph) {
        case 'PERIOD':
          return '開始日と終了日';
        case 'START_DAY_ONLY':
          return '開始日のみ表示';
        case 'HIDE':
          return '表示しない';
        default:
          return assertNever(memoDisplaySettings.displayToGraph);
      }
    }, [memoDisplaySettings]);

    return (
      <Wrapper>
        <Title>
          <TitleCell>
            施策メモ
            <StyledButton priority='low' onClick={onClickMemoDisplaySetting}>
              表示設定
            </StyledButton>
          </TitleCell>
          <TitleCell>
            <StyledButton priority='low' onClick={onClickCreateMemo}>
              <AddButtonWrapper>
                <StyledIcon type='post_add' />
                施策メモを作成
              </AddButtonWrapper>
            </StyledButton>
          </TitleCell>
        </Title>
        <MemoDisplaySettingWrapper>
          <Condition>
            <ConditionLabel>タグ</ConditionLabel>: {tagsLabel}
          </Condition>
          <Condition>
            <ConditionLabel>グラフへの表示</ConditionLabel>: {displayToGraphLabel}
          </Condition>
        </MemoDisplaySettingWrapper>
        <FlexWrapper style={{ ...tableHeaderStyle[stickyPosition] }}>
          <CountText>{isLoading ? '施策メモを取得中' : `${memoList.items.size}件の施策メモ`}</CountText>
          <ToggleTable>
            <Action onClick={onClickToggleDisplayTable}>{toggleDisplayTableLabel}</Action>
          </ToggleTable>
        </FlexWrapper>

        {showTable && (
          <TableWrapper>
            {!memoList.items.isEmpty() && (
              <MemoTable memoList={memoList} activeMemoIds={activeMemoIds} onSelectMemo={onSelectMemo} />
            )}
            {memoList.items.isEmpty() && (
              <MessageWrapper>
                {!isLoading && (
                  <Message>
                    集計期間内に指定された条件の施策メモがありませんでした
                    <br />
                    条件を変更するか、施策メモを作成してください
                  </Message>
                )}
              </MessageWrapper>
            )}
            {isLoading && (
              <LoadingWrapper>
                <Loader active={isLoading} size={'big'} inline={true} />
              </LoadingWrapper>
            )}
          </TableWrapper>
        )}
        {/* 表示設定モーダル */}
        <MemoDisplaySettingModal
          isOpen={isOpenMemoDisplaySettingModal}
          onClose={onCloseMemoDisplaySettingModal}
          setting={memoDisplaySettings}
          tags={tags.items}
          onSave={setMemoDisplaySettings}
          isLoadingMemoTagList={isLoadingMemoTagList}
        />
        {/* メモの編集モーダル */}
        <MemoModal
          isOpen={isOpenModal}
          onClose={onCloseMemoModal}
          memo={selectedMemo}
          tagList={tags}
          onCreate={onCreateMemo}
          onUpdate={onUpdateMemo}
          onDelete={onDeleteMemo}
        />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin: 20px 0 28px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const TitleCell = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  &&& {
    padding: 0;
  }
`;

const StyledButton = styled(Button)`
  font-size: 14px;
`;

// 現在設定されているメモの表示設定
const MemoDisplaySettingWrapper = styled.div`
  background: rgba(219, 219, 219, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 8px 16px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

// 現在設定されているメモの表示設定の1項目
const Condition = styled.div`
  margin-right: 18px;
  font-size: 14px;
`;

// 現在設定されているメモの表示設定の項目名
const ConditionLabel = styled.span`
  font-weight: bold;
  margin-right: 4px;
  font-size: 14px;
`;

const ToggleTable = styled.div`
  text-align: center;
`;
const Action = styled(Button).attrs({ priority: 'low' })`
  padding: 0;
`;

const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 136px;
`;

const Message = styled.div`
  text-align: center;
  color: ${COLOR.DARK_GRAY};
`;

const TableWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const CountText = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 16px;
  font-weight: bold;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  position: sticky;
  z-index: 1;
  background: ${COLOR.BACKGROUND};
  padding: 8px 0;
`;
