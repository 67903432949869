import React from 'react';

import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';

type ContextHelpProps = Pick<React.ComponentProps<typeof Popup>, 'header' | 'content'> & {
  className?: string;
  iconType?: React.ComponentProps<typeof Icon>['type'];
};

/**
 * コンテキストヘルプ
 */
export const ContextHelp = React.memo<ContextHelpProps>(({ className, header, content, iconType = 'question' }) => (
  <HeadIconWrapper className={className}>
    <Popup style={{ fontSize: '14px' }} header={header} content={content} trigger={<Icon type={iconType} />} />
  </HeadIconWrapper>
));

const HeadIconWrapper = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 4px;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;
