import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { UserEditForm } from 'components/organisms/UserEditForm';
import { User } from 'models/Domain/User';

type Props = {
  newUser: User;
  changeNewUser: (v: User) => void;
  registerNewUser: () => void;
  uploadNewUserImage: (file: File) => void;
};

const SingleRegister: React.FC<Props> = ({ newUser, changeNewUser, registerNewUser, uploadNewUserImage }) => {
  return (
    <Wrapper>
      <UserEditForm
        user={newUser}
        changeUser={changeNewUser}
        uploadUserImage={uploadNewUserImage}
        canChangeEmail={true}
        isMyAccountEdit={false}
        canEdit={true}
      />

      <ButtonWrapper>
        <Button priority='high' onClick={() => registerNewUser()} disabled={!newUser.isValid}>
          登録
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default SingleRegister;
