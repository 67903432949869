/* eslint-disable @typescript-eslint/no-use-before-define */

import dayjs, { Dayjs } from 'dayjs';
import { Record, Set } from 'immutable';

import { booleanToSearchParam, numberComparator, parseBooleanParam, parseNumberParameter } from 'helpers/search';
import { PROMOTION_TOPIC_TYPE_KEYS_WITH_ALL, PromotionTopicTypeWithALL } from 'models/Domain/Promotion/Promotion';
import { SORT_TYPES, SortType } from 'models/Domain/Promotion/PromotionList';
import { JSObject } from 'types/Common';

import { FilterStatus } from './PromotionDownloadCondition';

export type FilterStatusType = {
  // 「店舗」の選択されている値
  store: number | 'all' | 'my_store' | null;

  // 選択済の店舗
  store_ids: Set<number>;
  is_all_store_ids: boolean; // store_ids が store で指定されているグループのすべての店舗か

  free_word: string;
  topic_type: PromotionTopicTypeWithALL;

  // 投稿日時の開始日
  start_date: Dayjs | null;
  // 投稿日時の終了日
  end_date: Dayjs | null;
  // 下書きか
  isDraft: boolean;
  // 予約投稿か
  isScheduled: boolean;
  sort_type: SortType;

  // 閉店店舗を表示するか
  show_closed_store: boolean;
};

export type RequestParamsType = {
  query: string;
  topic_type: string;
  store_id: number[];
  start_time?: string;
  end_time?: string;
  is_draft?: '0' | '1';
  is_scheduled?: '0' | '1';
  sort_key: string;
  sort_order: string;
  page: number;
  limit: number;
};

const DEFAULT_STORE_VALUE = null;
const DEFAULT_IS_ALL_STORE_IDS_VALUE = true;
const DEFAULT_FREE_WORD_VALUE = '';
const DEFAULT_FILTER_VALUE = 'ALL';
const DEFAULT_START_DATE = null;
const DEFAULT_END_DATE = null;
const DEFAULT_IS_DRAFT = false;
const DEFAULT_IS_SCHEDULED = false;
const DEFAULT_SORT_VALUE = 'start_date_desc';
const DEFAULT_SHOW_CLOSED_STORE_VALUE = false;

/**
 * 投稿の検索条件
 */
export class ParamsStatus extends Record<FilterStatusType>({
  store: DEFAULT_STORE_VALUE,
  store_ids: Set<number>(),
  is_all_store_ids: DEFAULT_IS_ALL_STORE_IDS_VALUE,
  free_word: DEFAULT_FREE_WORD_VALUE,
  topic_type: DEFAULT_FILTER_VALUE,
  start_date: DEFAULT_START_DATE,
  end_date: DEFAULT_END_DATE,
  isDraft: DEFAULT_IS_DRAFT,
  isScheduled: DEFAULT_IS_SCHEDULED,
  sort_type: DEFAULT_SORT_VALUE,
  show_closed_store: DEFAULT_SHOW_CLOSED_STORE_VALUE,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  /**
   * store_idsが正しい形か？
   */
  isValidStoreIds() {
    return this.store_ids.size > 0;
  }

  setFreeWord(value: string) {
    return this.set('free_word', value);
  }

  setTopicType(value: PromotionTopicTypeWithALL) {
    if (!PROMOTION_TOPIC_TYPE_KEYS_WITH_ALL.includes(value)) {
      return this;
    }
    return this.set('topic_type', value);
  }

  setDate(key: 'start_date' | 'end_date', value: string) {
    if (!value.match(/\d{4}-\d{2}-\d{2}/)) {
      return this;
    }
    return this.set(key, dayjs(value));
  }

  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({
      store_ids: storeIds,
      is_all_store_ids: isAllStoreIds,
    });
  }

  setIsDraft(value: boolean) {
    return this.set('isDraft', value);
  }

  setIsScheduled(value: boolean) {
    return this.set('isScheduled', value);
  }

  setSort(value: SortType) {
    if (!SORT_TYPES.includes(value)) {
      return this;
    }
    return this.set('sort_type', value);
  }

  toDownloadFilter() {
    return new FilterStatus({
      startDate: this.start_date,
      endDate: this.end_date,
    });
  }
}

export type PaginationType = {
  page: number;
  per_page: number;
};

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;

export class Pagination extends Record<PaginationType>({
  page: DEFAULT_PAGE,
  per_page: DEFAULT_PER_PAGE,
}) {}

// URLパラメータのマッピング
const URLSearchParamsMapping = {
  free_word: 'fw',
  topic_type: 'tt',
  store: 'st',
  store_ids: 'si',
  start_date: 'sd',
  end_date: 'ed',
  isDraft: 'dr',
  isScheduled: 'sc',
  sort_type: 'so',
  page: 'pg',
  per_page: 'pp',
  show_closed_store: 'cs',
};

export class PromotionSearchCondition extends Record<{
  params: ParamsStatus;
  pagination: Pagination;
}>({
  params: new ParamsStatus(),
  pagination: new Pagination(),
}) {
  setParams(params: ParamsStatus) {
    return this.set('params', params);
  }

  setPage(page: number) {
    return this.setIn(['pagination', 'page'], page);
  }

  toRequestSortKey() {
    return {
      start_date: 'date',
      start_date_desc: 'date',
      view_count: 'imp',
      view_count_desc: 'imp',
      click_count: 'ct',
      click_count_desc: 'ct',
      click_rate: 'ctr',
      click_rate_desc: 'ctr',
    }[this.params.sort_type];
  }

  toRequestSortOrder() {
    return this.params.sort_type.includes('desc') ? 'desc' : 'asc';
  }

  /**
   * GmbPromotionSearchConditionをAPIのパラメータに変換する
   */
  toRequestParams(): RequestParamsType {
    return {
      query: this.params.free_word,
      topic_type: this.params.topic_type.toLowerCase(),
      store_id: this.params.store_ids.toArray().sort(numberComparator),
      start_time:
        this.params.start_date !== null
          ? // 開始日の00:00:00
            this.params.start_date.set('hour', 0).set('minute', 0).set('second', 0).toISOString()
          : undefined,
      end_time:
        this.params.end_date !== null
          ? // 終了日に設定されている次の日の00:00:00
            this.params.end_date.set('hour', 0).set('minute', 0).set('second', 0).add(1, 'day').toISOString()
          : undefined,
      is_draft: this.params.isDraft == null ? undefined : booleanToSearchParam(this.params.isDraft),
      // 下書きの場合は、予約投稿かどうかは指定しない
      is_scheduled: this.params.isDraft ? undefined : booleanToSearchParam(this.params.isScheduled),
      sort_key: this.toRequestSortKey(),
      sort_order: this.toRequestSortOrder(),
      page: this.pagination.page,
      limit: this.pagination.per_page,
    };
  }

  /**
   * 検索条件をページURL用のパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();
    if (this.params.store != DEFAULT_STORE_VALUE) {
      params.append(URLSearchParamsMapping.store, String(this.params.store));
    }

    if (!this.params.is_all_store_ids && this.params.store_ids.size > 0) {
      params.append(URLSearchParamsMapping.store_ids, this.params.store_ids.toArray().sort(numberComparator).join(','));
    }

    if (this.params.free_word != DEFAULT_FREE_WORD_VALUE) {
      params.append(URLSearchParamsMapping.free_word, String(this.params.free_word));
    }

    if (this.params.topic_type != DEFAULT_FILTER_VALUE) {
      params.append(URLSearchParamsMapping.topic_type, String(this.params.topic_type));
    }

    if (this.params.start_date != DEFAULT_START_DATE) {
      params.append(URLSearchParamsMapping.start_date, this.params.start_date.format('YYYY-MM-DD'));
    }

    if (this.params.end_date != DEFAULT_END_DATE) {
      params.append(URLSearchParamsMapping.end_date, this.params.end_date.format('YYYY-MM-DD'));
    }

    if (this.params.isDraft != DEFAULT_IS_DRAFT) {
      params.append(URLSearchParamsMapping.isDraft, booleanToSearchParam(this.params.isDraft));
    }

    if (this.params.isScheduled != DEFAULT_IS_SCHEDULED) {
      params.append(URLSearchParamsMapping.isScheduled, booleanToSearchParam(this.params.isScheduled));
    }

    if (this.params.sort_type != DEFAULT_SORT_VALUE) {
      params.append(URLSearchParamsMapping.sort_type, String(this.params.sort_type));
    }

    if (this.params.show_closed_store != DEFAULT_SHOW_CLOSED_STORE_VALUE) {
      params.append(URLSearchParamsMapping.show_closed_store, booleanToSearchParam(this.params.show_closed_store));
    }

    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }
    if (this.pagination.per_page !== DEFAULT_PER_PAGE) {
      params.append(URLSearchParamsMapping.per_page, String(this.pagination.per_page));
    }

    // 見栄え悪いので '%2C' は ',' に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * URLパラメータから検索条件を生成する
   * @param search
   */
  static fromURLSearchParams(search: string): PromotionSearchCondition {
    const condition = new PromotionSearchCondition();
    let { params, pagination } = condition;
    const searchParams = new URLSearchParams(search);

    const store = searchParams.get(URLSearchParamsMapping.store);
    if (store) {
      if (store === 'all' || store === 'my_store') {
        // すべての店舗もしくは担当店舗の場合
        params = params.set('store', store);
      } else if (store.match(/^\d+$/)) {
        // グループの場合
        params = params.set('store', parseInt(store, 10));
      }
    }

    const store_ids = searchParams.get(URLSearchParamsMapping.store_ids) || 'all';
    if (store_ids === 'all') {
      params = params.setStoreIds(Set<number>(), true);
    } else {
      const values = store_ids.split(',');
      const storeIds = values.filter((v) => v.match(/^\d+$/)).map((v) => parseInt(v, 10));
      params = params.setStoreIds(Set(storeIds), false);
    }

    const freeWord = searchParams.get(URLSearchParamsMapping.free_word);
    if (freeWord) {
      params = params.setFreeWord(freeWord);
    }

    const topicTypeValue: any = searchParams.get(URLSearchParamsMapping.topic_type);
    if (topicTypeValue) {
      params = params.setTopicType(topicTypeValue);
    }

    const startDateValue: any = searchParams.get(URLSearchParamsMapping.start_date);
    if (startDateValue) {
      params = params.setDate('start_date', startDateValue);
    }

    const endDateValue: any = searchParams.get(URLSearchParamsMapping.end_date);
    if (endDateValue) {
      params = params.setDate('end_date', endDateValue);
    }

    const isDraft = parseBooleanParam(searchParams.get(URLSearchParamsMapping.isDraft));
    params = params.setIsDraft(isDraft);

    const isScheduled = parseBooleanParam(searchParams.get(URLSearchParamsMapping.isScheduled));
    params = params.setIsScheduled(isScheduled);

    if (isDraft && isScheduled) {
      // タブによる表示切り替えなので、もしURLで直接下書きと予約投稿両方指定された場合は、下書きを優先する
      params = params.setIsScheduled(false);
    }

    const sort: any = searchParams.get(URLSearchParamsMapping.sort_type);
    if (sort) {
      params = params.setSort(sort);
    }

    const showClosedStore = parseBooleanParam(
      searchParams.get(URLSearchParamsMapping.show_closed_store),
      DEFAULT_SHOW_CLOSED_STORE_VALUE,
    );
    params = params.set('show_closed_store', showClosedStore);

    // ページネーション関連
    pagination = pagination.set(
      'page',
      parseNumberParameter(searchParams.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1),
    );
    pagination = pagination.set(
      'per_page',
      parseNumberParameter(searchParams.get(URLSearchParamsMapping.per_page), DEFAULT_PER_PAGE, 1, 100),
    );

    return condition.merge({ params, pagination });
  }
}
