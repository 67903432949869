import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Set } from 'immutable';
import { Radio } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import {
  AggregateDateRangePicker,
  DateRangeOption,
  DateRangeOptionValue,
  getComparisonDateRange,
  getComparisonDateRangeOption,
  getDateRangeFromDateRangeOption,
  getDateRangeOption,
} from 'components/molecules/AggregateDateRangePicker';
import { AggregateUnitGroup } from 'components/molecules/AggregateUnitGroup';
import { TagInput } from 'components/molecules/TagInput';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import {
  AggregateUnit,
  MapSearchRankSearchCondition as SearchCondition,
} from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { COLOR } from 'style/color';
import { Group } from 'types/Common';

// 集計期間に指定可能な最も新しい日付（前日）
const maxDate = dayjs().startOf('day').subtract(1, 'day');

export const dateRangeOptions: { [key: string]: DateRangeOption[] } = {
  day: [
    { text: '過去4週間', value: '4 week' },
    { text: '3ヶ月', value: '3 month' },
    { text: '半年', value: '6 month' },
    { text: '1年', value: '12 month' },
    { text: 'カスタム', value: 'カスタム' },
  ],
  week: [
    { text: '過去4週間', value: '4 week' },
    { text: '3ヶ月', value: '3 month' },
    { text: '半年', value: '6 month' },
    { text: '1年', value: '12 month' },
    { text: 'カスタム', value: 'カスタム' },
  ],
  month: [
    { text: '3ヶ月', value: '3 month' },
    { text: '半年', value: '6 month' },
    { text: '1年', value: '12 month' },
    { text: 'カスタム', value: 'カスタム' },
  ],
};

// 比較期間のタイプ（プルダウンの選択肢）
export type ComparisonDateRangeOptionValue = '前の期間' | '前年' | 'カスタム';

export type MapSearchRankSearchConditionProps = {
  className?: string;
  isCommitDisabled: boolean;
  committedSearchCondition: SearchCondition;
  commitSearchCondition: (condition: SearchCondition) => void;
};

export const MapSearchRankSearchCondition = React.memo<MapSearchRankSearchConditionProps>(
  ({ className, isCommitDisabled, committedSearchCondition, commitSearchCondition }) => {
    const [searchCondition, setSearchCondition] = useState<SearchCondition>(committedSearchCondition);

    // committedSearchConditionが変更されたら更新する
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    const {
      filter: {
        aggregateUnit,
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
        isEnabledComparison,
        searchWords,
        searchWordsExactMatch,
        excludeWords,
        excludeWordsExactMatch,
        group,
        storeIds,
        showClosedStores,
      },
    } = searchCondition;
    const [dateRangeOption, setDateRangeOption] = useState<DateRangeOptionValue>(() =>
      getDateRangeOption(dateRangeOptions[aggregateUnit], startDate, endDate, maxDate),
    );
    const [comparisonDateRangeOption, setComparisonDateRangeOption] = useState<ComparisonDateRangeOptionValue>(() =>
      isEnabledComparison
        ? getComparisonDateRangeOption(dateRangeOption, startDate, endDate, comparisonStartDate, comparisonEndDate)
        : '前の期間',
    );

    const aggregateDurationOptions = useMemo(() => dateRangeOptions[aggregateUnit], [aggregateUnit]);

    const handleChangeGroupStore = useCallback(
      (group: Group, storeIds: Set<number>, isAllStoreIds: boolean, showClosedStores: boolean) => {
        const newSearchCondition = searchCondition.mergeIn(['filter'], {
          group,
          storeIds,
          isAllStoreIds,
          showClosedStores,
        });
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleChangeSearchWords = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.setIn(['filter', 'searchWords'], Set(values));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleChangeExcludeWords = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.setIn(['filter', 'excludeWords'], Set(values));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleChangeAggregateUnit = useCallback(
      (value: AggregateUnit) => {
        // 集計期間が変更されたら、期間はそのまま「カスタム」に変更する
        if (aggregateUnit !== value) {
          setDateRangeOption('カスタム');
          const newSearchCondition = searchCondition.setIn(['filter', 'aggregateUnit'], value);
          setSearchCondition(newSearchCondition);
        }
      },
      [aggregateUnit, searchCondition],
    );

    const handleChangeIsEnabledComparison = useCallback(
      (value: boolean) => {
        let newSearchCondition = searchCondition.setIn(['filter', 'isEnabledComparison'], value);
        if (comparisonDateRangeOption !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(
            searchCondition.filter.startDate,
            searchCondition.filter.endDate,
            comparisonDateRangeOption,
            dateRangeOption,
          );
          newSearchCondition = newSearchCondition.mergeIn(['filter'], {
            comparisonStartDate: csd,
            comparisonEndDate: ced,
          });
        }
        setSearchCondition(newSearchCondition);
      },
      [comparisonDateRangeOption, dateRangeOption, searchCondition],
    );

    const handleChangeDateRangeOption = useCallback(
      (value: DateRangeOptionValue) => {
        setDateRangeOption(value);
        // 集計期間のオプションから開始日〜終了日を取得
        const [sd, ed] = getDateRangeFromDateRangeOption(value, startDate, endDate, maxDate);
        // 設定を反映
        let newSearchCondition = searchCondition.mergeIn(['filter'], {
          startDate: sd,
          endDate: ed,
        });

        // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
        if (comparisonDateRangeOption !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, value);
          newSearchCondition = newSearchCondition.mergeIn(['filter'], {
            comparisonStartDate: csd,
            comparisonEndDate: ced,
          });
        }
        setSearchCondition(newSearchCondition);
      },
      [comparisonDateRangeOption, endDate, searchCondition, startDate],
    );

    const handleChangeComparisonDateRangeOption = useCallback(
      (value: ComparisonDateRangeOptionValue) => {
        setComparisonDateRangeOption(value);

        if (value !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(startDate, endDate, value, dateRangeOption);
          const newSearchCondition = searchCondition.mergeIn(['filter'], {
            comparisonStartDate: csd,
            comparisonEndDate: ced,
          });
          setSearchCondition(newSearchCondition);
        }
      },
      [dateRangeOption, endDate, searchCondition, startDate],
    );

    const handleChangeDateRange = useCallback(
      (startDate: Date | null, endDate: Date | null) => {
        if (startDate && endDate) {
          const newDateRangeOption = 'カスタム';
          setDateRangeOption(newDateRangeOption);

          const sd = dayjs(startDate);
          const ed = dayjs(endDate);

          let newSearchCondition = searchCondition.mergeIn(['filter'], {
            startDate: sd,
            endDate: ed,
          });

          // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
          if (comparisonDateRangeOption !== 'カスタム') {
            const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, newDateRangeOption);
            newSearchCondition = newSearchCondition.mergeIn(['filter'], {
              comparisonStartDate: csd,
              comparisonEndDate: ced,
            });
          }
          setSearchCondition(newSearchCondition);
        }
      },
      [comparisonDateRangeOption, searchCondition],
    );

    const handleChangeComparisonDateRange = useCallback(
      (comparisonStartDate: Date | null, comparisonEndDate: Date | null) => {
        setComparisonDateRangeOption('カスタム');
        const csd = dayjs(comparisonStartDate);
        const ced = dayjs(comparisonEndDate);
        const newSearchCondition = searchCondition.mergeIn(['filter'], {
          comparisonStartDate: csd,
          comparisonEndDate: ced,
        });
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleCommitSearchCondition = useCallback(() => {
      commitSearchCondition(searchCondition);
    }, [commitSearchCondition, searchCondition]);

    /**
     * 含むキーワードの部分一致、完全一致を変更する
     */
    const handleOnChangeSearchWordsExactMatch = useCallback((value: boolean) => {
      setSearchCondition((searchCondition) =>
        searchCondition.update('filter', (filter) => filter.set('searchWordsExactMatch', value)),
      );
    }, []);

    /**
     * 除外キーワードの部分一致、完全一致を変更する
     */
    const handleOnChangeExcludeWordsExactMatch = useCallback((value: boolean) => {
      setSearchCondition((searchCondition) =>
        searchCondition.update('filter', (filter) => filter.set('excludeWordsExactMatch', value)),
      );
    }, []);

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <GroupStoreSelect
            group={group}
            storeIds={storeIds}
            showClosedStores={showClosedStores}
            onChange={handleChangeGroupStore}
          />
          <KeywordInputContent>
            <Label>
              含むキーワード
              <RadioContainer>
                <StyledRadio
                  label='部分一致'
                  checked={!searchWordsExactMatch}
                  onChange={() => handleOnChangeSearchWordsExactMatch(false)}
                />
                <StyledRadio
                  label='完全一致'
                  checked={searchWordsExactMatch}
                  onChange={() => handleOnChangeSearchWordsExactMatch(true)}
                />
              </RadioContainer>
            </Label>
            <TagInput values={searchWords.toArray()} onChange={handleChangeSearchWords} />
          </KeywordInputContent>
          <KeywordInputContent>
            <Label>
              除外キーワード
              <RadioContainer>
                <StyledRadio
                  label='部分一致'
                  checked={!excludeWordsExactMatch}
                  onChange={() => handleOnChangeExcludeWordsExactMatch(false)}
                />
                <StyledRadio
                  label='完全一致'
                  checked={excludeWordsExactMatch}
                  onChange={() => handleOnChangeExcludeWordsExactMatch(true)}
                />
              </RadioContainer>
            </Label>
            <TagInput values={excludeWords.toArray()} onChange={handleChangeExcludeWords} />
          </KeywordInputContent>
          <FlexContent>
            <Label>集計単位</Label>
            <AggregateUnitGroup value={aggregateUnit} onChange={handleChangeAggregateUnit} disableDay />
          </FlexContent>
          <PeriodContainer>
            <Label>集計期間</Label>
            <AggregateDateRangePicker
              startDate={startDate}
              endDate={endDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              maxDate={maxDate}
              isEnabledComparison={isEnabledComparison}
              dateRangeOption={dateRangeOption}
              dateRangeOptions={aggregateDurationOptions}
              comparisonDateRangeOption={comparisonDateRangeOption}
              onChangeDateRange={handleChangeDateRange}
              onChangeComparisonDateRange={handleChangeComparisonDateRange}
              onChangeIsEnabledComparison={handleChangeIsEnabledComparison}
              onChangeDateRangeOption={handleChangeDateRangeOption}
              onChangeComparisonDateRangeOption={handleChangeComparisonDateRangeOption}
            />
          </PeriodContainer>
          <CommitButtonContent>
            <CommitButton priority={'high'} onClick={handleCommitSearchCondition} disabled={isCommitDisabled}>
              検索
            </CommitButton>
          </CommitButtonContent>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled(Card)`
  width: 100%;
  margin-bottom: 32px;
  position: relative;
  z-index: 3; /* タブ(z-index=2)より上に表示する */
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FlexContent = styled.div`
  margin-right: 32px;
`;

const KeywordInputContent = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 7px;
  flex-wrap: wrap;
  gap: 4px 16px;
  margin-bottom: 4px;
`;

const PeriodContainer = styled.div``;

const CommitButtonContent = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const CommitButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    align-self: end;
    margin-left: 8px;
    box-shadow: none;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 16px;
`;

const StyledRadio = styled(Radio)`
  &&& {
    label {
      padding-left: 24px;
    }
    input {
      &:checked ~ label:before {
        border: 1px solid ${COLOR.GREEN};
      }
      &:checked ~ label:after {
        background: ${COLOR.GREEN};
      }
      &:focus {
        &:checked ~ label:after {
          background: ${COLOR.GREEN};
        }
      }
    }
  }
`;
