import React from 'react';

import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GmbSpecialHours } from 'models/Domain/GmbLocation/GmbSpecialHours';

import { BulkEditSpecialHours } from './BulkEditSpecialHours';

export type EditSpecialHoursModalProps = {
  open: boolean;
  specialHours: GmbSpecialHours;
  onChange: (speialHours: GmbSpecialHours) => void;
  onApply: () => void;
  onClose: () => void;
};
export const EditSpecialHoursModal: React.FC<EditSpecialHoursModalProps> = ({
  open,
  specialHours,
  onChange,
  onApply,
  onClose,
}) => (
  <Modal open={open} onClose={onClose} closeOnDimmerClick={false}>
    <BulkEditSpecialHours gmbSpecialHours={specialHours} setGmbSpecialHours={onChange} />
    <Modal.Actions>
      <ButtonWrapper>
        <CloseButton onClick={onClose}>キャンセル</CloseButton>
        <ApplyButton priority='high' onClick={onApply} disabled={!specialHours.validate.isValid}>
          入力
        </ApplyButton>
      </ButtonWrapper>
    </Modal.Actions>
  </Modal>
);

const ButtonCommonStyle = css`
  width: fit-content;
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
    margin-left: 20px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;
