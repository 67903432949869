import React from 'react';

import styled from 'styled-components';

import { Thumbnail } from 'components/atoms/Thumbnail';
import { COLOR } from 'style/color';

interface Props {
  className?: string;
  name: string;
  src?: string | null;
  size?: number;
}

/**
 * ユーザアイコンのコンポーネント
 * アイコンが設定されていない場合、名前の先頭2文字を表示する
 */
export const UserThumbnail = React.memo<Props>(({ className, name, src, size = 40 }) => {
  const viewName = name.length <= 2 ? name : name.slice(0, 2);

  return (
    <>
      {src ? (
        <Thumbnail className={className} src={src} size={size} />
      ) : (
        <UserIcon className={className} size={size}>
          {viewName}
        </UserIcon>
      )}
    </>
  );
});

const UserIcon = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  /* 指定されたアイコンサイズの 1/3 をフォントサイズとして指定 */
  font-size: calc(${({ size }) => size}px / 3);
  object-fit: cover;
  background: ${COLOR.BLACK};
  color: ${COLOR.WHITE};
`;
