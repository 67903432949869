import axios from 'axios';

import ApiClient, { ErrorResponse, FaasApiClient, Response, createErrorResponse, createResponse } from 'ApiClient';
import { sleep } from 'helpers/utils';
import { AggregateUnit as MapSearchRanksAggregateUnit } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { AggregateUnit, JSObject } from 'types/Common';
import { GmbAccountGetResponse } from 'types/responses/GmbAccount';
import { GmbAuthPostResponse } from 'types/responses/GmbAuth';
import { GmbLocationDiffsSummaryResponse } from 'types/responses/GmbLocationDiffsSummary';
import { GmbReviewTemplateGetResponse } from 'types/responses/GmbReviewTemplate';

const apiClient = new ApiClient({});
const faasApiClient = new FaasApiClient({});

const GMB_PATH = '/gmb';

const GMB_AUTH_URL = `${GMB_PATH}/auth`;
const GMB_ACCOUNT_URL = `${GMB_PATH}/accounts`;
const GMB_ACCOUNT_DUPLICATE_URL = `${GMB_PATH}/accounts:duplicate`;
const GMB_GROUP_URL = (accountId: string) => `${GMB_PATH}/accounts/${String(accountId)}/business_groups`;
const GMB_LOCATION_URL = (accountId: string, accountName: string) =>
  `${GMB_PATH}/accounts/${String(accountId)}/locations/${String(accountName)}`;
const GMB_PENDING_IMAGE_PATH = `${GMB_PATH}/pending_images`;
const GMB_PENDING_IMAGE_PROCESS_PATH = `${GMB_PENDING_IMAGE_PATH}/process`;
const GMB_CATEGORY_LIST_PATH = `${GMB_PATH}/categories`;
const GMB_LOCATION_VALIDATE_PATH = `${GMB_PATH}/location/validate`;

const GMB_INSIGHT_TOTAL_LIST_PATH = `${GMB_PATH}/insight/total`;
const GMB_INSIGHT_DOWNLOAD_PATH = `${GMB_PATH}/insight/csv/create`;
const GMB_INSIGHT_DOWNLOAD_STATUS_CHECK_PATH = `${GMB_PATH}/insight/csv/status`;

const GMB_INSIGHT_GRAPH_PATH = `${GMB_PATH}/insight/graph`;
const GMB_INSIGHT_SUMMARY_PATH = `${GMB_PATH}/insight/summary`;

const GMB_REMOVE_STORE_CONNECT = (storeId: number) => `${GMB_PATH}/location/${String(storeId)}`;
const GMB_REMOVE_ACCOUNT_URL = (accountId: number) => `${GMB_PATH}/account/${accountId}`;

const GMB_REVIEW_PATH = `${GMB_PATH}/reviews`;
const GMB_REVIEW_DETAIL_PATH = (reviewId: string) => `${GMB_PATH}/reviews/${reviewId}`;
const GMB_REVIEW_STATUS_PATH = `${GMB_PATH}/reviews/status`;
const GMB_REVIEW_REPLY_PATH = `${GMB_PATH}/reviews/reply`;

const GMB_REVIEW_TEMPLATE_BASE_PATH = `${GMB_PATH}/reviews/templates`;
const GMB_REVIEW_TEMPLATE_URL = (organizationId: number) => `${GMB_REVIEW_TEMPLATE_BASE_PATH}/${organizationId}`;
const GMB_REVIEW_TEMPLATE_RESOURCE_URL = (templateId: string, organizationId: number) =>
  `${GMB_REVIEW_TEMPLATE_BASE_PATH}/${organizationId}/${templateId}`;

const GMB_REVIEW_DOWNLOAD_PATH = `${GMB_PATH}/reviews/csv/create`;
const GMB_REVIEW_DOWNLOAD_STATUS_CHECK_PATH = `${GMB_PATH}/reviews/csv/status`;

const GMB_ATTRIBUTES_PATH = `${GMB_PATH}/attributes`;
const GBP_AVAILABLE_URL_TYPES_PATH = 'stores/available_url_types';

const GMB_LOCATION_UPDATED_PATH = `${GMB_PATH}/location/updated`;
const GMB_LOCATION_UPDATES_SUMMARY_PATH = `${GMB_PATH}/location/updated_summary`;
const GMB_LOCATION_LATLNG_PATH = `${GMB_PATH}/location/latlng`;

const GMB_IMPORT_STORES_PATH = `${GMB_PATH}/stores/import`;
const GMB_IMPORT_STORES_STATUS_PATH = `${GMB_PATH}/stores/status`;

const GMB_MAP_SEARCH_RANKS_AVERAGE_GRAPH_PATH = `${GMB_PATH}/map_searchranks/graph:average`;
const GMB_MAP_SEARCH_RANKS_GRAPH_PATH = `${GMB_PATH}/map_searchranks/graph`;
const GMB_MAP_SEARCH_RANKS_TABLE_PATH = `${GMB_PATH}/map_searchranks/table`;
const GMB_MAP_SEARCH_RANKS_CONFIGS_PATH = `${GMB_PATH}/map_searchranks/configs`;
const GMB_MAP_SEARCH_RANKS_COMPETITORS_PATH = `${GMB_PATH}/map_searchranks/competitors`;

const GMB_MAP_SEARCH_RANK_CONFIG_CSV_CREATE_PATH = `${GMB_PATH}/map_searchranks/configs/download/start`;
const GMB_MAP_SEARCH_RANK_CONFIG_CSV_STATUS_PATH = `${GMB_PATH}/map_searchranks/configs/download/status`;
const GMB_MAP_SEARCH_RANK_CONFIG_CSV_IMPORT_START_PATH = `${GMB_PATH}/map_searchranks/configs/import/start`;
const GMB_MAP_SEARCH_RANK_CONFIG_CSV_IMPORT_STATUS_PATH = `${GMB_PATH}/map_searchranks/configs/import/status`;

export class GmbAuthApi {
  static post(params: JSObject) {
    return apiClient.post<GmbAuthPostResponse>(GMB_AUTH_URL, params);
  }
}

export class GmbAccountApi {
  static post(params: JSObject) {
    return apiClient.post<null>(GMB_ACCOUNT_URL, params);
  }

  static get() {
    return apiClient.get<GmbAccountGetResponse>(GMB_ACCOUNT_URL);
  }

  static deleteStoreConnect(storeId: number) {
    return apiClient.delete(GMB_REMOVE_STORE_CONNECT(storeId));
  }

  static delete(accountId: number) {
    return apiClient.delete(GMB_REMOVE_ACCOUNT_URL(accountId));
  }

  static duplicate(params: { email: string }) {
    return apiClient.post<null>(GMB_ACCOUNT_DUPLICATE_URL, params);
  }
}

// FIXME: レスポンスの中身
export type GmbGroupApiResponse = JSObject;

export class GmbGroupApi {
  static get(accountId: string) {
    return apiClient.get<GmbGroupApiResponse>(GMB_GROUP_URL(accountId));
  }
}

// FIXME: レスポンスの中身
export type GmbLocationApiResponse = JSObject;

export class GmbLocationApi {
  static get(accountId: string, accountName: string) {
    return apiClient.get<GmbLocationApiResponse>(GMB_LOCATION_URL(accountId, accountName));
  }
}

export class GmbPendingImageApi {
  static get() {
    return apiClient.get(GMB_PENDING_IMAGE_PATH);
  }

  static post(params: JSObject) {
    return apiClient.post(GMB_PENDING_IMAGE_PROCESS_PATH, params);
  }
}

type GmbCategoryListApiResponse = {
  categoryId: string;
  displayName: string;
}[];

export class GmbCategoryListApi {
  static get() {
    return apiClient.get<GmbCategoryListApiResponse>(GMB_CATEGORY_LIST_PATH);
  }
}

type CategoryApiResponse = {
  categoryId: string;
  displayName: string;
  serviceTypes: {
    displayName: string;
    serviceTypeId: string;
  }[];
  moreHoursTypes: {
    displayName: string;
    hoursTypeId: string;
    localizedDisplayName: string;
  }[];
  attributes: {
    attributeId: string;
    displayName: string;
    groupDisplayName: string;
    isDeprecated: boolean | null;
    isRepeatable: boolean | null;
    valueMetadata: { value: string | boolean; displayName: string }[];
    valueType: 'BOOL' | 'ENUM' | 'REPEATED_ENUM' | 'URL';
  }[];
};

export class CategoryApi {
  static get(categoryId: string) {
    return apiClient.get<CategoryApiResponse>(`${GMB_CATEGORY_LIST_PATH}/${categoryId}`);
  }
}

export class GmbAttributeListApi {
  static get(categoryId: string) {
    return apiClient.get(GMB_ATTRIBUTES_PATH, { category_id: categoryId });
  }
}

export type GbpAvailableUrlTypesApiResponse = {
  items: {
    store_id: number;
    available_url_types: string[];
  }[];
};

export class GbpAvailableUrlTypesApi {
  static get(params: JSObject) {
    return apiClient.get<GbpAvailableUrlTypesApiResponse>(GBP_AVAILABLE_URL_TYPES_PATH, params);
  }
}

export type GmbLocationValidateApiResponse = {
  status: 'success' | 'error';
  validate_status: 'success' | 'error';
  error: {
    message: string;
    summaries: {
      field: string;
      require_manual_setting: boolean;
    }[];
    details: {
      code: number;
      field: string;
      message: string;
      subErrorCode: number;
    };
  };
};

export class GmbLocationValidateApi {
  static post(params: JSObject) {
    return apiClient.post<GmbLocationValidateApiResponse>(GMB_LOCATION_VALIDATE_PATH, params);
  }
}

// FIXME: レスポンスの中身
export type GmbInsightTotalListApiResponse = JSObject[];

export class GmbInsightTotalListApi {
  static get(params: JSObject) {
    return apiClient.get<GmbInsightTotalListApiResponse>(GMB_INSIGHT_TOTAL_LIST_PATH, params);
  }
}

/**
 * GBPインサイトのCSVダウンロードAPIのレスポンス
 */
type GbpInsightDownloadResponse = {
  executionArn: string;
};

export class GbpInsightDownloadApi {
  static post(params: JSObject) {
    return faasApiClient.post<GbpInsightDownloadResponse>(GMB_INSIGHT_DOWNLOAD_PATH, params);
  }
}

/**
 * GBPインサイトのCSVダウンロードAPIのレスポンス
 */
type GbpInsightDownloadStatusCheckResponse = {
  status: string;
  download_url: string;
  message: string;
};

export class GbpInsightDownloadStatusCheckApi {
  static post(params: JSObject) {
    return faasApiClient.post<GbpInsightDownloadStatusCheckResponse>(GMB_INSIGHT_DOWNLOAD_STATUS_CHECK_PATH, params);
  }
}

/**
 * GBPインサイト画面のグラフ部分のデータを取得するAPIのパラメータ
 */
export type GbpInsightGraphRequestParams = {
  unit: 'day' | 'week' | 'month';
  from_date: string;
  to_date: string;
  store_ids?: number[];
};

/**
 * GBPインサイト画面のグラフ部分のデータを取得するAPIのレスポンス
 */
type GbpInsightGraphResponse = {
  list: {
    date: string;
    stats: {
      queries_direct: number;
      queries_indirect: number;
      queries_chain: number;
      rate_count: number;
      comment_count: number;
      actions_website: number;
      actions_phone: number;
      actions_driving_directions: number;
    };
  }[];
  last_update_at: string;
};

/**
 * GBPインサイト画面のグラフ部分のデータを取得するAPI
 */
export class GbpInsightGraphApi {
  static get(params: GbpInsightGraphRequestParams) {
    const _params: any = { ...params };
    // 数字の配列をカンマ区切りの文字列に変換
    if (params.store_ids) {
      _params.store_ids = params.store_ids.join(',');
    }
    return apiClient.get<GbpInsightGraphResponse>(GMB_INSIGHT_GRAPH_PATH, _params);
  }
}

/**
 * GBPインサイト画面の表部分のデータを取得するAPIのパラメータ
 */
export type GbpInsightSummaryRequestParams = {
  from_date: string;
  to_date: string;
  store_ids?: number[];
};

/**
 * GBPインサイト画面の表部分のデータを取得するAPIのレスポンス
 */
type GbpInsightSummaryResponse = {
  period: {
    from_date: string;
    end_date: string;
  };
  stores: {
    store_id: number;
    period: {
      from_date: string;
      end_date: string;
    };
    stats: {
      queries_direct: number;
      queries_indirect: number;
      queries_chain: number;
      views_maps: number;
      views_search: number;
      actions_website: number;
      actions_phone: number;
      actions_driving_directions: number;
      photos_views_merchant: number;
      photos_views_customers: number;
      photos_count_merchant: number;
      photos_count_customers: number;
      local_post_views_search: number;
      local_post_actions_call_to_action: number;
      rate_count: number;
      comment_count: number;
    };
  }[];
};

/**
 * GBPインサイト画面の表部分のデータを取得するAPI
 */
export class GbpInsightSummaryApi {
  static get(params: GbpInsightSummaryRequestParams) {
    const _params: any = { ...params };
    // 数字の配列をカンマ区切りの文字列に変換
    if (params.store_ids) {
      _params.store_ids = params.store_ids.join(',');
    }
    return apiClient.get<GbpInsightSummaryResponse>(GMB_INSIGHT_SUMMARY_PATH, _params);
  }
}

export type GetGmbReviewRequestParams = {
  store_id: number[];
  has_comment: boolean;
  no_comment: boolean;
  done: boolean;
  todo: boolean;
  not_replied: boolean;
  start_date: string;
  end_date: string;
  star_ratings: (1 | 2 | 3 | 4 | 5)[];
  sort_key: 'date' | 'rating';
  sort_order: 'asc' | 'desc';
  limit: number;
  page: number;
};

// FIXME: レスポンスの中身
export type GetGmbReviewRequestResponse = JSObject;

export class GmbReviewApi {
  static get(params: GetGmbReviewRequestParams) {
    const storeIdsStr = (params.store_id || []).join(',');
    const starRatingsStr = (params.star_ratings || []).join(',');
    return apiClient.get<GetGmbReviewRequestResponse>(GMB_REVIEW_PATH, {
      ...params,
      store_id: storeIdsStr,
      star_ratings: starRatingsStr,
    });
  }
}

// FIXME: レスポンスの中身
export type GmbReviewDetailApiResponse = JSObject;

export class GmbReviewDetailApi {
  static get(reviewId: string) {
    return apiClient.get<GmbReviewDetailApiResponse>(GMB_REVIEW_DETAIL_PATH(reviewId));
  }
}

export class GmbReviewReplyApi {
  static post(params: JSObject = {}) {
    return apiClient.post(GMB_REVIEW_REPLY_PATH, params);
  }
}

export class GmbReviewStatusApi {
  static put(params: JSObject = {}) {
    return apiClient.put(GMB_REVIEW_STATUS_PATH, params);
  }
}

export class GmbReviewTemplateApi {
  static post(organizationId: number, params: JSObject) {
    return apiClient.post(GMB_REVIEW_TEMPLATE_URL(organizationId), params);
  }

  static put(templateId: string, organizationId: number, params: JSObject) {
    return apiClient.put(GMB_REVIEW_TEMPLATE_RESOURCE_URL(templateId, organizationId), params);
  }

  static get(organizationId: number) {
    return apiClient.get<GmbReviewTemplateGetResponse>(GMB_REVIEW_TEMPLATE_URL(organizationId));
  }

  static delete(templateId: string, organizationId: number) {
    return apiClient.delete(GMB_REVIEW_TEMPLATE_RESOURCE_URL(templateId, organizationId));
  }
}

type GmbReviewDownloadApiResponse = {
  executionArn: string;
};

export class GmbReviewDownloadApi {
  static post(params: JSObject) {
    return faasApiClient.post<GmbReviewDownloadApiResponse>(GMB_REVIEW_DOWNLOAD_PATH, params);
  }
}

type GmbReviewDownloadStatusCheckApiResponse = {
  status: 'RUNNING' | 'SUCCEEDED';
  download_url: string | null;
  message: string;
};

export class GmbReviewDownloadStatusCheckApi {
  static post(params: JSObject) {
    return faasApiClient.post<GmbReviewDownloadStatusCheckApiResponse>(GMB_REVIEW_DOWNLOAD_STATUS_CHECK_PATH, params);
  }
}

export class GmbLocationUpdatedApi {
  static get() {
    return apiClient.get(GMB_LOCATION_UPDATED_PATH);
  }
}

export class GmbLocationDiffsSummaryApi {
  static get() {
    return apiClient.get<GmbLocationDiffsSummaryResponse>(GMB_LOCATION_UPDATES_SUMMARY_PATH);
  }
}

/**
 * 店舗詳細画面でGBP側の緯度経度を取り込むボタン押下時に叩くfaasのエンドポイント
 *
 * 対象店舗のIDを元にGBP APIへ問い合わせて
 * LocationまたはLocationUpdatedから緯度経度の情報を取得
 * mongoを更新して緯度経度の情報がレスポンスとして返る。
 *
 * Location・LocationUpdatedの両方で緯度経度がなかった場合にはnullが返る
 */
export class GmbLocationLatlngApi {
  static get(params: { store_id: number }) {
    return faasApiClient.get(GMB_LOCATION_LATLNG_PATH, params);
  }
}

type ImportGBPStoreApiParams = {
  account_id: number;
  location_ids: string[];
};

type ImportGBPStoreApiResponse = {
  executionArn: string;
};

export class ImportGBPStoreApi {
  static post(params: ImportGBPStoreApiParams) {
    return faasApiClient.post<ImportGBPStoreApiResponse>(GMB_IMPORT_STORES_PATH, params);
  }
}

type ImportGBPStoreStatusApiParams = {
  executionArn: string;
};

type ImportGBPStoreStatusApiResponse = {
  status: 'RUNNING' | 'SUCCEEDED' | 'FAILED';
  message: string;
};

export class ImportGBPStoreStatusApi {
  static post(params: ImportGBPStoreStatusApiParams) {
    return faasApiClient.post<ImportGBPStoreStatusApiResponse>(GMB_IMPORT_STORES_STATUS_PATH, params);
  }
}

export type MapSearchRanksAverageGraphApiParams = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  start_date: string;
  end_date: string;
  store_ids?: string;
  search_words?: string;
  match_search_words?: string;
  exclude_words?: string;
  match_exclude_words?: string;
};

export type MapSearchRanksAverageGraphApiResponse = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  stats: {
    period: {
      start_date: string;
      end_date: string;
    };
    rank: number | null;
  }[];
};

export class MapSearchRanksAverageGraphApi {
  static get(params: MapSearchRanksAverageGraphApiParams) {
    return apiClient.get<MapSearchRanksAverageGraphApiResponse>(GMB_MAP_SEARCH_RANKS_AVERAGE_GRAPH_PATH, params, {
      cache: true,
    });
  }
}

export type MapSearchRanksGraphParams = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  start_date: string;
  end_date: string;
  config_ids: string;
};

export type MapSearchRanksGraphResponse = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  items: {
    config_id: number;
    store_id: number;
    store_name: string;
    area_name: string;
    search_word: string;
    stats: {
      period: {
        start_date: string;
        end_date: string;
      };
      rank: number | null;
    }[];
  }[];
};

export class MapSearchRanksGraphApi {
  static get(params: MapSearchRanksGraphParams) {
    return apiClient.get<MapSearchRanksGraphResponse>(GMB_MAP_SEARCH_RANKS_GRAPH_PATH, params, { cache: true });
  }
}

export type MapSearchRanksTableParams = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  start_date: string;
  end_date: string;
  start_date_compare: string;
  end_date_compare: string;
  store_ids: string;
  search_words?: string;
  match_search_words?: string;
  exclude_words?: string;
  match_exclude_words?: string;

  sort?: string;
  limit?: number;
  page?: number;
};

export type MapSearchRanksTableResponse = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  period: {
    start_date: string;
    end_date: string;
  };
  comparison_period: {
    start_date: string;
    end_date: string;
  };
  average: {
    rank: number | null;
    rank_comparison: number | null;
    diff: number | null;
    diff_comparison: number | null;
    latest_rank: number | null;
  };
  items: {
    config_id: number;
    store_name: string;
    area_name: string;
    search_word: string;
    map_url: string;
    rank: number | null;
    rank_comparison?: number;
    diff: number | null;
    diff_comparison?: number;
    latest_rank: number | null;
  }[];
  pagination: {
    limit: number;
    current_page: number;
    has_next: boolean;
    total_count: number;
  };
};

export class MapSearchRanksTableApi {
  static get(params: MapSearchRanksTableParams) {
    return apiClient.get<MapSearchRanksTableResponse>(GMB_MAP_SEARCH_RANKS_TABLE_PATH, params, { cache: true });
  }
}

export type MapSearchRanksCompetitorsParams = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  start_date: string;
  end_date: string;
  comparison_start_date?: string;
  comparison_end_date?: string;
  config_id: number;
  contains_all: '1' | '0';
};

export type MapSearchRanksCompetitorsResponse = {
  aggregate_unit: MapSearchRanksAggregateUnit;
  period: {
    start_date: string;
    end_date: string;
  };
  comparison_period: {
    start_date: string;
    end_date: string;
  };
  table_items: {
    config_id: number;
    place_id: string;
    store_name: string;
    search_word: string;
    area_name: string;
    rank: number | null;
    rank_has_data?: boolean | null;
    rank_comparison?: number | null;
    rank_comparison_has_data?: boolean | null;
    diff: number | null;
    diff_comparison?: number | null;
    latest_rank: number | null;
  }[];
  graph_items: {
    config_id: number;
    place_id: string;
    store_name: string;
    area_name: string;
    search_word: string;
    stats: {
      period: {
        start_date: string;
        end_date: string;
      };
      rank: number | null;
      has_data?: boolean;
    }[];
  }[];
  comparison_graph_items?: {
    config_id: number;
    place_id: string;
    store_name: string;
    area_name: string;
    search_word: string;
    stats: {
      period: {
        start_date: string;
        end_date: string;
      };
      rank: number | null;
      has_data?: boolean;
    }[];
  }[];
};

export class MapSearchRanksCompetitorsApi {
  static get(params: MapSearchRanksCompetitorsParams) {
    return apiClient.get<MapSearchRanksCompetitorsResponse>(GMB_MAP_SEARCH_RANKS_COMPETITORS_PATH, params);
  }
}

export type MapSearchRankConfigParams = {
  store_id: string;
  is_deleted: '0' | null;
  sort_key: 'store_id' | 'create_at';
  sort_order: 'asc' | 'desc';
  limit?: number;
  page?: number;
};

export type MapSearchRankConfigResponse = {
  pagination: {
    limit: number;
    current_page: number;
    has_next: boolean;
    total_count: number;
  };
  items: {
    id: number;
    search_word: string;
    area_name: string;
    latitude: number;
    longitude: number;
    zoom: number;
  }[];
};

export class MapSearchRankConfigApi {
  static get(params: MapSearchRankConfigParams, cache = false) {
    return apiClient.get<MapSearchRankConfigResponse>(GMB_MAP_SEARCH_RANKS_CONFIGS_PATH, params, { cache });
  }
}

/**
 * 検索順位監視設定CSVデータを作成するAPIのパラメータ
 */
export type MapSearchRankConfigCSVCreateParams = {
  store_ids: number[];
  is_deleted: null | false;
};

/**
 * 検索順位監視設定CSVデータ作成APIのレスポンス
 */
type MapSearchRankConfigCSVCreateResponse = {
  executionArn: string;
};

export class MapSearchRankConfigCSVCreateApi {
  static post(params: MapSearchRankConfigCSVCreateParams) {
    return faasApiClient.post<MapSearchRankConfigCSVCreateResponse>(GMB_MAP_SEARCH_RANK_CONFIG_CSV_CREATE_PATH, params);
  }
}

export type MapSearchRankConfigCSVStatusParams = {
  executionArn: string;
};

/**
 * 検索順位監視設定CSVデータ作成ステータス確認APIのレスポンス
 */
type MapSearchRankConfigCSVStatusResponse = {
  status: string;
  download_url: string;
  message: string;
};

export class MapSearchRankConfigCSVStatusApi {
  static post(params: MapSearchRankConfigCSVStatusParams) {
    return faasApiClient.post<MapSearchRankConfigCSVStatusResponse>(GMB_MAP_SEARCH_RANK_CONFIG_CSV_STATUS_PATH, params);
  }
}

/**
 * 検索順位取得設定インポートAPIのレスポンス
 */
export type MapSearchRankConfigCSVImportApiResponse = {
  execution_arn: string;
};

/**
 * 検索順位取得設定インポートAPI
 */
export class MapSearchRankConfigCSVImportApi {
  static post({ csv, validate_only }: { csv: File; validate_only: boolean }) {
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('validate_only', validate_only ? '1' : '0');
    return faasApiClient.post<MapSearchRankConfigCSVImportApiResponse>(
      GMB_MAP_SEARCH_RANK_CONFIG_CSV_IMPORT_START_PATH,
      formData,
    );
  }
}

/**
 * 検索順位取得設定インポートAPIのレスポンス
 */
export type MapSearchRankConfigCSVValidateStatusCheckResponse = {
  status: 'SUCCEEDED' | 'FAILED' | 'RUNNING';
  result: {
    message: string;
    total_succeed: number;
    total_failed: number;
    total_warning: number;
    validation_status: 'success' | 'failed';
    validation_errors: {
      message: string;
      errors: {
        row: number;
        messages: string[];
      }[];
    };
    validation_warnings:
      | {
          message: string;
          warnings: {
            row: number;
            messages: string[];
          };
        }[]
      | null;
  };
};

export type MapSearchRankConfigCSVImportStatusCheckResponse = {
  status: 'SUCCEEDED' | 'FAILED' | 'RUNNING';
  result: {
    message: string;
  };
};

/**
 * 検索順位取得設定インポートステータス確認API
 */
export class MapSearchRankConfigCSVImportCheckApi {
  /**
   * バリデーションのステータスチェック
   */
  static checkValidateStatus(params: { executionArn: string }) {
    return faasApiClient.post<MapSearchRankConfigCSVValidateStatusCheckResponse>(
      GMB_MAP_SEARCH_RANK_CONFIG_CSV_IMPORT_STATUS_PATH,
      params,
    );
  }

  /**
   * インポートのステータスチェック
   */
  static checkImportStatus(params: { executionArn: string }) {
    return faasApiClient.post<MapSearchRankConfigCSVImportStatusCheckResponse>(
      GMB_MAP_SEARCH_RANK_CONFIG_CSV_IMPORT_STATUS_PATH,
      params,
    );
  }
}

export type MapSearchRanksCompetitorsAverageStartParams = {
  aggregate_unit: AggregateUnit;
  store_ids: number[];
  search_words?: string[];
  match_search_words?: string[];
  exclude_words?: string[];
  match_exclude_words?: string[];

  period: {
    start_date: string;
    end_date: string;
  };
  comparison_period?: {
    start_date: string;
    end_date: string;
  };
};

export type MapSearchRanksCompetitorsAverageStartResponse = {
  executionArn: string;
};

export type MapSearchRanksCompetitorsAverageStatusParams = {
  executionArn: string;
};

export type MapSearchRanksCompetitorsAverageStatusResponse =
  | MapSearchRanksCompetitorsAverageStatusRunning
  | MapSearchRanksCompetitorsAverageStatusSucceeded
  | MapSearchRanksCompetitorsAverageStatusFailed;

export type MapSearchRanksCompetitorsAverageStatusRunning = {
  status: 'RUNNING';
  download_url: null;
  message: '';
};
export type MapSearchRanksCompetitorsAverageStatusSucceeded = {
  status: 'SUCCEEDED';
  download_url: string;
  message: '';
};
export type MapSearchRanksCompetitorsAverageStatusFailed = {
  status: 'Failed';
  message: string;
};

export type MapSearchRankCompetitorAverageData = {
  condition: {
    aggregate_unit: AggregateUnit;
    organization_id: number;
    store_ids: number[];
    search_words: string[];
    period: {
      start_date: string;
      end_date: string;
    };
    comparison_period: {
      start_date: string;
      end_date: string;
    };
  };
  table_items: {
    tag: string;
    store_count: number;
    is_competitor: boolean;
    rank: number | null;
    rank_has_data: boolean;
    rank_comparison: number | null;
    rank_comparison_has_data: boolean;
    diff: number | null;
    diff_comparison: number | null;
    latest_rank: number | null;
  }[];
  graph_items: {
    tag: string;
    is_competitor: boolean;
    stats: {
      period: {
        start_date: string;
        end_date: string;
      };
      rank: number;
      has_data: boolean;
    }[];
  }[];
  comparison_graph_items: {
    tag: string;
    is_competitor: boolean;
    stats: {
      period: {
        start_date: string;
        end_date: string;
      };
      rank: number;
      has_data: boolean;
    }[];
  }[];
};

export class MapSearchRanksCompetitorsAverageApi {
  static endpoint = '/gmb/map_searchranks/competitors:average';

  static async get(
    params: MapSearchRanksCompetitorsAverageStartParams,
  ): Promise<Response<MapSearchRankCompetitorAverageData> | ErrorResponse> {
    const startResponse = await faasApiClient.post<MapSearchRanksCompetitorsAverageStartResponse>(
      `${this.endpoint}/start`,
      params,
    );

    if (!startResponse.isSuccess) {
      return startResponse;
    }

    const executionArn = startResponse.data.executionArn;
    let url: string;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const statusResponse = await faasApiClient.post<MapSearchRanksCompetitorsAverageStatusResponse>(
        `${this.endpoint}/status`,
        { executionArn },
      );

      if (!statusResponse.isSuccess) {
        return statusResponse;
      }

      const data = statusResponse.data;
      if (data.status === 'RUNNING') {
        // 実行中なので、3秒後に再度チェックする
        await sleep(3000);
        continue;
      } else if (data.status === 'SUCCEEDED') {
        // 取得が完了したのでループを抜ける
        url = data.download_url;
        break;
      } else {
        return createErrorResponse({ message: statusResponse.data.message });
      }
    }

    const dataResponse = await axios.get<MapSearchRankCompetitorAverageData>(url, { cache: true });
    if (!dataResponse.data) {
      return createErrorResponse({ message: 'データの取得に失敗しました。' });
    }
    return createResponse(dataResponse.data);
  }
}
