import ApiClient from 'ApiClient';
import { JSObject } from 'types/Common';
import { TaskResponse } from 'types/responses/Tasks';

const apiClient = new ApiClient({});

const TASKS_PATH = '/tasks';
const TASKS_GET_PATH = (taskId: number) => `${TASKS_PATH}/${String(taskId)}`;
const TASKS_OFFER_BULK_PATH = (taskId: number) => `${TASKS_PATH}/${String(taskId)}/offer`;

export class TasksApi {
  static get(taskId: number) {
    return apiClient.get<TaskResponse>(TASKS_GET_PATH(taskId));
  }

  static post(params: JSObject) {
    return apiClient.post(TASKS_PATH, params);
  }
}

export class TasksOfferBulkApi {
  static post(taskId: number, params: JSObject) {
    return apiClient.post(TASKS_OFFER_BULK_PATH(taskId), params);
  }
}
