import React from 'react';

import { TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { COLOR } from 'style/color';

const GOOGLE_POLICY_URL = 'https://support.google.com/contributionpolicy/answer/7400114';

export const GmbReviewReplyConfirmModal: React.FC<{
  className?: string;
  open: boolean;
  sendReplyComment: () => void;
  onClose: () => void;
  text: string;
}> = ({ className, sendReplyComment, open, onClose, text }) => {
  return (
    <TransitionablePortal
      open={open}
      transition={{
        animation: 'fly up',
        duration: 0,
      }}
    >
      <>
        <PortalWrapper>
          <ConfirmWrapper>
            <ConfirmTitle>クチコミに返信しようとしています</ConfirmTitle>
            <ConfirmReplyText>{text}</ConfirmReplyText>
            <ConfirmText>
              返信内容はGoogleで公開されます。
              <br />
              また、
              <ExternalLink href={GOOGLE_POLICY_URL}>Googleローカルリスティングのコンテンツポリシー</ExternalLink>
              に
              <br />
              従っている必要があります。
            </ConfirmText>
            <ConfirmButton>
              <ConfirmCancelButton onClick={() => onClose()}>キャンセル</ConfirmCancelButton>
              <ConfirmSendButton priority='high' onClick={() => sendReplyComment()}>
                送信する
              </ConfirmSendButton>
            </ConfirmButton>
          </ConfirmWrapper>
        </PortalWrapper>
      </>
    </TransitionablePortal>
  );
};

const PortalWrapper = styled.div`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ConfirmWrapper = styled.div`
  &&& {
    background-color: ${COLOR.WHITE};
    width: 95%;
    max-width: 600px;
    padding: 24px;
    border-radius: 0.28571429rem;
    max-height: 100vh;
    overflow-y: scroll;
    @media only screen and (min-width: 768px) {
      width: 88%;
      margin: 0;
    }
  }
`;

const ConfirmTitle = styled.div`
  &&& {
    font-size: 18px;
    font-weight: bold;
  }
`;

const ConfirmText = styled.div`
  &&& {
    font-size: 14px;
  }
  margin-bottom: 8px;
`;

const ConfirmReplyText = styled.div`
  &&& {
    background-color: ${COLOR.LIGHT_GRAY};
    border-radius: 5px;
    margin: 15px 0;
    white-space: pre-wrap;
    font-size: 14px;
    padding: 8px;
  }
`;

const ConfirmButton = styled.div`
  display: flex;
  justify-content: center;
`;

const ConfirmSendButton = styled(Button)`
  &&& {
    margin-top: 10px;
    width: 154px;
    margin-left: 6px;
  }
`;

const ConfirmCancelButton = styled(Button)`
  &&& {
    margin-top: 10px;
    width: 154px;
    margin-right: 6px;
  }
`;
