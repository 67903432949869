import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

import { getDiffText } from '.';

type Props = {
  className?: string;
  label: string;
  value: number;
  diff?: number;
};

const getRateText = (value: number) => (!isFinite(value) ? 'ー' : `${value.toFixed(2)}%`);

export const TransitionCard = React.memo<Props>(({ className, label, value, diff }) => {
  return (
    <Wrapper className={className}>
      <Label>{label}</Label>
      <Value>{getRateText(value)}</Value>
      {diff != null && <Diff diff={diff}>{getDiffText(diff)}</Diff>}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid ${COLOR.LIGHT_GRAY};
  padding: 4px 8px;
  background: ${COLOR.WHITE};
`;

const Label = styled.div`
  position: absolute;
  text-align: left;
  font-size: 14px;
  line-height: 2.33;
  white-space: nowrap;
  @media (max-width: 700px) {
    white-space: pre-wrap;
  }
`;

const Value = styled.div`
  font-weight: bold;
  text-align: right;
  font-size: 18px;
  white-space: nowrap;
`;

const Diff = styled.div<{ diff: number }>`
  text-align: right;
  font-size: 14px;
  color: ${({ diff }) => (!isFinite(diff) ? COLOR.DARK_GRAY : diff >= 0 ? COLOR.GREEN : COLOR.RED)};
`;
