import { Record as ImmutableRecord } from 'immutable';

const MIN_RANK = 1;
const MAX_RANK = 60;

const MAX_REVIEW = 5;

export class GraphSettings extends ImmutableRecord<{
  min: number | 'auto' | 'dataMin';
  max: number | 'auto' | 'dataMax';
}>({
  min: 0,
  max: 'auto',
}) {
  get minValue() {
    return typeof this.min === 'number' ? this.min : undefined;
  }

  get maxValue() {
    return typeof this.max === 'number' ? this.max : undefined;
  }

  get domain(): any {
    if (this.min === 'auto' || this.max === 'auto') {
      // 片方に'auto'が設定されていたら、調整はrechartsに任せる
      return [this.min, this.max];
    }

    // 'auto'が設定されていない場合は、設定された値が最小値<最大値になるようにして返す
    return ([dataMin, dataMax]: [number, number]) => {
      // 入力されたminValueとmaxValueはminValue < maxValueとは限らない
      const v1 = this.minValue ?? dataMin;
      const v2 = this.maxValue ?? dataMax;
      // 最小値は切り捨て、最大値は切り上げして整数にする
      return [Math.floor(Math.min(v1, v2)), Math.ceil(Math.max(v1, v2))];
    };
  }

  get rateDomain(): any {
    // グラフがパーセント表記なので、入力値はパーセントとして扱う

    // 'auto'が設定されていたら、調整はrechartsに任せる
    if (this.min === 'auto' || this.max === 'auto') {
      const v1 = this.minValue ? this.minValue / 100 : this.min;
      const v2 = this.maxValue ? this.maxValue / 100 : this.max;
      return [v1, v2];
    }

    // 'auto'が設定されていない場合は、設定された値が最小値<最大値になるようにして返す
    return ([dataMin, dataMax]: [number, number]) => {
      // 入力されたminValueとmaxValueはminValue < maxValueとは限らない
      const v1 = this.minValue ?? dataMin;
      const v2 = this.maxValue ?? dataMax;
      // 最小値は切り捨て、最大値は切り上げして整数のパーセントにしてから100で割る
      return [Math.floor(Math.min(v1, v2)) / 100, Math.ceil(Math.max(v1, v2)) / 100];
    };
  }

  // 順位向けのdomain
  get rankDomain(): any {
    return ([dataMin, dataMax]: [number, number]) => {
      // 入力されたminValueとmaxValueはminValue < maxValueとは限らない
      const v1 = this.minValue ?? dataMin;
      const v2 = this.maxValue ?? dataMax;
      let min = Math.min(v1, v2);
      let max = Math.max(v1, v2);
      // 'auto'が含まれている場合の調整をrechartsに任せると、順位の範囲外が表示されてしまう
      // 'auto'の場合、最小値は1小さく、最大値は1大きくした値にする
      if (this.min === 'auto') {
        min -= 1;
      }
      if (this.max === 'auto') {
        max += 1;
      }
      // 最小値は切り捨て、最大値は切り上げして整数にする
      // 範囲はMIN_RANKからMAX_RANKの範囲に制限する
      return [
        Math.min(Math.max(Math.floor(min), MIN_RANK), MAX_RANK),
        Math.max(Math.min(Math.ceil(max), MAX_RANK), MIN_RANK),
      ];
    };
  }

  getRankTicks(minRank: number, maxRank: number) {
    // Y軸に表示する順位の1と5の倍数の配列を、表示されるべき範囲のみ返す
    // rechartsの仕組みに乗っていないので、最小値と最大値はデータから求めたものを渡す
    const interval = 5;
    // 入力されたminValueとmaxValueはminValue < maxValueとは限らない
    const v1 = this.minValue ?? minRank;
    const v2 = this.maxValue ?? maxRank;
    // 最小値は切り捨て、最大値は切り上げして整数にする
    const minValue = Math.floor(Math.min(v1, v2));
    const maxValue = Math.ceil(Math.max(v1, v2));

    // 最小値と最大値の差がinterval未満の場合はrechartsに任せる
    if (maxValue - minValue < interval) {
      return undefined;
    }
    const result = [1];
    for (let i = interval; i < MAX_RANK; i += interval) {
      result.push(i);
    }
    // 最小値以上最大値以下に絞り込む
    return result.filter((value) => minValue <= value && value <= maxValue);
  }

  // クチコミ評価向けのdomain
  get ratingDomain(): any {
    return ([dataMin, dataMax]: [number, number]) => {
      // 入力されたminValueとmaxValueはminValue < maxValueとは限らない
      const v1 = this.minValue ?? dataMin;
      const v2 = this.maxValue ?? dataMax;
      let min = Math.min(v1, v2);
      let max = Math.max(v1, v2);
      // 'auto'が含まれている場合の調整をrechartsに任せると、順位の範囲外が表示されてしまう
      // 'auto'の場合、最小値は1小さく、最大値は1大きくした値にする
      if (this.min === 'auto') {
        min -= 1;
      }
      if (this.max === 'auto') {
        max += 1;
      }
      // 最小値は切り捨て、最大値は切り上げして整数にする
      // 範囲は0からMAX_RANKの範囲に制限する
      return [Math.min(Math.max(Math.floor(min), 0), MAX_REVIEW), Math.max(Math.min(Math.ceil(max), MAX_REVIEW), 0)];
    };
  }
}
