import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export interface ExecutionArnStatusRecord {
  executionArn: string;
}

export class ExecutionArnStatus extends Record<ExecutionArnStatusRecord>({
  executionArn: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  setExecutionArn(executionArn: string) {
    return this.set('executionArn', executionArn);
  }
}
