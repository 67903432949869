import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { User } from 'models/Domain/User';
import { JSObject } from 'types/Common';

export class ImageComment extends Record<{
  id: number;
  text: string;
  x_axis_ratio: number;
  y_axis_ratio: number;
  order_num: number;
}>({
  id: 0,
  text: '',
  x_axis_ratio: 0,
  y_axis_ratio: 0,
  order_num: 0,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get hasPoint() {
    return this.x_axis_ratio > 0 && this.y_axis_ratio > 0;
  }

  get xAxisPercent() {
    return this.x_axis_ratio * 100;
  }

  get yAxisPercent() {
    return this.y_axis_ratio * 100;
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  setPoint(x: number, y: number) {
    return this.merge({ x_axis_ratio: x, y_axis_ratio: y });
  }

  setText(text: string) {
    return this.set('text', text);
  }

  validate() {
    const errors: JSObject = {};

    if (!this.hasPoint) {
      errors.point = ErrorType.REQUIRED_ERROR;
    }

    if (!this.text) {
      errors.text = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }

  requestParams() {
    const { id, text, x_axis_ratio, y_axis_ratio } = this.toObject();
    const params = { id: id < 1 ? null : id, text, x_axis_ratio, y_axis_ratio };
    return params;
  }
}

export class OfferImageComment extends Record<{
  id: number;
  offer_id: number;
  image_url: string;
  created_by: User;
  create_at: Dayjs;
  image_comments: List<ImageComment>;
}>({
  id: 0,
  offer_id: 0,
  image_url: '',
  created_by: new User(),
  create_at: dayjs(),
  image_comments: List(),
}) {
  constructor(data: JSObject = { image_comments: [] }) {
    const params = { ...data };
    params.created_by = new User(params.created_by);
    params.create_at = dayjs.unix(params.create_at);
    params.image_comments = List(params.image_comments.map((d: JSObject) => new ImageComment(d)));
    super(params);
  }

  get hasPoint() {
    return !this.image_comments.isEmpty() && this.image_comments.last<ImageComment>().hasPoint;
  }

  get isValid() {
    const validates = this.validate();

    if (Object.keys(validates).length !== 0) {
      return false;
    }

    return true;
  }

  static withOneImageComment() {
    const instance = new OfferImageComment();
    return instance.addImageComment();
  }

  sortedImageComments() {
    return this.image_comments.sortBy((image_comment) => image_comment.order_num);
  }

  setImageUrl(image_url: string) {
    return this.set('image_url', image_url);
  }

  setImageCommentPoint(index: number, x: number, y: number) {
    return this.updateIn(['image_comments', index], (image_comment: ImageComment) => image_comment.setPoint(x, y));
  }

  setImageCommentText(index: number, text: string) {
    return this.updateIn(['image_comments', index], (image_comment: ImageComment) => image_comment.setText(text));
  }

  addImageComment() {
    return this.update('image_comments', (image_comments) => image_comments.push(new ImageComment()));
  }

  deleteImageComment(index: number) {
    return this.update('image_comments', (image_comments) => image_comments.delete(index));
  }

  validate() {
    const errors: JSObject = {};

    if (!this.image_url) {
      errors.image_url = ErrorType.REQUIRED_ERROR;
    }

    const resultIndex = this.image_comments.findIndex((image_comment) => image_comment.isValid);
    if (resultIndex < 0) {
      errors.image_comment = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }

  requestParams() {
    const { id, image_url } = this.toObject();
    const image_comments = this.image_comments
      .filter((image_comment) => image_comment.isValid)
      .map((image_comment) => image_comment.requestParams())
      .toArray();
    const params = { id: id < 1 ? null : id, image_url, image_comments };
    return params;
  }
}
