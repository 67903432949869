import { List, Record, Set } from 'immutable';

import ErrorType from 'helpers/errorType';
import { validatePhoneNumber, validatePostalCode, validateWebsiteUrl } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { GmbAddress } from './GmbAddress';
import { GmbAttributes, GmbUrlAttributes, getDiffAttributes } from './GmbAttributes';
import { GmbLatlng } from './GmbLatlng';
import { GmbLocationCategories, GmbLocationCategory } from './GmbLocationCategories';
import { GmbOpenInfo, OpenInfoStatus } from './GmbOpenInfo';
import { GmbProfile } from './GmbProfile';
import { GmbRegularHours } from './GmbRegularHours';
import { GmbServiceItems } from './GmbServiceItems';
import { GmbSpecialHours } from './GmbSpecialHours';
import { MoreHoursList, MoreHoursType, getDiffMoreHoursTypeIds } from './MoreHours';
import { OpeningDate } from './OpeningDate';

// https://developers.google.com/my-business/reference/rest/v4/accounts.locations
export class GmbLocation extends Record<{
  name: string; // accounts/{account_id}/locations/{location_id}
  locationName: string;
  primaryPhone: string;
  additionalPhones: List<string>;
  websiteUrl: string;
  storeCode: string;
  regularHours: GmbRegularHours;
  specialHours: GmbSpecialHours;
  primaryCategory: GmbLocationCategory;
  additionalCategories: GmbLocationCategories;
  address: GmbAddress;
  openInfo: GmbOpenInfo;
  profile: GmbProfile;
  attributes: GmbAttributes;
  urlAttributes: GmbUrlAttributes;
  latlng: GmbLatlng;
  moreHours: MoreHoursList;
  openingDate: OpeningDate | null;
  serviceItems: GmbServiceItems;
}>({
  name: '',
  locationName: '',
  primaryPhone: '',
  additionalPhones: List(),
  websiteUrl: '',
  storeCode: '',
  regularHours: new GmbRegularHours(),
  specialHours: new GmbSpecialHours(),
  primaryCategory: new GmbLocationCategory(),
  additionalCategories: new GmbLocationCategories(),
  address: new GmbAddress(),
  openInfo: new GmbOpenInfo(),
  profile: new GmbProfile(),
  attributes: new GmbAttributes(),
  urlAttributes: new GmbUrlAttributes(),
  latlng: new GmbLatlng(),
  moreHours: new MoreHoursList(),
  openingDate: null,
  serviceItems: new GmbServiceItems(),
}) {
  constructor(data: JSObject = {}) {
    const { attributes, ...params } = data;
    params.additionalPhones = List(params.additionalPhones || []);
    params.address = new GmbAddress(params.address);
    params.regularHours = new GmbRegularHours((params.regularHours && params.regularHours.periods) || []);
    params.primaryCategory = new GmbLocationCategory(params.primaryCategory);
    params.additionalCategories = new GmbLocationCategories(params.additionalCategories);
    params.specialHours = new GmbSpecialHours(params.specialHours);
    params.openInfo = new GmbOpenInfo(params.openInfo);
    params.openingDate = params.openingDate && new OpeningDate(params.openingDate);
    params.profile = new GmbProfile(params.profile);

    // attributesは属性とURL属性の２つに分けて管理する
    params.attributes = GmbAttributes.fromJSON(attributes || []);
    params.urlAttributes = GmbUrlAttributes.fromJSON(attributes || []);
    params.latlng = GmbLatlng.fromJSON(params.latlng);
    params.moreHours = MoreHoursList.fromJSON(params.moreHours || []);
    params.serviceItems = GmbServiceItems.fromJSON(params.serviceItems || []);

    super(params);
  }

  get regularHoursForDisplay() {
    return this.regularHours ? this.regularHours.regularHoursForDisplay : [];
  }

  get specialHoursForDisplay() {
    return this.specialHours.specialHoursForDisplay;
  }

  get phones() {
    return [this.primaryPhone, ...this.additionalPhones.toArray()];
  }

  get additionalPhonesForEdit() {
    let phones = this.additionalPhones;
    if (phones.size === 0 || (phones.size < 2 && phones.last())) {
      phones = phones.push('');
    }
    return phones.toArray();
  }

  /**
   * 利用可能なその他の営業時間
   * メインカテゴリに紐づく追加の営業移管
   */
  get availableMoreHoursTypes() {
    return this.primaryCategory.moreHoursTypes ?? List<MoreHoursType>();
  }

  get allCatergoyIds() {
    return List([this.primaryCategory.categoryId]).concat(
      this.additionalCategories.list.map((category) => category.categoryId),
    );
  }

  /**
   * ロケーションを受け取り、自身との差分があるその他の営業時間のhoursTypeIdを返す
   */
  getMoreHoursDiffTypes(other: GmbLocation) {
    return getDiffMoreHoursTypeIds(this.moreHours, other.moreHours);
  }

  /**
   * ロケーションを受け取り、自身の属性差分がある attributeId を返す
   * ビジネスカテゴリが同じ前提
   * @param 比較対象のロケーション
   * @returns 差分がある attributeId の Set
   */
  getAttributesDiffIds(other: GmbLocation) {
    const urlAttributeIds = getDiffAttributes(this.urlAttributes, other.urlAttributes);
    const attributeIds = getDiffAttributes(this.attributes, other.attributes);
    return Set(urlAttributeIds.concat(attributeIds));
  }

  /**
   * 属性情報の差分が出ているid以外削除したものを返す
   * @param location 比較対象の属性情報を持つロケーション
   * @returns 差分が出ている属性を残したものを属性情報に持つロケーション
   */
  filterAttributeDiffs(location: GmbLocation) {
    const diffIds = this.getAttributesDiffIds(location);
    return this.setIn(
      ['attributes', 'list'],
      this.attributes.list.filter((attribute) => diffIds.includes(attribute.attributeId)),
    ).setIn(
      ['urlAttributes', 'list'],
      this.urlAttributes.list.filter((urlAttribute) => diffIds.includes(urlAttribute.attributeId)),
    );
  }

  changePrimaryPhone(phone: string) {
    // 数字と"-"以外の文字を除外する
    let result = phone.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    result = result.replace(/[^\d-]/g, '');
    return this.set('primaryPhone', result);
  }

  changeGmbAttributes(attributes: GmbAttributes) {
    return this.set('attributes', attributes);
  }

  changeGmbUrlAttributes(attributes: GmbUrlAttributes) {
    return this.set('urlAttributes', attributes);
  }

  changeAdditionalPhones(index: number, phone: string) {
    // 数字と"-"以外の文字を除外する
    let result = phone.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    result = result.replace(/[^\d-]/g, '');
    return this.setIn(['additionalPhones', index], result);
  }

  changeWebsiteUrl(websiteUrl: string) {
    return this.set('websiteUrl', websiteUrl);
  }

  changeRegularHours(regularHours: GmbRegularHours) {
    return this.set('regularHours', regularHours);
  }

  toggleDaySpecialHours(dayIndex: number, value: boolean) {
    return this.update('specialHours', (specialHours) => specialHours.toggleOpenDay(dayIndex, value));
  }

  changeSpecialHoursPeriod(dayIndex: number, timeIndex: number, openTime: string, closeTime: string) {
    return this.update('specialHours', (specialHours) =>
      specialHours.changePeriod(dayIndex, timeIndex, openTime, closeTime),
    );
  }

  changeSpecialHoursDate(dayIndex: number, value: string) {
    return this.update('specialHours', (specialHours) => specialHours.changeDate(dayIndex, value));
  }

  addSpecialHours() {
    return this.update('specialHours', (specialHours) => specialHours.addSpecialHours());
  }

  addSpecialHoursOpenDay(dayIndex: number) {
    return this.update('specialHours', (specialHours) => specialHours.addOpenDay(dayIndex));
  }

  removeSpecialHoursPeriod(dayIndex: number, timeIndex: number) {
    return this.update('specialHours', (specialHours) => specialHours.removePeriod(dayIndex, timeIndex));
  }

  removeSpecialHoursDay(index: number) {
    return this.update('specialHours', (specialHours) => specialHours.removeDay(index));
  }

  removePastSpecialHours() {
    return this.update('specialHours', (specialHours) => specialHours.removePastDate());
  }

  changePostalCode(postalCode: string) {
    return this.update('address', (address) => address.changePostalCode(postalCode));
  }

  changeAdministrativeArea(administrativeArea: string) {
    return this.update('address', (address) => address.changeAdministrativeArea(administrativeArea));
  }

  changeAddress1(address1: string) {
    return this.update('address', (address) => address.changeAddress1(address1));
  }
  changeAddress2(address2: string) {
    return this.update('address', (address) => address.changeAddress2(address2));
  }

  changeOpenInfoStatus(status: OpenInfoStatus) {
    return this.update('openInfo', (openInfo) => openInfo.changeStatus(status));
  }

  changeOpeningDate(openingDate: OpeningDate | null) {
    return this.set('openingDate', openingDate);
  }

  changeProfileDescription(description: string) {
    return this.update('profile', (profile) => profile.changeDescription(description));
  }

  changeMoreHours(moreHours: MoreHoursList) {
    return this.set('moreHours', moreHours);
  }

  validate() {
    const errors = {
      websiteUrl: this.validateWebsiteUrl(),
      postalCode: this.validatePostalCode(),
      primaryPhone: this.validatePrimaryPhone(),
      additionalPhones: this.validateAdditionalPhones(),
      administrativeArea: this.validateAdministrativeArea(),
      address1: this.validateAddress1(),
      address2: this.validateAddress2(),
      primaryCategory: this.primaryCategory.validateCategory(),
      additionalCategories: this.additionalCategories.validateCategories(),
      profile: this.profile.validateDescription(),
      attributes: this.attributes.validateAttributes(),
      urlAttributes: this.urlAttributes.validateAttributes(),
    };
    return errors;
  }

  changePrimaryCategory(value: string) {
    return this.update('primaryCategory', (primaryCategory) => primaryCategory.changeCategoryId(value));
  }

  updatePrimaryCategoryDetail(value: GmbLocationCategory) {
    return this.set('primaryCategory', value);
  }

  addAdditionalCategory() {
    return this.update('additionalCategories', (additionalCategories) => additionalCategories.addCategory());
  }

  changeAdditionalCategory(index: number, value: string) {
    return this.update('additionalCategories', (additionalCategories) =>
      additionalCategories.changeCategories(index, value),
    );
  }

  removeAdditionalCategory(index: number) {
    return this.set('additionalCategories', this.additionalCategories.removeCategory(index));
  }

  validatePrimaryPhone() {
    let result = {
      isValid: true,
      error: '',
    };
    if (this.primaryPhone && !validatePhoneNumber(this.primaryPhone)) {
      result = {
        isValid: false,
        error: ErrorType.PHONE_ERROR,
      };
    }
    return result;
  }

  validateAdditionalPhones() {
    const result = this.additionalPhonesForEdit.map((phone) => {
      if (phone && !validatePhoneNumber(phone)) {
        return { isValid: false, error: ErrorType.PHONE_ERROR };
      }
      return { isValid: true, error: '' };
    });
    return result;
  }

  validateWebsiteUrl() {
    let result = {
      isValid: true,
      error: '',
    };
    if (this.websiteUrl && !validateWebsiteUrl(this.websiteUrl)) {
      result = {
        isValid: false,
        error: ErrorType.WEBSITE_URL_ERROR,
      };
    }
    return result;
  }

  validateBusinessHours() {
    let result = {
      isValid: true,
      error: '',
    };
    if (this.regularHours && !this.regularHours.validate.isValid) {
      result = {
        isValid: false,
        error: this.regularHours.validate.error,
      };
    }
    return result;
  }

  validateAdministrativeArea() {
    let result = {
      isValid: true,
      error: '',
    };

    if (!this.address || !this.address.administrativeArea) {
      result = {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    return result;
  }

  validateSpecialHours() {
    let result = {
      isValid: true,
      error: '',
    };
    if (this.specialHours && !this.specialHours.validate.isValid) {
      result = {
        isValid: false,
        error: this.specialHours.validate.error,
      };
    }
    return result;
  }
  validatePostalCode() {
    if (!this.address || !this.address.postalCode) {
      return {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    if (!validatePostalCode(this.address.postalCode)) {
      return {
        isValid: false,
        error: ErrorType.POSTAL_CODE_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  validateAddress1() {
    if (!this.address || !this.address.address1) {
      return {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  validateAddress2() {
    return {
      isValid: true,
      error: '',
    };
  }

  updatePrimaryPhoneParams() {
    return { primaryPhone: this.primaryPhone };
  }

  updateAdditionalPhonesParams() {
    return {
      additionalPhones: this.additionalPhones.isEmpty()
        ? null // Googleビジネスプロフィール の差分検知と合わせるため
        : this.additionalPhones.filter((phone) => phone).toArray(),
    };
  }

  updatePhoneParams() {
    return { ...this.updatePrimaryPhoneParams(), ...this.updateAdditionalPhonesParams() };
  }

  updateWebsiteUrlParams() {
    return { websiteUrl: this.websiteUrl };
  }

  updateRegularHoursParams() {
    return { regularHours: this.regularHours.updateParams };
  }

  updateSpecialHoursParams() {
    return { specialHours: this.specialHours.updateParams };
  }

  updateMoreHoursParams() {
    return { moreHours: this.moreHours.updateParams };
  }

  updatePrimaryCategoryParams() {
    return { primaryCategory: this.primaryCategory.updateParams };
  }

  updateAdditionalCategoriesParams() {
    return {
      additionalCategories: this.additionalCategories.list
        .filter((target) => !target.isEmpty)
        .map((target) => {
          return {
            categoryId: target.categoryId,
          };
        })
        .toArray(),
    };
  }

  updateCategoriesParams() {
    return {
      ...this.updatePrimaryCategoryParams(),
      ...this.updateAdditionalCategoriesParams(),
    };
  }

  updateAttributesParams() {
    return { attributes: [...this.attributes.updateParams, ...this.urlAttributes.updateParams] };
  }

  updateOpenInfoParams() {
    return this.openInfo.updateParams();
  }

  updateOpeningDateParams() {
    return {
      openingDate: this.openingDate?.updateParams() ?? null,
    };
  }

  updateProfileParams() {
    return this.profile.updateParams();
  }

  updateAddressParams() {
    return this.address.updateParams();
  }

  updateGmbLatlngParams() {
    return this.latlng.updateParams();
  }
}
