import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { Dropdown, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { ResizeImage } from 'components/atoms/ResizeImage';
import { ImageCarousel } from 'components/pageComponents/PromotionIndex/ImageCarousel';
import ListPromotion from 'models/Domain/Promotion/ListPromotion';
import { Stores } from 'models/Domain/Store';
import { AppActions } from 'modules/app/actions';
import { PromotionActions } from 'modules/promotion/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';

import { TRANSLATION_LANGUAGE_LABELS } from '../../../models/Domain/Organization';

type Props = {
  listPromotion: ListPromotion;
  /** 単一店舗にしか紐づいてない場合に店舗名を表示するためのデータ */
  stores: Stores;
  /** 編集・削除メニューをアクティブにするかどうか */
  showEditMenu: boolean;
};

/**
 * 投稿一覧画面で、投稿の内容を表示
 */
export const PromotionInfoCard: React.FC<Props> = ({ listPromotion, showEditMenu, stores }) => {
  const dispatch = useDispatch();

  const onEdit = useCallback(() => {
    dispatch(
      AppActions.moveTo({
        pathname: Path.localpost.edit.replace(':id', listPromotion._id ?? ''),
        state: { source: Path.localpost.index },
      }),
    );
  }, [dispatch, listPromotion._id]);

  const onCreate = useCallback(() => {
    dispatch(
      AppActions.moveTo({
        pathname: Path.localpost.create,
        search: `?topic_type=${listPromotion.topic_type}`,
        state: { baseId: listPromotion._id ?? '', source: Path.localpost.index },
      }),
    );
  }, [dispatch, listPromotion._id, listPromotion.topic_type]);

  const onDelete = useCallback(() => {
    if (!window.confirm('削除してよろしいですか？')) return;
    dispatch(PromotionActions.deletePromotion({ id: listPromotion._id ?? '' }));
  }, [dispatch, listPromotion._id]);

  return (
    <StyledCard>
      <Link
        to={{
          // 下書きかつ編集可能な場合は直接編集ページ、そうでなければ詳細ページ
          pathname: (listPromotion.isDraft && showEditMenu ? Path.localpost.edit : Path.localpost.detail).replace(
            ':id',
            listPromotion._id ?? '',
          ),
          state: { source: Path.localpost.index },
        }}
      >
        <ContentWrapper>
          <MainWrapper>
            <Detail>
              <FlexHeader>
                <Category>{listPromotion.topicTypeLabel}</Category>
                <MetaInfoWrapper>
                  {!listPromotion.isDraft && listPromotion.isScheduled ? (
                    <DateWrapper>
                      {listPromotion.scheduledAt?.format('[投稿予定日時：]YYYY/MM/DD HH:mm') ?? ''}
                    </DateWrapper>
                  ) : !listPromotion.isDraft ? (
                    <DateWrapper>
                      {listPromotion.displayPostAt?.format('[投稿日時：]YYYY/MM/DD HH:mm') ?? ''}
                    </DateWrapper>
                  ) : undefined}
                  <EditableWrapper>{showEditMenu && listPromotion.canEdit ? '' : '編集不可'}</EditableWrapper>
                </MetaInfoWrapper>
              </FlexHeader>
              {listPromotion.event && (
                <EventContents>
                  <EventTitle>{listPromotion.event.title}</EventTitle>
                  <EventPeriod>{listPromotion.event.scheduleText}</EventPeriod>
                </EventContents>
              )}
              <Content>
                {listPromotion.getAnnotatedBody().map((data, i) => {
                  if (data.type == 'Alias') {
                    return <Alias key={i}>{data.text}</Alias>;
                  }
                  return data.text;
                })}
              </Content>
              <DetailWrapper>
                {listPromotion.offer?.couponCode && (
                  <CouponCode>クーポンコード：{listPromotion.offer.couponCode}</CouponCode>
                )}
                {listPromotion.offer?.redeemOnlineUrl && (
                  <DetailLinkWrapper>
                    特典へのリンク：
                    <DetailLink
                      href={listPromotion.offer.redeemOnlineUrl}
                      target={'_blank'}
                      onClick={(e: React.MouseEvent) => e.stopPropagation()}
                    >
                      {listPromotion.offer.redeemOnlineUrl}
                    </DetailLink>
                  </DetailLinkWrapper>
                )}
                {listPromotion.call_to_action?.url && (
                  <DetailLinkWrapper>
                    {listPromotion.call_to_action.actionTypeLabel}：
                    <DetailLink
                      href={listPromotion.urlWithoutAlias}
                      target='_blank'
                      onClick={(e: React.MouseEvent) => e.stopPropagation()}
                    >
                      {listPromotion.call_to_action?.url}
                    </DetailLink>
                  </DetailLinkWrapper>
                )}
              </DetailWrapper>
            </Detail>
            {!listPromotion.media.isEmpty() && (
              <ImageWrapper>
                <ImageCarousel urls={listPromotion.media.sourceUrls} />
              </ImageWrapper>
            )}
          </MainWrapper>
        </ContentWrapper>
      </Link>
      <Footer>
        <FooterWrapper>
          <StatusWrapper>
            {listPromotion.isDraft && (
              <FooterContent>
                <CustomIcon type='edit_for_template' />
                <ValueLabel>
                  <BoldFont>下書き</BoldFont>
                </ValueLabel>
              </FooterContent>
            )}

            {!listPromotion.isDraft && listPromotion.isScheduled && (
              <FooterContent>
                <CustomIcon type='calendar' />
                <ValueLabel>
                  <BoldFont>投稿予約済み</BoldFont>
                </ValueLabel>
              </FooterContent>
            )}

            <FooterContent>
              <FooterContentWrapper>
                <CustomIcon type='shop' />
                <ValueLabel>
                  {listPromotion.stats.total_stores_count === 0 ? (
                    <>{'店舗未選択'}</>
                  ) : listPromotion.stats.total_stores_count > 1 ? (
                    <>
                      店舗数 <BoldFont>{listPromotion.stats.total_stores_count.toLocaleString()}</BoldFont>
                      店舗
                    </>
                  ) : (
                    <>{stores.findStore(listPromotion.store_ids.get(0))?.shortName}</>
                  )}
                </ValueLabel>
                {listPromotion.hasGmbStatusReject && (
                  <Rejected>(公開拒否{listPromotion.stats.total_stores_count > 1 && 'あり'})</Rejected>
                )}
              </FooterContentWrapper>
              {listPromotion.translations?.children && listPromotion.translations.children.size > 0 && (
                <Popup
                  basic
                  trigger={
                    <FooterContentWrapper style={{ cursor: 'pointer' }}>
                      <CustomIcon type='globe' />
                      <ValueLabel>多言語対応</ValueLabel>
                    </FooterContentWrapper>
                  }
                >
                  <PopupWrapper>
                    <PopupTitle>対応言語</PopupTitle>
                    {listPromotion.translations.children?.map((post, i) => (
                      <PopupContent key={i}>{TRANSLATION_LANGUAGE_LABELS[post.language]}</PopupContent>
                    ))}
                  </PopupWrapper>
                </Popup>
              )}
              {listPromotion.source?.type === 'instagram' && (
                <FooterContentWrapper>
                  <CustomIcon type='instagram' />
                  <InstagramName>{listPromotion.source.name}</InstagramName>
                </FooterContentWrapper>
              )}
            </FooterContent>
          </StatusWrapper>
          <FlexWrapper>
            {/* <CustomIcon type='share' />
            <div>投稿を共有</div> */}
            <FooterContent />
            <FlexWrapper>
              <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
                <Dropdown.Menu direction='left'>
                  {showEditMenu && listPromotion.canEdit && (
                    <ActionMenuItem onClick={() => onEdit()}>
                      <ActionItemLabel>編集</ActionItemLabel>
                    </ActionMenuItem>
                  )}
                  <ActionMenuItem onClick={() => onCreate()}>
                    <ActionItemLabel>複製して新規作成</ActionItemLabel>
                  </ActionMenuItem>
                  {showEditMenu && (
                    <ActionMenuItem onClick={() => onDelete()}>
                      <ActionItemLabel>削除</ActionItemLabel>
                    </ActionMenuItem>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </FlexWrapper>
          </FlexWrapper>
        </FooterWrapper>
      </Footer>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  &&& {
    padding: 0px;
    max-width: 770px;
    margin-bottom: 21px;
  }
`;

const ContentWrapper = styled.div`
  padding: 18px;
  cursor: pointer;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const StatusWrapper = styled(FlexWrapper)`
  flex-wrap: wrap;
`;

const MainWrapper = styled(FlexWrapper)`
  gap: 16px;

  @media (max-width: 860px) {
    flex-wrap: wrap;
  }
`;

const FooterWrapper = styled(FlexWrapper)`
  padding: 10px 18px;
  min-height: 57px;
  align-items: flex-start;
  justify-content: space-between;
`;

const FooterContent = styled(FlexWrapper)`
  align-items: center;
  margin-right: 30px;
  font-family: monospace !important;
`;

const FooterContentWrapper = styled(FlexWrapper)`
  align-items: center;
  margin-right: 20px;
  font-family: monospace !important;
`;

const Detail = styled.div`
  min-width: 0;
  flex: 1;
`;

export const FlexHeader = styled(FlexWrapper)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #afafaf;
`;

export const Category = styled.div`
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BLACK};
  padding: 3px 8px;
  margin-right: 10px;
  font-size: 14px;
`;

export const MetaInfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const DateWrapper = styled.div`
  color: ${COLOR.DARK_GRAY};
  text-align: end;
  font-size: 14px;
`;

export const EditableWrapper = styled.div`
  color: ${COLOR.DARK_GRAY};
  text-align: end;
`;

const Content = styled.div`
  font-size: 14px;
  margin-top: 8px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const Alias = styled.span`
  font-weight: bold;
  color: ${COLOR.GREEN};
  vertical-align: baseline;
`;

const DetailWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  > :not(:last-child) {
    margin-bottom: 4px;
  }

  @media (max-width: 860px) {
    margin-bottom: 16px;
  }
`;

const DetailLinkWrapper = styled.div``;

const CouponCode = styled.div``;

const DetailLink = styled.a`
  color: blue;
  cursor: pointer;
  word-break: break-all;
  &:hover {
    color: blue;
  }
  &:active {
    color: red;
  }
  &:visited {
    color: #551b8c;
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 16px;
  @media (max-width: 860px) {
    width: 100%;
    ${Mixins.flex('center')}
    justify-content: center;
    flex-direction: column;
  }
`;

export const Img = styled(ResizeImage).attrs(() => ({
  width: 416, //最大幅416pxにリサイズ
}))`
  height: 139px;
  width: 208px;
  background-color: ${COLOR.DARK_GRAY};
  flex-shrink: 0;
  margin-left: 21px;
  object-fit: contain;
`;

const CustomIcon = styled(Icon)`
  width: 16px;
  padding: 10px 0px;
  height: 37px;
  margin-right: 6px;
`;

const Trigger = styled.div`
  cursor: pointer;
  /* position: absolute;
  left: -16px; */
  letter-spacing: -10px;

  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${COLOR.GREEN};

  transform: rotate(90deg);
`;

const ActionItemLabel = styled.div`
  padding: 10px;
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY} !important;
    &:first-of-type {
      border-top: none !important;
    }
  }
`;

const ValueLabel = styled.div`
  display: flex;
  flex-direction: row;
`;

const BoldFont = styled.div`
  font-weight: bold;
  font-family: monospace;
  margin: 0 4px;
`;

const Rejected = styled.span`
  color: ${COLOR.ERROR};
  margin-left: 4px;
`;

const EventContents = styled.div`
  margin-bottom: 8px;
`;

const EventTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const EventPeriod = styled.div`
  font-size: 12px;
  color: ${COLOR.DARK_GRAY};
`;

const InstagramName = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  word-break: break-all;
`;

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PopupTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;
