import React from 'react';

import { List } from 'immutable';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { InventoryHelp } from 'helpers/ContextHelp';
import { InventorySummary } from 'models/Domain/Omo/InventorySummary';
import { Path } from 'routes';
import { SIZE } from 'style/size';

type Props = {
  className?: string;
  inventorySummaryList: List<InventorySummary>;
  isLoading: boolean;
};

/**
 * 在庫のサマリーを表示
 */
export const InventorySummaryTable = React.memo<Props>(({ className, inventorySummaryList, isLoading }) => {
  return (
    <Wrapper className={className}>
      <StyledTable>
        <TableHeader>
          <Table.Row>
            <Table.HeaderCell>店舗コード</Table.HeaderCell>
            <Table.HeaderCell>店舗名</Table.HeaderCell>
            <Table.HeaderCell>
              <HeaderCellContent>
                有効な商品数
                <ContextHelp content={InventoryHelp.valid} />
              </HeaderCellContent>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <HeaderCellContent>
                期限切れ間近
                <ContextHelp content={InventoryHelp.expiringSoon} />
              </HeaderCellContent>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <HeaderCellContent>
                有効期限切れ
                <ContextHelp content={InventoryHelp.expired} />
              </HeaderCellContent>
            </Table.HeaderCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {inventorySummaryList.map((items) => {
            const {
              store: { code, fullName: name, id: storeId },
              counts: { valid, expiringSoon, expired },
            } = items;
            const linkPath = Path.omo.inventoryList.replace(':storeId', String(storeId));
            return (
              <Table.Row key={storeId}>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>店舗コード</MobileOnlyLabel>
                    <Code>{code}</Code>
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>店舗名</MobileOnlyLabel>
                    <Name to={linkPath}>{name}</Name>
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>有効な商品数</MobileOnlyLabel>
                    <Count>{isLoading ? 'ー' : valid.toLocaleString()}</Count>
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>期限切れ間近</MobileOnlyLabel>
                    <Count>{isLoading ? 'ー' : expiringSoon.toLocaleString()}</Count>
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>有効期限切れ</MobileOnlyLabel>
                    <Count>{isLoading ? 'ー' : expired.toLocaleString()}</Count>
                  </CellContent>
                </StyledTableCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </StyledTable>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  overflow-x: scroll;
`;

const StyledTable = styled(Table)`
  &&& {
    font-size: 13px;
    margin-top: 8px;
    white-space: nowrap;
  }
`;

const TableHeader = styled(Table.Header)`
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    &&& {
      /* モバイル表示はテーブルのヘッダを表示しない（セル内に、ラベルを表示させる）*/
      display: none !important;
    }
  }
`;

const HeaderCellContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTableCell = styled(Table.Cell)`
  vertical-align: middle;
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const Code = styled.span`
  font-weight: bold;
  white-space: normal;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    font-size: 1em;
    max-width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Name = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    font-size: 1em;
    max-width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Count = styled.div`
  font-family: monospace;
`;

const MobileOnly = styled.div`
  display: none;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    display: inline-block;
  }
`;

const MobileOnlyLabel = styled(MobileOnly)`
  width: 100px;
  font-weight: bold;
`;
