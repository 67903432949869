import { toast } from 'react-semantic-toasts';
import { all, put, takeLatest } from 'redux-saga/effects';

import ApiClient from 'ApiClient';
import ImageUploadApi, { PresignedUrlApi } from 'ApiClient/ImageUploadApi';
import { JSObject } from 'types/Common';

import { AppActions } from './actions';

export default function* saga() {
  yield takeLatest(AppActions.onDropRejected, onDropRejected);
}

export function* onDropAccepted(acceptedFiles: File[]) {
  yield put(AppActions.setLoading(true));
  const results: JSObject[] = yield all(
    acceptedFiles.map((file: File) => {
      const mb = file.size / (1000 * 1000);
      // 5MB以上の場合と処理を分ける
      // base64への変換でで画像のサイズが約1.3倍になってしまうことを加味して、
      // 判定時のサイズは5より小さくしている
      if (mb > 4.7) {
        return uploadLargeFile(file);
      }
      return uploadFile(file);
    }),
  );
  const urls = results.filter((result) => result.isSuccess).map((result) => result.data.url);
  yield put(AppActions.setLoading(false));
  return urls;
}

function onDropRejected(action: ReturnType<typeof AppActions.onDropRejected>) {
  const rejectedFiles = action.payload;
  rejectedFiles.forEach((file: File) => {
    toast({
      type: 'error',
      title: 'アップロードできない形式のファイルです',
      description: file.name,
      time: 10000,
    });
  });
}

function* uploadFile(file: File) {
  const response: YieldReturn<typeof ImageUploadApi.post> = yield ImageUploadApi.post(file);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'アップロードに失敗しました',
      description: file.name,
      time: 10000,
    });
  }
  return response;
}

function* uploadLargeFile(file: File) {
  // S3に直接アップロードするURLを取得し、そのURLにアップロードする

  const { name, type } = file;
  const presignedResponse: YieldReturn<typeof PresignedUrlApi.post> = yield PresignedUrlApi.post({
    upload_content_type: type,
  });
  if (!presignedResponse.isSuccess || !presignedResponse.data) {
    toast({
      type: 'error',
      title: 'アップロード用URLの取得に失敗しました',
      description: name,
      time: 10000,
    });
    return presignedResponse;
  }

  const {
    data: { upload_url, access_url },
  } = presignedResponse;

  const apiClient = new ApiClient({
    baseURL: upload_url,
    headers: { 'Content-Type': type },
    timeout: 60000,
    useToken: false,
  });

  const uploadResponse: YieldReturn<typeof apiClient.put> = yield apiClient.put('', file);
  if (!uploadResponse.isSuccess) {
    toast({
      type: 'error',
      title: 'アップロードに失敗しました',
      description: name,
      time: 10000,
    });
  } else {
    uploadResponse.data = { url: access_url };
  }
  return uploadResponse;
}
