import React from 'react';

import styled from 'styled-components';

import { PullDown } from 'components/atoms/PullDown';
import { OfferStores } from 'models/Composite/OfferStores';
import { Stores } from 'models/Domain/Store';

export const OfferEditSender = React.memo<{
  offerStores: OfferStores;
  changeOfferStores: (v: OfferStores) => void;
  stores: Stores;
}>(({ offerStores, changeOfferStores, stores }) => {
  const defaultValue = 0 < stores.options.length ? stores.options[0].value : 0;
  const displayValue = offerStores.storeId || defaultValue;

  return (
    <Field>
      <Label>送信元</Label>
      <OfferEditFormPullDown
        value={displayValue}
        options={stores.options}
        placeholder='店舗を選択してください'
        readOnly={stores.options.length === 1}
        onChange={(value: number) => changeOfferStores(offerStores.changeStores(value))}
      />
    </Field>
  );
});

const Field = styled.div`
  padding-bottom: 18px;
  padding-right: 18px;
  padding-left: 18px;
  &:first-of-type {
    padding-top: 18px;
  }
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;

const OfferEditFormPullDown = styled(PullDown)`
  margin-top: 12px;
`;
