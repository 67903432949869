import React from 'react';

import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { OfferStatus as Status } from 'components/atoms/OfferStatus';
import { TaskDetail } from 'models/Domain/TaskDetail';
import { User } from 'models/Domain/User';
import { COLOR } from 'style/color';

import { FileList } from './FileList';

export type TaskContentProps = {
  className?: string;
  task: TaskDetail;
  author: User;
  canEdit: boolean;
};

export const TaskContent: React.FC<TaskContentProps> = ({ className, task, author, canEdit }) => {
  const isOverdue = task.isOverdue && !task.isDone;
  return (
    <Wrapper className={className}>
      <Header>
        <Type>依頼</Type>
        <Title>{task.name}</Title>
        {canEdit && <PCEditLink to={task.editPagePath}>編集する</PCEditLink>}
      </Header>
      {canEdit && <MobileEditLink to={task.editPagePath}>編集する</MobileEditLink>}
      <Description>{task.content.text}</Description>
      <FileListContainer>
        <FileList files={task.content.files} />
      </FileListContainer>
      <Info>
        <InfoLabel>依頼者</InfoLabel>
        <InfoValue>{author.profile_image_url && <AuthorIcon src={author.profile_image_url} />}</InfoValue>
        <InfoValue>{author.fullName}</InfoValue>
      </Info>
      <Info>
        <InfoLabel>期限</InfoLabel>
        <InfoValue>
          <DueDate isOverdue={isOverdue}>{task.due_date.format('YYYY/MM/DD')}</DueDate>
        </InfoValue>
      </Info>
      <Info>
        <InfoLabel>進捗</InfoLabel>
        <InfoValue>
          <Status value={task.statusLabel} />
        </InfoValue>
      </Info>
      <CreateDate>作成日：{task.create_at.format('YYYY/MM/DD')}</CreateDate>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  background: ${COLOR.WHITE};
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

// 共通コンポーネントに置き換える
const Type = styled.div`
  width: 60px;
  height: 24px;
  background: #fffcab;
  border-radius: 12px;

  font-size: 13px;
  text-align: center;
  line-height: 1.85;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: ${COLOR.BLACK};
  margin-left: 8px;

  @media (max-width: 414px) {
    font-size: 18px;
  }
`;

const EditLink = styled(Link)`
  font-size: 13px;
  color: ${COLOR.GREEN};
  margin-left: auto;
  cursor: pointer;
`;

const PCEditLink = styled(EditLink)`
  @media (max-width: 414px) {
    display: none;
  }
`;

const MobileEditLink = styled(EditLink)`
  position: absolute;
  display: block;
  right: 20px;
  @media (min-width: 415px) {
    display: none;
  }
`;

const Description = styled.div`
  font-size: 14px;
  white-space: pre-wrap;
  margin: 20px 0;
  overflow-wrap: break-word;
`;

const FileListContainer = styled.div`
  margin: 20px 0;
`;

const Info = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

const InfoText = styled.div`
  font-size: 13px;
`;

const InfoLabel = styled(InfoText)`
  width: 80px;
  margin-right: 20px;
`;

const InfoValue = styled(InfoText)``;

const AuthorIcon = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 8px;
`;

const DueDate = styled.div<{ isOverdue: boolean }>`
  color: ${({ isOverdue }) => (isOverdue ? COLOR.RED : COLOR.BLACK)};
`;

const CreateDate = styled.div`
  font-size: 13px;
  color: ${COLOR.GRAY};
  position: absolute;
  right: 20px;
  bottom: 20px;
`;
