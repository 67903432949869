import { Record as ImmutableRecord } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Menu, MenuSummary } from 'models/Domain/Menu/Menu';

import { MenuActions } from './actions';

export class MenuState extends ImmutableRecord<{
  isLoadingMenu: boolean;
  menu: Menu;
  menuSummary: MenuSummary;
  uploadedImageUrl: string | null;
  isApplyingToGbp: boolean;
}>({
  isLoadingMenu: true,
  menu: new Menu(),
  menuSummary: new MenuSummary(),
  uploadedImageUrl: null,
  isApplyingToGbp: false,
}) {}

export const menuReducer = reducerWithInitialState(new MenuState())
  .case(MenuActions.setIsLoadingMenu, (state, payload) => {
    return state.set('isLoadingMenu', payload);
  })
  .case(MenuActions.setMenu, (state, payload) => {
    return state.set('menu', payload);
  })
  .case(MenuActions.setUploadedImageUrl, (state, payload) => {
    return state.set('uploadedImageUrl', payload);
  })
  .case(MenuActions.clearUploadedImageUrl, (state, payload) => {
    return state.set('uploadedImageUrl', null);
  })
  .case(MenuActions.setMenuSummary, (state, payload) => {
    return state.set('menuSummary', payload);
  })
  .case(MenuActions.setIsApplyingToGbp, (state, payload) => {
    return state.set('isApplyingToGbp', payload);
  });
