import React, { useCallback, useEffect, useState } from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { TagInput } from 'components/molecules/TagInput';

type Props = {
  className?: string;
  committedFilterWords: List<string>;
  commitFilterWords: (filterWords: List<string>) => void;
};

export const CompetitorFilter = React.memo<Props>(({ className, commitFilterWords, committedFilterWords }) => {
  const [filterWords, setFilterWords] = useState(List<string>());

  useEffect(() => {
    setFilterWords(committedFilterWords);
  }, [committedFilterWords]);

  const handleOnChangeKeyword = useCallback((value: string[]) => {
    setFilterWords(List(value));
  }, []);

  const handleOnCommit = useCallback(() => {
    commitFilterWords(filterWords);
  }, [commitFilterWords, filterWords]);

  return (
    <Wrapper>
      <FlexWrapper>
        <ContentWrapper>
          <ContentLabel>店舗名（部分一致）</ContentLabel>
          <TagInput values={filterWords.toArray()} onChange={handleOnChangeKeyword} />
        </ContentWrapper>
        <ButtonContainer>
          <StyledButton priority={'high'} onClick={handleOnCommit}>
            絞り込む
          </StyledButton>
        </ButtonContainer>
      </FlexWrapper>
    </Wrapper>
  );
});

const Wrapper = styled(Card)``;

const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    align-self: end;
    margin-left: 8px;
    box-shadow: none;
  }
`;
