import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export class PaginationWithoutLimit extends Record<{
  has_next: boolean;
  current_page: number;
  total_count: number;
}>({
  has_next: false,
  current_page: 0,
  total_count: 0,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }
}

export class Pagination extends Record<{
  has_next: boolean;
  current_page: number;
  total_count: number;
  limit: number;
}>({
  has_next: false,
  current_page: 1,
  total_count: 0,
  limit: 100,
}) {
  static fromJSON(data: JSObject = {}) {
    return new Pagination(data);
  }

  get range() {
    const start = Math.min(this.limit * (this.current_page - 1) + 1, this.total_count);
    const end = Math.min(this.limit * this.current_page, this.total_count);
    return [start, end];
  }
}
