import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { Store } from 'models/Domain/Store';
import { JSObject } from 'types/Common';

type AggregateUnit = 'day' | 'week' | 'month';

export type MetricsKey =
  | 'queriesDirect'
  | 'queriesIndirect'
  | 'queriesChain'
  | 'rateCount'
  | 'commentCount'
  | 'actionsWebsite'
  | 'actionsPhone'
  | 'actionsDrivingDirections';

/** 期間 */
interface DatePeriodRecord {
  fromDate: Dayjs | null;
  toDate: Dayjs | null;
}

export class DatePeriod extends Record<DatePeriodRecord>({
  fromDate: null,
  toDate: null,
}) {
  static fromJSON(data: JSObject = {}): DatePeriod {
    return new DatePeriod({
      fromDate: data.from_date ? dayjs(data.from_date) : null,
      toDate: data.to_date ? dayjs(data.to_date) : null,
    });
  }
}

/**
 * グラフ用インサイトデータの単一の期間の数値データ
 */
interface InsightGraphItemRecord {
  period: DatePeriod;
  queriesDirect: number;
  queriesIndirect: number;
  queriesChain: number;
  rateCount: number;
  commentCount: number;
  actionsWebsite: number;
  actionsPhone: number;
  actionsDrivingDirections: number;
}

export class InsightGraphItem extends Record<InsightGraphItemRecord>({
  period: new DatePeriod(),
  queriesDirect: 0,
  queriesIndirect: 0,
  queriesChain: 0,
  rateCount: 0,
  commentCount: 0,
  actionsWebsite: 0,
  actionsPhone: 0,
  actionsDrivingDirections: 0,
}) {
  static fromJSON(data: JSObject = {}): InsightGraphItem {
    return new InsightGraphItem({
      period: DatePeriod.fromJSON(data.period),
      queriesDirect: data.stats.queries_direct,
      queriesIndirect: data.stats.queries_indirect,
      queriesChain: data.stats.queries_chain,
      rateCount: data.stats.rate_count,
      commentCount: data.stats.comment_count,
      actionsWebsite: data.stats.actions_website,
      actionsPhone: data.stats.actions_phone,
      actionsDrivingDirections: data.stats.actions_driving_directions,
    });
  }

  /** 検索数の合計 */
  get sumSearch(): number {
    return this.queriesDirect + this.queriesIndirect;
  }

  /** アクションの合計 */
  get sumAction(): number {
    return this.actionsWebsite + this.actionsDrivingDirections + this.actionsPhone;
  }

  /** クチコミの合計 */
  get sumReview(): number {
    return this.rateCount + this.commentCount;
  }

  /** 「ウェブサイトへのアクセス」のアクション率*/
  get actionsWebsiteRate(): number {
    return this.actionsWebsite / this.sumSearch;
  }

  /** 「電話をかける」のアクション率 */
  get actionsPhoneRate(): number {
    return this.actionsPhone / this.sumSearch;
  }

  /** 「ルートの検索」のアクション率 */
  get actionsDrivingDirectionsRate(): number {
    return this.actionsDrivingDirections / this.sumSearch;
  }
}

/** 集計期間と比較期間の変化率のデータ */
interface InsightGraphDataDiffRateRecord {
  queriesDirect: number | null;
  queriesIndirect: number | null;
  queriesChain: number | null;
  actionsWebsite: number | null;
  actionsPhone: number | null;
  actionsDrivingDirections: number | null;
  rateCount: number | null;
  commentCount: number | null;
  searchCount: number | null;
  actionCount: number | null;
  reviewCount: number | null;
}

export class InsightGraphDataDiffRate extends Record<InsightGraphDataDiffRateRecord>({
  queriesDirect: null,
  queriesIndirect: null,
  queriesChain: null,
  actionsWebsite: null,
  actionsPhone: null,
  actionsDrivingDirections: null,
  rateCount: null,
  commentCount: null,
  searchCount: null,
  actionCount: null,
  reviewCount: null,
}) {}

interface InsightGraphDataRecord {
  aggregateUnit: AggregateUnit;
  items: List<InsightGraphItem>;
  lastUpdateAt: Dayjs | null;
}

/** インサイト（グラフ）のデータ */
export class InsightGraphData extends Record<InsightGraphDataRecord>({
  aggregateUnit: 'day',
  items: List<InsightGraphItem>(),
  lastUpdateAt: null,
}) {
  static fromJSON(data: JSObject = {}): InsightGraphData {
    return new InsightGraphData({
      aggregateUnit: data.aggregate_unit,
      items: List(data.list.map((item: JSObject) => InsightGraphItem.fromJSON(item))),
      lastUpdateAt: data.last_update_at ? dayjs(data.last_update_at) : null,
    });
  }

  /** 検索数の合計 */
  get sumSearch(): number {
    return this.items.reduce((sum, data) => sum + data.sumSearch, 0);
  }

  /** クチコミの合計 */
  get sumReview(): number {
    return this.items.reduce((sum, data) => sum + data.sumReview, 0);
  }

  /** アクション数の合計 */
  get sumAction(): number {
    return this.items.reduce((sum, data) => sum + data.sumAction, 0);
  }

  /** 期間全体の「ウェブサイトへのアクセス」のアクション率 */
  get overallActionsWebsiteRate(): number {
    return this.getSumByKey('actionsWebsite') / this.sumSearch;
  }

  /** 期間全体の「電話をかける」のアクション率 */
  get overallActionsPhoneRate(): number {
    return this.getSumByKey('actionsPhone') / this.sumSearch;
  }

  /** 期間全体の「ルートを検索」のアクション率 */
  get overallActionsDrivingDirectionsRate(): number {
    return this.getSumByKey('actionsDrivingDirections') / this.sumSearch;
  }

  /**
   * 検索数に対する3種類のアクション数のアクション率
   * ※ toRateData() していないことを前提
   */
  get overallActionsRate(): number {
    return this.sumAction / this.sumSearch;
  }

  /** 複数データをもっているか */
  get hasMultipleData(): boolean {
    return this.items.size > 1;
  }

  /**
   * 1つの指標の全期間の合計値を返す
   * @param key 対象のキー
   */
  getSumByKey(key: MetricsKey): number {
    return this.items.reduce((sum, data) => sum + data.get(key), 0);
  }

  /** 各アクション数の値をアクション率に組み替えたインスタンスを返却 */
  get toRateData(): InsightGraphData {
    return this.update('items', (items) =>
      items.map((item) =>
        item
          .set('actionsWebsite', item.actionsWebsiteRate)
          .set('actionsPhone', item.actionsPhoneRate)
          .set('actionsDrivingDirections', item.actionsDrivingDirectionsRate),
      ),
    );
  }

  /**
   * 比較対象の値からの変化率
   * @param comparison 比較対象のグラフデータ
   */
  getDiffRate(comparison: InsightGraphData) {
    return new InsightGraphDataDiffRate({
      // 比較対象が 0 の場合、値は Infinity (number) になる
      queriesDirect: comparison.getSumByKey('queriesDirect')
        ? this.getSumByKey('queriesDirect') / comparison.getSumByKey('queriesDirect') - 1
        : null,
      queriesIndirect: comparison.getSumByKey('queriesIndirect')
        ? this.getSumByKey('queriesIndirect') / comparison.getSumByKey('queriesIndirect') - 1
        : null,
      queriesChain: comparison.getSumByKey('queriesChain')
        ? this.getSumByKey('queriesChain') / comparison.getSumByKey('queriesChain') - 1
        : null,
      actionsWebsite: comparison.getSumByKey('actionsWebsite')
        ? this.getSumByKey('actionsWebsite') / comparison.getSumByKey('actionsWebsite') - 1
        : null,
      actionsPhone: comparison.getSumByKey('actionsPhone')
        ? this.getSumByKey('actionsPhone') / comparison.getSumByKey('actionsPhone') - 1
        : null,
      actionsDrivingDirections: comparison.getSumByKey('actionsDrivingDirections')
        ? this.getSumByKey('actionsDrivingDirections') / comparison.getSumByKey('actionsDrivingDirections') - 1
        : null,
      rateCount: comparison.getSumByKey('rateCount')
        ? this.getSumByKey('rateCount') / comparison.getSumByKey('rateCount') - 1
        : null,
      commentCount: comparison.getSumByKey('commentCount')
        ? this.getSumByKey('commentCount') / comparison.getSumByKey('commentCount') - 1
        : null,
      searchCount: comparison.sumSearch ? this.sumSearch / comparison.sumSearch - 1 : null,
      actionCount: comparison.sumAction ? this.sumAction / comparison.sumAction - 1 : null,
      reviewCount: comparison.sumReview ? this.sumReview / comparison.sumReview - 1 : null,
    });
  }
}

interface InsightTableItemStatsDiffRateRecord {
  queriesDirect: number | null;
  queriesIndirect: number | null;
  queriesChain: number | null;
  actionsWebsite: number | null;
  actionsPhone: number | null;
  actionsDrivingDirections: number | null;
  rateCount: number | null;
  commentCount: number | null;

  searchCount: number | null;
  actionCount: number | null;
  reviewCount: number | null;

  actionRate: number | null;
}

/** インサイト（表）の各行の変化率のデータ */
class InsightTableItemStatsDiffRate extends Record<InsightTableItemStatsDiffRateRecord>({
  queriesChain: null,
  queriesIndirect: null,
  queriesDirect: null,
  actionsWebsite: null,
  actionsPhone: null,
  actionsDrivingDirections: null,
  rateCount: null,
  commentCount: null,
  searchCount: null,
  actionCount: null,
  reviewCount: null,
  actionRate: null,
}) {}

interface InsightTableItemStatsRecord {
  queriesDirect: number;
  queriesIndirect: number;
  queriesChain: number;
  actionsWebsite: number;
  actionsPhone: number;
  actionsDrivingDirections: number;
  rateCount: number;
  commentCount: number;
}

/** インサイト（表）の各行のインサイトデータ */
export class InsightTableItemStats extends Record<InsightTableItemStatsRecord>({
  queriesChain: 0,
  queriesIndirect: 0,
  queriesDirect: 0,
  actionsWebsite: 0,
  actionsPhone: 0,
  actionsDrivingDirections: 0,
  rateCount: 0,
  commentCount: 0,
}) {
  static fromJSON(data: JSObject = {}): InsightTableItemStats {
    return new InsightTableItemStats({
      queriesDirect: data.queries_direct,
      queriesIndirect: data.queries_indirect,
      queriesChain: data.queries_chain,
      actionsWebsite: data.actions_website,
      actionsPhone: data.actions_phone,
      actionsDrivingDirections: data.actions_driving_directions,
      rateCount: data.rate_count,
      commentCount: data.comment_count,
    });
  }

  /** 検索数の合計 */
  get searchCount() {
    return this.queriesDirect + this.queriesIndirect;
  }

  /** アクション数の合計 */
  get actionCount() {
    return this.actionsWebsite + this.actionsPhone + this.actionsDrivingDirections;
  }

  /** アクション率 */
  get actionRate() {
    const searchCount = this.searchCount;
    return searchCount ? this.actionCount / searchCount : 0;
  }

  /** クチコミ数の合計 */
  get reviewCount(): number {
    return this.rateCount + this.commentCount;
  }

  /** 比較対象の値からの変化率 */
  getDiffRate(comparison: InsightTableItemStats) {
    return new InsightTableItemStatsDiffRate({
      // 比較対象が 0 の場合、値は Infinity (number) になる
      queriesChain: comparison.queriesChain ? this.queriesChain / comparison.queriesChain - 1 : null,
      queriesIndirect: comparison.queriesIndirect ? this.queriesIndirect / comparison.queriesIndirect - 1 : null,
      queriesDirect: comparison.queriesDirect ? this.queriesDirect / comparison.queriesDirect - 1 : null,
      actionsWebsite: comparison.actionsWebsite ? this.actionsWebsite / comparison.actionsWebsite - 1 : null,
      actionsPhone: comparison.actionsPhone ? this.actionsPhone / comparison.actionsPhone - 1 : null,
      actionsDrivingDirections: comparison.actionsDrivingDirections
        ? this.actionsDrivingDirections / comparison.actionsDrivingDirections - 1
        : null,
      rateCount: comparison.rateCount ? this.rateCount / comparison.rateCount - 1 : null,
      commentCount: comparison.commentCount ? this.commentCount / comparison.commentCount - 1 : null,

      searchCount: comparison.searchCount ? this.searchCount / comparison.searchCount - 1 : null,
      actionCount: comparison.actionCount ? this.actionCount / comparison.actionCount - 1 : null,
      reviewCount: comparison.reviewCount ? this.reviewCount / comparison.reviewCount - 1 : null,

      actionRate: comparison.actionRate ? this.actionRate / comparison.actionRate - 1 : null,
    });
  }
}

interface ComparisonInsightTableItemRecord {
  storeId: number;
  store: Store; // APIレスポンスには含まれていなくて、フロントエンド側で埋め込む情報
  stats: InsightTableItemStats;
  comparisonStats: InsightTableItemStats;
  diffRate: InsightTableItemStatsDiffRate;
}
export class ComparisonInsightTableItem extends Record<ComparisonInsightTableItemRecord>({
  storeId: 0,
  store: new Store(),
  stats: new InsightTableItemStats(),
  comparisonStats: new InsightTableItemStats(),
  diffRate: new InsightTableItemStatsDiffRate(),
}) {}

interface InsightTableItemRecord {
  storeId: number;
  store: Store; // APIレスポンスには含まれていなくて、フロントエンド側で埋め込む情報
  stats: InsightTableItemStats;
}

/** インサイト（表）の各行のデータ */
export class InsightTableItem extends Record<InsightTableItemRecord>({
  storeId: 0,
  store: new Store(),
  stats: new InsightTableItemStats(),
}) {
  static fromJSON(data: JSObject = {}): InsightTableItem {
    return new InsightTableItem({
      storeId: data.store_id,
      store: data.store,
      stats: InsightTableItemStats.fromJSON(data.stats),
    });
  }

  // ２つの期間のデータをまとめる
  zip(comparison: InsightTableItem) {
    return new ComparisonInsightTableItem({
      storeId: this.storeId,
      store: this.store,
      stats: this.stats,
      comparisonStats: comparison.stats,
      diffRate: this.stats.getDiffRate(comparison.stats),
    });
  }
}

export type SortType =
  | 'storeName'
  | 'storeCode'
  | 'searchCount'
  | 'queriesDirect'
  | 'queriesIndirect'
  | 'queriesChain'
  | 'actionCount'
  | 'reviewCount'
  | 'actionRate';

interface InsightTableDataRecord {
  items: List<InsightTableItem>;
  sortType: SortType;
  isSortOrderDesc: boolean;
}

/** インサイト（表）のデータ */
export class InsightTableData extends Record<InsightTableDataRecord>({
  items: List<InsightTableItem>(),
  sortType: 'storeCode',
  isSortOrderDesc: false,
}) {
  static fromJSON(data: JSObject = {}): InsightTableData {
    return new InsightTableData({ items: List(data.stores.map((item: JSObject) => InsightTableItem.fromJSON(item))) });
  }

  /** 指定されたキーとソート順で並び替えられたデータを返す */
  get sortedItems(): List<InsightTableItem> {
    let sortedItems = this.items;
    const sortType = this.sortType;
    if (sortType === 'storeName') {
      sortedItems = sortedItems.sortBy((item) => item.store.shortName);
    } else if (sortType === 'storeCode') {
      // 店舗コードがないものを後ろに回すために、店舗コードがない場合は、JIS第二水準漢字の文字'熙'で補完する
      sortedItems = sortedItems.sortBy((item) => item.store.code || '熙');
    } else {
      sortedItems = sortedItems.sortBy((item) => item.stats[sortType]);
    }

    if (this.isSortOrderDesc) {
      sortedItems = sortedItems.reverse();
    }

    return sortedItems;
  }

  changeSortType(sortType: SortType) {
    // 同じ項目が選択されたら、昇順と降順を切り替える
    if (sortType === this.sortType) {
      return this.set('isSortOrderDesc', !this.isSortOrderDesc);
    }
    return this.set('sortType', sortType);
  }
}

interface InsightRecord {
  graphData: InsightGraphData;
  tableData: InsightTableData;
}

/** インサイトのデータを保持する */
export class Insight extends Record<InsightRecord>({
  graphData: new InsightGraphData(),
  tableData: new InsightTableData(),
}) {}
