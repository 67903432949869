export type DataLayer = { [key: string]: any };

/**
 * Googleタグマネージャーのデータレイヤーでデータを追加する
 * @param data
 */
export const pushDataLayer = (data: DataLayer) => {
  const _window = window as any;
  _window.dataLayer = _window.dataLayer || [];
  _window.dataLayer.push(data);
};
