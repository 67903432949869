import React from 'react';

import styled, { css } from 'styled-components';

import { COLOR } from 'style/color';

type Props = {
  className?: string;
  type: 'confirm' | 'reply' | 'completed' | 'unread';
};

export const StatusLabel: React.FC<Props> = ({ className, type }) => {
  let content = null;
  switch (type) {
    case 'confirm':
      content = <ConfirmLabel className={className}>確認待ち</ConfirmLabel>;
      break;
    case 'reply':
      content = <ReplyLabel className={className}>未返信</ReplyLabel>;
      break;
    case 'completed':
      content = <CompleteLabel className={className}>完了</CompleteLabel>;
      break;
    case 'unread':
      content = <ReplyLabel className={className}>未読あり</ReplyLabel>;
      break;
    default:
      return null;
  }
  return <Wrapper>{content}</Wrapper>;
};

const Wrapper = styled.div`
  display: inline-block;
`;

const CommonCss = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  width: fit-content;
  padding-left: 22px;
  &:after {
    position: absolute;
    top: 5px;
    left: 4px;
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
`;

const ConfirmLabel = styled.div`
  ${CommonCss}
  color: ${COLOR.GREEN};
  border-color: ${COLOR.GREEN};
  &:after {
    background-color: ${COLOR.GREEN};
  }
`;

const ReplyLabel = styled.div`
  ${CommonCss}
  color: ${COLOR.PURPLE};
  border-color: ${COLOR.PURPLE};
  &:after {
    background-color: ${COLOR.PURPLE};
  }
`;

const CompleteLabel = styled.div`
  ${CommonCss}
  color: ${COLOR.GRAY};
  border-color: ${COLOR.GRAY};
  &:after {
    background-color: ${COLOR.GRAY};
  }
`;
