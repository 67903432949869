import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Loader } from 'components/atoms/Loader';
import { BackArrow, StickyHeader, Title } from 'components/atoms/StickyHeader';
import { InstagramAccountCard } from 'components/pageComponents/Instagram/InstagramAccountCard';
import { InstagramAccountEditForm } from 'components/pageComponents/Instagram/InstagramAccountEditForm';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { InstagramAccount } from 'models/Domain/instagram';
import { InstagramActions } from 'modules/instagram/actions';
import { Path } from 'routes';

const useAvailableStores = () => {
  // GBP連携中かつ閉店ではない店舗のリスト
  const { stores } = useSelector((state) => state.store);
  return stores.filterByIsConnectedGmb(true).filterByUnclosed();
};

const useAvailableStoreLists = () => {
  // GBP連携中かつ閉店ではない店舗を１つ以上含むグループのリスト
  const { storeLists } = useSelector((state) => state.storeList);
  const availableStores = useAvailableStores();
  return storeLists.filterByIncludingConnectedGmbStore(availableStores).filterByIncludingUnclosedStore(availableStores);
};

export const InstagramAccountEdit = React.memo(() => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ source: string }>();
  const dispatch = useDispatch();
  const { isLoadingAccount: isLoading, account } = useSelector((state) => state.instagram);
  const {
    initializeEditPage,
    deleteAccountForDetail: deleteAccount,
    updateAccount,
  } = useMemo(() => bindActionCreators(InstagramActions, dispatch), [dispatch]);
  // エラーメッセージを表示させるかのフラグ
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const stores = useAvailableStores();
  const storeLists = useAvailableStoreLists();

  useEffect(() => {
    if (!isNaN(parseInt(id, 10))) {
      initializeEditPage(parseInt(id, 10));
    }
  }, [id, initializeEditPage]);

  const handleOnBack = useCallback(() => {
    if (state?.source === Path.instagram.index) {
      dispatch(goBack());
    } else {
      dispatch(replaceWithOrganizationId(Path.instagram.index));
    }
  }, [dispatch, state?.source]);

  const handleOnDelete = useCallback(() => {
    deleteAccount(account.id);
  }, [account, deleteAccount]);

  const handleOnUpdate = useCallback(
    (account: InstagramAccount) => {
      // バリデーションNGの場合、アラートを表示し、エラー内容を可視化
      if (!account.isValid) {
        window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
        setShowErrorMessage(true);
        return;
      }
      updateAccount(account);
    },
    [updateAccount, setShowErrorMessage],
  );

  return (
    <MainWrapper>
      <Helmet title={getPageTitle(`Instagramアカウント詳細`)} />
      <StickyHeader>
        <TitleWrapper onClick={handleOnBack}>
          <BackArrow />
          <Title>Instagramアカウント詳細</Title>
        </TitleWrapper>
      </StickyHeader>
      <StyledBody>
        {isLoading && (
          <LoadingWrapper>
            <Loader active={true} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
        {!isLoading && (
          <Wrapper>
            {!account.isValid && (
              <StyledAlert type={'error'}>
                <Alert.Title>アカウントの再連携が必要です</Alert.Title>
                <Alert.Section>
                  {/* FIXME: アカウントの連携が切れた理由が取得できるようになったら、理由と修正方法がわかるようにする */}
                  <Alert.Content>
                    Instagramアカウントとの連携に問題があるため、GBPへの投稿の自動反映が停止されています。
                    <br />
                    ページ下部の「Instagramアカウントの連携を解除する」をクリックしてアカウントの連携を解除してから、再度Instagramアカウントを追加してください。
                    <br />
                    ※原因がわからない場合や解決しない場合、画面左下のサポートからお問い合わせください。
                  </Alert.Content>
                </Alert.Section>
              </StyledAlert>
            )}
            <InstagramAccountCard account={account} />
            <InstagramAccountEditForm
              account={account}
              onDelete={handleOnDelete}
              onUpdate={handleOnUpdate}
              stores={stores}
              storeLists={storeLists}
              showErrorMessage={showErrorMessage}
            />
          </Wrapper>
        )}
      </StyledBody>
    </MainWrapper>
  );
});

const StyledBody = styled(Body)`
  position: relative;
  min-height: 50vh;
`;

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 32px;
`;
