import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { Paging } from 'components/molecules/Paging';
import { MemoModal } from 'components/pageComponents/Memo/MemoModal';
import { MemoSearchCondition } from 'components/pageComponents/Memo/MemoSearchCondition';
import { MemoTable } from 'components/pageComponents/Memo/MemoTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { Memo as MemoModel } from 'models/Domain/Memo/Memo';
import { MemoActions } from 'modules/memo/actions';

export const Memo: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedMemo, setSelectedMemo] = useState<MemoModel | null>(null);

  const dispatch = useDispatch();
  const { memoList, memoTagList, searchCondition, pagination } = useSelector((state) => state.memo);
  const { initializePage, commitSearchCondition, createMemo, updateMemo, deleteMemo } = useMemo(
    () => bindActionCreators(MemoActions, dispatch),
    [dispatch],
  );

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const handleOnChangeNo = useCallback(
    (value: number) => {
      const newSearchCondition = searchCondition.update('pagination', (pagination) => pagination.set('page', value));
      commitSearchCondition(newSearchCondition);
    },
    [commitSearchCondition, searchCondition],
  );

  const handleOnSelectMemo = useCallback((memo: MemoModel) => {
    setSelectedMemo(memo);
    setIsOpenModal(true);
  }, []);

  const handleOnCreateMemo = useCallback(
    (memo: MemoModel) => {
      createMemo(memo);
    },
    [createMemo],
  );
  const handleOnUpdateMemo = useCallback(
    (memo: MemoModel) => {
      updateMemo(memo);
    },
    [updateMemo],
  );
  const handleOnDeleteMemo = useCallback(
    (memo: MemoModel) => {
      deleteMemo(memo);
    },
    [deleteMemo],
  );

  const handleOnClose = useCallback(() => {
    setIsOpenModal(false);
    setSelectedMemo(null);
  }, []);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('施策メモ')} />
      <StickyHeader>
        <Title>施策メモ</Title>
        <CreateButton onClick={() => setIsOpenModal(true)}>メモを作成</CreateButton>
      </StickyHeader>
      <WideBody>
        <MemoSearchCondition
          committedSearchCondition={searchCondition}
          commitSearchCondition={commitSearchCondition}
          tagList={memoTagList}
        />
        <Count>
          {pagination.total_count}件のメモ（
          {Math.min(pagination.limit * (pagination.current_page - 1) + 1, pagination.total_count)}〜
          {Math.min(pagination.limit * pagination.current_page, pagination.total_count)}件目のメモを表示中）
        </Count>
        <TableWrapper>
          <MemoTable memoList={memoList} onSelectMemo={handleOnSelectMemo} />
        </TableWrapper>
        <Paging
          currentPage={pagination.current_page}
          viewContentSize={pagination.limit}
          totalContentSize={pagination.total_count}
          onChangeNo={handleOnChangeNo}
        />
      </WideBody>
      <MemoModal
        isOpen={isOpenModal}
        onClose={handleOnClose}
        memo={selectedMemo}
        tagList={memoTagList}
        onCreate={handleOnCreateMemo}
        onUpdate={handleOnUpdateMemo}
        onDelete={handleOnDeleteMemo}
      />
    </MainWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const Count = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
`;

const CreateButton = styled(Button).attrs({ priority: 'high' })`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 14px;
  }
`;
