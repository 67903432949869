import React, { useCallback } from 'react';

import { Dayjs } from 'dayjs';
import { List, Set } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { DropdownMenu, DropdownMenuItem } from 'components/molecules/DropdownMenu';
import { Paging } from 'components/molecules/Paging';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { MapSearchRankHelp as Help } from 'helpers/ContextHelp';
import { MapSearchRankTableData, MapSearchRankTableItem } from 'models/Domain/MapSearchRank/MapSearchRank';
import { Sort, SortKey } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const convertNumberToText = (value: number | null, ifNull = 'ー') => {
  // 値を小数第１位までの表示、nullならば「ー」
  return value != null
    ? `${Math.abs(value).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}`
    : ifNull;
};

const sortKeyLabelMap = {
  store_name: '店舗名',
  search_word: 'キーワード',
  area_name: '検索地点',
  rank: '平均掲載順位',
  latest_rank: '最新掲載順位',
  diff: '集計期間の推移',
  rank_comparison: '平均掲載順位（比較期間）',
  diff_comparison: '比較期間との差分',
  tag: 'タグ',
} as Record<SortKey, string>;

interface Props {
  className?: string;
  isLoading: boolean;
  isSelectedAverage: boolean;
  selectedConfigIds: Set<number>;
  isEnabledComparison: boolean;
  tableData: MapSearchRankTableData;
  sort: Sort;
  displaySearchVolumeLink: boolean;
  onChangeSort: (sortKey: SortKey) => void;
  onChangePage: (page: number) => void;
  onSelectAverage: () => void;
  onSelectConfig: (configId: number) => void;
  onSelectAllConfig: () => void;
  onClickTableItem: (item: MapSearchRankTableItem) => void;
  onClickOpenSortConditionModal: () => void;
}

export const MapSearchRankTable: React.FC<Props> = ({
  className,
  isLoading,
  isSelectedAverage,
  selectedConfigIds,
  isEnabledComparison,
  tableData,
  sort,
  displaySearchVolumeLink,
  onChangeSort,
  onChangePage,
  onSelectAverage,
  onSelectConfig,
  onSelectAllConfig,
  onClickTableItem,
  onClickOpenSortConditionModal,
}) => {
  const {
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    items,
    average,
    pagination: {
      current_page: currentPage,
      total_count: totalCount,
      limit,
      range: [itemStart, itemEnd],
    },
  } = tableData;

  return (
    <Wrapper className={className}>
      <TextWrapper>
        <CountText>
          {`${totalCount.toLocaleString()}件のキーワード`}
          {totalCount > 0 && (
            <>
              （{itemStart.toLocaleString()}〜{itemEnd.toLocaleString()}件目を表示中）
            </>
          )}
        </CountText>
        <SortConditionWrapper>
          <OpenSortConditionModalButton priority='low' onClick={onClickOpenSortConditionModal}>
            並べ替え設定
          </OpenSortConditionModalButton>
          <SortConditionText>
            {sort.items.size > 0 &&
              `（現在の設定 : ${sort.items.map((sortItem) => sortKeyLabelMap[sortItem.key]).join(' > ')}）`}
          </SortConditionText>
        </SortConditionWrapper>
      </TextWrapper>
      <TableWrapper>
        <InnerTable
          isSelectedAverage={isSelectedAverage}
          selectedConfigIds={selectedConfigIds}
          average={average}
          items={items}
          isEnabledComparison={isEnabledComparison}
          startDate={startDate}
          endDate={endDate}
          comparisonStartDate={comparisonStartDate}
          comparisonEndDate={comparisonEndDate}
          sort={sort}
          displaySearchVolumeLink={displaySearchVolumeLink}
          onChangeSort={onChangeSort}
          onSelectAverage={onSelectAverage}
          onSelectConfig={onSelectConfig}
          onSelectAllConfig={onSelectAllConfig}
          onClickTableItem={onClickTableItem}
        />
        {isLoading && (
          <>
            <LoadingWrapper>
              <Loader active={isLoading} size='big' inline={true} />
            </LoadingWrapper>
          </>
        )}
      </TableWrapper>
      <Paging
        currentPage={currentPage}
        viewContentSize={limit}
        totalContentSize={totalCount}
        onChangeNo={onChangePage}
      />
    </Wrapper>
  );
};

interface InnerTableProps {
  isSelectedAverage: boolean;
  selectedConfigIds: Set<number>;
  average: MapSearchRankTableItem;
  items: List<MapSearchRankTableItem>;
  isEnabledComparison: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  sort: Sort;
  displaySearchVolumeLink: boolean;
  onChangeSort: (sortKey: SortKey) => void;
  onSelectAverage: () => void;
  onSelectConfig: (configId: number) => void;
  onSelectAllConfig: () => void;
  onClickTableItem: (item: MapSearchRankTableItem) => void;
}

const InnerTable = React.memo<InnerTableProps>(
  ({
    isSelectedAverage,
    selectedConfigIds,
    average,
    items,
    isEnabledComparison,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    sort,
    displaySearchVolumeLink,
    onChangeSort,
    onSelectAverage,
    onSelectConfig,
    onSelectAllConfig,
    onClickTableItem,
  }) => {
    const startMonth = startDate.format('YYYY-MM');
    const endMonth = endDate.format('YYYY-MM');
    return (
      <StyledTable unstackable={true}>
        <Header
          isEnabledComparison={isEnabledComparison}
          isAllSelected={items.size > 0 && selectedConfigIds.size === items.size}
          startDate={startDate}
          endDate={endDate}
          comparisonStartDate={comparisonStartDate}
          comparisonEndDate={comparisonEndDate}
          sort={sort}
          onChangeSort={onChangeSort}
          onSelectAllConfig={onSelectAllConfig}
        />
        <TableBody>
          {average && (
            <SummaryRow
              isEnabledComparison={isEnabledComparison}
              isSelected={isSelectedAverage}
              isDisabled={isSelectedAverage && selectedConfigIds.isEmpty()}
              onSelect={onSelectAverage}
              item={average}
            />
          )}
          {items.map((item, index) => (
            <ItemRow
              key={index}
              isEnabledComparison={isEnabledComparison}
              isSelected={selectedConfigIds.has(item.configId)}
              onSelect={() => onSelectConfig(item.configId)}
              onClick={() => onClickTableItem(item)}
              item={item}
              startMonth={startMonth}
              endMonth={endMonth}
              displaySearchVolumeLink={displaySearchVolumeLink}
            />
          ))}
        </TableBody>
      </StyledTable>
    );
  },
);

interface HeaderProps {
  isEnabledComparison: boolean;
  isAllSelected: boolean;
  sort: Sort;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  onChangeSort: (sortKey: SortKey) => void;
  onSelectAllConfig: () => void;
}

const Header = React.memo<HeaderProps>(
  ({
    isEnabledComparison,
    isAllSelected,
    sort,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    onChangeSort,
    onSelectAllConfig,
  }) => (
    <StyledTableHeader>
      <TableHeaderRow>
        <TableHeaderCell>
          <CheckboxWrapper>
            <SmallCheckBox checked={isAllSelected} onChange={onSelectAllConfig} />
          </CheckboxWrapper>
        </TableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => onChangeSort('store_name')}>
          <TableHeaderFlexWrapper position={'left'}>
            {sort.hasSortKey('store_name') && (
              <SortTriangle isSortOrderDesc={sort.getSortOrder('store_name') === 'desc'} />
            )}
            店舗名
          </TableHeaderFlexWrapper>
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => onChangeSort('search_word')}>
          <TableHeaderFlexWrapper position={'left'}>
            {sort.hasSortKey('search_word') && (
              <SortTriangle isSortOrderDesc={sort.getSortOrder('search_word') === 'desc'} />
            )}
            キーワード
          </TableHeaderFlexWrapper>
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => onChangeSort('area_name')}>
          <TableHeaderFlexWrapper position={'left'}>
            {sort.hasSortKey('area_name') && (
              <SortTriangle isSortOrderDesc={sort.getSortOrder('area_name') === 'desc'} />
            )}
            検索地点
          </TableHeaderFlexWrapper>
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => onChangeSort('rank')}>
          <TableHeaderFlexWrapper position={'right'}>
            {sort.hasSortKey('rank') && <SortTriangle isSortOrderDesc={sort.getSortOrder('rank') === 'desc'} />}
            <RankContainer>
              平均掲載順位
              {isEnabledComparison && (
                <RankDateLabel>
                  {startDate && startDate.format('YYYY/MM/DD')}〜{endDate && endDate.format('YYYY/MM/DD')}
                </RankDateLabel>
              )}
            </RankContainer>
            <ContextHelp content={Help.rank} />
          </TableHeaderFlexWrapper>
        </StyledSortableTableHeaderCell>
        {!isEnabledComparison && (
          <>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('latest_rank')}>
              <TableHeaderFlexWrapper position={'right'}>
                {sort.hasSortKey('latest_rank') && (
                  <SortTriangle isSortOrderDesc={sort.getSortOrder('latest_rank') === 'desc'} />
                )}
                <RankContainer>
                  最新掲載順位
                  {isEnabledComparison && (
                    <RankDateLabel>
                      {startDate && startDate.format('YYYY/MM/DD')}〜{endDate && endDate.format('YYYY/MM/DD')}
                    </RankDateLabel>
                  )}
                </RankContainer>
                <ContextHelp content={Help.latestRank} />
              </TableHeaderFlexWrapper>
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('diff')}>
              <TableHeaderFlexWrapper position={'right'}>
                {sort.hasSortKey('diff') && <SortTriangle isSortOrderDesc={sort.getSortOrder('diff') === 'desc'} />}
                集計期間の推移
                <ContextHelp content={Help.diff} />
              </TableHeaderFlexWrapper>
            </StyledSortableTableHeaderCell>
          </>
        )}
        {isEnabledComparison && (
          <>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('rank_comparison')}>
              <TableHeaderFlexWrapper position={'right'}>
                {sort.hasSortKey('rank_comparison') && (
                  <SortTriangle isSortOrderDesc={sort.getSortOrder('rank_comparison') === 'desc'} />
                )}
                <RankContainer>
                  平均掲載順位
                  <RankDateLabel>
                    {comparisonStartDate && comparisonStartDate.format('YYYY/MM/DD')}〜
                    {comparisonEndDate && comparisonEndDate.format('YYYY/MM/DD')}
                  </RankDateLabel>
                </RankContainer>
                <ContextHelp content={Help.comparisonRank} />
              </TableHeaderFlexWrapper>
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('diff_comparison')}>
              <TableHeaderFlexWrapper position={'right'}>
                {sort.hasSortKey('diff_comparison') && (
                  <SortTriangle isSortOrderDesc={sort.getSortOrder('diff_comparison') === 'desc'} />
                )}
                比較期間との差分
                <ContextHelp content={Help.diffComparison} />
              </TableHeaderFlexWrapper>
            </StyledSortableTableHeaderCell>
          </>
        )}
      </TableHeaderRow>
    </StyledTableHeader>
  ),
);

interface RowProps {
  isEnabledComparison: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
  onSelect: () => void;
  item: MapSearchRankTableItem;
}

const SummaryRow = React.memo<RowProps>(({ isEnabledComparison, isDisabled, isSelected, onSelect, item }) => (
  <SummaryTableRow onClick={() => !isDisabled && onSelect()}>
    <CheckboxCell>
      <CheckboxWrapper>
        <SmallCheckBox checked={isSelected} disabled={isDisabled} />
      </CheckboxWrapper>
    </CheckboxCell>
    <TextTableCell>平均</TextTableCell>
    <TextTableCell />
    <TextTableCell />
    <NumberTableCell>{convertNumberToText(item.rank)}</NumberTableCell>
    {!isEnabledComparison && (
      <>
        <NumberTableCell>{convertNumberToText(item.latestRank)}</NumberTableCell>
        <NumberTableCell>
          <Diff value={item.diff}>{convertNumberToText(item.diff)}</Diff>
        </NumberTableCell>
      </>
    )}
    {isEnabledComparison && (
      <>
        <NumberTableCell>{convertNumberToText(item.rankComparison)}</NumberTableCell>
        <NumberTableCell>
          <Diff value={item.diffComparison}>{convertNumberToText(item.diffComparison)}</Diff>
        </NumberTableCell>
      </>
    )}
  </SummaryTableRow>
));

type ItemRowProps = RowProps & {
  onClick: () => void;
  startMonth: string;
  endMonth: string;
  displaySearchVolumeLink: boolean;
};

const ItemRow = React.memo<ItemRowProps>(
  ({ isEnabledComparison, isSelected, onSelect, onClick, item, startMonth, endMonth, displaySearchVolumeLink }) => {
    const handleOnSelect = useCallback(
      (e) => {
        e.stopPropagation();
        onSelect();
      },
      [onSelect],
    );

    const searchParams = new URLSearchParams();
    searchParams.set('si', item.storeId.toString());
    searchParams.set('sw', item.searchWord);
    searchParams.set('sm', startMonth);
    searchParams.set('em', endMonth);
    const dropdownMenuItems: DropdownMenuItem[] = [];
    if (displaySearchVolumeLink) {
      dropdownMenuItems.push({
        label: '検索ボリューム',
        link: `${Path.searchVolume.index}?${searchParams.toString()}`,
      });
    }
    return (
      <StyledTableRow onClick={onClick}>
        <CheckboxCell>
          <CheckboxWrapper>
            <SmallCheckBox checked={isSelected} onClick={handleOnSelect} />
          </CheckboxWrapper>
        </CheckboxCell>
        <TextTableCell>{item.storeName}</TextTableCell>
        <TextTableCell>
          <KeywordWrapper>
            {item.searchWord}
            {dropdownMenuItems.length > 0 && (
              <DropdownMenu trigger={<StyledIcon type='settings' />} items={dropdownMenuItems} />
            )}
          </KeywordWrapper>
        </TextTableCell>
        <TextTableCell>{item.areaName}</TextTableCell>
        <NumberTableCell>{convertNumberToText(item.rank)}</NumberTableCell>
        {!isEnabledComparison && (
          <>
            <NumberTableCell>{convertNumberToText(item.latestRank)}</NumberTableCell>
            <NumberTableCell>
              <Diff value={item.diff}>{convertNumberToText(item.diff)}</Diff>
            </NumberTableCell>
          </>
        )}
        {isEnabledComparison && (
          <>
            <NumberTableCell>{convertNumberToText(item.rankComparison)}</NumberTableCell>
            <NumberTableCell>
              <Diff value={item.diffComparison}>{convertNumberToText(item.diffComparison)}</Diff>
            </NumberTableCell>
          </>
        )}
      </StyledTableRow>
    );
  },
);

const Wrapper = styled.div``;

const TextWrapper = styled.div`
  display: flex;
`;

export const CountText = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  position: relative;
`;

const StyledTable = styled(Table)`
  &&& {
    border-collapse: separate;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 141px;
    z-index: 1;
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)`
  max-width: 180px;
`;

export const TableHeaderFlexWrapper = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  justify-content: ${({ position }) => (position === 'right' ? 'flex-end' : 'flex-start')};
  align-items: center;
  gap: 8px;
`;

export const CheckboxCell = styled(TableCell)`
  &&& {
    width: 54px;
  }
`;

const TextTableCell = styled(TableCell)`
  &&& {
    font-weight: bold;
    word-break: break-word;
  }
`;

export const NumberTableCell = styled(TableCell)`
  &&& {
    text-align: right;
    font-family: monospace;
    max-width: 150px;
  }
`;

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
`;

const StyledTableRow = styled(TableRow)`
  &&& {
    cursor: pointer;
  }

  ${StyledIcon} {
    opacity: 0;
  }

  &:hover {
    ${StyledIcon} {
      opacity: 1;
    }
  }
`;

const SummaryTableRow = styled(StyledTableRow)`
  &&& {
    background: #f9fafb;
    ${TableCell} {
      border-bottom: 1px solid ${COLOR.LIGHT_GRAY};
    }
  }
`;

// valueが0より大きければ「↑」をつけて緑色、0より小さければ「↓」をつけて赤色、変化がなければ灰色で表示する
export const Diff = styled.span.attrs<{ value: number | null }, { isUp: boolean; isDown: boolean }>(({ value }) => ({
  isUp: value != null && value > 0,
  isDown: value != null && value < 0,
}))<{
  value: number | null;
}>`
  color: ${({ isUp, isDown }) => (isUp ? COLOR.GREEN : isDown ? COLOR.RED : COLOR.DARK_GRAY)};
  &:before {
    content: '${({ isUp, isDown }) => (isUp ? '↑' : isDown ? '↓' : '')}';
    margin-right: 4px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 14px;
  margin-left: 8px;
`;

export const RankContainer = styled.div``;

export const RankDateLabel = styled.div`
  font-size: 9px;
  color: ${COLOR.DARK_GRAY};
`;

export const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const KeywordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SortConditionWrapper = styled.div`
  display: flex;
  font-size: 14px;
  margin: 0 0 16px 10px;
`;

const OpenSortConditionModalButton = styled(Button)``;

const SortConditionText = styled.div`
  font-weight: bold;
  color: ${COLOR.GRAY};
`;
