import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ActiveCircle, ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import {
  DataKey,
  MemoIconData,
  getComparisonDataKey as _getComparisonDataKey,
  getTargetDataKey as _getTargetDataKey,
  isComparisonDataKey as _isComparisonDataKey,
  getDateLabelWithWeekOfDay,
  getRateText,
  getValueText,
} from 'helpers/graph';
import { addNullableValues } from 'helpers/utils';
import { InteractionType, StatsType, gbpPerformanceStatsKeyMapping } from 'models/Domain/GbpPerformance/GbpPerformance';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';
import { assertNever } from 'types/Common';

import { GraphType } from './index';

// 非活性のグラフの透明度
const INACTIVE_TRANSPARENCY = 0.8 as const;
// 最大のドット数
const MAX_DOTS = 50 as const;

type YAxisId = 'impressions' | 'interactions';

// グラフに使われるStatsTypeのリスト
const performanceGraphStatsTypes = [
  'businessImpressions',
  'interactions',
  'businessImpressionsDesktopMaps',
  'businessImpressionsDesktopSearch',
  'businessImpressionsMobileMaps',
  'businessImpressionsMobileSearch',
  'websiteClicks',
  'callClicks',
  'businessDirectionRequests',
  'businessConversations',
  'businessBookings',
] satisfies StatsType[];

// グラフに使われるStatsTypeのリスト
const impressionsGraphStatsTypes = [
  'businessImpressionsDesktopMaps',
  'businessImpressionsMobileMaps',
  'businessImpressionsDesktopSearch',
  'businessImpressionsMobileSearch',
  'interactions',
] satisfies StatsType[];

// グラフに使われるStatsTypeのリスト
const interactionsGraphStatsTypes = [
  'businessImpressions',
  'callClicks',
  'businessDirectionRequests',
  'websiteClicks',
  'businessConversations',
  'businessBookings',
] satisfies StatsType[];

export type TargetDataKey = (typeof performanceGraphStatsTypes)[number];
export type GraphDataKey = DataKey<TargetDataKey>;

export type PerformanceGraphDataItem = Record<'date' | 'comparisonDate', string> &
  Record<GraphDataKey, number | null | undefined>;

// 共通処理に型を指定する
const isComparisonDataKey = _isComparisonDataKey<TargetDataKey>;
const getTargetDataKey = _getTargetDataKey<TargetDataKey>;
const getComparisonDataKey = _getComparisonDataKey<TargetDataKey>;

const isDataKey = (statsType: StatsType): statsType is TargetDataKey => {
  return (performanceGraphStatsTypes as StatsType[]).includes(statsType);
};

/**
 * dataKeyがユーザー数用か
 * @param dataKey
 */
const isImpressionDataKey = (dataKey: GraphDataKey) => {
  const key = getTargetDataKey(dataKey);
  switch (key) {
    case 'businessImpressions':
    case 'businessImpressionsDesktopMaps':
    case 'businessImpressionsMobileMaps':
    case 'businessImpressionsDesktopSearch':
    case 'businessImpressionsMobileSearch':
      return true;
    default:
      return false;
  }
};

/**
 * dataKeyがインプレッション数用か
 * @param dataKey
 */
const isInteractionDataKey = (dataKey: GraphDataKey) => {
  const key = getTargetDataKey(dataKey);
  switch (key) {
    case 'interactions':
    case 'websiteClicks':
    case 'callClicks':
    case 'businessBookings':
    case 'businessConversations':
    case 'businessDirectionRequests':
      return true;
    default:
      return false;
  }
};

const impressionColors = UNIVERSAL_COLORS.slice(2, 6).toArray();
const interactionColors = UNIVERSAL_COLORS.slice(2, 7).toArray();

/**
 * dataKeyから色を取得する
 * @param dataKey
 */
const getStrokeColor = (dataKey: GraphDataKey): string => {
  const key = getTargetDataKey(dataKey);
  switch (key) {
    case 'businessImpressions':
      return UNIVERSAL_COLORS.get(0) as string;
    case 'businessImpressionsDesktopMaps':
      return impressionColors[0];
    case 'businessImpressionsMobileMaps':
      return impressionColors[1];
    case 'businessImpressionsDesktopSearch':
      return impressionColors[2];
    case 'businessImpressionsMobileSearch':
      return impressionColors[3];
    case 'interactions':
      return UNIVERSAL_COLORS.get(1) as string;
    case 'callClicks':
      return interactionColors[0];
    case 'businessConversations':
      return interactionColors[1];
    case 'businessBookings':
      return interactionColors[2];
    case 'businessDirectionRequests':
      return interactionColors[3];
    case 'websiteClicks':
      return interactionColors[4];
    default:
      throw new Error(`"${dataKey}" is unexpected dataKey`);
  }
};

/**
 * dateKeyからY軸のIDを取得する
 * @param dataKey
 */
const getYAxisId = (dataKey: GraphDataKey): YAxisId => {
  if (isImpressionDataKey(dataKey)) {
    return 'impressions';
  } else if (isInteractionDataKey(dataKey)) {
    return 'interactions';
  } else {
    throw new Error(`"${dataKey}" is unexpected dataKey`);
  }
};

/**
 * dateKeyの日本語表記を取得する
 * @param dataKey
 * @param interactionType
 */
const getDataKeyLabel = (dataKey: GraphDataKey, interactionType: InteractionType) => {
  const isComparison = isComparisonDataKey(dataKey);
  let label: string = gbpPerformanceStatsKeyMapping[getTargetDataKey(dataKey)];
  if (getTargetDataKey(dataKey) == 'interactions') {
    label = `インタラクション${interactionType === 'rate' ? '率' : '数'}`;
  }
  return isComparison ? `${label}（比較）` : label;
};

type PerformanceGraphType = Extract<GraphType, 'impressions' | 'interactions'>;

type PerformanceGraphProps = {
  graphData: PerformanceGraphDataItem[];
  interactionType: InteractionType;
  showComparisonGraph: boolean;
  graphType: PerformanceGraphType;
  isStacked: boolean;
  height: number;
  displayStats: StatsType[];
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
  onChangeActiveStats?: (statsType: StatsType) => void;
  showInactiveLegend: boolean;
  impressionGraphSettings?: GraphSettings;
  interactionGraphSettings?: GraphSettings;
};

export const PerformanceGraph = React.memo<PerformanceGraphProps>(
  ({
    graphData: composedGraphData,
    interactionType,
    showComparisonGraph,
    graphType,
    displayStats,
    isStacked,
    height,
    memoData,
    onClickMemo,
    onChangeActiveStats,
    showInactiveLegend,
    impressionGraphSettings = new GraphSettings(),
    interactionGraphSettings = new GraphSettings(),
  }) => {
    // ホバーしたときに強調表示するデータのキー
    const [activeDataKey, setActiveDataKey] = useState<TargetDataKey | null>(null);

    // ドットを表示するのはデータが50個まで
    const showDots = composedGraphData.length <= MAX_DOTS;

    // ユーザー数向けY軸
    const impressionsYAxisTickFormatter = useCallback((value) => {
      return getValueText(value);
    }, []);

    // インタラクション数向けY軸
    const interactionsYAxisTickFormatter = useCallback(
      (value) => {
        return interactionType === 'rate' ? getRateText(value) : getValueText(value);
      },
      [interactionType],
    );

    const impressionsYAxisSettings = useMemo((): { orientation: 'left' | 'right'; hide: boolean } => {
      // ユーザー数では左側に表示、インタラクションでは右側に表示
      // 合計が選択されていなければ表示しない
      switch (graphType) {
        case 'impressions':
          return { orientation: 'left', hide: false };
        case 'interactions':
          return { orientation: 'right', hide: !displayStats.includes('businessImpressions') };
        default:
          return assertNever(graphType);
      }
    }, [displayStats, graphType]);

    const interactionsYAxisSettings = useMemo((): { orientation: 'left' | 'right'; hide: boolean } => {
      // ユーザー数では右側に表示、インタラクションでは左側に表示
      // 合計が選択されていなければ表示しない
      switch (graphType) {
        case 'impressions':
          return { orientation: 'right', hide: !displayStats.includes('interactions') };
        case 'interactions':
          return { orientation: 'left', hide: false };
        default:
          return assertNever(graphType);
      }
    }, [displayStats, graphType]);

    // 比較期間なしの場合に表示するツールチップ
    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];

        // itemsをimpressionとinteractionで分ける
        const impressionItems = items.filter((item) => isImpressionDataKey(item.dataKey as GraphDataKey));
        const interactionItems = items.filter((item) => isInteractionDataKey(item.dataKey as GraphDataKey));

        // 選択中の合計値を求める
        const selectedImpressions = addNullableValues(...impressionItems.map((item) => item.value));
        const selectedInteractions = addNullableValues(...interactionItems.map((item) => item.value));

        // 2項目以上選択されている場合に合計値を表示する
        const showImpressions = impressionItems.length > 1 && graphType === 'impressions';
        const showInteractions = interactionItems.length > 1 && graphType === 'interactions';

        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {showImpressions && (
              <ChartTooltip.Item key={'impressions'}>
                <ChartTooltip.Label color={getStrokeColor('businessImpressions')}>
                  ユーザー数（合計）
                </ChartTooltip.Label>
                <ChartTooltip.Value color={COLOR.BLACK}>{getValueText(selectedImpressions)}</ChartTooltip.Value>
              </ChartTooltip.Item>
            )}
            {impressionItems.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const textColor = COLOR.BLACK;
              const itemColor = getStrokeColor(dataKey);
              return (
                <ChartTooltip.Item key={dataKey}>
                  <ChartTooltip.Label color={itemColor}>
                    <ActiveCircle color={item.color} />
                    {item.name}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>{getValueText(item.value)}</ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
            {showInteractions && (
              <ChartTooltip.Item key={'impressions'}>
                <ChartTooltip.Label color={getStrokeColor('interactions')}>
                  インタラクション{interactionType === 'rate' ? '率' : '数'}（合計）
                </ChartTooltip.Label>
                <ChartTooltip.Value color={COLOR.BLACK}>
                  {interactionType === 'rate' ? getRateText(selectedInteractions) : getValueText(selectedInteractions)}
                </ChartTooltip.Value>
              </ChartTooltip.Item>
            )}
            {interactionItems.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const textColor = COLOR.BLACK;
              const isInteractionRate = interactionType === 'rate';
              const itemColor = getStrokeColor(dataKey);
              return (
                <ChartTooltip.Item key={dataKey}>
                  <ChartTooltip.Label color={itemColor}>
                    <ActiveCircle color={item.color} />
                    {item.name}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>
                    {isInteractionRate ? getRateText(item.value) : getValueText(item.value)}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [graphType, interactionType],
    );

    // 比較期間ありの場合に表示するツールチップ
    const renderComparisonTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];

        // itemsをimpressionとinteractionで分ける
        const impressionItems = items.filter((item) => isImpressionDataKey(item.dataKey as GraphDataKey));
        const interactionItems = items.filter((item) => isInteractionDataKey(item.dataKey as GraphDataKey));

        const payload = items[0]?.payload;
        const date = getDateLabelWithWeekOfDay(payload?.date);
        const comparisonDate = getDateLabelWithWeekOfDay(payload?.comparisonDate);

        // 選択中の合計値を求める
        const selectedImpressions = impressionItems
          .filter((item) => !isComparisonDataKey(item.dataKey as GraphDataKey))
          .reduce((sum, item) => sum + (item.value ?? 0), 0);
        const selectedComparisonImpressions = impressionItems
          .filter((item) => isComparisonDataKey(item.dataKey as GraphDataKey))
          .reduce((sum, item) => sum + (item.value ?? 0), 0);
        const selectedInteractions = interactionItems
          .filter((item) => !isComparisonDataKey(item.dataKey as GraphDataKey))
          .reduce((sum, item) => sum + (item.value ?? 0), 0);
        const selectedComparisonInteractions = interactionItems
          .filter((item) => isComparisonDataKey(item.dataKey as GraphDataKey))
          .reduce((sum, item) => sum + (item.value ?? 0), 0);

        // 2項目以上選択されている場合に合計値を表示する
        const showImpressions = impressionItems.length > 2 && graphType === 'impressions';
        const showInteractions = interactionItems.length > 2 && graphType === 'interactions';

        let usedTitle = ImmutableSet<string>(); // 使用済みのタイトル
        return (
          <ChartTooltip>
            {showImpressions && (
              <React.Fragment key={'impressions'}>
                <ChartTooltip.Title color={getStrokeColor('businessImpressions')}>
                  ユーザー数（合計）
                </ChartTooltip.Title>
                <ChartTooltip.Item>
                  <ChartTooltip.Label color={COLOR.BLACK}>{date}</ChartTooltip.Label>
                  <ChartTooltip.Value color={COLOR.BLACK}>{selectedImpressions}</ChartTooltip.Value>
                </ChartTooltip.Item>
                <ChartTooltip.Item>
                  <ChartTooltip.Label color={COLOR.BLACK}>{comparisonDate}</ChartTooltip.Label>
                  <ChartTooltip.Value color={COLOR.BLACK}>{selectedComparisonImpressions}</ChartTooltip.Value>
                </ChartTooltip.Item>
              </React.Fragment>
            )}
            {impressionItems.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const targetDataKey = getTargetDataKey(dataKey);
              const isComparison = isComparisonDataKey(dataKey);
              const title = getDataKeyLabel(targetDataKey, interactionType);
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && (
                    <ChartTooltip.Title color={itemColor}>
                      <ActiveCircle color={item.color} />
                      {title}
                    </ChartTooltip.Title>
                  )}
                  <ChartTooltip.Item>
                    <ChartTooltip.Label color={textColor}>{isComparison ? comparisonDate : date}</ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>{getValueText(item.value)}</ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
            {showInteractions && (
              <React.Fragment key={'interactions'}>
                <ChartTooltip.Title color={getStrokeColor('interactions')}>
                  インタラクション{interactionType === 'rate' ? '率' : '数'}（合計）
                </ChartTooltip.Title>
                <ChartTooltip.Item>
                  <ChartTooltip.Label color={COLOR.BLACK}>{date}</ChartTooltip.Label>
                  <ChartTooltip.Value color={COLOR.BLACK}>
                    {interactionType === 'rate'
                      ? getRateText(selectedInteractions)
                      : getValueText(selectedInteractions)}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
                <ChartTooltip.Item>
                  <ChartTooltip.Label color={COLOR.BLACK}>{comparisonDate}</ChartTooltip.Label>
                  <ChartTooltip.Value color={COLOR.BLACK}>
                    {interactionType === 'rate'
                      ? getRateText(selectedComparisonInteractions)
                      : getValueText(selectedComparisonInteractions)}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              </React.Fragment>
            )}
            {interactionItems.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const targetDataKey = getTargetDataKey(dataKey);
              const isComparison = isComparisonDataKey(dataKey);
              const title = getDataKeyLabel(targetDataKey, interactionType);
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const isInteractionRate = interactionType === 'rate' && isInteractionDataKey(dataKey);
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && (
                    <ChartTooltip.Title color={itemColor}>
                      <ActiveCircle color={item.color} />
                      {title}
                    </ChartTooltip.Title>
                  )}
                  <ChartTooltip.Item>
                    <ChartTooltip.Label color={textColor}>{isComparison ? comparisonDate : date}</ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>
                      {isInteractionRate ? getRateText(item.value) : getValueText(item.value)}
                    </ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
          </ChartTooltip>
        );
      },
      [graphType, interactionType],
    );

    const handleOnMouseEnter = useCallback(
      (key: TargetDataKey) => () => {
        setActiveDataKey(key);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveDataKey(null);
    }, []);

    // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
    let displayedMemoDate = ImmutableSet<string>();

    return (
      <ResponsiveContainer height={height}>
        <ComposedChart
          data={composedGraphData}
          margin={{ top: 64, right: 32, left: 32, bottom: 16 }}
          onMouseLeave={() => setActiveDataKey(null)}
        >
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            content={(props: any) => renderLegend({ ...props, showInactiveLegend })}
            onClick={(o: any) => onChangeActiveStats && onChangeActiveStats(getTargetDataKey(o.dataKey))}
          />
          <XAxis dataKey={'date'} tickMargin={16} interval={'equidistantPreserveStart'} />
          <YAxis
            yAxisId={'impressions'}
            orientation={impressionsYAxisSettings.orientation}
            label={{ value: 'ユーザー数', position: 'top', offset: 24 }}
            tickFormatter={impressionsYAxisTickFormatter}
            hide={impressionsYAxisSettings.hide}
            allowDecimals={false}
            allowDataOverflow={true}
            domain={impressionGraphSettings.domain}
          />
          <YAxis
            yAxisId={'interactions'}
            orientation={interactionsYAxisSettings.orientation}
            label={{
              value: `インタラクション${interactionType === 'rate' ? '率' : '数'}`,
              position: 'top',
              offset: 24,
            }}
            tickFormatter={interactionsYAxisTickFormatter}
            hide={interactionsYAxisSettings.hide}
            allowDecimals={interactionType === 'rate'}
            allowDataOverflow={true}
            domain={interactionType === 'rate' ? interactionGraphSettings.rateDomain : interactionGraphSettings.domain}
          />
          <CartesianGrid vertical={false} />
          {(graphType === 'interactions'
            ? interactionsGraphStatsTypes
            : graphType === 'impressions'
              ? impressionsGraphStatsTypes
              : []
          ).map((dataKey) => {
            // グラフに使えるデータであることを確認する
            if (!isDataKey(dataKey)) {
              return null;
            }
            const targetDataKey = getTargetDataKey(dataKey);
            const comparisonDataKey = getComparisonDataKey(dataKey);

            const isStackedImpression = isStacked && graphType !== 'interactions' && isImpressionDataKey(dataKey);
            const isStackedInteraction = isStacked && graphType !== 'impressions' && isInteractionDataKey(dataKey);

            const isActive = activeDataKey == null || activeDataKey === targetDataKey;

            const color = transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(targetDataKey));

            const hide = !displayStats.includes(targetDataKey);

            return (
              <React.Fragment key={dataKey}>
                {isStackedImpression || isStackedInteraction ? (
                  <>
                    <Area
                      dataKey={targetDataKey}
                      stroke={color}
                      fill={color}
                      fillOpacity={0.4}
                      strokeWidth={2}
                      yAxisId={getYAxisId(targetDataKey)}
                      stackId={getYAxisId(targetDataKey)}
                      isAnimationActive={false}
                      name={getDataKeyLabel(targetDataKey, interactionType)}
                      cursor={'pointer'}
                      activeDot={{ cursor: 'pointer' }}
                      hide={hide}
                    />
                    {showComparisonGraph && (
                      <Area
                        dataKey={comparisonDataKey}
                        stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(comparisonDataKey))}
                        fill={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(comparisonDataKey))}
                        fillOpacity={0.2}
                        strokeWidth={2}
                        strokeDasharray={'5 5'}
                        yAxisId={getYAxisId(comparisonDataKey)}
                        stackId={`comparison${getYAxisId(comparisonDataKey)}`}
                        isAnimationActive={false}
                        name={getDataKeyLabel(comparisonDataKey, interactionType)}
                        cursor={'pointer'}
                        activeDot={{ cursor: 'pointer' }}
                        hide={hide}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Line
                      dataKey={targetDataKey}
                      stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(targetDataKey))}
                      strokeWidth={2}
                      yAxisId={getYAxisId(targetDataKey)}
                      isAnimationActive={false}
                      name={getDataKeyLabel(targetDataKey, interactionType)}
                      onMouseEnter={handleOnMouseEnter(targetDataKey)}
                      onMouseLeave={handleOnMouseLeave}
                      dot={
                        showDots
                          ? {
                              fill: color,
                              r: 2,
                              clipDot: false,
                            }
                          : false
                      }
                      activeDot={{
                        onMouseEnter: handleOnMouseEnter(targetDataKey),
                        onMouseLeave: handleOnMouseLeave,
                        cursor: 'pointer',
                      }}
                      cursor={'pointer'}
                      hide={hide}
                    />
                    {showComparisonGraph && (
                      <Line
                        dataKey={comparisonDataKey}
                        stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(comparisonDataKey))}
                        strokeWidth={2}
                        strokeDasharray={'5 5'}
                        yAxisId={getYAxisId(comparisonDataKey)}
                        isAnimationActive={false}
                        name={getDataKeyLabel(comparisonDataKey, interactionType)}
                        onMouseEnter={() => handleOnMouseEnter(targetDataKey)}
                        onMouseLeave={() => handleOnMouseLeave()}
                        dot={showDots ? { fill: color, r: 2, clipDot: false } : false}
                        activeDot={{
                          onMouseEnter: handleOnMouseEnter(targetDataKey),
                          onMouseLeave: handleOnMouseLeave,
                          cursor: 'pointer',
                        }}
                        cursor={'pointer'}
                        hide={hide}
                      />
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}

          {memoData?.map((item) => {
            // すでにメモを表示している日付ならスキップ
            if (displayedMemoDate.has(item.date)) {
              return null;
            }
            // メモを表示している日付リストに追加
            displayedMemoDate = displayedMemoDate.add(item.date);
            return (
              <ReferenceLine
                key={item.date}
                x={item.date}
                yAxisId={'impressions'}
                stroke={'#9994'}
                label={(props) =>
                  MemoIcon({
                    ...props,
                    title: getDateLabelWithWeekOfDay(item.date),
                    content: item.contents,
                    onClick: () => onClickMemo && onClickMemo(item.ids),
                  }) as any
                }
              />
            );
          })}

          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            content={showComparisonGraph ? renderComparisonTooltip : renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
);
