import { toast } from 'react-semantic-toasts';
import { put, takeLatest } from 'redux-saga/effects';

import { Awaited } from 'ApiClient';
import { TasksApi } from 'ApiClient/TasksApi';
import { UserApi } from 'ApiClient/UserApi';
import { replaceWithOrganizationId } from 'helpers/router';
import { TaskDetail } from 'models/Domain/TaskDetail';
import { User } from 'models/Domain/User';
import { Path } from 'routes';

import { TaskDetailActions } from './actions';
export default function* saga() {
  yield takeLatest(TaskDetailActions.fetchTask, fetchTask);
}

function* fetchTask(action: ReturnType<typeof TaskDetailActions.fetchTask>) {
  yield put(TaskDetailActions.setIsLoading(true));
  const taskId = action.payload;
  const taskResponse: Awaited<ReturnType<typeof TasksApi.get>> = yield TasksApi.get(taskId);

  if (!taskResponse.isSuccess) {
    if (taskResponse.status !== 403 && taskResponse.status !== 404) {
      toast({
        type: 'error',
        title: '依頼情報が取得できませんでした',
        description: String(taskResponse.error.message),
        time: 10000,
      });
    }
    yield put(TaskDetailActions.setIsLoading(false));

    // 詳細ページを表示できないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.offerGroups.index));
    return;
  }

  const task = TaskDetail.fromJSON(taskResponse.data);

  const authorUserId = task.content.created_by;
  const userResponse: Awaited<ReturnType<typeof UserApi.get>> = yield UserApi.get(authorUserId);

  if (!userResponse.isSuccess) {
    toast({
      type: 'error',
      title: 'ユーザー情報が取得できませんでした',
      description: String(userResponse.error.message),
      time: 10000,
    });
    yield put(TaskDetailActions.setIsLoading(false));
    return;
  }

  const author = new User(userResponse.data);

  yield put(TaskDetailActions.setTask(task));
  yield put(TaskDetailActions.setAuthor(author));
  yield put(TaskDetailActions.setIsLoading(false));
}
