import React from 'react';

import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

import { GuideOverlay } from 'components/molecules/GuideOverlay';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';
import { JSObject } from 'types/Common';

const colors = [
  { bar: 'rgba(107, 211, 163, 0.8)', area: `${COLOR.GREEN}` },
  { bar: 'rgba(240, 144, 97, 0.5)', area: '#f09061' },
  { bar: 'rgba(106, 66, 210, 0.5)', area: '#6a42d2' },
];

export const InsightStoreChart: React.FC<{
  data: {
    storeList: { storeId: number; areaKey: string; barKey: string }[];
    data: JSObject[];
    areaKey: string;
    barKey: string;
    areaLabel: string;
    barLabel: string;
  };
  stores: Stores;
}> = ({ data: dataGraph, stores }) => {
  return (
    <Wrapper>
      <ResponsiveContainer minHeight={220}>
        <ComposedChart data={dataGraph.data} margin={{ top: 35 }}>
          <Tooltip />
          <Legend verticalAlign='top' align='left' wrapperStyle={{ top: '0px', left: '15px' }} />
          <XAxis
            dataKey='period_label'
            interval={0}
            padding={{ left: dataGraph.storeList.length * 20, right: dataGraph.storeList.length * 20 }}
          />
          <YAxis
            yAxisId='right'
            orientation='right'
            label={{ value: dataGraph.barLabel, position: 'top', offset: 15 }}
          />
          <YAxis
            yAxisId='left'
            orientation='left'
            label={{ value: dataGraph.areaLabel, position: 'top', offset: 15 }}
          />
          <CartesianGrid stroke={COLOR.GRAY} vertical={false} strokeDasharray='5 5' />
          {dataGraph.storeList.map((data, idx) => {
            const colorIndex = (idx + colors.length) % colors.length;
            const store = stores.findStore(data.storeId);

            return (
              <Bar
                key={`bar-${idx}`}
                name={`${store?.shortName}:${dataGraph.barLabel}`}
                dataKey={data.barKey}
                yAxisId='right'
                barSize={20}
                stroke={colors[colorIndex].bar}
                fill={colors[colorIndex].bar}
              />
            );
          })}
          {dataGraph.storeList.map((data, idx) => {
            const colorIndex = (idx + colors.length) % colors.length;
            const store = stores.findStore(data.storeId);

            return (
              <Line
                key={`area-${idx}`}
                name={`${store?.shortName}:${dataGraph.areaLabel}`}
                dataKey={data.areaKey}
                stroke={colors[colorIndex].area}
                yAxisId='left'
                dot={false}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
      {dataGraph.storeList.length === 0 && <GuideOverlay>表示したい店舗を下から選んでください。</GuideOverlay>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 24px;
  font-size: 12px;
  font-weight: bold;
`;
