import { Record, Set } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { GbpChecklist } from 'models/Domain/GbpChecklist';
import { Group } from 'types/Common';

import { TopActions as Actions } from './actions';

interface TopRecord {
  isLoading: boolean;
  group: Group;
  storeIds: Set<number>;
  checklist: GbpChecklist;
}

export class TopState extends Record<TopRecord>({
  isLoading: false,
  storeIds: Set(),
  group: null,
  checklist: new GbpChecklist(),
}) {}

export const topReducer = reducerWithInitialState(new TopState())
  .case(Actions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  })
  .case(Actions.setChecklist, (state, payload) => {
    return state.set('checklist', payload);
  })
  .case(Actions.setStoreIds, (state, payload) => {
    return state.set('storeIds', payload);
  })
  .case(Actions.setGroup, (state, payload) => {
    return state.set('group', payload);
  });
