import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { GbpChecklist as Checklist } from 'models/Domain/GbpChecklist';
import { GbpChecklistSearchCondition } from 'models/Domain/GbpChecklist/GpbChecklistSearchCondition';

import { GbpChecklistActions as Actions } from './actions';

export class GbpChecklistState extends Record<{
  isLoading: boolean;
  isPreparedPage: boolean;
  searchCondition: GbpChecklistSearchCondition;
  checklist: Checklist;
}>({
  isLoading: false,
  isPreparedPage: false,
  searchCondition: new GbpChecklistSearchCondition(),
  checklist: new Checklist(),
}) {}

export const gbpChecklistReducer = reducerWithInitialState(new GbpChecklistState())
  .case(Actions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  })
  .case(Actions.setIsPreparedPage, (state, payload) => {
    return state.set('isPreparedPage', payload);
  })
  .case(Actions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(Actions.setChecklist, (state, payload) => {
    return state.set('checklist', payload);
  });
