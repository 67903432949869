import actionCreatorFactory from 'typescript-fsa';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { SearchKeywordGraphData, SearchKeywordTableData } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SearchKeywordSearchCondition } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';

const actionCreator = actionCreatorFactory('SearchKeyword');

export const SearchKeywordActions = {
  initializePage: actionCreator('initializePage'),
  getSearchKeywordGraphData: actionCreator<{ ignoreCache?: boolean }>('getSearchKeywordGraphData'),
  getSearchKeywordTableData: actionCreator<{ ignoreCache?: boolean }>('getSearchKeywordTableData'),
  setSearchKeywordGraphData: actionCreator<SearchKeywordGraphData>('setSearchKeywordGraphData'),
  setSearchKeywordTableData: actionCreator<SearchKeywordTableData>('setSearchKeywordTableData'),
  setSearchCondition: actionCreator<{
    searchCondition: SearchKeywordSearchCondition;
    updateLocation?: boolean;
  }>('setSearchCondition'),
  setIsUpdatedExcludedKeywords: actionCreator<boolean>('setIsUpdatedExcludedKeywords'),
  setCommittedSearchCondition: actionCreator<SearchKeywordSearchCondition>('setCommittedSearchCondition'),
  setIsLoadingGraphData: actionCreator<boolean>('setIsLoadingGraphData'),
  setIsLoadingTableData: actionCreator<boolean>('setIsLoadingTableData'),
  clearSearchKeyword: actionCreator('clearSearchKeyword'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),

  setIsProcessingCsvDownload: actionCreator<boolean>('setIsProcessingCsvDownload'),
  setCsvDownload: actionCreator<ExecutionArnStatus>('setCsvDownload'),
  startCsvDownload: actionCreator<SearchKeywordSearchCondition>('startCsvDownload'),
  clearCsvDownload: actionCreator('clearCsvDownload'),
  checkCsvDownloadStatus: actionCreator<number>('checkCsvDownloadStatus'),
};
