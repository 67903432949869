import React, { useCallback, useMemo, useState } from 'react';

import { List as ImmutableList, Set as ImmutableSet } from 'immutable';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Modal, Icon as SemanticIcon } from 'semantic-ui-react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';

import { ServiceApi } from 'ApiClient/ServiceApi';
import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { ServiceHelp as Help } from 'helpers/ContextHelp';
import { MAX_SERVICE_NAME_LENGTH, Service, ServiceList } from 'models/Domain/Service/Service';
import { COLOR } from 'style/color';

// APIを実行して指定されたカテゴリに紐づくサービスを取得する
const fetchStructuredServices = async (categoryId: string | null): Promise<ImmutableList<Service> | null> => {
  // カテゴリ未設定ならnullを返す
  if (!categoryId) {
    return null;
  }
  const response = await ServiceApi.getStructured({ category_id: categoryId });
  // エラーならnullを返す
  if (!response.isSuccess) {
    return null;
  }
  // 取得したサービスを返す
  return ImmutableList<Service>(
    response.data.structured_services.map(
      (service) =>
        new Service({
          name: service.name,
          serviceTypeId: service.service_type_id,
        }),
    ),
  );
};

// 指定されたカテゴリのプリセットサービスを取得する。取れなかった場合は空のリストを返す
const useStructuredServices = (categoryId: string | null): ImmutableList<Service> => {
  const { data } = useSWRImmutable([categoryId], fetchStructuredServices);
  return data || ImmutableList();
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  categoryId: string | null;
  services: ServiceList;
  onChange: (services: ServiceList) => void;
};

type FormInputs = {
  customServices: { name: string }[];
};

const initialValue: FormInputs['customServices'][number] = { name: '' };

export const ServiceAddModal = React.memo<Props>(({ isOpen, onClose, services, onChange, categoryId }) => {
  const [selectedStructuredServiceIds, setSelectedStructuredServiceIds] =
    useState<ImmutableSet<string>>(ImmutableSet());

  const structuredServices = useStructuredServices(categoryId);

  const {
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    control,
  } = useForm<FormInputs>({
    mode: 'onSubmit',
    defaultValues: {
      customServices: [initialValue],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customServices',
  });

  const selectableStructuredServices = useMemo(() => {
    return structuredServices.filter(
      (service) => services.structuredServices.find((x) => service.serviceTypeId === x.serviceTypeId) === undefined,
    );
  }, [services.structuredServices, structuredServices]);

  const onSubmit = useCallback(
    (data: FormInputs) => {
      // FIXME: カテゴリの設定やバリデーションの修正
      // バリデーションはサービス名の文字数超過の確認のみで、ここで保存可能なサービスリストを生成している
      // 入力欄が空の場合にエラーにしたり、プリセットサービスとカスタムサービスや既存のサービスとの重複時にエラーにするなら、別の実装が必要

      // 並び順は、プリセットサービス(APIで取得された順)、設定済みのカスタムサービス、入力されたカスタムサービスの順になる

      // 入力されたカスタムサービス名。空文字と重複を除去しておく
      const inputNames = ImmutableSet(data.customServices.map((x) => x.name).filter((x) => x));

      // 結果を格納するリスト
      let newServices = new ServiceList();

      // プリセットサービスを追加（並び順の都合で、APIで取得した値をfilterで絞り込んで取得する）
      const newStructuredServices = structuredServices.filter((service) => {
        // プリセットサービスのリストなので、serviceTypeIdがnullのものはないはず
        const serviceTypeId = service.serviceTypeId;
        if (!serviceTypeId) {
          return false;
        }
        // 設定済みのプリセットサービスに含まれるか
        const exists = services.findByServiceTypeId(serviceTypeId) != null;
        // 選択されたプリセットサービスに含まれるか
        const isSelected = selectedStructuredServiceIds.has(serviceTypeId);
        // カスタムサービスとして入力されたサービスと同名のサービスがある場合はプリセットとして追加する
        const isInputted = inputNames.find((name) => name === service.name) != null;
        // 上記いずれかの条件を満たす場合に追加
        return exists || isSelected || isInputted;
      });
      newServices = newServices.concatItems(newStructuredServices);

      // 設定済みのカスタムサービスを追加
      newServices = newServices.concatItems(services.customServices);
      // 入力されたカスタムサービスを追加
      const inputCustomServices = inputNames
        // プリセットサービスや設定済みのカスタムサービスとの重複を除去
        .filter((name) => !newServices.findByName(name))
        .map((name) => new Service({ name }))
        .toList();
      newServices = newServices.concatItems(inputCustomServices);
      onChange(newServices);
      onClose();
    },
    [structuredServices, services, onChange, onClose, selectedStructuredServiceIds],
  );

  const onError = useCallback(() => {
    window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
  }, []);

  const handleOnChangeSelectedStructuredService = useCallback((value: string) => {
    setSelectedStructuredServiceIds((ids) => (ids.contains(value) ? ids.remove(value) : ids.add(value)));
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title>サービスの追加</Title>
          <ContentWrapper>
            <Description>サービスの追加後、個別に価格、説明を追加できます。</Description>
            <Content>
              {selectableStructuredServices.size > 0 && (
                <FormContent name={'プリセットサービスを追加'} informationText={Help.addStructuredService}>
                  <OptionalToggleContainer>
                    {selectableStructuredServices.map((service) => (
                      <OptionalToggle
                        key={service.id}
                        label={service.name}
                        onClick={() => handleOnChangeSelectedStructuredService(service.serviceTypeId ?? '')}
                        checked={selectedStructuredServiceIds.contains(service.serviceTypeId ?? '') || undefined}
                      />
                    ))}
                  </OptionalToggleContainer>
                </FormContent>
              )}
              <FormContent name={'カスタムサービスを追加'} informationText={Help.addCustomService}>
                {fields.map((field, index) => {
                  return (
                    <InputContainer key={index}>
                      <Controller
                        control={control}
                        name={`customServices.${index}.name`}
                        defaultValue={field.name}
                        rules={{
                          maxLength: {
                            value: MAX_SERVICE_NAME_LENGTH,
                            message: `サービス名は${MAX_SERVICE_NAME_LENGTH}文字以内で入力してください`,
                          },
                        }}
                        render={({ field }) => (
                          <InputWithCounter>
                            <StyledInput
                              value={field.value}
                              onChange={field.onChange}
                              error={errors.customServices?.[index]?.name?.message}
                            />
                            <StyledTextCount size={field.value.length} maxSize={MAX_SERVICE_NAME_LENGTH} />
                          </InputWithCounter>
                        )}
                      />
                      <DeleteButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </DeleteButton>
                    </InputContainer>
                  );
                })}
                <AddButton onClick={() => append(initialValue)}>
                  <AddIcon />
                  サービスを追加する
                </AddButton>
              </FormContent>
            </Content>
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>キャンセル</StyledButton>
            <StyledButton
              onClick={handleSubmit(onSubmit, onError)}
              priority={'high'}
              disabled={isSubmitted && !isValid}
            >
              適用
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
});

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

const StyledInput = styled(Input)``;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const Description = styled.div`
  margin-bottom: 24px;
  color: ${COLOR.DARK_GRAY};
`;

const OptionalToggleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const AddButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

const AddIcon = styled(Icon).attrs({ type: 'add' })`
  &&& {
    padding: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;
const InputWithCounter = styled.div`
  flex: 1;
`;

const DeleteButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: ${COLOR.CHARCOAL_GRAY};
    :hover {
      color: ${COLOR.ERROR};
    }
    :disabled {
      color: ${COLOR.LIGHT_GRAY};
    }
  }
`;

const DeleteIcon = styled(SemanticIcon).attrs({ name: 'close' })`
  &&& {
    line-height: 1;
    height: auto;
  }
`;
