import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

type Props = {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const Card = React.memo<Props>(({ className, onClick, children }) => {
  return (
    <Wrapper className={className} onClick={onClick} canClick={onClick !== undefined}>
      {children}
    </Wrapper>
  );
});

const Wrapper = styled.div<{ canClick: boolean }>`
  padding: 18px;
  background-color: ${COLOR.WHITE};
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.01);
  cursor: ${(props) => props.canClick && 'pointer'};
`;
