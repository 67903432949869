import React, { useCallback, useEffect, useState } from 'react';

import { Set } from 'immutable';

import { Group } from 'types/Common';

import { GroupStoreSelect } from './GroupStoreSelect';
import { useInitialStoreIds } from './hooks';

type GroupStoreSelectProps = {
  initialGroup?: Group;
  initialStoreIds?: Set<number>;
  initialIsAllStores?: boolean;
  initialShowClosedStores?: boolean;
  showGbpConnectedStoresOnly?: boolean;
  showGroupSelectOnly?: boolean;
  showMyStoreOnly?: boolean;
  onChange: (params: { group: Group; storeIds: Set<number>; isAllStores: boolean; showClosedStores: boolean }) => void;
};

export const SimpleGroupStoreSelect: React.FC<GroupStoreSelectProps> = (props) => {
  const {
    initialGroup = 'my_store',
    initialStoreIds,
    initialIsAllStores = true,
    initialShowClosedStores = false,
    showGbpConnectedStoresOnly,
    showGroupSelectOnly,
    showMyStoreOnly,
    onChange,
  } = props;

  const [group, setGroup] = useState(initialGroup);
  const [storeIds, setStoreIds] = useState(initialStoreIds);
  const [isAllStores, setIsAllStores] = useState(initialIsAllStores);
  const [showClosedStores, setShowClosedStores] = useState(initialShowClosedStores);

  const defaultInitialStoreIds = useInitialStoreIds(initialGroup, initialShowClosedStores, showGbpConnectedStoresOnly);
  useEffect(() => {
    if (storeIds === undefined && defaultInitialStoreIds !== undefined) {
      setStoreIds(defaultInitialStoreIds);

      // 初期値をonChangeハンドラで伝達する(微妙だけどしょうがない。。)
      onChange({
        group,
        storeIds: defaultInitialStoreIds,
        isAllStores,
        showClosedStores,
      });
    }
  }, [storeIds, defaultInitialStoreIds, onChange, group, isAllStores, showClosedStores]);

  const handleOnChange = useCallback(
    (newGroup: Group, newStoreIds: Set<number>, newIsAllStores: boolean, newShowClosedStores: boolean) => {
      if (newGroup !== group) {
        setGroup(newGroup);
      }
      if (!newStoreIds.equals(storeIds)) {
        setStoreIds(newStoreIds);
      }
      if (newIsAllStores !== isAllStores) {
        setIsAllStores(newIsAllStores);
      }
      if (newShowClosedStores !== showClosedStores) {
        setShowClosedStores(newShowClosedStores);
      }

      onChange({
        group: newGroup,
        storeIds: newStoreIds,
        isAllStores: newIsAllStores,
        showClosedStores: newShowClosedStores,
      });
    },
    [group, isAllStores, onChange, showClosedStores, storeIds],
  );

  return (
    <GroupStoreSelect
      group={group}
      storeIds={storeIds}
      showClosedStores={showClosedStores}
      showGbpConnectedStoresOnly={showGbpConnectedStoresOnly}
      showGroupSelectOnly={showGroupSelectOnly}
      showMyStoreOnly={showMyStoreOnly}
      onChange={handleOnChange}
    />
  );
};
