import { List, Record } from 'immutable';

import Image, { ImageStore } from 'models/Domain/Image/Image';
import { JSObject } from 'types/Common';

type Storeimages = { store: ImageStore; images: List<Image> };

/**
 * 写真画面で複数一括掲載する写真を管理するモデル
 */
export default class CheckedImageList extends Record<{ list: List<Image>; category: string }>({
  list: List(),
  category: 'INTERIOR',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.list = List(params.list);
    super(params);
  }

  check(image: Image) {
    return this.set('list', this.list.push(image));
  }

  uncheck(imageResourceId: number) {
    return this.set(
      'list',
      this.list.filter((image) => image.image_resource_id !== imageResourceId),
    );
  }

  numberOfCheckedImages() {
    return this.list.size;
  }

  numberOfPublishedImages() {
    return this.list.filter((image) => image.published_gmb_stores_count > 0).size;
  }

  clear() {
    return this.set('list', List()).set('category', 'INTERIOR');
  }

  isEmpty() {
    return this.list.isEmpty();
  }

  includes(imageResourceId: number) {
    return this.list.map((image) => image.image_resource_id).includes(imageResourceId);
  }

  changeCategory(category: string) {
    return this.set('category', category);
  }

  static fromImages(images: List<Image>) {
    return new CheckedImageList({ list: images });
  }

  /**
   * チェックした写真から、店舗ごとにどの写真が紐づいているかを返す
   * Googleビジネスプロフィールに掲載済の写真は除外する
   */
  get imagesByStore() {
    let uniqueStoresConnectedGmb = List<ImageStore>();

    // 店舗を集計する
    this.list.forEach((image) => {
      image.stores.forEach((store) => {
        if (uniqueStoresConnectedGmb.map((_store) => _store.store_id).includes(store.store_id)) return;
        if (!store.is_connected_gmb) return;

        uniqueStoresConnectedGmb = uniqueStoresConnectedGmb.push(store);
      });
    });

    // 店舗と、店舗に紐づいたImageのリストを集計
    let storeimages = List<Storeimages>();
    uniqueStoresConnectedGmb.forEach((store) => {
      const images = this.list
        .filter((image) => image.published_gmb_stores_count === 0)
        .filter((image) => image.stores.map((_store) => _store.store_id).includes(store.store_id));

      if (images.isEmpty()) return;

      storeimages = storeimages.push({ store, images });
    });

    return storeimages;
  }

  /**
   * APIリクエスト用のパラメータを生成
   * 紐づいている全ての店舗がGBPに未連携の写真はパラメータに含まない
   * GBPに掲載済みの写真は含まない
   */
  get requestParams() {
    return {
      imageResourceIds: this.list
        .filter((image) => image.published_gmb_stores_count === 0)
        .filter((image) => image.stores.some((store) => store.is_connected_gmb))
        .map((image) => image.image_resource_id)
        .toArray(),
      category: this.category,
    };
  }
}
