import actionCreatorFactory from 'typescript-fsa';

import { OfferGroups } from 'models/Domain/OfferGroup/OfferGroup';
import { OfferGroupsSearchCondition } from 'models/Domain/OfferGroup/OfferGroupSearchCondition';

const actionCreator = actionCreatorFactory('OfferGroups');

export const OfferGroupsActions = {
  setOfferGroups: actionCreator<OfferGroups>('setOfferGroups'),

  setSearchCondition: actionCreator<{ searchCondition: OfferGroupsSearchCondition; updateLocation?: boolean }>(
    'setOfferGroupSearchCondition',
  ),
  setCommittedSearchCondition: actionCreator<OfferGroupsSearchCondition>('setCommittedSearchCondition'),
  fetchOfferGroups: actionCreator('fetchOfferGroups'),
  initializeOfferGroupsPage: actionCreator('initializeOfferGroupsPage'),
  setIsPreparedForOfferGroupsPage: actionCreator<boolean>('setIsPreparedForOfferGroupsPage'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
};
