import { Record } from 'immutable';

import { Store } from 'models/Domain/Store';
import { JSObject } from 'types/Common';

export interface InventorySummaryRecord {
  storeId: number;
  store: Store; // APIレスポンスには含まれていなくて、フロントエンド側で埋め込む情報
  counts: {
    total: number;
    valid: number;
    expiringSoon: number;
    expired: number;
  };
}

export class InventorySummary extends Record<InventorySummaryRecord>({
  storeId: 0,
  store: new Store(),
  counts: {
    total: 0,
    valid: 0,
    expiringSoon: 0,
    expired: 0,
  },
}) {
  static fromJSON(data: JSObject = {}): InventorySummary {
    return new InventorySummary({
      storeId: data.store_id,
      store: data.store,
      counts: {
        total: data.counts.total,
        valid: data.counts.valid,
        expiringSoon: data.counts.expiring_soon,
        expired: data.counts.expired,
      },
    });
  }
}
