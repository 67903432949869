import { PerformanceMetric, PerformanceProgram, ProductStatusStatus, Program } from 'models/Domain/OmoInsight';

export const getDiffText = (diff: number): string => {
  // 前期の値が0の場合は前期比が計算できない（ゼロ除算で無限大になる）ので「ー」を返す
  if (!isFinite(diff)) {
    return 'ー';
  }
  // diffが0以上の場合は「↑」、0未満の場合はマイナスの代わりに「↓」をつけた値を返す
  return `${diff < 0 ? '↓' : '↑'} ${Math.abs(diff).toFixed(1)}%`;
};

export const PROGRAM_LABELS: { [key in Program]: string } = {
  shopping_ads: 'ショッピング広告',
  local_inventory_ads: 'ローカル在庫広告',
  free_listings: '無料リスティング',
  free_local_listings: '無料ローカルリスティング',
};

export const PRODUCT_STATUS_COLORS: { [key in ProductStatusStatus]: string } = {
  active: '#1e8e3e',
  expiring: '#f29900',
  pending: '#4285f4',
  disapproved: '#d93025',
};

export const PRODUCT_STATUS_LABELS: { [key in ProductStatusStatus]: string } = {
  active: '有効',
  expiring: '期限切れ間近',
  pending: '保留中',
  disapproved: '不承認',
};

export const PEROFRMANCE_PROGRAM_LABELS: { [key in PerformanceProgram]: string } = {
  shopping_ads: 'ショッピング広告 / ローカル在庫広告',
  free_listings: '無料リスティング',
  free_local_listings: '無料ローカルリスティング',
  ads: '有料トラフィック',
  free: '無料トラフィック',
};

export const METRIC_LABELS: { [key in PerformanceMetric]: string } = {
  impressions: '表示回数',
  clicks: 'クリック数',
  ctr: 'クリック率',
  sessions: 'セッション数',
  conversions: 'コンバージョン数',
  revenues: '収益',
};
