import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { SearchVolumeCSVDownloadButton } from 'components/pageComponents/SearchVolume/SearchVolumeCSVDownloadButton';
import { SearchVolumeDetailModal } from 'components/pageComponents/SearchVolume/SearchVolumeDetailModal';
import { SearchVolumeProgress } from 'components/pageComponents/SearchVolume/SearchVolumeProgress';
import { SearchVolumeResultTable } from 'components/pageComponents/SearchVolume/SearchVolumeResultTable';
import {
  SEARCH_CONDITION_LIMIT,
  SearchVolumeSearchCondition,
} from 'components/pageComponents/SearchVolume/SearchVolumeSearchCondition';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { SearchVolumeSearchCondition as SearchCondition } from 'models/Domain/SearchVolume/SearchVolumeSearchCondition';
import { SearchVolumeSearchResult } from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import { SearchVolumeActions } from 'modules/searchVolume/actions';
import { Path } from 'routes';

export const SearchVolumeResult = React.memo((props: RouteComponentProps<{ id: string }>) => {
  const {
    match: {
      params: { id = '' },
    },
  } = props;
  const dispatch = useDispatch();
  const { searchStatus, searchCondition, isCommitDisabled, searchResults } = useSelector((state) => state.searchVolume);
  const { initializeResultPage, commitSearchCondition } = useMemo(
    () => bindActionCreators(SearchVolumeActions, dispatch),
    [dispatch],
  );

  const [selectedResult, setSelectedResult] = useState<SearchVolumeSearchResult | null>(null);

  useEffect(() => {
    // idが取得できない場合、検索ボリュームトップに移動
    if (!id) {
      dispatch(replaceWithOrganizationId(Path.searchVolume.index));
      return;
    }
    initializeResultPage(id);
  }, [dispatch, id, initializeResultPage]);

  const periodText = useMemo(() => {
    const { startMonth, endMonth } = searchStatus.conditions.period;
    const start = startMonth.format();
    const end = endMonth.format();
    return start === end ? `${start}` : `${start}〜${end}`;
  }, [searchStatus.conditions]);

  const handleOnCommitSearchCondition = useCallback(
    (value: SearchCondition) => {
      commitSearchCondition(value);
    },
    [commitSearchCondition],
  );

  const handleOnSelectSearchResult = useCallback((searchResult: SearchVolumeSearchResult) => {
    setSelectedResult(searchResult);
  }, []);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('検索ボリューム')} />
      <StickyHeader>
        <Title>検索ボリューム</Title>
      </StickyHeader>
      <WideBody>
        <Wrapper>
          <Content>
            <Header>
              検索条件<HeaderDescription>検索条件数は最大{SEARCH_CONDITION_LIMIT}件まで指定できます</HeaderDescription>
            </Header>
            <SearchVolumeSearchCondition
              isCommitDisabled={isCommitDisabled}
              committedSearchCondition={searchCondition}
              onCommitSearchCondition={handleOnCommitSearchCondition}
            />
          </Content>
          {searchStatus.isRunning() && searchStatus.items.size > 0 && (
            <Content>
              <SearchVolumeProgress totalCount={searchStatus.items.size} completedCount={searchResults.data.size} />
            </Content>
          )}
          <Header>
            検索ボリューム
            {!searchStatus.isRunning() && searchStatus.items.size > 0 && (
              <HeaderDescription>{periodText}</HeaderDescription>
            )}
            <StyledSearchVolumeCSVDownloadButton condition={{ execution_id: id }} />
          </Header>
          <Content>
            <SearchVolumeResultTable
              searchStatus={searchStatus}
              searchResults={searchResults}
              onClickSearchResult={handleOnSelectSearchResult}
            />
          </Content>
        </Wrapper>
      </WideBody>
      {selectedResult && (
        <SearchVolumeDetailModal
          isOpen={true}
          searchResult={selectedResult}
          executionId={id}
          onClose={() => setSelectedResult(null)}
        />
      )}
    </MainWrapper>
  );
});

const Wrapper = styled.div``;

const Content = styled.div`
  margin-bottom: 32px;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  align-items: baseline;
`;

const HeaderDescription = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-left: 16px;
`;

const StyledSearchVolumeCSVDownloadButton = styled(SearchVolumeCSVDownloadButton)`
  margin-left: auto;
  font-size: 14px;
`;
