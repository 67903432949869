import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { OfferGroups } from 'models/Domain/OfferGroup/OfferGroup';
import { OfferGroupsSearchCondition } from 'models/Domain/OfferGroup/OfferGroupSearchCondition';

import { OfferGroupsActions } from './actions';

export interface OfferGroupsRecord {
  offerGroups: OfferGroups;
  offerGroupsSearchCondition: OfferGroupsSearchCondition;
  committedOfferGroupsSearchCondition: OfferGroupsSearchCondition;

  // OfferGroupページの初期処理に必要な情報が準備できているか
  // （currentUserなど必要な情報が揃ってから初期処理が実行される）
  isPreparedForOfferGroupsPage: boolean;

  // 読み込み中か
  isLoading: boolean;
}

export class OfferGroupsState extends Record<OfferGroupsRecord>({
  offerGroups: new OfferGroups(),
  offerGroupsSearchCondition: new OfferGroupsSearchCondition(),
  committedOfferGroupsSearchCondition: new OfferGroupsSearchCondition(),
  isPreparedForOfferGroupsPage: false,
  isLoading: false,
}) {}

export const offerGroupReducer = reducerWithInitialState(new OfferGroupsState())
  .case(OfferGroupsActions.setOfferGroups, (state, data) => {
    return state.set('offerGroups', data);
  })
  .case(OfferGroupsActions.setSearchCondition, (state, data) => {
    return state.set('offerGroupsSearchCondition', data.searchCondition);
  })
  .case(OfferGroupsActions.setCommittedSearchCondition, (state, payload) => {
    return state.set('committedOfferGroupsSearchCondition', payload);
  })
  .case(OfferGroupsActions.setIsPreparedForOfferGroupsPage, (state, payload) => {
    return state.set('isPreparedForOfferGroupsPage', payload);
  })
  .case(OfferGroupsActions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  });
