import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { Comment } from 'models/Domain/Comment';
import { Offer } from 'models/Domain/Offer';
import { OtherOffer } from 'models/Domain/OtherOffer';
import { Report } from 'models/Domain/Report';
import { Stamp } from 'models/Domain/Stamp';
import { Task } from 'models/Domain/Task';
import { User } from 'models/Domain/User';
import { JSObject } from 'types/Common';

interface ActivityRecord {
  type: 'comment' | 'stamp' | 'status_change';
  status: 'done';
  comment: Comment;
  stamp: Stamp;
  user?: User;
  timestamp: Dayjs;
}

export class Activity extends Record<ActivityRecord>({
  type: 'comment',
  status: 'done',
  comment: new Comment(),
  stamp: new Stamp(),
  user: undefined,
  timestamp: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.comment = new Comment(params.comment);
    params.stamp = new Stamp(params.stamp);
    params.timestamp = dayjs.unix(params.timestamp);
    if (params.user) {
      params.user = new User(params.user);
    }
    super(params);
  }
}

interface OfferActivityRecord {
  offer: Offer;
  task: Task;
  report: Report;
  other_offer: OtherOffer;
  has_unread: boolean;
  last_activity?: Activity;
}

export class OfferActivity extends Record<OfferActivityRecord>({
  offer: new Offer(),
  task: new Task(),
  report: new Report(),
  other_offer: new OtherOffer(),
  has_unread: true,
  last_activity: undefined,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.offer = new Offer(params.offer);
    params.task = new Task(params.task);
    params.report = new Report(params.report);
    params.other_offer = new OtherOffer(params.other_offer);
    if (params.last_activity) {
      params.last_activity = new Activity(params.last_activity);
    }
    super(params);
  }

  get targetContent() {
    const {
      offer: { offer_type },
      task,
      report,
      other_offer,
    } = this;
    if (offer_type === 'task') {
      return task;
    } else if (offer_type === 'report') {
      return report;
    } else if (offer_type === 'other_offer') {
      return other_offer;
    }
    return task;
  }

  get title() {
    const { targetContent } = this;
    return targetContent.name;
  }

  get text() {
    const { last_activity } = this;
    if (!last_activity) {
      return null;
    }
    const { type, comment } = last_activity;
    const fileNum = comment.content.files.list.size;
    switch (type) {
      case 'comment':
        if (fileNum > 0) {
          return `${fileNum}枚の画像が送信されました`;
        }
        return comment.content.text;
      case 'stamp':
        return 'スタンプが送信されました';
      case 'status_change':
        return '完了しました！';
      default:
        return null;
    }
  }
}

interface OfferActivitiesRecord {
  offers: List<OfferActivity>;
  unread_count: number;
  has_next: boolean;
  current_page: number;
}

export class OfferActivities extends Record<OfferActivitiesRecord>({
  offers: List(),
  unread_count: 0,
  has_next: false,
  current_page: 1,
}) {
  constructor(data: JSObject = { offers: [] }) {
    const params = { ...data };
    super({
      ...params,
      offers: List(data.offers.map((d: JSObject) => new OfferActivity(d))),
    });
  }
  filteredByUnread() {
    return this.update('offers', (offers) => offers.filter((offerActivity) => offerActivity.has_unread));
  }
}
