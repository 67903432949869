import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { goBack } from 'connected-react-router';
import { List, Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Arrow } from 'components/atoms/Arrow';
import { Button } from 'components/atoms/Button';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { AreaKeywordRankModal } from 'components/pageComponents/MapCompetitorResearch/AreaKeywordRankModal';
import { AreaKeywordTable } from 'components/pageComponents/MapCompetitorResearch/AreaKeywordTable';
import { AverageRankTable } from 'components/pageComponents/MapCompetitorResearch/AverageRankTable';
import { ComparisonStoreModal } from 'components/pageComponents/MapCompetitorResearch/ComparisonStoreModal';
import { MapCompetitorResearchProgress } from 'components/pageComponents/MapCompetitorResearch/MapCompetitorResearchProgress';
import { RankComparisonTable } from 'components/pageComponents/MapCompetitorResearch/RankComparisonTable';
import { StoreDetailModal } from 'components/pageComponents/MapCompetitorResearch/StoreDetailModal';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { CompetitorList, MAX_COMPETITOR_SIZE } from 'models/Domain/MapCompetitorResearch/Competitor';
import { StoreKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { SearchHistory } from 'models/Domain/MapCompetitorResearch/SearchHistory';
import { MapCompetitorResearchActions } from 'modules/mapCompetitorResearch/actions';
import { useCompetitorSettings, useSearchHistory } from 'modules/mapCompetitorResearch/hooks';
import { MapCompetitorResearchSelectors } from 'modules/mapCompetitorResearch/selectors';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type MapCompetitorResearchResultProps = RouteComponentProps<{ id: string }>;

type Condition = { areaName: string; searchWord: string };

type ModalData = StoreKey | Condition;

export const MapCompetitorResearchResult = React.memo((props: MapCompetitorResearchResultProps) => {
  const {
    match: {
      params: { id = '' },
    },
  } = props;

  const location = useLocation();

  const [showComparisonStoreModal, setShowComparisonStoreModal] = useState(false);
  const [modalData, setModalData] = useState<List<ModalData>>(List());

  const dispatch = useDispatch();
  const { resultStatus, resultDetails, isLoadingResult, competitors } = useSelector(
    MapCompetitorResearchSelectors.selectState,
  );
  const storeKeys = useMemo(() => resultDetails.getStoreKeys(), [resultDetails]);

  const { stores } = useSelector((state: State) => state.store);

  const managedStoreKeys = useMemo(() => {
    return storeKeys.filter((storeKey) => stores.filterStoresByName(storeKey.storeName).list.size > 0);
  }, [storeKeys, stores]);

  const { initializeResultPage, setExecutionArn, setCompetitors } = useMemo(
    () => bindActionCreators(MapCompetitorResearchActions, dispatch),
    [dispatch],
  );

  const [competitorSettings, updateCompetitorSettings] = useCompetitorSettings();

  const averageRankTableData = useMemo(() => resultDetails.getAverageRankTableData(), [resultDetails]);

  const title = useMemo(() => {
    if (!resultStatus) {
      return '検索順位調査';
    }

    const limit = 5;
    let areaNames = resultStatus.conditions.areaNames.slice(0, limit).join(' / ');
    if (resultStatus.conditions.areaNames.length > limit) {
      areaNames += ` ...他${resultStatus.conditions.areaNames.length - limit}件`;
    }
    let searchWords = resultStatus.conditions.searchWords.slice(0, limit).join(' / ');
    if (resultStatus.conditions.searchWords.length > limit) {
      searchWords += ` ...他${resultStatus.conditions.searchWords.length - limit}件`;
    }
    return `${areaNames} × ${searchWords}`;
  }, [resultStatus]);

  useEffect(() => {
    // idが取得できない場合、検索順位調査トップに移動
    if (!id) {
      dispatch(replaceWithOrganizationId(Path.mapCompetitorResearch.index));
      return;
    }

    // idは圧縮されているので解凍する
    try {
      const executionArn = atob(id);
      const competitors = competitorSettings.findById(id) ?? new CompetitorList({ id });
      initializeResultPage({ executionArn, competitors });
    } catch (e) {
      console.error('invalid id');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, initializeResultPage, setExecutionArn]);

  const onClickBackButton = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const handleOnSelectStore = useCallback((storeKey: StoreKey) => {
    setModalData((modalData) => modalData.push(storeKey));
  }, []);

  const handleOnSelectAreaKeyword = useCallback((condition: Condition) => {
    setModalData((modalData) => modalData.push(condition));
  }, []);

  const handleOnChangeCompetitor = useCallback(
    (storeKey: StoreKey) => {
      let newCompetitors = competitors;
      if (competitors.contains(storeKey)) {
        newCompetitors = competitors.deleteItem(storeKey);
      } else if (competitors.size < MAX_COMPETITOR_SIZE) {
        newCompetitors = competitors.push(storeKey);
      }
      setCompetitors(newCompetitors);
      updateCompetitorSettings(competitorSettings.updateSettings(newCompetitors.id, newCompetitors));
    },
    [competitors, setCompetitors, competitorSettings, updateCompetitorSettings],
  );

  const handleOnChangeCompetitors = useCallback(
    (storeKeys: List<StoreKey>) => {
      const newCompetitors = competitors.concat(storeKeys);
      setCompetitors(newCompetitors);
      updateCompetitorSettings(competitorSettings.updateSettings(newCompetitors.id, newCompetitors));
    },
    [competitors, setCompetitors, competitorSettings, updateCompetitorSettings],
  );

  // 実際にデータが確認されたらヒストリーに追加する
  const isHistoryAdded = useRef(false);
  const [, pushHistory] = useSearchHistory();
  useEffect(() => {
    if (resultStatus && isHistoryAdded.current === false) {
      pushHistory(
        new SearchHistory({
          id,
          areaNames: List(resultStatus.conditions.areaNames),
          searchWords: List(resultStatus.conditions.searchWords),
          date: resultStatus.date,
        }),
      );
      isHistoryAdded.current = true;
    }
  }, [id, resultStatus, pushHistory]);

  // エリア×キーワードテーブル用のデータ
  const areaKeywordTableItems = useMemo(
    () =>
      resultStatus?.result.items
        .map((item) => {
          const detail = resultDetails.getDetail(item.condition);
          if (item.isProcessing() || !detail || detail.status === 'FAILED') {
            return {
              areaName: item.condition.areaName,
              searchWord: item.condition.searchWord,
              hasAds: null,
              managedStoreCount: null,
              competitorCount: null,
              status: !detail ? ('RUNNING' as const) : detail.status,
            };
          }
          return {
            areaName: detail.condition.areaName,
            searchWord: detail.condition.searchWord,
            hasAds: detail.hasAdsDetailItem(),
            managedStoreCount: detail.getItemsByManagedStoreKeys(managedStoreKeys).size,
            competitorCount: detail.getItemsByCompetitors(competitors).size,
            status: detail.result.isAmbiguous ? ('WARNING' as const) : detail.status,
            replacedAreaName: detail.replacedAreaName,
          };
        })
        .toArray() ?? [],
    [resultStatus, resultDetails, managedStoreKeys, competitors],
  );

  const displayModalData = modalData.last(null);

  // 検索実行時に指定地点とは別地点で検索が実行された検索地点と検索が行われた地点名の組み合わせ
  const replacedAreas = useMemo(
    () =>
      resultStatus?.result.items.reduce(
        (reduction, item) => {
          const detail = resultDetails.getDetail(item.condition);
          if (item.isProcessing() || !detail || detail.status === 'FAILED') {
            return reduction;
          }

          if (detail.replacedAreaName) {
            reduction[item.condition.areaName] = detail.replacedAreaName;
          }
          return reduction;
        },
        {} as { [key: string]: string },
      ) ?? {},
    [resultStatus, resultDetails],
  );

  // 競合店舗の初期化が完了しているかどうか
  const [isCompetitorsInitialized, setIsCompetitorsInitialized] = useState(false);

  /*
   * 競合設定が変更された場合にURLに反映する
   */
  useEffect(() => {
    // 競合設定が初期化される前は何もしない
    if (!isCompetitorsInitialized) {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    if (competitors.list.isEmpty()) {
      searchParams.delete('competitors');
    } else {
      searchParams.set('competitors', competitors.list.map((competitor) => competitor.placeId).join(','));
    }

    let newSearch = '';
    if (Array.from(searchParams).length > 0) {
      newSearch = '?' + searchParams.toString().replace(/%2C/g, ',');
    }
    if (location.search !== newSearch) {
      dispatch(replaceWithOrganizationId(location.pathname + newSearch));
    }
  }, [competitors, dispatch, location, isCompetitorsInitialized]);

  /*
   * 競合店舗の初期化処理
   */
  useEffect(() => {
    /*
     * 競合店舗の初期化処理フロー
     *
     * 1. URLにcompetitorsパラメータが設定されていない場合、何もしない（保存されている競合店舗）
     * 2. competitorsパラメータの競合店舗と保存されている競合店舗が同じ場合、何もしない（保存されている競合店舗）
     * 3. competitorsパラメータに含まれる店舗が結果のデータ（resultDetails）に含まれる場合
     *   3-1. 保存されている競合店舗がない場合、即座にcompetitorsパラメータから競合店舗に反映
     *   3-2. 保存されている競合店舗がある場合、競合店舗を上書きするか確認後、競合店舗に反映
     */

    // 結果のロードが完了する前は何もしない
    if (isLoadingResult) {
      return;
    }

    // 競合設定が初期化されている場合は何もしない
    if (isCompetitorsInitialized) {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const competitorsParam = searchParams.get('competitors') || null;
    if (competitorsParam !== null) {
      const competitorPlaceIds = competitorsParam.split(',');
      const currentCompetitorPlaceIds = competitors.list.map((competitor) => competitor.placeId);

      // 保存されている競合店舗と、URL指定の競合店舗が異なる場合
      if (!Set(competitorPlaceIds).equals(Set(currentCompetitorPlaceIds))) {
        // URLのplaceIdからstoreKeyに変換する
        const storeKeys = resultDetails.getStoreKeys();
        const competitoeStoreKeys = competitorPlaceIds
          .map((placeId) => storeKeys.find((storeKey) => storeKey.placeId === placeId))
          .filter((storeKey) => storeKey) as StoreKey[];

        const message =
          '現在設定されている競合店舗\n' +
          competitors.list.map((competitor) => `・${competitor.storeName}`).join('\n') +
          '\n\nを以下の店舗で上書きしますか？\n' +
          competitoeStoreKeys.map((storeKey) => `・${storeKey.storeName}`).join('\n');

        // 既存で設定されている競合店舗がない、もしくは競合店舗の上書きをOKした場合、競合店舗を設定する
        if (competitoeStoreKeys.length > 0 && (competitors.list.isEmpty() || window.confirm(message))) {
          const newCompetitors = competitoeStoreKeys.reduce(
            (competitorList, storeKey) => competitorList.push(storeKey),
            new CompetitorList({ id }),
          );
          setCompetitors(newCompetitors);
          updateCompetitorSettings(competitorSettings.updateSettings(newCompetitors.id, newCompetitors));
        }
      }
    }
    setIsCompetitorsInitialized(true);
  }, [
    competitorSettings,
    competitors.list,
    handleOnChangeCompetitors,
    id,
    isCompetitorsInitialized,
    isLoadingResult,
    location.search,
    resultDetails,
    setCompetitors,
    updateCompetitorSettings,
  ]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle(`${title}`)} />
      <StickyHeader>
        <BackButton onClick={onClickBackButton}>
          <Arrow direction={'left'} color={'#393939'} length={16} />
          <Title>{title}</Title>
        </BackButton>
      </StickyHeader>
      <WideBody>
        <Wrapper>
          <HeaderContent>
            <DateLabel>データ取得日時：{resultStatus?.date.format('YYYY/MM/DD HH:mm') ?? '取得中'}</DateLabel>
            <StoreListLink priority={'low'} onClick={() => setShowComparisonStoreModal(true)}>
              比較店舗を設定する{competitors.size > 0 && `（${competitors.size}店舗設定中）`}
            </StoreListLink>
          </HeaderContent>
          {isLoadingResult && resultStatus && (
            <ProgressContent>
              <MapCompetitorResearchProgress
                totalCount={resultStatus.getItemCount()}
                completedCount={resultDetails.getCompletedCount()}
              />
            </ProgressContent>
          )}
          <TableWrapper>
            <TitleLabel>検索条件一覧</TitleLabel>
            <AreaKeywordTable items={areaKeywordTableItems} onSelectAreaKeyword={handleOnSelectAreaKeyword} />
          </TableWrapper>
          {Object.keys(replacedAreas).length > 0 && (
            <StyledAlert type='caution'>
              <StyledAlertTitle>一部の検索条件で検索地点が変更されています</StyledAlertTitle>
              <StyledAlertSection>
                変更された検索地点が正しくない場合、別の検索地点を指定して再度検索を実行してください。
              </StyledAlertSection>
              <StyledAlertSection>
                <ul>
                  {Object.entries(replacedAreas).map(([areaName, replacedAreaName]) => (
                    <li key={areaName}>
                      {`「${areaName}」で検索地点を特定できなかったため、「${replacedAreaName}」で検索を実行しました。`}
                    </li>
                  ))}
                </ul>
              </StyledAlertSection>
            </StyledAlert>
          )}
          <AverageRankTable
            onSelectStore={handleOnSelectStore}
            onChangeCompetitor={handleOnChangeCompetitor}
            tableData={averageRankTableData}
            isLoading={isLoadingResult}
            competitors={competitors}
            managedStoreKeys={managedStoreKeys}
          />
          <TableWrapper>
            <RankComparisonTable
              details={resultDetails}
              competitors={competitors}
              onSelectAreaKeyword={handleOnSelectAreaKeyword}
              managedStoreKeys={managedStoreKeys}
            />
          </TableWrapper>
        </Wrapper>
        <ComparisonStoreModal
          competitors={competitors}
          isOpen={showComparisonStoreModal}
          onClose={() => setShowComparisonStoreModal(false)}
          onChangeCompetitor={handleOnChangeCompetitor}
          onChangeCompetitors={handleOnChangeCompetitors}
          onSelectStore={handleOnSelectStore}
          storeKeys={storeKeys}
          managedStoreKeys={managedStoreKeys}
        />
        {displayModalData &&
          (displayModalData instanceof StoreKey ? (
            <StoreDetailModal
              isOpen={true}
              isCompetitor={competitors.contains(displayModalData)}
              color={competitors.getColor(displayModalData)}
              disableAddCompetitor={competitors.size >= MAX_COMPETITOR_SIZE}
              data={resultDetails.getRanksByStoreKey(displayModalData)}
              onBack={() => setModalData((modalData) => modalData.pop())}
              onClose={() => setModalData(List())}
              onSelectAreaKeyword={handleOnSelectAreaKeyword}
              onChangeCompetitor={handleOnChangeCompetitor}
            />
          ) : (
            <AreaKeywordRankModal
              isOpen={true}
              data={resultDetails.getRanksByAreaKeyword(displayModalData)}
              onBack={() => setModalData((modalData) => modalData.pop())}
              onClose={() => setModalData(List())}
              onSelectStore={handleOnSelectStore}
              onChangeCompetitor={handleOnChangeCompetitor}
              condition={displayModalData}
              competitors={competitors}
              managedStoreKeys={managedStoreKeys}
            />
          ))}
      </WideBody>
    </MainWrapper>
  );
});

const Wrapper = styled.div``;

const TitleLabel = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
`;

const TableWrapper = styled.div`
  margin-bottom: 32px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
`;

const ProgressContent = styled.div`
  margin-bottom: 32px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
`;

const DateLabel = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-weight: bold;
`;

const StoreListLink = styled(Button)``;

const StyledAlert = styled(Alert)`
  margin-bottom: 32px;
`;

const StyledAlertTitle = styled(Alert.Title)`
  font-size: 16px;
`;

const StyledAlertSection = styled(Alert.Section)`
  font-size: 14px;
`;
