import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { InventoryCsvDownloadFilter } from 'models/Domain/Omo/InventoryCsvDownloadFilter';

import { InventoriesImportActions } from './actions';
import { ImportStatus, ValidateError } from './type';

type InventoriesImportStateType = {
  // 在庫情報のCSVファイルダウンロード
  inventoryCsvDownloadFilter: InventoryCsvDownloadFilter;
  downloadExecutionArn: string;

  // バリデーション関連の状態
  resultMessage?: string;
  isValidateError: boolean | undefined;
  validateError?: ValidateError;
  totalSucceed?: number;
  totalFailed?: number;

  // インポート関連の状態
  executionArn: string;
  importStatus?: ImportStatus;
  // バックエンドが処理中かどうか
  isProcessing: boolean;
};

const initialState: InventoriesImportStateType = {
  // 在庫情報のCSVファイルダウンロード
  inventoryCsvDownloadFilter: new InventoryCsvDownloadFilter(),
  downloadExecutionArn: '',

  // バリデーション関連の状態
  resultMessage: undefined,
  isValidateError: undefined,
  validateError: undefined,
  totalSucceed: 0,
  totalFailed: 0,

  // インポート関連の状態
  executionArn: '',
  importStatus: undefined,
  isProcessing: false,
};

/**
 * 店頭在庫インポートの状態を管理するState
 */
export class InventoriesImportState extends Record<InventoriesImportStateType>(initialState) {
  get isImportError() {
    return this.importStatus === 'FAILED';
  }

  get isAcceptedImport() {
    return !!this.executionArn;
  }
}

/**
 * 店頭在庫インポートの状態を管理するReducer
 */
export const inventoriesImportReducer = reducerWithInitialState(new InventoriesImportState())
  .case(InventoriesImportActions.setInventoryCsvDownloadFilter, (state, payload) => {
    return state.set('inventoryCsvDownloadFilter', payload);
  })
  .case(InventoriesImportActions.setDownloadExecutionArn, (state, payload) => {
    return state.set('downloadExecutionArn', payload ?? '');
  })
  .case(InventoriesImportActions.setValidateResult, (state, payload) => {
    return state.merge({
      resultMessage: payload.result.message,
      isValidateError:
        payload.result.validation_status === 'failed'
          ? true
          : payload.result.validation_status === 'success'
            ? false
            : undefined,
      validateError: payload.result.validation_errors,
      totalSucceed: payload.result.total_succeed,
      totalFailed: payload.result.total_failed,
    });
  })
  .case(InventoriesImportActions.setExecutionArn, (state, payload) => {
    return state.set('executionArn', payload);
  })
  .case(InventoriesImportActions.setImportStatus, (state, payload) => {
    const { importStatus } = payload;
    return state.merge({ importStatus });
  })
  .case(InventoriesImportActions.clearValidateResult, (state, _) => {
    return state.merge({
      isValidateError: undefined,
      validateError: undefined,
      importStatus: undefined,
      totalFailed: 0,
      totalSucceed: 0,
    });
  })
  .case(InventoriesImportActions.clearStatus, (state, _) => {
    return new InventoriesImportState();
  })
  .case(InventoriesImportActions.setIsProcessing, (state, payload) => {
    return state.set('isProcessing', payload);
  });
