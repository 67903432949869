import { List } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import Notification from 'models/Domain/Notification';

const actionCreator = actionCreatorFactory('Notification');

export const NotificationActions = {
  getNotificationList: actionCreator('getNotificationList'),
  setNotificationList: actionCreator<List<Notification>>('setNotificationList'),
  postNotificationRead: actionCreator<number>('postNotificationRead'),
  postNotificationBulkRead: actionCreator('postNotificationBulkRead'),
};
