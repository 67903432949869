import React from 'react';

import dayjs from 'dayjs';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { DatePicker } from 'components/atoms/DatePicker';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import Promotion from 'models/Domain/Promotion/Promotion';

type Props = {
  promotion: Promotion;
  setPromotion: (promotion: Promotion) => void;
  showErrorMessage: boolean;
};

export const PromotionFormPostSettings: React.FC<Props> = (props) => {
  const { promotion, setPromotion, showErrorMessage } = props;
  const { isScheduled, scheduledAt } = promotion;

  // 今日の0時(JST)
  const today = dayjs(dayjs().format('YYYY/MM/DD'));
  // 投稿日時として設定できるのは今日から1年以内（todayから1年後の前日23:59:59まで）
  const maxDate = today.add(1, 'year').subtract(1, 'seconds');

  const onChangeIsScheduled = () => {
    setPromotion(promotion.changeIsScheduled(!isScheduled));
  };

  const onChangeDate = (value: Date | null) => {
    // 日付にnullは設定させない
    if (value == null) {
      return;
    }
    const date = dayjs(value);
    setPromotion(promotion.changeScheduledAt(date));
  };

  const onChangeTime = (value: Date | null) => {
    // 時間にnullは設定させない
    if (value == null) {
      return;
    }
    // 時刻を手入力すると途中で日付がおかしくなることがあるので、設定済みの日付と入力された時刻を組み合わせる
    const dateText = (scheduledAt ?? today).format('YYYY-MM-DD');
    const timeText = dayjs(value).format('HH:mm');
    const date = dayjs(`${dateText}T${timeText}:00+09:00`);
    setPromotion(promotion.changeScheduledAt(date));
  };

  return (
    <>
      <FormContent name={'予約投稿'} informationText={'指定された日時から5分以内に投稿が開始されます'}>
        <ReserveCheckboxWrapper>
          日時を指定して投稿
          <StyledCheckbox toggle checked={isScheduled} onChange={onChangeIsScheduled} placeholderText={'日付を選択'} />
        </ReserveCheckboxWrapper>
        {isScheduled && (
          <>
            <DatePickerContainer>
              <DatePickerWrapper>
                <DatePickerLabel>日付</DatePickerLabel>
                <StyledDatePicker
                  dateFormat={'yyyy/MM/dd'}
                  selected={scheduledAt?.toDate()}
                  onChange={onChangeDate}
                  placeholderText={'日付を選択'}
                  minDate={today.toDate()}
                  maxDate={maxDate.toDate()}
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <DatePickerLabel>時刻</DatePickerLabel>
                <StyledDatePicker
                  dateFormat={'HH:mm'}
                  timeFormat={'HH:mm'}
                  selected={scheduledAt?.toDate()}
                  onChange={onChangeTime}
                  showTimeSelect
                  showTimeSelectOnly
                  placeholderText={'時刻を選択'}
                />
              </DatePickerWrapper>
            </DatePickerContainer>
            <DummyTextField error={showErrorMessage && promotion.validateSchedule().name} />
          </>
        )}
      </FormContent>
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  margin-left: 16px;
  &&& {
    input:focus:checked ~ label:before,
    input:checked ~ label:before {
      background-color: #05ccad !important;
    }
  }
`;

const ReserveCheckboxWrapper = styled.div`
  display: flex;
`;

const DatePickerContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  > :not(:first-child) {
    margin-left: 16px;
  }
`;

const DatePickerWrapper = styled.div`
  flex: 1;
`;

const DatePickerLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-popper {
    z-index: 10; /* デフォルトのz-index: 1では他のコンポーネントが上に表示されてしまう */
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    height: 54px;
    padding: 0 16px;
    font-family: monospace !important;
  }
`;

const DummyTextField = styled(Input)`
  &&& {
    input {
      display: none;
    }
  }
`;
