import React from 'react';

type ResizeImageProps = {
  width?: number;
  height?: number;
} & React.ComponentProps<'img'>;

export const createResizedImageURL = (
  originalUrl: string | undefined,
  width: number | undefined = undefined,
  height: number | undefined = undefined,
) => {
  // URLにcoundfront.netが入っていない場合はリサイズ対象外
  if (originalUrl === undefined || originalUrl.indexOf('.cloudfront.net/') === -1) {
    return originalUrl;
  }

  // originalUrlから既存のクエリパラメータを取得する
  const [url, query] = originalUrl.split('?');

  // 最大幅、最大高さが指定されている場合は設定する
  const params = new URLSearchParams(query);
  if (width) {
    params.set('w', String(width));
  }
  if (height) {
    params.set('h', String(height));
  }

  // クエリパラメータが存在しない場合はURLのみを返す
  const updatedQuery = params.toString();
  if (!updatedQuery) {
    return url;
  }

  return `${url}?${updatedQuery}`;
};

export const ResizeImage: React.FC<ResizeImageProps> = ({ width, height, src, ...props }) => {
  return <img src={createResizedImageURL(src, width, height)} {...props} />;
};
