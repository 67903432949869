import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { UserApi, UserListApi } from 'ApiClient/UserApi';
import { User } from 'models/Domain/User';
import { Path } from 'routes';

import { AppActions } from '../app/actions';
import { onDropAccepted } from '../app/fileUpload';
import { State } from '../reducers';

import { UserActions } from './actions';

export default function* saga() {
  yield takeLatest(UserActions.getUserList, () => getUserList());
  yield takeLatest(UserActions.registerNewUser, registerNewUser);
  yield takeLatest(UserActions.updateEditUser, updateEditUser);
  yield takeLatest(UserActions.deleteEditUser, deleteEditUser);
  yield takeLatest(UserActions.uploadNewUserImage, uploadNewUserImage);
  yield takeLatest(UserActions.uploadEditUserImage, uploadEditUserImage);
  yield takeLatest(UserActions.changePassword, changePassword);
}

export function* getUserList() {
  const response: YieldReturn<typeof UserListApi.get> = yield UserListApi.get();

  if (response.isSuccess) {
    yield put(UserActions.setUserList(response.data));
  } else {
    toast({
      type: 'error',
      title: 'スタッフ一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* registerNewUser(action: ReturnType<typeof UserActions.registerNewUser>) {
  yield put(AppActions.setLoading(true));

  const newUser: User = yield select((state) => state.user.newUser);

  const response: YieldReturn<typeof UserListApi.post> = yield UserListApi.post(newUser.requestParams());

  if (response.isSuccess) {
    yield put(UserActions.getUserList());
    yield put(UserActions.clearNewUser());
    yield put(AppActions.moveTo(Path.user.index));
    toast({
      type: 'success',
      title: '登録が完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: '登録に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* updateEditUser(action: ReturnType<typeof UserActions.updateEditUser>) {
  yield put(AppActions.setLoading(true));

  const editUser: User = yield select((state) => state.user.editUser);
  const currentUser: User = yield select((state) => state.app.currentUser);

  const response: YieldReturn<typeof UserApi.put> = yield UserApi.put(editUser.requestParams());

  if (response.isSuccess) {
    yield put(UserActions.getUserList());

    // 自分の情報を更新した場合、currentUser設定を更新した内容で置き換える
    if (currentUser.id === editUser.id) {
      yield put(AppActions.setCurrentUser(editUser));
    }

    toast({
      type: 'success',
      title: '更新しました',
      description: `${editUser.fullName}`,
    });
  } else {
    toast({
      type: 'error',
      title: '更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* deleteEditUser(action: ReturnType<typeof UserActions.deleteEditUser>) {
  yield put(AppActions.setLoading(true));

  const targetUser = action.payload;

  const response: YieldReturn<typeof UserApi.delete> = yield UserApi.delete(targetUser.id);

  if (response.isSuccess) {
    yield put(UserActions.getUserList());
    yield put(AppActions.moveTo(Path.user.index));
    toast({
      type: 'success',
      title: '削除しました',
      description: `${targetUser.fullName}`,
    });
  } else {
    toast({
      type: 'error',
      title: '削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* uploadNewUserImage(action: ReturnType<typeof UserActions.uploadNewUserImage>) {
  const files = [action.payload];
  const urls: string[] = yield call(onDropAccepted, files);
  if (urls && urls.length > 0) {
    const newUser: User = yield select((state) => state.user.newUser);
    yield put(UserActions.changeNewUser(newUser.changeProfileImageUrl(urls[0])));
  }
}

function* uploadEditUserImage(action: ReturnType<typeof UserActions.uploadEditUserImage>) {
  const files = [action.payload];
  const urls: string[] = yield call(onDropAccepted, files);
  if (urls && urls.length > 0) {
    const editUser: User = yield select((state: State) => state.user.editUser);
    yield put(UserActions.setEditUser(editUser.changeProfileImageUrl(urls[0])));
  }
}

function* changePassword(action: ReturnType<typeof UserActions.changePassword>) {
  try {
    yield put(AppActions.setLoading(true));
    const user: CognitoUser = yield call([Auth, 'currentAuthenticatedUser'], {
      bypassCache: false,
    });
    if (user) {
      yield call([Auth, 'changePassword'], user, action.payload.oldPassword, action.payload.newPassword);
      // サーバー側でセッションIDを破棄するためglobal:trueを指定（脆弱性対応）
      yield call([Auth, 'signOut'], { global: true });
      yield put(UserActions.setErrorMessage(''));
      toast({
        type: 'success',
        title: 'パスワードを変更しました',
        description: '',
      });
      yield put(UserActions.setSuccessChangePassword(true));
      yield put(AppActions.signOut(true)); // 同じユーザーのセッションを破棄
    }
  } catch (error: any) {
    let message = error.message;
    // cognito側のエラーメッセージをもとにユーザーに返す文言を設定する
    // こちらで観測したものは適切にメッセージを返し、それ以外はサポート問い合わせを促す文言を出す
    if (
      message === 'Incorrect username or password.' ||
      /Value at 'previousPassword' failed to satisfy constraint: /.test(message)
    ) {
      message = '現在のパスワードが一致しません';
    } else if (message === 'Attempt limit exceeded, please try after some time.') {
      message = '試行制限を超えました。しばらくしてからもう一度試してください。';
    } else if (
      /Value at 'proposedPassword' failed to satisfy constraint: /.test(message) ||
      /Password did not conform with policy: /.test(message)
    ) {
      message = '新しいパスワードが条件を満たしていません。';
    } else {
      message = 'パスワードの変更に失敗しました。サポートから問い合わせてください。';
    }
    toast({
      type: 'error',
      title: 'パスワードの変更に失敗しました',
      description: message,
      time: 10000,
    });
    yield put(UserActions.setErrorMessage(message));
  } finally {
    yield put(AppActions.setLoading(false));
  }
}
