import { toast } from 'react-semantic-toasts';
import useSWRImmutable from 'swr/immutable';

import { MemoApi } from 'ApiClient/MemoApi';
import { AccountSingleton } from 'models/Domain/AccountList';
import { MemoTagList } from 'models/Domain/Memo/Memo';

/** 施策メモのタグをAPIで取得する */
const fetchMemoTags = async () => {
  const response = await MemoApi.getTag();

  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: '施策メモのタグの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });

    return new MemoTagList();
  }

  return MemoTagList.fromJSON(response.data);
};

/** 施策メモのタグを取得する用のuseSWRのラッパー */
export const useMemoTags = () => {
  // 組織の切り替え時に再度取得されるように、keyに現在のorganizationIdを含める
  const currentOrganizationId = AccountSingleton.getInstance().organizationId;
  return useSWRImmutable([currentOrganizationId], fetchMemoTags);
};
