import React, { useMemo } from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { GbpChecklistItem, Status } from 'models/Domain/GbpChecklist';
import { COLOR } from 'style/color';

type GbpChecklistTableItem = GbpChecklistItem & { category: string; helpText?: string };

export type Props = {
  className?: string;
  isLoading: boolean;
  tableData: List<GbpChecklistTableItem>;
  onSelectItem: (item: GbpChecklistItem) => void;
};

export const GbpChecklistTable = React.memo<Props>(({ isLoading, tableData, onSelectItem }) => {
  return (
    <Wrapper>
      <Table unstackable={true}>
        <Header />
        <TableBody>
          {tableData.map((item, i) => {
            // ひとつ上の行のデータと同じカテゴリなら表示しない
            const showCategory = tableData.get(i - 1)?.category !== item.category;
            return (
              <ItemRow
                category={showCategory ? item.category : ''}
                title={item.title}
                helpText={item.helpText}
                status={item.status}
                key={i}
                onClick={() => onSelectItem(item)}
              />
            );
          })}
        </TableBody>
      </Table>
      {isLoading && (
        <LoadingWrapper>
          <Loader active={isLoading} size='big' inline={true} />
        </LoadingWrapper>
      )}
    </Wrapper>
  );
});

const Header = () => (
  <TableHeader>
    <TableHeaderRow>
      <StyledTableHeaderCell style={{ width: '100px' }}>カテゴリ</StyledTableHeaderCell>
      <StyledTableHeaderCell>項目</StyledTableHeaderCell>
      <StyledTableHeaderCell style={{ width: '100px' }}>達成</StyledTableHeaderCell>
      <StyledTableHeaderCell style={{ width: '150px' }}>達成店舗数</StyledTableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
);

type ItemRowProps = {
  category: string;
  title: string;
  helpText?: string;
  status: Status;
  onClick: () => void;
};

const ItemRow = React.memo<ItemRowProps>(({ category, title, helpText, status, onClick }) => {
  const statusStyle: { text: string; color: string } = useMemo(() => {
    if (status.total === 0) {
      return { text: 'ー', color: COLOR.BLACK };
    } else if (status.isDone) {
      return { text: '○', color: COLOR.GREEN };
    } else if (status.progress >= 50) {
      return { text: '△', color: 'orange' };
    } else {
      return { text: 'X', color: COLOR.ERROR };
    }
  }, [status]);

  return (
    <StyledTableRow onClick={onClick}>
      <StyledTableCell>{category}</StyledTableCell>
      <StyledTableCell textAlign='left'>
        {title}
        {helpText && <ContextHelp content={helpText} />}
      </StyledTableCell>
      <StyledTableCell>{statusStyle.text}</StyledTableCell>
      <StyledTableCell>
        <Stores color={statusStyle.color}>{`${status.doneCount} / ${status.total}`} 店舗</Stores>
      </StyledTableCell>
    </StyledTableRow>
  );
});

const Wrapper = styled.div`
  position: relative;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const StyledTableHeaderCell = styled(TableHeaderCell)<{ border?: number }>`
  &&& {
    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
  }
`;

const StyledTableCell = styled(TableCell)<{ border?: number; textAlign?: React.CSSProperties['textAlign'] }>`
  &&& {
    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
    text-align: ${({ textAlign = 'center' }) => textAlign};
  }
`;

// 達成店舗数を、完了なら緑、50%以上ならオレンジ、それ以下なら赤で表示する
const Stores = styled.span<{ color: string }>`
  font-family: monospace;
  color: ${({ color }) => color};
  font-weight: bold;
`;
