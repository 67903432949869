import { Record as ImmutableRecord } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
  GbpPerformanceMAGraphData,
  GbpPerformanceMAMonthlyData,
  GbpPerformanceMATableData,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { GbpPerformanceMACsvDownloadCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMACsvDownloadCondition';
import { GbpPerformanceMASearchCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMASearchCondition';

import { GbpPerformanceMAActions } from './actions';

export class GbpPerformanceMAState extends ImmutableRecord<{
  isInitializedSearchCondition: boolean;
  isLoadingData: boolean;
  isLoadingComparisonData: boolean;
  isLoadingMonthlyData: boolean;
  searchCondition: GbpPerformanceMASearchCondition | null;
  csvDownloadCondition: GbpPerformanceMACsvDownloadCondition;
  graphData: GbpPerformanceMAGraphData;
  tableData: GbpPerformanceMATableData;
  monthlyData: GbpPerformanceMAMonthlyData;
  prevSearchCondition: GbpPerformanceMASearchCondition | null;
  isFirstSearch: boolean; // 初回検索か
}>({
  isInitializedSearchCondition: false,
  isLoadingData: false,
  isLoadingComparisonData: false,
  isLoadingMonthlyData: false,
  searchCondition: null,
  csvDownloadCondition: new GbpPerformanceMACsvDownloadCondition(),
  graphData: new GbpPerformanceMAGraphData(),
  tableData: new GbpPerformanceMATableData(),
  monthlyData: new GbpPerformanceMAMonthlyData(),
  prevSearchCondition: null,
  isFirstSearch: true,
}) {}

export const gbpPerformanceMAReducer = reducerWithInitialState(new GbpPerformanceMAState())
  .case(GbpPerformanceMAActions.setIsInitializedSearchCondition, (state, payload) => {
    return state.set('isInitializedSearchCondition', payload);
  })
  .case(GbpPerformanceMAActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(GbpPerformanceMAActions.setCsvDownloadCondition, (state, payload) => {
    return state.set('csvDownloadCondition', payload);
  })
  .case(GbpPerformanceMAActions.setIsLoadingData, (state, payload) => {
    return state.set('isLoadingData', payload);
  })
  .case(GbpPerformanceMAActions.setIsLoadingComparisonData, (state, payload) => {
    return state.set('isLoadingComparisonData', payload);
  })
  .case(GbpPerformanceMAActions.setIsLoadingMonthlyData, (state, payload) => {
    return state.set('isLoadingMonthlyData', payload);
  })
  .case(GbpPerformanceMAActions.setGraphData, (state, payload) => {
    return state.set('graphData', payload);
  })
  .case(GbpPerformanceMAActions.setTableData, (state, payload) => {
    return state.set('tableData', payload);
  })
  .case(GbpPerformanceMAActions.setMonthlyData, (state, payload) => {
    return state.set('monthlyData', payload);
  })
  .case(GbpPerformanceMAActions.updatePrevSearchCondition, (state, payload) => {
    return state.set('prevSearchCondition', state.searchCondition);
  })
  .case(GbpPerformanceMAActions.setIsFirstSearch, (state, payload) => {
    return state.set('isFirstSearch', payload);
  });
