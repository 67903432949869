import { List, Map, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Inventory } from 'models/Domain/Omo/Inventory';
import { InventorySummary } from 'models/Domain/Omo/InventorySummary';
import { ProductGroups } from 'models/Domain/Omo/Product';
import { ProductSearchCondition } from 'models/Domain/Omo/ProductSearchCondition';
import { StoreSearchCondition } from 'models/Domain/Omo/StoreSearchCondition';

import { InventoryActions } from './actions';

export interface InventoryRecord {
  // 単一の店舗の在庫サマリー
  inventorySummary: InventorySummary;
  // 複数店舗の在庫サマリー
  inventorySummaryList: List<InventorySummary>;
  isLoadingInventorySummary: boolean;
  isLoadingProducts: boolean;
  storeSearchCondition: StoreSearchCondition;
  committedStoreSearchCondition: StoreSearchCondition;
  productSearchCondition: ProductSearchCondition;
  committedProductSearchCondition: ProductSearchCondition;
  productGroups: ProductGroups;
  committedProductGroups: ProductGroups;
  editedInventories: Map<string, Inventory>;
  // ページの初期処理に必要な情報が準備できているか
  isPreparedForInventoryListPage: boolean;
  // 有効期限の更新が実施されたか
  isUpdateExpirationExecuted: boolean;
}

export class InventoryState extends Record<InventoryRecord>({
  inventorySummary: new InventorySummary(),
  inventorySummaryList: List(),
  isLoadingInventorySummary: false,
  isLoadingProducts: false,
  storeSearchCondition: new StoreSearchCondition(),
  committedStoreSearchCondition: new StoreSearchCondition(),
  productSearchCondition: new ProductSearchCondition(),
  committedProductSearchCondition: new ProductSearchCondition(),
  productGroups: new ProductGroups(),
  committedProductGroups: new ProductGroups(),
  editedInventories: Map<string, Inventory>(),
  isPreparedForInventoryListPage: false,
  isUpdateExpirationExecuted: false,
}) {}

export const inventoryReducer = reducerWithInitialState(new InventoryState())
  .case(InventoryActions.setIsLoadingInventorySummary, (state, payload) => {
    return state.set('isLoadingInventorySummary', payload);
  })
  .case(InventoryActions.setIsLoadingProducts, (state, payload) => {
    return state.set('isLoadingProducts', payload);
  })
  .case(InventoryActions.setInventorySummary, (state, payload) => {
    return state.set('inventorySummary', payload);
  })
  .case(InventoryActions.setInventorySummaryList, (state, payload) => {
    return state.set('inventorySummaryList', payload);
  })
  .case(InventoryActions.setStoreSearchCondition, (state, payload) => {
    return state.set('storeSearchCondition', payload.searchCondition);
  })
  .case(InventoryActions.setCommittedStoreSearchCondition, (state, payload) => {
    return state.set('committedStoreSearchCondition', payload);
  })
  .case(InventoryActions.setProductSearchCondition, (state, payload) => {
    return state.set('productSearchCondition', payload.searchCondition);
  })
  .case(InventoryActions.setCommittedProductSearchCondition, (state, payload) => {
    return state.set('committedProductSearchCondition', payload);
  })
  .case(InventoryActions.setProductGroups, (state, payload) => {
    return state.set('productGroups', payload);
  })
  .case(InventoryActions.setCommittedProductGroups, (state, payload) => {
    return state.set('committedProductGroups', payload);
  })
  .case(InventoryActions.changeInventory, (state, payload) => {
    const { groupIndex, productIndex, storeId, inventory } = payload;
    return state.update('productGroups', (productGroups) =>
      productGroups.changeInventory(groupIndex, productIndex, storeId, inventory),
    );
  })
  .case(InventoryActions.setEditedInventories, (state, payload) => {
    return state.set('editedInventories', payload);
  })
  .case(InventoryActions.addEditedInventory, (state, payload) => {
    return state.update('editedInventories', (editedInventories) => editedInventories.set(payload.key, payload));
  })
  .case(InventoryActions.removeEditedInventory, (state, payload) => {
    return state.update('editedInventories', (editedInventories) => editedInventories.remove(payload.key));
  })
  .case(InventoryActions.setIsPreparedForInventoryListPage, (state, payload) => {
    return state.set('isPreparedForInventoryListPage', payload);
  })
  .case(InventoryActions.setIsUpdateExpirationExecuted, (state, payload) => {
    return state.set('isUpdateExpirationExecuted', payload);
  });
