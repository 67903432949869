import React from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';

export type size = 'small' | 'mini' | 'tiny' | 'large';

interface Props {
  className?: string;
  type?: size;
  title?: string;
  text?: string;
  contentText?: string;
  negativeText?: string;
  open?: boolean;
  onExecution: () => void;
  onNegativeExecution: () => void;
}

export const ConfirmModal: React.FC<Props> = ({
  className,
  type,
  title,
  text,
  open,
  onExecution,
  onNegativeExecution,
  contentText = 'OK',
  negativeText = 'キャンセル',
}) => {
  return (
    <Modal className={className} size={type} open={open}>
      {title && <Modal.Header>{title}</Modal.Header>}
      {text && <Modal.Content>{text}</Modal.Content>}
      <Modal.Actions>
        <ButtonsWrapper>
          <StyledButton onClick={() => onNegativeExecution()}>{negativeText}</StyledButton>
          <StyledButton priority='high' onClick={() => onExecution()}>
            {contentText}
          </StyledButton>
        </ButtonsWrapper>
      </Modal.Actions>
    </Modal>
  );
};

const StyledButton = styled(Button)`
  margin-left: 16px;

  &&& {
    width: 170px;
    @media (max-width: 600px) {
      margin-left: 0;
      margin-bottom: 16px;
      width: 100%;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  // Tab表示で下側の余白がなくなるので余白を確保
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  // SP表示ではボタンを逆順に表示（ポジティブアクションを上にする）
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    justify-content: unset;
    margin-bottom: unset;
  }
`;
