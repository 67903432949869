import React from 'react';

import styled from 'styled-components';

import Logo from 'components/atoms/Logo';

export const FinishedContent: React.FC = () => {
  return (
    <MessageWrapper>
      <MessageContent>
        <Logo gray width='240' />
        <Title>未読はありません</Title>
      </MessageContent>
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 120px);
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 4.44;
  letter-spacing: 0.45px;
  color: rgba(0, 0, 0, 0.35);
`;

const MessageContent = styled.div`
  text-align: center;
`;
