import React from 'react';

import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';

type Props = {
  className?: string;
  totalCount: number;
  completedCount: number;
};

export const SearchVolumeProgress = React.memo<Props>(({ className, totalCount, completedCount }) => {
  return (
    <Wrapper className={className}>
      <ProgressLabel>
        <LoaderWrapper>
          <Loader active={true} size='small' inline />
        </LoaderWrapper>
        現在検索ボリュームの取得中です。
      </ProgressLabel>
      <Progress value={completedCount} total={totalCount} progress='ratio' indicating autoSuccess />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  background: #fff;
  padding: 16px;
`;

const ProgressLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 16px;
`;

const LoaderWrapper = styled.div`
  margin-right: 8px;
`;
