import React from 'react';

import { List, Map } from 'immutable';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { CommaSeparate } from 'components/atoms/CommaSeparate';
import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  StyledTable,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderLabel,
  TableRow,
} from 'components/pageComponents/GmbInsight/GmbInsightTable';
import { InsightHelp as Help } from 'helpers/ContextHelp';
import {
  ComparisonInsightTableItem,
  InsightTableData,
  InsightTableItem,
  InsightTableItemStats,
  SortType,
} from 'models/Domain/GbpInsight/Insight';
import { COLOR } from 'style/color';

/** データと比較期間のデータをstoreIdを元にまとめる */
const zipInsightTableItems = (items: List<InsightTableItem>, comparisonItems: List<InsightTableItem>) => {
  // 比較対象のデータをstoreIdに対してInsightTableItemを持つデータ
  const comparisonItemMap = comparisonItems.reduce(
    (reduction, value) => reduction.set(value.storeId, value),
    Map<number, InsightTableItem>(),
  );

  return items.map((item) => {
    const comparisonItem = comparisonItemMap.get(item.storeId, new InsightTableItem());
    return item.zip(comparisonItem);
  });
};

export type StoreListTableProps = {
  tableData: InsightTableData;
  comparisonTableData: InsightTableData;
  period: string;
  comparisonPeriod: string;
  isEnabledComparison: boolean;
  addSelectedStore: (storeId: number) => void;
  changeSortType: (sortType: SortType) => void;
};

export const StoreListTable: React.FC<StoreListTableProps> = ({
  tableData,
  comparisonTableData,
  period,
  comparisonPeriod,
  isEnabledComparison,
  addSelectedStore,
  changeSortType,
}) => {
  const { sortType, isSortOrderDesc } = tableData;
  return (
    <StyledTable>
      <TableHeader>
        <Table.Row>
          <ClickableTableHeaderCell onClick={() => changeSortType('storeName')}>
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'storeName' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>店舗名</StyledTableHeaderLabel>
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell onClick={() => changeSortType('storeCode')}>
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'storeCode' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>コード</StyledTableHeaderLabel>
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell
            isSortedColumn={sortType === 'searchCount'}
            onClick={() => changeSortType('searchCount')}
          >
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'searchCount' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>検索数</StyledTableHeaderLabel>
              <ContextHelp {...Help.sumSearch} />
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell
            isSortedColumn={sortType === 'queriesDirect'}
            onClick={() => changeSortType('queriesDirect')}
          >
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'queriesDirect' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>直接検索</StyledTableHeaderLabel>
              <ContextHelp {...Help.queriesDirect} />
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell
            isSortedColumn={sortType === 'queriesIndirect'}
            onClick={() => changeSortType('queriesIndirect')}
          >
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'queriesIndirect' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>間接検索</StyledTableHeaderLabel>
              <ContextHelp {...Help.queriesIndirect} />
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell
            isSortedColumn={sortType === 'queriesChain'}
            onClick={() => changeSortType('queriesChain')}
          >
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'queriesChain' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>ブランド検索</StyledTableHeaderLabel>
              <ContextHelp {...Help.queriesChain} />
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell
            isSortedColumn={sortType === 'actionCount'}
            onClick={() => changeSortType('actionCount')}
          >
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'actionCount' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>アクション数</StyledTableHeaderLabel>
              <ContextHelp {...Help.sumAction} />
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell
            isSortedColumn={sortType === 'actionRate'}
            onClick={() => changeSortType('actionRate')}
          >
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'actionRate' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>アクション率</StyledTableHeaderLabel>
              <ContextHelp {...Help.overallActionRate} />
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <ClickableTableHeaderCell
            isSortedColumn={sortType === 'reviewCount'}
            onClick={() => changeSortType('reviewCount')}
          >
            <FlexWrapper>
              <TriangleWrapper>
                {sortType === 'reviewCount' && <Triangle isSortOrderDesc={isSortOrderDesc} />}
              </TriangleWrapper>
              <StyledTableHeaderLabel>クチコミ数</StyledTableHeaderLabel>
              <ContextHelp {...Help.sumReview} />
            </FlexWrapper>
          </ClickableTableHeaderCell>
          <TableHeaderCell />
        </Table.Row>
      </TableHeader>

      <Table.Body>
        {isEnabledComparison
          ? /* 比較期間がある場合 */ zipInsightTableItems(tableData.sortedItems, comparisonTableData.items).map(
              (item) => (
                <ComparisonStatsRow
                  key={item.storeId}
                  period={period}
                  comparisonPeriod={comparisonPeriod}
                  item={item}
                  sortedColumn={sortType}
                  onClickAddStoreButton={() => addSelectedStore(item.storeId)}
                />
              ),
            )
          : /* 比較期間がない場合 */ tableData.sortedItems.map((item) => (
              <SingleStatsRow
                key={item.storeId}
                item={item}
                sortedColumn={sortType}
                onClickAddStoreButton={() => addSelectedStore(item.storeId)}
              />
            ))}
      </Table.Body>
    </StyledTable>
  );
};

/** 検索数、直接検索数などのセル */
const StatsCells: React.FC<{ stats: InsightTableItemStats; sortedColumn: SortType }> = ({ stats, sortedColumn }) => (
  <>
    <StyledTableCell isSortedColumn={sortedColumn === 'searchCount'}>
      <CommaSeparate value={stats.searchCount} />
    </StyledTableCell>
    <StyledTableCell isSortedColumn={sortedColumn === 'queriesDirect'}>
      <CommaSeparate value={stats.queriesDirect} />
    </StyledTableCell>
    <StyledTableCell isSortedColumn={sortedColumn === 'queriesIndirect'}>
      <CommaSeparate value={stats.queriesIndirect} />
    </StyledTableCell>
    <StyledTableCell isSortedColumn={sortedColumn === 'queriesChain'}>
      <CommaSeparate value={stats.queriesChain} />
    </StyledTableCell>
    <StyledTableCell isSortedColumn={sortedColumn === 'actionCount'}>
      <CommaSeparate value={stats.actionCount} />
    </StyledTableCell>
    <StyledTableCell isSortedColumn={sortedColumn === 'actionRate'}>
      {(stats.actionRate * 100).toFixed(2)}%
    </StyledTableCell>
    <StyledTableCell isSortedColumn={sortedColumn === 'reviewCount'}>
      <CommaSeparate value={stats.reviewCount} />
    </StyledTableCell>
  </>
);

/** 比較期間なしのStatsの行 */
const SingleStatsRow: React.FC<{
  item: InsightTableItem;
  sortedColumn: SortType;
  onClickAddStoreButton: () => void;
}> = ({ item, sortedColumn, onClickAddStoreButton }) => {
  return (
    <StyledRow>
      <StoreNameCell>{item.store.shortName}</StoreNameCell>
      <StoreNameCell>{item.store.code}</StoreNameCell>
      <StatsCells stats={item.stats} sortedColumn={sortedColumn} />
      <TableCell>
        <ButtonWrapper>
          <StyledButton onClick={onClickAddStoreButton}>他店舗と比較</StyledButton>
        </ButtonWrapper>
      </TableCell>
    </StyledRow>
  );
};

/** 比較期間ありのStatsの行 */
const ComparisonStatsRow: React.FC<{
  period: string;
  comparisonPeriod: string;
  item: ComparisonInsightTableItem;
  sortedColumn: SortType;
  onClickAddStoreButton: () => void;
}> = ({ period, comparisonPeriod, item, sortedColumn, onClickAddStoreButton }) => {
  return (
    <>
      <StoreHeaderRow>
        <StoreNameCell>{item.store?.shortName}</StoreNameCell>
        <StoreNameCell>{item.store?.code}</StoreNameCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'searchCount'} />
        <StyledTableCell isSortedColumn={sortedColumn === 'queriesDirect'} />
        <StyledTableCell isSortedColumn={sortedColumn === 'queriesIndirect'} />
        <StyledTableCell isSortedColumn={sortedColumn === 'queriesChain'} />
        <StyledTableCell isSortedColumn={sortedColumn === 'actionCount'} />
        <StyledTableCell isSortedColumn={sortedColumn === 'actionRate'} />
        <StyledTableCell isSortedColumn={sortedColumn === 'reviewCount'} />
        <TableCell rowSpan='4' style={{ verticalAlign: 'middle' }}>
          <ButtonWrapper>
            <StyledButton onClick={onClickAddStoreButton}>他店舗と比較</StyledButton>
          </ButtonWrapper>
        </TableCell>
      </StoreHeaderRow>
      <NarrowRow>
        <PeriodCell colSpan='2'>{period}</PeriodCell>
        <StatsCells stats={item.stats} sortedColumn={sortedColumn} />
      </NarrowRow>
      <NarrowRow>
        <PeriodCell colSpan='2'>{comparisonPeriod}</PeriodCell>
        <StatsCells stats={item.comparisonStats} sortedColumn={sortedColumn} />
      </NarrowRow>
      <NarrowRow>
        <TableCell colSpan='2'>変化率</TableCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'searchCount'}>
          <Rate value={item.diffRate.searchCount} />
        </StyledTableCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'queriesDirect'}>
          <Rate value={item.diffRate.queriesDirect} />
        </StyledTableCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'queriesIndirect'}>
          <Rate value={item.diffRate.queriesIndirect} />
        </StyledTableCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'queriesChain'}>
          <Rate value={item.diffRate.queriesChain} />
        </StyledTableCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'actionCount'}>
          <Rate value={item.diffRate.actionCount} />
        </StyledTableCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'actionRate'}>
          <Rate value={item.diffRate.actionRate} />
        </StyledTableCell>
        <StyledTableCell isSortedColumn={sortedColumn === 'reviewCount'}>
          <Rate value={item.diffRate.reviewCount} />
        </StyledTableCell>
      </NarrowRow>
    </>
  );
};

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    padding: 3px 8px;
    font-size: 10px;
    background-color: ${COLOR.GREEN};
    color: ${COLOR.WHITE};
  }
`;

const StyledRow = styled(TableRow)`
  ${StyledButton} {
    display: none;
  }

  &:hover {
    background: #f2f2f2;

    ${StyledButton} {
      display: inline-block;
    }
  }
`;

const ClickableTableHeaderCell = styled(TableHeaderCell)<{ isSortedColumn: boolean }>`
  &&& {
    cursor: pointer;
    background: ${({ isSortedColumn = false }) => (isSortedColumn ? '#ebeced' : 'inherit')};
    min-width: 100px;
    &:hover {
      background: #ebeced;
    }
  }
`;

const NarrowRow = styled(StyledRow)`
  &&& {
    min-height: 23px;

    ${TableCell} {
      padding-top: 0;
      padding-bottom: 0;

      border-top-style: dashed;
      border-top-color: rgba(0.34, 0.36, 0.38, 0.1);
    }
  }
`;

const StoreHeaderRow = styled(NarrowRow)`
  &&& {
    ${TableCell} {
      border-top-style: solid;
      border-top-width: 3px;
    }

    &:first-child {
      ${TableCell} {
        border-top-width: 2px;
      }
    }
  }
`;

const StyledTableHeaderLabel = styled(TableHeaderLabel)`
  font-size: 12px;
`;

const StoreNameCell = styled(TableCell)`
  &&& {
    font-weight: bold;
  }
`;

const PeriodCell = styled(TableCell)`
  &&& {
    color: ${COLOR.DARK_GRAY};
  }
`;

const StyledTableCell = styled(TableCell)<{ isSortedColumn: boolean }>`
  &&& {
    background: ${({ isSortedColumn = false }) => (isSortedColumn ? '#f2f2f2' : 'inherit')};
    font-weight: ${({ isSortedColumn = false }) => (isSortedColumn ? 'bold' : 'inherit')};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TriangleWrapper = styled.div`
  width: 10px;
  margin-right: 8px;
`;

const Triangle = styled.div<{ isSortOrderDesc: boolean }>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${(props) => (props.isSortOrderDesc ? '5px 5px 0 5px' : '0px 5px 5px 5px')};
  border-color: ${(props) =>
    props.isSortOrderDesc
      ? `${COLOR.GREEN} transparent transparent transparent`
      : `transparent transparent ${COLOR.GREEN} transparent`};
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  min-width: 100px;
`;

const Rate = styled.span<{ value: number | null }>`
  color: ${({ value }) => (!value ? COLOR.DARK_GRAY : value >= 0 ? COLOR.GREEN : COLOR.RED)};
  &:before {
    content: '${({ value }) => (value === null ? '' : value === 0 ? '→' : value > 0 ? '↑' : '↓')}';
    margin-right: 4px;
  }
  &:after {
    content: '${({ value }) => (value === null ? '-' : `${(Math.abs(value) * 100).toFixed(1)}%`)}';
  }
`;
