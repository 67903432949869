import actionCreatorFactory from 'typescript-fsa';

import { InventoryCsvImportStatusCheckApiResponse } from 'ApiClient/OmoApi';
import { InventoryCsvDownloadFilter } from 'models/Domain/Omo/InventoryCsvDownloadFilter';

import { ImportStatus } from './type';

const actionCreator = actionCreatorFactory('InventoriesImport');

type ImportInventoriesPayload = {
  file: File;
  validateOnly: boolean;
};

/**
 * 「店頭在庫をまとめて更新」のアクション
 */
export const InventoriesImportActions = {
  initializeInventoriesImportPage: actionCreator<string>('initializeInventoriesImportPage'),

  // CSVダウンロード設定
  setInventoryCsvDownloadFilter: actionCreator<InventoryCsvDownloadFilter>('setInventoryCsvDownloadFilter'),
  setDownloadExecutionArn: actionCreator<string | undefined>('setDownloadExecutionArn'),

  // CSVダウンロード
  downloadInventoriesCsv: actionCreator('downloadInventoriesCsv'),
  checkInventoryCsvDownloadStatus: actionCreator('checkInventoryCsvDownloadStatus'),
  clearCsvDownload: actionCreator('clearCsvDownload'),

  // CSVインポート（バリデーション）関連
  clearStatus: actionCreator('clearStatus'),
  clearValidateResult: actionCreator('clearValidateResult'),
  setValidateResult: actionCreator<InventoryCsvImportStatusCheckApiResponse>('setValidateResult'),

  // CSVインポート（変更内容を反映）関連
  importInventories: actionCreator<ImportInventoriesPayload>('importInventories'),
  checkCsvImportStatus: actionCreator<{ validateOnly: boolean }>('checkCsvImportStatus'),
  setExecutionArn: actionCreator<string>('setExecutionArn'),
  startImport: actionCreator<string>('startImport'),
  finishImport: actionCreator('finishImport'),
  setImportStatus: actionCreator<{ importStatus: ImportStatus }>('setImportResult'),
  setIsProcessing: actionCreator<boolean>('setIsProcessing'),
};
