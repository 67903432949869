import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { MapSearchRanksCompetitorsParams } from 'ApiClient/GmbApi';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';
import { JSObject } from 'types/Common';

import { Stores } from '../Store';

export type AggregateUnit = 'day' | 'week' | 'month';

export const MAX_RANK = 60; // 圏外は60位として扱う

// 初期値は集計単位「週」期間「3ヶ月」前日の2ヶ月前の1日から前日まで
const DEFAULT_AGGREGATE_UNIT = 'week';
const maxDate = dayjs().subtract(1, 'day').startOf('day');
export const DEFAULT_START_DATE = maxDate.subtract(2, 'month').startOf('month');
export const DEFAULT_END_DATE = maxDate;
// 比較期間は「前の期間」DEFAULT_START_DATEの3ヶ月前から前日まで
export const DEFAULT_COMPARISON_START_DATE = DEFAULT_START_DATE.subtract(3, 'month');
export const DEFAULT_COMPARISON_END_DATE = DEFAULT_START_DATE.subtract(1, 'day');

export class FilterStatus extends Record<{
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  configId: number;
  isEnabledComparison: boolean;
  containsAll: boolean;
}>({
  aggregateUnit: DEFAULT_AGGREGATE_UNIT,
  startDate: DEFAULT_START_DATE,
  endDate: DEFAULT_END_DATE,
  comparisonStartDate: DEFAULT_COMPARISON_START_DATE,
  comparisonEndDate: DEFAULT_COMPARISON_END_DATE,
  configId: 0,
  isEnabledComparison: false,
  containsAll: false,
}) {
  validate() {
    const error: JSObject = {};
    if (!this.configId) {
      error.name = '取得対象が選択されていません';
    }
    if (this.isEnabledComparison) {
      if (!this.comparisonStartDate) {
        error.name = '比較期間の開始日が選択されていません';
      } else if (!this.comparisonEndDate) {
        error.name = '比較期間の終了日が選択されていません';
      } else if (this.comparisonEndDate.isBefore(this.comparisonStartDate)) {
        error.name = '比較期間の開始日は比較期間の終了日より前を選択してください';
      }
    }
    return error;
  }
}

export class MapSearchRankCompetitorSearchCondition extends Record<{
  filter: FilterStatus;
}>({
  filter: new FilterStatus(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankCompetitorSearchCondition({
      filter: new FilterStatus({
        aggregateUnit: data.aggregateUnit,
        startDate: data.startDate,
        endDate: data.endDate,
        comparisonStartDate: data.comparisonStartDate,
        comparisonEndDate: data.comparisonEndDate,
        configId: data.configId,
        isEnabledComparison: data.isEnabledComparison,
        containsAll: data.containsAll,
      }),
    });
  }

  toRequestParams(): MapSearchRanksCompetitorsParams {
    const params: MapSearchRanksCompetitorsParams = {
      aggregate_unit: this.filter.aggregateUnit,
      start_date: this.filter.startDate.format('YYYY-MM-DD'),
      end_date: this.filter.endDate.format('YYYY-MM-DD'),
      config_id: this.filter.configId,
      contains_all: this.filter.containsAll ? '1' : '0',
    };
    if (this.filter.isEnabledComparison && this.filter.comparisonStartDate && this.filter.comparisonEndDate) {
      params.comparison_start_date = this.filter.comparisonStartDate.format('YYYY-MM-DD');
      params.comparison_end_date = this.filter.comparisonEndDate.format('YYYY-MM-DD');
    }
    return params;
  }
}

export class MapSearchRankCompetitorTableData extends Record<{ items: List<MapSearchRankCompetitorTableItem> }>({
  items: List(),
}) {
  static fromJSON(items: JSObject[]): MapSearchRankCompetitorTableData {
    return new MapSearchRankCompetitorTableData({
      items: List(items.map((item) => MapSearchRankCompetitorTableItem.fromJSON(item))),
    });
  }
}

export class MapSearchRankCompetitorTableItem extends Record<{
  configId: number;
  placeId: string;
  storeName: string;
  areaName: string;
  searchWord: string;
  rank: number | null;
  rankHasData: boolean;
  rankComparison: number | null;
  rankComparisonHasData: boolean;
  diff: number | null;
  diffComparison: number | null;
  isCompetitor: boolean;
  latestRank: number | null;
}>({
  configId: 0,
  placeId: '',
  storeName: '',
  areaName: '',
  searchWord: '',
  rank: null,
  rankHasData: false,
  rankComparison: null,
  rankComparisonHasData: false,
  diff: null,
  diffComparison: null,
  isCompetitor: true,
  latestRank: null,
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorTableItem {
    return new MapSearchRankCompetitorTableItem({
      configId: data.config_id,
      placeId: data.place_id,
      storeName: data.store_name,
      areaName: data.area_name,
      searchWord: data.search_word,
      rank: data.rank,
      rankHasData: data.rank_has_data == null ? data.rank != null : data.rank_has_data,
      rankComparison: data.rank_comparison ?? null,
      rankComparisonHasData:
        data.rank_comparison_has_data == null ? data.rank_comparison != null : data.rank_comparison_has_data,
      diff: data.diff,
      diffComparison: data.diff_comparison ?? null,
      isCompetitor: data.is_competitor,
      latestRank: data.latest_rank,
    });
  }

  /**
   * ランキング圏外か（データ欠落ではなくrankがnull）
   */
  isRankUnranked() {
    return this.rankHasData && this.rank == null;
  }

  /**
   * 比較のランキング圏外か（データ欠落ではなくrankがnull）
   */
  isRankComparisonUnranked() {
    return this.rankComparisonHasData && this.rankComparison == null;
  }

  /**
   * PlaceIDをもとにした店舗のGoogleマップでのURL
   */
  get mapUrl(): string {
    return `https://www.google.com/maps/place/?q=place_id:${this.placeId}`;
  }
}

export class MapSearchRankCompetitorGraphItemStats extends Record<{
  startDate: Dayjs;
  endDate: Dayjs;
  rank: number | null; // 圏外または欠損の場合はnull
  hasData: boolean; // データが存在しているか
}>({
  startDate: dayjs(),
  endDate: dayjs(),
  rank: null,
  hasData: false,
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorGraphItemStats {
    return new MapSearchRankCompetitorGraphItemStats({
      startDate: dayjs(data.period.start_date),
      endDate: dayjs(data.period.end_date),
      rank: data.rank,
      hasData: data.has_data == null ? data.rank != null : data.has_data,
    });
  }

  /**
   * ランキング圏外か（データ欠落ではなくrankがnull）
   */
  isUnranked() {
    return this.hasData && this.rank == null;
  }
}

export class MapSearchRankCompetitorGraphItem extends Record<{
  placeId: string;
  configId: number;
  storeId: number;
  storeName: string;
  searchWord: string;
  areaName: string;
  stats: List<MapSearchRankCompetitorGraphItemStats>;
}>({
  placeId: '',
  configId: 0,
  storeId: 0,
  storeName: '',
  searchWord: '',
  areaName: '',
  stats: List(),
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorGraphItem {
    return new MapSearchRankCompetitorGraphItem({
      placeId: data.place_id,
      configId: data.config_id,
      storeId: data.store_id,
      storeName: data.store_name,
      searchWord: data.search_word,
      areaName: data.area_name,
      stats: List(data.stats.map((s: JSObject) => MapSearchRankCompetitorGraphItemStats.fromJSON(s))),
    });
  }

  get key(): string {
    return this.placeId;
  }
}

export class MapSearchRankCompetitorGraphData extends Record<{
  items: List<MapSearchRankCompetitorGraphItem>;
  comparisonItems: List<MapSearchRankCompetitorGraphItem>;
}>({
  items: List(),
  comparisonItems: List(),
}) {
  static fromJSON(items: JSObject[], comparisonItems: JSObject[]): MapSearchRankCompetitorGraphData {
    return new MapSearchRankCompetitorGraphData({
      items: List(items.map((item: JSObject) => MapSearchRankCompetitorGraphItem.fromJSON(item))),
      comparisonItems: List(
        (comparisonItems || []).map((item: JSObject) => MapSearchRankCompetitorGraphItem.fromJSON(item)),
      ),
    });
  }
}

export class MapSearchRankCompetitorData extends Record<{
  tableData: MapSearchRankCompetitorTableData;
  graphData: MapSearchRankCompetitorGraphData;
  comparisonGraphData: MapSearchRankCompetitorGraphData;
}>({
  tableData: new MapSearchRankCompetitorTableData(),
  graphData: new MapSearchRankCompetitorGraphData(),
  comparisonGraphData: new MapSearchRankCompetitorGraphData(),
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorData {
    return new MapSearchRankCompetitorData({
      tableData: MapSearchRankCompetitorTableData.fromJSON(data.table_items),
      graphData: MapSearchRankCompetitorGraphData.fromJSON(data.graph_items, data.comparison_graph_items),
    });
  }

  storeColors(stores: Stores, /** 選択している店舗のプレイスID */ selectedStoreList: List<string | null>) {
    const result: { [key: string]: string } = {};
    selectedStoreList.forEach((placeId, index) => {
      if (placeId === null) {
        return;
      }
      // 自店舗の場合は、緑色
      if (stores.findStoreByPlaceId(placeId)) {
        result[placeId] = COLOR.GREEN;
      } else {
        result[placeId] = UNIVERSAL_COLORS.get(index % UNIVERSAL_COLORS.size) ?? COLOR.GRAY;
        index += 1;
      }
    });
    return result;
  }
}
