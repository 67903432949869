import React from 'react';

import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { StickyFooter } from 'components/atoms/StickyFooter';
import { COLOR } from 'style/color';

type ComponentProps = {
  selectedSize: number;
  connectedSize: number;
  onCancel: () => void;
  onUpload: () => void;
  onDelete: () => void;
};

export const SelectImageListFooter = React.memo<ComponentProps>(
  ({ selectedSize, connectedSize, onCancel, onUpload, onDelete }) => {
    return (
      <StickyFooter>
        <ChildWrapper>
          <CloseIcon type='cancel' onClick={onCancel} />
          <CheckedImageListLabel>
            {' '}
            {selectedSize}枚<PcLabel>の画像</PcLabel>を選択<PcLabel>しています</PcLabel>
          </CheckedImageListLabel>
        </ChildWrapper>
        <GmbConnectionButtonWrapper>
          <ButtonWrapper>
            <StyledButton priority='high' onClick={onUpload} disabled={connectedSize === selectedSize}>
              GBPに掲載する
            </StyledButton>
            {connectedSize === selectedSize && (
              <ErrorMessage>選択した写真はすでにGoogleビジネスプロフィールに掲載済みです</ErrorMessage>
            )}
          </ButtonWrapper>
          <ButtonWrapper>
            <StyledButton priority='high' onClick={onDelete} disabled={connectedSize === 0} negative={true}>
              GBPから削除する
            </StyledButton>
            {connectedSize === 0 && (
              <ErrorMessage>選択した写真はGoogleビジネスプロフィールに掲載されていません</ErrorMessage>
            )}
          </ButtonWrapper>
        </GmbConnectionButtonWrapper>
      </StickyFooter>
    );
  },
);

const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

const CheckedImageListLabel = styled.div`
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  float: right;
  font-weight: bold;
  cursor: pointer;

  &&& {
    width: 200px;
  }

  @media (max-width: 600px) {
    &&& {
      width: 169px;
      height: 41px;
      padding: 0;
      font-size: 11px;
    }
  }
`;

const PcLabel = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const GmbConnectionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ErrorMessage = styled(Label).attrs(() => ({
  color: 'white',
  pointing: 'below',
}))`
  &&& {
    display: none;
    position: absolute;
    top: -60px;
    left: 2px;
    width: 242px;

    background: ${COLOR.WHITE};
    border: 1px solid ${COLOR.ERROR};
    color: ${COLOR.ERROR};

    ::before {
      left: 7%;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  :hover {
    ${ErrorMessage} {
      @media (min-width: 600px) {
        display: block;
      }
    }
  }

  margin: 4px 0px 4px 8px;

  @media (max-width: 600px) {
    &&& {
      width: 169px;
      height: 41px;
      padding: 0;
      font-size: 11px;
      margin: 4px 0px;
    }
  }
`;
