import dayjs, { Dayjs } from 'dayjs';
import { Record, Set, is } from 'immutable';

import {
  SearchKeywordsCSVCreateParams,
  SearchKeywordsGraphParams,
  SearchKeywordsKeywordGraphParams,
  SearchKeywordsKeywordTableParams,
  SearchKeywordsTableParams,
} from 'ApiClient/GmbApi/SearchKeywordApi';
import { booleanToSearchParam, numberComparator, parseBooleanParam, parseNumberParameter } from 'helpers/search';
import { Group } from 'types/Common';

export type SearchKeywordGraphType = 'search_count' | 'keyword_count';

export const getDefaultMonth = (now = dayjs()) => {
  // ある月のデータを取得できるするのは翌月7,10日の12時(JST)なので、確実に取得ができるよに
  // 1~10日は2ヶ月前まで、11日〜31日は1ヶ月前までの12ヶ月間をデフォルトの集計期間とする
  // 例：現在が2022年6月の場合
  // 6月01日〜10日は、start_month:2021-04 end_month: 2022-04 target_month: 2022-04
  // 6月11日〜30日は、start_month:2021-05 end_month: 2022-05 target_month: 2022-05

  const format = 'YYYY-MM';
  const monthPeriod = 12;
  if (now.date() <= 10) {
    return {
      startMonth: now.subtract(monthPeriod + 2, 'month').format(format),
      endMonth: now.subtract(2, 'month').format(format),
      targetMonth: now.subtract(2, 'month').format(format),
    };
  } else {
    return {
      startMonth: now.subtract(monthPeriod + 1, 'month').format(format),
      endMonth: now.subtract(1, 'month').format(format),
      targetMonth: now.subtract(1, 'month').format(format),
    };
  }
};

const DEFAULT_TYPE: SearchKeywordGraphType = 'search_count';
const defaultMonth = getDefaultMonth();
const DEFAULT_START_MONTH = defaultMonth.startMonth;
const DEFAULT_END_MONTH = defaultMonth.endMonth;
const DEFAULT_TARGET_MONTH = defaultMonth.targetMonth;
const DEFAULT_GROUP = null;
const DEFAULT_IS_ALL_STORE_IDS = true;
const DEFAULT_STORE_IDS = Set<number>();
const DEFAULT_SHOW_CLOSED_STORE = false;
const DEFAULT_FILTER_WORDS = Set<string>();
const DEFAULT_IS_EXACT_MATCH_FILTER_WORDS = false;
const DEFAULT_EXCLUDE_WORDS = Set<string>();
const DEFAULT_IS_EXACT_MATCH_EXCLUDE_WORDS = false;
const DEFAULT_EXCLUDE_LESS_THAN_COUNT = true;
const DEFAULT_CONTAINS_EXCLUDED = false;

export type FilterStatusType = {
  type: SearchKeywordGraphType;
  startMonth: string;
  endMonth: string;
  targetMonth: string;
  group: Group;
  storeIds: Set<number>;
  isAllStoreIds: boolean;
  showClosedStore: boolean;
  filterWords: Set<string>;
  filterWordsExactMatch: boolean;
  excludeWords: Set<string>;
  excludeWordsExactMatch: boolean;
  excludeLessThanCount: boolean;
  containsExcluded: boolean;
};

export class FilterStatus extends Record<FilterStatusType>({
  type: DEFAULT_TYPE,
  startMonth: DEFAULT_START_MONTH,
  endMonth: DEFAULT_END_MONTH,
  targetMonth: DEFAULT_TARGET_MONTH,
  group: DEFAULT_GROUP,
  storeIds: DEFAULT_STORE_IDS,
  isAllStoreIds: DEFAULT_IS_ALL_STORE_IDS,
  showClosedStore: DEFAULT_SHOW_CLOSED_STORE,
  filterWords: DEFAULT_FILTER_WORDS,
  filterWordsExactMatch: DEFAULT_IS_EXACT_MATCH_FILTER_WORDS,
  excludeWords: DEFAULT_EXCLUDE_WORDS,
  excludeWordsExactMatch: DEFAULT_IS_EXACT_MATCH_EXCLUDE_WORDS,
  excludeLessThanCount: DEFAULT_EXCLUDE_LESS_THAN_COUNT,
  containsExcluded: DEFAULT_CONTAINS_EXCLUDED,
}) {
  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({ storeIds, isAllStoreIds });
  }
}

export type SortKey = 'avg' | 'count' | 'mom_ratio' | 'mom_diff' | 'three_mom_ratio' | 'yoy_ratio';
export type SortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY: SortKey = 'avg';
const DEFAULT_SORT_ORDER: SortOrder = 'desc';

export type SortStatusType = {
  key: SortKey;
  order: SortOrder;
};

export class SortStatus extends Record<SortStatusType>({
  key: DEFAULT_SORT_KEY,
  order: DEFAULT_SORT_ORDER,
}) {
  updateBySortKey(sortKey: SortKey) {
    const { key: currentSortKey, order: currentSortOrder } = this;
    // sortKeyが既に設定されている値であれば、並び順の昇順/降順を変更する
    if (sortKey === currentSortKey) {
      return this.set('order', currentSortOrder === 'desc' ? 'asc' : 'desc');
    } else {
      return this.set('key', sortKey);
    }
  }
}

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 100;

export type PaginationType = {
  page: number;
  limit: number;
};

export class Pagination extends Record<PaginationType>({
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
}) {}

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType | keyof SortStatusType | keyof PaginationType, 'isAllStoreIds'>]: string;
} = {
  type: 'gt',
  startMonth: 'sm',
  endMonth: 'em',
  targetMonth: 'tm',
  group: 'st',
  storeIds: 'si',
  showClosedStore: 'cs',
  filterWords: 'fw',
  filterWordsExactMatch: 'fwm',
  excludeWords: 'ew',
  excludeWordsExactMatch: 'ewm',
  excludeLessThanCount: 'el',
  containsExcluded: 'ce',
  key: 'sk',
  order: 'so',
  page: 'pg',
  limit: 'li',
};

const isMatchMonthFormat = (str: string): boolean => {
  const regExp = new RegExp(/^\d{4}-\d{2}$/);
  return regExp.test(str);
};

export class SearchKeywordSearchCondition extends Record<{
  filter: FilterStatus;
  sort: SortStatus;
  pagination: Pagination;
}>({
  filter: new FilterStatus(),
  sort: new SortStatus(),
  pagination: new Pagination(),
}) {
  /**
   * ページのURLパラメータから条件を生成する
   * @param search URLパラメータ
   * @returns 検索条件
   */
  static fromURLSearchParams(search: string, date: Dayjs = dayjs()): SearchKeywordSearchCondition {
    const condition = new SearchKeywordSearchCondition();
    let { filter, sort, pagination } = condition;
    const params = new URLSearchParams(search);

    const {
      startMonth: defaultStartMonth,
      endMonth: defaultEndMonth,
      targetMonth: defaultTargetMonth,
    } = getDefaultMonth(date);

    // フィルタ関連
    const type = params.get(URLSearchParamsMapping.type) ?? DEFAULT_TYPE;
    if (['search_count', 'keyword_count'].includes(type)) {
      filter = filter.set('type', type as SearchKeywordGraphType);
    }

    const startMonth = params.get(URLSearchParamsMapping.startMonth);
    if (startMonth && isMatchMonthFormat(startMonth)) {
      filter = filter.set('startMonth', startMonth);
    } else {
      filter = filter.set('startMonth', defaultStartMonth);
    }

    const endMonth = params.get(URLSearchParamsMapping.endMonth);
    if (endMonth && isMatchMonthFormat(endMonth)) {
      filter = filter.set('endMonth', endMonth);
    } else {
      filter = filter.set('endMonth', defaultEndMonth);
    }

    const targetMonth = params.get(URLSearchParamsMapping.targetMonth);
    if (targetMonth && isMatchMonthFormat(targetMonth)) {
      filter = filter.set('targetMonth', targetMonth);
    } else {
      filter = filter.set('targetMonth', defaultTargetMonth);
    }

    const group = params.get(URLSearchParamsMapping.group);
    if (group) {
      if (group === 'all' || group === 'my_store') {
        // すべての店舗の場合
        filter = filter.set('group', group);
      } else if (group.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('group', parseInt(group, 10));
      }
    }

    const storeIds = params.get(URLSearchParamsMapping.storeIds) || 'all';
    if (storeIds === 'all') {
      filter = filter.setStoreIds(Set<number>(), true);
    } else {
      const values = storeIds
        .split(',')
        .filter((v) => v.match(/^\d+$/))
        .map((v) => parseInt(v, 10));
      filter = filter.setStoreIds(Set(values), false);
    }

    filter = filter.set(
      'showClosedStore',
      parseBooleanParam(params.get(URLSearchParamsMapping.showClosedStore), DEFAULT_SHOW_CLOSED_STORE),
    );

    const filterWords = params.get(URLSearchParamsMapping.filterWords);
    if (filterWords) {
      filter = filter.set('filterWords', Set(filterWords.split(',')));
    }

    filter = filter.set(
      'filterWordsExactMatch',
      params.get(URLSearchParamsMapping.filterWordsExactMatch) === '1' ? true : false,
    );
    filter = filter.set(
      'excludeWordsExactMatch',
      params.get(URLSearchParamsMapping.excludeWordsExactMatch) === '1' ? true : false,
    );

    const excludeWords = params.get(URLSearchParamsMapping.excludeWords);
    if (excludeWords) {
      filter = filter.set('excludeWords', Set(excludeWords.split(',')));
    }

    filter = filter.set(
      'excludeLessThanCount',
      parseBooleanParam(params.get(URLSearchParamsMapping.excludeLessThanCount), DEFAULT_EXCLUDE_LESS_THAN_COUNT),
    );

    filter = filter.set(
      'containsExcluded',
      parseBooleanParam(params.get(URLSearchParamsMapping.containsExcluded), DEFAULT_CONTAINS_EXCLUDED),
    );

    // ソート関連
    const sortKey = params.get(URLSearchParamsMapping.key) ?? '';
    if (['avg', 'count', 'mom_ratio', 'mom_diff', 'three_mom_ratio', 'yoy_ratio'].includes(sortKey)) {
      sort = sort.set('key', sortKey as SortKey);
    }

    const sortOrder = params.get(URLSearchParamsMapping.order) ?? '';
    if (['asc', 'desc'].includes(sortOrder)) {
      sort = sort.set('order', sortOrder as SortOrder);
    }

    // ページネーション関連
    pagination = pagination.set('page', parseNumberParameter(params.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1));
    pagination = pagination.set(
      'limit',
      parseNumberParameter(params.get(URLSearchParamsMapping.limit), DEFAULT_LIMIT, 1, 100),
    );

    return condition.merge({ filter, sort, pagination });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();
    // フィルタ関連
    if (this.filter.type) {
      params.append(URLSearchParamsMapping.type, this.filter.type);
    }
    if (this.filter.startMonth) {
      params.append(URLSearchParamsMapping.startMonth, this.filter.startMonth);
    }
    if (this.filter.endMonth) {
      params.append(URLSearchParamsMapping.endMonth, this.filter.endMonth);
    }
    if (this.filter.targetMonth) {
      params.append(URLSearchParamsMapping.targetMonth, this.filter.targetMonth);
    }
    if (this.filter.group != DEFAULT_GROUP) {
      params.append(URLSearchParamsMapping.group, String(this.filter.group));
    }
    if (!this.filter.isAllStoreIds && this.filter.storeIds.size > 0) {
      params.append(URLSearchParamsMapping.storeIds, this.filter.storeIds.toArray().sort(numberComparator).join(','));
    }
    if (this.filter.showClosedStore != DEFAULT_SHOW_CLOSED_STORE) {
      params.append(URLSearchParamsMapping.showClosedStore, booleanToSearchParam(this.filter.showClosedStore));
    }
    if (!is(this.filter.filterWords, DEFAULT_FILTER_WORDS)) {
      params.append(URLSearchParamsMapping.filterWords, this.filter.filterWords.join(','));
    }
    if (!is(this.filter.excludeWords, DEFAULT_EXCLUDE_WORDS)) {
      params.append(URLSearchParamsMapping.excludeWords, this.filter.excludeWords.join(','));
    }
    if (this.filter.filterWordsExactMatch !== DEFAULT_IS_EXACT_MATCH_FILTER_WORDS) {
      params.append(URLSearchParamsMapping.filterWordsExactMatch, this.filter.filterWordsExactMatch ? '1' : '0');
    }
    if (this.filter.excludeLessThanCount != DEFAULT_EXCLUDE_LESS_THAN_COUNT) {
      params.append(
        URLSearchParamsMapping.excludeLessThanCount,
        booleanToSearchParam(this.filter.excludeLessThanCount),
      );
    }
    if (this.filter.excludeWordsExactMatch !== DEFAULT_IS_EXACT_MATCH_EXCLUDE_WORDS) {
      params.append(URLSearchParamsMapping.excludeWordsExactMatch, this.filter.excludeWordsExactMatch ? '1' : '0');
    }
    if (this.filter.containsExcluded != DEFAULT_CONTAINS_EXCLUDED) {
      params.append(URLSearchParamsMapping.containsExcluded, booleanToSearchParam(this.filter.containsExcluded));
    }

    // ソート関連
    if (this.sort.key !== DEFAULT_SORT_KEY) {
      params.append(URLSearchParamsMapping.key, this.sort.key);
    }
    if (this.sort.order !== DEFAULT_SORT_ORDER) {
      params.append(URLSearchParamsMapping.order, this.sort.order);
    }

    // ページネーション関連
    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }
    if (this.pagination.limit !== DEFAULT_LIMIT) {
      params.append(URLSearchParamsMapping.limit, String(this.pagination.limit));
    }

    // 見栄え悪いので、'%2Cは','に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  toGraphDataRequestParams(): SearchKeywordsGraphParams {
    const params: SearchKeywordsGraphParams = {
      type: this.filter.type,
      start_month: this.filter.startMonth,
      end_month: this.filter.endMonth,
      contains_excluded: booleanToSearchParam(this.filter.containsExcluded),
    };

    if (this.filter.storeIds.size > 0) {
      params['store_ids'] = this.filter.storeIds.join(',');
    }
    if (this.filter.filterWords.size > 0) {
      params['filter_words'] = this.filter.filterWords.join(',');
      params['filter_words_exact_match'] = this.filter.filterWordsExactMatch ? '1' : '0';
    }
    if (this.filter.excludeWords.size > 0) {
      params['exclude_words'] = this.filter.excludeWords.join(',');
      params['exclude_words_exact_match'] = this.filter.excludeWordsExactMatch ? '1' : '0';
    }
    return params;
  }

  /**
   * 指定キーワードの店舗別の検索数のグラフデータ取得APIのリクエストパラメータを返す
   * @param word 指定キーワード
   * @returns
   */
  toKeywordGraphDataRequestParams(word?: string): SearchKeywordsKeywordGraphParams {
    const params: SearchKeywordsKeywordGraphParams = {
      start_month: this.filter.startMonth,
      end_month: this.filter.endMonth,
      contains_excluded: booleanToSearchParam(this.filter.containsExcluded),
    };

    if (this.filter.storeIds.size > 0) {
      params['store_ids'] = this.filter.storeIds.join(',');
    }

    if (word) {
      params['match_filter_words'] = word;
    } else {
      if (this.filter.filterWords.size > 0) {
        const key = this.filter.filterWordsExactMatch ? 'match_filter_words' : 'filter_words';
        params[key] = this.filter.filterWords.join(',');
      }
      if (this.filter.excludeWords.size > 0) {
        const key = this.filter.excludeWordsExactMatch ? 'match_exclude_words' : 'exclude_words';
        params[key] = this.filter.excludeWords.join(',');
      }
    }

    return params;
  }

  toTableDataRequestParams(): SearchKeywordsTableParams {
    const params: SearchKeywordsTableParams = {
      start_month: this.filter.startMonth,
      end_month: this.filter.endMonth,
      target_month: this.filter.targetMonth,
      exclude_less_than_count: booleanToSearchParam(this.filter.excludeLessThanCount),
      contains_excluded: booleanToSearchParam(this.filter.containsExcluded),
      sort_key: this.sort.key,
      sort_order: this.sort.order,
      limit: this.pagination.limit,
      page: this.pagination.page,
    };

    if (this.filter.storeIds.size > 0) {
      params['store_ids'] = this.filter.storeIds.join(',');
    }
    if (this.filter.filterWords.size > 0) {
      params['filter_words'] = this.filter.filterWords.join(',');
      params['filter_words_exact_match'] = this.filter.filterWordsExactMatch ? '1' : '0';
    }
    if (this.filter.excludeWords.size > 0) {
      params['exclude_words'] = this.filter.excludeWords.join(',');
      params['exclude_words_exact_match'] = this.filter.excludeWordsExactMatch ? '1' : '0';
    }
    return params;
  }

  /**
   * 指定キーワードの店舗別の検索数のテーブルデータ取得APIのリクエストパラメータを返す
   * @param word 指定キーワード
   * @returns
   */
  toKeywordTableDataRequestParams(word?: string): SearchKeywordsKeywordTableParams {
    const params: SearchKeywordsKeywordTableParams = {
      start_month: this.filter.startMonth,
      end_month: this.filter.endMonth,
      target_month: this.filter.targetMonth,
      exclude_less_than_count: booleanToSearchParam(this.filter.excludeLessThanCount),
      contains_excluded: booleanToSearchParam(this.filter.containsExcluded),
      sort_key: this.sort.key,
      sort_order: this.sort.order,
      limit: this.pagination.limit,
      page: this.pagination.page,
    };

    if (this.filter.storeIds.size > 0) {
      params['store_ids'] = this.filter.storeIds.join(',');
    }

    if (word) {
      params['match_filter_words'] = word;
    } else {
      if (this.filter.filterWords.size > 0) {
        const key = this.filter.filterWordsExactMatch ? 'match_filter_words' : 'filter_words';
        params[key] = this.filter.filterWords.join(',');
      }
      if (this.filter.excludeWords.size > 0) {
        const key = this.filter.excludeWordsExactMatch ? 'match_exclude_words' : 'exclude_words';
        params[key] = this.filter.excludeWords.join(',');
      }
    }

    return params;
  }

  toCSVCreateRequestParams(): SearchKeywordsCSVCreateParams {
    const filterWords = this.filter.filterWords.size > 0 ? this.filter.filterWords.toArray() : null;
    const excludeWords = this.filter.excludeWords.size > 0 ? this.filter.excludeWords.toArray() : null;

    const params: SearchKeywordsCSVCreateParams = {
      start_month: this.filter.startMonth,
      end_month: this.filter.endMonth,
      store_ids: this.filter.storeIds.toArray(),
      filter_words: this.filter.filterWordsExactMatch ? null : filterWords,
      match_filter_words: this.filter.filterWordsExactMatch ? filterWords : null,
      exclude_words: this.filter.excludeWordsExactMatch ? null : excludeWords,
      match_exclude_words: this.filter.excludeWordsExactMatch ? excludeWords : null,
      exclude_less_than_count: this.filter.excludeLessThanCount,
      contains_excluded: this.filter.containsExcluded,
    };

    return params;
  }
}
