import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Store, Stores } from 'models/Domain/Store';
import { StoreSearchCondition } from 'models/Domain/StoreSearchCondition';
import { BulkEditStoreState } from 'models/Other/BulkEditStoreState';

import { StoreActions } from './actions';

export class StoreState extends Record<{
  stores: Stores;
  registerStore: Store;
  bulkEditStoreState: BulkEditStoreState;
  searchCondition: StoreSearchCondition;
  isPreparedForStorePage: boolean;
}>({
  stores: new Stores(),
  registerStore: new Store(),
  bulkEditStoreState: new BulkEditStoreState(),
  searchCondition: new StoreSearchCondition(),
  isPreparedForStorePage: false,
}) {}

export const storeReducer = reducerWithInitialState(new StoreState())
  .case(StoreActions.setStores, (state, data) => {
    const stores = new Stores(data, true);
    return state.set('stores', stores);
  })
  .case(StoreActions.changeRegisterStore, (state, registerStore) => {
    return state.set('registerStore', registerStore);
  })
  .case(StoreActions.clearRegisterStore, (state) => {
    return state.set('registerStore', new Store());
  })
  .case(StoreActions.setBulkEditStoreState, (state, payload) => {
    return state.set('bulkEditStoreState', payload);
  })
  .case(StoreActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(StoreActions.setIsPreparedForStorePage, (state, payload) => {
    return state.set('isPreparedForStorePage', payload);
  });
