import * as holiday from '@holiday-jp/holiday_jp';
import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import {
  getCurrentMonthEndDay,
  getCurrentMonthStartDay,
  getCurrentWeekEndDay,
  getCurrentWeekStartDay,
} from 'helpers/date';
import { MemoList } from 'models/Domain/Memo/Memo';
import { AggregateUnit, SelectOption, assertNever } from 'types/Common';

import { nonNullable } from './utils';

type ComparisonDataKeyType = 'camel' | 'snake';

// 対象期間向けdataKey
export type ComparisonDataKey<T extends string, U extends ComparisonDataKeyType = 'camel'> = U extends 'camel'
  ? `comparison${Capitalize<T>}`
  : `comparison_${T}`;

// 集計期間または対象期間のdataKey(2つ目の引数でcamelかsnakeかを指定可能)
export type DataKey<T extends string, U extends ComparisonDataKeyType = 'camel'> = T | ComparisonDataKey<T, U>;
// 比較期間のグラフのタイプ
export type ComparisonGraphType = 'separated' | 'combined' | 'none';

export const getComparisonGraphOptions = (disableCombined: boolean): SelectOption<ComparisonGraphType>[] => {
  return disableCombined
    ? [
        { text: '表示する', value: 'separated' },
        { text: '表示しない', value: 'none' },
      ]
    : [
        { text: '並べて表示する', value: 'combined', disabled: disableCombined },
        { text: '重ねて表示する', value: 'separated' },
        { text: '表示しない', value: 'none' },
      ];
};

/**
 * 日付と集計期間からX軸およびグラフホバー時に表示する日付を返す
 * @param {string} date 日付
 * @param {AggregateUnit} aggregateUnit 集計期間
 * @returns 表示用の文字列
 */
export const convertDateLabel = (date: Dayjs, aggregateUnit: AggregateUnit): string => {
  switch (aggregateUnit) {
    case 'day':
      return date.format('YYYY年M月D日');
    case 'week':
      // 指定された日付の週の月曜日を取得
      return getCurrentWeekStartDay(date).format('YYYY年M月D日週');
    case 'month':
      // 指定された日付の月の1日を取得
      return getCurrentMonthStartDay(date).format('YYYY年M月');
    default: {
      return assertNever(aggregateUnit);
    }
  }
};

/**
 * 指定されたdateLabelに曜日を追加する）
 * @param dateLabel
 */
export const getDateLabelWithWeekOfDay = (dateLabel: string): string => {
  // dateLabelの日付のフォーマットでなければ、そのまま返す
  if (!dateLabel || !dateLabel.match(/^\d{4}年([0-9]|1[012])月([1-9]|[12]\d|3[01])日$/)) {
    return dateLabel;
  }
  // dateLabelは文字列なので、一旦日付に変換してからフォーマットし直す
  const date = dayjs(dateLabel, 'YYYY年M月D日');
  const isHoliday = holiday.isHoliday(date.toDate());
  return date.format(`YYYY年M月D日(ddd${isHoliday ? '祝' : ''})`);
};

export const getDateRangeFromDateLabel = (dateLabel: string, aggregateUnit: AggregateUnit): [Dayjs, Dayjs] => {
  switch (aggregateUnit) {
    case 'day': {
      const date = dayjs(dateLabel, 'YYYY年M月D日');
      if (!date.isValid()) {
        throw new Error(`"${dateLabel}" is unexpected date`);
      }
      return [date, date];
    }
    case 'week': {
      const date = dayjs(dateLabel, 'YYYY年M月D日週');
      if (!date.isValid()) {
        throw new Error(`"${dateLabel}" is unexpected date`);
      }
      return [getCurrentWeekStartDay(date), getCurrentWeekEndDay(date)];
    }
    case 'month': {
      const date = dayjs(dateLabel, 'YYYY年M月');
      if (!date.isValid()) {
        throw new Error(`"${dateLabel}" is unexpected date`);
      }
      return [getCurrentMonthStartDay(date), getCurrentMonthEndDay(date)];
    }

    default:
      return assertNever(aggregateUnit);
  }
};

/**
 * 指定された期間と集計単位からデータのサイズを取得する
 * @param startDate
 * @param endDate
 * @param aggregateUnit
 */
export const getDataSize = (startDate: Dayjs, endDate: Dayjs, aggregateUnit: AggregateUnit): number => {
  switch (aggregateUnit) {
    case 'day':
      // startDateからendDateまでの日数
      return endDate.diff(startDate, 'day') + 1;
    case 'week':
      // startDateを含む週からendDateを含む週までの週数
      return getCurrentWeekStartDay(endDate).diff(getCurrentWeekStartDay(startDate), 'week') + 1;
    case 'month':
      // startDateを含む月からendDateを含む月までの月数
      return getCurrentMonthStartDay(endDate).diff(getCurrentMonthStartDay(startDate), 'month') + 1;
    default:
      return assertNever(aggregateUnit);
  }
};

/**
 * 指定された日付と集計単位から、集計期間となる日付を指定個数分返す
 * @param startDate
 * @param aggregateUnit
 * @param size
 */
export const getAggregateStartDates = (startDate: Dayjs, aggregateUnit: AggregateUnit, size: number): Dayjs[] => {
  let firstDate: Dayjs;
  switch (aggregateUnit) {
    case 'day':
      firstDate = startDate;
      break;
    case 'week':
      // 指定された日付の週の月曜日を取得
      firstDate = getCurrentWeekStartDay(startDate);
      break;
    case 'month':
      // 指定された日付の月の1日を取得
      firstDate = getCurrentMonthStartDay(startDate);
      break;
    default:
      return assertNever(aggregateUnit);
  }
  return [...Array(size)].map((_, index) => firstDate.add(index, aggregateUnit));
};

/**
 * 指定された期間と集計単位から、グラフに表示する日付を返す
 * @param startDate
 * @param endDate
 * @param comparisonStartDate
 * @param comparisonEndDate
 * @param aggregateUnit
 * @param isCombined
 * @param isEnabledComparison
 */
export const getDisplayDates = (
  startDate: Dayjs,
  endDate: Dayjs,
  comparisonStartDate: Dayjs,
  comparisonEndDate: Dayjs,
  aggregateUnit: AggregateUnit,
  isCombined: boolean,
  isEnabledComparison: boolean,
): { targetDates: Dayjs[]; comparisonDates: Dayjs[] } => {
  let dataSize: number;
  let targetDates: Dayjs[];
  let comparisonDates: Dayjs[];
  if (isCombined) {
    // 集計期間と比較期間を合わせて一番早い日から一番遅い日までのデータを生成する
    const firstDate =
      isEnabledComparison && comparisonStartDate.isSameOrBefore(startDate) ? comparisonStartDate : startDate;
    const lastDate = isEnabledComparison && comparisonEndDate.isAfter(endDate) ? comparisonEndDate : endDate;
    dataSize = getDataSize(firstDate, lastDate, aggregateUnit);
    targetDates = getAggregateStartDates(firstDate, aggregateUnit, dataSize);
    comparisonDates = targetDates;
  } else {
    // 集計期間と比較期間の長さが違う場合は、期間が長い方に合わせてデータを生成する
    const targetDataSize = getDataSize(startDate, endDate, aggregateUnit);
    const comparisonDataSize = getDataSize(comparisonStartDate, comparisonEndDate, aggregateUnit);
    dataSize = isEnabledComparison ? Math.max(targetDataSize, comparisonDataSize) : targetDataSize;
    targetDates = getAggregateStartDates(startDate, aggregateUnit, dataSize);
    comparisonDates = getAggregateStartDates(comparisonStartDate, aggregateUnit, dataSize);
  }
  return { targetDates, comparisonDates };
};

/**
 * 比較期間向けのdataKeyか
 * @param dataKey
 */
export const isComparisonDataKey = <T extends string, U extends ComparisonDataKeyType = 'camel'>(
  dataKey: DataKey<T, U>,
): dataKey is ComparisonDataKey<T, U> => {
  return dataKey.startsWith('comparison');
};

/**
 * 集計期間のデータ用のdataKeyを取得する
 * @param dataKey
 */
export const getTargetDataKey = <T extends string, U extends ComparisonDataKeyType = 'camel'>(
  dataKey: DataKey<T, U>,
): T => {
  if (isComparisonDataKey(dataKey)) {
    // dataKeyからcomparisonを除外し、先頭を小文字にする
    // キャメルケース、スネークケースのどちらにも対応
    let key = dataKey.replace(/^comparison_?/, '');
    key = key.charAt(0).toLowerCase() + key.slice(1);
    return key as T;
  } else {
    // comparisonからはじまらなければそのまま返す
    return dataKey;
  }
};

/**
 * 比較期間のデータ用のdataKeyを取得する
 * @param dataKey
 */
export const getComparisonDataKey = <T extends string, U extends ComparisonDataKeyType = 'camel'>(
  dataKey: DataKey<T, U>,
  comparisonDataKeyType: ComparisonDataKeyType = 'camel',
): ComparisonDataKey<T, U> => {
  if (isComparisonDataKey(dataKey)) {
    // comparisonからはじまればそのまま返す
    return dataKey;
  } else {
    if (comparisonDataKeyType === 'camel') {
      // dataKeyの先頭を大文字にして、comparisonを追加する
      const key = 'comparison' + dataKey.charAt(0).toUpperCase() + dataKey.slice(1);
      return key as ComparisonDataKey<T, U>;
    }
    const key = 'comparison_' + dataKey;
    return key as ComparisonDataKey<T, U>;
  }
};

/**
 * パーセント表記のテキストを返す
 * @param value
 * @param fractionDigits
 */
export const getRateText = (value: number | null | undefined, fractionDigits = 2) =>
  value != null ? `${(value * 100).toFixed(fractionDigits)}%` : 'ー';

/**
 * 3桁ごとにカンマ区切りのテキストを返す
 * @param value
 * @param fractionDigits
 */
export const getValueText = (value: number | null | undefined, fractionDigits = 0) =>
  value != null
    ? `${value.toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })}`
    : 'ー';

/**
 * 金額のテキストを返す
 * @param value
 * @param fractionDigits
 * @param currency
 */
export const getCurrencyText = (value: number | undefined | null, fractionDigits = 0, currency = '¥') => {
  return value != null
    ? `${currency} ${value.toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })}`
    : 'ー';
};

export type MemoIconData = { ids: number[]; date: string; contents: string };

export type DisplayToGraphOption = 'PERIOD' | 'START_DAY_ONLY' | 'HIDE';

// メモの表示設定をLocalStorageに保存するときの型
export type MemoDisplaySettingsObject = {
  // タグによる絞り込み
  tags: string[];
  // グラフへの表示
  displayToGraph: DisplayToGraphOption;
};
export const MEMO_DISPLAY_SETTING_DEFAULT: MemoDisplaySettingsObject = {
  tags: [],
  displayToGraph: 'START_DAY_ONLY',
};

export class MemoDisplaySettings extends ImmutableRecord<{
  tags: ImmutableList<string>;
  displayToGraph: DisplayToGraphOption;
}>({
  tags: ImmutableList(),
  displayToGraph: 'START_DAY_ONLY',
}) {
  static fromObject(obj: MemoDisplaySettingsObject) {
    return new MemoDisplaySettings({
      tags: ImmutableList(obj.tags),
      displayToGraph: obj.displayToGraph,
    });
  }

  get toSaveParams(): MemoDisplaySettingsObject {
    return {
      tags: this.tags.toArray(),
      displayToGraph: this.displayToGraph,
    };
  }
}

export const getMemoData = (
  memoList: MemoList,
  startDate: Dayjs,
  endDate: Dayjs,
  aggregateUnit: AggregateUnit,
  option: DisplayToGraphOption,
  maxRows = 5,
): MemoIconData[] => {
  // 表示しないオプションなら、空データを返す
  if (option === 'HIDE') {
    return [];
  }

  // 集計期間に開始日/終了日を含む日付のリスト。開始日のみ表示する場合は、終了日は含めない
  const startDates = memoList.items.map((item) => item.startDate).filter(nonNullable);
  const endDates = memoList.items.map((item) => item.endDate).filter(nonNullable);
  const dates = option === 'START_DAY_ONLY' ? startDates : startDates.concat(endDates);
  // 開始日が古い順にソートして、集計単位に合わせたテキストに変換する
  const dateLabels = dates
    .filter((date: Dayjs) => date.isBetween(startDate, endDate, 'day', '[]'))
    .sortBy((item) => item.toDate())
    .map((date) => convertDateLabel(date, aggregateUnit))
    .toOrderedSet();
  return dateLabels
    .map((dateLabel) => {
      // optionが"PERIOD"の場合は、startDateまたはendDateが一致するメモを取得する
      // optionが"START_DAY_ONLY"の場合は、startDateが一致するメモのみを取得する
      // memoListはstartDateが新しい順なので、startDateが古い順にしてから絞り込む
      const filteredMemoList = memoList.items
        .reverse()
        .filter(
          (item) =>
            (item.startDate ? dateLabel === convertDateLabel(item.startDate, aggregateUnit) : false) ||
            (option === 'PERIOD' && item.endDate ? dateLabel === convertDateLabel(item.endDate, aggregateUnit) : false),
        );
      const contents = filteredMemoList.map((item) => {
        const startDateLabel = item.startDate ? convertDateLabel(item.startDate, aggregateUnit) : '';
        const endDateLabel = item.endDate ? convertDateLabel(item.endDate, aggregateUnit) : '';
        // startのラベルが一致し、endのラベルが一致しない場合は、その期間に開始して別の期間に終了したデータ
        const isStartOnly = dateLabel === startDateLabel && endDateLabel !== '' && dateLabel !== endDateLabel;
        // endのラベルが一致し、startのラベルが一致しない場合は、別の期間に開始してその期間に終了したデータ
        const isEndOnly = startDateLabel !== '' && dateLabel !== startDateLabel && dateLabel === endDateLabel;
        // 開始のみの場合はタイトルの後に「（開始）」、終了のみの場合はタイトルの後に「（終了）」、そうでない場合はタイトルのみ
        return `${item.title}${option === 'PERIOD' && isStartOnly ? '（開始）' : isEndOnly ? '（終了）' : ''}`;
      });

      // 最大行数がmaxRowsになるように表示する
      // maxRowsが5の場合は、
      // 1〜5件の場合は、すべて表示する（[1,2,3,4,5]）
      // 6件以上の場合は、4件目まで表示し、5件目以降の件数を「他◯件」として表示する（[1,2,3,4,他◯件]）
      const displayContents =
        contents.size <= maxRows ? contents : contents.slice(0, maxRows - 1).push(`他${contents.size - maxRows + 1}件`);

      return {
        ids: filteredMemoList.map((item) => item.id).toArray(),
        date: dateLabel,
        contents: displayContents.join('\n'),
      };
    })
    .toArray();
};
