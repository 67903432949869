import React from 'react';

import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { Linkify } from 'components/atoms/Linkify';
import { createResizedImageURL } from 'components/atoms/ResizeImage';
import { NameLabel } from 'components/molecules/NameLabel';
import { OfferTypeIcon } from 'components/molecules/OfferTypeIcon';
import { OfferItem } from 'models/Composite/OfferItem';
import { FileContent } from 'models/Domain/FileContent';
import { Stores } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const OfferDetailContent: React.FC<{
  className?: string;
  offerItem: OfferItem;
  stores: Stores;
  currentUser: User;
  onClickReadCount: () => void;
  openImageViewerModal: (imageIndex: number) => void;
}> = ({ className, offerItem, stores, onClickReadCount, openImageViewerModal, currentUser }) => {
  const { offer, user, other_store_offers, readers, targetContent } = offerItem;

  const {
    name,
    dueDateString,
    content: { text, files },
  } = targetContent;

  const pdfFiles = files.list.filter((file) => file.isPDF);
  const imageFiles = files.list.filter((file) => !file.isPDF);
  // PDF -> 画像の順に並べ替えられたものを表示に使用
  const displayingFiles = pdfFiles.concat(imageFiles);

  const onClick = (index: number, file: FileContent) => {
    if (file.isPDF) {
      window.open(file.url);
      return;
    }
    const clickedImageIndex = index - pdfFiles.size;
    openImageViewerModal(clickedImageIndex);
  };

  const readersWithoutTarget = readers.getUserswithoutTarget(currentUser);

  // タスクの場合だけ、タスクへのリンクを表示する
  const taskLink = offerItem.task.id ? Path.task.detail.replace(/:taskId\([^)]*\)/g, String(offerItem.task.id)) : null;

  return (
    <Wrapper>
      <CardWrapper className={className}>
        <TopContent>
          <OfferName>
            <OfferNameIcon>
              <OfferTypeIcon offer_type={offer.offer_type} />
            </OfferNameIcon>
            <OfferNameText>{name}</OfferNameText>
          </OfferName>
          <OfferStatus>
            <NameLabel profileImageUrl={user.profile_image_url} userName={user.fullName} />
            <OfferCreateDate>{offer.createDateString}</OfferCreateDate>
          </OfferStatus>
          <OfferText>
            <Linkify>{text}</Linkify>
          </OfferText>
          <OfferStatus>
            {taskLink && <TaskLink to={taskLink}>計{other_store_offers.list.size + 1}店舗</TaskLink>}
            <OfferDueDate>期限：{dueDateString}</OfferDueDate>
          </OfferStatus>
          {currentUser.isSameUser(user) && (
            <EditOffer>
              <Link to={Path.offer.edit.replace(':offerId', String(offer.id))}>編集する</Link>
            </EditOffer>
          )}
        </TopContent>

        {!displayingFiles.isEmpty() && (
          <FileListWrapper>
            {displayingFiles.map((file, i) => (
              <FileWrapper key={i}>
                <FileViewWrapper onClick={() => onClick(i, file)}>
                  <FileObject data={createResizedImageURL(file.url, 200)} />
                  {file.isPDF && <PDFLabel>PDF</PDFLabel>}
                  <FileObjectOverlay />
                </FileViewWrapper>
              </FileWrapper>
            ))}
          </FileListWrapper>
        )}
      </CardWrapper>

      {!readersWithoutTarget.list.isEmpty() && (
        <ReadCountWrapper>
          <ReadCount onClick={() => onClickReadCount()}>既読 {readersWithoutTarget.list.size}</ReadCount>
        </ReadCountWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const CardWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  font-size: 14px;
`;

const TopContent = styled.div`
  padding: 18px;
`;

const OfferName = styled.div`
  font-size: 18px;
  font-weight: bold;
  background-color: ${COLOR.WHITE};
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const OfferNameIcon = styled.div``;

const OfferNameText = styled.div`
  margin-left: 6px;
`;

const OfferText = styled.p`
  margin-top: 16px;
`;

const OfferStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditOffer = styled.div`
  text-align: right;
  text-decoration: underline;
`;

const OfferCreateDate = styled.div`
  font-weight: bold;
`;

const OfferDueDate = styled.div`
  font-weight: bold;
`;

const FileViewWrapper = styled.div`
  position: relative;
`;

const FileObject = styled.object`
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-color: ${COLOR.LIGHT_GRAY};
`;

const PDFLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: bold;
  color: ${COLOR.WHITE};
  padding: 0 10px;
  background-color: ${COLOR.BLACK};
`;

const FileObjectOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const FileListWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px;
`;

const FileWrapper = styled.div`
  margin: 5px;
`;

const ReadCountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
`;

const ReadCount = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: ${COLOR.DARK_GRAY};
`;

const TaskLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
`;
