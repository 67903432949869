import ApiClient, { FaasApiClient } from 'ApiClient';
import { SortKey, SortOrder } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';

const apiClient = new ApiClient({});
const faasApiClient = new FaasApiClient({});

const GMB_PATH = '/gmb';

const GMB_SEARCH_KEYWORDS_GRAPH_PATH = `${GMB_PATH}/search_keywords/graph`;
const GMB_SEARCH_KEYWORDS_TABLE_PATH = `${GMB_PATH}/search_keywords/table`;
const GMB_SEARCH_KEYWORDS_CSV_PATH = `${GMB_PATH}/search_keywords/csv`;
const GMB_SEARCH_KEYWORDS_CSV_CREATE_PATH = `${GMB_SEARCH_KEYWORDS_CSV_PATH}/create`;
const GMB_SEARCH_KEYWORDS_CSV_STATUS_PATH = `${GMB_SEARCH_KEYWORDS_CSV_PATH}/status`;

const GMB_EXCLUDED_SEARCH_KEYWORDS_PATH = `${GMB_PATH}/excluded_keywords`;

const GMB_SEARCH_KEYWORDS_KEYWORD_GRAPH_PATH = `${GMB_SEARCH_KEYWORDS_GRAPH_PATH}/keyword`;
const GMB_SEARCH_KEYWORDS_KEYWORD_TABLE_PATH = `${GMB_SEARCH_KEYWORDS_TABLE_PATH}/keyword`;

/**
 * 検索キーワード分析画面のグラフ部分のデータを取得するAPI
 */
export class SearchKeywordsGraphApi {
  static get(params: SearchKeywordsGraphParams, ignoreCache = false) {
    return apiClient.get<SearchKeywordsGraphResponse>(GMB_SEARCH_KEYWORDS_GRAPH_PATH, params, { cache: !ignoreCache });
  }
}

/**
 * 検索キーワード分析画面のグラフ部分のデータを取得するAPIのパラメータ
 */
export type SearchKeywordsGraphParams = {
  type: 'search_count' | 'keyword_count';
  start_month: string;
  end_month: string;
  store_ids?: string;
  filter_words?: string;
  filter_words_exact_match?: '0' | '1';
  exclude_words?: string;
  exclude_words_exact_match?: '0' | '1';
  contains_excluded?: '0' | '1';
};

/**
 * 検索キーワード分析画面のグラフ部分のデータを取得するAPIのレスポンス
 */
export type SearchKeywordsGraphResponse = {
  values: {
    period: string;
    value: number;
  }[];
};

/**
 * 検索キーワード分析画面の表部分のデータを取得するAPIのパラメータ
 */
export type SearchKeywordsTableParams = {
  start_month: string;
  end_month: string;
  target_month: string;
  store_ids?: string;
  filter_words?: string;
  filter_words_exact_match?: '0' | '1';
  exclude_words?: string;
  exclude_words_exact_match?: '0' | '1';
  exclude_less_than_count?: '0' | '1';
  contains_excluded?: '0' | '1';
  sort_key?: SortKey;
  sort_order?: SortOrder;
  limit?: number;
  page?: number;
};

/**
 * 検索キーワード分析画面の表部分のデータを取得するAPIのレスポンス
 */
export type SearchKeywordsTableResponse = {
  start_month: string;
  end_month: string;
  target_month: string;
  summary: {
    monthly_average_count: number | null;
    target_month_count: number | null;
    mom_ratio: number | null;
    mom_diff: number | null;
    three_mom_ratio: number | null;
    yoy_ratio: number | null;
  };
  items: {
    keyword: string;
    value: {
      monthly_average_count: number | null;
      target_month_count: number | null;
      mom_ratio: number | null;
      mom_diff: number | null;
      three_mom_ratio: number | null;
      yoy_ratio: number | null;
    };
  }[];
  pagination: {
    current_page: number;
    has_next: boolean;
    total_count: number;
    limit: number;
  };
};

/**
 * 検索キーワード分析画面の表部分のデータを取得するAPI
 */
export class SearchKeywordsTableApi {
  static get(params: SearchKeywordsTableParams, ignoreCache = false) {
    return apiClient.get<SearchKeywordsTableResponse>(GMB_SEARCH_KEYWORDS_TABLE_PATH, params, { cache: !ignoreCache });
  }
}

type GetExcludedKeywordsResponse = {
  items: {
    id: number;
    organization_id: number;
    keyword: string;
    exact_match: boolean;
  }[];
};

/**
 * 常に適用中の除外キーワードを取得するAPI
 * https://pathee.esa.io/posts/4625
 */
export class ExcludedKeywordsApi {
  static get() {
    return apiClient.get<GetExcludedKeywordsResponse>(GMB_EXCLUDED_SEARCH_KEYWORDS_PATH);
  }

  static post(keyword: string, exactMatch: boolean) {
    const params = { keyword, exact_match: exactMatch };
    return apiClient.post(GMB_EXCLUDED_SEARCH_KEYWORDS_PATH, params);
  }

  static delete(id: number) {
    const urlPath = `${GMB_EXCLUDED_SEARCH_KEYWORDS_PATH}/${id}`;
    return apiClient.delete(urlPath);
  }
}

/**
 * 検索キーワードCSVを作成するAPIのパラメータ
 */
export type SearchKeywordsCSVCreateParams = {
  start_month: string;
  end_month: string;
  store_ids: number[];
  filter_words: string[] | null;
  match_filter_words: string[] | null;
  exclude_words: string[] | null;
  match_exclude_words: string[] | null;
  exclude_less_than_count: boolean;
  contains_excluded: boolean;
};

/**
 * GBPインサイトのCSV作成APIのレスポンス
 */
type SearchKeywordsCSVCreateResponse = {
  executionArn: string;
};

export class SearchKeywordsCSVCreateApi {
  static post(params: SearchKeywordsCSVCreateParams) {
    return faasApiClient.post<SearchKeywordsCSVCreateResponse>(GMB_SEARCH_KEYWORDS_CSV_CREATE_PATH, params);
  }
}

export type SearchKeywordsCSVStatusParams = {
  executionArn: string;
};

/**
 * GBPインサイトのCSVダウンロードAPIのレスポンス
 */
type SearchKeywordsCSVStatusResponse = {
  status: string;
  download_url: string;
  message: string;
};

export class SearchKeywordsCSVStatusApi {
  static post(params: SearchKeywordsCSVStatusParams) {
    return faasApiClient.post<SearchKeywordsCSVStatusResponse>(GMB_SEARCH_KEYWORDS_CSV_STATUS_PATH, params);
  }
}

/** 店舗ごとのキーワード流入数のグラフデータを取得するAPIのパラメータ */
export type SearchKeywordsKeywordGraphParams = {
  start_month: string;
  end_month: string;
  store_ids?: string;
  filter_words?: string;
  match_filter_words?: string;
  exclude_words?: string;
  match_exclude_words?: string;
  contains_excluded?: '0' | '1';
};

/** 店舗ごとのキーワード流入数のグラフデータを取得するAPIのレスポンス */
export type SearchKeywordsKeywordGraphResponse = {
  summary: {
    period: string;
    value: number;
  }[];
  items: {
    store_id: number;
    values: {
      period: string;
      value: number;
    }[];
  }[];
};

/** 店舗ごとのキーワード流入数のグラフデータを取得するAPI */
export class SearchKeywordsKeywordGraphApi {
  static get(params: SearchKeywordsKeywordGraphParams, ignoreCache = false) {
    return apiClient.get<SearchKeywordsKeywordGraphResponse>(GMB_SEARCH_KEYWORDS_KEYWORD_GRAPH_PATH, params, {
      cache: !ignoreCache,
    });
  }
}

/** 店舗ごとのキーワード流入数のテーブルデータを取得するAPIのパラメータ */
export type SearchKeywordsKeywordTableParams = {
  start_month: string;
  end_month: string;
  target_month: string;
  store_ids?: string;
  filter_words?: string;
  match_filter_words?: string;
  exclude_words?: string;
  match_exclude_words?: string;
  exclude_less_than_count?: '0' | '1';
  contains_excluded?: '0' | '1';
  sort_key?: SortKey;
  sort_order?: SortOrder;
  limit?: number;
  page?: number;
};

/** 店舗ごとのキーワード流入数のテーブルデータを取得するAPIのレスポンス */
export type SearchKeywordsKeywordTableResponse = {
  start_month: string;
  end_month: string;
  target_month: string;
  summary: {
    monthly_average_count: number | null;
    target_month_count: number | null;
    mom_ratio: number | null;
    mom_diff: number | null;
    three_mom_ratio: number | null;
    yoy_ratio: number | null;
  };
  items: {
    store_id: number;
    value: {
      monthly_average_count: number | null;
      target_month_count: number | null;
      monthly_counts: {
        period: string;
        value: number;
      }[];
      mom_ratio: number | null;
      mom_diff: number | null;
      three_mom_ratio: number | null;
      yoy_ratio: number | null;
    };
  }[];
  pagination: {
    current_page: number;
    has_next: boolean;
    total_count: number;
    limit: number;
  };
};

/** 店舗ごとのキーワード流入数のテーブルデータを取得するAPI */
export class SearchKeywordsKeywordTableApi {
  static get(params: SearchKeywordsKeywordTableParams, ignoreCache = false) {
    return apiClient.get<SearchKeywordsKeywordTableResponse>(GMB_SEARCH_KEYWORDS_KEYWORD_TABLE_PATH, params, {
      cache: !ignoreCache,
    });
  }
}
