import React, { useCallback } from 'react';

import { Set as ImmutableSet } from 'immutable';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import { MemoIconData, getDateLabelWithWeekOfDay, getValueText } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { PRODUCT_STATUSES, ProductStatusStatus } from 'models/Domain/OmoInsight';
import { ProductStatusGraphItem } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { COLOR } from 'style/color';

import { PRODUCT_STATUS_COLORS, PRODUCT_STATUS_LABELS } from '../../index';

const DEFAULT_GRAPH_HEIGHT = 240;

type Props = {
  className?: string;
  height?: number;
  data: ProductStatusGraphItem[];
  statuses: ProductStatusStatus[];
  graphSettings?: GraphSettings;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
  showLegend: boolean;
};

export const ProductStatusGraph: React.FC<Props> = ({
  data,
  height = DEFAULT_GRAPH_HEIGHT,
  statuses,
  graphSettings = new GraphSettings(),
  memoData,
  onClickMemo,
  showLegend,
}) => {
  // 比較期間なしの場合に表示するツールチップ
  const renderTooltip = useCallback((data: TooltipProps<number, string>) => {
    const items = data.payload ?? [];
    return (
      <ChartTooltip>
        <ChartTooltip.Title>{data.label}</ChartTooltip.Title>
        {PRODUCT_STATUSES.map((status) => {
          const item = items.find((i) => i.dataKey === status);
          if (item === undefined) {
            return <React.Fragment key={status} />;
          }
          const itemColor = PRODUCT_STATUS_COLORS[status];
          const textColor = COLOR.BLACK;
          return (
            <ChartTooltip.Item key={status} style={{ lineHeight: 1.2 }}>
              <ChartTooltip.Label color={itemColor}>{item.name}</ChartTooltip.Label>
              <ChartTooltip.Value color={textColor}>
                {item.value != null ? item.value.toLocaleString() : 'ー'}
              </ChartTooltip.Value>
            </ChartTooltip.Item>
          );
        })}
      </ChartTooltip>
    );
  }, []);

  // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
  let displayedMemoDate = ImmutableSet<string>();

  return (
    <ResponsiveContainer height={height}>
      <AreaChart
        data={data}
        margin={{ top: showLegend ? 64 : 24, right: 32, left: 32, bottom: 16 }}
        syncId={'ProductStatus'}
      >
        {showLegend && (
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            content={(props: any) => renderLegend({ ...props, reverse: true })}
            onClick={(o: any) => console.log(o)}
          />
        )}
        <XAxis dataKey='date' />
        <YAxis domain={graphSettings.domain} allowDataOverflow={true} tickFormatter={(value) => getValueText(value)} />
        {/* メモアイコン用のダミーY軸 */}
        <YAxis yAxisId={'memo'} hide={true} />
        <CartesianGrid strokeDasharray='3 3' />
        <Tooltip wrapperStyle={{ zIndex: 10 }} content={renderTooltip} filterNull={false} isAnimationActive={false} />
        {[...PRODUCT_STATUSES].reverse().map((status) => {
          if (!statuses.includes(status)) {
            return <React.Fragment key={status} />;
          }
          return (
            <Area
              key={status}
              name={PRODUCT_STATUS_LABELS[status]}
              dataKey={status}
              stroke={PRODUCT_STATUS_COLORS[status]}
              fillOpacity={1}
              fill={PRODUCT_STATUS_COLORS[status]}
              stackId={1}
              isAnimationActive={false}
            />
          );
        })}

        {memoData?.map((item) => {
          // すでにメモを表示している日付ならスキップ
          if (displayedMemoDate.has(item.date)) {
            return null;
          }
          // メモを表示している日付リストに追加
          displayedMemoDate = displayedMemoDate.add(item.date);
          return (
            <ReferenceLine
              key={item.date}
              x={item.date}
              yAxisId={'memo'}
              stroke={'#9994'}
              label={(props) =>
                MemoIcon({
                  ...props,
                  title: getDateLabelWithWeekOfDay(item.date),
                  content: item.contents,
                  onClick: () => onClickMemo && onClickMemo(item.ids),
                }) as any
              }
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};
