import { List, Map } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { Inventory } from 'models/Domain/Omo/Inventory';
import { InventorySummary } from 'models/Domain/Omo/InventorySummary';
import { ProductGroups } from 'models/Domain/Omo/Product';
import { ProductSearchCondition } from 'models/Domain/Omo/ProductSearchCondition';
import { StoreSearchCondition } from 'models/Domain/Omo/StoreSearchCondition';

const actionCreator = actionCreatorFactory('Inventory');

export const InventoryActions = {
  initializeInventoryStoreList: actionCreator('initializeInventoryStoreList'),
  initializeInventoryList: actionCreator<number>('initializeInventoryList'),
  fetchInventorySummaryList: actionCreator('fetchInventorySummaryList'),
  fetchProducts: actionCreator<number>('fetchProducts'),
  fetchInventories: actionCreator('fetchInventories'),
  setIsLoadingInventorySummary: actionCreator<boolean>('setIsLoadingInventorySummary'),
  setIsLoadingProducts: actionCreator<boolean>('setIsLoadingProducts'),
  setInventorySummary: actionCreator<InventorySummary>('setInventorySummary'),
  setInventorySummaryList: actionCreator<List<InventorySummary>>('setInventorySummaryList'),
  setStoreSearchCondition: actionCreator<{ searchCondition: StoreSearchCondition; updateLocation?: boolean }>(
    'setStoreSearchCondition',
  ),
  setCommittedStoreSearchCondition: actionCreator<StoreSearchCondition>('setCommittedStoreSearchCondition'),
  setProductSearchCondition: actionCreator<{ searchCondition: ProductSearchCondition; updateLocation?: boolean }>(
    'setProductSearchCondition',
  ),
  setCommittedProductSearchCondition: actionCreator<ProductSearchCondition>('setCommittedProductSearchCondition'),
  setProductGroups: actionCreator<ProductGroups>('setProductGroups'),
  setCommittedProductGroups: actionCreator<ProductGroups>('setCommittedProductGroups'),
  setEditedInventories: actionCreator<Map<string, Inventory>>('setEditedProductIds'),
  updateInventories: actionCreator<number>('updateInventories'),
  bulkUpdateInventories: actionCreator<number>('bulkUpdateInventories'),
  changeInventory: actionCreator<{ groupIndex: number; productIndex: number; storeId: number; inventory: Inventory }>(
    'changeInventory',
  ),
  addEditedInventory: actionCreator<Inventory>('addEditedInventory'),
  removeEditedInventory: actionCreator<Inventory>('removeEditedInventory'),
  setIsPreparedForInventoryListPage: actionCreator<boolean>('setIsPreparedForInventoryListPage'),
  setIsUpdateExpirationExecuted: actionCreator<boolean>('setIsUpdateExpirationExecuted'),
};
