import { List as ImmutableList, Record as ImmutableRecord, is } from 'immutable';

export const STRUCTURED_SERVICE_GROUP_KEY = '[structured]';

export class GmbMoney extends ImmutableRecord<{
  currencyCode: string;
  units: string;
}>({
  currencyCode: '',
  units: '',
}) {
  static fromJSON(data: any = {}) {
    return new GmbMoney({
      currencyCode: data.currencyCode,
      units: data.units,
    });
  }

  get label() {
    if (this.currencyCode) {
      if (this.units) {
        if (this.currencyCode === 'JPY') {
          return `${this.units}円`;
        }
        return `${this.units} ${this.currencyCode}`;
      } else {
        return '無料';
      }
    }
    return 'なし';
  }
}

export class GmbLabel extends ImmutableRecord<{
  displayName: string;
  description?: string;
  languageCode?: string;
}>({
  displayName: '',
  description: undefined,
  languageCode: undefined,
}) {
  static fromJSON(data: any = {}) {
    return new GmbLabel({
      displayName: data.displayName,
      description: data.description,
      languageCode: data.languageCode,
    });
  }
}

export class GmbStructuredServiceItem extends ImmutableRecord<{
  serviceTypeId: string;
  description?: string;
}>({
  serviceTypeId: '',
  description: undefined,
}) {
  static fromJSON(data: any = {}) {
    return new GmbStructuredServiceItem({
      serviceTypeId: data.serviceTypeId,
      description: data.description,
    });
  }
}

export class GmbFreeFormServiceItem extends ImmutableRecord<{
  category: string;
  label?: GmbLabel;
}>({
  category: '',
  label: new GmbLabel(),
}) {
  static fromJSON(data: any = {}) {
    return new GmbFreeFormServiceItem({
      category: data.category,
      label: data.label ? GmbLabel.fromJSON(data.label) : undefined,
    });
  }
}

export class GmbServiceItem extends ImmutableRecord<{
  price?: GmbMoney;
  structuredServiceItem?: GmbStructuredServiceItem;
  freeFormServiceItem?: GmbFreeFormServiceItem;
}>({
  price: undefined,
  structuredServiceItem: undefined,
  freeFormServiceItem: undefined,
}) {
  static fromJSON(data: any = {}) {
    return new GmbServiceItem({
      price: data.price ? GmbMoney.fromJSON(data.price) : undefined,
      structuredServiceItem: data.structuredServiceItem
        ? GmbStructuredServiceItem.fromJSON(data.structuredServiceItem)
        : undefined,
      freeFormServiceItem: data.freeFormServiceItem
        ? GmbFreeFormServiceItem.fromJSON(data.freeFormServiceItem)
        : undefined,
    });
  }

  isStructuredServiceItem() {
    return !!this.structuredServiceItem;
  }

  isFreeFormServiceItem() {
    return !!this.freeFormServiceItem;
  }

  get groupKey() {
    if (this.isStructuredServiceItem()) {
      return STRUCTURED_SERVICE_GROUP_KEY;
    }
    if (this.isFreeFormServiceItem()) {
      return this.freeFormServiceItem!.category;
    }
    return '';
  }

  get key() {
    if (this.isStructuredServiceItem()) {
      return this.structuredServiceItem!.serviceTypeId;
    }
    if (this.isFreeFormServiceItem()) {
      return `${this.freeFormServiceItem!.category}:${this.freeFormServiceItem!.label?.displayName}`;
    }
    return '';
  }

  get description() {
    if (this.isStructuredServiceItem()) {
      return this.structuredServiceItem!.description;
    }
    if (this.isFreeFormServiceItem()) {
      return this.freeFormServiceItem!.label?.description;
    }
    return '';
  }
}

export type DiffServiceItem = {
  groupKey: string;
  type: string;
  items: ImmutableList<{
    key: string;
    location: GmbServiceItem | undefined;
    locationUpdated: GmbServiceItem | undefined;
  }>;
};

export class GmbServiceItems extends ImmutableRecord<{
  items: ImmutableList<GmbServiceItem>;
}>({
  items: ImmutableList(),
}) {
  static fromJSON(data: any = []) {
    return new GmbServiceItems({
      items: ImmutableList(data.map((item: any) => GmbServiceItem.fromJSON(item))),
    });
  }

  /**
   * 差分のある項目を取得する
   *
   * 順番の差分は考慮しない。
   *
   * @param other
   * @returns
   */
  getDiffItems(other: GmbServiceItems): ImmutableList<DiffServiceItem> {
    // まずどちらか片方にしかないもののグループキーとキーの組み合わせ一覧を取得する
    const thisKeys = this.items.map((item) => ({ groupKey: item.groupKey, key: item.key }));
    const otherKeys = other.items.map((item) => ({ groupKey: item.groupKey, key: item.key }));
    // keyを比較して、片方にしかないものを取得する
    const onlyThisKeys = thisKeys.filter((item) => !otherKeys.some((otherItem) => item.key === otherItem.key));
    const onlyOtherKeys = otherKeys.filter((item) => !thisKeys.some((otherItem) => item.key === otherItem.key));

    // 両方にあるもののうち、値が異なるものを取得する
    const diffKeys = this.items
      .filter((item) => {
        const otherItem = other.items.find((otherItem) => item.key === otherItem.key);
        if (!otherItem) {
          return false;
        }
        return !is(item, otherItem);
      })
      .map((item) => ({ groupKey: item.groupKey, key: item.key }));

    // グループごとにアイテムをまとめるために、グループの順番を整理する
    // グループの順番は、structuredサービスが最優先で、その後はフリーフォームはthisの順番を維持、otherの順番を維持
    const orderedGroupKeys = Array.from(
      new Set(
        [STRUCTURED_SERVICE_GROUP_KEY]
          .concat(thisKeys.map((item) => item.groupKey).toArray())
          .concat(otherKeys.map((item) => item.groupKey).toArray()),
      ),
    );

    // 差分のあるキーをグループごとにまとめる
    // {groupKey: "groupKey", keys: ["key1","key2", ...] 形式にまとめる
    const allDiffKeys = diffKeys.concat(onlyThisKeys).concat(onlyOtherKeys);
    const diffKeysByGroup = orderedGroupKeys.map((groupKey) => ({
      groupKey,
      keys: allDiffKeys.filter((item) => item.groupKey === groupKey).map((item) => item.key),
    }));

    // 差分のアイテムを取得する
    // グループごとに以下の形式になるようにまとめる
    // {
    //   groupKey: "groupKey",
    //   type: "structured" or "freeform",
    //   items: [
    //     {
    //       key: "key",
    //       item: GmbServiceItem,
    //       otherItem: GmbServiceItem,
    //     }
    //   ]
    // }
    const diffItems = diffKeysByGroup.map((group) => {
      const items = group.keys.map((key) => {
        const item = this.items.find((item) => item.key === key);
        const otherItem = other.items.find((otherItem) => otherItem.key === key);
        return {
          key,
          location: item,
          locationUpdated: otherItem,
        };
      });
      return {
        groupKey: group.groupKey.replace('categories/', ''), // 最初のcategories/を削除する
        type: group.groupKey === STRUCTURED_SERVICE_GROUP_KEY ? 'structured' : 'freeform',
        items,
      };
    });

    return ImmutableList(diffItems);
  }
}
