import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import { GbpPerformanceCsvCreateParams } from 'ApiClient/GbpPerformanceApi';
import { numberComparator } from 'helpers/search';
import { GbpPerformanceSearchCondition } from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';

const USER_COLUMNS = [
  'business_impressions_desktop_maps',
  'business_impressions_desktop_search',
  'business_impressions_mobile_maps',
  'business_impressions_mobile_search',
] as const;
const INTERACTION_COLUMNS = [
  'website_clicks',
  'call_clicks',
  'business_direction_requests',
  'business_conversations',
  'business_bookings',
] as const;
const REVIEW_COLUMNS = [
  'review_comment_count',
  'review_rate_count',
  'review_reply_count',
  'review_period_average_rating',
  'review_average_rating',
] as const;
const PROMOTION_COLUMNS = [
  'promotion_standard_count',
  'promotion_event_count',
  'promotion_offer_count',
  'promotion_alert_count',
  'promotion_standard_rejected_count',
  'promotion_event_rejected_count',
  'promotion_offer_rejected_count',
  'promotion_alert_rejected_count',
] as const;
const IMAGE_COLUMNS = [
  'image_interior_count',
  'image_exterior_count',
  'image_product_count',
  'image_additional_count',
] as const;

/** CSVダウンロードの取得項目 */
export const COLUMNS = [
  ...USER_COLUMNS,
  ...INTERACTION_COLUMNS,
  ...REVIEW_COLUMNS,
  ...PROMOTION_COLUMNS,
  ...IMAGE_COLUMNS,
] as const;

export type Column = (typeof COLUMNS)[number];
export type ColumnGroup = 'user' | 'interaction' | 'review' | 'promotion' | 'image';

export const COLUMNS_MAP = {
  user: USER_COLUMNS,
  interaction: INTERACTION_COLUMNS,
  review: REVIEW_COLUMNS,
  promotion: PROMOTION_COLUMNS,
  image: IMAGE_COLUMNS,
};

type GbpPerformanceCsvDownloadConditionType = {
  // 検索条件
  searchCondition: GbpPerformanceSearchCondition;
  // 取得対象の項目
  columns: ImmutableSet<Column>;
};

/**
 * GBPパフォーマンスCSVダウンロードの取得条件
 * @param searchCondition 検索条件
 * @param columns 取得対象の項目
 */
export class GbpPerformanceCsvDownloadCondition extends ImmutableRecord<GbpPerformanceCsvDownloadConditionType>({
  searchCondition: new GbpPerformanceSearchCondition(),
  columns: ImmutableSet(COLUMNS),
}) {
  /** グループ内の項目がすべてチェックされているかどうか */
  isSelectedAllColumns(group: ColumnGroup) {
    return this.columns.isSuperset(COLUMNS_MAP[group]);
  }

  /** グループ内の項目がいずれか1つ以上チェックされているかどうか */
  isSelectedSomeColumn(group: ColumnGroup) {
    for (const column of COLUMNS_MAP[group]) {
      if (this.columns.has(column)) {
        return true;
      }
    }
    return false;
  }

  /** グループ内の全ての項目をトグルする */
  checkColumnGroup(group: ColumnGroup) {
    if (this.isSelectedAllColumns(group)) {
      // すべてチェックされている場合は、すべてチェックをはずす
      this.columns.subtract(COLUMNS_MAP[group]);
    } else {
      // いずれか、または何もチェックされていない場合は、すべてチェックする
      this.columns.concat(COLUMNS_MAP[group]);
    }
  }

  /** CSVダウンロード開始APIのパラメータに変換 */
  toRequestParams(): GbpPerformanceCsvCreateParams {
    const { aggregateUnit, storeIds, startDate, endDate } = this.searchCondition.filter;

    return {
      aggregate_unit: aggregateUnit,
      store_ids: storeIds.toArray().sort(numberComparator),
      period: {
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      },
      columns: this.columns.toArray(),
    };
  }

  /** 現在の値が有効かどうか */
  isValid() {
    // 検索条件が有効であること、取得項目が1つ以上設定されていること
    return this.searchCondition.isValid() && this.columns.size > 0;
  }

  /** 検索条件からCSVダウンロード条件を生成する */
  static fromSearchCondition(searchCondition: GbpPerformanceSearchCondition) {
    return new GbpPerformanceCsvDownloadCondition({ searchCondition });
  }
}
