import React, { useCallback, useMemo, useState } from 'react';

import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { rgba } from 'polished';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { CheckBox } from 'components/atoms/CheckBox';
import { MoreHoursList, MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';
import { GmbLocationDiff } from 'models/Domain/GmbLocationDiffs';
import { GmbLocationUpdate } from 'models/Domain/GmbLocationUpdates';
import { Store } from 'models/Domain/Store';
import { SimpleUpdateType, UpdateType } from 'modules/gmbLocationUpdates/types';
import { COLOR } from 'style/color';

import { GmbLocationMoreHoursValue } from '../GmbLocationMoreHoursValue';

import { StoreCell } from './Cell/StoreCell';

import {
  ButtonsWrapper,
  CheckLabel,
  CustomButton,
  ResetButton,
  StatusLabel,
  StyledButton,
  StyledCancelButton,
  StyledCell,
  StyledCheckbox,
  TypeLabel,
} from '.';

type UpdatesTableMoreHoursRowsProps = {
  locationKey: 'moreHours';
  isFirstRowInStore: boolean;
  checked: boolean;
  store: Store;
  updateStatus: UpdateType | undefined;
  diff: GmbLocationDiff;
  gmbLocationUpdate: GmbLocationUpdate;
  moreHoursTypes: ImmutableList<MoreHoursType>;
  onAdaptationStorecast: (target: GmbLocationDiff) => void;
  onAdaptationGmb: (target: GmbLocationDiff) => void;
  onAdaptationCustomMoreHours: (target: GmbLocationDiff, moreHours: MoreHoursList) => void;
  onChangeCheckBox: (diff: GmbLocationDiff) => void;
  onResetItem: (target: GmbLocationDiff) => void;
};

export const UpdatesTableMoreHoursRows: React.FC<UpdatesTableMoreHoursRowsProps> = ({
  locationKey,
  isFirstRowInStore,
  checked,
  store,
  updateStatus,
  diff,
  gmbLocationUpdate,
  moreHoursTypes,
  onAdaptationStorecast,
  onAdaptationGmb,
  onAdaptationCustomMoreHours,
  onChangeCheckBox,
  onResetItem,
}) => {
  const { location, location_updated: locationUpdated } = gmbLocationUpdate;
  const updated = updateStatus !== undefined;
  const identifier = `${diff.store_id}-${diff.key}`;
  const first = isFirstRowInStore.toString();

  // 個別選択モードで、各項目についてどちらを選択しているかのデータ
  const [moreHoursChoices, setMoreHoursChoices] = useState<ImmutableMap<string, SimpleUpdateType>>(ImmutableMap());

  const setMoreHoursChoice = useCallback((hoursTypeId: string, type: SimpleUpdateType) => {
    setMoreHoursChoices((current) => current.set(hoursTypeId, type));
  }, []);

  // 差分のあるその他の営業時間を取得する
  const diffMoreHours = useMemo(() => {
    // カテゴリで利用可能なその他の営業時間
    const categoryMoreHoursTypes = moreHoursTypes;
    // データから得られる差分ありその他の営業時間ID(カテゴリで現在利用可能とは限らない)
    const rawDiffMoreHoursTypeIds = location.getMoreHoursDiffTypes(locationUpdated);

    // 利用可能なその他の営業時間に絞る
    // 通常時、個別選択時で順番が入れ替わらないように、通常時と同じカテゴリの並び順になるようにfilterで対応
    return categoryMoreHoursTypes.filter((hoursType) => rawDiffMoreHoursTypeIds.has(hoursType.hoursTypeId));
  }, [location, locationUpdated, moreHoursTypes]);

  // 個別選択モードかどうか
  const canSelectCustomMode = useMemo(() => diffMoreHours.size > 1, [diffMoreHours]);
  const [isCustomMode, setIsCustomMode] = useState(false);
  const switchIsCustomMode = useCallback(() => setIsCustomMode((isCustomMode) => !isCustomMode), []);

  // 更新ステータスがSTORECAST, GOOGLE_UPDATEDでなくて、個別に選択するを選んだ場合、カスタムモードになる
  const isOpenCustomMoreHours = isCustomMode && updateStatus !== 'STORECAST' && updateStatus !== 'GOOGLE_UPDATED';

  /**
   * カスタム選択した内容を反映する処理
   */
  const handleOnAdaptationCustomMoreHours = useCallback(() => {
    // 選択を元に更新するその他の営業時間を生成する
    const moreHoursTypeIds = moreHoursChoices
      .keySeq()
      .filter((hoursTypeId) => moreHoursTypes.find((hoursType) => hoursType.hoursTypeId === hoursTypeId))
      .map((hoursTypeId) => hoursTypeId);

    const storecastMoreHours = location.moreHours;
    const googleUpdatedMoreHours = locationUpdated.moreHours;

    // STORECASTのデータを元に更新していく
    let customMoreHours = storecastMoreHours;
    moreHoursTypes.forEach((hoursType) => {
      if (!moreHoursTypeIds.includes(hoursType.hoursTypeId)) {
        return;
      }
      const type = moreHoursChoices.get(hoursType.hoursTypeId);
      if (type === 'STORECAST') {
        // STORECASTのデータを元に更新していくので、STORECASTの場合は何もしない
        return;
      }
      const selectedValue = googleUpdatedMoreHours.find(hoursType);
      if (selectedValue) {
        // STORECASTのデータに存在しない項目なら追加する
        if (!storecastMoreHours.find(hoursType)) {
          customMoreHours = customMoreHours.addMoreHours(hoursType);
        }
        // GOOGLEのデータで更新する
        customMoreHours = customMoreHours.changeMoreHours(hoursType, selectedValue);
      } else {
        // 不要になったデータは削除する
        customMoreHours = customMoreHours.removeMoreHours(hoursType);
      }
    });

    onAdaptationCustomMoreHours(diff, customMoreHours);
  }, [
    diff,
    location.moreHours,
    locationUpdated.moreHours,
    moreHoursChoices,
    moreHoursTypes,
    onAdaptationCustomMoreHours,
  ]);

  // その他の営業時間の個別選択を確定可能か (選択している個数が、差分あり数と一致する場合確定可能)
  const canApplyCustomMoreHours = diffMoreHours.size === moreHoursChoices.size;

  if (locationKey != diff.key) {
    console.warn(`locationKey mismatch! locationKey=${locationKey}, diff.key=${diff.key}`);
  }
  return (
    <>
      <Table.Row key={identifier}>
        <StoreCell isFirstRowInStore={isFirstRowInStore} store={store} />
        <StyledCell first={first}>
          <CheckLabel htmlFor={identifier}>
            <StyledCheckbox
              id={identifier}
              checked={checked}
              disabled={updated}
              onChange={() => onChangeCheckBox(diff)}
            />
            {diff.label}
          </CheckLabel>
        </StyledCell>
        <StyledCell first={first}>
          <TypeLabel isGoogleUpdated={diff.isGoogleUpdated}>{diff.typeLabel}</TypeLabel>
        </StyledCell>
        <StyledCell first={first} isselected={(updateStatus !== undefined && updateStatus === 'STORECAST').toString()}>
          {!isOpenCustomMoreHours && (
            <GmbLocationMoreHoursValue
              gmbLocation={location}
              filterMoreHoursTypes={diffMoreHours}
              moreHoursTypes={moreHoursTypes}
            />
          )}
        </StyledCell>
        <StyledCell
          first={first}
          isselected={(updateStatus !== undefined && updateStatus === 'GOOGLE_UPDATED').toString()}
        >
          {!isOpenCustomMoreHours && (
            <GmbLocationMoreHoursValue
              gmbLocation={locationUpdated}
              filterMoreHoursTypes={diffMoreHours}
              moreHoursTypes={moreHoursTypes}
            />
          )}
        </StyledCell>
        <StyledCell first={first}>
          {!updated ? (
            <>
              <ButtonsWrapper>
                <StyledCancelButton onClick={() => onAdaptationStorecast(diff)}>STORECAST</StyledCancelButton>
                <StyledButton onClick={() => onAdaptationGmb(diff)}>Google</StyledButton>
              </ButtonsWrapper>
              {canSelectCustomMode && !isCustomMode && (
                <ButtonsWrapper>
                  <CustomButton onClick={switchIsCustomMode}>個別に選択する ▼</CustomButton>
                </ButtonsWrapper>
              )}
            </>
          ) : (
            <ButtonsWrapper>
              <StatusLabel>
                {updateStatus === 'STORECAST'
                  ? '却下済み'
                  : updateStatus === 'GOOGLE_UPDATED'
                    ? '承認済み'
                    : '個別選択'}
              </StatusLabel>
              <ResetButton onClick={() => onResetItem(diff)}>リセット</ResetButton>
            </ButtonsWrapper>
          )}
        </StyledCell>
      </Table.Row>

      {
        // その他の営業時間一つ一つの選択を行う行の表示
        isOpenCustomMoreHours && (
          <>
            {diffMoreHours.map((hoursType, index) => {
              // 各営業時間項目の選択状態 (項目全体が STORECAST / GOOGLE_UPDATED を選択している場合はそちらに従う)
              const moreHoursUpdateStatus = ['STORECAST', 'GOOGLE_UPDATED'].includes(updateStatus as any)
                ? updateStatus
                : moreHoursChoices.get(hoursType.hoursTypeId);

              return (
                <StyledRow key={`${identifier}_${hoursType.hoursTypeId}`}>
                  <StyledSubCell />
                  <StyledSubCell />
                  <StyledSubCell />
                  <StyledSubCell
                    isselected={(
                      moreHoursUpdateStatus !== undefined && moreHoursUpdateStatus === 'STORECAST'
                    ).toString()}
                  >
                    <GmbLocationMoreHoursValue
                      gmbLocation={location}
                      filterMoreHoursTypes={ImmutableList([hoursType])}
                      moreHoursTypes={moreHoursTypes}
                    />
                  </StyledSubCell>
                  <StyledSubCell
                    isselected={(
                      moreHoursUpdateStatus !== undefined && moreHoursUpdateStatus === 'GOOGLE_UPDATED'
                    ).toString()}
                  >
                    <GmbLocationMoreHoursValue
                      gmbLocation={locationUpdated}
                      filterMoreHoursTypes={ImmutableList([hoursType])}
                      moreHoursTypes={moreHoursTypes}
                    />
                  </StyledSubCell>
                  <StyledSubCell>
                    <ButtonsWrapper>
                      <CheckBoxWrapper type='STORECAST'>
                        <CheckBox
                          checked={moreHoursUpdateStatus === 'STORECAST'}
                          label='STORECAST'
                          onClick={() => setMoreHoursChoice(hoursType.hoursTypeId, 'STORECAST')}
                        />
                      </CheckBoxWrapper>
                      <CheckBoxWrapper type='GOOGLE_UPDATED'>
                        <CheckBox
                          checked={moreHoursUpdateStatus === 'GOOGLE_UPDATED'}
                          label='Google'
                          onClick={() => setMoreHoursChoice(hoursType.hoursTypeId, 'GOOGLE_UPDATED')}
                        />
                      </CheckBoxWrapper>
                    </ButtonsWrapper>
                  </StyledSubCell>
                </StyledRow>
              );
            })}
            <StyledRow key={`${identifier}_action`}>
              <StyledCell />
              <StyledCell />
              <StyledCell />
              <StyledCell colSpan={2}>
                <DescriptionWrapper>
                  <Description>すべての項目について保持するデータを選択し、選択を確定してください。</Description>
                </DescriptionWrapper>
              </StyledCell>
              <StyledCell>
                <ButtonsWrapper>
                  <CustomButton
                    onClick={handleOnAdaptationCustomMoreHours}
                    disabled={updateStatus !== undefined || !canApplyCustomMoreHours}
                  >
                    選択を確定
                  </CustomButton>
                  <CustomButton onClick={switchIsCustomMode} disabled={updateStatus !== undefined}>
                    閉じる ▲
                  </CustomButton>
                </ButtonsWrapper>
              </StyledCell>
            </StyledRow>
          </>
        )
      }
    </>
  );
};

const StyledRow = styled(Table.Row)`
  &&& {
    background: ${rgba('#e3e4e5', 0.1)};
  }
`;

export const StyledSubCell = styled(StyledCell)`
  &&&& {
    border-bottom: 1px dashed #e3e4e5 !important;
  }
`;

const CheckBoxWrapper = styled.div<{ type: 'STORECAST' | 'GOOGLE_UPDATED' }>`
  &&& {
    font-weight: bold;
    margin-left: 8px;
    margin-right: 8px;
    label {
      // チェックボックスのラベルの色を変更する手段がここで!important指定でやる以外ないのでやむなし。。
      color: ${({ type }) => (type === 'STORECAST' ? COLOR.GREEN : COLOR.GOOGLE)} !important;
      display: inline-block;
      width: 130px;
      text-align: center;
      line-height: 24px;
      ::after {
        top: 0;
      }
    }
  }
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 38px;
  border: 1px solid ${COLOR.GRAY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  display: inline-block;
  padding: 4px;
`;
