import React from 'react';

import { CheckboxProps, Checkbox as SemanticCheckBox } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';
export type { CheckboxProps } from 'semantic-ui-react';
type CheckBoxButtonProps = { className?: string; text: string } & CheckboxProps;

export const CheckBoxButton: React.FC<CheckBoxButtonProps> = ({ className, id, checked = false, text, ...props }) => (
  <ButtonLabel className={className} checked={checked} htmlFor={String(id)}>
    <CheckBox id={id} checked={checked} {...props} />
    <Text checked={checked}>{text}</Text>
  </ButtonLabel>
);

const SIZE = '24px';
const _SemanticCheckBox = SemanticCheckBox as any;
export const CheckBox = styled(_SemanticCheckBox)`
  &&& {
    min-width: ${SIZE};
    label {
      display: flex;
      align-items: center;
      height: ${SIZE};
      &:before {
        width: ${SIZE};
        height: ${SIZE};
      }
      &:after {
        width: ${SIZE};
        height: ${SIZE};
        top: 4px;
        font-size: 20px;
      }
    }
  }
`;

/**
 * CheckBoxより一回り小さくて緑色のチェックボックス
 */
export const SmallCheckBox = styled(_SemanticCheckBox)`
  &&& {
    input {
      &:checked ~ label:before {
        background: ${COLOR.GREEN};
        border: none;
      }
      &:checked ~ label:after {
        color: ${COLOR.WHITE};
      }
      // 一部だけチェックされている場合の表示
      &:indeterminate ~ label:before {
        background: ${COLOR.GREEN};
        border: none;
      }
      &:indeterminate ~ label:after {
        color: ${COLOR.WHITE};
      }
    }
  }
`;

const ButtonLabel = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  border: solid 1px ${COLOR.GRAY};
  background-color: ${COLOR.WHITE};
  padding: 16px 14px;
  border: ${(props) => (props.checked ? `solid 2px ${COLOR.GREEN}` : `solid 2px ${COLOR.GRAY}`)};
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
`;

const Text = styled.div<{ checked: boolean }>`
  font-size: 16px;
  color: ${COLOR.BLACK};
  font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};
  margin-left: 14px;
`;
