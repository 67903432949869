import ApiClient, { FaasApiClient } from 'ApiClient';
import CONFIG from 'config';
import { AccountSingleton } from 'models/Domain/AccountList';
import { InventoryAvailability, InventoryStatus } from 'models/Domain/Omo/Inventory';
import { InventoryCsvDownloadParams } from 'models/Domain/Omo/InventoryCsvDownloadFilter';
import { ImportStatus, ValidateError } from 'modules/inventoriesImport/type';

const apiClient = new ApiClient({});
const faasApiClient = new FaasApiClient({});

const OMO_PATH = '/omo';
const PRODUCT_PATH = `${OMO_PATH}/products`;
const INVENTORY_PATH = `${OMO_PATH}/inventories`;
const CSV_DOWNLOAD_PATH = `${OMO_PATH}/inventories/csv/download/create`;
const CSV_DOWNLOAD_CHECK_PATH = `${OMO_PATH}/inventories/csv/download/status`;
const CSV_IMPORT_PATH = `${OMO_PATH}/inventories/csv/import/start`;
const CSV_IMPORT_CHECK_PATH = `${OMO_PATH}/inventories/csv/import/status`;

export type OmoInsightSummaryApiParams = {
  from_date: string; // YYYY-MM-DD形式
  to_date: string;
  _oid?: string; // 複数アカウントでキャッシュが同一にならないようにするために利用
};

type OmoInsightSummaryGMCMetrics = {
  impressions: number;
  clicks: number;
};

type OmoInsightSummaryGAMetrics = {
  sessions: number;
  conversions: number;
  revenues: number;
};

type OmoInsightSummaryResponse = {
  from_date: string;
  to_date: string;
  items: {
    name: string;
    gmc: {
      shopping_ads: OmoInsightSummaryGMCMetrics;
      free_listings: OmoInsightSummaryGMCMetrics;
      free_local_listings: OmoInsightSummaryGMCMetrics;
    };
    ga: {
      free: OmoInsightSummaryGAMetrics;
      ads: OmoInsightSummaryGAMetrics;
    };
  }[];
};

export class OmoInsightSummaryApi {
  static get(params: OmoInsightSummaryApiParams) {
    const organizationId = AccountSingleton.getInstance().organizationId;
    return apiClient.get<OmoInsightSummaryResponse>(
      '/omo/insight/summary',
      { ...params, _oid: organizationId },
      { cache: true },
    );
  }
}

type OmoProductCounts = {
  active: number;
  pending: number;
  disapproved: number;
  expiring: number;
};

type OmoProductSummaryResponse = {
  date: string;
  items: {
    name: string;
    status: {
      organization_id: number;
      merchant_id: string;
      date: string;
      products: {
        shopping_ads: OmoProductCounts;
        local_inventory_ads: OmoProductCounts;
        free_listings: OmoProductCounts;
        free_local_listings: OmoProductCounts;
      };
      create_at: string;
      update_at: string;
    };
  }[];
};

export type OmoProductStatusApiParams = {
  date?: string;
  _oid?: string; // 複数アカウントでキャッシュが同一にならないようにするために利用
};
export class OmoProductStatusApi {
  static get(params: OmoProductStatusApiParams = {}) {
    const organizationId = AccountSingleton.getInstance().organizationId;
    return apiClient.get<OmoProductSummaryResponse>(
      '/omo/product/status',
      { ...params, _oid: organizationId },
      { cache: true },
    );
  }
}

export type OmoInsightGraphApiParams = {
  from_date: string; // YYYY-MM-DD形式
  to_date: string;
  aggregate_unit: 'month' | 'week' | 'day';
  merchant_id: string;
  _oid?: string;
};

export class OmoInsightGraphApi {
  static get(params: OmoInsightSummaryApiParams) {
    const organizationId = AccountSingleton.getInstance().organizationId;
    return apiClient.get<OmoInsightSummaryResponse>(
      '/omo/insight/graph',
      { ...params, _oid: organizationId },
      { cache: true },
    );
  }
}

/**
 * 商品検索APIのパラメータ
 */
export type SearchProductsParams = {
  store_id?: string;
  query?: string;
  sort_key?: 'create' | 'update';
  sort_order?: 'asc' | 'desc';
  page?: number;
  limit?: number;
  availability?: string;
  local_inventory_status?: string;
};

type GMCProduct = {
  id: string;
  offerId: string;
  title?: string;
  description?: string;
  link?: string;
  imageLink?: string;
  additionalImageLinks?: string[];
  contentLanguage: string;
  targetCountry: string;
  channel: 'local' | 'online';
  expirationDate?: string;
  adult?: boolean;
  kind?: string;
  brand?: string;
  color?: string;
  googleProductCategory?: string;
  gtin?: string;
  itemGroupId?: string;
  material?: string;
  mpn?: string;
  pattern?: string;
  price?: {
    value?: string;
    currency?: string;
  };
  salePrice?: {
    value?: string;
    currency?: string;
  };
  salePriceEffectiveDate?: string;
  productHeight?: {
    value?: number;
    unit?: 'in' | 'cm';
  };
  productLength?: {
    value?: number;
    unit?: 'in' | 'cm';
  };
  productWidth?: {
    value?: number;
    unit?: 'in' | 'cm';
  };
  productWeight?: {
    value?: number;
    unit?: 'g' | 'kg' | 'oz' | 'lb';
  };
  shipping?: {
    price?: {
      value?: string;
      currency?: string;
    };
    country?: string;
    region?: string;
    service?: string;
    locationId?: string;
    locationGroupName?: string;
    postalCode?: string;
    minHandlingTime?: string;
    maxHandlingTime?: string;
    minTransitTime?: string;
    maxTransitTime?: string;
  };
  shippingWeight?: {
    value?: number;
    unit?: string;
  };
  sizes?: string[];
  taxes?: {
    rate?: number;
    country?: string;
    region?: string;
    taxShip?: boolean;
    locationId?: string;
    postalCode?: string;
  }[];
  customAttributes?: {
    name?: string;
    value?: string;
    groupValues?: {
      name?: string;
      value?: string;
    }[];
  }[];
  identifierExists?: boolean;
  installment?: {
    months?: string;
    amount?: {
      value?: string;
      currency?: string;
    };
  };
  loyaltyPoints?: {
    name?: string;
    pointsValue?: string;
    ratio?: number;
  };
  multipack?: string;
  customLabel0?: string;
  customLabel1?: string;
  customLabel2?: string;
  customLabel3?: string;
  customLabel4?: string;
  isBundle?: boolean;
  mobileLink?: string;
  availabilityDate?: string;
  shippingLabel?: string;
  unitPricingMeasure?: {
    value?: number;
    unit?: string;
  };
  unitPricingBaseMeasure?: {
    value?: string;
    unit?: string;
  };
  shippingLength?: {
    value?: number;
    unit?: string;
  };
  shippingWidth?: {
    value?: number;
    unit?: string;
  };
  shippingHeight?: {
    value?: number;
    unit?: string;
  };
  displayAdsId?: string;
  displayAdsSimilarIds?: string[];
  displayAdsTitle?: string;
  displayAdsLink?: string;
  displayAdsValue?: number;
  sellOnGoogleQuantity?: string;
  promotionIds?: string[];
  maxHandlingTime?: string;
  minHandlingTime?: string;
  costOfGoodsSold?: {
    value?: string;
    currency?: string;
  };
  source?: 'api' | 'crawl' | 'feed';
  includedDestinations?: string[];
  excludedDestinations?: string[];
  adsGrouping?: string;
  adsLabels?: string[];
  adsRedirect?: string;
  productTypes?: string[];
  ageGroup?: string;
  availability?: string;
  condition?: string;
  gender?: string;
  sizeSystem?: string;
  sizeType?: string;
  additionalSizeType?: string;
  energyEfficiencyClass?: string;
  minEnergyEfficiencyClass?: string;
  maxEnergyEfficiencyClass?: string;
  taxCategory?: string;
  transitTimeLabel?: string;
  shoppingAdsExcludedCountries?: string[];
  pickupMethod?: 'buy' | 'reserve' | 'ship to store' | 'not supported';
  pickupSla?: 'same day' | 'next day' | '2-day' | '3-day' | '4-day' | '5-day' | '6-day' | '7-day' | 'multi-week';
  linkTemplate?: string;
  mobileLinkTemplate?: string;
  productDetails: {
    sectionName?: string;
    attributeName?: string;
    attributeValue?: string;
  }[];
  productHighlights?: string[];
  subscriptionCost?: {
    period?: string;
    periodLength?: string;
    amount?: {
      value?: string;
      currency?: string;
    };
  };
  canonicalLink?: string;
  externalSellerId?: string;
};

type Inventory = {
  store_id: number;
  inventory: {
    id: string;
    price: number | null;
    quantity: number | null;
    availability: string | null;
    sale_price: number | null;
    sale_price_effective_date: {
      start_date: string | null;
      end_date: string | null;
    } | null;
  };
  applied_to_gmc_at: string | null;
  create_at: string | null;
  update_at: string | null;
};

/**
 * 商品検索APIのレスポンス
 */
export type SearchProductsResponse = {
  products: {
    key: string;
    type: 'item' | 'group';
    merchant_id: string;
    items: {
      offer_id: string;
      product: GMCProduct;
      local_product: GMCProduct;
      local_inventories: Inventory[];
      product_update_at: string;
      search: string;
    }[];
  }[];
  pagination: {
    current_page: number;
    has_next: boolean;
    limit: number;
    total_count: number;
  };
};

export class SearchProductsApi {
  /**
   * 商品検索APIの実行
   * @params params パラメータ
   * @returns
   */
  static get(params: SearchProductsParams) {
    return apiClient.get<SearchProductsResponse>(PRODUCT_PATH, params);
  }
}

/**
 * 在庫更新APIのパラメータ
 */
export type UpdateInventoriesParams = {
  inventories: {
    store_id: number;
    merchant_id: string;
    offer_id: string;
    inventory: {
      price: number | null;
      quantity: number | null;
      availability: string | null;
      sale_price: number | null;
      sale_price_effective_date: {
        start_date: string | null;
        end_date: string | null;
      } | null;
    };
  }[];
};

/**
 * 在庫更新APIのレスポンス
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type UpdateInventoriesResponse = {};

export class UpdateInventoriesApi {
  /**
   * 在庫更新APIの実行
   * @params params パラメータ
   * @returns
   */
  static post(params: UpdateInventoriesParams) {
    return apiClient.post<UpdateInventoriesResponse>(`${INVENTORY_PATH}:batch_update`, params);
  }
}

/**
 * 有効期限更新APIのパラメータ
 */
export type UpdateExpirationParams = {
  store_id: number;
  query?: string;
  availabilities?: InventoryAvailability[];
  local_inventory_statuses?: InventoryStatus[];
};

/**
 * 有効期限更新APIのレスポンス
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type UpdateExpirationResponse = {};

export class UpdateExpirationApi {
  /**
   * 在庫の有効期限更新APIの実行
   * @params params パラメータ
   * @returns
   */
  static post(params: UpdateExpirationParams) {
    return apiClient.post<UpdateInventoriesResponse>(`${INVENTORY_PATH}:update_expiration`, params);
  }
}

/**
 * 在庫サマリーAPIのパラメータ
 */
export type InventorySummaryParams = {
  store_id?: number[];
};

/**
 * 在庫サマリーAPIのレスポンス
 */
export type InventorySummaryResponse = {
  stores: {
    store_id: number;
    counts: {
      total: number;
      valid: number;
      expiring_soon: number;
      expired: number;
    }[];
  }[];
};

export class InventorySummaryApi {
  static get(params: InventorySummaryParams) {
    const _params: any = { ...params };
    if (params.store_id) {
      _params.store_id = params.store_id.join(',');
    }
    return apiClient.get<InventorySummaryResponse>(`${INVENTORY_PATH}:summary`, _params);
  }
}

/**
 * 在庫情報CSVダウンロード
 * @param executionArn Step Functionsの実行ID
 */
export class InventoryCsvDownloadApi {
  static post(params: InventoryCsvDownloadParams) {
    return faasApiClient.post<{ executionArn: string }>(`${CSV_DOWNLOAD_PATH}`, params);
  }
}

/**
 * 在庫情報CSV更新ステータス確認APIのレスポンス
 */
export type InventoryCsvDownloadStatusCheckApiResponse = {
  status: ImportStatus;
  download_url: string | null;
  message: string;
};

/**
 * 在庫情報CSVダウンロードのステータス確認API
 * @param executionArn Step Functionsの実行ID
 */
export class InventoryCsvDownloadStatusCheckApi {
  static post(params: { executionArn: string }) {
    return faasApiClient.post<InventoryCsvDownloadStatusCheckApiResponse>(CSV_DOWNLOAD_CHECK_PATH, params);
  }
}

/**
 * 在庫情報CSV更新APIのレスポンス
 */
export type InventoryCsvImportApiResponse = {
  execution_arn: string;
};

/**
 * 在庫情報をCSVでまとめて更新API
 * @param csv CSVファイル
 * @param validate_only バリデーションのみ実行するか
 */
export class InventoryCsvImportApi {
  static post({ csv, validate_only }: { csv: File; validate_only: boolean }) {
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('validate_only', validate_only ? '1' : '0');
    const apiClient = new ApiClient({
      baseURL: CONFIG.FAAS_API_ENDPOINT,
    });
    return apiClient.post<InventoryCsvImportApiResponse>(CSV_IMPORT_PATH, formData);
  }
}

/**
 * 在庫情報CSV更新ステータス確認APIのレスポンス
 */
export type InventoryCsvImportStatusCheckApiResponse = {
  status: ImportStatus;
  result: {
    message: string;
    total_succeed?: number;
    total_failed?: number;
    validation_status?: 'success' | 'failed';
    validation_errors?: ValidateError;
  };
};

/**
 * 在庫情報CSV更新のステータス確認API
 * @param executionArn Step Functionsの実行ID
 */
export class InventoryCsvImportStatusCheckApi {
  static post(params: { executionArn: string }) {
    return faasApiClient.post<InventoryCsvImportStatusCheckApiResponse>(CSV_IMPORT_CHECK_PATH, params);
  }
}
