import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Map as ImmutableMap, Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import {
  GbpPerformanceMADataResponse,
  GbpPerformanceMAMonthlyDataResponse,
  GbpPerformanceMAStats as GbpPerformanceMAStatsResponse,
} from 'ApiClient/GbpPerformanceMAApi';
import { OverviewGraphDataItem } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAGraph/OverviewGraph';
import { PerformanceGraphDataItem } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAGraph/PerformanceGraph';
import { ReviewGraphDataItem } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAGraph/ReviewGraph';
import { convertDateLabel } from 'helpers/graph';
import { addNullableValues, divideNullableValue } from 'helpers/utils';
import { AccountList } from 'models/Domain/AccountList';
import { YearMonth } from 'models/Domain/YearMonth';
import { AggregateUnit, ArrayElement } from 'types/Common';

export const DISPLAY_TYPES = ['overview', 'impressions', 'interactions', 'activities'] as const;
export const INTERACTION_TYPES = ['value', 'rate'] as const;
export const REVIEW_TYPES = ['period', 'total'] as const;
export const AGGREGATE_METHOD_TYPES = ['total', 'average'] as const;

export type DisplayType = (typeof DISPLAY_TYPES)[number];
export type InteractionType = (typeof INTERACTION_TYPES)[number];
export type ReviewType = (typeof REVIEW_TYPES)[number];
export type AggregateMethod = (typeof AGGREGATE_METHOD_TYPES)[number];

export const displayTypeLabel = {
  overview: '概要',
  impressions: 'ユーザー',
  interactions: 'インタラクション',
  activities: 'アクティビティ',
} satisfies Record<DisplayType, string>;

export const interactionTypeLabel = {
  value: 'インタラクション数',
  rate: 'インタラクション率',
} satisfies Record<InteractionType, string>;

export const aggregateMethodLabel = {
  total: '合計',
  average: '平均',
} satisfies Record<AggregateMethod, string>;

type ConvertPeriodReviewStatsKey<T> = `period${Capitalize<string & T>}`;
type ConvertTotalReviewStatsKey<T> = `total${Capitalize<string & T>}`;

const aggregateStatsTypes = ['impressions', 'interactions', 'totalReviews', 'periodReviews'] as const;
type AggregateStatsType = (typeof aggregateStatsTypes)[number];

export type StatsType =
  | keyof GbpPerformanceMAStatsImpressionRecord
  | keyof GbpPerformanceMAStatsInteractionRecord
  | ConvertPeriodReviewStatsKey<keyof GbpPerformanceMAStatsReviewRecord>
  | ConvertTotalReviewStatsKey<keyof GbpPerformanceMAStatsReviewRecord>
  | AggregateStatsType;

const interactionStatsTypes = [
  'websiteClicks',
  'callClicks',
  'directionRequests',
  'bookings',
  'conversations',
  'interactions',
] as const satisfies readonly StatsType[];
type InteractionStatsType = (typeof interactionStatsTypes)[number];

export const isInteractionStatsType = (statsType: StatsType): statsType is InteractionStatsType => {
  return (interactionStatsTypes as readonly StatsType[]).includes(statsType);
};

export const isAggregateStatsType = (statsType: StatsType): statsType is AggregateStatsType => {
  return (aggregateStatsTypes as readonly StatsType[]).includes(statsType);
};

export const statsKeyMapping = {
  impressions: 'ユーザー数',
  interactions: 'インタラクション数',
  periodReviews: 'クチコミ数',
  totalReviews: 'クチコミ数（累計）',
  desktopMaps: 'Google マップ - パソコン',
  mobileMaps: 'Google マップ - モバイル',
  desktopSearch: 'Google 検索 - パソコン',
  mobileSearch: 'Google 検索 - モバイル',
  callClicks: '通話',
  conversations: 'メッセージ',
  bookings: '予約',
  directionRequests: 'ルート',
  websiteClicks: 'ウェブサイトのクリック',
  periodCommentCount: 'クチコミ数（コメントあり）',
  periodRateCount: 'クチコミ数（評価のみ）',
  periodReplyCount: '返信数',
  periodAverageRating: '評価（期間平均）',
  totalCommentCount: 'クチコミ数（コメントあり）',
  totalRateCount: 'クチコミ数（評価のみ）',
  totalReplyCount: '返信数',
  totalAverageRating: '評価（累計平均）',
} as const satisfies Record<StatsType, string>;

export type SortKey = 'organizationName' | 'organizationId' | StatsType;

export type SortType = 'number' | 'rate';
export type SortOrder = 'asc' | 'desc';
export type SortTarget = 'current' | 'comparison' | 'diffRate';

// タブごとのグラフに表示する項目
export const getAvailableDisplayStats = (displayType: DisplayType, reviewType: ReviewType): StatsType[] => {
  switch (displayType) {
    case 'overview':
      return ['impressions', 'interactions'];
    case 'impressions':
      return ['desktopMaps', 'mobileMaps', 'desktopSearch', 'mobileSearch', 'interactions'];
    case 'interactions':
      return ['impressions', 'callClicks', 'directionRequests', 'websiteClicks', 'conversations', 'bookings'];
    case 'activities':
      return reviewType === 'total'
        ? ['totalCommentCount', 'totalRateCount', 'totalReplyCount', 'totalAverageRating']
        : ['periodCommentCount', 'periodRateCount', 'periodReplyCount', 'periodAverageRating'];
  }
};

export type GbpPerformanceMAStatsImpressionRecord = {
  desktopMaps: number | null;
  desktopSearch: number | null;
  mobileMaps: number | null;
  mobileSearch: number | null;
};

export class GbpPerformanceMAStatsImpression extends ImmutableRecord<GbpPerformanceMAStatsImpressionRecord>({
  desktopMaps: null,
  desktopSearch: null,
  mobileMaps: null,
  mobileSearch: null,
}) {
  // Googleマップによるユーザー数の合計
  get maps() {
    return addNullableValues(this.desktopMaps, this.mobileMaps);
  }

  // Google検索によるユーザー数の合計
  get search(): number | null {
    return addNullableValues(this.desktopSearch, this.mobileSearch);
  }

  // ユーザー数の合計
  get total() {
    return addNullableValues(this.maps, this.search);
  }
}

export type GbpPerformanceMAStatsInteractionRecord = {
  callClicks: number | null;
  conversations: number | null;
  bookings: number | null;
  directionRequests: number | null;
  websiteClicks: number | null;
};

export class GbpPerformanceMAStatsInteraction extends ImmutableRecord<GbpPerformanceMAStatsInteractionRecord>({
  callClicks: null,
  conversations: null,
  bookings: null,
  directionRequests: null,
  websiteClicks: null,
}) {
  // インタラクション数の合計
  get total() {
    return addNullableValues(
      this.callClicks,
      this.conversations,
      this.bookings,
      this.directionRequests,
      this.websiteClicks,
    );
  }
}

export type GbpPerformanceMAStatsReviewRecord = {
  commentCount: number | null;
  rateCount: number | null;
  replyCount: number | null;
  averageRating: number | null;
};

export class GbpPerformanceMAStatsReview extends ImmutableRecord<GbpPerformanceMAStatsReviewRecord>({
  commentCount: null,
  rateCount: null,
  replyCount: null,
  averageRating: null,
}) {
  // クチコミ数の合計（コメントあり+評価のみ）
  get total() {
    return addNullableValues(this.commentCount, this.rateCount);
  }

  // 指定されたクチコミデータを追加したときの平均評価を求める
  addAverageRating(other: GbpPerformanceMAStatsReview) {
    // 平均評価は、存在するクチコミの合計点 / 存在するクチコミ数とし、クチコミがなければnullを返す

    // thisの平均評価がnullの場合、thisにはデータはないのでotherの値を返す
    if (this.averageRating == null || !this.total) {
      return other.averageRating;
    }
    // otherの平均評価がnullの場合、otherにはデータはないのでthisの値を返す
    if (other.averageRating == null || !other.total) {
      return this.averageRating;
    }

    // クチコミ数と平均評価をかけて評価の合計点を求め、クチコミ数で割る
    const totalRating = addNullableValues(this.averageRating * this.total, other.averageRating * other.total);
    const reviewCount = addNullableValues(this.total, other.total);
    return divideNullableValue(totalRating, reviewCount);
  }
}

export class GbpPerformanceMAStats extends ImmutableRecord<{
  impression: GbpPerformanceMAStatsImpression;
  interaction: GbpPerformanceMAStatsInteraction;
  periodReview: GbpPerformanceMAStatsReview;
  totalReview: GbpPerformanceMAStatsReview;
}>({
  impression: new GbpPerformanceMAStatsImpression(),
  interaction: new GbpPerformanceMAStatsInteraction(),
  periodReview: new GbpPerformanceMAStatsReview(),
  totalReview: new GbpPerformanceMAStatsReview(),
}) {
  static fromJSON(data: GbpPerformanceMAStatsResponse): GbpPerformanceMAStats {
    return new GbpPerformanceMAStats({
      impression: new GbpPerformanceMAStatsImpression({
        desktopMaps: data.business_impressions_desktop_maps,
        desktopSearch: data.business_impressions_desktop_search,
        mobileMaps: data.business_impressions_mobile_maps,
        mobileSearch: data.business_impressions_mobile_search,
      }),
      interaction: new GbpPerformanceMAStatsInteraction({
        callClicks: data.call_clicks,
        conversations: data.business_conversations,
        bookings: data.business_bookings,
        directionRequests: data.business_direction_requests,
        websiteClicks: data.website_clicks,
      }),
      periodReview: new GbpPerformanceMAStatsReview({
        commentCount: data.review_comment_count,
        rateCount: data.review_rate_count,
        replyCount: data.review_reply_count,
        averageRating: data.review_period_average_rating,
      }),
      totalReview: new GbpPerformanceMAStatsReview({
        commentCount: data.total_review_comment_count,
        rateCount: data.total_review_rate_count,
        replyCount: data.total_review_reply_count,
        averageRating: data.review_average_rating,
      }),
    });
  }

  // FIXME: keyでアクセスしている箇所を置き換えできていないので、一旦全要素をgetで取得できるようにしている

  get desktopMaps() {
    return this.impression.desktopMaps;
  }

  get mobileMaps() {
    return this.impression.mobileMaps;
  }

  get desktopSearch() {
    return this.impression.desktopSearch;
  }

  get mobileSearch() {
    return this.impression.mobileSearch;
  }

  get callClicks() {
    return this.interaction.callClicks;
  }

  get conversations() {
    return this.interaction.conversations;
  }

  get bookings() {
    return this.interaction.bookings;
  }

  get directionRequests() {
    return this.interaction.directionRequests;
  }

  get websiteClicks() {
    return this.interaction.websiteClicks;
  }

  get periodCommentCount() {
    return this.periodReview.commentCount;
  }

  get totalCommentCount() {
    return this.totalReview.commentCount;
  }

  get periodRateCount() {
    return this.periodReview.rateCount;
  }

  get totalRateCount() {
    return this.totalReview.rateCount;
  }

  get periodReplyCount() {
    return this.periodReview.replyCount;
  }

  get totalReplyCount() {
    return this.totalReview.replyCount;
  }

  get periodAverageRating() {
    return this.periodReview.averageRating;
  }
  get totalAverageRating() {
    return this.totalReview.averageRating;
  }

  // ユーザー数の合計
  get impressions() {
    return this.impression.total;
  }

  // インタラクション数の合計
  get interactions() {
    return this.interaction.total;
  }

  // 期間のクチコミ数の合計（コメントあり＋評価のみ）
  get periodReviews() {
    return this.periodReview.total;
  }

  // 累計のクチコミ数の合計（コメントあり＋評価のみ）
  get totalReviews() {
    return this.totalReview.total;
  }

  /**
   * 対象キー項目のインプレッション数に対する割合を返す
   * @param key
   * @returns
   */
  getRate(key: InteractionStatsType) {
    return divideNullableValue(this[key], this.impressions);
  }

  // ユーザー数に対する各インタラクション数の割合を返す
  getInteractionsRatio(statsType: InteractionStatsType) {
    return this.getRate(statsType);
  }

  add(other: GbpPerformanceMAStats) {
    return new GbpPerformanceMAStats({
      impression: new GbpPerformanceMAStatsImpression({
        desktopMaps: addNullableValues(this.impression.desktopMaps, other.impression.desktopMaps),
        mobileMaps: addNullableValues(this.impression.mobileMaps, other.impression.mobileMaps),
        desktopSearch: addNullableValues(this.impression.desktopSearch, other.impression.desktopSearch),
        mobileSearch: addNullableValues(this.impression.mobileSearch, other.impression.mobileSearch),
      }),
      interaction: new GbpPerformanceMAStatsInteraction({
        callClicks: addNullableValues(this.interaction.callClicks, other.interaction.callClicks),
        conversations: addNullableValues(this.interaction.conversations, other.interaction.conversations),
        bookings: addNullableValues(this.interaction.bookings, other.interaction.bookings),
        directionRequests: addNullableValues(this.interaction.directionRequests, other.interaction.directionRequests),
        websiteClicks: addNullableValues(this.interaction.websiteClicks, other.interaction.websiteClicks),
      }),
      periodReview: new GbpPerformanceMAStatsReview({
        commentCount: addNullableValues(this.periodReview.commentCount, other.periodReview.commentCount),
        rateCount: addNullableValues(this.periodReview.rateCount, other.periodReview.rateCount),
        replyCount: addNullableValues(this.periodReview.replyCount, other.periodReview.replyCount),
        averageRating: this.periodReview.addAverageRating(other.periodReview),
      }),
      totalReview: new GbpPerformanceMAStatsReview({
        commentCount: addNullableValues(this.totalReview.commentCount, other.totalReview.commentCount),
        rateCount: addNullableValues(this.totalReview.rateCount, other.totalReview.rateCount),
        replyCount: addNullableValues(this.totalReview.replyCount, other.totalReview.replyCount),
        averageRating: this.totalReview.addAverageRating(other.totalReview),
      }),
    });
  }
}

class Period extends ImmutableRecord<{
  startDate: Dayjs;
  endDate: Dayjs;
}>({
  startDate: dayjs(),
  endDate: dayjs(),
}) {
  static fromJSON(data: { start_date: string; end_date: string }) {
    return new Period({ startDate: dayjs(data.start_date), endDate: dayjs(data.end_date) });
  }

  mergePeriod(other: Period) {
    return new Period({
      startDate: other.startDate.isBefore(this.startDate) ? other.startDate : this.startDate,
      endDate: other.endDate.isAfter(this.endDate) ? other.endDate : this.endDate,
    });
  }
}

export class GbpPerformanceMAGraphItem extends ImmutableRecord<{
  period: Period;
  stats: GbpPerformanceMAStats;
  organizationCount: number;
}>({
  period: new Period(),
  stats: new GbpPerformanceMAStats(),
  organizationCount: 0,
}) {
  static fromJSON(data: ArrayElement<GbpPerformanceMADataResponse['graph_items']>): GbpPerformanceMAGraphItem {
    return new GbpPerformanceMAGraphItem({
      period: Period.fromJSON(data.period),
      stats: GbpPerformanceMAStats.fromJSON(data.stats),
      organizationCount: data.organization_count,
    });
  }
}

export class GbpPerformanceMAGraphItemList extends ImmutableRecord<{
  list: ImmutableList<GbpPerformanceMAGraphItem>;
}>({ list: ImmutableList() }) {
  static fromJSON(data: GbpPerformanceMADataResponse['graph_items']): GbpPerformanceMAGraphItemList {
    return new GbpPerformanceMAGraphItemList({
      list: ImmutableList(data.map((item) => GbpPerformanceMAGraphItem.fromJSON(item))),
    });
  }
}

export class GbpPerformanceMAGraphData extends ImmutableRecord<{
  target: GbpPerformanceMAGraphItemList;
  comparison: GbpPerformanceMAGraphItemList;
}>({
  target: new GbpPerformanceMAGraphItemList(),
  comparison: new GbpPerformanceMAGraphItemList(),
}) {
  get combined(): GbpPerformanceMAGraphItemList {
    return new GbpPerformanceMAGraphItemList({
      list: this.comparison.list.concat(this.target.list),
    });
  }

  getOverviewGraphData(
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    interactionType: InteractionType,
    aggregateUnit: AggregateUnit,
  ): OverviewGraphDataItem[] {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceMAStats>(),
    );
    return [...Array(targetDates.length)].map((_, index) => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;
      return {
        date: targetDate,
        comparisonDate: comparisonDate,
        impressions: targetData?.impressions,
        interactions: interactionType === 'rate' ? targetData?.getRate('interactions') : targetData?.interactions,
        periodReviews: targetData?.periodReviews,
        comparisonImpressions: comparisonData?.impressions,
        comparisonInteractions:
          interactionType === 'rate' ? comparisonData?.getRate('interactions') : comparisonData?.interactions,
        comparisonPeriodReviews: comparisonData?.periodReviews,
      };
    });
  }

  getPerformanceGraphData(
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    interactionType: InteractionType,
    aggregateUnit: AggregateUnit,
  ): PerformanceGraphDataItem[] {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceMAStats>(),
    );
    return [...Array(targetDates.length)].map((_, index) => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;
      if (interactionType === 'rate') {
        return {
          date: targetDate,
          comparisonDate: comparisonDate,
          impressions: targetData?.impressions,
          interactions: targetData?.getRate('interactions'),
          desktopMaps: targetData?.impression.desktopMaps,
          mobileMaps: targetData?.impression.mobileMaps,
          desktopSearch: targetData?.impression.desktopSearch,
          mobileSearch: targetData?.impression.mobileSearch,
          callClicks: targetData?.getRate('callClicks'),
          conversations: targetData?.getRate('conversations'),
          bookings: targetData?.getRate('bookings'),
          directionRequests: targetData?.getRate('directionRequests'),
          websiteClicks: targetData?.getRate('websiteClicks'),
          comparisonImpressions: comparisonData?.impressions,
          comparisonInteractions: comparisonData?.getRate('interactions'),
          comparisonDesktopMaps: comparisonData?.impression.desktopMaps,
          comparisonMobileMaps: comparisonData?.impression.mobileMaps,
          comparisonDesktopSearch: comparisonData?.impression.desktopSearch,
          comparisonMobileSearch: comparisonData?.impression.mobileSearch,
          comparisonCallClicks: comparisonData?.getRate('callClicks'),
          comparisonConversations: comparisonData?.getRate('conversations'),
          comparisonBookings: comparisonData?.getRate('bookings'),
          comparisonDirectionRequests: comparisonData?.getRate('directionRequests'),
          comparisonWebsiteClicks: comparisonData?.getRate('websiteClicks'),
        };
      } else {
        return {
          date: targetDate,
          comparisonDate: comparisonDate,
          impressions: targetData?.impressions,
          interactions: targetData?.interactions,
          desktopMaps: targetData?.impression.desktopMaps,
          mobileMaps: targetData?.impression.mobileMaps,
          desktopSearch: targetData?.impression.desktopSearch,
          mobileSearch: targetData?.impression.mobileSearch,
          callClicks: targetData?.interaction.callClicks,
          conversations: targetData?.interaction.conversations,
          bookings: targetData?.interaction.bookings,
          directionRequests: targetData?.interaction.directionRequests,
          websiteClicks: targetData?.interaction.websiteClicks,
          comparisonImpressions: comparisonData?.impressions,
          comparisonInteractions: comparisonData?.interactions,
          comparisonDesktopMaps: comparisonData?.impression.desktopMaps,
          comparisonMobileMaps: comparisonData?.impression.mobileMaps,
          comparisonDesktopSearch: comparisonData?.impression.desktopSearch,
          comparisonMobileSearch: comparisonData?.impression.mobileSearch,
          comparisonCallClicks: comparisonData?.interaction.callClicks,
          comparisonConversations: comparisonData?.interaction.conversations,
          comparisonBookings: comparisonData?.interaction.bookings,
          comparisonDirectionRequests: comparisonData?.interaction.directionRequests,
          comparisonWebsiteClicks: comparisonData?.interaction.websiteClicks,
        };
      }
    });
  }

  getReviewGraphData(
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    reviewType: ReviewType,
    aggregateUnit: AggregateUnit,
  ): ReviewGraphDataItem[] {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceMAStats>(),
    );
    return [...Array(targetDates.length)].map((_, index): ReviewGraphDataItem => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;

      return {
        date: targetDate,
        comparisonDate: comparisonDate,
        totalReviews: targetData?.totalReviews,
        totalCommentCount: targetData?.totalReview.commentCount,
        totalRateCount: targetData?.totalReview.rateCount,
        totalReplyCount: targetData?.totalReview.replyCount,
        totalAverageRating: targetData?.totalReview.averageRating,
        periodReviews: targetData?.periodReviews,
        periodCommentCount: targetData?.periodReview.commentCount,
        periodRateCount: targetData?.periodReview.rateCount,
        periodReplyCount: targetData?.periodReview.replyCount,
        periodAverageRating: targetData?.periodReview.averageRating,
        comparisonTotalReviews: comparisonData?.totalReviews,
        comparisonTotalCommentCount: comparisonData?.totalReview.commentCount,
        comparisonTotalRateCount: comparisonData?.totalReview.rateCount,
        comparisonTotalReplyCount: comparisonData?.totalReview.replyCount,
        comparisonTotalAverageRating: comparisonData?.totalReview.averageRating,
        comparisonPeriodReviews: comparisonData?.periodReviews,
        comparisonPeriodCommentCount: comparisonData?.periodReview.commentCount,
        comparisonPeriodRateCount: comparisonData?.periodReview.rateCount,
        comparisonPeriodReplyCount: comparisonData?.periodReview.replyCount,
        comparisonPeriodAverageRating: comparisonData?.periodReview.averageRating,
      };
    });
  }
}

export class GbpPerformanceMATableItem extends ImmutableRecord<{
  organizationId: number;
  period: Period;
  stats: GbpPerformanceMAStats;
  storeCount: number;
}>({
  organizationId: 0,
  period: new Period(),
  stats: new GbpPerformanceMAStats(),
  storeCount: 0,
}) {
  static fromJSON(data: ArrayElement<GbpPerformanceMADataResponse['table_items']>) {
    return new GbpPerformanceMATableItem({
      organizationId: data.organization_id,
      period: Period.fromJSON(data.period),
      stats: GbpPerformanceMAStats.fromJSON(data.stats),
      storeCount: data.store_count,
    });
  }
}

export class GbpPerformanceMATableItemList extends ImmutableRecord<{
  list: ImmutableList<GbpPerformanceMATableItem>;
}>({
  list: ImmutableList(),
}) {
  static fromJSON(data: GbpPerformanceMADataResponse['table_items']) {
    return new GbpPerformanceMATableItemList({
      list: ImmutableList(data.map((item) => GbpPerformanceMATableItem.fromJSON(item))),
    });
  }

  /**
   * パフォーマンスの各指標の合計値を返す
   * @param statsType
   */
  getStatsSumValue(statsType: StatsType) {
    const totalStats = this.list.reduce((sum, item) => sum.add(item.stats), new GbpPerformanceMAStats());
    return totalStats[statsType];
  }

  /**
   * パフォーマンステーブルデータの合計を返す
   * @returns
   */
  getTotal() {
    return this.list.reduce<GbpPerformanceMATableItem>((current, item, index) => {
      if (index === 0) {
        return item;
      }
      return new GbpPerformanceMATableItem({
        period: current.period.mergePeriod(item.period),
        stats: current.stats.add(item.stats),
        storeCount: current.storeCount + item.storeCount,
      });
    }, new GbpPerformanceMATableItem());
  }
}

export class GbpPerformanceMATableData extends ImmutableRecord<{
  target: GbpPerformanceMATableItemList;
  comparison: GbpPerformanceMATableItemList | null;
}>({
  target: new GbpPerformanceMATableItemList(),
  comparison: null,
}) {
  hasComparisonData() {
    return this.comparison !== null;
  }

  getDataPairList() {
    // 比較対象期間のデータがない場合は、comparisonにnullを設定して返す
    if (this.comparison === null) {
      return new GbpPerformanceMATableDataPairList({
        list: this.target.list.map((item) => new GbpPerformanceMATableDataPair({ target: item, comparison: null })),
      });
    }

    // 比較対象のデータを店舗IDをキーにデータを持つ
    const comparisonMap = this.comparison.list.reduce((reduction, value) => {
      return reduction.set(value.organizationId, value);
    }, ImmutableMap<number, GbpPerformanceMATableItem>());

    return new GbpPerformanceMATableDataPairList({
      list: this.target.list.map((item) => {
        return new GbpPerformanceMATableDataPair({
          target: item,
          comparison: comparisonMap.get(item.organizationId) ?? null,
        });
      }),
    });
  }

  /**
   * 合計を返す
   * @returns
   */
  getTotalDataPair() {
    return new GbpPerformanceMATableDataPair({
      target: this.target.getTotal(),
      comparison: this.comparison ? this.comparison.getTotal() : null,
    });
  }

  /**
   * 集計期間におけるパフォーマンスの各指標の合計値を返す
   * @param statsType
   */
  getTargetStatsSumValue(statsType: StatsType): number | null {
    return this.target ? this.target.getStatsSumValue(statsType) : null;
  }

  /**
   * 比較期間におけるパフォーマンスの各指標の合計値を返す
   * @param statsType
   */
  getComparisonStatsSumValue(statsType: StatsType): number | null {
    return this.comparison ? this.comparison.getStatsSumValue(statsType) : null;
  }

  getStatsComparisonData(statsType: StatsType) {
    const targetPeriodSumValue = this.getTargetStatsSumValue(statsType);
    const comparisonPeriodSumValue = this.getComparisonStatsSumValue(statsType);
    const diffValue =
      targetPeriodSumValue != null && comparisonPeriodSumValue != null
        ? targetPeriodSumValue - comparisonPeriodSumValue
        : null;
    const growthRatio =
      targetPeriodSumValue != null && comparisonPeriodSumValue
        ? targetPeriodSumValue / comparisonPeriodSumValue - 1.0
        : null;

    return { targetPeriodSumValue, comparisonPeriodSumValue, diffValue, growthRatio };
  }
}

export class GbpPerformanceMATableDataPair extends ImmutableRecord<{
  target: GbpPerformanceMATableItem;
  comparison: GbpPerformanceMATableItem | null;
}>({
  target: new GbpPerformanceMATableItem(),
  comparison: null,
}) {
  hasComparisonData() {
    return this.comparison !== null;
  }

  /**
   * 対象キー項目の対象期間と比較期間の変化率を返す
   * @param key
   * @returns
   */
  getDiffRate(key: StatsType) {
    const targetStats = this.target.stats[key];
    const comparisonStats = this.comparison?.stats[key];
    const value = divideNullableValue(targetStats, comparisonStats);
    return value != null ? value - 1 : null;
  }

  /**
   * 対象キー項目のインプレッション数に対する割合の対象期間と比較期間の変化率を返す
   * @param key
   * @returns
   */
  getRateDiffRate(key: InteractionStatsType) {
    const targetRate = this.target.stats.getRate(key);
    const comparisonRate = this.comparison?.stats.getRate(key);
    const value = divideNullableValue(targetRate, comparisonRate);
    return value != null ? value - 1 : null;
  }

  getStoreCountDiffRate() {
    const targetStoreCount = this.target.storeCount;
    const comparisonStoreCount = this.comparison?.storeCount;
    const value = divideNullableValue(targetStoreCount, comparisonStoreCount);
    return value != null ? value - 1 : null;
  }
}

/**
 * 同一組織のデータ(対象期間、比較期間)のペアのリスト
 */
export class GbpPerformanceMATableDataPairList extends ImmutableRecord<{
  list: ImmutableList<GbpPerformanceMATableDataPair>;
}>({
  list: ImmutableList(),
}) {
  /**
   * パフォーマンスの表データをソートする
   * @param sortKey ソートキー（値のキー）
   * @param sortType ソートタイプ
   * @param sortOrder ソート順
   * @param sortTarget ソート対象
   * @returns
   */
  sortBy(
    sortKey: Exclude<SortKey, 'organizationName' | 'organizationId'>,
    sortType: SortType,
    sortOrder: SortOrder,
    sortTarget: SortTarget,
  ) {
    return this.update('list', (list) =>
      list.sort((a, b) => {
        let valueA: number | null | undefined;
        let valueB: number | null | undefined;

        if (sortType === 'number') {
          if (sortTarget === 'current') {
            valueA = a.target.stats[sortKey];
            valueB = b.target.stats[sortKey];
          } else if (sortTarget === 'comparison') {
            valueA = a.comparison?.stats[sortKey];
            valueB = b.comparison?.stats[sortKey];
          } else {
            valueA = a.getDiffRate(sortKey);
            valueB = b.getDiffRate(sortKey);
          }
        } else {
          if (
            'websiteClicks' !== sortKey &&
            'callClicks' !== sortKey &&
            'directionRequests' !== sortKey &&
            'conversations' !== sortKey &&
            'bookings' !== sortKey &&
            'interactions' !== sortKey
          ) {
            return 0;
          }
          if (sortTarget === 'current') {
            valueA = a.target.stats.getRate(sortKey);
            valueB = b.target.stats.getRate(sortKey);
          } else if (sortTarget === 'comparison') {
            valueA = a.comparison?.stats.getRate(sortKey);
            valueB = b.comparison?.stats.getRate(sortKey);
          } else {
            valueA = a.getRateDiffRate(sortKey);
            valueB = b.getRateDiffRate(sortKey);
          }
        }

        if (valueA === valueB) {
          return 0;
        }
        if (valueA == null) {
          return 1;
        }
        if (valueB == null) {
          return -1;
        }

        if (sortOrder === 'asc') {
          return valueA < valueB ? -1 : 1;
        }
        return valueA < valueB ? 1 : -1;
      }),
    );
  }

  /**
   * パフォーマンスの表データを組織データを元にソートする
   * @param sortKey ソートキー（organizationName)
   * @param sortOrder ソート順
   * @param organizations
   * @returns
   */
  sortByOrganizationInfo(sortKey: SortKey, sortOrder: SortOrder, organizations: AccountList) {
    return this.update('list', (list) =>
      list.sort((a, b) => {
        const organizationA = organizations.find(a.target.organizationId);
        const organizationB = organizations.find(b.target.organizationId);

        let valueA: string | number | undefined;
        let valueB: string | number | undefined;

        if (sortKey === 'organizationName') {
          valueA = organizationA?.name;
          valueB = organizationB?.name;
        } else {
          valueA = organizationA?.organizationId;
          valueB = organizationB?.organizationId;
        }

        if (valueA === valueB) {
          return 0;
        }
        if (valueA == null) {
          return 1;
        }
        if (valueB == null) {
          return -1;
        }

        if (sortOrder === 'asc') {
          return valueA < valueB ? -1 : 1;
        }
        return valueA < valueB ? 1 : -1;
      }),
    );
  }
}

export class GbpPerformanceMAMonthlyCondition extends ImmutableRecord<{
  target: YearMonth;
  organizationIds: ImmutableList<number>;
}>({
  target: new YearMonth(),
  organizationIds: ImmutableList(),
}) {
  static fromJSON(data: GbpPerformanceMAMonthlyDataResponse['condition']) {
    return new GbpPerformanceMAMonthlyCondition({
      target: YearMonth.fromString(data.month),
      organizationIds: ImmutableList(data.organization_ids),
    });
  }
}

export class GbpPerformanceMAMonthlyItems extends ImmutableRecord<{
  targetMonth: GbpPerformanceMAStats | null;
  lastMonth: GbpPerformanceMAStats | null;
  threeMonthsAgo: GbpPerformanceMAStats | null;
  twelveMonthsAgo: GbpPerformanceMAStats | null;
}>({
  targetMonth: null,
  lastMonth: null,
  threeMonthsAgo: null,
  twelveMonthsAgo: null,
}) {
  static fromJSON(data: GbpPerformanceMAMonthlyDataResponse['items']) {
    return new GbpPerformanceMAMonthlyItems({
      targetMonth: data.month ? GbpPerformanceMAStats.fromJSON(data.month) : null,
      lastMonth: data.last_month ? GbpPerformanceMAStats.fromJSON(data.last_month) : null,
      threeMonthsAgo: data.three_months_ago ? GbpPerformanceMAStats.fromJSON(data.three_months_ago) : null,
      twelveMonthsAgo: data.twelve_months_ago ? GbpPerformanceMAStats.fromJSON(data.twelve_months_ago) : null,
    });
  }

  /**
   * 対象月の前月からの変化率を返す
   * @param statsType
   */
  getMomGrowth(statsType: StatsType): number | null {
    return this.getGrowthRatio(statsType, this.targetMonth, this.lastMonth);
  }

  /**
   * 対象月の前月からの変化率の増減率を返す
   * @param statsType
   */
  getMomGrowthRatio(statsType: InteractionStatsType): number | null {
    return this.getInteractionRateGrowthRatio(statsType, this.targetMonth, this.lastMonth);
  }

  /**
   * 対象月の3ヶ月前からの変化率を返す
   * @param statsType
   */
  getThreeMomGrowth(statsType: StatsType): number | null {
    return this.getGrowthRatio(statsType, this.targetMonth, this.threeMonthsAgo);
  }

  /**
   * 対象月の3ヶ月前からの変化率の増減率を返す
   * @param statsType
   */
  getThreeMomGrowthRatio(statsType: InteractionStatsType): number | null {
    return this.getInteractionRateGrowthRatio(statsType, this.targetMonth, this.threeMonthsAgo);
  }

  /**
   * 対象月の12ヶ月前（前年同月）からの変化率を返す
   * @param statsType
   */
  getYoyGrowth(statsType: StatsType): number | null {
    return this.getGrowthRatio(statsType, this.targetMonth, this.twelveMonthsAgo);
  }

  /**
   * 対象月の12ヶ月前（前年同月）からの変化率の増減率を返す
   * @param statsType
   */
  getYoyGrowthRatio(statsType: InteractionStatsType): number | null {
    return this.getInteractionRateGrowthRatio(statsType, this.targetMonth, this.twelveMonthsAgo);
  }

  /**
   * 前期の値からの変化率（パーセント）を求める
   * @param statsType
   * @param target
   * @param comparison
   * @private
   */
  private getGrowthRatio(
    statsType: StatsType,
    target: GbpPerformanceMAStats | null,
    comparison: GbpPerformanceMAStats | null,
  ): number | null {
    const targetValue = target?.[statsType];
    const comparisonValue = comparison?.[statsType];
    if (targetValue == null || !comparisonValue) {
      return null;
    }
    return targetValue / comparisonValue - 1.0;
  }

  /**
   * 前期のインタラクション率との変化率（パーセント）を求める
   * @param statsType
   * @param target
   * @param comparison
   * @private
   */
  private getInteractionRateGrowthRatio(
    statsType: InteractionStatsType,
    target: GbpPerformanceMAStats | null,
    comparison: GbpPerformanceMAStats | null,
  ): number | null {
    const targetRatio = target?.getInteractionsRatio(statsType);
    const comparisonRatio = comparison?.getInteractionsRatio(statsType);
    if (targetRatio == null || !comparisonRatio) {
      return null;
    }
    return targetRatio / comparisonRatio - 1.0;
  }
}

export class GbpPerformanceMAMonthlyData extends ImmutableRecord<{
  condition: GbpPerformanceMAMonthlyCondition;
  items: GbpPerformanceMAMonthlyItems;
}>({
  condition: new GbpPerformanceMAMonthlyCondition(),
  items: new GbpPerformanceMAMonthlyItems(),
}) {
  static fromJSON(data: GbpPerformanceMAMonthlyDataResponse) {
    return new GbpPerformanceMAMonthlyData({
      condition: GbpPerformanceMAMonthlyCondition.fromJSON(data.condition),
      items: GbpPerformanceMAMonthlyItems.fromJSON(data.items),
    });
  }
}

// 選択中のStatsTypeを保持する
export class GbpPerformanceMAActiveStats extends ImmutableRecord<{
  overview: ImmutableSet<StatsType>;
  impressions: ImmutableSet<StatsType>;
  interactions: ImmutableSet<StatsType>;
  activities: ImmutableSet<StatsType>;
}>({
  overview: ImmutableSet(['impressions', 'interactions'] as const),
  impressions: ImmutableSet(['desktopMaps', 'mobileMaps', 'desktopSearch', 'mobileSearch'] as const),
  interactions: ImmutableSet(['callClicks', 'directionRequests', 'websiteClicks'] as const),
  activities: ImmutableSet([
    'periodCommentCount',
    'periodRateCount',
    'periodReplyCount',
    'periodAverageRating',
  ] as const),
}) {
  static getDefaultByReviewType(reviewType: ReviewType): GbpPerformanceMAActiveStats {
    // newの場合のデフォルト値はperiodだが、reviewTypeを指定してデフォルト値を取得できるようにする
    if (reviewType === 'total') {
      return new GbpPerformanceMAActiveStats().replaceReviewType('period', 'total');
    } else {
      return new GbpPerformanceMAActiveStats().replaceReviewType('total', 'period');
    }
  }

  change(displayType: DisplayType, statsType: StatsType) {
    return this.update(displayType, (activeStats) =>
      activeStats.has(statsType) ? activeStats.delete(statsType) : activeStats.add(statsType),
    );
  }

  // 選択中の項目のReviewTypeのみ変更する
  replaceReviewType(currentReviewType: ReviewType, newReviewType: ReviewType) {
    return this.update('activities', (activeStats) =>
      activeStats.map((statsType) => statsType.replace(currentReviewType, newReviewType) as StatsType),
    );
  }
}
