import dayjs from 'dayjs';
import { Record as ImmutableRecord, List } from 'immutable';

import { convertDateLabel } from 'helpers/graph';
import { AggregateUnit } from 'types/Common';

import {
  GAPerformanceMetric,
  GAPerformanceProgram,
  GMCPerformanceMetric,
  GMCPerformanceProgram,
  GMC_PERFORMANCE_METRICS,
  PERFORMANCE_METRICS,
  PROGRAMS,
  PerformanceMetric,
  ProductStatusStatus,
  Program,
} from '.';

class Period extends ImmutableRecord<{
  from_date: dayjs.Dayjs;
  to_date: dayjs.Dayjs;
}>({
  from_date: dayjs(),
  to_date: dayjs(),
}) {
  static fromJson(json: any): Period {
    return new Period({
      from_date: dayjs(json.from_date),
      to_date: dayjs(json.to_date),
    });
  }
}

class OMOInsightGraphCondition extends ImmutableRecord<{
  merchant_id: string;
  period: Period;
  aggregate_unit: AggregateUnit;
}>({
  merchant_id: '',
  period: new Period(),
  aggregate_unit: 'day',
}) {
  static fromJson(json: any): OMOInsightGraphCondition {
    return new OMOInsightGraphCondition({
      merchant_id: json.merchant_id,
      period: Period.fromJson(json.period),
      aggregate_unit: json.aggregate_unit,
    });
  }
}

class OmoInsightGraphDataProductCount extends ImmutableRecord<{
  [key in ProductStatusStatus]: number;
}>({
  active: 0,
  disapproved: 0,
  expiring: 0,
  pending: 0,
}) {
  /**
   * 商品数が1以上あるかどうかを返す
   * @returns
   */
  hasProduct() {
    return this.active > 0 || this.expiring > 0 || this.pending > 0 || this.disapproved > 0;
  }
}

class OMOInsightGraphItemGMCPerformance extends ImmutableRecord<{
  [key in Exclude<GMCPerformanceMetric, 'ctr'>]: number;
}>({
  impressions: 0,
  clicks: 0,
}) {
  get ctr() {
    if (this.impressions === 0) {
      return null;
    }
    return (this.clicks / this.impressions) * 100;
  }
}

class OMOInsightGraphItemGAPerformance extends ImmutableRecord<{
  [key in GAPerformanceMetric]: number;
}>({
  sessions: 0,
  conversions: 0,
  revenues: 0,
}) {}

class OmoInsightGraphItem extends ImmutableRecord<{
  period: Period;
  products: { [key in Program]: OmoInsightGraphDataProductCount };
  performances: {
    gmc: { [key in GMCPerformanceProgram]: OMOInsightGraphItemGMCPerformance };
    ga: { [key in GAPerformanceProgram]: OMOInsightGraphItemGAPerformance };
  };
}>({
  period: new Period(),
  products: {
    shopping_ads: new OmoInsightGraphDataProductCount(),
    local_inventory_ads: new OmoInsightGraphDataProductCount(),
    free_listings: new OmoInsightGraphDataProductCount(),
    free_local_listings: new OmoInsightGraphDataProductCount(),
  },
  performances: {
    gmc: {
      shopping_ads: new OMOInsightGraphItemGMCPerformance(),
      free_listings: new OMOInsightGraphItemGMCPerformance(),
      free_local_listings: new OMOInsightGraphItemGMCPerformance(),
    },
    ga: {
      free: new OMOInsightGraphItemGAPerformance(),
      ads: new OMOInsightGraphItemGAPerformance(),
    },
  },
}) {
  static fromJson(json: any): OmoInsightGraphItem {
    return new OmoInsightGraphItem({
      period: Period.fromJson(json.period),
      products: {
        shopping_ads: new OmoInsightGraphDataProductCount(json.products.shopping_ads),
        local_inventory_ads: new OmoInsightGraphDataProductCount(json.products.local_inventory_ads),
        free_listings: new OmoInsightGraphDataProductCount(json.products.free_listings),
        free_local_listings: new OmoInsightGraphDataProductCount(json.products.free_local_listings),
      },
      performances: {
        gmc: {
          shopping_ads: new OMOInsightGraphItemGMCPerformance(json.performances.gmc.shopping_ads),
          free_listings: new OMOInsightGraphItemGMCPerformance(json.performances.gmc.free_listings),
          free_local_listings: new OMOInsightGraphItemGMCPerformance(json.performances.gmc.free_local_listings),
        },
        ga: {
          free: new OMOInsightGraphItemGAPerformance(json.performances.ga.free),
          ads: new OMOInsightGraphItemGAPerformance(json.performances.ga.ads),
        },
      },
    });
  }
}

export class OMOInsightGraphData extends ImmutableRecord<{
  condition: OMOInsightGraphCondition;
  items: List<OmoInsightGraphItem>;
}>({
  condition: new OMOInsightGraphCondition(),
  items: List(),
}) {
  static fromJson(json: any): OMOInsightGraphData {
    return new OMOInsightGraphData({
      condition: OMOInsightGraphCondition.fromJson(json.condition),
      items: List(json.items.map((item: any) => OmoInsightGraphItem.fromJson(item))),
    });
  }

  toOmoInsightProductStatusGraphData() {
    return new OmoInsightProductStatusGraphData({
      condition: this.condition,
      items: this.items.map((item) => {
        return new OmoInsightProductStatusGraphItem({
          period: item.period,
          products: item.products,
        });
      }),
    });
  }

  toOmoInsightPerformanceGraphData() {
    return new OmoInsightPerformanceGraphData({
      condition: this.condition,
      items: this.items.map((item) => {
        return new OmoInsightPerformanceGraphItem({
          period: item.period,
          performances: item.performances,
        });
      }),
    });
  }
}

export type ProductStatusGraphItem = {
  date: string;
  active: number;
  disapproved: number;
  expiring: number;
  pending: number;
};

export class CompositeOMOInsightGraphData extends ImmutableRecord<{
  target: OMOInsightGraphData;
  comparison: OMOInsightGraphData | null;
}>({
  target: new OMOInsightGraphData(),
  comparison: new OMOInsightGraphData(),
}) {
  toProductStatusGraphData() {
    return new CompositeProductStatusGraphData({
      target: this.target.toOmoInsightProductStatusGraphData(),
      comparison: this.comparison?.toOmoInsightProductStatusGraphData() || null,
    });
  }

  toPerformanceGraphData() {
    return new CompositePerformanceGraphData({
      target: this.target.toOmoInsightPerformanceGraphData(),
      comparison: this.comparison?.toOmoInsightPerformanceGraphData() || null,
    });
  }
}

class OmoInsightProductStatusGraphItem extends ImmutableRecord<{
  period: Period;
  products: { [key in Program]: OmoInsightGraphDataProductCount };
}>({
  period: new Period(),
  products: {
    shopping_ads: new OmoInsightGraphDataProductCount(),
    local_inventory_ads: new OmoInsightGraphDataProductCount(),
    free_listings: new OmoInsightGraphDataProductCount(),
    free_local_listings: new OmoInsightGraphDataProductCount(),
  },
}) {}

export class OmoInsightProductStatusGraphData extends ImmutableRecord<{
  condition: OMOInsightGraphCondition;
  items: List<OmoInsightProductStatusGraphItem>;
}>({
  condition: new OMOInsightGraphCondition(),
  items: List(),
}) {
  get activePrograms(): Program[] {
    // 商品数が1以上あるプログラムは有効なプログラムとする
    const programs: Program[] = PROGRAMS.filter(
      (parogram) => this.items.find((item) => item.products[parogram].hasProduct()) !== undefined,
    );
    return programs;
  }
}

export class CompositeProductStatusGraphData extends ImmutableRecord<{
  target: OmoInsightProductStatusGraphData;
  comparison: OmoInsightProductStatusGraphData | null;
}>({
  target: new OmoInsightProductStatusGraphData(),
  comparison: null,
}) {
  hasComparisonData() {
    return this.comparison !== null;
  }
  getPorductStatusGraphData(program: Program | null, forComparison = false): ProductStatusGraphItem[] {
    if (program === null) {
      return [];
    }

    const data = forComparison ? this.comparison : this.target;
    if (data === null) {
      return [];
    }
    return data.items
      .map((item) => {
        const productStatus = item.products[program];
        return {
          date: convertDateLabel(item.period.from_date, data.condition.aggregate_unit),
          active: productStatus.active,
          disapproved: productStatus.disapproved,
          expiring: productStatus.expiring,
          pending: productStatus.pending,
        };
      })
      .toArray();
  }

  /**
   * 有効なプログラムのリストを取得する
   */
  get activePrograms(): Program[] {
    // 有効なプログラムとは、データ期間内に少なくとも１回は、商品数が1以上あるプログラムのこと
    const targetActivePrograms = this.target.activePrograms;
    const comparisonActivePrograms = this.comparison?.activePrograms || [];
    return PROGRAMS.filter(
      (program) => targetActivePrograms.includes(program) || comparisonActivePrograms.includes(program),
    );
  }
}

export type GMCPerformanceGraphItem = {
  date: string;
  // クリック率がnullになることがあるため、null許容型にしている
  shopping_ads: number | null;
  free_listings: number | null;
  free_local_listings: number | null;

  // 比較期間のデータ
  comparison_date?: string;
  comparison_shopping_ads?: number | null;
  comparison_free_listings?: number | null;
  comparison_free_local_listings?: number | null;
};

export type GAPerformanceGraphItem = {
  date: string;
  free: number;
  ads: number;

  // 比較期間のデータ
  comparison_date?: string;
  comparison_free?: number;
  comparison_ads?: number;
};

export type PerformanceGraphItem = GMCPerformanceGraphItem | GAPerformanceGraphItem;

class OmoInsightPerformanceGraphItem extends ImmutableRecord<{
  period: Period;
  performances: {
    gmc: { [key in GMCPerformanceProgram]: OMOInsightGraphItemGMCPerformance };
    ga: { [key in GAPerformanceProgram]: OMOInsightGraphItemGAPerformance };
  };
}>({
  period: new Period(),
  performances: {
    gmc: {
      shopping_ads: new OMOInsightGraphItemGMCPerformance(),
      free_listings: new OMOInsightGraphItemGMCPerformance(),
      free_local_listings: new OMOInsightGraphItemGMCPerformance(),
    },
    ga: {
      free: new OMOInsightGraphItemGAPerformance(),
      ads: new OMOInsightGraphItemGAPerformance(),
    },
  },
}) {}

export class OmoInsightPerformanceGraphData extends ImmutableRecord<{
  condition: OMOInsightGraphCondition;
  items: List<OmoInsightPerformanceGraphItem>;
}>({
  condition: new OMOInsightGraphCondition(),
  items: List(),
}) {
  get activeMetrics() {
    // 商品数が1以上あるプログラムは有効なプログラムとする
    return PERFORMANCE_METRICS.filter((metric) => this.hasMetric(metric));
  }

  hasMetric(metric: GMCPerformanceMetric | GAPerformanceMetric) {
    if (GMC_PERFORMANCE_METRICS.includes(metric as GMCPerformanceMetric)) {
      return this.items.find((item) => {
        return (
          (item.performances.gmc.shopping_ads[metric as GMCPerformanceMetric] || 0) > 0 ||
          (item.performances.gmc.free_listings[metric as GMCPerformanceMetric] || 0) > 0 ||
          (item.performances.gmc.free_local_listings[metric as GMCPerformanceMetric] || 0) > 0
        );
      });
    }
    return this.items.find((item) => {
      return (
        item.performances.ga.ads[metric as GAPerformanceMetric] > 0 ||
        item.performances.ga.free[metric as GAPerformanceMetric] > 0
      );
    });
  }
}

export class CompositePerformanceGraphData extends ImmutableRecord<{
  target: OmoInsightPerformanceGraphData;
  comparison: OmoInsightPerformanceGraphData | null;
}>({
  target: new OmoInsightPerformanceGraphData(),
  comparison: null,
}) {
  hasComparisonData() {
    return this.comparison !== null && this.comparison.items.size > 0;
  }

  getPerformanceGraphData(metric: PerformanceMetric | null): GMCPerformanceGraphItem[] | GAPerformanceGraphItem[] {
    if (metric === null) {
      return [];
    }

    if (GMC_PERFORMANCE_METRICS.includes(metric as GMCPerformanceMetric)) {
      return this.getGMCPerformanceGraphData(metric as GMCPerformanceMetric);
    }
    return this.getGAPerformanceGraphData(metric as GAPerformanceMetric);
  }

  getGMCPerformanceGraphData(metric: GMCPerformanceMetric): GMCPerformanceGraphItem[] {
    const graphData: GMCPerformanceGraphItem[] = this.target.items
      .map((item) => ({
        date: convertDateLabel(item.period.from_date, this.target.condition.aggregate_unit),
        shopping_ads: item.performances.gmc.shopping_ads[metric],
        free_listings: item.performances.gmc.free_listings[metric],
        free_local_listings: item.performances.gmc.free_local_listings[metric],
      }))
      .toArray();

    if (this.comparison !== null) {
      this.comparison.items.forEach((item, index) => {
        if (graphData[index] === undefined) {
          return;
        }
        graphData[index] = {
          ...graphData[index],
          comparison_date: convertDateLabel(item.period.from_date, this.comparison!.condition.aggregate_unit),
          comparison_shopping_ads: item.performances.gmc.shopping_ads[metric],
          comparison_free_listings: item.performances.gmc.free_listings[metric],
          comparison_free_local_listings: item.performances.gmc.free_local_listings[metric],
        };
      });
    }

    return graphData;
  }

  getGAPerformanceGraphData(metric: GAPerformanceMetric): GAPerformanceGraphItem[] {
    const graphData: GAPerformanceGraphItem[] = this.target.items
      .map((item) => ({
        date: convertDateLabel(item.period.from_date, this.target.condition.aggregate_unit),
        ads: item.performances.ga.ads[metric],
        free: item.performances.ga.free[metric],
      }))
      .toArray();

    if (this.comparison !== null) {
      this.comparison.items.forEach((item, index) => {
        if (graphData[index] === undefined) {
          return;
        }
        graphData[index] = {
          ...graphData[index],
          comparison_date: convertDateLabel(item.period.from_date, this.comparison!.condition.aggregate_unit),
          comparison_ads: item.performances.ga.ads[metric],
          comparison_free: item.performances.ga.free[metric],
        };
      });
    }

    return graphData;
  }

  /**
   * 有効なメトリックのリストを取得する
   */
  get activeMetrics(): PerformanceMetric[] {
    // 有効なメトリックとは、データ期間内に少なくとも１回は、値が1以上あるメトリックのこと
    const targetActiveMetrics = this.target.activeMetrics;
    const comparisonActiveMetrics = this.comparison?.activeMetrics || [];
    return PERFORMANCE_METRICS.filter(
      (metric) => targetActiveMetrics.includes(metric) || comparisonActiveMetrics.includes(metric),
    );
  }
}
