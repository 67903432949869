import actionCreatorFactory from 'typescript-fsa';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { MapSearchRankConfigImportResult } from 'models/Domain/MapSearchRank/MapSearchRankConfigImportResult';
import { MapSearchRankConfigValidationResult } from 'models/Domain/MapSearchRank/MapSearchRankConfigValidationResult';

const actionCreator = actionCreatorFactory('MapSearchRankConfig');

export const MapSearchRankConfigImportActions = {
  clearStatus: actionCreator('clearStatus'),

  validateCsv: actionCreator<{ file: File }>('validateCsv'),
  setIsValidateProcessing: actionCreator<boolean>('setIsValidateProcessing'),
  setValidationExecutionArn: actionCreator<ExecutionArnStatus | null>('setValidationExecutionArn'),
  checkValidationStatus: actionCreator('checkValidationStatus'),
  setValidationResult: actionCreator<MapSearchRankConfigValidationResult>('setValidationResult'),

  importCsv: actionCreator<{ file: File }>('importCsv'),
  setIsImportProcessing: actionCreator<boolean>('setIsImportProcessing'),
  setImportExecutionArn: actionCreator<ExecutionArnStatus | null>('setImportExecutionArn'),
  checkImportStatus: actionCreator('checkImportStatus'),
  setImportResult: actionCreator<MapSearchRankConfigImportResult | null>('setImportResult'),
};
