import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';

import { SearchKeywordsKeywordGraphApi, SearchKeywordsKeywordTableApi } from 'ApiClient/GmbApi/SearchKeywordApi';
import { Button } from 'components/atoms/Button';
import { CsvDownloadButton } from 'components/molecules/CsvDownloadButton';
import { AnalysisMemoTable } from 'components/pageComponents/Analysis/AnalysisMemoTable';
import { MemoDisplaySettings } from 'helpers/graph';
import { MemoList, Memo as MemoModel, MemoTagList } from 'models/Domain/Memo/Memo';
import { KeywordGraphData, KeywordTableData, getMonthLabel } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SearchKeywordSearchCondition } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';

import { SearchKeywordsDetailGraph } from './SearchKeywordsDetailGraph';
import { GraphKey, SUMMARY_ROW, SearchKeywordsDetailTable, SortKey, SortOrder } from './SearchKeywordsDetailTable';

type Props = {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  keyword: string;
  isSummary: boolean; // trueの場合、全キーワードの合算値を店舗別に表示
  searchCondition: SearchKeywordSearchCondition;
  displaySearchVolumeLink: boolean;
  stores: Stores;
  onClickCsvDownload: () => void;
  isProcessingCsvDownload: boolean;
  memoList: MemoList; // 施策メモ
  memoTagList: MemoTagList; // 選択可能なタグの一覧
  memoDisplaySettings: MemoDisplaySettings; // メモの表示設定
  setMemoDisplaySettings: (setting: MemoDisplaySettings) => void; // メモの表示設定が変更された
  onCreateMemo: (memo: MemoModel) => void; // メモの保存ボタンが押された (新規作成)
  onUpdateMemo: (memo: MemoModel) => void; // メモの保存ボタンが押された (更新)
  onDeleteMemo: (memo: MemoModel) => void; // メモの削除ボタンが押された
  isLoadingMemoList: boolean; // 施策メモを読み込み中か
  isLoadingMemoTagList: boolean; // 選択可能なタグを読み込み中か
};

const DEFAULT_SORT_KEY = 'avg';
const DEFAULT_SORT_ORDER = 'desc';

/** キーワードの店舗別流入数データを取得 */
const fetchSearchKeywordGraphData = async (
  searchCondition: SearchKeywordSearchCondition,
  word: string,
  summary: boolean, // trueの場合、全キーワードを店舗別に取得する
) => {
  // キーワードの指定も合計行の指定もない場合何もしない
  if (!word && !summary) {
    return;
  }

  const params = searchCondition.toKeywordGraphDataRequestParams(word);
  const response = await SearchKeywordsKeywordGraphApi.get(params);
  let graphData;
  if (response.isSuccess) {
    graphData = KeywordGraphData.fromJSON(response.data);
  }
  return graphData;
};

const fetchSearchKeywordTableData = async (
  searchCondition: SearchKeywordSearchCondition,
  word: string,
  summary: boolean, // trueの場合、全キーワードを店舗別に取得する
) => {
  // キーワードの指定も合計行の指定もない場合何もしない
  if (!word && !summary) {
    return;
  }

  const params = searchCondition.toKeywordTableDataRequestParams(word);
  params.limit = 10000; // 全店舗分取得してフロント側でソートを行うため大きな数を指定
  const response = await SearchKeywordsKeywordTableApi.get(params);
  let tableData;
  if (response.isSuccess) {
    tableData = KeywordTableData.fromJSON(response.data);
  }
  return tableData;
};

/** キーワードの店舗別流入数グラフデータを取得するhook */
const useSearchKeywordDetailGraphData = (
  searchCondition: SearchKeywordSearchCondition,
  word: string,
  summary: boolean,
): {
  graphData: KeywordGraphData | undefined;
  isLoading: boolean;
} => {
  const { data, error } = useSWRImmutable([searchCondition, word, summary, 'graph'], fetchSearchKeywordGraphData);
  return { graphData: data as KeywordGraphData, isLoading: !data && !error };
};

/** キーワードの店舗別流入数テーブルデータを取得するhook */
const useSearchKeywordDetailTableData = (
  searchCondition: SearchKeywordSearchCondition,
  word: string,
  summary: boolean,
): {
  tableData: KeywordTableData | undefined;
  isLoading: boolean;
} => {
  const { data, error } = useSWRImmutable([searchCondition, word, summary, 'table'], fetchSearchKeywordTableData);
  return { tableData: data as KeywordTableData, isLoading: !data && !error };
};

/** テーブルデータのソート関数 */
const comparator = <S, T extends string | number | null | undefined>(
  a: S,
  b: S,
  getValue: (item: S) => T,
  sortOrder: SortOrder,
): number => {
  const valueA = getValue(a);
  const valueB = getValue(b);

  // nullの場合は、値のあるデータより後ろに表示する
  if (valueA === valueB) {
    return 0;
  } else if (valueA == null) {
    return 1;
  } else if (valueB == null) {
    return -1;
  }

  if (sortOrder === 'asc') {
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  } else {
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }
};

/**
 * 店舗別の流入数を表示するモーダル
 */
export const SearchKeywordsDetailModal = React.memo<Props>(
  ({
    isOpen,
    onClose,
    keyword,
    isSummary,
    searchCondition,
    displaySearchVolumeLink,
    stores,
    onClickCsvDownload,
    isProcessingCsvDownload,
    memoList,
    memoTagList,
    memoDisplaySettings,
    setMemoDisplaySettings,
    onCreateMemo,
    onUpdateMemo,
    onDeleteMemo,
    isLoadingMemoList,
    isLoadingMemoTagList,
  }) => {
    const { startMonth, endMonth } = searchCondition.filter;
    const [sortKey, setSortKey] = useState<SortKey>(DEFAULT_SORT_KEY);
    const [sortOrder, setSortOrder] = useState<SortOrder>(DEFAULT_SORT_ORDER);

    // 店舗別のグラフ・テーブルデータを取得
    const { graphData, isLoading: isGraphDataLoading } = useSearchKeywordDetailGraphData(
      searchCondition,
      keyword,
      isSummary,
    );
    const { tableData, isLoading: isTableDataLoading } = useSearchKeywordDetailTableData(
      searchCondition,
      keyword,
      isSummary,
    );

    const titleLabel = isSummary ? '合計' : keyword;

    // 表示中のグラフ デフォルトは合計行のみ
    const [activeGraphs, setActiveGraphs] = useState<ImmutableSet<GraphKey>>(ImmutableSet([SUMMARY_ROW as GraphKey]));
    // グラフを表示中の店舗ID
    const selectedStoreIds = activeGraphs.filter((key) => key !== SUMMARY_ROW) as ImmutableSet<number>;
    // 合計行のグラフを表示するか
    const showSummary = activeGraphs.includes(SUMMARY_ROW);
    // 選択中の施策メモID
    const [selectingMemoIds, setSelectingMemoIds] = useState<ImmutableSet<number>>(ImmutableSet());

    // 表示中のグラフが変更された
    const onToggleDisplayGraph = useCallback(
      (key: GraphKey) => {
        // 対象行の表示・非表示を変更
        let updatedActiveGraphs: ImmutableSet<GraphKey>;
        if (activeGraphs.includes(key)) {
          updatedActiveGraphs = activeGraphs.delete(key);
        } else {
          updatedActiveGraphs = activeGraphs.add(key);
        }
        setActiveGraphs(updatedActiveGraphs);
      },
      [activeGraphs, setActiveGraphs],
    );

    // 並び替え対象の項目が変更された
    const onChangeSort = useCallback(
      (newSortKey: SortKey) => {
        let order: SortOrder;
        if (newSortKey !== sortKey) {
          order = 'desc';
        } else {
          switch (sortOrder) {
            case 'asc':
              order = 'desc';
              break;
            case 'desc':
              order = 'asc';
              break;
            default:
              order = 'asc';
              break;
          }
        }
        setSortKey(newSortKey);
        setSortOrder(order);
      },
      [sortKey, sortOrder],
    );

    // 並び替え対象のキーでソートしたテーブルデータ
    const orderedTableData = useMemo(() => {
      if (!tableData) {
        return;
      }

      const items = tableData.items;
      let sortedItems = items;

      switch (sortKey) {
        case 'storeName':
          sortedItems = items?.sort((a, b) =>
            comparator(a, b, (item) => stores.map.get(item.storeId)?.fullName, sortOrder),
          );
          break;
        case 'storeCode':
          sortedItems = items?.sort((a, b) =>
            comparator(a, b, (item) => stores.map.get(item.storeId)?.code, sortOrder),
          );
          break;
        case 'avg':
          sortedItems = items?.sort((a, b) => comparator(a, b, (item) => item.value.monthlyAverageCount, sortOrder));
          break;
        case 'count':
          sortedItems = items?.sort((a, b) => comparator(a, b, (item) => item.value.targetMonthCount, sortOrder));
          break;
        case 'momDiff':
          sortedItems = items?.sort((a, b) => comparator(a, b, (item) => item.value.momDiff, sortOrder));
          break;
        case 'momRatio':
          sortedItems = items?.sort((a, b) => comparator(a, b, (item) => item.value.momRatio, sortOrder));
          break;
        case 'threeMomRatio':
          sortedItems = items?.sort((a, b) => comparator(a, b, (item) => item.value.threeMomRatio, sortOrder));
          break;
        case 'yoyRatio':
          sortedItems = items?.sort((a, b) => comparator(a, b, (item) => item.value.yoyRatio, sortOrder));
          break;
      }

      return tableData.set('items', sortedItems);
    }, [tableData, sortKey, sortOrder, stores]);

    return (
      <StyledModal open={isOpen} size={'fullscreen'} onClose={onClose}>
        <ModalContent>
          <Wrapper>
            <Title>{titleLabel}</Title>
            <ContentWrapper>
              <CsvDownloadButtonWrapper>
                <CsvDownloadButton
                  label='CSVダウンロード'
                  disabled={isProcessingCsvDownload}
                  isProcessing={isProcessingCsvDownload}
                  onClick={onClickCsvDownload}
                />
              </CsvDownloadButtonWrapper>
              <Content>
                <SearchKeywordsDetailGraph
                  isLoading={isGraphDataLoading}
                  searchCondition={searchCondition}
                  graphData={graphData}
                  showSummary={showSummary}
                  selectedStoreIds={selectedStoreIds}
                  onClickLegend={onToggleDisplayGraph}
                  memoList={memoList}
                  memoDisplaySettings={memoDisplaySettings}
                  onClickMemo={(ids) => setSelectingMemoIds(ImmutableSet(ids))}
                />
              </Content>
              <Content>
                <AnalysisMemoTable
                  memoList={memoList}
                  activeMemoIds={selectingMemoIds}
                  clearActiveMemoIds={() => setSelectingMemoIds(ImmutableSet())}
                  tags={memoTagList}
                  memoDisplaySettings={memoDisplaySettings}
                  setMemoDisplaySettings={setMemoDisplaySettings}
                  onCreateMemo={onCreateMemo}
                  onUpdateMemo={onUpdateMemo}
                  onDeleteMemo={onDeleteMemo}
                  isLoadingMemoList={isLoadingMemoList}
                  isLoadingMemoTagList={isLoadingMemoTagList}
                  stickyPosition={'Modal'}
                />
              </Content>
              <Content>
                <ConditionWrapper>
                  <TargetText>{`集計対象：${getMonthLabel(startMonth)}${
                    startMonth == endMonth ? '' : `〜${getMonthLabel(endMonth)}`
                  }`}</TargetText>
                </ConditionWrapper>
                <SearchKeywordsDetailTable
                  searchWord={keyword}
                  tableData={orderedTableData}
                  isLoading={isTableDataLoading}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  stores={stores}
                  activeGraphs={activeGraphs}
                  displaySearchVolumeLink={displaySearchVolumeLink}
                  onChangeSort={(sortKey) => onChangeSort(sortKey)}
                  onToggleDisplayGraph={(key) => onToggleDisplayGraph(key)}
                />
              </Content>
            </ContentWrapper>
            <ModalFooter>
              <StyledButton onClick={onClose}>閉じる</StyledButton>
            </ModalFooter>
          </Wrapper>
        </ModalContent>
      </StyledModal>
    );
  },
);

const StyledModal = styled(Modal)`
  height: 80%;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

const CsvDownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
  margin-left: auto;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const ConditionWrapper = styled.div`
  margin-bottom: 16px;
`;

const TargetText = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 14px;
  font-weight: bold;
`;
