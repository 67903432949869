import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

export interface Option {
  text: string;
  value: string;
}

export class GmbCategory extends Record<{
  categoryId: string;
  displayName: string;
}>({
  categoryId: '',
  displayName: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }
}

export class GmbCategories extends Record<{
  list: List<GmbCategory>;
}>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data && data.map((p) => new GmbCategory(p)));
    super({ list });
  }

  generateDisplayName(value: string): string {
    return (
      this.list.find((target) => {
        return target.categoryId === value;
      })?.displayName || ''
    );
  }

  generateSingleCategoryOption(value: string): Option[] {
    return this.list
      .filter((target) => {
        return target.categoryId === value;
      })
      .map((target) => {
        return {
          text: target.displayName,
          value: target.categoryId,
        };
      })
      .toArray();
  }

  generateCategoryOption(value: string): Option[] {
    return this.list
      .filter((target, idx) => {
        if (!value) {
          return idx <= 10;
        }
        return target.displayName.indexOf(value) > -1;
      })
      .map((target) => {
        return {
          text: target.displayName,
          value: target.categoryId,
        };
      })
      .toArray();
  }

  findByCategoryId(categoryId: string): GmbCategory | undefined {
    return this.list.find((category) => category.categoryId === categoryId);
  }
}
