import { List as ImmutableList, Record as ImmutableRecord, Set as ImmutableSet, is } from 'immutable';

import { JSObject } from 'types/Common';

import { DAYS_OF_WEEK, DayOfWeek, GmbTimePeriods } from './GmbTimePeriod';
import { GmbTimePeriodString } from './GmbTimePeriodString';

/** 曜日ごとの営業時間 */
export class BusinessHours extends ImmutableRecord<{
  sunday: GmbTimePeriods;
  monday: GmbTimePeriods;
  tuesday: GmbTimePeriods;
  wednesday: GmbTimePeriods;
  thursday: GmbTimePeriods;
  friday: GmbTimePeriods;
  saturday: GmbTimePeriods;
}>({
  sunday: new GmbTimePeriods(),
  monday: new GmbTimePeriods(),
  tuesday: new GmbTimePeriods(),
  wednesday: new GmbTimePeriods(),
  thursday: new GmbTimePeriods(),
  friday: new GmbTimePeriods(),
  saturday: new GmbTimePeriods(),
}) {
  static fromJSON(data: JSObject) {
    const params: JSObject = {};
    DAYS_OF_WEEK.forEach((day: DayOfWeek) => {
      params[day.toLowerCase()] = new GmbTimePeriods(data.filter((param: JSObject) => param.openDay === day));
    });
    return new BusinessHours(params);
  }

  get businessHoursForEdit() {
    interface ListType {
      type: DayOfWeek;
      name: string;
      gmbTimePeriods: GmbTimePeriods;
    }

    const result: ListType[] = [
      { type: 'SUNDAY', name: '日曜日', gmbTimePeriods: this.sunday },
      { type: 'MONDAY', name: '月曜日', gmbTimePeriods: this.monday },
      { type: 'TUESDAY', name: '火曜日', gmbTimePeriods: this.tuesday },
      { type: 'WEDNESDAY', name: '水曜日', gmbTimePeriods: this.wednesday },
      { type: 'THURSDAY', name: '木曜日', gmbTimePeriods: this.thursday },
      { type: 'FRIDAY', name: '金曜日', gmbTimePeriods: this.friday },
      { type: 'SATURDAY', name: '土曜日', gmbTimePeriods: this.saturday },
    ];
    return result;
  }

  /** 各曜日を大文字から小文字に変換する */
  convertLowerCase(dayOfWeek: string) {
    switch (dayOfWeek) {
      case 'SUNDAY':
        return 'sunday';
      case 'MONDAY':
        return 'monday';
      case 'TUESDAY':
        return 'tuesday';
      case 'WEDNESDAY':
        return 'wednesday';
      case 'THURSDAY':
        return 'thursday';
      case 'FRIDAY':
        return 'friday';
      case 'SATURDAY':
        return 'saturday';
      default:
        throw new TypeError();
    }
  }

  /**
   * 指定の曜日の営業日/定休日を切り替える
   * @param dayOfWeek
   * @param isOpen
   */
  toggleOpenDay(dayOfWeek: DayOfWeek, isOpen: boolean) {
    return isOpen ? this.changeOpenDay(dayOfWeek) : this.changeCloseDay(dayOfWeek);
  }

  /** 指定の曜日を営業日にする */
  private changeOpenDay(dayOfWeek: DayOfWeek) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.addOpenDay(dayOfWeek));
  }

  /** 指定の曜日を定休日にする */
  private changeCloseDay(dayOfWeek: DayOfWeek) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.closeDay());
  }

  /** 時間を変更する */
  changePeriod(dayOfWeek: DayOfWeek, index: number, openTime: GmbTimePeriodString, closeTime: GmbTimePeriodString) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods: GmbTimePeriods) =>
      periods.changePeriod(index, openTime, closeTime),
    );
  }

  /** 営業時間を削除する */
  removePeriod(dayOfWeek: DayOfWeek, index: number) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.removePeriod(index));
  }

  /** 営業時間を追加する */
  addOpenDay(dayOfWeek: DayOfWeek) {
    return this.update(this.convertLowerCase(dayOfWeek), (periods) => periods.addOpenDay(dayOfWeek));
  }

  get validate() {
    const validate = [
      this.sunday.validate,
      this.monday.validate,
      this.tuesday.validate,
      this.wednesday.validate,
      this.thursday.validate,
      this.friday.validate,
      this.saturday.validate,
    ];

    const errors = validate.filter((day) => !day.isValid);
    const isValid = errors.length === 0;

    return {
      isValid,
      error: isValid ? '' : errors[0].error,
    };
  }

  get updateParams() {
    return {
      periods: [
        ...this.sunday.requestParams(),
        ...this.monday.requestParams(),
        ...this.tuesday.requestParams(),
        ...this.wednesday.requestParams(),
        ...this.thursday.requestParams(),
        ...this.friday.requestParams(),
        ...this.saturday.requestParams(),
      ],
    };
  }
}

export class MoreHoursType extends ImmutableRecord<{
  hoursTypeId: string;
  displayName: string;
  localizedDisplayName: string;
}>({
  hoursTypeId: '',
  displayName: '',
  localizedDisplayName: '',
}) {
  static fromJSON(data: JSObject) {
    return new MoreHoursType({ ...data });
  }
}

export class MoreHours extends ImmutableRecord<{
  hoursTypeId: string;
  businessHours: BusinessHours;
}>({
  hoursTypeId: '',
  businessHours: new BusinessHours(),
}) {
  static fromJSON(data: JSObject) {
    return new MoreHours({
      hoursTypeId: data.hoursTypeId,
      businessHours: BusinessHours.fromJSON(data.periods),
    });
  }

  changeBusinessHours(businessHours: BusinessHours) {
    return this.set('businessHours', businessHours);
  }

  /**
   * 一覧画面での表示
   */

  get moreHoursForDisplay() {
    const businessHours = this.businessHours;
    return [
      `日曜日 ${businessHours.sunday.periodsForDisplay}`,
      `月曜日 ${businessHours.monday.periodsForDisplay}`,
      `火曜日 ${businessHours.tuesday.periodsForDisplay}`,
      `水曜日 ${businessHours.wednesday.periodsForDisplay}`,
      `木曜日 ${businessHours.thursday.periodsForDisplay}`,
      `金曜日 ${businessHours.friday.periodsForDisplay}`,
      `土曜日 ${businessHours.saturday.periodsForDisplay}`,
    ];
  }

  get validate() {
    return this.businessHours.validate;
  }
}

export class MoreHoursList extends ImmutableRecord<{
  list: ImmutableList<MoreHours>;
}>({
  list: ImmutableList(),
}) {
  static fromJSON(data: JSObject[]) {
    return new MoreHoursList({
      list: ImmutableList(data.map((item: JSObject) => MoreHours.fromJSON(item))),
    });
  }

  find(moreHoursType: MoreHoursType) {
    return this.list.find((item) => item.hoursTypeId === moreHoursType.hoursTypeId);
  }

  filter(moreHoursTypes: ImmutableList<MoreHoursType>) {
    const availableHoursTypeIds = ImmutableSet(moreHoursTypes.map((item) => item.hoursTypeId));
    return this.update('list', (list) => list.filter((item: MoreHours) => availableHoursTypeIds.has(item.hoursTypeId)));
  }

  addMoreHours(hoursType: MoreHoursType) {
    return this.update('list', (list) => list.push(new MoreHours({ hoursTypeId: hoursType.hoursTypeId })));
  }

  removeMoreHours(hoursType: MoreHoursType) {
    return this.update('list', (list) => list.filter((moreHours) => moreHours.hoursTypeId !== hoursType.hoursTypeId));
  }

  changeMoreHours(hoursType: MoreHoursType, value: MoreHours) {
    return this.update('list', (list) =>
      list.map((item) => (item.hoursTypeId === hoursType.hoursTypeId ? value : item)),
    );
  }

  get validate() {
    const validate = this.list.map((item) => item.validate).toArray();

    const errors = validate.filter((day) => !day.isValid);
    const isValid = errors.length === 0;

    return {
      isValid,
      error: isValid ? '' : errors[0].error,
    };
  }

  get updateParams() {
    return this.list
      .map((moreHours) => ({ hoursTypeId: moreHours.hoursTypeId, ...moreHours.businessHours.updateParams }))
      .toArray();
  }
}

/**
 * 2つのmoreHoursが同じ値を持つかを返す
 * @param a
 * @param b
 */
export const isSameMoreHours = (a: MoreHours | undefined, b: MoreHours | undefined) => {
  if (a === b) {
    return true;
  }
  if (a === undefined || b === undefined) {
    return false;
  }
  return is(a, b);
};

/**
 * 2つのその他の営業時間のうち、差分のあるhoursTypeIdを返す
 * @param a
 * @param b
 */
export const getDiffMoreHoursTypeIds = (a: MoreHoursList, b: MoreHoursList) => {
  return ImmutableList([...a.list.toArray(), ...b.list.toArray()])
    .map((moreHours) => moreHours.hoursTypeId)
    .toSet()
    .filter(
      (hoursType) =>
        !isSameMoreHours(
          a.list.find((item) => item.hoursTypeId === hoursType),
          b.list.find((item) => item.hoursTypeId === hoursType),
        ),
    );
};
