import React from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { COLOR } from 'style/color';

type Props = {
  label?: string | React.ReactNode;
  subLabel?: string;
  showEditButton?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const StoreItemCard = React.memo<Props>(({ label, subLabel, showEditButton, onClick, children }) => {
  return (
    <Wrapper onClick={onClick}>
      <LeftContent>
        {label && (
          <Label>
            {label}
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
          </Label>
        )}
        <ItemContent>{children}</ItemContent>
      </LeftContent>
      {showEditButton && (
        <RightContent>
          <EditButton>
            <Icon name={'pencil'} fitted />
          </EditButton>
        </RightContent>
      )}
    </Wrapper>
  );
});

const Wrapper = styled(Card)`
  display: flex;
`;
const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;
const SubLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  color: ${COLOR.DARK_GRAY};
`;
const ItemContent = styled.div`
  font-size: 14px;
`;

const LeftContent = styled.div`
  flex: 1;
`;

const RightContent = styled.div`
  flex: none;
  display: flex;
  align-items: flex-start;
`;

const EditButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    font-size: 20px;
    padding: 0;
    color: ${COLOR.CHARCOAL_GRAY};
    :hover {
      color: ${COLOR.BLACK};
    }
  }
`;
