import React, { useCallback } from 'react';

import { Search } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { SortKey, SortOrder, StoreSearchCondition } from 'models/Domain/Omo/StoreSearchCondition';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  searchCondition: StoreSearchCondition;
  onChangeSearchCondition: (searchCondition: StoreSearchCondition) => void;
  onCommitSearchCondition: () => void;
};

export type SortType = 'code_asc' | 'code_desc' | 'id_asc' | 'id_desc';

export const sortTypeOptions: { text: string; value: SortType }[] = [
  {
    text: '店舗コード順',
    value: 'code_asc',
  },
  {
    text: '店舗コード逆順',
    value: 'code_desc',
  },
  {
    text: '登録日が古い順',
    value: 'id_asc',
  },
  {
    text: '登録日が新しい順',
    value: 'id_desc',
  },
];

/**
 * 店舗の検索条件を指定するコンポーネント
 */
export const StoreFilter: React.FC<Props> = ({
  className,
  searchCondition,
  onChangeSearchCondition,
  onCommitSearchCondition,
}) => {
  const { key: sortKey, order: sortOrder } = searchCondition.sort;
  const selectedSortType = `${sortKey}_${sortOrder}` as SortType;

  const onChangeSearchValue = useCallback(
    (_: React.SyntheticEvent, data: any) => {
      const updatedCondition = searchCondition.setIn(['filter', 'searchValue'], data.value || '');
      onChangeSearchCondition(updatedCondition);
    },
    [onChangeSearchCondition, searchCondition],
  );

  const onChangeGroup = useCallback(
    (group) => {
      const updatedCondition = searchCondition.setIn(['filter', 'storeListId'], group);
      onChangeSearchCondition(updatedCondition);
    },
    [onChangeSearchCondition, searchCondition],
  );

  const onChangeSort = useCallback(
    (value: string) => {
      const splitParam = value.split('_');
      const sortKey = splitParam[0] as SortKey;
      const sortOrder = splitParam[1] as SortOrder;
      const updatedCondition = searchCondition.setSortCondition(sortKey, sortOrder);
      onChangeSearchCondition(updatedCondition);
    },
    [onChangeSearchCondition, searchCondition],
  );

  return (
    <Card className={className}>
      <FilterWrapper>
        <ContentWrapper>
          <ContentLabel>検索ワード</ContentLabel>
          <SearchField
            onSearchChange={onChangeSearchValue}
            placeholder={'店舗名 / 店舗コード'}
            showNoResults={false}
            value={searchCondition.filter.searchValue}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onCommitSearchCondition();
              }
            }}
            icon={null}
          />
        </ContentWrapper>
        <GroupStoreSelect
          group={searchCondition.filter.storeListId}
          showClosedStores={false}
          showGroupSelectOnly={true}
          showMyStoreOnly={true}
          showGbpConnectedStoresOnly={true}
          onChange={onChangeGroup}
        />
        <ContentWrapper>
          <ContentLabel>並び順</ContentLabel>
          <PullDown value={selectedSortType} options={sortTypeOptions} onChange={onChangeSort} />
        </ContentWrapper>
        <SearchButtonWrapper>
          <SearchButton priority={'high'} onClick={onCommitSearchCondition}>
            検索
          </SearchButton>
        </SearchButtonWrapper>
      </FilterWrapper>
    </Card>
  );
};

const FilterWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0;
  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-width: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const SearchButtonWrapper = styled(ContentWrapper)`
  margin-left: auto;
  padding-top: 28px;
`;

const SearchButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const SearchField = styled(Search)`
  width: 380px;
  @media (max-width: 920px) {
    width: 100%;
  }

  &&& {
    .ui.input {
      width: 100%;
    }

    input {
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
      font-weight: bold;
      height: 33px;
    }
  }
`;
