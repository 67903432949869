import { FaasApiClient } from 'ApiClient';
import { JSObject } from 'types/Common';
import { ImageUploadPostResponse, PresignedPostResponse } from 'types/responses/ImageUpload';

const IMAGE_PATH = '/image/upload_image';
const IMAGE_PRESIGNED_PATH = '/image/generate_presigned_url';

export default class ImageUploadApi {
  static post(file: File) {
    const headers = { 'Content-Type': file.type };

    // API Gatewayのタイムアウトは30秒だが、
    // ファイルアップロードに時間がかかる場合があるため
    // クライアントサイドのタイムアウトはもっと長く設定しておく
    const faasApiClient = new FaasApiClient({ headers, timeout: 180000 });

    return faasApiClient.post<ImageUploadPostResponse>(IMAGE_PATH, file);
  }
}

export class PresignedUrlApi {
  static post(params: JSObject) {
    const faasApiClient = new FaasApiClient({});
    return faasApiClient.post<PresignedPostResponse>(IMAGE_PRESIGNED_PATH, params);
  }
}
