import React, { useCallback, useEffect, useState } from 'react';

import { List, Set } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { TagInput } from 'components/molecules/TagInput';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { CompetitorSearchCondition as SearchCondition } from 'models/Domain/Competitor/CompetitorSearchCondition';
import { Group } from 'types/Common';

export type CompetitorSearchConditionProps = {
  className?: string;
  isCommitDisabled: boolean;
  committedSearchCondition: SearchCondition;
  commitSearchCondition: (condition: SearchCondition) => void;
};

export const CompetitorSearchCondition = React.memo<CompetitorSearchConditionProps>(
  ({ className, isCommitDisabled, committedSearchCondition, commitSearchCondition }) => {
    const [searchCondition, setSearchCondition] = useState<SearchCondition>(committedSearchCondition);

    // committedSearchConditionが変更されたら更新する
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    const {
      filter: { group, storeIds, showClosedStores, queries, tags },
    } = searchCondition;

    const handleChangeGroupStore = useCallback(
      (group: Group, storeIds: Set<number>, isAllStoreIds: boolean, showClosedStores: boolean) => {
        const newSearchCondition = searchCondition.mergeIn(['filter'], {
          group,
          storeIds,
          isAllStoreIds,
          showClosedStores,
        });
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleChangeQueries = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.setIn(['filter', 'queries'], List(values));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleChangeTags = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.setIn(['filter', 'tags'], List(values));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleCommitSearchCondition = useCallback(() => {
      commitSearchCondition(searchCondition);
    }, [commitSearchCondition, searchCondition]);

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <GroupStoreSelect
            group={group}
            storeIds={storeIds}
            showClosedStores={showClosedStores}
            onChange={handleChangeGroupStore}
          />
          <KeywordInputContent>
            <Label>競合店舗名（部分一致）</Label>
            <TagInput values={queries.toArray()} onChange={handleChangeQueries} />
          </KeywordInputContent>
          <KeywordInputContent>
            <Label>タグ（完全一致）</Label>
            <TagInput values={tags.toArray()} onChange={handleChangeTags} />
          </KeywordInputContent>
          <CommitButtonContent>
            <CommitButton priority={'high'} onClick={handleCommitSearchCondition} disabled={isCommitDisabled}>
              検索
            </CommitButton>
          </CommitButtonContent>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled(Card)`
  width: 100%;
  margin-bottom: 32px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const KeywordInputContent = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 7px;
`;

const CommitButtonContent = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const CommitButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    align-self: end;
    margin-left: 8px;
    box-shadow: none;
  }
`;
