import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { PullDown } from 'components/atoms/PullDown';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { DatePickerWrapper, DateRangePicker } from 'components/molecules/DateRangePicker';
import { TagInput } from 'components/molecules/TagInput';
import { MemoTagList } from 'models/Domain/Memo/Memo';
import { MemoSearchCondition as SearchCondition, SortKey, SortOrder } from 'models/Domain/Memo/MemoSearchCondition';
import { SIZE } from 'style/size';
import { SelectOption } from 'types/Common';

type Props = {
  className?: string;
  committedSearchCondition: SearchCondition;
  commitSearchCondition: (condition: SearchCondition) => void;
  tagList: MemoTagList;
};

export const MemoSearchCondition = React.memo<Props>(
  ({ className, committedSearchCondition, commitSearchCondition, tagList }) => {
    const [searchCondition, setSearchCondition] = useState<SearchCondition>(committedSearchCondition);

    const tagOptions: SelectOption<string>[] = tagList.items
      .map((item) => ({ text: item.tag, value: item.tag }))
      .toArray();

    const sortOptions: SelectOption<`${SortKey}_${SortOrder}`>[] = [
      { text: '開始日が新しい順', value: 'date_desc' },
      { text: '開始日が古い順', value: 'date_asc' },
    ];

    // committedSearchConditionが変更されたら更新する
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    const {
      filter: { startDate, endDate, query, tags },
      sort: { key: sortKey, order: sortOrder },
    } = searchCondition;

    const handleOnChangeDate = (sd: Date | null, ed: Date | null) => {
      const newSearchCondition = searchCondition.update('filter', (filter) =>
        filter.merge({ startDate: sd ? dayjs(sd) : null, endDate: ed ? dayjs(ed) : null }),
      );
      setSearchCondition(newSearchCondition);
    };

    const handleOnChangeQuery = (value: string[]) => {
      const newSearchCondition = searchCondition.update('filter', (filter) => filter.set('query', ImmutableSet(value)));
      setSearchCondition(newSearchCondition);
    };

    const handleOnChangeTag = (value: string[]) => {
      const newSearchCondition = searchCondition.update('filter', (filter) => filter.set('tags', ImmutableSet(value)));
      setSearchCondition(newSearchCondition);
    };

    const handleOnChangeSort = (value: string) => {
      const [sortKey, sortOrder] = value.split('_');
      const newSearchCondition = searchCondition.update('sort', (sort) =>
        sort.merge({
          key: sortKey as SortKey,
          order: sortOrder as SortOrder,
        }),
      );
      setSearchCondition(newSearchCondition);
    };

    const handleOnCommitSearchCondition = () => {
      // 1ページ目に固定する
      const newSearchCondition = searchCondition.setIn(['pagination', 'page'], 1);
      commitSearchCondition(newSearchCondition);
    };

    return (
      <Wrapper>
        <FlexWrapper>
          <FullContentWrapper>
            <Label>キーワード</Label>
            <TagInput values={query.toArray()} onChange={handleOnChangeQuery} />
          </FullContentWrapper>
          <FullContentWrapper>
            <Label>タグ</Label>
            <CustomPullDown
              multiple={true}
              closeOnChange={false}
              value={tags.toArray()}
              options={tagOptions}
              placeholder={'選択してください'}
              onChange={handleOnChangeTag}
            />
          </FullContentWrapper>
          <ContentWrapper>
            <Label>施策実施日</Label>
            <StyledDateRangePicker
              onChange={handleOnChangeDate}
              startDate={startDate?.toDate() ?? null}
              endDate={endDate?.toDate() ?? null}
              startPlaceholderText={'開始日:未設定'}
              endPlaceholderText={'終了日:未設定'}
            />
          </ContentWrapper>
          <ContentWrapper>
            <Label>並び順</Label>
            <SortPullDown value={`${sortKey}_${sortOrder}`} onChange={handleOnChangeSort} options={sortOptions} />
          </ContentWrapper>
          <SearchButtonWrapper>
            <SearchButton priority={'high'} onClick={handleOnCommitSearchCondition}>
              検索
            </SearchButton>
          </SearchButtonWrapper>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled(Card)`
  width: 100%;
  margin-bottom: 32px;
  position: relative;
`;

const FlexWrapper = styled.div`
  display: Flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

const ContentWrapper = styled.div`
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
  }
`;

const FullContentWrapper = styled(ContentWrapper)`
  width: 100%;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 7px;
  flex-wrap: wrap;
  gap: 4px 16px;
  margin-bottom: 4px;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  @media (min-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    ${DatePickerWrapper} {
      min-width: 176px;
    }
  }
`;

const CustomPullDown = styled(PullDown)``;

const SortPullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;

const SearchButtonWrapper = styled(ContentWrapper)`
  margin-top: auto;
  margin-left: auto;
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
  }
`;

const SearchButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;
