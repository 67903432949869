import { range } from './utils';

/**
 * 数値(正の整数)の配列を連続した数値ごとに分割する
 * @param numbers 数値(正の整数)の配列(ソートされ、重複がないこと)
 * @returns 分割された数値の配列
 */
export const divideNumbers = (numbers: number[]): number[][] => {
  const results: number[][] = [];
  let current: number[] = [];

  numbers.forEach((n) => {
    if (current.length > 0 && n !== current[current.length - 1] + 1) {
      results.push(current);
      current = [n];
    } else {
      current.push(n);
    }
  });

  if (current.length > 0) {
    results.push(current);
  }

  return results;
};

/**
 * ページャーに表示するページ数の一覧を生成する
 * @param current 現在のページ番号
 * @param total 全ページ数
 * @param limit 含めるページの数
 * @param fixed １ページ目、最終ページ目を含む固定で表示するページ数
 * @returns
 */
export const createPageNumbers = ({
  current,
  total,
  limit,
  fixed,
}: {
  current: number;
  total: number;
  limit: number;
  fixed: number;
}): number[] => {
  if (total <= limit) {
    return range(1, total);
  }

  let middleStart = current - Math.floor((limit - fixed * 2) / 2);
  if (middleStart <= fixed) {
    middleStart = fixed + 1;
  }
  let middleStop = middleStart + (limit - fixed * 2 - 1);
  if (middleStop >= total - fixed + 1) {
    middleStop = total - fixed;
    middleStart = middleStop - (limit - fixed * 2 - 1);
  }
  return [...range(1, fixed), ...range(middleStart, middleStop), ...range(total - fixed + 1, total)];
};

export const createPageNumbersWithoutFixed = ({
  current,
  total,
  limit,
}: {
  current: number;
  total: number;
  limit: number;
}): number[] => {
  if (total <= limit) {
    return range(1, total);
  }
  let start = current - Math.floor(limit / 2);
  if (start <= 0) {
    start = 1;
  }
  let end = start + limit - 1;
  if (end > total) {
    end = total;
    start = end - limit + 1;
  }

  return [...range(start, end)];
};

/**
 * ページャー用データの生成
 * @param current 現在のページ番号
 * @param total 全ページ数
 * @param limit 含めるページの数
 * @param fixed １ページ目、最終ページ目を含む固定で表示するページ数
 * @returns
 */
export const createPagerData = (args: { current: number; total: number; limit: number; fixed: number }): number[][] =>
  divideNumbers(createPageNumbers(args));

/**
 * ページャー用データの生成（固定ページを表示しない）
 * @param current 現在のページ番号
 * @param total 全ページ数
 * @param limit 含めるページの数
 * @returns
 */
export const createPagerDataWithoutFixed = (args: { current: number; total: number; limit: number }): number[][] =>
  divideNumbers(createPageNumbersWithoutFixed(args));
