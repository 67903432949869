import React from 'react';

import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';

export type PopupIconProps = {
  className?: string;
  iconName?: React.ComponentProps<typeof Icon>['name'];
  iconColor?: React.ComponentProps<typeof Icon>['color'];
} & React.ComponentProps<typeof Popup>;

const convertBreak = (content: PopupIconProps['content']) => {
  if (typeof content !== 'string') {
    return content;
  }

  const lines = content.split('\n');
  return (
    <>
      {lines.map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

export const PopupIcon: React.FC<PopupIconProps> = ({
  className,
  size = 'mini',
  iconName = 'info circle',
  iconColor = 'grey',
  header,
  content,
  hideOnScroll = true,
  ...restProps
}) => {
  return (
    <Popup
      header={header}
      content={
        content && (
          <>
            {header && <Hr />}
            {convertBreak(content)}
          </>
        )
      }
      size={size}
      trigger={
        <TriggerWrapper className={className}>
          <Icon name={iconName} color={iconColor} />
        </TriggerWrapper>
      }
      hideOnScroll={hideOnScroll}
      {...restProps}
    />
  );
};

const TriggerWrapper = styled.span``;
const Hr = styled.hr`
  border-width: 1px 0 0 0;
  border-width: ${COLOR.LIGHT_GRAY};
`;
