import React from 'react';

import { List } from 'immutable';
import Slider from 'react-slick';
import styled from 'styled-components';

import { COLOR } from 'style/color';

type Props = {
  urls: List<string>;
};

export const ImageCarousel: React.FC<Props> = ({ urls }) => {
  return (
    <Wrapper>
      <StyledSlider dots={true}>
        {urls.map((url, i) => (
          <ImageWrapper key={i}>
            <Img key={i} src={url} />
          </ImageWrapper>
        ))}
      </StyledSlider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 208px;
  height: 139px;
`;

const StyledSlider = styled(Slider)`
  &&& {
    .slick-slide {
      background: ${COLOR.GRAY};
      height: 139px;
      margin-bottom: 8px;
    }

    .slick-prev,
    .slick-next {
      /* 画像より上に表示させる */
      z-index: 1;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #222;
    }
    .slick-prev {
      left: 4px;
    }
    .slick-next {
      right: 4px;
    }

    .slick-dots {
      bottom: -33px;
      margin-bottom: 8px;
      padding: 8px 0;
      li {
        margin: 0;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Img = styled.img`
  width: 100%;
  height: 139px;
  object-fit: contain;
`;
