import React, { useMemo } from 'react';

import { List as ImmutableList, Set as ImmutableSet } from 'immutable';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { Table, TableBody, TableCell, TableRow } from 'components/molecules/Table';
import { InstagramBusinessAccount } from 'models/Domain/instagram';
import { COLOR } from 'style/color';

export type Props = {
  isLoading: boolean;
  accounts: ImmutableList<InstagramBusinessAccount>;
  selectedUserIds: ImmutableSet<string>;
  registeredUserIds: ImmutableSet<string>;
  onSelect: (id: string) => void;
};

export const InstagramAccountTable = React.memo<Props>(
  ({ isLoading, accounts, selectedUserIds, registeredUserIds, onSelect }) => {
    // 連携済みのアカウントは下にする
    const sortedAccounts = useMemo(
      () => accounts.sortBy((account) => registeredUserIds.contains(account.userId)),
      [accounts, registeredUserIds],
    );

    if (!isLoading && accounts.isEmpty()) {
      return (
        <Message>
          Facebookアカウントに連携されているInstagramアカウントがありません。
          <br />
          事前にInstagramアカウントとFacebookアカウントを連携してください。
        </Message>
      );
    }

    return (
      <Wrapper>
        <Table unstackable={true}>
          <TableBody>
            {sortedAccounts.map((account) => (
              <StyledTableRow key={account.userId} onClick={() => onSelect(account.userId)}>
                <CheckboxCell>
                  <CheckboxWrapper>
                    <SmallCheckBox
                      onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                        onSelect(account.userId);
                      }}
                      checked={selectedUserIds.contains(account.userId)}
                      disabled={registeredUserIds.contains(account.userId)}
                    />
                  </CheckboxWrapper>
                </CheckboxCell>
                <TableCell>
                  <FlexContainer>
                    <NameContainer>
                      {account.profilePictureUrl ? (
                        <StyledImage src={account.profilePictureUrl} />
                      ) : (
                        <StyledIcon type={'profile'} />
                      )}
                      <Name>{account.name}</Name>
                      <UserName>{account.userName}</UserName>
                    </NameContainer>
                  </FlexContainer>
                </TableCell>
                <StatusCell>{registeredUserIds.contains(account.userId) && '連携中'}</StatusCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {isLoading && (
          <>
            <LoadingWrapperBase />
            <LoadingWrapper>
              <Loader active={true} size={'big'} inline={true} />
            </LoadingWrapper>
          </>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
`;

const Name = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-right: 8px;
  word-break: break-all;
`;
const UserName = styled.span`
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
  word-break: break-all;
  &:before {
    content: '@';
    margin-right: 0.05em;
  }
`;

const NameContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledTableRow = styled(TableRow)`
  &&& {
    cursor: pointer;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SettingsIcon = styled(Icon).attrs({
  type: 'settings',
})`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  margin-bottom: -4px;
  margin-left: 16px;
`;

export const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

export const ActionItemLabel = styled.div<{ disabled?: boolean }>`
  padding: 4px 8px;
  font-size: 14px;
  color: ${({ disabled = false }) => (disabled ? COLOR.GRAY : COLOR.BLACK)};
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
`;
const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  mix-blend-mode: hard-light;
`;

const Message = styled.div`
  padding: 16px 0;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const CheckboxCell = styled(TableCell)`
  &&& {
    width: 54px;
  }
`;

const StyledIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  padding: 0;
  margin-right: 8px;
`;

const StyledImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
`;

const StatusCell = styled(TableCell)`
  &&& {
    width: 100px;
    text-align: center;
    color: ${COLOR.CHARCOAL_GRAY};
  }
`;
