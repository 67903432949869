import React from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import { COLOR } from 'style/color';

type Props = {
  name: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  // 入力項目に関するヘルプURL
  // 存在する場合、見出しの横にアイコンを表示 (クリックで遷移可能)
  informationUrl?: string;
  // 入力項目に関する注釈の文字列
  // 存在する場合、見出しの横にアイコンを表示 (ホバーで注釈を表示)
  informationText?: React.ReactNode;
  required?: boolean;
  // その他のラベルの横に表示したいコンポーネント
  additionalContents?: React.ReactNode;
};

export const FormContent = React.memo<Props>(
  ({ name, className, children, informationUrl, informationText, required = false, additionalContents }) => {
    return (
      <Wrapper className={className}>
        <Title>
          <Name>{name}</Name>
          {informationUrl && (
            <a href={informationUrl} target='_blank' rel='noreferrer'>
              <Information>
                <StyledIcon name='info circle' />
              </Information>
            </a>
          )}
          {informationText && (
            <Information>
              <ContextHelp content={informationText} />
            </Information>
          )}
          {required && <Required>必須</Required>}
          {additionalContents}
        </Title>
        {children}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Information = styled.div`
  margin-left: 4px;
`;

const Required = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.ERROR};
  color: ${COLOR.WHITE};
  border-radius: 4px;
  font-size: 12px;
  padding: 1px 6px;
  margin-left: 8px;
  font-weight: bold;
`;

const StyledIcon = styled(Icon)``;
