import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { COLOR } from 'style/color';

type ComponentProps = {
  // 選択中の差分件数
  selectedSize: number;
  // 「STORECASTを保持」できない差分項目があるかどうか
  hasStorecastDataCannotApply: boolean;

  onCancel: () => void;
  onConfirm: () => void;
};

export const GmbLocationUpdatesFooter = React.memo<ComponentProps>(
  ({ selectedSize, hasStorecastDataCannotApply, onCancel, onConfirm }) => {
    return (
      <Footer>
        <ChildWrapper>
          {selectedSize}件の項目を選択しています
          {hasStorecastDataCannotApply && (
            <>
              <br />
              <Warning>
                STORECASTを保持できない項目が選択されています。
                <br />
                「STORECASTを保持」を選択した場合、保持できない項目はスキップされます。
              </Warning>
            </>
          )}
        </ChildWrapper>
        <ButtonWrapper>
          <StyledCancelButton onClick={() => onCancel()}>STORECASTを保持</StyledCancelButton>
          <StyledButton onClick={() => onConfirm()}>Googleを保持</StyledButton>
        </ButtonWrapper>
      </Footer>
    );
  },
);

const ChildWrapper = styled.div`
  margin-right: 18px;
  text-align: right;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 860px) {
    min-width: 180px;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    font-size: 16px;
    width: auto;
    margin-right: 8px;
    margin-left: 8px;
    padding: 16px;
    border-radius: 0;
    color: ${COLOR.GOOGLE};
    border-color: ${COLOR.GOOGLE};
  }
`;

const StyledCancelButton = styled(StyledButton)`
  &&& {
    color: ${COLOR.GREEN};
    border-color: ${COLOR.GREEN};
  }
`;

const Footer = styled.div`
  position: sticky;
  background-color: ${COLOR.WHITE};
  bottom: 0;
  height: 100px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 26px 64px;
  border-radius: 2px;
  @media (max-width: 860px) {
    padding: 24px 18px;
    height: auto;
  }
  border-top: 1px solid #e3e4e5;
`;

const Warning = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4em;
  color: ${COLOR.ERROR};
`;
