import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { Pagination } from 'models/Domain/Pagination';
import { JSObject } from 'types/Common';

import { Conditions, Status } from './index';

export class SearchVolumeSearchHistoryItem extends ImmutableRecord<{
  status: Status;
  conditions: Conditions;
  userId: number;
  date: Dayjs;
  executionId: string;
}>({
  status: 'SUCCEEDED',
  conditions: new Conditions(),
  userId: 0,
  date: dayjs(),
  executionId: '',
}) {
  static fromJSON(data: JSObject) {
    return new SearchVolumeSearchHistoryItem({
      status: data.status,
      conditions: Conditions.fromJSON(data.conditions),
      userId: data.user_id,
      date: dayjs(data.date),
      executionId: data.execution_id,
    });
  }
}

export class SearchVolumeSearchHistory extends ImmutableRecord<{
  items: ImmutableList<SearchVolumeSearchHistoryItem>;
  pagination: Pagination;
}>({
  items: ImmutableList(),
  pagination: new Pagination(),
}) {
  static fromJSON(data: JSObject) {
    return new SearchVolumeSearchHistory({
      items: ImmutableList(data.items.map((item: JSObject) => SearchVolumeSearchHistoryItem.fromJSON(item))),
      pagination: Pagination.fromJSON(data.pagination),
    });
  }
}
