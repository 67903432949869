import React, { useState } from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';

import { Icon, IconType } from './Icon';

type Priority = 'small';

interface TextAreaProps {
  className?: string;
  error?: boolean | string;
  onChange?: (v: string) => void;
  iconType?: IconType;
  iconText?: string;
  priority?: Priority;
  minRows?: number;
  maxRows?: number;
  onClickIcon?: () => void;
}

export const TextArea: React.FC<
  TextAreaProps & Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>
> = ({ className, error = false, onChange, iconType, iconText, onClickIcon, priority, ...props }) => {
  const params = {
    control: TextareaAutosize,
    error: error,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange && onChange(e.target.value),
    icon: iconType, // key: 'iconType' だとWarningが出るためキー名を変更
    ...props,
  };

  const [textareaHeight, setTextAreaHeight] = useState<number | null>(null);

  return (
    <Container className={className}>
      {priority === 'small' ? (
        <SmallTextArea {...params} />
      ) : (
        <DefaultTextArea {...params} onHeightChange={setTextAreaHeight} />
      )}
      {iconType && (
        <IconWrapper onClick={() => onClickIcon && onClickIcon()} textareaHeight={textareaHeight}>
          <TextAreaIcon type={iconType} />
          {iconText}
        </IconWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const DefaultTextArea = styled(Form.TextArea)<{ icon?: string; error?: string | boolean }>`
  &&& {
    > div {
      &.pointing {
        opacity: 1;
        background: ${COLOR.WHITE} !important;
        border: 1px solid ${COLOR.ERROR} !important;
        color: ${COLOR.ERROR} !important;
      }
    }

    textarea {
      display: block;
      color: ${COLOR.BLACK};
      font-size: 16px;
      ${(props) => (props.icon ? 'padding: 16px 16px 48px;' : 'padding: 16px;')}
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      background-color: ${COLOR.WHITE};
      ${(props) =>
        props.error &&
        `
        background-color: #fff6f6;
        border-color: ${COLOR.ERROR};
        color: ${COLOR.ERROR};
      `}
      outline: none;
      resize: vertical;
      min-height: 70px;
      width: 100%;

      &:read-only {
        cursor: default;
      }

      &:disabled {
        color: ${COLOR.GRAY};
        background-color: ${COLOR.BACKGROUND};
        border-color: ${COLOR.LIGHT_GRAY};
        border-color: ${COLOR.LIGHT_GRAY};
        cursor: not-allowed;
      }
    }
  }
`;

const SmallTextArea = styled(DefaultTextArea)`
  &&& {
    textarea {
      &:not([rows]) {
        min-height: 54px;
      }
    }
  }
`;

const IconWrapper = styled.div<{ textareaHeight: number | null }>`
  position: absolute;
  left: 8px;
  cursor: pointer;
  text-decoration: underline;
  ${(props) => (props.textareaHeight ? `top: ${props.textareaHeight - 44}px;` : 'top: 0;')}
`;

const TextAreaIcon = styled(Icon)`
  vertical-align: middle;
`;
