import React, { useCallback, useLayoutEffect, useRef } from 'react';

import { List } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Arrow } from 'components/atoms/Arrow';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { StoreCardList } from 'components/pageComponents/StoreIndex/StoreCardList';
import { WideBody } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { SortType, Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { StoreSearchCondition } from 'models/Domain/StoreSearchCondition';
import { BulkEditStoreState } from 'models/Other/BulkEditStoreState';
import { Path } from 'routes';
import { COLOR } from 'style/color';

import { StoreDetailContents } from '../StoreDetail/StoreDetailContents';

import { StoreFilter } from './StoreFilter';

/**
 * 店舗のリスト表示のコンテンツ。左側にリスト、右側に選択された店舗の詳細が表示される
 */
export const StoreIndexListContents: React.FC<{
  stores: Stores;
  storeLists: StoreLists;
  bulkEditStoreState: BulkEditStoreState;
  displayManagedList: boolean;
  managedStores: List<number>;
  setBulkEditStoreState: (v: BulkEditStoreState) => void;
  searchCondition: StoreSearchCondition;
  setSearchCondition: (searchCondition: StoreSearchCondition) => void;
  storeId?: number;
}> = ({
  stores,
  storeLists,
  bulkEditStoreState,
  setBulkEditStoreState,
  displayManagedList,
  managedStores,
  searchCondition,
  setSearchCondition,
  storeId,
}) => {
  const dispatch = useDispatch();

  const storeDetailRef = useRef<HTMLDivElement>(null);

  const { key: sortKey, order: sortOrder } = searchCondition.sort;
  const filter = searchCondition.filter;
  const { searchValue, storeListId, showClosedStore } = filter;
  const selectedSortId = `${sortKey}_${sortOrder}` as SortType;
  let filteredStores = stores;

  const targetFilterStores = showClosedStore ? stores : stores.filterByUnclosed();

  if (storeListId === 'all') {
    filteredStores = targetFilterStores.filterStores(searchValue).sortBy(selectedSortId);
  } else if (storeListId === 'managed_list') {
    filteredStores = targetFilterStores
      .filterStores(searchValue)
      .filterStoresById(managedStores.toArray())
      .sortBy(selectedSortId);
  } else {
    // 絞り込み・並び替えされた店舗データ
    filteredStores = targetFilterStores
      .filterStores(searchValue)
      .filterStoresByStoreListId(storeLists, storeListId)
      .sortBy(selectedSortId);
  }

  const selectedStore = stores.findStore(storeId);

  const onChangeSearchCondition = useCallback(
    (searchCondition: StoreSearchCondition) => {
      setBulkEditStoreState(bulkEditStoreState.removeAllStoreIds());
      setSearchCondition(searchCondition);
    },
    [bulkEditStoreState, setBulkEditStoreState, setSearchCondition],
  );

  const handleOnClickStore = (id: number) => {
    dispatch(
      replaceWithOrganizationId(
        `${Path.store.detail.replace(':storeId', String(id))}?${searchCondition.toURLSearchParams()}`,
      ),
    );
  };

  const onClickBackButton = () => {
    dispatch(replaceWithOrganizationId(`${Path.store.index}?${searchCondition.toURLSearchParams()}`));
  };

  useLayoutEffect(() => {
    storeDetailRef?.current?.scrollTo(0, 0);
  }, [selectedStore]);

  return (
    <>
      <Helmet title={getPageTitle(selectedStore?.fullName ?? '店舗詳細')} />
      {/* 店舗詳細 */}
      <StoreDetailHeader>
        <BackButton onClick={onClickBackButton}>
          <Arrow direction='left' color='#393939' length={16} weight={3} />
        </BackButton>
        <StoreDetailHeaderContainer>
          <StoreName>{selectedStore?.fullName}</StoreName>
          <StoreCode>{selectedStore?.code && `店舗コード：${selectedStore?.code}`}</StoreCode>
        </StoreDetailHeaderContainer>
      </StoreDetailHeader>

      <StoreDetailBody>
        <StoreDetailContents storeId={String(storeId)} />
      </StoreDetailBody>

      {/* 店舗一覧 */}
      <StoreListWrapper>
        <StoreListHeader>
          <StoreListHeaderContainer>
            <Title>店舗一覧</Title>
          </StoreListHeaderContainer>
          <StyledStoreFilter
            storeLists={storeLists}
            setBulkEditStoreState={setBulkEditStoreState}
            displayManagedList={displayManagedList}
            searchCondition={searchCondition}
            setSearchCondition={setSearchCondition}
            onChangeSearchCondition={onChangeSearchCondition}
            showSwitch={true}
          />
        </StoreListHeader>
        <StoreCardList
          stores={filteredStores}
          selectedStore={selectedStore}
          onClick={handleOnClickStore}
          storeDetailLink={`${Path.store.detail}?${searchCondition.toURLSearchParams()}`}
        />
      </StoreListWrapper>
    </>
  );
};

const StoreListHeader = styled(StickyHeader)`
  flex-direction: column;
  height: auto;
  align-items: flex-start;
  padding: 0;
  background: ${COLOR.WHITE};
`;

const StoreListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  width: 100%;
`;

const StoreDetailHeader = styled(StickyHeader)`
  /* PC表示の場合は店舗詳細の左に店舗一覧を表示する */
  margin-left: 331px;
  height: auto;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const StoreDetailHeaderContainer = styled.div`
  width: 100%;
  min-width: 0;
  padding: 16px 16px 16px 8px;
`;

const StoreName = styled.div`
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StoreCode = styled.div`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
  margin-right: 8px;
  padding: 20px 20px 0;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
`;

const StoreListWrapper = styled.div`
  /* PC表示の場合は店舗詳細の左に店舗一覧を表示する */
  position: fixed;
  padding: 0;
  top: 0;
  left: 320px;
  height: 100vh;
  overflow-y: scroll;
  width: 331px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const StoreDetailBody = styled(WideBody)`
  /* PC表示の場合は店舗詳細の左に店舗一覧を表示する */
  margin-left: 331px;
  width: calc(100% - 331px);
  max-width: 800px;
  overflow-y: hidden;
  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    max-width: 800px;
  }
`;

const StyledStoreFilter = styled(StoreFilter)`
  padding: 8px 16px;
  background: ${COLOR.WHITE};
  width: 100%;
  border-bottom: 1px solid ${COLOR.GRAY};
`;
