import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Map as ImmutableMap, Record as ImmutableRecord } from 'immutable';

import { JSObject } from 'types/Common';

export type AggregateType = 'recent' | 'total';

export class GoogleLocation extends ImmutableRecord<{
  id: string;
  placeId: string;
  kgmid: string;
  location: GoogleLocationLocation | null;
  attribute: GoogleLocationAttribute | null;
  localPost: GoogleLocationLocalPost | null;
  photo: GoogleLocationPhoto | null;
  review: GoogleLocationReview | null;
  product: GoogleLocationProduct | null;
  service: GoogleLocationService | null;
  timestamp: Dayjs | null;
}>({
  id: '',
  placeId: '',
  kgmid: '',
  location: null,
  attribute: null,
  localPost: null,
  photo: null,
  review: null,
  product: null,
  service: null,
  timestamp: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocation({
      id: data.id,
      placeId: data.place_id,
      kgmid: data.kgmid,
      location: data.location && GoogleLocationLocation.fromJSON(data.location),
      attribute: data.attribute && GoogleLocationAttribute.fromJSON(data.attribute),
      localPost: data.local_post && GoogleLocationLocalPost.fromJSON(data.local_post),
      photo: data.photo && GoogleLocationPhoto.fromJSON(data.photo),
      review: data.review && GoogleLocationReview.fromJSON(data.review),
      product: data.product && GoogleLocationProduct.fromJSON(data.product),
      service: data.service && GoogleLocationService.fromJSON(data.service),
      timestamp: data.timestamp && dayjs(data.timestamp),
    });
  }

  // GoogleマップのURL
  get mapUrl() {
    return this.placeId ? `https://www.google.com/maps/place/?q=place_id:${this.placeId}` : null;
  }

  // ナレッジパネルのURL
  get knowledgePanelUrl() {
    return this.kgmid ? `https://www.google.com/search?kgmid=${this.kgmid}&kponly` : null;
  }
}

type OpenStatus = '営業中' | '臨時休業' | '閉業';

export class GoogleLocationBusinessHours extends ImmutableRecord<{
  sunday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
}>({
  sunday: '',
  monday: '',
  tuesday: '',
  wednesday: '',
  thursday: '',
  friday: '',
  saturday: '',
}) {
  static fromJSON(data: JSObject) {
    // 祝日の曜日は "日曜日 (勤労感謝の日)" のようなキー名になるため、祝日名を取り除く
    const newData: JSObject = {};
    Object.entries(data).map(([day, value]) => {
      const newDay = day.replace(/\s\(.+\)/, '');
      newData[newDay] = value;
    });

    return new GoogleLocationBusinessHours({
      sunday: newData['日曜日'],
      monday: newData['月曜日'],
      tuesday: newData['火曜日'],
      wednesday: newData['水曜日'],
      thursday: newData['木曜日'],
      friday: newData['金曜日'],
      saturday: newData['土曜日'],
    });
  }

  get businessHoursForDisplay() {
    return [
      `日曜日 ${this.sunday}`,
      `月曜日 ${this.monday}`,
      `火曜日 ${this.tuesday}`,
      `水曜日 ${this.wednesday}`,
      `木曜日 ${this.thursday}`,
      `金曜日 ${this.friday}`,
      `土曜日 ${this.saturday}`,
    ];
  }
}

export class GoogleLocationLocation extends ImmutableRecord<{
  name: string | null;
  category: string | null;
  subCategories: ImmutableList<string> | null;
  openStatus: OpenStatus | null;
  address: string | null;
  businessHours: GoogleLocationBusinessHours | null;
  moreHours: ImmutableMap<string, GoogleLocationBusinessHours> | null;
  reservationLinks: ImmutableList<string> | null;
  menuLink: string | null;
  websiteUrl: string | null;
  phoneNumber: string | null;
}>({
  name: null,
  category: null,
  subCategories: null,
  openStatus: null,
  address: null,
  businessHours: null,
  moreHours: null,
  reservationLinks: null,
  menuLink: null,
  websiteUrl: null,
  phoneNumber: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationLocation({
      name: data.name,
      category: data.category,
      subCategories: data.sub_categories && ImmutableList(data.sub_categories),
      openStatus: data.open_status,
      address: data.address,
      businessHours: data.business_hours && GoogleLocationBusinessHours.fromJSON(data.business_hours),
      moreHours:
        data.more_hours &&
        ImmutableMap(
          Object.entries(data.more_hours as Record<string, Record<string, string>>).reduce(
            (x, [key, value]) => Object.assign(x, { [key]: GoogleLocationBusinessHours.fromJSON(value) }),
            {},
          ),
        ),
      reservationLinks: data.reservation_links && ImmutableList(data.reservation_links),
      menuLink: data.menu_link,
      websiteUrl: data.website_url,
      phoneNumber: data.phone_number,
    });
  }
}

export class GoogleLocationAttributeItem extends ImmutableRecord<{
  name: string;
  label: string;
  isAvailable: boolean;
}>({
  name: '',
  label: '',
  isAvailable: true,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationAttributeItem({
      name: data.name,
      label: data.label,
      isAvailable: data.is_available,
    });
  }
}
export class GoogleLocationAttributeGroup extends ImmutableRecord<{
  name: string;
  items: ImmutableList<GoogleLocationAttributeItem>;
}>({
  name: '',
  items: ImmutableList<GoogleLocationAttributeItem>(),
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationAttributeGroup({
      name: data.name,
      items:
        data.attributes &&
        ImmutableList(data.attributes.map((item: JSObject) => GoogleLocationAttributeItem.fromJSON(item))),
    });
  }
}
export class GoogleLocationAttribute extends ImmutableRecord<{
  groups: ImmutableList<GoogleLocationAttributeGroup>;
}>({
  groups: ImmutableList<GoogleLocationAttributeGroup>(),
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationAttribute({
      groups:
        data.groups && ImmutableList(data.groups.map((item: JSObject) => GoogleLocationAttributeGroup.fromJSON(item))),
    });
  }
}

export class GoogleLocationLocalPostItem extends ImmutableRecord<{
  all: number | null;
  standard: number | null;
  event: number | null;
  offer: number | null;
  hasMore: boolean | null;
}>({
  all: null,
  standard: null,
  event: null,
  offer: null,
  hasMore: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationLocalPostItem({
      all: data.all,
      standard: data.standard,
      event: data.event,
      offer: data.offer,
      hasMore: data.has_more,
    });
  }
}

export class GoogleLocationLocalPost extends ImmutableRecord<{
  recent: GoogleLocationLocalPostItem | null;
  total: GoogleLocationLocalPostItem | null;
}>({
  recent: null,
  total: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationLocalPost({
      recent: data.recent && GoogleLocationLocalPostItem.fromJSON(data.recent),
      total: data.total && GoogleLocationLocalPostItem.fromJSON(data.total),
    });
  }
}

export class GoogleLocationPhotoCategory extends ImmutableRecord<{
  name: string;
  count: number;
}>({
  name: '',
  count: 0,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationPhotoCategory({
      name: data.category,
      count: data.count,
    });
  }
}
export class GoogleLocationPhotoItem extends ImmutableRecord<{
  count: number | null;
  hasMore: boolean | null;
  categories?: ImmutableList<GoogleLocationPhotoCategory> | null;
}>({
  count: null,
  hasMore: null,
  categories: undefined,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationPhotoItem({
      count: data.count,
      hasMore: data.has_more,
      categories:
        data.categories &&
        ImmutableList(data.categories.map((item: JSObject) => GoogleLocationPhotoCategory.fromJSON(item))),
    });
  }

  get ownerImageCount() {
    return this.categories?.find((category) => category.name === 'オーナー提供')?.count;
  }
}

export class GoogleLocationPhoto extends ImmutableRecord<{
  currentMonth: GoogleLocationPhotoItem | null;
  lastMonth: GoogleLocationPhotoItem | null;
  total: GoogleLocationPhotoItem | null;
}>({
  currentMonth: null,
  lastMonth: null,
  total: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationPhoto({
      currentMonth: data.current_month && GoogleLocationPhotoItem.fromJSON(data.current_month),
      lastMonth: data.last_month && GoogleLocationPhotoItem.fromJSON(data.last_month),
      total: data.total && GoogleLocationPhotoItem.fromJSON(data.total),
    });
  }
}

export class GoogleLocationReviewItem extends ImmutableRecord<{
  averageReviewScore: number | null;
  reviewCount: number | null;
  reviewReplyCount: number | null;
  hasMore: boolean | null;
}>({
  averageReviewScore: null,
  reviewCount: null,
  reviewReplyCount: null,
  hasMore: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationReviewItem({
      averageReviewScore: data.average_review_score,
      reviewCount: data.review_count,
      reviewReplyCount: data.review_reply_count,
      hasMore: data.has_more,
    });
  }
}

export class GoogleLocationReview extends ImmutableRecord<{
  recent: GoogleLocationReviewItem | null;
  total: GoogleLocationReviewItem | null;
}>({
  recent: null,
  total: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationReview({
      recent: data.recent && GoogleLocationReviewItem.fromJSON(data.recent),
      total: data.total && GoogleLocationReviewItem.fromJSON(data.total),
    });
  }
}
export class GoogleLocationProduct extends ImmutableRecord<{
  hasGmcProducts: boolean | null;
  hasGbpProducts: boolean | null;
}>({
  hasGbpProducts: null,
  hasGmcProducts: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationProduct({
      hasGmcProducts: data.gmc?.has_products,
      hasGbpProducts: data.gbp?.has_products,
    });
  }
}

export class GoogleLocationServiceItem extends ImmutableRecord<{
  name: string;
  description: string;
  price: string;
}>({
  name: '',
  description: '',
  price: '',
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationServiceItem({
      name: data.name,
      description: data.description,
      price: data.price,
    });
  }
}

export class GoogleLocationServiceGroup extends ImmutableRecord<{
  name: string;
  items: ImmutableList<GoogleLocationServiceItem>;
}>({
  name: '',
  items: ImmutableList<GoogleLocationServiceItem>(),
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationServiceGroup({
      name: data.name,
      items: ImmutableList(data.services.map((item: JSObject) => GoogleLocationServiceItem.fromJSON(item))),
    });
  }
}

export class GoogleLocationService extends ImmutableRecord<{ groups: ImmutableList<GoogleLocationServiceGroup> }>({
  groups: ImmutableList<GoogleLocationServiceGroup>(),
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationService({
      groups: ImmutableList(data.items.map((item: JSObject) => GoogleLocationServiceGroup.fromJSON(item))),
    });
  }
}

export class GoogleLocationSummaryValue extends ImmutableRecord<{
  value: number;
  hasMore: boolean;
}>({
  value: 0,
  hasMore: false,
}) {
  static fromJSON(data: { value: number | null; hasMore?: boolean | null }) {
    if (data.value == null) {
      return null;
    } else {
      return new GoogleLocationSummaryValue({
        value: data.value,
        hasMore: data.hasMore == null ? false : data.hasMore,
      });
    }
  }
}

class RecentTotalValue extends ImmutableRecord<{
  recent: GoogleLocationSummaryValue | null;
  total: GoogleLocationSummaryValue | null;
}>({
  recent: null,
  total: null,
}) {}

class MonthlyRecentTotalValue extends ImmutableRecord<{
  lastMonth: GoogleLocationSummaryValue | null;
  currentMonth: GoogleLocationSummaryValue | null;
  total: GoogleLocationSummaryValue | null;
}>({
  lastMonth: null,
  currentMonth: null,
  total: null,
}) {}

export class GoogleLocationSummary extends ImmutableRecord<{
  id: number;
  placeId: string;
  ownerImageCount: MonthlyRecentTotalValue;
  localPostCount: RecentTotalValue;
  reviewCount: RecentTotalValue;
  reviewReplyCount: RecentTotalValue;
  reviewAverageScore: RecentTotalValue;
  crawlStatus: CrawlStatus | null;
}>({
  id: 0,
  placeId: '',
  ownerImageCount: new MonthlyRecentTotalValue(),
  localPostCount: new RecentTotalValue(),
  reviewCount: new RecentTotalValue(),
  reviewReplyCount: new RecentTotalValue(),
  reviewAverageScore: new RecentTotalValue(),
  crawlStatus: null,
}) {
  static fromJSON(data: JSObject) {
    return new GoogleLocationSummary({
      id: data.id,
      placeId: data.place_id,
      ownerImageCount: new MonthlyRecentTotalValue({
        lastMonth: GoogleLocationSummaryValue.fromJSON({
          value: data.last_month_owner_image_count,
          hasMore: data.has_more_last_month_owner_image,
        }),
        currentMonth: GoogleLocationSummaryValue.fromJSON({
          value: data.current_month_owner_image_count,
          hasMore: data.has_more_current_month_owner_image,
        }),
        total: GoogleLocationSummaryValue.fromJSON({
          value: data.total_owner_image_count,
          hasMore: data.has_more_total_owner_image,
        }),
      }),
      localPostCount: new RecentTotalValue({
        recent: GoogleLocationSummaryValue.fromJSON({
          value: data.recent_local_post_count,
          hasMore: data.has_more_recent_local_post,
        }),
        total: GoogleLocationSummaryValue.fromJSON({
          value: data.total_local_post_count,
          hasMore: data.has_more_total_local_post,
        }),
      }),
      reviewCount: new RecentTotalValue({
        recent: GoogleLocationSummaryValue.fromJSON({
          value: data.recent_review_count,
          hasMore: data.has_more_recent_review,
        }),
        total: GoogleLocationSummaryValue.fromJSON({
          value: data.total_review_count,
          hasMore: data.has_more_total_review,
        }),
      }),
      reviewReplyCount: new RecentTotalValue({
        recent: GoogleLocationSummaryValue.fromJSON({ value: data.recent_review_reply_count }),
        total: GoogleLocationSummaryValue.fromJSON({ value: data.total_review_reply_count }),
      }),
      reviewAverageScore: new RecentTotalValue({
        recent: GoogleLocationSummaryValue.fromJSON({ value: data.recent_average_review_score }),
        total: GoogleLocationSummaryValue.fromJSON({ value: data.total_average_review_score }),
      }),
      crawlStatus: data.crawl_status ? CrawlStatus.fromJSON(data) : null,
    });
  }
}

export class CrawlStatus extends ImmutableRecord<{
  status: 'SUCCESS' | 'FAILED';
  createAt: Dayjs | null;
  updateAt: Dayjs | null;
}>({
  status: 'FAILED',
  createAt: null,
  updateAt: null,
}) {
  static fromJSON(data: JSObject) {
    return new CrawlStatus({
      status: data.crawl_status,
      createAt: data.create_at ? dayjs(data.create_at) : null,
      updateAt: data.update_at ? dayjs(data.update_at) : null,
    });
  }
}
