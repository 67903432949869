import dayjs from 'dayjs';
import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import { Area, SearchVolumesStartParams, SearchWord } from 'ApiClient/AdsApi';
import { numberComparator, parseMonthParameter } from 'helpers/search';
import { Stores } from 'models/Domain/Store';
import { YearMonth } from 'models/Domain/YearMonth';
import { Group, assertNever } from 'types/Common';

// 検索地点の指定方法
export type AreaType = 'name' | 'store';

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType, 'isAllStoreIds' | 'isEnabledComparison'>]: string;
} = {
  group: 'st',
  storeIds: 'si',
  startMonth: 'sm',
  endMonth: 'em',
  areaType: 'at',
  areaNames: 'an',
  searchWords: 'sw',
};

const DEFAULT_AREA_TYPE: AreaType = 'store';
const DEFAULT_GROUP: Group = 'all';

// FIXME
// デフォルト値をモジュールレベルで定義してしまうと、テスト時に値(maxDateのdayjs())が固定できないので
// 関数の形にして利用するときに生成するようにしている
const defaultValues = (): FilterStatusType => {
  // 検索ボリュームが取得できるまで1ヶ月かかるので、デフォルトは、2ヶ月前までの13ヶ月間
  const maxDate = dayjs().subtract(1, 'month').endOf('month');
  const DEFAULT_START_MONTH = YearMonth.fromDate(maxDate.subtract(12, 'month').startOf('month'));
  const DEFAULT_END_MONTH = YearMonth.fromDate(maxDate);

  return {
    areaType: 'store',
    areaNames: ImmutableSet(),
    group: null,
    storeIds: ImmutableSet(),
    isAllStoreIds: true,
    startMonth: DEFAULT_START_MONTH,
    endMonth: DEFAULT_END_MONTH,
    searchWords: ImmutableSet(),
  };
};

type FilterStatusType = {
  areaType: AreaType;
  areaNames: ImmutableSet<string>;
  group: Group;
  storeIds: ImmutableSet<number>;
  isAllStoreIds: boolean;
  searchWords: ImmutableSet<string>;
  startMonth: YearMonth;
  endMonth: YearMonth;
};

export class FilterStatus extends ImmutableRecord<FilterStatusType>(defaultValues()) {
  setStoreIds(storeIds: ImmutableSet<number>, isAllStoreIds: boolean) {
    return this.merge({ storeIds, isAllStoreIds });
  }

  changePeriodByDate(startDate: Date, endDate: Date) {
    return this.merge({
      startMonth: YearMonth.fromDate(startDate),
      endMonth: YearMonth.fromDate(endDate),
    });
  }
}

export class SearchVolumeSearchCondition extends ImmutableRecord<{
  filter: FilterStatus;
}>({
  filter: new FilterStatus(),
}) {
  isValid() {
    return (
      this.filter.searchWords.size > 0 &&
      (this.filter.areaType === 'name' || (this.filter.areaType === 'store' && this.filter.storeIds.size > 0)) &&
      this.filter.startMonth &&
      this.filter.endMonth &&
      this.filter.startMonth.isSameOrBefore(this.filter.endMonth)
    );
  }

  /**
   * ページのURLパラメータから検索条件を生成する
   * @param search URLパラメータ
   * @returns 検索条件
   */
  static fromURLSearchParams(search: string): SearchVolumeSearchCondition {
    const condition = new SearchVolumeSearchCondition();
    let { filter } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連

    // エリアタイプ
    const areaType = params.get(URLSearchParamsMapping.areaType);
    if (areaType === 'name' || areaType === 'store') {
      filter = filter.set('areaType', areaType);
    }

    // グループ
    const group = params.get(URLSearchParamsMapping.group);
    if (group) {
      if (group === 'all' || group === 'my_store') {
        // すべての店舗の場合
        filter = filter.set('group', group);
      } else if (group.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('group', parseInt(group, 10));
      }
    }

    // 店舗ID
    const storeIds = params.get(URLSearchParamsMapping.storeIds) || 'all';
    if (storeIds === 'all') {
      filter = filter.setStoreIds(ImmutableSet<number>(), true);
    } else {
      const values = storeIds
        .split(',')
        .filter((v) => v.match(/^\d+$/))
        .map((v) => Number.parseInt(v, 10));
      filter = filter.setStoreIds(ImmutableSet<number>(values), false);
    }

    // 集計期間
    const startMonth = parseMonthParameter(params.get(URLSearchParamsMapping.startMonth));
    const endMonth = parseMonthParameter(params.get(URLSearchParamsMapping.endMonth));
    if (startMonth && endMonth && startMonth <= endMonth) {
      filter = filter.merge({ startMonth: YearMonth.fromString(startMonth), endMonth: YearMonth.fromString(endMonth) });
    }

    // エリア名
    const areaNamesStr = params.get(URLSearchParamsMapping.areaNames);
    if (areaNamesStr) {
      filter = filter.set('areaNames', ImmutableSet(areaNamesStr.split(',')));
    }

    // 検索ワード
    const searchWordsStr = params.get(URLSearchParamsMapping.searchWords);
    if (searchWordsStr) {
      filter = filter.set('searchWords', ImmutableSet(searchWordsStr.split(',')));
    }

    return condition.merge({ filter });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    // フィルタ関連

    if (this.filter.group !== DEFAULT_GROUP) {
      params.append(URLSearchParamsMapping.group, String(this.filter.group));
    }

    if (!this.filter.isAllStoreIds && this.filter.storeIds.size > 0) {
      params.append(URLSearchParamsMapping.storeIds, this.filter.storeIds.sort(numberComparator).join(','));
    }

    if (this.filter.areaType !== DEFAULT_AREA_TYPE) {
      params.append(URLSearchParamsMapping.areaType, this.filter.areaType);
    }

    if (this.filter.areaNames.size > 0) {
      params.append(URLSearchParamsMapping.areaNames, this.filter.areaNames.join(','));
    }

    if (this.filter.searchWords.size > 0) {
      params.append(URLSearchParamsMapping.searchWords, this.filter.searchWords.join(','));
    }

    params.append(URLSearchParamsMapping.startMonth, this.filter.startMonth.toString());
    params.append(URLSearchParamsMapping.endMonth, this.filter.endMonth.toString());

    // 見栄え悪いので、'%2Cは','に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  toRequestParams(stores: Stores): SearchVolumesStartParams {
    const areaType = this.filter.areaType;
    let areas: ImmutableSet<Area>;
    switch (areaType) {
      case 'name': {
        let areaNames = this.filter.areaNames;
        // 1件もなければ、全国「日本」を指定する
        if (areaNames.isEmpty()) {
          areaNames = areaNames.add('日本');
        }
        areas = areaNames.map((name) => ({ name }));
        break;
      }
      case 'store': {
        areas = this.filter.storeIds
          .map((storeId): Area => {
            const store = stores.findStore(storeId);
            return {
              name: store?.location?.address.searchWord ?? '',
              extra_info: {
                store_id: storeId,
                store_name: store?.fullName ?? '',
              },
            };
          })
          .filter((area) => area.name);
        break;
      }
      default:
        return assertNever(areaType);
    }
    const searchWords: ImmutableSet<SearchWord> = this.filter.searchWords.map((searchWord) => ({ name: searchWord }));
    return {
      conditions: {
        areas: areas.toArray(),
        search_words: searchWords.toArray(),
        period: {
          start_month: this.filter.startMonth.toString(),
          end_month: this.filter.endMonth.toString(),
        },
      },
    };
  }
}
