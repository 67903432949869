import React from 'react';

import styled from 'styled-components';

type Props = {
  name: string;
};

const GridArea = styled.div`
  grid-area: ${({ name }: Props) => name};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export default React.memo(GridArea);
