import React from 'react';

import dayjs from 'dayjs';
import { Button as SemanticButton, Label as SemanticLabel } from 'semantic-ui-react';
import styled from 'styled-components';

import { DatePicker } from 'components/atoms/DatePicker';
import { Input } from 'components/atoms/Input';
import { TextArea } from 'components/atoms/TextArea';
import { UploadButton, UploadType, defaultAcceptWithPDF } from 'components/molecules/FileUploader';
import { OfferCommon } from 'models/Composite/OfferCommon';
import { COLOR } from 'style/color';

type Props = {
  offerCommon: OfferCommon;
  accept?: string[];
  uploadType?: UploadType;
  changeOfferCommon: (v: OfferCommon) => void;
  handleAcceptedFiles: (v: File[]) => void;
  handleRejectedFiles: (v: File[]) => void;
};

export const OfferEditForm = React.memo<Props>(
  ({
    offerCommon,
    accept = defaultAcceptWithPDF,
    uploadType = 'file',
    changeOfferCommon,
    handleAcceptedFiles,
    handleRejectedFiles,
  }) => {
    const { errors, hashtags, content } = {
      errors: offerCommon.errors,
      hashtags: offerCommon.provisionalHashtag,
      content: offerCommon.content,
    };

    if (!content) return null;

    return (
      <Wrapper>
        <Field>
          <Label>{offerCommon.getLabel('name')}</Label>
          <Input
            value={offerCommon.name || ''}
            placeholder='依頼 / 報告のタイトル'
            error={errors.name}
            onChange={(value) => changeOfferCommon(offerCommon.changeName(value))}
          />
        </Field>
        <Field>
          <Label>{content.getLabel('text')}</Label>
          <TextArea
            value={offerCommon.text || ''}
            placeholder='詳しい説明'
            error={errors.text}
            onChange={(value) => changeOfferCommon(offerCommon.changeText(value))}
          />
        </Field>
        <Field>
          {hashtags.map((hashtag, index) => {
            return <Hashtag key={index}>#{hashtag}</Hashtag>;
          })}
        </Field>
        <Field>
          <Label>{offerCommon.getLabel('due_date')}</Label>
          <StyledDatePicker
            dateFormat='yyyy/MM/dd'
            selected={offerCommon.dueDate ? offerCommon.dueDate.toDate() : null}
            onChange={(value: Date | null) => value && changeOfferCommon(offerCommon.changeDueDate(dayjs(value)))}
            isClearable={false}
            popperPlacement='bottom'
          />
          {errors.due_date && (
            <SemanticLabel basic color='red' pointing='above'>
              {errors.due_date}
            </SemanticLabel>
          )}
        </Field>
        <Field>
          <Label>{content.getLabel('files')}</Label>
          <UploadButton
            uploadType={uploadType}
            accept={accept}
            onDropAccepted={(files) => handleAcceptedFiles(files)}
            onDropRejected={(files) => handleRejectedFiles(files)}
          />

          <FileGroup>
            {content.files.list.map((taskFile, fileIndex: number) => {
              const { url, mimeType } = taskFile;
              return (
                <FileItem key={fileIndex}>
                  <ObjectContent data={url} type={mimeType} />
                  <FileRight>
                    <TextArea
                      value={taskFile.caption}
                      onChange={(value) => changeOfferCommon(offerCommon.changeFileCaption(fileIndex, value))}
                      placeholder='ファイルの説明'
                    />
                  </FileRight>
                  <DeleteFileButton
                    circular
                    size='mini'
                    icon='close'
                    color='black'
                    onClick={() => changeOfferCommon(offerCommon.deleteFile(fileIndex))}
                  />
                </FileItem>
              );
            })}
          </FileGroup>
        </Field>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  background-color: ${COLOR.WHITE};
`;

const Field = styled.div`
  padding-bottom: 18px;
  padding-right: 18px;
  padding-left: 18px;
  &:first-of-type {
    padding-top: 18px;
  }
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;

const Hashtag = styled.div`
  display: inline-block;
  margin: 0 8px 8px 0;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 15px;
  color: ${COLOR.BLACK};
  background-color: ${COLOR.LIGHT_GRAY};
`;

const FileGroup = styled.div`
  margin-top: 20px;
`;

const FileItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const DeleteFileButton = styled(SemanticButton)`
  &&& {
    position: absolute;
    top: 0;
    left: 55px;
  }
`;

const FileRight = styled.div`
  margin-left: 20px;
  flex: 1;
`;

const ObjectContent = styled.object`
  object-fit: cover;
  width: 70px;
  height: 70px;
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    height: 54px;
    padding: 0 16px;
    font-family: monospace !important;
  }
`;
