import dayjs, { Dayjs } from 'dayjs';
import { Record } from 'immutable';

import { JSObject } from 'types/Common';

import { User } from '../User';

import { Review } from './Review';

import { GmbReviewStatus } from '.';

export class ReviewDetail extends Record<{
  review: Review;
  store_id: number;
  replied_by: User;
  status: GmbReviewStatus;
  can_update: boolean;
  isDeleted: boolean;
  deleteAt: Dayjs | null;
}>({
  review: new Review(),
  store_id: 0,
  replied_by: new User(),
  status: 'todo',
  can_update: false,
  isDeleted: false,
  deleteAt: null,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.review = new Review(params.review);
    params.replied_by = new User(params.replied_by);
    params.isDeleted = params.is_deleted;
    params.deleteAt = params.delete_at ? dayjs(params.delte_at) : null;
    super(params);
  }
}
