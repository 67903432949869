import { List, Record } from 'immutable';

import { Offer } from 'models/Domain/Offer';
import { OtherOffer } from 'models/Domain/OtherOffer';
import { PaginationWithoutLimit } from 'models/Domain/Pagination';
import { Report } from 'models/Domain/Report';
import { Task } from 'models/Domain/Task';
import { OFFER_TYPE_FILTER_ALL, OfferTypeForFilter } from 'models/Other/OfferTypeFilter';
import { JSObject } from 'types/Common';

export class OffersItem extends Record<{
  offer: Offer;
  task: Task;
  report: Report;
  other_offer: OtherOffer;
}>({
  offer: new Offer(),
  task: new Task(),
  report: new Report(),
  other_offer: new OtherOffer(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.offer = new Offer(params.offer);
    params.task = new Task(params.task);
    params.report = new Report(params.report);
    params.other_offer = new OtherOffer(params.other_offer);
    super(params);
  }

  get targetContent() {
    const {
      offer: { offer_type },
      task,
      report,
      other_offer,
    } = this;
    if (offer_type === 'task') {
      return task;
    } else if (offer_type === 'report') {
      return report;
    } else if (offer_type === 'other_offer') {
      return other_offer;
    }
    return task;
  }

  get title() {
    const { targetContent } = this;
    return targetContent.name;
  }
}

interface StoreOfferRecord {
  store_id: number;
  offers: List<OffersItem>;
  pagination: PaginationWithoutLimit;
}

export class StoreOffer extends Record<StoreOfferRecord>({
  store_id: 0,
  offers: List(),
  pagination: new PaginationWithoutLimit(),
}) {
  constructor(data: JSObject = { offers: [] }) {
    const params = { ...data };
    params.offers = List(params.offers.map((offerItem: JSObject) => new OffersItem(offerItem)));
    params.pagination = new PaginationWithoutLimit(params.pagination);
    super(params);
  }

  getOfferByOfferId(offerId: number) {
    return this.offers.find((offersItem) => offersItem.offer.id === offerId);
  }

  getOffersByOfferType(offerType: OfferTypeForFilter) {
    return this.offers.filter(
      (offer) => offerType === undefined || offerType === OFFER_TYPE_FILTER_ALL || offer.offer.offer_type === offerType,
    );
  }

  mergeOffers(storeOffer: StoreOffer) {
    return this.update('offers', (offers) => offers.merge(storeOffer.offers)).set('pagination', storeOffer.pagination);
  }
}

export class StoreOffers extends Record<{
  list: List<StoreOffer>;
}>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data.map((p) => new StoreOffer(p)));
    super({ list });
  }

  margeOffers(data: JSObject) {
    const storeOffer = new StoreOffer(data);
    const targetIndex = this.list.findIndex((storeOffer) => storeOffer.store_id === storeOffer.store_id);
    if (targetIndex < 0) {
      return this;
    }

    return this.updateIn(['list', targetIndex], (targetStoreOffer) => targetStoreOffer.mergeOffers(storeOffer));
  }

  getByStoreId(storeId: number) {
    return this.list.find((storeOffer) => storeOffer.store_id === storeId);
  }

  getOfferByOfferId(offerId: number) {
    const storeOffer = this.list.filter((storeOffer) => storeOffer.getOfferByOfferId(offerId)).get(0);
    if (!storeOffer) return;
    return storeOffer.getOfferByOfferId(offerId);
  }
}
