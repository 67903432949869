import ApiClient from 'ApiClient';
import { StampListGetResponse } from 'types/responses/Stamp';

const apiClient = new ApiClient({});

const STAMP_PATH = '/stamps';

export class StampApi {
  static get() {
    return apiClient.get<StampListGetResponse>(STAMP_PATH);
  }
}
