import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { Counter } from 'components/atoms/Counter';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TagInput } from 'components/molecules/TagInput';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import {
  AreaType,
  SearchVolumeSearchCondition as SearchCondition,
} from 'models/Domain/SearchVolume/SearchVolumeSearchCondition';
import { SIZE } from 'style/size';
import { SelectOption } from 'types/Common';

export const SEARCH_CONDITION_LIMIT = 50;

type Props = {
  isCommitDisabled: boolean;

  committedSearchCondition: SearchCondition;
  onCommitSearchCondition: (searchCondition: SearchCondition) => void;
};

const areaTypeOptions: SelectOption<AreaType>[] = [
  { text: '店舗の住所', value: 'store' },
  { text: '地域を入力', value: 'name' },
];

export const SearchVolumeSearchCondition = React.memo<Props>(
  ({ committedSearchCondition, onCommitSearchCondition, isCommitDisabled }) => {
    const [searchCondition, setSearchCondition] = useState<SearchCondition>(committedSearchCondition);
    const {
      filter: { areaType, areaNames, group, storeIds, searchWords, startMonth, endMonth },
    } = searchCondition;

    // committedSearchConditionが変更されたら更新する
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    const handleOnChangeGroupStore = useCallback(
      (
        group: SearchCondition['filter']['group'],
        storeIds: SearchCondition['filter']['storeIds'],
        isAllStores: SearchCondition['filter']['isAllStoreIds'],
      ) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.merge({
            group: group,
            storeIds: storeIds,
            isAllStoreIds: isAllStores,
          }),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleOnChangeAreaNames = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.merge({ areaNames: ImmutableSet(values) }),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleOnChangeSearchWords = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.merge({ searchWords: ImmutableSet(values) }),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleOnChangePeriod = useCallback(
      (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
          return;
        }
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.changePeriodByDate(startDate, endDate),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleOnChangeAreaType = useCallback(
      (value: SearchCondition['filter']['areaType']) => {
        const newSearchCondition = searchCondition.update('filter', (filter) => filter.merge({ areaType: value }));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleOnCommit = useCallback(() => {
      onCommitSearchCondition(searchCondition);
    }, [onCommitSearchCondition, searchCondition]);

    // 検索条件数(地域数or店舗数×検索ワード数)
    const conditionCount = useMemo(
      () => (areaType === 'name' ? areaNames.size || 1 : storeIds.size) * searchWords.size,
      [areaNames, areaType, searchWords, storeIds],
    );

    return (
      <Wrapper>
        <FlexContainer>
          <InputContent>
            <Label>検索ワード</Label>
            <TagInput values={searchWords.toArray()} onChange={handleOnChangeSearchWords} />
          </InputContent>
          <AreaWrapper>
            <PullDownContent>
              <Label>検索地点</Label>
              <PullDown value={areaType} options={areaTypeOptions} onChange={handleOnChangeAreaType} />
            </PullDownContent>
            {areaType === 'name' && (
              <InputContent>
                <Label>
                  地域
                  <ContextHelp
                    content={
                      '入力された地域をもとに、Google広告上で指定可能な地域で検索されます。一致する地域がない場合は「日本」で検索されます。'
                    }
                  />
                </Label>
                <TagInput
                  values={areaNames.toArray()}
                  onChange={handleOnChangeAreaNames}
                  placeholder={'日本 / 都道府県 / 市区町村 / 住所'}
                />
              </InputContent>
            )}
            {areaType === 'store' && (
              <GroupStoreSelect
                group={group}
                storeIds={storeIds}
                showClosedStores={false}
                onChange={handleOnChangeGroupStore}
                showClosedStoreCheckbox={false}
              />
            )}
          </AreaWrapper>
          <FlexContent>
            <Label>集計期間</Label>
            <StyledDateRangePicker
              startDate={startMonth.toDate()}
              endDate={endMonth.toDate()}
              onChange={handleOnChangePeriod}
              dateFormat={'yyyy年M月'}
              showMonthYearPicker
              // 取得可能な期間は1ヶ月前〜3年前まで(検索ボリュームの取得に1ヶ月かかる、APIの限界は4年で前年比を出すのに1年必要)
              maxDate={dayjs().subtract(1, 'month').endOf('month').toDate()}
              minDate={dayjs().subtract(36, 'month').startOf('month').toDate()}
            />
          </FlexContent>
          <ButtonContainer>
            <CounterWrapper>
              検索条件数 <StyledCounter size={conditionCount} maxSize={SEARCH_CONDITION_LIMIT} />
            </CounterWrapper>
            <StyledButton
              onClick={handleOnCommit}
              disabled={isCommitDisabled || !searchCondition.isValid() || conditionCount > SEARCH_CONDITION_LIMIT}
            >
              検索
            </StyledButton>
          </ButtonContainer>
        </FlexContainer>
      </Wrapper>
    );
  },
);

const Wrapper = styled(Card)`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

const FlexContent = styled.div``;

const PullDownContent = styled(FlexContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
  }
`;

const InputContent = styled(FlexContent)`
  flex: 1;
`;

const Label = styled.div`
  font-size: 16px;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  &&& {
    input {
      max-width: 120px;
      min-width: 90px;
    }
  }
`;

const PullDown = styled(PullDownNarrow).attrs(() => ({ search: false }))`
  &&& {
    width: 176px;
    margin: 8px 27px 8px 0;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: end;
  margin-left: auto;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledButton = styled(Button).attrs({ priority: 'high' })`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;
  }
`;

const CounterWrapper = styled.div`
  margin-left: auto;
`;

const StyledCounter = styled(Counter)`
  display: inline-block;
`;

const AreaWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
