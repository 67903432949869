import ApiClient from 'ApiClient';
import { SortKey, SortOrder } from 'models/Domain/OfferGroup/OfferGroupSearchCondition';
import { JSObject } from 'types/Common';

const apiClient = new ApiClient({});

const OFFER_GROUPS_PATH = '/offergroups';

export type GetOfferGroupRequestParams = {
  store_id: string;
  type: string;
  status: string;
  created_by: string;
  sort_key: SortKey;
  sort_order: SortOrder;
  limit: number;
  page: number;
};

export class OfferGroupsApi {
  static get(params: GetOfferGroupRequestParams) {
    return apiClient.get<JSObject>(OFFER_GROUPS_PATH, params);
  }
}
