import actionCreatorFactory from 'typescript-fsa';

import { Store } from 'models/Domain/Store';
import { StoreSearchCondition } from 'models/Domain/StoreSearchCondition';
import { BulkEditStoreState } from 'models/Other/BulkEditStoreState';
import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('Store');

export const StoreActions = {
  initializeStorePage: actionCreator('initialize'),
  getStores: actionCreator('getStores'),
  setStores: actionCreator<any>('setStores'),
  changeRegisterStore: actionCreator<Store>('changeRegisterStore'),
  registerSingleStore: actionCreator('registerSingleStore'),
  clearRegisterStore: actionCreator('clearRegisterStore'),
  setBulkEditStoreState: actionCreator<BulkEditStoreState>('setBulkEditStoreState'),
  bulkEditStore: actionCreator('bulkEditStore'),
  bulkEditBusinessInfo: actionCreator('bulkEditBusinessInfo'),
  setSearchCondition: actionCreator<StoreSearchCondition>('setSearchCondition'),
  setIsPreparedForStorePage: actionCreator<boolean>('setIsPreparedForStorePage'),
  downloadStoresCsv: actionCreator('downloadStoresCsv'),
  uploadStoresCsv: actionCreator<JSObject>('uploadStoresCsv'),
  checkUploadStoresCsvStatus: actionCreator<number>('checkUploadStoresCsvStatus'),
};
