import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import { GbpPerformanceMACsvCreateParams } from 'ApiClient/GbpPerformanceMAApi';

import { GbpPerformanceMASearchCondition } from './GbpPerformanceMASearchCondition';

/** CSVダウンロードの取得項目 */
export const COLUMNS = [
  'business_impressions_desktop_maps',
  'business_impressions_desktop_search',
  'business_impressions_mobile_maps',
  'business_impressions_mobile_search',
  'website_clicks',
  'call_clicks',
  'business_direction_requests',
  'business_conversations',
  'business_bookings',
  // "business_food_orders",  // 利用しない
  'review_comment_count',
  'review_rate_count',
  'review_reply_count',
  'review_period_average_rating',
  'review_average_rating',
] as const;

export type Column = (typeof COLUMNS)[number];

type GbpPerformanceMACsvDownloadConditionType = {
  // 検索条件
  searchCondition: GbpPerformanceMASearchCondition;
  // 取得対象の項目
  columns: ImmutableSet<Column>;
};

/**
 * GBPパフォーマンスCSVダウンロードの取得条件
 * @param searchCondition 検索条件
 * @param columns 取得対象の項目
 */
export class GbpPerformanceMACsvDownloadCondition extends ImmutableRecord<GbpPerformanceMACsvDownloadConditionType>({
  searchCondition: new GbpPerformanceMASearchCondition(),
  columns: ImmutableSet(COLUMNS),
}) {
  /** CSVダウンロード開始APIのパラメータに変換 */
  toRequestParams(): GbpPerformanceMACsvCreateParams {
    const { aggregateUnit, organizationIds, startDate, endDate } = this.searchCondition.filter;

    return {
      aggregate_unit: aggregateUnit,
      organization_ids: organizationIds.sort().toArray(),
      period: {
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      },
      columns: this.columns.toArray(),
    };
  }

  /** 現在の値が有効かどうか */
  isValid() {
    // 検索条件が有効であること、取得項目が1つ以上設定されていること
    return this.searchCondition.isValid() && this.columns.size > 0;
  }

  /** 検索条件からCSVダウンロード条件を生成する */
  static fromSearchCondition(searchCondition: GbpPerformanceMASearchCondition) {
    return new GbpPerformanceMACsvDownloadCondition({ searchCondition });
  }
}
