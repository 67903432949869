import { Record } from 'immutable';
import { ReducerBuilder, reducerWithInitialState } from 'typescript-fsa-reducers';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { GbpInsightSearchCondition } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { Insight, InsightTableData } from 'models/Domain/GbpInsight/Insight';
import { GmbInsightDownloadModalState } from 'models/Other/GmbInsightDownloadModalState';

import { GbpInsightActions } from './action';

interface GbpInsightStateRecord {
  insight: Insight;
  comparisonInsight: Insight;
  searchCondition: GbpInsightSearchCondition;
  committedSearchCondition: GbpInsightSearchCondition;
  downloadModalState: GmbInsightDownloadModalState;
  csvDownload: ExecutionArnStatus;
  isPreparedPage: boolean;
  isInitializedSearchCondition: boolean; // ページランディング時にURLから検索条件の初期化が完了しているか
  isLoadingGraphData: boolean;
  isLoadingTableData: boolean;
}

export class GbpInsightState extends Record<GbpInsightStateRecord>({
  insight: new Insight(),
  comparisonInsight: new Insight(),
  searchCondition: new GbpInsightSearchCondition(),
  committedSearchCondition: new GbpInsightSearchCondition(),
  downloadModalState: new GmbInsightDownloadModalState(),
  csvDownload: new ExecutionArnStatus(),
  isPreparedPage: false,
  isInitializedSearchCondition: false,
  isLoadingGraphData: false,
  isLoadingTableData: false,
}) {}

export const gbpInsightReducer: ReducerBuilder<GbpInsightState, GbpInsightState, GbpInsightState> =
  reducerWithInitialState(new GbpInsightState())
    .case(GbpInsightActions.setInsightGraphData, (state, payload) => {
      return state.setIn(['insight', 'graphData'], payload[0]).setIn(['comparisonInsight', 'graphData'], payload[1]);
    })
    .case(GbpInsightActions.setInsightTableData, (state, payload) => {
      return state.setIn(['insight', 'tableData'], payload[0]).setIn(['comparisonInsight', 'tableData'], payload[1]);
    })
    .case(GbpInsightActions.changeSortType, (state, payload) => {
      return state.updateIn(['insight', 'tableData'], (tableData: InsightTableData) =>
        tableData.changeSortType(payload),
      );
    })
    .case(GbpInsightActions.updateInsight, (state, payload) => {
      return state.set('insight', payload);
    })
    .case(GbpInsightActions.setSearchCondition, (state, payload) => {
      return state.set('searchCondition', payload.searchCondition);
    })
    .case(GbpInsightActions.setCommittedSearchCondition, (state, payload) => {
      return state.set('committedSearchCondition', payload);
    })
    .case(GbpInsightActions.setDownloadModalState, (state, payload) => {
      return state.set('downloadModalState', payload);
    })
    .case(GbpInsightActions.setCsvDownload, (state, payload) => {
      return state.set('csvDownload', payload);
    })
    .case(GbpInsightActions.clearCsvDownload, (state) => {
      return state.set('csvDownload', new ExecutionArnStatus());
    })
    .case(GbpInsightActions.setIsPreparedPage, (state, payload) => {
      return state.set('isPreparedPage', payload);
    })
    .case(GbpInsightActions.setIsInitializedSearchCondition, (state, payload) => {
      return state.set('isInitializedSearchCondition', payload);
    })
    .case(GbpInsightActions.setIsLoadingGraphData, (state, payload) => {
      return state.set('isLoadingGraphData', payload);
    })
    .case(GbpInsightActions.setIsLoadingTableData, (state, payload) => {
      return state.set('isLoadingTableData', payload);
    })
    .case(GbpInsightActions.clearInsightData, (state) => {
      return state.set('insight', new Insight());
    });
