import React from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';

export const UpdateModal = React.memo(() => {
  const onClickReload = () => {
    // ページの再読み込み
    window.location.reload();
  };

  return (
    <StyledModal open={true}>
      <Wrapper>
        <Title>アプリケーションが更新されました</Title>
        <Text>ページを再読み込みしてください。</Text>
        <Text>実行してもこの画面が表示される場合は、時間をおいてから再度お試しください。</Text>
        <StyledButton priority={'high'} onClick={onClickReload}>
          再読み込み
        </StyledButton>
      </Wrapper>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  &&& {
    width: min(400px, 100%);
  }
`;

const Wrapper = styled.div`
  padding: 16px;
`;

const Text = styled.div`
  padding: 8px;
  line-height: 1.5;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: min(400px, 100%);
    height: 42px;
    padding: 0;
    margin-top: 32px;
  }
`;
