import React from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon as _Icon } from 'components/atoms/Icon';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';

type Props = { className?: string; onClick: () => void; isSelected: boolean };

export const NotificationIcon = React.memo<Props>(({ className, onClick, isSelected }) => {
  const { notificationList } = useSelector(
    (state: State) => ({
      notificationList: state.notification.notificationList,
    }),
    shallowEqual,
  );
  const noticeCount = notificationList.filter((noti) => noti.is_read === false).size;
  const iconType = isSelected ? 'bell_frame' : 'bell';
  return (
    <NotificationWrapper className={className} onClick={() => onClick()}>
      <Icon type={iconType} />
      {noticeCount > 0 && <NotificationNumber>{noticeCount >= 100 ? '99' : noticeCount}</NotificationNumber>}
    </NotificationWrapper>
  );
});

const NotificationWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 44px;
  cursor: pointer;
`;

const NotificationNumber = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  display: inline-block;
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${COLOR.GOLDEN_YELLOW};
  text-align: center;
  font-size: 10px;
`;

const Icon = styled(_Icon)`
  padding: 5px;
`;
