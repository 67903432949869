import dayjs from 'dayjs';
import { Record } from 'immutable';

import { JSObject } from 'types/Common';

/**
 * コメント
 */
export class Reviewer extends Record<{
  profilePhotoUrl: string;
  displayName: string;
  isAnonymous: boolean;
}>({
  profilePhotoUrl: '',
  displayName: '',
  isAnonymous: false,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.createTime = dayjs(params.createTime);
    params.updateTime = dayjs(params.updateTime);
    super(params);
  }

  /** View表示用の名前 */
  get name() {
    if (this.isAnonymous) {
      return '匿名のユーザー';
    }
    return `${this.displayName}`;
  }
}
