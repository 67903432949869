import actionCreatorFactory from 'typescript-fsa';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { TranslationLanguage } from 'models/Domain/Organization';
import Promotion, { Media } from 'models/Domain/Promotion/Promotion';
import { FilterStatus } from 'models/Domain/Promotion/PromotionDownloadCondition';
import { PromotionGroup } from 'models/Domain/Promotion/PromotionGroup';
import PromotionList from 'models/Domain/Promotion/PromotionList';
import { PromotionSearchCondition } from 'models/Domain/Promotion/PromotionSearchCondition';

export type GetCsvDownloadParameterType = ReturnType<FilterStatus['getCsvDownloadRequestParams']>;

const actionCreator = actionCreatorFactory('Promotion');

export const PromotionActions = {
  getPromotion: actionCreator<string>('getPromotion'),
  getBasePromotion: actionCreator<string>('getBasePromotion'),
  setPromotion: actionCreator<Promotion>('setPromotion'),
  setPromotionGroup: actionCreator<PromotionGroup>('setPromotionGroup'),
  getPromotionForEdit: actionCreator<string>('getPromotionForEdit'),

  setPromotionGroupForEdit: actionCreator<PromotionGroup>('setPromotionGroupForEdit'),
  setPromotionForEdit: actionCreator<Promotion>('setPromotionForEdit'),

  uploadFile: actionCreator<File[]>('uploadFile'),
  setMediaForEdit: actionCreator<Media>('setMediaForEdit'),
  deleteMediaForEdit: actionCreator<number>('deleteMediaForEdit'),
  clearMediaForEdit: actionCreator('clearMediaForEdit'),

  createPromotion: actionCreator<Promotion>('createPromotion'),
  updatePromotion: actionCreator<Promotion>('updatePromotion'),
  deletePromotion: actionCreator<{ id: string; search?: string }>('deletePromotion'),

  setPromotionList: actionCreator<PromotionList>('setPromotionList'),

  setSearchCondition: actionCreator<{ searchCondition: PromotionSearchCondition; updateLocation?: boolean }>(
    'setSearchCondition',
  ),
  setCommittedSearchCondition: actionCreator<PromotionSearchCondition>('setCommittedSearchCondition'),
  fetchPromotionList: actionCreator('fetchPromotionList'),
  initializePromotionListPage: actionCreator('initializePromotionListPage'),
  setIsPreparedForPromotionListPage: actionCreator<boolean>('setIsPreparedForPromotionListPage'),
  setIsLoadingPromotionList: actionCreator<boolean>('setIsLoadingPromotionList'),
  setIsLoadingPromotion: actionCreator<boolean>('setIsLoadingPromotion'),

  // 投稿の実績データのCSVダウンロード
  getCsvDownload: actionCreator<GetCsvDownloadParameterType>('getCsvDownload'),
  setCsvDownload: actionCreator<ExecutionArnStatus>('setCsvDownload'),
  clearCsvDownload: actionCreator('clearCsvDownload'),
  checkCsvDownloadStatus: actionCreator<number>('checkCsvDownloadStatus'),
  setIsOpenDownloadModal: actionCreator<boolean>('setIsOpenDownloadModal'),

  setLoadingLanguages: actionCreator<{ language: TranslationLanguage; isLoading: boolean }>('setLoadingLanguages'),
  translatePost: actionCreator<{ language: TranslationLanguage; promotion: Promotion }>('translatePost'),
};
