import React from 'react';

import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { OfferStatus as Status } from 'components/atoms/OfferStatus';
import { OfferTypeLabel } from 'components/atoms/OfferTypeLabel';
import { OfferGroups } from 'models/Domain/OfferGroup/OfferGroup';
import { Stores } from 'models/Domain/Store';
import { UserList } from 'models/Domain/User';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Props = {
  offerGroups: OfferGroups;
  stores: Stores;
  userList: UserList;
};

/**
 * 投稿の内容を表示
 * 投稿一覧と投稿詳細で使用
 */
export const OfferGroupsList: React.FC<Props> = ({ offerGroups, stores, userList }) => {
  return (
    <Wrapper>
      <StyledTable selectable>
        <TableHeader>
          <Table.Row>
            <Table.HeaderCell>タイトル</Table.HeaderCell>
            <Table.HeaderCell>種別</Table.HeaderCell>
            <Table.HeaderCell>店舗</Table.HeaderCell>
            <Table.HeaderCell>作成者</Table.HeaderCell>
            <Table.HeaderCell>更新日</Table.HeaderCell>
            <Table.HeaderCell>期限</Table.HeaderCell>
            <Table.HeaderCell>進捗</Table.HeaderCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {offerGroups.items.map((item) => {
            const firstOffer = item.offers.list.get(0);
            if (!firstOffer) {
              return;
            }
            const firstStore = stores.findStore(firstOffer.store_id);

            let linkPath: string;
            switch (item.type) {
              case 'task':
                linkPath = Path.task.detail.replace(/:taskId\([^)]*\)/g, String(item.real_id));
                break;
              case 'report':
              case 'other_offer':
                linkPath = Path.offer.detail.replace(':offerId', String(firstOffer.id));
                break;
            }

            // 作成者を取得する
            const author = userList.findUser(item.created_by);

            // タスクが期限切れかどうか(期限過ぎているけど未完了のもの)
            const isOverdue = item.isOverdue && item.status !== 'done';
            return (
              <Table.Row key={item.id}>
                <StyledTableCell>
                  <CellContent>
                    <StyledTitle>
                      <MobileOnlyOfferTypeLabel type={item.type} />
                      <StyledLink to={linkPath}>{item.name}</StyledLink>
                    </StyledTitle>
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <DesktopOnly>
                    <CellContent>
                      <OfferTypeLabel type={item.type} />
                    </CellContent>
                  </DesktopOnly>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>店舗</MobileOnlyLabel>
                    {firstStore?.fullName} {item.offers.list.size > 1 && <>他{item.offers.list.size - 1}店舗</>}
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>作成者</MobileOnlyLabel>
                    {author && author.profile_image_url && <AuthorIcon src={author.profile_image_url} />}
                    {author?.fullName}
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>更新日</MobileOnlyLabel>
                    {item.latest_activity_time.fromNow()}
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>期限</MobileOnlyLabel>
                    <StyledDueDate isOverdue={isOverdue}>{item.due_date.format('YYYY/MM/DD')}</StyledDueDate>
                  </CellContent>
                </StyledTableCell>
                <StyledTableCell>
                  <CellContent>
                    <MobileOnlyLabel>進捗</MobileOnlyLabel>
                    <Status value={item.status === 'done' ? 'done' : `${item.done_stores} / ${item.total_stores}`} />
                  </CellContent>
                </StyledTableCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </StyledTable>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: scroll;
`;

const StyledTable = styled(Table)`
  &&& {
    font-size: 13px;
    margin-top: 6px;
    white-space: nowrap;
  }
`;

const TableHeader = styled(Table.Header)`
  @media (max-width: 599px) {
    &&& {
      /* モバイル表示時はテーブルのヘッダを表示しない（セル内に、ラベルを表示させるので） */
      /* !important書かないと効かないレベルでCSSががんじがらめ。 */
      /* ただ、このスタイルの発動条件は、かなり限定的なので影響はない */
      display: none !important;
    }
  }
`;

const StyledTableCell = styled(Table.Cell)`
  vertical-align: middle;
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  font-weight: bold;
  font-size: 1.5em;
  white-space: normal;
  @media (min-width: 600px) {
    font-size: 1em;
    max-width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
`;

const MobileOnlyOfferTypeLabel = styled(OfferTypeLabel)`
  &&& {
    margin-right: 8px;
    display: inline-flex;
    vertical-align: text-top;
    @media (min-width: 600px) {
      display: none;
    }
  }
`;

const AuthorIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 8px;
  vertical-align: top;
`;

const StyledDueDate = styled.div<{ isOverdue: boolean }>`
  color: ${({ isOverdue }) => (isOverdue ? COLOR.RED : COLOR.BLACK)};
`;

const MobileOnly = styled.div`
  display: inline-block;
  @media (min-width: 600px) {
    display: none;
  }
`;

const DesktopOnly = styled.div`
  display: inline-block;
  @media (max-width: 599px) {
    display: none;
  }
`;

const MobileOnlyLabel = styled(MobileOnly)`
  width: 100px;
  font-weight: bold;
`;
