import React from 'react';

import styled from 'styled-components';

import { AlbumImageListContent } from 'components/organisms/AlbumImageListContent';
import CheckedImageList from 'models/Domain/CheckedImageList';
import Image, { ImageList as ImageListModel } from 'models/Domain/Image/Image';

type Props = {
  imageList: ImageListModel;
  imageResourceIdList: CheckedImageList;
  showCheckbox: boolean;
  onChangeCheck: (image: Image) => void;
};

export const ImageList = React.memo<Props>(({ imageList, imageResourceIdList, showCheckbox, onChangeCheck }) => {
  return (
    <Wrapper>
      <AlbumImageListContent
        checkedImageList={imageResourceIdList}
        onChangeCheck={onChangeCheck}
        imageList={imageList.list}
        showCheckbox={showCheckbox}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div``;
