import React, { useCallback, useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { COLOR } from 'style/color';

import { Notification } from './Common';

export type AreaKeywordTableItem = {
  areaName: string;
  searchWord: string;
  hasAds: boolean | null;
  managedStoreCount: number | null;
  competitorCount: number | null;
  status: 'RUNNING' | 'SUCCESS' | 'FAILED' | 'WARNING';
  replacedAreaName?: string | null;
};

type AreaKeywordTableProps = {
  className?: string;
  items: AreaKeywordTableItem[];
  onSelectAreaKeyword: (condition: { areaName: string; searchWord: string }) => void;
};

const FOLDED_TABLE_ITEMS = 3;

type SortKey = keyof AreaKeywordTableItem | null;
type SortOrder = 'asc' | 'desc' | null;

export const AreaKeywordTable = React.memo(({ className, items, onSelectAreaKeyword }: AreaKeywordTableProps) => {
  const [showAllData, setShowAllData] = useState(false);

  const [sortKey, setSortKey] = useState<SortKey>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(null);

  const onChangeSort = useCallback(
    (key: keyof AreaKeywordTableItem) => {
      let order: SortOrder;
      if (key !== sortKey) {
        order = 'asc';
      } else {
        switch (sortOrder) {
          case 'asc':
            order = 'desc';
            break;
          case 'desc':
            order = null;
            break;
          default:
            order = 'asc';
            break;
        }
      }
      setSortKey(key);
      setSortOrder(order);
    },
    [sortKey, sortOrder],
  );

  // データのソート
  const sortedItems = useMemo(() => {
    let _items = items;
    if (sortKey && sortOrder) {
      _items = sortBy(_items, sortKey);
      if (sortOrder == 'desc') {
        _items = _items.reverse();
      }
    }

    if (!showAllData) {
      _items = _items.slice(0, FOLDED_TABLE_ITEMS);
    }
    return _items;
  }, [items, sortKey, sortOrder, showAllData]);

  return (
    <Wrapper className={className}>
      <Table unstackable>
        <TableHeader>
          <TableHeaderRow>
            <SortableTableHeaderCell onClick={() => onChangeSort('status')} width={1}>
              <SortTriangleWrapper>
                {sortKey === 'status' && sortOrder && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
              </SortTriangleWrapper>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell onClick={() => onChangeSort('areaName')}>
              <SortTriangleWrapper>
                {sortKey === 'areaName' && sortOrder && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
              </SortTriangleWrapper>
              検索地点
            </SortableTableHeaderCell>
            <SortableTableHeaderCell onClick={() => onChangeSort('searchWord')}>
              <SortTriangleWrapper>
                {sortKey === 'searchWord' && sortOrder && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
              </SortTriangleWrapper>
              検索ワード
            </SortableTableHeaderCell>
            <TableHeaderCell>広告の有無</TableHeaderCell>
            <TableHeaderCell>所属組織の店舗数</TableHeaderCell>
            <TableHeaderCell>比較対象の店舗数</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {sortedItems.map((item) => (
            <StyledTableRow key={`${item.areaName}_${item.searchWord}`} onClick={() => onSelectAreaKeyword(item)}>
              <StatusCell>
                {item.status === 'SUCCESS' ? (
                  <StyledIcon type='status_active' />
                ) : item.status === 'FAILED' ? (
                  <StyledIcon type='status_error' />
                ) : item.status === 'WARNING' ? (
                  <StyledContextHelp
                    iconType='status_warning'
                    content={`「${item.areaName}」で検索地点を特定できなかったため、「${item.replacedAreaName}」で検索を実行しました。`}
                  />
                ) : (
                  <LoaderWrapper>
                    <Loader active={true} size='mini' inline />
                  </LoaderWrapper>
                )}
              </StatusCell>
              <TableCell>
                {item.replacedAreaName ? (
                  <>
                    <OriginalAreaName>{item.areaName}</OriginalAreaName>
                    {item.replacedAreaName}
                  </>
                ) : (
                  item.areaName
                )}
              </TableCell>
              <TableCell>{item.searchWord}</TableCell>
              <TableCell>{item.hasAds === null ? '-' : item.hasAds ? 'あり' : 'なし'}</TableCell>
              <TableCell>{item.managedStoreCount == null ? '-' : item.managedStoreCount}</TableCell>
              <TableCell>{item.competitorCount == null ? '-' : item.competitorCount}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {items.length === 0 && <Notification>データ取得中</Notification>}
      {items.length > FOLDED_TABLE_ITEMS && (
        <>
          {!showAllData ? (
            <ViewMore onClick={() => setShowAllData(true)}>全て表示する</ViewMore>
          ) : (
            <ViewMore onClick={() => setShowAllData(false)}>表示を減らす</ViewMore>
          )}
        </>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const StatusCell = styled(TableCell)`
  display: flex;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

const StyledContextHelp = styled(ContextHelp)`
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
`;

const LoaderWrapper = styled.div``;

const SortTriangleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 16px;
`;

const ViewMore = styled.div`
  width: 100%;
  margin: 8px 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const OriginalAreaName = styled.span`
  margin-right: 8px;
  text-decoration: line-through;
  color: ${COLOR.GRAY};
`;
