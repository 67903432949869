import React, { useMemo } from 'react';

import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { PullDown } from 'components/atoms/PullDown';
import CheckedImageList from 'models/Domain/CheckedImageList';
import { getCategoryOptions } from 'models/Domain/Image/Image';

export const BulkGmbPublishModal = React.memo<{
  isOpen: boolean;
  onClose: () => void;
  checkedImageList: CheckedImageList;
  changeCheckedImageList: (checkedImageList: CheckedImageList) => void;
  onClickStartBulkUpload: () => void;
  canUseProductCategory: boolean;
}>(({ isOpen, onClose, checkedImageList, changeCheckedImageList, onClickStartBulkUpload, canUseProductCategory }) => {
  const categoryOptions = useMemo(
    () => getCategoryOptions({ canUseProduct: canUseProductCategory }),
    [canUseProductCategory],
  );

  return (
    <Modal open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>写真をGoogleビジネスプロフィールに掲載する</Modal.Header>
      <Modal.Content>
        <Description>Googleビジネスプロフィール カテゴリーを選択してください</Description>
        <Category>
          <PullDown
            value={checkedImageList.category}
            options={categoryOptions}
            onChange={(v) => changeCheckedImageList(checkedImageList.changeCategory(v))}
            placeholder={'カテゴリー'}
          />
        </Category>
        <StoreimagesWrapper>
          {checkedImageList.imagesByStore.map((storeimage) => (
            <Storeimages key={storeimage.store.store_id}>
              <StoreName>{storeimage.store.store_name}</StoreName>
              <StoreimageWrapper>
                {storeimage.images.map((image) => (
                  <Storeimage key={image.image_resource_id}>
                    <img src={image.url} />
                  </Storeimage>
                ))}
              </StoreimageWrapper>
            </Storeimages>
          ))}
        </StoreimagesWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Buttons>
          <ButtonWrapper>
            <CancelButton onClick={() => onClose()}>キャンセル</CancelButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <PublishButton
              priority='high'
              onClick={() => {
                onClose();
                onClickStartBulkUpload();
              }}
            >
              掲載する
            </PublishButton>
          </ButtonWrapper>
        </Buttons>
      </Modal.Actions>
    </Modal>
  );
});

const Heading = css`
  font-size: 16px;
  line-height: 208.19%;
`;

const Description = styled.h2`
  ${Heading};
  margin: 5px 0;
`;

const Category = styled.div`
  margin-bottom: 39px;
`;

const StoreimagesWrapper = styled.div`
  height: 350px;
  overflow: auto;
`;

const Storeimages = styled.div``;

const StoreName = styled.div`
  margin: 11px 0;
`;

const StoreimageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -6.5px 0 0 -6.5px;
`;

const Storeimage = styled.div`
  margin: 6.5px;

  img {
    object-fit: contain;
    width: 105px;
    height: 68px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

const ButtonWrapper = styled.div`
  @media (max-width: 600px) {
    width: calc(50% - 8px);
    margin-bottom: 16px;
  }
`;

const CancelButton = styled(Button)`
  @media (max-width: 600px) {
    &&& {
      width: 100%;
    }
  }
`;

const PublishButton = styled(Button)`
  margin-left: 16px;

  @media (max-width: 600px) {
    &&& {
      width: 100%;
    }
    margin-left: 0;
  }
`;
