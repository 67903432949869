import React, { useCallback, useEffect, useMemo } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Arrow } from 'components/atoms/Arrow';
import { Loader } from 'components/atoms/Loader';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { CompetitorDetailContents } from 'components/pageComponents/CompetitorDetail/CompetitorDetailContents';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useHoldOn } from 'hooks/useHoldOn';
import { Competitor } from 'models/Domain/Competitor/Competitor';
import { AppActions } from 'modules/app/actions';
import { CompetitorActions } from 'modules/competitor/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
};

export const CompetitorDetail = React.memo<Props>(() => {
  const { id } = useParams<{ id: string }>();

  const { competitor, isPreparedDetailPage } = useSelector((state) => state.competitor);
  const dispatch = useDispatch();
  const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
  const { initializeDetailPage, setCompetitor, putCompetitor, deleteCompetitor } = useMemo(
    () => bindActionCreators(CompetitorActions, dispatch),
    [dispatch],
  );
  const isLoading = useHoldOn(!isPreparedDetailPage, 1000);
  const { state } = useLocation<{ source: string; name: string }>();

  const handleOnClickBackButton = useCallback(() => {
    // 遷移元が競合店舗一覧なら検索条件やスクロール位置を保持するために戻る、それ以外なら競合店舗一覧に遷移
    if (state?.source === Path.competitors.index) {
      dispatch(goBack());
    } else {
      dispatch(replaceWithOrganizationId(Path.competitors.index));
    }
  }, [dispatch, state?.source]);

  useEffect(() => {
    const competitorId = parseInt(id, 10);
    if (!isNaN(competitorId)) {
      initializeDetailPage(competitorId);
    }
  }, [id, initializeDetailPage]);

  const title = competitor?.name ?? state?.name ?? '競合店舗詳細';

  const handleOnChange = useCallback(
    (id: number, competitor: Competitor) => {
      setCompetitor(competitor);
      putCompetitor({ id, competitor });
    },
    [putCompetitor, setCompetitor],
  );

  const handleOnDelete = useCallback(() => {
    if (!competitor || !window.confirm(`競合店舗「${competitor.name}」を削除します。\nよろしいですか？`)) {
      return;
    }
    deleteCompetitor(competitor.id);
    moveTo(Path.competitors.index);
  }, [competitor, deleteCompetitor, moveTo]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle(title)} />
      <StickyHeader>
        <TitleWrapper onClick={handleOnClickBackButton}>
          <Arrow direction={'left'} color={COLOR.BLACK} length={16} weight={3} />
          <Title>{title}</Title>
        </TitleWrapper>
      </StickyHeader>
      <Body>
        <Wrapper>
          {isLoading && (
            <LoadingWrapper>
              <Loader active={true} size={'big'} inline={true} />
            </LoadingWrapper>
          )}
          {!isLoading && !!competitor && (
            <CompetitorDetailContents competitor={competitor} onChange={handleOnChange} onDelete={handleOnDelete} />
          )}
        </Wrapper>
      </Body>
    </MainWrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 200px;
`;
