import React from 'react';

import { shade, tint, transparentize } from 'polished';
import styled, { CSSProperties } from 'styled-components';

import { COLOR } from 'style/color';

type Props = {
  className?: string;
  label: string;
  value: number;
  ratio?: number | null;
  style?: CSSProperties;
  selected: boolean;
  color: string;
  showRatio: boolean;
  showIcon: boolean;
};

export const ProgramStatusCard = React.memo<Props>(
  ({ className, color, label, value, ratio = null, style, selected, showRatio, showIcon }) => {
    return (
      <Wrapper className={className} style={style} color={color} selected={selected}>
        <Label color={color} selected={selected} showIcon={showIcon}>
          {label}
        </Label>
        <Value>{value.toLocaleString()}</Value>
        {showRatio && <Ratio>{ratio !== null ? `${(ratio * 100).toFixed()}%` : '-'}</Ratio>}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ selected: boolean; color: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  background: ${COLOR.WHITE};
  position: relative;
  cursor: pointer;
  height: 100%;

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 2px solid ${({ selected, color }) => (selected ? color : 'transparent')};
  }

  :hover {
    background: ${({ selected, color }) => tint(0.8, color)};
    :after {
      border: 2px solid ${({ selected, color }) => (selected ? shade(0.2, color) : 'transparent')};
    }
  }
`;

type LabelProps = {
  color: string;
  selected: boolean;
  showIcon: boolean;
};

const Label = styled.div<LabelProps>`
  color: ${COLOR.BLACK};
  font-size: 14px;
  white-space: nowrap;
  flex: 1;
  @media (max-width: 700px) {
    white-space: pre-wrap;
  }
  line-height: 1.5;
  ${({ showIcon, color, selected }) =>
    showIcon &&
    `
      :before {
        content: '■';
        margin-right: 2px;
        color: ${transparentize(selected ? 0 : 0.5, color)};
      }
    `}
`;
const Value = styled.div`
  color: ${COLOR.BLACK};
  font-weight: bold;
  font-size: 24px;
  white-space: nowrap;
  line-height: 1.8;
`;
const Ratio = styled.div`
  color: ${COLOR.BLACK};
  font-size: 16px;
  line-height: 1.4;
`;
