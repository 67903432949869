import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { Notification } from 'components/pageComponents/MapCompetitorResearch/Common';
import { ComparisonStoreTable } from 'components/pageComponents/MapCompetitorResearch/ComparisonStoreTable';
import { CompetitorList, MAX_COMPETITOR_SIZE } from 'models/Domain/MapCompetitorResearch/Competitor';
import { StoreKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { COLOR } from 'style/color';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChangeCompetitor: (storeKey: StoreKey) => void;
  onChangeCompetitors: (storeKeys: List<StoreKey>) => void;
  onSelectStore: (storeKey: StoreKey) => void;
  competitors: CompetitorList;
  storeKeys: List<StoreKey>;
  managedStoreKeys: List<StoreKey>;
}

export const ComparisonStoreModal = React.memo<Props>(
  ({
    isOpen,
    competitors,
    onClose,
    onSelectStore,
    onChangeCompetitor,
    onChangeCompetitors,
    storeKeys,
    managedStoreKeys,
  }) => {
    const [selectedStores, setSelectedStores] = useState<List<StoreKey>>(List());
    const handleOnChangeStores = useCallback(
      (value) => {
        if (value.length + competitors.size > MAX_COMPETITOR_SIZE) {
          return;
        }
        setSelectedStores(List(value));
      },
      [competitors],
    );
    const handleOnClickAdd = useCallback(() => {
      if (selectedStores.size > 0) {
        onChangeCompetitors(selectedStores);
      }
      setSelectedStores(List());
    }, [onChangeCompetitors, selectedStores]);

    const handleOnChangeCompetitor = useCallback(
      (value: StoreKey) => {
        // PullDownで選択中に表から追加されたら、selectedStoresから削除する
        if (selectedStores.contains(value)) {
          setSelectedStores(selectedStores.filterNot((store) => store === value));
        }
        onChangeCompetitor(value);
      },
      [onChangeCompetitor, selectedStores],
    );

    const getOptions = useMemo(() => {
      return storeKeys
        .filter((storeKey) => !competitors.contains(storeKey))
        .map((storeKey) => {
          return { text: storeKey.storeName, value: storeKey };
        })
        .sortBy((x) => x.value)
        .toArray();
    }, [competitors, storeKeys]);

    useEffect(() => {
      // モーダルが開く/閉じるタイミングで保存されていない選択された店舗をクリアする
      return () => {
        setSelectedStores(List());
      };
    }, [isOpen]);

    return (
      <StyledModal open={isOpen}>
        <ModalContent>
          <Wrapper>
            <Label>比較店舗設定</Label>
            <Description>比較対象にする店舗を最大10店舗まで設定できます</Description>
            <AddContainer>
              <PullDown
                placeholder={'追加したい店舗を選択してください'}
                value={selectedStores.toArray()}
                options={getOptions}
                onChange={handleOnChangeStores}
                closeOnChange={false}
                multiple
                disabled={competitors.size >= MAX_COMPETITOR_SIZE}
              />
              <AddButton priority={'high'} onClick={handleOnClickAdd} disabled={selectedStores.isEmpty()}>
                追加
              </AddButton>
            </AddContainer>
            <ContentWrapper>
              <ComparisonStoreTable
                competitors={competitors}
                onChangeCompetitor={handleOnChangeCompetitor}
                onSelectStore={onSelectStore}
                managedStoreKeys={managedStoreKeys}
              />
              {competitors.list.isEmpty() && managedStoreKeys.isEmpty() && (
                <Notification>比較店舗が設定されていません</Notification>
              )}
            </ContentWrapper>
            <ButtonWrapper>
              <CloseButton onClick={onClose}>閉じる</CloseButton>
            </ButtonWrapper>
          </Wrapper>
        </ModalContent>
      </StyledModal>
    );
  },
);

const StyledModal = styled(Modal)`
  height: 80%;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

const Label = styled.label`
  color: ${COLOR.BLACK};
  display: block;
  margin: 8px 0 16px;
  font-size: 20px;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px 24px;
`;

const AddButton = styled(Button)`
  &&& {
    width: 150px;
    height: 42px;
    padding: 0;
  }
`;

const CloseButton = styled(Button)`
  &&& {
    width: 150px;
    height: 42px;
    padding: 0;
  }
`;

const AddContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
`;

const PullDown = styled(PullDownNarrow)`
  flex: 1;

  &&& {
    & .ui.multiple.search.selection.dropdown {
      font-size: 14px;
      padding: 10px 8px;

      > .label {
        margin: 0 2px 2px;
      }

      > .search {
        padding: 0 !important;
      }
    }
  }
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
`;
