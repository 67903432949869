import React, { useCallback, useMemo, useState } from 'react';

import { List } from 'immutable';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { CompetitorList } from 'models/Domain/MapCompetitorResearch/Competitor';
import {
  MapSearchResultDetails,
  StoreKey,
  convertToConditionKey,
} from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';

import { AreaKeywordLabel, Notification, RankCell, SearchWord, ShopIcon } from './Common';
interface Props {
  className?: string;
  details: MapSearchResultDetails;
  competitors: CompetitorList;
  managedStoreKeys: List<StoreKey>;
  onSelectAreaKeyword: (condition: { areaName: string; searchWord: string }) => void;
}

type SortKey = string | null;
type SortOrder = 'asc' | 'desc' | null;

export const RankComparisonTable = React.memo<Props>(
  ({ className, details, competitors, onSelectAreaKeyword, managedStoreKeys }) => {
    const [isExcludeAds, setIsExcludeAds] = useState(true);

    const [sortKey, setSortKey] = useState<SortKey>(null); // ソートキーは店舗名
    const [sortOrder, setSortOrder] = useState<SortOrder>(null);

    const datas = useMemo(() => details.getRankComparisonTableData(isExcludeAds), [details, isExcludeAds]);

    // データを選択店舗の順位の昇順/降順でソートする
    const sortedDatas = useMemo(() => {
      if (!sortKey || !sortOrder) {
        return datas;
      }

      const _sortedDatas = datas.sortBy(
        (data) =>
          (isExcludeAds ? data.itemsByStoreName[sortKey]?.rankExcludeAds : data.itemsByStoreName[sortKey]?.rank) ?? 60,
      );

      if (sortOrder === 'desc') {
        return _sortedDatas.reverse();
      }
      return _sortedDatas;
    }, [datas, isExcludeAds, sortKey, sortOrder]);

    const onChangeSort = useCallback(
      (key: string) => {
        let order: SortOrder;
        if (key !== sortKey) {
          order = 'asc';
        } else {
          switch (sortOrder) {
            case 'asc':
              order = 'desc';
              break;
            case 'desc':
              order = null;
              break;
            default:
              order = 'asc';
              break;
          }
        }
        setSortKey(key);
        setSortOrder(order);
      },
      [sortKey, sortOrder],
    );

    const averageByStoreName = useMemo(
      () =>
        competitors.list.reduce(
          (current, competitor) => {
            const sum = datas.reduce((total, data) => {
              const storeData = data.itemsByStoreName[competitor.storeName];
              return total + ((isExcludeAds ? storeData?.rankExcludeAds : storeData?.rank) ?? 60);
            }, 0);

            // 小数点以下1桁に調整
            const average = Math.round((sum / datas.size) * 10) / 10;

            current[competitor.storeName] = average;
            return current;
          },
          {} as { [key: string]: number },
        ),
      [competitors.list, datas, isExcludeAds],
    );

    // 比較店舗が設定されていない場合は、表示するコンテンツがないため、設定させるためのメッセージを表示する
    if (competitors.size === 0) {
      return (
        <Wrapper className={className} style={{ overflow: 'hidden' }}>
          <Header style={{ justifyContent: 'flexStart' }}>
            <TitleLabel>順位比較</TitleLabel>
          </Header>
          <StyledNotification>
            「順位比較」では、比較設定された店舗の検索条件ごとの順位を比較することができます。
            <br />
            ページ上部の「比較店舗を設定する」などから、比較したい店舗を選択してください。
          </StyledNotification>
        </Wrapper>
      );
    }

    return (
      <Wrapper className={className}>
        <Header>
          <TitleLabel>順位比較</TitleLabel>
          <CheckboxContainer>
            <StyledCheckbox
              onChange={() => setIsExcludeAds((value) => !value)}
              checked={isExcludeAds}
              label='広告を除外する'
            />
          </CheckboxContainer>
        </Header>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell width={2} />
              {competitors.list.map((competitor) => (
                <StyledTableHeaderCell
                  width={1}
                  key={competitor.storeName}
                  onClick={() => onChangeSort(competitor.storeName)}
                >
                  <HeaderCellInner>
                    <SortTriangleWrapper>
                      {sortKey === competitor.storeName && sortOrder && (
                        <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />
                      )}
                    </SortTriangleWrapper>
                    <StoreNameWrapper>
                      <StoreName>{competitor.storeName}</StoreName>
                      {managedStoreKeys.contains(competitor.getStoreKey()) && <ShopIcon />}
                    </StoreNameWrapper>
                  </HeaderCellInner>
                  <ColorBar style={{ background: competitor.color }} />
                </StyledTableHeaderCell>
              ))}
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>平均</TableCell>
              {competitors.list.map((competitor) => {
                const average = averageByStoreName[competitor.storeName];
                return <RankCell key={competitor.storeName} rank={average} />;
              })}
            </TableRow>
            {sortedDatas.map((data) => {
              const { condition, itemsByStoreName } = data;
              return (
                <TableRow key={convertToConditionKey(condition)}>
                  <TableCell>
                    <SearchWord onClick={() => onSelectAreaKeyword(condition)}>
                      <AreaKeywordLabel areaName={condition.areaName} searchWord={condition.searchWord} isLink />
                    </SearchWord>
                  </TableCell>
                  {competitors.list.map((competitor) => {
                    const storeData = itemsByStoreName[competitor.storeName];
                    return (
                      <RankCell
                        key={competitor.storeName}
                        rank={isExcludeAds ? storeData?.rankExcludeAds : storeData?.rank}
                        ads={storeData?.isAds}
                        color
                      />
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const ColorBar = styled.div`
  position: absolute;
  height: 8px;
  bottom: -1px;
  left: 1px;
  right: 1px;
`;

const StyledTableHeaderCell = styled(SortableTableHeaderCell)`
  position: relative;
`;

const HeaderCellInner = styled.div`
  display: flex;
  align-items: center;
`;

const SortTriangleWrapper = styled.div`
  width: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleLabel = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 14px;
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledNotification = styled(Notification)`
  border-top-width: 1px;
`;

const StoreNameWrapper = styled.div``;

const StoreName = styled.span``;
