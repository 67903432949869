import axios, { AxiosResponse } from 'axios';
import { is } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { MapSearchResultApi } from 'ApiClient/GmbApi/MapSearchResultApi';
import { buildPath, pushWithOrganizationId } from 'helpers/router';
import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import {
  MapSearchResultDetail,
  MapSearchResultDetailFailed,
} from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { MapSearchResultStatus } from 'models/Domain/MapCompetitorResearch/MapSearchResultStatus';
import { waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

import { MapCompetitorResearchActions as Actions } from './actions';
import { MapCompetitorResearchSelectors as Selectors } from './selectors';

export default function* saga() {
  yield takeLatest(Actions.initializeIndexPage, initializeIndexPage);
  yield takeLatest(Actions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(Actions.initializeResultPage, initializeResultPage);
  yield takeLatest(Actions.getResultStatus, getResultStatus);
}

// 検索順位調査画面を初期化する
function* initializeIndexPage() {
  yield put(Actions.clearState());
}

function* commitSearchCondition(action: ReturnType<typeof Actions.commitSearchCondition>) {
  const searchCondition: YieldReturn<typeof Selectors.selectSearchCondition> = yield select(
    Selectors.selectSearchCondition,
  );

  if (searchCondition.areaNames.isEmpty() || searchCondition.searchWords.isEmpty()) {
    return;
  }

  // 検索順位取得を開始する
  const params = {
    conditions: {
      area_names: searchCondition.areaNames.toArray(),
      search_words: searchCondition.searchWords.toArray(),
    },
  };

  const response: YieldReturn<typeof MapSearchResultApi.start> = yield MapSearchResultApi.start(params);

  if (response.isSuccess) {
    const compressedExecutionArn = btoa(response.data.executionArn);
    yield put(
      pushWithOrganizationId(buildPath(Path.mapCompetitorResearch.result, { params: { id: compressedExecutionArn } })),
    );

    return;
  } else {
    toast({
      type: 'error',
      title: 'Googleマップ順位調査を開始できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }
}

function* initializeResultPage(action: ReturnType<typeof Actions.initializeResultPage>) {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);
  yield put(Actions.clearState());
  yield put(Actions.setExecutionArn(new ExecutionArnStatus({ executionArn: action.payload.executionArn })));
  yield put(Actions.setCompetitors(action.payload.competitors));
  yield put(Actions.getResultStatus());
}

function* getResultStatus(action: ReturnType<typeof Actions.getResultStatus>) {
  const executionArn: YieldReturn<typeof Selectors.selectExecutionArn> = yield select(Selectors.selectExecutionArn);
  if (!executionArn) {
    return;
  }

  // まだローディング状態じゃない場合、ローディング状態に遷移する
  const isLoadingResult: YieldReturn<typeof Selectors.selectIsLoadingResult> = yield select(
    Selectors.selectIsLoadingResult,
  );
  if (!isLoadingResult) {
    yield put(Actions.setIsLoadingResult(true));
  }

  // 検索順位取得状況を取得する
  const response: YieldReturn<typeof MapSearchResultApi.getResult> = yield MapSearchResultApi.getResult(
    executionArn.toJSON(),
  );

  if (response.isSuccess) {
    const currentResultStatus: YieldReturn<typeof Selectors.selectResultStatus> = yield select(
      Selectors.selectResultStatus,
    );

    // ステータスデータを生成し、変化があったら更新する
    const resultStatus = MapSearchResultStatus.fromJSON(response.data);

    // 詳細データ(追加更新していく)
    let resultDetails: YieldReturn<typeof Selectors.selectResultDetails> = yield select(Selectors.selectResultDetails);

    // データの準備ができているけど未取得のデータを取得する
    let hasUpdate = false; // 詳細データの更新があったか
    for (const item of resultStatus.result.items.toArray()) {
      // まだデータが準備できていない場合はスキップ
      if (!item.url) {
        continue;
      }

      // 既に取得済みの場合はスキップ
      if (resultDetails.hasDetail(item.condition)) {
        continue;
      }

      let dataResponse: AxiosResponse<any> = yield axios.get<any>(item.url, { cache: true });

      // たまに307応答でリダイレクトURLが指定される場合の対応
      // NOTE Axiosにリダイレクトフォロー機能があればな。。。
      if (!dataResponse.data && (((dataResponse.status ?? 0) / 100) | 0) === 3) {
        const redirectUrl = dataResponse.headers['location'];
        console.log('redirect', redirectUrl);

        if (redirectUrl) {
          // リダイレクトURLをもとにリダイレクト実施
          dataResponse = yield axios.get<any>(dataResponse.headers['location'], { cache: true });
        }
      }

      if (dataResponse.data) {
        hasUpdate = true;
        if (dataResponse.data.status === 'SUCCESS') {
          // データ取得が成功している場合
          resultDetails = resultDetails.setDetail(item.condition, MapSearchResultDetail.fromJSON(dataResponse.data));
        } else {
          // データ取得が失敗している場合
          resultDetails = resultDetails.setDetail(item.condition, new MapSearchResultDetailFailed());
        }
      }
    }

    // 本来immutable.isで比較できるはずなのに、うまく動作しないため、JSON形式にして比較する
    if (!is(JSON.stringify(resultStatus.toJS()), JSON.stringify(currentResultStatus?.toJS()))) {
      yield put(Actions.setResultStatus(resultStatus));

      // 検索条件のデータを更新する
      hasUpdate = true;
      resultDetails = resultDetails.setConditions(resultStatus.result.items.map((item) => item.condition));
    }

    // 更新がある場合ストアに反映
    if (hasUpdate) {
      yield put(Actions.setResultDetails(resultDetails));
    }

    if (response.data.status === 'RUNNING') {
      // 3秒後に再実行
      yield delay(3000);
      yield put(Actions.getResultStatus());
    } else {
      yield put(Actions.setIsLoadingResult(false));
    }
  } else {
    toast({
      type: 'error',
      title: 'Googleマップ順位調査結果が取得できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }
}
