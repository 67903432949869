import React from 'react';

import { Auth } from '@aws-amplify/auth';
import { isEmpty } from '@aws-amplify/core';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import Logo from 'components/atoms/Logo';
import { COLOR } from 'style/color';

import { AuthPieceProps } from './AuthPiece';

type State = {
  password: string;
};

export class CustomRequireNewPassword extends React.Component<AuthPieceProps, State> {
  readonly state: State = {
    password: '',
  };

  triggerAuthEvent = (event: any) => {
    const state = this.props.authState;
    if (this.props.onAuthEvent) {
      this.props.onAuthEvent(state, event);
    }
  };

  changeState = (state: string, data?: any) => {
    if (this.props.onStateChange) {
      this.props.onStateChange(state, data);
    }

    this.triggerAuthEvent({
      type: 'stateChange',
      data: state,
    });
  };

  error = (err: any) => {
    this.triggerAuthEvent({
      type: 'error',
      data: this.errorMessage(err),
    });
  };

  errorMessage = (err: any) => {
    if (typeof err === 'string') {
      return err;
    }
    return err.message ? err.message : JSON.stringify(err);
  };

  checkContact = (user: any) => {
    Auth.verifiedContact(user).then((data) => {
      if (!isEmpty(data.verified)) {
        this.changeState('signedIn', user);
      } else {
        user = Object.assign(user, data);
        this.changeState('verifyContact', user);
      }
    });
  };

  change = async () => {
    const user = this.props.authData;
    const {
      challengeParam: { requiredAttributes },
    } = user;

    const { password } = this.state;

    try {
      await Auth.completeNewPassword(user, password, requiredAttributes);
    } catch (err) {
      this.error(err);
      return;
    }

    if (user.challengeName === 'SMS_MFA') {
      this.changeState('confirmSignIn', user);
    } else if (user.challengeName === 'MFA_SETUP') {
      this.changeState('TOTPSetup', user);
    } else {
      this.checkContact(user);
    }
  };

  render() {
    const { authState } = this.props;
    if (authState !== 'requireNewPassword') {
      return null;
    }

    const { password } = this.state;

    return (
      <Wrapper>
        <LogoWrapper>
          <Logo width='260px' />
        </LogoWrapper>

        <FormWrapper>
          <FormTitle>パスワードの設定</FormTitle>
          <FormText>
            パスワードは次の条件を全て満たす必要があります
            <br />
            - アルファベット・数字の両方を含む
            <br />- アルファベットは大文字・小文字の両方を含む
            <br />- 8文字以上
          </FormText>
          <FormItemWrapper>
            <FormInput
              type='password'
              icon='lock'
              iconPosition='left'
              value={password}
              placeholder='パスワード'
              onChange={(password: string) => this.setState({ password })}
            />
          </FormItemWrapper>

          <FormItemWrapper margin='50'>
            <CustomButton
              priority='high'
              onClick={(e) => {
                e.preventDefault();
                this.change();
              }}
            >
              パスワードを設定
            </CustomButton>
          </FormItemWrapper>

          <FormItemWrapper>
            <CustomButton
              onClick={(e) => {
                e.preventDefault();
                this.changeState('signIn');
              }}
            >
              戻る
            </CustomButton>
          </FormItemWrapper>
        </FormWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

const LogoWrapper = styled.div`
  margin-bottom: 80px;
  text-align: center;
`;

const FormWrapper = styled.form`
  background-color: ${COLOR.WHITE};
  padding: 18px;
`;

const FormTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
`;

const FormText = styled.p`
  margin-top: 18px;
`;

const FormItemWrapper = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.margin ? `${props.margin}px` : '18px')};
  width: 100%;
`;

const FormInput = styled(Input)`
  &&& {
    width: 280px;
  }
`;

const CustomButton = styled(Button)`
  &&& {
    width: 280px;
  }
`;
