import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import { Arrow } from 'components/atoms/Arrow';
import { COLOR } from 'style/color';

export type SwitchContentProps = {
  openLabel: string; // 領域が開いているときの開閉ボタンのラベル
  closedLabel: string; // 領域が閉じているときの開閉ボタンのラベル
  criteriaMediaQuery?: string;
  openContents?: React.ReactNode;
  closedContents?: React.ReactNode;
  children: React.ReactNode;
};

export const SwitchContent: React.FC<SwitchContentProps> = ({
  children,
  openLabel,
  closedLabel,
  criteriaMediaQuery = '(max-width: 599px)',
  openContents,
  closedContents,
}) => {
  // デスクトップ表示かどうか
  const [enabled, setEnabled] = useState(window.matchMedia(criteriaMediaQuery).matches);
  // モバイル表示時のフィルターの開閉状態
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // デスクトップ/モバイル問わないフィルターの開閉状態
  const isSwitchOpen = !enabled || isFilterOpen;

  // ウィンドウサイズが替わった際の処理（isDesktopの更新）
  const handleResize = useCallback(() => {
    setEnabled(window.matchMedia(criteriaMediaQuery).matches);
  }, [criteriaMediaQuery]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <Wrapper>
      {!isSwitchOpen && closedContents}
      {isSwitchOpen && openContents}
      {isSwitchOpen && children}
      <Switch
        open={isSwitchOpen}
        hasClosedContents={!!closedContents}
        onClick={() => setIsFilterOpen((state) => !state)}
      >
        <SwitchLabel>{isSwitchOpen ? openLabel : closedLabel}</SwitchLabel>
        <SwitchArrow direction={isSwitchOpen ? 'up' : 'down'} />
      </Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Switch = styled.div<{ open: boolean; hasClosedContents: boolean }>`
  background: ${COLOR.WHITE};
  height: 56px;
  cursor: pointer;
  padding: 0 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 600px) {
    display: none;
  }

  margin-top: ${({ open, hasClosedContents }) => (open || hasClosedContents ? '4px' : '0px')};
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.2px;

  color: ${COLOR.BLACK};
`;

const SwitchArrow = styled(Arrow).attrs(() => ({
  color: COLOR.BLACK,
  length: 12,
  weight: 2,
}))`
  margin-left: auto;
`;
