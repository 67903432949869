import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { COLOR } from './color';
import { SIZE } from './size';

import 'semantic-ui-css/semantic.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html > body {
    font-family: 'Montserrat', "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif !important;
    font-size: ${SIZE.FONT.BASE}px;
    line-height: ${SIZE.LINE_HEIGHT};
    letter-spacing: ${SIZE.LETTER_SPACING}px;
    color: ${COLOR.BLACK};
    background-color: ${COLOR.BACKGROUND};

    * {
      -webkit-overflow-scrolling: touch;
      &::placeholder {
        color: ${COLOR.GRAY};
      }
    }

    h1, h2, h3, h4, h5, h6, div, button, input, select, textarea {
      font-family : inherit !important;
    }

    p {
      white-space: pre-wrap;
      word-break: break-word;
    }

    input,textarea {
      font-size: ${SIZE.FONT.BASE}px;
    }

    a, a:hover {
      color: ${COLOR.BLACK};
    }

    > div#root {
      height: ${SIZE.MAX_HEIGHT}%;
    }

    #intercom-container {
      iframe {
        @media (max-width: 600px) {
          bottom: 45px;
          &[name='intercom-messenger-frame'] {
            bottom: 0;
          }
        }
      }
    }

  }
` as any;
