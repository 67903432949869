export const PROGRAMS = ['shopping_ads', 'local_inventory_ads', 'free_listings', 'free_local_listings'] as const;
export type Program = (typeof PROGRAMS)[number];

export const PRODUCT_STATUSES = ['active', 'expiring', 'pending', 'disapproved'] as const;
export type ProductStatusStatus = (typeof PRODUCT_STATUSES)[number];

export const GMC_PERFORMANCE_PROGRAMS = ['shopping_ads', 'free_listings', 'free_local_listings'] as const;
export type GMCPerformanceProgram = (typeof GMC_PERFORMANCE_PROGRAMS)[number];

export const GMC_PERFORMANCE_METRICS = ['impressions', 'clicks', 'ctr'] as const;
export type GMCPerformanceMetric = (typeof GMC_PERFORMANCE_METRICS)[number];

export const GA_PERFORMANCE_PROGRAMS = ['free', 'ads'] as const;
export type GAPerformanceProgram = (typeof GA_PERFORMANCE_PROGRAMS)[number];

export const GA_PERFORMANCE_METRICS = ['sessions', 'conversions', 'revenues'] as const;
export type GAPerformanceMetric = (typeof GA_PERFORMANCE_METRICS)[number];

export type PerformanceProgram = GMCPerformanceProgram | GAPerformanceProgram;
export const PERFORMANCE_PROGRAMS: PerformanceProgram[] = [...GMC_PERFORMANCE_PROGRAMS, ...GA_PERFORMANCE_PROGRAMS];

export type PerformanceMetric = GMCPerformanceMetric | GAPerformanceMetric;
export const PERFORMANCE_METRICS: PerformanceMetric[] = [...GMC_PERFORMANCE_METRICS, ...GA_PERFORMANCE_METRICS];

export const calcDiff = (current: number, comparison: number): number => {
  return ((current - comparison) / comparison) * 100;
};
