import React, { useCallback } from 'react';

import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { COLOR } from 'style/color';

const EXCLUDED_KEYWORD_MAX_LENGTH = 128;

export const matchTypeOptions = [
  { text: '完全一致', value: true },
  { text: '部分一致', value: false },
];

export const ExcludedKeywordCreateModal = React.memo<{
  isOpen: boolean;
  keyword: string;
  exactMatch: boolean;
  disabled: boolean;
  errorMessage: string;
  onChange: (keyword: string, matchType: boolean) => void;
  onCreate: (keyword: string, matchType: boolean) => void;
  onClose: () => void;
}>(({ isOpen, keyword, exactMatch, disabled, errorMessage, onChange, onCreate, onClose }) => {
  const onChangeKeyword = useCallback(
    (keyword: string) => {
      onChange(keyword, exactMatch);
    },
    [onChange, exactMatch],
  );

  const onChangeMatchType = useCallback(
    (exactMatch: boolean) => {
      onChange(keyword, exactMatch);
    },
    [onChange, keyword],
  );

  return (
    <Modal size='small' open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Content>
        <ContentWrapper>
          <Label>除外キーワードの新規作成</Label>
          <EditContentWrapper>
            <LeftContent>
              <LabelWrapper>
                <ItemLabel>除外キーワード</ItemLabel>
                <StyledTextCount size={keyword.length} maxSize={EXCLUDED_KEYWORD_MAX_LENGTH} />
              </LabelWrapper>
              <Input value={keyword} error={disabled && errorMessage} onChange={onChangeKeyword} />
            </LeftContent>
            <RightContent>
              <ItemLabel>除外判定タイプ</ItemLabel>
              <SmallPullDown value={exactMatch} options={matchTypeOptions} onChange={onChangeMatchType} />
            </RightContent>
          </EditContentWrapper>
        </ContentWrapper>
      </Modal.Content>
      <ButtonWrapper>
        <CloseButton onClick={onClose}>キャンセル</CloseButton>
        <ApplyButton priority='high' onClick={() => onCreate(keyword, exactMatch)} disabled={disabled}>
          追加
        </ApplyButton>
      </ButtonWrapper>
    </Modal>
  );
});

const ContentWrapper = styled.div``;

const Label = styled.label`
  color: ${COLOR.BLACK};
  display: block;
  margin-top: 8px;
  font-size: 24px;
  font-weight: bold;
  &:first-of-type {
    margin-top: 0;
  }
`;

const EditContentWrapper = styled.div`
  display: flex;
  margin-top: 36px;
`;

const LeftContent = styled.div`
  width: calc(100% - 150px);
  padding-right: 40px;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightContent = styled.div`
  width: 130px;
`;

const ItemLabel = styled.label`
  color: ${COLOR.BLACK};
  display: flex;
  margin-bottom: 4px;
  font-weight: bold;
  &:first-of-type {
    margin-top: 0;
  }
`;

const StyledTextCount = styled(Counter)`
  font-size: 13px;
  margin: 6px 6px 0 0;
`;

const SmallPullDown = styled(PullDown)`
  &&& {
    & > .ui.selection.dropdown {
      padding: 17px 16px;
      min-height: 53px;
      min-width: 130px;
      & > input {
        padding: 17px 16px !important;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const ButtonCommonStyle = css`
  max-width: 150px;
  width: calc(50% - 10px);
  padding: 14px 8px;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
    margin-left: 20px;
  }
`;
