import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

// 常に適用する除外キーワードの最大文字数
export const EXCLUDED_KEYWORD_MAX_LENGTH = 128;

type ExcludedKeywordRecord = {
  id: number;
  organizationId: number;
  keyword: string;
  exactMatch: boolean; // trueの場合keywordの完全一致, falseの場合部分一致
};

type ExcludedKeywordsRecord = {
  items: List<ExcludedKeyword>;
};

/**
 * キーワード分析画面から常に除外するキーワード
 */
export class ExcludedKeyword extends Record<ExcludedKeywordRecord>({
  id: 0,
  organizationId: 0,
  keyword: '',
  exactMatch: false, // trueの場合keywordの完全一致, falseの場合部分一致
}) {
  static fromJSON(data: JSObject): ExcludedKeyword {
    return new ExcludedKeyword({
      id: data.id,
      organizationId: data.organization_id,
      keyword: data.keyword,
      exactMatch: data.exact_match,
    });
  }

  validate(excludedKeywords?: ExcludedKeywords) {
    if (this.keyword.length === 0) {
      return 'キーワードを入力してください';
    } else if (this.keyword.length > EXCLUDED_KEYWORD_MAX_LENGTH) {
      return `キーワードの文字数は${EXCLUDED_KEYWORD_MAX_LENGTH}文字以内で入力してください`;
    } else if (excludedKeywords && excludedKeywords.items.includes(this)) {
      return 'すでに同じキーワードを登録済みです';
    }
    return '';
  }

  match(keyword: string) {
    if (this.exactMatch) {
      return keyword === this.keyword;
    }
    return keyword.indexOf(this.keyword) !== -1;
  }
}

/**
 * ExcludedKeywordのコレクション
 */
export class ExcludedKeywords extends Record<ExcludedKeywordsRecord>({
  items: List<ExcludedKeyword>(),
}) {
  static fromJSON(data: JSObject): ExcludedKeywords {
    return new ExcludedKeywords({
      items: List(data.items.map((item: JSObject) => ExcludedKeyword.fromJSON(item))),
    });
  }

  match(keyword: string) {
    return this.items.find((item) => item.match(keyword)) !== undefined;
  }
}
