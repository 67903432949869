import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Account, AccountList } from 'models/Domain/AccountList';
import { GmbLocationDiffs } from 'models/Domain/GmbLocationDiffs';
import { User } from 'models/Domain/User';

import { AppActions } from './actions';

export interface StateRecord {
  currentUser: User;
  isLogoLoading: boolean;
  isLoading: boolean;
  gmbLocationDiffs: GmbLocationDiffs;
  needsUpdate: boolean;
  isInitialized: boolean;
  accountList: AccountList;
  currentAccount: Account;
}

export class AppState extends Record<StateRecord>({
  currentUser: new User(),
  isLogoLoading: false,
  isLoading: false,
  gmbLocationDiffs: new GmbLocationDiffs(),
  needsUpdate: false,
  isInitialized: false,
  accountList: new AccountList(),
  currentAccount: new Account(),
}) {}

export const appReducer = reducerWithInitialState(new AppState())
  .case(AppActions.setLoading, (state, isLoading) => {
    return state.set('isLoading', isLoading);
  })
  .case(AppActions.setCurrentUser, (state, currentUser) => {
    return state.set('currentUser', currentUser);
  })
  .case(AppActions.clearCurrentUser, (state, payload) => {
    return state.set('currentUser', new User());
  })
  .case(AppActions.setLogoLoading, (state, payload) => {
    return state.set('isLogoLoading', payload);
  })
  .case(AppActions.setGmbLocationDiffs, (state, payload) => {
    return state.set('gmbLocationDiffs', payload);
  })
  .case(AppActions.setNeedsUpdate, (state, payload) => {
    return state.set('needsUpdate', payload);
  })
  .case(AppActions.setIsInitialized, (state, payload) => {
    return state.set('isInitialized', payload);
  })
  .case(AppActions.setAccountList, (state, payload) => {
    return state.set('accountList', payload);
  })
  .case(AppActions.setCurrentAccount, (state, payload) => {
    return state.set('currentAccount', payload);
  });
