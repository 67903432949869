import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import StoreList, { StoreLists } from 'models/Domain/StoreList';

import { StoreListActions } from './actions';

export interface StateRecord {
  storeLists: StoreLists;
  storeListForCreate: StoreList;
  storeListForEdit: StoreList;
}

export class StoreListState extends Record<StateRecord>({
  storeLists: new StoreLists(),
  storeListForCreate: new StoreList(),
  storeListForEdit: new StoreList(),
}) {}

export const StoreListReducer = reducerWithInitialState(new StoreListState())
  .case(StoreListActions.setStoreList, (state, payload) => {
    return state.set('storeLists', new StoreLists(payload, true));
  })
  .case(StoreListActions.setStoreListForCreate, (state, payload) => {
    return state.set('storeListForCreate', payload);
  })
  .case(StoreListActions.clearStoreListForCreate, (state, payload) => {
    return state.set('storeListForCreate', new StoreList());
  })
  .case(StoreListActions.setStoreListForEdit, (state, payload) => {
    return state.set('storeListForEdit', payload);
  });
