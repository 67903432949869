import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Set as ImmutableSet, is } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import { GbpPerformanceMAApi, GbpPerformanceMACsvApi } from 'ApiClient/GbpPerformanceMAApi';
import { pushWithOrganizationId } from 'helpers/router';
import { AccountList } from 'models/Domain/AccountList';
import {
  GbpPerformanceMAGraphData,
  GbpPerformanceMAGraphItemList,
  GbpPerformanceMAMonthlyData,
  GbpPerformanceMATableData,
  GbpPerformanceMATableItemList,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { GbpPerformanceMACsvDownloadCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMACsvDownloadCondition';
import { GbpPerformanceMASearchCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMASearchCondition';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

import { GbpPerformanceMAActions } from './actions';

export default function* saga() {
  yield takeLatest(GbpPerformanceMAActions.initializePage, initializePage);
  yield takeLatest(GbpPerformanceMAActions.commitSearchCondition, commitSearchConditionProxy);
  yield takeLatest(GbpPerformanceMAActions.downloadCsv, downloadCsv);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const searchCondition: GbpPerformanceMASearchCondition = yield select(
    (state: State) => state.gbpPerformanceMA.searchCondition,
  );

  // 検索条件が残っている場合、URLの復元を実施する
  if (!searchCondition) {
    // URLパラメータから検索条件を初期化する
    yield call(initializeSearchCondition);
  } else {
    // URLの更新だけを行う
    yield call(updateQueryPerameter);
  }
}

// ページへのランディング時の初期化処理(データの取得を伴う)
function* initializePageForFirstRendering() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // URLパラメータから検索条件を初期化する
  yield call(initializeSearchCondition);
  yield call(commitSearchCondition, null);
}

/**
 * URLのクエリパラメータから検索条件の初期化を行う
 */
function* initializeSearchCondition() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  yield put(GbpPerformanceMAActions.setIsInitializedSearchCondition(false));
  let searchCondition = GbpPerformanceMASearchCondition.fromURLSearchParams(location.search);
  const currentOrganizationIds = searchCondition.filter.organizationIds;
  const accountList: AccountList = yield select((state: State) => state.app.accountList);
  const availableOrganizationIds = ImmutableSet(accountList.items.map((item) => item.organizationId));
  if (currentOrganizationIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setOrganizationIds(availableOrganizationIds, true),
    );
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setOrganizationIds(
        currentOrganizationIds.intersect(availableOrganizationIds),
        currentOrganizationIds.equals(availableOrganizationIds),
      ),
    );
  }

  yield put(GbpPerformanceMAActions.setSearchCondition(searchCondition));
  yield put(GbpPerformanceMAActions.setIsInitializedSearchCondition(true));
}

function* commitSearchConditionProxy(action: ReturnType<typeof GbpPerformanceMAActions.commitSearchCondition>) {
  yield call(commitSearchCondition, action.payload);
}

function* commitSearchCondition(searchCondition: GbpPerformanceMASearchCondition | null) {
  let prevSearchCondition: GbpPerformanceMASearchCondition | null = null;
  if (searchCondition) {
    // 現在の検索条件を保持しておく
    yield put(GbpPerformanceMAActions.updatePrevSearchCondition());
    prevSearchCondition = yield select((state: State) => state.gbpPerformanceMA.prevSearchCondition);

    yield put(GbpPerformanceMAActions.setSearchCondition(searchCondition));
  } else {
    searchCondition = yield select((state: State) => state.gbpPerformanceMA.searchCondition);
  }

  const isFirstSearch: boolean = yield select((state: State) => state.gbpPerformanceMA.isFirstSearch);

  // 検索条件に差分がある場合、データの取得及びURLの更新を実施する
  if (searchCondition && (isFirstSearch || !is(searchCondition, prevSearchCondition))) {
    if (isFirstSearch) {
      yield put(GbpPerformanceMAActions.setIsFirstSearch(false));
    }

    // URLの更新を行う
    yield call(updateQueryPerameter);
    // データの取得を行う
    yield call(updateGbpPerformanceMA);
  }
}

function* updateQueryPerameter() {
  const searchCondition: GbpPerformanceMASearchCondition | null = yield select(
    (state: State) => state.gbpPerformanceMA.searchCondition,
  );
  if (!searchCondition) {
    return;
  }
  // URLの更新を行う
  const location: Location = yield select((state: State) => state.router.location);
  const search = searchCondition.toURLSearchParams();
  const path = `${location.pathname}?${search}`;
  yield put(pushWithOrganizationId(path));
}

/**
 * URLパラメータから検索条件を復元して取得する
 */
function* updateGbpPerformanceMA() {
  const searchCondition: GbpPerformanceMASearchCondition = yield select(
    (state: State) => state.gbpPerformanceMA.searchCondition,
  );

  const prevSearchCondition: GbpPerformanceMASearchCondition | null = yield select(
    (state: State) => state.gbpPerformanceMA.prevSearchCondition,
  );

  // 変更前の検索条件との差分がdisplayTypeなら、データを取得せずに終了
  if (prevSearchCondition?.hasDiffDisplayType(searchCondition)) {
    return;
  }

  // 月ごとの比較のデータを取得
  yield fork(fetchMonthlyData);

  // 変更前の検索条件との差分がtargetMonthなら、テーブル / グラフデータを取得せずに終了
  if (prevSearchCondition?.hasDiffTargetMonth(searchCondition)) {
    return;
  }

  // テーブル / グラフのデータを取得
  yield fork(fetchGraphAndTableData);
  yield fork(fetchGraphAndTableComparisonData);
}

function* fetchGraphAndTableData() {
  yield put(GbpPerformanceMAActions.setIsLoadingData(true));
  const searchCondition: GbpPerformanceMASearchCondition = yield select(
    (state: State) => state.gbpPerformanceMA.searchCondition,
  );
  const params = searchCondition.toRequestParams();
  const response: YieldReturn<typeof GbpPerformanceMAApi.get> = yield GbpPerformanceMAApi.get(params);
  const graphData: GbpPerformanceMAGraphData = yield select((state: State) => state.gbpPerformanceMA.graphData);
  const tableData: GbpPerformanceMATableData = yield select((state: State) => state.gbpPerformanceMA.tableData);
  let newGraphData: GbpPerformanceMAGraphData;
  let newTableData: GbpPerformanceMATableData;
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'GBPパフォーマンスのデータ取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    // 取得できたと誤解されないように取得済みのデータは空にする
    newGraphData = graphData.set('target', new GbpPerformanceMAGraphItemList());
    newTableData = tableData.set('target', new GbpPerformanceMATableItemList());
  } else {
    const data = response.data;
    const graphItemList = GbpPerformanceMAGraphItemList.fromJSON(data.graph_items);
    const tableItemList = GbpPerformanceMATableItemList.fromJSON(data.table_items);
    newGraphData = graphData.set('target', graphItemList);
    newTableData = tableData.set('target', tableItemList);
  }
  yield put(GbpPerformanceMAActions.setGraphData(newGraphData));
  yield put(GbpPerformanceMAActions.setTableData(newTableData));
  yield put(GbpPerformanceMAActions.setIsLoadingData(false));
}

function* fetchGraphAndTableComparisonData() {
  yield put(GbpPerformanceMAActions.setIsLoadingComparisonData(true));
  const searchCondition: GbpPerformanceMASearchCondition = yield select(
    (state: State) => state.gbpPerformanceMA.searchCondition,
  );
  // 比較が無効の場合は以降実施しない
  if (!searchCondition.filter.isEnabledComparison) {
    yield put(GbpPerformanceMAActions.setIsLoadingComparisonData(false));
    return;
  }
  const params = searchCondition.toRequestParams(true);
  const response: YieldReturn<typeof GbpPerformanceMAApi.get> = yield GbpPerformanceMAApi.get(params);
  const graphData: GbpPerformanceMAGraphData = yield select((state: State) => state.gbpPerformanceMA.graphData);
  const tableData: GbpPerformanceMATableData = yield select((state: State) => state.gbpPerformanceMA.tableData);
  let newGraphData: GbpPerformanceMAGraphData;
  let newTableData: GbpPerformanceMATableData;
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'GBPパフォーマンス（比較）のデータ取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    // 取得できたと誤解されないように取得済みのデータは空にする
    newGraphData = graphData.set('comparison', new GbpPerformanceMAGraphItemList());
    newTableData = tableData.set('comparison', new GbpPerformanceMATableItemList());
  } else {
    const data = response.data;
    const graphItemList = GbpPerformanceMAGraphItemList.fromJSON(data.graph_items);
    const tableItemList = GbpPerformanceMATableItemList.fromJSON(data.table_items);
    newGraphData = graphData.set('comparison', graphItemList);
    newTableData = tableData.set('comparison', tableItemList);
  }
  yield put(GbpPerformanceMAActions.setGraphData(newGraphData));
  yield put(GbpPerformanceMAActions.setTableData(newTableData));

  yield put(GbpPerformanceMAActions.setIsLoadingComparisonData(false));
}

function* fetchMonthlyData() {
  yield put(GbpPerformanceMAActions.setIsLoadingMonthlyData(true));
  const searchCondition: GbpPerformanceMASearchCondition = yield select(
    (state: State) => state.gbpPerformanceMA.searchCondition,
  );
  if (searchCondition.filter.isEnabledComparison) {
    // isEnabledComparisonがtrueの場合は、表示しないので取得せず終了
    yield put(GbpPerformanceMAActions.setIsLoadingMonthlyData(false));
    return;
  }
  const params = searchCondition.toMonthlyDataRequestParams();
  const response: YieldReturn<typeof GbpPerformanceMAApi.getMonthly> = yield GbpPerformanceMAApi.getMonthly(params);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'GBPパフォーマンス（月ごとの比較）のデータ取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(
      GbpPerformanceMAActions.setMonthlyData(
        GbpPerformanceMAMonthlyData.fromJSON({
          condition: {
            month: searchCondition.filter.targetMonth.format('YYYY-MM'),
            start_date: searchCondition.filter.startDate.format('YYYY-MM-DD'),
            end_date: searchCondition.filter.endDate.format('YYYY-MM-DD'),
            organization_ids: searchCondition.filter.organizationIds.sort().toArray(),
          },
          items: {
            month: null,
            last_month: null,
            three_months_ago: null,
            twelve_months_ago: null,
          },
        }),
      ),
    );
  } else {
    const monthlyData = GbpPerformanceMAMonthlyData.fromJSON(response.data);
    yield put(GbpPerformanceMAActions.setMonthlyData(monthlyData));
  }
  yield put(GbpPerformanceMAActions.setIsLoadingMonthlyData(false));
}

/**
 * CSVダウンロード
 */
function* downloadCsv() {
  yield put(AppActions.setLoading(true));

  const condition: GbpPerformanceMACsvDownloadCondition = yield select(
    (state: State) => state.gbpPerformanceMA.csvDownloadCondition,
  );
  const params = condition.toRequestParams();

  // executionArnの取得
  const executionArnResponse: YieldReturn<typeof GbpPerformanceMACsvApi.create> =
    yield GbpPerformanceMACsvApi.create(params);

  let executionArn: string;
  if (executionArnResponse.isSuccess) {
    executionArn = executionArnResponse.data.executionArn;
  } else {
    toast({
      type: 'error',
      title: 'CSVファイルのダウンロードに失敗しました',
      description: String(executionArnResponse.error.message),
      time: 10000,
    });

    yield put(AppActions.setLoading(false));

    return;
  }

  // 取得が完了しているか定期的に確認する
  let message: string;
  while (true) {
    const statusResponse: YieldReturn<typeof GbpPerformanceMACsvApi.status> = yield GbpPerformanceMACsvApi.status({
      executionArn,
    });
    if (statusResponse.isSuccess) {
      const data = statusResponse.data;
      if (data.status === 'RUNNING') {
        // 実行中なので、3秒後に再度チェックする
        yield delay(3000);
        continue;
      } else if (data.status === 'SUCCEEDED') {
        // レスポンスURLのファイルをダウンロードする
        window.location.assign(data.download_url);

        toast({ type: 'success', title: 'CSVファイルをダウンロードしました' });
        yield put(AppActions.setLoading(false));

        // 取得が完了したのでループを抜ける
        break;
      } else {
        // 失敗したのでエラーメッセージを表示する
        message = data.message;
      }
    } else {
      message = statusResponse.error.message;
    }
    toast({
      type: 'error',
      title: 'CSVファイルのダウンロードに失敗しました',
      description: String(message),
      time: 10000,
    });
    // 取得に失敗したのでループを抜ける
    break;
  }

  yield put(AppActions.setLoading(false));
}

function* locationChange(action: LocationChangeAction) {
  const { location, isFirstRendering } = action.payload;

  // PathがGBPパフォーマンスページ以外の場合は何もしない
  if (location.pathname !== Path.gbp.performanceMA) {
    return;
  }

  // 最初のレンダリング時(URLを入力しての遷移の場合)、データの取得を実行する
  if (isFirstRendering) {
    yield call(initializePageForFirstRendering);
  }
}
