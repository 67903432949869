import React from 'react';

import { Icon, TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';

import { StampApi } from 'ApiClient/StampApi';
import { Stamps } from 'models/Domain/Stamp';
import { COLOR } from 'style/color';

type Props = {
  isModalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  sendOfferStamp: (v: number) => void;
};

const fetchStamps = async () => {
  const response = await StampApi.get();
  if (response.isSuccess) {
    return new Stamps(response.data);
  }
  return new Stamps();
};

export const StampPortal: React.FC<Props> = ({ isModalOpen, setModalOpen, sendOfferStamp }) => {
  return (
    <TransitionablePortal
      open={isModalOpen}
      transition={{
        animation: 'fade',
        duration: 500,
      }}
    >
      <PortalWrapper onClick={() => setModalOpen(false)}>
        <PortalContent>{isModalOpen && <StampPortalContent sendOfferStamp={sendOfferStamp} />}</PortalContent>
      </PortalWrapper>
    </TransitionablePortal>
  );
};

// NOTE
// モーダルの大きさを決めているPortalContentの中身の描画は実際にモーダルが開かれてからでいいので、
// コンポーネントを分離している。
// スタンプの取得をこの中で実施することで、本当に必要になった時のみAPIが実行されるようにしている。
const StampPortalContent = ({ sendOfferStamp }: { sendOfferStamp: (v: number) => void }) => {
  const { data: stamps = new Stamps() } = useSWRImmutable('/stamps', fetchStamps);
  return (
    <>
      <Header>
        <Title>スタンプを選択</Title>
        <CloseIcon name='close' />
      </Header>
      <Body>
        <StampListWrapper>
          {stamps.list.map((stamp) => {
            return <StampPreview key={stamp.id} src={stamp.url} onClick={() => sendOfferStamp(stamp.id)} />;
          })}
        </StampListWrapper>
      </Body>
    </>
  );
};

const PortalWrapper = styled.div`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 200;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.35);
  }
`;

const PortalContent = styled.div`
  height: 100%;
  width: 100%;
  max-height: 800px;
  max-width: 600px;
  background-color: ${COLOR.WHITE};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  background-color: ${COLOR.LIGHT_GRAY};
`;

const Title = styled.div`
  font-weight: bold;
`;

const CloseIcon = styled(Icon)`
  &&& {
    font-size: 24px;
    line-height: 1;
    color: ${COLOR.GREEN};
    cursor: pointer;
  }
`;

const Body = styled.div`
  overflow-y: scroll;
  padding: 10px 10px 60px 10px;
  height: calc(100% - 60px);
`;

const StampListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StampPreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 6px;
  cursor: pointer;
`;
