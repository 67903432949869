import React from 'react';

import styled from 'styled-components';

type Props = {
  className?: string;
  width?: string;
  gray?: boolean;
};

const Logo: React.FC<Props> = ({ className, width, gray }) => {
  const svgPath = gray ? '/images/storecast_logo_gray.svg' : '/images/storecast_logo.svg';
  return <Img className={className} width={width} src={svgPath} />;
};

const Img = styled.img<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : '130px')};
`;

export default Logo;
