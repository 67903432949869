import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { createHashtagText, createUniqueArray } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { CommonContent } from './CommonContent';
import { FileContent } from './FileContent';

interface TaskRecord {
  id: number;
  name: string;
  due_date: Dayjs;
  content: CommonContent;
}

export class Task extends Record<TaskRecord>({
  id: 0,
  name: '',
  due_date: dayjs().add(1, 'week').hour(23).minute(59), // 1週間後の23:59に設定
  content: new CommonContent(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.content) {
      params.content = new CommonContent(params.content);
    }
    if (params.due_date) {
      params.due_date = dayjs.unix(params.due_date);
    }
    super(params);
  }

  get dueDateString() {
    return this.due_date && this.due_date.format('MM/DD');
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  get provisionalHashtag() {
    let tags = [];
    const name = createHashtagText(this.name);
    if (name) {
      tags.push(name);
    }
    tags = tags.concat(this.content.provisionalHashtag);
    return createUniqueArray(tags);
  }

  getLabel(key: 'name' | 'due_date') {
    switch (key) {
      case 'name':
        return 'タスク';
      case 'due_date':
        return '期限';
      default:
        return '';
    }
  }

  changeName(name: string) {
    return this.set('name', name);
  }

  changeText(text: string) {
    return this.update('content', (content) => content.changeText(text));
  }

  changeTags(tags: string[]) {
    return this.update('content', (content) => content.changeTags(tags));
  }

  addFiles(urls: string[]) {
    return this.update('content', (content) => content.addFiles(urls));
  }

  deleteFile(index: number) {
    return this.update('content', (content) => content.deleteFile(index));
  }

  changeFileCaption(index: number, caption: string) {
    return this.update('content', (content) => content.changeFileCaption(index, caption));
  }

  changeDueDate(date: Dayjs) {
    return this.set('due_date', date);
  }

  validate() {
    const errors: JSObject = {};

    if (!this.name || !this.name.trim()) {
      errors.name = ErrorType.REQUIRED_ERROR;
    }

    if (!this.due_date) {
      errors.due_date = ErrorType.REQUIRED_ERROR;
    } else if (this.due_date < dayjs()) {
      errors.due_date = ErrorType.PAST_TIME_ERROR;
    }

    return errors;
  }

  requestParams() {
    const { id, name } = this.toObject();
    const params = {
      id: id < 1 ? null : id,
      name,
      due_date: this.due_date.unix(),
      content: this.content.changeTags(this.provisionalHashtag).requestParams(),
    };
    return params;
  }
}

interface TaskListRecord {
  list: List<Task>;
}

export class Tasks extends Record<TaskListRecord>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data.map((p) => new Task(p)));
    super({ list });
  }

  get firstFileContentUrl() {
    const targetTask = this.list.find((task) => task.content.files.list.size > 0);
    if (!targetTask) {
      return null;
    }
    return targetTask.content.files.list.first<FileContent>().url;
  }

  get isValid() {
    const validates = this.validate();
    const listValidates: JSObject = validates.list;
    delete validates.list;

    if (Object.keys(validates).length !== 0) {
      return false;
    }

    for (const taskValidate of Object.values(listValidates)) {
      if (Object.keys(taskValidate).length !== 0) {
        return false;
      }
    }

    return true;
  }

  addTask() {
    return this.update('list', (list) => list.push(new Task()));
  }

  deleteTask(index: number) {
    return this.update('list', (list) => list.delete(index));
  }

  findTask(taskId: string | number | undefined) {
    if (!taskId) {
      return undefined;
    }
    return this.list.find((task) => task.id === Number(taskId));
  }

  changeTaskName(index: number, name: string) {
    return this.updateIn(['list', index], (targetTask) => targetTask.changeName(name));
  }

  changeTaskText(index: number, text: string) {
    return this.updateIn(['list', index], (targetTask) => targetTask.changeText(text));
  }

  changeTaskTags(index: number, tags: string[]) {
    return this.updateIn(['list', index], (targetTask) => targetTask.changeTags(tags));
  }

  addFileContents(index: number, urls: string[]) {
    return this.updateIn(['list', index], (targetTask) => targetTask.addFiles(urls));
  }

  deleteFileContents(index: number, fileIndex: number) {
    return this.updateIn(['list', index], (targetTask) => targetTask.deleteFiles(fileIndex));
  }

  changeFileContentsComment(index: number, fileIndex: number, caption: string) {
    return this.updateIn(['list', index], (targetTask) => targetTask.changeFileComment(fileIndex, caption));
  }

  validate() {
    const errors: JSObject = { list: {} };

    this.list.forEach((task, index) => {
      errors.list[index] = task.validate();
    });

    return errors;
  }

  requestParams() {
    return this.list.map((task) => task.requestParams()).toArray();
  }
}
