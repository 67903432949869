import React, { useCallback, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Set } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import {
  AggregateDateRangePicker,
  ComparisonDateRangeOptionValue,
  DateRangeOption,
  DateRangeOptionValue,
  getComparisonDateRange,
  getComparisonDateRangeOption,
  getDateRangeFromDateRangeOption,
  getDateRangeOption,
} from 'components/molecules/AggregateDateRangePicker';
import { AggregateUnitGroup } from 'components/molecules/AggregateUnitGroup';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { AggregateUnit, GbpInsightSearchCondition, MAX_DATE } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { COLOR } from 'style/color';

const maxDate = MAX_DATE;

const dateRangeOptions: { [key: string]: DateRangeOption[] } = {
  day: [
    { text: '過去7日間', value: '7 day' },
    { text: '過去30日間', value: '30 day' },
    { text: 'カスタム', value: 'カスタム' },
  ],
  week: [
    { text: '過去4週間', value: '4 week' },
    { text: '過去8週間', value: '8 week' },
    { text: 'カスタム', value: 'カスタム' },
  ],
  month: [
    { text: '3ヶ月', value: '3 month' },
    { text: '半年', value: '6 month' },
    { text: '1年', value: '12 month' },
    { text: 'カスタム', value: 'カスタム' },
  ],
};

/**
 * インサイトの検索欄
 * @param param0
 * @returns
 */
export const SearchArea: React.FC<{
  searchCondition: GbpInsightSearchCondition;
  committedSearchCondition: GbpInsightSearchCondition;
  storeLists: StoreLists;
  stores: Stores;
  isShowStoreListSelect?: boolean;
  onChangeSearchCondition: (searchCondition: GbpInsightSearchCondition) => void;
  onCommitSearchCondition: () => void;
}> = ({
  searchCondition,
  committedSearchCondition,
  storeLists,
  stores,
  isShowStoreListSelect = false,
  onChangeSearchCondition,
  onCommitSearchCondition,
}) => {
  const [dateRangeOption, setDateRangeOption] = useState<DateRangeOptionValue>(() =>
    getDateRangeOption(
      dateRangeOptions[searchCondition.aggregateUnit],
      searchCondition.startDate,
      searchCondition.endDate,
      maxDate,
    ),
  );

  // 比較期間のタイプ
  const [comparisonDateRangeOption, setComparisonDateRangeOption] = useState<ComparisonDateRangeOptionValue>(() =>
    searchCondition.isEnabledComparison
      ? getComparisonDateRangeOption(
          dateRangeOption,
          searchCondition.startDate,
          searchCondition.endDate,
          searchCondition.comparisonStartDate,
          searchCondition.comparisonEndDate,
        )
      : '前の期間',
  );
  const [open, setOpen] = useState(true);

  const { aggregateUnit } = searchCondition;

  const aggregateDurationOptions = useMemo(() => dateRangeOptions[aggregateUnit], [aggregateUnit]);

  const onChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        const newDateRangeOption = 'カスタム';
        setDateRangeOption(newDateRangeOption);

        const sd = dayjs(startDate);
        const ed = dayjs(endDate);

        let newSearchCondition = searchCondition.changeStartDate(sd).changeEndDate(ed);

        // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
        if (comparisonDateRangeOption !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, newDateRangeOption);
          newSearchCondition = newSearchCondition.merge({ comparisonStartDate: csd, comparisonEndDate: ced });
        }

        onChangeSearchCondition(newSearchCondition);
      }
    },
    [searchCondition, comparisonDateRangeOption, onChangeSearchCondition],
  );

  /**
   * 期間のタイプが変更された際のハンドラ
   * @param DateRangeOptionValue 集計期間のオプション
   * @param AggregateUnit 集計単位
   */
  const onChangeDateRangeOption = useCallback(
    (value: DateRangeOptionValue) => {
      setDateRangeOption(value);
      // 集計期間のオプションから開始日〜終了日を取得
      const [sd, ed] = getDateRangeFromDateRangeOption(
        value,
        searchCondition.startDate,
        searchCondition.endDate,
        maxDate,
      );
      // 設定を反映
      let newSearchCondition = searchCondition.changeStartDate(sd).changeEndDate(ed);

      // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
      if (comparisonDateRangeOption !== 'カスタム') {
        const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, value);
        newSearchCondition = newSearchCondition.merge({ comparisonStartDate: csd, comparisonEndDate: ced });
      }

      onChangeSearchCondition(newSearchCondition);
    },
    [searchCondition, comparisonDateRangeOption, onChangeSearchCondition],
  );

  /**
   * 比較期間が変更された際のハンドラ
   * @param comparisonStartDate 比較期間の開始日
   * @param comparisonEndDate 比較期間の終了日
   */
  const onChangeComparisonDateRange = useCallback(
    (comparisonStartDate: Date | null, comparisonEndDate: Date | null) => {
      setComparisonDateRangeOption('カスタム');
      const csd = dayjs(comparisonStartDate);
      const ced = dayjs(comparisonEndDate);
      const newSearchCondition = searchCondition.merge({ comparisonStartDate: csd, comparisonEndDate: ced });
      onChangeSearchCondition(newSearchCondition);
    },
    [onChangeSearchCondition, searchCondition],
  );

  /**
   * 比較期間のタイプが変更された際のハンドラ
   * @param value 比較期間のタイプ
   */
  const onChangeComparisonDateRangeOption = useCallback(
    (value: ComparisonDateRangeOptionValue) => {
      setComparisonDateRangeOption(value);

      if (value !== 'カスタム') {
        const [csd, ced] = getComparisonDateRange(
          searchCondition.startDate,
          searchCondition.endDate,
          value,
          dateRangeOption,
        );

        const newSearchCondition = searchCondition.merge({ comparisonStartDate: csd, comparisonEndDate: ced });
        onChangeSearchCondition(newSearchCondition);
      }
    },
    [dateRangeOption, onChangeSearchCondition, searchCondition],
  );

  const onChangeAggregateUnit = useCallback(
    (aggregateUnit: AggregateUnit) => {
      // 集計期間が変更されたら、期間はそのまま「カスタム」に変更する
      if (searchCondition.aggregateUnit !== aggregateUnit) {
        setDateRangeOption('カスタム');
        const newSearchCondition = searchCondition.changeAggregateUnit(aggregateUnit);
        onChangeSearchCondition(newSearchCondition);
      }
    },
    [onChangeSearchCondition, searchCondition],
  );

  /**
   * グループ、店舗のフィルターを変更する
   * @param group グループ
   * @param storeIds 店舗のID
   * @param isAllStores すべての店舗が選択されているか
   * @param showClosedStores 閉店店舗を表示するか
   */
  const handleChangeGroupStore = useCallback(
    (
      group: GbpInsightSearchCondition['group'],
      storeIds: Set<number>,
      isAllStores: boolean,
      showClosedStores: boolean,
    ) => {
      const newSearchCondition = searchCondition.merge({
        group: group,
        store_ids: storeIds,
        is_all_store_ids: isAllStores,
        show_closed_store: showClosedStores,
      });
      onChangeSearchCondition(newSearchCondition);
    },
    [searchCondition, onChangeSearchCondition],
  );

  /** 集計設定を閉じた時に表示するラベルの文言 */
  const committedAggregateTargetLabel = useMemo(() => {
    if (committedSearchCondition.is_all_store_ids) {
      const selectedGroupId =
        typeof committedSearchCondition.group === 'number' ? committedSearchCondition.group : null;
      const storeListOptions = [{ text: '全ての店舗', value: 0 }, ...storeLists.options];
      return storeListOptions.find((option) => option.value === selectedGroupId)?.text || '全ての店舗';
    }

    const selectedStoreNameLabel = committedSearchCondition.store_ids
      .toArray()
      .sort()
      .slice(0, 3) // 3店舗分の店舗名のみ表示する
      .map((storeId) => stores.findStore(storeId)?.fullName || '')
      .join('、');

    return committedSearchCondition.store_ids.size > 3
      ? `${selectedStoreNameLabel} ...他${committedSearchCondition.store_ids.size - 3}店舗`
      : selectedStoreNameLabel;
  }, [committedSearchCondition, storeLists, stores]);

  /** 集計設定を閉じた時に表示する集計単位の文言 */
  const committedAggregateUnitLabel = useMemo(() => {
    switch (committedSearchCondition.aggregateUnit) {
      case 'day':
        return '日';
      case 'week':
        return '週';
      case 'month':
        return '月';
    }
  }, [committedSearchCondition.aggregateUnit]);

  const onChangeIsEnabledComparison = useCallback(
    (value: boolean) => {
      let newSearchCondition = searchCondition.set('isEnabledComparison', value);

      if (comparisonDateRangeOption !== 'カスタム') {
        const [csd, ced] = getComparisonDateRange(
          searchCondition.startDate,
          searchCondition.endDate,
          comparisonDateRangeOption,
          dateRangeOption,
        );
        newSearchCondition = newSearchCondition.merge({ comparisonStartDate: csd, comparisonEndDate: ced });
      }

      onChangeSearchCondition(newSearchCondition);
    },
    [searchCondition, comparisonDateRangeOption, onChangeSearchCondition, dateRangeOption],
  );

  return (
    <div>
      <SearchWrapper>
        <OpenText onClick={() => setOpen(!open)}>{open ? '閉じる' : '設定を変更する'}</OpenText>
        {open ? (
          <FlexWrapper>
            {isShowStoreListSelect && (
              <GroupStoreSelect
                group={searchCondition.group}
                storeIds={searchCondition.store_ids}
                showClosedStores={searchCondition.show_closed_store}
                showGbpConnectedStoresOnly={true}
                onChange={handleChangeGroupStore}
              />
            )}
            <FlexContent>
              <Label>集計単位</Label>
              <AggregateUnitGroup value={searchCondition.aggregateUnit} onChange={onChangeAggregateUnit} />
            </FlexContent>
            <FlexContent>
              <FlexLabel>
                集計期間
                <ContextHelp content='直近のインサイトは、通常2〜3日後に取得されます。' />
              </FlexLabel>
              <PeriodContainer>
                <AggregateDateRangePicker
                  startDate={searchCondition.startDate}
                  endDate={searchCondition.endDate}
                  comparisonStartDate={searchCondition.comparisonStartDate}
                  comparisonEndDate={searchCondition.comparisonEndDate}
                  maxDate={maxDate}
                  isEnabledComparison={searchCondition.isEnabledComparison}
                  dateRangeOption={dateRangeOption}
                  dateRangeOptions={aggregateDurationOptions}
                  comparisonDateRangeOption={comparisonDateRangeOption}
                  onChangeDateRange={onChangeDateRange}
                  onChangeComparisonDateRange={onChangeComparisonDateRange}
                  onChangeIsEnabledComparison={onChangeIsEnabledComparison}
                  onChangeDateRangeOption={onChangeDateRangeOption}
                  onChangeComparisonDateRangeOption={onChangeComparisonDateRangeOption}
                />
              </PeriodContainer>
            </FlexContent>
            <ActionWrapper>
              <StyledButton disabled={!searchCondition.isValid()} onClick={onCommitSearchCondition}>
                適用
              </StyledButton>
            </ActionWrapper>
          </FlexWrapper>
        ) : (
          <div>
            <SearchStatusLabel>集計対象: {committedAggregateTargetLabel}</SearchStatusLabel>
            <SearchStatusLabel>集計単位: {committedAggregateUnitLabel}</SearchStatusLabel>
            <SearchStatusLabel>集計期間: {committedSearchCondition.targetPeriod}</SearchStatusLabel>
            <SearchStatusLabel>
              比較期間:{' '}
              {committedSearchCondition.isEnabledComparison ? committedSearchCondition.comparisonPeriod : 'なし'}
            </SearchStatusLabel>
          </div>
        )}
      </SearchWrapper>
    </div>
  );
};

const SearchWrapper = styled(Card)`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  margin-right: 24px;
  box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.05);
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FlexContent = styled.div`
  margin-right: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  margin-top: 7px;
`;

const FlexLabel = styled(Label)`
  display: flex;
  align-items: center;
`;

const SearchStatusLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 4px;
  margin-top: 4px;
  /* 「集計対象」が長い文字列の場合「設定を変更する」ボタンと被るため、最初の余白を大きめに取る */
  &:first-of-type {
    margin-top: 24px;
  }
`;

const OpenText = styled.div`
  font-size: 14px;
  color: ${COLOR.GREEN};
  min-width: 40px;
  cursor: pointer;

  position: absolute;
  right: 18px;
  top: 18px;
`;

const PeriodContainer = styled.div``;

const ActionWrapper = styled(Card)`
  margin-left: auto;
  margin-top: auto;
  padding-right: 0;
  min-width: 176px;
`;

const StyledButton = styled(Button).attrs(() => ({ priority: 'high' }))`
  &&& {
    height: 32px;
    padding: 0;
    width: 176px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;
