import React, { useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { TaskDetailTemplate } from 'components/pageComponents/TaskDetail';
import { getPageTitle } from 'helpers/utils';
import { TaskDetailActions } from 'modules/taskDetail/actions';
import { TaskDetailSelectors } from 'modules/taskDetail/selectors';

type TaskDetailProps = RouteComponentProps<{ taskId: string }>;

export const TaskDetail: React.FC<TaskDetailProps> = ({
  match: {
    params: { taskId },
  },
}) => {
  const dispatch = useDispatch();
  const { clearState, fetchTask } = useMemo(() => bindActionCreators(TaskDetailActions, dispatch), [dispatch]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [clearState]);

  useEffect(() => {
    // タスク情報を取得する
    fetchTask(Number(taskId));
  }, [fetchTask, taskId]);

  const isLoading = useSelector(TaskDetailSelectors.getIsLoading);
  const task = useSelector(TaskDetailSelectors.getTask);
  const author = useSelector(TaskDetailSelectors.getAuthor);
  const stores = useSelector((state) => state.store.stores);
  const currentUser = useSelector((state) => state.app.currentUser);

  return (
    <>
      <Helmet title={getPageTitle('依頼詳細')} />
      <TaskDetailTemplate isLoading={isLoading} task={task} author={author} stores={stores} currentUser={currentUser} />
    </>
  );
};
