import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Header, Message, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { PullDown } from 'components/atoms/PullDown';
import Gmb from 'models/Domain/Gmb';
import GmbGroup from 'models/Domain/GmbGroup';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { GmbActions } from 'modules/gmb/actions';

// 契約店舗数を超えて連携できない時に表示するメッセージ
const REACHED_CONTRACTED_STORE_LIMIT_MESSAGE =
  '契約店舗数の上限に達しているため、新たに店舗をGoogleビジネスプロフィールと連携できません。上限の変更につきましては、メニューの「サポート」よりお問い合わせください。';

export const GmbConnectModal: React.FC = () => {
  const dispatch = useDispatch();
  const { gmbState, appState, storeState } = useSelector((state) => ({
    gmbState: state.gmb,
    appState: state.app,
    storeState: state.store,
  }));

  useEffect(() => {
    dispatch(GmbActions.getGmbList());
  }, [dispatch]);

  const {
    gmbList,
    targetStore,
    currentAccountId,
    groupList,
    currentGroupId,
    locationList,
    notImportedLocationList,
    currentLocationId,
  } = gmbState;

  const currentUser = appState.currentUser;
  const organization = currentUser.organization;
  if (organization === null) {
    return <></>;
  }

  const gmbConnectedStoresCount = storeState.stores.filterByIsConnectedGmb().list.size;

  const accountOptions = gmbList.map((gmb: Gmb) => ({ text: `${gmb.name}(${gmb.email})`, value: gmb.id })).toArray();

  const groupOptions = groupList
    .map((group: GmbGroup) => ({ text: group.accountName, value: group.name.split('/')[1] }))
    .toArray();

  const locationOptions = notImportedLocationList
    .map((location: GmbLocation) => ({ text: location.locationName, value: location.name }))
    .toArray();

  const showWarning = !locationList.isEmpty() && !locationOptions.length;

  // 連携ボタンの活性/非活性
  // 連携したいロケーションが選択されている かつ GBP連携店舗数が契約店舗数上限を超えていない
  const reachedContractedStoreLimit = organization.contractedStores <= gmbConnectedStoresCount;
  const canConnectGmb = !!currentLocationId && !reachedContractedStoreLimit;

  return (
    <Modal open={targetStore !== null}>
      <Modal.Header>Googleビジネスプロフィール連携設定</Modal.Header>
      <Modal.Content>
        {/* 契約店舗数上限に達している場合メッセージを表示 */}
        {reachedContractedStoreLimit && <Message error content={REACHED_CONTRACTED_STORE_LIMIT_MESSAGE} />}

        <Header>{targetStore?.fullName}</Header>
        <Form warning={showWarning}>
          <Form.Field>
            <label>Googleアカウント</label>
            <CustomPullDown
              value={currentAccountId}
              options={accountOptions}
              placeholder={'Googleアカウントを選択'}
              multiple={false}
              onChange={(value: string) => dispatch(GmbActions.selectGmbAccount(value))}
              disabled={gmbList.isEmpty()}
            />
          </Form.Field>
          <Form.Field>
            <label>Googleビジネスプロフィール グループ</label>
            <CustomPullDown
              value={currentGroupId}
              options={groupOptions}
              placeholder={'Googleビジネスプロフィール グループ選択'}
              multiple={false}
              onChange={(value: string) => dispatch(GmbActions.selectGmbGroup(value))}
              disabled={groupList.isEmpty()}
            />
          </Form.Field>
          <Form.Field>
            <label>ロケーション</label>
            <CustomPullDown
              value={currentLocationId}
              options={locationOptions}
              placeholder={'ロケーション選択'}
              multiple={false}
              onChange={(value: string) => dispatch(GmbActions.selectGmbLocation(value))}
              disabled={!locationOptions.length}
            />
            <Message warning content='新規に取り込みができる店舗がありません。' />
          </Form.Field>
        </Form>
      </Modal.Content>
      <ModalActions>
        <CancelButton onClick={() => dispatch(GmbActions.clearModalData())}>キャンセル</CancelButton>
        <Button
          priority={'high'}
          disabled={!canConnectGmb}
          onClick={() => {
            dispatch(GmbActions.connectGmb());
            dispatch(GmbActions.clearModalData());
          }}
        >
          連携する
        </Button>
      </ModalActions>
    </Modal>
  );
};

const CustomPullDown = styled(PullDown)`
  margin-top: 16px;
`;

const ModalActions = styled(Modal.Actions)`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 20px;
    }
  }
`;

const CancelButton = styled(Button)`
  margin-right: 12px;
`;
