import React, { useMemo, useState } from 'react';

import { Set as ImmutableSet, List, Map } from 'immutable';
import { Dropdown, Icon as SemanticUIIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { ExternalLink } from 'components/atoms/ExternalLink';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import Menu from 'components/atoms/Menu';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { Paging } from 'components/molecules/Paging';
import { RatingStar } from 'components/molecules/Rating';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { StoreMenu } from 'components/organisms/StoreMenu';
import { CompetitorHelp as Help } from 'helpers/ContextHelp';
import { Competitor, Competitors } from 'models/Domain/Competitor/Competitor';
import { CompetitorSearchCondition } from 'models/Domain/Competitor/CompetitorSearchCondition';
import { AggregateType } from 'models/Domain/GoogleLocation';
import { Store, Stores } from 'models/Domain/Store';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Frequency = 'High' | 'Medium' | 'Low' | 'None';

const getFrequency = (value: number | null | undefined): Frequency => {
  return !value ? 'None' : value < 5 ? 'Low' : value < 10 ? 'Medium' : 'High';
};

const getCellColor = (value: number | null | undefined) => {
  switch (getFrequency(value)) {
    case 'High':
      return '#f4cccc';
    case 'Medium':
      return '#fce5cd';
    case 'Low':
      return '#d9ead3';
    case 'None':
      return 'none';
  }
};

export type CompetitorTableProps = {
  className?: string;
  isLoading: boolean;
  competitors: Competitors;
  stores: Stores;
  searchCondition: CompetitorSearchCondition;
  onChangePage: (page: number) => void;
  onSelectTag: (tag: string) => void;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
};

export const CompetitorTable = React.memo<CompetitorTableProps>(
  ({ className, isLoading, competitors, stores, searchCondition, onChangePage, onSelectTag, onDelete, onEdit }) => {
    const [aggregateType, setAggregateType] = useState<AggregateType>('recent');

    const {
      items,
      pagination: {
        current_page: currentPage,
        total_count: totalCount,
        limit,
        range: [itemStart, itemEnd],
      },
    } = competitors;

    return (
      <Wrapper className={className}>
        <TextWrapper>
          {competitors.googleLocationLatestUpdateAt && (
            <LatestUpdateAt>
              最終データ取得日時：{competitors.googleLocationLatestUpdateAt.format('YYYY/MM/DD HH:mm:ss')}
            </LatestUpdateAt>
          )}
          <FlexContainer>
            <CountText>
              {`${totalCount.toLocaleString()}件の競合店舗`}
              {totalCount > 0 && (
                <>
                  （{itemStart.toLocaleString()}〜{itemEnd.toLocaleString()}件目を表示中）
                </>
              )}
            </CountText>
            <MenuWrapper>
              <MenuLabel>
                集計期間
                <ContextHelp content={Help.aggregateType} />
              </MenuLabel>
              <Menu>
                <Menu.Item
                  name={'recent'}
                  active={aggregateType === 'recent'}
                  onClick={() => setAggregateType('recent')}
                >
                  直近
                </Menu.Item>
                <Menu.Item name={'total'} active={aggregateType === 'total'} onClick={() => setAggregateType('total')}>
                  全期間
                </Menu.Item>
              </Menu>
            </MenuWrapper>
          </FlexContainer>
        </TextWrapper>
        <TableWrapper>
          <InnerTable
            items={items}
            stores={stores}
            aggregateType={aggregateType}
            searchCondition={searchCondition}
            onSelectTag={onSelectTag}
            onDelete={onDelete}
            onEdit={onEdit}
          />
          {/* キーワードが0件かつ、読み込み中でない場合のメッセージ */}
          {items.size === 0 && !isLoading && (
            <Message>
              <MessageTitle>条件に一致する競合店舗が見つかりませんでした</MessageTitle>
              <MessageDetail>条件を変更して検索してください</MessageDetail>
            </Message>
          )}
          {/* キーワードが0件かつ、読み込み中の場合にLoader表示用のスペースを追加 */}
          {items.size === 0 && isLoading && <LoaderContainer />}
          {isLoading && (
            <>
              <LoadingWrapper>
                <Loader active={isLoading} size='big' inline={true} />
              </LoadingWrapper>
            </>
          )}
        </TableWrapper>
        <Paging
          currentPage={currentPage}
          viewContentSize={limit}
          totalContentSize={totalCount}
          onChangeNo={onChangePage}
        />
      </Wrapper>
    );
  },
);

interface InnerTableProps {
  items: List<Competitor>;
  stores: Stores;
  aggregateType: AggregateType;
  searchCondition: CompetitorSearchCondition;
  onSelectTag: (tag: string) => void;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

const InnerTable = React.memo<InnerTableProps>(
  ({ items, stores, aggregateType, searchCondition, onSelectTag, onDelete, onEdit }) => {
    const storeMap: Map<number, Store> = useMemo(() => {
      const storeIds = items.map((item) => item.storeId).toSet();
      return storeIds.reduce((reduction, storeId) => {
        const store = stores.findStore(storeId);
        if (store) {
          return reduction.set(storeId, store);
        }
        return reduction;
      }, Map());
    }, [items, stores]);

    return (
      <StyledTable unstackable={true}>
        <StyledTableHeader>
          <TableHeaderRow>
            <StyledTableHeaderCell>自社店舗名</StyledTableHeaderCell>
            <StyledTableHeaderCell>競合店舗名</StyledTableHeaderCell>
            <StyledTableHeaderCell>タグ</StyledTableHeaderCell>
            <ValueTableHeaderCell>
              写真追加数
              <ContextHelp content={aggregateType === 'recent' ? Help.hasMoreAndFrequency : Help.hasMore} />
              {aggregateType === 'recent' && <AggregateUnit>今月</AggregateUnit>}
            </ValueTableHeaderCell>
            {aggregateType === 'recent' && (
              <ValueTableHeaderCell>
                写真追加数
                <ContextHelp content={aggregateType === 'recent' ? Help.hasMoreAndFrequency : Help.hasMore} />
                <AggregateUnit>先月</AggregateUnit>
              </ValueTableHeaderCell>
            )}
            <ValueTableHeaderCell>
              投稿数
              <ContextHelp content={aggregateType === 'recent' ? Help.hasMoreAndFrequency : Help.hasMore} />
              {aggregateType === 'recent' && <AggregateUnit>直近1ヶ月</AggregateUnit>}
            </ValueTableHeaderCell>
            <ValueTableHeaderCell>
              クチコミ数
              <ContextHelp content={aggregateType === 'recent' ? Help.hasMoreAndFrequency : Help.hasMore} />
              {aggregateType === 'recent' && <AggregateUnit>直近1ヶ月</AggregateUnit>}
            </ValueTableHeaderCell>
            <ValueTableHeaderCell>
              クチコミ返信数
              <ContextHelp content={aggregateType === 'recent' ? Help.hasMoreAndFrequency : Help.hasMore} />
              {aggregateType === 'recent' && <AggregateUnit>直近1ヶ月</AggregateUnit>}
            </ValueTableHeaderCell>
            <ValueTableHeaderCell>
              平均評価
              {aggregateType === 'recent' && <AggregateUnit>直近1ヶ月</AggregateUnit>}
            </ValueTableHeaderCell>
            <MenuHeaderCell></MenuHeaderCell>
          </TableHeaderRow>
        </StyledTableHeader>
        <TableBody>
          {items.map((item, index) => {
            const { googleLocationSummary: summary } = item;
            const searchParams = searchCondition
              .mergeIn(['filter'], {
                group: 'all',
                storeIds: ImmutableSet([item.storeId]),
                isAllStoreIds: false,
              })
              .toURLSearchParams();
            const storeName = storeMap.get(item.storeId)?.fullName;
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <StoreMenu
                    trigger={<StoreName>{storeName}</StoreName>}
                    storeId={item.storeId}
                    items={[]}
                    additionalItems={{
                      start: [
                        {
                          label: 'この店舗で絞り込む',
                          link: `${Path.competitors.index}?${searchParams}`,
                          target: '_blank',
                        },
                      ],
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Link
                    to={{
                      pathname: `${Path.competitors.detail.replace(':id', String(item.id))}`,
                      state: { source: Path.competitors.index, name: item.name },
                    }}
                  >
                    <StoreName>{item.name}</StoreName>
                  </Link>
                  <StyledExternalLink href={item.url} />
                </StyledTableCell>
                <StyledTableCell>
                  {item.tags.map((tag) => (
                    <Tag key={tag} onClick={() => onSelectTag(tag)}>
                      <SemanticUIIcon name='tag' color='grey' />
                      {tag}
                    </Tag>
                  ))}
                </StyledTableCell>
                {aggregateType === 'recent' && (
                  <>
                    <ValueCell color={getCellColor(summary?.ownerImageCount.currentMonth?.value)}>
                      <Value hasMore={summary?.ownerImageCount.currentMonth?.hasMore}>
                        {summary?.ownerImageCount.currentMonth?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell color={getCellColor(summary?.ownerImageCount.lastMonth?.value)}>
                      <Value hasMore={summary?.ownerImageCount.lastMonth?.hasMore}>
                        {summary?.ownerImageCount.lastMonth?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell color={getCellColor(summary?.localPostCount.recent?.value)}>
                      <Value hasMore={summary?.localPostCount.recent?.hasMore}>
                        {summary?.localPostCount.recent?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell color={getCellColor(summary?.reviewCount.recent?.value)}>
                      <Value hasMore={summary?.reviewCount.recent?.hasMore}>
                        {summary?.reviewCount.recent?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell color={getCellColor(summary?.reviewReplyCount.recent?.value)}>
                      <Value
                        hasMore={
                          summary?.reviewReplyCount.recent?.value != null && summary?.reviewCount.recent?.hasMore
                        }
                      >
                        {summary?.reviewReplyCount.recent?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell>
                      <Value>{summary?.reviewAverageScore.recent?.value.toFixed(1) ?? <EmptyValue />}</Value>
                      {summary?.reviewAverageScore.recent != null && (
                        <StyledRatingStar rating={summary.reviewAverageScore.recent.value} maxRating={5} />
                      )}
                    </ValueCell>
                  </>
                )}
                {aggregateType === 'total' && (
                  <>
                    <ValueCell>
                      <Value hasMore={summary?.ownerImageCount.total?.hasMore}>
                        {summary?.ownerImageCount.total?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell>
                      <Value hasMore={summary?.localPostCount.total?.hasMore}>
                        {summary?.localPostCount.total?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell>
                      <Value hasMore={summary?.reviewCount.total?.hasMore}>
                        {summary?.reviewCount.total?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell>
                      <Value
                        hasMore={summary?.reviewReplyCount.total?.value != null && summary?.reviewCount.total?.hasMore}
                      >
                        {summary?.reviewReplyCount.total?.value.toLocaleString() ?? <EmptyValue />}
                      </Value>
                    </ValueCell>
                    <ValueCell>
                      <Value>{summary?.reviewAverageScore.total?.value.toFixed(1) ?? <EmptyValue />}</Value>
                      {summary?.reviewAverageScore.total != null && (
                        <StyledRatingStar rating={summary.reviewAverageScore.total.value} maxRating={5} />
                      )}
                    </ValueCell>
                  </>
                )}
                <MenuCell>
                  <Dropdown trigger={<SettingsIcon />} icon={null}>
                    <Dropdown.Menu direction={'left'}>
                      <ActionMenuItem onClick={() => onEdit(item.id)}>
                        <ActionItemLabel>タグを編集</ActionItemLabel>
                      </ActionMenuItem>
                      <ActionMenuItem onClick={() => onDelete(item.id)}>
                        <ActionItemLabel>競合店舗を削除</ActionItemLabel>
                      </ActionMenuItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </MenuCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    );
  },
);

const Wrapper = styled.div``;

const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
  border: none;
`;

const StyledTableHeader = styled(TableHeader)`
  position: sticky;
  top: 70px;
  z-index: 10;
`;

const TextWrapper = styled.div``;

const CountText = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 14px;
  font-weight: bold;
`;

const Tag = styled.div`
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  i {
    text-decoration: none;
  }
`;

// メニュー
export const MenuHeaderCell = styled(TableHeaderCell)`
  width: 32px;
`;

export const MenuCell = styled(TableCell)`
  width: 32px;
`;

export const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

export const ActionItemLabel = styled.div<{ disabled?: boolean }>`
  padding: 4px 8px;
  font-size: 14px;
  color: ${({ disabled = false }) => (disabled ? COLOR.GRAY : COLOR.BLACK)};
`;

// 設定アイコン
export const SettingsIcon = styled(Icon).attrs({
  type: 'settings',
})`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  margin-bottom: -4px;
`;

const StyledTableRow = styled(TableRow)`
  ${SettingsIcon} {
    display: none;
  }

  &:hover {
    ${SettingsIcon} {
      display: inline-block;
    }
  }
`;

const TableWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoaderContainer = styled.div`
  height: 200px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 200px;
`;
const MessageTitle = styled.div`
  font-weight: bold;
  color: ${COLOR.BLACK};
`;
const MessageDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AggregateUnit = styled.span`
  display: block;
  font-size: 12px;
  color: ${COLOR.DARK_GRAY};
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    text-align: left;
    padding: 8px 16px;
  }
`;

const ValueTableHeaderCell = styled(StyledTableHeaderCell)`
  &&& {
    text-align: right;
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    padding: 8px 16px;
  }
`;

const ValueCell = styled(StyledTableCell)<{ color?: string }>`
  &&& {
    text-align: right;
    font-family: monospace !important;
    background: ${({ color }) => color || 'none'};
  }
`;

const Value = styled.span<{ hasMore?: boolean }>`
  display: inline-block;
  white-space: pre-wrap;
  &:after {
    margin-left: 2px;
    content: '+';
    visibility: ${({ hasMore = false }) => (hasMore ? 'visible' : 'hidden')};
  }
`;

const EmptyValue = styled.span`
  &:before {
    content: 'ー';
    color: ${COLOR.GRAY};
  }
`;

const StoreName = styled.span`
  font-weight: bold;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-left: 4px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const LatestUpdateAt = styled.div`
  width: 100%;
  text-align: right;
  color: ${COLOR.DARK_GRAY};
  margin-bottom: 8px;
`;

const MenuLabel = styled.div``;

const MenuWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;

const StyledRatingStar = styled(RatingStar)`
  margin-left: 4px;
`;
