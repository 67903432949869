import React, { useCallback, useEffect, useState } from 'react';

import { List as ImmutableList, Set } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { InstagramHelp as Help } from 'helpers/ContextHelp';
import {
  ActionUrlType,
  InstagramPostTarget,
  InstagramPostTargetKeywordRule,
  PERMALINK_ACTION_URL,
} from 'models/Domain/instagram';
import { COLOR } from 'style/color';
import { Group, SelectOption } from 'types/Common';

import { InstagramPostTargetKeywordRuleEdit } from './InstagramPostTargetKeywordRuleEdit';

export const buttonTypeOptions: SelectOption<ActionUrlType>[] = [
  {
    text: 'なし',
    value: 'none',
  },
  {
    text: '詳細（Instagramへのリンク）',
    value: 'instagram',
  },
  {
    text: '詳細（任意のURL）',
    value: 'url',
  },
];

export const targetOptions: SelectOption<'all' | 'keyword'>[] = [
  { text: '全ての投稿', value: 'all' },
  { text: '条件に一致する投稿', value: 'keyword' },
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
  target: InstagramPostTarget | null;
  onChange: (target: InstagramPostTarget) => void;
};

type TargetType = 'all' | 'keyword';

type GroupStoreSelectionConfig = {
  group: Group;
  storeIds: Set<number>;
  isAllStores: boolean;
  showClosedStores: boolean;
  showGroupSelectOnly: boolean;
};

export const InstagramPostTargetModal = React.memo<Props>(({ isOpen, onClose, target, onChange }) => {
  const [targetForEdit, setTargetForEdit] = useState<InstagramPostTarget>(new InstagramPostTarget());

  const [targetOptionValue, setTargetOptionValue] = useState<TargetType>('all');

  /* storeIdsとgroupIdsの値によってグループ or 店舗個別が切り替わる
   ** 1. storeIds === null かつ groupIds === null：グループ選択で全ての店舗
   ** 2. storeIds === null かつ groupIds !== null：グループ選択のみ
   ** 3. storeIds !== null かつ groupIds === null：個別選択で全ての店舗
   ** storeIdsとgroupIdsが存在する場合は無し。個別選択でグループを選択する場合は更新時に全ての店舗を選択するようにする
   */
  const initialGroup = target?.groupIds === null ? 'all' : target?.groupIds?.get(0) ?? null;

  const [groupStoreSelect, setGroupStoreSelect] = useState<GroupStoreSelectionConfig>({
    group: initialGroup,
    storeIds: Set(target?.storeIds ?? []),
    isAllStores: false,
    showClosedStores: false,
    showGroupSelectOnly: target === null || target.storeIds === null,
  });

  const showKeywordRule = targetOptionValue === 'keyword';

  const handleOnChangeTargetOption = useCallback((value: TargetType) => {
    setTargetForEdit((target) => target.set('keywordRule', new InstagramPostTargetKeywordRule()));
    setTargetOptionValue(value);
  }, []);

  const handleOnChangeButtonType = useCallback((value: ActionUrlType) => {
    setTargetForEdit((target) => target.setActionType(value));
  }, []);

  const handleOnChangeActionUrl = useCallback((value: InstagramPostTarget['actionUrl']) => {
    setShowUrlError(false);
    setTargetForEdit((target) => target.setActionUrl(value));
  }, []);

  const handleOnChangeKeywordRule = useCallback((value: InstagramPostTarget['keywordRule']) => {
    setTargetForEdit((target) => target.set('keywordRule', value));
  }, []);

  const handleOnSave = useCallback(() => {
    onChange(targetForEdit);
    onClose();
  }, [onChange, onClose, targetForEdit]);

  // もとのtargetが変更されたら、編集用targetを更新する
  useEffect(() => {
    setTargetForEdit(target ? target : new InstagramPostTarget());
    setTargetOptionValue(
      (target?.matchWords == null && target?.excludeWords == null) ||
        (target.matchWords.isEmpty() && target.excludeWords.isEmpty())
        ? 'all'
        : 'keyword',
    );
  }, [target]);

  const handleOnChangeSelectedStore = useCallback(
    (
      group: Group,
      storeIds: Set<number>,
      isAllStores: boolean,
      showClosedStores: boolean,
      showGroupSelectOnly: boolean,
    ) => {
      // target.groupIdsはImmutalbeListなので文字列のallの場合はnullになり全店舗選択と同義になる
      const groupForEdit = !showGroupSelectOnly ? null : ImmutableList(typeof group === 'number' ? [group] : []);

      const storeIdsForEdit = showGroupSelectOnly ? null : ImmutableList(storeIds);

      setTargetForEdit((target) => target.set('storeIds', storeIdsForEdit).set('groupIds', groupForEdit));
      setGroupStoreSelect({ group, storeIds, isAllStores, showClosedStores, showGroupSelectOnly });
    },
    [],
  );
  const [showUrlError, setShowUrlError] = useState<boolean>(false);

  const handleOnBlurActionUrl = useCallback(() => {
    setShowUrlError(true);
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title>投稿対象</Title>
          <ContentWrapper>
            <Content>
              <GroupStoreSelect
                group={groupStoreSelect.group}
                storeIds={groupStoreSelect.storeIds}
                showClosedStores={false}
                showClosedStoreCheckbox={false}
                showGbpConnectedStoresOnly={true}
                showMyStoreOnly={true}
                showGroupSelectOnly={groupStoreSelect.showGroupSelectOnly}
                canSwitchSelectionMode={true}
                sizeVariant={'large'}
                onChange={handleOnChangeSelectedStore}
              ></GroupStoreSelect>
            </Content>
            <Content>
              <Label>条件</Label>
              <StyledPullDown
                value={targetOptionValue}
                options={targetOptions}
                onChange={handleOnChangeTargetOption}
                clearable={false}
              />
              {showKeywordRule && (
                <InstagramPostTargetKeywordRuleEdit
                  keywordRule={targetForEdit.keywordRule}
                  onChange={handleOnChangeKeywordRule}
                />
              )}
            </Content>
            <Content>
              <Label>
                ボタンの追加
                <ContextHelp content={Help.actionType} />
              </Label>
              <StyledPullDown
                value={
                  targetForEdit?.actionType === 'LEARN_MORE'
                    ? targetForEdit?.actionUrl === PERMALINK_ACTION_URL
                      ? 'instagram'
                      : 'url'
                    : 'none'
                }
                options={buttonTypeOptions}
                onChange={handleOnChangeButtonType}
                clearable={false}
              />
            </Content>
            {targetForEdit?.actionType === 'LEARN_MORE' && targetForEdit?.actionUrl !== PERMALINK_ACTION_URL && (
              <Content>
                <Label>
                  サイトのURL
                  <ContextHelp content={Help.actionUrl} />
                </Label>
                <StyledInput
                  value={targetForEdit?.actionUrl || ''}
                  onChange={handleOnChangeActionUrl}
                  onBlur={handleOnBlurActionUrl}
                  placeholder='https://example.com'
                  error={
                    showUrlError && targetForEdit?.actionType === 'LEARN_MORE' && targetForEdit?.validateWebsiteUrl
                  }
                />
              </Content>
            )}
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>閉じる</StyledButton>
            <StyledButton onClick={handleOnSave} priority={'high'} disabled={!targetForEdit.isValid}>
              {targetForEdit.id ? '更新' : '追加'}
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
});

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 4px;
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 16px;
`;

const StyledPullDown = styled(PullDown)`
  width: 100%;
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
  }
`;
