import React, { useEffect, useMemo, useState } from 'react';

import { is } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Prompt } from 'components/atoms/Prompt';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { OrganizationDataReset } from 'components/pageComponents/Organization/OrganizationDataReset';
import { OrganizationEditContent } from 'components/pageComponents/Organization/OrganizationEditContent';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import CONFIG from 'config';
import { getPageTitle } from 'helpers/utils';
import { useHoldOn } from 'hooks/useHoldOn';
import { OrganizationActions as Actions } from 'modules/organization/actions';

const showDataReset = CONFIG.RESET_DATA_ENABLED;

const promptMessage =
  '変更を保存せずにページから離れようとしています。\n保存されていない内容は失われてしまいますが、よろしいですか？';

export const Organization = React.memo(() => {
  const dispatch = useDispatch();

  const { isLoading, organization } = useSelector((state) => state.organization);
  const { stores } = useSelector((state) => state.store);

  const [editingOrganization, setEditingOrganization] = useState(organization);

  const { initializePage, updateOrganization, resetData } = useMemo(
    () => bindActionCreators(Actions, dispatch),
    [dispatch],
  );

  const isChanged = !is(organization, editingOrganization);

  // 保存ボタンが無効か
  const isSaveDisabled = useHoldOn(isLoading, 1000) || !editingOrganization.isValid();

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  useEffect(() => {
    // 元の組織情報が更新されたら反映する
    setEditingOrganization(organization);
  }, [organization]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('組織')} />
      <StickyHeader>
        <Title>組織</Title>
      </StickyHeader>
      <WideBody>
        <Wrapper>
          <OrganizationEditContent
            organization={editingOrganization}
            committedOrganization={organization}
            onChange={setEditingOrganization}
            stores={stores}
            isSaveDisabled={isSaveDisabled}
            onSave={() => updateOrganization(editingOrganization)}
          />
          {showDataReset && <OrganizationDataReset onReset={resetData} />}
        </Wrapper>
      </WideBody>
      <Prompt when={isChanged} message={promptMessage} />
    </MainWrapper>
  );
});

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
`;
