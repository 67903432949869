import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { JSObject } from 'types/Common';

export type OfferStatus = 'none' | 'done';

export type OfferType = 'task' | 'report' | 'other_offer';

export const OfferTypeOptions: { value: OfferType; text: string }[] = [
  { value: 'task', text: '店舗への依頼' },
  { value: 'report', text: '本社への報告' },
  { value: 'other_offer', text: 'その他' },
];

interface OfferRecord {
  id: number;
  status: OfferStatus;
  offer_type: OfferType;
  store_id: number;
  task_id: number;
  comment_count: number;
  create_at: Dayjs;
}

export class Offer extends Record<OfferRecord>({
  id: 0,
  status: 'none',
  offer_type: 'task',
  store_id: 0,
  task_id: 0,
  comment_count: 0,
  create_at: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.create_at = dayjs.unix(params.create_at);
    super(params);
  }

  get OfferTypeLabel() {
    const targetOption = OfferTypeOptions.find((option) => option.value === this.offer_type);
    return targetOption ? targetOption.text : '';
  }

  get isDone() {
    return this.status === 'done';
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  get createDateString() {
    return this.create_at && this.create_at.format('YYYY/MM/DD HH:mm');
  }

  getLabel(key: 'offer_type') {
    switch (key) {
      case 'offer_type':
        return '依頼 / 報告の種類';
      default:
        return '';
    }
  }

  changeId(id: number) {
    return this.set('id', id);
  }

  changeOfferType(offer_type: OfferType) {
    return this.set('offer_type', offer_type);
  }

  changeStoreId(store_id: number) {
    return this.set('store_id', store_id);
  }

  validate() {
    const errors: JSObject = {};

    if (!this.offer_type) {
      errors.offer_type = ErrorType.REQUIRED_ERROR;
    }

    if (!this.store_id) {
      errors.store_id = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }

  requestParams() {
    const { offer_type, store_id } = this.toObject();
    const params = { offer_type, store_id };
    return params;
  }
}

export class Offers extends Record<{
  list: List<Offer>;
}>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data.map((p) => new Offer(p)));
    super({ list });
  }

  get offerDoneCount() {
    return this.list.filter((offer) => offer.isDone).size;
  }

  get isAllDone() {
    return this.list.every((offer) => offer.isDone);
  }

  filteredListByStatus(status: OfferStatus) {
    return this.list.filter((offer) => offer.status === status);
  }

  sortBy() {
    return this.list.sortBy((offer) => offer.store_id);
  }
}
