import React, { useCallback, useMemo } from 'react';

import { List, Set } from 'immutable';
import { Checkbox, Form, Icon as SemanticUIIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  DEFAULT_GBP_CHECK_DAYS_OF_DURATION,
  DEFAULT_GBP_CHECK_DAYS_OF_REVIEW_REPLY_DEADLINE,
  Organization,
  OrganizationFeature,
  OrganizationPlan,
  TRANSLATION_LANGUAGES,
  TRANSLATION_LANGUAGE_LABELS,
  TranslationLanguage,
} from 'models/Domain/Organization';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

type SelectOption<T> = {
  text: string;
  value: T;
};

const PlanOptions: SelectOption<OrganizationPlan>[] = [
  {
    text: 'ベーシックプラン',
    value: 'basic',
  },
  {
    text: 'アドバンスプラン',
    value: 'analysis',
  },
  {
    text: 'エキスパートプラン',
    value: 'competitor',
  },
];

type Props = {
  className?: string;
  organization: Organization;
  committedOrganization: Organization;
  stores: Stores;
  isSaveDisabled: boolean;
  onChange: (organization: Organization) => void;
  onSave: () => void;
};

export const OrganizationEditContent = React.memo<Props>(
  ({ className, organization, committedOrganization, stores, onChange, onSave, isSaveDisabled }) => {
    const handleOnChangeName = useCallback(
      (name: string) => {
        const newOrganization = organization.changeName(name);
        onChange(newOrganization);
      },
      [onChange, organization],
    );

    const handleOnChangePlan = useCallback(
      (plan: OrganizationPlan) => {
        let newOrganization = organization;
        let newFeatures = newOrganization.features;

        newFeatures = newFeatures.changePlan(plan);
        newFeatures = newFeatures.clearOptionalFeatures();

        newOrganization = newOrganization.changeFeatures(newFeatures);
        onChange(newOrganization);
      },
      [onChange, organization],
    );

    const handleOnChangeCanUseGbpMediaProductCategory = useCallback(() => {
      const newValue = !organization.params.can_use_gbp_media_product_category;
      const newOrganization = organization.changeCanUseGbpMediaProductCategory(newValue);
      onChange(newOrganization);
    }, [onChange, organization]);

    const handleOnChangeContractedStores = useCallback(
      (value: string) => {
        const newValue = value ? Number.parseInt(value, 10) : 0;
        const newOrganization = organization.changeContractedStores(newValue || 0);
        onChange(newOrganization);
      },
      [onChange, organization],
    );

    const handleOnChangeShowFeature = useCallback(
      (feature: OrganizationFeature) => {
        let newOrganization = organization;
        let newFeatures = newOrganization.features;

        // 現状ONになっている機能か
        const isEnabledFeature = organization.features.isEnabledFeature(feature);

        const enabledFeaturesSet = Set(organization.features.enabledFeatures);
        const planFeaturesSet = Set(organization.features.planFeatures);

        // 現状ONになっている機能の場合はOFFに、現状OFFになっている機能の場合はONにする
        const newEnabledFeaturesSet = isEnabledFeature
          ? enabledFeaturesSet.delete(feature)
          : enabledFeaturesSet.add(feature);

        // 更新後のincludedFeatures = 更新後の有効機能 - 契約プランに紐づく機能
        let newIncludedFeaturesSet = newEnabledFeaturesSet.subtract(planFeaturesSet);
        // 更新後のexcludedFeatures = 契約プランに紐づく機能 - 更新後の有効機能
        const newExcludedFeaturesSet = planFeaturesSet.subtract(newEnabledFeaturesSet);

        if (isEnabledFeature) {
          switch (feature) {
            case 'local_inventory':
              // 「見本展示のみ」の設定をOFFにする
              newIncludedFeaturesSet = newIncludedFeaturesSet.delete('local_inventory:on_display_to_order');
              break;
            case 'map_search_rank':
              // 「設定上限数」の設定値をnullにする
              newOrganization = newOrganization.changeMapSearchRankConfigLimit(null);
              break;
            case 'localpost_translation':
              // 「対応言語」の設定を空にする
              newOrganization = newOrganization.changeTranslationLanguages([]);
              break;
            default:
              break;
          }
        }

        newFeatures = newFeatures.changeIncludedFeatures(List(newIncludedFeaturesSet));
        newFeatures = newFeatures.changeExcludedFeatures(List(newExcludedFeaturesSet));
        newOrganization = newOrganization.changeFeatures(newFeatures);

        onChange(newOrganization);
      },
      [onChange, organization],
    );

    const handleOnChangeTranslationLanguages = useCallback(
      (value: TranslationLanguage) => {
        if (organization.canUseLocalPostTranslationLanguage(value)) {
          // 有効な言語の場合はOFFにする
          const newValue = TRANSLATION_LANGUAGES.filter(
            (language) => organization.canUseLocalPostTranslationLanguage(language) && language !== value,
          );
          const newOrganization = organization.changeTranslationLanguages(newValue);
          onChange(newOrganization);
        } else {
          // 無効な言語の場合はONにする
          const newValue = TRANSLATION_LANGUAGES.filter(
            (language) => organization.canUseLocalPostTranslationLanguage(language) || language === value,
          );
          const newOrganization = organization.changeTranslationLanguages(newValue);
          onChange(newOrganization);
        }
      },
      [onChange, organization],
    );

    const handleOnChangeMapSearchRankConfigLimit = useCallback(
      (value: string) => {
        let newValue = value ? Number.parseInt(value, 10) : null;
        newValue = Number.isNaN(newValue) ? null : newValue;
        const newOrganization = organization.changeMapSearchRankConfigLimit(newValue);
        onChange(newOrganization);
      },
      [onChange, organization],
    );

    const handleOnChangeGbpCheckDaysOfDuration = useCallback(
      (value: string) => {
        let newValue = value ? Number.parseInt(value, 10) : null;
        newValue = Number.isNaN(newValue) ? null : newValue;
        const newOrganization = organization.changeGbpCheckDaysOfDuration(newValue);
        onChange(newOrganization);
      },
      [onChange, organization],
    );

    const handleOnChangeGbpCheckDaysOfReviewReplyDeadline = useCallback(
      (value: string) => {
        let newValue = value ? Number.parseInt(value, 10) : null;
        newValue = Number.isNaN(newValue) ? null : newValue;
        const newOrganization = organization.changeGbpCheckDaysOfReviewReplyDeadline(newValue);
        onChange(newOrganization);
      },
      [onChange, organization],
    );

    const error = organization.validate();

    const mapSearchRankConfigLimitWarningMessage: string | null = useMemo(() => {
      const configLimit = organization.params.map_search_rank_config_limit;
      if (configLimit == null) {
        return null;
      }
      const committedConfigLimit = committedOrganization.params.map_search_rank_config_limit;
      if (committedConfigLimit && configLimit < committedConfigLimit) {
        return `検索順位監視の設定上限数が現在の設定値（${committedConfigLimit}）より少なくなっています`;
      }
      if (configLimit < organization.defaultConfigLimit) {
        return `検索順位監視の設定上限数がデフォルト（契約店舗数×5＝${organization.defaultConfigLimit}）より少なくなっています`;
      }
      return null;
    }, [organization, committedOrganization]);

    return (
      <Wrapper className={className}>
        <Form>
          <Header>組織情報</Header>
          <ContentWrapper>
            <Form.Field required>
              <Label>組織名</Label>
              <Input value={organization.name} onChange={handleOnChangeName} error={error.name} />
            </Form.Field>
          </ContentWrapper>
          <Header>契約情報</Header>
          <ContentWrapper>
            <Form.Field>
              <Label>契約プラン</Label>
              <PullDown
                value={organization.features.plan}
                options={PlanOptions}
                onChange={(value: any) => handleOnChangePlan(value)}
              />
            </Form.Field>
          </ContentWrapper>
          <ContentWrapper>
            <Form.Field>
              <Label>
                契約店舗数
                <Button
                  priority={'low'}
                  onClick={() => handleOnChangeContractedStores(`${stores.getUnclosedStoreIds().length}`)}
                  style={{ marginLeft: '8px', fontSize: '14px' }}
                >
                  登録されている店舗数（{stores.getUnclosedStoreIds().length}店舗）を入力する
                </Button>
              </Label>
              <Input value={organization.contractedStores} onChange={handleOnChangeContractedStores} />
            </Form.Field>
          </ContentWrapper>
          <Header>機能設定</Header>
          <Divider />
          <ContentWrapper>
            <Title>写真</Title>
            <FlexWrapper>
              <SubTitle>「商品」カテゴリを有効にする</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.params.can_use_gbp_media_product_category}
                  onChange={handleOnChangeCanUseGbpMediaProductCategory}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>投稿</Title>
            <FlexWrapper>
              <SubTitle>AIアシストを有効にする</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseLocalPostAi()}
                  onChange={() => handleOnChangeShowFeature('localpost_ai')}
                />
              </ValueWrapper>
            </FlexWrapper>
            <FlexWrapper>
              <SubTitle>多言語対応を有効にする</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseLocalPostTranslation()}
                  onChange={() => handleOnChangeShowFeature('localpost_translation')}
                />
              </ValueWrapper>
            </FlexWrapper>
            {organization.canUseLocalPostTranslation() && (
              <>
                <SectionTitle>対応言語</SectionTitle>
                {TRANSLATION_LANGUAGES.map((language) => (
                  <FlexWrapper key={language}>
                    <SubTitle>{TRANSLATION_LANGUAGE_LABELS[language]}</SubTitle>
                    <ValueWrapper>
                      <StyledCheckbox
                        toggle
                        checked={organization.canUseLocalPostTranslationLanguage(language)}
                        onChange={() => handleOnChangeTranslationLanguages(language)}
                      />
                    </ValueWrapper>
                  </FlexWrapper>
                ))}
              </>
            )}
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>クチコミ</Title>
            <FlexWrapper>
              <SubTitle>AIアシストを有効にする</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseReviewAi()}
                  onChange={() => handleOnChangeShowFeature('review_ai')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>サービス</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseService()}
                  onChange={() => handleOnChangeShowFeature('service')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>飲食メニュー</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseMenu()}
                  onChange={() => handleOnChangeShowFeature('menu')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>店頭在庫</Title>
            <FlexWrapper>
              <SubTitle>機能を有効化する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseLocalInventory()}
                  onChange={() => handleOnChangeShowFeature('local_inventory')}
                />
              </ValueWrapper>
            </FlexWrapper>
            {organization.canUseLocalInventory() && (
              <FlexWrapper>
                <SubTitle>メニューに表示する</SubTitle>
                <ValueWrapper>
                  <StyledCheckbox
                    toggle
                    checked={organization.canUseLocalInventoryPage()}
                    onChange={() => handleOnChangeShowFeature('local_inventory:page')}
                  />
                </ValueWrapper>
              </FlexWrapper>
            )}
            {/* 店頭在庫がONのときのみ表示する */}
            {organization.canUseLocalInventory() && (
              <FlexWrapper>
                <SubTitle>「見本展示のみ」を有効にする</SubTitle>
                <ValueWrapper>
                  <StyledCheckbox
                    toggle
                    checked={organization.canUseOnDisplayToOrder()}
                    onChange={() => handleOnChangeShowFeature('local_inventory:on_display_to_order')}
                  />
                </ValueWrapper>
              </FlexWrapper>
            )}
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>競合店舗</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseCompetitor()}
                  onChange={() => handleOnChangeShowFeature('competitor')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>GBPパフォーマンス</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseGbpPerformance()}
                  onChange={() => handleOnChangeShowFeature('performance')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>OMOインサイト</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseOmoInsight()}
                  onChange={() => handleOnChangeShowFeature('omo')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>検索キーワード</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseSearchKeywords()}
                  onChange={() => handleOnChangeShowFeature('search_keywords')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>検索順位監視</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseMapSearchRank()}
                  onChange={() => handleOnChangeShowFeature('map_search_rank')}
                />
              </ValueWrapper>
            </FlexWrapper>
            {/* 検索順位監視がONのときのみ表示する */}
            {organization.canUseMapSearchRank() && (
              <FormWrapper>
                <Form.Field>
                  <Label>
                    設定上限数
                    <ContextHelp content='空欄の場合は、契約店舗数×5が上限になります' />
                  </Label>
                  <Input
                    value={organization.params.map_search_rank_config_limit ?? ''}
                    placeholder={`${organization.defaultConfigLimit}`}
                    onChange={handleOnChangeMapSearchRankConfigLimit}
                  />
                </Form.Field>
              </FormWrapper>
            )}
            {mapSearchRankConfigLimitWarningMessage && (
              <Warning>
                <SemanticUIIcon name='exclamation triangle' />
                {mapSearchRankConfigLimitWarningMessage}
              </Warning>
            )}
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>検索順位調査</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseMapCompetitorResearch()}
                  onChange={() => handleOnChangeShowFeature('map_competitor_research')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>GBP診断</Title>
            <FormWrapper>
              <Form.Field>
                <Label>
                  運用情報の評価を行う期間の日数
                  <ContextHelp
                    content={`GBP診断はこの設定値の期間を対象に行われます。空欄の場合は ${DEFAULT_GBP_CHECK_DAYS_OF_DURATION}日 が適用されます`}
                  />
                </Label>
                <Input
                  value={organization.params.gbp_check_days_of_duration ?? ''}
                  placeholder={`${DEFAULT_GBP_CHECK_DAYS_OF_DURATION}`}
                  onChange={handleOnChangeGbpCheckDaysOfDuration}
                />
              </Form.Field>
            </FormWrapper>
            <FormWrapper>
              <Form.Field>
                <Label>
                  クチコミの対応期限の日数
                  <ContextHelp
                    content={`GBP診断のクチコミに対応した店舗数の判定基準。空欄の場合は ${DEFAULT_GBP_CHECK_DAYS_OF_REVIEW_REPLY_DEADLINE}日 が適用されます`}
                  />
                </Label>
                <Input
                  value={organization.params.gbp_check_days_of_review_reply_deadline ?? ''}
                  placeholder={`${DEFAULT_GBP_CHECK_DAYS_OF_REVIEW_REPLY_DEADLINE}`}
                  onChange={handleOnChangeGbpCheckDaysOfReviewReplyDeadline}
                  error={error.gbpCheck}
                />
              </Form.Field>
            </FormWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <Title>Instagram</Title>
            <FlexWrapper>
              <SubTitle>メニューに表示する</SubTitle>
              <ValueWrapper>
                <StyledCheckbox
                  toggle
                  checked={organization.canUseInstagram()}
                  onChange={() => handleOnChangeShowFeature('instagram')}
                />
              </ValueWrapper>
            </FlexWrapper>
          </ContentWrapper>
        </Form>
        <Divider />
        <ButtonWrapper>
          <SaveButton disabled={isSaveDisabled} onClick={onSave}>
            保存
          </SaveButton>
        </ButtonWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 16px;
  :not(:first-child) {
    margin-top: 32px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Label = styled.label`
  &&& {
    font-size: 16px !important;
    font-weight: normal !important;
    color: ${COLOR.BLACK} !important;
  }
`;

const SubTitle = styled.div`
  font-size: 16px;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin-bottom: 16px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  margin-bottom: 8px;
  min-height: 32px;
  :hover {
    background: #b5b5b520;
  }
`;

const FormWrapper = styled.div`
  padding: 8px 0;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 16px;
  &&& {
    input:focus:checked ~ label:before,
    input:checked ~ label:before {
      background-color: #05ccad !important;
    }
  }
`;

const Divider = styled.hr`
  border-top: 1px solid ${COLOR.LIGHT_GRAY};
  border-bottom: none;
  margin: 0 0 16px;
`;

const Warning = styled.div`
  font-size: 14px;
  margin: 0 8px;
  color: #f09061;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 180px;
    & .ui.search.selection.dropdown {
      font-size: 15px;
      padding: 14px 12px !important;
      min-height: 50px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      padding: 15px 34px 14px 13px !important;
    }
    & .ui.search.selection.dropdown > i {
      padding-top: 16px !important;
    }
    & .ui.search.selection.dropdown > .menu > .item {
      font-size: 15px;
      padding: 16px 12px !important;
    }

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SaveButton = styled(Button).attrs({ priority: 'high' })`
  &&& {
    height: 54px;
    width: 170px;
    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      margin-top: 8px;
      margin-left: 0;
      width: 100%;
    }
  }
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0 16px;
`;
