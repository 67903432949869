import React from 'react';

import styled from 'styled-components';

// import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { GmbAttributeMetadata } from 'models/Domain/GmbAttributeMetadatas';
import { GmbUrlAttribute } from 'models/Domain/GmbLocation/GmbAttributes';

type Props = {
  className?: string;
  urlAttribute: GmbUrlAttribute;
  attributeMetadatas: GmbAttributeMetadata;
  onChange: (attributeId: string, idx: number, value: string) => void;
  addUrl: () => void;
  onRemove: (idx: number) => void;
};

const URL_ATTRIBUTE_PLACEHOLDERS: { [key: string]: string } = {
  url_facebook: '例) https://www.facebook.com/[ユーザー名]',
  url_instagram: '例) https://www.instagram.com/[ユーザー名]/',
  url_linkedin: '例) https://www.linkedin.com/in/[ユーザー名]',
  url_pinterest: '例) https://www.pinterest.com/[ユーザー名]/',
  url_tiktok: '例) https://www.tiktok.com/@[ユーザー名]',
  url_twitter: '例) https://x.com/[ユーザー名]',
  url_youtube: '例) https://www.youtube.com/@[ユーザー名]',
};

export const AttributeUrl = React.memo<Props>(
  ({ className, attributeMetadatas, urlAttribute, onChange, addUrl, onRemove }) => {
    return (
      <UrlWrapper className={className}>
        {urlAttribute.urlValues.map((urlValue, idx) => {
          return (
            <InputWrapper key={idx}>
              <StyledInput
                onChange={(value) => onChange(attributeMetadatas.attributeId, idx, value)}
                value={urlValue.url}
                error={!urlAttribute.getUrlError(idx).isValid && urlAttribute.getUrlError(idx).error}
                placeholder={URL_ATTRIBUTE_PLACEHOLDERS[urlAttribute.attributeId]}
              />
              {urlAttribute.urlValues.size > 1 && <Icon onClick={() => onRemove(idx)} type='cancel' />}
            </InputWrapper>
          );
        })}
        {!!attributeMetadatas.isRepeatable && urlAttribute.urlValues.size < 10 && (
          <AddLabel onClick={addUrl}>URLを追加</AddLabel>
        )}
      </UrlWrapper>
    );
  },
);

const UrlWrapper = styled.div`
  margin-top: 8px;
  padding: 8px;
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
    margin-top: 8px;
    & > .ui.input {
      & > input {
        width: 100% !important;
      }
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddLabel = styled.div`
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 8px;
`;
