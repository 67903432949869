import React from 'react';

type Props = {
  className?: string;
  value: number;
};

export const CommaSeparate: React.FC<Props> = ({ className, value }) => {
  return <span>{value?.toLocaleString()}</span>;
};
