import { List } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { OpenInfoStatus } from 'models/Domain/GmbLocation/GmbOpenInfo';
import { GmbProfile } from 'models/Domain/GmbLocation/GmbProfile';
import { GmbRegularHours } from 'models/Domain/GmbLocation/GmbRegularHours';
import { GmbSpecialHours } from 'models/Domain/GmbLocation/GmbSpecialHours';
import { DayOfWeek } from 'models/Domain/GmbLocation/GmbTimePeriod';
import { Stores } from 'models/Domain/Store';

const actionCreator = actionCreatorFactory('BulkEditStores');

export const BulkEditStoresActions = {
  initialize: actionCreator('initialize'),
  setInitialized: actionCreator<boolean>('setInitialized'),
  setStores: actionCreator<{ stores: Stores }>('setStores'),
  resetStateWithoutStores: actionCreator('resetStateWithoutStores'),
  fetchAttributeMetadatas: actionCreator('fetchAttributeMetadatas'),
  setAttributeMetadatas: actionCreator<{ attributeMetadatas: { [categoryId: string]: GmbAttributeMetadatas } }>(
    'setAttributeMetadatas',
  ),
  changeOpenInfo: actionCreator<{ index: number; value: OpenInfoStatus }>('changeOpenInfo'),
  changeSpecialHours: actionCreator<{ index: number; specialHours: GmbSpecialHours }>('changeSpecialHours'),
  bulkChangeSpecialHours: actionCreator<{ specialHours: GmbSpecialHours }>('bulkChangeSpecialHours'),
  changeRegularHours: actionCreator<{ index: number; dayOfWeek: DayOfWeek; regularHours: GmbRegularHours }>(
    'changeRegularHours',
  ),
  changeProfile: actionCreator<{ index: number; profile: GmbProfile }>('changeProfile'),
  bulkChangeProfile: actionCreator<{ profile: GmbProfile }>('bulkChangeProfile'),
  updateBulkEditBusinessInfo: actionCreator('updateBulkEditBusinessInfo'),
  changeAttributes: actionCreator<{ index: number; attributes: GmbAttributes }>('changeAttributes'),
  bulkChangeAttributes: actionCreator<{ targetAttributeIds: List<string>; attributes: GmbAttributes }>(
    'bulkChangeAttributes',
  ),
};
