import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { COLOR } from 'style/color';

export type DropdownMenuItem = {
  label: string;
  link?: string;
  withOrganizationId?: boolean;
  onClick?: () => void;
  disabled?: boolean;
} & Pick<React.ComponentProps<typeof Link>, 'target'>;

export type DropdownMenuProps = {
  items: DropdownMenuItem[];
  dividerIndex?: number[];
} & Pick<React.ComponentProps<typeof Dropdown>, 'trigger'> &
  Pick<React.ComponentProps<typeof Dropdown.Menu>, 'direction'>;

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ items, trigger, direction, dividerIndex }) => {
  return (
    <Dropdown trigger={trigger} icon={null}>
      <Dropdown.Menu direction={direction}>
        {items.map((item, index) => {
          if (item.disabled) {
            return (
              <React.Fragment key={item.label}>
                <MenuItem disabled>
                  <ItemLabel>{item.label}</ItemLabel>
                  <Link to={'test'} />
                </MenuItem>
                {dividerIndex?.includes(index) && <Dropdown.Divider />}
              </React.Fragment>
            );
          }
          if (item.link) {
            if (item.link.startsWith('http')) {
              return (
                <React.Fragment key={item.label}>
                  <MenuItem>
                    <ExternalLink href={item.link} target={item.target} />
                    <ItemLabel>{item.label}</ItemLabel>
                  </MenuItem>
                  {dividerIndex?.includes(index) && <Dropdown.Divider />}
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={item.label}>
                  <MenuItem>
                    <InternalLink
                      to={item.link}
                      target={item.target}
                      withOrganizationId={item.withOrganizationId ?? false}
                    />
                    <ItemLabel>{item.label}</ItemLabel>
                  </MenuItem>
                  {dividerIndex?.includes(index) && <Dropdown.Divider />}
                </React.Fragment>
              );
            }
          }

          return (
            <>
              <MenuItem key={item.label} onClick={item.onClick}>
                <ItemLabel>{item.label}</ItemLabel>
              </MenuItem>
              {dividerIndex?.includes(index) && <Dropdown.Divider />}
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const MenuItem = styled(Dropdown.Item)<{ disabled?: boolean }>`
  &&& {
    display: inline;
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
    color: ${({ disabled = false }) => (disabled ? COLOR.GRAY : COLOR.BLACK)};
  }
`;

const InternalLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ExternalLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ItemLabel = styled.span`
  padding: 4px 8px;
  font-size: 14px;
`;
