import actionCreatorFactory from 'typescript-fsa';

import {
  GbpPerformanceMAGraphData,
  GbpPerformanceMAMonthlyData,
  GbpPerformanceMATableData,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { GbpPerformanceMACsvDownloadCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMACsvDownloadCondition';
import { GbpPerformanceMASearchCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMASearchCondition';

const actionCreator = actionCreatorFactory('GbpPerformanceMA');

export const GbpPerformanceMAActions = {
  initializePage: actionCreator('initializePage'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),
  commitSearchCondition: actionCreator<GbpPerformanceMASearchCondition>('commitSearchCondition'),
  setSearchCondition: actionCreator<GbpPerformanceMASearchCondition>('setSearchCondition'),
  updatePrevSearchCondition: actionCreator('updatePrevSearchCondition'),
  setCsvDownloadCondition: actionCreator<GbpPerformanceMACsvDownloadCondition>('setCsvDownloadCondition'),
  downloadCsv: actionCreator('downloadCsv'),
  setIsLoadingData: actionCreator<boolean>('setIsLoadingData'),
  setIsLoadingComparisonData: actionCreator<boolean>('setIsLoadingComparisonData'),
  setIsLoadingMonthlyData: actionCreator<boolean>('setIsLoadingMonthlyData'),
  setGraphData: actionCreator<GbpPerformanceMAGraphData>('setGraphData'),
  setTableData: actionCreator<GbpPerformanceMATableData>('setTableData'),
  setMonthlyData: actionCreator<GbpPerformanceMAMonthlyData>('setMonthlyData'),
  setIsFirstSearch: actionCreator<boolean>('setIsFirstSearch'),
};
