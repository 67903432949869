import { Record } from 'immutable';

import { OfferType, OfferTypeOptions } from 'models/Domain/Offer';
import { JSObject } from 'types/Common';

export const OFFER_TYPE_FILTER_ALL = 'all';

export type OfferTypeForFilter = typeof OFFER_TYPE_FILTER_ALL | OfferType;

export class OfferTypeFilter extends Record<{
  offerType?: OfferTypeForFilter;
}>({
  offerType: undefined,
}) {
  changeOfferType(offerType: OfferTypeForFilter): this {
    return this.set('offerType', offerType);
  }
  static generateOfferTypeFilterOption(): JSObject[] {
    const offerTypeFilterAllOption = { value: OFFER_TYPE_FILTER_ALL, text: 'すべて' };
    const offerTypeIconlessOptions = OfferTypeOptions.map((offerType) => ({
      text: offerType.text,
      value: offerType.value,
    }));
    return [offerTypeFilterAllOption, ...offerTypeIconlessOptions];
  }
}
