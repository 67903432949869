import React from 'react';

import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';

import { DropdownMenu, DropdownMenuItem } from 'components/molecules/DropdownMenu';
import { ORGANIZATION_ID_QUERY_KEY } from 'modules/app/const';
import { Path } from 'routes';
import { assertNever } from 'types/Common';

const AccountMenuItems = [
  'performance',
  'store',
  'album',
  'localpost',
  'review',
  'offer',
  'searchKeyword',
  'mapSearchRank',
] as const;
type AccountMenuItem = (typeof AccountMenuItems)[number];
type AccountMenuProps = {
  organizationId: number;
  startDate?: Dayjs;
  endDate?: Dayjs;
  items?: AccountMenuItem[];
  excludeItems?: AccountMenuItem[];
  additionalItems?: {
    start?: DropdownMenuItem[];
    end?: DropdownMenuItem[];
  };
} & Pick<React.ComponentProps<typeof DropdownMenu>, 'trigger' | 'direction' | 'dividerIndex'>;

export const AccountMenu: React.FC<AccountMenuProps> = ({
  trigger,
  direction,
  organizationId,
  startDate,
  endDate,
  items = AccountMenuItems,
  excludeItems = [],
  additionalItems,
  dividerIndex,
}) => {
  // NOTE 本コンポーネントが位置するのはコンポーネント階層構造の末端の方なので、
  // アカウント一覧をリレーして渡すよりもシンプルな実装にするために、コンポーネント内で直接ストアからアカウント一覧を取得している。
  const accountList = useSelector((state) => state.app.accountList);

  const account = accountList.find(organizationId);

  const menuItems: DropdownMenuItem[] = account
    ? (items
        .filter((item) => !excludeItems.includes(item))
        .map((item) => {
          switch (item) {
            case 'store': {
              const searchParams = new URLSearchParams();
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              return {
                label: '店舗一覧',
                link: `${Path.store.index}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            case 'album': {
              const searchParams = new URLSearchParams();
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              return {
                label: '写真一覧',
                link: `${Path.album.index}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            case 'localpost': {
              const searchParams = new URLSearchParams();
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              if (startDate) {
                searchParams.set('sd', startDate.format('YYYY-MM-DD'));
              }
              if (endDate) {
                searchParams.set('ed', endDate.format('YYYY-MM-DD'));
              }
              return {
                label: '投稿一覧',
                link: `${Path.localpost.index}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            case 'review': {
              const searchParams = new URLSearchParams();
              if (startDate) {
                searchParams.set('sd', startDate.format('YYYY-MM-DD'));
              }
              if (endDate) {
                searchParams.set('ed', endDate.format('YYYY-MM-DD'));
              }
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              return {
                label: 'クチコミ一覧',
                link: `${Path.gmb.review}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            case 'offer': {
              const searchParams = new URLSearchParams();
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              return {
                label: '依頼 / 報告',
                link: `${Path.offerGroups.index}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            case 'searchKeyword': {
              if (!account.user.canUseSearchKeywords) {
                return null;
              }
              const searchParams = new URLSearchParams();
              if (startDate && endDate) {
                searchParams.set('sm', startDate.format('YYYY-MM'));
                searchParams.set('em', endDate.format('YYYY-MM'));
              }
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              return {
                label: '検索キーワード',
                link: `${Path.searchKeywords.index}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            case 'mapSearchRank': {
              if (!account.user.canUseMapSearchRank) {
                return null;
              }
              const searchParams = new URLSearchParams();
              if (startDate && endDate) {
                searchParams.set('sd', startDate.format('YYYY-MM-DD'));
                searchParams.set('ed', endDate.format('YYYY-MM-DD'));
              }
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              return {
                label: '検索順位監視',
                link: `${Path.mapSearchRank.index}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            case 'performance': {
              if (!account.user.canUseGbpPerformance) {
                return null;
              }
              const searchParams = new URLSearchParams();
              if (startDate && endDate) {
                searchParams.set('sd', startDate.format('YYYY-MM-DD'));
                searchParams.set('ed', endDate.format('YYYY-MM-DD'));
              }
              searchParams.set(ORGANIZATION_ID_QUERY_KEY, organizationId.toString());
              return {
                label: 'GBPパフォーマンス',
                link: `${Path.gbp.performance}?${searchParams.toString()}`,
                target: '_blank',
              };
            }
            default:
              return assertNever(item);
          }
        })
        .filter((item) => item) as DropdownMenuItem[])
    : [];

  const additionalStartItems = additionalItems?.start ?? [];
  const additionalEndItems = additionalItems?.end ?? [];
  const totalItems = additionalStartItems.concat(menuItems).concat(additionalEndItems);

  return <DropdownMenu trigger={trigger} direction={direction} items={totalItems} dividerIndex={dividerIndex} />;
};
