import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { PaginationWithoutLimit } from 'models/Domain/Pagination';
import { JSObject } from 'types/Common';

import { OfferType, Offers } from '../Offer';

export type OfferGroupStatus = 'done' | 'in_progress';

interface OfferRecord {
  id: string;
  organization_id: string;
  type: OfferType;
  real_id: number;
  name: string;
  content_id: number;
  created_by: number;
  total_stores: number;
  done_stores: number;
  status: OfferGroupStatus;
  create_at: Dayjs;
  update_at: Dayjs;
  due_date: Dayjs;
  latest_activity_time: Dayjs;
  offers: Offers;
}

export class OfferGroup extends Record<OfferRecord>({
  id: '',
  organization_id: '',
  type: 'task',
  real_id: 0,
  name: '',
  content_id: 0,
  created_by: 0,
  total_stores: 0,
  done_stores: 0,
  status: 'in_progress',
  create_at: dayjs(),
  update_at: dayjs(),
  due_date: dayjs(),
  latest_activity_time: dayjs(),
  offers: new Offers(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.offers = new Offers(params.offers);
    params.create_at = dayjs(params.create_at);
    params.update_at = dayjs(params.update_at);
    params.due_date = dayjs(params.due_date);
    params.latest_activity_time = dayjs(params.latest_activity_time);
    super(params);
  }

  /**
   * 期限を過ぎているかどうか
   */
  get isOverdue() {
    return this.due_date.isBefore(dayjs());
  }
}

export class OfferGroups extends Record<{
  items: List<OfferGroup>;
  pagination: PaginationWithoutLimit;
}>({
  items: List(),
  pagination: new PaginationWithoutLimit(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (data.items) {
      params.items = List(data.items.map((p: JSObject) => new OfferGroup(p)));
    }
    params.pagination = new PaginationWithoutLimit(params.pagination);
    super(params);
  }
}
