import React from 'react';

import { Dayjs } from 'dayjs';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { OfferStatus as Status } from 'components/atoms/OfferStatus';
import { OfferStatus } from 'models/Domain/Offer';
import { COLOR } from 'style/color';

export type TaskOfferItem = {
  id: number;
  storeName: string;
  offerPath: string;
  commentCount: number;
  hasUnreadComments: boolean;
  status: OfferStatus;
  updateAt: Dayjs;
};

export type TaskOfferListProps = {
  className?: string;
  title: string;
  offers: TaskOfferItem[];
  displayUpdateBadge: boolean; // 更新ありバッジを表示するかどうか
};

export const TaskOfferList: React.FC<TaskOfferListProps> = ({ className, title, offers, displayUpdateBadge }) => {
  const doneStores = offers.filter((o) => o.status === 'done').length;
  const noneStores = offers.filter((o) => o.status === 'none').length;
  return (
    <Wrapper className={className}>
      <Header>
        <Title>{title}</Title>
        <Summary>完了店舗：{doneStores}</Summary>
        <Summary>未完了店舗：{noneStores}</Summary>
      </Header>
      <StyledTable selectable>
        <TableHeader>
          <Table.Row>
            <Table.HeaderCell>{/* 未読表示 */}</Table.HeaderCell>
            <Table.HeaderCell>店舗名</Table.HeaderCell>
            <Table.HeaderCell>コメント数</Table.HeaderCell>
            <Table.HeaderCell>最新コメント</Table.HeaderCell>
            <Table.HeaderCell>進捗</Table.HeaderCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {offers.map((taskOffer) => {
            const { id, storeName, offerPath, commentCount, hasUnreadComments, status, updateAt } = taskOffer;
            return (
              <Table.Row key={id}>
                <UpdateBadgeCell>
                  {displayUpdateBadge && hasUnreadComments && (
                    <CellContent>
                      <UpdateBadge />
                    </CellContent>
                  )}
                </UpdateBadgeCell>
                <Table.Cell>
                  <CellContent>
                    <MobileOnlyLabel>店舗名</MobileOnlyLabel>
                    <StoreLink to={offerPath}>{storeName}</StoreLink>
                  </CellContent>
                </Table.Cell>
                <Table.Cell>
                  <CellContent>
                    <MobileOnlyLabel>コメント数</MobileOnlyLabel>
                    {commentCount}
                  </CellContent>
                </Table.Cell>
                <Table.Cell>
                  <CellContent>
                    <MobileOnlyLabel>最新コメント</MobileOnlyLabel>
                    {commentCount > 0 ? updateAt.fromNow() : '-'}
                  </CellContent>
                </Table.Cell>
                <Table.Cell>
                  <CellContent>
                    <MobileOnlyLabel>進捗</MobileOnlyLabel>
                    <Status value={status} />
                  </CellContent>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </StyledTable>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Header = styled.div`
  padding: 0 20px;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Summary = styled.span`
  display: inline-block;
  margin-right: 1em;
  font-size: 12px;
  color: ${COLOR.GRAY};
`;

const StyledTable = styled(Table)`
  &&& {
    font-size: 13px;
    margin-top: 6px;
  }
`;

const TableHeader = styled(Table.Header)`
  @media (max-width: 599px) {
    &&& {
      /* モバイル表示時はテーブルのヘッダを表示しない（セル内に、ラベルを表示させるので） */
      /* !important書かないと効かないレベルでCSSががんじがらめ。 */
      /* ただ、このスタイルの発動条件は、かなり限定的なので影響はない */
      display: none !important;
    }
  }
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const StoreLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const UpdateBadgeCell = styled(Table.Cell)`
  &&& {
    width: 8px;
    padding-left: 16px;
    padding-right: 0;
  }
`;

const UpdateBadge = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${COLOR.PURPLE};
`;

const MobileOnly = styled.div`
  display: inline-block;
  @media (min-width: 600px) {
    display: none;
  }
`;

const MobileOnlyLabel = styled(MobileOnly)`
  width: 100px;
  font-weight: bold;
`;
