import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { PopupIcon } from 'components/molecules/PopupIcon';
import { MemoIconData } from 'helpers/graph';
import { PerformanceMetric } from 'models/Domain/OmoInsight';
import { CompositeOmoInsightSummaryItem } from 'models/Domain/OmoInsight/InsightSummary';
import { CompositePerformanceGraphData } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { COLOR } from 'style/color';

import { METRIC_LABELS } from '../index';

import { OmoInsightPeformanceMetricsList } from './OmoInsightPeformanceMetricsList';
import { OmoInsightPerformanceGraph } from './OmoInsightPerformanceGraph';

type Props = {
  className?: string;
  insight: CompositeOmoInsightSummaryItem;
  graphData: CompositePerformanceGraphData;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
};

export const Performance = React.memo<Props>(({ className, insight, graphData, memoData, onClickMemo }) => {
  // 有効なメトリックのリスト
  const activeMetrics = graphData.activeMetrics;

  // 選択しているメトリック
  const [selectedMetric, selectMetric] = useState<PerformanceMetric | null>(activeMetrics[0] ?? null);

  // グラフデータが変更された時に、
  // 選択されたメトリックがない場合 or 有効なメトリックでない場合、有効なメトリックの中で最初のメトリックを選択する
  useEffect(() => {
    if (selectedMetric !== null && activeMetrics.includes(selectedMetric)) {
      return;
    }

    const firstMetric = activeMetrics[0] ?? null;
    if (selectedMetric !== firstMetric) {
      selectMetric(firstMetric);
    }
  }, [activeMetrics, graphData, selectMetric, selectedMetric]);

  // 選択されたプログラムの商品ステータスのグラフデータ
  const performanceGraphData = useMemo(
    () => graphData.getPerformanceGraphData(selectedMetric),
    [graphData, selectedMetric],
  );

  return (
    <Wrapper className={className}>
      <Header>
        <Title>
          パフォーマンス
          <StyledPopupIcon
            header='パフォーマンス'
            content='プログラムごとのGoogleマップまたはGoogle検索で表示・クリックされた数、掲載された商品情報経由での流入数や収益'
          />
        </Title>
      </Header>
      {activeMetrics.length === 0 ? (
        <Message>表示するデータがありません</Message>
      ) : (
        <>
          <StatsContainer>
            <OmoInsightPeformanceMetricsList
              insight={insight}
              selectedMetric={selectedMetric}
              selectMetric={selectMetric}
              activeMetrics={activeMetrics}
            />
          </StatsContainer>

          <GraphContainer>
            {selectedMetric && <SubTitle>{METRIC_LABELS[selectedMetric]}</SubTitle>}
            {/* FIXME: 本来は集計期間のグラフを表示するべきだが、空のデータの場合はitemsが生成されないので表示していない */}
            {/* 集計期間のデータが１つもないときでも検索条件をもとに空のデータを生成するようにしたい */}
            {graphData.target.items.size > 0 ? (
              <OmoInsightPerformanceGraph
                metric={selectedMetric}
                graphData={performanceGraphData}
                showComparisonGraph={graphData.hasComparisonData()}
                memoData={memoData}
                onClickMemo={onClickMemo}
              />
            ) : (
              <Message>表示するデータがありません</Message>
            )}
          </GraphContainer>
        </>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 64px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 18px;
`;

const Message = styled.div`
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
  width: 100%;
  text-align: center;
  padding: 24px 0;
`;

const StyledPopupIcon = styled(PopupIcon)`
  margin-left: 4px;
`;
const StatsContainer = styled.div`
  margin-bottom: 16px;
`;

const GraphContainer = styled.div`
  padding: 16px;
`;

const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 16px;
`;
