import { Record as ImmutableRecord } from 'immutable';

import { ReviewReplyGenerateParams } from 'ApiClient/ReviewApi';
import { ValidationResult } from 'types/Common';

import { Review } from './Review';

export const MAX_COMMENT_LENGTH = 1500;
export const MAX_MESSAGE_LENGTH = 200;

type RatingType = 'good' | 'bad';

type ReviewReplyGenerateConditionRecord = {
  comment: string;
  reviewer: string;
  ratingType: RatingType;
  message: string;
};

export class ReviewReplyGenerateCondition extends ImmutableRecord<ReviewReplyGenerateConditionRecord>({
  comment: '',
  reviewer: '',
  ratingType: 'good',
  message: '',
}) {
  /**
   * クチコミのモデルの情報をもとに生成条件を作成する
   * @param review
   */
  static fromReview(review: Review) {
    const { starRating } = review;
    // 返信対象のクチコミの評価が1か2なら低評価をデフォルトにする
    const ratingType = starRating === 'ONE' || starRating === 'TWO' ? 'bad' : 'good';
    return new ReviewReplyGenerateCondition({
      comment: review.comment,
      reviewer: review.reviewer.name,
      ratingType,
      message: '',
    });
  }

  get reviewType() {
    // commentの有無とratingTypeから、クチコミの内容のタイプを返す
    return `${this.comment ? 'comment' : 'rating'}_${this.ratingType}` as const;
  }

  changeComment(value: string) {
    return this.set('comment', value);
  }

  changeReviewer(value: string) {
    return this.set('reviewer', value);
  }

  changeRatingType(value: RatingType) {
    return this.set('ratingType', value);
  }

  changeMessage(value: string) {
    return this.set('message', value);
  }

  isValid() {
    return Object.values(this.validate()).every((result) => result.isValid);
  }

  validate(): Record<keyof ReviewReplyGenerateConditionRecord, ValidationResult> {
    return {
      comment: this.validateComment(),
      message: this.validateMessage(),
      reviewer: this.validateReviewer(),
      ratingType: this.validateRatingType(),
    };
  }

  validateComment(): ValidationResult {
    let error;
    if (this.comment.length > MAX_COMMENT_LENGTH) {
      error = `クチコミの内容は${MAX_COMMENT_LENGTH}文字以内にしてください`;
    }
    return error ? { isValid: false, error } : { isValid: true };
  }

  validateReviewer(): ValidationResult {
    let error;
    if (!this.reviewer) {
      error = 'クチコミの投稿者名は必須です';
    }
    return error ? { isValid: false, error } : { isValid: true };
  }

  validateRatingType(): ValidationResult {
    let error;
    if (!this.ratingType) {
      error = `返信文の種別は必須です`;
    } else if (!['good', 'bad'].includes(this.ratingType)) {
      error = '返信文の種別が不正です';
    }
    return error ? { isValid: false, error } : { isValid: true };
  }

  validateMessage(): ValidationResult {
    let error;
    if (this.message.length > MAX_MESSAGE_LENGTH) {
      error = `メッセージは${MAX_MESSAGE_LENGTH}文字以内にしてください`;
    }
    return error ? { isValid: false, error } : { isValid: true };
  }

  toGenerateParams(): ReviewReplyGenerateParams {
    return {
      comment: this.comment || undefined,
      reviewer: this.reviewer,
      review_type: this.reviewType,
      message: this.message || undefined,
    };
  }
}
