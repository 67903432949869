import React from 'react';

import styled from 'styled-components';

import Image from 'models/Domain/Image/Image';
import { COLOR } from 'style/color';

export const SinglePreview = React.memo<{ image: Image }>(({ image }) => {
  const { url } = image;
  return (
    <Wrapper>
      <ImagePreview src={url} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  background-color: ${COLOR.LIGHT_GRAY};
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
`;
