import React, { useCallback, useMemo } from 'react';

import { List as ImmutableList } from 'immutable';
import { Checkbox, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { DatePicker } from 'components/atoms/DatePicker';
import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { GmbTimePeriodString } from 'models/Domain/GmbLocation/GmbTimePeriodString';
import { BusinessHours, MoreHoursList, MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';
import { COLOR } from 'style/color';

type Props = {
  moreHours: MoreHoursList;
  moreHoursTypes: ImmutableList<MoreHoursType>;
  changeMoreHours: (value: MoreHoursList) => void;
};

export const EditMoreHours: React.FC<Props> = ({ moreHours, moreHoursTypes, changeMoreHours }) => {
  const availableMoreHours = useMemo(
    () =>
      moreHours.list.filter((item) => moreHoursTypes.find((hoursType) => hoursType.hoursTypeId === item.hoursTypeId)),
    [moreHours.list, moreHoursTypes],
  );

  // 指定されたその他の営業時間を追加
  const handleOnAddMoreHours = useCallback(
    (hoursType: MoreHoursType) => {
      const newMoreHours = moreHours.addMoreHours(hoursType);
      changeMoreHours(newMoreHours);
    },
    [changeMoreHours, moreHours],
  );

  // 指定されたその他の営業時間を削除
  const handleOnDeleteMoreHours = useCallback(
    (hoursType: MoreHoursType) => {
      const newMoreHours = moreHours.removeMoreHours(hoursType);
      changeMoreHours(newMoreHours);
    },
    [changeMoreHours, moreHours],
  );

  const handleOnChangeBusinessHours = useCallback(
    (hoursType: MoreHoursType, businessHours: BusinessHours) => {
      const item = moreHours.find(hoursType);
      if (!item) {
        return null;
      }
      const newMoreHours = moreHours.changeMoreHours(hoursType, item.changeBusinessHours(businessHours));
      changeMoreHours(newMoreHours);
    },
    [changeMoreHours, moreHours],
  );

  // メインカテゴリに紐づくその他の営業時間の項目が１つもない場合
  if (moreHoursTypes.isEmpty()) {
    return <Wrapper>設定可能な項目がありません</Wrapper>;
  }

  return (
    <Wrapper>
      <Label>その他の営業時間</Label>
      {availableMoreHours.map((item) => {
        // 選択可能なその他の営業時間に含まれていなければなにもしない
        const hoursType = moreHoursTypes.find((hoursType) => hoursType.hoursTypeId === item.hoursTypeId);
        if (hoursType == null) {
          return null;
        }
        const businessHours = item.businessHours;
        const { isValid, error } = businessHours.validate;
        return (
          <MoreHoursWrapper key={hoursType.hoursTypeId}>
            <TitleWrapper>
              <GroupName>{hoursType.localizedDisplayName}</GroupName>
              <DeleteButton onClick={() => handleOnDeleteMoreHours(hoursType)}>削除</DeleteButton>
            </TitleWrapper>

            {!isValid && <Error>{error}</Error>}

            {businessHours.businessHoursForEdit.map((hours, idx) => {
              return (
                <DayWrapper key={hours.name}>
                  <ListWrapper>
                    <DayOfTheWeek>{hours.name}</DayOfTheWeek>
                    <CheckboxHoliday
                      toggle
                      checked={hours.gmbTimePeriods.isOpen}
                      onChange={() =>
                        handleOnChangeBusinessHours(
                          hoursType,
                          businessHours.toggleOpenDay(hours.type, !hours.gmbTimePeriods.isOpen),
                        )
                      }
                    />
                    <LabelHoliday>{hours.gmbTimePeriods.isOpen ? '営業日' : '定休日'}</LabelHoliday>
                  </ListWrapper>
                  <TimeWrapper>
                    {hours.gmbTimePeriods.isOpen &&
                      hours.gmbTimePeriods.list.map((hour, index) => {
                        return (
                          <TimeListWrapper key={index}>
                            <StyledDatePicker
                              dateFormat={'HH:mm'}
                              timeFormat={'HH:mm'}
                              selected={hour.openTime.toDayjs?.toDate()}
                              onChange={(value: Date) =>
                                handleOnChangeBusinessHours(
                                  hoursType,
                                  businessHours.changePeriod(
                                    hours.type,
                                    index,
                                    GmbTimePeriodString.fromDate(value),
                                    hour.closeTime,
                                  ),
                                )
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              placeholderText={'開始時間'}
                            />
                            <Period>ー</Period>
                            <StyledDatePicker
                              dateFormat={'HH:mm'}
                              timeFormat={'HH:mm'}
                              selected={hour.closeTime.toDayjs?.toDate()}
                              onChange={(value: Date) =>
                                handleOnChangeBusinessHours(
                                  hoursType,
                                  businessHours.changePeriod(
                                    hours.type,
                                    index,
                                    hour.openTime,
                                    GmbTimePeriodString.fromDate(value),
                                  ),
                                )
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              placeholderText={'終了時間'}
                            />
                            {!hours.gmbTimePeriods.list.isEmpty() && (
                              <XIcon
                                onClick={() =>
                                  handleOnChangeBusinessHours(hoursType, businessHours.removePeriod(hours.type, index))
                                }
                                name='x'
                              />
                            )}
                          </TimeListWrapper>
                        );
                      })}
                  </TimeWrapper>
                  {hours.gmbTimePeriods.isOpen && (
                    <AddWrapper>
                      <AddTime
                        onClick={() => handleOnChangeBusinessHours(hoursType, businessHours.addOpenDay(hours.type))}
                      >
                        営業時間を追加
                      </AddTime>
                    </AddWrapper>
                  )}
                </DayWrapper>
              );
            })}
          </MoreHoursWrapper>
        );
      })}
      <AvailableMoreHoursWrapper>
        {!availableMoreHours.isEmpty() && <GroupName>他の営業時間を追加</GroupName>}
        <ToggleWrapper>
          {moreHoursTypes
            .filter((hoursType) => !moreHours.find(hoursType))
            .map((hoursType) => (
              <OptionalToggle
                key={hoursType.hoursTypeId}
                label={hoursType.localizedDisplayName}
                checked={undefined}
                onClick={() => handleOnAddMoreHours(hoursType)}
              />
            ))}
        </ToggleWrapper>
      </AvailableMoreHoursWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MoreHoursWrapper = styled.div`
  border-bottom: 1px solid rgba(181, 181, 181, 0.5);
  padding: 25px 0 18px;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
`;

const GroupName = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const AvailableMoreHoursWrapper = styled.div`
  margin-top: 32px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  margin-top: 16px;
`;

const CheckboxHoliday = styled(Checkbox)`
  margin-right: 8px;
`;

const DayOfTheWeek = styled.div`
  margin-right: 20px;
  align-items: center;
`;

const Period = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
  margin-bottom: auto;
`;

const DayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 0;
  margin-top: 16px;
  min-height: 32px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  gap: 8px;
`;

const TimeListWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-popper {
    z-index: 10; /* デフォルトのz-index: 1では他のコンポーネントが上に表示されてしまう */
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    width: 100px;
    padding: 0 8px;
    font-family: monospace !important;
  }
`;

const LabelHoliday = styled.label`
  display: block;
  margin-right: 16px;
`;

const AddTime = styled.div`
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
`;
const XIcon = styled(Icon)`
  &&& {
    margin-left: 16px;
    cursor: pointer;
  }
`;

const DeleteButton = styled(Button).attrs({ negative: true, priority: 'low' })``;

const Error = styled.div`
  margin: 16px 0;
  color: ${COLOR.ERROR};
`;
