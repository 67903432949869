import React from 'react';

import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

type Props = {
  store: Store;
  selected: boolean;
  storeDetailLink: string;
  onClick: () => void;
};

/**
 * 店舗一覧のSP版と店舗詳細のリストに表示する店舗情報
 */
export const StoreCard = React.memo<Props>(({ store, selected, storeDetailLink, onClick }) => {
  const {
    id,
    code,
    fullName,
    is_connected_gmb,
    location: { openInfo },
    location_state,
  } = store;
  const isGMBError = is_connected_gmb && location_state.isGmbError;
  const storeDetailUrl = storeDetailLink.replace(':storeId', String(id));
  return (
    <Wrapper checked={selected} isError={isGMBError} onClick={onClick}>
      <StatusContainer>
        <GMBLabel hasError={isGMBError} isConnected={is_connected_gmb}>
          GBP{isGMBError ? '要確認' : is_connected_gmb ? '連携中' : '未連携'}
        </GMBLabel>
        <BusinessStatus isOpen={openInfo.isOpen}>{openInfo.statusName}</BusinessStatus>
      </StatusContainer>
      <DetailLink onClick={(e) => e.stopPropagation()} to={storeDetailUrl}>
        {fullName}
      </DetailLink>
      {code && <Code>{code}</Code>}
    </Wrapper>
  );
});

const Wrapper = styled.div<{ checked: boolean; isError: boolean }>`
  padding: 8px 12px;

  background: ${({ checked, isError }) =>
    checked ? 'rgba(207, 242, 230, 0.6)' : isError ? 'rgba(247, 216, 216, 0.4)' : COLOR.WHITE};

  :hover {
    cursor: pointer;
    background: ${({ checked, isError }) =>
      checked ? 'rgba(207, 242, 230, 1)' : isError ? 'rgba(247, 216, 216, 1.0)' : 'rgba(0, 0, 0, 0.04)'};
  }

  border: 1px solid ${COLOR.LIGHT_GRAY};
  :not(:first-child) {
    margin-top: -1px;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledLabel = styled(Label)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    white-space: nowrap;
    width: 76px;
    font-weight: normal;
  }
`;

const GMBLabel = styled(StyledLabel)<{ hasError: boolean; isConnected: boolean }>`
  &&& {
    background-color: ${({ isConnected, hasError }) =>
      hasError ? COLOR.ERROR : isConnected ? COLOR.GOOGLE : COLOR.LIGHT_GRAY};
    color: ${({ isConnected, hasError }) => (isConnected || hasError ? COLOR.WHITE : '#757575')};
    width: 84px;
    font-size: 12px;
  }
`;

const Code = styled.div`
  font-size: 12px;
`;

const BusinessStatus = styled.div<{ isOpen: boolean }>`
  &&& {
    color: ${({ isOpen }) => (isOpen ? '#757575' : COLOR.PURPLE)};
    font-size: 12px;
    margin-left: 8px;
  }
`;

const DetailLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
`;
