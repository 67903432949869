import React, { useCallback, useState } from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ServiceSummary } from 'models/Domain/Service/Service';
import { COLOR } from 'style/color';

import { ServiceListEditTable } from './ServiceListEditTable';

type Props = {
  isOpen: boolean;
  serviceSummary: ServiceSummary;
  onClose: () => void;
  onChange: (items: ServiceSummary['items']) => void;
};

export const ServiceListModal = React.memo<Props>(({ isOpen, serviceSummary, onClose, onChange }) => {
  const [serviceSummaryForEdit, setServiceSummaryForEdit] = useState(serviceSummary);

  const handleOnChangeItems = useCallback((items: ServiceSummary['items']) => {
    setServiceSummaryForEdit((prev) => prev.set('items', items));
  }, []);

  const handleOnSave = useCallback(() => {
    onChange(serviceSummaryForEdit.items);
    onClose();
  }, [serviceSummaryForEdit, onChange, onClose]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title>並び替え</Title>
          <ContentWrapper>
            <Description>
              同じ名称のサービスが複数のサービスグループに設定されている場合、順番が下にあるサービスグループの内容がGBPに反映されます。
            </Description>
            <Content>
              <ServiceListEditTable serviceSummary={serviceSummaryForEdit} onChange={handleOnChangeItems} />
            </Content>
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>キャンセル</StyledButton>
            <StyledButton onClick={handleOnSave} priority={'high'} disabled={false}>
              適用
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
});

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const Description = styled.div`
  margin-bottom: 16px;
`;
