import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { createResizedImageURL } from 'components/atoms/ResizeImage';
import { ImageViewerModal } from 'components/molecules/ImageViewerModal';
import { FileContents } from 'models/Domain/FileContent';
import { COLOR } from 'style/color';

export type FileListProps = {
  className?: string;
  files: FileContents;
};

export const FileList: React.FC<FileListProps> = ({ className, files }) => {
  const [imageModalOption, setImageModalOption] = useState({ isOpen: false, index: 0 });

  const openModal = useCallback((index: number) => {
    setImageModalOption({ isOpen: true, index });
  }, []);

  const closeModal = useCallback(() => {
    setImageModalOption((state) => ({ ...state, isOpen: false }));
  }, []);

  const openPDF = useCallback((url: string) => {
    window.open(url);
  }, []);

  const pdfFiles = files.pdfFiles;
  const imageFiles = files.imageFiles;

  return (
    <>
      <Wrapper className={className}>
        <Container>
          {pdfFiles.list.map((file) => (
            <FileContainer key={file.url} onClick={() => openPDF(file.url)}>
              <PDFThumbnail data={file.url} />
              <PDFLabel>PDF</PDFLabel>
              <PDFOverlay />
            </FileContainer>
          ))}
          {imageFiles.list.map((file, index) => (
            <FileContainer key={file.url} onClick={() => openModal(index)}>
              <FileThumbnail data={createResizedImageURL(file.url, 300)} />
            </FileContainer>
          ))}
        </Container>
      </Wrapper>
      <ImageViewerModal
        open={imageModalOption.isOpen}
        initialIndex={imageModalOption.index}
        images={imageFiles}
        onClose={closeModal}
        displayCommentButton={false}
      />
    </>
  );
};

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const FileContainer = styled.div`
  position: relative;
  margin: 0 4px;
  cursor: pointer;
`;

const FileThumbnail = styled.object`
  display: block;
  min-width: 100px;
  max-width: 150px;
  height: 100px;
  object-fit: cover;
  background-color: ${COLOR.LIGHT_GRAY};
`;

const PDFThumbnail = styled.object`
  display: block;
  width: 71px;
  height: 100px;
  object-fit: cover;
  background-color: ${COLOR.LIGHT_GRAY};
`;

const PDFLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: bold;
  color: #fff;
  padding: 0 10px;
  background-color: ${COLOR.BLACK};
`;

// これを被せないとPDFはカーソルがポインタ表示にならない
const PDFOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
