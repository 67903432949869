import React, { useCallback, useEffect } from 'react';

import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { CheckBoxButton } from 'components/atoms/CheckBox';
import { PullDown } from 'components/atoms/PullDown';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { MAX_DATE } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { DataType, GmbInsightDownloadModalState } from 'models/Other/GmbInsightDownloadModalState';
import { AppActions } from 'modules/app/actions';
import { GbpInsightActions } from 'modules/gbpInsight/action';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';

export const DownloadModal: React.FC = () => {
  const dispatch = useDispatch();
  const { downloadModalState, stores, currentUser, storeLists, csvDownload } = useSelector(
    (state: State) => ({
      downloadModalState: state.gbpInsight.downloadModalState,
      stores: state.store.stores,
      storeLists: state.storeList.storeLists,
      currentUser: state.app.currentUser,
      csvDownload: state.gbpInsight.csvDownload,
    }),
    shallowEqual,
  );
  const {
    isOpen,
    selectStoreTypeValue,
    aggregate_unit: aggregateUnit,
    start_date: startDate,
    end_date: endDate,
    isValid,
  } = downloadModalState;
  const filteredStores = stores.filterByUserRole(currentUser);
  const storeOptions = downloadModalState.getStoreOptions(storeLists, filteredStores);

  const setDownloadModalState = useCallback(
    (state: GmbInsightDownloadModalState) => {
      dispatch(GbpInsightActions.setDownloadModalState(state));
    },
    [dispatch],
  );

  const onClose = useCallback(() => {
    setDownloadModalState(downloadModalState.changeIsOpen(false));
  }, [downloadModalState, setDownloadModalState]);

  const startDownload = useCallback(() => {
    dispatch(GbpInsightActions.getCsvDownload());
  }, [dispatch]);

  /**
   * 期間を変更する
   */
  const onChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      let updatedState = downloadModalState;
      if (startDate) {
        updatedState = updatedState.set('start_date', dayjs(startDate));
      }
      if (endDate) {
        updatedState = updatedState.set('end_date', dayjs(endDate));
      }
      setDownloadModalState(updatedState);
    },
    [downloadModalState, setDownloadModalState],
  );

  useEffect(() => {
    // CSVダウンロード処理の実行中、3秒おきに進捗を取得する
    if (csvDownload.executionArn) {
      const intervalId = window.setInterval(() => {
        dispatch(GbpInsightActions.checkCsvDownloadStatus(intervalId));
      }, 3000);

      // 戻るボタンなどで遷移した場合にタイマーを停止
      return () => {
        window.clearInterval(intervalId);
        // 再度ダウンロードした場合に以前のExecutionArnが参照されないように削除
        dispatch(GbpInsightActions.clearCsvDownload());
        dispatch(AppActions.setLoading(false));
      };
    }
  }, [csvDownload, dispatch]);

  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <Modal.Content>
        <Title>CSVダウンロード</Title>
        <ItemWrapper>
          <ItemLabel>店舗</ItemLabel>
          <StorePullDown
            value={selectStoreTypeValue}
            options={storeOptions}
            onChange={(v) => setDownloadModalState(downloadModalState.changeSelectedStoreType(v))}
          />
        </ItemWrapper>
        <ItemWrapper>
          <PeriodWrapper>
            <div>
              <ItemLabelWrapper>
                <ItemLabel>集計単位</ItemLabel>
              </ItemLabelWrapper>
              <UnitButtonGroup>
                <UnitButton
                  active={aggregateUnit === 'daily'}
                  onClick={() => setDownloadModalState(downloadModalState.changeAggregateUnit('daily'))}
                >
                  日
                </UnitButton>
                <UnitButton
                  active={aggregateUnit === 'weekly'}
                  onClick={() => setDownloadModalState(downloadModalState.changeAggregateUnit('weekly'))}
                >
                  週
                </UnitButton>
                <UnitButton
                  active={aggregateUnit === 'monthly'}
                  onClick={() => setDownloadModalState(downloadModalState.changeAggregateUnit('monthly'))}
                >
                  月
                </UnitButton>
              </UnitButtonGroup>
            </div>
            <div>
              <ItemLabelWrapper className='with-left-margin'>
                <ItemLabel>集計期間</ItemLabel>
              </ItemLabelWrapper>
              <RangeWrapper>
                <StyledDateRangePicker
                  startDate={startDate.toDate()}
                  endDate={endDate.toDate()}
                  maxDate={MAX_DATE.toDate()}
                  onChange={onChangeDateRange}
                  startPlaceholderText='開始日:未指定'
                  endPlaceholderText='終了日:未指定'
                  isClearable={false}
                />
              </RangeWrapper>
            </div>
          </PeriodWrapper>
        </ItemWrapper>
        <ItemWrapper>
          <ItemLabel>取得情報</ItemLabel>
          <AllSelectButton
            priority='low'
            onClick={() => setDownloadModalState(downloadModalState.toggleDataTypeAllSelect())}
          >
            {downloadModalState.isDataTypeAllSelected ? 'すべて解除する' : 'すべて選択する'}
          </AllSelectButton>
          <DataTypeList>
            <DataTypeCheckBox
              dataType='store_name'
              text='店舗名'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <DataTypeCheckBox
              dataType='store_id'
              text='店舗ID'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <Divider />
            <DataTypeCheckBox
              dataType='QUERIES_DIRECT'
              text='直接検索'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <DataTypeCheckBox
              dataType='QUERIES_INDIRECT'
              text='間接検索'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <DataTypeCheckBox
              dataType='QUERIES_CHAIN'
              text='ブランド検索'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <Divider />
            <DataTypeCheckBox
              dataType='ACTIONS_WEBSITE'
              text='ウェブサイトへのアクセス'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <DataTypeCheckBox
              dataType='ACTIONS_DRIVING_DIRECTIONS'
              text='ルートの検索'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <DataTypeCheckBox
              dataType='ACTIONS_PHONE'
              text='電話をかける'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <Divider />
            <DataTypeCheckBox
              dataType='MEDIA_POST_COUNT'
              text='画像掲載数'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <DataTypeCheckBox
              dataType='MEDIA_VIEW_COUNT'
              text='画像閲覧数'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <Divider />
            <DataTypeCheckBox
              dataType='RATE_COUNT'
              text='クチコミ数（評価のみ）'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
            <DataTypeCheckBox
              dataType='COMMENT_COUNT'
              text='クチコミ数（コメントあり）'
              state={downloadModalState}
              setDownloadModalState={setDownloadModalState}
            />
          </DataTypeList>
        </ItemWrapper>
        <ButtonWrapper>
          <CancelButton onClick={() => onClose()}>キャンセル</CancelButton>
          <DownloadButton
            priority='high'
            disabled={!isValid}
            onClick={() => {
              startDownload();
            }}
          >
            ダウンロード
          </DownloadButton>
        </ButtonWrapper>
      </Modal.Content>
    </Modal>
  );
};

const DataTypeCheckBox: React.FC<{
  dataType: DataType;
  text: string;
  state: GmbInsightDownloadModalState;
  setDownloadModalState: (v: GmbInsightDownloadModalState) => void;
}> = ({ dataType, text, state, setDownloadModalState }) => {
  return (
    <DataTypeCheckBoxWrapper>
      <CustomCheckBox
        id={dataType}
        checked={state.includekDataType(dataType)}
        text={text}
        onClick={() => setDownloadModalState(state.toggleDataType(dataType))}
      />
    </DataTypeCheckBoxWrapper>
  );
};

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const ItemWrapper = styled.div`
  margin-top: 24px;
`;

const ItemLabelWrapper = styled.div`
  margin-bottom: 14px;

  &.with-left-margin {
    margin: 0 24px 14px;
  }
`;

const ItemLabel = styled.label`
  font-size: 14px;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const CancelButton = styled(Button)``;

const DownloadButton = styled(Button)`
  margin-left: 16px;
`;

const StorePullDown = styled(PullDown)`
  margin-top: 14px;
`;

const DataTypeList = styled.div`
  margin-top: 14px;
`;

const DataTypeCheckBoxWrapper = styled.div`
  display: inline-block;
  margin-right: 16px;
`;

const CustomCheckBox = styled(CheckBoxButton)`
  &&& {
    border: none;
    font-size: 14px;
    font-weight: bold;
  }
`;

const Divider = styled.hr`
  border-top: 1px solid ${COLOR.LIGHT_GRAY};
  border-bottom: none;
`;

const AllSelectButton = styled(Button)`
  margin-left: 24px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const UnitButtonGroup = styled(SemanticButton.Group)``;

const UnitButton = styled(SemanticButton)`
  &&& {
    background-color: ${COLOR.WHITE};
    color: ${COLOR.GRAY};
    border: 1px solid ${COLOR.GRAY} !important;
    font-size: 18px;
    height: 56px;
    &.active {
      background-color: ${COLOR.GREEN};
      color: ${COLOR.WHITE};
      border-color: ${COLOR.GREEN} !important;
    }
  }
`;

const RangeWrapper = styled.div`
  display: inline-block;
  margin-left: 24px;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  &&& {
    & input {
      height: 57px;
      font-size: 16px;
    }
  }
`;
