import { State } from 'modules/reducers';

export const MapCompetitorResearchSelectors = {
  selectState: (state: State) => state.mapCompetitorResearch,
  selectSearchCondition: (state: State) => state.mapCompetitorResearch.searchCondition,
  selectIsLoadingResult: (state: State) => state.mapCompetitorResearch.isLoadingResult,
  selectExecutionArn: (state: State) => state.mapCompetitorResearch.executionArn,
  selectResultStatus: (state: State) => state.mapCompetitorResearch.resultStatus,
  selectResultDetails: (state: State) => state.mapCompetitorResearch.resultDetails,
  selectCompetitors: (state: State) => state.mapCompetitorResearch.competitors,
};
