import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { GmbAttributeMetadata, GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttribute, GmbAttributes } from 'models/Domain/GmbLocation/GmbAttributes';

export type AttributeListProps = {
  attributes: GmbAttributes;
  attributeMetadatas: GmbAttributeMetadatas;
  categoryName: string;
};

type AttributePair = [GmbAttribute, GmbAttributeMetadata];
export const CellAttributes: React.FC<AttributeListProps> = React.memo(
  ({ attributes, attributeMetadatas, categoryName }) => {
    if (!attributeMetadatas) {
      return <></>;
    }
    const targetPair = attributeMetadatas.list
      .map((attributeMetadata) => [attributes.find(attributeMetadata.attributeId), attributeMetadata] as AttributePair)
      .filter(([attribute]) => attribute && attribute.hasValue() && !attribute.isRepeatedEnum) as List<AttributePair>;
    const displayPair = targetPair.filter((_, idx) => idx < 2) as List<AttributePair>;
    return (
      <Wrapper>
        {categoryName && <div>メインカテゴリー：{categoryName}</div>}
        {displayPair.map(([attribute, attributeMetadata], idx) => {
          switch (attributeMetadata.valueType) {
            case 'ENUM':
              return (
                <React.Fragment key={attribute.attributeId}>
                  <AttributeValueWrapper>
                    <AttributeValue>
                      {attributeMetadata.findValueMetadata(attribute.values.get(0) as string)?.displayName}
                    </AttributeValue>
                    {targetPair.size > 2 && idx === 1 && <AttribitesCount>＋{targetPair.size - 2}</AttribitesCount>}
                  </AttributeValueWrapper>
                </React.Fragment>
              );
            case 'BOOL':
              return (
                <React.Fragment key={attribute.attributeId}>
                  <AttributeValueWrapper>
                    <AttributeValue>{attributeMetadata.displayName}</AttributeValue>
                    {targetPair.size > 2 && idx === 1 && <AttribitesCount>＋{targetPair.size - 2}</AttribitesCount>}
                  </AttributeValueWrapper>
                </React.Fragment>
              );
            default:
              return <React.Fragment key={attribute.attributeId} />;
          }
        })}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const AttributeValue = styled.div`
  background: #f2f2f2;
  border-radius: 50px;
  padding: 4px 10px;
`;

const AttributeValueWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 4px;
`;

const AttribitesCount = styled.div`
  margin-left: 6px;
  background: #5e5e5e;
  border-radius: 50px;
  padding: 4px 10px;
  color: white;
`;
