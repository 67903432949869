import { List, Record, Set, is } from 'immutable';

import ErrorType from 'helpers/errorType';
import { validateWebsiteUrl } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { GmbAttributeMetadatas } from '../GmbAttributeMetadatas';

export type UrlValuesType = List<{
  url: string;
}>;

const ATTRIBUTE_SNS_URL_TYPES = [
  'url_facebook',
  'url_instagram',
  'url_linkedin',
  'url_pinterest',
  'url_tiktok',
  'url_twitter',
  'url_youtube',
] as const;

export type AttributeSnsUrlType = (typeof ATTRIBUTE_SNS_URL_TYPES)[number];

export class GmbAttribute extends Record<{
  attributeId: string;
  valueType: 'ATTRIBUTE_VALUE_TYPE_UNSPECIFIED' | 'BOOL' | 'ENUM' | 'REPEATED_ENUM';
  values: List<string | boolean>;
  repeatedEnumValue: {
    setValues: List<string>;
    unsetValues: List<string>;
  };
}>({
  attributeId: '',
  valueType: 'ATTRIBUTE_VALUE_TYPE_UNSPECIFIED',
  values: List(),
  repeatedEnumValue: {
    setValues: List(),
    unsetValues: List(),
  },
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    params.values = List(params.values || []);
    params.repeatedEnumValue = {
      setValues: List(params.repeatedEnumValue?.setValues || []),
      unsetValues: List(params.repeatedEnumValue?.unsetValues || []),
    };

    return new GmbAttribute(params);
  }

  get isRepeatedEnum() {
    return this.valueType === 'REPEATED_ENUM';
  }

  toggleBoolAttribute() {
    switch (this.values.get(0)) {
      case true:
        return this.set('values', List([false]));
      case false:
        return this.set('values', List([]));
      default:
        return this.set('values', List([true]));
    }
  }

  toggleEnumAttribute(value: string | boolean) {
    if (this.values.find((v) => v === value)) {
      return this.set('values', List());
    }
    return this.set('values', List([value]));
  }

  toggleRepeatedEnumAttribute(value: string) {
    const setValues = this.repeatedEnumValue.setValues.find((v) => v === value);
    const unsetValues = this.repeatedEnumValue.unsetValues.find((v) => v === value);
    if (setValues) {
      return this.set('repeatedEnumValue', {
        setValues: this.repeatedEnumValue.setValues.filter((v) => v !== value),
        unsetValues: this.repeatedEnumValue.unsetValues.push(value),
      });
    } else if (unsetValues) {
      return this.set('repeatedEnumValue', {
        setValues: this.repeatedEnumValue.setValues,
        unsetValues: this.repeatedEnumValue.unsetValues.filter((v) => v !== value),
      });
    }
    return this.set('repeatedEnumValue', {
      setValues: this.repeatedEnumValue.setValues.push(value),
      unsetValues: this.repeatedEnumValue.unsetValues,
    });
  }

  getRepeatedEnumValue(value: string | boolean) {
    const setValues = this.repeatedEnumValue.setValues.find((v) => v === value);
    const unsetValues = this.repeatedEnumValue.unsetValues.find((v) => v === value);
    if (setValues) {
      return true;
    } else if (unsetValues) {
      return false;
    }
    return;
  }

  /**
   * 値を持つかを返す
   */
  hasValue() {
    switch (this.valueType) {
      case 'BOOL':
      case 'ENUM':
        return !this.values.isEmpty();
      case 'REPEATED_ENUM':
        return !this.repeatedEnumValue.setValues.isEmpty() || !this.repeatedEnumValue.unsetValues.isEmpty();
      default:
        return false;
    }
  }

  get stringValues() {
    return this.values.map((v) => v.toString());
  }

  get booleanValues() {
    return this.values.map((v) => !!v);
  }

  static newAttribute(attributeId: string, valueType: 'BOOL' | 'ENUM' | 'REPEATED_ENUM') {
    return new GmbAttribute({ attributeId, valueType });
  }

  is(other: GmbAttribute) {
    if (this.valueType !== other.valueType) {
      return false;
    }

    if (this.attributeId !== other.attributeId) {
      return false;
    }

    if (this.valueType === 'BOOL' || this.valueType === 'ENUM') {
      return is(Set(this.values), Set(other.values));
    } else if (this.valueType === 'REPEATED_ENUM') {
      return (
        is(Set(this.repeatedEnumValue.setValues), Set(other.repeatedEnumValue.setValues)) &&
        is(Set(this.repeatedEnumValue.unsetValues), Set(other.repeatedEnumValue.unsetValues))
      );
    }
  }

  /**
   * Repetead Enumの値を名前順にソートし、set/unsetの値を含めて返す
   * @returns [Repetead Enumの値を名前, 値がsetの場合true/unsetの場合false]
   */
  getSortedRepeatedEnumValues() {
    return [
      ...this.repeatedEnumValue.setValues.map((value) => [value, true] as const).toArray(),
      ...this.repeatedEnumValue.unsetValues.map((value) => [value, false] as const).toArray(),
    ].sort((a, b) => (b[0] === a[0] ? 0 : b[0] < a[0] ? 1 : -1));
  }
}

export class GmbAttributes extends Record<{
  list: List<GmbAttribute>;
}>({
  list: List(),
}) {
  static fromJSON(data: JSObject[] = []) {
    const list = List(data && data.filter((p) => p.valueType !== 'URL').map((p) => GmbAttribute.fromJSON(p)));
    return new GmbAttributes({ list });
  }

  setAttributes(attributes: List<GmbAttribute>) {
    return this.set('list', attributes);
  }

  validate() {
    const errors = {
      list: this.validateAttributes(),
    };
    return errors;
  }

  validateAttributes() {
    // 現状バリデーションする内容がない
    return { isValid: true, error: '' };
  }

  /**
   * 指定したIDの属性を取得する
   * @param attributeId 属性のID
   * @returns
   */
  getAttribute(attributeId: string) {
    return this.list.find((target) => target.attributeId === attributeId);
  }

  /**
   * 指定したIDの属性を更新する、存在しない場合は追加する
   * @param attributeId 属性のID
   * @param value 更新・追加する属性
   * @returns
   */
  setAttribute(attributeId: string, value: GmbAttribute) {
    let list: List<GmbAttribute>;
    if (this.find(attributeId)) {
      list = this.list.map((target) => (target.attributeId === attributeId ? value : target));
    } else {
      list = this.list.push(value);
    }
    return this.set('list', list);
  }

  /**
   * 指定したIDの属性を削除する
   * @param attributeId 属性のID
   * @returns
   */
  removeAttribute(attributeId: string) {
    return this.update('list', (list) => list.filter((target) => target.attributeId !== attributeId));
  }

  toggleBoolAttribute(attributeId: string) {
    return this.update('list', (list) =>
      list.map((target) => (target.attributeId === attributeId ? target.toggleBoolAttribute() : target)),
    );
  }

  toggleEnumAttribute(attributeId: string, value: string | boolean) {
    return this.update('list', (list) =>
      list.map((target) => (target.attributeId === attributeId ? target.toggleEnumAttribute(value) : target)),
    );
  }

  toggleRepeatedEnumAttribute(attributeId: string, value: string | boolean) {
    return this.update('list', (list) =>
      list.map((target) =>
        target.attributeId === attributeId ? target.toggleRepeatedEnumAttribute(value.toString()) : target,
      ),
    );
  }

  /**
   * 更新用のパラメータ
   */
  get updateParams() {
    return this.list.filter((value) => value.hasValue()).toJS();
  }

  find(attributeId: string) {
    return this.list.find((attribute) => attribute.attributeId === attributeId);
  }

  /**
   * 属性メタ情報を元に、空の値も含めて属性データを補完する
   * @param attributeMetadatas 属性メタデータ
   * @returns 空の値も含む属性データ
   */
  complement(attributeMetadatas: GmbAttributeMetadatas) {
    const attributesMap = this.list.reduce<{ [key: string]: GmbAttribute }>(
      (current, attribute) => ({ ...current, [attribute.attributeId]: attribute }),
      {},
    );
    const complementedAttributes = attributeMetadatas
      .getListWithoutIsDeprecatedAndUrl() // ここでvalueType: 'URL'は除かれる
      .map(
        (attributeMetadata) =>
          attributesMap[attributeMetadata.attributeId] ||
          GmbAttribute.newAttribute(
            attributeMetadata.attributeId,
            attributeMetadata.valueType as 'BOOL' | 'ENUM' | 'REPEATED_ENUM',
          ),
      );
    return this.set('list', complementedAttributes);
  }

  /**
   * 値を持たない属性を取り除く
   * @returns 値を持つ属性のみを含むGmbAttributes
   */
  removeNoValueAttributes() {
    return this.update('list', (list) => list.filter((attribute) => attribute.hasValue()));
  }
}

export class GmbUrlAttribute extends Record<{
  attributeId: string;
  valueType: 'ATTRIBUTE_VALUE_TYPE_UNSPECIFIED' | 'URL';
  urlValues: UrlValuesType;
}>({
  attributeId: '',
  valueType: 'ATTRIBUTE_VALUE_TYPE_UNSPECIFIED',
  urlValues: List(),
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    params.urlValues = List(params.urlValues || []);
    return new GmbUrlAttribute(params);
  }

  getUrlError(idx: number) {
    const validResult = {
      isValid: true,
      error: '',
    };
    const url = this.urlValues.get(idx);
    if (!url || !url.url) {
      return validResult;
    }

    if (!validateWebsiteUrl(url.url)) {
      return {
        isValid: false,
        error: ErrorType.WEBSITE_URL_ERROR,
      };
    }

    if (
      ATTRIBUTE_SNS_URL_TYPES.includes(this.attributeId as any) &&
      !validateAttributeSnsUrl(url.url, this.attributeId as AttributeSnsUrlType)
    ) {
      return {
        isValid: false,
        error:
          (ErrorType as any)[`ATTRIBUTE_SNS_${this.attributeId.toUpperCase()}_ERROR`] ?? ErrorType.WEBSITE_URL_ERROR,
      };
    }
    return validResult;
  }

  getUrlListError() {
    const hasUrlError =
      this.urlValues.find((url) => {
        if (!url.url) {
          return false;
        }
        if (!validateWebsiteUrl(url.url)) {
          return true;
        }
        if (
          ATTRIBUTE_SNS_URL_TYPES.includes(this.attributeId as any) &&
          !validateAttributeSnsUrl(url.url, this.attributeId as AttributeSnsUrlType)
        ) {
          return true;
        }
        return false;
      }) !== undefined;
    return hasUrlError
      ? {
          isValid: false,
          error: ErrorType.WEBSITE_URL_ERROR,
        }
      : {
          isValid: true,
          error: '',
        };
  }

  updateUrlAttribute(targetIdx: number, value: string) {
    return this.update('urlValues', (urlValues) =>
      urlValues.map((urlValue, idx) => (idx === targetIdx ? { url: value } : urlValue)),
    );
  }

  addUrl() {
    return this.update('urlValues', (urlValues) => urlValues.push({ url: '' }));
  }

  removeUrl(targetIdx: number) {
    return this.update('urlValues', (urlValues) => urlValues.filter((target, idx) => idx !== targetIdx));
  }

  /**
   * 値を持つかを返す
   */
  hasValue() {
    return !this.urlValues.filter((value) => value.url).isEmpty();
  }

  static newUrlAttribute(attributeId: string) {
    return new GmbUrlAttribute({ attributeId, valueType: 'URL', urlValues: List([{ url: '' }]) });
  }

  is(other: GmbUrlAttribute) {
    if (this.valueType !== other.valueType) {
      return false;
    }
    return is(
      Set(this.urlValues.map((urlValue) => urlValue.url)),
      Set(other.urlValues).map((urlValue) => urlValue.url),
    );
  }
}

export class GmbUrlAttributes extends Record<{
  list: List<GmbUrlAttribute>;
}>({
  list: List(),
}) {
  static fromJSON(data: JSObject[] = []) {
    const list = List(data && data.filter((p) => p.valueType === 'URL').map((p) => GmbUrlAttribute.fromJSON(p)));
    return new GmbUrlAttributes({ list });
  }

  setAttributes(attributes: List<GmbUrlAttribute>) {
    return this.set('list', attributes);
  }

  validate() {
    const errors = {
      list: this.validateAttributes(),
    };
    return errors;
  }

  validateAttributes() {
    const errorUrl = this.list.filter((v) => !v.getUrlListError().isValid);
    return errorUrl.size > 0 ? { isValid: false, error: '' } : { isValid: true, error: '' };
  }

  /**
   * 指定したIDのURL属性を取得する
   * @param attributeId URL属性のID
   * @returns
   */
  getUrlAttribute(attributeId: string) {
    return this.list.find((target) => target.attributeId === attributeId);
  }

  /**
   * 指定したIDのURL属性を更新する、存在しない場合は追加する
   * @param attributeId URL属性のID
   * @param value 更新・追加するURL属性
   * @returns
   */
  setUrlAttribute(attributeId: string, value: GmbUrlAttribute) {
    let list: List<GmbUrlAttribute>;
    if (this.find(attributeId)) {
      list = this.list.map((target) => (target.attributeId === attributeId ? value : target));
    } else {
      list = this.list.push(value);
    }
    return this.set('list', list);
  }

  /**
   * 指定したIDのURL属性を削除する
   * @param attributeId URL属性のID
   * @returns
   */
  removeUrlAttribute(attributeId: string) {
    return this.update('list', (list) => list.filter((target) => target.attributeId !== attributeId));
  }

  updateUrlAttribute(attributeId: string, idx: number, value: string) {
    return this.update('list', (list) =>
      list.map((target) => (target.attributeId === attributeId ? target.updateUrlAttribute(idx, value) : target)),
    );
  }

  addUrl(attributeId: string) {
    return this.update('list', (list) =>
      list.map((target) => (target.attributeId === attributeId ? target.addUrl() : target)),
    );
  }

  removeUrl(attributeId: string, idx: number) {
    return this.update('list', (list) =>
      list.map((target) => (target.attributeId === attributeId ? target.removeUrl(idx) : target)),
    );
  }

  /**
   * 更新用のパラメータ
   */
  get updateParams() {
    return this.list.filter((value) => value.hasValue()).toJS();
  }

  find(attributeId: string) {
    return this.list.find((attribute) => attribute.attributeId === attributeId);
  }

  /**
   * 属性メタ情報を元に、空の値も含めてURL属性データを補完する
   * @param attributeMetadatas 属性メタデータ
   * @returns 空の値も含むURL属性データ
   */
  complement(attributeMetadatas: GmbAttributeMetadatas) {
    const urlAttributesMap = this.list.reduce<{ [key: string]: GmbUrlAttribute }>(
      (current, attribute) => ({ ...current, [attribute.attributeId]: attribute }),
      {},
    );
    const complementedAttributes = attributeMetadatas
      .getListWithoutIsDeprecatedOtherThanUrl()
      .map(
        (attributeMetadata) =>
          urlAttributesMap[attributeMetadata.attributeId] ||
          GmbUrlAttribute.newUrlAttribute(attributeMetadata.attributeId),
      );
    return this.set('list', complementedAttributes);
  }

  /**
   * 値を持たない属性を取り除く
   * @returns 値を持つ属性のみを含むGmbUrlAttributes
   */
  removeNoValueAttributes() {
    return this.update('list', (list) => list.filter((attribute) => attribute.hasValue()));
  }

  /**
   * URL属性が存在するか
   */
  hasUrlAttribute() {
    return !!this.list.find((attribute) => attribute.valueType === 'URL');
  }

  /**
   * 予約リンクの属性の値を取得する
   */
  getReservationLinkValues() {
    const urlAppointment = this.find('url_appointment')
      ?.urlValues.map((url) => url.url)
      .toArray();
    const urlReservations = this.find('url_reservations')
      ?.urlValues.map((url) => url.url)
      .toArray();
    return [...(urlAppointment || []), ...(urlReservations || [])];
  }

  /**
   * 予約リンクの属性が存在するか
   */
  hasReservationLinkAttribute() {
    return this.getReservationLinkValues().length > 0;
  }

  /**
   * 予約リンクの属性の値が複数存在するか
   */
  hasMultipleReservationLinkAttribute() {
    return this.getReservationLinkValues().length > 1;
  }
}

/**
 * ２つの属性が同じ値を持つかを返す
 * @returns
 */
export const isSameAttribute = (
  a: GmbAttribute | GmbUrlAttribute | undefined,
  b: GmbAttribute | GmbUrlAttribute | undefined,
) => {
  if (a === b) {
    return true;
  }

  if (a === undefined || b === undefined) {
    return false;
  }

  if (a.valueType !== b.valueType) {
    return false;
  } else if (a.valueType === 'URL') {
    return a.is(b as GmbUrlAttribute);
  } else if (a.valueType === 'BOOL' || a.valueType === 'ENUM' || a.valueType === 'REPEATED_ENUM') {
    return a.is(b as GmbAttribute);
  }
  return false;
};

/**
 * ２つの属性リストが持つ属性値のうち、差分のある属性のIDを返す
 * @param a
 * @param b
 * @returns
 */
export const getDiffAttributes = <T extends GmbAttributes | GmbUrlAttributes>(a: T, b: T) => {
  return (
    // どちらかのロケーションに含まれている属性IDをまとめる
    List([...a.list.toArray(), ...b.list.toArray()])
      .map((attribute) => attribute.attributeId)
      .toSet()
      // その属性IDについて値を比較する
      .filter((attributeId) => !isSameAttribute(a.find(attributeId), b.find(attributeId)))
      .toSet()
  );
};

export const validateAttributeSnsUrl = (url: string, type: AttributeSnsUrlType) => {
  // ソーシャル メディアのリンクの形式
  // https://support.google.com/business/answer/13580646?sjid=10890649764224931379-AP

  if (type === 'url_facebook') {
    // ユーザーネームに使用できる文字は、英数字(a～z、0～9)とピリオド(「.」)のみ
    // ユーザーネームは5文字以上とします
    // ピリオド(「.」)や、大文字と小文字の違いによってユーザーネームを区別することはできません。例えば、johnsmith55、John.Smith55、john.smith.55はすべて同じユーザーネームとみなされます。
    // 参考 https://www.facebook.com/help/1740158369563165
    const pattern = new RegExp(/^https:\/\/(?:www\.)?facebook\.com\/([^\\/]+)$/);
    const match = url.match(pattern);
    if (!match) {
      return false;
    }
    const userName = match.at(1)?.replaceAll('.', '') ?? ''; // ピリオドは区別に利用されないので除外する
    const pattern2 = new RegExp(/^[0-9a-zA-Z\\.]{5,}$/);
    return pattern2.test(userName);
  } else if (type === 'url_instagram') {
    // 半角英数字とピリオド（.）、アンダースコア(_)
    // 最大30文字まで
    // ※「.（ピリオド）」は先頭と最後につけられない.
    // 参考 https://www.catasumisns.com/basiciinfo/instagramusername
    const pattern = new RegExp(/^https:\/\/(?:www\.)?instagram\.com\/(?:\w|\w[\w\\.]{0,28}\w)\/?$/);
    return pattern.test(url);
  } else if (type === 'url_linkedin') {
    // カスタムURLの長さは3～100文字で、スペース、記号、特殊文字、LinkedInという語を含めることはできません。
    // 参考 https://www.linkedin.com/help/linkedin/answer/a542685/manage-your-public-profile-url
    const pattern = new RegExp(/^https:\/\/(?:www\.)?linkedin\.com\/(?:in|company)\/[0-9a-zA-Z]{3,100}$/);
    return pattern.test(url);
  } else if (type === 'url_pinterest') {
    // ・文字のみの名前、あるいは文字、数字、下線を組み合わせた名前
    // ・3～30 文字
    // ・数字だけにすることはできない
    // 参考 https://help.pinterest.com/ja/article/edit-your-profile
    const pattern = new RegExp(/^https:\/\/(?:www\.)?pinterest\.(?:com|jp)\/\w{3,30}\/?$/);
    const match = url.match(pattern);
    if (!match) {
      return false;
    }
    const userName = match.at(1) ?? ''; // ピリオドは区別に利用されないので除外する
    const pattern2 = new RegExp(/\d+$/); // 数字だけのパターン
    return !pattern2.test(userName);
  } else if (type === 'url_tiktok') {
    // 文字、数字、アンダースコア、ピリオドのみ
    // ピリオドをユーザー名の末尾に使用することはできない
    // 参考 https://support.tiktok.com/ja/getting-started/setting-up-your-profile/changing-your-username
    const pattern = new RegExp(/^https:\/\/(?:www\.)?tiktok\.com\/@[\w\\.]+\w$/);
    return pattern.test(url);
  } else if (type === 'url_twitter') {
    // ユーザー名の長さは15文字まで
    // 英数字（文字A～Z、数字0～9）とアンダースコア（_）を利用可能
    // 参考 https://help.twitter.com/en/managing-your-account/x-username-rules
    const pattern = new RegExp(/^https:\/\/(?:www\.)?(?:twitter|x)\.com\/\w{1,15}$/);
    return pattern.test(url);
  } else if (type === 'url_youtube') {
    // 3～30 文字
    // 英数字（A～Z、a～z、1～9）で構成されていること
    // ハンドルには、アンダースコア（_）、ハイフン（-）、ピリオド（.）も使用可能
    // 参考 https://support.google.com/youtube/answer/11585688?sjid=8529101755235285746-AP
    const pattern = new RegExp(/^https:\/\/(?:www\.)?youtube\.com\/(?:channel\/|user\/|@)[\w\\.-]{3,30}$/);
    return pattern.test(url);
  }

  return false;
};
