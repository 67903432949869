import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyFooter } from 'components/atoms/StickyFooter';
import { COLOR } from 'style/color';

type Props = {
  editedProducts: number;
  onCancel: () => void;
  onSave: () => void;
  hasError: boolean;
};

/**
 * 在庫情報編集時に表示されるフッター
 */
export const InventoryListFooter = React.memo<Props>(({ editedProducts, onCancel, onSave, hasError }) => {
  return (
    <StyledStickyFooter>
      <TextWrapper>
        <Label>在庫情報を更新する商品数：{editedProducts}件</Label>
        {hasError && <Error>※エラーがあるため保存できません</Error>}
      </TextWrapper>
      <ButtonWrapper>
        <CustomButton onClick={onCancel}>キャンセル</CustomButton>
        <CustomButton onClick={onSave} priority={'high'} disabled={hasError}>
          保存
        </CustomButton>
      </ButtonWrapper>
    </StyledStickyFooter>
  );
});

const StyledStickyFooter = styled(StickyFooter)`
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 320px; /* メニューの文は空ける */
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.2));
  z-index: 20; /* テーブルヘッダー(15)より上に表示 */

  @media (max-width: 600px) {
    left: 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-size: 16px;
`;

const Error = styled.div`
  margin-left: 32px;
  color: ${COLOR.ERROR};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0;
`;

const CustomButton = styled(Button)`
  &&& {
    font-weight: bold;
    cursor: pointer;
    width: auto;
    font-size: 16px;
    margin-right: 11px;
    border-radius: 8px;
    padding: 12px 16px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &:before {
    white-space: pre;
  }
`;
