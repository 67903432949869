import React from 'react';

import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Offer, OfferType } from 'models/Domain/Offer';
import { COLOR } from 'style/color';

export const OfferTypeIcon: React.FC<{ className?: string; offer_type: OfferType }> = ({ className, offer_type }) => {
  let content = null;
  switch (offer_type) {
    case 'task':
      content = <CheckIcon className={className} name='check' />;
      break;
    case 'report':
      content = <ReportIcon className={className} name='bullhorn' />;
      break;
    case 'other_offer':
      content = <OtherIcon className={className} name='circle' />;
      break;
    default:
      return null;
  }
  return <OfferTypeIconWrapper>{content}</OfferTypeIconWrapper>;
};

const OfferTypeIconWrapper = styled.div`
  display: inline-block;
`;

const IconCommonCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR.WHITE};
  height: 24px;
  width: 24px;
  line-height: 1;
  border-radius: 4px;
  flex: none;
  margin-right: 0;
`;

const CheckIcon = styled(Icon)`
  &&& {
    ${IconCommonCss};
    background-color: ${COLOR.GREEN};
  }
`;

const ReportIcon = styled(Icon)`
  &&& {
    ${IconCommonCss};
    background-color: ${COLOR.LIGHT_RED};
  }
`;

const OtherIcon = styled(Icon)`
  &&& {
    ${IconCommonCss};
    background-color: ${COLOR.GRAY};
  }
`;

export const OfferTypeLabel: React.FC<{ className?: string; offer: Offer }> = ({ className, offer }) => {
  return (
    <OfferTypeLabelWrapper className={className}>
      <OfferTypeLabelContent>
        <OfferTypeIcon offer_type={offer.offer_type} />
        <OfferTypeText>{offer.OfferTypeLabel}</OfferTypeText>
      </OfferTypeLabelContent>
    </OfferTypeLabelWrapper>
  );
};

const OfferTypeLabelWrapper = styled.div`
  display: inline-block;
`;

const OfferTypeLabelContent = styled.div`
  display: flex;
  align-items: center;
`;

const OfferTypeText = styled.div`
  font-weight: bold;
  margin-left: 4px;
`;
