import React, { useCallback } from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import Grid from 'components/atoms/Grid';
import GridArea from 'components/atoms/GridArea';
import { PopupIcon } from 'components/molecules/PopupIcon';
import { StatsCard } from 'components/pageComponents/OmoInsight/StatsCard';
import { useDisplayType } from 'hooks/useDisplayType';
import { useStorage } from 'hooks/useStorage';
import { Program } from 'models/Domain/OmoInsight';
import { CompositeOmoInsightSummaryItem } from 'models/Domain/OmoInsight/InsightSummary';
import { COLOR } from 'style/color';

import { TransitionCard } from './TransitionRateCard';

type Props = {
  className?: string;
  insight: CompositeOmoInsightSummaryItem;
  activePrograms: Program[];
};

const HELP_MESSAGE_ADS_IMPRESSIONS = 'Google検索またはGoogleショッピングで商品が表示された回数';
const HELP_MESSAGE_ADS_CLICKS = 'Google検索またはGoogleショッピングで商品がクリックされた回数';
const HELP_MESSAGE_ADS_SESSIONS =
  'Google検索またはGoogleショッピングで表示された商品情報経由でECの商品ページに流入したセッション数。\n\n計測ロジックがGoogleショッピング側と異なるため、若干差異が生じる場合があります';
const HELP_MESSAGE_ADS_CONVERSIONS =
  'Google検索またはGoogleショッピングで表示された商品情報経由でECの商品ページに流入し、コンバージョンした件数';
const HELP_MESSAGE_ADS_REVENUES =
  'Google検索またはGoogleショッピングで表示された商品情報経由でECの商品ページに流入し、コンバージョンにより発生した収益';
const HELP_MESSAGE_FREE_IMPRESSIONS = 'Googleショッピングで商品が表示された回数';
const HELP_MESSAGE_FREE_CLICKS = 'Googleショッピングで商品がクリックされた回数';
const HELP_MESSAGE_FREE_SESSIONS =
  'Googleショッピング経由でECの商品ページに流入したセッション数。\n\n計測ロジックがGoogleショッピング側と異なるため、若干差異が生じる場合があります';
const HELP_MESSAGE_FREE_CONVERSIONS = 'Googleショッピング経由でECの商品ページに流入し、コンバージョンした件数';
const HELP_MESSAGE_FREE_REVENUES = 'Googleショッピング経由でECの商品ページに流入し、コンバージョンにより発生した収益';

export const InsightSummary = React.memo<Props>(({ className, insight, activePrograms }) => {
  const { current, diff, transitionRateDiff, merchantId } = insight;
  const { transitionRates } = current;

  const [displayTrafficsSetting, setDisplayTrafficsSetting] = useStorage<{ [key: string]: boolean }>(
    'OMO_INSIGHT_DISPLAY_TRAFFICS_SETTING',
    {},
  );
  const displayAdsTraffic = displayTrafficsSetting[`${merchantId}_ads`] ?? true;
  const displayFreeTraffic = displayTrafficsSetting[`${merchantId}_free`] ?? true;
  const toggleDisplayTrafficsSetting = useCallback(
    (type: 'ads' | 'free') => {
      setDisplayTrafficsSetting({
        ...displayTrafficsSetting,
        [`${merchantId}_${type}`]: type === 'ads' ? !displayAdsTraffic : !displayFreeTraffic,
      });
    },
    [setDisplayTrafficsSetting, displayTrafficsSetting, merchantId, displayAdsTraffic, displayFreeTraffic],
  );

  // 指定期間のデータが存在するか、現在有効な掲載プログラムのデータのみ表示する
  // (現在は無効な掲載プログラムでも、過去のデータは閲覧できるようにするため)
  // 有料トラフィックはショッピング広告またはローカル在庫広告が有効の場合に表示する
  // 無料トラフィックは無料トラフィックまたは無料ローカル商品リスティングが有効の場合に表示する
  const showAdsTraffic =
    current.gmc.shopping_ads.hasValue ||
    activePrograms.includes('shopping_ads') ||
    activePrograms.includes('local_inventory_ads');
  const showFreeListings = current.gmc.free_listings.hasValue || activePrograms.includes('free_listings');
  const showFreeLocalListings =
    current.gmc.free_local_listings.hasValue || activePrograms.includes('free_local_listings');
  const showFreeTraffic = showFreeListings || showFreeLocalListings;

  const isDesktop = useDisplayType('1100px').isDesktop;

  // PC表示・SP表示のGridの設定
  const LABEL_HEIGHT = '20px';
  const CARD_HEIGHT = 'auto';

  // 有料トラフィック
  // PCは1列にGAとGMCのデータを表示し、SPはGAとGMCを分けて表示する
  const adsPcColumns = ['1fr', '1fr', '1fr', '1fr', '1fr'];
  const adsSpColumns = ['1fr', '1fr'];
  const adsPcRows = [LABEL_HEIGHT, CARD_HEIGHT];
  const adsSpRows = [LABEL_HEIGHT, CARD_HEIGHT, LABEL_HEIGHT, CARD_HEIGHT];
  const adsPcAreas = [
    ['ads-gmc-label', 'ads-gmc-label', 'ads-ga-label', 'ads-ga-label', 'ads-ga-label'],
    ['ads-impressions', 'ads-clicks', 'ads-sessions', 'ads-conversions', 'ads-revenues'],
  ];
  const adsSpAreas = [
    ['ads-gmc-label', 'ads-gmc-label'],
    ['ads-impressions', 'ads-clicks'],
    ['ads-ga-label', 'ads-ga-label'],
    ['ads-sessions', 'ads-conversions'],
    ['ads-revenues', '.'],
  ];

  // 無料トラフィック
  // PCは1列にGAとGMCのデータを表示し、SPはGAとGMCを分けて表示する
  // GAのデータは無料商品リスティングと無料ローカル商品リスティングで表示可能なものを縦に並べて表示する
  const freePcColumns = ['1fr', '1fr', '1fr', '1fr', '1fr'];
  const freeSpColumns = ['1fr', '1fr'];
  const freePcRows =
    showFreeListings && showFreeLocalListings
      ? [LABEL_HEIGHT, CARD_HEIGHT, LABEL_HEIGHT, CARD_HEIGHT]
      : [LABEL_HEIGHT, CARD_HEIGHT];
  const freeSpRows =
    showFreeListings && showFreeLocalListings
      ? [LABEL_HEIGHT, CARD_HEIGHT, LABEL_HEIGHT, CARD_HEIGHT, LABEL_HEIGHT, CARD_HEIGHT, CARD_HEIGHT]
      : [LABEL_HEIGHT, CARD_HEIGHT, LABEL_HEIGHT, CARD_HEIGHT, CARD_HEIGHT];
  let freePcAreas = [
    ['free-gmc-label', 'free-gmc-label', 'free-ga-label', 'free-ga-label', 'free-ga-label'],
    ['free-impressions', 'free-clicks', 'free-sessions', 'free-conversions', 'free-revenues'],
    ['freelocal-gmc-label', 'freelocal-gmc-label', 'free-sessions', 'free-conversions', 'free-revenues'],
    ['freelocal-impressions', 'freelocal-clicks', 'free-sessions', 'free-conversions', 'free-revenues'],
  ];
  // 無料商品リスティングと無料ローカル商品リスティングどちらも有効な場合
  let freeSpAreas = [
    ['free-gmc-label', 'free-gmc-label'],
    ['free-impressions', 'free-clicks'],
    ['freelocal-gmc-label', 'freelocal-gmc-label'],
    ['freelocal-impressions', 'freelocal-clicks'],
    ['free-ga-label', 'free-ga-label'],
    ['free-sessions', 'free-conversions'],
    ['free-revenues', '.'],
  ];
  // 無料商品リスティングのみ有効な場合
  if (showFreeListings && !showFreeLocalListings) {
    freePcAreas = [
      ['free-gmc-label', 'free-gmc-label', 'free-ga-label', 'free-ga-label', 'free-ga-label'],
      ['free-impressions', 'free-clicks', 'free-sessions', 'free-conversions', 'free-revenues'],
    ];
    freeSpAreas = [
      ['free-gmc-label', 'free-gmc-label'],
      ['free-impressions', 'free-clicks'],
      ['free-ga-label', 'free-ga-label'],
      ['free-sessions', 'free-conversions'],
      ['free-revenues', '.'],
    ];
  }
  // 無料ローカル商品リスティングのみ有効な場合
  if (!showFreeListings && showFreeLocalListings) {
    freePcAreas = [
      ['freelocal-gmc-label', 'freelocal-gmc-label', 'free-ga-label', 'free-ga-label', 'free-ga-label'],
      ['freelocal-impressions', 'freelocal-clicks', 'free-sessions', 'free-conversions', 'free-revenues'],
    ];
    freeSpAreas = [
      ['freelocal-gmc-label', 'freelocal-gmc-label'],
      ['freelocal-impressions', 'freelocal-clicks'],
      ['free-ga-label', 'free-ga-label'],
      ['free-sessions', 'free-conversions'],
      ['free-revenues', '.'],
    ];
  }

  return (
    <Wrapper className={className}>
      <Header>
        <Title>掲載結果</Title>
        <Remarks>下段の数字は比較期間の数値からの変化率を示しています</Remarks>
      </Header>
      {showAdsTraffic && (
        <TrafficWrapper>
          <TrafficTitleContainer>
            <TrafficTitle>有料トラフィック</TrafficTitle>
            <TrafficTitleSwitch onClick={() => toggleDisplayTrafficsSetting('ads')}>
              {displayAdsTraffic ? (
                <CloseIconWrapper>
                  <CloseIcon />
                </CloseIconWrapper>
              ) : (
                '表示する'
              )}
            </TrafficTitleSwitch>
          </TrafficTitleContainer>
          {displayAdsTraffic && (
            <CardContainer
              columns={isDesktop ? adsPcColumns : adsSpColumns}
              rows={isDesktop ? adsPcRows : adsSpRows}
              areas={isDesktop ? adsPcAreas : adsSpAreas}
            >
              <GridArea name='ads-gmc-label'>
                <Label>
                  Googleショッピング
                  <StyledPopupIcon
                    header='Googleショッピング'
                    content='「ショッピング広告」と「ローカル在庫広告」による結果の合計'
                  />
                </Label>
              </GridArea>
              <GridArea name='ads-ga-label'>
                <Label>EC</Label>
              </GridArea>
              <GridArea name='ads-impressions'>
                <StyledStatsCard
                  label='表示回数'
                  value={current.gmc.shopping_ads.impressions}
                  diff={diff.gmc.shopping_ads.impressions}
                  help={HELP_MESSAGE_ADS_IMPRESSIONS}
                />
              </GridArea>
              <GridArea name='ads-clicks'>
                <StyledStatsCard
                  label='クリック数'
                  value={current.gmc.shopping_ads.clicks}
                  diff={diff.gmc.shopping_ads.clicks}
                  help={HELP_MESSAGE_ADS_CLICKS}
                />
                <StyledTransitionCard
                  label='クリック率'
                  value={transitionRates.gmc.shopping_ads.clicks}
                  diff={transitionRateDiff?.gmc.shopping_ads.clicks}
                />
              </GridArea>
              <GridArea name='ads-sessions'>
                <StyledStatsCard
                  label='セッション数'
                  value={current.ga.ads.sessions}
                  diff={diff.ga.ads.sessions}
                  help={HELP_MESSAGE_ADS_SESSIONS}
                />
                <StyledTransitionCard
                  label='遷移率'
                  value={transitionRates.ga.ads.sessions}
                  diff={transitionRateDiff?.ga.ads.sessions}
                />
              </GridArea>
              <GridArea name='ads-conversions'>
                <StyledStatsCard
                  label='コンバージョン数'
                  value={current.ga.ads.conversions}
                  diff={diff.ga.ads.conversions}
                  help={HELP_MESSAGE_ADS_CONVERSIONS}
                />
                <StyledTransitionCard
                  label='CV率'
                  value={transitionRates.ga.ads.conversions}
                  diff={transitionRateDiff?.ga.ads.conversions}
                />
              </GridArea>
              <GridArea name='ads-revenues'>
                <StyledStatsCard
                  label='収益'
                  value={current.ga.ads.revenues}
                  diff={diff.ga.ads.revenues}
                  currency={true}
                  help={HELP_MESSAGE_ADS_REVENUES}
                />
              </GridArea>
            </CardContainer>
          )}
        </TrafficWrapper>
      )}
      {showFreeTraffic && (
        <TrafficWrapper>
          <TrafficTitleContainer>
            <TrafficTitle>無料トラフィック</TrafficTitle>
            <TrafficTitleSwitch onClick={() => toggleDisplayTrafficsSetting('free')}>
              {displayFreeTraffic ? (
                <CloseIconWrapper>
                  <CloseIcon />
                </CloseIconWrapper>
              ) : (
                '表示する'
              )}
            </TrafficTitleSwitch>
          </TrafficTitleContainer>
          {displayFreeTraffic && (
            <CardContainer
              columns={isDesktop ? freePcColumns : freeSpColumns}
              rows={isDesktop ? freePcRows : freeSpRows}
              areas={isDesktop ? freePcAreas : freeSpAreas}
            >
              <GridArea name='free-ga-label'>
                <Label>EC</Label>
              </GridArea>
              {showFreeListings && (
                <>
                  <GridArea name='free-gmc-label'>
                    <Label>無料商品リスティング</Label>
                  </GridArea>
                  <GridArea name='free-impressions'>
                    <StyledStatsCard
                      label='表示回数'
                      value={current.gmc.free_listings.impressions}
                      diff={diff.gmc.free_listings.impressions}
                      help={HELP_MESSAGE_FREE_IMPRESSIONS}
                    />
                  </GridArea>
                  <GridArea name='free-clicks'>
                    <StyledStatsCard
                      label='クリック数'
                      value={current.gmc.free_listings.clicks}
                      diff={diff.gmc.free_listings.clicks}
                      help={HELP_MESSAGE_FREE_CLICKS}
                    />
                    <StyledTransitionCard
                      label='クリック率'
                      value={transitionRates.gmc.free_listings.clicks}
                      diff={transitionRateDiff?.gmc.free_listings.clicks}
                    />
                  </GridArea>
                </>
              )}
              {showFreeLocalListings && (
                <>
                  <GridArea name='freelocal-gmc-label'>
                    <Label>無料ローカル商品リスティング</Label>
                  </GridArea>
                  <GridArea name='freelocal-impressions'>
                    <StyledStatsCard
                      label='表示回数'
                      value={current.gmc.free_local_listings.impressions}
                      diff={diff.gmc.free_local_listings.impressions}
                      help={HELP_MESSAGE_FREE_IMPRESSIONS}
                    />
                  </GridArea>
                  <GridArea name='freelocal-clicks'>
                    <StyledStatsCard
                      label='クリック数'
                      value={current.gmc.free_local_listings.clicks}
                      diff={diff.gmc.free_local_listings.clicks}
                      help={HELP_MESSAGE_FREE_CLICKS}
                    />
                    <StyledTransitionCard
                      label='クリック率'
                      value={transitionRates.gmc.free_local_listings.clicks}
                      diff={transitionRateDiff?.gmc.free_local_listings.clicks}
                    />
                  </GridArea>
                </>
              )}
              <GridArea name='free-sessions'>
                <LargeCardWrapper>
                  <StyledTransitionCard
                    label='遷移率'
                    value={transitionRates.ga.free.sessions}
                    diff={transitionRateDiff?.ga.free.sessions}
                  />
                  <StyledStatsCard
                    label='セッション数'
                    value={current.ga.free.sessions}
                    diff={diff.ga.free.sessions}
                    help={HELP_MESSAGE_FREE_SESSIONS}
                  />
                </LargeCardWrapper>
              </GridArea>
              <GridArea name='free-conversions'>
                <LargeCardWrapper>
                  <StyledTransitionCard
                    label='CV率'
                    value={transitionRates.ga.free.conversions}
                    diff={transitionRateDiff?.ga.free.conversions}
                  />
                  <StyledStatsCard
                    label='コンバージョン数'
                    value={current.ga.free.conversions}
                    diff={diff.ga.free.conversions}
                    help={HELP_MESSAGE_FREE_CONVERSIONS}
                  />
                </LargeCardWrapper>
              </GridArea>
              <GridArea name='free-revenues'>
                <LargeCardWrapper>
                  <StyledStatsCard
                    label='収益'
                    value={current.ga.free.revenues}
                    diff={diff.ga.free.revenues}
                    currency={true}
                    help={HELP_MESSAGE_FREE_REVENUES}
                  />
                </LargeCardWrapper>
              </GridArea>
            </CardContainer>
          )}
        </TrafficWrapper>
      )}
      {!(showAdsTraffic || showFreeTraffic) && <Message>表示するデータがありません</Message>}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin-bottom: 64px;
`;

const TrafficTitleContainer = styled.div`
  display: flex;
  position: relative;
`;

const TrafficTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
`;
const TrafficTitleSwitch = styled.div`
  position: absolute;
  color: ${COLOR.GREEN};
  top: 0;
  right: 0;
  min-width: 28px;
  height: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: 18px;
  margin-left: auto;
  user-select: none;
`;

const CardContainer = styled(Grid)`
  margin-top: 8px;
  grid-gap: 8px;
  overflow-x: auto;
`;

const TrafficWrapper = styled.div`
  margin-bottom: 16px;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 18px;
  flex-shrink: 0;
`;

const Remarks = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 14px;
  margin-left: 16px;
`;

const Label = styled.div`
  font-size: 16px;
`;

const StyledStatsCard = styled(StatsCard)``;

const StyledTransitionCard = styled(TransitionCard)`
  border-top: none;
`;

const LargeCardWrapper = styled.div`
  display: flex;
  /* 隣接セレクタでStyledTransitionCardの前に表示されているStyledStatsCardを選択するためにcolumn-reverseで逆順にしている */
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;

  /* StyledStatsCard単体で表示されている場合、margin-bottomで空白を開ける */
  ${StyledStatsCard} {
    flex-grow: 1;
    margin-bottom: 58px;
  }

  /* StyledTransitionCardとStyledStatsCardが表示されている場合、margin-bottomで0にしてくっつける */
  ${StyledTransitionCard}+${StyledStatsCard} {
    margin-bottom: 0;
  }
`;

const Message = styled.div`
  font-size: 16px;
  color: ${COLOR.DARK_GRAY};
  width: 100%;
  text-align: center;
  padding: 24px 0;
`;

const StyledPopupIcon = styled(PopupIcon)`
  margin-left: 4px;
`;

const CloseIconWrapper = styled.div`
  color: ${COLOR.BLACK};
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14px;
`;

const CloseIcon = styled(Icon).attrs(() => ({ name: 'close' }))`
  color: ${COLOR.BLACK};
`;
