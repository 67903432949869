import { all, fork } from 'redux-saga/effects';

import SearchKeywordSaga from './SearchKeyword/sagas';
import TopSaga from './Top/sagas';
import FileUploadSaga from './app/fileUpload';
import AppSaga from './app/sagas';
import BulkEditStoresSaga from './bulkEditStores/sagas';
import CompetitorRegisterSaga from './competitor/register/sagas';
import CompetitorSaga from './competitor/sagas';
import GbpChecklistSaga from './gbpChecklist/sagas';
import GbpInsightSaga from './gbpInsight/sagas';
import GbpPerformanceSaga from './gbpPerformance/sagas';
import GbpPerformanceMASaga from './gbpPerformanceMA/sagas';
import GmbSaga from './gmb/sagas';
import GmbLocationUpdatesSaga from './gmbLocationUpdates/sagas';
import ImageSaga from './image/sagas';
import InstagramSaga from './instagram/sagas';
import InventoriesImportSaga from './inventoriesImport/sagas';
import InventorySaga from './inventory/sagas';
import MapCompetitorResearchSaga from './mapCompetitorResearch/sagas';
import MapSearchRankConfigSaga from './mapSearchRank/config/sagas';
import MapSearchRankImportSaga from './mapSearchRank/import/sagas';
import MapSearchRankSaga from './mapSearchRank/sagas';
import MemoSaga from './memo/sagas';
import MenuSaga from './menu/sagas';
import NotificationSaga from './notification/sagas';
import OfferSaga from './offer/sagas';
import OfferGroupsSaga from './offerGroups/sagas';
import OmoInsightSaga from './omoInsight/sagas';
import OrganizationSaga from './organization/sagas';
import PromotionSaga from './promotion/sagas';
import SearchVolumeSaga from './searchVolume/sagas';
import ServiceSaga from './service/sagas';
import StoreSaga from './store/sagas';
import StoreDetailSaga from './storeDetail/sagas';
import StoreListSaga from './storeList/sagas';
import StoresImport from './storesImport/sagas';
import TaskDetailSaga from './taskDetail/sagas';
import UserSaga from './user/sagas';

const rootSaga = function* root() {
  yield all([
    fork(AppSaga),
    fork(TopSaga),
    fork(StoreSaga),
    fork(StoreDetailSaga),
    fork(UserSaga),
    fork(GmbSaga),
    fork(ImageSaga),
    fork(NotificationSaga),
    fork(StoreListSaga),
    fork(OfferSaga),
    fork(FileUploadSaga),
    fork(PromotionSaga),
    fork(BulkEditStoresSaga),
    fork(GmbLocationUpdatesSaga),
    fork(StoresImport),
    fork(OfferGroupsSaga),
    fork(TaskDetailSaga),
    fork(GbpInsightSaga),
    fork(InventorySaga),
    fork(InventoriesImportSaga),
    fork(SearchKeywordSaga),
    fork(MapSearchRankSaga),
    fork(MapSearchRankConfigSaga),
    fork(MapSearchRankImportSaga),
    fork(MapCompetitorResearchSaga),
    fork(CompetitorSaga),
    fork(CompetitorRegisterSaga),
    fork(GbpChecklistSaga),
    fork(OrganizationSaga),
    fork(GbpPerformanceSaga),
    fork(SearchVolumeSaga),
    fork(InstagramSaga),
    fork(GbpPerformanceMASaga),
    fork(MemoSaga),
    fork(OmoInsightSaga),
    fork(MenuSaga),
    fork(ServiceSaga),
  ]);
};

export default rootSaga;
