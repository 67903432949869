import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { COLOR } from 'style/color';

type ComponentProps = {
  canApply: boolean; // 変更内容があるときだけ変更ボタンを押せる
  selectedSize: number;
  onCancel: () => void;
  onConfirm: () => void;
};

export const StoreBusinessInfoFooter = React.memo<ComponentProps>(({ canApply, selectedSize, onCancel, onConfirm }) => {
  return (
    <Footer>
      <ChildWrapper>変更を加える店舗総数：{selectedSize} 店舗</ChildWrapper>
      <ButtonWrapper>
        <CustomButton onClick={() => onCancel()}>キャンセル</CustomButton>
        <CustomButton priority='high' onClick={() => onConfirm()} disabled={!canApply}>
          変更する
        </CustomButton>
      </ButtonWrapper>
    </Footer>
  );
});

const ChildWrapper = styled.div`
  margin-right: 18px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 860px) {
    min-width: 180px;
  }
`;

const CustomButton = styled(Button)`
  &&& {
    font-weight: bold;
    cursor: pointer;
    width: auto;
    font-size: 16px;
    margin-right: 11px;
    &:last-of-type {
      margin-right: 0px;
    }
    @media (max-width: 1024px) {
      font-size: 11px;
      padding: 13.5px;
      margin: 4px;
    }
  }
  &:before {
    white-space: pre;
  }
`;

const Footer = styled.div`
  position: sticky;
  background-color: ${COLOR.WHITE};
  bottom: 0;
  height: 100px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 26px 64px;
  border-radius: 2px;
  @media (max-width: 860px) {
    padding: 24px 18px;
    height: auto;
  }
`;
