import { List, Record, Set, is } from 'immutable';

import { booleanToSearchParam, numberComparator, parseBooleanParam, parseNumberParameter } from 'helpers/search';
import { Group } from 'types/Common';

export type AggregateUnit = 'day' | 'week' | 'month';

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType | keyof PaginationType, 'isAllStoreIds' | 'isEnabledComparison'>]: string;
} = {
  group: 'st',
  storeIds: 'si',
  showClosedStores: 'sc',
  queries: 'q',
  tags: 't',
  page: 'pg',
  limit: 'li',
};

const DEFAULT_GROUP = null;
const DEFAULT_IS_ALL_STORE_IDS = true;
const DEFAULT_STORE_IDS = Set<number>();
const DEFAULT_SHOW_CLOSED_STORES = false;
const DEFAULT_QUERIES = List<string>();
const DEFAULT_TAGS = List<string>();

type FilterStatusType = {
  group: Group;
  storeIds: Set<number>;
  isAllStoreIds: boolean;
  showClosedStores: boolean;
  queries: List<string>;
  tags: List<string>;
};

export class FilterStatus extends Record<FilterStatusType>({
  group: DEFAULT_GROUP,
  storeIds: DEFAULT_STORE_IDS,
  isAllStoreIds: DEFAULT_IS_ALL_STORE_IDS,
  showClosedStores: DEFAULT_SHOW_CLOSED_STORES,
  queries: DEFAULT_QUERIES,
  tags: DEFAULT_TAGS,
}) {
  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({ storeIds, isAllStoreIds });
  }
}

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 100;

type PaginationType = {
  page: number;
  limit: number;
};

export class Pagination extends Record<PaginationType>({
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
}) {}

export class CompetitorSearchCondition extends Record<{
  filter: FilterStatus;
  pagination: Pagination;
}>({
  filter: new FilterStatus(),
  pagination: new Pagination(),
}) {
  /**
   * ページのURLパラメータから条件を生成する
   * @param search URLパラメータ
   * @returns 検索条件
   */
  static fromURLSearchParams(search: string): CompetitorSearchCondition {
    const condition = new CompetitorSearchCondition();
    let { filter, pagination } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連
    const group = params.get(URLSearchParamsMapping.group);
    if (group) {
      if (group === 'all' || group === 'my_store') {
        // すべての店舗の場合
        filter = filter.set('group', group);
      } else if (group.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('group', parseInt(group, 10));
      }
    }

    const storeIds = params.get(URLSearchParamsMapping.storeIds) || 'all';
    if (storeIds === 'all') {
      filter = filter.setStoreIds(Set<number>(), true);
    } else {
      const values = storeIds
        .split(',')
        .filter((v) => v.match(/^\d+$/))
        .map((v) => parseInt(v, 10));
      filter = filter.setStoreIds(Set(values), false);
    }

    filter = filter.set(
      'showClosedStores',
      parseBooleanParam(params.get(URLSearchParamsMapping.showClosedStores), DEFAULT_SHOW_CLOSED_STORES),
    );

    const queries = params.get(URLSearchParamsMapping.queries);
    if (queries) {
      filter = filter.set('queries', Set(queries.split(',')).toList());
    }

    const tags = params.get(URLSearchParamsMapping.tags);
    if (tags) {
      filter = filter.set('tags', Set(tags.split(',')).toList());
    }

    // ページネーション関連
    pagination = pagination.set('page', parseNumberParameter(params.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1));
    pagination = pagination.set(
      'limit',
      parseNumberParameter(params.get(URLSearchParamsMapping.limit), DEFAULT_LIMIT, 1, 100),
    );

    return condition.merge({ filter, pagination });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    // フィルタ関連
    if (this.filter.group != DEFAULT_GROUP) {
      params.append(URLSearchParamsMapping.group, String(this.filter.group));
    }
    if (!this.filter.isAllStoreIds && this.filter.storeIds.size > 0) {
      params.append(URLSearchParamsMapping.storeIds, this.filter.storeIds.toArray().sort(numberComparator).join(','));
    }

    if (this.filter.showClosedStores != DEFAULT_SHOW_CLOSED_STORES) {
      params.append(URLSearchParamsMapping.showClosedStores, booleanToSearchParam(this.filter.showClosedStores));
    }

    if (!is(this.filter.queries, DEFAULT_QUERIES)) {
      params.append(URLSearchParamsMapping.queries, this.filter.queries.join(','));
    }

    if (!is(this.filter.tags, DEFAULT_TAGS)) {
      params.append(URLSearchParamsMapping.tags, this.filter.tags.join(','));
    }

    // ページネーション関連
    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }

    if (this.pagination.limit !== DEFAULT_LIMIT) {
      params.append(URLSearchParamsMapping.limit, String(this.pagination.limit));
    }

    // 見栄え悪いので、'%2Cは','に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  toRequestParams() {
    return {
      store_id: this.filter.storeIds.join(','),
      query: this.filter.queries.join(','),
      tag: this.filter.tags.join(','),
      page: this.pagination.page,
      limit: this.pagination.limit,
    };
  }
}
