import actionCreatorFactory from 'typescript-fsa';

import {
  GbpPerformanceGraphData,
  GbpPerformanceMonthlyData,
  GbpPerformanceTableData,
} from 'models/Domain/GbpPerformance/GbpPerformance';
import { GbpPerformanceCsvDownloadCondition } from 'models/Domain/GbpPerformance/GbpPerformanceCsvDownloadCondition';
import { GbpPerformanceSearchCondition } from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';

const actionCreator = actionCreatorFactory('GbpPerformance');

export const GbpPerformanceActions = {
  initializePage: actionCreator('initializePage'),
  setSearchCondition: actionCreator<GbpPerformanceSearchCondition>('setSearchCondition'),
  commitSearchCondition: actionCreator<GbpPerformanceSearchCondition>('commitSearchCondition'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),
  setCsvDownloadCondition: actionCreator<GbpPerformanceCsvDownloadCondition>('setCsvDownloadCondition'),
  downloadCsv: actionCreator('downloadCsv'),
  setIsLoading: actionCreator<{ graphData?: boolean; tableData?: boolean; monthlyData?: boolean }>('setIsLoading'),
  setGraphAndTableData: actionCreator<{
    graphData: GbpPerformanceGraphData;
    tableData: GbpPerformanceTableData;
  }>('setGraphAndTableData'),
  setMonthlyData: actionCreator<{ monthlyData: GbpPerformanceMonthlyData }>('setMonthlyData'),
};
