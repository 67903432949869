import ApiClient, { FaasApiClient, LambdaFunctionApiClient } from 'ApiClient';
import CONFIG from 'config';
import { RequestParamsType } from 'models/Domain/Promotion/PromotionSearchCondition';
import { GetCsvDownloadParameterType } from 'modules/promotion/actions';
import { JSObject } from 'types/Common';

import { TranslationLanguage } from '../models/Domain/Organization';

const apiClient = new ApiClient({});
const faasApiClient = new FaasApiClient({});

const PROMOTION_BASE_PATH = '/promotion/gmb';

const PROMOTION_PATH = (promotionId: string) => `${PROMOTION_BASE_PATH}/${String(promotionId)}`;
const PROMOTION_SUMMARY_DETAIL_PATH = (promotionId: string) => `/promotion/post-status/${promotionId}`;

const PROMOTION_CSV_DOWNLOAD_PATH = '/promotion/insight/csv/create';
const PROMOTION_CSV_STATUS_CHECK_PATH = '/promotion/insight/csv/status';

type ActionType = 'ACTION_TYPE_UNSPECIFIED' | 'BOOK' | 'ORDER' | 'SHOP' | 'LEARN_MORE' | 'SIGN_UP' | 'CALL';
type TopicType = 'LOCAL_POST_TOPIC_TYPE_UNSPECIFIED' | 'STANDARD' | 'EVENT' | 'OFFER' | 'ALERT';
type MediaFormat = 'MEDIA_FORMAT_UNSPECIFIED' | 'PHOTO' | 'VIDEO';

export type Pagination = {
  total_count: number;
  limit: number;
  current_page: number;
  has_next: boolean;
};

export type datetime = string;

type MediaItem = {
  name: string | null;
  source_url: string;
  media_format: MediaFormat;
};

export type CallToAction = {
  action_type: ActionType;
  url: string | null;
};

export type TimeIntervalDate = {
  year: number;
  month: number;
  day: number;
};

export type TimeIntervalTime = {
  hours: number;
  minutes: number;
  seconds: number;
};

export type TimeInterval = {
  start_date: TimeIntervalDate | null;
  start_time: TimeIntervalTime | null;
  end_date: TimeIntervalDate | null;
  end_time: TimeIntervalTime | null;
};

type LocalPostEvent = {
  title: string;
  schedule: TimeInterval;
};

type LocalPostOffer = {
  coupon_code: string;
  redeem_online_url: string;
  terms_conditions: string;
};

type Stats = {
  total_stores_count: number;
};

type PostStatusSummary = {
  unspecified_post_count: number;
  processing_post_count: number;
  live_post_count: number;
  reject_post_count: number;
};

type SourceInstagram = {
  type: 'instagram';
  permalink: string;
  ig_username: string;
  ig_name: string;
};

type Source = SourceInstagram;

type Store = {
  local_post_name: string;
  store_id: number;
};

export type TranslationPostData = {
  _id?: string;
  language: string | null;
  title?: string | null;
  body?: string | null;
  call_to_action?: CallToAction | null;
};

export type Translations = {
  parent: string | null;
  language: TranslationLanguage | null;
  children: TranslationPostData[] | null;
};

// 一覧表示向けの投稿データの型
type PromotionItem = {
  _id: string;
  organization_id: number;
  media: MediaItem[];
  body: string;
  topic_type: TopicType;
  call_to_action: CallToAction | null;
  event: LocalPostEvent | null;
  offer: LocalPostOffer | null;
  stats: Stats;
  post_status: PostStatusSummary;
  store_ids: number[];
  is_draft: boolean;
  is_scheduled: boolean;
  scheduled_at: datetime | null;
  posted_at: datetime | null;
  source: Source | null;
  create_at: string | null;
  update_at: string | null;
  translations: Translations | null;
  has_rejected_post: boolean;
};

// 個別表示向けの投稿データの型
type Promotion = {
  _id: string;
  organization_id: number;
  media: MediaItem[];
  body: string;
  topic_type: TopicType;
  call_to_action: CallToAction | null;
  event: LocalPostEvent | null;
  offer: LocalPostOffer | null;
  stores: Store[];
  stats: Stats;
  is_draft: boolean;
  is_scheduled: boolean;
  scheduled_at: datetime | null;
  posted_at: datetime | null;
  source: Source | null;
  create_at: string | null;
  update_at: string | null;
  translations: Translations | null;
};

// 更新用の投稿データの型
export type PromotionForUpdate = {
  _id: string | null;
  organization_id: number | null;
  media: MediaItem[];
  body: string;
  topic_type: TopicType;
  call_to_action: CallToAction | null;
  event: LocalPostEvent | null;
  offer: LocalPostOffer | null;
  store_ids: number[];
  is_draft: boolean;
  is_scheduled: boolean;
  scheduled_at: datetime | null;
  source: Source | null;
  translations: Translations | null;
};

export type PromotionGetResponse = Promotion;

export type PromotionPostParams = {
  promotions: PromotionForUpdate;
  translations: TranslationPostData[];
};

export type PromotionPutParams = {
  promotions: PromotionForUpdate;
};

export type PromotionListGetResponse = {
  items: PromotionItem[];
  pagination: Pagination;
};

export type PromotionBatchUpdateParams = {
  promotions: PromotionForUpdate;
  translations: TranslationPostData[];
};

export type PromotionBatchUpdateResponse = Promotion;

export class PromotionApi {
  static get(promotionId: string) {
    return apiClient.get<PromotionGetResponse>(PROMOTION_PATH(promotionId));
  }

  static post(params: PromotionPostParams) {
    return apiClient.post(PROMOTION_BASE_PATH, params);
  }

  static put(promotionId: string, params: PromotionPutParams) {
    return apiClient.put<string>(PROMOTION_PATH(promotionId), params);
  }

  static delete(promotionId: string) {
    return apiClient.delete<string>(PROMOTION_PATH(promotionId));
  }

  static batchUpdate(promotionId: string, params: PromotionBatchUpdateParams) {
    return apiClient.post<PromotionBatchUpdateResponse>(`${PROMOTION_PATH(promotionId)}:batch_update`, params);
  }
}

export class PromotionListApi {
  static get(params: RequestParamsType) {
    const storeIdsStr = (params.store_id || []).join(',');

    return apiClient.get<PromotionListGetResponse>(PROMOTION_BASE_PATH, { ...params, store_id: storeIdsStr });
  }
}

type State = 'SCHEDULED' | 'QUEUED' | 'INPROGRESS' | 'POSTED' | 'FAILED';

type GmbState = 'LOCAL_POST_STATE_UNSPECIFIED' | 'REJECTED' | 'LIVE' | 'PROCESSING';

type PostStatus = {
  local_post_doc_id: string;
  local_post_name: string;
  store_id: number;
  state: State;
  gmb_state: GmbState;
  post_create_at: datetime | null;
  post_update_at: datetime | null;
};

export type PromotionSummaryDetailResponse = PostStatus[];

export class PromotionSummaryDetailApi {
  static get(promotionId: string) {
    return apiClient.get<PromotionSummaryDetailResponse>(PROMOTION_SUMMARY_DETAIL_PATH(promotionId));
  }
}

type PromotionCsvDownloadApiResponse = {
  executionArn: string;
};

// 投稿の実績データをCSVでダウンロード開始するエンドポイント
export class PromotionCsvDownloadApi {
  static post(params: GetCsvDownloadParameterType) {
    return faasApiClient.post<PromotionCsvDownloadApiResponse>(PROMOTION_CSV_DOWNLOAD_PATH, params);
  }
}

type PromotionCsvStatusCheckApiResponse = {
  status: 'RUNNING' | 'SUCCEEDED' | 'FAILED';
  message: string;
  download_url: string | null;
};

// 投稿CSVダウンロード処理の進捗を取得するエンドポイント
export class PromotionCsvStatusCheckApi {
  static post(params: JSObject) {
    return faasApiClient.post<PromotionCsvStatusCheckApiResponse>(PROMOTION_CSV_STATUS_CHECK_PATH, params);
  }
}

export type PromotionGenerateParams = {
  url?: string;
  contents?: string;
  keywords?: string[];
  targets?: string[];
  cite?: string;
};

export type PromotionGenerateResponse = {
  items: {
    body: string;
  }[];
  contents_filtered: boolean;
};

export class PromotionGenerateApi {
  static post(params: PromotionGenerateParams) {
    const lambdaFunctionClient = new LambdaFunctionApiClient({
      endpoint: CONFIG.GENERATE_LOCALPOST_TEXT_ENDPOINT,
    });
    return lambdaFunctionClient.post<PromotionGenerateResponse>(`${PROMOTION_BASE_PATH}/generate`, params);
  }
}

export type PromotionTranslateParams = {
  title?: string;
  body: string;
  language: string;
};

export type PromotionTranslateResponse = {
  title: string;
  body: string;
};

// 投稿文の翻訳を行うエンドポイント
export class PromotionTranslateApi {
  static post(params: PromotionTranslateParams) {
    const lambdaFunctionClient = new LambdaFunctionApiClient({
      endpoint: CONFIG.GENERATE_LOCALPOST_TRASNLATION_ENDPOINT,
    });
    return lambdaFunctionClient.post<PromotionTranslateResponse>(`${PROMOTION_BASE_PATH}/translate`, params);
  }
}
