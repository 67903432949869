import React from 'react';

import { replace } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { UserEditContent } from 'components/organisms/UserEditContent';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { State } from 'modules/reducers';
import { UserActions } from 'modules/user/actions';
import { Path } from 'routes';

type Props = RouteComponentProps<{ userId: string }>;

export const UserEdit: React.FC<Props> = ({
  match: {
    params: { userId },
  },
}) => {
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
  const { currentUser, editUser, userList } = useSelector(
    (state: State) => ({
      currentUser: state.app.currentUser,
      editUser: state.user.editUser,
      userList: state.user.userList,
    }),
    shallowEqual,
  );
  const targetUser = userList.findUser(userId);

  React.useEffect(() => {
    if (targetUser) {
      dispatch(UserActions.setEditUser(targetUser));
    } else if (userList.initialized) {
      // userListが取得済みなのに、targetUserがないなら、
      // 指定されたユーザーが存在しない/表示権限がないので、一覧ページに遷移する
      dispatch(replace(Path.user.index));
    }
  }, [dispatch, targetUser, userList.initialized]);

  if (!targetUser || editUser.id !== targetUser.id) {
    return null;
  }

  // 編集・削除権限
  const canEdit = targetUser ? currentUser.canEditUser(targetUser) : false;
  const canDelete = targetUser ? currentUser.canDeleteUser(targetUser) : false;
  const isMyAccountEdit = currentUser.isSameUser(targetUser);

  return (
    <>
      <MainWrapper>
        <Helmet title={getPageTitle('スタッフ編集')} />
        <StickyHeader title='スタッフ編集' />
        <Body>
          <UserEditContent
            editUser={editUser}
            targetUser={targetUser}
            isMyAccountEdit={isMyAccountEdit}
            canEdit={canEdit}
            canDelete={canDelete}
            setEditUser={(v) => dispatch(UserActions.setEditUser(v))}
            updateEditUser={() => dispatch(UserActions.updateEditUser())}
            uploadEditUserImage={(v) => dispatch(UserActions.uploadEditUserImage(v))}
            setIsOpenDeleteModal={setIsOpenDeleteModal}
          />
        </Body>
      </MainWrapper>

      <ConfirmModal
        type='small'
        open={isOpenDeleteModal}
        onExecution={() => dispatch(UserActions.deleteEditUser(targetUser))}
        onNegativeExecution={() => setIsOpenDeleteModal(false)}
        text='メンバーを削除しますか？'
        contentText='削除する'
        negativeText='キャンセル'
      />
    </>
  );
};
