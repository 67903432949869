/* eslint-disable @typescript-eslint/no-use-before-define */

import { Record } from 'immutable';

/** グループによる絞り込みとして選択可能なパターン */
export type StoreListIdType = number | 'all' | 'my_store' | null;

export type FilterStatusType = {
  searchValue: string;
  storeListId: StoreListIdType;
};

const DEFAULT_SEARCH_VALUE = '';
const DEFAULT_STORE_LIST_ID = null;

export type SortKey = 'code' | 'id';
export type SortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY: SortKey = 'code';
const DEFAULT_SORT_ORDER: SortOrder = 'asc';

export type SortStatusType = {
  key: SortKey;
  order: SortOrder;
};

export class FilterStatus extends Record<FilterStatusType>({
  searchValue: DEFAULT_SEARCH_VALUE,
  storeListId: DEFAULT_STORE_LIST_ID,
}) {}
export class SortStatus extends Record<SortStatusType>({
  key: DEFAULT_SORT_KEY,
  order: DEFAULT_SORT_ORDER,
}) {}

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in keyof FilterStatusType | keyof SortStatusType]: string;
} = {
  searchValue: 'sv',
  storeListId: 'sl',
  key: 'sk',
  order: 'so',
};

export class StoreSearchCondition extends Record<{
  filter: FilterStatus;
  sort: SortStatus;
}>({
  filter: new FilterStatus(),
  sort: new SortStatus(),
}) {
  /**
   * ソート条件をセットする
   * @param key
   * @param order
   */
  setSortCondition(key: SortKey, order: SortOrder) {
    return this.mergeIn(['sort'], { key, order });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    const searchValue = this.filter.searchValue;
    if (searchValue !== DEFAULT_SEARCH_VALUE) {
      params.append(URLSearchParamsMapping.searchValue, searchValue);
    }

    const storeListId = this.filter.storeListId;
    if (storeListId !== DEFAULT_STORE_LIST_ID) {
      params.append(URLSearchParamsMapping.storeListId, `${storeListId}`);
    }

    const sortKey = this.sort.key;
    if (sortKey !== DEFAULT_SORT_KEY) {
      params.append(URLSearchParamsMapping.key, sortKey);
    }

    const sortOrder = this.sort.order;
    if (sortOrder !== DEFAULT_SORT_ORDER) {
      params.append(URLSearchParamsMapping.order, sortOrder);
    }

    return params.toString();
  }

  /**
   * URLのパラメータから検索条件を生成する
   * @param search
   */
  static fromURLSearchParams(search: string): StoreSearchCondition {
    const condition = new StoreSearchCondition();
    let { filter, sort } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連
    const searchValue = params.get(URLSearchParamsMapping.searchValue);
    filter = filter.set('searchValue', searchValue || DEFAULT_SEARCH_VALUE);

    const storeListId = params.get(URLSearchParamsMapping.storeListId);
    filter = filter.set('storeListId', parseStoreListId(storeListId, DEFAULT_STORE_LIST_ID));

    // ソート関連
    const sortKey = params.get(URLSearchParamsMapping.key);
    if (sortKey) {
      if (['code', 'id'].includes(sortKey)) {
        sort = sort.set('key', sortKey as SortKey);
      }
    }
    const sortOrder = params.get(URLSearchParamsMapping.order);
    if (sortOrder) {
      if (['asc', 'desc'].includes(sortOrder)) {
        sort = sort.set('order', sortOrder as SortOrder);
      }
    }
    return condition.merge({ filter, sort });
  }
}

/**
 * URLのグループIDをパラメータの文字列から変換する
 * @param param
 * @param defaultValue デフォルト値
 */
export const parseStoreListId = (param: string | null, defaultValue: StoreListIdType): StoreListIdType => {
  if (param === 'all') {
    return param;
  }
  if (param === 'my_store') {
    return param;
  }
  if (param) {
    const num = parseInt(param, 10);
    if (!isNaN(num)) {
      return num;
    }
  }
  return defaultValue;
};
