import React, { useCallback, useMemo } from 'react';

import { toast } from 'react-semantic-toasts';
import { Dropdown, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import {
  SearchVolumeSearchHistory,
  SearchVolumeSearchHistoryItem,
} from 'models/Domain/SearchVolume/SearchVolumeSearchHistory';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  searchHistory: SearchVolumeSearchHistory;
  onClickHistory: (executionId: string) => void;
  deleteHistory: (executionId: string) => void;
  isLoading: boolean;
};

// 検索地点、検索ワードを省略せずに表示する件数
const DISPLAY_CONDITION_COUNT = 3;

export const SearchHistoryTable = React.memo<Props>(
  ({ className, searchHistory, onClickHistory, deleteHistory, isLoading }) => {
    return (
      <Wrapper className={className}>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <StyledTableHeaderCell></StyledTableHeaderCell>
              <StyledTableHeaderCell>検索ワード</StyledTableHeaderCell>
              <StyledTableHeaderCell>検索地点</StyledTableHeaderCell>
              <StyledTableHeaderCell>期間</StyledTableHeaderCell>
              <StyledTableHeaderCell>実行日時</StyledTableHeaderCell>
              <StyledTableHeaderCell></StyledTableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {!isLoading &&
              searchHistory.items.map((item, index) => (
                <SearchHistoryTableRow
                  key={index}
                  item={item}
                  onClick={onClickHistory}
                  onDeleteHistory={deleteHistory}
                />
              ))}
            {!isLoading && searchHistory.items.isEmpty() && (
              <TableRow>
                <StyledTableCell colSpan={7}>
                  <Content>検索履歴はありません</Content>
                </StyledTableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <StyledTableCell colSpan={7}>
                  <Content>
                    <LoaderWrapper>
                      <Loader active={isLoading} size='big' inline />
                    </LoaderWrapper>
                  </Content>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Wrapper>
    );
  },
);

export const SearchHistoryTableRow = React.memo<{
  className?: string;
  item: SearchVolumeSearchHistoryItem;
  onDeleteHistory: (executionId: string) => void;
  onClick: (executionId: string) => void;
}>(({ item, onDeleteHistory, onClick }) => {
  const {
    conditions: {
      areas,
      searchWords,
      period: { startMonth, endMonth },
    },
    date,
    executionId,
    status,
  } = item;

  const areaNames = useMemo(() => areas.map((item) => item.areaName).filter((name) => name), [areas]);
  const searchWordNames = useMemo(() => searchWords.map((item) => item.name), [searchWords]);

  const periodText = useMemo((): string => {
    const start = startMonth.format();
    const end = endMonth.format();
    return start === end ? `${start}` : `${start}〜${end}`;
  }, [startMonth, endMonth]);

  const handleOnClick = useCallback(() => {
    onClick(item.executionId);
  }, [item.executionId, onClick]);

  const copyLink = useCallback((executionId: string) => {
    const url = `${location.protocol}//${location.host}${Path.searchVolume.result.replace(':id', executionId)}`;
    navigator.clipboard.writeText(url);
    toast({ type: 'success', title: 'リンクをコピーしました' });
  }, []);

  return (
    <StyledTableRow onClick={handleOnClick}>
      <StatusCell>
        <IconWrapper>
          {status === 'SUCCEEDED' ? (
            <StyledIcon type={'status_active'} />
          ) : status === 'FAILED' ? (
            <StyledIcon type={'status_error'} />
          ) : (
            <LoaderWrapper>
              <Loader active={true} size={'mini'} inline />
            </LoaderWrapper>
          )}
        </IconWrapper>
      </StatusCell>
      <StyledTableCell>
        <DisplayNames>{searchWordNames.slice(0, DISPLAY_CONDITION_COUNT).join(' / ')}</DisplayNames>
        {searchWordNames.size > DISPLAY_CONDITION_COUNT && (
          <Popup
            content={
              <PopupContent>
                {searchWordNames.map((searchWord) => (
                  <div key={searchWord}>{searchWord}</div>
                ))}
              </PopupContent>
            }
            trigger={<PopupTrigger>{`他${searchWordNames.size - DISPLAY_CONDITION_COUNT}件`}</PopupTrigger>}
          />
        )}
      </StyledTableCell>
      <StyledTableCell>
        <DisplayNames>{areaNames.slice(0, DISPLAY_CONDITION_COUNT).join(' / ')}</DisplayNames>
        {areaNames.size > DISPLAY_CONDITION_COUNT && (
          <Popup
            content={
              <PopupContent>
                {areaNames.map((areaName) => (
                  <div key={areaName}>{areaName}</div>
                ))}
              </PopupContent>
            }
            trigger={<PopupTrigger>{`他${areaNames.size - DISPLAY_CONDITION_COUNT}件`}</PopupTrigger>}
          />
        )}
      </StyledTableCell>
      <PeriodCell>{periodText}</PeriodCell>
      <DateTableCell>{date.format('YYYY/MM/DD HH:mm:ss')}</DateTableCell>
      <MenuCell>
        <MenuWrapper>
          <Dropdown
            trigger={
              <SettingsIconWrapper>
                <SettingsIcon />
              </SettingsIconWrapper>
            }
            icon={null}
          >
            <Dropdown.Menu direction={'left'}>
              <ActionMenuItem onClick={() => copyLink(executionId)}>
                <ActionItemLabel>リンクを共有</ActionItemLabel>
              </ActionMenuItem>
              <ActionMenuItem onClick={() => onDeleteHistory(executionId)}>
                <ActionItemLabel>履歴から削除</ActionItemLabel>
              </ActionMenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </MenuWrapper>
      </MenuCell>
    </StyledTableRow>
  );
});

const Wrapper = styled.div``;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const StatusCell = styled(StyledTableCell)``;

const MenuCell = styled(StyledTableCell)``;

const PeriodCell = styled(StyledTableCell)`
  font-family: monospace;
`;

const DateTableCell = styled(StyledTableCell)`
  &&& {
    font-family: monospace;
  }
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

const LoaderWrapper = styled.div``;

const IconWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const Content = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${COLOR.DARK_GRAY};
`;

const PopupTrigger = styled.span`
  display: inline-block;
  text-decoration: underline;
  font-size: 12px;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;

const MenuWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const SettingsIconWrapper = styled.div`
  display: grid;
`;

const SettingsIcon = styled(Icon).attrs({
  type: 'settings',
})`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

const ActionItemLabel = styled.div`
  padding: 4px 8px;
  font-size: 14px;
  color: ${COLOR.BLACK};
`;

const DisplayNames = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;
