import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Checkbox, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ErrorLabel } from 'components/atoms/Label';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { FilterStatus } from 'models/Domain/Promotion/PromotionDownloadCondition';
import { AppActions } from 'modules/app/actions';
import { PromotionActions } from 'modules/promotion/actions';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';

type PromotionInfoDownloadModalProps = {
  open: boolean;
  onClose: () => void;
  downloadFilter: FilterStatus;
};

// 投稿の実績データをCSVでダウンロードできる画面
export const PromotionInfoDownloadModal: React.FC<PromotionInfoDownloadModalProps> = ({
  open,
  onClose,
  downloadFilter,
}) => {
  const [filter, setFilter] = useState<FilterStatus>(downloadFilter);
  useEffect(() => {
    setFilter(downloadFilter);
  }, [downloadFilter]);

  const dispatch = useDispatch();

  const { csvDownload } = useSelector(
    (state: State) => ({
      csvDownload: state.promotion.csvDownload,
    }),
    shallowEqual,
  );

  /**
   * フィルターを変更する
   */
  const onChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      const updatedFilter = filter
        .set('startDate', startDate ? dayjs(startDate) : null)
        .set('endDate', endDate ? dayjs(endDate) : null);
      setFilter(updatedFilter);
    },
    [filter],
  );

  /**
   * フィルターを変更する
   */
  const onChangeFullText = useCallback(() => {
    const updatedFilter = filter.update('fullText', (fullText) => !fullText);
    setFilter(updatedFilter);
  }, [filter]);

  const startDownload = () => {
    dispatch(PromotionActions.getCsvDownload(filter.getCsvDownloadRequestParams()));
  };

  useEffect(() => {
    // CSVダウンロード処理の実行中、3秒おきに進捗を取得する
    if (csvDownload.executionArn) {
      const intervalId = window.setInterval(() => {
        dispatch(PromotionActions.checkCsvDownloadStatus(intervalId));
      }, 3000);

      // 戻るボタンなどで遷移した場合にタイマーを停止
      return () => {
        window.clearInterval(intervalId);
        // 再度ダウンロードした場合に以前のExecutionArnが参照されないように削除
        dispatch(PromotionActions.clearCsvDownload());
        dispatch(AppActions.setLoading(false));
      };
    }
  }, [csvDownload, dispatch]);

  // 日付が不正な形式になっていないか
  const isValidDateRange = filter.isValidDateRange();

  const onClickCancel = () => {
    // 設定を初期化してからモーダルを閉じる
    setFilter(new FilterStatus());
    onClose();
  };

  return (
    <Modal size='tiny' open={open} onClose={() => onClose()}>
      <Modal.Content>
        <Title>CSVダウンロード</Title>
        <SubTitle>ダウンロード対象（投稿予定/下書きは含まれません）</SubTitle>
        <ContentWrapper>
          <ContentLabel>期間</ContentLabel>
          <RangeWrapper>
            <DateRangePicker
              startDate={filter.startDate ? filter.startDate.toDate() : null}
              endDate={filter.endDate ? filter.endDate.toDate() : null}
              onChange={onChangeDateRange}
              startPlaceholderText='開始日:未指定'
              endPlaceholderText='終了日:未指定'
              isClearable
            />
          </RangeWrapper>
          {!isValidDateRange && <ErrorLabel pointing>開始日は終了日より前の日付を指定してください</ErrorLabel>}
          <CheckboxInnerContent>
            <StyledCheckbox checked={filter.fullText} onChange={onChangeFullText} label='投稿の本文を全文含める' />
            <ContextHelpWrapper>
              <ContextHelp content='チェックしない場合、投稿の本文は先頭の30字だけダウンロードされます。' />
            </ContextHelpWrapper>
          </CheckboxInnerContent>
        </ContentWrapper>
        <ButtonWrapper>
          <CancelButton onClick={() => onClickCancel()}>キャンセル</CancelButton>
          <DownloadButton
            priority='high'
            disabled={!filter.isValid()}
            onClick={() => {
              startDownload();
            }}
          >
            ダウンロード
          </DownloadButton>
        </ButtonWrapper>
      </Modal.Content>
    </Modal>
  );
};

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0;
  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const RangeWrapper = styled.div`
  display: block;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const CancelButton = styled(Button)``;

const DownloadButton = styled(Button)`
  margin-left: 16px;
`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 14px;

  /* identical to box height, or 21px */
  color: ${COLOR.BLACK};
`;

const CheckboxInnerContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 15px;
    color: #707070;
    cursor: pointer;
  }
`;

const ContextHelpWrapper = styled.div`
  display: inline-block;
`;
