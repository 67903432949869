import ApiClient from 'ApiClient';
import { NotificationListResponse, NotificationResponse } from 'types/responses/Notification';

const apiClient = new ApiClient({});

const NOTIFICATION_BASE_PATH = '/notifications';
const NOTIFICATION_READ_PATH = (notificationId: number) => `${NOTIFICATION_BASE_PATH}/${String(notificationId)}/read`;
const NOTIFICATION_BULK_READ_PATH = `${NOTIFICATION_BASE_PATH}/read`;

export class NotificationListApi {
  static get(params = {}) {
    return apiClient.get<NotificationListResponse>(NOTIFICATION_BASE_PATH, params);
  }
}

export class NotificationReadApi {
  static post(notificationId: number) {
    return apiClient.post<NotificationResponse>(NOTIFICATION_READ_PATH(notificationId));
  }
}

export class NotificationBulkReadApi {
  static post(params = {}) {
    return apiClient.post<NotificationListResponse>(NOTIFICATION_BULK_READ_PATH, params);
  }
}
