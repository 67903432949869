import actionCreatorFactory from 'typescript-fsa';

import { GbpChecklist as Checklist } from 'models/Domain/GbpChecklist';
import { GbpChecklistSearchCondition as SearchCondition } from 'models/Domain/GbpChecklist/GpbChecklistSearchCondition';

const actionCreator = actionCreatorFactory('GbpChecklist');

export const GbpChecklistActions = {
  initializePage: actionCreator('initializePage'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  setSearchCondition: actionCreator<SearchCondition>('setSearchCondition'),
  commitSearchCondition: actionCreator<SearchCondition>('commitSearchCondition'),
  setChecklist: actionCreator<Checklist>('setChecklist'),
};
