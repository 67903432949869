import { Record, Set } from 'immutable';

import { numberComparator } from 'helpers/search';
import { Group } from 'types/Common';

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType, 'isAllStoreIds'>]: string;
} = {
  group: 'st',
  storeIds: 'si',
};

const DEFAULT_GROUP = null;
const DEFAULT_IS_ALL_STORE_IDS = true;
const DEFAULT_STORE_IDS = Set<number>();

type FilterStatusType = {
  group: Group;
  storeIds: Set<number>;
  isAllStoreIds: boolean;
};

export class FilterStatus extends Record<FilterStatusType>({
  group: DEFAULT_GROUP,
  storeIds: DEFAULT_STORE_IDS,
  isAllStoreIds: DEFAULT_IS_ALL_STORE_IDS,
}) {
  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({ storeIds, isAllStoreIds });
  }
}

export class GbpChecklistSearchCondition extends Record<{
  filter: FilterStatus;
}>({
  filter: new FilterStatus(),
}) {
  /**
   * ページのURLパラメータから条件を生成する
   * @param search URLパラメータ
   * @returns 検索条件
   */
  static fromURLSearchParams(search: string): GbpChecklistSearchCondition {
    const condition = new GbpChecklistSearchCondition();
    let { filter } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連
    const group = params.get(URLSearchParamsMapping.group);
    if (group) {
      if (group === 'all' || group === 'my_store') {
        // すべての店舗の場合
        filter = filter.set('group', group);
      } else if (group.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('group', parseInt(group, 10));
      }
    }

    const storeIds = params.get(URLSearchParamsMapping.storeIds) || 'all';
    if (storeIds === 'all') {
      filter = filter.setStoreIds(Set<number>(), true);
    } else {
      const values = storeIds
        .split(',')
        .filter((v) => v.match(/^\d+$/))
        .map((v) => parseInt(v, 10));
      filter = filter.setStoreIds(Set(values), false);
    }

    return condition.merge({ filter });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    // フィルタ関連
    if (this.filter.group != DEFAULT_GROUP) {
      params.append(URLSearchParamsMapping.group, String(this.filter.group));
    }
    if (!this.filter.isAllStoreIds && this.filter.storeIds.size > 0) {
      params.append(URLSearchParamsMapping.storeIds, this.filter.storeIds.toArray().sort(numberComparator).join(','));
    }

    // 見栄え悪いので、'%2Cは','に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  toRequestParams() {
    return {
      store_ids: this.filter.storeIds.join(',') || undefined,
    };
  }

  isValid(): boolean {
    return !this.filter.storeIds.isEmpty();
  }
}
