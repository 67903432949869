export const createHashtagText = (text: string) => {
  // eslint-disable-next-line no-useless-escape
  return text.replace(/[\s-\/:@\(\)\[\]\^\`\{\}\~【】（）「」『』［］〈〉《》〔〕“”‘’｛｝、。$]+/g, '');
};

export const extraceHashtag = (text: string) => {
  // ノーブレークスペースとBOMを空文字に置き換え
  const shapedText = text.replace(/\xa0|\ufeff/g, '');

  // #で始まり、_を除く記号で終わる部分をハッシュタグとして抽出
  // eslint-disable-next-line no-useless-escape
  const reg = /#([\S]+?)(?=[ -\/:@\(\)\[\]\^\`\{\}\~【】（）「」『』［］〈〉《》〔〕“”‘’｛｝、。$])|#([\S]+?)(?=$)/gm;
  const matches = shapedText.match(reg) || [];

  // #を削除
  return matches.map((hashtag) => hashtag.replace('#', ''));
};

export const createUniqueArray = (array: string[]) => {
  return array.filter((x, i, self) => self.indexOf(x) === i);
};

export const eachSlice = <T extends any[]>(arr: T, size: number): T[][] => {
  return arr.reduce((newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]), [] as T[][]);
};

export const validateEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const reg = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  return reg.test(email);
};

/**
 * HTTPもしくはHTTPS形式のURLか確認する
 * @param url
 */
export const validateWebsiteUrl = (url: string) => {
  // 元は全角文字を許容しない正規表現だったが、全角文字を許容するように変更
  // https://pathee.atlassian.net/browse/ST-2226
  // -> ドメイン名のバリデーションだけやる
  const urlReg = new RegExp(/^https?:\/\/\S+\.\S+(?:\/\S*)?$/);
  return urlReg.test(url);
};

export const validateInWebsiteUrl = (value: string) => {
  const regex = /https?:\/\/.+\..+(?:\/.*)?/g;
  const result = value.match(regex);
  return !!result;
};

/**
 * 正しい形式の電話番号であるかどうかを返す
 * @param phone 電話番号
 */
export const validatePhoneNumber = (phone: string) => {
  const replacePhone = phone.replace(/-/g, '');
  const checkPhone = /^0[0-9]{9,10}$/;
  return replacePhone.match(checkPhone) !== null;
};

/**
 * 郵便番号の形式チェック
 * @param postalCode
 */
export const validatePostalCode = (postalCode: string) => {
  const postalCodeReg = new RegExp(/^[0-9]{3}-[0-9]{4}$/);
  return postalCodeReg.test(postalCode);
};

/**
 * 都道府県のリストを取得する
 */
export const administrativeAreaOptions = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
].map((target) => {
  return {
    text: target,
    value: target,
  };
});

// 数字,文字列,空欄の順に並び替えるための比較用関数
export const sortComparator = (a: string | number, b: string | number) => {
  if (a === b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return a < b ? -1 : 1;
};

// ２つのセットが同一かどうかを返す
export const isSetsEqual = (a: Set<any>, b: Set<any>) =>
  a.size === b.size && Array.from(a).every((value) => b.has(value));

/**
 * 開始から終了までの連続した数値の配列を生成する
 * @param start 開始の値
 * @param stop 終了の値
 * @returns 数値配列
 */
export const range = (start: number, stop: number): number[] => [...Array(stop - start + 1)].map((_, i) => start + i);

/**
 * 配列の中から頻出の情報を返す
 */
export const getMostCommon = <T>(array: T[]) => {
  return array.sort((a, b) => array.filter((v) => v === a).length - array.filter((v) => v === b).length).pop();
};

/**
 *
 * @param array 配列をn個ずつの配列に分割する
 * @param n
 * @returns
 */
export const sliceArrayByNumber = <T>(array: T[], n: number) => {
  const results = [];
  const idx = 0;
  while (idx < array.length) {
    results.push(array.splice(idx, idx + n));
  }
  return results;
};

/**
 * 文字列に対してGoogleビジネスプロフィール上で編集するのと同じトリミング処理をかける
 * @param text 対象の文字列
 * @returns トリミングした文字列
 */
export const trimForGmbText = (text: string) =>
  text
    .replace(/\u3000+/g, ' ') // 全角スペースを半角に
    .replace(/ +/g, ' ') // 連続したスペースを1つの半角スペースに
    .replace(/\n{3,}/g, '\n\n') // 3つ以上の連続した改行は2つの改行に
    .trim(); // 文頭, 文末の不要な文字を削除

/**
 * STORECAST 利用ガイドへのリンク
 */
export const GUIDE_LINKS = {
  // Googleビジネスプロフィールに自動反映できない情報について
  gmbPatchFailed: 'https://www.notion.so/Google-58724c946e8b4233af95f3d67b9f7259',
  // 緯度経度がGoogleビジネスプロフィールとの差分として検出された場合
  handleLatlngDiff: 'https://www.notion.so/11e3be5ad43c45b7a37bbb298566cb75#e02c285f7cf442c4996cf413ebe9502c',
};

/**
 * ページタイトルを取得する
 * @param title
 */
export const getPageTitle = (title: string): string => {
  return `${title} | STORECAST`;
};

/** 数値を文字列に変換する */
export const convertNumberText = (value: number): string => {
  /*
    value < 1万 の場合は全桁表示する
    1万 <= n < 1億 の場合は万単位で小数点以下第2位まで表示する
    1億 <= n の場合は億単位で小数点以下第2位まで表示する

    例)
    1               1
    12              12
    123             123
    1,234           1,234
    12,345          1.23万
    123,456         12.34万
    1,234,567       123.45万
    12,345,678      1234.56万
    123,456,789     1.23億
    1,234,567,890   12.34億
   */

  // 小数点以下の桁数
  const digit = 2;

  if (value < 10000) {
    return `${value.toLocaleString()}`;
  } else if (value < 100000000) {
    // 万に変換する
    let returnValue = value / 10000;
    // toFixed()は値を四捨五入してしまうので、指定桁数までで切り捨てる
    returnValue = Math.floor(returnValue * Math.pow(10, digit)) / Math.pow(10, digit);
    return `${returnValue.toFixed(digit)}万`;
  } else {
    // 億に変換する
    let returnValue = value / 100000000;
    // toFixed()は値を四捨五入してしまうので、指定桁数までで切り捨てる
    returnValue = Math.floor(returnValue * Math.pow(10, digit)) / Math.pow(10, digit);
    return `${returnValue.toFixed(digit)}億`;
  }
};

/** 値を3桁ごとにカンマ区切りで小数第1位までのパーセント表示 nullならば「ー」 */
export const convertRateToText = (value: number | null) => {
  return value != null
    ? `${Math.abs(value).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}%`
    : 'ー';
};

export const nonNullable = <T>(value: T): value is NonNullable<T> => value != null;

export const addNullableValues = (...values: (number | null | undefined)[]): number | null => {
  const nonNullableValues = values.filter(nonNullable);
  if (nonNullableValues.length === 0) {
    return null;
  }
  return nonNullableValues.reduce((sum, v) => sum + v, 0);
};

export const divideNullableValue = (a: number | null | undefined, b: number | null | undefined) => {
  // aかbがnullの場合、または0で割ることになる場合は、null扱いにする
  return a == null || !b ? null : a / b;
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * 文字列から電話番号を抽出する
 * @param text
 * @returns
 */
export const extractPhoneNumber = (text: string) => {
  const patternString = [
    '(',
    '(?:(?:0(?:\\d{1}[-\\(]?\\d{4}|\\d{2}[-\\(]?\\d{3}|\\d{3}[-\\(]?\\d{2}|\\d{4}[-\\(]?\\d{1}|[5789]0[-\\(]?\\d{4})[-\\)]?))\\d{4}',
    // フリーダイヤル, ナビダイアル
    '|(?:0120|0570)[-\\(]?(?:\\d{2}[-\\)]?\\d{4}|\\d{3}[-\\)]?\\d{3}|\\d{4}[-\\)]?\\d{2})',
    ')',
  ].join('');

  const searchPattern = new RegExp(`(?:^|[^\\d])${patternString}(?:$|[^\\d])`, 'g');

  const phoneNumbers = new Set<string>();
  // 英数字と記号を半角に変換した上でマッチングし、マッチングした文字列の場所からワードを抽出することで
  // 全半角が混ざったパターンでも抽出できるようにしている。
  for (const match of Array.from(toHalfWidth(text).matchAll(searchPattern))) {
    if (match.index !== undefined) {
      const index = match.index + match[0].indexOf(match[1]);
      phoneNumbers.add(text.slice(index, index + match[1].length));
    }
  }
  return Array.from(phoneNumbers);
};

/**
 * 文字列の英数字と記号を半角に変換する
 */
export const toHalfWidth = (text: string) => {
  // 半角変換
  const halfVal = text.replace(/[！-～]/g, (char: string) => String.fromCharCode(char.charCodeAt(0) - 0xfee0));

  // 文字コードシフトで対応できない文字の変換
  return (
    halfVal
      .replace(/”/g, '"')
      .replace(/’/g, "'")
      .replace(/‘/g, '`')
      .replace(/￥/g, '\\')
      // eslint-disable-next-line no-irregular-whitespace
      .replace(/　/g, ' ')
      .replace(/〜/g, '~')
  );
};

/**
 * 指定したワードのうち、テキストに含まれるものを取得する
 *
 * @param text
 * @param words 検索するワード
 * @param ignoreCase 大文字小文字を無視するか
 * @param ignoreWordWidth 全角半角を無視するか
 * @returns
 */
export const extractContainWords = (text: string, words: string[], ignoreCase = true, ignoreWordWidth = true) => {
  // 元テキストをオプションによって変換する
  let modifiedText = text;
  if (ignoreCase) {
    modifiedText = modifiedText.toLowerCase();
  }
  if (ignoreWordWidth) {
    modifiedText = toHalfWidth(modifiedText);
  }

  const extractedWords = new Set<string>();
  for (const word of words) {
    // 検索対象のワードもオプションによって変換する
    let modifiedWord = word;
    if (ignoreCase) {
      modifiedWord = modifiedWord.toLowerCase();
    }
    if (ignoreWordWidth) {
      modifiedWord = toHalfWidth(modifiedWord);
    }

    // 存在する場合、元テキストから取得する
    const index = modifiedText.indexOf(modifiedWord);
    if (index >= 0) {
      extractedWords.add(text.slice(index, index + word.length));
    }
  }

  return Array.from(extractedWords);
};

/**
 * 0または正の整数かを返す
 * @param value
 */
export const isNonNegativeInteger = (value: string): boolean => {
  return /^(0|[1-9]\d*)$/.test(value);
};

/**
 * 文字数カウント（全角半角絵文字問わず見た目通りのカウント）
 * @param value
 */
export const countGraphemes = (value: string): number => {
  const segmenter = new Intl.Segmenter('ja', { granularity: 'grapheme' });
  const segments = segmenter.segment(value);
  return Array.from(segments).length;
};
