import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { MAX_DATE } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';

type SelectStoreList = 'all' | 'store_list' | 'store';

const DataTypeList = [
  'store_name',
  'store_id',
  'QUERIES_DIRECT',
  'QUERIES_INDIRECT',
  'QUERIES_CHAIN',
  'ACTIONS_WEBSITE',
  'ACTIONS_PHONE',
  'ACTIONS_DRIVING_DIRECTIONS',
  'MEDIA_VIEW_COUNT',
  'MEDIA_POST_COUNT',
  'RATE_COUNT',
  'COMMENT_COUNT',
] as const;

export type DataType = (typeof DataTypeList)[number];

type AggregateUnitType = 'daily' | 'weekly' | 'monthly';

export class GmbInsightDownloadModalState extends Record<{
  isOpen: boolean;
  select_store_type: SelectStoreList;
  store_list_id: number | null;
  store_id: number | null;
  aggregate_unit: AggregateUnitType;
  start_date: Dayjs;
  end_date: Dayjs;
  data_type: List<DataType>;
}>({
  isOpen: false,
  select_store_type: 'all',
  store_list_id: null,
  store_id: null,
  aggregate_unit: 'daily',
  start_date: MAX_DATE.subtract(13, 'day'),
  end_date: MAX_DATE,
  data_type: List(DataTypeList),
}) {
  get selectStoreTypeValue() {
    const { select_store_type, store_list_id, store_id } = this;
    if (select_store_type === 'all') {
      return 'all';
    }
    if (select_store_type === 'store_list') {
      return `store_list:${store_list_id}`;
    }
    if (select_store_type === 'store') {
      return `store:${store_id}`;
    }
    return '';
  }

  get isDataTypeAllSelected() {
    return this.data_type.size === DataTypeList.length;
  }

  get latestDate() {
    return dayjs().subtract(3, 'day');
  }

  get isValid() {
    const { data_type, start_date, end_date } = this;
    if (data_type.isEmpty()) {
      return false;
    }
    return !start_date.isAfter(end_date);
  }

  includekDataType(dataType: DataType) {
    const index = this.data_type.findIndex((data) => data === dataType);
    return index > -1;
  }

  getStoreOptions(storeLists: StoreLists, stores: Stores) {
    const allOption = { text: 'すべての店舗', value: 'all' };
    const storeListOptions = storeLists.list.map((storeList) => ({
      text: storeList.name,
      value: `store_list:${storeList.id}`,
    }));
    const storeOptions = stores
      .filterByIsConnectedGmb(true)
      .list.map((store) => ({ text: store.shortName, value: `store:${store.id}` }));
    return [allOption, ...storeListOptions.toArray(), ...storeOptions.toArray()];
  }

  changeSelectedStoreType(value: string) {
    const [select_store_type, id] = value.split(':');
    if (select_store_type === 'all') {
      return this.merge({ select_store_type, store_list_id: null, store_id: null });
    }
    if (select_store_type === 'store_list') {
      return this.merge({ select_store_type, store_list_id: Number(id), store_id: null });
    }
    if (select_store_type === 'store') {
      return this.merge({ select_store_type, store_list_id: null, store_id: Number(id) });
    }
    return this;
  }

  toggleDataType(dataType: DataType) {
    const { data_type } = this;
    const index = data_type.findIndex((data) => data === dataType);
    if (index > -1) {
      return this.set('data_type', data_type.delete(index));
    }
    return this.set('data_type', data_type.push(dataType));
  }

  changeIsOpen(isOpen: boolean) {
    return this.set('isOpen', isOpen);
  }

  toggleDataTypeAllSelect() {
    if (this.isDataTypeAllSelected) {
      return this.set('data_type', List());
    }
    return this.set('data_type', List(DataTypeList));
  }

  changeAggregateUnit(value: AggregateUnitType) {
    return this.set('aggregate_unit', value);
  }

  changeStartDate(dateString: string) {
    if (!dateString) {
      return this;
    }
    const [yearString, monthString, dayString] = dateString.split('-');
    let startDate = this.start_date
      .year(Number(yearString))
      .month(Number(monthString) - 1)
      .date(Number(dayString));

    const { latestDate } = this;
    if (latestDate.isBefore(startDate)) {
      startDate = latestDate;
    }
    return this.set('start_date', startDate);
  }

  changeEndDate(dateString: string) {
    if (!dateString) {
      return this;
    }
    const [yearString, monthString, dayString] = dateString.split('-');
    let endDate = this.start_date
      .year(Number(yearString))
      .month(Number(monthString) - 1)
      .date(Number(dayString));

    const { latestDate } = this;
    if (latestDate.isBefore(endDate)) {
      endDate = latestDate;
    }
    return this.set('end_date', endDate);
  }

  requestParams() {
    const { select_store_type, store_list_id, store_id, aggregate_unit, start_date, end_date, data_type } = this;
    return {
      select_store_type,
      store_list_id,
      store_id,
      aggregate_unit,
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD'),
      data_type: data_type.toArray(),
    };
  }
}
