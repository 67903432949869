import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ActiveCircle, ChartTooltip } from 'components/atoms/ChartTooltip';
import { legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import {
  DataKey,
  getComparisonDataKey as _getComparisonDataKey,
  getTargetDataKey as _getTargetDataKey,
  getValueText as _getValueText,
  isComparisonDataKey as _isComparisonDataKey,
  getDateLabelWithWeekOfDay,
} from 'helpers/graph';
import {
  AggregateMethod,
  ReviewType,
  StatsType,
  statsKeyMapping,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';

// 非活性のグラフの透明度
const INACTIVE_TRANSPARENCY = 0.8 as const;
// 最大のドット数
const MAX_DOTS = 50 as const;

// グラフに使われるStatsTypeのリスト
const reviewGraphStatsTypes = [
  'totalReviews',
  'totalCommentCount',
  'totalRateCount',
  'totalReplyCount',
  'totalAverageRating',
  'periodReviews',
  'periodCommentCount',
  'periodRateCount',
  'periodReplyCount',
  'periodAverageRating',
] satisfies StatsType[];

export type TargetDataKey = (typeof reviewGraphStatsTypes)[number];
export type GraphDataKey = DataKey<TargetDataKey>;

// 共通処理に型を指定する
const isComparisonDataKey = _isComparisonDataKey<TargetDataKey>;
const getTargetDataKey = _getTargetDataKey<TargetDataKey>;
const getComparisonDataKey = _getComparisonDataKey<TargetDataKey>;

export type ReviewGraphDataItem = Record<'date' | 'comparisonDate', string> &
  Record<GraphDataKey, number | null | undefined>;

const reviewColors = UNIVERSAL_COLORS.slice(0, 7).toArray();

/**
 * dataKeyから色を取得する
 * @param dataKey
 */
const getStrokeColor = (dataKey: GraphDataKey): string => {
  const key = getTargetDataKey(dataKey);
  switch (key) {
    case 'totalCommentCount':
    case 'periodCommentCount':
      return reviewColors[0];
    case 'totalRateCount':
    case 'periodRateCount':
      return reviewColors[1];
    case 'totalReplyCount':
    case 'periodReplyCount':
      return reviewColors[3];
    case 'totalAverageRating':
    case 'periodAverageRating':
      return reviewColors[2];
    default:
      throw new Error(`"${dataKey}" is unexpected dataKey`);
  }
};

/**
 * dateKeyの日本語表記を取得する
 * @param dataKey
 */
const getDataKeyLabel = (dataKey: GraphDataKey) => {
  const isComparison = isComparisonDataKey(dataKey);
  const label = statsKeyMapping[getTargetDataKey(dataKey)];
  return isComparison ? `${label}（比較）` : label;
};

const isRatingDataKey = (dataKey: GraphDataKey): boolean => {
  const key = getTargetDataKey(dataKey);
  switch (key) {
    case 'totalAverageRating':
    case 'periodAverageRating':
      return true;
    default:
      return false;
  }
};

type ReviewGraphProps = {
  graphData: ReviewGraphDataItem[];
  height: number;
  showComparisonGraph: boolean;
  displayStats: StatsType[];
  reviewType: ReviewType;
  aggregateMethod: AggregateMethod;
  onChangeActiveStats: (statsType: StatsType) => void;
  showInactiveLegend: boolean;
  reviewGraphSettings?: GraphSettings;
  ratingGraphSettings?: GraphSettings;
};

export const ReviewGraph = React.memo<ReviewGraphProps>(
  ({
    graphData,
    height,
    showComparisonGraph,
    displayStats,
    reviewType,
    aggregateMethod,
    onChangeActiveStats,
    showInactiveLegend,
    reviewGraphSettings = new GraphSettings(),
    ratingGraphSettings = new GraphSettings(),
  }) => {
    // ホバーしたときに強調表示するデータのキー
    const [activeDataKey, setActiveDataKey] = useState<TargetDataKey | null>(null);

    // ドットを表示するのはデータが50個まで
    const showDots = graphData.length <= MAX_DOTS;

    const commentCountKey: TargetDataKey = `${reviewType}CommentCount`;
    const rateCountKey: TargetDataKey = `${reviewType}RateCount`;
    const replyCountKey: TargetDataKey = `${reviewType}ReplyCount`;
    const averageRatingKey: TargetDataKey = `${reviewType}AverageRating`;

    const comparisonCommentCountKey = getComparisonDataKey(commentCountKey);
    const comparisonRateCountKey = getComparisonDataKey(rateCountKey);
    const comparisonReplyCountKey = getComparisonDataKey(replyCountKey);
    const comparisonAverageRatingKey = getComparisonDataKey(averageRatingKey);

    const reviewsYAxisSettings = useMemo((): { orientation: 'left' | 'right'; hide: boolean } => {
      return {
        orientation: 'left',
        hide:
          !displayStats.includes(commentCountKey) &&
          !displayStats.includes(rateCountKey) &&
          !displayStats.includes(replyCountKey),
      };
    }, [commentCountKey, displayStats, rateCountKey, replyCountKey]);

    const ratingYAxisSettings = useMemo((): { orientation: 'left' | 'right'; hide: boolean } => {
      return {
        orientation:
          displayStats.includes(commentCountKey) ||
          displayStats.includes(rateCountKey) ||
          displayStats.includes(replyCountKey)
            ? 'right'
            : 'left',
        hide: !displayStats.includes(averageRatingKey),
      };
    }, [averageRatingKey, commentCountKey, displayStats, rateCountKey, replyCountKey]);

    const getValueText = useCallback(
      (value: number | null | undefined, fractionDigits?: number) => {
        return _getValueText(value, fractionDigits ?? aggregateMethod === 'average' ? 1 : 0);
      },
      [aggregateMethod],
    );

    const getRatingText = useCallback((value: number | null | undefined) => {
      return _getValueText(value, 1);
    }, []);

    // レビュー数向けY軸
    const reviewsYAxisTickFormatter = useCallback((value) => {
      return _getValueText(value, 0);
    }, []);

    // 評価向けY軸
    const ratingYAxisTickFormatter = useCallback((value: number) => {
      return _getValueText(value, 1);
    }, []);

    const isActiveDataKey = useCallback(
      (dataKey: GraphDataKey) => {
        const targetDataKey = getTargetDataKey(dataKey);
        return activeDataKey == null || activeDataKey === targetDataKey;
      },
      [activeDataKey],
    );

    // 比較期間なしの場合に表示するツールチップ
    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        const payload = items[0]?.payload;
        const reviewsDataKey = `${reviewType}Reviews` as const;
        const reviews = payload?.[reviewsDataKey];
        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            <ChartTooltip.Item key={'total'}>
              <ChartTooltip.Label color={COLOR.BLACK}>クチコミ数（合計）</ChartTooltip.Label>
              <ChartTooltip.Value color={COLOR.BLACK}>{getValueText(reviews)}</ChartTooltip.Value>
            </ChartTooltip.Item>
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              const isRating = isRatingDataKey(dataKey);
              return (
                <ChartTooltip.Item key={dataKey}>
                  <ChartTooltip.Label color={itemColor}>
                    <ActiveCircle color={item.color} />
                    {item.name}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>
                    {isRating ? getRatingText(item.value) : getValueText(item.value)}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [getRatingText, getValueText, reviewType],
    );

    // 比較期間ありの場合に表示するツールチップ
    const renderComparisonTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        const payload = items[0]?.payload;
        const reviewsDataKey = `${reviewType}Reviews` as const;
        const reviews = payload?.[reviewsDataKey];
        const comparisonReviews = payload?.[getComparisonDataKey(reviewsDataKey)];

        let usedTitle = ImmutableSet<string>(); // 使用済みのタイトル

        return (
          <ChartTooltip>
            <React.Fragment key={'totalReviews'}>
              <ChartTooltip.Title color={COLOR.BLACK}>クチコミ数（合計）</ChartTooltip.Title>
              <ChartTooltip.Item>
                <ChartTooltip.Label color={COLOR.BLACK}>
                  {getDateLabelWithWeekOfDay(payload?.date) ?? ''}
                </ChartTooltip.Label>
                <ChartTooltip.Value color={COLOR.BLACK}>{getValueText(reviews)}</ChartTooltip.Value>
              </ChartTooltip.Item>
              <ChartTooltip.Item>
                <ChartTooltip.Label color={COLOR.BLACK}>
                  {getDateLabelWithWeekOfDay(payload?.comparisonDate) ?? ''}
                </ChartTooltip.Label>
                <ChartTooltip.Value color={COLOR.BLACK}>{getValueText(comparisonReviews)}</ChartTooltip.Value>
              </ChartTooltip.Item>
            </React.Fragment>
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const targetDataKey = getTargetDataKey(dataKey);
              const isComparison = isComparisonDataKey(dataKey);
              const title = getDataKeyLabel(targetDataKey);
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const date = getDateLabelWithWeekOfDay(item.payload.date);
              const comparisonDate = getDateLabelWithWeekOfDay(item.payload.comparisonDate);
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              const isRating = isRatingDataKey(dataKey);
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && (
                    <ChartTooltip.Title color={itemColor}>
                      <ActiveCircle color={item.color} />
                      {title}
                    </ChartTooltip.Title>
                  )}
                  <ChartTooltip.Item>
                    <ChartTooltip.Label color={textColor}>{isComparison ? comparisonDate : date}</ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>
                      {isRating ? getRatingText(item.value) : getValueText(item.value)}
                    </ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
          </ChartTooltip>
        );
      },
      [getRatingText, getValueText, reviewType],
    );

    const getColor = useCallback(
      (dataKey: TargetDataKey) => {
        return transparentize(isActiveDataKey(dataKey) ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(dataKey));
      },
      [isActiveDataKey],
    );

    const handleOnMouseEnter = useCallback(
      (value: TargetDataKey | null) => () => {
        setActiveDataKey(value);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveDataKey(null);
    }, []);

    return (
      <ResponsiveContainer height={height}>
        <ComposedChart
          data={graphData}
          margin={{ top: 64, right: 32, left: 32, bottom: 16 }}
          onMouseLeave={() => setActiveDataKey(null)}
        >
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            onClick={(o: any) => onChangeActiveStats(getTargetDataKey(o.dataKey))}
            content={(props: any) => renderLegend({ ...props, showInactiveLegend })}
          />
          <XAxis dataKey={'date'} tickMargin={16} interval={'equidistantPreserveStart'} />
          <YAxis
            yAxisId={'reviews'}
            orientation={reviewsYAxisSettings.orientation}
            label={{ value: 'クチコミ数', position: 'top', offset: 24 }}
            tickFormatter={reviewsYAxisTickFormatter}
            allowDecimals={false}
            hide={reviewsYAxisSettings.hide}
            allowDataOverflow={true}
            domain={reviewGraphSettings.domain}
          />
          <YAxis
            yAxisId={'rating'}
            orientation={ratingYAxisSettings.orientation}
            label={{ value: '評価', position: 'top', offset: 24 }}
            tickFormatter={ratingYAxisTickFormatter}
            ticks={[1, 2, 3, 4, 5]}
            hide={ratingYAxisSettings.hide}
            allowDataOverflow={true}
            domain={ratingGraphSettings.ratingDomain}
          />
          <CartesianGrid vertical={false} />
          <Area
            dataKey={commentCountKey}
            stroke={getColor(commentCountKey)}
            fill={getColor(commentCountKey)}
            fillOpacity={0.4}
            strokeWidth={2}
            yAxisId={'reviews'}
            stackId={'reviews'}
            isAnimationActive={false}
            name={getDataKeyLabel(commentCountKey)}
            hide={!displayStats.includes(commentCountKey)}
          />
          {showComparisonGraph && (
            <Area
              dataKey={comparisonCommentCountKey}
              stroke={getColor(commentCountKey)}
              fill={getColor(commentCountKey)}
              fillOpacity={0.2}
              strokeWidth={2}
              strokeDasharray={'5 5'}
              yAxisId={'reviews'}
              stackId={'comparisonReviews'}
              isAnimationActive={false}
              name={getDataKeyLabel(commentCountKey)}
              hide={!displayStats.includes(commentCountKey)}
            />
          )}
          <Area
            dataKey={rateCountKey}
            stroke={getColor(rateCountKey)}
            fill={getColor(rateCountKey)}
            fillOpacity={0.4}
            strokeWidth={2}
            yAxisId={'reviews'}
            stackId={'reviews'}
            isAnimationActive={false}
            name={getDataKeyLabel(rateCountKey)}
            hide={!displayStats.includes(rateCountKey)}
          />
          {showComparisonGraph && (
            <Area
              dataKey={comparisonRateCountKey}
              stroke={getColor(rateCountKey)}
              fill={getColor(rateCountKey)}
              fillOpacity={0.2}
              strokeWidth={2}
              strokeDasharray={'5 5'}
              yAxisId={'reviews'}
              stackId={'comparisonReviews'}
              isAnimationActive={false}
              name={getDataKeyLabel(comparisonRateCountKey)}
              hide={!displayStats.includes(rateCountKey)}
            />
          )}
          <Line
            dataKey={replyCountKey}
            stroke={getColor(replyCountKey)}
            strokeWidth={2}
            yAxisId={'reviews'}
            isAnimationActive={false}
            name={getDataKeyLabel(replyCountKey)}
            onMouseEnter={handleOnMouseEnter(replyCountKey)}
            onMouseLeave={handleOnMouseLeave}
            dot={showDots ? { fill: getColor(replyCountKey), r: 2, clipDot: false } : false}
            activeDot={{
              cursor: 'pointer',
              onMouseEnter: handleOnMouseEnter(replyCountKey),
              onMouseLeave: handleOnMouseLeave,
            }}
            cursor={'pointer'}
            hide={!displayStats.includes(replyCountKey)}
          />
          {showComparisonGraph && (
            <Line
              dataKey={comparisonReplyCountKey}
              stroke={getColor(replyCountKey)}
              strokeWidth={2}
              strokeDasharray={'5 5'}
              yAxisId={'reviews'}
              isAnimationActive={false}
              name={getDataKeyLabel(comparisonReplyCountKey)}
              onMouseEnter={handleOnMouseEnter(replyCountKey)}
              onMouseLeave={handleOnMouseLeave}
              dot={showDots ? { fill: getColor(replyCountKey), r: 2, clipDot: false } : false}
              activeDot={{
                cursor: 'pointer',
                onMouseEnter: handleOnMouseEnter(replyCountKey),
                onMouseLeave: handleOnMouseLeave,
              }}
              cursor={'pointer'}
              hide={!displayStats.includes(replyCountKey)}
            />
          )}
          {reviewType === 'period' && (
            <Bar
              dataKey={averageRatingKey}
              stroke={getColor(averageRatingKey)}
              strokeWidth={2}
              fill={getColor(averageRatingKey)}
              fillOpacity={0.6}
              yAxisId={'rating'}
              isAnimationActive={false}
              name={getDataKeyLabel(averageRatingKey)}
              barSize={10}
              hide={!displayStats.includes(averageRatingKey)}
            />
          )}
          {reviewType === 'period' && showComparisonGraph && (
            <Bar
              dataKey={comparisonAverageRatingKey}
              stroke={getColor(averageRatingKey)}
              strokeWidth={2}
              fill={'#FFE168'}
              fillOpacity={0.6}
              yAxisId={'rating'}
              isAnimationActive={false}
              name={getDataKeyLabel(comparisonAverageRatingKey)}
              barSize={10}
              hide={!displayStats.includes(averageRatingKey)}
            />
          )}

          {reviewType === 'total' && (
            <Line
              dataKey={averageRatingKey}
              stroke={getColor(averageRatingKey)}
              strokeWidth={3}
              yAxisId={'rating'}
              isAnimationActive={false}
              name={getDataKeyLabel(averageRatingKey)}
              onMouseEnter={handleOnMouseEnter(averageRatingKey)}
              onMouseLeave={handleOnMouseLeave}
              dot={showDots ? { fill: getColor(averageRatingKey), r: 2, clipDot: false } : false}
              activeDot={{
                cursor: 'pointer',
                onMouseEnter: handleOnMouseEnter(averageRatingKey),
                onMouseLeave: handleOnMouseLeave,
              }}
              cursor={'pointer'}
              hide={!displayStats.includes(averageRatingKey)}
            />
          )}
          {reviewType === 'total' && showComparisonGraph && (
            <Line
              dataKey={comparisonAverageRatingKey}
              stroke={getColor(averageRatingKey)}
              strokeWidth={3}
              strokeDasharray={'5 5'}
              yAxisId={'rating'}
              isAnimationActive={false}
              name={getDataKeyLabel(comparisonAverageRatingKey)}
              onMouseEnter={handleOnMouseEnter(averageRatingKey)}
              onMouseLeave={handleOnMouseLeave}
              dot={showDots ? { fill: getColor(averageRatingKey), r: 2, clipDot: false } : false}
              activeDot={{
                cursor: 'pointer',
                onMouseEnter: handleOnMouseEnter(averageRatingKey),
                onMouseLeave: handleOnMouseLeave,
              }}
              cursor={'pointer'}
              hide={!displayStats.includes(averageRatingKey)}
            />
          )}

          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            content={showComparisonGraph ? renderComparisonTooltip : renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
);
