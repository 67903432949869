import dayjs, { Dayjs } from 'dayjs';
import { Record, Set } from 'immutable';

import { GbpInsightGraphRequestParams, GbpInsightSummaryRequestParams } from 'ApiClient/GmbApi';
import { booleanToSearchParam, numberComparator, parseBooleanParam, parseDateParameter } from 'helpers/search';
import { JSObject } from 'types/Common';

export type Group = number | 'all' | 'my_store' | null;
export type AggregateUnit = 'day' | 'week' | 'month';

export type SearchConditionType = {
  // 選択されているグループ
  group: Group;
  // 選択されている店舗ID
  store_ids: Set<number>;
  // store_ids が group で指定されているグループの全ての店舗か
  is_all_store_ids: boolean;
  // 閉店店舗を表示するか
  show_closed_store: boolean;
  // 集計単位
  aggregateUnit: AggregateUnit;
  // 集計期間（いつから）
  startDate: Dayjs;
  // 集計期間（いつまで）
  endDate: Dayjs;
  // 比較が有効か
  isEnabledComparison: boolean;
  // 比較期間（いつから）
  comparisonStartDate: Dayjs;
  // 比較期間（いつまで）
  comparisonEndDate: Dayjs;
};

/**
 * 期間の文字列を生成する
 * @param startDate 開始日
 * @param endDate 終了日
 * @returns 期間の文字列
 */
const createPeriodText = (startDate: Dayjs, endDate: Dayjs) => {
  const startDateStr = startDate.format('YYYY/MM/DD');
  const endDateStr = endDate.format('YYYY/MM/DD');
  return startDateStr === endDateStr ? startDateStr : `${startDateStr}〜${endDateStr}`;
};

// 集計期間に指定可能な最も新しい日付（GoogleのAPI提供終了日以降は取得できない）
export const MAX_DATE = dayjs('2023/02/20');

// デフォルト値
const DEFAULT_GROUP_VALUE = null;
const DEFAULT_IS_ALL_STORE_IDS_VALUE = true;
const DEFAULT_SHOW_CLOSED_STORE_VALUE = false;
const DEFAULT_AGGREGATE_UNIT_VALUE = 'day';
const DEFAULT_START_DATE_VALUE = MAX_DATE.subtract(6, 'day');
const DEFAULT_END_DATE_VALUE = MAX_DATE;
const DEFAULT_COMPARISON_START_DATE_VALUE = MAX_DATE.subtract(6 + 7, 'day');
const DEFAULT_COMPARISON_END_DATE_VALUE = MAX_DATE.subtract(7, 'day');

// URLパラメータのマッピング
const URLSearchParamsMapping = {
  group: 'gr',
  storeIds: 'si',
  showClosedStore: 'cs',
  aggregateUnit: 'au',
  startDate: 'sd',
  endDate: 'ed',
  comparisonStartDate: 'csd',
  comparisonEndDate: 'ced',
};

/**
 * GBPインサイトの集計条件
 */
export class GbpInsightSearchCondition extends Record<SearchConditionType>({
  group: DEFAULT_GROUP_VALUE,
  store_ids: Set<number>(),
  is_all_store_ids: DEFAULT_IS_ALL_STORE_IDS_VALUE,
  show_closed_store: DEFAULT_SHOW_CLOSED_STORE_VALUE,
  aggregateUnit: DEFAULT_AGGREGATE_UNIT_VALUE,
  startDate: DEFAULT_START_DATE_VALUE,
  endDate: DEFAULT_END_DATE_VALUE,
  isEnabledComparison: false,
  comparisonStartDate: DEFAULT_COMPARISON_START_DATE_VALUE,
  comparisonEndDate: DEFAULT_COMPARISON_END_DATE_VALUE,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get targetPeriod(): string {
    return createPeriodText(this.startDate, this.endDate);
  }

  get comparisonPeriod(): string {
    return createPeriodText(this.comparisonStartDate, this.comparisonEndDate);
  }

  changeGroup(group: Group) {
    return this.set('group', group);
  }

  changeStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({
      store_ids: storeIds,
      is_all_store_ids: isAllStoreIds,
    });
  }

  changeShowClosedStore(showClosedStore: boolean) {
    return this.set('show_closed_store', showClosedStore);
  }

  changeAggregateUnit(aggregateUnit: AggregateUnit) {
    return this.set('aggregateUnit', aggregateUnit);
  }

  changeStartDate(date: Dayjs) {
    return this.set('startDate', date);
  }

  changeEndDate(date: Dayjs) {
    return this.set('endDate', date);
  }

  isValid(): boolean {
    return this.isValidStoreIds();
  }

  isValidStoreIds(): boolean {
    return this.store_ids.size > 0;
  }

  /**
   * GBPInsightGraphAPIのリクエストパラメータを生成する
   * @returns GBPInsightGraphAPIのリクエストパラメータ
   */
  toGraphRequestParams(): GbpInsightGraphRequestParams {
    const params = {
      unit: this.aggregateUnit,
      from_date: this.startDate.format('YYYY-MM-DD'),
      to_date: this.endDate.format('YYYY-MM-DD'),
    };

    return this.store_ids.size > 0 ? { ...params, store_ids: this.store_ids.toArray() } : params;
  }

  /**
   * GBPInsightSummaryAPIのリクエストパラメータを生成する
   * @returns GBPInsightSummaryAPIのリクエストパラメータ
   */
  toSummaryRequestParams(): GbpInsightSummaryRequestParams {
    const params = {
      from_date: this.startDate.format('YYYY-MM-DD'),
      to_date: this.endDate.format('YYYY-MM-DD'),
    };

    return this.store_ids.size > 0 ? { ...params, store_ids: this.store_ids.toArray() } : params;
  }

  /**
   * 集計条件からページのURLパラメータを生成する
   * @returns ページのURLパラメータ
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    if (this.group != DEFAULT_GROUP_VALUE) {
      params.append(URLSearchParamsMapping.group, String(this.group));
    }

    if (!this.is_all_store_ids && this.store_ids.size > 0) {
      params.append(URLSearchParamsMapping.storeIds, this.store_ids.toArray().sort(numberComparator).join(','));
    }

    if (this.show_closed_store != DEFAULT_SHOW_CLOSED_STORE_VALUE) {
      params.append(URLSearchParamsMapping.showClosedStore, booleanToSearchParam(this.show_closed_store));
    }

    params.append(URLSearchParamsMapping.aggregateUnit, this.aggregateUnit);
    params.append(URLSearchParamsMapping.startDate, this.startDate.format('YYYY-MM-DD'));
    params.append(URLSearchParamsMapping.endDate, this.endDate.format('YYYY-MM-DD'));

    if (this.isEnabledComparison) {
      params.append(URLSearchParamsMapping.comparisonStartDate, this.comparisonStartDate.format('YYYY-MM-DD'));
      params.append(URLSearchParamsMapping.comparisonEndDate, this.comparisonEndDate.format('YYYY-MM-DD'));
    }

    // 見栄え悪いので '%2C' は ',' に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * ページのURLパラメータから集計条件を生成する
   * @param search URLパラメータ
   * @returns 集計条件
   */
  static fromURLSearchParams(search: string): GbpInsightSearchCondition {
    let searchCondition = new GbpInsightSearchCondition();
    const params = new URLSearchParams(search);

    const group = params.get(URLSearchParamsMapping.group);
    if (group) {
      if (group === 'all' || group === 'my_store') {
        // 全ての店舗、または、担当の店舗の場合
        searchCondition = searchCondition.changeGroup(group);
      } else if (group.match(/^\d+$/)) {
        // グループのIDの場合
        searchCondition = searchCondition.changeGroup(parseInt(group, 10));
      }
    }

    const storeIdsStr = params.get(URLSearchParamsMapping.storeIds) || 'all';
    if (storeIdsStr === 'all') {
      searchCondition = searchCondition.changeStoreIds(Set<number>(), true);
    } else {
      const storeIds = storeIdsStr
        .split(',')
        .filter((storeId) => storeId.match(/^\d+$/))
        .map((storeId) => parseInt(storeId, 10));
      searchCondition = searchCondition.changeStoreIds(Set(storeIds), false);
    }

    searchCondition = searchCondition.changeShowClosedStore(
      parseBooleanParam(params.get(URLSearchParamsMapping.showClosedStore), DEFAULT_SHOW_CLOSED_STORE_VALUE),
    );

    const aggregateUnit = params.get(URLSearchParamsMapping.aggregateUnit);
    if (aggregateUnit && ['day', 'week', 'month'].includes(aggregateUnit)) {
      searchCondition = searchCondition.changeAggregateUnit(aggregateUnit as AggregateUnit);
    }

    const startDateStr = parseDateParameter(params.get(URLSearchParamsMapping.startDate));
    const endDateStr = parseDateParameter(params.get(URLSearchParamsMapping.endDate));
    if (startDateStr && endDateStr) {
      const startDate = dayjs(startDateStr);
      const endDate = dayjs(endDateStr);
      if (
        startDate.isValid() &&
        endDate.isValid() &&
        startDate.isSameOrBefore(endDate) &&
        endDate.isSameOrBefore(DEFAULT_END_DATE_VALUE)
      ) {
        searchCondition = searchCondition.changeStartDate(startDate);
        searchCondition = searchCondition.changeEndDate(endDate);
      }
    }

    const comparisonStartDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonStartDate));
    const comparisonEndDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonEndDate));
    if (comparisonStartDateStr && comparisonEndDateStr) {
      const comparisonStartDate = dayjs(comparisonStartDateStr);
      const comparisonEndDate = dayjs(comparisonEndDateStr);
      if (
        comparisonStartDate.isValid() &&
        comparisonEndDate.isValid() &&
        comparisonStartDate.isSameOrBefore(comparisonEndDate) &&
        comparisonEndDate.isSameOrBefore(DEFAULT_END_DATE_VALUE)
      ) {
        searchCondition = searchCondition.merge({ comparisonStartDate, comparisonEndDate, isEnabledComparison: true });
      }
    }

    return searchCondition;
  }
}
