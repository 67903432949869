import React from 'react';

import { Set } from 'immutable';
import { Checkbox, Label, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

export type Props = {
  className?: string;
  stores: Stores;
  selectedStoreIds: Set<number>;
  canSelectStore: boolean;
  onChangeAllSelect: () => void;
  onChangeStoreSelect: (id: number) => void;
  storeDetailLink: string;
};

/**
 * 店舗一覧のPC版に表示するテーブル形式の店舗情報
 */
export const StoreTable: React.FC<Props> = ({
  className,
  stores,
  selectedStoreIds,
  canSelectStore,
  onChangeAllSelect,
  onChangeStoreSelect,
  storeDetailLink,
}) => {
  // 全ての店舗が選択中かどうか
  const allChecked = !stores.isEmpty && Set(stores.getStoreIds()).subtract(selectedStoreIds).size === 0;

  return (
    <Wrapper className={className}>
      <Table selectable>
        <TableHeader>
          <Table.Row>
            <StyledHeaderCell>
              {canSelectStore && (
                <DesktopOnly>
                  <StyledCheckbox onChange={onChangeAllSelect} checked={allChecked} />
                </DesktopOnly>
              )}
            </StyledHeaderCell>
            <StyledHeaderCell>GBP</StyledHeaderCell>
            <StyledHeaderCell>店舗名</StyledHeaderCell>
            <StyledHeaderCell>店舗コード</StyledHeaderCell>
            <StyledHeaderCell>営業ステータス</StyledHeaderCell>
            <StyledHeaderCell>
              <DesktopOnly>住所</DesktopOnly>
            </StyledHeaderCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {stores.list.map((store, i) => {
            const {
              id,
              code,
              fullName,
              is_connected_gmb,
              location: { address, openInfo },
              location_state,
            } = store;
            const isGMBError = is_connected_gmb && location_state.isGmbError;
            const checked = selectedStoreIds.includes(id);
            return (
              <StyledTableRow
                isError={isGMBError}
                key={i}
                checked={checked}
                onClick={() => canSelectStore && onChangeStoreSelect(id)}
              >
                <StyledCell>
                  {canSelectStore && (
                    <DesktopOnly>
                      <StyledCheckbox onChange={() => onChangeStoreSelect(id)} checked={checked} />
                    </DesktopOnly>
                  )}
                </StyledCell>
                <StyledCell>
                  <GMBLabel hasError={isGMBError} isConnected={is_connected_gmb}>
                    <GMBPrefix>GBP</GMBPrefix>
                    {isGMBError ? '要確認' : is_connected_gmb ? '連携中' : '未連携'}
                  </GMBLabel>
                </StyledCell>
                <StyledCell>
                  <DetailLink onClick={(e) => e.stopPropagation()} to={storeDetailLink.replace(':storeId', String(id))}>
                    {fullName}
                  </DetailLink>
                </StyledCell>
                <StyledCell>{code}</StyledCell>
                <StyledCell>
                  <BusinessStatus isOpen={openInfo.isOpen}>{openInfo.statusName}</BusinessStatus>
                </StyledCell>
                <StyledCell>{address.searchWord}</StyledCell>
              </StyledTableRow>
            );
          })}
        </Table.Body>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: scroll;
`;

const StyledHeaderCell = styled(Table.HeaderCell)`
  &&& {
    background-color: ${COLOR.LIGHT_GRAY};
    color: #757575;
    white-space: nowrap;
  }
`;

const StyledTableRow = styled(Table.Row)<{ checked: boolean; isError: boolean }>`
  &&& {
    cursor: pointer;
    position: relative;

    /* 選択やhoverした列全体の背景色を、選択状態によって変更する */

    > td {
      background: ${({ checked, isError }) =>
        checked ? 'rgba(207, 242, 230, 0.6)' : isError ? 'rgba(247, 216, 216, 0.4)' : COLOR.WHITE};
    }

    &:hover {
      > td {
        background: ${({ checked, isError }) =>
          checked ? 'rgba(207, 242, 230, 1)' : isError ? 'rgba(247, 216, 216, 1.0)' : 'rgba(255, 255, 255, 0.08)'};
      }
    }

    @media (max-width: ${SIZE.MOBILE_TABLE}) {
      /* セルの色は!importantで設定されているので、!importantで上書きしないと効かない */
      &:not(.unstackable) {
        padding: 4px 16px;
      }

      &:hover:not(.unstackable) {
        > td {
          background: transparent !important;
        }
      }
    }
  }
`;

const StyledLabel = styled(Label)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    white-space: nowrap;
    width: 76px;
    font-weight: normal;
  }
`;

const GMBLabel = styled(StyledLabel)<{ hasError: boolean; isConnected: boolean }>`
  &&& {
    background-color: ${({ isConnected, hasError }) =>
      hasError ? COLOR.ERROR : isConnected ? COLOR.GOOGLE : COLOR.LIGHT_GRAY};
    color: ${({ isConnected, hasError }) => (isConnected || hasError ? COLOR.WHITE : '#757575')};
    width: 60px;
    font-size: 12px;

    @media (max-width: ${SIZE.MOBILE_TABLE}) {
      width: 84px;
    }
  }
`;

const GMBPrefix = styled.span`
  /* PCではヘッダに「GBP」と表示されていて冗長なので表示しない */
  display: none;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    display: inline-block;
  }
`;

const BusinessStatus = styled.div<{ isOpen: boolean }>`
  &&& {
    color: ${({ isOpen }) => (isOpen ? '#757575' : COLOR.PURPLE)};
  }
`;

const StyledCell = styled(Table.Cell)`
  &&& {
    color: ${COLOR.BLACK};
    vertical-align: middle;
    white-space: nowrap;
    font-size: 14px;

    @media (max-width: ${SIZE.MOBILE_TABLE}) {
      white-space: break-spaces;
      font-size: 12px;
    }
  }
`;

const DetailLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
`;

const TableHeader = styled(Table.Header)`
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    &&& {
      /* モバイル表示時はテーブルのヘッダを表示しない */
      /* !important書かないと効かないレベルでCSSががんじがらめ。 */
      /* ただ、このスタイルの発動条件は、かなり限定的なので影響はない */
      display: none !important;
    }
  }
`;

const DesktopOnly = styled.div`
  /* PCのみ表示する */
  display: inline-block;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    display: none;
  }
`;

const CHECKBOX_SIZE = '16px';

const StyledCheckbox = styled(Checkbox)`
  &&& {
    min-width: ${CHECKBOX_SIZE};
    input {
      &:checked ~ label:before {
        background: ${COLOR.GREEN};
        border: none;
      }
      &:checked ~ label:after {
        color: ${COLOR.WHITE};
        font-size: 12px;
        font-weight: normal;
      }
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        width: ${CHECKBOX_SIZE};
        height: ${CHECKBOX_SIZE};
        top: 4px;
        left: 4px;
      }
      &:after {
        width: ${CHECKBOX_SIZE};
        height: ${CHECKBOX_SIZE};
        top: 4px;
        font-size: 20px;
        left: 4px;
      }
    }
  }
`;

export default React.memo(StoreTable);
