import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { toast } from 'react-semantic-toasts';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { AccountApi, AccountsApi } from 'ApiClient/AccountApi';
import { GmbLocationDiffsSummaryApi } from 'ApiClient/GmbApi';
import CONFIG from 'config';
import ChannelService from 'helpers/ChannelService';
import { pushDataLayer } from 'helpers/gtm';
import { pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { getStorageItem, removeStorageItem, setStorageItem } from 'helpers/storage';
import { eachSlice } from 'helpers/utils';
import { AccountList, AccountSingleton } from 'models/Domain/AccountList';
import { GmbLocationDiffs } from 'models/Domain/GmbLocationDiffs';
import { User } from 'models/Domain/User';
import { getMenuSummary } from 'modules/menu/sagas';
import { State } from 'modules/reducers';
import { getServiceSummary } from 'modules/service/sagas';
import { getStores } from 'modules/store/sagas';
import { getStoreList } from 'modules/storeList/sagas';
import { getUserList } from 'modules/user/sagas';
import revision from 'revision.json';
import { Path } from 'routes';

import { getOfferActivity } from '../offer/sagas';

import { AppActions } from './actions';
import {
  LAST_LOGIN_ORGANIZATION_ID_STORAGE_KEY,
  ORGANIZATION_ID_QUERY_KEY,
  SESSION_ORGANIZATION_ID_STORAGE_KEY,
} from './const';

const revisionUrl = CONFIG.REVISION_URL;

export default function* saga() {
  yield takeLatest(AppActions.initialize, initialize);
  yield takeLatest(AppActions.moveTo, moveTo);
  yield takeLatest(AppActions.getCurrentUser, getCurrentUser);
  yield takeLatest(AppActions.signOut, signOut);
  yield takeLatest(AppActions.getGmbLocationDiffs, getGmbLocationDiffs);
  yield takeLatest(AppActions.watchLatestRevision, watchLatestRevision);
  yield takeLatest(AppActions.changeCurrentAccount, changeCurrentAccount);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initialize(_: ReturnType<typeof AppActions.initialize>) {
  const isInitialized: boolean = yield select((state: State) => state.app.isInitialized);
  // 実行済みの場合は何もせず終了
  if (isInitialized) {
    return;
  }

  yield put(AppActions.setIsInitialized(true));
  yield put(AppActions.setLogoLoading(true));

  // アカウント一覧の取得および、クエリパラメータ・ローカルストレージからの組織IDの初期化
  yield call(initializeAccount);

  // 必須のグローバルなデータ取得を先に行う
  yield all([getCurrentUser(), getStores(), getStoreList()]);
  yield put(AppActions.setLogoLoading(false));

  // サイドメニューのデータなど後読みで良いデータを取得
  const user: User = yield select((state: State) => state.app.currentUser);
  const subDataFunctions: any[] = [getUserList(), getOfferActivity(), getGmbLocationDiffs()];
  if (user.organization?.canUseService()) {
    subDataFunctions.push(getServiceSummary());
  }
  if (user.organization?.canUseMenu()) {
    subDataFunctions.push(getMenuSummary());
  }

  // 4つごとに分けてリクエストを投げる
  for (const functions of eachSlice(subDataFunctions, 4)) {
    const user: User = yield select((state: State) => state.app.currentUser);
    if (user.id === 0) continue; // ログアウトしたら停止する
    yield all(functions);
  }
}

/**
 * アカウント一覧の取得および、クエリパラメータ・ローカルストレージからの組織IDの初期化
 */
function* initializeAccount() {
  // アカウント一覧の取得
  yield call(getAccounts);

  const accountList: AccountList = yield select((state: State) => state.app.accountList);

  // クエリパラメータで指定された組織ID(最も優先度が高い)
  const organizationIdFromQuery = extractOrganizationIdFromQuery();

  // セッションで利用されている組織ID(2番目の優先度、そのタブ内でのみ保持される)
  const sessionOrganizationId = getSessionOrganization();
  // 最終ログインの組織ID(上記2つがない場合に利用される)
  const lastLoginOrganizationId = getLastLoginOrganization();

  let organizationId: number | null = null;
  if (organizationIdFromQuery && accountList.contains(organizationIdFromQuery)) {
    // クエリパラメータに組織IDがあり、その組織に所属している場合
    organizationId = organizationIdFromQuery;
  } else if (sessionOrganizationId && accountList.contains(sessionOrganizationId)) {
    // セッションストレージにセッションでログインしている組織IDがあり、その組織に所属している場合
    organizationId = sessionOrganizationId;
  } else if (lastLoginOrganizationId && accountList.contains(lastLoginOrganizationId)) {
    // ローカルストレージに最後にログインした組織IDがあり、その組織に所属している場合
    organizationId = lastLoginOrganizationId;
  }

  // 値がある場合はその組織IDのアカウントを、なければリストの最初のアカウントを選択する
  const account = (organizationId && accountList.find(organizationId)) || accountList.first();
  if (account) {
    yield put(AppActions.setCurrentAccount(account));
    // ログイン中の組織IDをAPIのヘッダーに含めるために、組織IDを別に保存しておく
    AccountSingleton.getInstance().set(account.organizationId);

    // セッションでログインしている組織に保存
    setStorageItem(SESSION_ORGANIZATION_ID_STORAGE_KEY, account.organizationId, {
      isGlobal: true,
      storageType: 'session',
    });

    // 最終ログインIDがない場合は、最終ログインIDに保存
    if (!lastLoginOrganizationId) {
      setStorageItem(LAST_LOGIN_ORGANIZATION_ID_STORAGE_KEY, account.organizationId, { isGlobal: true });
    }
  }

  // 組織ID付きのURLに遷移する(URLに組織IDが含まれない場合)
  yield put(replaceWithOrganizationId());
}

function* moveTo(action: ReturnType<typeof AppActions.moveTo>) {
  const path = action.payload;
  // string | LocationDescriptorObject<unknown> を分解する必要があるので、
  // 一見無意味だけどif文で分割した上で、同じ構文で処理する
  if (typeof path === 'string') {
    yield put(pushWithOrganizationId(path)); // stringの場合
  } else {
    yield put(pushWithOrganizationId(path)); // LocationDescriptorObjectの場合
  }
}

function* getAccounts() {
  const response: YieldReturn<typeof AccountsApi.get> = yield AccountsApi.get();
  if (response.isSuccess) {
    const accountList = AccountList.fromJSON(response.data);
    yield put(AppActions.setAccountList(accountList));
  } else {
    toast({
      type: 'error',
      title: 'アカウント一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getCurrentUser() {
  const response: YieldReturn<typeof AccountApi.get> = yield AccountApi.get();

  if (response.isSuccess) {
    yield put(AppActions.setCurrentUser(new User(response.data)));

    const currentUser = new User(response.data);
    const { organization_id, id, fullName, email } = currentUser;
    const userId = `${organization_id}-${id}`;

    // データレイヤー変数user_idにユーザーIDを設定
    // GoogleタグマネージャーでGA4のユーザーIDへのマッピングが行われる
    pushDataLayer({ user_id: userId });

    // チャネルトークに同一ユーザが複数作られないようにemailからhashを生成しmemberIdとする
    const memberIdHashArray: YieldReturn<typeof digestMessage> = yield digestMessage(email);
    const memberIdHashString = Array.from(new Uint8Array(memberIdHashArray))
      .map((v) => v.toString(16).padStart(2, '0'))
      .join('');

    ChannelService.updateUser(currentUser, memberIdHashString);

    Sentry.configureScope((scope) => {
      scope.setUser({ id: userId, username: fullName, email });
      scope.setTag('username', fullName);
      scope.setTag('email', email);
    });
  } else {
    toast({
      type: 'error',
      title: 'アカウントの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* signOut(action: ReturnType<typeof AppActions.signOut>) {
  const global = action.payload;
  if (global === true) {
    // サーバー側でセッションIDを破棄するためglobal:trueを指定（脆弱性対応）
    yield call([Auth, 'signOut'], { global });
  } else {
    yield call([Auth, 'signOut']);
  }

  ChannelService.shutdown();
  ChannelService.boot();
  Sentry.configureScope((scope) => {
    scope.setUser({});
  });
  yield put(pushWithOrganizationId(Path.top));
  // 別のアカウントでログインしたとき変な挙動にならないように、データを初期化する
  yield put(AppActions.reset());
  // 組織IDをリセットする
  AccountSingleton.getInstance().clear();

  // 最終ログインID、セッションでログインしているIDをクリア
  removeStorageItem(LAST_LOGIN_ORGANIZATION_ID_STORAGE_KEY, { isGlobal: true });
  removeStorageItem(SESSION_ORGANIZATION_ID_STORAGE_KEY, { isGlobal: true, storageType: 'session' });
}

/** Googleビジネスプロフィールとの差分情報を取得する */
function* getGmbLocationDiffs() {
  const response: YieldReturn<typeof GmbLocationDiffsSummaryApi.get> = yield GmbLocationDiffsSummaryApi.get();
  if (response.isSuccess) {
    const gmbLocationUpdates = GmbLocationDiffs.fromJSON(response.data);
    yield put(AppActions.setGmbLocationDiffs(gmbLocationUpdates));
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィールとの差分情報の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

async function digestMessage(message: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return hash;
}

/** 最新のリビジョンの取得 */
const getRevision = () => {
  return axios
    .get<{ revision: string }>(revisionUrl)
    .then((res) => ({ revision: res.data.revision }))
    .catch((error) => ({ error }));
};

/** 最新のリビジョンの監視 */
function* watchLatestRevision() {
  while (true) {
    // 最新（S3側）のリビジョン
    const { revision: latestRevision, error } = yield call(getRevision);
    // クライアント側のリビジョン
    const currentRevision = revision.revision;

    if (revision) {
      // ページの更新が必要かのフラグ
      // S3側のリビジョンの日時より、クライアント側のリビジョンの日時が古ければメッセージを表示する
      // （リビジョンが一致しなくても、新しければメッセージは表示されないので注意）
      const needsUpdate = !!latestRevision && latestRevision > currentRevision;

      if (needsUpdate) {
        // フラグを更新したらこれ以上監視する必要がないので終了
        yield put(AppActions.setNeedsUpdate(needsUpdate));
        break;
      }
    }

    if (error) {
      console.warn(error);
    }

    // 1分おきに実行する
    yield delay(60000);
  }
}

function* changeCurrentAccount(action: ReturnType<typeof AppActions.changeCurrentAccount>) {
  const { account, resetPage } = action.payload;
  yield put(AppActions.setCurrentAccount(account));

  // ログイン中の組織IDをAPIのヘッダーに含めるために、組織IDを別に保存しておく
  AccountSingleton.getInstance().set(account.organizationId);

  // resetの影響で遷移情報が消えるので、今開いているページを保持しておく
  const { pathname, search } = yield select((state) => state.router.location);

  // 別のアカウントに切り替えるときに変な挙動にならないように、データを初期化する
  ChannelService.shutdown();
  ChannelService.boot();
  Sentry.configureScope((scope) => {
    scope.setUser({});
  });
  yield put(AppActions.reset());
  yield put(AppActions.initialize());

  // 最終ログインID、セッションでログインしているIDをクリア
  removeStorageItem(LAST_LOGIN_ORGANIZATION_ID_STORAGE_KEY, { isGlobal: true });
  removeStorageItem(SESSION_ORGANIZATION_ID_STORAGE_KEY, { isGlobal: true, storageType: 'session' });

  if (resetPage) {
    // ページをリセットする場合、トップページに遷移させる
    yield put(replaceWithOrganizationId(Path.top));
  } else {
    // 保持しておいたページに遷移する
    yield put(replaceWithOrganizationId(`${pathname}${search}`));
  }
}

function* locationChange(action: LocationChangeAction) {
  const isInitialized: boolean = yield select((state: State) => state.app.isInitialized);
  // 初期化前は何も行わない
  if (!isInitialized) {
    return;
  }

  // デフォルトで必要なクエリパラメータがない場合は付加して遷移する
  const { location } = action.payload;

  let path = location.pathname;
  if (location.search) {
    path = `${path}${location.search}`;
  }
  path = `${path}${location.hash}`;
  yield put(replaceWithOrganizationId(path));
}

/**
 * クエリパラメータに設定されている組織IDを取得する
 * @returns
 */
export const extractOrganizationIdFromQuery = () => {
  const organizationIdInQuery = new URLSearchParams(window.location.search).get(ORGANIZATION_ID_QUERY_KEY);
  const organizationId = Number(organizationIdInQuery);
  if (!isNaN(organizationId)) {
    return organizationId;
  }
  return null;
};

/**
 * ストレージからセッションの、または最後にログインしたアカウントの組織IDを取得する
 * @returns
 */
export const getLastLoginOrganization = () => {
  // 最後にログインした組織IDがある場合、その値を返す
  const lastLoginOrganizationId = getStorageItem<number | null>(LAST_LOGIN_ORGANIZATION_ID_STORAGE_KEY, null, {
    isGlobal: true,
  });
  if (lastLoginOrganizationId !== null && !isNaN(lastLoginOrganizationId)) {
    return lastLoginOrganizationId;
  }

  return null;
};

const getSessionOrganization = () => {
  // セッションでログイン中の組織IDがある場合、その値を返す
  const sessionOrganizationId = getStorageItem<number | null>(SESSION_ORGANIZATION_ID_STORAGE_KEY, null, {
    isGlobal: true,
    storageType: 'session',
  });
  if (sessionOrganizationId !== null && !isNaN(sessionOrganizationId)) {
    return sessionOrganizationId;
  }

  return null;
};
