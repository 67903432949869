import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { SIZE } from 'style/size';

type Props = {
  onReset: () => void;
};

export const OrganizationDataReset: React.FC<Props> = ({ onReset }) => {
  // 複数回の実行を防ぐために、一度押したら無効にする
  const [isResetDisabled, setIsResetDisabled] = useState(false);

  const handleOnClick = useCallback(() => {
    if (!window.confirm('本当にリセットしますか？')) {
      return;
    }
    setIsResetDisabled(true);
    onReset();
  }, [onReset]);

  return (
    <Wrapper>
      <Header>データリセット</Header>
      <ButtonWrapper>
        <Description>
          組織のすべてのデータを、あらかじめ用意されたデータにリセットします。
          <br />
          反映されない場合は、ページを再読み込みしてください。
        </Description>
        <ResetButton onClick={handleOnClick} disabled={isResetDisabled}>
          リセット
        </ResetButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 16px;
`;

const Description = styled.div`
  flex: 1;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: nowrap;
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
    flex-direction: column;
  }
`;

const ResetButton = styled(Button).attrs({ priority: 'high', negative: true })`
  &&& {
    height: 54px;
    width: 170px;
    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;
