import dayjs from 'dayjs';
import { Record } from 'immutable';

const DATE_REG_EXP = /([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})/;

export class GmbTimePeriodDate extends Record<{
  value: string;
}>({
  value: '',
}) {
  constructor(value = '') {
    const params = { value };
    super(params);
  }

  get paddedDate() {
    return this.getPaddedDate(this.value);
  }

  get gmbDateObject() {
    return { year: Number(this.year), month: Number(this.month), day: Number(this.day) };
  }

  /** dayjsを使って翌日にしてから返却する */
  get gmbTomorrowDateObject() {
    const date = dayjs(this.value).add(1, 'day');
    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  }

  get year() {
    const match = this.value.match(DATE_REG_EXP);
    if (!match) return '';
    return match[1];
  }

  get month() {
    const match = this.value.match(DATE_REG_EXP);
    if (!match) return '';
    return this.removeLeadingZeros(match[2]);
  }

  get day() {
    const match = this.value.match(DATE_REG_EXP);
    if (!match) return '';
    return this.removeLeadingZeros(match[3]);
  }

  toString() {
    return this.value;
  }

  toDate() {
    const paddedDate = this.paddedDate;
    if (paddedDate.length > 0) {
      return new Date(paddedDate);
    }
    return null;
  }

  changeDate(value: string) {
    return this.set('value', value);
  }

  private getPaddedDate(date: string) {
    const match = date.match(DATE_REG_EXP);
    if (!match) return date;
    return `${match[1]}-${match[2].padStart(2, '0')}-${match[3].padStart(2, '0')}`;
  }

  private removeLeadingZeros(value: string) {
    return value.replace(/\b0+/g, '');
  }
}
