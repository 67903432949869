import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyFooter } from 'components/atoms/StickyFooter';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

type Props = {
  className?: string;
  selectedSize: number;
  onDelete: () => void;
  onCancel: () => void;
};

export const MenuIndexFooter = React.memo<Props>(({ className, selectedSize, onCancel, onDelete }) => {
  return (
    <StyledStickyFooter className={className}>
      <ChildWrapper>
        <CheckedLabel>{selectedSize}件を選択中</CheckedLabel>
        <CancelLabel onClick={onCancel}>全て解除</CancelLabel>
      </ChildWrapper>
      <ButtonWrapper>
        <StyledButton onClick={onDelete} negative priority={'high'}>
          メニューを削除
        </StyledButton>
      </ButtonWrapper>
    </StyledStickyFooter>
  );
});

const StyledStickyFooter = styled(StickyFooter)`
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 320px; /* メニューの分は空ける */
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.2));
  z-index: 10; /* 他のstickyの要素より上に表示する */
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    left: 0;
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CheckedLabel = styled.div`
  font-size: 16px;
  border-right: 1px solid ${COLOR.GRAY};
  margin-right: 16px;
  padding-right: 16px;
`;

const CancelLabel = styled.div`
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    font-weight: bold;
    cursor: pointer;
    width: auto;
    font-size: 16px;
    border-radius: 8px;
    padding: 12px 16px;
  }
`;
