import React, { useCallback, useState } from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import {
  MAX_COMMENT_LENGTH,
  MAX_MESSAGE_LENGTH,
  ReviewReplyGenerateCondition,
} from 'models/Domain/GmbReview/ReviewReplyGenerateCondition';
import { COLOR } from 'style/color';

import { SelectOption } from '../../../types/Common';
import { Counter } from '../../atoms/Counter';
import { FormContent } from '../../atoms/FormContent';
import { Input } from '../../atoms/Input';
import { PullDown } from '../../atoms/PullDown';
import { TextArea } from '../../atoms/TextArea';

type Props = {
  condition: ReviewReplyGenerateCondition;
  onChangeCondition: (value: ReviewReplyGenerateCondition) => void;
  onGenerate: () => void;
  onCancel: () => void;
};

const ratingTypeOptions: SelectOption<'good' | 'bad'>[] = [
  { text: '高評価に対する返信文', value: 'good' },
  { text: '低評価に対する返信文', value: 'bad' },
];

export const ReviewReplyGenerateConditionContents: React.FC<Props> = ({
  condition,
  onChangeCondition,
  onGenerate,
  onCancel,
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { reviewer, comment, ratingType, message } = condition;

  const handleOnChangeReviewer = useCallback(
    (value: string) => {
      onChangeCondition(condition.changeReviewer(value));
    },
    [condition, onChangeCondition],
  );
  const handleOnChangeComment = useCallback(
    (value: string) => {
      onChangeCondition(condition.changeComment(value));
    },
    [condition, onChangeCondition],
  );
  const handleOnChangeRatingType = useCallback(
    (value: 'good' | 'bad') => {
      onChangeCondition(condition.changeRatingType(value));
    },
    [condition, onChangeCondition],
  );
  const handleOnChangeMessage = useCallback(
    (value: string) => {
      onChangeCondition(condition.changeMessage(value));
    },
    [condition, onChangeCondition],
  );

  const handleOnCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleOnGenerate = useCallback(() => {
    setShowErrorMessage(true);
    if (condition.isValid()) {
      onGenerate();
    }
  }, [condition, onGenerate]);

  const validate = condition.validate();

  return (
    <ModalContent>
      <Wrapper>
        <Title>クチコミ返信文の自動生成</Title>
        <ContentWrapper>
          <StyledFormContent name={'投稿者名'} required>
            <StyledInput
              value={reviewer}
              onChange={handleOnChangeReviewer}
              error={showErrorMessage && !validate.reviewer.isValid}
            />
            <ValidationWrapper>
              <Error>{showErrorMessage && validate.reviewer.error}</Error>
            </ValidationWrapper>
          </StyledFormContent>
          <StyledFormContent name={'クチコミの内容'}>
            <StyledTextArea
              value={comment}
              onChange={handleOnChangeComment}
              error={showErrorMessage && !validate.comment.isValid}
            />
            <ValidationWrapper>
              <Error>{showErrorMessage && validate.comment.error}</Error>
              <StyledTextCounter size={comment.length} maxSize={MAX_COMMENT_LENGTH} />
            </ValidationWrapper>
          </StyledFormContent>
          <StyledFormContent name={'どのような返信文を作成するか'} required>
            <StyledPullDown
              options={ratingTypeOptions}
              value={ratingType}
              onChange={handleOnChangeRatingType}
              error={showErrorMessage && !validate.ratingType.isValid}
            />
            <ValidationWrapper>
              <Error>{showErrorMessage && validate.ratingType.error}</Error>
            </ValidationWrapper>
          </StyledFormContent>
          <StyledFormContent name={'お店から投稿者に伝えたいメッセージ'}>
            <StyledTextArea
              value={message}
              onChange={handleOnChangeMessage}
              error={showErrorMessage && !validate.message.isValid}
            />
            <ValidationWrapper>
              <Error>{showErrorMessage && validate.message.error}</Error>
              <StyledTextCounter size={message.length} maxSize={MAX_MESSAGE_LENGTH} />
            </ValidationWrapper>
          </StyledFormContent>
        </ContentWrapper>
        <ButtonWrapper>
          <StyledButton onClick={handleOnCancel}>キャンセル</StyledButton>
          <StyledButton
            onClick={handleOnGenerate}
            priority={'high'}
            disabled={showErrorMessage && !condition.isValid()}
          >
            文章生成
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    </ModalContent>
  );
};

const ModalContent = styled(Modal.Content)``;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding-right: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    min-width: 140px;
    width: auto;
  }
`;

const StyledFormContent = styled(FormContent)`
  margin-bottom: 16px;
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const StyledPullDown = styled(PullDown)`
  width: 100%;
  margin-bottom: 16px;
`;

const Error = styled.div`
  color: ${COLOR.ERROR};
  flex: 1;
`;

const ValidationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  margin-top: 8px;
`;

const StyledTextCounter = styled(Counter)`
  text-align: right;
`;
