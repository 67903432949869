import React, { useCallback, useEffect, useState } from 'react';

import { CustomRadio, SearchPullDown } from 'components/molecules/SearchPullDown';
import { StoreLists } from 'models/Domain/StoreList';
import { StoreListIdType } from 'models/Domain/StoreSearchCondition';

/** グループによる店舗絞り込みにおける単一の選択肢の型 */
type GroupOption = { text: string; value: StoreListIdType };

const managingStoreOption: GroupOption = { text: 'あなたの所属/管理店舗', value: 'managed_list' };
const allStoresOption: GroupOption = { text: 'すべて', value: 'all' };

/**
 * グループによる店舗の絞り込み
 */
export const StoreListFilter = React.memo<{
  className?: string;
  selectedStoreListId: StoreListIdType;
  storeLists: StoreLists;
  setStoreListId: (v: StoreListIdType) => void;
  /** 「あなたの所属/管理店舗」選択肢を表示するかどうか */
  displayManagedList: boolean;
}>(({ className, selectedStoreListId, storeLists, setStoreListId, displayManagedList }) => {
  const [inputValue, setInputValue] = useState(selectedStoreListId);

  /** グループの選択肢 */
  const options: GroupOption[] = displayManagedList
    ? [managingStoreOption, allStoresOption, ...storeLists.options]
    : [allStoresOption, ...storeLists.options];

  const handleOnCancel = useCallback(() => {
    setInputValue(selectedStoreListId);
  }, [selectedStoreListId]);

  const handleOnApply = useCallback(() => {
    setStoreListId(inputValue);
  }, [setStoreListId, inputValue]);

  useEffect(() => {
    setInputValue(selectedStoreListId);
  }, [selectedStoreListId]);

  return (
    <SearchPullDown value={'グループ'} onCancel={handleOnCancel} onApply={handleOnApply} className={className}>
      {options.map((option) => {
        return (
          <CustomRadio
            key={option.value}
            label={option.text}
            checked={option.value === inputValue}
            onChange={() => setInputValue(option.value)}
          />
        );
      })}
    </SearchPullDown>
  );
});
