import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { TextArea } from 'components/atoms/TextArea';
import { Review } from 'models/Domain/GmbReview';
import { GmbReviewTemplate, TEMPLATE_ALIASES } from 'models/Domain/GmbReviewTemplate';
import { Store } from 'models/Domain/Store';
import { GmbActions } from 'modules/gmb/actions';
import { COLOR } from 'style/color';

interface Props {
  className?: string;
  open: boolean;
  review?: Review;
  store?: Store;
  onApply?: (body: string) => void;
  onClose: () => void;
  canApply: boolean; // 適用できるかどうか
}

export const GmbReviewTemplateModal: React.FC<Props> = ({
  className,
  open,
  review,
  store,
  onApply,
  onClose,
  canApply,
}) => {
  const dispatch = useDispatch();

  // 編集中かどうか
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // 編集中のテンプレートの保存済みデータ（編集中のみ値が入り、それ以外はnull）
  const [savedGmbReviewTemplate, setSavedGmbReviewTemplate] = useState<GmbReviewTemplate | null>(null);
  // 編集中のテンプレートデータ（どのテンプレートを選択しているかも兼ねているので、テンプレートが1つもない時以外は値が入っている）
  const [editGmbReviewTemplate, setEditGmbReviewTemplate] = useState<GmbReviewTemplate | null>(null);
  // 最後に選択していたテンプレートのID（新規作成→キャンセル時に選択状態の再現に利用する）
  const [lastSelectedTemplateId, setLastSelectedTemplateId] = useState<string | null>(null);

  const { reviewTemplateList, currentUser } = useSelector((state) => ({
    currentUser: state.app.currentUser,
    reviewTemplateList: state.gmb.reviewTemplateList,
  }));

  // 初期データ取得
  useEffect(() => {
    if (open) {
      dispatch(GmbActions.getGmbReviewTemplateList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * テンプレートID、タイトルからテンプレートを選択する
   */
  const selectTemplate = useCallback(
    (templateId?: string, title?: string) => {
      // 更新されたテンプレートの中からtemplate_idを元に検索
      // 新規作成時は保存時に初めてtemplate_idが付与されるのでタイトルで検索
      const updatedTemplate =
        reviewTemplateList.list.find((template) => template.template_id === templateId) ||
        reviewTemplateList.list.find((template) => template.title === title) ||
        null;

      if (updatedTemplate) {
        return updatedTemplate;
      }

      // 選択中のテンプレートがない場合は、最初の１つを選択状態にする
      const firstTemplate = reviewTemplateList.list.get(0);
      if (firstTemplate) {
        return firstTemplate;
      }

      return null;
    },
    [reviewTemplateList],
  );

  // モーダルの開閉、テンプレート一覧が更新された時に、自動でテンプレートを選択する処理
  useEffect(() => {
    // 閉じる時は選択状態を解除する
    if (!open) {
      setEditGmbReviewTemplate(null);
      return;
    }

    const selectedTemplate = selectTemplate(editGmbReviewTemplate?.template_id, editGmbReviewTemplate?.title);
    setEditGmbReviewTemplate(selectedTemplate);
    if (selectedTemplate) {
      setLastSelectedTemplateId(selectedTemplate.template_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, reviewTemplateList, selectTemplate]);

  const onTemplateSelect = useCallback(
    (reviewTemplate: GmbReviewTemplate) => {
      if (isEdit) {
        return;
      }
      setEditGmbReviewTemplate(reviewTemplate);
      setLastSelectedTemplateId(reviewTemplate.template_id);
    },
    [isEdit],
  );

  const onClickRemoveTemplateButton = useCallback(() => {
    if (!editGmbReviewTemplate) return;

    if (!window.confirm(`${editGmbReviewTemplate.title} を削除してもよろしいですか？`)) return;
    dispatch(GmbActions.deleteGmbReviewTemplate(editGmbReviewTemplate));
    setEditGmbReviewTemplate(null);
    setIsEdit(false);
  }, [dispatch, editGmbReviewTemplate]);

  const parsedTemplate =
    editGmbReviewTemplate && review && store
      ? editGmbReviewTemplate.parse({
          storeName: store.fullName,
          storeManagerName: currentUser.fullName,
          reviewerName: review.reviewer.displayName,
        })
      : [
          {
            type: 'Text' as const,
            value: editGmbReviewTemplate?.body || '',
          },
        ];

  const handleStartEdit = useCallback(() => {
    setIsEdit(true);
    setSavedGmbReviewTemplate(editGmbReviewTemplate);
  }, [editGmbReviewTemplate]);

  // テンプレートの新規作成を開始する
  const handleStartEditNewTemplate = useCallback(() => {
    const example = {
      title: '新規テンプレート',
      body:
        '{{レビュワー氏名}} 様\n\n' +
        'この度は {{店舗名}} にご来店いただき、誠にありがとうございます。\n' +
        '{{店舗名}}  の {{アカウント名}}  と申します。\n\n' +
        '{{(例) レビュー内容に関する返信記入箇所}} に関して、満足をいただけたようで嬉しい限りでございます。\n\n' +
        'またのお越しを、心よりお待ちしています。\n' +
        '今後ともよろしくお願いいたします。\n',
    };
    const newTemplate = new GmbReviewTemplate(example);
    setSavedGmbReviewTemplate(newTemplate);
    setEditGmbReviewTemplate(newTemplate);
    setIsEdit(true);
  }, []);

  const handleApply = () => {
    if (editGmbReviewTemplate && review && store) {
      const filledTemplate = editGmbReviewTemplate.fill({
        storeName: store.fullName,
        storeManagerName: currentUser.fullName,
        reviewerName: review.reviewer.displayName,
      });
      onApply && onApply(filledTemplate);
    }
  };

  const handleSave = useCallback(() => {
    if (editGmbReviewTemplate == null) {
      return;
    }
    if (editGmbReviewTemplate.isSaved()) {
      dispatch(GmbActions.updateGmbReviewTemplate(editGmbReviewTemplate));
    } else {
      dispatch(GmbActions.createGmbReviewTemplate(editGmbReviewTemplate));
    }

    // 保存済みテンプレートに反映する
    setSavedGmbReviewTemplate(null);
    setIsEdit(false);
  }, [dispatch, editGmbReviewTemplate]);

  const handleCancelEdit = useCallback(() => {
    if (!savedGmbReviewTemplate?.equals(editGmbReviewTemplate)) {
      if (!window.confirm(`変更内容を破棄してもよろしいですか？`)) return;
    }

    setSavedGmbReviewTemplate(null);
    // 前に選択していた状態に戻す
    if (lastSelectedTemplateId) {
      const selectedTemplate = selectTemplate(lastSelectedTemplateId);
      setEditGmbReviewTemplate(selectedTemplate);
    }

    setIsEdit(false);
  }, [savedGmbReviewTemplate, editGmbReviewTemplate, lastSelectedTemplateId, selectTemplate]);

  const showAddNewTemplateButton = currentUser.isCompanyUser && !isEdit;
  const canEdit = currentUser.isCompanyUser;
  const showSaveButton = isEdit;
  const showCancelButton = isEdit;
  const showApplyButton = !isEdit && canApply;
  const showCloseButton = !isEdit;

  let titleError: string | false = false;
  let bodyError: string | false = false;
  if (editGmbReviewTemplate) {
    if (editGmbReviewTemplate.title.trim() === '') {
      titleError = 'テンプレートの名前を入力してください';
    }

    // 自分自身以外に同一名称のテンプレートがある場合
    const hasSameTitle = !!reviewTemplateList.list.find(
      (template) =>
        template.template_id !== editGmbReviewTemplate.template_id && template.title === editGmbReviewTemplate.title,
    );
    if (hasSameTitle) {
      titleError = 'テンプレートに同じ名前をつけることはできません';
    }

    if (editGmbReviewTemplate.body.trim() === '') {
      bodyError = 'テンプレートの内容を入力してください';
    }
  }
  return (
    <Modal className={className} open={open} style={{ backgroundColor: 'transparent', boxShadow: 'initial' }}>
      <Modal.Content>
        <Wrapper>
          <LeftContent>
            <TitleWrapper>
              <Title>テンプレート</Title>
              {showAddNewTemplateButton && (
                <AddNewTemplateButton onClick={handleStartEditNewTemplate}>＋ 新規作成</AddNewTemplateButton>
              )}
            </TitleWrapper>
            <ListWrapper collapseSp={!!editGmbReviewTemplate}>
              {reviewTemplateList.list.size === 0 ? (
                <ListContent active={false} empty={true} isEdit={false}>
                  <pre>
                    テンプレートがありません。
                    {showAddNewTemplateButton && '\n\n新規作成ボタンから\nテンプレートの追加をお願いします'}
                  </pre>
                </ListContent>
              ) : (
                reviewTemplateList.list.map((reviewTemplate, idx) => (
                  <ListContent
                    key={idx}
                    onClick={() => onTemplateSelect(reviewTemplate)}
                    active={editGmbReviewTemplate?.template_id === reviewTemplate.template_id}
                    isEdit={isEdit}
                  >
                    {reviewTemplate.title}
                  </ListContent>
                ))
              )}
            </ListWrapper>
          </LeftContent>
          <RightContent hideSp={!editGmbReviewTemplate}>
            {isEdit ? (
              <div>
                <Input
                  onChange={(value) =>
                    setEditGmbReviewTemplate((editGmbReviewTemplate || new GmbReviewTemplate()).setTitle(value))
                  }
                  placeholder='テンプレート タイトル'
                  value={editGmbReviewTemplate?.title || ''}
                  error={titleError}
                />
                <StyledTextArea
                  onChange={(value) =>
                    setEditGmbReviewTemplate((editGmbReviewTemplate || new GmbReviewTemplate()).setBody(value))
                  }
                  value={editGmbReviewTemplate?.body || ''}
                  placeholder='テンプレート 本文'
                  minRows={17}
                  maxRows={17}
                  error={bodyError}
                />
                <AliasExplanation>
                  {Object.entries(TEMPLATE_ALIASES).map(([_, value]) => (
                    <AliasTerm key={value}>{value}</AliasTerm>
                  ))}
                  <span>
                    と記述すると、テンプレート適用時に自動でそれぞれの値が入力されます。 また、それ以外のテキストを
                    <AliasTerm>{' {{  }} '}</AliasTerm>
                    で囲うことで店舗側で記入する部分として指定することが可能です。
                  </span>
                </AliasExplanation>
              </div>
            ) : (
              <div>
                <TemplateTitle active={!!editGmbReviewTemplate}>
                  <div>{editGmbReviewTemplate?.title}</div>
                  {canEdit && (
                    <ActionWrapper>
                      <ActionButton disabled={!editGmbReviewTemplate} onClick={handleStartEdit}>
                        <Icon type='edit_for_template' />
                      </ActionButton>
                      <ActionButton
                        disabled={!editGmbReviewTemplate?.template_id}
                        onClick={onClickRemoveTemplateButton}
                      >
                        <Icon type='trash_for_template' />
                      </ActionButton>
                    </ActionWrapper>
                  )}
                </TemplateTitle>
                <TemplateText>
                  <p>
                    {parsedTemplate.map((pt, i) => {
                      switch (pt.type) {
                        case 'Text':
                          return pt.value;
                        case 'StoreEntry':
                          return <TemplateStoreEntry key={i}>{pt.value}</TemplateStoreEntry>;
                        case 'Alias':
                          return <TemplateAlias key={i}>{pt.value}</TemplateAlias>;
                      }
                    })}
                  </p>
                </TemplateText>
              </div>
            )}
          </RightContent>
        </Wrapper>
        <BottomContent>
          <ButtonsWrapper>
            {showCloseButton && <CancelButton onClick={onClose}>閉じる</CancelButton>}
            {showCancelButton && <CancelButton onClick={handleCancelEdit}>キャンセル</CancelButton>}

            {showSaveButton && (
              <SubmitButton onClick={handleSave} priority='high' disabled={!!(titleError || bodyError)}>
                保存
              </SubmitButton>
            )}
            {showApplyButton && (
              <SubmitButton
                onClick={() => {
                  handleApply();
                }}
                priority='high'
                disabled={!editGmbReviewTemplate}
              >
                適用
              </SubmitButton>
            )}
          </ButtonsWrapper>
        </BottomContent>
      </Modal.Content>
    </Modal>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 53px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: ${COLOR.BLACK};
`;

const AddNewTemplateButton = styled.div`
  cursor: pointer;
`;

const ListWrapper = styled.div<{ collapseSp?: boolean }>`
  margin: 12px 0;
  overflow-y: auto;
  height: 430px;

  @media (max-width: 600px) {
    ${(props) => props.collapseSp && 'max-height: 95px;'}
  }
`;

const ListContent = styled.div<{ active: boolean; empty?: boolean; isEdit: boolean }>`
  color: ${(props) => (!props.empty ? COLOR.BLACK : COLOR.GRAY)};
  background-color: ${(props) => (props.active ? COLOR.LIGHT_GRAY : 'none')};
  padding: 8px 12px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => props.isEdit && 'pointer-events: none;'};
  ${(props) => props.active && 'cursor: pointer;'};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  width: 35%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const RightContent = styled.div<{ hideSp?: boolean }>`
  width: 65%;
  padding-left: 30px;

  @media (max-width: 600px) {
    ${(props) => props.hideSp && 'display: none;'}
    width: 100%;
    padding-left: 0;
  }
`;

const BottomContent = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    width: 150px;
    margin-right: 16px;
  }
`;

const SubmitButton = styled(Button)`
  &&& {
    width: 150px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;

  @media (min-width: 601px) {
    margin-left: auto;
  }
`;

const TemplateTitle = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? COLOR.BLACK : COLOR.GRAY)};
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
`;

const ActionWrapper = styled.div``;

const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;

  :disabled {
    cursor: default;
    > * {
      opacity: 0.5;
    }
  }
`;

const TemplateText = styled.div`
  overflow-y: auto;
  margin-top: 12px;
  margin-bottom: 20px;
  color: ${COLOR.BLACK};
  font-size: 16px;
  word-break: break-word;
  padding: 16px 20px;
  height: 430px;
  background-color: ${COLOR.LIGHT_GRAY};

  @media (max-width: 600px) {
    height: 240px;
  }
  > p {
    white-space: pre-wrap;
  }
`;

const TemplateStoreEntry = styled.span`
  color: ${COLOR.RED};
  vertical-align: baseline;
`;

const TemplateAlias = styled.span`
  color: ${COLOR.GREEN};
  vertical-align: baseline;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const AliasTerm = styled.span`
  display: inline-block;
  background-color: ${COLOR.LIGHT_GRAY};
`;

const AliasExplanation = styled.div`
  flex-shrink: 1;
  font-size: 12px;
  line-height: 1.6;
  ${AliasTerm} + ${AliasTerm} {
    margin-left: 4px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;
