import React from 'react';

import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { COLOR } from 'style/color';

export type MapCompetitorResearchProgressProps = {
  className?: string;
  totalCount: number;
  completedCount: number;
};

export const MapCompetitorResearchProgress = React.memo(
  ({ className, totalCount, completedCount }: MapCompetitorResearchProgressProps) => {
    return (
      <Wrapper className={className}>
        <ProgressLabel>
          <LoaderWrapper>
            <Loader active={true} size='small' inline />
          </LoaderWrapper>
          現在検索順位の取得中です。
        </ProgressLabel>
        <Progress value={completedCount} total={totalCount} progress='ratio' indicating autoSuccess />
        <Description>平均順位などは取得済みのデータのみから算出されるため、取得状況により変動します。</Description>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  background: #fff;
  padding: 16px;
`;

const ProgressLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const Description = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 16px;
`;

const LoaderWrapper = styled.div`
  margin-right: 8px;
`;
