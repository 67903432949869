import React from 'react';

import styled from 'styled-components';

import Grid from 'components/atoms/Grid';
import GridArea from 'components/atoms/GridArea';
import { useDisplayType } from 'hooks/useDisplayType';
import { PROGRAMS, Program } from 'models/Domain/OmoInsight';
import { ProductStatusSummary } from 'models/Domain/OmoInsight/ProductStatusSummary';
import { COLOR } from 'style/color';

import { PROGRAM_LABELS } from '..';

import { ProgramStatusCard } from './ProgramStatusCard';

type Props = {
  className?: string;
  productStatus: ProductStatusSummary;
  selectedProgram: Program | null;
  selectProgram: (program: Program) => void;
  activePrograms: Program[];
};

export const ProductStatusProgramList = React.memo<Props>(
  ({ className, productStatus, selectedProgram, selectProgram, activePrograms }) => {
    const products = productStatus.products;

    // PC表示・SP表示のGridの設定
    const isDesktop = useDisplayType('1100px').isDesktop;
    const pcColumns = ['1fr', '1fr', '1fr', '1fr'];
    const spColumns = ['1fr', '1fr'];
    const pcRows = ['1fr'];
    const spRows = ['1fr'];
    // areasは有効な掲載プログラム名のリストで、4つに満たない分は"."で埋める
    // PCは1列に4つ表示し、SPは2列に2つずつ表示する
    const areas = (activePrograms as string[]).concat(['.', '.', '.', '.']).slice(0, 4);
    const pcAreas = [areas];
    const spAreas = new Array(2).fill([]).map((_, i) => areas.slice(i * 2, i * 2 + 2));

    return (
      <Wrapper className={className}>
        <CardContainer
          columns={isDesktop ? pcColumns : spColumns}
          rows={isDesktop ? pcRows : spRows}
          areas={isDesktop ? pcAreas : spAreas}
        >
          {PROGRAMS.map((program) => {
            if (!activePrograms.includes(program)) {
              return null;
            }
            return (
              <GridArea key={program} name={program}>
                <CardWrapper selected={selectedProgram === program} onClick={() => selectProgram(program)}>
                  <ProgramStatusCard
                    label={PROGRAM_LABELS[program]}
                    value={products[program].active}
                    color={COLOR.GREEN}
                    selected={selectedProgram === program}
                    showRatio={false}
                    showIcon={false}
                  />
                </CardWrapper>
              </GridArea>
            );
          })}
        </CardContainer>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const CardContainer = styled(Grid)`
  grid-gap: 8px 0;
  margin-bottom: 8px;
  background: ${COLOR.WHITE};
  @media (max-width: 700px) {
    background: none;
  }
`;

const CardWrapper = styled.div<{ selected: boolean }>`
  height: 100%;
  position: relative;
`;
