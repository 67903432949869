import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

export class Status extends Record<{
  done: List<number>;
  notDone: List<number>;
}>({
  done: List(),
  notDone: List(),
}) {
  static fromJSON(data: JSObject): Status {
    return new Status({
      done: List(data.done),
      notDone: List(data.not_done),
    });
  }

  get storeIds(): List<number> {
    return this.done.concat(this.notDone);
  }

  get isDone() {
    return this.total !== 0 && this.notDone.isEmpty();
  }

  /**
   * 合計
   */
  get total(): number {
    return this.doneCount + this.notDoneCount;
  }

  /**
   * 完了数
   */
  get doneCount(): number {
    return this.done.size;
  }

  /**
   * 未完了数
   */
  get notDoneCount(): number {
    return this.notDone.size;
  }

  /**
   * 達成率
   */
  get progress(): number {
    return (this.doneCount / this.total) * 100;
  }
}

export class GbpChecklist extends Record<{
  period: {
    startDate: Dayjs;
    endDate: Dayjs;
  };
  items: {
    basicInformation: {
      name: Status;
      address: Status;
      phone: Status;
      businessHour: Status;
      url: Status;
      description: Status;
      diffGbp: Status;
    };
    localPost: {
      newPost: Status;
    };
    review: {
      replied: Status;
    };
    photo: {
      interior: Status;
      exterior: Status;
      newPhoto: Status;
    };
  };
}>({
  period: {
    startDate: dayjs().subtract(30, 'day'),
    endDate: dayjs(),
  },
  items: {
    basicInformation: {
      name: new Status(),
      address: new Status(),
      phone: new Status(),
      businessHour: new Status(),
      url: new Status(),
      description: new Status(),
      diffGbp: new Status(),
    },
    localPost: {
      newPost: new Status(),
    },
    review: {
      replied: new Status(),
    },
    photo: {
      interior: new Status(),
      exterior: new Status(),
      newPhoto: new Status(),
    },
  },
}) {
  static fromJSON(data: JSObject): GbpChecklist {
    const basicInformation: JSObject = data.items.basic_information;
    const localPost: JSObject = data.items.local_post;
    const review: JSObject = data.items.review;
    const photo: JSObject = data.items.photo;
    return new GbpChecklist({
      period: {
        startDate: dayjs(data.period.start_date),
        endDate: dayjs(data.period.end_date),
      },
      items: {
        basicInformation: {
          name: Status.fromJSON(basicInformation.name),
          address: Status.fromJSON(basicInformation.address),
          phone: Status.fromJSON(basicInformation.phone),
          businessHour: Status.fromJSON(basicInformation.business_hour),
          url: Status.fromJSON(basicInformation.url),
          description: Status.fromJSON(basicInformation.description),
          diffGbp: Status.fromJSON(basicInformation.diff_gbp),
        },
        localPost: {
          newPost: Status.fromJSON(localPost.new_post),
        },
        review: {
          replied: Status.fromJSON(review.replied),
        },
        photo: {
          interior: Status.fromJSON(photo.interior),
          exterior: Status.fromJSON(photo.exterior),
          newPhoto: Status.fromJSON(photo.new_photo),
        },
      },
    });
  }

  /**
   * 店舗情報の達成率
   */
  getStoreInfoProgress(): number {
    const { basicInformation, photo } = this.items;
    const statuses = [
      basicInformation.name,
      basicInformation.address,
      basicInformation.phone,
      basicInformation.businessHour,
      basicInformation.url,
      basicInformation.description,
      photo.interior,
      photo.exterior,
    ];
    return this.calcProgress(statuses);
  }

  /**
   * 運用状況の達成率
   */
  getOperationProgress(): number {
    const { basicInformation, localPost, review, photo } = this.items;
    const statuses = [basicInformation.diffGbp, photo.newPhoto, localPost.newPost, review.replied];
    return this.calcProgress(statuses);
  }

  /**
   * 集計対象のステータスの達成率を返す
   * @param statuses
   * @private
   */
  private calcProgress(statuses: Status[]) {
    const [done, total] = statuses.reduce(
      ([done, total], status: Status) => [done + status.done.size, total + status.total],
      [0, 0],
    );
    return (done / total) * 100;
  }
}

export type GbpChecklistUrlType =
  | 'store'
  | 'diff'
  | 'photo'
  | 'photoInterior'
  | 'photoExterior'
  | 'localPost'
  | 'review';

export type GbpChecklistItem = {
  title: string;
  status: Status;
  url: GbpChecklistUrlType;
};
