import { List, Record } from 'immutable';

import { StoreOffer, StoreOffers } from 'models/Composite/StoreOffer';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { User } from 'models/Domain/User';

import { OFFER_TYPE_FILTER_ALL, OfferTypeFilter, OfferTypeForFilter } from './OfferTypeFilter';
import { StoresFilter } from './StoresFilter';

export class StoreOfferFilter extends Record<{
  storeFilter: StoresFilter;
  offerTypeFilter: OfferTypeFilter;
}>({
  storeFilter: new StoresFilter(),
  offerTypeFilter: new OfferTypeFilter(),
}) {
  changeStoreFilter(storeFilter: StoresFilter) {
    return this.set('storeFilter', storeFilter);
  }

  changeStoreFilterFromString(value: string) {
    return this.set('storeFilter', this.storeFilter.update(value));
  }

  changeOfferType(offerType: OfferTypeForFilter) {
    return this.setIn(['offerTypeFilter', 'offerType'], offerType);
  }

  changeStoreFilterByUserRole(user: User) {
    const filterType =
      user.isMemberUser && !user.stores.isEmpty()
        ? 'belonging_stores'
        : user.isSvUser && !user.managing_stores.isEmpty()
          ? 'managing_stores'
          : 'all';
    return this.set('storeFilter', this.storeFilter.changeType(filterType));
  }

  getFilteredStoreOffers(storeOffers: StoreOffers, stores: Stores, storeLists: StoreLists, currentUser: User) {
    const storeOffersByOfferType = this.getStoreOffersByOfferType(storeOffers);

    switch (this.storeFilter.type) {
      case 'managing_stores':
      case 'belonging_stores':
        return this.getStoreOffersByStores(storeOffersByOfferType, currentUser, stores);
      case 'store':
        return this.getStoreOffersByStore(storeOffersByOfferType);
      case 'store_list':
        return this.getStoreOffersByStoreList(storeOffersByOfferType, storeLists);
      default:
        return storeOffersByOfferType;
    }
  }

  private getStoreOffersByOfferType(storeOffers: StoreOffers) {
    const offerType = this.offerTypeFilter.offerType || OFFER_TYPE_FILTER_ALL;

    let filteredStoreOffers: List<StoreOffer> = List();
    storeOffers.list.forEach((storeOffer) => {
      const filteredByOfferType = storeOffer.set('offers', storeOffer.getOffersByOfferType(offerType));
      filteredStoreOffers = filteredStoreOffers.push(filteredByOfferType);
    });

    return storeOffers.set('list', filteredStoreOffers);
  }

  private getStoreOffersByStores(storeOffers: StoreOffers, currentUser: User, stores: Stores) {
    const filteredStores = stores.filterByUserRole(currentUser);
    return storeOffers.update('list', (list) =>
      list.filter((storeOffer) => filteredStores.findStore(storeOffer.store_id)),
    );
  }

  private getStoreOffersByStore(storeOffers: StoreOffers) {
    return storeOffers.set(
      'list',
      storeOffers.list.filter((storeOffer) => storeOffer.store_id === this.storeFilter.storeId),
    );
  }

  private getStoreOffersByStoreList(storeOffers: StoreOffers, storeLists: StoreLists) {
    const storeList = storeLists.list.find((storeList) => storeList.id === this.storeFilter.storeListId);
    if (!storeList) return storeOffers;

    return storeOffers.set(
      'list',
      storeOffers.list.filter((storeOffer) => storeList.stores.includes(storeOffer.store_id)),
    );
  }
}
