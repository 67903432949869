import { Set } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { MapSearchRankGraphData, MapSearchRankTableData } from 'models/Domain/MapSearchRank/MapSearchRank';
import { MapSearchRankCompetitorAverageData } from 'models/Domain/MapSearchRank/MapSearchRankCompetitorAverage';
import { MapSearchRankSearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';

const actionCreator = actionCreatorFactory('MapSearchRank');

export const MapSearchRankActions = {
  initializePage: actionCreator('initializePage'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  commitSearchCondition: actionCreator<MapSearchRankSearchCondition>('commitSearchCondition'),
  setSearchCondition: actionCreator<MapSearchRankSearchCondition>('setSearchCondition'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),
  setSearchKeywordGraphData: actionCreator<MapSearchRankGraphData>('setSearchKeywordGraphData'),
  setSearchKeywordTableData: actionCreator<MapSearchRankTableData>('setSearchKeywordTableData'),
  setIsLoadingGraphData: actionCreator<boolean>('setIsLoadingGraphData'),
  setIsLoadingTableData: actionCreator<boolean>('setIsLoadingTableData'),
  setIsSelectedAverage: actionCreator<boolean>('setIsSelectedAverage'),
  setSelectedConfigIds: actionCreator<Set<number>>('setSelectedConfigIds'),
  setSelectedTags: actionCreator<Set<string>>('setSelectedTags'),
  setTableData: actionCreator<MapSearchRankTableData>('setTableData'),
  setGraphData: actionCreator<MapSearchRankGraphData>('setGraphData'),
  setCompetitorAverageData: actionCreator<MapSearchRankCompetitorAverageData>('setCompetitorAverageData'),
  updateSelectedTableData: actionCreator<{
    isSelectedAverage?: boolean;
    selectedConfigIds?: Set<number>;
  }>('updateSelectedTableData'),
  setNeedsUpdateCompetitorAverageData: actionCreator<boolean>('setNeedsUpdateCompetitorAverageData'),
};
