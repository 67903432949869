import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { MapSearchRankConfigImportResult } from 'models/Domain/MapSearchRank/MapSearchRankConfigImportResult';
import { MapSearchRankConfigValidationResult } from 'models/Domain/MapSearchRank/MapSearchRankConfigValidationResult';

import { MapSearchRankConfigImportActions as Actions } from './actions';

export class MapSearchRankConfigImportState extends Record<{
  isValidateProcessing: boolean;
  validationExecutionArn: ExecutionArnStatus | null;
  validationResult: MapSearchRankConfigValidationResult | null;

  isImportProcessing: boolean;
  importExecutionArn: ExecutionArnStatus | null;
  importResult: MapSearchRankConfigImportResult | null;
}>({
  isValidateProcessing: false,
  validationExecutionArn: null,
  validationResult: null,

  isImportProcessing: false,
  importExecutionArn: null,
  importResult: null,
}) {
  /**
   * インポートAPIが受け付けられたか
   */
  get isAcceptedImport() {
    return !!this.importExecutionArn;
  }

  get importStatus() {
    if (this.isValidateProcessing) {
      return 'validating';
    } else if (this.isImportProcessing) {
      return 'importing';
    } else if (this.importResult) {
      if (this.importResult.isSuccess) {
        return 'importCompleted';
      } else {
        return 'importFailed';
      }
    } else if (this.validationResult) {
      if (this.validationResult.isSuccess) {
        return 'validationCompleted';
      } else {
        return 'validationFailed';
      }
    }
    return 'initial';
  }
}

export const mapSearchRankConfigImportReducer = reducerWithInitialState(new MapSearchRankConfigImportState())
  .case(Actions.clearStatus, (state, payload) => {
    return new MapSearchRankConfigImportState();
  })
  .case(Actions.setIsValidateProcessing, (state, payload) => {
    return state.set('isValidateProcessing', payload);
  })
  .case(Actions.setValidationExecutionArn, (state, payload) => {
    return state.set('validationExecutionArn', payload);
  })
  .case(Actions.setValidationResult, (state, payload) => {
    return state.set('validationResult', payload);
  })
  .case(Actions.setIsImportProcessing, (state, payload) => {
    return state.set('isImportProcessing', payload);
  })
  .case(Actions.setImportExecutionArn, (state, payload) => {
    return state.set('importExecutionArn', payload);
  })
  .case(Actions.setImportResult, (state, payload) => {
    return state.set('importResult', payload);
  });
