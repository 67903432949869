import React from 'react';

import { Checkbox, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { DatePicker } from 'components/atoms/DatePicker';
import { GmbRegularHours } from 'models/Domain/GmbLocation/GmbRegularHours';
import { GmbTimePeriod } from 'models/Domain/GmbLocation/GmbTimePeriod';
import { GmbTimePeriodString } from 'models/Domain/GmbLocation/GmbTimePeriodString';
import { COLOR } from 'style/color';

export const EditRegularHours: React.FC<{
  regularHours: GmbRegularHours;
  changeRegularHours: (v: GmbRegularHours) => void;
}> = ({ regularHours, changeRegularHours }) => {
  const { isValid, error } = regularHours.validate;

  return (
    <Wrapper>
      <Label>営業時間</Label>
      {!isValid && <Error>{error}</Error>}
      <ContentWrapper>
        {regularHours.regularHoursForEdit.map((hours, idx) => (
          <DayWrapper key={idx}>
            <div>
              <ListWrapper>
                <DayOfTheWeek>{hours.name}</DayOfTheWeek>
                <CheckboxHoliday
                  toggle
                  checked={hours.gmbTimePeriods.isOpen}
                  onChange={() =>
                    changeRegularHours(regularHours.toggleOpenDay(hours.type, !hours.gmbTimePeriods.isOpen))
                  }
                />
                <LabelHoliday>{hours.gmbTimePeriods.isOpen ? '営業日' : '定休日'}</LabelHoliday>
              </ListWrapper>
            </div>
            <>
              <TimeWrapper>
                {hours.gmbTimePeriods.isOpen &&
                  hours.gmbTimePeriods.list.map((hour: GmbTimePeriod, idx) => (
                    <TimeListWrapper key={idx}>
                      <StyledDatePicker
                        dateFormat={'HH:mm'}
                        timeFormat={'HH:mm'}
                        selected={hour.openTime.toDayjs?.toDate()}
                        onChange={(value: Date) =>
                          changeRegularHours(
                            regularHours.changePeriod(
                              hours.type,
                              idx,
                              GmbTimePeriodString.fromDate(value),
                              hour.closeTime,
                            ),
                          )
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText={'開始時間'}
                      />
                      <Period>ー</Period>
                      <StyledDatePicker
                        dateFormat={'HH:mm'}
                        timeFormat={'HH:mm'}
                        selected={hour.closeTime.toDayjs?.toDate()}
                        onChange={(value: Date) =>
                          changeRegularHours(
                            regularHours.changePeriod(
                              hours.type,
                              idx,
                              hour.openTime,
                              GmbTimePeriodString.fromDate(value),
                            ),
                          )
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText={'終了時間'}
                      />
                      {!hours.gmbTimePeriods.list.isEmpty() && (
                        <XIcon
                          onClick={() => {
                            changeRegularHours(regularHours.removePeriod(hours.type, idx));
                          }}
                          name='x'
                        />
                      )}
                    </TimeListWrapper>
                  ))}
              </TimeWrapper>
              {hours.gmbTimePeriods.isOpen && (
                <AddWrapper>
                  <AddTime onClick={() => changeRegularHours(regularHours.addOpenDay(hours.type))}>
                    営業時間を追加
                  </AddTime>
                </AddWrapper>
              )}
            </>
          </DayWrapper>
        ))}
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Label = styled.label`
  font-weight: bold;
`;

const Error = styled.div`
  margin: 16px 0;
  color: ${COLOR.ERROR};
`;

const XIcon = styled(Icon)`
  &&& {
    margin-left: 16px;
    cursor: pointer;
  }
`;

const DayOfTheWeek = styled.div`
  margin-right: 20px;
`;

const Period = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const ContentWrapper = styled.div``;
const DayWrapper = styled.div`
  display: flex;
  min-height: 30px;
  margin-top: 16px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const TimeWrapper = styled.div`
  margin-right: 8px;
  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

const TimeListWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-top: 8px;
`;

const AddWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-popper {
    z-index: 10; /* デフォルトのz-index: 1では他のコンポーネントが上に表示されてしまう */
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    width: 100px;
    padding: 0 8px;
    font-family: monospace !important;
  }
`;

const CheckboxHoliday = styled(Checkbox)`
  margin-right: 8px;
`;

const LabelHoliday = styled.label`
  display: block;
  margin-right: 16px;
`;

const AddTime = styled.div`
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
`;
