import dayjs, { Dayjs } from 'dayjs';
import { List, Record, Set, is } from 'immutable';

import {
  MapSearchRanksAverageGraphApiParams,
  MapSearchRanksCompetitorsAverageStartParams,
  MapSearchRanksGraphParams,
  MapSearchRanksTableParams,
} from 'ApiClient/GmbApi';
import {
  booleanToSearchParam,
  numberComparator,
  parseBooleanParam,
  parseDateParameter,
  parseNumberParameter,
} from 'helpers/search';
import { Group } from 'types/Common';

export type AggregateUnit = 'day' | 'week' | 'month';
// 集計方法
// 画面上はタブ名が「キーワード」だが、後に「店舗単位」「キーワード単位」が追加されるかもしれないので、'keyword'にはしないでおく
export type AggregateType = 'competitor' | undefined;

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<
    keyof FilterStatusType | keyof SortStatusType | keyof PaginationType | 'sort',
    'isAllStoreIds' | 'isEnabledComparison'
  >]: string;
} = {
  startDate: 'sd',
  endDate: 'ed',
  comparisonStartDate: 'csd',
  comparisonEndDate: 'ced',
  group: 'st',
  storeIds: 'si',
  showClosedStores: 'sc',
  searchWords: 'sw',
  searchWordsExactMatch: 'swm',
  excludeWords: 'ew',
  excludeWordsExactMatch: 'ewm',
  aggregateUnit: 'au',
  aggregateType: 'at',
  sort: 'sort',
  key: 'sk',
  order: 'so',
  page: 'page',
  limit: 'limit',
};

const DEFAULT_GROUP = null;
const DEFAULT_IS_ALL_STORE_IDS = true;
const DEFAULT_STORE_IDS = Set<number>();
const DEFAULT_SHOW_CLOSED_STORES = false;
const DEFAULT_SEARCH_WORDS = Set<string>();
const DEFAULT_EXACT_MATCH = false;
const DEFAULT_IS_ENABLED_COMPARISON = false;
// 初期値は集計単位「週」期間「3ヶ月」前日の2ヶ月前の1日から前日まで
const DEFAULT_AGGREGATE_UNIT = 'week';
const DEFAULT_AGGREGATE_TYPE = undefined;

// FIXME
// デフォルト値をモジュールレベルで定義してしまうと、テスト時に値(maxDateのdayjs())が固定できないので
// 関数の形にして利用するときに生成するようにしている
export const defaultDates = () => {
  const maxDate = dayjs().subtract(1, 'day').startOf('day');
  const DEFAULT_START_DATE = maxDate.subtract(2, 'month').startOf('month');
  const DEFAULT_END_DATE = maxDate;
  // 比較期間は「前の期間」DEFAULT_START_DATEの3ヶ月前から前日まで
  const DEFAULT_COMPARISON_START_DATE = DEFAULT_START_DATE.subtract(3, 'month');
  const DEFAULT_COMPARISON_END_DATE = DEFAULT_START_DATE.subtract(1, 'day');

  return { DEFAULT_START_DATE, DEFAULT_END_DATE, DEFAULT_COMPARISON_START_DATE, DEFAULT_COMPARISON_END_DATE };
};

type FilterStatusType = {
  group: Group;
  storeIds: Set<number>;
  isAllStoreIds: boolean;
  showClosedStores: boolean;
  searchWords: Set<string>;
  searchWordsExactMatch: boolean;
  excludeWords: Set<string>;
  excludeWordsExactMatch: boolean;
  isEnabledComparison: boolean;
  aggregateUnit: AggregateUnit;
  aggregateType: AggregateType;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs;
  comparisonEndDate: Dayjs;
};

export class FilterStatus extends Record<FilterStatusType>({
  group: DEFAULT_GROUP,
  storeIds: DEFAULT_STORE_IDS,
  isAllStoreIds: DEFAULT_IS_ALL_STORE_IDS,
  showClosedStores: DEFAULT_SHOW_CLOSED_STORES,
  searchWords: DEFAULT_SEARCH_WORDS,
  searchWordsExactMatch: DEFAULT_EXACT_MATCH,
  excludeWords: DEFAULT_SEARCH_WORDS,
  excludeWordsExactMatch: DEFAULT_EXACT_MATCH,
  isEnabledComparison: DEFAULT_IS_ENABLED_COMPARISON,
  aggregateUnit: DEFAULT_AGGREGATE_UNIT,
  aggregateType: DEFAULT_AGGREGATE_TYPE,
  startDate: defaultDates().DEFAULT_START_DATE,
  endDate: defaultDates().DEFAULT_END_DATE,
  comparisonStartDate: defaultDates().DEFAULT_COMPARISON_START_DATE,
  comparisonEndDate: defaultDates().DEFAULT_COMPARISON_END_DATE,
}) {
  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({ storeIds, isAllStoreIds });
  }
}

const SORT_KEYS = [
  'store_name',
  'search_word',
  'area_name',
  'rank',
  'rank_comparison',
  'diff',
  'diff_comparison',
  'tag',
  'latest_rank',
] as const;
export type SortKey = (typeof SORT_KEYS)[number];
export type SortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY = 'rank';
const DEFAULT_SORT_ORDER = 'desc';

type SortStatusType = {
  key: SortKey;
  order: SortOrder;
};

export class SortStatus extends Record<SortStatusType>({
  key: DEFAULT_SORT_KEY,
  order: DEFAULT_SORT_ORDER,
}) {
  updateBySortKey(sortKey: SortKey) {
    const { key: currentSortKey, order: currentSortOrder } = this;
    // sortKeyが既に設定されている値であれば、並び順の昇順/降順を変更する
    if (sortKey === currentSortKey) {
      return this.set('order', currentSortOrder === 'desc' ? 'asc' : 'desc');
    } else {
      return this.set('key', sortKey);
    }
  }
}

type SortType = {
  items: List<SortStatus>;
};

export class Sort extends Record<SortType>({
  items: List<SortStatus>(List([new SortStatus()])),
}) {
  hasSortKey(sortKey: SortKey): boolean {
    return this.items.filter((sortItem) => sortItem.key === sortKey).size > 0;
  }

  getSortOrder(sortKey: SortKey): SortOrder | undefined {
    return this.hasSortKey(sortKey)
      ? this.items.filter((sortItem) => sortItem.key === sortKey).first(null)?.order
      : undefined;
  }

  updateFirstBySortKey(sortKey: SortKey) {
    const currentSortOrder = this.getSortOrder(sortKey);
    const newSortOrder = currentSortOrder ? (currentSortOrder === 'desc' ? 'asc' : 'desc') : DEFAULT_SORT_ORDER;
    const updatedItems = this.items.clear().push(new SortStatus({ key: sortKey, order: newSortOrder }));
    return this.set('items', updatedItems);
  }

  addBySortKey(sortKey: SortKey) {
    const updatedItems = this.items.push(new SortStatus({ key: sortKey, order: DEFAULT_SORT_ORDER }));
    return this.set('items', updatedItems);
  }

  deleteByIndex(index: number) {
    const updatedItems = this.items.delete(index);
    return this.set('items', updatedItems);
  }

  changeItemOrderByIndex(indexFrom: number, indexTo: number) {
    const sourceItem = this.items.get(indexFrom);
    if (sourceItem === undefined) {
      return this;
    }
    const updatedItems = this.items.delete(indexFrom).insert(indexTo, sourceItem);
    return this.set('items', updatedItems);
  }

  updateSortKey(index: number, key: SortKey) {
    const updatedItems = this.items.update(index, (item) => item.set('key', key));
    return this.set('items', updatedItems);
  }

  updateSortOrder(index: number, order: SortOrder) {
    const updatedItems = this.items.update(index, (item) => item.set('order', order));
    return this.set('items', updatedItems);
  }

  get sortKeys(): List<SortKey> {
    return this.items.map((sortItem) => sortItem.key);
  }

  get paramsString(): string {
    return this.items.map((sortItem) => `${sortItem.key}:${sortItem.order}`).join(',');
  }
}

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 100;

type PaginationType = {
  page: number;
  limit: number;
};

export class Pagination extends Record<PaginationType>({
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
}) {}

export class MapSearchRankSearchCondition extends Record<{
  filter: FilterStatus;
  sort: Sort;
  pagination: Pagination;
}>({
  filter: new FilterStatus(),
  sort: new Sort(),
  pagination: new Pagination(),
}) {
  /**
   * ページのURLパラメータから条件を生成する
   * @param search URLパラメータ
   * @returns 検索条件
   */
  static fromURLSearchParams(search: string): MapSearchRankSearchCondition {
    const { DEFAULT_END_DATE } = defaultDates();

    const condition = new MapSearchRankSearchCondition();
    let { filter, sort, pagination } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連
    const group = params.get(URLSearchParamsMapping.group);
    if (group) {
      if (group === 'all' || group === 'my_store') {
        // すべての店舗の場合
        filter = filter.set('group', group);
      } else if (group.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('group', parseInt(group, 10));
      }
    }

    const storeIds = params.get(URLSearchParamsMapping.storeIds) || 'all';
    if (storeIds === 'all') {
      filter = filter.setStoreIds(Set<number>(), true);
    } else {
      const values = storeIds
        .split(',')
        .filter((v) => v.match(/^\d+$/))
        .map((v) => parseInt(v, 10));
      filter = filter.setStoreIds(Set(values), false);
    }

    filter = filter.set(
      'showClosedStores',
      parseBooleanParam(params.get(URLSearchParamsMapping.showClosedStores), DEFAULT_SHOW_CLOSED_STORES),
    );

    const searchWords = params.get(URLSearchParamsMapping.searchWords);
    if (searchWords) {
      filter = filter.set('searchWords', Set(searchWords.split(',')));
    }

    filter = filter.set(
      'searchWordsExactMatch',
      parseBooleanParam(params.get(URLSearchParamsMapping.searchWordsExactMatch)),
    );

    const excludeWords = params.get(URLSearchParamsMapping.excludeWords);
    if (excludeWords) {
      filter = filter.set('excludeWords', Set(excludeWords.split(',')));
    }

    filter = filter.set(
      'excludeWordsExactMatch',
      parseBooleanParam(params.get(URLSearchParamsMapping.excludeWordsExactMatch)),
    );

    const aggregateUnit = params.get(URLSearchParamsMapping.aggregateUnit);
    if (aggregateUnit && ['day', 'week', 'month'].includes(aggregateUnit)) {
      filter = filter.set('aggregateUnit', aggregateUnit as AggregateUnit);
    }

    const aggregateType = params.get(URLSearchParamsMapping.aggregateType);
    if (aggregateType && ['competitor'].includes(aggregateType)) {
      filter = filter.set('aggregateType', aggregateType as AggregateType);
    }

    const startDateStr = parseDateParameter(params.get(URLSearchParamsMapping.startDate));
    const endDateStr = parseDateParameter(params.get(URLSearchParamsMapping.endDate));
    if (startDateStr && endDateStr) {
      const startDate = dayjs(startDateStr);
      const endDate = dayjs(endDateStr);
      if (
        startDate.isValid() &&
        endDate.isValid() &&
        startDate.isSameOrBefore(endDateStr) &&
        endDate.isSameOrBefore(DEFAULT_END_DATE)
      ) {
        filter = filter.merge({ startDate, endDate });
      }
    }

    const comparisonStartDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonStartDate));
    const comparisonEndDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonEndDate));
    if (comparisonStartDateStr && comparisonEndDateStr) {
      const comparisonStartDate = dayjs(comparisonStartDateStr);
      const comparisonEndDate = dayjs(comparisonEndDateStr);
      if (
        comparisonStartDate.isValid() &&
        comparisonEndDate.isValid() &&
        comparisonStartDate.isSameOrBefore(comparisonEndDate) &&
        comparisonEndDate.isSameOrBefore(DEFAULT_END_DATE)
      ) {
        filter = filter.merge({ comparisonStartDate, comparisonEndDate, isEnabledComparison: true });
      }
    }

    // 旧ソート関連
    const sortKey = params.get(URLSearchParamsMapping.key) ?? '';
    if (SORT_KEYS.includes(sortKey as any)) {
      let _sortKey = sortKey;
      // 比較ありでsortKeyがdiffの場合は、diff_comparisonに変更する
      if (filter.isEnabledComparison && sortKey === 'diff') {
        _sortKey = 'diff_comparison';
      }
      // 比較なしでsortKeyがdiff_comparisonの場合は、diffに変更する
      if (!filter.isEnabledComparison && sortKey === 'diff_comparison') {
        _sortKey = 'diff';
      }
      // aggregateTypeが指定なしでsortKeyがtagの場合は、store_nameに変更する
      if (!filter.aggregateType && sortKey === 'tag') {
        _sortKey = 'store_name';
      }
      // aggregateTypeがcompetitorでsortKeyがstore_nameまたはsearch_wordの場合は、tagに変更する
      if (filter.aggregateType === 'competitor' && (sortKey === 'store_name' || sortKey === 'search_word')) {
        _sortKey = 'tag';
      }
      const sortOrder = params.get(URLSearchParamsMapping.order) ?? DEFAULT_SORT_ORDER;
      if (['asc', 'desc'].includes(sortOrder)) {
        sort = sort.set('items', List([new SortStatus({ key: _sortKey as SortKey, order: sortOrder as SortOrder })]));
      }
    }

    // ソート関連
    const sortString = params.get(URLSearchParamsMapping.sort) ?? '';
    if (sortString) {
      const sortItems: SortStatus[] = [];
      sortString.split(',').forEach((value) => {
        const sortItemValues = value.split(':');
        const sortKeyString = sortItemValues[0];
        const sortOrderString = sortItemValues[1];

        if (SORT_KEYS.includes(sortKeyString as SortKey)) {
          // filterの状態に一致しないソートキーは除外する
          if (filter.isEnabledComparison && (sortKeyString === 'diff' || sortKeyString === 'latest_rank')) {
            return;
          }
          if (
            !filter.isEnabledComparison &&
            (sortKeyString === 'rank_comparison' || sortKeyString === 'diff_comparison')
          ) {
            return;
          }
          if (!filter.aggregateType && sortKeyString === 'tag') {
            return;
          }
          if (filter.aggregateType === 'competitor' && (sortKey === 'store_name' || sortKey === 'search_word')) {
            return;
          }
          sortItems.push(
            new SortStatus({
              key: sortKeyString as SortKey,
              order: ['asc', 'desc'].includes(sortOrderString) ? (sortOrderString as SortOrder) : DEFAULT_SORT_ORDER,
            }),
          );
        }
      });
      if (sortItems.length > 0) {
        sort = sort.set('items', List(sortItems));
      }
    }

    // ページネーション関連
    pagination = pagination.set(
      'page',
      parseNumberParameter(params.get(URLSearchParamsMapping.page) ?? params.get('pg'), DEFAULT_PAGE, 1),
    );
    pagination = pagination.set(
      'limit',
      parseNumberParameter(params.get(URLSearchParamsMapping.limit) ?? params.get('li'), DEFAULT_LIMIT, 1, 100),
    );

    return condition.merge({ filter, sort, pagination });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    // フィルタ関連
    if (this.filter.group != DEFAULT_GROUP) {
      params.append(URLSearchParamsMapping.group, String(this.filter.group));
    }
    if (!this.filter.isAllStoreIds && this.filter.storeIds.size > 0) {
      params.append(URLSearchParamsMapping.storeIds, this.filter.storeIds.toArray().sort(numberComparator).join(','));
    }

    if (this.filter.showClosedStores != DEFAULT_SHOW_CLOSED_STORES) {
      params.append(URLSearchParamsMapping.showClosedStores, booleanToSearchParam(this.filter.showClosedStores));
    }

    if (!is(this.filter.searchWords, DEFAULT_SEARCH_WORDS)) {
      params.append(URLSearchParamsMapping.searchWords, this.filter.searchWords.join(','));
    }

    if (this.filter.searchWordsExactMatch !== DEFAULT_EXACT_MATCH) {
      params.append(
        URLSearchParamsMapping.searchWordsExactMatch,
        booleanToSearchParam(this.filter.searchWordsExactMatch),
      );
    }

    if (!is(this.filter.excludeWords, DEFAULT_SEARCH_WORDS)) {
      params.append(URLSearchParamsMapping.excludeWords, this.filter.excludeWords.join(','));
    }

    if (this.filter.excludeWordsExactMatch !== DEFAULT_EXACT_MATCH) {
      params.append(
        URLSearchParamsMapping.excludeWordsExactMatch,
        booleanToSearchParam(this.filter.excludeWordsExactMatch),
      );
    }

    params.append(URLSearchParamsMapping.aggregateUnit, this.filter.aggregateUnit);
    params.append(URLSearchParamsMapping.startDate, this.filter.startDate.format('YYYY-MM-DD'));
    params.append(URLSearchParamsMapping.endDate, this.filter.endDate.format('YYYY-MM-DD'));

    if (this.filter.aggregateType) {
      params.append(URLSearchParamsMapping.aggregateType, this.filter.aggregateType);
    }

    if (this.filter.isEnabledComparison) {
      params.append(URLSearchParamsMapping.comparisonStartDate, this.filter.comparisonStartDate.format('YYYY-MM-DD'));
      params.append(URLSearchParamsMapping.comparisonEndDate, this.filter.comparisonEndDate.format('YYYY-MM-DD'));
    }

    // ソート関連
    if (this.sort.items.size > 0) {
      params.append(URLSearchParamsMapping.sort, this.sort.paramsString);
    }

    // ページネーション関連
    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }

    if (this.pagination.limit !== DEFAULT_LIMIT) {
      params.append(URLSearchParamsMapping.limit, String(this.pagination.limit));
    }

    // 見栄え悪いので、'%2Cは','に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  toTableDataRequestParams(): MapSearchRanksTableParams {
    const params: MapSearchRanksTableParams = {
      aggregate_unit: this.filter.aggregateUnit,
      start_date: this.filter.startDate.format('YYYY-MM-DD'),
      end_date: this.filter.endDate.format('YYYY-MM-DD'),
      start_date_compare: this.filter.comparisonStartDate.format('YYYY-MM-DD'),
      end_date_compare: this.filter.comparisonEndDate.format('YYYY-MM-DD'),
      store_ids: this.filter.storeIds.join(','),
      page: this.pagination.page,
      limit: this.pagination.limit,
    };
    if (this.filter.searchWords.size > 0) {
      const key = this.filter.searchWordsExactMatch ? 'match_search_words' : 'search_words';
      params[key] = this.filter.searchWords.join(',');
    }
    if (this.filter.excludeWords.size > 0) {
      const key = this.filter.excludeWordsExactMatch ? 'match_exclude_words' : 'exclude_words';
      params[key] = this.filter.excludeWords.join(',');
    }
    if (this.sort.items.size > 0) {
      params['sort'] = this.sort.paramsString;
    }
    return params;
  }

  toAverageGraphDataRequestParams(isComparison: boolean): MapSearchRanksAverageGraphApiParams {
    const params: MapSearchRanksAverageGraphApiParams = {
      aggregate_unit: this.filter.aggregateUnit as AggregateUnit,
      start_date: (isComparison ? this.filter.comparisonStartDate : this.filter.startDate).format('YYYY-MM-DD'),
      end_date: (isComparison ? this.filter.comparisonEndDate : this.filter.endDate).format('YYYY-MM-DD'),
    };
    if (this.filter.storeIds.size > 0) {
      params['store_ids'] = this.filter.storeIds.join(',');
    }
    if (this.filter.searchWords.size > 0) {
      const key = this.filter.searchWordsExactMatch ? 'match_search_words' : 'search_words';
      params[key] = this.filter.searchWords.join(',');
    }
    if (this.filter.excludeWords.size > 0) {
      const key = this.filter.excludeWordsExactMatch ? 'match_exclude_words' : 'exclude_words';
      params[key] = this.filter.excludeWords.join(',');
    }
    return params;
  }

  toGraphItemDataRequestParams(configId: number, isComparison: boolean): MapSearchRanksGraphParams {
    return {
      aggregate_unit: this.filter.aggregateUnit,
      start_date: (isComparison ? this.filter.comparisonStartDate : this.filter.startDate).format('YYYY-MM-DD'),
      end_date: (isComparison ? this.filter.comparisonEndDate : this.filter.endDate).format('YYYY-MM-DD'),
      config_ids: [configId].join(','),
    };
  }

  toCompetitorAverageStatRequestParams(): MapSearchRanksCompetitorsAverageStartParams {
    const searchWords = this.filter.searchWords.size > 0 ? this.filter.searchWords.toArray() : undefined;
    const excludeWords = this.filter.excludeWords.size > 0 ? this.filter.excludeWords.toArray() : undefined;
    return {
      aggregate_unit: this.filter.aggregateUnit,
      period: {
        start_date: this.filter.startDate.format('YYYY-MM-DD'),
        end_date: this.filter.endDate.format('YYYY-MM-DD'),
      },
      comparison_period:
        !!this.filter.comparisonStartDate && !!this.filter.comparisonEndDate
          ? {
              start_date: this.filter.comparisonStartDate.format('YYYY-MM-DD'),
              end_date: this.filter.comparisonEndDate.format('YYYY-MM-DD'),
            }
          : undefined,
      search_words: this.filter.searchWordsExactMatch ? undefined : searchWords,
      match_search_words: this.filter.searchWordsExactMatch ? searchWords : undefined,
      exclude_words: this.filter.excludeWordsExactMatch ? undefined : excludeWords,
      match_exclude_words: this.filter.excludeWordsExactMatch ? excludeWords : undefined,
      store_ids: this.filter.storeIds.toArray(),
    };
  }
}
