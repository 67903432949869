import React, { useMemo } from 'react';

import { List } from 'immutable';
import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { CompetitorSearchResult } from 'models/Domain/Competitor/CompetitorSearchResult';

type Props = {
  className?: string;
  searchResults: List<CompetitorSearchResult>;
};

export const CompetitorSearchProgress = React.memo<Props>(({ className, searchResults }) => {
  const completedCount = useMemo(() => {
    return searchResults.filter((item) => !item.isLoading).size;
  }, [searchResults]);
  return (
    <Wrapper className={className}>
      <ProgressLabel>
        <LoaderWrapper>
          <Loader active={true} size='small' inline />
        </LoaderWrapper>
        現在競合店舗を検索中です
      </ProgressLabel>
      <Progress value={completedCount} total={searchResults.size} progress='ratio' indicating autoSuccess />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  background: #fff;
  padding: 16px;
`;

const ProgressLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const LoaderWrapper = styled.div`
  margin-right: 8px;
`;
