import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Service, ServiceList as ServiceListModel } from 'models/Domain/Service/Service';

import { ServiceAddModal } from './ServiceAddModal';
import { ServiceListItem } from './ServiceListItem';

type Props = {
  services: ServiceListModel;
  categoryId: string | null;
  onChange: (services: ServiceListModel) => void;
};

export const ServiceList: React.FC<Props> = ({ services, onChange, categoryId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleChange = useCallback(
    (index: number) => (service: Service) => {
      onChange(services.updateItem(index, service));
    },
    [onChange, services],
  );

  const handleDelete = useCallback(
    (index: number) => {
      onChange(services.removeItem(index));
    },
    [onChange, services],
  );

  return (
    <Wrapper>
      <ServiceListContainer>
        {services.items.map((service, index) => (
          <ServiceListItem
            key={service.id}
            service={service}
            onDelete={() => handleDelete(index)}
            onChange={handleChange(index)}
          />
        ))}
      </ServiceListContainer>
      <AddButton onClick={() => setShowModal(true)}>
        <AddIcon />
        サービスを追加する
      </AddButton>
      {showModal && (
        <ServiceAddModal
          isOpen={true}
          onClose={() => setShowModal(false)}
          onChange={onChange}
          services={services}
          categoryId={categoryId}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ServiceListContainer = styled.div`
  margin-bottom: 16px;
`;

const AddButton = styled(Button).attrs({ priority: 'low' })`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AddIcon = styled(Icon).attrs({ type: 'add' })`
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
