import React from 'react';

import { LocationDescriptorObject } from 'history';
import { Link as RouterLink, NavLink as RouterNavLink, Redirect as RouterRedirect } from 'react-router-dom';

import { buildLocationDescriptor, buildPath } from 'helpers/router';

type LinkProps = Omit<React.ComponentProps<typeof RouterLink>, 'to'> & {
  to: string | LocationDescriptorObject<unknown>;
  withOrganizationId?: boolean;
};

/**
 * 与えられたパスにデフォルトで必要なクエリパラメータを付加してリンクを生成するLinkコンポーネント
 * @param props
 * @returns
 */
export const Link = (props: LinkProps) => {
  const { withOrganizationId, to, ...rest } = props;
  const _to = buildToParams(to, withOrganizationId);
  return <RouterLink to={_to} {...rest} />;
};

type NavLinkProps = Omit<React.ComponentProps<typeof RouterNavLink>, 'to'> & {
  to: string | LocationDescriptorObject<unknown>;
  withOrganizationId?: boolean;
};

export const NavLink = (props: NavLinkProps) => {
  const { withOrganizationId, to, ...rest } = props;
  const _to = buildToParams(to, withOrganizationId);
  return <RouterNavLink to={_to} {...rest} />;
};

type RedirectProps = Omit<React.ComponentProps<typeof RouterRedirect>, 'to'> & {
  to: string | LocationDescriptorObject<unknown>;
  withOrganizationId?: boolean;
};

/**
 * 与えられたパスにデフォルトで必要なクエリパラメータを付加してリンクを生成するRedirectコンポーネント
 * @param props
 * @returns
 */
export const Redirect = (props: RedirectProps) => {
  const { withOrganizationId, to, ...rest } = props;
  const _to = buildToParams(to, withOrganizationId);
  return <RouterRedirect to={_to} {...rest} />;
};

const buildToParams = (to: string | LocationDescriptorObject<unknown>, withOrganizationId?: boolean) => {
  if (typeof to === 'string') {
    return buildPath(to, { withOrganizationId });
  } else {
    return buildLocationDescriptor(to, { withOrganizationId });
  }
};
