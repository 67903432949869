import { toast } from 'react-semantic-toasts';
import useSWRImmutable from 'swr/immutable';

import { MemoApi } from 'ApiClient/MemoApi';
import { AccountSingleton } from 'models/Domain/AccountList';
import { Memo, MemoList } from 'models/Domain/Memo/Memo';
import { MemoSearchCondition } from 'models/Domain/Memo/MemoSearchCondition';

/** 全ての施策メモをAPIで取得する */
const fetchAllMemos = async (searchCondition: MemoSearchCondition) => {
  const params = searchCondition.toRequestParams();

  let memoList = new MemoList();
  for (;;) {
    const response = await MemoApi.get(params);

    if (!response.isSuccess) {
      toast({
        type: 'error',
        title: '施策メモの取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
      return new MemoList();
    }

    const memoListPage = MemoList.fromJSON(response.data);
    memoList = memoList.update('items', (items) => items.concat(memoListPage.items));

    // 最後のページまで来たら取得終了
    if (!response.data.pagination.has_next) {
      break;
    }
    // ページング
    params.page = response.data.pagination.current_page + 1;
  }

  return memoList;
};

/** メモの作成 */
export const createMemo = async (memo: Memo) => {
  const response = await MemoApi.post(memo.toUpdateParams());
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メモを作成しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'メモの作成に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }
};

/** メモの更新 */
export const updateMemo = async (memo: Memo) => {
  const response = await MemoApi.patch(memo.id, memo.toUpdateParams());
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メモを更新しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'メモの更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }
};

/** メモの削除 */
export const deleteMemo = async (memo: Memo) => {
  const response = await MemoApi.delete(memo.id);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メモを削除しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'メモの削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }
};

/** 全ての施策メモを取得する用のuseSWRのラッパー */
export const useAllMemos = (searchCondition: MemoSearchCondition) => {
  // 組織の切り替え時に再度取得されるように、keyに現在のorganizationIdを含める
  const currentOrganizationId = AccountSingleton.getInstance().organizationId;
  return useSWRImmutable([searchCondition, currentOrganizationId], fetchAllMemos);
};
