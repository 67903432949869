import React, { useCallback, useEffect, useState } from 'react';

import { CustomRadio, SearchPullDown } from 'components/molecules/SearchPullDown';

const options: { text: string; value: boolean }[] = [
  {
    text: '表示する',
    value: true,
  },
  {
    text: '表示しない',
    value: false,
  },
];

/**
 * 営業ステータスによる店舗の絞り込み
 * 現状は閉業店舗の表示/非表示の切り替え
 */
export const BusinessStatusFilter = React.memo<{
  selectedValue: boolean;
  onChange: (value: boolean) => void;
}>(({ selectedValue, onChange }) => {
  const [inputValue, setInputValue] = useState(selectedValue);

  const handleOnCancel = useCallback(() => {
    setInputValue(selectedValue);
  }, [selectedValue]);

  const handleOnApply = useCallback(() => {
    onChange(inputValue);
  }, [onChange, inputValue]);

  useEffect(() => {
    setInputValue(selectedValue);
  }, [selectedValue]);

  return (
    <SearchPullDown value={'閉業店舗'} onCancel={handleOnCancel} onApply={handleOnApply}>
      {options.map((option) => {
        return (
          <CustomRadio
            key={option.text}
            label={option.text}
            checked={option.value === inputValue}
            onChange={() => setInputValue(option.value)}
          />
        );
      })}
    </SearchPullDown>
  );
});
