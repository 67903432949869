import React, { useCallback, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ActiveCircle, ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import {
  DataKey,
  MemoIconData,
  getComparisonDataKey as _getComparisonDataKey,
  getTargetDataKey as _getTargetDataKey,
  isComparisonDataKey as _isComparisonDataKey,
  getDateLabelWithWeekOfDay,
  getDateRangeFromDateLabel,
  getValueText,
} from 'helpers/graph';
import { createAlbumIndexPath } from 'helpers/path';
import { addNullableValues } from 'helpers/utils';
import { StatsType, gbpPerformanceStatsKeyMapping } from 'models/Domain/GbpPerformance/GbpPerformance';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';
import { AggregateUnit } from 'types/Common';

// 非活性グラフの透明度
const INACTIVE_TRANSPARENCY = 0.8 as const;

// グラフに使われるStatsTypeのリスト
const imageGraphStatsTypes = [
  'imageInteriorCount',
  'imageExteriorCount',
  'imageProductCount',
  'imageAdditionalCount',
] satisfies StatsType[];

export type TargetDataKey = (typeof imageGraphStatsTypes)[number];
export type GraphDataKey = DataKey<TargetDataKey>;

// 共通処理に型を指定する
const isComparisonDataKey = _isComparisonDataKey<TargetDataKey>;
const getTargetDataKey = _getTargetDataKey<TargetDataKey>;
const getComparisonDataKey = _getComparisonDataKey<TargetDataKey>;

const isDataKey = (statsType: StatsType): statsType is TargetDataKey => {
  return (imageGraphStatsTypes as StatsType[]).includes(statsType);
};

export type ImageGraphDataItem = Record<'date' | 'comparisonDate', string> &
  Record<GraphDataKey, number | null | undefined>;

type ImageGraphProps = {
  graphData: ImageGraphDataItem[];
  showComparisonGraph: boolean;
  displayStats: StatsType[];
  height: number;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
  aggregateUnit: AggregateUnit;
  storeIds: ImmutableSet<number>;
  onChangeActiveStats?: (statsType: StatsType) => void;
  showInactiveLegend: boolean;
  graphSettings?: GraphSettings;
};

const imageColors = UNIVERSAL_COLORS.slice(0, 5).toArray();

const getStrokeColor = (dataKey: GraphDataKey): string => {
  const key = getTargetDataKey(dataKey);
  switch (key) {
    case 'imageInteriorCount':
      return imageColors[0];
    case 'imageExteriorCount':
      return imageColors[1];
    case 'imageProductCount':
      return imageColors[2];
    case 'imageAdditionalCount':
      return imageColors[3];
    default:
      throw new Error(`"${dataKey}" is unexpected data`);
  }
};

/**
 * dateKeyの日本語表記を取得する
 * @param dataKey
 */
const getDataKeyLabel = (dataKey: GraphDataKey) => {
  const isComparison = isComparisonDataKey(dataKey);
  const label = gbpPerformanceStatsKeyMapping[getTargetDataKey(dataKey)];
  return isComparison ? `${label}（比較）` : label;
};

export const ImageGraph = React.memo<ImageGraphProps>(
  ({
    graphData,
    showComparisonGraph,
    displayStats,
    height,
    memoData,
    onClickMemo,
    aggregateUnit,
    storeIds,
    onChangeActiveStats,
    showInactiveLegend,
    graphSettings = new GraphSettings(),
  }) => {
    const [activeDataKey, setActiveDataKey] = useState<TargetDataKey | null>(null);

    const isActiveDataKey = useCallback(
      (dataKey: GraphDataKey) => {
        const targetDataKey = getTargetDataKey(dataKey);
        return activeDataKey == null || activeDataKey === targetDataKey;
      },
      [activeDataKey],
    );

    const getColor = useCallback(
      (dataKey: GraphDataKey) => {
        const targetDataKey = getTargetDataKey(dataKey);
        return transparentize(
          isActiveDataKey(targetDataKey) ? 0 : INACTIVE_TRANSPARENCY,
          getStrokeColor(targetDataKey),
        );
      },
      [isActiveDataKey],
    );

    const onClickBar = useCallback(
      (dateLabel: string) => {
        const [startDate, endDate] = getDateRangeFromDateLabel(dateLabel, aggregateUnit);
        window.open(createAlbumIndexPath({ storeIds: storeIds.toArray(), startDate, endDate }), '_blank');
      },
      [aggregateUnit, storeIds],
    );

    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        const displayDataKeys = items.map((item) => item.dataKey as StatsType);
        const payload = items[0]?.payload;
        const imageCount = addNullableValues(...displayDataKeys.map((dataKey) => payload?.[dataKey]));

        // 2項目以上選択されている場合に合計値を表示する
        const showTotal = items.length >= 2;

        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {showTotal && (
              <ChartTooltip.Item key={'total'}>
                <ChartTooltip.Label color={COLOR.BLACK}>写真追加数（合計）</ChartTooltip.Label>
                <ChartTooltip.Value color={COLOR.BLACK}>{getValueText(imageCount)}</ChartTooltip.Value>
              </ChartTooltip.Item>
            )}
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <ChartTooltip.Item key={dataKey}>
                  <ChartTooltip.Label color={itemColor}>
                    <ActiveCircle color={getColor(dataKey)} />
                    {item.name}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>{getValueText(item.value)}</ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [getColor],
    );

    // 比較期間ありの場合に表示するツールチップ
    const renderComparisonTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        const displayDataKeys = items.map((item) => item.dataKey as StatsType);
        const payload = items[0]?.payload;
        const imageCount = addNullableValues(
          ...displayDataKeys
            .filter((dataKey) => !isComparisonDataKey(dataKey as GraphDataKey))
            .map((dataKey) => payload?.[dataKey]),
        );
        const comparisonImageCount = addNullableValues(
          ...displayDataKeys
            .filter((dataKey) => isComparisonDataKey(dataKey as GraphDataKey))
            .map((dataKey) => payload?.[dataKey]),
        );

        // 2項目以上選択されている場合に合計値を表示する
        const showTotal = items.length / 2 >= 2;

        let usedTitle = ImmutableSet<string>(); // 使用済みのタイトル

        return (
          <ChartTooltip>
            {showTotal && (
              <React.Fragment key={'totalReviews'}>
                <ChartTooltip.Title color={COLOR.BLACK}>写真追加数（合計）</ChartTooltip.Title>
                <ChartTooltip.Item>
                  <ChartTooltip.Label color={COLOR.BLACK}>
                    {getDateLabelWithWeekOfDay(payload?.comparisonDate) ?? ''}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={COLOR.BLACK}>{getValueText(comparisonImageCount)}</ChartTooltip.Value>
                </ChartTooltip.Item>
                <ChartTooltip.Item>
                  <ChartTooltip.Label color={COLOR.BLACK}>
                    {getDateLabelWithWeekOfDay(payload?.date) ?? ''}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={COLOR.BLACK}>{getValueText(imageCount)}</ChartTooltip.Value>
                </ChartTooltip.Item>
              </React.Fragment>
            )}
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const targetDataKey = getTargetDataKey(dataKey);
              const isComparison = isComparisonDataKey(dataKey);
              const title = getDataKeyLabel(targetDataKey);
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const date = item.payload.date;
              const comparisonDate = item.payload.comparisonDate;
              const itemColor = getColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && (
                    <ChartTooltip.Title color={itemColor}>
                      <ActiveCircle color={itemColor} />
                      {title}
                    </ChartTooltip.Title>
                  )}
                  <ChartTooltip.Item>
                    <ChartTooltip.Label color={textColor}>
                      {getDateLabelWithWeekOfDay(isComparison ? comparisonDate : date)}
                    </ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>{getValueText(item.value)}</ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
          </ChartTooltip>
        );
      },
      [getColor],
    );

    // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
    let displayedMemoDate = ImmutableSet<string>();

    return (
      <ResponsiveContainer height={height}>
        <ComposedChart
          data={graphData}
          margin={{ top: 64, right: 32, left: 32, bottom: 16 }}
          onMouseLeave={() => setActiveDataKey(null)}
        >
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            onClick={(o: any) => onChangeActiveStats && onChangeActiveStats(getTargetDataKey(o.dataKey))}
            content={(props: any) => renderLegend({ ...props, showInactiveLegend })}
          />
          <XAxis dataKey={'date'} tickMargin={16} />
          <YAxis
            label={{ value: '写真追加数', position: 'top', offset: 24 }}
            allowDecimals={false}
            allowDataOverflow={true}
            includeHidden={false}
            domain={graphSettings.domain}
          />
          <CartesianGrid vertical={false} />
          {imageGraphStatsTypes.map((dataKey) => {
            // グラフに使えるデータであることを確認する
            if (!isDataKey(dataKey)) {
              return null;
            }
            const targetDataKey = getTargetDataKey(dataKey);
            const comparisonDataKey = getComparisonDataKey(dataKey);
            const color = transparentize(isActiveDataKey(dataKey) ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(dataKey));
            const hide = !displayStats.includes(targetDataKey);
            return (
              <React.Fragment key={dataKey}>
                {showComparisonGraph && (
                  <Bar
                    dataKey={comparisonDataKey}
                    stackId={'comparisonImage'}
                    fill={color}
                    fillOpacity={hide ? 0.1 : 0.6}
                    stroke={color}
                    strokeDasharray={'1 1'}
                    name={getDataKeyLabel(comparisonDataKey)}
                    onClick={(o: any) => onClickBar(o.comparisonDate)}
                    isAnimationActive={false}
                    cursor={'pointer'}
                    hide={hide}
                  />
                )}
                <Bar
                  dataKey={dataKey}
                  stackId={'image'}
                  fill={color}
                  fillOpacity={hide ? 0.4 : 1.0}
                  stroke={color}
                  name={getDataKeyLabel(targetDataKey)}
                  onClick={(o: any) => onClickBar(o.date)}
                  isAnimationActive={false}
                  cursor={'pointer'}
                  hide={hide}
                />
              </React.Fragment>
            );
          })}

          {memoData?.map((item) => {
            // すでにメモを表示している日付ならスキップ
            if (displayedMemoDate.has(item.date)) {
              return null;
            }
            // メモを表示している日付リストに追加
            displayedMemoDate = displayedMemoDate.add(item.date);
            return (
              <ReferenceLine
                key={item.date}
                x={item.date}
                stroke={'#9994'}
                label={(props) =>
                  MemoIcon({
                    ...props,
                    title: getDateLabelWithWeekOfDay(item.date),
                    content: item.contents,
                    onClick: () => onClickMemo && onClickMemo(item.ids),
                  }) as any
                }
              />
            );
          })}
          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            content={showComparisonGraph ? renderComparisonTooltip : renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
);
