import axios from 'axios';

import ApiClient, { FaasApiClient } from 'ApiClient';
import { PromotionActionTypeKey } from 'models/Domain/Promotion/Promotion';

const apiClient = new ApiClient({});
const faasApiClient = new FaasApiClient({});

const INSTAGRAM_PATH = '/instagram';

export type GetInstagramListResponse = {
  items: {
    id: number;
    ig_user_id: string;
    ig_name: string;
    ig_username: string;
    is_deleted: boolean;
    is_valid: boolean;
    created_by: number;
    create_at: string;
    update_at: string;
  }[];
};

export type GetInstagramResponse = {
  id: number;
  ig_user_id: string;
  ig_name: string;
  ig_username: string;
  is_deleted: boolean;
  is_valid: boolean;
  created_by: number;
  post_rule: {
    id: number;
    exclude_words: string[] | null;
    body_length: number | null;
    targets: {
      id: number;
      group_ids: number[] | null;
      store_ids: number[] | null;
      match_words: string[] | null;
      exclude_words: string[] | null;
      action_type: PromotionActionTypeKey;
      action_url: string | null;
      position: number | null;
      create_at: string;
      update_at: string;
    }[];
    create_at: string;
    update_at: string;
  };
  create_at: string;
  update_at: string;
};

export type PostInstagramParams = {
  ig_user_id: string;
  ig_name: string;
  ig_username: string;
  page_id: string;
  page_access_token: string;
  scopes: string[];
};

export type PostInstagramResponse = {
  id: number;
  ig_user_id: string;
  ig_name: string;
  ig_username: string;
  is_deleted: boolean;
  is_valid: boolean;
  created_by: number;
  create_at: string;
  update_at: string;
};

export type PatchInstagramParams = {
  post_rule: {
    exclude_words?: string[] | null;
    body_length?: number | null;
    targets: {
      id?: number | null;
      group_ids: number[] | null;
      store_ids: number[] | null;
      match_words: string[] | null;
      exclude_words: string[] | null;
      action_type: PromotionActionTypeKey;
      action_url: string | null;
    }[];
  };
};

export type PatchInstagramResponse = {
  id: number;
  ig_user_id: string;
  ig_name: string;
  ig_username: string;
  post_rule?: {
    id: number;
    exclude_words: string[] | null;
    body_length: number | null;
    targets: {
      id: number;
      group_ids: number[] | null;
      store_ids: number[] | null;
      match_words: string[] | null;
      exclude_words: string[] | null;
      action_type: PromotionActionTypeKey;
      action_url: string | null;
      position: number | null;
      create_at: string;
      update_at: string;
    }[];
    create_at: string;
    update_at: string;
  };
  create_at: string;
  update_at: string;
};

export type CheckPostRuleParams = {
  caption: string;
  post_rule: {
    id?: number;
    account_id: number;
    body_length?: number | null;
    exclude_words?: string[] | null;
    create_at?: string;
    update_at?: string;
    targets: {
      id?: number | null;
      post_rule_id?: number | null;
      group_ids: number[] | null;
      store_ids: number[] | null;
      match_words: string[] | null;
      exclude_words: string[] | null;
      action_type: string | null;
      action_url: string | null;
      position?: number | null;
      create_at?: string | null;
      update_at?: string | null;
    }[];
  };
};

export type CheckPostRuleResponse = {
  items: {
    target: {
      id?: number;
      post_rule_id?: number;
      group_ids: number[];
      store_ids: number[];
      match_words: string[];
      exclude_words: string[];
      action_type: string;
      action_url: string;
      position?: number;
      create_at?: string;
      update_at?: string;
    };
    store_ids: number[];
  }[];
};

export class InstagramApi {
  static getList() {
    return apiClient.get<GetInstagramListResponse>(`${INSTAGRAM_PATH}`);
  }

  static get(id: number) {
    return apiClient.get<GetInstagramResponse>(`${INSTAGRAM_PATH}/${id}`);
  }

  static post(params: PostInstagramParams) {
    return apiClient.post<PostInstagramResponse>(`${INSTAGRAM_PATH}`, params);
  }

  static patch(id: number, params: PatchInstagramParams) {
    return apiClient.patch<PatchInstagramResponse>(`${INSTAGRAM_PATH}/${id}`, params);
  }

  static delete(id: number) {
    return apiClient.delete(`${INSTAGRAM_PATH}/${id}`);
  }

  static checkPostRule(params: CheckPostRuleParams) {
    return apiClient.post<CheckPostRuleResponse>(`${INSTAGRAM_PATH}/check`, params);
  }
}

export type GetInstagramAccountsParams = {
  fb_user_id: string;
  fb_user_access_token: string;
};

export type GetInstagramAccountsResponse = {
  executionArn: string;
};

export type GetInstagramAccountsStatusParams = {
  executionArn: string;
};

export type GetInstagramAccountsStatusResponse = {
  status: 'RUNNING' | 'SUCCEEDED' | 'FAILED';
  download_url: string;
  message: string;
};

export type GetInstagramAccountsDataResponse = {
  items: {
    instagram_user_id: string;
    instagram_name: string;
    instagram_username: string;
    instagram_profile_picture_url?: string;
    page_id: string;
    page_access_token: string;
    scopes: string[];
  }[];
};

export class InstagramAccountApi {
  static getAccounts(params: GetInstagramAccountsParams) {
    return faasApiClient.post<GetInstagramAccountsResponse>(`${INSTAGRAM_PATH}/accounts/start`, params);
  }

  static getStatus(params: GetInstagramAccountsStatusParams) {
    return faasApiClient.post<GetInstagramAccountsStatusResponse>(`${INSTAGRAM_PATH}/accounts/status`, params);
  }

  static getData(url: string) {
    return axios.get<GetInstagramAccountsDataResponse>(url);
  }
}
