import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

interface StampRecord {
  id: number;
  name: string;
  url: string;
  timestamp: Dayjs;
}

export class Stamp extends Record<StampRecord>({
  id: 0,
  name: '',
  url: '',
  timestamp: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.timestamp = dayjs.unix(params.timestamp);
    super(params);
  }
}

interface StampsRecord {
  list: List<Stamp>;
}

export class Stamps extends Record<StampsRecord>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data.map((d) => new Stamp(d)));
    super({ list });
  }

  findStamp(stampId: string | number | undefined) {
    if (!stampId) {
      return undefined;
    }
    return this.list.find((stamp) => stamp.id === Number(stampId));
  }
}
