import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ActiveCircle, ChartTooltip } from 'components/atoms/ChartTooltip';
import { legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import { getDateLabelWithWeekOfDay, getValueText } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { SearchVolumeSearchResult } from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import { YearMonth } from 'models/Domain/YearMonth';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';

// 非活性のグラフの透明度
const INACTIVE_TRANSPARENCY = 0.8 as const;

type Props = {
  className?: string;
  searchResult: SearchVolumeSearchResult;
  selectedKeywords: ImmutableSet<string>;
  onClickLegend?: (keyword: string) => void;
  graphSettings?: GraphSettings;
};

export const SearchVolumeDetailGraph = React.memo<Props>(
  ({ searchResult, selectedKeywords, onClickLegend, graphSettings = new GraphSettings() }) => {
    // ホバーしたときに強調表示するキーワード
    const [activeKeyword, setActiveKeyword] = useState<string | null>(null);

    const items = searchResult.items;
    const composedGraphData = useMemo(() => {
      const {
        condition: {
          period: { startMonth, endMonth },
        },
      } = searchResult;
      return YearMonth.range(startMonth, endMonth).map((targetMonth) => {
        const data: Record<string, any> = {
          date: targetMonth.format(),
        };
        items.forEach((item) => {
          data[item.keyword] = item.metrics.getDataByMonth(targetMonth);
        });
        return data;
      });
    }, [items, searchResult]);

    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];

        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {items.map((item, index) => {
              const circleColor = transparentize(
                activeKeyword == null || activeKeyword === item.name ? 0 : INACTIVE_TRANSPARENCY,
                item.color ?? COLOR.WHITE,
              );
              return (
                <ChartTooltip.Item key={item.name}>
                  <ChartTooltip.Label color={item.color}>
                    <ActiveCircle color={circleColor} />
                    {item.name}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value>{getValueText(item.value)}</ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [activeKeyword],
    );

    const handleOnMouseEnter = useCallback(
      (value: string | null) => () => {
        setActiveKeyword(value);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveKeyword(null);
    }, []);

    return (
      <ResponsiveContainer minHeight={300}>
        <LineChart
          data={composedGraphData}
          margin={{ top: 64, right: 32, left: 32, bottom: 16 }}
          onMouseLeave={() => setActiveKeyword(null)}
        >
          <Legend
            verticalAlign={'top'}
            onClick={(o: any) => onClickLegend && onClickLegend(o.dataKey)}
            wrapperStyle={legendWrapperStyle}
            content={(props: any) => renderLegend(props)}
          />
          <CartesianGrid vertical={false} />
          <XAxis dataKey={'date'} tickMargin={16} interval={'equidistantPreserveStart'} />
          <YAxis
            label={{ value: '検索数', position: 'top', offset: 24 }}
            tickFormatter={(value) => getValueText(value)}
            allowDecimals={false}
            domain={graphSettings.domain}
            allowDataOverflow={true}
          />
          {selectedKeywords.toList().map((keyword, index) => {
            const color = UNIVERSAL_COLORS.get(index % UNIVERSAL_COLORS.size);
            const opacity = !activeKeyword || activeKeyword === keyword ? 1.0 : 1.0 - INACTIVE_TRANSPARENCY;
            return (
              <Line
                key={keyword}
                dataKey={keyword}
                strokeWidth={2}
                stroke={color}
                strokeOpacity={opacity}
                isAnimationActive={false}
                dot={{ fill: color, r: 2, opacity, clipDot: false }}
                activeDot={{
                  cursor: 'pointer',
                  onMouseEnter: handleOnMouseEnter(keyword),
                  onMouseLeave: handleOnMouseLeave,
                }}
                cursor={'pointer'}
                onMouseEnter={handleOnMouseEnter(keyword)}
                onMouseLeave={handleOnMouseLeave}
              />
            );
          })}
          <Tooltip wrapperStyle={{ zIndex: 10 }} content={renderTooltip} filterNull={false} isAnimationActive={false} />
        </LineChart>
      </ResponsiveContainer>
    );
  },
);
