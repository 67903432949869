import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { List, is } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { OmoInsightGraphApi, OmoInsightSummaryApi, OmoProductStatusApi } from 'ApiClient/OmoApi';
import { pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { CompositeOmoInsightSummary, OmoInsightSummary } from 'models/Domain/OmoInsight/InsightSummary';
import { CompositeOMOInsightGraphData, OMOInsightGraphData } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { OmoInsightSearchCondition } from 'models/Domain/OmoInsight/OmoInsightSearchCondition';
import { ProductStatus } from 'models/Domain/OmoInsight/ProductStatusSummary';
import { State } from 'modules/reducers';
import { waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

import { OmoInsightActions } from './actions';
import { OmoInsightSelectors } from './selectors';

export default function* saga() {
  yield takeLatest(OmoInsightActions.initializePage, initializePage);
  yield takeLatest(OmoInsightActions.commitSearchCondition, commitSearchConditionProxy);
  yield takeLatest(OmoInsightActions.commitSelectedMerchantId, commitSelectedMerchantId);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}
// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const searchCondition: OmoInsightSearchCondition = yield select(OmoInsightSelectors.selectSearchCondition);

  // 検索条件が残っている場合、URLの復元を実施する
  if (!searchCondition) {
    // URLパラメータから検索条件を初期化する
    yield call(initializeSearchCondition);
  } else {
    // URLの更新だけを行う
    yield call(updateQueryPerameter, 'replace');
  }
}

// ページへのランディング時の初期化処理(データの取得を伴う)
function* initializePageForFirstRendering() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // URLパラメータから検索条件を初期化する
  yield call(initializeSearchCondition);
  yield call(commitSearchCondition, null, 'replace');
}

/**
 * URLのクエリパラメータから検索条件の初期化を行う
 */
function* initializeSearchCondition() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  yield put(OmoInsightActions.setIsInitializedSearchCondition(false));
  const searchCondition = OmoInsightSearchCondition.fromURLSearchParams(location.search);
  yield put(OmoInsightActions.setSearchCondition(searchCondition));
  yield put(OmoInsightActions.setIsInitializedSearchCondition(true));
}

function* commitSearchConditionProxy(action: ReturnType<typeof OmoInsightActions.commitSearchCondition>) {
  yield call(commitSearchCondition, action.payload);
}

function* commitSearchCondition(
  searchCondition: OmoInsightSearchCondition | null,
  updateUrlMethod: 'push' | 'replace' = 'push',
) {
  let prevSearchCondition: OmoInsightSearchCondition | null = null;
  if (searchCondition) {
    // 現在の検索条件を保持しておく
    yield put(OmoInsightActions.updatePrevSearchCondition());
    prevSearchCondition = yield select(OmoInsightSelectors.selectPrevSearchCondition);

    yield put(OmoInsightActions.setSearchCondition(searchCondition));
  } else {
    searchCondition = yield select(OmoInsightSelectors.selectSearchCondition);
  }

  const isFirstSearch: boolean = yield select(OmoInsightSelectors.selectIsFirstSearch);

  // 検索条件に差分がある場合、データの取得及びURLの更新を実施する
  if (searchCondition && (isFirstSearch || !is(searchCondition, prevSearchCondition))) {
    if (isFirstSearch) {
      yield put(OmoInsightActions.setIsFirstSearch(false));
    }

    // URLの更新を行う
    yield call(updateQueryPerameter, updateUrlMethod);
    // データの取得を行う
    yield call(updateOmoInsight);
  }
}

function* updateQueryPerameter(updateUrlMethod: 'push' | 'replace' = 'push') {
  const searchCondition: OmoInsightSearchCondition | null = yield select(OmoInsightSelectors.selectSearchCondition);
  if (!searchCondition) {
    return;
  }
  // URLの更新を行う
  const location: Location = yield select((state: State) => state.router.location);
  const search = searchCondition.toURLSearchParams();
  const path = `${location.pathname}?${search}`;
  if (updateUrlMethod === 'push') {
    yield put(pushWithOrganizationId(path));
  } else {
    yield put(replaceWithOrganizationId(path));
  }
}

/**
 * URLパラメータから検索条件を復元して取得する
 */
function* updateOmoInsight() {
  const searchCondition: OmoInsightSearchCondition = yield select(OmoInsightSelectors.selectSearchCondition);

  const prevSearchCondition: OmoInsightSearchCondition | null = yield select(
    OmoInsightSelectors.selectPrevSearchCondition,
  );

  if (prevSearchCondition && !prevSearchCondition.needsUpdate(searchCondition)) {
    return;
  }

  // データを取得
  yield fork(fetchProductStatusData);
  yield fork(fetchInsightSummary);
}

function* fetchProductStatusData() {
  const searchCondition: YieldReturn<typeof OmoInsightSelectors.selectSearchCondition> = yield select(
    OmoInsightSelectors.selectSearchCondition,
  );
  if (!searchCondition) {
    return;
  }
  yield put(OmoInsightActions.setLoadingStatus({ target: 'productStatus', value: true }));
  try {
    const params = searchCondition.toOmoProductStatusApiParams();
    const response: YieldReturn<typeof OmoProductStatusApi.get> = yield call(OmoProductStatusApi.get, params);
    if (response.isSuccess) {
      yield put(OmoInsightActions.setProductStatus(ProductStatus.fromJson(response.data)));
    } else {
      toast({
        type: 'error',
        title: '商品ステータスが取得できませんでした',
        description: (response.error as any)?.detail?.error || '',
        time: 10000,
      });
      yield put(OmoInsightActions.setProductStatus(new ProductStatus()));
    }
  } finally {
    yield put(OmoInsightActions.setLoadingStatus({ target: 'productStatus', value: false }));
  }
}

function* fetchInsightSummary() {
  const searchCondition: YieldReturn<typeof OmoInsightSelectors.selectSearchCondition> = yield select(
    OmoInsightSelectors.selectSearchCondition,
  );
  if (!searchCondition) {
    return;
  }

  yield put(OmoInsightActions.setLoadingStatus({ target: 'insight', value: true }));
  try {
    const params = searchCondition.toOmoInsightSummaryApiParams();
    const response: YieldReturn<typeof OmoInsightSummaryApi.get> = yield call(OmoInsightSummaryApi.get, params);
    let insightSummary: OmoInsightSummary;
    if (response.isSuccess) {
      insightSummary = OmoInsightSummary.fromJson(response.data);

      // 選択されているマーチャントIDの検査の実施(マーチャントID一覧がわかるこのタイミングで実施する)
      const merchantIds = insightSummary.items.map((item) => item.merchantId);
      yield fork(updateSelectedMerchantId, merchantIds);
    } else {
      toast({
        type: 'error',
        title: '商品インサイトが取得できませんでした',
        description: (response.error as any)?.detail?.error || '',
        time: 10000,
      });
      return;
    }

    if (!searchCondition.filter.isEnabledComparison) {
      // 比較期間が有効化されていない場合
      yield put(OmoInsightActions.setInsight(CompositeOmoInsightSummary.fromInsightSummary(insightSummary, null)));
      return;
    }

    const comparisonParams = searchCondition.toOmoInsightSummaryApiParams(true);
    const comparisonResponse: YieldReturn<typeof OmoInsightSummaryApi.get> = yield call(
      OmoInsightSummaryApi.get,
      comparisonParams,
    );
    let comparisonInsightSummary: OmoInsightSummary;
    if (comparisonResponse.isSuccess) {
      comparisonInsightSummary = OmoInsightSummary.fromJson(comparisonResponse.data);
    } else {
      toast({
        type: 'error',
        title: '商品インサイトが取得できませんでした',
        description: (comparisonResponse.error as any)?.detail?.error || '',
        time: 10000,
      });
      return;
    }

    yield put(
      OmoInsightActions.setInsight(
        CompositeOmoInsightSummary.fromInsightSummary(insightSummary, comparisonInsightSummary),
      ),
    );
  } finally {
    yield put(OmoInsightActions.setLoadingStatus({ target: 'insight', value: false }));
  }
}

function* fetchInsightGraph() {
  const searchCondition: YieldReturn<typeof OmoInsightSelectors.selectSearchCondition> = yield select(
    OmoInsightSelectors.selectSearchCondition,
  );
  if (!searchCondition) {
    return;
  }

  yield put(OmoInsightActions.setLoadingStatus({ target: 'graph', value: true }));
  try {
    const params = searchCondition.toOmoInsightGraphApiParams();
    const response: YieldReturn<typeof OmoInsightGraphApi.get> = yield call(OmoInsightGraphApi.get, params);
    let graphData: OMOInsightGraphData;
    if (response.isSuccess) {
      graphData = OMOInsightGraphData.fromJson(response.data);
    } else {
      toast({
        type: 'error',
        title: '商品インサイト（グラフ）が取得できませんでした',
        description: (response.error as any)?.detail?.error || '',
        time: 10000,
      });
      return;
    }

    if (!searchCondition.filter.isEnabledComparison) {
      // 比較期間が有効化されていない場合
      yield put(
        OmoInsightActions.setGraphData(new CompositeOMOInsightGraphData({ target: graphData, comparison: null })),
      );
      return;
    }

    const comparisonParams = searchCondition.toOmoInsightGraphApiParams(true);
    const comparisonResponse: YieldReturn<typeof OmoInsightGraphApi.get> = yield call(
      OmoInsightGraphApi.get,
      comparisonParams,
    );
    let comparisonGraphData: OMOInsightGraphData;
    if (comparisonResponse.isSuccess) {
      comparisonGraphData = OMOInsightGraphData.fromJson(comparisonResponse.data);
    } else {
      toast({
        type: 'error',
        title: '商品インサイト（グラフ）が取得できませんでした',
        description: (comparisonResponse.error as any)?.detail?.error || '',
        time: 10000,
      });
      return;
    }

    yield put(
      OmoInsightActions.setGraphData(
        new CompositeOMOInsightGraphData({ target: graphData, comparison: comparisonGraphData }),
      ),
    );
  } finally {
    yield put(OmoInsightActions.setLoadingStatus({ target: 'graph', value: false }));
  }
}
/**
 * 検索条件の選択されているマーチャントIDが正しいものかを検査し、不正な場合は最初のマーチャントIDに置き換える
 */
function* updateSelectedMerchantId(merchantIds: List<string>) {
  // 検索条件のマーチャントIDが結果に含まれていない場合、最初のマーチャントIDに置き換える(再リクエストは走らない)
  const searchCondition: OmoInsightSearchCondition = yield select(OmoInsightSelectors.selectSearchCondition);
  let selectedMerchantId = searchCondition.filter.selectedMerchantId;
  if (selectedMerchantId === null || !merchantIds.includes(selectedMerchantId)) {
    selectedMerchantId = merchantIds.get(0) ?? null;
  }
  if (selectedMerchantId !== null) {
    yield put(OmoInsightActions.commitSelectedMerchantId(selectedMerchantId));
  }
}

function* commitSelectedMerchantId(action: ReturnType<typeof OmoInsightActions.commitSelectedMerchantId>) {
  const searchCondition: OmoInsightSearchCondition = yield select(OmoInsightSelectors.selectSearchCondition);

  const isInitialize = searchCondition.filter.selectedMerchantId === null;
  const updatedSearchCondition = searchCondition.setIn(['filter', 'selectedMerchantId'], action.payload);
  yield call(commitSearchCondition, updatedSearchCondition, isInitialize ? 'replace' : 'push');

  // グラフデータの取得はここで行う
  yield fork(fetchInsightGraph);
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // PathがOMOインサイト以外の場合は何もしない
  if (location.pathname !== Path.omo.insight) {
    return;
  }

  yield call(initializePageForFirstRendering);
}
