import actionCreatorFactory from 'typescript-fsa';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { CompetitorList } from 'models/Domain/MapCompetitorResearch/Competitor';
import { MapCompetitorResearchSearchCondition } from 'models/Domain/MapCompetitorResearch/MapCompetitorResearchSearchCondition';
import { MapSearchResultDetails } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { MapSearchResultStatus } from 'models/Domain/MapCompetitorResearch/MapSearchResultStatus';

const actionCreator = actionCreatorFactory('MapCompetitorResearch');

export const MapCompetitorResearchActions = {
  clearState: actionCreator('clearState'),
  initializeIndexPage: actionCreator('initializeIndexPage'),
  setSearchCondition: actionCreator<MapCompetitorResearchSearchCondition>('setSearchCondition'),
  commitSearchCondition: actionCreator('commitSearchCondition'),

  setIsLoadingResult: actionCreator<boolean>('setIsLoadingResult'),
  initializeResultPage: actionCreator<{ executionArn: string; competitors: CompetitorList }>('initializeResultPage'),
  setExecutionArn: actionCreator<ExecutionArnStatus>('setExecutionArn'),
  getResultStatus: actionCreator('getResultStatus'),
  setResultStatus: actionCreator<MapSearchResultStatus>('setResultStatus'),
  setResultDetails: actionCreator<MapSearchResultDetails>('setResultDetails'),

  setCompetitors: actionCreator<CompetitorList>('setCompetitors'),
};
