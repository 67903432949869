import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { OfferItem } from 'models/Composite/OfferItem';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';

type Props = {
  className?: string;
  offerItem: OfferItem;
  store: Store;
  acceptOffer: () => void;
};

export const OfferHeader: React.FC<Props> = (props) => {
  const { className, offerItem, store, acceptOffer } = props;
  const { offer, has_authority, title } = offerItem;

  return (
    <StickyHeader className={className}>
      <LeftWrapper>
        <ShopName>{store.fullName}</ShopName>
        <TaskName>{title}</TaskName>
      </LeftWrapper>
      <RightWrapper>
        {offer.isDone && <AcceptButton disabled>完了</AcceptButton>}
        {!offer.isDone && has_authority && (
          <AcceptButton onClick={acceptOffer} disabled={!has_authority}>
            完了する
          </AcceptButton>
        )}
      </RightWrapper>
    </StickyHeader>
  );
};

const LeftWrapper = styled.div`
  width: calc(100% - 85px);
`;

const RightWrapper = styled.div`
  flex: none;
`;

const ShopName = styled.div`
  font-size: 12px;
  font-weight: bold;
  ${Mixins.ellipsis};
`;

const TaskName = styled.div`
  font-size: 18px;
  font-weight: bold;
  ${Mixins.ellipsis};
`;

const AcceptButton = styled(Button)`
  &&& {
    width: fit-content;
    padding: 10px;
    color: ${COLOR.GREEN};
  }
`;
