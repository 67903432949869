import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Review } from 'models/Domain/GmbReview';
import { ReviewReplyGenerateCondition } from 'models/Domain/GmbReview/ReviewReplyGenerateCondition';

import { ReviewReplyGenerateConditionContents } from './ReviewReplyGenerateConditionContents';
import { ReviewReplyGenerateResultContents } from './ReviewReplyGenerateResultContents';

type ModalMode = 'condition' | 'result';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  review: Review;
  onApply: (body: string) => void;
};

export const ReviewReplyGenerateModal: React.FC<Props> = ({ isOpen, onClose, review, onApply }) => {
  const [mode, setMode] = useState<ModalMode>('condition');
  const [condition, setCondition] = useState<ReviewReplyGenerateCondition>(
    ReviewReplyGenerateCondition.fromReview(review),
  );
  // 同一条件で再生成する際にキャッシュを使われないように、実行時の日時を保持して一緒に渡す
  const [generateTime, setGenerateTime] = useState('');

  const handleOnGenerate = useCallback(() => {
    setMode('result');
    setGenerateTime(dayjs().format());
  }, []);

  const handleOnBack = useCallback(() => {
    setMode('condition');
  }, []);

  const handleOnApply = useCallback(
    (body: string) => {
      onApply(body);
      onClose();
    },
    [onApply, onClose],
  );

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      {mode === 'condition' && (
        <ReviewReplyGenerateConditionContents
          condition={condition}
          onCancel={onClose}
          onGenerate={handleOnGenerate}
          onChangeCondition={setCondition}
        />
      )}
      {mode === 'result' && (
        <ReviewReplyGenerateResultContents
          condition={condition}
          generateTime={generateTime}
          onApply={handleOnApply}
          onBack={handleOnBack}
          onGenerate={handleOnGenerate}
        />
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)``;
