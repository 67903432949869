import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { BackArrow, StickyHeader, Title } from 'components/atoms/StickyHeader';
import { COLOR } from 'style/color';

type Props = {
  onEdit: () => void;
  onCreate: () => void;
  onDelete: () => void;
  onBack: () => void;
  canEdit: boolean; // 編集可能かどうか
  canDelete: boolean; // 削除可能かどうか
};

export const PromotionInfoDetailHeader: React.FC<Props> = ({
  onEdit,
  onCreate,
  onDelete,
  onBack,
  canEdit,
  canDelete,
}) => {
  return (
    <StickyHeader>
      <LeftWrapper>
        <TitleWrapper onClick={onBack}>
          <BackArrow />
          <Title>投稿詳細</Title>
        </TitleWrapper>
      </LeftWrapper>
      <RightWrapper>
        {canEdit && <AcceptButton onClick={onEdit}>編集</AcceptButton>}
        <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
          <Dropdown.Menu direction='left'>
            <ActionMenuItem onClick={onCreate}>
              <ActionItemLabel>複製して新規作成</ActionItemLabel>
            </ActionMenuItem>
            {canDelete && (
              <ActionMenuItem onClick={onDelete}>
                <ActionItemLabel>削除</ActionItemLabel>
              </ActionMenuItem>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </RightWrapper>
    </StickyHeader>
  );
};

const LeftWrapper = styled.div``;

const RightWrapper = styled.div`
  flex: none;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AcceptButton = styled(Button)`
  &&& {
    width: fit-content;
    padding: 10px;
    color: ${COLOR.GREEN};
    &:disabled {
      padding: 10px;
    }
  }
`;

const Trigger = styled.div`
  cursor: pointer;
  letter-spacing: -10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${COLOR.GREEN};
  transform: rotate(90deg) translateX(-4px);
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

const ActionItemLabel = styled.div`
  padding: 10px;
`;
