import { toast } from 'react-semantic-toasts';
import { delay, put, select, takeLatest } from 'redux-saga/effects';

import { MapSearchRankConfigCSVImportApi, MapSearchRankConfigCSVImportCheckApi } from 'ApiClient/GmbApi';
import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { MapSearchRankConfigImportResult } from 'models/Domain/MapSearchRank/MapSearchRankConfigImportResult';
import { MapSearchRankConfigValidationResult } from 'models/Domain/MapSearchRank/MapSearchRankConfigValidationResult';
import { State } from 'modules/reducers';

import { MapSearchRankConfigImportActions as Actions } from './actions';

export default function* saga() {
  yield takeLatest(Actions.validateCsv, validateCsv);
  yield takeLatest(Actions.checkValidationStatus, checkValidationStatus);
  yield takeLatest(Actions.importCsv, importCsv);
  yield takeLatest(Actions.checkImportStatus, checkImportStatus);
}

function* validateCsv(action: ReturnType<typeof Actions.validateCsv>) {
  yield put(Actions.setIsValidateProcessing(true));
  const { file } = action.payload;
  const response: YieldReturn<typeof MapSearchRankConfigCSVImportApi.post> = yield MapSearchRankConfigCSVImportApi.post(
    { csv: file, validate_only: true },
  );
  if (response.isSuccess) {
    yield put(Actions.setValidationExecutionArn(new ExecutionArnStatus({ executionArn: response.data.execution_arn })));
    yield put(Actions.checkValidationStatus());
  } else {
    yield put(Actions.setIsValidateProcessing(false));
    toast({
      type: 'error',
      title: 'CSVファイルの検証に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* checkValidationStatus(action: ReturnType<typeof Actions.checkValidationStatus>) {
  const arn: ExecutionArnStatus | null = yield select(
    (state: State) => state.mapSearchRankConfigImport.validationExecutionArn,
  );
  if (!arn || !arn.executionArn) {
    return;
  }

  const response: YieldReturn<typeof MapSearchRankConfigCSVImportCheckApi.checkValidateStatus> =
    yield MapSearchRankConfigCSVImportCheckApi.checkValidateStatus({
      executionArn: arn.executionArn,
    });
  if (response.isSuccess) {
    if (response.data.status === 'RUNNING') {
      // 3秒後に再度チェックを行う
      yield delay(3000);
      yield put(Actions.checkValidationStatus());
      return;
    } else if (response.data.status === 'SUCCEEDED') {
      yield put(Actions.setValidationResult(MapSearchRankConfigValidationResult.fromJSON(response.data.result)));
    } else {
      toast({
        type: 'error',
        title: 'CSVファイルの検証に失敗しました',
        description: String(response.data.status),
        time: 10000,
      });
    }
  } else {
    toast({
      type: 'error',
      title: 'CSVファイルの検証に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(Actions.setValidationExecutionArn(null));
  yield put(Actions.setIsValidateProcessing(false));
}

function* importCsv(action: ReturnType<typeof Actions.importCsv>) {
  yield put(Actions.setIsImportProcessing(true));
  const { file } = action.payload;
  const response: YieldReturn<typeof MapSearchRankConfigCSVImportApi.post> = yield MapSearchRankConfigCSVImportApi.post(
    { csv: file, validate_only: false },
  );
  if (response.isSuccess) {
    yield put(Actions.setImportExecutionArn(new ExecutionArnStatus({ executionArn: response.data.execution_arn })));
    yield put(Actions.checkImportStatus());
  } else {
    yield put(Actions.setIsImportProcessing(false));
    toast({
      type: 'error',
      title: 'CSVファイルのインポートに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* checkImportStatus(action: ReturnType<typeof Actions.checkImportStatus>) {
  const arn: ExecutionArnStatus | null = yield select(
    (state: State) => state.mapSearchRankConfigImport.importExecutionArn,
  );
  if (!arn || !arn.executionArn) {
    return;
  }

  const response: YieldReturn<typeof MapSearchRankConfigCSVImportCheckApi.checkImportStatus> =
    yield MapSearchRankConfigCSVImportCheckApi.checkImportStatus({
      executionArn: arn.executionArn,
    });

  if (response.isSuccess) {
    if (response.data.status === 'RUNNING') {
      // 3秒後に再度チェックを行う
      yield delay(3000);
      yield put(Actions.checkImportStatus());
      return;
    } else {
      yield put(
        Actions.setImportResult(
          new MapSearchRankConfigImportResult({ status: response.data.status, message: response.data.result.message }),
        ),
      );
    }
  } else {
    toast({
      type: 'error',
      title: 'CSVファイルのインポートに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(Actions.setImportExecutionArn(null));
  yield put(Actions.setIsImportProcessing(false));
}
