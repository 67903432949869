import React, { useCallback, useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { Paging } from 'components/molecules/Paging';
import { SearchHistoryTable } from 'components/pageComponents/SearchVolume/SearchHistoryTable';
import {
  SEARCH_CONDITION_LIMIT,
  SearchVolumeSearchCondition,
} from 'components/pageComponents/SearchVolume/SearchVolumeSearchCondition';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { SearchVolumeSearchCondition as SearchCondition } from 'models/Domain/SearchVolume/SearchVolumeSearchCondition';
import { SearchVolumeActions } from 'modules/searchVolume/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const SearchVolumeIndex = React.memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { searchCondition, isLoadingSearchHistory, searchHistory, isCommitDisabled } = useSelector(
    (state) => state.searchVolume,
  );
  const { initializeIndexPage, fetchSearchHistory, deleteSearchHistory, commitSearchCondition } = useMemo(
    () => bindActionCreators(SearchVolumeActions, dispatch),
    [dispatch],
  );

  useEffect(() => {
    initializeIndexPage();
  }, [initializeIndexPage]);

  const handleOnCommitSearchCondition = useCallback(
    (value: SearchCondition) => {
      commitSearchCondition(value);
    },
    [commitSearchCondition],
  );

  const handleOnChangePage = useCallback(
    (value: any) => {
      fetchSearchHistory(value);
    },
    [fetchSearchHistory],
  );

  const handleOnClickHistory = useCallback(
    (executionId: string) => {
      history.push(Path.searchVolume.result.replace(':id', executionId));
    },
    [history],
  );

  const {
    current_page: currentPage,
    limit,
    total_count: totalCount,
    range: [itemStart, itemEnd],
  } = searchHistory.pagination;

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('検索ボリューム')} />
      <StickyHeader>
        <Title>検索ボリューム</Title>
      </StickyHeader>
      <WideBody>
        <Content>
          <Header>
            検索条件<HeaderDescription>検索条件数は最大{SEARCH_CONDITION_LIMIT}件まで指定できます</HeaderDescription>
          </Header>
          <SearchVolumeSearchCondition
            committedSearchCondition={searchCondition}
            onCommitSearchCondition={handleOnCommitSearchCondition}
            isCommitDisabled={isCommitDisabled}
          />
        </Content>
        <Content>
          <Header>
            検索履歴
            <HeaderDescription>
              {!isLoadingSearchHistory && (
                <CountText>
                  {totalCount.toLocaleString()}件の検索履歴
                  {totalCount > 0 && (
                    <>
                      （{itemStart.toLocaleString()}〜{itemEnd.toLocaleString()}件目を表示中）
                    </>
                  )}
                </CountText>
              )}
            </HeaderDescription>
          </Header>
          <SearchHistoryTable
            isLoading={isLoadingSearchHistory}
            searchHistory={searchHistory}
            onClickHistory={handleOnClickHistory}
            deleteHistory={deleteSearchHistory}
          />
          <PagingWrapper>
            <Paging
              currentPage={currentPage}
              viewContentSize={limit}
              totalContentSize={totalCount}
              onChangeNo={handleOnChangePage}
            />
          </PagingWrapper>
        </Content>
      </WideBody>
    </MainWrapper>
  );
});

const Content = styled.div`
  margin-bottom: 32px;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const HeaderDescription = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-left: 16px;
`;

const PagingWrapper = styled.div`
  margin-top: 16px;
`;

const CountText = styled.span`
  color: ${COLOR.DARK_GRAY};
`;
