import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Set as ImmutableSet } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import { GbpPerformanceApi, GbpPerformanceCsvApi } from 'ApiClient/GbpPerformanceApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import {
  GbpPerformanceGraphData,
  GbpPerformanceGraphItemList,
  GbpPerformanceMonthlyData,
  GbpPerformanceTableData,
  GbpPerformanceTableItemList,
} from 'models/Domain/GbpPerformance/GbpPerformance';
import { GbpPerformanceCsvDownloadCondition } from 'models/Domain/GbpPerformance/GbpPerformanceCsvDownloadCondition';
import { GbpPerformanceSearchCondition } from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { GbpPerformanceActions } from './actions';
import { GbpPerformanceSelectors } from './selectors';

export default function* saga() {
  yield takeLatest(GbpPerformanceActions.initializePage, initializePage);
  yield takeLatest(GbpPerformanceActions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(GbpPerformanceActions.downloadCsv, downloadCsv);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const isPreparedPage: ReturnType<typeof GbpPerformanceSelectors.selectIsPreparedPage> = yield select(
    GbpPerformanceSelectors.selectIsPreparedPage,
  );

  // 初期化済みの場合は以降の処理を行わない
  if (isPreparedPage) {
    return;
  }

  yield put(GbpPerformanceActions.setIsPreparedPage(true));

  // URLパラメータから検索条件を復元する
  yield call(updateGbpPerformance);
}

function* commitSearchCondition(action: ReturnType<typeof GbpPerformanceActions.commitSearchCondition>) {
  const searchCondition = action.payload;
  yield put(GbpPerformanceActions.setSearchCondition(searchCondition));
  const location: Location = yield select((state: State) => state.router.location);
  // 既存とURLに差分がある場合、URLの更新を行う
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(pushWithOrganizationId(path));
  }
}

// 前回のデータ取得条件
let prevCommitedSearchCondition: GbpPerformanceSearchCondition | null = null;

/**
 * URLパラメータから検索条件を復元する
 */
function* updateGbpPerformance() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  yield put(GbpPerformanceActions.setIsInitializedSearchCondition(false));

  // URLの置き換え
  let searchCondition = GbpPerformanceSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: ImmutableSet<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: searchCondition.filter.showClosedStores,
    onlyGbpConnectedStores: true,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  if (prevCommitedSearchCondition != null && !searchCondition.needsUpdate(prevCommitedSearchCondition)) {
    // 前回実行時と同一条件なのでAPI実行しない
    return;
  }

  const search = searchCondition.toURLSearchParams();
  // 既存のURLと、検索条件から生成されるURLが異なる場合（不要なパラメータがある場合など）、正しいURLに置き換える
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // URLパラメータから復元した検索条件をセットする
  yield put(GbpPerformanceActions.setSearchCondition(searchCondition));

  // 同一条件でのAPI実行抑制のため実行条件を保存
  prevCommitedSearchCondition = searchCondition;

  yield put(GbpPerformanceActions.setIsInitializedSearchCondition(true));

  // CSVダウンロードの初期値に復元した検索条件をセットする
  const csvDownloadCondition: GbpPerformanceCsvDownloadCondition = yield select(
    (state: State) => state.gbpPerformance.csvDownloadCondition,
  );
  const newCsvDownloadCondition = csvDownloadCondition.set('searchCondition', searchCondition);
  yield put(GbpPerformanceActions.setCsvDownloadCondition(newCsvDownloadCondition));

  // データを取得する
  yield fork(updateGraphAndTableData);
  yield fork(updateMonthlyData);
}

function* updateGraphAndTableData() {
  try {
    yield put(GbpPerformanceActions.setIsLoading({ graphData: true, tableData: true }));

    const searchCondition: YieldReturn<typeof GbpPerformanceSelectors.selectSearchCondition> = yield select(
      GbpPerformanceSelectors.selectSearchCondition,
    );

    const calls = [];

    // 対象期間のデータ
    const params = searchCondition.toGraphAndTableDataRequestParams();
    calls.push(call(GbpPerformanceApi.get, params));

    // 比較期間のデータ（比較期間が有効な場合のみ）
    if (searchCondition.filter.isEnabledComparison) {
      const paramsForComparison = searchCondition.toGraphAndTableDataRequestParams(true);
      calls.push(call(GbpPerformanceApi.get, paramsForComparison));
    }

    // 対象期間と比較期間のデータは並列で取得する
    const responses: YieldReturn<typeof GbpPerformanceApi.get>[] = yield all(calls);
    const [response, comparisonResponse = null] = responses;

    if (!response.isSuccess) {
      toast({
        type: 'error',
        title: 'パフォーマンスデータの取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
      return;
    }

    if (comparisonResponse !== null && !comparisonResponse.isSuccess) {
      toast({
        type: 'error',
        title: 'パフォーマンスデータの取得に失敗しました',
        description: String(comparisonResponse.error.message),
        time: 10000,
      });
      return;
    }

    const graphData = new GbpPerformanceGraphData({
      target: GbpPerformanceGraphItemList.fromJSON(response.data.graph_items),
      comparison: GbpPerformanceGraphItemList.fromJSON(
        comparisonResponse !== null ? comparisonResponse.data.graph_items : [],
      ),
    });

    const tableData = new GbpPerformanceTableData({
      target: GbpPerformanceTableItemList.fromJSON(response.data.table_items),
      comparison:
        comparisonResponse !== null ? GbpPerformanceTableItemList.fromJSON(comparisonResponse.data.table_items) : null,
    });

    yield put(GbpPerformanceActions.setGraphAndTableData({ graphData, tableData }));
  } finally {
    yield put(GbpPerformanceActions.setIsLoading({ graphData: false, tableData: false }));
  }
}

function* updateMonthlyData() {
  try {
    yield put(GbpPerformanceActions.setIsLoading({ monthlyData: true }));

    const searchCondition: YieldReturn<typeof GbpPerformanceSelectors.selectSearchCondition> = yield select(
      GbpPerformanceSelectors.selectSearchCondition,
    );

    const params = searchCondition.toMonthlyDataRequestParams();
    const response: YieldReturn<typeof GbpPerformanceApi.getMonthly> = yield call(GbpPerformanceApi.getMonthly, params);
    if (!response.isSuccess) {
      toast({
        type: 'error',
        title: 'パフォーマンスデータ（月別）の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
      return;
    }

    const monthlyData = GbpPerformanceMonthlyData.fromJSON(response.data);
    yield put(GbpPerformanceActions.setMonthlyData({ monthlyData }));
  } finally {
    yield put(GbpPerformanceActions.setIsLoading({ monthlyData: false }));
  }
}

/**
 * CSVダウンロード
 */
function* downloadCsv() {
  yield put(AppActions.setLoading(true));

  const condition: GbpPerformanceCsvDownloadCondition = yield select(
    (state: State) => state.gbpPerformance.csvDownloadCondition,
  );
  const params = condition.toRequestParams();

  // executionArnの取得
  const executionArnResponse: YieldReturn<typeof GbpPerformanceCsvApi.create> =
    yield GbpPerformanceCsvApi.create(params);

  let executionArn: string;
  if (executionArnResponse.isSuccess) {
    executionArn = executionArnResponse.data.executionArn;
  } else {
    toast({
      type: 'error',
      title: 'CSVファイルのダウンロードに失敗しました',
      description: String(executionArnResponse.error.message),
      time: 10000,
    });

    yield put(AppActions.setLoading(false));

    return;
  }

  // 取得が完了しているか定期的に確認する
  let message: string;
  while (true) {
    const statusResponse: YieldReturn<typeof GbpPerformanceCsvApi.status> = yield GbpPerformanceCsvApi.status({
      executionArn,
    });
    if (statusResponse.isSuccess) {
      const data = statusResponse.data;
      if (data.status === 'RUNNING') {
        // 実行中なので、3秒後に再度チェックする
        yield delay(3000);
        continue;
      } else if (data.status === 'SUCCEEDED') {
        // レスポンスURLのファイルをダウンロードする
        window.location.assign(data.download_url);

        toast({ type: 'success', title: 'CSVファイルをダウンロードしました' });
        yield put(AppActions.setLoading(false));

        // 取得が完了したのでループを抜ける
        break;
      } else {
        // 失敗したのでエラーメッセージを表示する
        message = data.message;
      }
    } else {
      message = statusResponse.error.message;
    }
    toast({
      type: 'error',
      title: 'CSVファイルのダウンロードに失敗しました',
      description: String(message),
      time: 10000,
    });
    // 取得に失敗したのでループを抜ける
    break;
  }

  yield put(AppActions.setLoading(false));
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // PathがGBPパフォーマンスページ以外の場合は何もしない
  if (location.pathname !== Path.gbp.performance) {
    return;
  }

  // GBPパフォーマンスページの初期化処理が完了していない場合は何もしない
  const isPreparedPage: ReturnType<typeof GbpPerformanceSelectors.selectIsPreparedPage> = yield select(
    GbpPerformanceSelectors.selectIsPreparedPage,
  );
  if (!isPreparedPage) {
    return;
  }

  yield call(updateGbpPerformance);
}
