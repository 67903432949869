import { DataResetApiClient } from 'ApiClient';

const apiClient = new DataResetApiClient({});

export class DataResetApi {
  /**
   * データリセットの実行
   */
  static reset() {
    return apiClient.post('/system/data/reset');
  }
}
