import { toast } from 'react-semantic-toasts';
import { put, select, takeLatest } from 'redux-saga/effects';

import { StoreListApi, StoreListDetailApi } from 'ApiClient/StoreListApi';
import StoreList from 'models/Domain/StoreList';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';

import { StoreListActions } from './actions';

export default function* saga() {
  yield takeLatest(StoreListActions.getStoreList, () => getStoreList());
  yield takeLatest(StoreListActions.createStoreList, createStoreList);
  yield takeLatest(StoreListActions.updateStoreList, updateStoreList);
  yield takeLatest(StoreListActions.deleteStoreList, deleteStoreList);
}

export function* getStoreList() {
  const response: YieldReturn<typeof StoreListApi.get> = yield StoreListApi.get();
  if (response.isSuccess) {
    yield put(StoreListActions.setStoreList(response.data));
  } else {
    console.warn(response.error);
    toast({
      type: 'error',
      title: 'グループの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* createStoreList(action: ReturnType<typeof StoreListActions.createStoreList>) {
  yield put(AppActions.setLoading(true));
  const storeList: StoreList = yield select((state: State) => state.storeList.storeListForCreate);
  const params = storeList.requestParams();
  const response: YieldReturn<typeof StoreListApi.post> = yield StoreListApi.post(params);
  if (response.isSuccess) {
    yield put(StoreListActions.getStoreList(response.data));
    yield put(StoreListActions.clearStoreListForCreate());
    yield put(AppActions.moveTo(Path.storeList.index));
    toast({
      type: 'success',
      title: '登録が完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: '登録に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* updateStoreList(action: ReturnType<typeof StoreListActions.updateStoreList>) {
  yield put(AppActions.setLoading(true));
  const storeList: StoreList = yield select((state: State) => state.storeList.storeListForEdit);
  const params = storeList.requestParams();
  const response: YieldReturn<typeof StoreListDetailApi.put> = yield StoreListDetailApi.put(params);
  if (response.isSuccess) {
    yield put(StoreListActions.getStoreList(response.data));
    toast({
      type: 'success',
      title: '更新しました',
    });
  } else {
    toast({
      type: 'error',
      title: '更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* deleteStoreList(action: ReturnType<typeof StoreListActions.deleteStoreList>) {
  yield put(AppActions.setLoading(true));
  const storeListId = action.payload;
  const response: YieldReturn<typeof StoreListDetailApi.delete> = yield StoreListDetailApi.delete(storeListId);
  if (response.isSuccess) {
    yield put(StoreListActions.getStoreList(response.data));
    yield put(AppActions.moveTo(Path.storeList.index));
    toast({
      type: 'success',
      title: '削除しました',
    });
  } else {
    toast({
      type: 'error',
      title: '削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}
