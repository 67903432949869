import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import {
  ACTION_TYPES_WITH_URL,
  AvailableAliases,
  CallToAction,
  DefaultPromotionRecord,
  Event,
  Media,
  Offer,
  PromotionTopicTypeLabel,
  SourceInstagram,
  Translations,
  defaultPromotionInitialValues,
  removeAll,
} from 'models/Domain/Promotion/Promotion';
import { JSObject } from 'types/Common';

export interface SummaryRecord {
  unspecified_post_count: number;
  processing_post_count: number;
  live_post_count: number;
  reject_post_count: number;
}

export interface StatsRecord {
  total_stores_count: number;
}

interface ListPromotionRecord extends DefaultPromotionRecord {
  post_status: SummaryRecord;
  stats: StatsRecord;
  store_ids: List<number>;
  create_at: Dayjs;
  update_at: Dayjs;
}

/**
 * 投稿一覧における個々の投稿を表す
 */
export default class ListPromotion extends Record<ListPromotionRecord>({
  ...defaultPromotionInitialValues,
  post_status: {
    unspecified_post_count: 0,
    processing_post_count: 0,
    live_post_count: 0,
    reject_post_count: 0,
  },
  stats: {
    total_stores_count: 0,
  },
  // 投稿の送信先店舗IDのリスト
  store_ids: List(),
  create_at: dayjs(),
  update_at: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.call_to_action) {
      params.call_to_action = new CallToAction(params.call_to_action);
    }
    if (params.store_ids) {
      params.store_ids = List(params.store_ids);
    }
    if (params.media) {
      params.media = Media.fromJSON(params.media);
    }
    if (params.event) {
      params.event = Event.fromJSON(params.event);
    }
    if (params.offer) {
      params.offer = Offer.fromJSON(params.offer);
    }
    if (params.create_at) {
      params.create_at = dayjs.utc(params.create_at).local();
    }
    if (params.update_at) {
      params.update_at = dayjs.utc(params.update_at).local();
    }
    if (params.is_draft) {
      params.isDraft = params.is_draft;
    }
    if (params.is_scheduled) {
      params.isScheduled = params.is_scheduled;
    }
    // APIが返すscheduled_atとposted_atはタイムゾーンが指定されていないので、UTCとして取り込みJSTに変換する
    if (params.scheduled_at) {
      params.scheduledAt = dayjs.utc(params.scheduled_at).local();
    }
    if (params.posted_at) {
      params.postedAt = dayjs.utc(params.posted_at).local();
    }
    if (params.source && params.source.type === 'instagram') {
      params.source = SourceInstagram.fromJSON(params.source);
    }
    if (params.translations) {
      params.translations = Translations.fromJSON(params.translations);
    }
    super(params);
  }

  /** 投稿が編集可能か */
  get canEdit(): boolean {
    // 下書きなら編集可能
    if (this.isDraft) {
      return true;
    }
    // メディアが複数または動画を含む場合は不可
    if (this.media.items.size > 1 || this.media.hasVideo()) {
      return false;
    }
    // 拒否されていたら編集不可
    return !this.hasGmbStatusReject;
  }

  /** URLの入力が可能なボタンタイプ */
  get canInputUrl() {
    return ACTION_TYPES_WITH_URL.includes(this.call_to_action.action_type);
  }

  get topicTypeLabel() {
    return PromotionTopicTypeLabel[this.topic_type];
  }

  /** 全ての店舗がgmbに投稿完了している */
  get isGmbStatusSuccess() {
    // リスト向けのデータの場合、summaryのlive_post_count以外が0ではない場合
    if (
      this.post_status.processing_post_count !== 0 ||
      this.post_status.reject_post_count !== 0 ||
      this.post_status.unspecified_post_count !== 0
    ) {
      return false;
    }
    return true;
  }

  /** rejectの投稿を含んでいる */
  get hasGmbStatusReject() {
    return this.post_status.reject_post_count > 0;
  }

  /**
   * bodyのtype(Alias or Text)の注釈付きデータを返す
   */
  getAnnotatedBody() {
    const splitPattern = new RegExp(`(${AvailableAliases['body'].join('|')})`);
    return this.body.split(splitPattern).map((text) => {
      if ((AvailableAliases['body'] as string[]).includes(text)) {
        return { type: 'Alias' as const, text };
      }
      return { type: 'Text' as const, text };
    });
  }

  /** エイリアスを除いたURL */
  get urlWithoutAlias() {
    return removeAll(this.call_to_action.url, AvailableAliases.url);
  }

  get isPosted(): boolean {
    return this.postedAt != null;
  }

  /** 投稿日時として表示する日時(投稿の予定・成否に関わらず投稿を実行した/する日時) */
  get displayPostAt(): Dayjs {
    // GBP投稿済みの投稿は投稿日時を表示する
    // 予約投稿は投稿予定日時を表示する
    // 予約投稿がエラーだった場合は投稿予定日時を投稿日時として表示する
    // それ以外は投稿したがエラーと考えられるので、作成日を投稿日とする
    if (this.postedAt) {
      return this.postedAt;
    } else if (this.scheduledAt) {
      return this.scheduledAt;
    } else {
      return this.create_at;
    }
  }
}
