import React, { useCallback, useState } from 'react';

import { List } from 'immutable';
import Slider from 'react-slick';
import styled from 'styled-components';

import { ErrorText } from 'components/atoms/ErrorText';
import { Icon } from 'components/atoms/Icon';
import { SegmentUpload } from 'components/molecules/FileUploader';
import { COLOR } from 'style/color';

export type PromotionFormImageUploadProps = {
  className?: string;
  imageUrls: List<string>;
  error?: string;
  onChangeImage: (imageInfo: ImageFileMeta) => void;
  onDeleteImage: (index: number) => void;
  uploadImage: (files: File[]) => void;
};

export const PromotionFormImageUpload: React.FC<PromotionFormImageUploadProps> = ({
  className,
  imageUrls,
  error,
  onChangeImage,
  onDeleteImage,
  uploadImage,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const onDropAccepted = useCallback(
    (files: File[]) => {
      const imageFile = files[0];
      if (!imageFile) return;
      const localImageUrl = URL.createObjectURL(imageFile);
      const imageObject = new window.Image();

      imageObject.onload = () => {
        onChangeImage({
          type: imageFile.type,
          width: imageObject.naturalWidth,
          height: imageObject.naturalHeight,
          size: imageFile.size,
        });
        URL.revokeObjectURL(localImageUrl);
      };

      imageObject.src = localImageUrl;
      uploadImage(files);
    },
    [onChangeImage, uploadImage],
  );

  const onClickRemoveButton = useCallback(() => {
    onDeleteImage(currentSlide);
  }, [onDeleteImage, currentSlide]);

  return (
    <Wrapper className={className}>
      {imageUrls.isEmpty() ? (
        <StyledSegmentUpload
          multiple={false}
          uploadType={'image'}
          iconName='camera'
          buttonText='写真を追加'
          onDropAccepted={onDropAccepted}
          onDropRejected={(files) => {
            console.error(files);
          }}
        />
      ) : (
        <CarouselWrapper>
          <RemoveIcon onClick={onClickRemoveButton} type='cancel' />
          <StyledSlider dots={true} afterChange={setCurrentSlide}>
            {imageUrls.map((url, i) => (
              <ImageWrapper key={i}>
                <Img key={i} src={url} />
              </ImageWrapper>
            ))}
          </StyledSlider>
        </CarouselWrapper>
      )}
      {error && <ErrorText error={error} />}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyledSegmentUpload = styled(SegmentUpload)`
  width: 100%;
  cursor: pointer;
`;

const Img = styled.img`
  width: 100%;
  height: 400px;
  object-fit: contain;
`;

const RemoveIcon = styled(Icon)`
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  /* 画像より上に表示する */
  z-index: 1;
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const StyledSlider = styled(Slider)`
  &&& {
    .slick-slide {
      background: ${COLOR.LIGHT_GRAY};
      height: 400px;
    }

    .slick-prev,
    .slick-next {
      /* 画像より上に表示させる */
      z-index: 1;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #222;
    }
    .slick-prev {
      left: 4px;
    }
    .slick-next {
      right: 4px;
    }

    .slick-dots {
      bottom: -33px;
      margin-bottom: 0;
      padding: 8px 0;
      li {
        margin: 0;
      }
    }
  }
`;
