import React, { useCallback } from 'react';

import dayjs from 'dayjs';
import { Checkbox, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { DatePicker } from 'components/atoms/DatePicker';
import { GmbSpecialHours } from 'models/Domain/GmbLocation/GmbSpecialHours';
import { GmbTimePeriodString } from 'models/Domain/GmbLocation/GmbTimePeriodString';
import { COLOR } from 'style/color';

export const EditSpecialHours: React.FC<{
  specialHours: GmbSpecialHours;
  changeSpecialHours: (v: GmbSpecialHours) => void;
}> = ({ specialHours, changeSpecialHours }) => {
  const { isValid, error } = specialHours.validate;
  const hasPastDate = specialHours.hasPastDate();

  const onChangeDate = useCallback(
    (dayIdx: number, value: Date | null) => {
      const dayStr = value !== null ? dayjs(value).format('YYYY-MM-DD') : '';
      changeSpecialHours(specialHours.changeDate(dayIdx, dayStr));
    },
    [changeSpecialHours, specialHours],
  );

  return (
    <Wrapper>
      <Label>特別営業時間</Label>
      {!isValid && <Error>{error}</Error>}
      {isValid && hasPastDate && <Error>過去の日付が含まれています。 過去の日付は反映時に自動的に削除されます。</Error>}

      {hasPastDate && (
        <RemovePastPeriod onClick={() => changeSpecialHours(specialHours.removePastDate())}>
          過去の日付を削除する
        </RemovePastPeriod>
      )}
      <ContentWrapper>
        {specialHours.specialHourPeriods.map((specialHour, dayIdx) => (
          <DayWrapper key={dayIdx}>
            <div>
              <ListWrapper>
                <DatePicker
                  dateFormat='yyyy/MM/dd'
                  selected={specialHour.date.toDate()}
                  onChange={(value: Date | null) => onChangeDate(dayIdx, value)}
                  placeholderText='日付'
                  wrapperClassName={`date_picker${
                    !specialHour.validate.isValid ? '_error' : specialHour.isPastDate ? '_inactive' : ''
                  }`}
                />
                <CheckboxHoliday
                  toggle
                  checked={specialHour.isOpen}
                  onChange={() => changeSpecialHours(specialHours.toggleOpenDay(dayIdx, !specialHour.isOpen))}
                />
                <LabelHoliday>{specialHour.isOpen ? '営業日' : '定休日'}</LabelHoliday>
              </ListWrapper>
            </div>
            <TimeWrapper>
              {specialHour.isOpen &&
                specialHour.list.map((period, timeIdx) => (
                  <TimeListWrapper key={timeIdx}>
                    <StyledDatePicker
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      selected={period.openTime.toDayjs?.toDate()}
                      onChange={(value: Date) =>
                        changeSpecialHours(
                          specialHours.changePeriod(
                            dayIdx,
                            timeIdx,
                            GmbTimePeriodString.fromDate(value).toString(),
                            period.closeTime.value,
                          ),
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      placeholderText={'開始時間'}
                    />
                    <Period>ー</Period>
                    <StyledDatePicker
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      selected={period.closeTime.toDayjs?.toDate()}
                      onChange={(value: Date) =>
                        changeSpecialHours(
                          specialHours.changePeriod(
                            dayIdx,
                            timeIdx,
                            period.openTime.value,
                            GmbTimePeriodString.fromDate(value).toString(),
                          ),
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      placeholderText={'終了時間'}
                    />
                    <XIcon
                      onClick={() => {
                        if (specialHour.list.size == 1) {
                          changeSpecialHours(specialHours.removeDay(dayIdx));
                        } else {
                          changeSpecialHours(specialHours.removePeriod(dayIdx, timeIdx));
                        }
                      }}
                      name='x'
                    />
                  </TimeListWrapper>
                ))}
              {specialHour.isOpen && (
                <TimeListWrapper>
                  <AddWrapper>
                    <AddTime onClick={() => changeSpecialHours(specialHours.addOpenDay(dayIdx))}>
                      営業時間を追加
                    </AddTime>
                  </AddWrapper>
                </TimeListWrapper>
              )}
            </TimeWrapper>
            {!specialHour.isOpen && (
              <XIconWrapper>
                <XIcon
                  onClick={() => {
                    changeSpecialHours(specialHours.removeDay(dayIdx));
                  }}
                  name='x'
                />
              </XIconWrapper>
            )}
          </DayWrapper>
        ))}
        <AddWrapper>
          <AddDay
            onClick={() => {
              changeSpecialHours(specialHours.addSpecialHours());
            }}
          >
            他の日を追加
          </AddDay>
        </AddWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Label = styled.label`
  font-weight: bold;
`;

const Error = styled.div`
  margin: 16px 0;
  color: ${COLOR.ERROR};
`;

const XIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;

const XIcon = styled(Icon)`
  &&& {
    margin-left: 16px;
    cursor: pointer;
  }
`;

const Period = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const ContentWrapper = styled.div``;
const DayWrapper = styled.div`
  display: flex;
  min-height: 30px;
  margin-top: 16px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const TimeWrapper = styled.div`
  margin-right: 8px;
  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

const TimeListWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const AddWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-popper {
    z-index: 10; /* デフォルトのz-index: 1では他のコンポーネントが上に表示されてしまう */
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    width: 100px;
    padding: 0 8px;
    font-family: monospace !important;
  }
`;

const CheckboxHoliday = styled(Checkbox)`
  margin: 0 8px;
  z-index: 0;
`;

const LabelHoliday = styled.label`
  display: block;
  margin-right: 16px;
`;

const RemovePastPeriod = styled.div`
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
`;

const AddTime = styled.div`
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
`;

const AddDay = styled.div`
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;
`;
