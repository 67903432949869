import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Pagination } from 'models/Domain/Pagination';

type ImageListInfoProps = {
  className?: string;
  pagination: Pagination;
  isAllImageSelected: boolean;
  showToggleCheckButton: boolean;
  onClickToggleAllImagesChecked: () => void;
};

export const ImageListInfo: React.FC<ImageListInfoProps> = ({
  className,
  pagination,
  isAllImageSelected,
  showToggleCheckButton,
  onClickToggleAllImagesChecked,
}) => (
  <Wrapper className={className}>
    <Count>
      <>
        {pagination.total_count}件の写真（
        {Math.min(pagination.limit * (pagination.current_page - 1) + 1, pagination.total_count)}〜
        {Math.min(pagination.limit * pagination.current_page, pagination.total_count)}件目の写真を表示中）
      </>
    </Count>
    {showToggleCheckButton && (
      <Button onClick={onClickToggleAllImagesChecked} priority='low'>
        {isAllImageSelected ? 'すべての写真の選択を解除する' : '表示中のすべての写真を選択する'}
      </Button>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Count = styled.div`
  font-weight: bold;
  font-size: 16px;
`;
