import { Record } from 'immutable';

export class GMCProductCounts extends Record<{
  active: number;
  pending: number;
  disapproved: number;
  expiring: number;
}>({
  active: 0,
  pending: 0,
  disapproved: 0,
  expiring: 0,
}) {
  static fromJson(data: any = {}) {
    return new GMCProductCounts(data);
  }
}

export class GMCInsightMetrics extends Record<{
  impressions: number;
  clicks: number;
}>({
  impressions: 0,
  clicks: 0,
}) {
  static fromJson(data: any = {}) {
    return new GMCInsightMetrics(data);
  }

  get hasValue(): boolean {
    return this.impressions > 0 || this.clicks > 0;
  }
}

export class GAInsightMetrics extends Record<{
  sessions: number;
  conversions: number;
  revenues: number;
}>({
  sessions: 0,
  conversions: 0,
  revenues: 0,
}) {
  static fromJson(data: any = {}) {
    return new GAInsightMetrics(data);
  }

  get hasValue(): boolean {
    return this.sessions > 0 || this.conversions > 0 || this.revenues > 0;
  }
}
