import { Record as ImmutableRecord } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ServiceGroup, ServiceSummary } from '../../models/Domain/Service/Service';

import { ServiceActions } from './actions';

export class ServiceState extends ImmutableRecord<{
  isLoading: boolean;
  serviceGroup: ServiceGroup;
  serviceSummary: ServiceSummary;
  isApplyingToGbp: boolean;
}>({
  isLoading: true,
  serviceGroup: new ServiceGroup(),
  serviceSummary: new ServiceSummary(),
  isApplyingToGbp: false,
}) {}

export const serviceReducer = reducerWithInitialState(new ServiceState())
  .case(ServiceActions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  })
  .case(ServiceActions.setServiceGroup, (state, payload) => {
    return state.set('serviceGroup', payload);
  })
  .case(ServiceActions.setServiceSummary, (state, payload) => {
    return state.set('serviceSummary', payload);
  })
  .case(ServiceActions.setIsApplyingToGbp, (state, payload) => {
    return state.set('isApplyingToGbp', payload);
  });
