import React, { useCallback, useMemo } from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { RatingStar } from 'components/molecules/Rating';
import { MapSearchResultDetails, StoreKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { COLOR } from 'style/color';

import { StoreDetailTable } from './StoreDetailTable';

interface Props {
  isOpen: boolean;
  isCompetitor: boolean;
  color: string | null;
  disableAddCompetitor: boolean;

  // よろしくない実装だけどわざわざ型定義する必要ないので
  data: ReturnType<MapSearchResultDetails['getRanksByStoreKey']>;

  onBack: () => void;
  onClose: () => void;
  onSelectAreaKeyword: (condition: { areaName: string; searchWord: string }) => void;
  onChangeCompetitor: (storeKey: StoreKey) => void;
}

export const StoreDetailModal = React.memo<Props>(
  ({
    isOpen,
    isCompetitor,
    disableAddCompetitor,
    color,
    data,
    onBack,
    onClose,
    onSelectAreaKeyword,
    onChangeCompetitor,
  }) => {
    const { storeInfo, ranks, hasAdsItem } = data;
    const { storeKey } = storeInfo;

    const items = useMemo(
      () =>
        ranks.map((rankItem) => ({
          areaName: rankItem.condition.areaName,
          searchWord: rankItem.condition.searchWord,
          rank: rankItem.item?.itemNotAds?.rankExcludeAds ?? null,
          adsRank: rankItem.item?.itemAds?.rank ?? null,
        })),
      [ranks],
    );

    /**
     * 「比較対象に追加する」をクリックした時の処理
     */
    const handleOnChangeCompetitor = useCallback(() => {
      if (storeKey) {
        onChangeCompetitor(storeKey);
      }
    }, [onChangeCompetitor, storeKey]);

    return (
      <Modal open={isOpen}>
        <Modal.Content>
          <Wrapper>
            <Header>
              <StoreName>{storeKey?.storeName ?? ''}</StoreName>
              <Button
                priority={'low'}
                onClick={handleOnChangeCompetitor}
                negative={isCompetitor}
                disabled={!isCompetitor && disableAddCompetitor}
              >
                {isCompetitor ? '比較店舗から除外する' : '比較店舗に追加する'}
              </Button>
            </Header>
            <InfoContainer>
              {storeInfo.category && <StoreInfo>{storeInfo.category}</StoreInfo>}
              {storeInfo.evaluation != null && (
                <>
                  <StoreInfo>
                    評価 {storeInfo.evaluation.toFixed(1)}
                    <RatingStar rating={storeInfo.evaluation} />
                  </StoreInfo>
                </>
              )}
              {storeInfo.numberOfReviews != null && <StoreInfo>レビュー {storeInfo.numberOfReviews}件</StoreInfo>}
              {storeInfo.link != null && (
                <StoreInfo>
                  Googleマップで表示する
                  <ExternalLink href={storeInfo.link} />
                </StoreInfo>
              )}
            </InfoContainer>
            <StoreDetailTable
              items={items}
              hasAdsItem={hasAdsItem}
              onSelectAreaKeyword={onSelectAreaKeyword}
              color={color}
            />
          </Wrapper>
        </Modal.Content>
        <ButtonWrapper>
          <StyledButton onClick={onBack}>戻る</StyledButton>
          <StyledButton onClick={onClose}>閉じる</StyledButton>
        </ButtonWrapper>
      </Modal>
    );
  },
);

const Wrapper = styled.div``;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 16px;
`;

const StoreName = styled.label`
  color: ${COLOR.BLACK};
  display: block;
  font-size: 20px;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  margin: 8px 0 16px;
`;

const StoreInfo = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-right: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 14px 24px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 150px;
    padding: 14px 8px;
  }
`;
