import React, { useCallback } from 'react';

import { Set } from 'immutable';
import { Checkbox, Modal, Search } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { ExpirationType, InventoryCsvDownloadFilter } from 'models/Domain/Omo/InventoryCsvDownloadFilter';
import { COLOR } from 'style/color';

type Props = {
  // モーダルの開閉状態
  open: boolean;
  // CSVダウンロード条件
  filter: InventoryCsvDownloadFilter;
  // CSVダウンロード条件が変更された
  setFilter: (filter: InventoryCsvDownloadFilter) => void;
  // モーダルを閉じる
  onClose: () => void;
  // ダウンロードを開始
  onStartCsvDownload: () => void;
};

/**
 * 在庫情報のCSVダウンロード条件を設定するモーダル
 */
export const InventoryCsvDownloadModal: React.FC<Props> = ({
  open,
  filter,
  setFilter,
  onClose,
  onStartCsvDownload,
}) => {
  /**
   * グループ、店舗の選択内容が変更された
   * @param group グループ
   * @param storeIds 店舗IDのセット
   * @param isAllStores すべての店舗が選択されているか
   * @param showClosedStores 閉店店舗を表示するか
   */
  const onChangeGroupStore = useCallback(
    (
      group: InventoryCsvDownloadFilter['store'],
      storeIds: Set<number>,
      isAllStores: boolean,
      showClosedStores: boolean,
    ) => {
      const updatedFilter = filter.merge({
        store: group,
        storeIds: storeIds,
        isAllStoreIds: isAllStores,
        showClosedStore: showClosedStores,
      });
      setFilter(updatedFilter);
    },
    [filter, setFilter],
  );

  /**
   * 検索ワードが変更された
   */
  const onChangeSearchValue = useCallback(
    (_: React.SyntheticEvent, data: any) => {
      const updatedFilter = filter.set('searchValue', data.value);
      setFilter(updatedFilter);
    },
    [filter, setFilter],
  );

  /**
   * 有効期限が変更された
   * @param expiration 有効期限の種類
   * @param value ダウンロード対象に含めるかどうか
   */
  const onChangeExpiration = useCallback(
    (expiration: ExpirationType, value: boolean) => {
      // 対応する有効期限のキーの選択状況を更新する
      const updatedFilter = filter.set(expiration, value);
      setFilter(updatedFilter);
    },
    [filter, setFilter],
  );

  /**
   * 「在庫情報のある商品のみ」が変更された
   * @param value 「在庫情報のある商品のみ」の選択状態
   */
  const onChangeOnlyInventoryExists = useCallback(
    (value: boolean) => {
      const updatedFilter = filter.set('onlyInventoryExists', value);
      setFilter(updatedFilter);
    },
    [filter, setFilter],
  );

  return (
    <Modal size='small' open={open} onClose={onClose}>
      <Modal.Content>
        <Title>CSVダウンロード</Title>
        <SubTitle>ダウンロード対象</SubTitle>
        {/* 絞り込み条件の表示・編集 */}
        <FilterWrapper>
          {/* グループ・店舗 */}
          <GroupStoreSelect
            group={filter.store}
            storeIds={filter.storeIds}
            showClosedStores={filter.showClosedStore}
            // GBP連携済み店舗のみから選択
            showGbpConnectedStoresOnly={true}
            showMyStoreOnly={true}
            onChange={onChangeGroupStore}
          />
          {/* 商品コード */}
          <ContentWrapper>
            <ContentLabel>商品コード</ContentLabel>
            <SearchField
              onSearchChange={onChangeSearchValue}
              placeholder={'商品コード（部分一致）'}
              showNoResults={false}
              value={filter.searchValue}
              icon={null}
            />
          </ContentWrapper>
          {/* 有効期限 */}
          <ContentWrapper>
            <ContentLabel>有効期限</ContentLabel>
            <MultiSelectBox value={filter.getExpirationLabel()}>
              <MultiSelectBoxContent>
                <MultiSelectBoxCheckLabel
                  onChange={() => onChangeExpiration('valid', !filter.valid)}
                  checked={filter.valid}
                  label='有効'
                />
              </MultiSelectBoxContent>
              <MultiSelectBoxContent>
                <MultiSelectBoxCheckLabel
                  onChange={() => onChangeExpiration('expiringSoon', !filter.expiringSoon)}
                  checked={filter.expiringSoon}
                  label='期限切れ間近'
                />
              </MultiSelectBoxContent>
              <MultiSelectBoxContent>
                <MultiSelectBoxCheckLabel
                  onChange={() => onChangeExpiration('expired', !filter.expired)}
                  checked={filter.expired}
                  label='有効期限切れ'
                />
              </MultiSelectBoxContent>
            </MultiSelectBox>
          </ContentWrapper>
          {/* 在庫情報のある商品のみ */}
          <ContentWrapper>
            <CheckLabel
              onChange={() => onChangeOnlyInventoryExists(!filter.onlyInventoryExists)}
              checked={filter.onlyInventoryExists}
              label='在庫情報のある商品のみ'
            />
          </ContentWrapper>
        </FilterWrapper>
        <ButtonWrapper>
          <CancelButton onClick={() => onClose()}>キャンセル</CancelButton>
          <DownloadButton priority='high' disabled={!filter.isValid()} onClick={onStartCsvDownload}>
            ダウンロード
          </DownloadButton>
        </ButtonWrapper>
      </Modal.Content>
    </Modal>
  );
};

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const SubTitle = styled.div`
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 14px;
  /* identical to box height, or 21px */
  color: #000000;
`;

const FilterWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0;

  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const SearchField = styled(Search)`
  width: 380px;
  @media (max-width: 920px) {
    width: 100%;
  }
  &&& {
    .ui.input {
      width: 100%;
    }
    input {
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
      font-weight: bold;
      height: 33px;
    }
  }
`;

const MultiSelectBoxContent = styled.div`
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const MultiSelectBoxCheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const CancelButton = styled(Button)`
  &&& {
    width: 195px;
  }
`;

const DownloadButton = styled(Button)`
  margin-left: 16px;

  &&& {
    width: 195px;
  }
`;
