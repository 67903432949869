import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from 'semantic-ui-react';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Link } from 'components/atoms/Link';
import { InsightHeader } from 'components/pageComponents/GmbInsight/InsightHeader';
import { InsightStoreChart } from 'components/pageComponents/GmbInsightStore/InsightStoreChart';
import { SearchArea } from 'components/pageComponents/GmbInsightStore/SearchArea';
import { SelectStoreListTable } from 'components/pageComponents/GmbInsightStore/SelectStoreListTable';
import { getPageTitle } from 'helpers/utils';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyOnce';
import { GmbInsight as GmbInsightModel } from 'models/Domain/GmbInsight';
import { InsightMetrics } from 'models/Domain/GmbInsightMetrics';
import { GmbActions } from 'modules/gmb/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const GmbInsightStore: React.FC = () => {
  const { insight, stores, storeLists, metrics } = useSelector((state) => ({
    stores: state.store.stores,
    storeLists: state.storeList.storeLists,
    insight: state.gmb.insight,
    metrics: state.gmb.metrics,
  }));
  const dispatch = useDispatch();
  const [searchStore, setSearchStore] = useState('');

  const updateGmbInsight = (gmbInsight: GmbInsightModel) => {
    dispatch(GmbActions.updateGmbInsight(gmbInsight));
  };

  const updateInsightMetrics = (nextMetrics: InsightMetrics) => {
    dispatch(GmbActions.updateInsightMetrics(nextMetrics));
    if (metrics.hasDiff(nextMetrics)) {
      dispatch(GmbActions.getInsightTotalList(nextMetrics));
    }
  };

  useEffectOnlyOnce(() => {
    dispatch(GmbActions.getInsightTotalList(metrics));
  });

  const data = insight.selectedGraphDataList(metrics);

  return (
    <div>
      <Helmet title={getPageTitle('GBPインサイト')} />
      <InsightHeader selected='store' />
      <GraphBody>
        <StyledAlert type={'info'}>
          <Alert.Title>
            GBPインサイトは <StyledLink to={Path.gbp.performance}>GBPパフォーマンス</StyledLink> に移行します
          </Alert.Title>
          <Alert.Section>
            <Alert.Content>
              Googleが提供するAPIの廃止に伴い、2023年4月1日をもってGBPインサイト(旧)の提供を終了いたします。
              <br />
              今後はGBPパフォーマンス機能をご利用ください。
            </Alert.Content>
          </Alert.Section>
        </StyledAlert>
        <SearchArea
          updateGmbInsight={updateGmbInsight}
          metrics={metrics}
          insight={insight}
          storeLists={storeLists}
          isShowCompareData={true}
          updateMetrics={updateInsightMetrics}
        />
        <LatestDate>最終データ取得日：{insight.latestDate}</LatestDate>
        <InsightStoreChart data={data} stores={stores} />
        <TableTitle>比較する店舗(５店舗まで)</TableTitle>
        {insight.selectedStoreList.size > 0 && (
          <SelectStoreListTable
            isActive={true}
            updateGmbInsight={updateGmbInsight}
            insight={insight}
            stores={stores}
            metrics={metrics}
          />
        )}
        <SearchContainer>
          <SearchField
            onSearchChange={(_: any, data: any) => {
              setSearchStore(data.value || '');
            }}
            input={{ fluid: true }}
            placeholder='店舗名'
            showNoResults={false}
            value={searchStore}
          />
        </SearchContainer>
        <SelectStoreListTable
          storeIdList={stores.filterStoreIdList(searchStore)}
          isActive={false}
          updateGmbInsight={updateGmbInsight}
          insight={insight}
          stores={stores}
          metrics={metrics}
        />
      </GraphBody>
    </div>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

const StyledLink = styled(Link)`
  color: ${COLOR.GREEN};
  text-decoration: underline;

  &:hover {
    color: ${COLOR.GREEN};
    text-decoration: underline;
  }
`;

const GraphBody = styled.div`
  padding: 24px;
`;

const TableTitle = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
`;

const SearchContainer = styled.div`
  margin: 24px 18px 0;

  .prompt {
    border-radius: 8px !important;
  }
`;

const SearchField = styled(Search)`
  &&& {
    @media (min-width: 600px) {
      width: 330px;
      & > .input {
        width: 100%;
      }
    }
  }
`;

const LatestDate = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
  color: ${COLOR.DARK_GRAY};
`;
