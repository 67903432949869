import React, { useCallback, useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { Checkbox, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { TextArea } from 'components/atoms/TextArea';
import { TagInput } from 'components/molecules/TagInput';
import {
  MAX_CITE_LENGTH,
  MAX_CONTENTS_LENGTH,
  MAX_KEYWORDS_LENGTH,
  MAX_TARGETS_LENGTH,
  PromotionGenerateCondition,
} from 'models/Domain/Promotion/PromotionGenerateCondition';
import { COLOR } from 'style/color';
import { SelectOption } from 'types/Common';

type InputType = 'url' | 'contents';

type Props = {
  condition: PromotionGenerateCondition;
  onChangeCondition: (value: PromotionGenerateCondition) => void;
  onGenerate: () => void;
  onClose: () => void;
  applyUrl: boolean;
  onChangeApplyUrl: (value: boolean) => void;
};

const inputTypeOptions: SelectOption<InputType>[] = [
  { text: 'URLを入力', value: 'url' },
  { text: '説明文を入力', value: 'contents' },
];

export const PromotionGenerateConditionContents: React.FC<Props> = ({
  condition,
  onChangeCondition,
  onGenerate,
  onClose,
  applyUrl,
  onChangeApplyUrl,
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [inputType, setInputType] = useState<InputType>('url');

  const { url, contents, keywords, targets, cite, totalKeywordsWordCount, totalTargetsWordCount } = condition;

  const handleOnChangeInputType = useCallback(
    (value: InputType) => {
      setInputType(value);
      const newCondition = condition
        .changeUrl(value === 'url' ? '' : null)
        .changeContents(value === 'contents' ? '' : null);
      onChangeCondition(newCondition);
    },
    [condition, onChangeCondition],
  );

  const handleOnChangeUrl = useCallback(
    (value: string) => {
      onChangeCondition(condition.changeUrl(value));
    },
    [condition, onChangeCondition],
  );
  const handleOnChangeContents = useCallback(
    (value: string) => {
      onChangeCondition(condition.changeContents(value));
    },
    [condition, onChangeCondition],
  );

  const handleOnChangeKeywords = useCallback(
    (values: string[]) => {
      onChangeCondition(condition.changeKeywords(ImmutableList(values)));
    },
    [condition, onChangeCondition],
  );

  const handleOnChangeTargets = useCallback(
    (values: string[]) => {
      onChangeCondition(condition.changeTargets(ImmutableList(values)));
    },
    [condition, onChangeCondition],
  );

  const handleOnChangeCite = useCallback(
    (value: string) => {
      onChangeCondition(condition.changeCite(value));
    },
    [condition, onChangeCondition],
  );

  const validate = condition.validate();

  const handleOnCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleOnGenerate = useCallback(() => {
    setShowErrorMessage(true);
    if (condition.isValid()) {
      onGenerate();
    }
  }, [condition, onGenerate]);

  const handleOnChangeApplyUrl = useCallback(() => {
    onChangeApplyUrl(!applyUrl);
  }, [applyUrl, onChangeApplyUrl]);

  return (
    <ModalContent>
      <Wrapper>
        <Title>投稿文の自動生成</Title>
        <ContentWrapper>
          <StyledFormContent
            name={'商品・サービス情報'}
            informationText={'商品の情報を含むURL、または、商品の説明文を入力するか、選択できます'}
            required
          >
            <StyledPullDown onChange={handleOnChangeInputType} value={inputType} options={inputTypeOptions} />
            {inputType === 'contents' ? (
              <>
                <StyledTextArea
                  value={contents ?? ''}
                  onChange={handleOnChangeContents}
                  placeholder={'説明文を入力してください'}
                  error={showErrorMessage && !validate.contents.isValid}
                />
                <ValidationWrapper>
                  <Error>{showErrorMessage && validate.contents.error}</Error>
                  <StyledTextCount size={contents?.length ?? 0} maxSize={MAX_CONTENTS_LENGTH} />
                </ValidationWrapper>
              </>
            ) : (
              <>
                <StyledInput
                  value={url ?? ''}
                  onChange={handleOnChangeUrl}
                  placeholder={'URLを入力してください'}
                  error={showErrorMessage && !validate.url.isValid}
                />
                <ValidationWrapper>
                  <Error>{showErrorMessage && validate.url.error}</Error>
                </ValidationWrapper>
                <StyledCheckbox
                  onChange={handleOnChangeApplyUrl}
                  checked={applyUrl}
                  label={'投稿の詳細ボタンのリンクとして設定する'}
                />
              </>
            )}
          </StyledFormContent>
          <StyledFormContent
            name={'投稿に含めたいキーワード'}
            informationText={'投稿文に含めたいキーワードを複数指定できます'}
          >
            <StyledTagInput
              values={keywords.toArray()}
              placeholder={'例：出産祝い'}
              onChange={handleOnChangeKeywords}
            />
            <ValidationWrapper>
              <Error>{showErrorMessage && validate.keywords.error}</Error>
              <StyledTextCount size={totalKeywordsWordCount ?? 0} maxSize={MAX_KEYWORDS_LENGTH} />
            </ValidationWrapper>
          </StyledFormContent>
          <StyledFormContent
            name={'ターゲット'}
            informationText={'投稿のターゲットとして年齢や性別、属性などを複数指定できます'}
          >
            <StyledTagInput
              values={targets.toArray()}
              placeholder={'例：子供をもつ親、20代女性'}
              onChange={handleOnChangeTargets}
            />
            <ValidationWrapper>
              <Error>{showErrorMessage && validate.targets.error}</Error>
              <StyledTextCount size={totalTargetsWordCount} maxSize={MAX_TARGETS_LENGTH} />
            </ValidationWrapper>
          </StyledFormContent>
          <StyledFormContent
            name={'参考文章'}
            informationText={'過去の投稿など、文章生成の参考となるテキストを指定できます'}
          >
            <StyledTextArea
              value={cite}
              onChange={handleOnChangeCite}
              placeholder={'過去の投稿など、参考にしたい文章を入力してください'}
              error={showErrorMessage && !validate.cite.isValid}
            />
            <ValidationWrapper>
              <Error>{showErrorMessage && validate.cite.error}</Error>
              <StyledTextCount size={cite?.length ?? 0} maxSize={MAX_CITE_LENGTH} />
            </ValidationWrapper>
          </StyledFormContent>
        </ContentWrapper>
        <ButtonWrapper>
          <StyledButton onClick={handleOnCancel}>キャンセル</StyledButton>
          <StyledButton
            onClick={handleOnGenerate}
            priority={'high'}
            disabled={showErrorMessage && !condition.isValid()}
          >
            文章生成
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    </ModalContent>
  );
};

const ModalContent = styled(Modal.Content)``;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding-right: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    min-width: 140px;
    width: auto;
  }
`;

const StyledPullDown = styled(PullDown)`
  width: 100%;
  margin-bottom: 16px;
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
  }
`;

const StyledTagInput = styled(TagInput)`
  &&& {
    width: 100%;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const StyledFormContent = styled(FormContent)`
  margin-bottom: 16px;
`;

const StyledTextCount = styled(Counter)`
  text-align: right;
`;

const Error = styled.div`
  color: ${COLOR.ERROR};
  flex: 1;
`;

const ValidationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  margin-top: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 12px;
    color: #707070;
    cursor: pointer;
    margin-top: 4px;
    padding-left: 0;
  }
`;
