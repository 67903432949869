import { List } from 'immutable';

export const COLOR = {
  WHITE: '#fff',
  GREEN: '#05CCAD',
  LIGHT_GREEN: '#cff2e6',
  DARK_GREEN: '#04A38A',
  BLACK: '#393939',
  PURPLE: '#FF5ABC',
  YELLOW: '#EFFFD3',
  GOLDEN_YELLOW: '#fcbd05',
  BLUE: '#6EDDFF',
  GRAY: '#B5B5B5',
  LIGHT_GRAY: '#EAEAEA',
  DARK_GRAY: '#A9A9A9',
  CHARCOAL_GRAY: '#757575',
  RED: '#ff0000',
  LIGHT_RED: '#ff9999',
  BACKGROUND: '#f8f8f8',
  GOOGLE: '#1a73e8',
  ERROR: '#dd5252',
  WARNING: '#FF9800',
  LIGHT_ERROR: '#F1BABA',
  DARK_ERROR: '#B14242',
  TABLE_BORDER: 'rgba(34, 36, 38, 0.1)',
  TABLE_HEADER: '#F9FAFB',
};

// グラフなど複数の色を使う場面で、見分けやすいような色のセット
// Googleスプレッドシートで要素数10のグラフを生成したときの色での色の組み合わせをもとに、
// 透明度80%をアルファチャンネルを使わずに表現したときのRGB
export const UNIVERSAL_COLORS = List([
  '#4B72F1',
  '#D04144',
  '#F1AB2D',
  '#399758',
  '#FA612C',
  '#53B5BB',
  '#9B1EB8',
  '#B6B738',
  '#43469F',
  '#F3629D',
]);
