import React from 'react';

import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Table as _Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { SortTriangle } from 'components/molecules/Table';
import { UserThumbnail } from 'components/molecules/UserThumbnail';
import { UserSortKey, UserSortStatus } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { UserActions } from 'modules/user/actions';
import { UserState } from 'modules/user/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

type Props = Pick<UserState, 'userList' | 'userSortStatus'>;

const UserListContent: React.FC<Props> = ({ userList, userSortStatus }) => {
  const dispatch = useDispatch();
  const storeState = useSelector((state) => state.store);

  const handleSort = (key: UserSortKey) => {
    dispatch(
      UserActions.setUserSortStatus(
        new UserSortStatus({
          key: key,
          order: userSortStatus.key === key && userSortStatus.order === 'asc' ? 'desc' : 'asc',
        }),
      ),
    );
  };

  return (
    <StyledTable>
      <StyledTableHeader>
        <TableHeaderRow>
          <StyledSortableTableHeaderCell onClick={() => handleSort('name')}>
            <FlexWrapper>
              {userSortStatus.key === 'name' && <SortTriangle isSortOrderDesc={userSortStatus.order === 'desc'} />}
              名前
            </FlexWrapper>
          </StyledSortableTableHeaderCell>
          <StyledSortableTableHeaderCell onClick={() => handleSort('role')}>
            <FlexWrapper>
              {userSortStatus.key === 'role' && <SortTriangle isSortOrderDesc={userSortStatus.order === 'desc'} />}
              役割
            </FlexWrapper>
          </StyledSortableTableHeaderCell>
          <TableHeaderCell>店舗</TableHeaderCell>
          <StyledSortableTableHeaderCell onClick={() => handleSort('last_active_time')}>
            <FlexWrapper>
              {userSortStatus.key === 'last_active_time' && (
                <SortTriangle isSortOrderDesc={userSortStatus.order === 'desc'} />
              )}
              最終アクティブ時間
            </FlexWrapper>
          </StyledSortableTableHeaderCell>
        </TableHeaderRow>
      </StyledTableHeader>
      <Table.Body>
        {userList
          .getSortedList(userSortStatus)
          .filter((user) => !user.isDeleted)
          .map((user) => {
            const { id, fullName, last_name, profile_image_url, roleLabel, managing_stores, stores, last_active_time } =
              user;
            const jstLastActiveTime = dayjs(last_active_time);
            return (
              <ClickableTableRow
                key={id}
                onClick={() => dispatch(AppActions.moveTo(Path.user.edit.replace(':userId', String(id))))}
              >
                <Table.Cell>
                  <FlexCellContent>
                    <UserThumbnail src={profile_image_url} name={last_name} />
                    <Name>{fullName}</Name>
                  </FlexCellContent>
                </Table.Cell>
                <Table.Cell verticalAlign='middle'>
                  <CellContent>{roleLabel && roleLabel.text}</CellContent>
                </Table.Cell>
                <Table.Cell verticalAlign='middle'>
                  <CellContent>
                    {managing_stores.map((storeId) => {
                      const store = storeState.stores.findStore(storeId);
                      if (!store) {
                        return null;
                      }
                      return <div key={storeId}>{store.shortName}</div>;
                    })}
                    {stores.map((storeId) => {
                      const store = storeState.stores.findStore(storeId);
                      if (!store) {
                        return null;
                      }
                      return <div key={storeId}>{store.shortName}</div>;
                    })}
                  </CellContent>
                </Table.Cell>
                <Table.Cell>
                  <CellContent>
                    {jstLastActiveTime &&
                      jstLastActiveTime.isValid() &&
                      jstLastActiveTime.format('YYYY-MM-DD HH:mm:ss')}
                  </CellContent>
                </Table.Cell>
              </ClickableTableRow>
            );
          })}
      </Table.Body>
    </StyledTable>
  );
};

export const Table = styled(_Table)`
  &&& {
    margin: 0;
    border-collapse: collapse;
  }
`;

const StyledTable = styled(Table)`
  &&& {
    border-collapse: separate;
  }
`;

export const TableHeader = styled(_Table.Header)``;

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 71px;
    z-index: 1;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  @media (min-width: ${SIZE.MOBILE_TABLE}) {
    justify-content: flex-start;
  }
`;

export const TableHeaderCell = styled(_Table.HeaderCell)`
  &&& {
    padding: 8px;
    color: ${COLOR.BLACK};
    font-size: 14px;
    vertical-align: middle;
    height: 50px;
    text-align: left;
  }
`;

export const SortableTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    cursor: pointer;
    &:hover {
      background: #ebeced;
    }
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)`
  &&& {
    max-width: 130px;
    min-width: 130px;
    text-align: right;
  }
`;

export const TableHeaderRow = styled(_Table.Row)`
  background: #f9fafb;
`;

const FlexCellContent = styled.div`
  display: flex;
  align-items: center;
`;

const CellContent = styled.div``;

const Name = styled.div`
  padding-left: 10px;
`;

const ClickableTableRow = styled(Table.Row)`
  cursor: pointer;
`;

export default UserListContent;
