import actionCreatorFactory from 'typescript-fsa';

import { ChangePasswordParam } from 'components/pageComponents/AccountEdit/PasswordChangeModal';
import { User, UserSortStatus } from 'models/Domain/User';
import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('User');

export const UserActions = {
  getUserList: actionCreator('getUserList'),
  setUserList: actionCreator<JSObject[]>('setUserList'),
  changeNewUser: actionCreator<User>('changeNewUser'),
  registerNewUser: actionCreator('registerNewUser'),
  clearNewUser: actionCreator('clearNewUser'),
  uploadNewUserImage: actionCreator<File>('uploadNewUserImage'),
  setEditUser: actionCreator<User>('setEditUser'),
  updateEditUser: actionCreator('updateEditUser'),
  uploadEditUserImage: actionCreator<File>('uploadEditUserImage'),
  deleteEditUser: actionCreator<User>('deleteEditUser'),
  changePassword: actionCreator<ChangePasswordParam>('changePassword'),
  setSuccessChangePassword: actionCreator<boolean>('setSuccessChangePassword'),
  setErrorMessage: actionCreator<string>('setErrorMessage'),
  setUserSortStatus: actionCreator<UserSortStatus>('seteUserSortStatus'),
};
