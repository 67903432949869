import React, { useCallback, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { DownloadModal } from 'components/pageComponents/GmbInsight/DownloadModal';
import { InsightChart } from 'components/pageComponents/GmbInsight/InsightChart';
import { InsightHeader } from 'components/pageComponents/GmbInsight/InsightHeader';
import { SearchArea } from 'components/pageComponents/GmbInsight/SearchArea';
import { StoreListTable } from 'components/pageComponents/GmbInsight/StoreListTable';
import { SumArea } from 'components/pageComponents/GmbInsight/SumArea';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { useDisplayType } from 'hooks/useDisplayType';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyOnce';
import { GbpInsightSearchCondition } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { InsightGraphItem } from 'models/Domain/GbpInsight/Insight';
import { AppActions } from 'modules/app/actions';
import { GbpInsightActions } from 'modules/gbpInsight/action';
import { GmbActions } from 'modules/gmb/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

/**
 * アクション率のフォーマッター
 *
 * InsightGraphData.toRateData を適用してアクション率が入っているグラフデータに対して、
 * アクション率がNaN, Infinityのものを0に改める
 */
const formatActionRate = (item: InsightGraphItem) => {
  return item
    .set('actionsWebsite', isFinite(item.actionsWebsite) ? item.actionsWebsite : 0)
    .set('actionsPhone', isFinite(item.actionsPhone) ? item.actionsPhone : 0)
    .set('actionsDrivingDirections', isFinite(item.actionsDrivingDirections) ? item.actionsDrivingDirections : 0);
};

export const GmbInsight: React.FC = () => {
  const {
    stores,
    storeLists,
    insight,
    comparisonInsight,
    searchCondition,
    committedSearchCondition,
    downloadModalState,
    isLoadingGraphData,
    isLoadingTableData,
    isInitializedSearchCondition,
  } = useSelector(
    (state: State) => ({
      stores: state.store.stores,
      storeLists: state.storeList.storeLists,
      insight: state.gbpInsight.insight,
      comparisonInsight: state.gbpInsight.comparisonInsight,
      searchCondition: state.gbpInsight.searchCondition,
      committedSearchCondition: state.gbpInsight.committedSearchCondition,
      downloadModalState: state.gbpInsight.downloadModalState,
      isLoadingGraphData: state.gbpInsight.isLoadingGraphData,
      isLoadingTableData: state.gbpInsight.isLoadingTableData,
      isInitializedSearchCondition: state.gbpInsight.isInitializedSearchCondition,
    }),
    shallowEqual,
  );
  const { tableData, graphData } = insight;
  const { graphData: comparisonGraphData, tableData: comparisonTableData } = comparisonInsight;
  const { aggregateUnit, isEnabledComparison, targetPeriod, comparisonPeriod } = committedSearchCondition;

  const dispatch = useDispatch();
  const { initializePage, changeSortType, setSearchCondition } = useMemo(
    () => bindActionCreators(GbpInsightActions, dispatch),
    [dispatch],
  );

  // アクション数のグラフでアクション率の表示に切り替えるかどうか
  const [showActionRate, setShowActionRate] = useState<boolean>(false);

  // アクション数グラフの表示切り替えが「アクション率」の場合、アクション率の入ったデータに組み替える
  const actionGraphData = showActionRate
    ? // 組み替える前に、アクション率がNaN, Infinityになっているものは0に改める (y軸がうまく動作しないため)
      graphData.toRateData.update('items', (items) => items.map((item) => formatActionRate(item)))
    : graphData;
  const comparisonActionGraphData = showActionRate
    ? // 組み替える前に、アクション率がNaN, Infinityになっているものは0に改める (y軸がうまく動作しないため)
      comparisonGraphData.toRateData.update('items', (items) => items.map((item) => formatActionRate(item)))
    : comparisonGraphData;

  // 比較が有効な場合、合計表示用の変化率を用意
  const diffRate = isEnabledComparison ? actionGraphData.getDiffRate(comparisonActionGraphData) : null;

  const addSelectedStoreAndMoveToInsightStore = (storeId: number) => {
    dispatch(GmbActions.addGmbInsightSelectedStore(storeId));
    dispatch(AppActions.moveTo(Path.gmb.insightStore));
  };

  useEffectOnlyOnce(() => {
    initializePage();
  });

  /**
   * アクション数のグラフの表示切り替えが押された時のハンドラ
   * アクション数グラフの表示種別を変更する
   * @param {boolean} showActionRate - アクション率の表示にするかどうか
   */
  const onChangeShowActionRate = useCallback(
    (showActionRate: boolean) => {
      setShowActionRate(showActionRate);
    },
    [setShowActionRate],
  );

  const changeSearchCondition = useCallback(
    (nextSearchCondition: GbpInsightSearchCondition) => {
      setSearchCondition({ searchCondition: nextSearchCondition, updateLocation: false });
    },
    [setSearchCondition],
  );

  const commitSearchCondition = useCallback(() => {
    setSearchCondition({ searchCondition, updateLocation: true });
  }, [setSearchCondition, searchCondition]);

  // 最終データ更新日
  const lastUpdateAt = graphData.lastUpdateAt ? graphData.lastUpdateAt.format('YYYY/MM/DD') : '-';

  // SP表示に切り替わる横幅600px+メニューの横幅320pxで判定
  const displayType = useDisplayType('920px');

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('GBPインサイト')} />
      <InsightHeader selected='all' />
      <WideBody>
        <StyledAlert type={'info'}>
          <Alert.Title>
            GBPインサイトは <StyledLink to={Path.gbp.performance}>GBPパフォーマンス</StyledLink> に移行します
          </Alert.Title>
          <Alert.Section>
            <Alert.Content>
              Googleが提供するAPIの廃止に伴い、2023年4月1日をもってGBPインサイト(旧)の提供を終了いたします。
              <br />
              今後はGBPパフォーマンス機能をご利用ください。
            </Alert.Content>
          </Alert.Section>
        </StyledAlert>
        {displayType.isMobile && (
          <Alert type={'info'}>
            <Alert.Title>このページはPCでの利用を推奨しております</Alert.Title>
            <Alert.Section>
              <Alert.Content>
                スマートフォンやタブレットなど狭い画面では正しく表示されない場合がありますのでご了承ください。
              </Alert.Content>
            </Alert.Section>
          </Alert>
        )}
        <SearchArea
          // URLから検索条件の初期化が完了してから検索条件を刷新するようにkeyを切り替える
          key={isInitializedSearchCondition ? 1 : 0}
          searchCondition={searchCondition}
          committedSearchCondition={committedSearchCondition}
          storeLists={storeLists}
          stores={stores}
          isShowStoreListSelect={true}
          onChangeSearchCondition={changeSearchCondition}
          onCommitSearchCondition={commitSearchCondition}
        />

        <InsightDateHeader>
          <LatestDate>最終データ取得日：{lastUpdateAt}</LatestDate>

          <DownloadButtonWrapper>
            <DownloadButton
              priority='low'
              onClick={() => dispatch(GbpInsightActions.setDownloadModalState(downloadModalState.changeIsOpen(true)))}
            >
              CSVダウンロード
            </DownloadButton>
          </DownloadButtonWrapper>
        </InsightDateHeader>

        <InsightContainer>
          <InsightWrapper>
            <InsightLabel>検索</InsightLabel>
            <InsightChart
              graphData={graphData}
              comparisonGraphData={comparisonGraphData}
              aggregateUnit={aggregateUnit}
              metricsType='search'
              isLoading={isLoadingGraphData}
              isEnabledComparison={isEnabledComparison}
            />
            <SumArea
              data={graphData}
              metricsType='search'
              period={targetPeriod}
              isEnabledComparison={isEnabledComparison}
              comparisonPeriod={comparisonPeriod}
              isLoading={isLoadingGraphData}
              diffRate={diffRate}
            />
          </InsightWrapper>
          <InsightWrapper>
            <InsightLabel>アクション</InsightLabel>
            <InsightChart
              graphData={actionGraphData}
              comparisonGraphData={comparisonActionGraphData}
              aggregateUnit={aggregateUnit}
              metricsType='action'
              isLoading={isLoadingGraphData}
              showActionRate={showActionRate}
              onChangeShowActionRate={onChangeShowActionRate}
              isEnabledComparison={isEnabledComparison}
            />
            <SumArea
              data={graphData}
              metricsType='action'
              period={targetPeriod}
              isEnabledComparison={isEnabledComparison}
              comparisonPeriod={comparisonPeriod}
              isLoading={isLoadingGraphData}
              showActionRate={showActionRate}
              diffRate={diffRate}
            />
          </InsightWrapper>
          <InsightWrapper>
            <InsightLabel>クチコミ</InsightLabel>
            <InsightChart
              graphData={graphData}
              comparisonGraphData={comparisonGraphData}
              aggregateUnit={aggregateUnit}
              metricsType='review'
              isLoading={isLoadingGraphData}
              isEnabledComparison={isEnabledComparison}
            />
            <SumArea
              data={graphData}
              metricsType='review'
              period={targetPeriod}
              isEnabledComparison={isEnabledComparison}
              comparisonPeriod={comparisonPeriod}
              isLoading={isLoadingGraphData}
              diffRate={diffRate}
            />
          </InsightWrapper>
        </InsightContainer>

        <TableWrapper>
          <StoreListTable
            tableData={tableData}
            comparisonTableData={comparisonTableData}
            period={targetPeriod}
            comparisonPeriod={comparisonPeriod}
            isEnabledComparison={isEnabledComparison}
            addSelectedStore={addSelectedStoreAndMoveToInsightStore}
            changeSortType={changeSortType}
          />
          {isLoadingTableData && (
            <LoadingWrapper>
              <Loader active={isLoadingTableData} size='big' inline={true} />
            </LoadingWrapper>
          )}
        </TableWrapper>
      </WideBody>

      <DownloadModal />
    </MainWrapper>
  );
};

const InsightDateHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 12px;
`;

const StyledLink = styled(Link)`
  color: ${COLOR.GREEN};
  text-decoration: underline;

  &:hover {
    color: ${COLOR.GREEN};
    text-decoration: underline;
  }
`;

const LatestDate = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${COLOR.DARK_GRAY};
`;

const InsightWrapper = styled.div`
  margin-top: 24px;
  border-top: 1px solid #bababa;
  margin-bottom: 36px;
`;

const InsightContainer = styled.div``;

const InsightLabel = styled.h3`
  font-weight: bold;
  font-size: 20px;
  padding-top: 16px;
`;

const DownloadButtonWrapper = styled.div`
  text-align: right;
`;

const DownloadButton = styled(Button)``;

const TableWrapper = styled.div`
  position: relative;
  margin-top: 32px;
  overflow-x: scroll;
`;

const LoadingWrapper = styled.div`
  background: white;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
