import React, { useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { DiffServiceItem } from 'models/Domain/GmbLocation/GmbServiceItems';
import { ServiceType } from 'models/Domain/GmbLocation/Service';
import { GmbLocationDiff } from 'models/Domain/GmbLocationDiffs';
import { GmbLocationUpdate } from 'models/Domain/GmbLocationUpdates';
import { Store } from 'models/Domain/Store';
import { UpdateType } from 'modules/gmbLocationUpdates/types';
import { Path } from 'routes';
import { COLOR } from 'style/color';

import { GmbLocationServiceItemsValue } from '../GmbLocationServiceItemsValue';

import { StoreCell } from './Cell/StoreCell';

import { ButtonsWrapper, CheckLabel, CustomButton, StyledCell, StyledCheckbox, TypeLabel } from '.';

type UpdatesTableServiceItemsRowsProps = {
  locationKey: 'serviceItems';
  isFirstRowInStore: boolean;
  checked: boolean;
  store: Store;
  updateStatus: UpdateType | undefined;
  diff: GmbLocationDiff;
  gmbLocationUpdate: GmbLocationUpdate;
  gmbCategoryList: GmbCategories;
  serviceTypes: { [categoryId: string]: ImmutableList<ServiceType> };
};

const filterDiffServiceItems = (
  diffServiceItems: ImmutableList<DiffServiceItem>,
  key: string,
): ImmutableList<DiffServiceItem> => {
  const diffServiceItem = diffServiceItems.find((diffServiceItem) =>
    diffServiceItem.items.some((item) => item.key === key),
  );
  if (!diffServiceItem) {
    return ImmutableList();
  }
  const filteredItems = diffServiceItem.items.filter((item) => item.key === key);
  return ImmutableList([{ ...diffServiceItem, items: filteredItems }]);
};

export const UpdatesTableServiceItemsRows: React.FC<UpdatesTableServiceItemsRowsProps> = ({
  locationKey,
  isFirstRowInStore,
  checked,
  store,
  updateStatus,
  diff,
  gmbLocationUpdate,
  gmbCategoryList,
  serviceTypes,
}) => {
  const { location, location_updated: locationUpdated } = gmbLocationUpdate;
  const identifier = `${diff.store_id}-${diff.key}`;
  const first = isFirstRowInStore.toString();

  const [isDetailMode, setIsDetailMode] = useState(false);

  const diffServiceItems = location.serviceItems.getDiffItems(locationUpdated.serviceItems);

  if (locationKey != diff.key) {
    console.warn(`locationKey mismatch! locationKey=${locationKey}, diff.key=${diff.key}`);
  }
  // 全てを表示する前に表示する項目
  // * 表示対象は差分がある項目のみ
  // * 表示内容はdisplayNameのみ(フリーフォームの場合、カテゴリのまとまりの前にカテゴリ名)

  // 全てを表示後に表示する項目
  // * 表示対象は差分がある項目のみ
  // * 表示内容はdisplayName, description, price
  // * 順番が異なるだけのものは表示しない
  return (
    <>
      <Table.Row key={identifier}>
        <StoreCell isFirstRowInStore={isFirstRowInStore} store={store} />
        <StyledCell first={first}>
          <CheckLabel htmlFor={identifier}>
            <StyledCheckbox id={identifier} checked={checked} disabled={true} />
            {diff.label}
          </CheckLabel>
        </StyledCell>
        <StyledCell first={first}>
          <TypeLabel isGoogleUpdated={diff.isGoogleUpdated}>{diff.typeLabel}</TypeLabel>
        </StyledCell>
        {diffServiceItems.isEmpty() ? (
          <StyledCell first={first} colSpan={2}>
            {!isDetailMode && (
              <DescriptionWrapper>
                <Description>
                  内容に差分があるもののみ表示しています。差分が表示順序のみのものは表示されません。
                </Description>
              </DescriptionWrapper>
            )}
          </StyledCell>
        ) : (
          <>
            <StyledCell first={first}>
              {!isDetailMode && (
                <GmbLocationServiceItemsValue
                  gmbCategoryList={gmbCategoryList}
                  diffServiceItems={diffServiceItems}
                  target='location'
                  serviceTypes={serviceTypes}
                />
              )}
            </StyledCell>
            <StyledCell first={first}>
              {!isDetailMode && (
                <GmbLocationServiceItemsValue
                  gmbCategoryList={gmbCategoryList}
                  diffServiceItems={diffServiceItems}
                  target='location_updated'
                  serviceTypes={serviceTypes}
                />
              )}
            </StyledCell>
          </>
        )}
        <StyledCell first={first}>
          <Information>
            サービスは一括管理となっており、差分画面からの変更はできません。
            <StyledLink to={Path.service.index}>サービス画面</StyledLink>から対応してください。
          </Information>
          {!isDetailMode && <CustomButton onClick={() => setIsDetailMode(true)}>詳細を表示する ▼</CustomButton>}
        </StyledCell>
      </Table.Row>
      {isDetailMode && (
        <>
          {diffServiceItems
            .map((diffServiceItem) => diffServiceItem.items)
            .flatten()
            .map((item) =>
              (() => {
                // 対象のキーのアイテムのみを含む差分アイテムを取得
                const filteredDiffServiceItems = filterDiffServiceItems(diffServiceItems, item.key);
                return (
                  <StyledRow key={item.key}>
                    <StyledSubCell />
                    <StyledSubCell />
                    <StyledSubCell />
                    <StyledSubCell>
                      <GmbLocationServiceItemsValue
                        gmbCategoryList={gmbCategoryList}
                        diffServiceItems={filteredDiffServiceItems}
                        target='location'
                        serviceTypes={serviceTypes}
                        showDetail={true}
                      />
                    </StyledSubCell>
                    <StyledSubCell>
                      <GmbLocationServiceItemsValue
                        gmbCategoryList={gmbCategoryList}
                        diffServiceItems={filteredDiffServiceItems}
                        target='location_updated'
                        serviceTypes={serviceTypes}
                        showDetail={true}
                      />
                    </StyledSubCell>
                    <StyledSubCell />
                  </StyledRow>
                );
              })(),
            )}
          <StyledRow key={`${identifier}_action`}>
            <StyledCell />
            <StyledCell />
            <StyledCell />
            <StyledCell colSpan={2}>
              <DescriptionWrapper>
                <Description>
                  内容に差分があるもののみ表示しています。差分が表示順序のみのものは表示されません。
                </Description>
              </DescriptionWrapper>
            </StyledCell>
            <StyledCell>
              <ButtonsWrapper>
                <CustomButton onClick={() => setIsDetailMode(false)}>閉じる ▲</CustomButton>
              </ButtonsWrapper>
            </StyledCell>
          </StyledRow>
        </>
      )}
    </>
  );
};

const StyledRow = styled(Table.Row)`
  &&& {
    background: ${rgba('#e3e4e5', 0.1)};
  }
`;
export const StyledSubCell = styled(StyledCell)`
  &&&& {
    border-bottom: 1px dashed #e3e4e5 !important;
  }
`;

const Information = styled.div`
  margin-bottom: 8px;
`;

const StyledLink = styled(Link)`
  color: ${COLOR.GREEN};
  text-decoration: underline;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 38px;
  border: 1px solid ${COLOR.GRAY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  display: inline-block;
  padding: 4px;
`;
