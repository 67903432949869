import React, { useState } from 'react';

import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { AggregateUnitGroup } from 'components/molecules/AggregateUnitGroup';
import { GmbInsight } from 'models/Domain/GmbInsight';
import { InsightMetrics, SelectedDataType } from 'models/Domain/GmbInsightMetrics';
import { StoreLists } from 'models/Domain/StoreList';
import { COLOR } from 'style/color';

/**
 * インサイトの検索欄
 * @param param0
 * @returns
 */
export const SearchArea: React.FC<{
  insight: GmbInsight;
  metrics: InsightMetrics;
  storeLists: StoreLists;
  isShowStoreListSelect?: boolean;
  isShowCompareData?: boolean;
  updateGmbInsight: (insight: GmbInsight) => void;
  updateMetrics: (metrics: InsightMetrics) => void;
}> = ({
  insight,
  metrics,
  storeLists,
  isShowCompareData = false,
  isShowStoreListSelect = false,
  updateGmbInsight,
  updateMetrics,
}) => {
  const [open, setOpen] = useState(true);

  const selectingStoreListId = insight.storeList?.id === 0 ? null : insight.storeList?.id;
  const allStoresOption = { text: '全ての店舗', value: 0 };
  const storeListOptions = [allStoresOption, ...storeLists.options];

  const aggregateDurationOptions =
    metrics.aggregateUnit === 'week'
      ? [
          { text: '4週間', value: 4 },
          { text: '8週間', value: 8 },
        ]
      : [
          { text: '3ヶ月', value: 3 },
          { text: '半年', value: 6 },
          { text: '1年', value: 12 },
        ];

  const selectedDataOptionsForBar: { value: SelectedDataType; text: string }[] = [
    { value: 'MEDIA_POST_COUNT', text: '写真投稿数' },
    { value: 'REVIEW_REPLY_COUNT', text: 'クチコミ返信数' },
    { value: 'RATE_COUNT', text: 'クチコミ数（評価のみ）' },
    { value: 'COMMENT_COUNT', text: 'クチコミ数（コメントあり）' },
  ];

  const selectedDataOptionsForArea: { value: SelectedDataType; text: string }[] = [
    { value: 'SEARCH_COUNT', text: '検索数' },
    { value: 'MEDIA_VIEW_DIVISION', text: '画像閲覧数/検索数' },
    { value: 'ACTION_RATE', text: 'アクション率' },
  ];

  // 集計設定を閉じた時に表示するラベルの文言を用意
  const aggregateDurationLabel =
    aggregateDurationOptions.find((option) => option.value === metrics.duration)?.text || '';
  const graphTypeLabel = `${metrics.selectedAreaDataLabel} と ${metrics.selectedBarDataLabel} を見る`;

  return (
    <div>
      <SearchWrapper>
        {open ? (
          <div>
            <div>
              <FlexWrapper>
                <FlexContent>
                  <Label>集計単位</Label>
                  <AggregateUnitGroup
                    value={metrics.aggregateUnit}
                    onChange={(aggregateUnit) =>
                      updateMetrics(metrics.set('aggregateUnit', aggregateUnit as 'week' | 'month'))
                    }
                    disableDay
                  />
                </FlexContent>
                <FlexContent>
                  <Label>集計期間</Label>
                  <PullDown
                    value={metrics.duration}
                    options={aggregateDurationOptions}
                    placeholder={'対象期間'}
                    onChange={(value) => updateMetrics(metrics.changeDuration(value))}
                  />
                </FlexContent>
                {isShowCompareData && (
                  <FlexContent>
                    <Label>比較対象のデータ</Label>
                    <DataPullDownWrapper>
                      <PullDown
                        value={metrics.selectedBarData}
                        options={selectedDataOptionsForBar}
                        onChange={(value) => updateMetrics(metrics.changeSelectedBarData(value))}
                      />
                      <DataPulldownText>と</DataPulldownText>
                    </DataPullDownWrapper>
                    <DataPullDownWrapper>
                      <PullDown
                        value={metrics.selectedAreaData}
                        options={selectedDataOptionsForArea}
                        onChange={(value) => updateMetrics(metrics.changeSelectedAreaData(value))}
                      />
                      <DataPulldownText>を見る</DataPulldownText>
                    </DataPullDownWrapper>
                  </FlexContent>
                )}
                {isShowStoreListSelect && (
                  <FlexContent>
                    <Label>集計対象のグループ</Label>
                    <PullDown
                      value={selectingStoreListId}
                      options={storeListOptions}
                      placeholder={'全ての店舗'}
                      onChange={(value) => {
                        const storeList = storeLists.findStoreList(value);
                        updateGmbInsight(insight.changeStoreList(storeList));
                      }}
                    />
                  </FlexContent>
                )}
              </FlexWrapper>
            </div>
          </div>
        ) : (
          <div>
            <SearchStatusLabel>集計期間：{aggregateDurationLabel}</SearchStatusLabel>
            <SearchStatusLabel>グラフ種別：{graphTypeLabel}</SearchStatusLabel>
          </div>
        )}

        <OpenText onClick={() => setOpen(!open)}>{open ? '閉じる' : '設定を変更する'}</OpenText>
      </SearchWrapper>
    </div>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FlexContent = styled.div`
  margin-right: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const SearchWrapper = styled(Card)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  margin-right: 24px;
  box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.05);
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 8px;
  margin-top: 8px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const SearchStatusLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 4px;
  margin-top: 4px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 120px;
  }
`;

const OpenText = styled.div`
  font-size: 12px;
  color: ${COLOR.GREEN};
  min-width: 40px;
  cursor: pointer;
`;

const DataPullDownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const DataPulldownText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #707070;
  margin-left: 4px;
`;
