import React, { useState } from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { GmbCategories, Option } from 'models/Domain/GmbLocation/GmbCategories';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

interface AdditionalCategorySearchValues {
  key: number;
  options: Option[];
}

export const EditGmbCategoryList: React.FC<{
  storeForEdit: Store;
  categoryList: GmbCategories;
  changeStore: (v: Store) => void;
}> = ({ storeForEdit, changeStore, categoryList }) => {
  const [primaryCategoryListOptions, setPrimaryCategoryListOptions] = useState<Option[]>([]);
  const [additionalCategorySearchOptions, setAdditionalCategorySearchOptions] = useState<
    AdditionalCategorySearchValues[]
  >([]);
  const { location } = storeForEdit;

  const { isValid, error } = storeForEdit.validate().primaryCategory.isValid
    ? storeForEdit.validate().additionalCategories
    : storeForEdit.validate().primaryCategory;

  // サブカテゴリーの入力情報が変更された場合、対象のoptionsを更新
  const updateAdditionalCategorySearchOptions = (idx: number, value: string) => {
    if (!additionalCategorySearchOptions[idx]) {
      setAdditionalCategorySearchOptions([
        ...additionalCategorySearchOptions,
        { key: idx, options: categoryList.generateCategoryOption(value) },
      ]);
    } else {
      setAdditionalCategorySearchOptions(
        additionalCategorySearchOptions.map((target) => {
          if (target.key === idx) {
            return {
              key: target.key,
              options: categoryList.generateCategoryOption(value),
            };
          }
          return target;
        }),
      );
    }
  };

  return (
    <Wrapper>
      <Label>カテゴリー</Label>
      {!isValid && <Error>{error}</Error>}
      <ContentWrapper>
        メインカテゴリー
        <Category
          value={location.primaryCategory.categoryId}
          options={
            location.primaryCategory.categoryId && primaryCategoryListOptions.length === 0
              ? categoryList.generateSingleCategoryOption(location.primaryCategory.categoryId)
              : primaryCategoryListOptions
          }
          placeholder={'カテゴリー'}
          onChange={(value) => {
            changeStore(storeForEdit.changePrimaryCategory(value));
          }}
          onSearchChange={(value) => {
            setPrimaryCategoryListOptions(categoryList.generateCategoryOption(value));
          }}
        />
      </ContentWrapper>
      <ContentWrapper>
        サブカテゴリー
        {location.additionalCategories.list.map((additionalCategory, idx) => {
          const additionalOptions = additionalCategorySearchOptions.find((target) => {
            return target.key === idx;
          })?.options;
          return (
            <AdditionalCategoryWrapper key={idx}>
              <Category
                value={additionalCategory.categoryId}
                options={
                  additionalCategory.categoryId && !additionalOptions
                    ? categoryList.generateSingleCategoryOption(additionalCategory.categoryId)
                    : additionalOptions || []
                }
                placeholder={'カテゴリー'}
                onChange={(value) => {
                  changeStore(storeForEdit.changeAdditionalCategory(idx, value));
                }}
                onSearchChange={(value) => {
                  updateAdditionalCategorySearchOptions(idx, value);
                }}
              />
              <XIcon
                onClick={() => {
                  changeStore(storeForEdit.removeAdditionalCategory(idx));
                }}
                name='x'
              />
            </AdditionalCategoryWrapper>
          );
        })}
        <AddWrapper>
          {location.additionalCategories.list.size < 9 && (
            <AddCategory onClick={() => changeStore(storeForEdit.addAdditionalCategory())}>
              サブカテゴリーを追加
            </AddCategory>
          )}
        </AddWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Label = styled.label`
  font-weight: bold;
`;

const XIcon = styled(Icon)`
  &&& {
    margin-left: 16px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 16px;
`;

const AddWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const AddCategory = styled.div`
  color: #4183c4;
  text-decoration: underline;
  margin-top: 8px;
  cursor: pointer;
`;

const AdditionalCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Category = styled(PullDownNarrow)`
  &&& {
    margin-top: 8px;
    width: 200px;
  }
`;

const Error = styled.div`
  margin: 16px 0;
  color: ${COLOR.RED};
`;
