import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { SegmentUpload } from 'components/molecules/FileUploader';
import { MenuActions } from 'modules/menu/actions';
import { COLOR } from 'style/color';

type Props = {
  imageUrl: string | null;
  onDelete: () => void;
  onChange: (imageInfo: ImageFileMeta) => void;
};

export const ImageUploader: React.FC<Props> = ({ imageUrl, onDelete, onChange }) => {
  const dispatch = useDispatch();
  const { uploadImage, clearUploadedImageUrl } = useMemo(() => bindActionCreators(MenuActions, dispatch), [dispatch]);
  const handleOnDropAccepted = useCallback(
    (files: File[]) => {
      const imageFile = files[0];
      if (!imageFile) return;
      const localImageUrl = URL.createObjectURL(imageFile);
      const imageObject = new window.Image();

      imageObject.onload = () => {
        onChange({
          type: imageFile.type,
          width: imageObject.naturalWidth,
          height: imageObject.naturalHeight,
          size: imageFile.size,
        });
        URL.revokeObjectURL(localImageUrl);
      };

      imageObject.src = localImageUrl;
      uploadImage(files);
    },
    [onChange, uploadImage],
  );

  const handleOnDropRejected = useCallback((files: File[]) => {
    console.error(files);
  }, []);

  const handleOnClickRemoveButton = useCallback(() => {
    clearUploadedImageUrl();
    onDelete();
  }, [onDelete, clearUploadedImageUrl]);

  useEffect(() => {
    return () => {
      clearUploadedImageUrl();
    };
  }, [clearUploadedImageUrl]);

  return (
    <Wrapper>
      {imageUrl ? (
        <ImageContainer>
          <ImageWrapper>
            <Image src={imageUrl} />
            <RemoveIcon onClick={handleOnClickRemoveButton} />
          </ImageWrapper>
        </ImageContainer>
      ) : (
        <StyledSegmentUpload
          multiple={false}
          uploadType={'image'}
          iconName={'camera'}
          buttonText={'写真を追加'}
          onDropRejected={handleOnDropRejected}
          onDropAccepted={handleOnDropAccepted}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ImageContainer = styled.div``;

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 288px;
  object-fit: contain;
  border: 1px solid ${COLOR.LIGHT_GRAY};
`;

const RemoveIcon = styled(Icon).attrs({ type: 'cancel' })`
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  /* 画像より前に表示する */
  z-index: 1;
`;

const StyledSegmentUpload = styled(SegmentUpload)`
  cursor: pointer;
  width: 100%;
`;
