import React from 'react';

import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';
import { Header, Icon, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { COLOR } from 'style/color';

export const defaultAcceptTypes = ['image/jpg', 'image/jpeg', 'image/png'];
export const defaultAcceptWithPDF = defaultAcceptTypes.concat(['application/pdf']);

export type UploadType = 'file' | 'image' | 'csv';

type Props = {
  className?: string;
  uploadType?: UploadType;
  iconName?: IconNameType;
  buttonText?: string;
  multiple?: boolean;
  accept?: string[];
  onDropAccepted: (files: File[]) => void;
  onDropRejected: (files: File[]) => void;
};

type IconNameType = 'camera' | 'paperclip';

type ComponentProps = {
  className?: string;
  buttonText: string;
  iconName: IconNameType;
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
};

export const DropzoneHOC = (Component: React.ComponentType<ComponentProps>) => {
  return (props: Props): React.ReactElement => {
    const {
      onDropAccepted,
      onDropRejected,
      accept = defaultAcceptTypes,
      multiple = true,
      uploadType = 'image',
      iconName: iconNameProps,
      buttonText: buttonTextProps,
      ...otherProps
    } = props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { getRootProps, getInputProps } = useDropzone({
      onDropAccepted,
      onDropRejected: (fileRejection) => onDropRejected(fileRejection.map((f) => f.file)),
      accept,
      multiple,
    });

    let buttonText = buttonTextProps;
    if (!buttonText) {
      if (uploadType === 'file') {
        buttonText = 'ファイルを追加';
      } else {
        buttonText = '画像を追加';
      }
    }

    let iconName = iconNameProps;
    if (!iconName) {
      if (uploadType === 'file') {
        iconName = 'paperclip';
      } else {
        iconName = 'camera';
      }
    }

    return (
      <Component
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        buttonText={buttonText}
        iconName={iconName}
        {...otherProps}
      />
    );
  };
};

export const UploadButton = DropzoneHOC(({ getRootProps, getInputProps, buttonText, iconName, className }) => (
  <ButtonWrapper className={className} {...getRootProps()}>
    <WideUploadButton>
      <input {...getInputProps()} />
      <WideUploadCameraIcon name={iconName} size='large' />
      {buttonText}
    </WideUploadButton>
  </ButtonWrapper>
));

export const ActionUploadButton = DropzoneHOC(({ getRootProps, getInputProps, buttonText, iconName, className }) => (
  <ActionButton className={className} {...getRootProps()}>
    <input {...getInputProps()} />
    <ActionButtonIcon name={iconName} />
    {buttonText}
  </ActionButton>
));

export const SmallUploadButton = DropzoneHOC(({ getRootProps, getInputProps, className, iconName }) => (
  <SmallUploadButtonWrapper className={className} {...getRootProps()}>
    <input {...getInputProps()} />
    <Icon name={iconName} size='big' />
  </SmallUploadButtonWrapper>
));

export const SegmentUpload = DropzoneHOC(({ getRootProps, getInputProps, className, iconName }) => (
  <div {...getRootProps()} className={className}>
    <StyledSegment placeholder>
      <Header icon>
        <ActionButtonIcon name={iconName} />
        {iconName === 'camera'
          ? 'ここをクリックまたは画像をドロップして画像を追加'
          : 'ここをクリックまたはCSVファイルをドロップして追加'}

        <input {...getInputProps()} />
      </Header>
    </StyledSegment>
  </div>
));

const ButtonWrapper = styled.div`
  display: inline-block;
  outline: none;
  width: 100%;
`;

const ActionButton = styled(Button).attrs(() => ({ priority: 'high' }))``;

const ActionButtonIcon = styled(Icon)`
  &&& {
    line-height: 1;
    margin-right: 8px;
  }
`;

const WideUploadButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const WideUploadCameraIcon = styled(Icon)`
  &&& {
    color: ${COLOR.GREEN};
    height: unset !important;
  }
`;

const SmallUploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR.GREEN};
  border-radius: 14px;
  border: 2px solid ${COLOR.GREEN};
  width: 64px;
  height: 64px;
  margin: 10px 10px 0 0;
  cursor: pointer;
  i {
    margin-right: 0 !important;
  }
`;

const StyledSegment = styled(Segment)`
  &&& {
    border-style: dotted;
  }
`;
