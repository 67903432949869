import dayjs, { Dayjs } from 'dayjs';
import { Record } from 'immutable';

import { JSObject } from 'types/Common';

/**
 * 返信
 */
export class ReviewReply extends Record<{
  comment: string;
  updateTime: Dayjs;
}>({
  comment: '',
  updateTime: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.updateTime = dayjs(params.updateTime);
    super(params);
  }
}
