import { Dayjs } from 'dayjs';
import { Set } from 'immutable';

import ImageSearchCondition from 'models/Domain/Image/ImageSearchCondition';
import { Path } from 'routes';
import { Group } from 'types/Common';

/**
 * 特定の条件における写真ページのURLを生成する
 * @param param0
 * @returns
 */
export const createAlbumListIndexPath = ({ tag, storeIds }: { tag?: string; storeIds?: number[] }) => {
  let condition = new ImageSearchCondition();

  if (tag) {
    condition = condition.set('tag', tag);
  }

  if (storeIds) {
    condition = condition.set('storeIds', Set(storeIds));
  }

  return `${Path.album.index}?${condition.toURLSearchParams()}`;
};

/**
 * 店舗詳細ページのパスを生成する
 * @param param0
 * @returns
 */
export const createStoreDetailPath = ({ storeId }: { storeId: number }) => {
  return Path.store.detail.replace(':storeId', storeId.toString()).replace(/%2C/g, ',');
};

/**
 * 写真一覧ページのパスを生成する
 * @param param0
 * @returns
 */
export const createAlbumIndexPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
  startDate,
  endDate,
}: {
  group?: Group;
  storeIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  if (startDate) {
    searchParams.set('sd', startDate.format('YYYY-MM-DD'));
  }
  if (endDate) {
    searchParams.set('ed', endDate.format('YYYY-MM-DD'));
  }
  return `${Path.album.index}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * 投稿一覧ページのパスを生成する
 * @param param0
 * @returns
 */
export const createLocalpostIndexPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
  startDate,
  endDate,
}: {
  group?: Group;
  storeIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  if (startDate) {
    searchParams.set('sd', startDate.format('YYYY-MM-DD'));
  }
  if (endDate) {
    searchParams.set('ed', endDate.format('YYYY-MM-DD'));
  }
  return `${Path.localpost.index}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * クチコミ一覧ページのパスを生成する
 * @param param0
 * @returns
 */
export const createReviewIndexPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
  startDate,
  endDate,
}: {
  group?: Group;
  storeIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  if (startDate) {
    searchParams.set('sd', startDate.format('YYYY-MM-DD'));
  }
  if (endDate) {
    searchParams.set('ed', endDate.format('YYYY-MM-DD'));
  }
  return `${Path.gmb.review}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * 依頼報告一覧ページのパスを生成する
 * @param param0
 * @returns
 */
export const createOfferIndexPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
}: {
  group?: Group;
  storeIds?: number[];
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  return `${Path.offerGroups.index}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * 店頭在庫ページのパスを生成する
 * @param param0
 * @returns
 */
export const createLocalInventoryPath = ({
  // 他のパラメータ、必要になったら追加する
  storeId,
}: {
  storeId: number;
}) => {
  return Path.omo.inventoryList.replace(':storeId', storeId.toString()).replace(/%2C/g, ',');
};

/**
 * 検索キーワードページのパスを生成する
 * @param param0
 * @returns
 */
export const createSearchKeywordsPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
  startDate,
  endDate,
}: {
  group?: Group;
  storeIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  if (startDate && endDate) {
    searchParams.set('sm', startDate.format('YYYY-MM'));
    searchParams.set('em', endDate.format('YYYY-MM'));
    searchParams.set('tm', endDate.format('YYYY-MM'));
  }
  return `${Path.searchKeywords.index}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * マップ順位監視ページのパスを生成する
 * @param param0
 * @returns
 */
export const createMapSearchRankPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
  startDate,
  endDate,
}: {
  group?: Group;
  storeIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  if (startDate && endDate) {
    searchParams.set('sd', startDate.format('YYYY-MM-DD'));
    searchParams.set('ed', endDate.format('YYYY-MM-DD'));
  }
  return `${Path.mapSearchRank.index}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * GBPチェックリストページのパスを生成する
 * @param param0
 * @returns
 */
export const createGBPChecklistPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
}: {
  group?: Group;
  storeIds?: number[];
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  return `${Path.gbpChecklist.index}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * GBPパフォーマンスページのパスを生成する
 * @param param0
 * @returns
 */
export const createGBPPerformancePath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
  startDate,
  endDate,
  displayType,
}: {
  group?: Group;
  storeIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  displayType?: 'overview' | 'impressions' | 'interactions' | 'activities';
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  if (startDate && endDate) {
    searchParams.set('sm', startDate.format('YYYY-MM'));
    searchParams.set('em', endDate.format('YYYY-MM'));
  }
  if (displayType) {
    searchParams.set('dt', displayType);
  }
  return `${Path.gbp.performance}?${searchParams.toString()}`.replace(/%2C/g, ',');
};

/**
 * 競合店舗一覧ページのパスを生成する
 * @param param0
 * @returns
 */
export const createCompetitorsIndexPath = ({
  // 他のパラメータ、必要になったら追加する
  group,
  storeIds,
}: {
  group?: Group;
  storeIds?: number[];
}) => {
  const searchParams = new URLSearchParams();
  if (group) {
    searchParams.set('st', typeof group === 'number' ? group.toString() : group);
  }
  if (storeIds && storeIds.length > 0) {
    searchParams.set('si', storeIds.map((storeId) => storeId.toString()).join(','));
  }
  return `${Path.competitors.index}?${searchParams.toString()}`.replace(/%2C/g, ',');
};
