/* eslint-disable @typescript-eslint/no-use-before-define */

import { List, Record, Set } from 'immutable';

import { GetOfferGroupRequestParams } from 'ApiClient/OfferGroupsApi';
import { booleanToSearchParam, parseBooleanParam, parseNumberParameter } from 'helpers/search';
import { JSObject } from 'types/Common';

import { OfferType } from '../Offer';

import { OfferGroupStatus } from './OfferGroup';

export type OfferTypeKeyValueType = {
  key: OfferType;
  value: boolean;
};

export class OfferTypeKeyValue extends Record<OfferTypeKeyValueType>({
  key: 'task',
  value: true,
}) {}

export type OfferStatusKeyValueType = {
  key: OfferGroupStatus;
  value: boolean;
};

export class OfferStatusKeyValue extends Record<OfferStatusKeyValueType>({
  key: 'in_progress',
  value: true,
}) {}

const DEFAULT_STORE_VALUE = null;
const DEFAULT_IS_ALL_STORE_IDS_VALUE = true;
const DEFAULT_SHOW_CLOSED_STORE_VALUE = false;
const DEFAULT_OFFER_TYPE_VALUE = Set(['task', 'report', 'other_offer']);
const DEFAULT_OFFER_STATUS_VALUE = Set(['done', 'in_progress']);
const DEFAULT_IS_ALL_USERS_CREATED_BY_VALUE = true;

export type FilterStatusType = {
  store: number | 'all' | 'my_store' | null;
  // 選択されている店舗一覧(カンマ区切り)
  store_ids: Set<number>;
  is_all_store_ids: boolean; // store_ids が store で指定されているグループのすべての店舗か
  // オファーの種類
  type: List<OfferTypeKeyValue>;
  // オファーの状態
  status: List<OfferStatusKeyValue>;
  // 依頼報告作成者のユーザーID
  created_by: Set<number>;
  is_all_users_created_by: boolean; // 作成者指定が すべてのユーザーか
  // 閉店店舗を表示するか
  show_closed_store: boolean;
};

/**
 * オファーグループの絞り込みの条件
 */
export class FilterStatus extends Record<FilterStatusType>({
  store: DEFAULT_STORE_VALUE,
  store_ids: Set<number>(),
  is_all_store_ids: DEFAULT_IS_ALL_STORE_IDS_VALUE,
  type: List(
    ['task', 'report', 'other_offer'].map(
      (k) =>
        new OfferTypeKeyValue({
          key: k as OfferType,
          value: DEFAULT_OFFER_TYPE_VALUE.has(k),
        }),
    ),
  ),
  status: List(
    ['done', 'in_progress'].map(
      (k) =>
        new OfferStatusKeyValue({
          key: k as OfferGroupStatus,
          value: DEFAULT_OFFER_STATUS_VALUE.has(k),
        }),
    ),
  ),
  created_by: Set<number>(),
  is_all_users_created_by: DEFAULT_IS_ALL_USERS_CREATED_BY_VALUE,
  show_closed_store: DEFAULT_SHOW_CLOSED_STORE_VALUE,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  /**
   * 選択されている店舗を更新する
   * @param storeIds 選択されている店舗のID
   * @param isAllStoreIds 全ての店舗か
   * @returns
   */
  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({
      store_ids: storeIds,
      is_all_store_ids: isAllStoreIds,
    });
  }

  /**
   * store_idsが正しい形か？
   */
  isValidStoreIds() {
    return this.store_ids.size > 0;
  }

  /**
   * 正しい絞り込み条件かを返す
   */
  isValid() {
    return this.isValidStoreIds();
  }

  /**
   * 種別を更新する
   */
  setType(key: OfferType, value: boolean) {
    const index = this.type.findIndex((type) => type.key === key);
    return this.setIn(['type', index, 'value'], value);
  }

  /**
   * 状態を更新する
   */
  setStatus(key: OfferGroupStatus, value: boolean) {
    const index = this.status.findIndex((status) => status.key === key);
    return this.setIn(['status', index, 'value'], value);
  }

  /**
   * 選択されている作成者を更新する
   * @param createdByUserIds 選択されている作成者のユーザーのID
   * @param isAllUserCreatedBy 全てのユーザーか
   * @returns
   */
  setCreatedByUserIds(createdByUserIds: Set<number>, isAllUserCreatedBy: boolean) {
    return this.merge({
      created_by: createdByUserIds,
      is_all_users_created_by: isAllUserCreatedBy,
    });
  }

  getTypeLabel(key: OfferType) {
    return key === 'task' ? '店舗への依頼' : key === 'report' ? '本社への報告' : 'その他';
  }

  getStatusLabel(key: OfferGroupStatus) {
    return key === 'in_progress' ? '未完了' : '完了';
  }

  /**
   * 種別のラベルを返す
   */
  getTypeOptionLabel() {
    const values = this.type.map((type) => type.value);

    // すべてtrue(falseを含まない)か、すべてfalse(trueを含まない)の場合
    if (!values.includes(false) || !values.includes(true)) {
      return 'すべて';
    }

    return this.type
      .filter((type) => type.value === true)
      .map((type) => this.getTypeLabel(type.key))
      .join('、');
  }

  /**
   * 状態のラベルを返す
   */
  getStatusOptionLabel() {
    const values = this.status.map((status) => status.value);

    // すべてtrue(falseを含まない)か、すべてfalse(trueを含まない)の場合
    if (!values.includes(false) || !values.includes(true)) {
      return 'すべて';
    }

    return this.status
      .filter((status) => status.value === true)
      .map((status) => this.getStatusLabel(status.key))
      .join('、');
  }
}

export type SortKey = 'update_at' | 'create_at' | 'due_date' | 'latest_activity_time';
export type SortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY: SortKey = 'latest_activity_time';
const DEFAULT_SORT_ORDER = 'desc';

export type SortStatusType = {
  sort_key: SortKey;
  sort_order: SortOrder;
};

export class SortStatus extends Record<SortStatusType>({
  sort_key: DEFAULT_SORT_KEY,
  sort_order: DEFAULT_SORT_ORDER,
}) {
  get sortTypeOptions() {
    return [
      { text: '更新日', value: 'latest_activity_time' },
      { text: '作成日', value: 'create_at' },
      { text: '期限', value: 'due_date' },
    ];
  }

  get sortKeyOptions() {
    return [
      { text: '新しい順', value: 'desc' },
      { text: '古い順', value: 'asc' },
    ];
  }
}

export type PaginationType = {
  page: number;
  limit: number;
};

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 20;

export class Pagination extends Record<PaginationType>({
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
}) {}

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<
    keyof FilterStatusType | keyof SortStatusType | keyof PaginationType,
    'is_all_store_ids' | 'is_all_users_created_by'
  >]: string;
} = {
  store: 'st',
  store_ids: 'si',
  type: 'ty',
  status: 'sa',
  created_by: 'cb',
  page: 'pg',
  limit: 'li',
  sort_key: 'sk',
  sort_order: 'so',
  show_closed_store: 'cs',
};

export class OfferGroupsSearchCondition extends Record<{
  filter: FilterStatus;
  sort: SortStatus;
  pagination: Pagination;
}>({
  filter: new FilterStatus(),
  sort: new SortStatus(),
  pagination: new Pagination(),
}) {
  // ソート条件を
  setSortCondition(key: SortKey, order: SortOrder) {
    return this.mergeIn(['sort'], { key, order });
  }

  setPage(page: number) {
    return this.setIn(['pagination', 'page'], page);
  }
  /**
   * GmbReviewSearchConditionをAPIのパラメータに変換する
   */
  toRequestParams(): GetOfferGroupRequestParams {
    return {
      store_id: this.filter.store_ids.toArray().sort(numberComparator).join(','),
      type: this.filter.type
        .filter((r) => r.value)
        .map((r) => r.key)
        .join(','),
      status: this.filter.status
        .filter((r) => r.value)
        .map((r) => r.key)
        .join(','),
      created_by: this.filter.created_by.toArray().sort(numberComparator).join(','),
      sort_key: this.sort.sort_key,
      sort_order: this.sort.sort_order,
      page: this.pagination.page,
      limit: this.pagination.limit,
    };
  }

  /**
   * 検索条件をページURL用のパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();
    if (this.filter.store != DEFAULT_STORE_VALUE) {
      params.append(URLSearchParamsMapping.store, String(this.filter.store));
    }

    if (!this.filter.is_all_store_ids && this.filter.store_ids.size > 0) {
      params.append(URLSearchParamsMapping.store_ids, this.filter.store_ids.toArray().sort(numberComparator).join(','));
    }

    const type = this.filter.type
      .filter((r) => r.value)
      .map((r) => r.key)
      .toArray();
    if (type.length > 0 && !Set(type).equals(DEFAULT_OFFER_TYPE_VALUE)) {
      params.append(URLSearchParamsMapping.type, type.join(','));
    }

    const status = this.filter.status
      .filter((r) => r.value)
      .map((r) => r.key)
      .toArray();

    if (status.length > 0 && !Set(status).equals(DEFAULT_OFFER_STATUS_VALUE)) {
      params.append(URLSearchParamsMapping.status, status.join(','));
    }

    if (!this.filter.is_all_users_created_by && this.filter.created_by.size > 0) {
      params.append(
        URLSearchParamsMapping.created_by,
        this.filter.created_by.toArray().sort(numberComparator).join(','),
      );
    }

    if (this.sort.sort_key !== DEFAULT_SORT_KEY) {
      params.append(URLSearchParamsMapping.sort_key, this.sort.sort_key);
    }
    if (this.sort.sort_order !== DEFAULT_SORT_ORDER) {
      params.append(URLSearchParamsMapping.sort_order, this.sort.sort_order);
    }

    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }
    if (this.pagination.limit !== DEFAULT_LIMIT) {
      params.append(URLSearchParamsMapping.limit, String(this.pagination.limit));
    }

    if (this.filter.show_closed_store != DEFAULT_SHOW_CLOSED_STORE_VALUE) {
      params.append(URLSearchParamsMapping.show_closed_store, booleanToSearchParam(this.filter.show_closed_store));
    }

    // 見栄え悪いので '%2C' は ',' に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * URLパラメータから検索条件を生成する
   * @param search
   */
  static fromURLSearchParams(search: string): OfferGroupsSearchCondition {
    const condition = new OfferGroupsSearchCondition();
    let { filter, sort, pagination } = condition;
    const params = new URLSearchParams(search);

    const store = params.get(URLSearchParamsMapping.store);
    if (store) {
      if (store === 'all' || store === 'my_store') {
        // すべての店舗もしくは担当店舗の場合
        filter = filter.set('store', store);
      } else if (store.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('store', parseInt(store, 10));
      }
    }

    const store_ids = params.get(URLSearchParamsMapping.store_ids) || 'all';
    if (store_ids === 'all') {
      filter = filter.setStoreIds(Set<number>(), true);
    } else {
      const values = store_ids.split(',');
      const storeIds = values.filter((v) => v.match(/^\d+$/)).map((v) => parseInt(v, 10));
      filter = filter.setStoreIds(Set(storeIds), false);
    }

    const craeted_by = params.get(URLSearchParamsMapping.created_by) || 'all';
    if (craeted_by === 'all') {
      filter = filter.setCreatedByUserIds(Set<number>(), true);
    } else {
      const values = craeted_by.split(',');
      const createdBy = values.filter((v) => v.match(/^\d+$/)).map((v) => parseInt(v, 10));
      filter = filter.setCreatedByUserIds(Set(createdBy), false);
    }

    const type = params.get(URLSearchParamsMapping.type);
    if (type) {
      const typeStrs = type.split(',');
      const typeKeys = typeStrs.filter((v) => v.match(/^task|report|other_offer$/));
      const value = List(
        ['task', 'report', 'other_offer'].map(
          (key) =>
            new OfferTypeKeyValue({
              key: key as OfferType,
              value: typeKeys.includes(key),
            }),
        ),
      );
      filter = filter.set('type', value);
    }

    const status = params.get(URLSearchParamsMapping.status);
    if (status) {
      const statusStrs = status.split(',');
      const statusKeys = statusStrs.filter((v) => v.match(/^in_progress|done$/));
      const value = List(
        ['done', 'in_progress'].map(
          (key) =>
            new OfferStatusKeyValue({
              key: key as OfferGroupStatus,
              value: statusKeys.includes(key),
            }),
        ),
      );
      filter = filter.set('status', value);
    }

    const sort_key = params.get(URLSearchParamsMapping.sort_key);
    if (sort_key) {
      if (['update_at', 'create_at', 'due_date', 'latest_activity_time'].includes(sort_key)) {
        sort = sort.set('sort_key', sort_key as SortKey);
      }
    }

    const sort_order = params.get(URLSearchParamsMapping.sort_order);
    if (sort_order) {
      if (['asc', 'desc'].includes(sort_order)) {
        sort = sort.set('sort_order', sort_order as SortOrder);
      }
    }

    filter = filter.set(
      'show_closed_store',
      parseBooleanParam(params.get(URLSearchParamsMapping.show_closed_store), DEFAULT_SHOW_CLOSED_STORE_VALUE),
    );

    // ページネーション関連
    pagination = pagination.set('page', parseNumberParameter(params.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1));
    pagination = pagination.set(
      'limit',
      parseNumberParameter(params.get(URLSearchParamsMapping.limit), DEFAULT_LIMIT, 1, 100),
    );

    return condition.merge({ filter, sort, pagination });
  }
}

const numberComparator = (a: number, b: number) => a - b;
