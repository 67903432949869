import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { Prompt } from 'components/atoms/Prompt';
import { PromotionEditForm } from 'components/pageComponents/Promotion/PromotionEditForm';
import { PromotionEditHeader } from 'components/pageComponents/Promotion/PromotionEditHeader';
import { PromotionPreviewModal } from 'components/pageComponents/Promotion/PromotionPreviewModal';
import { PromotionTopicTypeTab } from 'components/pageComponents/Promotion/PromotionTopicTypeTab';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useConfirmLeave } from 'hooks/useConfirmLeave';
import { useDisplayType } from 'hooks/useDisplayType';
import { TranslationLanguage } from 'models/Domain/Organization';
import Promotion, { PROMOTION_TOPIC_TYPE_KEYS, PromotionTopicType } from 'models/Domain/Promotion/Promotion';
import { PromotionGroup } from 'models/Domain/Promotion/PromotionGroup';
import { Store } from 'models/Domain/Store';
import { AppActions } from 'modules/app/actions';
import { PromotionActions } from 'modules/promotion/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

const TOPIC_TYPE_LABEL: { [key in PromotionTopicType]: string } = {
  STANDARD: '最新情報',
  ALERT: 'COVID-19の最新情報',
  OFFER: '特典',
  EVENT: 'イベント',
};

// 未保存の変更がある場合に、他のページに遷移しようとした場合のメッセージ
const promptMessage = 'ページから離れようとしています。\n公開されていない内容は失われてしまいますが、よろしいですか？';

export const PromotionCreate: React.FC = () => {
  const dispatch = useDispatch();
  const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
  const {
    clearMediaForEdit,
    getBasePromotion,
    setMediaForEdit,
    createPromotion,
    translatePost,
    setPromotionForEdit,
    setPromotionGroupForEdit,
  } = useMemo(() => bindActionCreators(PromotionActions, dispatch), [dispatch]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // 編集済みの状態から投稿種別を切り替えようとしたときにアラートを出すためのフラグ
  const [changed, setChanged] = useState<boolean>(false);
  // 必須項目などのエラーメッセージを表示させるかのフラグ
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const location = useLocation<{ baseId: string }>();
  const state = location.state;
  const basePromotionId = location.state?.baseId;

  // クエリパラメータからトピックタイプを取得する
  // 実際には PromotionTopicType の文字列だけではないが、その場合は次のuseEffectで投稿一覧に遷移させる
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const topicType = (queryParams.get('topic_type') || 'STANDARD') as PromotionTopicType;

  const { stores, storeLists, mediaForEdit, currentUser, isLoading, loadingLanguages, promotionGroup } = useSelector(
    (state: State) => ({
      stores: state.store.stores,
      storeLists: state.storeList.storeLists,
      currentUser: state.app.currentUser,
      mediaForEdit: state.promotion.mediaForEdit,
      promotionGroup: state.promotion.promotionGroupForEdit,
      isLoading: state.promotion.isLoadingPromotion,
      loadingLanguages: state.promotion.loadingLanguages,
    }),
    shallowEqual,
  );

  // 編集用の投稿データ
  const promotionForEdit = promotionGroup.promotion;

  const handleChangePromotionGroup = useCallback(
    (promotionGroup: PromotionGroup) => {
      setChanged(true);
      setPromotionGroupForEdit(promotionGroup);
    },
    [setPromotionGroupForEdit],
  );

  // 投稿の内容が変更されたらisEditedをtrueにする
  const changePromotion = useCallback(
    (promotion: Promotion) => {
      setPromotionForEdit(promotion);
      setChanged(true);
    },
    [setPromotionForEdit],
  );

  const onChangeStoreList = useCallback(
    (storeIds: number[]) => {
      setPromotionForEdit(promotionForEdit.changeStores(storeIds));
      // 投稿先店舗が変更されたらchangedをtrueにする
      if (storeIds.length > 0) {
        setChanged(true);
      }
    },
    [promotionForEdit, setPromotionForEdit],
  );

  useEffect(() => {
    // URLのトピックタイプが未知の場合は、投稿トップに移動する
    if (!PROMOTION_TOPIC_TYPE_KEYS.includes(topicType)) {
      moveTo(Path.localpost.index);
      return;
    }

    // トピックタイプが変わった場合、データは新規に作り直す
    const newPromotion = new Promotion({ topic_type: topicType });
    setPromotionGroupForEdit(new PromotionGroup({ promotion: newPromotion }));
    // エラーメッセージの表示フラグをリセットする
    setShowErrorMessage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicType]);

  useEffect(() => {
    clearMediaForEdit();
    if (basePromotionId) {
      getBasePromotion(basePromotionId);
      setChanged(true);
    } else {
      const newPromotion = new Promotion({ topic_type: topicType });
      setPromotionGroupForEdit(new PromotionGroup({ promotion: newPromotion }));
      // setMediaForEdit(newPromotion.media);
      setChanged(false);
    }
    return () => {
      setPromotionGroupForEdit(new PromotionGroup());
    };
  }, [basePromotionId, clearMediaForEdit, getBasePromotion, setMediaForEdit, setPromotionGroupForEdit, topicType]);

  // 写真がアップロードされてmediaForEditが変更されたら、それを編集中のpromotionに取り込む
  useEffect(() => {
    const promotion = promotionForEdit.changeMedia(mediaForEdit);
    setPromotionForEdit(promotion);
  }, [mediaForEdit, setPromotionForEdit, promotionForEdit]);

  const previewTargetStoreId = promotionForEdit.stores.find((store) => !!stores.findStore(store.store_id))?.store_id;
  const previewTargetStore = stores.findStore(previewTargetStoreId) || new Store();

  // 投稿タイプのタブをクリックした場合、遷移させる
  const onChangeTopicType = useCallback(
    (topicType: PromotionTopicType) => {
      moveTo(`${Path.localpost.create}?topic_type=${topicType}`);
      setChanged(false);
    },

    [moveTo],
  );

  const handleOnBack = useCallback(() => {
    // 遷移元が投稿一覧なら検索条件やスクロール位置を保持するために戻る、それ以外なら投稿一覧に遷移
    if (state?.source === Path.localpost.index) {
      dispatch(goBack());
    } else {
      dispatch(replaceWithOrganizationId(Path.localpost.index));
    }
  }, [dispatch, state]);

  const handleTranslatePost = useCallback(
    (language: TranslationLanguage) => {
      translatePost({ language, promotion: promotionForEdit });
    },
    [promotionForEdit, translatePost],
  );

  useConfirmLeave(true);

  const isDesktop = useDisplayType('800px').isDesktop;
  return (
    <MainWrapper>
      <Helmet title={getPageTitle(`投稿の新規作成 - ${TOPIC_TYPE_LABEL[topicType]}`)} />
      <PromotionEditHeader
        title={`投稿の新規作成 - ${TOPIC_TYPE_LABEL[topicType]}`}
        onBack={handleOnBack}
        formType={'create'}
      />
      {isDesktop && <PromotionTopicTypeTab selectedTopicType={topicType} onChangeTab={onChangeTopicType} />}
      <StyledBody>
        <PromotionEditForm
          formType='create'
          promotion={promotionForEdit}
          setPromotion={changePromotion}
          storeLists={storeLists}
          stores={stores}
          setSelectedStoreList={(v) => onChangeStoreList(v)}
          currentUser={currentUser}
          onCreate={() => {
            // 送信して画面遷移するときにダイアログが出ないように、changedをここでfalseにする
            setChanged(false);
            setModalOpen(true);
          }}
          onChangeTopicType={(value) => onChangeTopicType(value)}
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          onSaveDraft={() => {
            setChanged(false);
            createPromotion(promotionForEdit.changeIsDraft(true));
          }}
          loadingLanguages={loadingLanguages}
          translatePost={handleTranslatePost}
          promotionGroup={promotionGroup}
          onChangePromotionGroup={handleChangePromotionGroup}
        />
        {isLoading && (
          <LoadingWrapper>
            <LoadingWrapperBase />
            <Loader active={true} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
      </StyledBody>
      <PromotionPreviewModal
        promotionGroup={promotionGroup}
        open={modalOpen}
        store={previewTargetStore}
        onClose={() => {
          // ダイアログを出すときにchangedをfalseにしているのでtrueに戻す
          setChanged(true);
          setModalOpen(false);
        }}
        onSubmit={() => {
          setChanged(false);
          createPromotion(promotionForEdit);
          setModalOpen(false);
        }}
      />
      <Prompt when={changed} message={promptMessage} />
    </MainWrapper>
  );
};

const StyledBody = styled(Body)`
  position: relative;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  z-index: 1; /** カルーセルの矢印より上に表示されるようにする */
`;
