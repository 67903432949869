import { List, Record } from 'immutable';

import { PaginationWithoutLimit } from 'models/Domain/Pagination';
import ListPromotion from 'models/Domain/Promotion/ListPromotion';
import { JSObject } from 'types/Common';

export const SortTypeOptions: { text: string; value: SortType }[] = [
  {
    text: '投稿日時が新しい順',
    value: 'start_date_desc',
  },
  {
    text: '投稿日時が古い順',
    value: 'start_date',
  },
];

export const SORT_TYPES = ['start_date', 'start_date_desc'] as const;
export type SortType = (typeof SORT_TYPES)[number];

export interface PromotionListRecord {
  items: List<ListPromotion>;
  sort_type: SortType;
  pagination: PaginationWithoutLimit;
  initialized: boolean;
}

export default class PromotionList extends Record<PromotionListRecord>({
  items: List(),
  sort_type: 'start_date',
  pagination: new PaginationWithoutLimit(),
  initialized: false,
}) {
  constructor(data: JSObject = {}, initialized = false) {
    const items = data.items ? List(data.items.map((item: JSObject) => new ListPromotion(item))) : List();
    const pagination = new PaginationWithoutLimit(data.pagination ?? {});
    super({ items, pagination, initialized });
  }

  changeSortType(value: SortType) {
    return this.set('sort_type', value);
  }
}
