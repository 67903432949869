import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import { GetMemoResponse, GetMemoTagResponse } from 'ApiClient/MemoApi';
import { getCurrentWeekEndDay, getCurrentWeekStartDay } from 'helpers/date';
import { AggregateUnit, ArrayElement } from 'types/Common';

import { MemoDisplaySettings } from '../../../helpers/graph';
import { DateRangeConfig } from '../DateRangeConfig';

const TITLE_LENGTH_LIMIT = 255;
const TAG_NAME_LENGTH_LIMIT = 255;

export class Memo extends ImmutableRecord<{
  id: number;
  organizationId: number;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  title: string;
  description: string | null;
  createdBy: number | null;
  createAt: Dayjs | null;
  updateAt: Dayjs | null;
  tags: ImmutableList<MemoTag>;
}>({
  id: 0,
  organizationId: 0,
  startDate: null,
  endDate: null,
  title: '',
  description: null,
  createdBy: null,
  createAt: null,
  updateAt: null,
  tags: ImmutableList(),
}) {
  static fromJSON(data: ArrayElement<GetMemoResponse['items']>) {
    return new Memo({
      id: data.id,
      organizationId: data.organization_id,
      startDate: data.start_date ? dayjs(data.start_date) : null,
      endDate: data.end_date ? dayjs(data.end_date) : null,
      title: data.title,
      description: data.description,
      createdBy: data.created_by,
      createAt: data.create_at ? dayjs(data.create_at) : null,
      updateAt: data.update_at ? dayjs(data.update_at) : null,
      tags: ImmutableList(data.tags.map((item) => new MemoTag(item))),
    });
  }

  toUpdateParams() {
    return {
      start_date: this.startDate?.format('YYYY-MM-DDTHH:mm:ssZ') ?? null,
      end_date: this.endDate?.format('YYYY-MM-DDTHH:mm:ssZ') ?? null,
      title: this.title || '',
      description: this.description || '',
      tags: this.tags.map((tag) => ({ tag: tag.tag })).toArray(),
    };
  }

  isValid() {
    const validates = [
      this.validateStartDate(),
      this.validateEndDate(),
      this.validateTitle(),
      this.validateDescription(),
      this.validateTags(),
    ];
    return !validates.some((validate) => !validate.isValid);
  }

  validateStartDate() {
    if (!this.startDate) {
      return { isValid: false, error: '開始日は必須です' };
    }
    return { isValid: true, error: '' };
  }

  validateEndDate() {
    if (this.startDate && this.endDate && this.endDate.isBefore(this.startDate)) {
      return { isValid: false, error: '終了日は開始日より前を指定してください' };
    }
    return { isValid: true, error: '' };
  }

  validateTitle() {
    if (this.title.length === 0) {
      return { isValid: false, error: 'タイトルは必須です' };
    }
    if (this.title.length > TITLE_LENGTH_LIMIT) {
      return { isValid: false, error: `タイトルの上限は${TITLE_LENGTH_LIMIT}文字までです` };
    }
    return { isValid: true, error: '' };
  }

  validateDescription() {
    return { isValid: true, error: '' };
  }

  validateTags() {
    if (this.tags.some((tag) => tag.tag.length > TAG_NAME_LENGTH_LIMIT)) {
      return { isValid: false, error: `それぞれのタグの上限は${TAG_NAME_LENGTH_LIMIT}文字までです` };
    }
    return { isValid: true, error: '' };
  }
}

export class MemoTag extends ImmutableRecord<{
  id?: number;
  tag: string;
  count?: number;
}>({
  id: undefined,
  tag: '',
  count: undefined,
}) {
  static fromJSON(data: ArrayElement<GetMemoTagResponse['items']>) {
    return new MemoTag({ ...data.tag, count: data.count });
  }
}

export class MemoList extends ImmutableRecord<{ items: ImmutableList<Memo> }>({ items: ImmutableList() }) {
  static fromJSON(data: GetMemoResponse) {
    return new MemoList({
      items: ImmutableList(data.items.map((item) => Memo.fromJSON(item))),
    });
  }

  /**
   * タグでフィルタリングしたメモのリストを返す
   */
  filterByTags(tags: ImmutableSet<string>) {
    // 何も指定されていない場合はfilterしない
    if (tags.isEmpty()) {
      return this;
    }
    return this.update('items', (items) =>
      items.filter((item) => {
        const tagsSet = item.tags.map((tag) => tag.tag).toSet();
        const intersection = tagsSet.intersect(tags);
        return intersection.size > 0;
      }),
    );
  }

  /**
   * 開始日と終了日でフィルタリングしたメモのリストを返す
   */
  filterByDates(startDate: Dayjs, endDate: Dayjs, aggregateUnit: AggregateUnit) {
    return this.update('items', (items) =>
      items.filter((item) => {
        let _startDate = startDate;
        let _endDate = endDate;
        if (aggregateUnit === 'day') {
          // 何もしない
        } else if (aggregateUnit === 'week') {
          _startDate = getCurrentWeekStartDay(startDate); // 直前の月曜日
          _endDate = getCurrentWeekEndDay(endDate); // 直後の月曜日
        } else if (aggregateUnit === 'month') {
          _startDate = startDate.startOf('month'); // 月初
          _endDate = endDate.endOf('month'); // 月末
        }
        // 開始日または終了日が期間内に含まれているメモを返す
        return item.endDate
          ? // 終了日がある場合は、開始日が集計期間の終了日より前かつ終了日が集計期間の開始日より後であること
            item.startDate?.isSameOrBefore(_endDate, 'day') && item.endDate?.isSameOrAfter(_startDate, 'day')
          : // 終了日がない場合は、開始日が集計期間に含まれること
            item.startDate?.isBetween(_startDate, _endDate, 'day', '[]');
      }),
    );
  }

  filterByMemoDisplaySettings(memoDisplaySettings: MemoDisplaySettings) {
    return this.filterByTags(memoDisplaySettings.tags.toSet());
  }

  filterByDateRangeConfig(dateRangeConfig: DateRangeConfig) {
    const { targetPeriod, oldestDate, latestDate, aggregateUnit } = dateRangeConfig;
    // 対象期間の指定がない場合は絞り込まない
    if (!targetPeriod) {
      return this;
    }
    // 集計期間と比較期間が連続している場合は、比較期間も含める
    const containsComparison = dateRangeConfig.isContinuousPeriod;
    return this.filterByDates(
      containsComparison && oldestDate ? oldestDate : targetPeriod.start,
      containsComparison && latestDate ? latestDate : targetPeriod.end,
      aggregateUnit,
    );
  }

  displayMemoList(memoDisplaySettings: MemoDisplaySettings, dateRangeConfig: DateRangeConfig) {
    return this.filterByMemoDisplaySettings(memoDisplaySettings).filterByDateRangeConfig(dateRangeConfig);
  }
}

export class MemoTagList extends ImmutableRecord<{ items: ImmutableList<MemoTag> }>({ items: ImmutableList() }) {
  static fromJSON(data: GetMemoTagResponse) {
    return new MemoTagList({
      items: ImmutableList(data.items.map((item) => MemoTag.fromJSON(item))),
    });
  }
}
