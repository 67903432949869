import { Record as ImmutableRecord } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
  GbpPerformanceGraphData,
  GbpPerformanceMonthlyData,
  GbpPerformanceTableData,
} from 'models/Domain/GbpPerformance/GbpPerformance';
import { GbpPerformanceCsvDownloadCondition } from 'models/Domain/GbpPerformance/GbpPerformanceCsvDownloadCondition';
import { GbpPerformanceSearchCondition } from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';

import { GbpPerformanceActions } from './actions';

export class GbpPerformanceState extends ImmutableRecord<{
  isLoadingGraphData: boolean;
  isLoadingTableData: boolean;
  isLoadingMonthlyData: boolean;
  searchCondition: GbpPerformanceSearchCondition;
  isPreparedPage: boolean;
  isInitializedSearchCondition: boolean;
  graphData: GbpPerformanceGraphData;
  tableData: GbpPerformanceTableData;
  monthlyData: GbpPerformanceMonthlyData;
  csvDownloadCondition: GbpPerformanceCsvDownloadCondition;
}>({
  isLoadingGraphData: true,
  isLoadingTableData: true,
  isLoadingMonthlyData: true,
  searchCondition: new GbpPerformanceSearchCondition(),
  isPreparedPage: false,
  isInitializedSearchCondition: false,
  graphData: new GbpPerformanceGraphData(),
  tableData: new GbpPerformanceTableData(),
  monthlyData: new GbpPerformanceMonthlyData(),
  csvDownloadCondition: new GbpPerformanceCsvDownloadCondition(),
}) {}

export const gbpPerformanceReducer = reducerWithInitialState(new GbpPerformanceState())
  .case(GbpPerformanceActions.setIsLoading, (state, payload) => {
    return state.merge({
      // FIXME: payloadの値がundefinedの場合は無視されるはず
      // なぜかmerge後の値が意図しない値に変更されることがあったので、undefinedの場合はstateの値をセット
      // isLoadingGraphData: payload.graphData,
      // isLoadingTableData: payload.tableData,
      // isLoadingMonthlyData: payload.monthlyData,
      isLoadingGraphData: payload.graphData ?? state.isLoadingGraphData,
      isLoadingTableData: payload.tableData ?? state.isLoadingTableData,
      isLoadingMonthlyData: payload.monthlyData ?? state.isLoadingMonthlyData,
    });
  })
  .case(GbpPerformanceActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(GbpPerformanceActions.setIsPreparedPage, (state, payload) => {
    return state.set('isPreparedPage', payload);
  })
  .case(GbpPerformanceActions.setIsInitializedSearchCondition, (state, payload) => {
    return state.set('isInitializedSearchCondition', payload);
  })
  .case(GbpPerformanceActions.setCsvDownloadCondition, (state, payload) => {
    return state.set('csvDownloadCondition', payload);
  })
  .case(GbpPerformanceActions.setGraphAndTableData, (state, payload) => {
    return state.merge(payload);
  })
  .case(GbpPerformanceActions.setMonthlyData, (state, payload) => {
    return state.merge(payload);
  });
