import React from 'react';

import { List } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { AttributesForm } from 'components/molecules/GmbAttributes/AttributesForm';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes } from 'models/Domain/GmbLocation/GmbAttributes';

export type EditSpecialHoursModalProps = {
  open: boolean;
  gmbAttributes: GmbAttributes;
  attributeMetadatas: GmbAttributeMetadatas;
  categoryName: string;
  individualUpdate: boolean;
  onChange: (gmbAttributes: GmbAttributes) => void;
  targetAttributeIds: List<string>;
  setTargetAttributeIds: (attributeId: string) => void;
  onApply: () => void;
  onClose: () => void;
};
export const BulkEditAttributesModal: React.FC<EditSpecialHoursModalProps> = ({
  open,
  gmbAttributes,
  attributeMetadatas,
  categoryName,
  individualUpdate,
  targetAttributeIds,
  onChange,
  onApply,
  onClose,
  setTargetAttributeIds,
}) => {
  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>属性情報の変更</Modal.Header>
      <Modal.Content scrolling>
        {individualUpdate ? (
          <Information>
            チェックされた項目を一括で反映します。
            <br />
            カテゴリーに対応した属性のみ反映されます。
          </Information>
        ) : (
          <div>対象メインカテゴリー：{categoryName}</div>
        )}
        {attributeMetadatas && (
          <AttributesForm
            attributes={gmbAttributes}
            individualUpdate={individualUpdate}
            attributeMetadatas={attributeMetadatas}
            setAttributes={onChange}
            targetAttributeIds={targetAttributeIds}
            setTargetAttributeIds={setTargetAttributeIds}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <ButtonWrapper>
          <CloseButton onClick={onClose}>キャンセル</CloseButton>
          <ApplyButton priority='high' onClick={onApply}>
            入力
          </ApplyButton>
        </ButtonWrapper>
      </Modal.Actions>
    </Modal>
  );
};

const Information = styled.div`
  font-size: 14px;
  line-height: 1.8em;
`;

const ButtonCommonStyle = css`
  width: fit-content;
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
    margin-left: 20px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;
