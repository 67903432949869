import React, { useEffect, useMemo } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { StoreIndexListContents } from 'components/pageComponents/StoreIndex/StoreIndexListContents';
import { StoreIndexTableContents } from 'components/pageComponents/StoreIndex/StoreIndexTableContents';
import { MainWrapper } from 'components/templates/MainWrapper';
import { BulkEditStoreState } from 'models/Other/BulkEditStoreState';
import { StoreActions } from 'modules/store/actions';

type Props = RouteComponentProps<{ storeId?: string }>;

export const Store = React.memo<Props>(
  ({
    match: {
      params: { storeId },
    },
  }) => {
    const dispatch = useDispatch();
    const { stores, storeLists, bulkEditStoreState, currentUser, gmbLocationDiffs, searchCondition } = useSelector(
      (state) => ({
        stores: state.store.stores,
        storeLists: state.storeList.storeLists,
        bulkEditStoreState: state.store.bulkEditStoreState,
        currentUser: state.app.currentUser,
        gmbLocationDiffs: state.app.gmbLocationDiffs,
        searchCondition: state.store.searchCondition,
      }),
      shallowEqual,
    );

    const { initializeStorePage, setSearchCondition, setBulkEditStoreState } = useMemo(
      () => bindActionCreators(StoreActions, dispatch),
      [dispatch],
    );

    useEffect(() => {
      if (storeLists.initialized) {
        initializeStorePage();
      }
    }, [storeLists, initializeStorePage, storeId]);

    // ページ遷移時（componentがmountされる時）に BulkEditStoreState を初期化
    React.useEffect(() => {
      setBulkEditStoreState(new BulkEditStoreState());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showCsvImportButton = currentUser.canUseCSVImport;

    // 有効なstoreIdをURLに持つ場合は、店舗詳細を表示する
    const selectedStore = storeId ? stores.findStore(storeId) : undefined;
    const showStoreDetail = selectedStore != undefined;

    return (
      <Wrapper>
        {showStoreDetail ? (
          <StoreIndexListContents
            stores={stores}
            storeLists={storeLists}
            bulkEditStoreState={bulkEditStoreState}
            setBulkEditStoreState={setBulkEditStoreState}
            displayManagedList={currentUser.isMemberUser || currentUser.isSvUser}
            managedStores={currentUser.isMemberUser ? currentUser.stores : currentUser.managing_stores}
            searchCondition={searchCondition}
            setSearchCondition={setSearchCondition}
            storeId={storeId ? Number(storeId) : undefined}
          />
        ) : (
          <StoreIndexTableContents
            stores={stores}
            storeLists={storeLists}
            bulkEditStoreState={bulkEditStoreState}
            gmbLocationDiffs={gmbLocationDiffs}
            setBulkEditStoreState={setBulkEditStoreState}
            canSelectStore={!currentUser.isMemberUser && !currentUser.isSvUser}
            displayManagedList={currentUser.isMemberUser || currentUser.isSvUser}
            managedStores={currentUser.isMemberUser ? currentUser.stores : currentUser.managing_stores}
            searchCondition={searchCondition}
            setSearchCondition={setSearchCondition}
            showCsvImportButton={showCsvImportButton}
          />
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled(MainWrapper)`
  /* フッター表示用の調整 */
  min-height: 100%;
  padding-bottom: 0;
`;
