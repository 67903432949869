import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

export const MAX_HISTORIES = 100;

export class SearchHistory extends Record<{
  id: string;
  areaNames: List<string>;
  searchWords: List<string>;
  date: Dayjs;
}>({
  id: '',
  areaNames: List(),
  searchWords: List(),
  date: dayjs(),
}) {
  static fromJSON(data: JSObject) {
    return new SearchHistory({
      id: data.id,
      areaNames: List(data.areaNames),
      searchWords: List(data.searchWords),
      date: dayjs(data.date),
    });
  }
}

export class SearchHistoryList extends Record<{
  histories: List<SearchHistory>;
}>({ histories: List() }) {
  static fromJSON(data: JSObject) {
    return new SearchHistoryList({
      histories: List((data.histories || []).map((h: JSObject) => SearchHistory.fromJSON(h))),
    });
  }

  toJSON() {
    return this.toJS();
  }

  push(history: SearchHistory) {
    // 既に存在しているならば特に何もしない
    if (this.histories.find((h) => h.id === history.id) !== undefined) {
      return this;
    }

    // 追加して検索日時順に降順ソート
    return this.set(
      'histories',
      this.histories
        .push(history)
        .sort((a, b) => (a.date.isAfter(b.date) ? -1 : 1))
        .slice(0, MAX_HISTORIES),
    );
  }

  delete(id: string) {
    return this.set(
      'histories',
      this.histories.filter((h) => h.id !== id),
    );
  }
}
