import React, { useMemo } from 'react';

import dayjs from 'dayjs';
import { Set } from 'immutable';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { DropdownMenu, DropdownMenuItem } from 'components/molecules/DropdownMenu';
import { Paging } from 'components/molecules/Paging';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { SearchKeywordHelp as Help } from 'helpers/ContextHelp';
import { convertRateToText } from 'helpers/utils';
import { SearchKeywordTableData, getGrowthRate, getMonthLabel } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SortKey, SortOrder } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

interface Props {
  className?: string;
  tableData: SearchKeywordTableData;
  sortKey: SortKey;
  sortOrder: SortOrder;
  onChangeSort: (sortKey: SortKey) => void;
  onChangePage: (page: number) => void;
  onChangeSelectedMonth: (month: string) => void;
  openExcludedKeywordCreateModal: (keyword: string) => void;
  openExcludeKeywordsConfig: () => void;
  isLoading?: boolean;
  excludes?: Set<string>;
  displaySearchVolumeLink: boolean;
  onClickSummary: () => void;
  onClickKeyword: (keyword: string) => void;
}

const convertNumberToText = (value: number | null): string => {
  // 値を3桁ごとにカンマ区切りで表示、nullならば「ー」
  return value != null ? Math.abs(value).toLocaleString() : 'ー';
};

export const SearchKeywordsTable: React.FC<Props> = ({
  className,
  tableData,
  sortKey,
  sortOrder,
  onChangeSort,
  onChangePage,
  onChangeSelectedMonth,
  openExcludedKeywordCreateModal,
  openExcludeKeywordsConfig,
  isLoading = false,
  excludes = Set(),
  displaySearchVolumeLink,
  onClickSummary,
  onClickKeyword,
}) => {
  const {
    startMonth,
    endMonth,
    targetMonth,
    items,
    pagination: { current_page: page, total_count: totalCount, limit },
  } = tableData;

  /**
   * 表示月選択のオプション
   */
  const monthOptions = useMemo(() => {
    // startMonthからendMonthまでの月をオプションとして返す
    // ex. startMonth: 2021-10, endMonth: 2022-03 の場合は、
    // 2021年10月、2021年11月、2021年12月、2022年1月、2022年2月、2022年3月
    if (!startMonth || !endMonth) {
      return [];
    }
    const startDate = dayjs(startMonth, 'YYYY-MM');
    const endDate = dayjs(endMonth, 'YYYY-MM');
    const diff = endDate.diff(startDate, 'month');

    // startDateから1ずつ大きくした分だけmonthを増やしてオプションを生成
    return [...Array(diff + 1)].map((_, i) => {
      const date = startDate.add(i, 'month');
      return { text: date.format('YYYY年M月'), value: date.format('YYYY-MM') };
    });
  }, [startMonth, endMonth]);

  return (
    <Wrapper className={className}>
      <ConditionWrapper>
        <TextWrapper>
          <CountText>
            {`${totalCount.toLocaleString()}件のキーワード`}
            {totalCount > 0 && (
              <>
                （{Math.min(limit * (page - 1) + 1, totalCount).toLocaleString()}〜
                {Math.min(limit * page, totalCount).toLocaleString()}
                件目を表示中）
              </>
            )}
          </CountText>
          <TargetText>{`集計対象：${getMonthLabel(startMonth)}${
            startMonth == endMonth ? '' : `〜${getMonthLabel(endMonth)}`
          }`}</TargetText>
        </TextWrapper>
        <PullDownWrapper>
          <PullDownLabel>表示月</PullDownLabel>
          <StyledPullDown
            value={targetMonth}
            options={monthOptions}
            onChange={onChangeSelectedMonth}
            disabled={isLoading}
          />
        </PullDownWrapper>
      </ConditionWrapper>
      <TableWrapper>
        <InnerTable
          tableData={tableData}
          sortKey={sortKey}
          sortOrder={sortOrder}
          onChangeSort={onChangeSort}
          openExcludedKeywordCreateModal={openExcludedKeywordCreateModal}
          openExcludeKeywordsConfig={openExcludeKeywordsConfig}
          excludes={excludes}
          displaySearchVolumeLink={displaySearchVolumeLink}
          onClickSummary={onClickSummary}
          onClickKeyword={onClickKeyword}
        ></InnerTable>
        {/* キーワードが0件かつ、読み込み中でない場合のメッセージ */}
        {items.size === 0 && !isLoading && (
          <Message>
            <MessageTitle>条件に一致するキーワードが見つかりませんでした</MessageTitle>
            <MessageDetail>条件を変更して検索してください</MessageDetail>
          </Message>
        )}
        {/* キーワードが0件かつ、読み込み中の場合にLoader表示用のスペースを追加 */}
        {items.size === 0 && isLoading && <LoaderContainer />}
        {isLoading && (
          <>
            <LoadingWrapper>
              <Loader active={isLoading} size='big' inline={true} />
            </LoadingWrapper>
          </>
        )}
      </TableWrapper>
      <Paging currentPage={page} viewContentSize={limit} totalContentSize={totalCount} onChangeNo={onChangePage} />
    </Wrapper>
  );
};

interface InnerTableProps {
  tableData: SearchKeywordTableData;
  sortKey: SortKey;
  sortOrder: SortOrder;
  onChangeSort: (sortKey: SortKey) => void;
  openExcludedKeywordCreateModal: (keyword: string) => void;
  openExcludeKeywordsConfig: () => void;
  excludes?: Set<string>;
  displaySearchVolumeLink: boolean;
  onClickSummary: () => void;
  onClickKeyword: (keyword: string) => void;
}

const InnerTable: React.VFC<InnerTableProps> = React.memo(
  ({
    tableData,
    sortKey,
    sortOrder,
    onChangeSort,
    openExcludedKeywordCreateModal,
    openExcludeKeywordsConfig,
    excludes = Set(),
    displaySearchVolumeLink,
    onClickSummary,
    onClickKeyword,
  }) => {
    const { targetMonth, items, summary, startMonth, endMonth } = tableData;

    const summaryMomGrowthRate = summary && getGrowthRate(summary.momRatio);
    const summaryThreeMomGrowthRate = summary && getGrowthRate(summary.threeMomRatio);
    const summaryYoyGrowthRate = summary && getGrowthRate(summary.yoyRatio);

    return (
      <StyledTable>
        <StyledTableHeader>
          <TableHeaderRow>
            <StyledTableHeaderCell weight={1} style={{ textAlign: 'left' }}>
              キーワード
            </StyledTableHeaderCell>
            <TargetMonthHeaderCell onClick={() => onChangeSort('avg')}>
              <FlexWrapper>
                {sortKey === 'avg' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                月間平均検索数
                <ContextHelp content={Help.avg} />
              </FlexWrapper>
            </TargetMonthHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('count')}>
              <FlexWrapper>
                {sortKey === 'count' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                {targetMonth ? getMonthLabel(targetMonth) : '表示月'}
                <ContextHelp content={Help.count} />
              </FlexWrapper>
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('mom_diff')}>
              <FlexWrapper>
                {sortKey === 'mom_diff' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                前月差
                <ContextHelp content={Help.momDiff} />
              </FlexWrapper>
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('mom_ratio')}>
              <FlexWrapper>
                {sortKey === 'mom_ratio' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                前月比
                <ContextHelp content={Help.momRatio} />
              </FlexWrapper>
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('three_mom_ratio')}>
              <FlexWrapper>
                {sortKey === 'three_mom_ratio' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                3ヶ月前比
                <ContextHelp content={Help.threeMomRatio} />
              </FlexWrapper>
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => onChangeSort('yoy_ratio')}>
              <FlexWrapper>
                {sortKey === 'yoy_ratio' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                前年同月比
                <ContextHelp content={Help.yoyRatio} />
              </FlexWrapper>
            </StyledSortableTableHeaderCell>
          </TableHeaderRow>
        </StyledTableHeader>
        <TableBody>
          {summary && (
            <SummaryTableRow key={'status'} onClick={onClickSummary}>
              <StyledSummaryTableCell style={{ textAlign: 'left' }}>合計</StyledSummaryTableCell>
              <StyledTableCell>
                <MobileOnlyLabel>月間平均検索数</MobileOnlyLabel>
                <NumberValue>{convertNumberToText(summary.monthlyAverageCount)}</NumberValue>
              </StyledTableCell>
              <StyledTableCell>
                <MobileOnlyLabel>{targetMonth ? getMonthLabel(targetMonth) : '表示月'}</MobileOnlyLabel>
                <NumberValue>{convertNumberToText(summary.targetMonthCount)}</NumberValue>
              </StyledTableCell>
              <StyledTableCell>
                <MobileOnlyLabel>前月差</MobileOnlyLabel>
                <Diff value={summary.momDiff}>{convertNumberToText(summary.momDiff)}</Diff>
              </StyledTableCell>
              <StyledTableCell>
                <MobileOnlyLabel>前月比</MobileOnlyLabel>
                <Diff value={summaryMomGrowthRate}>{convertRateToText(summaryMomGrowthRate)}</Diff>
              </StyledTableCell>
              <StyledTableCell>
                <MobileOnlyLabel>3ヶ月前比</MobileOnlyLabel>
                <Diff value={summaryThreeMomGrowthRate}>{convertRateToText(summaryThreeMomGrowthRate)}</Diff>
              </StyledTableCell>
              <StyledTableCell>
                <MobileOnlyLabel>前年同月比</MobileOnlyLabel>
                <Diff value={summaryYoyGrowthRate}>{convertRateToText(summaryYoyGrowthRate)}</Diff>
              </StyledTableCell>
            </SummaryTableRow>
          )}
          {items.map((item, i) => {
            const { keyword, value } = item;
            const { monthlyAverageCount, monthlyCountSeries, targetMonthCount } = value;
            const momDiff = value.getMomDiff();
            const momGrowthRatio = value.getMomGrowthRate();
            const threeMomGrowthRatio = value.getThreeMomGrowthRate();
            const yoyGrowthRatio = value.getYoyGrowthRate();

            const searchParams = new URLSearchParams();
            searchParams.set('sw', keyword);
            searchParams.set('sm', startMonth);
            searchParams.set('em', endMonth);

            const dropdownMenuItems: DropdownMenuItem[] = [];
            if (displaySearchVolumeLink) {
              dropdownMenuItems.push({
                label: '検索ボリューム',
                link: `${Path.searchVolume.index}?${searchParams.toString()}`,
              });
            }
            if (!excludes.contains(keyword)) {
              dropdownMenuItems.push({
                label: '除外リストに追加する',
                onClick: () => openExcludedKeywordCreateModal(keyword),
              });
            } else {
              dropdownMenuItems.push({
                label: '除外リストを確認する',
                onClick: openExcludeKeywordsConfig,
              });
            }
            return (
              <StyledTableRow key={i}>
                <StyledTableCell style={{ textAlign: 'left' }}>
                  <KeywordWrapper>
                    <StyledKeywordText
                      onClick={() => {
                        onClickKeyword(keyword);
                      }}
                    >
                      {keyword}
                    </StyledKeywordText>
                    <DropdownMenu
                      trigger={<>{excludes.contains(keyword) ? <Excluded /> : <StyledIcon type='settings' />}</>}
                      items={dropdownMenuItems}
                    />
                  </KeywordWrapper>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>月間平均検索数</MobileOnlyLabel>
                  <NumberValue>{convertNumberToText(monthlyAverageCount)}</NumberValue>
                  <SparklinesWrapper>
                    <Sparklines
                      style={{ verticalAlign: 'middle' }}
                      data={monthlyCountSeries.toArray()}
                      svgWidth={60}
                      svgHeight={30}
                      min={0}
                    >
                      <SparklinesLine
                        style={{ strokeWidth: 3, stroke: '#458a6a', fill: '#E2F3EB', fillOpacity: 0.4 }}
                      />
                      <SparklinesReferenceLine type={'avg'} />
                    </Sparklines>
                  </SparklinesWrapper>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>{targetMonth ? getMonthLabel(targetMonth) : '表示月'}</MobileOnlyLabel>
                  <NumberValue>{convertNumberToText(targetMonthCount ?? 0)}</NumberValue>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>前月差</MobileOnlyLabel>
                  <Diff value={momDiff}>{convertNumberToText(momDiff)}</Diff>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>前月比</MobileOnlyLabel>
                  <Diff value={momGrowthRatio}>{convertRateToText(momGrowthRatio)}</Diff>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>3ヶ月前比</MobileOnlyLabel>
                  <Diff value={threeMomGrowthRatio}>{convertRateToText(threeMomGrowthRatio)}</Diff>
                </StyledTableCell>
                <StyledTableCell>
                  <MobileOnlyLabel>前年同月比</MobileOnlyLabel>
                  <Diff value={yoyGrowthRatio}>{convertRateToText(yoyGrowthRatio)}</Diff>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    );
  },
);

const Wrapper = styled.div``;

const ConditionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  gap: 8px;
`;

const TextWrapper = styled.div``;

const CountText = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 13px;
  font-weight: bold;
`;

const TargetText = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 12px;
  font-weight: bold;
`;

const PullDownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PullDownLabel = styled.div`
  font-size: 13px;
  margin-right: 8px;
`;

const StyledPullDown = styled(PullDownNarrow)`
  width: 176px;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  position: relative;
`;

const StyledTable = styled(Table)`
  &&& {
    border-collapse: separate;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 71px;
    z-index: 1;
  }
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    text-align: right;
    min-width: 240px;
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)`
  &&& {
    max-width: 130px;
    min-width: 130px;
    text-align: right;
  }
`;

const TargetMonthHeaderCell = styled(StyledSortableTableHeaderCell)`
  &&& {
    max-width: 160px;
    min-width: 160px;
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    font-weight: bold;
    @media (min-width: ${SIZE.MOBILE_TABLE}) {
      text-align: right;
    }
  }
`;

const StyledSummaryTableCell = styled(StyledTableCell)`
  :hover {
    text-decoration: underline;
  }
`;

const SummaryTableRow = styled(TableRow)`
  &&& {
    background: #f9fafb;
    ${TableCell} {
      border-bottom: 1px solid ${COLOR.LIGHT_GRAY};
    }
  }

  :hover {
    cursor: pointer;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  @media (min-width: ${SIZE.MOBILE_TABLE}) {
    justify-content: flex-end;
  }
`;

const NumberValue = styled.span`
  font-family: monospace;
  font-weight: normal;
`;

// valueが0より大きければ「↑」をつけて緑色、0より小さければ「↓」をつけて赤色、変化がなければ灰色で表示する
const Diff = styled(NumberValue).attrs<{ value: number | null }, { isUp: boolean; isDown: boolean }>(({ value }) => ({
  isUp: value != null && value > 0,
  isDown: value != null && value < 0,
}))<{
  value: number | null;
}>`
  color: ${({ isUp, isDown }) => (isUp ? COLOR.GREEN : isDown ? COLOR.RED : COLOR.DARK_GRAY)};
  &:before {
    content: '${({ isUp, isDown }) => (isUp ? '↑' : isDown ? '↓' : '')}';
    margin-right: 4px;
  }
`;

const KeywordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    text-decoration: underline;
  }
`;

const StyledKeywordText = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const SparklinesWrapper = styled.div`
  display: inline-block;
  width: 60px;
  margin-left: 8px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const MobileOnlyLabel = styled.div`
  display: inline-block;
  width: 100%;
  font-weight: bold;

  @media (min-width: ${SIZE.MOBILE_TABLE}) {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  height: 200px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 200px;
`;
const MessageTitle = styled.div`
  font-weight: bold;
  color: ${COLOR.BLACK};
`;
const MessageDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
`;

const StyledTableRow = styled(TableRow)`
  ${StyledIcon} {
    opacity: 0;
  }
  &:hover {
    ${StyledIcon} {
      opacity: 1;
    }
  }
`;

const Excluded = styled.div`
  flex-shrink: 0;
  ::after {
    content: '除外対象';
    display: block;
    font-size: 0.8em;
    padding: 2px 4px;
    border: 1px solid ${COLOR.GRAY};
    color: ${COLOR.BLACK};
  }
`;
