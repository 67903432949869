import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { extraceHashtag } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { FileContents } from './FileContent';

interface CommonContentRecord {
  id: number;
  text: string;
  files: FileContents;
  tags: List<string>;
  created_by: number;
}

export class CommonContent extends Record<CommonContentRecord>({
  id: 0,
  text: '',
  files: new FileContents(),
  tags: List(),
  created_by: 0,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.files) {
      params.files = new FileContents(params.files);
    }
    super(params);
  }

  get provisionalHashtag() {
    let tags: string[] = [];
    tags = tags.concat(extraceHashtag(this.text));
    return tags;
  }

  getLabel(key: 'text' | 'files' | 'tags' | 'fileText') {
    switch (key) {
      case 'text':
        return '説明';
      case 'files':
        return '添付ファイル';
      case 'tags':
        return 'ハッシュタグ';
      case 'fileText':
        return '添付ファイルの補足';
      default:
        return '';
    }
  }

  changeText(text: string) {
    return this.set('text', text);
  }

  addFiles(urls: string[]) {
    return this.update('files', (files) => files.addFiles(urls));
  }

  deleteFile(index: number) {
    return this.update('files', (files) => files.deleteFile(index));
  }

  changeFileCaption(index: number, caption: string) {
    return this.update('files', (files) => files.changeFileCaption(index, caption));
  }

  changeTags(tags: string[]) {
    return this.set('tags', List(tags));
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  validate() {
    const errors: JSObject = {};

    if (!this.text && this.files.list.size === 0) {
      errors.text = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }

  requestParams() {
    const { id, text, tags } = this.toObject();
    const params: JSObject = { text, files: this.files.requestParams(), tags: tags.toArray() };
    // 編集の場合は、idを含めて送信
    if (id !== 0) {
      params.id = id;
    }
    return params;
  }
}
