import React from 'react';

import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { NotificationType, User } from 'models/Domain/User';

export const NotificationEdit = React.memo<{
  onChange: (type: NotificationType) => void;
  currentUser: User;
}>(({ onChange, currentUser }) => {
  return (
    <div>
      <Wrapper>
        <ContentWrapper>
          <FlexWrapper>
            <div>
              <Title>クチコミ</Title>
              <Content>Googleビジネスプロフィールにクチコミが投稿された際に通知が届きます。</Content>
            </div>
            <CheckboxWrapper>
              <Checkbox
                onChange={() => onChange('notify_review_update')}
                checked={currentUser.notify_review_update}
                toggle={true}
              />
            </CheckboxWrapper>
          </FlexWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <FlexWrapper>
            <div>
              <Title>依頼報告</Title>
              <Content>依頼報告が作成・更新された際に通知が届きます。</Content>
            </div>
            <CheckboxWrapper>
              <Checkbox
                onChange={() => onChange('notify_offer_update')}
                checked={currentUser.notify_offer_update}
                toggle={true}
              />
            </CheckboxWrapper>
          </FlexWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <FlexWrapper>
            <div>
              <Title>店舗情報</Title>
              <Content>店舗情報が変更された際に通知が届きます。</Content>
            </div>
            <CheckboxWrapper>
              <Checkbox
                onChange={() => onChange('notify_location_update')}
                checked={currentUser.notify_location_update}
                toggle={true}
              />
            </CheckboxWrapper>
          </FlexWrapper>
        </ContentWrapper>
      </Wrapper>
    </div>
  );
});

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  margin-top: 16px;
`;

const ContentWrapper = styled.div`
  margin-top: 23px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

const Content = styled.div`
  margin-top: 7px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;
