import { List, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import CheckedImageList from 'models/Domain/CheckedImageList';
import { GbpImageCategory, ImageList } from 'models/Domain/Image/Image';
import ImageDetail from 'models/Domain/Image/ImageDetail';
import ImageSearchCondition from 'models/Domain/Image/ImageSearchCondition';
import { Pagination } from 'models/Domain/Pagination';

import { ImageActions } from './actions';

export interface StateRecord {
  hashtagList: List<string>;
  detailImage: ImageDetail;
  imageListForCreate: ImageList;
  editHashtagList: List<string>;
  editCategory: GbpImageCategory | null;

  // 画像一覧ページ向け
  isLoadingImageList: boolean;
  isPreparedForImageIndex: boolean;
  imageList: ImageList;
  pagination: Pagination;
  searchCondition: ImageSearchCondition;
  committedSearchCondition: ImageSearchCondition;
  checkedImageList: CheckedImageList;
}

export class ImageState extends Record<StateRecord>({
  hashtagList: List(),
  detailImage: new ImageDetail(),
  imageListForCreate: new ImageList(),
  editHashtagList: List(),
  editCategory: null,
  isLoadingImageList: false,
  isPreparedForImageIndex: false,
  imageList: new ImageList(),
  pagination: new Pagination(),
  searchCondition: new ImageSearchCondition(),
  committedSearchCondition: new ImageSearchCondition(),
  checkedImageList: new CheckedImageList(),
}) {}

export const imageReducer = reducerWithInitialState(new ImageState())
  .case(ImageActions.setHashtagList, (state, data) => {
    const hashtagList = List(data);
    return state.set('hashtagList', hashtagList);
  })
  .case(ImageActions.setImage, (state, detailImage) => {
    return state.set('detailImage', new ImageDetail(detailImage));
  })
  .case(ImageActions.setImageListForCreate, (state, payload) => {
    return state.set('imageListForCreate', payload);
  })
  .case(ImageActions.setEditHashtagList, (state, payload) => {
    return state.set('editHashtagList', List(payload));
  })
  .case(ImageActions.setEditCategory, (state, payload) => {
    return state.set('editCategory', payload);
  })
  .case(ImageActions.setIsLoadingImageList, (state, payload) => {
    return state.set('isLoadingImageList', payload);
  })
  .case(ImageActions.setImageList, (state, data) => {
    return state.merge({
      imageList: ImageList.fromJSON(data.images),
      pagination: Pagination.fromJSON(data.pagination),
    });
  })
  .case(ImageActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload.searchCondition);
  })
  .case(ImageActions.setCommittedSearchCondition, (state, payload) => {
    return state.set('committedSearchCondition', payload);
  })
  .case(ImageActions.setIsPreparedForImageIndex, (state, payload) => {
    return state.set('isPreparedForImageIndex', payload);
  })
  .case(ImageActions.setCheckedImageList, (state, payload) => {
    return state.set('checkedImageList', payload);
  });
