import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import EditForm from 'components/pageComponents/StoreRegister/EditForm';
import { Store } from 'models/Domain/Store';

type Props = { registerStore: Store; changeRegisterStore: (v: Store) => void; registerSingleStore: () => void };

const SingleRegister: React.FC<Props> = ({ registerStore, changeRegisterStore, registerSingleStore }) => {
  return (
    <Wrapper>
      <EditForm store={registerStore} changeStore={changeRegisterStore} />

      <ButtonWrapper>
        <StyledButton onClick={() => registerSingleStore()} disabled={!registerStore.isValidForRegister}>
          登録
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    @media (max-width: 860px) {
      width: 100%;
    }
  }
`;

export default SingleRegister;
