import React, { useMemo } from 'react';

import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  SortTriangle,
  SortableTableHeaderCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from 'components/molecules/Table';
import { GbpPerformanceHelp } from 'helpers/ContextHelp';
import { ReviewType, SortKey, SortType } from 'models/Domain/GbpPerformance/GbpPerformance';

import { OpenableColumn, SortCondition } from '.';

type TableHeaderProps = {
  sortCondition: SortCondition;
  toggleSortKey: (key: SortKey, type?: SortType) => void;
};

type ActivityTableHeaderProps = {
  sortCondition: SortCondition;
  toggleSortKey: (key: SortKey, type?: SortType) => void;
  openedColumns: Set<OpenableColumn>;
  toggleOpenedColumn: (column: OpenableColumn) => void;
  reviewType: ReviewType;
};

export const OverviewTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('storeName')}>
          <Sort sortKey='storeName' sortCondition={sortCondition} />
          店舗名
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('storeCode')} border={2}>
          <Sort sortKey='storeCode' sortCondition={sortCondition} />
          店舗コード
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('businessImpressions')}>
          <Sort sortKey='businessImpressions' sortCondition={sortCondition} />
          ユーザー数
          <ContextHelp header={'ユーザー数'} content={GbpPerformanceHelp.businessImpressions} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('interactions', 'number')}>
          <Sort sortKey='interactions' sortType='number' sortCondition={sortCondition} />
          インタラクション数
          <ContextHelp header={'インタラクション数'} content={GbpPerformanceHelp.interactions} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('interactions', 'rate')}>
          <Sort sortKey='interactions' sortType='rate' sortCondition={sortCondition} />
          インタラクション率
          <ContextHelp header={'インタラクション率'} content={GbpPerformanceHelp.interactionsRate} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('imageCount', 'number')}>
          <Sort sortKey='imageCount' sortType='number' sortCondition={sortCondition} />
          写真追加数
          <ContextHelp header={'写真追加数'} content={GbpPerformanceHelp.imageCount} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionCount', 'number')}>
          <Sort sortKey='promotionCount' sortType='number' sortCondition={sortCondition} />
          投稿数
          <ContextHelp header={'投稿数'} content={GbpPerformanceHelp.promotionCount} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodReviews', 'number')}>
          <Sort sortKey='periodReviews' sortType='number' sortCondition={sortCondition} />
          クチコミ数
          <ContextHelp header={'クチコミ数'} content={GbpPerformanceHelp.reviews} />
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

export const ImpressionsTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('storeName')}>
          <Sort sortKey='storeName' sortCondition={sortCondition} />
          店舗名
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('storeCode')} border={2}>
          <Sort sortKey='storeCode' sortCondition={sortCondition} />
          店舗コード
        </StyledSortableTableHeaderCell>
        <StyledTableHeaderCell colSpan={1}>ユーザー数</StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={2}>Googleマップ</StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={2}>Google検索</StyledTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('interactions', 'number')}>
          <Sort sortKey='interactions' sortType='number' sortCondition={sortCondition} />
          インタラクション数
          <ContextHelp header={'インタラクション数'} content={GbpPerformanceHelp.interactions} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('interactions', 'rate')}>
          <Sort sortKey='interactions' sortType='rate' sortCondition={sortCondition} />
          インタラクション率
          <ContextHelp header={'インタラクション率'} content={GbpPerformanceHelp.interactionsRate} />
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('businessImpressions')}>
          <Sort sortKey='businessImpressions' sortCondition={sortCondition} />
          合計
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('businessImpressionsDesktopMaps')}>
          <Sort sortKey='businessImpressionsDesktopMaps' sortCondition={sortCondition} />
          パソコン
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('businessImpressionsMobileMaps')}>
          <Sort sortKey='businessImpressionsMobileMaps' sortCondition={sortCondition} />
          モバイル
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('businessImpressionsDesktopSearch')}>
          <Sort sortKey='businessImpressionsDesktopSearch' sortCondition={sortCondition} />
          パソコン
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('businessImpressionsMobileSearch')}>
          <Sort sortKey='businessImpressionsMobileSearch' sortCondition={sortCondition} />
          モバイル
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

export const InteractionTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('storeName')}>
          <Sort sortKey='storeName' sortCondition={sortCondition} />
          店舗名
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('storeCode')} border={2}>
          <Sort sortKey='storeCode' sortCondition={sortCondition} />
          店舗コード
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('businessImpressions')}>
          <Sort sortKey='businessImpressions' sortCondition={sortCondition} />
          ユーザー数
          <ContextHelp header={'ユーザー数'} content={GbpPerformanceHelp.businessImpressions} />
        </StyledSortableTableHeaderCell>
        <StyledTableHeaderCell colSpan={12}>インタラクション（数 / 率）</StyledTableHeaderCell>
      </TableHeaderRow>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('interactions')}>
          合計 {<MultiTypeSort sortKey='interactions' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('callClicks')}>
          通話 {<MultiTypeSort sortKey='callClicks' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('businessDirectionRequests')}>
          ルート {<MultiTypeSort sortKey='businessDirectionRequests' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('websiteClicks')}>
          ウェブサイトのクリック {<MultiTypeSort sortKey='websiteClicks' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('businessConversations')}>
          メッセージ {<MultiTypeSort sortKey='businessConversations' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('businessBookings')}>
          予約 {<MultiTypeSort sortKey='businessBookings' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

export const ActivityTableHeader: React.FC<ActivityTableHeaderProps> = ({
  sortCondition,
  toggleSortKey,
  openedColumns,
  toggleOpenedColumn,
  reviewType,
}) => {
  /** 投稿ヘッダー列でマージする列数 */
  const promotionColSpan = useMemo(() => {
    let colSpan = 2;
    if (openedColumns.has('promotionLive')) colSpan += 4;
    if (openedColumns.has('promotionRejected')) colSpan += 4;
    return colSpan;
  }, [openedColumns]);

  return (
    <StyledTableHeader>
      {/* 1行目 */}
      <TableHeaderRow>
        <StyledSortableTableHeaderCell rowSpan={3} onClick={() => toggleSortKey('storeName')}>
          <Sort sortKey='storeName' sortCondition={sortCondition} />
          店舗名
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={3} onClick={() => toggleSortKey('storeCode')} border={2}>
          <Sort sortKey='storeCode' sortCondition={sortCondition} />
          店舗コード
        </StyledSortableTableHeaderCell>
        <StyledTableHeaderCell rowSpan={2} colSpan={openedColumns.has('image') ? 5 : 1}>
          写真追加数
          <ContextHelp header={'写真追加数'} content={GbpPerformanceHelp.imageCount} />
          <OpenLabel opened={openedColumns.has('image')} onClick={() => toggleOpenedColumn('image')} />
        </StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={promotionColSpan}>
          投稿数
          <ContextHelp header={'投稿数'} content={GbpPerformanceHelp.promotionTotalCount} />
        </StyledTableHeaderCell>
        {reviewType === 'period' ? (
          <StyledTableHeaderCell rowSpan={2} colSpan={openedColumns.has('review') ? 3 : 1}>
            クチコミ数
            <ContextHelp header={'クチコミ数'} content={GbpPerformanceHelp.periodReviews} />
            <OpenLabel opened={openedColumns.has('review')} onClick={() => toggleOpenedColumn('review')} />
          </StyledTableHeaderCell>
        ) : (
          <StyledTableHeaderCell rowSpan={2} colSpan={openedColumns.has('review') ? 3 : 1}>
            クチコミ数（累計）
            <ContextHelp header={'クチコミ数（累計）'} content={GbpPerformanceHelp.totalReviews} />
            <OpenLabel opened={openedColumns.has('review')} onClick={() => toggleOpenedColumn('review')} />
          </StyledTableHeaderCell>
        )}
        <StyledTableHeaderCell rowSpan={2} colSpan={1}>
          返信
          <ContextHelp header={'返信'} content={GbpPerformanceHelp.periodReviewReplyCount} />
        </StyledTableHeaderCell>
        <StyledTableHeaderCell rowSpan={2} colSpan={2}>
          評価
        </StyledTableHeaderCell>
      </TableHeaderRow>

      {/* 2行目 */}
      <TableHeaderRow>
        <StyledTableHeaderCell colSpan={openedColumns.has('promotionLive') ? 5 : 1}>
          公開済み
          <ContextHelp header={'公開済み'} content={GbpPerformanceHelp.promotionCount} />
          <OpenLabel opened={openedColumns.has('promotionLive')} onClick={() => toggleOpenedColumn('promotionLive')} />
        </StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={openedColumns.has('promotionRejected') ? 5 : 1}>
          公開拒否
          <ContextHelp header={'公開拒否'} content={GbpPerformanceHelp.promotionRejectedCount} />
          <OpenLabel
            opened={openedColumns.has('promotionRejected')}
            onClick={() => toggleOpenedColumn('promotionRejected')}
          />
        </StyledTableHeaderCell>
      </TableHeaderRow>

      {/* 3行目 */}
      <TableHeaderRow>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('imageCount')}>
          合計
          <Sort sortKey='imageCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        {openedColumns.has('image') && (
          <>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('imageInteriorCount')}>
              店内
              <Sort sortKey='imageInteriorCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('imageExteriorCount')}>
              外観
              <Sort sortKey='imageExteriorCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('imageProductCount')}>
              商品
              <Sort sortKey='imageProductCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('imageAdditionalCount')}>
              その他
              <Sort sortKey='imageAdditionalCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
          </>
        )}
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionCount')}>
          合計
          <Sort sortKey='promotionCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        {openedColumns.has('promotionLive') && (
          <>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionStandardCount')}>
              最新情報
              <Sort sortKey='promotionStandardCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionEventCount')}>
              イベント
              <Sort sortKey='promotionEventCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionOfferCount')}>
              特典
              <Sort sortKey='promotionOfferCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionAlertCount')}>
              その他
              <Sort sortKey='promotionAlertCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
          </>
        )}
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionRejectedCount')}>
          合計
          <Sort sortKey='promotionRejectedCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        {openedColumns.has('promotionRejected') && (
          <>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionStandardRejectedCount')}>
              最新情報
              <Sort sortKey='promotionStandardRejectedCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionEventRejectedCount')}>
              イベント
              <Sort sortKey='promotionEventRejectedCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionOfferRejectedCount')}>
              特典
              <Sort sortKey='promotionOfferRejectedCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell onClick={() => toggleSortKey('promotionAlertRejectedCount')}>
              その他
              <Sort sortKey='promotionAlertRejectedCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
          </>
        )}
        {reviewType === 'period' ? (
          <>
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('periodReviews')}>
              合計
              <Sort sortKey='periodReviews' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            {openedColumns.has('review') && (
              <>
                <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('periodReviewCommentCount')}>
                  コメントあり
                  <Sort sortKey='periodReviewCommentCount' sortCondition={sortCondition} />
                </StyledSortableTableHeaderCell>
                <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('periodReviewRateCount')}>
                  評価のみ
                  <Sort sortKey='periodReviewRateCount' sortCondition={sortCondition} />
                </StyledSortableTableHeaderCell>
              </>
            )}
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('periodReviewReplyCount')}>
              返信数
              <Sort sortKey='periodReviewReplyCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('periodReviewAverageRating')}>
              期間平均
              <ContextHelp header={'評価（期間平均）'} content={GbpPerformanceHelp.periodReviewAverageRating} />
              <Sort sortKey='periodReviewAverageRating' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('totalReviewAverageRating')}>
              累計平均
              <ContextHelp header={'評価（累計平均）'} content={GbpPerformanceHelp.totalReviewAverageRating} />
              <Sort sortKey='totalReviewAverageRating' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
          </>
        ) : (
          <>
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('totalReviews')}>
              合計
              <Sort sortKey='totalReviews' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            {openedColumns.has('review') && (
              <>
                <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('totalReviewCommentCount')}>
                  コメントあり
                  <Sort sortKey='totalReviewCommentCount' sortCondition={sortCondition} />
                </StyledSortableTableHeaderCell>
                <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('totalReviewRateCount')}>
                  評価のみ
                  <Sort sortKey='totalReviewRateCount' sortCondition={sortCondition} />
                </StyledSortableTableHeaderCell>
              </>
            )}
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('totalReviewReplyCount')}>
              返信数
              <Sort sortKey='totalReviewReplyCount' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('periodReviewAverageRating')}>
              期間平均
              <ContextHelp header={'評価（期間平均）'} content={GbpPerformanceHelp.periodReviewAverageRating} />
              <Sort sortKey='periodReviewAverageRating' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
            <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('totalReviewAverageRating')}>
              累計平均
              <ContextHelp header={'評価（累計平均）'} content={GbpPerformanceHelp.totalReviewAverageRating} />
              <Sort sortKey='totalReviewAverageRating' sortCondition={sortCondition} />
            </StyledSortableTableHeaderCell>
          </>
        )}
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

const Sort: React.FC<{ sortKey: SortKey; sortType?: SortType; sortCondition: SortCondition }> = ({
  sortKey,
  sortType = 'number',
  sortCondition,
}) => (
  <SortWrapper>
    {sortKey === sortCondition?.key && sortType === sortCondition?.type && (
      <Triangle isSortOrderDesc={sortCondition.order === 'desc'} />
    )}
  </SortWrapper>
);

const MultiTypeSort: React.FC<{ sortKey: SortKey; sortCondition: SortCondition }> = ({ sortKey, sortCondition }) => {
  if (sortKey !== sortCondition?.key) {
    return <></>;
  }
  return (
    <>
      （{<Sort sortKey={sortKey} sortType={'number'} sortCondition={sortCondition} />}数 /
      {<Sort sortKey={sortKey} sortType={'rate'} sortCondition={sortCondition} />}率）
    </>
  );
};

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const StyledTableHeaderCell = styled(TableHeaderCell)<{ border?: number }>`
  &&& {
    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)<{ border?: number }>`
  &&& {
    user-select: none;

    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
  }
`;

const SortWrapper = styled.div`
  display: inline-block;
  width: 16px;
`;

const Triangle = styled(SortTriangle)`
  display: inline-block;
  vertical-align: middle;
`;

const OpenLabel = styled.span<{ opened: boolean }>`
  display: inline-block;
  cursor: pointer;

  &:after {
    display: inline-block;
    margin-left: 4px;
    content: ${({ opened }) => (opened ? '"( ◀︎閉じる )"' : '"( 開く▶︎ )"')};
    font-size: 0.75em;
  }
`;
