import dayjs, { Dayjs } from 'dayjs';
import { Record } from 'immutable';

import { JSObject } from 'types/Common';

import { ReviewReply } from './ReviewReply';
import { Reviewer } from './Reviewer';

import { StarRatingType } from './';

export class Review extends Record<{
  name: string; // resource path
  reviewId: string;
  comment: string;
  reviewer: Reviewer;
  starRating: StarRatingType;
  reviewReply: ReviewReply;
  createTime: Dayjs;
  updateTime: Dayjs;
}>({
  name: '',
  reviewId: '',
  comment: '',
  reviewer: new Reviewer(),
  starRating: 'ONE',
  reviewReply: new ReviewReply(),
  createTime: dayjs(),
  updateTime: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.reviewer = new Reviewer(params.reviewer);
    params.reviewReply = new ReviewReply(params.reviewReply);
    params.createTime = dayjs(params.createTime);
    params.updateTime = dayjs(params.updateTime);
    super(params);
  }

  get hasReply() {
    return Boolean(this.reviewReply.comment);
  }
}
