import React from 'react';

import { TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';

import Logo from 'components/atoms/Logo';
import { COLOR } from 'style/color';

export const LogoLoading: React.FC<{ isLogoLoading: boolean }> = ({ isLogoLoading }) => {
  return (
    <TransitionablePortal
      open={isLogoLoading}
      transition={{
        animation: 'fade',
        duration: 500,
      }}
    >
      <PortalWrapper>
        <LoadingLogo />
      </PortalWrapper>
    </TransitionablePortal>
  );
};

const PortalWrapper = styled.div`
  &&& {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1100;
    background-color: ${COLOR.WHITE};
    width: 100%;
  }
`;

const LoadingLogo = styled(Logo)`
  width: 70%;
  max-width: 500px;
`;
