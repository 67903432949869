import React, { useCallback } from 'react';

import { Checkbox, Search } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import {
  AvailabilityType,
  ExpirationType,
  FilterStatus,
  ProductSearchCondition,
  SortKey,
  SortOrder,
} from 'models/Domain/Omo/ProductSearchCondition';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  isLoading: boolean; // 検索が実行中かどうか
  searchCondition: ProductSearchCondition;
  canUseOnDisplayToOrder: boolean;
  onChangeSearchCondition: (searchCondition: ProductSearchCondition) => void;
  onCommitSearchCondition: () => void;
};

export type SortType = 'create_desc' | 'create_asc';

export const sortTypeOptions: { text: string; value: SortType }[] = [
  {
    text: '商品の追加日が新しい順',
    value: 'create_desc',
  },
  {
    text: '商品の追加日が古い順',
    value: 'create_asc',
  },
];

/**
 * 店頭在庫一覧画面の検索条件を指定するコンポーネント
 */
export const InventoryFilter = React.memo<Props>(
  ({
    className,
    isLoading,
    searchCondition,
    canUseOnDisplayToOrder,
    onChangeSearchCondition,
    onCommitSearchCondition,
  }) => {
    const filter = searchCondition.filter;
    const { key: sortKey, order: sortOrder } = searchCondition.sort;
    const selectedSortType = `${sortKey}_${sortOrder}` as SortType;

    const onChangeFilter = useCallback(
      (filter: FilterStatus) => {
        const updatedCondition = searchCondition.set('filter', filter);
        onChangeSearchCondition(updatedCondition);
      },
      [onChangeSearchCondition, searchCondition],
    );

    /**
     * 検索ワードが変更された
     */
    const onChangeSearchValue = useCallback(
      (_: React.SyntheticEvent, data: any) => {
        const updatedFilter = filter.setSearchValue(data.value || '');
        onChangeFilter(updatedFilter);
      },
      [filter, onChangeFilter],
    );

    /**
     * 有効期限が変更された
     * @param key 変更するフィルターのキー
     * @param value 変更するフィルターの値
     */
    const onChangeExpiration = useCallback(
      (key: ExpirationType, value: boolean) => {
        onChangeFilter(filter.setExpiration(key, value));
      },
      [filter, onChangeFilter],
    );

    /**
     * 在庫状況が変更された
     * @param key 変更するフィルターのキー
     * @param value 変更するフィルターの値
     */
    const onChangeAvailability = useCallback(
      (key: AvailabilityType, value: boolean) => {
        onChangeFilter(filter.setAvailability(key, value));
      },
      [filter, onChangeFilter],
    );

    /**
     * 並び順が変更された
     */
    const onChangeSort = useCallback(
      (value: string) => {
        const splitParam = value.split('_');
        const sortKey = splitParam[0] as SortKey;
        const sortOrder = splitParam[1] as SortOrder;
        const updatedCondition = searchCondition.setSortCondition(sortKey, sortOrder);
        onChangeSearchCondition(updatedCondition);
      },
      [onChangeSearchCondition, searchCondition],
    );

    return (
      <Card className={className}>
        <FilterWrapper>
          <ContentWrapper>
            <ContentLabel>商品コード</ContentLabel>
            <SearchField
              onSearchChange={onChangeSearchValue}
              placeholder={'商品コード（部分一致）'}
              showNoResults={false}
              value={searchCondition.filter.searchValue}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  if (isLoading) {
                    return; // 検索に負荷がかかるため検索の実行中は新しい検索を行わない
                  }
                  onCommitSearchCondition();
                }
              }}
              icon={null}
            />
          </ContentWrapper>
          <ContentWrapper>
            <ContentLabel>有効期限</ContentLabel>
            <MultiSelectBox value={searchCondition.filter.getExpirationOptionLabel()}>
              {filter.expiration.keySeq().map((key) => {
                const value = filter.expiration.get(key);
                return (
                  <MultiSelectBoxContent key={key}>
                    <CheckLabel
                      onChange={() => onChangeExpiration(key, !value)}
                      checked={value}
                      label={filter.getExpirationLabel(key)}
                    />
                  </MultiSelectBoxContent>
                );
              })}
            </MultiSelectBox>
          </ContentWrapper>
          <ContentWrapper>
            <ContentLabel>店頭在庫状況</ContentLabel>
            <MultiSelectBox value={searchCondition.filter.getAvailabilityOptionLabel(canUseOnDisplayToOrder)}>
              {filter.availability.keySeq().map((key) => {
                // 「見本展示のみ」は利用可能な場合だけ表示
                if (!canUseOnDisplayToOrder && key === 'on_display_to_order') {
                  return;
                }
                const value = filter.availability.get(key);
                return (
                  <MultiSelectBoxContent key={key}>
                    <CheckLabel
                      onChange={() => onChangeAvailability(key, !value)}
                      checked={value}
                      label={filter.getAvailabilityLabel(key)}
                    />
                  </MultiSelectBoxContent>
                );
              })}
            </MultiSelectBox>
          </ContentWrapper>
          <ContentWrapper>
            <ContentLabel>並び順</ContentLabel>
            <PullDown value={selectedSortType} options={sortTypeOptions} onChange={onChangeSort} />
          </ContentWrapper>
          <SearchButtonWrapper>
            <SearchButton priority={'high'} onClick={onCommitSearchCondition} disabled={isLoading}>
              検索
            </SearchButton>
          </SearchButtonWrapper>
        </FilterWrapper>
      </Card>
    );
  },
);

const FilterWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0;
  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

const MultiSelectBoxContent = styled.div`
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-width: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const SearchButtonWrapper = styled(ContentWrapper)`
  margin-left: auto;
  padding-top: 28px;
`;

const SearchButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const SearchField = styled(Search)`
  width: 380px;
  @media (max-width: 920px) {
    width: 100%;
  }

  &&& {
    .ui.input {
      width: 100%;
    }

    input {
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
      font-weight: bold;
      height: 33px;
    }
  }
`;
