import React, { useCallback, useMemo } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { Popup, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { TableCell, TableHeader, TableHeaderCell, TableHeaderRow, TableRow } from 'components/molecules/Table';
import { GbpPerformanceHelp } from 'helpers/ContextHelp';
import {
  DisplayType,
  GbpPerformanceTableData,
  InteractionType,
  ReviewType,
  StatsType,
  gbpPerformanceStatsKeyMapping,
  isAggregateStatsType,
  isInteractionStatsType,
} from 'models/Domain/GbpPerformance/GbpPerformance';
import { GbpPerformanceSearchCondition as SearchCondition } from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';
import { COLOR } from 'style/color';
import { assertNever } from 'types/Common';

const DATE_FORMAT = 'YYYY/MM/DD';
const CLICKABLE_STATS: { [stat in StatsType]?: { displayType: DisplayType } } = {
  businessImpressions: { displayType: 'impressions' },
  interactions: { displayType: 'interactions' },
  totalReviews: { displayType: 'activities' },
  imageCount: { displayType: 'activities' },
  promotionCount: { displayType: 'activities' },
  periodReviews: { displayType: 'activities' },
};

export type GbpPerformanceStatsComparisonTableProps = {
  data: GbpPerformanceTableData;
  displayType: DisplayType;
  searchCondition: SearchCondition;
  onChangeDisplayType: (displayType: DisplayType) => void;
  isLoading: boolean;
  onChangeActiveStats: (statsType: StatsType) => void;
  activeStats: ImmutableSet<StatsType>;
  interactionType: InteractionType;
  reviewType: ReviewType;
};

export const GbpPerformanceStatsComparisonTable: React.FC<GbpPerformanceStatsComparisonTableProps> = ({
  data,
  displayType,
  searchCondition,
  onChangeDisplayType,
  isLoading,
  onChangeActiveStats,
  activeStats,
  interactionType,
  reviewType,
}) => {
  const startDate = searchCondition.filter.startDate;
  const endDate = searchCondition.filter.endDate;
  const comparisonStartDate = searchCondition.filter.comparisonStartDate;
  const comparisonEndDate = searchCondition.filter.comparisonEndDate;

  const impressionsTargetSumValue = data.getTargetStatsSumValue('businessImpressions');
  const impressionsComparisonSumValue = data.getComparisonStatsSumValue('businessImpressions');

  const displayStats = useMemo((): readonly StatsType[] => {
    switch (displayType) {
      case 'overview':
        return ['businessImpressions', 'interactions', 'imageCount', 'promotionCount', 'periodReviews'];
      case 'impressions':
        return [
          'businessImpressions',
          'businessImpressionsDesktopMaps',
          'businessImpressionsMobileMaps',
          'businessImpressionsDesktopSearch',
          'businessImpressionsMobileSearch',
          'interactions',
        ];
      case 'interactions':
        return [
          'businessImpressions',
          'interactions',
          'callClicks',
          'businessDirectionRequests',
          'websiteClicks',
          'businessConversations',
          'businessBookings',
        ];
      case 'activities':
        return reviewType === 'total'
          ? [
              'imageCount',
              'imageInteriorCount',
              'imageExteriorCount',
              'imageProductCount',
              'imageAdditionalCount',
              'promotionCount',
              'promotionStandardCount',
              'promotionEventCount',
              'promotionOfferCount',
              'promotionAlertCount',
              'totalReviews',
              'totalReviewCommentCount',
              'totalReviewRateCount',
              'totalReviewReplyCount',
              'totalReviewAverageRating',
            ]
          : [
              'imageCount',
              'imageInteriorCount',
              'imageExteriorCount',
              'imageProductCount',
              'imageAdditionalCount',
              'promotionCount',
              'promotionStandardCount',
              'promotionEventCount',
              'promotionOfferCount',
              'promotionAlertCount',
              'periodReviews',
              'periodReviewCommentCount',
              'periodReviewRateCount',
              'periodReviewReplyCount',
              'periodReviewAverageRating',
            ];
    }
  }, [displayType, reviewType]);

  // チェックボックスを選択不可にする項目
  const disabledStats = useMemo((): StatsType[] => {
    switch (displayType) {
      case 'overview':
        return [];
      case 'impressions':
        return ['businessImpressions'];
      case 'interactions':
        return ['interactions'];
      case 'activities':
        return ['totalReviews', 'periodReviews', 'promotionCount', 'imageCount'];
      default:
        return assertNever(displayType);
    }
  }, [displayType]);

  const handleOnClickStatsName = useCallback(
    (stats: StatsType) => {
      // 閲覧数またはインタラクションをクリックしたとき、そのタブを選択した状態にする（テーブルを展開）
      // すでに選択された状態の場合は概要に戻る（テーブルを閉じる）
      const statsSetting = CLICKABLE_STATS[stats];
      if (statsSetting) {
        const targetDisplayType = statsSetting.displayType;
        onChangeDisplayType(displayType === targetDisplayType ? 'overview' : targetDisplayType);
      }
    },
    [displayType, onChangeDisplayType],
  );

  const handleOnSelect = useCallback(
    (statsType: StatsType) => {
      onChangeActiveStats(statsType);
    },
    [onChangeActiveStats],
  );

  return (
    <TableWrapper>
      <Title>比較期間との比較</Title>
      <StyledTable unstackable={true}>
        <StyledTableHeader>
          <StyledTableHeaderRow>
            <CheckboxHeaderCell>
              表示
              <ContextHelp
                header={'グラフへの表示'}
                content={
                  displayType === 'overview' ? GbpPerformanceHelp.overviewActiveStats : GbpPerformanceHelp.activeStats
                }
              />
            </CheckboxHeaderCell>
            <StyledTableHeaderCell>項目名</StyledTableHeaderCell>
            <StyledTableHeaderCell>
              集計対象
              <RankDateLabel>
                {startDate && startDate.format(DATE_FORMAT)}〜{endDate && endDate.format(DATE_FORMAT)}
              </RankDateLabel>
            </StyledTableHeaderCell>
            <StyledTableHeaderCell>
              比較対象
              <RankDateLabel>
                {comparisonStartDate && comparisonStartDate.format(DATE_FORMAT)}〜
                {comparisonEndDate && comparisonEndDate.format(DATE_FORMAT)}
              </RankDateLabel>
            </StyledTableHeaderCell>
            <StyledTableHeaderCell>比較期間との差分</StyledTableHeaderCell>
            <StyledTableHeaderCell>比較期間との変化率</StyledTableHeaderCell>
          </StyledTableHeaderRow>
        </StyledTableHeader>

        <Table.Body>
          {displayStats.map((stats) => {
            const label = gbpPerformanceStatsKeyMapping[stats];
            const hasRatio = isInteractionStatsType(stats);
            const isGrouped = !isAggregateStatsType(stats);

            const statsComparisonData = data.getStatsComparisonData(stats);
            const { targetPeriodSumValue, comparisonPeriodSumValue, diffValue, growthRatio } = statsComparisonData;

            // 集計期間に対するインタラクション率
            const targetPeriodInteractionRatio =
              hasRatio && impressionsTargetSumValue && targetPeriodSumValue != null
                ? targetPeriodSumValue / impressionsTargetSumValue
                : null;
            // 比較期間に対するインタラクション率
            const comparisonPeriodInteractionRatio =
              hasRatio && impressionsComparisonSumValue && comparisonPeriodSumValue != null
                ? comparisonPeriodSumValue / impressionsComparisonSumValue
                : null;
            // インタラクション率の比較期間との差分
            const diffRatioValue =
              hasRatio && targetPeriodInteractionRatio != null && comparisonPeriodInteractionRatio != null
                ? targetPeriodInteractionRatio - comparisonPeriodInteractionRatio
                : null;
            // インタラクション率の比較期間との変化率
            const diffRatioRate =
              hasRatio && targetPeriodInteractionRatio != null && comparisonPeriodInteractionRatio
                ? targetPeriodInteractionRatio / comparisonPeriodInteractionRatio - 1.0
                : null;

            const showInteractionRate = hasRatio && interactionType === 'rate';
            const showRating = stats === 'periodReviewAverageRating' || stats === 'totalReviewAverageRating';
            return (
              <TableRow key={stats}>
                <CheckboxCell>
                  {/* 選択不可の項目の場合は何も表示しない */}
                  {/* 「概要」かつ2つ以上の項目が設定されている場合は、選択不可のチェックボックスにポップアップを表示する */}
                  {disabledStats.includes(stats) ? null : displayType === 'overview' &&
                    activeStats.size >= 2 &&
                    !activeStats.has(stats) ? ( // 「概要」かつ2つ以上の項目が設定されている場合は、ポップアップを表示する
                    <Popup
                      style={{ fontSize: '14px' }}
                      content={'表示できるグラフは2つまでです。他の項目を選択解除してください'}
                      trigger={
                        <CheckboxWrapper>
                          <SmallCheckBox checked={false} disabled={true} />
                        </CheckboxWrapper>
                      }
                    />
                  ) : (
                    <CheckboxWrapper>
                      <SmallCheckBox
                        checked={activeStats.has(stats)}
                        onChange={(e: React.MouseEvent<HTMLInputElement>) => {
                          e.stopPropagation();
                          handleOnSelect(stats);
                        }}
                      />
                    </CheckboxWrapper>
                  )}
                </CheckboxCell>
                <StatsNameCell>
                  <StatsName
                    grouped={isGrouped}
                    onClick={() => handleOnClickStatsName(stats)}
                    clickable={!!CLICKABLE_STATS[stats]}
                  >
                    {(() => {
                      switch (stats) {
                        case 'interactions': {
                          return !showInteractionRate ? (
                            <>
                              インタラクション数
                              <ContextHelp header={'インタラクション数'} content={GbpPerformanceHelp.interactions} />
                            </>
                          ) : (
                            <>
                              インタラクション率
                              <ContextHelp
                                header={'インタラクション率'}
                                content={GbpPerformanceHelp.interactionsRate}
                              />
                            </>
                          );
                        }
                        case 'businessImpressions':
                        case 'totalReviews':
                        case 'periodReviews':
                        case 'totalReviewReplyCount':
                        case 'periodReviewReplyCount':
                        case 'totalReviewAverageRating':
                        case 'periodReviewAverageRating':
                        case 'imageCount':
                        case 'promotionCount':
                          return (
                            <>
                              {label}
                              <ContextHelp header={label} content={GbpPerformanceHelp[stats]} />
                            </>
                          );
                        default:
                          return label;
                      }
                    })()}
                  </StatsName>
                </StatsNameCell>
                <NumberCell>
                  {showRating ? (
                    <Rating value={targetPeriodSumValue} />
                  ) : showInteractionRate ? (
                    <Rate value={targetPeriodInteractionRatio} />
                  ) : (
                    <Number value={targetPeriodSumValue} />
                  )}
                </NumberCell>
                <NumberCell>
                  {showRating ? (
                    <Rating value={comparisonPeriodSumValue} />
                  ) : showInteractionRate ? (
                    <Rate value={comparisonPeriodInteractionRatio} />
                  ) : (
                    <Number value={comparisonPeriodSumValue} />
                  )}
                </NumberCell>
                <NumberCell>
                  {showInteractionRate ? <DiffRate value={diffRatioValue} /> : <DiffNumber value={diffValue} />}
                </NumberCell>
                <NumberCell>
                  {showInteractionRate ? <DiffRate value={diffRatioRate} /> : <DiffRate value={growthRatio} />}
                </NumberCell>
              </TableRow>
            );
          })}
        </Table.Body>
      </StyledTable>
      {isLoading && (
        <LoadingWrapper>
          <Loader active={isLoading} size={'big'} inline={true} />
        </LoadingWrapper>
      )}
    </TableWrapper>
  );
};

const Number = ({ value }: { value: number | null | undefined }) => {
  return <>{value == null ? 'ー' : value.toLocaleString()}</>;
};

const Rate = ({ value }: { value: number | null | undefined }) => {
  return (
    <>
      {value == null
        ? 'ー'
        : `${(value * 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`}
    </>
  );
};

const Rating = ({ value }: { value: number | null | undefined }) => {
  return (
    <>
      {value == null ? 'ー' : value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
    </>
  );
};

const DiffNumber = styled.span<{ value: number | null | undefined }>`
  color: ${({ value }) => (!value ? COLOR.BLACK : value >= 0 ? COLOR.GREEN : COLOR.RED)};
  &:before {
    content: '${({ value }) => (value == null ? '' : value === 0 ? '' : value > 0 ? '↑' : '↓')}';
    margin-right: 4px;
  }
  &:after {
    content: '${({ value }) => (value == null ? 'ー' : `${Math.abs(value).toLocaleString()}`)}';
  }
`;

const DiffRate = styled.span<{ value: number | null | undefined }>`
  color: ${({ value }) => (!value ? COLOR.BLACK : value >= 0 ? COLOR.GREEN : COLOR.RED)};
  &:before {
    content: '${({ value }) => (value == null ? '' : value === 0 ? '' : value > 0 ? '↑' : '↓')}';
    margin-right: 4px;
  }
  &:after {
    content: '${({ value }) => (value == null ? 'ー' : `${(Math.abs(value) * 100).toFixed(2)}%`)}';
  }
`;

const StyledTable = styled(Table)`
  &&& {
    width: 100%;
  }
`;

const StyledTableHeader = styled(TableHeader)``;

const StyledTableHeaderRow = styled(TableHeaderRow)``;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  :not(:last-child) {
    border-right: 1px solid ${COLOR.TABLE_BORDER};
  }
`;

const StyledTableCell = styled(TableCell)`
  :not(:last-child) {
    border-right: 1px solid ${COLOR.TABLE_BORDER};
  }
`;

export const RankDateLabel = styled.div`
  font-size: 9px;
  color: ${COLOR.DARK_GRAY};
`;

const CheckboxHeaderCell = styled(StyledTableHeaderCell)`
  width: 64px;
`;

const CheckboxCell = styled(StyledTableCell)`
  width: 64px;
`;

const StatsNameCell = styled(StyledTableCell)`
  &&& {
    font-weight: bold;
  }
`;

const StatsName = styled.span<{ grouped?: boolean; clickable?: boolean }>`
  padding-left: ${({ grouped = false }) => (grouped ? '24' : '0')}px;
  cursor: ${({ clickable = false }) => (clickable ? 'pointer' : 'inherit')};
  text-decoration: ${({ clickable = false }) => (clickable ? 'underline' : 'none')};
`;

const RightAlignCell = styled(StyledTableCell)`
  &&& {
    text-align: right;
  }
`;

const NumberCell = styled(RightAlignCell)`
  &&& {
    font-weight: bold;
    font-size: 14px;
    font-family: monospace;
  }
`;

const TableWrapper = styled.div`
  position: relative;
  min-height: 200px;
  margin-bottom: 32px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;
