import actionCreatorFactory from 'typescript-fsa';

import CheckedImageList from 'models/Domain/CheckedImageList';
import { GbpImageCategory, ImageList } from 'models/Domain/Image/Image';
import ImageSearchCondition from 'models/Domain/Image/ImageSearchCondition';
import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('Image/Index');

/**
 * GBP画像掲載時に必要なinterface
 */
interface UploadGmbParam {
  imageResourceIds: number[];
  category: string;
}

export const ImageActions = {
  initializeAlbumListIndexPage: actionCreator('initializeAlbumListIndexPage'),
  setIsPreparedForImageIndex: actionCreator<boolean>('setIsPreparedForImageIndex'),
  fetchImages: actionCreator('fetchImages'),
  getHashtagList: actionCreator('getHashtagList'),
  setHashtagList: actionCreator<string[]>('setHashtagList'),
  getImage: actionCreator<number>('getImage'),
  setImage: actionCreator<JSObject>('setImage'),
  deleteImage: actionCreator<number>('deleteImage'),
  uploadGmb: actionCreator<UploadGmbParam>('uploadGmb'),
  disconnectImages: actionCreator<number[]>('disconnectImages'),

  // 写真の追加
  // 画像ファイルの配列と、画像ファイルのメタデータ（バリデーションに使う）の配列
  uploadNewImageFile: actionCreator<{ files: File[]; metadata: ImageFileMeta[] }>('uploadNewImageFile'),

  setImageListForCreate: actionCreator<ImageList>('setImageListForCreate'),
  setEditHashtagList: actionCreator<string[]>('setEditHashtagList'),
  setEditCategory: actionCreator<GbpImageCategory | null>('setEditCategory'),
  updateImageHashtagList: actionCreator('updateImageHashtagList'),
  updateImageCategory: actionCreator('updateImageCategory'),
  createImage: actionCreator<JSObject>('createImage'),
  setIsLoadingImageList: actionCreator<boolean>('setIsLoadingImageList'),
  setImageList: actionCreator<JSObject>('setImageList'),
  setSearchCondition: actionCreator<{ searchCondition: ImageSearchCondition; updateLocation?: boolean }>(
    'setSearchCondition',
  ),
  setCommittedSearchCondition: actionCreator<ImageSearchCondition>('setCommittedSearchCondition'),
  setCheckedImageList: actionCreator<CheckedImageList>('setCheckedImageList'),
};
