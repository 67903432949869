import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { ExternalLink } from 'components/atoms/ExternalLink';
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'components/molecules/Table';
import { MapSearchRankConfig } from 'models/Domain/MapSearchRank/MapSearchRankConfigData';
import { Stores } from 'models/Domain/Store';

type MapSearchRankConfigTableProps = {
  configs: List<MapSearchRankConfig>;
  stores: Stores;
  containsStatus: boolean;
};

export const MapSearchRankConfigTable: React.FC<MapSearchRankConfigTableProps> = React.memo(
  ({ configs, stores, containsStatus }) => {
    return (
      <StyledTable>
        <StyledTableHeader>
          <TableRow>
            <TableHeaderCell>店舗</TableHeaderCell>
            <TableHeaderCell>店舗コード</TableHeaderCell>
            <TableHeaderCell>検索ワード</TableHeaderCell>
            <TableHeaderCell>検索地点</TableHeaderCell>
            <TableHeaderCell>店舗との距離</TableHeaderCell>
            <TableHeaderCell>縮尺</TableHeaderCell>
            {containsStatus && <TableHeaderCell>有効</TableHeaderCell>}
            <TableHeaderCell>追加日</TableHeaderCell>
          </TableRow>
        </StyledTableHeader>
        <TableBody>
          {configs.map((config) => (
            <MapSearchRankConfigTableRow
              key={config.id}
              config={config}
              stores={stores}
              containsStatus={containsStatus}
            />
          ))}
        </TableBody>
      </StyledTable>
    );
  },
);

type MapSearchRankConfigTableRowProps = {
  config: MapSearchRankConfig;
  stores: Stores;
  containsStatus: boolean;
};

export const MapSearchRankConfigTableRow: React.FC<MapSearchRankConfigTableRowProps> = React.memo(
  ({ config, stores, containsStatus }) => {
    const store = stores.findStore(config.storeId);
    if (!store) {
      return <></>;
    }
    const distance = store.distanceFrom(config.latitude, config.longitude, 'km');
    const distanceStr =
      distance !== undefined ? (distance >= 1 ? `${distance.toFixed(3)} km` : `${(distance * 1000).toFixed()} m`) : '';
    return (
      <StyledTableRow key={config.id} deleted={+config.isDeleted}>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>店舗</MobileOnlyLabel>
            {store.fullName}
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>店舗コード</MobileOnlyLabel>
            {store.code}
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>検索ワード</MobileOnlyLabel>
            {config.searchWord}
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>検索地点</MobileOnlyLabel>
            <LinkLabel>{config.areaName}</LinkLabel>
            <ExternalLink href={config.coordinateMapURL} />
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>店舗との距離</MobileOnlyLabel>
            {distanceStr}
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>縮尺</MobileOnlyLabel>
            {config.zoom}
          </CellContent>
        </StyledTableCell>
        {containsStatus && (
          <StyledTableCell>
            <CellContent>
              <MobileOnlyLabel>有効</MobileOnlyLabel>
              {!config.isDeleted ? 'はい' : 'いいえ'}
            </CellContent>
          </StyledTableCell>
        )}
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>追加日</MobileOnlyLabel>
            {config.createAt.format('YYYY/MM/DD')}
          </CellContent>
        </StyledTableCell>
      </StyledTableRow>
    );
  },
);

const StyledTable = styled(Table)`
  &&& {
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    border-collapse: separate;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 71px;
    z-index: 1;
  }
  @media (max-width: 599px) {
    &&& {
      /* モバイル表示時はテーブルのヘッダを表示しない（セル内に、ラベルを表示させるので） */
      /* !important書かないと効かないレベルでCSSががんじがらめ。 */
      /* ただ、このスタイルの発動条件は、かなり限定的なので影響はない */
      display: none !important;
    }
  }
`;

// propsにbooleanを指定するとWarningが出るので 1, 0に変換する
const StyledTableRow = styled(TableRow)<{ deleted: 1 | 0 }>`
  background: ${({ deleted }) => (deleted ? 'rgba(0, 0, 0, 0.05)' : 'initial')};
`;

const StyledTableCell = styled(TableCell)`
  vertical-align: middle;
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const MobileOnly = styled.div`
  display: inline-block;
  @media (min-width: 600px) {
    display: none;
  }
`;

const MobileOnlyLabel = styled(MobileOnly)`
  width: 100px;
  font-weight: bold;
`;

const LinkLabel = styled.span`
  display: inline-block;
  margin-right: 4px;
`;
