import { convertDistance, getDistance } from 'geolib';
import { List, Map, Record, is } from 'immutable';

import ErrorType from 'helpers/errorType';
import { getMostCommon, sortComparator, trimForGmbText } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { GmbAttributes, GmbUrlAttributes } from './GmbLocation/GmbAttributes';
import { GmbLocation } from './GmbLocation/GmbLocation';
import { GmbLocationCategory } from './GmbLocation/GmbLocationCategories';
import { OpenInfoStatus } from './GmbLocation/GmbOpenInfo';
import { GmbRegularHours } from './GmbLocation/GmbRegularHours';
import { MoreHoursList } from './GmbLocation/MoreHours';
import { OpeningDate } from './GmbLocation/OpeningDate';
import { LocationState } from './LocationState';
import { StoreLists } from './StoreList';
import { User, UserRole } from './User';

export type SortType = 'code_asc' | 'code_desc' | 'id_asc' | 'id_desc';

type AliasType = '{{店名}}' | '{{支店名}}';

// エイリアスとStoreモデル内のパスを関係づけて保持
export const STORE_ALIASES: { [alias in AliasType]: (string | number)[] } = {
  '{{店名}}': ['name'],
  '{{支店名}}': ['branch'],
};

export class Store extends Record<{
  id: number;
  code: string;
  name: string;
  branch: string;
  organization_id: number | null;
  is_connected_gmb: boolean;
  managed_users: List<number>;
  users: List<number>;
  location: GmbLocation;
  location_state: LocationState;
  locationErrorMessages: string[];
  locationIsValid: boolean;
  placeId: string | null;
  pastPlaceIds: List<string>;
}>({
  id: 0,
  code: '',
  name: '',
  branch: '',
  organization_id: null,
  is_connected_gmb: false,
  managed_users: List(),
  users: List(),
  location: new GmbLocation(),
  location_state: new LocationState(),
  locationErrorMessages: [],
  locationIsValid: false,
  placeId: null,
  pastPlaceIds: List(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.managed_users = List(params.managed_users && params.managed_users.map((user: JSObject) => user.id));
    params.users = List(params.users && params.users.map((user: JSObject) => user.id));
    params.location = new GmbLocation(params.location || {});
    params.location_state = LocationState.fromJSON(params.location_state);
    params.placeId = params.place_id || null;
    params.pastPlaceIds = List(params.past_place_ids || []);
    super(params);
  }

  get isExist() {
    return Boolean(this.id);
  }

  get fullName() {
    return this.branch ? `${this.name} ${this.branch}` : this.name;
  }

  get shortName() {
    return this.branch ? this.branch : this.name;
  }

  get isValid() {
    const validates = this.validate();
    return (
      Object.values(validates).filter((value) => {
        if (value instanceof Array) {
          return value.filter((one) => !one.isValid).length == 0;
        } else {
          return !value.isValid;
        }
      }).length === 0
    );
  }

  get isValidForRegister() {
    return this.validate().name.isValid;
  }

  get searchWord() {
    return this.fullName + this.code + this.location.address.searchWord;
  }

  /**
   * 管理者, 本社スタッフ: true
   * SV: 店舗の管理ユーザに入っている場合 : true
   * 店舗ユーザー：自分の所属店舗の場合
   * それ以外: false
   */
  hasApproveAuth(user: User) {
    if (user.isAdminUser || user.isVmdUser) {
      return true;
    } else if (user.isSvUser && this.managed_users.includes(user.id)) {
      return true;
    } else if (user.isMemberUser && this.users.includes(user.id)) {
      return true;
    } else {
      return false;
    }
  }

  changeCode(code: string) {
    return this.set('code', code);
  }

  changeName(name: string) {
    return this.set('name', name);
  }

  changeBranchName(branch: string) {
    return this.set('branch', branch);
  }

  changePrimaryPhone(phone: string) {
    return this.update('location', (location) => location.changePrimaryPhone(phone));
  }

  changeAdditionalPhones(index: number, phone: string) {
    return this.update('location', (location) => location.changeAdditionalPhones(index, phone));
  }

  changeWebsiteUrl(websiteUrl: string) {
    return this.update('location', (location) => location.changeWebsiteUrl(websiteUrl));
  }

  changeRegularHours(regularHours: GmbRegularHours) {
    return this.update('location', (location) => location.changeRegularHours(regularHours));
  }

  toggleDaySpecialHours(dayIndex: number, value: boolean) {
    return this.update('location', (location) => location.toggleDaySpecialHours(dayIndex, value));
  }

  changeSpecialHoursPeriod(dayIndex: number, timeIndex: number, openTime: string, closeTime: string) {
    return this.update('location', (location) =>
      location.changeSpecialHoursPeriod(dayIndex, timeIndex, openTime, closeTime),
    );
  }

  changeSpecialHoursDate(dayIndex: number, value: string) {
    return this.update('location', (location) => location.changeSpecialHoursDate(dayIndex, value));
  }

  addSpecialHoursOpenDay(dayIndex: number) {
    return this.update('location', (location) => location.addSpecialHoursOpenDay(dayIndex));
  }

  addSpecialHours() {
    return this.update('location', (location) => location.addSpecialHours());
  }

  removeSpecialHoursPeriod(dayIndex: number, timeIndex: number) {
    return this.update('location', (location) => location.removeSpecialHoursPeriod(dayIndex, timeIndex));
  }

  removeSpecialHoursDay(index: number) {
    return this.update('location', (location) => location.removeSpecialHoursDay(index));
  }

  removePastSpecialHours() {
    return this.update('location', (location) => location.removePastSpecialHours());
  }
  changePostalCode(postalCode: string) {
    return this.update('location', (location) => this.location.changePostalCode(postalCode));
  }

  changeAdministrativeArea(administrativeArea: string) {
    return this.update('location', (location) => this.location.changeAdministrativeArea(administrativeArea));
  }

  changeAddress1(address: string) {
    return this.update('location', (location) => this.location.changeAddress1(address));
  }
  changeAddress2(address: string) {
    return this.update('location', (location) => this.location.changeAddress2(address));
  }

  changePrimaryCategory(value: string) {
    return this.update('location', (location) => location.changePrimaryCategory(value));
  }

  updatePrimaryCategory(value: GmbLocationCategory) {
    return this.update('location', (location) => location.updatePrimaryCategoryDetail(value));
  }

  addAdditionalCategory() {
    return this.update('location', (location) => location.addAdditionalCategory());
  }

  changeAdditionalCategory(index: number, value: string) {
    return this.update('location', (location) => location.changeAdditionalCategory(index, value));
  }

  removeAdditionalCategory(index: number) {
    return this.update('location', (location) => location.removeAdditionalCategory(index));
  }

  changeOpenInfoStatus(status: OpenInfoStatus) {
    const result = this.update('location', (location) => location.changeOpenInfoStatus(status));
    return result;
  }

  changeOpeningDate(openingDate: OpeningDate | null) {
    const result = this.update('location', (location) => location.changeOpeningDate(openingDate));
    return result;
  }

  changeAttributes(status: GmbAttributes) {
    const result = this.update('location', (location) => location.changeGmbAttributes(status));
    return result;
  }

  changeUrlAttributes(status: GmbUrlAttributes) {
    const result = this.update('location', (location) => location.changeGmbUrlAttributes(status));
    return result;
  }

  changeProfileDescription(description: string) {
    const result = this.update('location', (location) => location.changeProfileDescription(description));
    return result;
  }

  changeMoreHours(moreHours: MoreHoursList) {
    const result = this.update('location', (location) => location.changeMoreHours(moreHours));
    return result;
  }

  changeLocationIsValid(isValid: boolean) {
    return this.set('locationIsValid', isValid);
  }

  changeLocationErrorMessages(messages: JSObject[]) {
    return this.set(
      'locationErrorMessages',
      messages.map((message) => {
        if (message.field === 'locationName') {
          return '店舗名';
        } else if (message.field === 'primaryPhone') {
          return '電話番号';
        } else if (message.field === 'additionalPhones') {
          return '追加の電話番号';
        } else if (message.field === 'websiteUrl') {
          return 'ウェブサイト';
        } else if (message.field === 'storeCode') {
          return '店舗コード';
        } else if (message.field === 'regularHours') {
          return '営業時間';
        } else if (message.field === 'specialHours') {
          return '特別営業時間';
        } else if (message.field === 'primaryCategory') {
          return 'メインカテゴリー';
        } else if (message.field === 'additionalCategories') {
          return 'サブカテゴリー';
        } else if (message.field === 'address') {
          return '住所';
        } else if (message.field === 'moreHours') {
          return 'その他の営業時間';
        }
        return '';
      }),
    );
  }

  validateCode() {
    const result = {
      isValid: true,
      error: '',
    };
    return result;
  }

  validateName() {
    let result = {
      isValid: true,
      error: '',
    };
    if (!this.name || !this.name.trim()) {
      result = {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    return result;
  }

  validateBranch() {
    const result = {
      isValid: true,
      error: '',
    };
    return result;
  }

  validateOpenInfo() {
    const result = {
      isValid: true,
      error: '',
    };
    return result;
  }

  validateOpeningDate() {
    let result = { isValid: true, error: '' };
    if (!this.location.openingDate) {
      return result;
    }
    Object.values(this.location.openingDate.validate()).forEach((r) => {
      result = result.isValid && !r.isValid ? { ...r } : result;
    });
    return result;
  }

  validate() {
    const errors = {
      openInfo: this.validateOpenInfo(),
      openingDate: this.validateOpeningDate(),
      code: this.validateCode(),
      name: this.validateName(),
      branch: this.validateBranch(),
      businessHours: this.location.validateBusinessHours(),
      specialHours: this.location.validateSpecialHours(),
      moreHours: this.location.moreHours.validate,
      ...this.location.validate(),
    };
    return errors;
  }

  is(store: Store) {
    return is(this, store);
  }

  requestParams() {
    const { id, code, name, branch } = this.toObject();
    const params = {
      id: id < 1 ? null : id,
      code,
      name: trimForGmbText(name),
      branch: trimForGmbText(branch),
    };
    return params;
  }

  updateCodeParams() {
    return { code: this.code };
  }

  updateNameBranchParams() {
    return {
      name: trimForGmbText(this.name),
      branch: trimForGmbText(this.branch),
    };
  }

  validateNameBranchParams() {
    return {
      locationName: `${this.name} ${this.branch}`,
    };
  }

  validateStoreCodeParams() {
    return {
      storeCode: this.code,
    };
  }

  validateOpenInfoParams() {
    return {
      openInfo: { status: this.location.openInfo.status },
    };
  }

  /**
   * エイリアスを復元したテキストを返す
   * @param text エイリアスを含むテキスト
   * @returns エイリアスを復元したテキスト
   */
  getAliasRestoredText(text: string) {
    return Object.entries(STORE_ALIASES).reduce<string>(
      (current, [alias, path]) => current.replaceAll(alias, this.getIn(path) || ''),
      text,
    );
  }

  /**
   * 与えられたテキストの注釈(Alias, Text)付きデータを返す
   * @param text エイリアスを含むテキスト
   * @returns 注釈付きデータ
   */
  getAliasAnnotatedText(text: string) {
    const aliasList = Object.keys(STORE_ALIASES);
    const splitPattern = new RegExp(`(${aliasList.join('|')})`);
    return text
      .split(splitPattern)
      .filter((fragment) => fragment)
      .map((fragment) => {
        if (aliasList.includes(fragment)) {
          return { type: 'Alias' as const, text: this.getIn(STORE_ALIASES[fragment as AliasType]), alias: fragment };
        }
        return { type: 'Text' as const, text: fragment };
      });
  }

  /**
   * 一括編集用の差分情報を生成する
   * @param other 比較対象のStore
   * @returns
   */
  getModifiedParamsForBulkEdit(other?: Store) {
    const modifiedParams = {
      SUNDAY: false,
      MONDAY: false,
      TUESDAY: false,
      WEDNESDAY: false,
      THURSDAY: false,
      FRIDAY: false,
      SATURDAY: false,
      OPEN_INFO: false,
      SPECIAL_HOURS: false,
      ATTRIBUTES: false,
      PROFILE: false,
    };
    if (!other) {
      return modifiedParams;
    }

    // 営業時間の差分を曜日ごとに確認
    const editTargetList = other.location.regularHours.regularHoursForEdit;
    this.location.regularHours.regularHoursForEdit.forEach((target) => {
      const editTarget = editTargetList.find((edit) => edit.type === target.type);
      if (!editTarget) return;
      if (!is(target.gmbTimePeriods, editTarget.gmbTimePeriods)) {
        modifiedParams[target.type] = true;
      }
    });

    // 営業ステータスの差分を確認
    if (this.location.openInfo.status !== other.location.openInfo.status) {
      modifiedParams.OPEN_INFO = true;
    }

    // 特別営業時間の差分を確認
    if (!is(this.location.specialHours, other.location.specialHours)) {
      modifiedParams.SPECIAL_HOURS = true;
    }

    // 属性の差分を確認
    if (!is(this.location.attributes, other.location.attributes)) {
      modifiedParams.ATTRIBUTES = true;
    }

    // ビジネスの情報の差分を確認
    if (!is(this.location.profile, other.location.profile)) {
      modifiedParams.PROFILE = true;
    }

    return modifiedParams;
  }

  distanceFrom(latitude: number, longitude: number, targetUnit = 'km') {
    if (!this.location) {
      return undefined;
    }

    const from = {
      latitude: this.location.latlng.latitude,
      longitude: this.location.latlng.longitude,
    };

    const to = {
      latitude: latitude,
      longitude: longitude,
    };

    return convertDistance(getDistance(from, to), targetUnit);
  }
}

export class Stores extends Record<{
  list: List<Store>;
  map: Map<number, Store>;
  initialized: boolean;
}>({
  list: List(),
  map: Map(),
  initialized: false,
}) {
  constructor(data: JSObject[] = [], initialized = false) {
    const list = List(data.map((d) => new Store(d)));
    const map = list.reduce((storeMap, store) => storeMap.set(store.id, store), Map<number, Store>());
    super({ list, map, initialized });
  }

  updateList(updater: (list: List<Store>) => List<Store>): Stores {
    const list = updater(this.list);
    const map = list.reduce((storeMap, store) => storeMap.set(store.id, store), Map<number, Store>());
    return this.merge({ list, map });
  }

  findStore(storeId: number | string | undefined) {
    if (!storeId) {
      return undefined;
    }
    return this.map.get(Number(storeId));
  }

  /**
   * placeIdから店舗を取得する
   * @param placeId
   */
  findStoreByPlaceId(placeId: string | null | undefined): Store | undefined {
    if (!placeId) {
      return undefined;
    }
    return this.list.find((store) => store.placeId === placeId);
  }

  filterStores(searchWord: string) {
    if (!searchWord) {
      return this;
    }

    // 空白文字で区切った配列を生成して空文字を除く
    const words = searchWord
      .toLowerCase()
      .split(/\s/)
      .filter((word) => word.length > 0);

    // wordsを含む店舗のみ抽出する
    return this.updateList((list) =>
      list.filter((store) => {
        for (const word of words) {
          if (!store.searchWord.toLowerCase().includes(word)) {
            return false;
          }
        }
        return true;
      }),
    );
  }

  filterStoreIdList(searchWord: string) {
    // wordsを含む店舗のみ抽出する
    const result = this.filterStores(searchWord).list.map((store) => store.id);
    return result.toArray();
  }

  filterStoresByName(name: string) {
    return this.updateList((list) => list.filter((store) => store.fullName === name));
  }

  filterStoresById(storeIds: number[]) {
    const storeIdSet = new Set(storeIds);
    return this.updateList((list) => list.filter((store) => storeIdSet.has(store.id)));
  }

  filterStoresByStoreListId(storeList: StoreLists, storeListId: number | null) {
    if (!storeListId) {
      return this;
    }
    const targetStoreList = storeList.findStoreList(storeListId);
    if (!targetStoreList) {
      return this;
    }
    return this.filterStoresById(targetStoreList.stores.toArray());
  }

  filterStoresHasStoreCode() {
    return this.updateList((list) => list.filter((store) => !!store.code));
  }

  filterByUserRole(user: User) {
    switch (user.role) {
      case UserRole.Admin:
      case UserRole.Vmd:
        return this;
      case UserRole.Sv:
        return this.filterStoresById(user.managing_stores.toArray());
      case UserRole.Member:
      default:
        return this.filterStoresById(user.stores.toArray());
    }
  }

  filterByIsConnectedGmb(is_connected_gmb = true) {
    return this.updateList((list) => list.filter((store) => store.is_connected_gmb === is_connected_gmb));
  }

  filter(predicate: (value: Store) => boolean) {
    return this.updateList((list) => list.filter(predicate));
  }

  sortBy(sortType: SortType) {
    let list = this.list;
    switch (sortType) {
      case 'id_asc':
        list = list.sortBy((store) => store.id);
        break;
      case 'id_desc':
        list = list.sortBy((store) => store.id).reverse();
        break;
      case 'code_asc':
        list = list.sortBy((store) => store.code, sortComparator);
        break;
      case 'code_desc':
        list = list.sortBy((store) => store.code, sortComparator).reverse();
        break;
      default:
        break;
    }
    return this.set('list', list);
  }

  get isEmpty() {
    return this.list.isEmpty();
  }

  getStoreIds() {
    return this.list.map((store) => store.id).toArray();
  }

  getUnclosedStoreIds() {
    return this.list
      .filter((store) => !store.location.openInfo.isClose)
      .map((store) => store.id)
      .toArray();
  }

  get options() {
    return this.list
      .map((store) => {
        return {
          text: store.fullName,
          value: store.id,
        };
      })
      .toArray();
  }

  /** 閉店していない店舗を返す */
  filterByUnclosed() {
    return this.updateList((list) => list.filter((store) => !store.location.openInfo.isClose));
  }

  /**
   * GBPロケーションがエラーとなっている店舗を返す
   *
   * GBP連携されていない店舗は、データとしてはエラーとなっていても対象外
   */
  filterByIsGmbError() {
    return this.updateList((list) => list.filter((store) => store.is_connected_gmb && store.location_state.isGmbError));
  }

  /**
   * 引数のstoreIdの配列の中に
   * Googleビジネスプロフィールと連携している店舗があるかどうか
   *
   * 空配列の場合は全ての店舗から判定する
   */
  hasGmbConnectedStoreInStoreIds(storeIds: number[]) {
    if (storeIds.length === 0) {
      return this.list.some((store) => store.is_connected_gmb);
    }

    return this.list
      .filter((store) => store.is_connected_gmb)
      .map((store) => store.id)
      .some((storeId) => storeIds.includes(storeId));
  }

  /**
   * 引数のstoreIdの配列の中に
   * Googleビジネスプロフィールにしていない店舗があるかどうか
   *
   * 空配列の場合はfalse
   */
  hasGmbUnconnectedStoreInStoreIds(storeIds: number[]) {
    if (storeIds.length === 0) return false;

    return this.list
      .filter((store) => !store.is_connected_gmb)
      .map((store) => store.id)
      .some((storeId) => storeIds.includes(storeId));
  }

  /**
   * 引数のstoreIdの配列の中から
   * Googleビジネスプロフィールと連携している店舗のIDのみを返す
   *
   * @param storeIds 店舗IDの配列
   * @returns Googleビジネスプロフィールと連携している店舗のIDの配列
   */
  getGbpConnectedStoresInStoreIds(storeIds: number[]) {
    const gbpConnectedStoreIds = this.list
      .filter((store) => store.is_connected_gmb)
      .map((store) => store.id)
      .toSet();

    return storeIds.filter((storeId) => gbpConnectedStoreIds.includes(storeId));
  }

  /**
   * グループ内で最頻出のカテゴリーのIDを返す
   *
   * GBP連携店舗がない場合undefinedが返る
   * @returns 最頻出のカテゴリーのIDを返す
   */
  getMostCommonCategoryId() {
    const categoryIds = this.list
      .map((store) => store.location.primaryCategory.categoryId)
      .filter((categoryId) => !!categoryId)
      .toArray();

    return getMostCommon(categoryIds);
  }

  findCategory(categoryId: string) {
    const categories = this.list.map((store) => store.location.primaryCategory);
    return categories.find((category) => category.categoryId === categoryId);
  }

  getGroupedCategory() {
    const categories = this.list.map((store) => store.location.primaryCategory);
    return categories
      .filter((category) => !!category.categoryId)
      .groupBy((category) => category.categoryId)
      .toList();
  }
}
