import { List, Set } from 'immutable';
import { useSelector } from 'react-redux';

import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { User } from 'models/Domain/User';
import { State } from 'modules/reducers';
import { Group } from 'types/Common';

export const useInitialStoreIds = (
  group: Group,
  containsClosedStores = false,
  onlyGbpConnectedStores = true,
  onlyMyStore = false,
): Set<number> | undefined => {
  // 管理店舗・所属店舗
  const stores: Stores = useSelector((state: State) => state.store.stores);
  const storeLists: StoreLists = useSelector((state: State) => state.storeList.storeLists);
  const currentUser: User = useSelector((state: State) => state.app.currentUser);

  if (currentUser.id === 0 || !stores.initialized || !storeLists.initialized) {
    return undefined;
  }

  const myStores: List<number> = currentUser.isSvUser
    ? currentUser.managing_stores
    : currentUser.isMemberUser
      ? currentUser.stores
      : stores.list.map((store) => store.id);

  let filteredStoreLists: StoreLists = storeLists;

  // showGbpConnectedStoresOnlyフラグがtrueのとき、GBP連携済み店舗を含まないグループを表示しない
  if (onlyGbpConnectedStores) {
    filteredStoreLists = filteredStoreLists.filterByIncludingConnectedGmbStore(stores);
  }

  // showMyStoreOnlyフラグがtrueのとき、店舗スタッフ・SVは所属/管理店舗以外の店舗を表示しない
  if (onlyMyStore) {
    filteredStoreLists = filteredStoreLists.filterByMyStore(myStores);
  }

  let targetFilterStores = stores;
  if (typeof group === 'number') {
    // グループIDの場合
    const storelist = filteredStoreLists.findStoreList(group);
    targetFilterStores = targetFilterStores.filterStoresById(storelist ? storelist.stores.toArray() : []);
  } else if (group === 'my_store') {
    // 所属店舗(店舗ユーザー)、管理店舗(SVユーザー)の場合
    targetFilterStores = targetFilterStores.filterStoresById(myStores.toArray());
  }
  // 閉店店舗を表示しないための絞り込み
  if (!containsClosedStores) {
    targetFilterStores = targetFilterStores.filterByUnclosed();
  }
  // GBP連携済みのみを表示するための絞り込み
  if (onlyGbpConnectedStores) {
    targetFilterStores = targetFilterStores.filterByIsConnectedGmb();
  }
  // 管理/所属店舗のみ表示するための絞り込み
  if (onlyMyStore) {
    targetFilterStores = targetFilterStores.filterStoresById(myStores.toArray());
  }
  return Set(targetFilterStores.list.map((target) => target.id).toArray());
};
