import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

export type TagInputProps = {
  className?: string;
  values: string[];
  pasteDelimiter?: string | RegExp;
  onChange: (values: string[]) => void;
} & Omit<
  React.ComponentProps<typeof Dropdown>,
  'multiple' | 'search' | 'clearable' | 'selection' | 'allowAdditions' | 'options' | 'onChange'
>;

export const TagInput = ({
  className,
  values,
  pasteDelimiter = /\r\n|\n|\t/,
  onChange,
  ...restProps
}: TagInputProps) => {
  const options = useMemo(() => values.map((tag) => ({ text: tag, value: tag })), [values]);
  const handleOnChange = useCallback(
    (_: any, { value }: { value: string[] }) => {
      onChange(value);
    },
    [onChange],
  );
  const handleOnBlur = useCallback(
    (event: any, { value }: { value: string[] }) => {
      const inputText = event.target.value;
      if (!inputText || value.includes(inputText)) {
        return;
      }
      onChange([...value, inputText]);
    },
    [onChange],
  );

  // 貼り付け対応
  // インプット要素を特定できるように一意で識別できるidを払い出す
  const id = useMemo(() => `taginput-${Math.random().toString(32).substring(2)}`, []);

  // イベントリスナーの切り替えが頻繁に起こらないように、設定されている値にはrefを通してアクセスする
  const valuesRef = useRef(values);
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  // テキスト貼り付け時のイベントリスナーの定義および登録
  useEffect(() => {
    const inputElement = document
      .evaluate(`//*[@id="${id}"]//input[@class="search"]`, document, null, XPathResult.ANY_TYPE, null)
      .iterateNext();

    if (!inputElement) {
      return;
    }

    const handler = (event: ClipboardEvent) => {
      event.preventDefault();
      const text = event.clipboardData?.getData('text/plain') ?? '';
      const pasteValues = text.split(pasteDelimiter).filter((v) => v);
      onChange(Array.from(new Set([...valuesRef.current, ...pasteValues])));
    };

    // 入力要素の貼り付け操作に対してイベントリスナーを設定する
    inputElement.addEventListener('paste', handler as EventListener);
    return () => {
      inputElement.removeEventListener('paste', handler as EventListener);
    };
  }, [id, onChange, pasteDelimiter]);

  return (
    <Wrapper id={id} className={className}>
      <StyledDropdown
        multiple
        search
        clearable={false}
        selection={false}
        allowAdditions
        value={values}
        options={options}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        {...restProps}
      />
    </Wrapper>
  );
};

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 100%;

    .menu {
      /* ポップアップメニューを消す */
      display: none !important;
    }
    .sizer {
      /* 入力している内容のサイズを測る要素
         display: noneにしてしまうと入力欄も潰れてしまうので、非表示＆position:absoluteで邪魔にならないように */
      visibility: hidden;
      position: absolute;
    }
    .dropdown.icon {
      display: none;
    }
    .ui.label {
      word-break: break-all;
    }
  }
`;
const Wrapper = styled.div`
  width: 100%;
  background: white;
  border: 1px solid #a9a9a9;
  border-radius: 8px;
`;
