import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { List, Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  MapSearchRanksAverageGraphApi,
  MapSearchRanksCompetitorsAverageApi,
  MapSearchRanksGraphApi,
  MapSearchRanksTableApi,
} from 'ApiClient/GmbApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import {
  MapSearchRankGraphAverage,
  MapSearchRankGraphData,
  MapSearchRankGraphItem,
  MapSearchRankGraphItemList,
  MapSearchRankTableData,
} from 'models/Domain/MapSearchRank/MapSearchRank';
import { MapSearchRankCompetitorAverageData } from 'models/Domain/MapSearchRank/MapSearchRankCompetitorAverage';
import { MapSearchRankSearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { MapSearchRankActions } from './action';

export default function* saga() {
  yield takeLatest(MapSearchRankActions.initializePage, initializePage);
  yield takeLatest(MapSearchRankActions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(MapSearchRankActions.updateSelectedTableData, updateSelectedTableData);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const isPreparedPage: boolean = yield select((state: State) => state.mapSearchRank.isPreparedPage);

  yield put(MapSearchRankActions.setIsSelectedAverage(true));
  yield put(MapSearchRankActions.setSelectedConfigIds(Set()));

  // 初期化済みの場合は以下の処理を行わない
  if (isPreparedPage) {
    return;
  }

  yield put(MapSearchRankActions.setIsPreparedPage(true));

  // URLパラメータから検索条件を復元する
  yield call(updateMapSearchRank);

  yield put(MapSearchRankActions.setIsInitializedSearchCondition(true));
}

function* commitSearchCondition(action: ReturnType<typeof MapSearchRankActions.commitSearchCondition>) {
  const searchCondition = action.payload;
  const location: Location = yield select((state: RouterRootState) => state.router.location);
  // 既存とURLに差分がある場合、URLの更新を行う
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(pushWithOrganizationId(path));
  }
  yield put(MapSearchRankActions.setSearchCondition(searchCondition));
}

/**
 * URLパラメータから検索条件を復元する
 */
function* updateMapSearchRank() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  // URLの置き換え
  let searchCondition = MapSearchRankSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: searchCondition.filter.showClosedStores,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  yield put(MapSearchRankActions.setSearchCondition(searchCondition));

  yield put(MapSearchRankActions.setIsLoadingGraphData(true));
  yield put(MapSearchRankActions.setIsLoadingTableData(true));

  if (searchCondition.filter.aggregateType === 'competitor') {
    // aggregateTypeが'competitor'の場合は、競合比較のデータを取得する
    // ただし、取得に時間がかかるのでデータの更新が不要な場合は取得しない
    const needsUpdate: boolean = yield select((state: State) => state.mapSearchRank.needsUpdateCompetitorAverageData);
    if (needsUpdate) {
      yield call(fetchCompetitorAverageData);
      yield put(MapSearchRankActions.setNeedsUpdateCompetitorAverageData(false));
    }
  } else {
    // aggregateTypeが指定なしの場合は、クエリ単位のデータを取得する
    yield call(fetchTableData);
    yield call(fetchGraphData);
  }

  yield put(MapSearchRankActions.setIsLoadingTableData(false));
  yield put(MapSearchRankActions.setIsLoadingGraphData(false));
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // Pathが検索順位監視画面以外の場合は何もしない
  if (location.pathname !== Path.mapSearchRank.index) {
    return;
  }

  // 検索順位監視画面の初期化処理が完了していない場合は何もしない
  const isPreparedPage: boolean = yield select((state: State) => state.mapSearchRank.isPreparedPage);
  if (!isPreparedPage) {
    return;
  }

  yield call(updateMapSearchRank);
}

function* fetchTableData() {
  yield put(MapSearchRankActions.setIsLoadingTableData(true));
  const searchCondition: MapSearchRankSearchCondition = yield select(
    (state: State) => state.mapSearchRank.searchCondition,
  );
  const params = searchCondition.toTableDataRequestParams();
  const response: YieldReturn<typeof MapSearchRanksTableApi.get> = yield MapSearchRanksTableApi.get(params);
  if (response.isSuccess) {
    // 新しいテーブルデータをセットする
    const newTableData = MapSearchRankTableData.fromJSON(response.data);
    yield put(MapSearchRankActions.setTableData(newTableData));

    // 既に選択していたデータが新しいデータにも含まれていたら、選択状態にしておく
    // ひとつも選択されていない場合は、平均を選択状態にする
    const newConfigIds = newTableData.items.map((item) => item.configId).toSet();
    const currentIsSelectedAverage: boolean = yield select((state: State) => state.mapSearchRank.isSelectedAverage);
    const currentSelectedConfigIds: Set<number> = yield select((state: State) => state.mapSearchRank.selectedConfigIds);
    const newSelectedConfigIds = currentSelectedConfigIds.filter((configId) => newConfigIds.includes(configId));
    const newIsSelectedAverage = currentIsSelectedAverage || newSelectedConfigIds.size === 0;
    yield put(MapSearchRankActions.setIsSelectedAverage(newIsSelectedAverage));
    yield put(MapSearchRankActions.setSelectedConfigIds(newSelectedConfigIds));
  } else {
    toast({
      type: 'error',
      title: 'テーブルデータの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(MapSearchRankActions.setIsLoadingTableData(false));
}

function* fetchGraphData() {
  yield put(MapSearchRankActions.setIsLoadingGraphData(true));
  // 平均、店舗×キーワード、比較の選択状況を元に、グラフのデータを取得する
  const searchCondition: MapSearchRankSearchCondition = yield select(
    (state: State) => state.mapSearchRank.searchCondition,
  );
  const isSelectedAverage: Set<number> = yield select((state: State) => state.mapSearchRank.isSelectedAverage);
  const configIds: Set<number> = yield select((state: State) => state.mapSearchRank.selectedConfigIds);
  // 平均
  const average: MapSearchRankGraphAverage | null = isSelectedAverage ? yield call(fetchAverageGraphData, false) : null;
  // 平均（比較）
  const comparisonAverage: MapSearchRankGraphAverage | null =
    isSelectedAverage && searchCondition.filter.isEnabledComparison ? yield call(fetchAverageGraphData, true) : null;
  // 同じパラメータでAPIを叩けばキャッシュが効くので、config_idを元に１つずつ取得する
  // 店舗×キーワードのデータ
  const itemCalls: any[] = [];
  yield configIds.forEach((configId) => itemCalls.push(call(fetchGraphItemData, configId, false)));
  const items: List<MapSearchRankGraphItem> = List(yield all(itemCalls));
  const itemList = new MapSearchRankGraphItemList({ list: items.filter((x) => x) });
  // 店舗×キーワードのデータ（比較）
  const comparisonItemCalls: any[] = [];
  yield configIds.forEach((configId) => comparisonItemCalls.push(call(fetchGraphItemData, configId, true)));
  const comparisonItems: List<MapSearchRankGraphItem> = List(yield all(comparisonItemCalls));
  const comparisonItemList = new MapSearchRankGraphItemList({ list: comparisonItems.filter((x) => x) });
  // 4つのデータを組み合わせて新しいグラフデータとしてセットする
  const newGraphDate = new MapSearchRankGraphData({
    average,
    comparisonAverage,
    items: itemList,
    comparisonItems: comparisonItemList,
  });

  yield put(MapSearchRankActions.setGraphData(newGraphDate));
  yield put(MapSearchRankActions.setIsLoadingGraphData(false));
}

/**
 * 平均のグラフデータを取得する
 * @param isComparison 比較期間か
 */
function* fetchAverageGraphData(isComparison: boolean) {
  const searchCondition: MapSearchRankSearchCondition = yield select(
    (state: State) => state.mapSearchRank.searchCondition,
  );
  const params = searchCondition.toAverageGraphDataRequestParams(isComparison);
  const response: YieldReturn<typeof MapSearchRanksAverageGraphApi.get> =
    yield MapSearchRanksAverageGraphApi.get(params);
  if (response.isSuccess) {
    return MapSearchRankGraphAverage.fromJSON(response.data);
  } else {
    toast({
      type: 'error',
      title: 'グラフデータの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  return null;
}

/**
 * 店舗×キーワードのグラフデータを取得する
 * @param configId 設定のID
 * @param isComparison 比較期間か
 */
function* fetchGraphItemData(configId: number, isComparison: boolean) {
  const searchCondition: MapSearchRankSearchCondition = yield select(
    (state: State) => state.mapSearchRank.searchCondition,
  );
  const params = searchCondition.toGraphItemDataRequestParams(configId, isComparison);
  const response: YieldReturn<typeof MapSearchRanksGraphApi.get> = yield MapSearchRanksGraphApi.get(params);
  if (response.isSuccess) {
    // １つずつで取得しているので、最初のitemを取得する
    const item = response.data?.items?.[0];
    if (item) {
      return MapSearchRankGraphItem.fromJSON(item);
    }
  } else {
    toast({
      type: 'error',
      title: 'グラフデータの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  return null;
}

/**
 * 競合比較のグラフ・テーブルデータを取得する
 */
function* fetchCompetitorAverageData() {
  const searchCondition: MapSearchRankSearchCondition = yield select(
    (state: State) => state.mapSearchRank.searchCondition,
  );
  const params = searchCondition.toCompetitorAverageStatRequestParams();

  const response: YieldReturn<typeof MapSearchRanksCompetitorsAverageApi.get> =
    yield MapSearchRanksCompetitorsAverageApi.get(params);

  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: '競合比較データの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }

  const data = MapSearchRankCompetitorAverageData.fromJSON(response.data);
  yield put(MapSearchRankActions.setCompetitorAverageData(data));
  // すべてのタグを選択状態にする
  const selectedTags = data.tableData.items.map((item) => item.tag).toSet();
  yield put(MapSearchRankActions.setSelectedTags(selectedTags));
}

function* updateSelectedTableData(action: ReturnType<typeof MapSearchRankActions.updateSelectedTableData>) {
  const { isSelectedAverage, selectedConfigIds } = action.payload;
  if (isSelectedAverage != null) {
    yield put(MapSearchRankActions.setIsSelectedAverage(isSelectedAverage));
  }
  if (selectedConfigIds != null) {
    yield put(MapSearchRankActions.setSelectedConfigIds(selectedConfigIds));
  }
  yield call(fetchGraphData);
}
