import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { YearMonth } from 'models/Domain/YearMonth';
import { JSObject, SortOrder, assertNever } from 'types/Common';

import { SearchVolumeSearchResults, SortKey } from './SearchVolumeSearchResult';

import { Condition, Conditions, Status } from './index';

export class SearchVolumeSearchStatusItem extends ImmutableRecord<{
  condition: Condition;
  url: string | null;
}>({
  condition: new Condition(),
  url: null,
}) {
  static fromJSON(data: JSObject) {
    return new SearchVolumeSearchStatusItem({
      condition: Condition.fromJSON(data.condition),
      url: data.url,
    });
  }
}

const comparator = <S, T extends string | number | null | undefined>(
  a: S,
  b: S,
  getValue: (item: S) => T,
  sortOrder: SortOrder,
): number => {
  const valueA = getValue(a);
  const valueB = getValue(b);

  // nullの場合は、値のあるデータより後ろに表示する
  if (valueA === valueB) {
    return 0;
  } else if (valueA == null) {
    return 1;
  } else if (valueB == null) {
    return -1;
  }

  if (sortOrder === 'asc') {
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  } else {
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }
};

export class SearchVolumeSearchStatus extends ImmutableRecord<{
  status: Status;
  date: Dayjs;
  userId: number;
  conditions: Conditions;
  items: ImmutableList<SearchVolumeSearchStatusItem>;
}>({
  status: 'RUNNING',
  date: dayjs(),
  userId: 0,
  conditions: new Conditions(),
  items: ImmutableList(),
}) {
  static fromJSON(data: JSObject) {
    return new SearchVolumeSearchStatus({
      status: data.status,
      date: dayjs(data.date),
      userId: data.user_id,
      conditions: Conditions.fromJSON(data.conditions),
      items: data.result.items
        ? ImmutableList(data.result.items.map((item: JSObject) => SearchVolumeSearchStatusItem.fromJSON(item)))
        : ImmutableList(),
    });
  }

  isRunning() {
    return this.status === 'RUNNING' || this.status === 'QUEUED';
  }

  getSortedItems(
    sortKey: SortKey,
    sortOrder: SortOrder,
    latestMonth: YearMonth,
    searchResults: SearchVolumeSearchResults,
  ) {
    let items = this.items;
    switch (sortKey) {
      case 'searchWord':
        items = items.sort((a, b) => comparator(a, b, (item) => item.condition.searchWord.name, sortOrder));
        break;
      case 'inputArea':
        items = items.sort((a, b) => comparator(a, b, (item) => item.condition.area.areaName, sortOrder));
        break;
      case 'searchArea':
        items = items.sort((a, b) =>
          comparator(a, b, (item) => searchResults.getData(item.url)?.geoTarget.canonicalName, sortOrder),
        );
        break;
      case 'average':
        items = items.sort((a, b) =>
          comparator(
            a,
            b,
            (item) => {
              const { startMonth, endMonth } = item.condition.period;
              return searchResults.getMetrics(item.url)?.getAverage(startMonth, endMonth);
            },
            sortOrder,
          ),
        );
        break;
      case 'latest':
        items = items.sort((a, b) =>
          comparator(a, b, (item) => searchResults.getMetrics(item.url)?.getDataByMonth(latestMonth), sortOrder),
        );
        break;
      case 'momRatio':
        items = items.sort((a, b) =>
          comparator(a, b, (item) => searchResults.getMetrics(item.url)?.getGrowthRatio(latestMonth, 1), sortOrder),
        );
        break;
      case 'threeMomRatio':
        items = items.sort((a, b) =>
          comparator(a, b, (item) => searchResults.getMetrics(item.url)?.getGrowthRatio(latestMonth, 3), sortOrder),
        );
        break;
      case 'twelveMomRatio':
        items = items.sort((a, b) =>
          comparator(a, b, (item) => searchResults.getMetrics(item.url)?.getGrowthRatio(latestMonth, 12), sortOrder),
        );
        break;
      default:
        return assertNever(sortKey);
    }

    return items;
  }
}
