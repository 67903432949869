import React from 'react';

import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  SortTriangle,
  SortableTableHeaderCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from 'components/molecules/Table';
import { GbpPerformanceHelp } from 'helpers/ContextHelp';
import { SortKey, SortType } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';

import { SortCondition } from '.';

type TableHeaderProps = {
  sortCondition: SortCondition;
  toggleSortKey: (key: SortKey, type?: SortType) => void;
};

export const OverviewTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell border={2} colSpan={2} onClick={() => toggleSortKey('organizationName')}>
          <Sort sortKey='organizationName' sortCondition={sortCondition} />
          アカウント
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('impressions')}>
          <Sort sortKey='impressions' sortCondition={sortCondition} />
          ユーザー数
          <ContextHelp header={'ユーザー数'} content={GbpPerformanceHelp.businessImpressions} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('interactions', 'number')}>
          <Sort sortKey='interactions' sortType='number' sortCondition={sortCondition} />
          インタラクション数
          <ContextHelp header={'インタラクション数'} content={GbpPerformanceHelp.interactions} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('interactions', 'rate')}>
          <Sort sortKey='interactions' sortType='rate' sortCondition={sortCondition} />
          インタラクション率
          <ContextHelp header={'インタラクション率'} content={GbpPerformanceHelp.interactionsRate} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodReviews', 'number')}>
          <Sort sortKey='periodReviews' sortType='number' sortCondition={sortCondition} />
          クチコミ数
          <ContextHelp header={'クチコミ数'} content={GbpPerformanceHelp.reviews} />
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

export const ImpressionsTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell
          border={2}
          colSpan={2}
          rowSpan={2}
          onClick={() => toggleSortKey('organizationName')}
        >
          <Sort sortKey='organizationName' sortCondition={sortCondition} />
          アカウント
        </StyledSortableTableHeaderCell>
        <StyledTableHeaderCell colSpan={1}>ユーザー数</StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={2}>Googleマップ</StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={2}>Google検索</StyledTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('interactions', 'number')}>
          <Sort sortKey='interactions' sortType='number' sortCondition={sortCondition} />
          インタラクション数
          <ContextHelp header={'インタラクション数'} content={GbpPerformanceHelp.interactions} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('interactions', 'rate')}>
          <Sort sortKey='interactions' sortType='rate' sortCondition={sortCondition} />
          インタラクション率
          <ContextHelp header={'インタラクション率'} content={GbpPerformanceHelp.interactionsRate} />
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('impressions')}>
          <Sort sortKey='impressions' sortCondition={sortCondition} />
          合計
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('desktopMaps')}>
          <Sort sortKey='desktopMaps' sortCondition={sortCondition} />
          パソコン
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('mobileMaps')}>
          <Sort sortKey='mobileMaps' sortCondition={sortCondition} />
          モバイル
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('desktopSearch')}>
          <Sort sortKey='desktopSearch' sortCondition={sortCondition} />
          パソコン
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('mobileSearch')}>
          <Sort sortKey='mobileSearch' sortCondition={sortCondition} />
          モバイル
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

export const InteractionTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell
          border={2}
          colSpan={2}
          rowSpan={2}
          onClick={() => toggleSortKey('organizationName')}
        >
          <Sort sortKey='organizationName' sortCondition={sortCondition} />
          アカウント
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell rowSpan={2} onClick={() => toggleSortKey('impressions')}>
          <Sort sortKey='impressions' sortCondition={sortCondition} />
          ユーザー数
          <ContextHelp header={'ユーザー数'} content={GbpPerformanceHelp.businessImpressions} />
        </StyledSortableTableHeaderCell>
        <StyledTableHeaderCell colSpan={12}>インタラクション（数 / 率）</StyledTableHeaderCell>
      </TableHeaderRow>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('interactions')}>
          合計 {<MultiTypeSort sortKey='interactions' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('callClicks')}>
          通話 {<MultiTypeSort sortKey='callClicks' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('directionRequests')}>
          ルート {<MultiTypeSort sortKey='directionRequests' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('websiteClicks')}>
          ウェブサイトのクリック {<MultiTypeSort sortKey='websiteClicks' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('conversations')}>
          メッセージ {<MultiTypeSort sortKey='conversations' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell colSpan={2} onClick={() => toggleSortKey('bookings')}>
          予約 {<MultiTypeSort sortKey='bookings' sortCondition={sortCondition} />}
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

export const PeriodReviewTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell
          border={2}
          colSpan={2}
          rowSpan={2}
          onClick={() => toggleSortKey('organizationName')}
        >
          <Sort sortKey='organizationName' sortCondition={sortCondition} />
          アカウント
        </StyledSortableTableHeaderCell>
        <StyledTableHeaderCell colSpan={3}>
          クチコミ数
          <ContextHelp header={'クチコミ数'} content={GbpPerformanceHelp.periodReviews} />
        </StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={1}>
          返信
          <ContextHelp header={'返信'} content={GbpPerformanceHelp.periodReviewReplyCount} />
        </StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={2}>評価</StyledTableHeaderCell>
      </TableHeaderRow>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodReviews')}>
          合計
          <Sort sortKey='periodReviews' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodCommentCount')}>
          コメントあり
          <Sort sortKey='periodCommentCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodRateCount')}>
          評価のみ
          <Sort sortKey='periodRateCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodReplyCount')}>
          返信数
          <Sort sortKey='periodReplyCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodAverageRating')}>
          期間平均
          <ContextHelp header={'評価（期間平均）'} content={GbpPerformanceHelp.periodReviewAverageRating} />
          <Sort sortKey='periodAverageRating' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('totalAverageRating')}>
          累計平均
          <ContextHelp header={'評価（累計平均）'} content={GbpPerformanceHelp.totalReviewAverageRating} />
          <Sort sortKey='totalAverageRating' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

export const TotalReviewTableHeader: React.FC<TableHeaderProps> = ({ sortCondition, toggleSortKey }) => {
  return (
    <StyledTableHeader>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell
          border={2}
          colSpan={2}
          rowSpan={2}
          onClick={() => toggleSortKey('organizationName')}
        >
          <Sort sortKey='organizationName' sortCondition={sortCondition} />
          アカウント
        </StyledSortableTableHeaderCell>
        <StyledTableHeaderCell colSpan={3}>
          クチコミ数（累計）
          <ContextHelp header={'クチコミ数（累計）'} content={GbpPerformanceHelp.totalReviews} />
        </StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={1}>
          返信
          <ContextHelp header={'返信（累計）'} content={GbpPerformanceHelp.totalReviewReplyCount} />
        </StyledTableHeaderCell>
        <StyledTableHeaderCell colSpan={2}>評価</StyledTableHeaderCell>
      </TableHeaderRow>
      <TableHeaderRow>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('totalReviews')}>
          合計
          <Sort sortKey='totalReviews' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('totalCommentCount')}>
          コメントあり
          <Sort sortKey='totalCommentCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('totalRateCount')}>
          評価のみ
          <Sort sortKey='totalRateCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('totalReplyCount')}>
          返信数
          <Sort sortKey='totalReplyCount' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('periodAverageRating')}>
          期間平均
          <ContextHelp header={'評価（期間平均）'} content={GbpPerformanceHelp.periodReviewAverageRating} />
          <Sort sortKey='periodAverageRating' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
        <StyledSortableTableHeaderCell onClick={() => toggleSortKey('totalAverageRating')}>
          累計平均
          <ContextHelp header={'評価（累計平均）'} content={GbpPerformanceHelp.totalReviewAverageRating} />
          <Sort sortKey='totalAverageRating' sortCondition={sortCondition} />
        </StyledSortableTableHeaderCell>
      </TableHeaderRow>
    </StyledTableHeader>
  );
};

const Sort: React.FC<{ sortKey: SortKey; sortType?: SortType; sortCondition: SortCondition }> = ({
  sortKey,
  sortType = 'number',
  sortCondition,
}) => (
  <SortWrapper>
    {sortKey === sortCondition?.key && sortType === sortCondition?.type && (
      <Triangle isSortOrderDesc={sortCondition.order === 'desc'} />
    )}
  </SortWrapper>
);

const MultiTypeSort: React.FC<{ sortKey: SortKey; sortCondition: SortCondition }> = ({ sortKey, sortCondition }) => {
  if (sortKey !== sortCondition?.key) {
    return <></>;
  }
  return (
    <>
      （{<Sort sortKey={sortKey} sortType={'number'} sortCondition={sortCondition} />}数 /
      {<Sort sortKey={sortKey} sortType={'rate'} sortCondition={sortCondition} />}率）
    </>
  );
};

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const StyledTableHeaderCell = styled(TableHeaderCell)<{ border?: number }>`
  &&& {
    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)<{ border?: number }>`
  &&& {
    user-select: none;

    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
  }
`;

const SortWrapper = styled.div`
  display: inline-block;
  width: 16px;
`;

const Triangle = styled(SortTriangle)`
  display: inline-block;
  vertical-align: middle;
`;
