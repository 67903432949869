import { List, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import Notification from 'models/Domain/Notification';

import { NotificationActions } from './actions';

export interface StateRecord {
  notificationList: List<Notification>;
}

export class NotificationState extends Record<StateRecord>({
  notificationList: List(),
}) {}

export const notificationReducer = reducerWithInitialState(new NotificationState()).case(
  NotificationActions.setNotificationList,
  (state, data) => {
    return state.set('notificationList', data);
  },
);
