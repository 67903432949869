import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Competitor } from 'models/Domain/Competitor/Competitor';
import { CompetitorSearchResult as SearchResult } from 'models/Domain/Competitor/CompetitorSearchResult';
import { Stores } from 'models/Domain/Store';

import { CompetitorTable } from './CompetitorTable';

type Props = {
  className?: string;
  searchResults: List<SearchResult>;
  stores: Stores;
  filterWords: List<string>;
  selectedCompetitors: List<Competitor>;
  registeredCompetitors: List<Competitor>;
  onSelectCompetitor: (competitor: Competitor) => void;
};

export const CompetitorSearchResult = React.memo<Props>(
  ({
    className,
    stores,
    searchResults,
    filterWords,
    selectedCompetitors,
    registeredCompetitors,
    onSelectCompetitor,
  }) => {
    return (
      <Wrapper className={className}>
        {searchResults.map((searchResult) => (
          <CompetitorTable
            key={searchResult.storeId}
            isLoading={searchResult.isLoading}
            isError={searchResult.isError}
            stores={stores}
            searchResult={searchResult}
            filterWords={filterWords}
            selectedCompetitors={selectedCompetitors}
            registeredCompetitors={registeredCompetitors}
            onSelectCompetitor={onSelectCompetitor}
          />
        ))}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;
