import ApiClient from 'ApiClient';
import { OrganizationFeature, OrganizationPlan } from 'models/Domain/Organization';

const apiClient = new ApiClient({});

export type PatchOrganizationFeaturesParams = {
  plan: OrganizationPlan;
  included_features: OrganizationFeature[];
  excluded_features: OrganizationFeature[];
};

type Organization = {
  id: number;
  name: string;
  features: PatchOrganizationFeaturesParams;
  params: {
    map_search_rank_config_limit: number | null;
    can_use_gbp_media_product_category: boolean | null;
    gbp_check_days_of_duration: number | null;
    gbp_check_days_of_review_reply_deadline: number | null;
    localpost_translation_languages: string[] | null;
  };
  contracted_stores: number;
};

export type PatchOrganizationParams = Omit<Organization, 'id'>;

export type PatchOrganizationResponse = Organization;

export class OrganizationApi {
  static endpoint = '/organizations';

  /**
   * 組織情報を変更する
   * @param id
   * @param params
   */
  static patch(id: number, params: PatchOrganizationParams) {
    return apiClient.patch<PatchOrganizationResponse>(`${this.endpoint}/${id}`, params);
  }
}
