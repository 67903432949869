import React from 'react';

import { Icon as SemanticUIIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';

export type PromotionFormMessageProps = {
  messages: string | string[];
};

const messagesToArray = (messages: string | string[]) => {
  if (typeof messages === 'string') {
    return [messages];
  }
  return messages;
};

export const PromotionFormWarningMessages: React.FC<PromotionFormMessageProps> = ({ messages }) => (
  <>
    {messagesToArray(messages).map((message, i) => (
      <WarningDescription key={i}>
        <SemanticUIIcon name='exclamation triangle' />
        {message}
      </WarningDescription>
    ))}
  </>
);

export const PromotionFormErrorMessages: React.FC<PromotionFormMessageProps> = ({ messages }) => (
  <>
    {messagesToArray(messages).map((message, i) => (
      <ErrorDescription key={i}>
        <SemanticUIIcon name='exclamation triangle' />
        {message}
      </ErrorDescription>
    ))}
  </>
);

const FormContentDescription = styled.div`
  font-size: 14px;
  margin: 4px 8px;

  i {
    height: auto;
  }
`;

const WarningDescription = styled(FormContentDescription)`
  color: #f09061;
`;

const ErrorDescription = styled(FormContentDescription)`
  color: ${COLOR.ERROR};
`;
