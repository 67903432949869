import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { Pagination } from 'models/Domain/Pagination';
import { JSObject } from 'types/Common';

import { GoogleLocation, GoogleLocationSummary } from '../GoogleLocation';

export class Competitor extends ImmutableRecord<{
  id: number;
  organizationId: number;
  storeId: number;
  placeId: string;
  name: string;
  pastPlaceIds: ImmutableList<string>;
  tags: ImmutableList<string>;
  googleLocationSummary: GoogleLocationSummary | null;
  googleLocation: GoogleLocation | null;
}>({
  id: 0,
  organizationId: 0,
  storeId: 0,
  placeId: '',
  name: '',
  pastPlaceIds: ImmutableList(),
  tags: ImmutableList(),
  googleLocationSummary: null,
  googleLocation: null,
}) {
  static fromJSON(data: JSObject): Competitor {
    return new Competitor({
      id: data.competitor.id,
      organizationId: data.competitor.organization_id,
      storeId: data.competitor.store_id,
      placeId: data.competitor.place_id,
      name: data.competitor.name,
      pastPlaceIds: ImmutableList(data.competitor.past_place_ids || []),
      tags: ImmutableList(data.competitor.tags),
      googleLocationSummary:
        data.google_location_summary && GoogleLocationSummary.fromJSON(data.google_location_summary),
      googleLocation: data.google_location && GoogleLocation.fromJSON(data.google_location),
    });
  }

  toParams() {
    return {
      id: this.id === 0 ? null : this.id,
      organization_id: this.organizationId,
      store_id: this.storeId,
      place_id: this.placeId,
      name: this.name,
      past_place_ids: this.pastPlaceIds.toArray(),
      tags: this.tags.toArray(),
    };
  }

  get url() {
    return `https://www.google.com/maps/place/?q=place_id:${this.placeId}`;
  }
}

export class Competitors extends ImmutableRecord<{
  items: ImmutableList<Competitor>;
  googleLocationLatestUpdateAt: Dayjs | null;
  pagination: Pagination;
}>({
  items: ImmutableList(),
  googleLocationLatestUpdateAt: null,
  pagination: new Pagination(),
}) {
  static fromJSON(data: JSObject): Competitors {
    return new Competitors({
      items: ImmutableList(data.items.map((item: JSObject) => Competitor.fromJSON(item))),
      googleLocationLatestUpdateAt:
        data.google_location_latest_data_update_at && dayjs(data.google_location_latest_data_update_at),
      pagination: Pagination.fromJSON(data.pagination),
    });
  }

  get totalCount() {
    return this.pagination.total_count;
  }

  get restCount() {
    return Math.max(0, this.totalCount - this.items.size);
  }

  findById(id: number) {
    return this.items.find((item) => item.id === id);
  }
}
