import dayjs, { Dayjs } from 'dayjs';
import { Record as ImmutableRecord, is } from 'immutable';

import { OmoInsightGraphApiParams, OmoInsightSummaryApiParams } from 'ApiClient/OmoApi';
import { dateToSearchParam, parseDateParameter } from 'helpers/search';
import { AggregateUnit } from 'types/Common';

import { DateRangeConfig } from '../DateRangeConfig';

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType, 'isEnabledComparison'>]: string;
} = {
  aggregateUnit: 'au',
  startDate: 'sd',
  endDate: 'ed',
  comparisonStartDate: 'csd',
  comparisonEndDate: 'ced',
  selectedMerchantId: 'mid',
};

// FIXME
// デフォルト値をモジュールレベルで定義してしまうと、テスト時に値(maxDateのdayjs())が固定できないので
// 関数の形にして利用するときに生成するようにしている
export const defaultDates = () => {
  const MAX_DATE = dayjs().startOf('day');
  const DEFAULT_END_DATE = MAX_DATE.subtract(1, 'day');
  const DEFAULT_START_DATE = DEFAULT_END_DATE.subtract(DEFAULT_DAY_OPTION - 1, 'day');
  // 比較期間は「前の期間」DEFAULT_START_DATEの7日前からDEFAULT_START_DATEの前日まで
  const DEFAULT_COMPARISON_END_DATE = DEFAULT_START_DATE.subtract(1, 'day');
  const DEFAULT_COMPARISON_START_DATE = DEFAULT_START_DATE.subtract(DEFAULT_DAY_OPTION, 'day');
  return {
    MAX_DATE,
    DEFAULT_START_DATE,
    DEFAULT_END_DATE,
    DEFAULT_COMPARISON_START_DATE,
    DEFAULT_COMPARISON_END_DATE,
  };
};

// 初期値は集計単位「日」の「過去7日間」
const DEFAULT_AGGREGATE_UNIT: AggregateUnit = 'day';
const DEFAULT_DAY_OPTION = 7;
export const DEFAULT_IS_ENABLED_COMPARISON = false;

type FilterStatusType = {
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs;
  comparisonEndDate: Dayjs;
  isEnabledComparison: boolean;
  selectedMerchantId: string | null;
};

export class FilterStatus extends ImmutableRecord<FilterStatusType>({
  aggregateUnit: DEFAULT_AGGREGATE_UNIT,
  startDate: defaultDates().DEFAULT_START_DATE,
  endDate: defaultDates().DEFAULT_END_DATE,
  comparisonStartDate: defaultDates().DEFAULT_COMPARISON_START_DATE,
  comparisonEndDate: defaultDates().DEFAULT_COMPARISON_END_DATE,
  isEnabledComparison: DEFAULT_IS_ENABLED_COMPARISON,
  selectedMerchantId: null,
}) {}

export class OmoInsightSearchCondition extends ImmutableRecord<{
  filter: FilterStatus;
}>({
  filter: new FilterStatus(),
}) {
  /**
   * ページのURLパラメータから検索条件を生成する
   * @param search URLパラメータ
   * @returns 検索条件
   */
  static fromURLSearchParams(search: string): OmoInsightSearchCondition {
    const condition = new OmoInsightSearchCondition();
    let { filter } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連

    const { MAX_DATE } = defaultDates();

    // 集計期間
    const aggregateUnit = params.get(URLSearchParamsMapping.aggregateUnit);
    if (aggregateUnit && ['day', 'week', 'month'].includes(aggregateUnit)) {
      filter = filter.set('aggregateUnit', aggregateUnit as AggregateUnit);
    }
    const startDateStr = parseDateParameter(params.get(URLSearchParamsMapping.startDate));
    const endDateStr = parseDateParameter(params.get(URLSearchParamsMapping.endDate));
    if (startDateStr && endDateStr) {
      const startDate = dayjs(startDateStr);
      const endDate = dayjs(endDateStr);
      if (
        startDate.isValid() &&
        endDate.isValid() &&
        startDate.isSameOrBefore(endDate) &&
        endDate.isSameOrBefore(MAX_DATE)
      ) {
        filter = filter.merge({ startDate, endDate });
      }
    }

    // 比較期間
    const comparisonStartDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonStartDate));
    const comparisonEndDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonEndDate));
    if (comparisonStartDateStr && comparisonEndDateStr) {
      const comparisonStartDate = dayjs(comparisonStartDateStr);
      const comparisonEndDate = dayjs(comparisonEndDateStr);
      if (
        comparisonStartDate.isValid() &&
        comparisonEndDate.isValid() &&
        comparisonStartDate.isSameOrBefore(comparisonEndDate) &&
        comparisonEndDate.isSameOrBefore(MAX_DATE)
      ) {
        filter = filter.merge({ comparisonStartDate, comparisonEndDate, isEnabledComparison: true });
      }
    }

    // 選択しているマーチャントID
    const selectedMerchantId = params.get(URLSearchParamsMapping.selectedMerchantId) || null;
    filter = filter.merge({ selectedMerchantId });

    return condition.merge({ filter });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    // フィルタ関連

    params.append(URLSearchParamsMapping.aggregateUnit, this.filter.aggregateUnit);
    params.append(URLSearchParamsMapping.startDate, dateToSearchParam(this.filter.startDate));
    params.append(URLSearchParamsMapping.endDate, dateToSearchParam(this.filter.endDate));

    if (this.filter.isEnabledComparison) {
      params.append(URLSearchParamsMapping.comparisonStartDate, dateToSearchParam(this.filter.comparisonStartDate));
      params.append(URLSearchParamsMapping.comparisonEndDate, dateToSearchParam(this.filter.comparisonEndDate));
    }

    if (this.filter.selectedMerchantId) {
      params.append(URLSearchParamsMapping.selectedMerchantId, this.filter.selectedMerchantId);
    }

    // 見栄え悪いので、'%2Cは','に戻す
    return params.toString().replace(/%2C/g, ',');
  }
  /**
   * OMOインサイト商品数データ取得用のAPIパラメータを取得する
   * @param forComparison
   * @returns
   */

  toOmoProductStatusApiParams() {
    return {
      date: this.filter.endDate.format('YYYY-MM-DD'),
    };
  }

  /**
   * OMOインサイトデータ取得用のAPIパラメータを取得する
   * @param forComparison
   * @returns
   */
  toOmoInsightSummaryApiParams(forComparison = false): OmoInsightSummaryApiParams {
    let startDate: Dayjs, endDate: Dayjs;
    if (forComparison) {
      startDate = this.filter.comparisonStartDate;
      endDate = this.filter.comparisonEndDate;
    } else {
      startDate = this.filter.startDate;
      endDate = this.filter.endDate;
    }

    return {
      from_date: startDate.format('YYYY-MM-DD'),
      to_date: endDate.format('YYYY-MM-DD'),
    };
  }

  /**
   * OMOインサイトグラフデータ取得用のAPIパラメータを取得する
   * @param forComparison
   * @returns
   */
  toOmoInsightGraphApiParams(forComparison = false): OmoInsightGraphApiParams {
    let startDate: Dayjs, endDate: Dayjs;
    if (forComparison) {
      startDate = this.filter.comparisonStartDate;
      endDate = this.filter.comparisonEndDate;
    } else {
      startDate = this.filter.startDate;
      endDate = this.filter.endDate;
    }

    return {
      from_date: startDate.format('YYYY-MM-DD'),
      to_date: endDate.format('YYYY-MM-DD'),
      aggregate_unit: this.filter.aggregateUnit,
      merchant_id: this.filter.selectedMerchantId || '',
    };
  }

  isValid() {
    return this.isValidDateRange() && this.isValidComparisonDateRange();
  }

  isValidDateRange() {
    const startDate = this.filter.startDate;
    const endDate = this.filter.endDate;
    if (startDate && endDate) {
      return startDate.isSameOrBefore(endDate);
    }
    return !startDate && !endDate;
  }

  isValidComparisonDateRange() {
    if (!this.filter.isEnabledComparison) {
      return true;
    }
    const startDate = this.filter.comparisonStartDate;
    const endDate = this.filter.comparisonEndDate;
    if (startDate && endDate) {
      return startDate.isSameOrBefore(endDate);
    }
    return !startDate && !endDate;
  }

  /**
   * ２つの検索条件を比較して、データ更新が必要な変更が行われているかを返す
   * @param other
   * @returns
   */
  needsUpdate(other: OmoInsightSearchCondition) {
    const checkProperties: (keyof FilterStatusType)[] = [
      'aggregateUnit',
      'startDate',
      'endDate',
      'comparisonStartDate',
      'comparisonEndDate',
      'isEnabledComparison',
    ];

    for (const property of checkProperties) {
      if (!is(this.filter[property], other.filter[property])) {
        return true;
      }
    }
    return false;
  }

  get dateRangeConfig() {
    return new DateRangeConfig({
      startDate: this.filter.startDate,
      endDate: this.filter.endDate,
      comparisonStartDate: this.filter.comparisonStartDate,
      comparisonEndDate: this.filter.comparisonEndDate,
      isEnabledComparison: this.filter.isEnabledComparison,
      aggregateUnit: this.filter.aggregateUnit,
    });
  }
}
