import { LOCATION_CHANGE } from 'connected-react-router';
import { List } from 'immutable';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { NotificationBulkReadApi, NotificationListApi, NotificationReadApi } from 'ApiClient/NotificationApi';
import Notification from 'models/Domain/Notification';
import { User } from 'models/Domain/User';
import { State } from 'modules/reducers';

import { NotificationActions } from './actions';

export default function* saga() {
  yield fork(initialize);
  yield takeLatest(NotificationActions.getNotificationList, () => getNotificationList());
  yield takeLatest(NotificationActions.postNotificationRead, postNotificationRead);
  yield takeLatest(NotificationActions.postNotificationBulkRead, () => postNotificationBulkRead());
  yield takeLatest(LOCATION_CHANGE, fetchNotificationList);
}

function* initialize() {
  yield call(fetchNotificationList);
}

export function* getNotificationList() {
  const response: YieldReturn<typeof NotificationListApi.get> = yield NotificationListApi.get();
  if (response.isSuccess) {
    const notificationList: List<Notification> = List(response.data.map((d) => new Notification(d)));
    yield put(NotificationActions.setNotificationList(notificationList));
  } else {
    console.warn(response.error);
  }
}

export function* postNotificationRead(action: ReturnType<typeof NotificationActions.postNotificationRead>) {
  const notificationId = action.payload;
  const response: YieldReturn<typeof NotificationReadApi.post> = yield NotificationReadApi.post(notificationId);
  if (response.isSuccess) {
    yield put(NotificationActions.getNotificationList());
  } else {
    console.warn(response.error);
  }
}

export function* postNotificationBulkRead() {
  const response: YieldReturn<typeof NotificationBulkReadApi.post> = yield NotificationBulkReadApi.post();
  if (response.isSuccess) {
    const notificationList: List<Notification> = List(response.data.map((d) => new Notification(d)));
    yield put(NotificationActions.setNotificationList(notificationList));
  } else {
    console.warn(response.error);
  }
}

let lastFetchNotificationListTime = 0; // 前回通知一覧を取得した時刻
const FETCH_NOTIFICATION_INTERVAL = 300; // 秒 (5分)
export function* fetchNotificationList() {
  const nowTime = new Date().getTime();
  if (nowTime - lastFetchNotificationListTime > FETCH_NOTIFICATION_INTERVAL * 1000) {
    const currentUser: User = yield select((state: State) => state.app.currentUser);
    if (currentUser.isExist) {
      lastFetchNotificationListTime = nowTime;
      yield put(NotificationActions.getNotificationList());
    }
  }
}
