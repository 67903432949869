import { Dayjs } from 'dayjs';
import { Record } from 'immutable';

import { Offer, OfferType } from 'models/Domain/Offer';
import { OtherOffer } from 'models/Domain/OtherOffer';
import { Report } from 'models/Domain/Report';
import { Task } from 'models/Domain/Task';
import { JSObject } from 'types/Common';

interface OfferCommonRecord {
  offer: Offer;
  task: Task;
  report: Report;
  other_offer: OtherOffer;
}

export class OfferCommon extends Record<OfferCommonRecord>({
  offer: new Offer(),
  task: new Task(),
  report: new Report(),
  other_offer: new OtherOffer(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.offer = new Offer(params.offer);
    params.task = new Task(params.task);
    params.report = new Report(params.report);
    params.other_offer = new OtherOffer(params.other_offer);
    super(params);
  }

  get isExist() {
    return this.offer.id;
  }

  get isTaskType() {
    return this.offer.offer_type === 'task';
  }

  get isReportType() {
    return this.offer.offer_type === 'report';
  }

  get isOtherOfferType() {
    return this.offer.offer_type === 'other_offer';
  }

  get offer_type() {
    return this.offer.offer_type;
  }

  get name() {
    if (this.isTaskType) {
      return this.task.name;
    }
    if (this.isReportType) {
      return this.report.name;
    }
    if (this.isOtherOfferType) {
      return this.other_offer.name;
    }
    return null;
  }

  get text() {
    if (this.isTaskType) {
      return this.task.content.text;
    }
    if (this.isReportType) {
      return this.report.content.text;
    }
    if (this.isOtherOfferType) {
      return this.other_offer.content.text;
    }
    return null;
  }

  get dueDate() {
    if (this.isTaskType) {
      return this.task.due_date;
    }
    if (this.isReportType) {
      return this.report.due_date;
    }
    if (this.isOtherOfferType) {
      return this.other_offer.due_date;
    }
    return null;
  }

  get content() {
    if (this.isTaskType) {
      return this.task.content;
    }
    if (this.isReportType) {
      return this.report.content;
    }
    if (this.isOtherOfferType) {
      return this.other_offer.content;
    }
    return null;
  }

  get isValid() {
    const {
      offer: { offer_type },
    } = this;
    switch (offer_type) {
      case 'task':
        return this.task.isValid;
      case 'report':
        return this.report.isValid;
      case 'other_offer':
        return this.other_offer.isValid;
      default:
        return false;
    }
  }

  get errors() {
    return this.isTaskType
      ? this.task.validate()
      : this.isReportType
        ? this.report.validate()
        : this.isOtherOfferType
          ? this.other_offer.validate()
          : {};
  }

  get provisionalHashtag() {
    const {
      offer: { offer_type },
    } = this;
    switch (offer_type) {
      case 'task':
        return this.task.provisionalHashtag;
      case 'report':
        return this.report.provisionalHashtag;
      case 'other_offer':
        return this.other_offer.provisionalHashtag;
      default:
        return [];
    }
  }

  getLabel(key: 'offer_type' | 'name' | 'due_date') {
    switch (key) {
      case 'offer_type':
        return '依頼 / 報告の種類';
      case 'name':
        return this.isTaskType ? 'タスク' : this.isReportType ? '報告' : this.isOtherOfferType ? 'その他' : '';
      case 'due_date':
        return '期限';
      default:
        return '';
    }
  }

  changeOfferId(offer_id: number) {
    return this.update('offer', (offer) => offer.changeId(offer_id));
  }

  changeOfferStoreId(store_id: number) {
    return this.update('offer', (offer) => offer.changeStoreId(store_id));
  }

  changeOfferType(offer_type: OfferType) {
    return this.update('offer', (offer) => offer.changeOfferType(offer_type));
  }

  /**
   * 依頼／報告の名前を変更する
   *
   * @param name 依頼／報告の名前
   */
  changeName(name: string) {
    return this.update(this.offer.offer_type, (offerType) => offerType.changeName(name));
  }

  /**
   * 依頼／報告の本文を変更する
   *
   * @param text 依頼／報告の本文
   */
  changeText(text: string) {
    return this.update(this.offer.offer_type, (offerType) => offerType.changeText(text));
  }

  /**
   * 依頼／報告のタグを変更する
   *
   * @param tags 依頼／報告のタグ
   */
  changeTags(tags: string[]) {
    return this.update(this.offer.offer_type, (offerType) => offerType.changeTags(tags));
  }

  /**
   * 依頼／報告のファイルを追加する
   *
   * @param urls 追加したいファイルのURL
   */
  addFiles(urls: string[]) {
    return this.update(this.offer.offer_type, (offerType) => offerType.addFiles(urls));
  }

  /**
   * 依頼／報告のファイルを削除する
   *
   * @param index 削除したいファイルのリスト上のインデックス
   */
  deleteFile(index: number) {
    return this.update(this.offer.offer_type, (offerType) => offerType.deleteFile(index));
  }

  /**
   * ファイルの説明を変更する
   *
   * @param index   対象ファイルのリスト中の順番
   * @param caption ファイルの説明
   */
  changeFileCaption(index: number, caption: string) {
    return this.update(this.offer.offer_type, (offerType) => offerType.changeFileCaption(index, caption));
  }

  /**
   * 依頼／報告の期限を変更する
   *
   * @param date 依頼／報告の期限
   */
  changeDueDate(date: Dayjs) {
    return this.update(this.offer.offer_type, (offerType) => offerType.changeDueDate(date));
  }

  requestParams() {
    const {
      offer: { offer_type },
    } = this;
    const params: JSObject = { offer: { offer_type }, task: null, report: null, other_offer: null };
    switch (offer_type) {
      case 'task':
        params.task = this.task.requestParams();
        break;
      case 'report':
        params.report = this.report.requestParams();
        break;
      case 'other_offer':
        params.other_offer = this.other_offer.requestParams();
        break;
      default:
        break;
    }
    return params;
  }
}
