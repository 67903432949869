import React, { useCallback, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ActiveCircle, ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import {
  DataKey,
  MemoIconData,
  getComparisonDataKey as _getComparisonDataKey,
  getTargetDataKey as _getTargetDataKey,
  isComparisonDataKey as _isComparisonDataKey,
  getCurrencyText,
  getDateLabelWithWeekOfDay,
  getRateText,
  getValueText,
} from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { PERFORMANCE_PROGRAMS, PerformanceMetric, PerformanceProgram } from 'models/Domain/OmoInsight';
import { PerformanceGraphItem } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { COLOR, UNIVERSAL_COLORS } from 'style/color';

import { PEROFRMANCE_PROGRAM_LABELS } from '../../index';

// 非活性のグラフの透明度
const INACTIVE_TRANSPARENCY = 0.9 as const;
// 最大のドット数
const MAX_DOTS = 50 as const;

export type TargetDataKey = PerformanceProgram;
export type GraphDataKey = DataKey<TargetDataKey, 'snake'>;

export type OmoInsightPerformanceGraphDataItem = Record<'date' | 'comparison_date', string> &
  Record<GraphDataKey, number | null | undefined>;

// 共通処理に型を指定する
const isComparisonDataKey = _isComparisonDataKey<TargetDataKey, 'snake'>;
const getTargetDataKey = _getTargetDataKey<TargetDataKey, 'snake'>;
const getComparisonDataKey = _getComparisonDataKey<TargetDataKey, 'snake'>;

const isDataKey = (dataKey: PerformanceProgram): dataKey is TargetDataKey => {
  return PERFORMANCE_PROGRAMS.includes(dataKey);
};

const PERFORMANCE_COLORS = UNIVERSAL_COLORS.toArray();

const DEFAULT_GRAPH_HEIGHT = 300;

/**
 * dataKeyから色を取得する
 * @param dataKey
 */
const getStrokeColor = (dataKey: GraphDataKey): string => {
  const key = getTargetDataKey(dataKey);
  return PERFORMANCE_COLORS[PERFORMANCE_PROGRAMS.indexOf(key) % PERFORMANCE_COLORS.length];
};

/**
 * dateKeyの日本語表記を取得する
 * @param dataKey
 */
const getDataKeyLabel = (dataKey: GraphDataKey) => {
  const isComparison = isComparisonDataKey(dataKey);
  const label: string = PEROFRMANCE_PROGRAM_LABELS[getTargetDataKey(dataKey)];
  return isComparison ? `${label}（比較）` : label;
};

type OmoInsightPerformanceGraphProps = {
  metric: PerformanceMetric | null;
  graphData: PerformanceGraphItem[];
  showComparisonGraph: boolean;
  height?: number;
  onChangeActiveKeys?: (dataKey: PerformanceProgram) => void;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
  showInactiveLegend: boolean;
  graphSettings?: GraphSettings;
  dataKeys: PerformanceProgram[];
  displayDataKeys: ImmutableSet<PerformanceProgram>;
  toggleDataKey?: (dataKey: PerformanceProgram) => void;
};

export const OmoInsightPerformanceGraph = React.memo<OmoInsightPerformanceGraphProps>(
  ({
    metric,
    graphData,
    showComparisonGraph,
    height = DEFAULT_GRAPH_HEIGHT,
    memoData,
    onClickMemo,
    onChangeActiveKeys: onChangeActiveStats,
    showInactiveLegend,
    graphSettings = new GraphSettings(),
    dataKeys,
    displayDataKeys,
    toggleDataKey,
  }) => {
    // ホバーしたときに強調表示するデータのキー
    const [activeDataKey, setActiveDataKey] = useState<TargetDataKey | null>(null);

    // ドットを表示するのはデータが50個まで
    const showDots = graphData.length <= MAX_DOTS;

    // 比較期間なしの場合に表示するツールチップ
    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <ChartTooltip.Item key={dataKey}>
                  <ChartTooltip.Label color={itemColor}>
                    <ActiveCircle color={getStrokeColor(dataKey)} />
                    {item.name}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>
                    {(() => {
                      const value = item.value;
                      switch (metric) {
                        case 'ctr':
                          return getRateText(value != null ? value / 100 : null);
                        case 'revenues':
                          return getCurrencyText(value);
                        default:
                          return getValueText(value);
                      }
                    })()}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [metric],
    );

    // 比較期間ありの場合に表示するツールチップ
    const renderComparisonTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];

        let usedTitle = ImmutableSet<string>(); // 使用済みのタイトル

        return (
          <ChartTooltip>
            {items.map((item) => {
              const dataKey = item.dataKey as GraphDataKey;
              const targetDataKey = getTargetDataKey(dataKey);
              const isComparison = isComparisonDataKey(dataKey);
              const title = getDataKeyLabel(targetDataKey);
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const date = item.payload.date;
              const comparison_date = item.payload.comparison_date;
              // comparison_dateがない場合は表示しない
              // FIXME: 本来は比較期間のデータを表示するべきだが、comparison_dateを表示できないので表示していない
              // 比較期間のデータが１つもないときでも検索条件の期間の空のデータを生成するようにしたい
              if (isComparison && !comparison_date) {
                return null;
              }
              const itemColor = getStrokeColor(dataKey);
              const textColor = COLOR.BLACK;
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && (
                    <ChartTooltip.Title color={itemColor}>
                      <ActiveCircle color={itemColor} />
                      {title}
                    </ChartTooltip.Title>
                  )}
                  <ChartTooltip.Item>
                    <ChartTooltip.Label color={textColor}>
                      {getDateLabelWithWeekOfDay(isComparison ? comparison_date : date)}
                    </ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>
                      {(() => {
                        const value = item.value;
                        switch (metric) {
                          case 'ctr':
                            return getRateText(value != null ? value / 100 : null);
                          case 'revenues':
                            return getCurrencyText(value);
                          default:
                            return getValueText(value);
                        }
                      })()}
                    </ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
          </ChartTooltip>
        );
      },
      [metric],
    );

    const handleOnMouseEnter = useCallback(
      (value: TargetDataKey | null) => () => {
        setActiveDataKey(value);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveDataKey(null);
    }, []);

    // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
    let displayedMemoDate = ImmutableSet<string>();

    return (
      <ResponsiveContainer height={height}>
        <ComposedChart
          data={graphData}
          margin={{ top: 64, right: 32, left: 32, bottom: 16 }}
          onMouseLeave={() => setActiveDataKey(null)}
        >
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            content={(props: any) => renderLegend({ ...props, showInactiveLegend })}
            onClick={(o: any) => toggleDataKey && toggleDataKey(getTargetDataKey(o.dataKey))}
          />
          <XAxis dataKey={'date'} tickMargin={16} interval={'equidistantPreserveStart'} />
          <YAxis
            allowDecimals={false}
            domain={graphSettings.domain}
            allowDataOverflow={true}
            tickFormatter={(value) => {
              switch (metric) {
                case 'ctr':
                  return getRateText(value / 100);
                case 'revenues':
                  return getCurrencyText(value);
                default:
                  return getValueText(value);
              }
            }}
          />
          {/* 非表示用のダミーY軸 */}
          <YAxis yAxisId={'hide'} hide={true} />
          {/* メモアイコン用のダミーY軸 */}
          <YAxis yAxisId={'memo'} hide={true} />
          <CartesianGrid vertical={false} />
          {dataKeys.map((dataKey) => {
            // グラフに使えるデータであることを確認する
            if (!isDataKey(dataKey)) {
              return null;
            }
            const targetDataKey = getTargetDataKey(dataKey);
            const comparisonDataKey = getComparisonDataKey(dataKey, 'snake');

            const isActive = activeDataKey == null || activeDataKey === targetDataKey;

            const color = transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(targetDataKey));

            const hide = !displayDataKeys.has(targetDataKey);

            return (
              <React.Fragment key={dataKey}>
                <Line
                  dataKey={targetDataKey}
                  stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(targetDataKey))}
                  strokeWidth={2}
                  isAnimationActive={false}
                  name={getDataKeyLabel(targetDataKey)}
                  onMouseEnter={handleOnMouseEnter(targetDataKey)}
                  onMouseLeave={handleOnMouseLeave}
                  dot={showDots ? { fill: color, r: 2, clipDot: false } : false}
                  activeDot={{
                    cursor: 'pointer',
                    onMouseEnter: handleOnMouseEnter(targetDataKey),
                    onMouseLeave: handleOnMouseLeave,
                  }}
                  cursor={'pointer'}
                  hide={hide}
                />
                {showComparisonGraph && (
                  <Line
                    dataKey={comparisonDataKey}
                    stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, getStrokeColor(comparisonDataKey))}
                    strokeWidth={2}
                    strokeDasharray={'5 5'}
                    isAnimationActive={false}
                    name={getDataKeyLabel(comparisonDataKey)}
                    onMouseEnter={handleOnMouseEnter(targetDataKey)}
                    onMouseLeave={handleOnMouseLeave}
                    dot={showDots ? { fill: color, r: 2, clipDot: false } : false}
                    activeDot={{
                      cursor: 'pointer',
                      onMouseEnter: handleOnMouseEnter(targetDataKey),
                      onMouseLeave: handleOnMouseLeave,
                    }}
                    cursor={'pointer'}
                    hide={hide}
                  />
                )}
              </React.Fragment>
            );
          })}

          {memoData?.map((item) => {
            // すでにメモを表示している日付ならスキップ
            if (displayedMemoDate.has(item.date)) {
              return null;
            }
            // メモを表示している日付リストに追加
            displayedMemoDate = displayedMemoDate.add(item.date);
            return (
              <ReferenceLine
                key={item.date}
                x={item.date}
                yAxisId={'memo'}
                stroke={'#9994'}
                label={(props) =>
                  MemoIcon({
                    ...props,
                    title: getDateLabelWithWeekOfDay(item.date),
                    content: item.contents,
                    onClick: () => onClickMemo && onClickMemo(item.ids),
                  }) as any
                }
              />
            );
          })}

          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            content={showComparisonGraph ? renderComparisonTooltip : renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
);
