import React, { useCallback, useEffect, useState } from 'react';

import { List } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { CompetitorApi } from 'ApiClient/CompetitorApi';
import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { TagInput } from 'components/molecules/TagInput';
import { CompetitorHelp as Help } from 'helpers/ContextHelp';
import { Competitor } from 'models/Domain/Competitor/Competitor';
import { Store } from 'models/Domain/Store';

type Props = {
  className?: string;
  isOpen: boolean;
  competitor: Competitor;
  store?: Store;
  onClose: () => void;
  onSuccess?: (competitor: Competitor) => void;
  onFailure?: (competitor: Competitor, error: any) => void;
};

export const CompetitorRegisterModal = React.memo<Props>((props) => {
  const { onSuccess, onFailure, ...restProps } = props;

  const handleOnSave = useCallback(
    async (competitor: Competitor) => {
      const params = competitor.toParams();
      const response = await CompetitorApi.putCompetitor(params.id, params);
      if (response.isSuccess) {
        if (onSuccess) {
          const newCompetitor = Competitor.fromJSON(response.data[0]);
          onSuccess(newCompetitor);
        }
      } else {
        if (onFailure) {
          onFailure(competitor, response.error);
        }
      }
    },
    [onFailure, onSuccess],
  );
  return <CompetitorRegisterModalComponent {...restProps} onSave={handleOnSave} />;
});

type InnerProps = Exclude<Props, 'onSaveSuccess' | 'onSaveFailure'> & {
  onSave: (competitor: Competitor) => void;
};

export const CompetitorRegisterModalComponent: React.FC<InnerProps> = ({
  className,
  isOpen,
  competitor,
  store,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState<string>('');
  const [tags, setTags] = useState<List<string>>(List());
  const [isProcessing, setIsProccessing] = useState(false);

  useEffect(() => {
    setName(competitor ? competitor.name : '');
    setTags(competitor ? competitor.tags : List());
  }, [competitor]);

  const handleOnChangeTags = useCallback((values: string[]) => {
    setTags(List(values));
  }, []);

  const handleOnSave = useCallback(() => {
    const newCompetitor = competitor.merge({ name, tags });
    setIsProccessing(true);
    onSave(newCompetitor);
    setIsProccessing(false);
    onClose();
  }, [competitor, name, tags, onSave, onClose]);

  return (
    <Modal className={className} open={isOpen} onClose={onClose}>
      <Modal.Content>
        <Wrapper>
          <Title>競合店舗の登録</Title>
          <hr />
          {store != null && (
            <EditItem>
              <Label>登録対象の店舗</Label>
              <TextValue>{store.fullName}</TextValue>
            </EditItem>
          )}
          <EditItem>
            <Label>競合店舗名 {name !== competitor.name && <>（「{competitor.name}」から変更）</>}</Label>
            <StyledInput value={name} onChange={setName} />
          </EditItem>
          <EditItem>
            <Label>
              タグ
              <ContextHelp content={Help.tags} />
            </Label>
            <TagInput values={tags.toArray()} onChange={handleOnChangeTags} />
          </EditItem>
        </Wrapper>
        <ButtonWrapper>
          <StyledButton onClick={onClose} disabled={isProcessing}>
            閉じる
          </StyledButton>
          <StyledButton onClick={handleOnSave} disabled={isProcessing} priority={'high'}>
            登録
          </StyledButton>
        </ButtonWrapper>
      </Modal.Content>
    </Modal>
  );
};

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const EditItem = styled.div`
  margin: 24px 0;
`;

const StyledInput = styled(Input)`
  &&& {
    input {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const TextValue = styled.div`
  font-size: 16px;
  margin-left: 16px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  ${StyledButton} {
    :not(:first-child) {
      margin-left: 16px;
    }
  }
`;
