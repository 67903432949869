import actionCreatorFactory from 'typescript-fsa';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { GmbInsight } from 'models/Domain/GmbInsight';
import { InsightMetrics } from 'models/Domain/GmbInsightMetrics';
import { GmbReview, GmbReviewSearchCondition, ReviewDetail } from 'models/Domain/GmbReview';
import { GmbReviewTemplate, GmbReviewTemplates } from 'models/Domain/GmbReviewTemplate';
import { Store } from 'models/Domain/Store';
import { GmbInsightDownloadModalState } from 'models/Other/GmbInsightDownloadModalState';
import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('Gmb');

type SendReviewReplyType = { reviewId: string; text: string };
type ChangeReviewStatusType = { gmb_review_id: string; status: string };

export const GmbActions = {
  getGmbList: actionCreator('getGmbList'),
  setGmbList: actionCreator<JSObject>('setGmbList'),
  setCurrentAccountId: actionCreator<string>('setCurrentAccountId'),
  setCurrentGroupId: actionCreator<string>('setCurrentGroupId'),
  setCurrentLocationId: actionCreator<string>('setCurrentLocationId'),
  setGroupList: actionCreator<JSObject>('setGmbGroupList'),
  setLocationList: actionCreator<JSObject>('setGmbLocationList'),
  openConnectModal: actionCreator<Store>('openConnectModal'),
  selectGmbAccount: actionCreator<string>('selectGmbAccount'),
  selectGmbGroup: actionCreator<string>('selectGmbGroup'),
  selectGmbLocation: actionCreator<string>('selectGmbLocation'),
  connectGmb: actionCreator('connectGmb'),
  removeGmbAccount: actionCreator<number>('removeGmbAccount'),
  clearModalData: actionCreator('clearModalData'),
  clearTargetStore: actionCreator('clearTargetStore'),
  getGmbCategoryList: actionCreator('getGmbCategoryList'),
  setGmbCategoryList: actionCreator<JSObject[]>('setGmbCategoryList'),

  updateGmbInsight: actionCreator<GmbInsight>('updateGmbInsight'),
  addGmbInsightSelectedStore: actionCreator<number>('addGmbInsightSelectedStore'),
  getInsightTotalList: actionCreator<JSObject>('getInsightTotalList'),
  updateInsightMetrics: actionCreator<InsightMetrics>('updateInsightMetrics'),
  setDownloadModalState: actionCreator<GmbInsightDownloadModalState>('setDownloadModalState'),

  setGmbReview: actionCreator<GmbReview>('setGmbReview'),
  getReview: actionCreator<string>('getReview'),
  setReview: actionCreator<ReviewDetail>('setReview'),
  setIsLoadingReviews: actionCreator<boolean>('setIsLoadingReviews'),
  setIsLoadingReview: actionCreator<boolean>('setIsLoadingReview'),

  sendReviewReply: actionCreator<SendReviewReplyType>('sendReviewReply'),
  changeReviewStatus: actionCreator<ChangeReviewStatusType>('changeReviewStatus'),

  setGmbReviewTemplateList: actionCreator<GmbReviewTemplates>('setGmbReviewTemplateList'),
  getGmbReviewTemplateList: actionCreator('getGmbReviewTemplateList'),
  createGmbReviewTemplate: actionCreator<GmbReviewTemplate>('createGmbReviewTemplate'),
  updateGmbReviewTemplate: actionCreator<GmbReviewTemplate>('updateGmbReviewTemplate'),
  deleteGmbReviewTemplate: actionCreator<GmbReviewTemplate>('deleteGmbReviewTemplate'),

  getCsvDownload: actionCreator('getCsvDownload'),
  setCsvDownload: actionCreator<ExecutionArnStatus>('setCsvDownload'),
  clearCsvDownload: actionCreator('clearCsvDownload'),
  setSearchCondition: actionCreator<{ searchCondition: GmbReviewSearchCondition; updateLocation?: boolean }>(
    'setSearchCondition',
  ),

  setCommittedSearchCondition: actionCreator<{ searchCondition: GmbReviewSearchCondition }>(
    'setCommittedSearchCondition',
  ),
  fetchReviews: actionCreator('fetchReviews'),
  initializeReviewListPage: actionCreator('initializeReviewListPage'),
  setIsPreparedForReviewListPage: actionCreator<boolean>('setIsPreparedForReviewListPage'),

  getReviewCsvDownload: actionCreator<JSObject>('getReviewCsvDownload'),
  checkReviewCsvDownloadStatus: actionCreator<number>('checkReviewCsvDownloadStatus'),
  setIsOpenReviewDownloadModal: actionCreator<boolean>('setIsOpenReviewDownloadModal'),

  // GBPからの店舗取り込み関連
  importStoresFromGbp: actionCreator<string[]>('importStoresFromGbp'),
  setImportStoresFromGbpStatus: actionCreator<ExecutionArnStatus>('setImportStoresFromGbpStatus'),
  clearImportStoresFromGbpStatus: actionCreator('clearImportStoresFromGbpStatus'),
  checkImportStoresFromGbpStatus: actionCreator('checkImportStoresFromGbpStatus'),
};
