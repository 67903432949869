import dayjs from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { Pagination } from 'models/Domain/Pagination';
import { YearMonth } from 'models/Domain/YearMonth';
import { JSObject } from 'types/Common';

import { SearchKeywordGraphType } from './SearchKeywordSearchCondition';

export const getMonthLabel = (str: string): string => {
  // YYYY-MM形式の文字列を、YYYY年M月形式に変換する
  return str ? dayjs(str, 'YYYY-MM').format('YYYY年M月') : '';
};

export const getGraphTypeLabel = (type: SearchKeywordGraphType): string => {
  switch (type) {
    case 'search_count':
      return '流入数';
    case 'keyword_count':
      return 'キーワード数';
    default:
      return '';
  }
};

/**
 * 前期比をもとに変化率（パーセント）を求める
 * @param value
 */
export const getGrowthRate = (value: number | null): number | null => {
  return value != null ? (value - 1.0) * 100.0 : null;
};

type SearchKeywordGraphItemRecord = {
  period: YearMonth;
  value: number;
};

export class SearchKeywordGraphItem extends ImmutableRecord<SearchKeywordGraphItemRecord>({
  period: new YearMonth(),
  value: 0,
}) {
  static fromJSON(data: JSObject): SearchKeywordGraphItem {
    return new SearchKeywordGraphItem({
      period: YearMonth.fromString(data.period),
      value: data.value,
    });
  }
}

type SearchKeywordGraphDataRecord = {
  items: ImmutableList<SearchKeywordGraphItem>;
};

export class SearchKeywordGraphData extends ImmutableRecord<SearchKeywordGraphDataRecord>({
  items: ImmutableList<SearchKeywordGraphItem>(),
}) {
  static fromJSON(data: JSObject): SearchKeywordGraphData {
    return new SearchKeywordGraphData({
      items: ImmutableList(data.values.map((item: JSObject) => SearchKeywordGraphItem.fromJSON(item))),
    });
  }

  findByPeriod(month: YearMonth) {
    return this.items.find((item) => item.period.isSame(month))?.value;
  }
}

type MonthlyCountRecord = {
  period: string;
  value: number;
};
export class MonthlyCount extends ImmutableRecord<MonthlyCountRecord>({
  period: '',
  value: 0,
}) {
  static fromJSON(data: JSObject): MonthlyCount {
    return new MonthlyCount({
      period: data.period,
      value: data.value,
    });
  }
}

type SearchKeywordTableItemValueRecord = {
  monthlyAverageCount: number | null;
  targetMonthCount: number | null;
  monthlyCounts: ImmutableList<MonthlyCount>;
  momRatio: number | null;
  momDiff: number | null;
  threeMomRatio: number | null;
  yoyRatio: number | null;
};

export class SearchKeywordTableItemValue extends ImmutableRecord<SearchKeywordTableItemValueRecord>({
  monthlyAverageCount: null,
  targetMonthCount: null,
  monthlyCounts: ImmutableList<MonthlyCount>(),
  momRatio: null,
  momDiff: null,
  threeMomRatio: null,
  yoyRatio: null,
}) {
  static fromJSON(data: JSObject): SearchKeywordTableItemValue {
    return new SearchKeywordTableItemValue({
      monthlyAverageCount: data.monthly_average_count ?? null,
      targetMonthCount: data.target_month_count ?? null,
      monthlyCounts: ImmutableList<MonthlyCount>(data.monthly_counts ?? []),
      momRatio: data.mom_ratio ?? null,
      momDiff: data.mom_diff ?? null,
      threeMomRatio: data.three_mom_ratio ?? null,
      yoyRatio: data.yoy_ratio ?? null,
    });
  }

  get monthlyCountSeries() {
    return this.monthlyCounts.map((monthlyCount) => monthlyCount.value);
  }

  /**
   * targetMonthから指定した月前のデータを取得する
   * 存在しなければnullを返す
   * @param targetMonth
   * @param ago
   */
  getMonthlyCount(targetMonth: string, ago: number): number | null {
    // targetMonthと一致するデータのインデックスを取得
    const index = this.monthlyCounts.findIndex((value) => value.period === targetMonth);
    // インデックスが存在し、agoを引いてもマイナスにならない場合はそのデータを返す
    if (index >= 0 && index - ago >= 0) {
      return this.monthlyCountSeries.get(index - ago) ?? null;
    }
    return null;
  }

  /**
   * 前月差を返す
   */
  getMomDiff(): number | null {
    return this.momDiff;
  }

  /**
   * 前月比の変化率（パーセント）を返す
   */
  getMomGrowthRate(): number | null {
    return getGrowthRate(this.momRatio);
  }

  /**
   * 3ヶ月前比の変化率（パーセント）を返す
   */
  getThreeMomGrowthRate(): number | null {
    return getGrowthRate(this.threeMomRatio);
  }

  /**
   * 前年同月比の変化率（パーセント）を返す
   */
  getYoyGrowthRate(): number | null {
    return getGrowthRate(this.yoyRatio);
  }
}

type SearchKeywordTableItemRecord = {
  keyword: string;
  value: SearchKeywordTableItemValue;
};

export class SearchKeywordTableItem extends ImmutableRecord<SearchKeywordTableItemRecord>({
  keyword: '',
  value: new SearchKeywordTableItemValue(),
}) {
  static fromJSON(data: JSObject): SearchKeywordTableItem {
    return new SearchKeywordTableItem({
      keyword: data.keyword,
      value: SearchKeywordTableItemValue.fromJSON(data.value),
    });
  }
}

type SearchKeywordTableSummaryRecord = {
  monthlyAverageCount: number | null;
  targetMonthCount: number | null;
  momRatio: number | null;
  momDiff: number | null;
  threeMomRatio: number | null;
  yoyRatio: number | null;
};

export class SearchKeywordTableSummary extends ImmutableRecord<SearchKeywordTableSummaryRecord>({
  monthlyAverageCount: null,
  targetMonthCount: null,
  momRatio: null,
  momDiff: null,
  threeMomRatio: null,
  yoyRatio: null,
}) {
  static fromJSON(data: JSObject): SearchKeywordTableSummary {
    return new SearchKeywordTableSummary({
      monthlyAverageCount: data.monthly_average_count ?? null,
      targetMonthCount: data.target_month_count ?? null,
      momRatio: data.mom_ratio ?? null,
      momDiff: data.mom_diff ?? null,
      threeMomRatio: data.three_mom_ratio ?? null,
      yoyRatio: data.yoy_ratio ?? null,
    });
  }
}

type SearchKeywordTableDataRecord = {
  startMonth: string;
  endMonth: string;
  targetMonth: string;
  summary: SearchKeywordTableItemValue | null;
  items: ImmutableList<SearchKeywordTableItemRecord>;
  pagination: Pagination;
};

export class SearchKeywordTableData extends ImmutableRecord<SearchKeywordTableDataRecord>({
  startMonth: '',
  endMonth: '',
  targetMonth: '',
  summary: null,
  items: ImmutableList<SearchKeywordTableItem>(),
  pagination: new Pagination(),
}) {
  static fromJSON(data: JSObject): SearchKeywordTableData {
    return new SearchKeywordTableData({
      startMonth: data.start_month,
      endMonth: data.end_month,
      targetMonth: data.target_month,
      summary: data.summary ? SearchKeywordTableItemValue.fromJSON(data.summary) : null,
      items: ImmutableList(data.items.map((item: JSObject) => SearchKeywordTableItem.fromJSON(item))),
      pagination: Pagination.fromJSON(data.pagination),
    });
  }
}

/** 店舗ごとのキーワード流入数のテーブルデータの1行分 */
type SearchKeywordDetailTableItemRecord = {
  storeId: number;
  value: SearchKeywordTableItemValue;
};

export class SearchKeywordDetailTableItem extends ImmutableRecord<SearchKeywordDetailTableItemRecord>({
  storeId: 0,
  value: new SearchKeywordTableItemValue(),
}) {
  static fromJSON(data: JSObject): SearchKeywordDetailTableItem {
    return new SearchKeywordDetailTableItem({
      storeId: data.store_id,
      value: SearchKeywordTableItemValue.fromJSON(data.value),
    });
  }
}

/** 店舗ごとのキーワード流入数のテーブルデータ */
type KeywordTableDataRecord = {
  startMonth: YearMonth;
  endMonth: YearMonth;
  targetMonth: YearMonth;
  summary: SearchKeywordTableItemValue | null;
  items: ImmutableList<SearchKeywordDetailTableItem>;
};

export class KeywordTableData extends ImmutableRecord<KeywordTableDataRecord>({
  startMonth: new YearMonth(),
  endMonth: new YearMonth(),
  targetMonth: new YearMonth(),
  summary: null,
  items: ImmutableList(),
}) {
  static fromJSON(data: JSObject): KeywordTableData {
    return new KeywordTableData({
      startMonth: YearMonth.fromString(data.start_month),
      endMonth: YearMonth.fromString(data.end_month),
      targetMonth: YearMonth.fromString(data.target_month),
      summary: SearchKeywordTableItemValue.fromJSON(data.summary),
      items: ImmutableList(data.items.map((item: JSObject) => SearchKeywordDetailTableItem.fromJSON(item))),
    });
  }
}

interface SearchKeywordRecord {
  graphData: SearchKeywordGraphData;
  tableData: SearchKeywordTableData;
}

export class SearchKeyword extends ImmutableRecord<SearchKeywordRecord>({
  graphData: new SearchKeywordGraphData(),
  tableData: new SearchKeywordTableData(),
}) {}

export class SearchKeywordGraphItemList extends ImmutableRecord<{
  items: ImmutableList<SearchKeywordGraphItem>;
}>({
  items: ImmutableList(),
}) {
  static fromJSON(items: JSObject[]): SearchKeywordGraphItemList {
    return new SearchKeywordGraphItemList({
      items: ImmutableList(items.map((item) => SearchKeywordGraphItem.fromJSON(item))),
    });
  }

  findByPeriod(month: YearMonth) {
    return this.items.find((item) => item.period.isSame(month))?.value;
  }
}

export class KeywordGraphDataItem extends ImmutableRecord<{
  storeId: number;
  value: SearchKeywordGraphItemList;
}>({
  storeId: 0,
  value: new SearchKeywordGraphItemList(),
}) {
  static fromJSON(data: JSObject) {
    return new KeywordGraphDataItem({
      storeId: data.store_id ?? null,
      value: SearchKeywordGraphItemList.fromJSON(data.values),
    });
  }
}

// キーワードごとのグラフデータ
export class KeywordGraphData extends ImmutableRecord<{
  summary: SearchKeywordGraphItemList;
  items: ImmutableList<KeywordGraphDataItem>;
}>({
  summary: new SearchKeywordGraphItemList(),
  items: ImmutableList(),
}) {
  static fromJSON(data: JSObject): KeywordGraphData {
    return new KeywordGraphData({
      summary: SearchKeywordGraphItemList.fromJSON(data.summary),
      items: ImmutableList(data.items.map((item: JSObject) => KeywordGraphDataItem.fromJSON(item))),
    });
  }
}
