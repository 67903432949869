import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { PullDown } from 'components/atoms/PullDown';
import { createHashtagText } from 'helpers/utils';
import { COLOR } from 'style/color';

type Props = {
  hashtagList: List<string>;
  selectedHashTagList: List<string>;
  setSelectedHashTagList: (v: List<string>) => void;
};

export const SelectHashtag = React.memo<Props>(({ hashtagList, selectedHashTagList, setSelectedHashTagList }) => {
  const [additionalTags, setAdditionalTags] = React.useState<List<string>>(List());
  const options = hashtagList
    .merge(additionalTags)
    .map((hashtag) => ({ text: hashtag, value: hashtag }))
    .toArray();
  return (
    <Wrapper>
      <Label>紐付けるタグ</Label>
      <LabelDescription>
        ※タグを追加すると、STORECASTでの写真検索で利用できます。Googleビジネスプロフィールには反映されません。
      </LabelDescription>
      <CustomPullDown
        multiple={true}
        closeOnChange={false}
        allowAdditions={true}
        value={selectedHashTagList.toArray()}
        options={options}
        placeholder='タグを選択もしくは作成してください'
        onChange={(v) => {
          setSelectedHashTagList(
            List(v.map((value: string) => createHashtagText(value)).filter((value: string) => value)),
          );
        }}
        onAddItem={(v) => {
          setAdditionalTags(additionalTags.push(v));
        }}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-top: 24px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const LabelDescription = styled.div`
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  color: ${COLOR.DARK_GRAY};
`;

const CustomPullDown = styled(PullDown)`
  &&& {
    margin-top: 16px;
  }
`;
