import { List, Map, Record } from 'immutable';

export type RawLocationDiffKey =
  | 'locationName'
  | 'storeCode'
  | 'websiteUrl'
  | 'primaryPhone'
  | 'additionalPhones'
  | 'moreHours'
  | 'address'
  | 'latlng'
  | 'regularHours'
  | 'specialHours'
  | 'primaryCategory'
  | 'additionalCategories'
  | 'attributes'
  | 'openInfo'
  | 'openingDate'
  | 'profile'
  | 'serviceItems';

// 電話番号、カテゴリーをまとめた差分キー
export type LocationDiffKey =
  | Exclude<RawLocationDiffKey, 'primaryPhone' | 'additionalPhones' | 'primaryCategory' | 'additionalCategories'>
  | 'phoneNumbers'
  | 'categories';

export type DiffGroup = '基本情報' | '営業情報' | '属性情報' | 'サービス情報';
export const diffGroupMap: { [key in LocationDiffKey]: DiffGroup } = {
  locationName: '基本情報',
  storeCode: '基本情報',
  websiteUrl: '基本情報',
  phoneNumbers: '基本情報',
  address: '基本情報',
  latlng: '基本情報',
  regularHours: '営業情報',
  specialHours: '営業情報',
  moreHours: '営業情報',
  categories: '基本情報',
  attributes: '属性情報',
  openInfo: '営業情報',
  openingDate: '営業情報',
  profile: '基本情報',
  serviceItems: 'サービス情報',
};

const keyLabels: { [key in LocationDiffKey]: string } = {
  locationName: '店舗名',
  openInfo: '営業ステータス',
  openingDate: '開業日',
  categories: 'カテゴリー',
  storeCode: '店舗コード',
  address: '所在地',
  latlng: '緯度経度',
  regularHours: '営業時間',
  specialHours: '特別営業時間',
  moreHours: 'その他の営業時間',
  phoneNumbers: '電話番号',
  websiteUrl: 'ウェブサイト',
  attributes: '属性',
  profile: 'ビジネス情報',
  serviceItems: 'サービス',
};
export type LocationDiffType = 'GOOGLE_UPDATED' | 'DIFF';

export interface RawGmbLocationDiff {
  key: RawLocationDiffKey;
  diff_type: LocationDiffType;
  store_id: number;
}

//電話番号、カテゴリーをまとめた差分レコード
interface GmbLocationDiffRecord {
  key: LocationDiffKey;
  diff_type: LocationDiffType;
  store_id: number;
}

/**
 * 差分情報のキーを、マージしたキーに変換する
 * @param key
 * @returns
 */
export const convertToMergedKey = (key: RawLocationDiffKey): LocationDiffKey => {
  if (key == 'primaryPhone' || key == 'additionalPhones') {
    // primaryPhone, additionalPhones は同時更新しないといけないので、phoneNumbers にマージ
    return 'phoneNumbers';
  } else if (key == 'primaryCategory' || key == 'additionalCategories') {
    // primaryCategory, additionalCategories は同時更新しないといけないので、categories にマージ
    return 'categories';
  } else {
    // それ以外はそのまま
    return key;
  }
};

export class GmbLocationDiff extends Record<GmbLocationDiffRecord>({
  key: 'locationName',
  diff_type: 'GOOGLE_UPDATED',
  store_id: 0,
}) {
  static fromRawLocationDiff(data: RawGmbLocationDiff) {
    const mergedKey = convertToMergedKey(data.key);
    return new GmbLocationDiff({ key: mergedKey, diff_type: data.diff_type, store_id: data.store_id });
  }
  get label() {
    return keyLabels[this.key];
  }

  get typeLabel() {
    const keyLabelList = {
      DEFAULT: '',
      GOOGLE_UPDATED: 'Googleからの提案',
      DIFF: 'GBPとの差分',
    };
    return keyLabelList[this.diff_type];
  }

  get isGoogleUpdated() {
    return this.diff_type === 'GOOGLE_UPDATED';
  }
}

interface GmbLocationDiffsRecord {
  list: List<GmbLocationDiff>;
}

export class GmbLocationDiffs extends Record<GmbLocationDiffsRecord>({
  list: List(),
}) {
  static fromJSON(data: RawGmbLocationDiff[] = []) {
    let mergeMap = Map<string, GmbLocationDiff>();
    data.forEach((diff) => {
      const mergedKey = convertToMergedKey(diff.key);
      const mapKey = `${mergedKey}_${diff.store_id}`;
      if (mergeMap.get(mapKey)?.diff_type !== 'GOOGLE_UPDATED') {
        mergeMap = mergeMap.set(mapKey, GmbLocationDiff.fromRawLocationDiff(diff));
      }
    });

    return new GmbLocationDiffs({ list: List(mergeMap.values()) });
  }

  /** 変更依頼のある店舗の数 */
  get numberOfStores() {
    return this.list.map((locationDiff) => locationDiff.store_id).toSet().size;
  }

  /**
   * 項目分類ごとの差分がある店舗数
   */
  get diffStoreCountsByGroup() {
    // store_id, groupの組みに変換した上で重複排除し、グループごとに件数を数え上げる
    return this.list
      .map((diff) => Map({ store_id: diff.store_id, group: diffGroupMap[diff.key] }))
      .toSet()
      .countBy((map) => map.get('group'));
  }
}
