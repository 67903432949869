import React, { useCallback, useMemo, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Map, Set } from 'immutable';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { ChartTooltip } from 'components/atoms/ChartTooltip';
import { Loader } from 'components/atoms/Loader';
import Menu from 'components/atoms/Menu';
import { GuideOverlay } from 'components/molecules/GuideOverlay';
import { AggregateUnit } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { InsightGraphData } from 'models/Domain/GbpInsight/Insight';
import { COLOR } from 'style/color';

type MetricsType = 'search' | 'review' | 'action';

type BaseDataKey =
  | 'queriesDirect'
  | 'queriesIndirect'
  | 'queriesChain'
  | 'rateCount'
  | 'commentCount'
  | 'actionsWebsite'
  | 'actionsDrivingDirections'
  | 'actionsPhone';

type ComparisonDataKey =
  | 'comparisonQueriesDirect'
  | 'comparisonQueriesIndirect'
  | 'comparisonQueriesChain'
  | 'comparisonRateCount'
  | 'comparisonCommentCount'
  | 'comparisonActionsWebsite'
  | 'comparisonActionsDrivingDirections'
  | 'comparisonActionsPhone';

type DataKey = BaseDataKey | ComparisonDataKey;

/**
 * 日付と集計期間からX軸およびグラフホバー時に表示する日付を返す
 * @param {string} value 日付の文字列
 * @param {AggregateUnit} aggregateUnit 集計期間
 * @returns ツールチップ表示用の文字列
 */
export const convertDateLabel = (value: string, aggregateUnit: AggregateUnit): string => {
  const date = dayjs(value, 'YYYY-MM-DD');
  if (!date.isValid()) {
    return '';
  }
  switch (aggregateUnit) {
    case 'day':
      return date.format('YYYY年M月D日');
    case 'week':
      // 指定された日付の週の月曜日を取得
      return date.subtract((date.day() + 6) % 7, 'day').format('YYYY年M月D日週');
    case 'month':
      // 指定された日付の月の1日を取得
      return date.date(1).format('YYYY年M月');
  }
};

/**
 * 比較用のDataKeyを取得する
 * @param dataKey データのキー
 */
const getComparisonDataKey = (dataKey: BaseDataKey): ComparisonDataKey => {
  return `comparison${dataKey.charAt(0).toUpperCase()}${dataKey.slice(1)}` as ComparisonDataKey;
};

/**
  ベースのDataKeyを取得する
 * @param dataKey データのキー
 */
const getBaseDataKey = (dataKey: DataKey): BaseDataKey => {
  const key = dataKey.replace('comparison', '');
  return `${key.charAt(0).toLowerCase()}${key.slice(1)}` as BaseDataKey;
};

// FIXME: 色変更する場合は定数名を変更する
const GRAPH_COLOR = {
  GREEN: '#458a6a',
  LIGHT_GREEN: '#E2F3EB',
  ORANGE: '#dc8459',
  LIGHT_ORANGE: '#F8EEE9',
  PURPLE: '#6A42D2',
  LIGHT_PURPLE: '#EAE6F5',
};

/**
 * インサイトのグラフ
 *
 * @param graphData グラフデータ
 * @param comparisonGraphData 比較のグラフデータ
 * @param metricsType グラフ種別（検索, アクション, クチコミ）
 * @param aggregateUnit 集計単位
 * @param isLoading ローディング中かどうか
 * @param showActionRate （アクション数のグラフの場合のみ）アクション率のグラフを表示するかどうか
 * @param onChangeShowActionRate （アクション数のグラフの場合のみ）表示切替時に呼ぶ関数
 * @param isEnabledComparison 比較を表示するか
 */
export const InsightChart: React.FC<{
  graphData: InsightGraphData;
  comparisonGraphData: InsightGraphData;
  metricsType: MetricsType;
  aggregateUnit: AggregateUnit;
  isLoading: boolean;
  isEnabledComparison: boolean;
  showActionRate?: boolean;
  onChangeShowActionRate?: (showActionRate: boolean) => void;
}> = ({
  graphData,
  comparisonGraphData,
  aggregateUnit,
  metricsType,
  isLoading,
  isEnabledComparison,
  showActionRate,
  onChangeShowActionRate,
}) => {
  const [disableDataKeys, setDisabledDataKeys] = useState<Set<DataKey>>(Set([]));

  /** アクション数のグラフの表示切り替えに応じたラベル文字列 */
  const actionGraphLabel = showActionRate ? 'アクション率' : 'アクション数';
  /** y軸のラベル */
  const yAxisLabel = metricsType === 'search' ? '検索数' : metricsType === 'review' ? 'クチコミ数' : actionGraphLabel;
  /** グラフを積み上げるか */
  const [stacked, setStacked] = useState<boolean>(metricsType == 'review');

  let errorMessage = '';
  if (graphData.items.isEmpty() && comparisonGraphData.items.isEmpty()) {
    errorMessage = '指定された期間のデータがありません';
  }

  // データを複数もつか（データが複数ないと積み上げグラフは表示されないので、グラフの見た目を変更する必要がある）
  const hasMultipleData = graphData.hasMultipleData || comparisonGraphData.hasMultipleData;

  // グラフ用のデータを整形
  const composedChartData = useMemo(() => {
    // 対象期間のデータが比較期間のデータより少ない場合は、始端を揃えて足りない期間の日付は補完する
    // 実装が複雑になりすぎるので、各データの日付に歯抜けがある場合は想定していない
    const maxSize = Math.max(graphData.items.size, comparisonGraphData.items.size);
    // 期間の補完のための基準の日
    let firstDate: Dayjs | undefined;
    return [...Array(maxSize)].map((_, index) => {
      // X軸とツールチップ表示用に、期間の開始日を整形
      const item = graphData.items.get(index);
      const data: { [key: string]: any } = item ?? Map({});
      let date = data.period?.fromDate;
      if (!firstDate) {
        firstDate = date;
      } else if (date == null) {
        date = firstDate.add(index, aggregateUnit);
      }
      const comparisonGraphDataItem = comparisonGraphData.items.get(index)?.toJS();
      const comparisonData: { [key: string]: any } = {};
      if (comparisonGraphDataItem) {
        Object.keys(comparisonGraphDataItem).forEach((key) => {
          const newKey = getComparisonDataKey(key as BaseDataKey);
          comparisonData[newKey] = (comparisonGraphDataItem as { [key: string]: any })[key];
        });
      }
      const comparisonDate = comparisonData.comparisonPeriod?.fromDate;
      // 基本的にはgraphDataの日付をもとに表示するが、graphDataにデータがないときはcomparisonDataの日付を表示する
      const formattedDate = date?.format('YYYY-MM-DD') ?? comparisonDate?.format('YYYY-MM-DD') ?? '';
      // InsightGraphItemをobject化して、期間の開始日と共に返す
      return { ...data.toJS(), ...comparisonData, date: formattedDate };
    });
  }, [graphData.items, comparisonGraphData.items, aggregateUnit]);

  const onChangeDisabledDataKey = useCallback(
    (dataKey: DataKey) => {
      if (disableDataKeys.has(dataKey)) {
        setDisabledDataKeys(disableDataKeys.delete(dataKey));
      } else {
        setDisabledDataKeys(disableDataKeys.add(dataKey));
      }
    },
    [disableDataKeys],
  );

  const getGraphDataKey = useCallback(
    (dataKey: DataKey): string => {
      // 表示しないdataKeyには末尾にスペースをつけることで、凡例に渡すデータを残したままグラフを非表示にする
      return disableDataKeys.contains(dataKey) ? `${dataKey} ` : dataKey;
    },
    [disableDataKeys],
  );

  const renderLegend = useCallback(
    (data: any) => {
      const items: { color: string; dataKey: string; value: string }[] = data.payload;
      const sortedItems = hasMultipleData ? items.reverse() : items;
      return (
        <LegendWrapper>
          {sortedItems.map((item) => {
            // 非表示の場合はdataKeyの末尾にスペースがあるので削除する
            const dataKey = item.dataKey.trim() as DataKey;
            if (!isEnabledComparison && dataKey.startsWith('comparison')) {
              return null;
            }
            const isDisabled = disableDataKeys.contains(dataKey);
            return (
              <LegendItem key={item.dataKey} onClick={() => onChangeDisabledDataKey(dataKey)}>
                <StyledCheckbox checked={!isDisabled} onChange={() => onChangeDisabledDataKey(dataKey)} />
                <LegendItemLabel disabled={isDisabled} color={item.color}>
                  {item.dataKey.startsWith('comparison') ? '- -' : 'ー'} {item.value}
                </LegendItemLabel>
              </LegendItem>
            );
          })}
        </LegendWrapper>
      );
    },
    [disableDataKeys, hasMultipleData, isEnabledComparison, onChangeDisabledDataKey],
  );

  /** Y軸に表示する目盛りのフォーマット */
  const yAxisTickFormatter = useCallback(
    (value: number) => {
      // アクショングラフのアクション率表示の場合、カンマ区切りにしてパーセント記号をつける
      if (metricsType === 'action' && showActionRate) {
        return `${new Intl.NumberFormat('en', { style: 'percent', maximumFractionDigits: 2 }).format(value)}`;
      } else {
        // 上記以外の場合、カンマ区切りに変換
        return value.toLocaleString();
      }
    },
    [metricsType, showActionRate],
  );

  /** ツールチップに表示する文字列のフォーマット */
  const tooltipFormatter = useCallback(
    (value: number) => {
      // アクショングラフのアクション率表示の場合、パーセント表記に変換
      if (metricsType === 'action' && showActionRate) {
        return `${new Intl.NumberFormat('en', { style: 'percent', maximumFractionDigits: 2 }).format(value)}`;
      } else {
        // 上記以外の場合、カンマ区切りに変換
        return new Intl.NumberFormat('en').format(value);
      }
    },
    [metricsType, showActionRate],
  );

  const renderTooltip = useCallback(
    (data: TooltipProps<number, string>) => {
      const items = data.payload ?? [];
      const sortedItems = hasMultipleData ? items.reverse() : items;
      return (
        <ChartTooltip>
          <ChartTooltip.Title>{convertDateLabel(data.label, aggregateUnit)}</ChartTooltip.Title>
          {sortedItems.map((item) => (
            <ChartTooltip.Item key={item.dataKey}>
              <ChartTooltip.Label color={item.color}>{item.name}</ChartTooltip.Label>
              <ChartTooltip.Value>{item.value != null ? tooltipFormatter(item.value) : ''}</ChartTooltip.Value>
            </ChartTooltip.Item>
          ))}
        </ChartTooltip>
      );
    },
    [aggregateUnit, hasMultipleData, tooltipFormatter],
  );

  const renderComparisonTooltip = useCallback(
    (data: TooltipProps<number, string>) => {
      // itemsには表示されているグラフのデータのみが含まれる
      const items = data.payload ?? [];
      // 面グラフの重なり順を変更しているため、複数データをもっているかでデータの並び順が異なる
      const sortedItems = hasMultipleData ? items.reverse() : items;
      // payloadには表示されていないグラフも含めてすべてのデータが入っている
      const payload = items[0]?.payload;
      // item(グラフ)が1つ以上なければ表示するものはないのでnullを返す
      if (!payload) {
        return null;
      }
      // タイトル→指定期間のデータ→比較期間のデータの順番で、表示するべき内容だけを表示する
      let usedTitle = Set<string>(); // 使用済みのタイトル
      return (
        <ChartTooltip>
          {sortedItems.map((item) => {
            const dataKey = item.dataKey as DataKey;
            const baseDataKey = getBaseDataKey(dataKey);
            // dataKeyがcomparisonからはじまれば比較
            const isComparison = dataKey.startsWith('comparison');
            // タイトルは対象期間と比較期間で共通なので、一度だけ表示する
            const title = (item.name ?? '').replace('(比較)', '');
            const showTitle = !usedTitle.has(title);
            usedTitle = usedTitle.add(title);
            const date = item.payload.period?.fromDate.format('YYYY-MM-DD') ?? '';
            const comparisonDate = item.payload.comparisonPeriod?.fromDate.format('YYYY-MM-DD') ?? '';
            return (
              <React.Fragment key={dataKey}>
                {showTitle && <ChartTooltip.Title color={item.color}>{title}</ChartTooltip.Title>}
                <ChartTooltip.Item>
                  <ChartTooltip.Label>
                    {convertDateLabel(isComparison ? comparisonDate : date, aggregateUnit)}
                  </ChartTooltip.Label>
                  <ChartTooltip.Value>
                    {tooltipFormatter(payload[isComparison ? dataKey : baseDataKey])}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              </React.Fragment>
            );
          })}
        </ChartTooltip>
      );
    },
    [aggregateUnit, hasMultipleData, tooltipFormatter],
  );

  // stackedがtrueの場合は、stackIdを設定してfillOpacityを0.6にすることで積み上げグラフにする
  // stackedがfalseの場合は、stackIdを設定せずfillOpacityを0にすることで折れ線グラフ（のような見た目）にする
  const stackId = stacked ? '1' : undefined;
  const comparisonStackId = stacked ? '2' : undefined;
  const fillOpacity = stacked ? 0.6 : 0;
  const comparisonFillOpacity = stacked ? 0.2 : 0;

  return (
    <Wrapper>
      <MenuWrapper>
        {metricsType === 'action' && (
          <Menu>
            <Menu.Item
              name={'count'}
              active={!showActionRate}
              onClick={() => onChangeShowActionRate && onChangeShowActionRate(false)}
            >
              アクション数
            </Menu.Item>
            <Menu.Item
              name={'rate'}
              active={showActionRate}
              onClick={() => onChangeShowActionRate && onChangeShowActionRate(true)}
            >
              アクション率
            </Menu.Item>
          </Menu>
        )}
        {metricsType !== 'search' && (
          <Menu>
            <Menu.Item name={'stacked'} active={stacked} onClick={() => setStacked(true)}>
              積み上げグラフ
            </Menu.Item>
            <Menu.Item name={'unstacked'} active={!stacked} onClick={() => setStacked(false)}>
              折れ線グラフ
            </Menu.Item>
          </Menu>
        )}
      </MenuWrapper>
      <ResponsiveContainer minHeight={360}>
        <ComposedChart data={composedChartData} margin={{ top: 60, left: 20, right: 40 }}>
          <Tooltip
            labelFormatter={(value: string) => convertDateLabel(value, aggregateUnit)}
            formatter={(value: number) => tooltipFormatter(value)}
            content={isEnabledComparison ? renderComparisonTooltip : renderTooltip}
          />
          <Legend
            verticalAlign='bottom'
            align='left'
            wrapperStyle={{ top: '0', left: '0', right: '0', width: '100%' }}
            content={(props: any) => renderLegend(props)}
          />
          <XAxis dataKey='date' tickFormatter={(value) => convertDateLabel(value, aggregateUnit)} tickMargin={8} />
          <YAxis
            yAxisId='left'
            orientation='left'
            label={{ value: yAxisLabel, position: 'top', offset: 30 }}
            tickFormatter={(value: number) => yAxisTickFormatter(value)}
            tickMargin={8}
          />
          <CartesianGrid stroke={COLOR.GRAY} vertical={false} strokeDasharray='1 1' />
          {/* データ数が１の場合にAreaだと何も表示されなくなるので、別のグラフを表示する */}
          {/* 積み上げグラフの積み上げ順を凡例・ツールチップに合わせるため、AreaとLineでは順番を逆にしている */}
          {/* metricsType === 'search'の場合は、折れ線グラフのみ */}
          {metricsType === 'search' && (
            <>
              <Line
                yAxisId='left'
                name='ブランド検索(比較)'
                dataKey={getGraphDataKey('comparisonQueriesChain')}
                stroke={GRAPH_COLOR.PURPLE}
                fill={GRAPH_COLOR.LIGHT_PURPLE}
                legendType='plainline'
                strokeDasharray={'1 1'}
                r={2}
                strokeWidth={2}
              />
              <Line
                yAxisId='left'
                name='ブランド検索'
                dataKey={getGraphDataKey('queriesChain')}
                stroke={GRAPH_COLOR.PURPLE}
                fill={GRAPH_COLOR.LIGHT_PURPLE}
                legendType='plainline'
                r={2}
                strokeWidth={2}
              />
              <Line
                yAxisId='left'
                name='間接検索(比較)'
                dataKey={getGraphDataKey('comparisonQueriesIndirect')}
                stroke={GRAPH_COLOR.ORANGE}
                fill={GRAPH_COLOR.LIGHT_ORANGE}
                legendType='plainline'
                strokeDasharray={'1 1'}
                r={2}
                strokeWidth={2}
              />
              <Line
                yAxisId='left'
                name='間接検索'
                dataKey={getGraphDataKey('queriesIndirect')}
                stroke={GRAPH_COLOR.ORANGE}
                fill={GRAPH_COLOR.LIGHT_ORANGE}
                legendType='plainline'
                r={2}
                strokeWidth={2}
              />
              <Line
                yAxisId='left'
                name='直接検索(比較)'
                dataKey={getGraphDataKey('comparisonQueriesDirect')}
                stroke={GRAPH_COLOR.GREEN}
                fill={GRAPH_COLOR.LIGHT_GREEN}
                legendType='plainline'
                strokeDasharray={'1 1'}
                r={2}
                strokeWidth={2}
              />
              <Line
                yAxisId='left'
                name='直接検索'
                dataKey={getGraphDataKey('queriesDirect')}
                stroke={GRAPH_COLOR.GREEN}
                fill={GRAPH_COLOR.LIGHT_GREEN}
                legendType='plainline'
                r={2}
                strokeWidth={2}
              />
            </>
          )}
          {metricsType === 'action' &&
            (hasMultipleData ? (
              <>
                <Area
                  yAxisId='left'
                  name='電話をかける(比較)'
                  dataKey={getGraphDataKey('comparisonActionsPhone')}
                  stackId={comparisonStackId}
                  stroke={GRAPH_COLOR.PURPLE}
                  fill={GRAPH_COLOR.LIGHT_PURPLE}
                  fillOpacity={comparisonFillOpacity}
                  legendType='plainline'
                  strokeDasharray={'8 4'}
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='電話をかける'
                  dataKey={getGraphDataKey('actionsPhone')}
                  stackId={stackId}
                  stroke={GRAPH_COLOR.PURPLE}
                  fill={GRAPH_COLOR.LIGHT_PURPLE}
                  fillOpacity={fillOpacity}
                  legendType='plainline'
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='ルート検索(比較)'
                  dataKey={getGraphDataKey('comparisonActionsDrivingDirections')}
                  stackId={comparisonStackId}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  fillOpacity={comparisonFillOpacity}
                  legendType='plainline'
                  strokeDasharray={'8 4'}
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='ルート検索'
                  dataKey={getGraphDataKey('actionsDrivingDirections')}
                  stackId={stackId}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  fillOpacity={fillOpacity}
                  legendType='plainline'
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='ウェブサイトへのアクセス(比較)'
                  dataKey={getGraphDataKey('comparisonActionsWebsite')}
                  stackId={comparisonStackId}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  fillOpacity={comparisonFillOpacity}
                  legendType='plainline'
                  strokeDasharray={'8 4'}
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='ウェブサイトへのアクセス'
                  dataKey={getGraphDataKey('actionsWebsite')}
                  stackId={stackId}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  fillOpacity={fillOpacity}
                  legendType='plainline'
                  strokeWidth={2}
                />
              </>
            ) : (
              <>
                <Line
                  yAxisId='left'
                  name='ウェブサイトへのアクセス'
                  dataKey={getGraphDataKey('actionsWebsite')}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  legendType='plainline'
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='ウェブサイトへのアクセス(比較)'
                  dataKey={getGraphDataKey('comparisonActionsWebsite')}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  legendType='plainline'
                  strokeDasharray={'1 1'}
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='ルート検索'
                  dataKey={getGraphDataKey('actionsDrivingDirections')}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  legendType='plainline'
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='ルート検索(比較)'
                  dataKey={getGraphDataKey('comparisonActionsDrivingDirections')}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  legendType='plainline'
                  strokeDasharray={'1 1'}
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='電話をかける'
                  dataKey={getGraphDataKey('actionsPhone')}
                  stroke={GRAPH_COLOR.PURPLE}
                  fill={GRAPH_COLOR.LIGHT_PURPLE}
                  legendType='plainline'
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='電話をかける(比較)'
                  dataKey={getGraphDataKey('comparisonActionsPhone')}
                  stroke={GRAPH_COLOR.PURPLE}
                  fill={GRAPH_COLOR.LIGHT_PURPLE}
                  legendType='plainline'
                  strokeDasharray={'1 1'}
                  r={2}
                  strokeWidth={2}
                />
              </>
            ))}
          {metricsType === 'review' &&
            (hasMultipleData ? (
              <>
                <Area
                  yAxisId='left'
                  name='クチコミ数(コメントあり)(比較)'
                  dataKey={getGraphDataKey('comparisonCommentCount')}
                  stackId={comparisonStackId}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  fillOpacity={comparisonFillOpacity}
                  legendType='plainline'
                  strokeDasharray={'8 4'}
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='クチコミ数(コメントあり)'
                  dataKey={getGraphDataKey('commentCount')}
                  stackId={stackId}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  fillOpacity={fillOpacity}
                  legendType='plainline'
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='クチコミ数(評価のみ)(比較)'
                  dataKey={getGraphDataKey('comparisonRateCount')}
                  stackId={comparisonStackId}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  fillOpacity={comparisonFillOpacity}
                  legendType='plainline'
                  strokeDasharray={'8 4'}
                  strokeWidth={2}
                />
                <Area
                  yAxisId='left'
                  name='クチコミ数(評価のみ)'
                  dataKey={getGraphDataKey('rateCount')}
                  stackId={stackId}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  fillOpacity={fillOpacity}
                  legendType='plainline'
                  strokeWidth={2}
                />
              </>
            ) : (
              <>
                <Line
                  yAxisId='left'
                  name='クチコミ数(評価のみ)'
                  dataKey={getGraphDataKey('rateCount')}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  legendType='plainline'
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='クチコミ数(評価のみ)(比較)'
                  dataKey={getGraphDataKey('comparisonRateCount')}
                  stroke={GRAPH_COLOR.GREEN}
                  fill={GRAPH_COLOR.LIGHT_GREEN}
                  legendType='plainline'
                  strokeDasharray={'1 1'}
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='クチコミ数(コメントあり)'
                  dataKey={getGraphDataKey('commentCount')}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  legendType='plainline'
                  r={2}
                  strokeWidth={2}
                />
                <Line
                  yAxisId='left'
                  name='クチコミ数(コメントあり)(比較)'
                  dataKey={getGraphDataKey('comparisonCommentCount')}
                  stroke={GRAPH_COLOR.ORANGE}
                  fill={GRAPH_COLOR.LIGHT_ORANGE}
                  legendType='plainline'
                  strokeDasharray={'1 1'}
                  r={2}
                  strokeWidth={2}
                />
              </>
            ))}
        </ComposedChart>
      </ResponsiveContainer>
      {!isLoading && errorMessage && <GuideOverlay>{errorMessage}</GuideOverlay>}
      {isLoading && (
        <LoadingWrapper>
          <Loader active={isLoading} size='big' inline={true} />
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
  position: relative;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LegendWrapper = styled.div`
  padding-left: 80px;
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
`;

const LegendItem = styled.div`
  margin-left: 20px;
  padding: 2px 0;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  :hover {
    opacity: 0.8;
  }
`;

const LegendItemLabel = styled.div<{ disabled: boolean; color: string }>`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1.0)};
  color: ${({ color }) => color};
  margin-left: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 12px;
    color: #707070;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 16px;
`;
