import actionCreatorFactory from 'typescript-fsa';

import { TaskDetail } from 'models/Domain/TaskDetail';
import { User } from 'models/Domain/User';

const actionCreator = actionCreatorFactory('TaskDetail');

export const TaskDetailActions = {
  clearState: actionCreator('clearState'),
  fetchTask: actionCreator<number>('fetchTask'),
  setTask: actionCreator<TaskDetail>('setTask'),
  setAuthor: actionCreator<User>('setAuthor'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
};
