import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Location } from 'history';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { MemoApi } from 'ApiClient/MemoApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { MemoList, MemoTagList } from 'models/Domain/Memo/Memo';
import { MemoSearchCondition } from 'models/Domain/Memo/MemoSearchCondition';
import { Pagination } from 'models/Domain/Pagination';
import { waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

import { MemoActions } from './actions';

export default function* saga() {
  yield takeLatest(MemoActions.initializePage, initializePage);
  yield takeLatest(MemoActions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(MemoActions.createMemo, createMemo);
  yield takeLatest(MemoActions.updateMemo, updateMemo);
  yield takeLatest(MemoActions.deleteMemo, deleteMemo);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const isPreparedPage: boolean = yield select((state) => state.memo.isPreparedPage);

  // 初期化済みの場合は以下の処理を行わない
  if (isPreparedPage) {
    return;
  }

  yield put(MemoActions.setIsPreparedPage(true));

  // URLパラメータから検索条件を復元する
  yield call(updateMemos);
}

function* commitSearchCondition(action: ReturnType<typeof MemoActions.commitSearchCondition>) {
  const searchCondition = action.payload;
  const location: Location = yield select((state: RouterRootState) => state.router.location);
  // 既存とURLに差分がある場合、URLの更新を行う
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(pushWithOrganizationId(path));
  }
  yield put(MemoActions.setSearchCondition(searchCondition));
}

function* updateMemos() {
  const location: Location = yield select((state: RouterRootState) => state.router.location);

  // URLパラメータから検索条件を復元する
  const searchCondition = MemoSearchCondition.fromURLSearchParams(location.search);
  const search = searchCondition.toURLSearchParams();

  // 既存のURLと、検索条件から生成されるURLが異なる場合（不要なパラメータがある場合など）、正しいURLに置き換える
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // URLパラメータから復元した検索条件をセットする
  yield put(MemoActions.setSearchCondition(searchCondition));

  // データを取得する
  yield call(fetchMemos);
  yield call(fetchTags);
}

function* fetchMemos() {
  yield put(MemoActions.setIsLoadingMemos(true));
  const searchCondition: MemoSearchCondition = yield select((state) => state.memo.searchCondition);
  const params = searchCondition.toRequestParams();
  const response: YieldReturn<typeof MemoApi.get> = yield call(MemoApi.get, params);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'メモの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(MemoActions.setIsLoadingMemos(false));
    return;
  }
  const memoList = MemoList.fromJSON(response.data);
  const pagination = Pagination.fromJSON(response.data.pagination);
  yield put(MemoActions.setPagination(pagination));
  yield put(MemoActions.setMemoList(memoList));
  yield put(MemoActions.setIsLoadingMemos(false));
}

function* fetchTags() {
  yield put(MemoActions.setIsLoadingTags(true));
  const response: YieldReturn<typeof MemoApi.getTag> = yield call(MemoApi.getTag);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'タグの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(MemoActions.setIsLoadingTags(false));
    return;
  }
  const memoTagList = MemoTagList.fromJSON(response.data);
  yield put(MemoActions.setMemoTagList(memoTagList));
  yield put(MemoActions.setIsLoadingTags(false));
}

function* createMemo(action: ReturnType<typeof MemoActions.createMemo>) {
  const memo = action.payload;
  yield put(MemoActions.setIsLoadingTags(true));
  const response: YieldReturn<typeof MemoApi.post> = yield call(MemoApi.post, memo.toUpdateParams());
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メモを作成しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'メモの作成に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }
  // メモ一覧を更新する
  yield call(fetchMemos);
  yield call(fetchTags);
}

function* updateMemo(action: ReturnType<typeof MemoActions.updateMemo>) {
  const memo = action.payload;
  yield put(MemoActions.setIsLoadingTags(true));
  const response: YieldReturn<typeof MemoApi.patch> = yield call(MemoApi.patch, memo.id, memo.toUpdateParams());
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メモを更新しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'メモの更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }
  // メモ一覧を更新する
  yield call(fetchMemos);
  yield call(fetchTags);
}

function* deleteMemo(action: ReturnType<typeof MemoActions.deleteMemo>) {
  const memo = action.payload;
  yield put(MemoActions.setIsLoadingTags(true));
  const response: YieldReturn<typeof MemoApi.delete> = yield call(MemoApi.delete, memo.id);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メモを削除しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'メモの削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }
  // itemsが1件で削除したら空になってしまうので、2ページ目以降の場合は前のページの結果を取得する
  const memoList: MemoList = yield select((state) => state.memo.memoList);
  const searchCondition: MemoSearchCondition = yield select((state) => state.memo.searchCondition);
  const pagination: Pagination = yield select((state) => state.memo.pagination);
  const { current_page: currentPage } = pagination;
  const newPage = memoList.items.size === 1 && currentPage > 1 ? currentPage - 1 : currentPage;
  if (newPage !== currentPage) {
    yield put(MemoActions.setSearchCondition(searchCondition.setIn(['pagination', 'page'], newPage)));
  }

  // メモ一覧を更新する
  yield call(fetchMemos);
  yield call(fetchTags);
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // Pathがメモページ以外の場合は何もしない
  if (location.pathname !== Path.memo.index) {
    return;
  }

  // 初期化処理が完了していない場合は何もしない
  const isPreparedPage: boolean = yield select((state) => state.memo.isPreparedPage);
  if (!isPreparedPage) {
    return;
  }

  yield call(updateMemos);
}
