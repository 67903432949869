import axios from 'axios';

import { sleep } from 'helpers/utils';
import { AggregateUnit } from 'types/Common';

import { ErrorResponse, FaasApiClient, Response, createErrorResponse, createResponse } from './index';

const faasApiClient = new FaasApiClient({ useCurrentOrganization: false });

type AggregateMethod = 'total' | 'average';

type Period = {
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
};

export type GbpPerformanceMAStats = {
  business_impressions_desktop_maps: number | null;
  business_impressions_desktop_search: number | null;
  business_impressions_mobile_maps: number | null;
  business_impressions_mobile_search: number | null;
  website_clicks: number | null;
  call_clicks: number | null;
  business_direction_requests: number | null;
  business_conversations: number | null;
  business_bookings: number | null;
  business_food_orders: number | null;
  review_rate_count: number | null;
  total_review_rate_count: number | null;
  review_comment_count: number | null;
  total_review_comment_count: number | null;
  review_reply_count: number | null;
  total_review_reply_count: number | null;
  review_average_rating: number | null;
  review_period_average_rating: number | null;
};

export type GbpPerformanceMAStartParams = {
  aggregate_unit: AggregateUnit;
  start_date: Period['start_date'];
  end_date: Period['end_date'];
  organization_ids: number[];
  aggregate_method: AggregateMethod;
  exclude_incomplete_stores: boolean;
};

export type GbpPerformanceMAStartResponse = {
  executionArn: string;
};

export type GbpPerformanceMAStatusResponse =
  | {
      status: 'RUNNING';
      download_url: null;
      message: '';
    }
  | {
      status: 'SUCCESS';
      download_url: string;
      message: '';
    }
  | {
      status: 'FAILED';
      message: string;
    };

export type GbpPerformanceMADataResponse = {
  condition: {
    aggregate_unit: AggregateUnit;
    organization_ids: number[];
    period: Period;
    aggregate_method: AggregateMethod;
    exclude_incomplete_stores: boolean;
  };
  last_update_at: string;
  graph_items: {
    period: Period;
    stats: GbpPerformanceMAStats;
    organization_count: number;
  }[];
  table_items: {
    organization_id: number;
    period: Period;
    stats: GbpPerformanceMAStats;
    store_count: number;
  }[];
};

export type GbpPerformanceMAMonthlyStartParams = {
  month: string; // YYYY-MM
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
  organization_ids?: number[];
  aggregate_method?: AggregateMethod;
  exclude_incomplete_stores?: boolean;
};

export type GbpPerformanceMAMonthlyStartResponse = {
  executionArn: string;
};

export type GbpPerformanceMAMonthlyStatusResponse =
  | {
      status: 'RUNNING';
      download_url: null;
      message: '';
    }
  | {
      status: 'SUCCESS';
      download_url: string;
      message: '';
    }
  | {
      status: 'FAILED';
      message: string;
    };

export type GbpPerformanceMAMonthlyDataResponse = {
  condition: {
    month: string;
    start_date: string;
    end_date: string;
    organization_ids: number[];
  };
  items: {
    month: GbpPerformanceMAStats | null;
    last_month: GbpPerformanceMAStats | null;
    three_months_ago: GbpPerformanceMAStats | null;
    twelve_months_ago: GbpPerformanceMAStats | null;
  };
};

const GBP_PERFORMANCE_MA_API_ENDPOINT = `/gmb/performance:ma`;
const GBP_PERFORMANCE_MA_MONTHLY_API_ENDPOINT = `${GBP_PERFORMANCE_MA_API_ENDPOINT}/monthly`;
export class GbpPerformanceMAApi {
  static async get(
    params: GbpPerformanceMAStartParams,
  ): Promise<Response<GbpPerformanceMADataResponse> | ErrorResponse> {
    const startResponse = await faasApiClient.post<GbpPerformanceMAStartResponse>(
      `${GBP_PERFORMANCE_MA_API_ENDPOINT}/start`,
      params,
    );
    if (!startResponse.isSuccess) {
      return startResponse;
    }

    const executionArn = startResponse.data.executionArn;
    let url: string;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const statusResponse = await faasApiClient.post<GbpPerformanceMAStatusResponse>(
        `${GBP_PERFORMANCE_MA_API_ENDPOINT}/status`,
        { executionArn },
      );

      if (!statusResponse.isSuccess) {
        return statusResponse;
      }

      const data = statusResponse.data;
      if (data.status === 'RUNNING') {
        // 実行中なので、3秒後に再度チェックする
        await sleep(3000);
      } else if (data.status === 'SUCCESS') {
        // 取得が完了したのでループを抜ける
        url = data.download_url;
        break;
      } else {
        return createErrorResponse({ message: statusResponse.data.message });
      }
    }

    const dataResponse = await axios.get<GbpPerformanceMADataResponse>(url, { cache: true });
    if (!dataResponse.data) {
      return createErrorResponse({ message: 'データの取得に失敗しました。' });
    }
    return createResponse(dataResponse.data);
  }

  static async getMonthly(
    params: GbpPerformanceMAMonthlyStartParams,
  ): Promise<Response<GbpPerformanceMAMonthlyDataResponse> | ErrorResponse> {
    const startResponse = await faasApiClient.post<GbpPerformanceMAMonthlyStartResponse>(
      `${GBP_PERFORMANCE_MA_MONTHLY_API_ENDPOINT}/start`,
      params,
    );
    if (!startResponse.isSuccess) {
      return startResponse;
    }

    const executionArn = startResponse.data.executionArn;
    let url: string;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const statusResponse = await faasApiClient.post<GbpPerformanceMAMonthlyStatusResponse>(
        `${GBP_PERFORMANCE_MA_MONTHLY_API_ENDPOINT}/status`,
        { executionArn },
      );

      if (!statusResponse.isSuccess) {
        return statusResponse;
      }

      const data = statusResponse.data;
      if (data.status === 'RUNNING') {
        // 実行中なので、3秒後に再度チェックする
        await sleep(3000);
      } else if (data.status === 'SUCCESS') {
        // 取得が完了したのでループを抜ける
        url = data.download_url;
        break;
      } else {
        return createErrorResponse({ message: statusResponse.data.message });
      }
    }

    const dataResponse = await axios.get<GbpPerformanceMAMonthlyDataResponse>(url, { cache: true });
    if (!dataResponse.data) {
      return createErrorResponse({ message: 'データの取得に失敗しました。' });
    }
    return createResponse(dataResponse.data);
  }
}

export type GbpPerformanceMACsvCreateParams = {
  organization_ids: number[];
  aggregate_unit: AggregateUnit;
  period: {
    start_date: string; // YYYY-MM-DD
    end_date: string; // YYYY-MM-DD
  };
  columns: (keyof GbpPerformanceMAStats)[];
};
type GbpPerformanceMACsvCreateResponse = {
  executionArn: string;
};

type GbpPerformanceMACsvStatusParams = {
  executionArn: string;
};
type GbpPerformanceMACsvStatusResponse = {
  status: 'RUNNING' | 'SUCCEEDED' | 'FAILED';
  download_url: string;
  message: string;
};

const GBP_PERFORMANCE_CSV_API_ENDPOINT = `/gmb/performance:ma/csv`;
export class GbpPerformanceMACsvApi {
  static create(params: GbpPerformanceMACsvCreateParams) {
    return faasApiClient.post<GbpPerformanceMACsvCreateResponse>(`${GBP_PERFORMANCE_CSV_API_ENDPOINT}/create`, params);
  }

  static status(params: GbpPerformanceMACsvStatusParams) {
    return faasApiClient.post<GbpPerformanceMACsvStatusResponse>(`${GBP_PERFORMANCE_CSV_API_ENDPOINT}/status`, params);
  }
}
