import { Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { JSObject } from 'types/Common';

export interface GmbRecord {
  id: number | null;
  organization_id: number | null;
  name: string;
  email: string;
}

export default class Gmb extends Record<GmbRecord>({
  id: null,
  organization_id: null,
  name: '',
  email: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  validate() {
    const errors: JSObject = {};

    if (!this.name || !this.name.trim()) {
      errors.name = ErrorType.REQUIRED_ERROR;
    }

    if (!this.email || !this.email.trim()) {
      errors.email = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }
}
