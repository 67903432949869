import React from 'react';

import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import {
  AreaIcon,
  AreaKeywordLabel,
  AreaNameWrapper,
  ColorCell,
  ColorHeaderCell,
  RankCell,
  SearchWord,
} from 'components/pageComponents/MapCompetitorResearch/Common';
import { convertToConditionKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';

interface Props {
  className?: string;
  color: string | null;
  items: {
    areaName: string;
    searchWord: string;
    rank: number | null;
    adsRank: number | null;
  }[];
  hasAdsItem: boolean;
  onSelectAreaKeyword: (condition: { areaName: string; searchWord: string }) => void;
}

export const StoreDetailTable = React.memo<Props>(({ className, items, hasAdsItem, onSelectAreaKeyword, color }) => {
  return (
    <Table unstackable={true} className={className}>
      <TableHeader>
        <TableHeaderRow>
          <ColorHeaderCell color={color} />
          <StyledTableHeaderCell weight={1}>
            検索ワード
            <AreaNameWrapper>
              <AreaIcon type='pin_gray' />
              検索地点
            </AreaNameWrapper>
          </StyledTableHeaderCell>
          <StyledTableHeaderRankCell>
            <TableHeaderFlexWrapper>順位</TableHeaderFlexWrapper>
          </StyledTableHeaderRankCell>
          {hasAdsItem && (
            <StyledTableHeaderRankCell>
              <TableHeaderFlexWrapper>広告の順位</TableHeaderFlexWrapper>
            </StyledTableHeaderRankCell>
          )}
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <StoreDetailTableRow
            key={convertToConditionKey(item)}
            areaName={item.areaName}
            searchWord={item.searchWord}
            rank={item.rank}
            adsRank={item.adsRank}
            hasAdsItem={hasAdsItem}
            color={color}
            onSelectAreaKeyword={onSelectAreaKeyword}
          />
        ))}
      </TableBody>
    </Table>
  );
});

export const StoreDetailTableRow = React.memo<{
  areaName: string;
  searchWord: string;
  rank: number | null;
  adsRank: number | null;
  hasAdsItem: boolean;
  color: string | null;
  onSelectAreaKeyword: (condition: { areaName: string; searchWord: string }) => void;
}>(({ onSelectAreaKeyword, areaName, searchWord, rank, adsRank, hasAdsItem, color }) => {
  return (
    <StyledTableRow>
      <ColorCell color={color} />
      <StyledTableCell>
        <SearchWord onClick={() => onSelectAreaKeyword({ areaName, searchWord })}>
          <AreaKeywordLabel areaName={areaName} searchWord={searchWord} isLink />
        </SearchWord>
      </StyledTableCell>
      <StyledRankCell rank={rank} />
      {hasAdsItem && <StyledRankCell rank={adsRank} ads={!!adsRank} />}
    </StyledTableRow>
  );
});

const StyledTableRow = styled(TableRow)``;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  display: flex;
`;

const StyledTableHeaderRankCell = styled(TableHeaderCell)`
  &&& {
    width: 120px;
    padding-right: 20px;
  }
`;

const TableHeaderFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledTableCell = styled(TableCell)``;

const StyledRankCell = styled(RankCell)`
  &&& {
    padding-right: 20px;
  }
`;
