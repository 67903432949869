import React, { useCallback } from 'react';

import styled from 'styled-components';

import { DatePicker } from 'components/atoms/DatePicker';

type DateRangePickerProps = {
  className?: string;
  startDate: Date | null;
  endDate: Date | null;
  disabled?: boolean;
  onChange: ((startDate: Date | null, endDate: Date | null) => void) | null;
  maxDate?: Date;
  startPlaceholderText?: string;
  endPlaceholderText?: string;
} & Omit<React.ComponentProps<typeof DatePicker>, 'onChange' | 'startDate' | 'endDate'>;

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  className,
  startDate,
  endDate,
  disabled,
  onChange,
  maxDate,
  startPlaceholderText,
  endPlaceholderText,
  ...restProps
}) => {
  const onChangeStartDate = useCallback(
    (startDate: Date | null) => {
      if (!onChange) {
        // onChangeが定義されていない場合、処理しない
        return;
      }

      if (startDate && endDate) {
        // 開始日、終了日が逆転してる場合は、終了日を開始日に合わせるように修正する
        onChange(startDate, startDate < endDate ? endDate : startDate);
      } else {
        onChange(startDate, endDate);
      }
    },
    [endDate, onChange],
  );

  const onChangeEndDate = useCallback(
    (endDate: Date | null) => {
      if (!onChange) {
        // onChangeが定義されていない場合、処理しない
        return;
      }

      if (startDate && endDate) {
        // 開始日、終了日が逆転してる場合は、開始日を終了日に合わせるように修正する
        onChange(startDate < endDate ? startDate : endDate, endDate);
      } else {
        onChange(startDate, endDate);
      }
    },
    [startDate, onChange],
  );

  return (
    <Wrapper className={className}>
      <DatePickerWrapper>
        <DatePicker
          dateFormat='yyyy/MM/dd'
          selected={startDate}
          onChange={onChangeStartDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          disabled={disabled}
          maxDate={maxDate}
          placeholderText={startPlaceholderText}
          {...restProps}
        />
      </DatePickerWrapper>
      <Hyphen>〜</Hyphen>
      <DatePickerWrapper>
        <DatePicker
          dateFormat='yyyy/MM/dd'
          selected={endDate}
          onChange={onChangeEndDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          disabled={disabled}
          maxDate={maxDate}
          placeholderText={endPlaceholderText}
          {...restProps}
        />
      </DatePickerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export const DatePickerWrapper = styled.div`
  display: inline-block;
  width: calc((100% - 20px) / 2);
  min-width: 80px;
`;

const Hyphen = styled.div`
  display: inline-block;
  text-align: center;
  width: 20px;
`;
