import React from 'react';

import { Set as ImmutableSet } from 'immutable';
import { rgba } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { TableCell, TableRow } from 'components/molecules/Table';
import { StoreMenu } from 'components/organisms/StoreMenu';
import { createAlbumIndexPath, createLocalpostIndexPath, createReviewIndexPath } from 'helpers/path';
import {
  GbpPerformanceTableDataPair,
  ReviewType,
  SortKey,
  SortType,
  StatsType,
} from 'models/Domain/GbpPerformance/GbpPerformance';
import { GbpPerformanceSearchCondition as SearchCondition } from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';
import { Store } from 'models/Domain/Store';
import { Path } from 'routes';
import { COLOR } from 'style/color';

import { OpenableColumn, SortCondition } from '.';

type TableRowProps = {
  item: GbpPerformanceTableDataPair;
  sortCondition: SortCondition;
  searchCondition: SearchCondition;
} & (
  | {
      total?: never;
      store: Store;
    }
  | {
      total: true;
      store?: never;
    }
);

type ActivitiesTableRowProps = TableRowProps & { reviewType: ReviewType; openedColumns: Set<OpenableColumn> };

const IMPRESSIONS_ITEMS = [
  'businessImpressions',
  'businessImpressionsDesktopMaps',
  'businessImpressionsMobileMaps',
  'businessImpressionsDesktopSearch',
  'businessImpressionsMobileSearch',
] as const;

const INTERACTIONS_ITEMS = [
  'interactions',
  'callClicks',
  'businessDirectionRequests',
  'websiteClicks',
  'businessConversations',
  'businessBookings',
] as const;

type ClosableColumn = { key: StatsType; closable?: boolean };
const IMAGE_ITEMS: ClosableColumn[] = [
  { key: 'imageCount' },
  { key: 'imageInteriorCount', closable: true },
  { key: 'imageExteriorCount', closable: true },
  { key: 'imageProductCount', closable: true },
  { key: 'imageAdditionalCount', closable: true },
];

const PROMOTION_LIVE_ITEMS: ClosableColumn[] = [
  { key: 'promotionCount' },
  { key: 'promotionStandardCount', closable: true },
  { key: 'promotionEventCount', closable: true },
  { key: 'promotionOfferCount', closable: true },
  { key: 'promotionAlertCount', closable: true },
];

const PROMOTION_REJECTED_ITEMS: ClosableColumn[] = [
  { key: 'promotionRejectedCount' },
  { key: 'promotionStandardRejectedCount', closable: true },
  { key: 'promotionEventRejectedCount', closable: true },
  { key: 'promotionOfferRejectedCount', closable: true },
  { key: 'promotionAlertRejectedCount', closable: true },
];

const PERIOD_REVIEWS_ITEMS = [
  { key: 'periodReviews' },
  { key: 'periodReviewCommentCount', closable: true },
  { key: 'periodReviewRateCount', closable: true },
  { key: 'periodReviewReplyCount' },
  { key: 'periodReviewAverageRating' },
  { key: 'totalReviewAverageRating' },
];

const TOTAL_REVIEWS_ITEMS: ClosableColumn[] = [
  { key: 'totalReviews' },
  { key: 'totalReviewCommentCount', closable: true },
  { key: 'totalReviewRateCount', closable: true },
  { key: 'totalReviewReplyCount' },
  { key: 'periodReviewAverageRating' },
  { key: 'totalReviewAverageRating' },
];

const DATE_FORMAT = 'YYYY/MM/DD';

const StoreColumns: React.FC<{ store: Store; sortCondition: SortCondition; searchCondition: SearchCondition }> = ({
  store,
  sortCondition,
  searchCondition,
}) => {
  const searchParams = searchCondition
    .mergeIn(['filter'], {
      group: 'all',
      storeIds: ImmutableSet([store.id]),
      isAllStoreIds: false,
    })
    .toURLSearchParams();

  return (
    <>
      <StyledTableCell highlited={isSortedColumn(sortCondition, 'storeName')}>
        <StoreMenu
          trigger={
            <StoreName>
              {store.shortName}
              <StatsIcon />
            </StoreName>
          }
          group={searchCondition.filter.group}
          storeId={store.id}
          startDate={searchCondition.filter.startDate}
          endDate={searchCondition.filter.endDate}
          items={['storeDetail', 'searchKeyword', 'mapSearchRank', 'album', 'localpost', 'review']}
          additionalItems={{
            start: [{ label: 'この店舗で絞り込む', link: `${Path.gbp.performance}?${searchParams}`, target: '_blank' }],
          }}
        />
      </StyledTableCell>
      <StyledTableCell highlited={isSortedColumn(sortCondition, 'storeCode')} border={2}>
        <StoreCode>{store?.code}</StoreCode>
      </StyledTableCell>
    </>
  );
};

const TotalLabelColumns: React.FC<{ sortCondition: SortCondition; searchCondition: SearchCondition }> = ({
  sortCondition,
  searchCondition,
}) => {
  return (
    <>
      <StyledTableCell highlited={isSortedColumn(sortCondition, 'storeName')}>
        <StoreMenu
          trigger={
            <StoreName>
              合計
              <StatsIcon />
            </StoreName>
          }
          group={searchCondition.filter.group}
          storeIds={searchCondition.filter.isAllStoreIds ? undefined : searchCondition.filter.storeIds.toArray().sort()}
          startDate={searchCondition.filter.startDate}
          endDate={searchCondition.filter.endDate}
          items={['storeDetail', 'searchKeyword', 'mapSearchRank', 'album', 'localpost', 'review']}
        />
      </StyledTableCell>
      <StyledTableCell highlited={isSortedColumn(sortCondition, 'storeCode')} border={2} />
    </>
  );
};

export const OverviewTableRow: React.FC<TableRowProps> = ({ item, store, total, sortCondition, searchCondition }) => {
  const linkParams = {
    storeIds: store ? [store.id] : undefined,
    startDate: searchCondition.filter.startDate,
    endDate: searchCondition.filter.endDate,
  };
  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
      ) : (
        <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
      )}
      <NumberCell highlited={isSortedColumn(sortCondition, 'businessImpressions')}>
        <Number value={item.target.stats.businessImpressions} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
        <Number value={item.target.stats.interactions} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
        <Rate value={item.target.stats.getRate('interactions')} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'imageCount')}>
        <CellLink to={createAlbumIndexPath(linkParams)}>
          <Number value={item.target.stats.imageCount} />
        </CellLink>
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'promotionCount')}>
        <CellLink to={createLocalpostIndexPath(linkParams)}>
          <Number value={item.target.stats.promotionCount} />
        </CellLink>
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'periodReviews')}>
        <CellLink to={createReviewIndexPath(linkParams)}>
          <Number value={item.target.stats.periodReviews} />
        </CellLink>
      </NumberCell>
    </StyledTableRow>
  );
};

export const ImpressionsTableRow: React.FC<TableRowProps> = ({
  item,
  store,
  total,
  sortCondition,
  searchCondition,
}) => {
  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
      ) : (
        <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
      )}
      {IMPRESSIONS_ITEMS.map((key) => (
        <React.Fragment key={key}>
          <NumberCell highlited={isSortedColumn(sortCondition, key)}>
            <Number value={item.target.stats[key]} />
          </NumberCell>
        </React.Fragment>
      ))}
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
        <Number value={item.target.stats.interactions} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
        <Rate value={item.target.stats.getRate('interactions')} />
      </NumberCell>
    </StyledTableRow>
  );
};

export const InteractionsTableRow: React.FC<TableRowProps> = ({
  item,
  store,
  total,
  sortCondition,
  searchCondition,
}) => {
  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
      ) : (
        <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
      )}
      <NumberCell>
        <Number value={item.target.stats.businessImpressions} />
      </NumberCell>
      {INTERACTIONS_ITEMS.map((key) => (
        <React.Fragment key={key}>
          <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
            <Number value={item.target.stats[key]} />
          </NumberCell>
          <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
            <Rate value={item.target.stats.getRate(key)} />
          </NumberCell>
        </React.Fragment>
      ))}
    </StyledTableRow>
  );
};

export const ActivitiesTableRow: React.FC<ActivitiesTableRowProps> = ({
  item,
  store,
  total,
  sortCondition,
  searchCondition,
  reviewType,
  openedColumns,
}) => {
  const REVIEW_ITEMS = reviewType === 'total' ? TOTAL_REVIEWS_ITEMS : PERIOD_REVIEWS_ITEMS;
  const ACTIVITY_ITEMS = [
    ...IMAGE_ITEMS.filter((column) => (openedColumns.has('image') ? true : !column.closable)),
    ...PROMOTION_LIVE_ITEMS.filter((column) => (openedColumns.has('promotionLive') ? true : !column.closable)),
    ...PROMOTION_REJECTED_ITEMS.filter((column) => (openedColumns.has('promotionRejected') ? true : !column.closable)),
    ...REVIEW_ITEMS.filter((column) => (openedColumns.has('review') ? true : !column.closable)),
  ].map((column) => column.key) as ClosableColumn['key'][];

  const linkParams = {
    storeIds: store ? [store.id] : undefined,
    startDate: searchCondition.filter.startDate,
    endDate: searchCondition.filter.endDate,
  };

  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
      ) : (
        <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
      )}
      {ACTIVITY_ITEMS.map((key) => (
        <React.Fragment key={key}>
          <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
            {(() => {
              switch (key) {
                case 'totalReviewAverageRating':
                case 'periodReviewAverageRating': {
                  return <Rating value={item.target.stats[key]} />;
                }
                case 'imageCount':
                  return (
                    <CellLink to={createAlbumIndexPath(linkParams)}>
                      <Number value={item.target.stats.imageCount} />
                    </CellLink>
                  );
                case 'promotionCount':
                  return (
                    <CellLink to={createLocalpostIndexPath(linkParams)}>
                      <Number value={item.target.stats.promotionCount} />
                    </CellLink>
                  );
                case 'periodReviews':
                  return (
                    <CellLink to={createReviewIndexPath(linkParams)}>
                      <Number value={item.target.stats.periodReviews} />
                    </CellLink>
                  );
                case 'totalReviews':
                  return (
                    <CellLink to={createReviewIndexPath(linkParams)}>
                      <Number value={item.target.stats.totalReviews} />
                    </CellLink>
                  );
                default:
                  return <Number value={item.target.stats[key]} />;
              }
            })()}
          </NumberCell>
        </React.Fragment>
      ))}
    </StyledTableRow>
  );
};

export const OverviewWithComparisonTableRow: React.FC<TableRowProps> = ({
  item,
  store,
  total,
  sortCondition,
  searchCondition,
}) => {
  const linkParams = {
    storeIds: store ? [store.id] : undefined,
    startDate: searchCondition.filter.startDate,
    endDate: searchCondition.filter.endDate,
  };
  const comparisonLinkParams = {
    storeIds: store ? [store.id] : undefined,
    startDate: searchCondition.filter.comparisonStartDate,
    endDate: searchCondition.filter.comparisonEndDate,
  };
  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
        ) : (
          <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
        )}
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'businessImpressions')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'imageCount')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'promotionCount')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'periodReviews')} />
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'businessImpressions')}>
          <Number value={item.target.stats.businessImpressions} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.target.stats.interactions} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.target.stats.getRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'imageCount')}>
          <CellLink to={createAlbumIndexPath(linkParams)}>
            <Number value={item.target.stats.imageCount} />
          </CellLink>
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'promotionCount')}>
          <CellLink to={createLocalpostIndexPath(linkParams)}>
            <Number value={item.target.stats.promotionCount} />
          </CellLink>
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'periodReviews')}>
          <CellLink to={createReviewIndexPath(linkParams)}>
            <Number value={item.target.stats.periodReviews} />
          </CellLink>
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'businessImpressions')}>
          <Number value={item.comparison?.stats.businessImpressions} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.comparison?.stats.interactions} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.comparison?.stats.getRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'imageCount')}>
          <CellLink to={createAlbumIndexPath(comparisonLinkParams)}>
            <Number value={item.comparison?.stats.imageCount} />
          </CellLink>
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'promotionCount')}>
          <CellLink to={createLocalpostIndexPath(comparisonLinkParams)}>
            <Number value={item.comparison?.stats.promotionCount} />
          </CellLink>
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'periodReviews')}>
          <CellLink to={createReviewIndexPath(comparisonLinkParams)}>
            <Number value={item.comparison?.stats.periodReviews} />
          </CellLink>
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell colSpan={2} border={2}>
          <Label>変化率</Label>
        </RightAlignCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'businessImpressions')}>
          <DiffRate value={item.getDiffRate('businessImpressions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <DiffRate value={item.getDiffRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <DiffRate value={item.getRateDiffRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'imageCount')}>
          <DiffRate value={item.getDiffRate('imageCount')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'promotionCount')}>
          <DiffRate value={item.getDiffRate('promotionCount')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'periodReviews')}>
          <DiffRate value={item.getDiffRate('periodReviews')} />
        </NumberCell>
      </NarrowRow>
    </>
  );
};

export const ImpressionsWithComparisonTableRow: React.FC<TableRowProps> = ({
  item,
  store,
  total,
  sortCondition,
  searchCondition,
}) => {
  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
        ) : (
          <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
        )}
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <StyledTableCell highlited={isSortedColumn(sortCondition, key)} />
          </React.Fragment>
        ))}
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')} />
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key)}>
              <Number value={item.target.stats[key]} />
            </NumberCell>
          </React.Fragment>
        ))}
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.target.stats.interactions} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.target.stats.getRate('interactions')} />
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key)}>
              <Number value={item.comparison?.stats[key]} />
            </NumberCell>
          </React.Fragment>
        ))}
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.comparison?.stats.interactions} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.comparison?.stats.getRate('interactions')} />
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell colSpan={2} border={2}>
          <Label>変化率</Label>
        </RightAlignCell>
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key)}>
              <DiffRate value={item.getDiffRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <DiffRate value={item.getDiffRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <DiffRate value={item.getRateDiffRate('interactions')} />
        </NumberCell>
      </NarrowRow>
    </>
  );
};

export const InteractionsWithComparisonTableRow: React.FC<TableRowProps> = ({
  item,
  store,
  total,
  sortCondition,
  searchCondition,
}) => {
  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
        ) : (
          <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
        )}
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'businessImpressions')} />
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <StyledTableCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0} />
            <StyledTableCell highlited={isSortedColumn(sortCondition, key, 'rate')} />
          </React.Fragment>
        ))}
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'businessImpressions')}>
          <Number value={item.target.stats.businessImpressions} />
        </NumberCell>
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
              <Number value={item.target.stats[key]} />
            </NumberCell>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
              <Rate value={item.target.stats.getRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'businessImpressions')}>
          <Number value={item.comparison?.stats.businessImpressions} />
        </NumberCell>
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
              <Number value={item.comparison?.stats[key]} />
            </NumberCell>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
              <Rate value={item.comparison?.stats.getRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell colSpan={2} border={2}>
          <Label>変化率</Label>
        </RightAlignCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'businessImpressions')}>
          <DiffRate value={item.getDiffRate('businessImpressions')} />
        </NumberCell>
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
              <DiffRate value={item.getDiffRate(key)} />
            </NumberCell>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
              <DiffRate value={item.getRateDiffRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
    </>
  );
};

export const ActivitiesWithComparisonTableRow: React.FC<ActivitiesTableRowProps> = ({
  item,
  store,
  total,
  sortCondition,
  searchCondition,
  reviewType,
  openedColumns,
}) => {
  const REVIEW_ITEMS = reviewType === 'total' ? TOTAL_REVIEWS_ITEMS : PERIOD_REVIEWS_ITEMS;
  const ACTIVITY_ITEMS = [
    ...IMAGE_ITEMS.filter((column) => (openedColumns.has('image') ? true : !column.closable)),
    ...PROMOTION_LIVE_ITEMS.filter((column) => (openedColumns.has('promotionLive') ? true : !column.closable)),
    ...PROMOTION_REJECTED_ITEMS.filter((column) => (openedColumns.has('promotionRejected') ? true : !column.closable)),
    ...REVIEW_ITEMS.filter((column) => (openedColumns.has('review') ? true : !column.closable)),
  ].map((column) => column.key) as ClosableColumn['key'][];

  const linkParams = {
    storeIds: store ? [store.id] : undefined,
    startDate: searchCondition.filter.startDate,
    endDate: searchCondition.filter.endDate,
  };
  const comparisonLinkParams = {
    storeIds: store ? [store.id] : undefined,
    startDate: searchCondition.filter.comparisonStartDate,
    endDate: searchCondition.filter.comparisonEndDate,
  };

  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} searchCondition={searchCondition} />
        ) : (
          <StoreColumns store={store} sortCondition={sortCondition} searchCondition={searchCondition} />
        )}
        {ACTIVITY_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <StyledTableCell highlited={isSortedColumn(sortCondition, key, 'number')} />
          </React.Fragment>
        ))}
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        {ACTIVITY_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
              {(() => {
                switch (key) {
                  case 'totalReviewAverageRating':
                  case 'periodReviewAverageRating': {
                    return <Rating value={item.target.stats[key]} />;
                  }
                  case 'imageCount':
                    return (
                      <CellLink to={createAlbumIndexPath(linkParams)}>
                        <Number value={item.target.stats.imageCount} />
                      </CellLink>
                    );
                  case 'promotionCount':
                    return (
                      <CellLink to={createLocalpostIndexPath(linkParams)}>
                        <Number value={item.target.stats.promotionCount} />
                      </CellLink>
                    );
                  case 'periodReviews':
                    return (
                      <CellLink to={createReviewIndexPath(linkParams)}>
                        <Number value={item.target.stats.periodReviews} />
                      </CellLink>
                    );
                  case 'totalReviews':
                    return (
                      <CellLink to={createReviewIndexPath(linkParams)}>
                        <Number value={item.target.stats.totalReviews} />
                      </CellLink>
                    );
                  default:
                    return <Number value={item.target.stats[key]} />;
                }
              })()}
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell colSpan={2} border={2}>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        {ACTIVITY_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
              {(() => {
                switch (key) {
                  case 'totalReviewAverageRating':
                  case 'periodReviewAverageRating': {
                    return <Rating value={item.comparison?.stats[key]} />;
                  }
                  case 'imageCount':
                    return (
                      <CellLink to={createAlbumIndexPath(comparisonLinkParams)}>
                        <Number value={item.comparison?.stats.imageCount} />
                      </CellLink>
                    );
                  case 'promotionCount':
                    return (
                      <CellLink to={createLocalpostIndexPath(comparisonLinkParams)}>
                        <Number value={item.comparison?.stats.promotionCount} />
                      </CellLink>
                    );
                  case 'periodReviews':
                    return (
                      <CellLink to={createReviewIndexPath(comparisonLinkParams)}>
                        <Number value={item.comparison?.stats.periodReviews} />
                      </CellLink>
                    );
                  case 'totalReviews':
                    return (
                      <CellLink to={createReviewIndexPath(comparisonLinkParams)}>
                        <Number value={item.target.stats.totalReviews} />
                      </CellLink>
                    );
                  default:
                    return <Number value={item.comparison?.stats[key]} />;
                }
              })()}
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell colSpan={2} border={2}>
          <Label>変化率</Label>
        </RightAlignCell>
        {ACTIVITY_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
              <DiffRate value={item.getDiffRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
    </>
  );
};

const isSortedColumn = (sortCondition: SortCondition, sortKey: SortKey, sortType: SortType = 'number') =>
  sortCondition?.key === sortKey && sortCondition?.type === sortType ? '1' : '0';

const Number = ({ value }: { value: number | null | undefined }) => {
  return <>{value == null ? 'ー' : value.toLocaleString()}</>;
};

const Rating = ({ value }: { value: number | null | undefined }) => {
  return (
    <>
      {value == null ? 'ー' : value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
    </>
  );
};

const Rate = ({ value }: { value: number | null | undefined }) => {
  return (
    <>
      {value == null
        ? 'ー'
        : `${(value * 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}%`}
    </>
  );
};

const DiffRate = styled.span<{ value: number | null | undefined }>`
  color: ${({ value }) => (!value ? COLOR.BLACK : value >= 0 ? COLOR.GREEN : COLOR.RED)};
  &:before {
    content: '${({ value }) => (value == null ? '' : value === 0 ? '' : value > 0 ? '↑' : '↓')}';
    margin-right: 4px;
  }
  &:after {
    content: '${({ value }) => (value == null ? 'ー' : `${(Math.abs(value) * 100).toFixed(1)}%`)}';
  }
`;

const StatsIcon = styled(Icon).attrs(() => ({ type: 'assessment' }))`
  width: 18px;
  height: 18px;
  padding: 0;
  vertical-align: middle;
  margin-left: 4px;
`;

const StoreName = styled.span`
  font-weight: bold;
`;

const StyledTableCell = styled(TableCell)<{ highlited?: '0' | '1'; border?: number }>`
  &&& {
    background: ${({ highlited = '0' }) => (highlited === '1' ? rgba(COLOR.GREEN, 0.1) : 'inherit')};
    font-weight: ${({ highlited = '0' }) => (highlited === '1' ? 'bold' : 'inherit')};

    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
  }
`;

const CellLink = styled(Link).attrs(() => ({ target: '_blank' }))``;

const StyledTableRow = styled(TableRow)<{ total: '0' | '1' }>`
  &&& {
    background: ${({ total = '0' }) => (total === '1' ? '#f9fafb' : 'inherit')};
  }

  ${StatsIcon} {
    visibility: hidden;
  }

  :hover {
    ${StoreName} {
      text-decoration: underline;
    }
    ${StatsIcon} {
      visibility: visible;
    }
    ${CellLink} {
      text-decoration: underline;
    }
  }
`;

const NarrowRow = styled(StyledTableRow)`
  &&& {
    min-height: 23px;

    ${StyledTableCell} {
      padding-top: 0;
      padding-bottom: 0;

      border-top-style: dashed;
      border-top-color: rgba(0.34, 0.36, 0.38, 0.1);
    }
    &:first-child {
      ${StyledTableCell} {
        border-top-width: 0;
      }
    }
  }
`;

const NarrowHeaderRow = styled(NarrowRow)`
  &&& {
    ${StyledTableCell} {
      border-top-style: solid;
      border-top-width: 2px;
    }

    &:first-child {
      ${StyledTableCell} {
        border-top-width: 1px;
      }
    }
  }
`;

const RightAlignCell = styled(StyledTableCell)`
  &&& {
    text-align: right;
  }
`;

const NumberCell = styled(RightAlignCell)`
  &&& {
    font-weight: bold;
    font-size: 14px;
    font-family: monospace;
  }
`;

const PeriodCell = styled(StyledTableCell)`
  &&& {
    color: ${COLOR.DARK_GRAY};
    text-align: right;
    font-family: monospace;
  }
`;

const StoreCode = styled.span`
  font-weight: bold;
`;

const Label = styled.span`
  font-weight: bold;
`;
