import React, { useCallback, useState } from 'react';

import { Set } from 'immutable';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import { PullDown } from 'components/atoms/PullDown';
import { OfferTypeIcon } from 'components/molecules/OfferTypeIcon';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { OfferEditForm } from 'components/organisms/OfferEditForm';
import { OfferEditSender } from 'components/pageComponents/OfferCreate/OfferEditSender';
import { OfferCommon } from 'models/Composite/OfferCommon';
import { DestinationType, OfferStores } from 'models/Composite/OfferStores';
import { OfferType, OfferTypeOptions } from 'models/Domain/Offer';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';
import { Group } from 'types/Common';

import { OfferEditDestination } from './OfferEditDestination';

type SelectedGroupStore = {
  group: Group;
  storeIds: Set<number>;
  showClosedStores: boolean;
  isAllStores: boolean;
};

const OfferTypeIconOptions = OfferTypeOptions.map((option) => ({
  ...option,
  icon: <OfferTypeIcon offer_type={option.value} />,
}));

const OfficeIconOptions = OfferTypeIconOptions.filter((option) => option.value !== 'report');
const StoreIconOptions = OfferTypeIconOptions.filter((option) => option.value !== 'task');

type Props = {
  offerStores: OfferStores;
  changeOfferStores: (v: OfferStores) => void;
  handleAcceptedFiles: (v: File[]) => void;
  handleRejectedFiles: (v: File[]) => void;
};

export const OfferNewForm = React.memo<Props>(
  ({ offerStores, changeOfferStores, handleAcceptedFiles, handleRejectedFiles }) => {
    const { stores, storeLists, currentUser } = useSelector(
      (state: State) => ({
        stores: state.store.stores,
        storeLists: state.storeList.storeLists,
        currentUser: state.app.currentUser,
      }),
      shallowEqual,
    );
    const { offer, storeListId } = offerStores;
    const options = currentUser.isMemberUser ? StoreIconOptions : OfficeIconOptions;

    const initialGroupStoreState: SelectedGroupStore = {
      group: null,
      storeIds: Set(),
      showClosedStores: false,
      isAllStores: false,
    };

    const [selectedGroupStore, setSelectedGroupStore] = useState<SelectedGroupStore>(initialGroupStoreState);

    const handleChangeGroupStore = useCallback(
      (group: Group, storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
        setSelectedGroupStore({ group, storeIds, isAllStores, showClosedStores });
        changeOfferStores(
          offerStores
            .changeDestinationType(DestinationType.Store)
            .changeStores(storeIds.toArray())
            .changeStoreListId(0),
        );
      },
      [changeOfferStores, offerStores],
    );

    return (
      <Wrapper>
        <Field>
          <Label>{offer.getLabel('offer_type')}</Label>
          <PullDown
            value={offer.offer_type}
            ValueIcon={<OfferTypeIcon offer_type={offer.offer_type} />}
            placeholder='選択してください'
            options={options}
            onChange={(value: OfferType) => changeOfferStores(offerStores.changeOfferType(value))}
          />
        </Field>
        {currentUser.isMemberUser && (
          <OfferEditSender
            offerStores={offerStores}
            changeOfferStores={changeOfferStores}
            stores={stores.filterStoresById(currentUser.stores.toArray())}
          />
        )}
        {!currentUser.isMemberUser && offer.offer_type === 'other_offer' && (
          <OfferEditDestination
            offerStores={offerStores}
            changeOfferStores={changeOfferStores}
            storeListId={storeListId}
            storeLists={storeLists}
            stores={stores}
          />
        )}
        {!currentUser.isMemberUser && offer.offer_type === 'task' && (
          <GroupStoreSelectWrapper>
            <GroupStoreSelect
              group={selectedGroupStore.group}
              storeIds={selectedGroupStore.storeIds}
              showClosedStores={selectedGroupStore.showClosedStores}
              sizeVariant={'large'}
              onChange={handleChangeGroupStore}
            />
          </GroupStoreSelectWrapper>
        )}
        <OfferEditForm
          offerCommon={offer}
          changeOfferCommon={(value: OfferCommon) => changeOfferStores(offerStores.changeOffer(value))}
          handleAcceptedFiles={handleAcceptedFiles}
          handleRejectedFiles={handleRejectedFiles}
        />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  background-color: ${COLOR.WHITE};
`;

const Field = styled.div`
  padding-bottom: 18px;
  padding-right: 18px;
  padding-left: 18px;
  &:first-of-type {
    padding-top: 18px;
  }
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;

const GroupStoreSelectWrapper = styled.div`
  margin: 18px;
`;
