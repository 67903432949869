import React from 'react';

import styled from 'styled-components';

import { GoogleMap } from 'components/atoms/GoogleMap';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { LocationDiffKey } from 'models/Domain/GmbLocationDiffs';

// 緯度経度を表示する際のGoogleマップのズームレベル
// Googleビジネスプロフィールの住所編集画面より2回寄った状態を指定
const LATLNG_MAP_ZOOM_LEVEL = 17;

export type Props = {
  gmbLocation: GmbLocation;
  locationKey: Exclude<LocationDiffKey, 'attributes' | 'moreHours' | 'serviceItems'>;
  gmbCategoryList: GmbCategories;
};
export const GmbLocationValue: React.FC<Props> = ({ gmbLocation, locationKey, gmbCategoryList }) => {
  switch (locationKey) {
    case 'locationName':
      return <>{gmbLocation.locationName}</>;
    case 'storeCode':
      return <>{gmbLocation.storeCode}</>;
    case 'websiteUrl':
      return <>{gmbLocation.websiteUrl}</>;
    case 'phoneNumbers':
      return (
        <>
          <SubLabel>電話番号</SubLabel>
          {gmbLocation.primaryPhone}
          <br />
          <br />
          <SubLabel>追加の電話番号</SubLabel>
          {gmbLocation.additionalPhones.map((additionalPhone, index) => (
            <div key={index}>{additionalPhone}</div>
          ))}
        </>
      );
    case 'address':
      return (
        <div>
          <div>{gmbLocation.address?.postalCode}</div>
          <div>
            {gmbLocation.address.administrativeArea}
            {gmbLocation.address.address1}
          </div>
          <div>{gmbLocation.address?.address2}</div>
        </div>
      );
    case 'latlng':
      return (
        <div>
          <StyledGoogleMap
            latitude={gmbLocation.latlng.latitude}
            longitude={gmbLocation.latlng.longitude}
            zoom={LATLNG_MAP_ZOOM_LEVEL}
          />
        </div>
      );
    case 'regularHours':
      return (
        <>
          {gmbLocation.regularHoursForDisplay.map((businessHour, index) => (
            <div key={index}>{businessHour}</div>
          ))}
        </>
      );
    case 'specialHours':
      return (
        <>
          {gmbLocation.specialHoursForDisplay.map((specialHour, index) => (
            <div key={index}>{specialHour}</div>
          ))}
        </>
      );
    case 'categories':
      return (
        <>
          <SubLabel>メインカテゴリー</SubLabel>
          {gmbCategoryList.generateDisplayName(gmbLocation.primaryCategory.categoryId)}
          <br />
          <br />
          <SubLabel>サブカテゴリー</SubLabel>
          {gmbLocation.additionalCategories.list.map((additionalCategory, idx) => {
            return <div key={idx}>{gmbCategoryList.generateDisplayName(additionalCategory.categoryId)}</div>;
          })}
        </>
      );
    case 'openInfo':
      return <>{gmbLocation.openInfo.statusName}</>;
    case 'openingDate':
      return <>{gmbLocation.openingDate?.displayDate ?? '未設定'}</>;
    case 'profile':
      return <Profile>{gmbLocation.profile.description}</Profile>;
  }
};

const Profile = styled.div`
  white-space: normal;
`;

const SubLabel = styled.div`
  font-weight: bold;
`;

const StyledGoogleMap = styled(GoogleMap)`
  width: 300px;

  /* 地図のズーム・ズームアウトボタンを表示して、必要に応じて細かく確認できるようにしたい。
   * ズームボタンが表示されるのが縦200px以上のため、200pxとしている */
  height: 200px;
`;
