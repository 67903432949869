import { List as ImmutableList, Map as ImmutableMap, Record as ImmutableRecord } from 'immutable';

import { YearMonth } from 'models/Domain/YearMonth';
import { JSObject, SortOrder } from 'types/Common';

import { Condition, GeoTarget } from './index';

export class MonthlySearchVolume extends ImmutableRecord<{
  period: YearMonth;
  value: number | null;
}>({
  period: new YearMonth(),
  value: null,
}) {
  static fromJSON(data: JSObject) {
    return new MonthlySearchVolume({
      period: YearMonth.fromString(data.period),
      value: data.value,
    });
  }
}

export class SearchVolumeMetrics extends ImmutableRecord<{
  competition: string | null;
  monthlySearchVolumes: ImmutableList<MonthlySearchVolume>;
}>({
  competition: '',
  monthlySearchVolumes: ImmutableList(),
}) {
  static fromJSON(data: JSObject) {
    return new SearchVolumeMetrics({
      competition: data.competition ?? null,
      monthlySearchVolumes: data.monthly_search_volumes
        ? ImmutableList(data.monthly_search_volumes.map((item: JSObject) => MonthlySearchVolume.fromJSON(item)))
        : ImmutableList(),
    });
  }

  getDataByMonth(month: YearMonth) {
    return this.monthlySearchVolumes.find((item) => item.period.isSame(month))?.value;
  }

  getGrowthRatio(month: YearMonth, ago: number) {
    const targetData = this.getDataByMonth(month);
    const comparisonData = this.getDataByMonth(month.add({ months: -ago }));
    return targetData != null && comparisonData ? targetData / comparisonData - 1.0 : null;
  }

  getAverage(startMonth: YearMonth, endMonth: YearMonth) {
    // 集計の開始月から終了月までのデータを取得していき、平均を求める
    const range = YearMonth.range(startMonth, endMonth);
    const sum = range.reduce((sum, month) => sum + (this.getDataByMonth(month) ?? 0), 0);
    return sum / range.length;
  }
}

export class SearchVolumeSearchResultItem extends ImmutableRecord<{
  keyword: string;
  metrics: SearchVolumeMetrics;
}>({
  keyword: '',
  metrics: new SearchVolumeMetrics(),
}) {
  static fromJSON(data: JSObject) {
    return new SearchVolumeSearchResultItem({
      keyword: data.keyword,
      metrics: SearchVolumeMetrics.fromJSON(data.metrics),
    });
  }
}

export class SearchVolumeSearchResult extends ImmutableRecord<{
  status: 'SUCCESS' | 'FAILED';
  condition: Condition;
  geoTarget: GeoTarget;
  items: ImmutableList<SearchVolumeSearchResultItem>;
}>({
  status: 'SUCCESS',
  condition: new Condition(),
  geoTarget: new GeoTarget(),
  items: ImmutableList(),
}) {
  static fromJSON(data: JSObject) {
    return new SearchVolumeSearchResult({
      status: data.status,
      condition: Condition.fromJSON(data.condition),
      geoTarget: GeoTarget.fromJSON(data.geo_target),
      items: data.items
        ? ImmutableList(data.items.map((item: JSObject) => SearchVolumeSearchResultItem.fromJSON(item)))
        : ImmutableList(),
    });
  }

  // 検索したワード
  get item() {
    return this.items.first(null);
  }

  // 検索したワードの関連キーワード
  get relatedItems() {
    return this.items.rest();
  }
}

export class SearchVolumeSearchResults extends ImmutableRecord<{
  data: ImmutableMap<string, SearchVolumeSearchResult>;
}>({
  data: ImmutableMap(),
}) {
  hasData(url: string) {
    return this.data.has(url);
  }

  getData(url: string | null) {
    if (!url) {
      return undefined;
    }
    return this.data.get(url);
  }

  setData(url: string, searchResult: SearchVolumeSearchResult) {
    return this.update('data', (data) => data.set(url, searchResult));
  }

  getMetrics(url: string | null) {
    return this.getData(url)?.item?.metrics;
  }
}

export type SortKey =
  | 'searchWord'
  | 'inputArea'
  | 'searchArea'
  | 'average'
  | 'latest'
  | 'momRatio'
  | 'threeMomRatio'
  | 'twelveMomRatio';

export class SortCondition extends ImmutableRecord<{
  key: SortKey;
  order: SortOrder;
}>({
  key: 'average',
  order: 'desc',
}) {}
