import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { BackArrow, StickyHeader, Title } from 'components/atoms/StickyHeader';
import { COLOR } from 'style/color';

type PromotionEditHeaderProps = {
  className?: string;
  title: string;
  onDelete?: () => void;
  onBack: () => void;
  formType: 'create' | 'update';
};

export const PromotionEditHeader: React.FC<PromotionEditHeaderProps> = ({
  className,
  title,
  onBack,
  onDelete,
  formType,
}) => {
  return (
    <StickyHeader className={className}>
      <LeftWrapper>
        <TitleWrapper onClick={onBack}>
          <BackArrow />
          <Title>{title}</Title>
        </TitleWrapper>
      </LeftWrapper>
      <RightWrapper>
        {formType === 'update' && (
          <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
            <Dropdown.Menu direction={'left'}>
              <ActionMenuItem onClick={onDelete}>
                <ActionItemLabel>削除</ActionItemLabel>
              </ActionMenuItem>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </RightWrapper>
    </StickyHeader>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LeftWrapper = styled.div``;

const RightWrapper = styled.div``;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

const ActionItemLabel = styled.div`
  padding: 10px;
`;

const Trigger = styled.div`
  cursor: pointer;
  letter-spacing: -10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${COLOR.GREEN};
  transform: rotate(90deg) translateX(-4px);
`;
