import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { TextArea } from 'components/atoms/TextArea';
import { MenuHelp as Help } from 'helpers/ContextHelp';
import { isNonNegativeInteger } from 'helpers/utils';
import {
  DietaryRestriction,
  DietaryRestrictions,
  MAX_MENU_ITEM_DESCRIPTION_LENGTH,
  MAX_MENU_ITEM_NAME_LENGTH,
  MenuItem,
  dietaryRestrictionLabels,
} from 'models/Domain/Menu/Menu';
import { COLOR } from 'style/color';

import { ImageUploader } from './MenuItemModalImageUploader';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  item: MenuItem;
  onChange: (item: MenuItem) => void;
  onDelete?: () => void;
  isEdit: boolean;
};

export const MenuItemModal = React.memo<Props>(({ isOpen, onClose, item: defaultItem, onChange, onDelete, isEdit }) => {
  const { uploadedImageUrl } = useSelector((state) => state.menu);
  const [itemForEdit, setItemForEdit] = useState<MenuItem>(defaultItem);
  const [inputPrice, setInputPrice] = useState<string>(defaultItem.price != null ? `${defaultItem.price}` : '');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [validateImage, setValidateImage] = useState<{ isValid: boolean; error?: string }>({ isValid: true });

  const isValid = useMemo(() => {
    return itemForEdit.isValid() && validateImage.isValid;
  }, [itemForEdit, validateImage]);

  const handleOnSave = useCallback(() => {
    // エラーがあればエラーの表示を有効にして、保存しない
    if (!isValid) {
      setShowErrorMessage(true);
      window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
    } else {
      onChange(itemForEdit);
      onClose();
    }
  }, [isValid, onChange, itemForEdit, onClose]);

  const handleOnChangeName = useCallback((value: string) => {
    setItemForEdit((item) => item.changeName(value));
  }, []);

  const handleOnChangePrice = useCallback((value: string) => {
    // valueから数値以外の文字を取り除く
    const numberValue = value.replace(/[^0-9]/g, '');
    setInputPrice(numberValue);
  }, []);

  const handleOnBlurPrice = useCallback(() => {
    const price = parseInt(inputPrice);
    if (isNonNegativeInteger(inputPrice)) {
      setItemForEdit((item) => item.changePrice(price));
    } else {
      setInputPrice('');
      setItemForEdit((item) => item.changePrice(null));
    }
  }, [inputPrice]);

  const handleOnChangeDescription = useCallback((value: string) => {
    setItemForEdit((item) => item.changeDescription(value));
  }, []);

  const handleOnChangeDietaryRestriction = useCallback(
    (value: DietaryRestriction) => () => {
      setItemForEdit((item) => {
        let dietaryRestrictions: ImmutableList<DietaryRestriction> | null =
          item.dietaryRestrictions ?? ImmutableList<DietaryRestriction>();
        // 既に選択されている場合は削除、そうでない場合は追加
        // GBPの画面の仕様に合わせて、同時に複数の項目は選択されないようにする
        if (dietaryRestrictions.contains(value)) {
          dietaryRestrictions = null;
        } else {
          dietaryRestrictions = ImmutableList([value]);
        }
        return item.set('dietaryRestrictions', dietaryRestrictions);
      });
    },
    [],
  );

  const handleOnDeleteImage = useCallback(() => {
    setItemForEdit((item) => item.set('imageUrl', null));
    setValidateImage({ isValid: true });
  }, []);

  const handleOnChangeImage = useCallback((imageFile: ImageFileMeta) => {
    let error: string = '';
    const VALID_IMAGE_FILE_TYPES = ['image/jpeg', 'image/png'];
    if (imageFile) {
      if (!VALID_IMAGE_FILE_TYPES.includes(imageFile.type)) {
        error = '指定可能な画像ファイルの形式はJPEGまたはPNGのみです';
      } else if (imageFile.size < 10 * 1024 || imageFile.size > 25 * 1000 * 1000) {
        error = '指定可能な画像ファイルのサイズは10KBから25MBまでです';
      } else if (
        imageFile.width < 400 ||
        imageFile.width > 10000 ||
        imageFile.height < 300 ||
        imageFile.height > 10000
      ) {
        error = '指定可能な画像ファイルのピクセル数は縦300~10000px、横400~10000pxまでです';
      }
    }
    if (error) {
      setValidateImage({ isValid: false, error });
    } else {
      setValidateImage({ isValid: true });
    }
  }, []);

  const handleOnDeleteItem = useCallback(() => {
    onDelete && onDelete();
    onClose();
  }, [onDelete, onClose]);

  useEffect(() => {
    // アップロードした画像URLがあるならそれを使う
    setItemForEdit((item) => item.set('imageUrl', uploadedImageUrl || item.imageUrl));
  }, [uploadedImageUrl]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title data-testid={'title'}>{isEdit ? 'メニューアイテムの編集' : 'メニューアイテムの追加'}</Title>
          <ContentWrapper>
            <Content>
              <FormContent name={'商品名'} required informationText={Help.menuItemName}>
                <StyledInput
                  value={itemForEdit.name}
                  onChange={handleOnChangeName}
                  error={showErrorMessage && itemForEdit.validateName().error}
                  placeholder={'（例）カレー、サラダ'}
                  data-testId={'nameInput'}
                />
                <StyledTextCount size={itemForEdit.name.length} maxSize={MAX_MENU_ITEM_NAME_LENGTH} />
              </FormContent>
              <FormContent name={'価格(円)'} informationText={Help.price}>
                <StyledInput
                  value={inputPrice}
                  onChange={handleOnChangePrice}
                  onBlur={handleOnBlurPrice}
                  error={showErrorMessage && itemForEdit.validatePrice().error}
                />
              </FormContent>
              <FormContent name={'写真'} informationText={Help.image}>
                <ImageUploader
                  imageUrl={itemForEdit.imageUrl}
                  onDelete={handleOnDeleteImage}
                  onChange={handleOnChangeImage}
                />
                {/* 画像のバリデーションエラーがある場合は表示する */}
                {validateImage.error && <DummyInput error={validateImage.error} />}
              </FormContent>
              <FormContent name={'説明文'} informationText={Help.description}>
                <StyledTextArea
                  value={itemForEdit.description || ''}
                  onChange={handleOnChangeDescription}
                  error={showErrorMessage && itemForEdit.validateDescription().error}
                />
                <StyledTextCount
                  size={itemForEdit.description?.length ?? 0}
                  maxSize={MAX_MENU_ITEM_DESCRIPTION_LENGTH}
                />
              </FormContent>
              <FormContent name={'食事制限対応'} informationText={Help.dietaryRestrictions}>
                <OptionalToggleWrapper>
                  {DietaryRestrictions.map((dietaryRestriction) => {
                    return (
                      <OptionalToggle
                        key={dietaryRestriction}
                        onClick={handleOnChangeDietaryRestriction(dietaryRestriction)}
                        label={dietaryRestrictionLabels[dietaryRestriction]}
                        checked={itemForEdit.dietaryRestrictions?.contains(dietaryRestriction) || undefined}
                      />
                    );
                  })}
                </OptionalToggleWrapper>
              </FormContent>
            </Content>
          </ContentWrapper>
          <ButtonWrapper>
            {isEdit && (
              <DeleteButton onClick={handleOnDeleteItem} data-testid={'deleteButton'}>
                削除
              </DeleteButton>
            )}
            <StyledButton onClick={onClose}>キャンセル</StyledButton>
            <StyledButton
              onClick={handleOnSave}
              priority={'high'}
              disabled={showErrorMessage && !isValid}
              data-testid={'submitButton'}
            >
              適用
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
});

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 0 16px 0 0;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Content = styled.div``;

const StyledButton = styled(Button)`
  &&& {
    max-width: 150px;
    width: calc(50% - 8px);
    padding: 14px 8px;
  }
`;

const DeleteButton = styled(StyledButton).attrs({ priority: 'high', negative: true })`
  &&& {
    margin-right: auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledInput = styled(Input)``;

const DummyInput = styled(Input)`
  &&& {
    input {
      display: none;
    }
  }
`;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const StyledTextArea = styled(TextArea)``;

const OptionalToggleWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
