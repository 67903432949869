import React, { useCallback } from 'react';

import { Set } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { Group } from 'types/Common';

type Props = {
  className?: string;
  isLoading: boolean;
  storeIds: Set<number>;
  onChangeStoreIds: (storeIds: Set<number>) => void;
  onChangeGroup: (group: Group) => void;
  commit: () => void;
  groupStoreSelectOptions: Omit<React.ComponentProps<typeof GroupStoreSelect>, 'onChange' | 'storeIds'>;
};

export const DashboardSearchCondition = React.memo<Props>(
  ({ className, isLoading, storeIds, onChangeStoreIds, onChangeGroup, commit, groupStoreSelectOptions }) => {
    const handleOnChangeSelectedStore: React.ComponentProps<typeof GroupStoreSelect>['onChange'] = useCallback(
      (group, storeIds) => {
        onChangeGroup(group);
        onChangeStoreIds(storeIds);
      },
      [onChangeStoreIds, onChangeGroup],
    );

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <GroupStoreSelect storeIds={storeIds} onChange={handleOnChangeSelectedStore} {...groupStoreSelectOptions} />
          <StyledButton priority={'high'} onClick={commit} disabled={isLoading}>
            変更
          </StyledButton>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 87px;
    height: 32px;
    padding: 0;
    box-shadow: none;
    margin-top: 36px;
  }
`;
