import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { CompetitorApi } from 'ApiClient/CompetitorApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { Competitor, Competitors } from 'models/Domain/Competitor/Competitor';
import { CompetitorSearchCondition as SearchCondition } from 'models/Domain/Competitor/CompetitorSearchCondition';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { CompetitorActions as Actions } from './actions';

export default function* saga() {
  yield takeLatest(Actions.initializePage, initializePage);
  yield takeLatest(Actions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(Actions.deleteCompetitor, deleteCompetitor);
  yield takeLatest(Actions.putCompetitor, putCompetitor);
  yield takeLatest(Actions.initializeDetailPage, initializeDetailPage);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initializePage() {
  yield call(waitForUserAndStoresInitialized);

  const isPreparedPage: boolean = yield select((state: State) => state.competitor.isPreparedPage);
  // 初期化済みの場合は以下の処理を行わない
  if (isPreparedPage) {
    return;
  }

  yield put(Actions.setIsPreparedPage(true));

  // URLパラメータから検索条件を復元する
  yield call(updateCompetitors);

  yield put(Actions.setIsInitializedSearchCondition(true));

  yield put(Actions.setIsLoading(false));
}

function* commitSearchCondition(action: ReturnType<typeof Actions.setSearchCondition>) {
  const searchCondition = action.payload;
  const location: Location = yield select((state: RouterRootState) => state.router.location);
  // 既存とURLに差分がある場合、URLの更新を行う
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(pushWithOrganizationId(path));
  }
  yield put(Actions.setSearchCondition(searchCondition));
}

/**
 * URLパラメータから検索条件を復元する
 */
function* updateCompetitors() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  // URLの置き換え
  let searchCondition = SearchCondition.fromURLSearchParams(location.search);
  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: searchCondition.filter.showClosedStores,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  yield put(Actions.setSearchCondition(searchCondition));

  yield put(Actions.setIsLoading(true));

  yield call(fetchCompetitors);
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // Pathが検索順位監視画面以外の場合は何もしない
  if (location.pathname !== Path.competitors.index) {
    return;
  }

  // 検索順位監視画面の初期化処理が完了していない場合は何もしない
  const isPreparedPage: boolean = yield select((state: State) => state.competitor.isPreparedPage);
  if (!isPreparedPage) {
    return;
  }

  yield call(updateCompetitors);
}

function* fetchCompetitors() {
  // 競合店舗データを取得する
  const searchCondition: SearchCondition = yield select((state: State) => state.competitor.searchCondition);
  const params = searchCondition.toRequestParams();
  const response: YieldReturn<typeof CompetitorApi.getCompetitors> = yield CompetitorApi.getCompetitors(params);
  if (response.isSuccess) {
    const competitors = Competitors.fromJSON(response.data);
    yield put(Actions.setCompetitors(competitors));
  } else {
    toast({
      type: 'error',
      title: '競合店舗の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(Actions.setIsLoading(false));
}

function* deleteCompetitor(action: ReturnType<typeof Actions.deleteCompetitor>) {
  // 競合店舗データを削除する
  const response: YieldReturn<typeof CompetitorApi.deleteCompetitor> = yield CompetitorApi.deleteCompetitor(
    action.payload,
  );
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: '競合店舗を削除しました',
    });
    yield call(updateCompetitors);
  } else {
    toast({
      type: 'error',
      title: '競合店舗の削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(Actions.setIsLoading(false));
}

function* putCompetitor(action: ReturnType<typeof Actions.putCompetitor>) {
  // 競合店舗データを更新する
  const { id, competitor } = action.payload;
  const params = {
    id: competitor.id,
    organization_id: competitor.organizationId,
    store_id: competitor.storeId,
    place_id: competitor.placeId,
    name: competitor.name,
    past_place_ids: competitor.pastPlaceIds.toArray(),
    tags: competitor.tags.toArray(),
  };
  yield put(Actions.setIsLoading(true));
  const response: YieldReturn<typeof CompetitorApi.putCompetitor> = yield CompetitorApi.putCompetitor(id, params);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: '競合店舗情報を更新しました',
    });
    // 更新が完了したら、一覧を取得し直す
    yield call(updateCompetitors);
  } else {
    toast({
      type: 'error',
      title: 'タグの編集に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(Actions.setIsLoading(false));
  }
}

function* initializeDetailPage(action: ReturnType<typeof Actions.initializeDetailPage>) {
  yield put(Actions.setIsPreparedDetailPage(false));

  const id = action.payload;
  const response: YieldReturn<typeof CompetitorApi.getCompetitor> = yield CompetitorApi.getCompetitor(id);
  if (response.isSuccess) {
    const competitor = Competitor.fromJSON(response.data);
    yield put(Actions.setCompetitor(competitor));
  } else {
    toast({
      type: 'error',
      title: '競合店舗情報の取得に失敗しました',
      description: String(response.error.message),
    });
  }

  yield put(Actions.setIsPreparedDetailPage(true));
}
