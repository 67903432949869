import React from 'react';

import { I18n } from 'aws-amplify';
import { AmplifyTheme, ConfirmSignIn, ConfirmSignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';

import { CustomForgotPassword } from './ForgotPassword';
import CustomLoading from './Loading';
import { CustomRequireNewPassword } from './RequireNewPassword';
import CustomSignIn from './SignIn';

const authScreenLabels = {
  ja: {
    'Sign In': 'ログイン',
    'Sign Up': 'アカウント登録',
    'Sign Out': 'ログアウト',
    'Sign in to your account': 'ログイン',
    Username: 'メールアドレス',
    Password: 'パスワード',
    'Enter your username': 'メールアドレスを入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Password cannot be empty': 'パスワードを入力してください',
    'No account? ': 'アカウントをお持ちでない場合はこちら',
    'Forget your password? ': 'パスワードが不明な場合はこちら',
    'Reset password': 'パスワードのリセット',
    'User does not exist': 'メールアドレスまたはパスワードが間違っています',
    'User already exists': '登録済みのユーザーです',
    'Incorrect username or password': 'メールアドレスまたはパスワードが間違っています',
    'Invalid password format': 'パスワードが間違っています',
    'Create account': 'アカウントの作成',
    'Forgot Password': 'パスワードが不明な場合',
    'Change Password': 'パスワードの変更',
    'Reset your password': 'パスワードのリセット',
    'New Password': '新しいパスワード',
    Email: 'メールアドレス',
    'Phone Number': '電話番号',
    'Confirm a Code': '認証コードの確認',
    'Confirm Sign In': 'ログインの確認',
    'Confirm Sign Up': 'アカウント登録の確認',
    'Back to Sign In': 'ログインに戻る',
    'Send Code': 'コードを送信',
    Confirm: '確認',
    'Resend a Code': '認証コードを再送信',
    Submit: '送信',
    Skip: 'スキップ',
    Verify: '認証',
    'Verify Contact': '連絡先の認証',
    Code: '認証コード',
    'Invalid phone number format': '電話番号が間違っています',
    Change: '変更',
    'Password does not conform to policy: Password not long enough': 'パスワードは8文字以上必要です',
    'Password does not conform to policy: Password must have numeric characters': 'パスワードには半角数字が必要です',
    'Password does not conform to policy: Password must have lowercase characters':
      'パスワードには半角英字の大文字・小文字の両方が必要です',
    'Password does not conform to policy: Password must have uppercase characters':
      'パスワードには半角英字の大文字・小文字の両方が必要です',
    'Account recovery requires verified contact information':
      '認証を行うとパスワードを忘れた場合にリセットすることができます',
    'Cannot reset password for the user as there is no registered/verified email or phone_number':
      '認証済みのメールアドレスではないためパスワードのリセットができません',
    'Neither Email nor Phone Number selected': '選択してください',
    'Resend Code': 'コードを再送信',
    'Temporary password has expired and must be reset by an administrator.':
      '仮パスワードの有効期限が切れています。サポートから問い合わせてください。',
    'Invalid verification code provided, please try again.': '検証コードが間違っています',
    'Attempt limit exceeded, please try after some time.':
      '短時間に実行できる上限数に達したため、時間をおいてから実行してください',
    'User password cannot be reset in the current state':
      '現在の登録状況ではパスワードをリセットできません。サポートから問い合わせてください。',
    'Username/client id combination not found.': 'メールアドレスが存在しません。メールアドレスをご確認ください。',
  },
};

I18n.setLanguage('ja');
I18n.putVocabularies(authScreenLabels);

export const withCustomAuthenticator = (Component: any) => {
  const includeGreetings = false;
  const authenticatorComponents = [
    <CustomSignIn key='signin' />,
    <ConfirmSignIn key='confirm-signin' />,
    <CustomRequireNewPassword key='require-new-password' />,
    <ConfirmSignUp key='confirm-signup' />,
    <VerifyContact key='verify-contact' />,
    <CustomForgotPassword key='forgot-password' />,
    <CustomLoading key='loading' />,
  ];
  const federated = null;
  const theme = {
    ...AmplifyTheme,
    formContainer: {
      ...AmplifyTheme.formContainer,
      margin: '20px auto 50px',
    },
    formSection: {
      ...AmplifyTheme.formSection,
      maxWidth: '460px',
      width: '100%',
      boxShadow: '1px 1px 4px 0 rgba(0,0,0,0.15)',
      boxSizing: 'border-box',
    },
    input: {
      ...AmplifyTheme.input,
      fontSize: '16px',
      height: 'auto',
    },
    toast: {
      color: 'red',
      padding: '10px 0',
      marginBottom: '20px',
    },
  };
  return withAuthenticator(Component, includeGreetings, authenticatorComponents, federated, theme);
};
