import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { MapSearchRankConfigData } from 'models/Domain/MapSearchRank/MapSearchRankConfigData';
import { MapSearchRankConfigSearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankConfigSearchCondition';

import { MapSearchRankConfigActions as Actions } from './actions';

export class MapSearchRankConfigState extends Record<{
  isLoading: boolean;
  isPreparedPage: boolean;
  isInitializedSearchCondition: boolean;
  searchCondition: MapSearchRankConfigSearchCondition;
  data: MapSearchRankConfigData;
  isProcessingCsvDownload: boolean;
  csvDownload: ExecutionArnStatus;
}>({
  isPreparedPage: false,
  isInitializedSearchCondition: false,
  isLoading: false,
  searchCondition: new MapSearchRankConfigSearchCondition(),
  data: new MapSearchRankConfigData(),
  isProcessingCsvDownload: false,
  csvDownload: new ExecutionArnStatus(),
}) {}

export const mapSearchRankConfigReducer = reducerWithInitialState(new MapSearchRankConfigState())
  .case(Actions.setIsPreparedPage, (state, payload) => {
    return state.set('isPreparedPage', payload);
  })
  .case(Actions.setIsInitializedSearchCondition, (state, payload) => {
    return state.set('isInitializedSearchCondition', payload);
  })
  .case(Actions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  })
  .case(Actions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(Actions.setData, (state, payload) => {
    return state.set('data', payload);
  })
  .case(Actions.setIsProcessingCsvDownload, (state, payload) => {
    return state.set('isProcessingCsvDownload', payload);
  })
  .case(Actions.setCsvDownload, (state, payload) => {
    return state.set('csvDownload', payload);
  })
  .case(Actions.clearCsvDownload, (state, payload) => {
    return state.set('csvDownload', new ExecutionArnStatus());
  });
