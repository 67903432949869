import actionCreatorFactory from 'typescript-fsa';

import { Competitor, Competitors } from 'models/Domain/Competitor/Competitor';
import { CompetitorSearchCondition as SearchCondition } from 'models/Domain/Competitor/CompetitorSearchCondition';

const actionCreator = actionCreatorFactory('Competitor');

export const CompetitorActions = {
  // 画面ページ
  initializePage: actionCreator('initializePage'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
  commitSearchCondition: actionCreator<SearchCondition>('commitSearchCondition'),
  setSearchCondition: actionCreator<SearchCondition>('setSearchCondition'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),
  setCompetitors: actionCreator<Competitors>('setCompetitors'),
  deleteCompetitor: actionCreator<number>('deleteCompetitor'),
  putCompetitor: actionCreator<{ id: number; competitor: Competitor }>('putCompetitor'),
  // 詳細ページ
  initializeDetailPage: actionCreator<number>('initializeDetailPage'),
  setIsPreparedDetailPage: actionCreator<boolean>('setIsPreparedDetailPage'),
  setCompetitor: actionCreator<Competitor>('setCompetitor'),
};
