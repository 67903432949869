import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { CustomRadio, SearchPullDown } from 'components/molecules/SearchPullDown';
import { SortType } from 'models/Domain/Store';

export const SortTypeOptions: { text: string; value: SortType }[] = [
  {
    text: '店舗コード順',
    value: 'code_asc',
  },
  {
    text: '店舗コード逆順',
    value: 'code_desc',
  },
  {
    text: '登録日が古い順',
    value: 'id_asc',
  },
  {
    text: '登録日が新しい順',
    value: 'id_desc',
  },
];

/**
 * 店舗の並び順を指定する
 */
export const SortStoreFilter = React.memo<{
  selectedSortId: SortType;
  changeSort: (v: SortType) => void;
}>(({ selectedSortId, changeSort }) => {
  const selectedOption = SortTypeOptions.find((option) => option.value === selectedSortId) || SortTypeOptions[0];
  const [inputSortId, setInputSortId] = useState(selectedOption.value);

  const handleOnCancel = useCallback(() => {
    setInputSortId(selectedSortId);
  }, [selectedSortId]);

  const handleOnApply = useCallback(() => {
    changeSort(inputSortId);
  }, [changeSort, inputSortId]);

  useEffect(() => {
    setInputSortId(selectedSortId);
  }, [selectedSortId]);
  const icon = <CustomIcon type='compare_arrows' />;

  return (
    <SearchPullDown icon={icon} value={'並び替え'} onCancel={handleOnCancel} onApply={handleOnApply}>
      {SortTypeOptions.map((option) => {
        return (
          <CustomRadio
            key={option.value}
            label={option.text}
            checked={option.value === inputSortId}
            onChange={() => setInputSortId(option.value)}
          />
        );
      })}
    </SearchPullDown>
  );
});

const CustomIcon = styled(Icon)`
  width: 34px;
  height: 36px;
  /* デフォルトのアイコンとは画像サイズが異なるので、表示位置を調整する */
  margin-right: 5px;
  margin-left: -8px;
`;

export default React.memo(SortStoreFilter);
