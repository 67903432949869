import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Icon, TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { TextArea } from 'components/atoms/TextArea';
import { ImageCommentOrderIcon } from 'components/molecules/ImageCommentOrderIcon';
import { OfferImageComment } from 'models/Domain/OfferImageComment';
import { OfferActions } from 'modules/offer/actions';
import { COLOR } from 'style/color';

interface Props {
  className?: string;
  open: boolean;
  imageUrl: string;
  onClose: () => void;
}

export const ImageCommentModal: React.FC<Props> = ({ className, open, imageUrl, onClose }) => {
  const dispatch = useDispatch();
  const [offerImageComment, setOfferImageComment] = useState(OfferImageComment.withOneImageComment());
  const { image_comments } = offerImageComment;

  useEffect(() => {
    if (!open) return;
    setOfferImageComment(OfferImageComment.withOneImageComment().setImageUrl(imageUrl));
  }, [open, imageUrl]);

  const onClick = (e: any) => {
    const contentWidth = e.target.getBoundingClientRect().right - e.target.getBoundingClientRect().left;
    const contentHeight = e.target.getBoundingClientRect().bottom - e.target.getBoundingClientRect().top;
    const x = e.nativeEvent.offsetX / contentWidth;
    const y = e.nativeEvent.offsetY / contentHeight;
    const index = offerImageComment.image_comments.size - 1;
    setOfferImageComment(offerImageComment.setImageCommentPoint(index, x, y));
  };

  const onChange = (index: number, text: string) => {
    setOfferImageComment(offerImageComment.setImageCommentText(index, text));
  };

  const addComment = () => {
    setOfferImageComment(offerImageComment.addImageComment());
  };

  const deleteComment = (index: number) => {
    setOfferImageComment(offerImageComment.deleteImageComment(index));
  };

  return (
    <TransitionablePortal
      open={open}
      transition={{
        animation: 'fly up',
        duration: 500,
      }}
    >
      <PortalWrapper>
        <PortalHeader>
          <PortalTitle>
            <HeaderIcon name='comment outline' />
            <Title>画像にコメント</Title>
          </PortalTitle>
          <CloseWrapper onClick={() => onClose()}>
            <HeaderIcon name='x' size='large' />
            <CloseText>閉じる</CloseText>
          </CloseWrapper>
        </PortalHeader>
        <PortalContent>
          <ImgArea>
            <Img onClick={onClick} src={offerImageComment.image_url} alt='' />
            {image_comments.map((image_comment, idx) => {
              if (!image_comment.hasPoint) {
                return null;
              }
              return (
                <NumberIcon
                  key={idx}
                  x={image_comment.xAxisPercent}
                  y={image_comment.yAxisPercent}
                  order_num={idx + 1}
                />
              );
            })}
          </ImgArea>
          <BottomContentWrapper>
            {image_comments.map((image_comment, idx) => (
              <CommentArea key={idx}>
                <CommentAreaIcon order_num={idx + 1} />
                <CommentTextArea
                  disabled={!image_comment.hasPoint}
                  value={image_comment.text}
                  onChange={(text) => onChange(idx, text)}
                  placeholder={
                    image_comment.hasPoint ? 'コメントを入力してください' : 'コメントする場所を選択してください'
                  }
                />
                {image_comments.size > 1 && (
                  <DeleteButton size='large' name='close' color='grey' onClick={() => deleteComment(idx)} />
                )}
              </CommentArea>
            ))}
            <ButtonsWrapper>
              <AddButtonWraper>
                {offerImageComment.hasPoint && (
                  <AddButton priority='low' onClick={addComment}>
                    + コメントを追加
                  </AddButton>
                )}
              </AddButtonWraper>
              <CommentButton
                priority='high'
                disabled={!offerImageComment.isValid}
                onClick={() => {
                  dispatch(OfferActions.sendOfferImageComment(offerImageComment));
                  onClose();
                }}
              >
                送信
              </CommentButton>
            </ButtonsWrapper>
          </BottomContentWrapper>
        </PortalContent>
      </PortalWrapper>
    </TransitionablePortal>
  );
};

const PortalWrapper = styled.div`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 200;
    background-color: ${COLOR.WHITE};
    width: 100%;
    margin: auto;
    height: 100vh;
    overflow-y: scroll;
  }
`;

const PortalContent = styled.div`
  width: 60%;
  margin: auto;
  padding-bottom: 60px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const NumberIcon = styled(ImageCommentOrderIcon)<{ x: number; y: number }>`
  position: absolute;
  left: calc(${(props) => props.x}% - 12px);
  top: calc(${(props) => props.y}% - 12px);
  z-index: 99;
`;

const ImgArea = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const Img = styled.img`
  width: 100%;
`;

// StickyHeaderの全デバイスのtop指定を0にしている
const PortalHeader = styled(StickyHeader)`
  top: 0;
  @media (max-width: 600px) {
    top: 0;
  }
`;

const PortalTitle = styled.h1`
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
`;

const Title = styled.div`
  margin-left: 8px;
`;

const HeaderIcon = styled(Icon)`
  &&& {
    height: auto;
    &:before {
      font-size: 22px;
    }
  }
`;

const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CloseText = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const BottomContentWrapper = styled.div`
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;

const CommentArea = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
`;

const CommentAreaIcon = styled(ImageCommentOrderIcon)`
  margin-top: 15px;
  flex: none;
`;

const CommentTextArea = styled(TextArea)`
  &&& {
    margin-left: 6px;
    width: 100%;
  }
`;

const DeleteButton = styled(Icon)`
  &&& {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0;
    cursor: pointer;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
`;

const CommentButton = styled(Button)`
  &&& {
    width: fit-content;
  }
`;

const AddButtonWraper = styled.div``;

const AddButton = styled(Button)`
  &&& {
    width: fit-content;
  }
`;
