import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { GbpImageCategory, gbpImageCategoryLabel } from 'models/Domain/Image/Image';
import { Store, Stores } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { JSObject } from 'types/Common';

export type PublishGmbStatusKey = 'PUBLISHED' | 'UNPUBLISHED' | 'INPROGRESS' | 'FAILED';

export const PublishGmbStatus = {
  PUBLISHED: '掲載済み',
  UNPUBLISHED: '未掲載',
  INPROGRESS: '処理中',
  FAILED: '掲載失敗',
};

export class ImageInfo extends Record<{
  album_id: number;
  album_image_id: number;
  created_by: number;
  gmb_category: GbpImageCategory | null;
  gmb_create_at: Dayjs | null;
  publish_gmb_status: PublishGmbStatusKey;
  store_id: number;
}>({
  album_id: 0,
  album_image_id: 0,
  created_by: 0,
  gmb_category: null,
  gmb_create_at: dayjs(),
  publish_gmb_status: 'UNPUBLISHED',
  store_id: 0,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.gmb_create_at = params.gmb_create_at ? dayjs(params.gmb_create_at) : null;
    super(params);
  }

  get isPublishedGmb() {
    return this.publish_gmb_status === 'PUBLISHED';
  }
}

export default class ImageDetail extends Record<{
  create_at: Dayjs;
  image_resource_id: number;
  image_info: List<ImageInfo>;
  tags: List<string>;
  url: string;
}>({
  create_at: dayjs(),
  image_resource_id: 0,
  image_info: List(),
  tags: List(),
  url: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data, ...data.image_detail };
    if (data?.image_detail?.image_info) {
      params.image_info = List(data.image_detail.image_info.map((d: JSObject) => new ImageInfo(d)));
    }
    params.tags = List(data.tags);
    params.create_at = dayjs.unix(params.create_at);
    super(params);
  }

  /**
   * 写真に紐づいている店舗数
   */
  get numberOfStores() {
    return this.image_info.size;
  }

  /**
   * image_info に値が入っているか
   */
  get hasImageInfo() {
    return this.numberOfStores > 0;
  }

  hasStatusedGmbImage(currentUser: User, publish_gmb_status: PublishGmbStatusKey) {
    // Admin or Vmdの場合、ユーザーに紐づく店舗での絞り込みを実施しない
    if (currentUser.isAdminUser || currentUser.isVmdUser) {
      return !this.image_info.filter((imageInfo) => imageInfo.publish_gmb_status === publish_gmb_status).isEmpty();
    }

    // SVユーザーの場合は管理店舗で、Memberユーザーの場合は所属店舗で絞り込みを実施する
    const stores = currentUser.isSvUser
      ? currentUser.managing_stores
      : currentUser.isMemberUser
        ? currentUser.stores
        : List<number>();

    return !this.image_info
      .filter((imageInfo) => stores.contains(imageInfo.store_id))
      .filter((imageInfo) => imageInfo.publish_gmb_status === publish_gmb_status)
      .isEmpty();
  }

  /**
   * GBP連携されている画像を持っているかどうか
   */
  hasPublishedGmbImage(currentUser: User) {
    return this.hasStatusedGmbImage(currentUser, 'PUBLISHED');
  }

  /**
   * GBP連携/解除中の画像を持っているかどうか
   */
  hasInprogressGmbImage(currentUser: User) {
    return this.hasStatusedGmbImage(currentUser, 'INPROGRESS');
  }

  /**
   * 写真が紐づいている店舗に、GBPロケーションに連携している店舗があるかどうか
   */
  hasConnectedGmbStore(stores: Stores) {
    return this.image_info.some((imageInfo) => {
      const targetStore = stores.findStore(imageInfo.store_id);
      return targetStore ? targetStore.is_connected_gmb : false;
    });
  }

  /**
   * 写真がGoogleビジネスプロフィールに連携できるかどうか
   */
  canPublishToGmb(currentUser: User, stores: Stores) {
    return !this.hasPublishedGmbImage(currentUser) && this.hasConnectedGmbStore(stores);
  }

  /**
   * 画像の操作に関する権限を有しているか
   */
  hasChangeAuthorityMultiStoresImage(currentUser: User) {
    // Admin, Vmdは複数店舗に紐づいた画像でも操作できる
    // SV, Memberは単店舗に紐づいた画像のみ操作できる
    return (
      currentUser.isAdminUser ||
      currentUser.isVmdUser ||
      ((currentUser.isSvUser || currentUser.isMemberUser) && this.numberOfStores == 1)
    );
  }

  get formatCreateAt() {
    return this.create_at.format('YYYY年MM月DD日');
  }

  get formatGmbCreateAt() {
    const target = this.image_info.filter((image) => image.gmb_create_at).get(0);
    if (!target) return '';
    return target.gmb_create_at && target.gmb_create_at.format('YYYY年MM月DD日');
  }

  get gmbCategory(): GbpImageCategory | null {
    const targetImageInfo = this.image_info.filter((image) => image.gmb_category).get(0);
    if (!targetImageInfo) return null;
    return targetImageInfo.gmb_category;
  }

  get categoryLabel() {
    const category = this.gmbCategory;
    if (!category) return '';
    // 未知のカテゴリの場合はそのまま表示する
    return gbpImageCategoryLabel[category] || category;
  }

  /**
   * Googleビジネスプロフィール掲載ステータスの文言を返す
   */
  getGmbPublishStatusLabel(publish_gmb_status: PublishGmbStatusKey, store: Store) {
    if (!store.is_connected_gmb) return 'ー';
    return PublishGmbStatus[publish_gmb_status];
  }
}
