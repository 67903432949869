import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyFooter } from 'components/atoms/StickyFooter';
import { SearchKeywordActions } from 'modules/SearchKeyword/action';
import { SIZE } from 'style/size';

type ComponentProps = {
  className?: string;
  setIsOpenExcludedKeywordsStickyFooter: (isUpdating: boolean) => void;
};

/**
 * テーブルから店舗を選択したときに表示されるフッター
 */
export const ExcludedKeywordFooter = React.memo<ComponentProps>(
  ({ className, setIsOpenExcludedKeywordsStickyFooter }) => {
    const dispatch = useDispatch();
    const { setIsUpdatedExcludedKeywords } = useMemo(
      () => bindActionCreators(SearchKeywordActions, dispatch),
      [dispatch],
    );

    const handleOnClickUpdatePageByExcludedKeywords = useCallback(() => {
      setIsUpdatedExcludedKeywords(true);
      setIsOpenExcludedKeywordsStickyFooter(false);
    }, [setIsUpdatedExcludedKeywords, setIsOpenExcludedKeywordsStickyFooter]);

    return (
      <StyledStickyFooter className={className}>
        <ChildWrapper>
          <CancelLabel>常に適用する除外キーワードが更新されました</CancelLabel>
        </ChildWrapper>
        <ButtonWrapper>
          <CustomButton priority='high' onClick={handleOnClickUpdatePageByExcludedKeywords}>
            画面を更新する
          </CustomButton>
        </ButtonWrapper>
      </StyledStickyFooter>
    );
  },
);

const StyledStickyFooter = styled(StickyFooter)`
  height: 80px;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 320px; /* メニューの分は空ける */
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.2));

  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    /* 店舗を選択して実行するアクションがSP表示を想定していないのでPCでのみ表示する */
    display: none;
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0;
`;

const CustomButton = styled(Button)`
  &&& {
    font-weight: bold;
    cursor: pointer;
    width: auto;
    font-size: 16px;
    margin-right: 11px;
    border-radius: 8px;
    padding: 12px 16px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &:before {
    white-space: pre;
  }
`;

const CancelLabel = styled.div`
  font-size: 16px;
  cursor: pointer;
`;
