import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { Paging } from 'components/molecules/Paging';
import { SwitchContent } from 'components/molecules/SwitchContent';
import { AlbumListIndexHeader } from 'components/organisms/AlbumListIndexHeader';
import { BulkGmbPublishModal } from 'components/organisms/BulkGmbPublishModal';
import { SelectImageListFooter } from 'components/organisms/SelectImageListFooter';
import { ImageFilter } from 'components/pageComponents/AlbumListIndex/ImageFilter';
import { ImageList } from 'components/pageComponents/AlbumListIndex/ImageList';
import { ImageListInfo } from 'components/pageComponents/AlbumListIndex/ImageListInfo';
import { ImageSearchConditionSummary } from 'components/pageComponents/AlbumListIndex/ImageSearchConditionSummary';
import { MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import CheckedImageList from 'models/Domain/CheckedImageList';
import Image from 'models/Domain/Image/Image';
import ImageSearchCondition from 'models/Domain/Image/ImageSearchCondition';
import { ImageActions } from 'modules/image/actions';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';

export const AlbumListIndex = React.memo(() => {
  const [isBulkGmbPublishModalOpen, setBulkGmbPublishModalOpen] = useState<boolean>(false);

  const {
    searchCondition,
    committedSearchCondition,
    hashtagList,
    imageList,
    pagination,
    isLoadingImageList,
    checkedImageList,
  } = useSelector((state: State) => state.image);
  const { stores } = useSelector((state: State) => state.store);
  const { currentUser } = useSelector((state: State) => state.app);

  const canUseProductCategory = currentUser.organization?.params.can_use_gbp_media_product_category ?? false;

  const dispatch = useDispatch();

  const {
    initializeAlbumListIndexPage,
    setSearchCondition,
    setCheckedImageList,
    uploadNewImageFile,
    uploadGmb,
    disconnectImages,
    fetchImages,
  } = useMemo(() => bindActionCreators(ImageActions, dispatch), [dispatch]);
  useEffect(() => {
    initializeAlbumListIndexPage();
  }, [initializeAlbumListIndexPage]);

  /**
   * 「写真を追加」した際に写真のアップロードを行う
   */
  const onChangeImages = useCallback(
    (files: File[], metadata: ImageFileMeta[]) => {
      uploadNewImageFile({ files, metadata });
    },
    [uploadNewImageFile],
  );

  /**
   * 検索を実行
   */
  const search = useCallback(
    (searchCondition: ImageSearchCondition) => {
      if (!checkedImageList.isEmpty()) {
        if (!window.confirm('写真の選択状態が解除されますがよろしいですか？')) {
          return;
        }
      }

      // URLを変更するため
      setSearchCondition({ searchCondition, updateLocation: true });

      // 選択していた画像のチェックはクリアする
      setCheckedImageList(new CheckedImageList());
    },
    [checkedImageList, setCheckedImageList, setSearchCondition],
  );

  /**
   * 「検索」ボタンクリック時のハンドラ
   */
  const commitFilter = useCallback(() => {
    search(searchCondition);
  }, [search, searchCondition]);

  /**
   * 絞り込み条件を変更する。データの取得/URL変更は行わない
   */
  const changeFilter = useCallback(
    (searchCondition: ImageSearchCondition) => {
      // 絞り込み条件変更時はページを1に戻す
      const updatedSearchCondition = searchCondition.setPage(1);
      // 検索条件のセットのみ実施する（依頼報告の取得はcommitFilterで実施）
      setSearchCondition({ searchCondition: updatedSearchCondition, updateLocation: false });
    },
    [setSearchCondition],
  );

  const changePage = useCallback(
    (page: number) => {
      // ページ条件の変更は確定済み検索条件から変更する
      const updatedSearchCondition = committedSearchCondition.setPage(page);

      search(updatedSearchCondition);
    },
    [committedSearchCondition, search],
  );

  useEffect(() => {
    // 一覧の内容が変更された場合、トップに遷移する
    window.scrollTo(0, 0);

    // チェックした画像を表示されているもののみに変更
    const checkedImageUrls = Set(checkedImageList.list.map((image) => image.url));
    const checkedImages = imageList.list.filter((image) => checkedImageUrls.has(image.url));
    setCheckedImageList(CheckedImageList.fromImages(checkedImages));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageList]);

  // 表示中のすべての画像がチェックされているか
  const isAllImageChecked = useMemo(() => {
    return Set(imageList.list.map((image) => image.url)).equals(Set(checkedImageList.list.map((image) => image.url)));
  }, [imageList, checkedImageList]);

  const clearCheckedImageList = useCallback(() => {
    setCheckedImageList(new CheckedImageList());
  }, [setCheckedImageList]);

  /**
   * すべての写真を選択する、すべての写真の選択を解除するトグル処理
   */
  const toggleAllImagesChecked = useCallback(() => {
    if (isAllImageChecked) {
      clearCheckedImageList();
    } else {
      setCheckedImageList(
        CheckedImageList.fromImages(
          imageList.list.filter((image) => image.stores.some((store) => store.is_connected_gmb)),
        ),
      );
    }
  }, [clearCheckedImageList, imageList.list, isAllImageChecked, setCheckedImageList]);

  /**
   * 単一の写真の選択、選択解除のトグル処理
   */
  const toggleImageCheked = useCallback(
    (image: Image) => {
      if (checkedImageList.includes(image.image_resource_id)) {
        setCheckedImageList(checkedImageList.uncheck(image.image_resource_id));
      } else {
        setCheckedImageList(checkedImageList.check(image));
      }
    },
    [checkedImageList, setCheckedImageList],
  );

  /**
   * 選択している写真をGBPから削除する
   */
  const onClickDisconnectImages = useCallback(() => {
    if (!window.confirm('選択した写真をGoogleビジネスプロフィールから削除しますか？')) {
      return;
    }
    const imageIds = checkedImageList.list.map((image) => image.image_resource_id).toArray();
    disconnectImages(imageIds);
    fetchImages();
    // 選択していた画像のチェックはクリアする
    setCheckedImageList(new CheckedImageList());
  }, [checkedImageList, disconnectImages, fetchImages, setCheckedImageList]);

  // 写真をチェックできるのは管理者か本社スタッフのみ
  const canCheckImage = currentUser.isAdminUser || currentUser.isVmdUser;

  if (!hashtagList && !imageList) {
    return null;
  }

  return (
    <>
      <Helmet title={getPageTitle('写真')} />
      <CustomMainWrapper>
        <AlbumListIndexHeader onChangeImages={onChangeImages} />
        <FilterWrapper>
          <SwitchContent
            openLabel='絞り込み条件を閉じる'
            closedLabel='絞り込み条件を変更する'
            closedContents={<ImageSearchConditionSummary filter={searchCondition} stores={stores} />}
          >
            <ImageFilter
              filter={searchCondition}
              hashtagList={hashtagList}
              onChangeFilter={changeFilter}
              onCommitFilter={commitFilter}
              disableCommitButton={isLoadingImageList}
            />
          </SwitchContent>
        </FilterWrapper>

        <Contents>
          {pagination.total_count > 0 && (
            <InfoWrapper>
              <ImageListInfo
                pagination={pagination}
                isAllImageSelected={isAllImageChecked}
                showToggleCheckButton={canCheckImage}
                onClickToggleAllImagesChecked={toggleAllImagesChecked}
              />
            </InfoWrapper>
          )}
          {!imageList.isEmpty && (
            <ListWrapper>
              <ImageList
                imageResourceIdList={checkedImageList}
                imageList={imageList}
                showCheckbox={canCheckImage}
                onChangeCheck={toggleImageCheked}
              />
            </ListWrapper>
          )}
          {!isLoadingImageList && imageList.isEmpty && (
            <ListWrapper>
              <ExplanationWrapper>
                <ExplanationTitle>指定された条件の写真はありません</ExplanationTitle>
              </ExplanationWrapper>
            </ListWrapper>
          )}

          {pagination.total_count > 0 && (
            <PagingWrapper>
              <Paging
                currentPage={pagination.current_page}
                viewContentSize={pagination.limit}
                totalContentSize={pagination.total_count}
                viewMaxPage={5}
                fixedPage={1}
                onChangeNo={changePage}
              />
            </PagingWrapper>
          )}
          <BulkGmbPublishModal
            isOpen={isBulkGmbPublishModalOpen}
            onClose={() => setBulkGmbPublishModalOpen(false)}
            checkedImageList={checkedImageList}
            changeCheckedImageList={(checkedImageList) => setCheckedImageList(checkedImageList)}
            onClickStartBulkUpload={() => dispatch(uploadGmb(checkedImageList.requestParams))}
            canUseProductCategory={canUseProductCategory}
          />
          {isLoadingImageList && (
            <>
              <LoadingWrapperBase />
              <LoadingWrapper>
                <Loader active={true} size='big' inline={true} />
              </LoadingWrapper>
            </>
          )}
        </Contents>
      </CustomMainWrapper>
      {!checkedImageList.isEmpty() && (
        <SelectImageListFooter
          selectedSize={checkedImageList.numberOfCheckedImages()}
          connectedSize={checkedImageList.numberOfPublishedImages()}
          onCancel={clearCheckedImageList}
          onUpload={() => setBulkGmbPublishModalOpen(true)}
          onDelete={onClickDisconnectImages}
        />
      )}
    </>
  );
});

const CustomMainWrapper = styled(MainWrapper)`
  &&& {
    position: relative;
    min-height: 100%;
  }
`;

const Contents = styled.div`
  position: relative;
  margin: 0 18px;
`;

const Container = styled.div`
  width: 100%;
  padding: 0;
  margin-top: 18px;
`;
const FilterWrapper = styled(Container)`
  padding: 0 18px;
`;
const ListWrapper = styled(Container)``;
const InfoWrapper = styled(Container)``;
const PagingWrapper = styled(Container)``;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: #f8f8f8;
  mix-blend-mode: hard-light;
`;

const ExplanationWrapper = styled.div`
  background-color: ${COLOR.LIGHT_GRAY};
  padding: 48px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ExplanationTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
