import React, { useCallback, useMemo, useState } from 'react';

import { List } from 'immutable';
import orderBy from 'lodash/orderBy';
import { Checkbox, Dropdown, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import {
  ActionItemLabel,
  ActionMenuItem,
  AreaKeywordLabel,
  ColorCell,
  ColorHeaderCell,
  MenuCell,
  MenuHeaderCell,
  Notification,
  Rank,
  SettingsIcon,
  ShopIcon,
  StoreName,
} from 'components/pageComponents/MapCompetitorResearch/Common';
import { CompetitorList, MAX_COMPETITOR_SIZE } from 'models/Domain/MapCompetitorResearch/Competitor';
import {
  AverageRankTableData,
  StoreKey,
  convertFromConditionKey,
} from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';

interface Props {
  className?: string;
  onSelectStore: (storeKey: StoreKey) => void;
  onChangeCompetitor: (storeKey: StoreKey) => void;
  tableData: List<AverageRankTableData>;
  isLoading: boolean;
  competitors: CompetitorList;
  managedStoreKeys: List<StoreKey>;
}

type SortKey = 'default' | 'storeName' | 'conditionCount' | 'rankedAverageRank' | 'averageRank';
type SortOrder = 'asc' | 'desc';

const FOLDED_TABLE_ITEMS = 10;

export const AverageRankTable = React.memo<Props>(
  ({ className, isLoading, onSelectStore, onChangeCompetitor, tableData, competitors, managedStoreKeys }) => {
    const [sortKey, setSortKey] = useState<SortKey>('default');
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
    const [showAllData, setShowAllData] = useState(false);
    const [showOnlyCompetitor, setShowOnlyCompetitor] = useState(false);
    const [isExcludeAds, setIsExcludeAds] = useState(true);

    const sortedTableData = useMemo(() => {
      let data = tableData;
      const inverseSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      const [sortKeys, sortOrders] = ((): [((item: AverageRankTableData) => any)[], SortOrder[]] => {
        switch (sortKey) {
          case 'default':
            // 自店舗を上位表示する
            // 掲載数が多い順、同じなら平均順位が高い順
            // defaultは初期表示のみなのでSortOrderは固定
            return [
              [
                (item) => managedStoreKeys.contains(item.storeKey),
                (item) => (isExcludeAds ? item.rankedConditionsExcludeAds.size : item.rankedConditions.size),
                (item) => (isExcludeAds ? item.averageRankExcludeAds : item.averageRank),
              ],
              ['desc', 'desc', 'asc'],
            ];
          case 'storeName':
            // 店舗名が同じ場合は、掲載数が多い順、平均順位が高い順（ascの場合）
            return [
              [
                (item) => item.storeKey.key,
                (item) => (isExcludeAds ? item.rankedConditionsExcludeAds.size : item.rankedConditions.size),
                (item) => (isExcludeAds ? item.averageRankExcludeAds : item.averageRank),
              ],
              [sortOrder, inverseSortOrder, sortOrder],
            ];
          case 'averageRank':
            // 平均順位が同じ場合は、掲載数が多い順、名前順（ascの場合）
            return [
              [
                (item) => (isExcludeAds ? item.averageRankExcludeAds : item.averageRank),
                (item) => (isExcludeAds ? item.rankedConditionsExcludeAds.size : item.rankedConditions.size),
                (item) => item.storeKey.key,
              ],
              [sortOrder, inverseSortOrder, sortOrder],
            ];
          case 'rankedAverageRank':
            // 平均掲載順位が同じ場合は、掲載数が多い順、名前順（ascの場合）
            return [
              [
                (item) => (isExcludeAds ? item.rankedAverageRankExcludeAds : item.rankedAverageRank),
                (item) => (isExcludeAds ? item.rankedConditionsExcludeAds.size : item.rankedConditions.size),
                (item) => item.storeKey.key,
              ],
              [sortOrder, inverseSortOrder, sortOrder],
            ];
          case 'conditionCount':
            // 掲載数が同じ場合は、平均順位が高い順、名前順（ascの場合）
            return [
              [
                (item) => (isExcludeAds ? item.rankedConditionsExcludeAds.size : item.rankedConditions.size),
                (item) => (isExcludeAds ? item.averageRankExcludeAds : item.averageRank),
                (item) => item.storeKey.key,
              ],
              [sortOrder, inverseSortOrder],
            ];
        }
      })();
      data = List<AverageRankTableData>(orderBy(data.toArray(), sortKeys, sortOrders));
      if (showOnlyCompetitor) {
        data = data.filter((item) => competitors.contains(item.storeKey));
      } else if (!showAllData) {
        data = data.slice(0, FOLDED_TABLE_ITEMS);
      }
      return data;
    }, [competitors, isExcludeAds, managedStoreKeys, showAllData, showOnlyCompetitor, sortKey, sortOrder, tableData]);

    const onChangeSort = useCallback(
      (newSortKey: SortKey) => {
        if (sortKey === newSortKey) {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
          setSortKey(newSortKey);
        }
      },
      [sortKey, sortOrder],
    );

    return (
      <Wrapper className={className}>
        <Header>
          <TitleLabel>平均掲載順位</TitleLabel>
          <CheckboxContainer>
            <StyledCheckbox
              onChange={() => setIsExcludeAds((value) => !value)}
              checked={isExcludeAds}
              label='広告を除外する'
            />
            <StyledCheckbox
              onChange={() => setShowOnlyCompetitor((value) => !value)}
              checked={showOnlyCompetitor}
              label='比較店舗のみ表示する'
            />
          </CheckboxContainer>
        </Header>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <ColorHeaderCell />
              <StyledTableHeaderCell onClick={() => onChangeSort('storeName')}>
                <StoreNameHeaderFlexWrapper>
                  {sortKey === 'storeName' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                  店舗名
                </StoreNameHeaderFlexWrapper>
              </StyledTableHeaderCell>
              <RankHeaderCell onClick={() => onChangeSort('conditionCount')}>
                <TableHeaderFlexWrapper>
                  {sortKey === 'conditionCount' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                  掲載数
                  <ContextHelp header={'掲載数'} content={'60位以内に掲載された検索条件の数'} />
                </TableHeaderFlexWrapper>
              </RankHeaderCell>
              <RankHeaderCell onClick={() => onChangeSort('rankedAverageRank')}>
                <TableHeaderFlexWrapper>
                  {sortKey === 'rankedAverageRank' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                  平均掲載順位
                  <ContextHelp header={'平均掲載順位'} content={'60位以内に掲載された検索条件の平均順位'} />
                </TableHeaderFlexWrapper>
              </RankHeaderCell>
              <RankHeaderCell onClick={() => onChangeSort('averageRank')}>
                <TableHeaderFlexWrapper>
                  {sortKey === 'averageRank' && <SortTriangle isSortOrderDesc={sortOrder === 'desc'} />}
                  平均順位
                  <ContextHelp
                    header={'平均順位'}
                    content={'60位以内に掲載されていない場合は60位として計算した平均順位'}
                  />
                </TableHeaderFlexWrapper>
              </RankHeaderCell>
              <MenuHeaderCell />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {sortedTableData.map((item, index) => (
              <AverageRankTableRow
                key={index}
                storeKey={item.storeKey}
                conditions={isExcludeAds ? item.rankedConditionsExcludeAds : item.rankedConditions}
                averageRank={isExcludeAds ? item.averageRankExcludeAds : item.averageRank}
                rankedAverageRank={isExcludeAds ? item.rankedAverageRankExcludeAds : item.rankedAverageRank}
                color={competitors.getColor(item.storeKey)}
                isCompetitor={competitors.contains(item.storeKey)}
                isManaged={managedStoreKeys.contains(item.storeKey)}
                disableAddCompetitor={competitors.size >= MAX_COMPETITOR_SIZE}
                onSelectStore={onSelectStore}
                onChangeCompetitor={onChangeCompetitor}
              />
            ))}
          </TableBody>
        </Table>
        {isLoading && sortedTableData.isEmpty() && <Notification>データを取得中</Notification>}
        {!isLoading && showOnlyCompetitor && competitors.size === 0 && (
          <Notification>比較店舗が設定されていません</Notification>
        )}
        {!showOnlyCompetitor && tableData.size > FOLDED_TABLE_ITEMS && (
          <ViewMore onClick={() => setShowAllData(!showAllData)}>
            {showAllData ? '表示を減らす' : '全て表示する'}
          </ViewMore>
        )}
      </Wrapper>
    );
  },
);

export const AverageRankTableRow = React.memo<{
  storeKey: StoreKey;
  conditions: List<string>;
  averageRank: number | null;
  rankedAverageRank: number | null;
  color: string | null;
  isCompetitor: boolean;
  isManaged: boolean;
  disableAddCompetitor: boolean;
  onChangeCompetitor: (storeKey: StoreKey) => void;
  onSelectStore: (storeKey: StoreKey) => void;
}>(
  ({
    storeKey,
    conditions,
    rankedAverageRank,
    averageRank,
    color,
    isCompetitor,
    isManaged,
    disableAddCompetitor,
    onChangeCompetitor,
    onSelectStore,
  }) => {
    const handleOnClick = useCallback(() => {
      onSelectStore(storeKey);
    }, [onSelectStore, storeKey]);

    const handleOnChangeCompetitor = useCallback(() => {
      onChangeCompetitor(storeKey);
    }, [onChangeCompetitor, storeKey]);

    return (
      <StyledTableRow>
        <ColorCell color={isCompetitor ? color : 'transparent'} />
        <StyledTableCell>
          <StoreName onClick={handleOnClick}>{storeKey.storeName}</StoreName>
          {isManaged && <ShopIcon />}
        </StyledTableCell>
        <RankCell>
          <RankCellContainer>
            <Popup
              content={
                <PopupContent>
                  {conditions.map((condition) => {
                    const { areaName, searchWord } = convertFromConditionKey(condition);
                    return (
                      <div key={condition}>
                        <AreaKeywordLabel areaName={areaName} searchWord={searchWord} />
                      </div>
                    );
                  })}
                </PopupContent>
              }
              trigger={<PopupTrigger>{conditions.size}件</PopupTrigger>}
            />
          </RankCellContainer>
        </RankCell>
        <RankCell>
          <RankCellContainer>
            <Rank>{rankedAverageRank?.toFixed(1) ?? 'ー'}</Rank>
          </RankCellContainer>
        </RankCell>
        <RankCell>
          <RankCellContainer>
            <Rank>{averageRank?.toFixed(1) ?? 'ー'}</Rank>
          </RankCellContainer>
        </RankCell>
        <MenuCell>
          <Dropdown trigger={<SettingsIcon />} icon={null}>
            <Dropdown.Menu direction={'left'}>
              {!isCompetitor && disableAddCompetitor ? (
                <ActionMenuItem>
                  <Popup
                    content={<PopupContent>比較店舗に設定できるのは{MAX_COMPETITOR_SIZE}店舗までです</PopupContent>}
                    trigger={<ActionItemLabel disabled>比較店舗に追加</ActionItemLabel>}
                  />
                </ActionMenuItem>
              ) : (
                <ActionMenuItem onClick={handleOnChangeCompetitor}>
                  <ActionItemLabel>{isCompetitor ? '比較店舗から除外' : '比較店舗に追加'}</ActionItemLabel>
                </ActionMenuItem>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </MenuCell>
      </StyledTableRow>
    );
  },
);

const StyledTableRow = styled(TableRow)``;

const StyledTableHeaderCell = styled(SortableTableHeaderCell)``;

const StyledTableCell = styled(TableCell)``;

const RankHeaderCell = styled(StyledTableHeaderCell)`
  width: 200px;
  text-align: right !important;
`;

const RankCell = styled(TableCell)`
  width: 200px;
`;

const RankCellContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PopupTrigger = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-family: monospace !important;
  font-weight: normal;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;

const TableHeaderFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const StoreNameHeaderFlexWrapper = styled(TableHeaderFlexWrapper)`
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const ViewMore = styled.div`
  width: 100%;
  margin: 8px 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleLabel = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 14px;
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 8px;
`;
