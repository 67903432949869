import { toast } from 'react-semantic-toasts';
import { call, put, takeLatest } from 'redux-saga/effects';

import { MenuApi } from 'ApiClient/MenuApi';
import { Menu, MenuSummary } from 'models/Domain/Menu/Menu';
import { MenuSearchCondition } from 'models/Domain/Menu/MenuSearchCondition';
import { AppActions } from 'modules/app/actions';
import { onDropAccepted } from 'modules/app/fileUpload';
import { waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

import { MenuActions } from './actions';

export default function* saga() {
  yield takeLatest(MenuActions.initializeIndexPage, initializeIndexPage);
  yield takeLatest(MenuActions.initializeCreatePage, initializeCreatePage);
  yield takeLatest(MenuActions.initializeEditPage, initializeEditPage);
  yield takeLatest(MenuActions.uploadImage, uploadImage);
  yield takeLatest(MenuActions.updateMenuSummary, updateMenuSummary);
  yield takeLatest(MenuActions.deleteMenuSummary, deleteMenuSummary);
  yield takeLatest(MenuActions.createMenu, createMenu);
  yield takeLatest(MenuActions.updateMenu, updateMenu);
  yield takeLatest(MenuActions.deleteMenu, deleteMenu);
  yield takeLatest(MenuActions.applyToGbp, applyToGbp);
}

// メニューグループ一覧を取得する
export function* getMenuSummary() {
  // TODO: First Releaseでは絞り込み条件がないため、空の条件で取得する
  const condition = new MenuSearchCondition();
  const params = condition.toParams();
  const response: YieldReturn<typeof MenuApi.getSummary> = yield MenuApi.getSummary(params);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'メニューグループの取得に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
    yield put(MenuActions.setIsLoadingMenu(false));
    return;
  }
  const menuSummary = MenuSummary.fromJSON(response.data);
  yield put(MenuActions.setMenuSummary(menuSummary));
}

// 一覧ページを初期化する
function* initializeIndexPage() {
  yield put(MenuActions.setIsLoadingMenu(true));

  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // メニューグループ一覧を取得する
  yield call(getMenuSummary);

  yield put(MenuActions.setIsLoadingMenu(false));
}

// 作成ページを初期化する
function* initializeCreatePage(action: ReturnType<typeof MenuActions.initializeCreatePage>) {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // idが指定されていた場合は、複製元のデータを取得し、そうでなければ新規のメニューを作成する
  const id = action.payload;

  if (!id) {
    yield put(MenuActions.setMenu(new Menu()));
    yield put(MenuActions.setIsLoadingMenu(false));
    return;
  }

  const response: YieldReturn<typeof MenuApi.get> = yield MenuApi.get(id);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'メニューグループの取得に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
    yield put(MenuActions.setIsLoadingMenu(false));
    yield put(MenuActions.setMenu(new Menu()));
    return;
  }

  // 取得したメニューをcloneする（idはすべてnullになる）
  const menu = Menu.fromJSON(response.data).clone();
  yield put(MenuActions.setMenu(menu));
  yield put(MenuActions.setIsLoadingMenu(false));
}

// 編集ページを初期化する
function* initializeEditPage(action: ReturnType<typeof MenuActions.initializeEditPage>) {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const id = action.payload;

  const response: YieldReturn<typeof MenuApi.get> = yield MenuApi.get(id);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'メニューグループの取得に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
    yield put(MenuActions.setIsLoadingMenu(false));
    return;
  }

  const menu = Menu.fromJSON(response.data);
  yield put(MenuActions.setMenu(menu));

  yield put(MenuActions.setIsLoadingMenu(false));
}

function* uploadImage(action: ReturnType<typeof MenuActions.uploadImage>) {
  yield put(MenuActions.clearUploadedImageUrl());
  const files = action.payload;
  const urls: string[] = yield call(onDropAccepted, files);
  const url = urls[0];
  if (!url) return;
  yield put(MenuActions.setUploadedImageUrl(url));
}

function* updateMenuSummary(action: ReturnType<typeof MenuActions.updateMenuSummary>) {
  yield put(MenuActions.setIsLoadingMenu(true));
  const menuSummary = action.payload;
  const params = menuSummary.toUpdateParams();
  const response: YieldReturn<typeof MenuApi.batchUpdateSummary> = yield MenuApi.batchUpdateSummary(params);
  if (response.isSuccess) {
    yield put(MenuActions.initializeIndexPage());
  } else {
    toast({
      type: 'error',
      title: '保存に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(MenuActions.setIsLoadingMenu(false));
}

function* deleteMenuSummary(action: ReturnType<typeof MenuActions.deleteMenuSummary>) {
  yield put(MenuActions.setIsLoadingMenu(true));
  const ids = action.payload.toArray();
  const response: YieldReturn<typeof MenuApi.batchDelete> = yield MenuApi.batchDelete({ ids });
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メニューを削除しました',
    });
    yield put(MenuActions.initializeIndexPage());
  } else {
    toast({
      type: 'error',
      title: '削除に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(MenuActions.setIsLoadingMenu(false));
}

function* createMenu(action: ReturnType<typeof MenuActions.createMenu>) {
  yield put(MenuActions.setIsLoadingMenu(true));
  const menu = action.payload;
  const params = menu.toCreateParams();
  const response: YieldReturn<typeof MenuApi.post> = yield MenuApi.post(params);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メニューを作成しました',
    });
    yield put(AppActions.moveTo(Path.menu.index));
  } else {
    toast({
      type: 'error',
      title: '作成に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(MenuActions.setIsLoadingMenu(false));
}

function* updateMenu(action: ReturnType<typeof MenuActions.updateMenu>) {
  yield put(MenuActions.setIsLoadingMenu(true));
  const menu = action.payload;
  if (!menu.id) {
    toast({
      type: 'error',
      title: 'メニューの更新に失敗しました',
      time: 3000,
    });
    yield put(MenuActions.setIsLoadingMenu(false));
    return;
  }
  const params = menu.toUpdateParams();
  const response: YieldReturn<typeof MenuApi.put> = yield MenuApi.put(menu.id, params);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メニューを更新しました',
    });
    yield put(AppActions.moveTo(Path.menu.index));
  } else {
    toast({
      type: 'error',
      title: 'メニューの更新に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(MenuActions.setIsLoadingMenu(false));
}

function* deleteMenu(action: ReturnType<typeof MenuActions.deleteMenu>) {
  yield put(MenuActions.setIsLoadingMenu(true));
  const id = action.payload;
  const response: YieldReturn<typeof MenuApi.batchDelete> = yield MenuApi.batchDelete({ ids: [id] });
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'メニューを削除しました',
    });
    yield put(AppActions.moveTo(Path.menu.index));
  } else {
    toast({
      type: 'error',
      title: '削除に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(MenuActions.setIsLoadingMenu(false));
}

function* applyToGbp() {
  yield put(MenuActions.setIsApplyingToGbp(true));
  const response: YieldReturn<typeof MenuApi.applyToGbp> = yield MenuApi.applyToGbp();
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'GBPへの反映を開始しました',
    });
    yield put(MenuActions.initializeIndexPage());
  } else {
    toast({
      type: 'error',
      title: 'GBPへの反映に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(MenuActions.setIsApplyingToGbp(false));
}
