import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List as ImmutableList, Set as ImmutableSet, is } from 'immutable';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { Prompt } from 'components/atoms/Prompt';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { MenuHelp as Help } from 'helpers/ContextHelp';
import { useConfirmLeave } from 'hooks/useConfirmLeave';
import { MAX_MENU_NAME_LENGTH, Menu, MenuSection } from 'models/Domain/Menu/Menu';
import { Group } from 'types/Common';

import { MenuSectionModal } from './MenuSectionModal';
import { MenuSectionTable } from './MenuSectionTable';

const promptMessage = 'ページから離れようとしています。\n保存されていない内容は失われてしまいますが、よろしいですか？';

type Props = {
  className?: string;
  menu: Menu;
  onCancel: () => void;
  onSave: (menu: Menu) => void;
};

export const MenuEditForm: React.FC<Props> = ({ menu: defaultMenu, onSave, onCancel }) => {
  const [menuForEdit, setMenuForEdit] = useState<Menu>(defaultMenu);
  const [showSectionModal, setShowSectionModal] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showGroupSelectOnly, setShowGroupSelectOnly] = useState(menuForEdit.storeIds === null);
  const [showPrompt, setShowPrompt] = useState<boolean>(true);

  useEffect(() => {
    setMenuForEdit(defaultMenu);
  }, [defaultMenu]);

  const groupStoreSelectError = useMemo(() => {
    if (menuForEdit.storeIds != null && menuForEdit.storeIds.isEmpty()) {
      return { store: '店舗を選択してください' };
    }
    return null;
  }, [menuForEdit.storeIds]);

  const handleOnChangeName = useCallback((value: string) => {
    setMenuForEdit((menu) => menu.changeName(value));
  }, []);

  const handleOnChangeGroupStore = useCallback(
    (group: Group, storeIds: ImmutableSet<number>, isAllStores, showClosedStores, showGroupSelectOnly) => {
      setMenuForEdit((menu) =>
        menu.merge({
          groupIds: typeof group === 'number' ? ImmutableList([group]) : null,
          storeIds: showGroupSelectOnly ? null : ImmutableList(storeIds),
        }),
      );
      setShowGroupSelectOnly(showGroupSelectOnly);
    },
    [],
  );

  const handleOnAddSection = useCallback((section: MenuSection) => {
    setMenuForEdit((menu) => menu.update('sections', (sections) => sections.push(section)));
  }, []);

  const handleOnDeleteSection = useCallback(
    (index: number) => () => {
      setMenuForEdit((menu) => menu.removeIn(['sections', index]));
    },
    [],
  );

  const handleOnChangeSection = useCallback(
    (index: number) => (section: MenuSection) => {
      setMenuForEdit((menu) => menu.setIn(['sections', index], section));
    },
    [],
  );

  const handleOnChangeSectionOrder = useCallback(
    (index: number, newIndex: number) => () => {
      setMenuForEdit((menu) =>
        menu.update('sections', (sections) => {
          const section = sections.get(index)!;
          return sections.remove(index).insert(newIndex, section);
        }),
      );
    },
    [],
  );

  const handleOnChangeApplyToGbp = useCallback(() => {
    setMenuForEdit((menu) => menu.set('applyToGbp', !menu.applyToGbp));
  }, []);

  const validateSection = useMemo(() => {
    if (menuForEdit.sections.isEmpty()) {
      return { isValid: false, error: 'メニューにはセクションを1つ以上設定してください' };
    }
    return { isValid: true };
  }, [menuForEdit.sections]);

  const validateItems = useMemo(() => {
    const emptySection = menuForEdit.sections.find((section) => section.items.isEmpty());
    if (emptySection) {
      return { isValid: false, error: `「${emptySection.name}」に１つ以上のアイテムを設定してください` };
    }
    return { isValid: true };
  }, [menuForEdit]);

  const isValid = useMemo(() => {
    return menuForEdit.validateName().isValid && validateSection.isValid && validateItems.isValid;
  }, [menuForEdit, validateSection, validateItems]);

  const handleOnSave = useCallback(() => {
    // エラーがあればエラーの表示を有効にして、保存しない
    if (!isValid) {
      setShowErrorMessage(true);
      window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
    } else {
      setShowPrompt(false);
      onSave(menuForEdit);
    }
  }, [onSave, menuForEdit, isValid]);

  // データが変更されている場合、タブを閉じる前に確認ダイアログを表示
  useConfirmLeave(showPrompt && !is(menuForEdit, defaultMenu));

  return (
    <Wrapper>
      <Container>
        <CheckboxWrapper>
          GBP連携
          <ContextHelp content={Help.applyToGbp} />
          <StyledCheckbox toggle checked={menuForEdit.applyToGbp} onChange={handleOnChangeApplyToGbp} />
        </CheckboxWrapper>
        {menuForEdit.updateAt && <UpdateAt>最終更新日：{menuForEdit.updateAt.format('YYYY/MM/DD')}</UpdateAt>}
      </Container>
      <FormContent name={'メニューグループ名'} required informationText={Help.menuName}>
        <StyledInput
          value={menuForEdit.name}
          onChange={handleOnChangeName}
          error={showErrorMessage && menuForEdit.validateName().error}
          placeholder={'（例）全国共通メニュー、関東限定メニュー'}
          data-testid={'nameInput'}
        />
        <StyledTextCount maxSize={MAX_MENU_NAME_LENGTH} size={menuForEdit.name.length} />
      </FormContent>
      <FormContent name={'対象店舗・グループ'} required informationText={Help.groupStore}>
        <GroupStoreSelect
          group={menuForEdit.groupIds?.get(0) ?? 'all'}
          storeIds={menuForEdit.storeIds?.toSet() ?? ImmutableSet([])}
          showClosedStores={false}
          showClosedStoreCheckbox={false}
          onChange={handleOnChangeGroupStore}
          sizeVariant={'large'}
          errors={groupStoreSelectError}
          showGroupSelectOnly={showGroupSelectOnly}
          canSwitchSelectionMode={true}
          storeFilter={(store) => !!store}
        />
      </FormContent>
      <FormContent
        name={'メニューの内容'}
        informationText={
          'メニューには１つ以上のセクション、それぞれのセクションには１つ以上のアイテムを設定してください'
        }
      >
        {menuForEdit.sections.map((section, index) => (
          <MenuSectionTable
            key={index}
            menuSection={section}
            onDelete={handleOnDeleteSection(index)}
            onChange={handleOnChangeSection(index)}
            onMoveUp={handleOnChangeSectionOrder(index, index - 1)}
            onMoveDown={handleOnChangeSectionOrder(index, index + 1)}
            showMoveUp={index !== 0}
            showMoveDown={index !== menuForEdit.sections.size - 1}
            showErrorMessage={showErrorMessage}
          />
        ))}
      </FormContent>
      <AddButton onClick={() => setShowSectionModal(true)}>
        <AddIcon />
        セクションを追加する
      </AddButton>

      {showErrorMessage && !validateSection.isValid && <DummyInput error={validateSection.error} />}
      <ButtonContainer>
        <StyledButton onClick={onCancel}>キャンセル</StyledButton>
        <StyledButton
          onClick={handleOnSave}
          priority={'high'}
          disabled={showErrorMessage && !isValid}
          data-testid={'submitButton'}
        >
          保存
        </StyledButton>
      </ButtonContainer>
      {showSectionModal && (
        <MenuSectionModal
          isOpen={true}
          onClose={() => setShowSectionModal(false)}
          section={new MenuSection()}
          onChange={handleOnAddSection}
          isEdit={false}
        />
      )}
      <Prompt when={showPrompt && !is(menuForEdit, defaultMenu)} message={promptMessage} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyledInput = styled(Input)``;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const AddButton = styled(Button).attrs({ priority: 'low' })`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AddIcon = styled(Icon).attrs({ type: 'add' })`
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 176px;
    height: 54px;
    padding: 0;
    align-self: end;
    margin-left: 16px;
    box-shadow: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  width: 100%;
  padding: 16px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px 24px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
  &&& {
    input:focus:checked ~ label:before,
    input:checked ~ label:before {
      background-color: #05ccad !important;
    }
  }
`;

const UpdateAt = styled.div``;

const DummyInput = styled(Input)`
  &&& {
    input {
      display: none;
    }
  }
`;
