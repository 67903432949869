import dayjs, { Dayjs } from 'dayjs';
import { Record as ImmutableRecord, Set as ImmutableSet, is } from 'immutable';

import { GbpPerformanceGetMonthlyParams, GbpPerformanceGetParams } from 'ApiClient/GbpPerformanceApi';
import {
  booleanToSearchParam,
  dateToSearchParam,
  numberComparator,
  parseBooleanParam,
  parseDateParameter,
} from 'helpers/search';
import { AggregateUnit, Group } from 'types/Common';

import { DISPLAY_TYPES, DisplayType } from './GbpPerformance';

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType, 'isAllStoreIds' | 'isEnabledComparison'>]: string;
} = {
  group: 'st',
  storeIds: 'si',
  showClosedStores: 'cs',
  aggregateUnit: 'au',
  startDate: 'sd',
  endDate: 'ed',
  comparisonStartDate: 'csd',
  comparisonEndDate: 'ced',
  displayType: 'dt',
};

// FIXME
// デフォルト値をモジュールレベルで定義してしまうと、テスト時に値(maxDateのdayjs())が固定できないので
// 関数の形にして利用するときに生成するようにしている
export const defaultDates = () => {
  const MAX_DATE = dayjs().startOf('day').subtract(1, 'day');
  const DEFAULT_START_DATE = MAX_DATE.subtract(DEFAULT_DAY_OPTION - 1, 'day');
  const DEFAULT_END_DATE = MAX_DATE;
  // 比較期間は「前の期間」DEFAULT_START_DATEの28日前からDEFAULT_START_DATEの前日まで
  const DEFAULT_COMPARISON_START_DATE = DEFAULT_START_DATE.subtract(DEFAULT_DAY_OPTION, 'day');
  const DEFAULT_COMPARISON_END_DATE = DEFAULT_START_DATE.subtract(1, 'day');
  return {
    MAX_DATE,
    DEFAULT_START_DATE,
    DEFAULT_END_DATE,
    DEFAULT_COMPARISON_START_DATE,
    DEFAULT_COMPARISON_END_DATE,
  };
};

const DEFAULT_GROUP: Group = null;
const DEFAULT_IS_ALL_STORE_IDS = true;
const DEFAULT_STORE_IDS: ImmutableSet<number> = ImmutableSet<number>();
const DEFAULT_SHOW_CLOSED_STORES = false;
const DEFAULT_AGGREGATE_UNIT: AggregateUnit = 'day';
// 初期値は集計単位「日」の「過去28日間」
const DEFAULT_DAY_OPTION = 28;
export const DEFAULT_IS_ENABLED_COMPARISON = false;
const DEFAULT_DISPLAY_TYPE: DisplayType = 'overview';

type FilterStatusType = {
  group: Group;
  storeIds: ImmutableSet<number>;
  isAllStoreIds: boolean;
  showClosedStores: boolean;
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs;
  comparisonEndDate: Dayjs;
  isEnabledComparison: boolean;

  // 画面表示上における絞り込み要素
  displayType: DisplayType;
};

export class FilterStatus extends ImmutableRecord<FilterStatusType>({
  group: DEFAULT_GROUP,
  storeIds: DEFAULT_STORE_IDS,
  isAllStoreIds: DEFAULT_IS_ALL_STORE_IDS,
  showClosedStores: DEFAULT_SHOW_CLOSED_STORES,
  aggregateUnit: DEFAULT_AGGREGATE_UNIT,
  startDate: defaultDates().DEFAULT_START_DATE,
  endDate: defaultDates().DEFAULT_END_DATE,
  comparisonStartDate: defaultDates().DEFAULT_COMPARISON_START_DATE,
  comparisonEndDate: defaultDates().DEFAULT_COMPARISON_END_DATE,
  isEnabledComparison: DEFAULT_IS_ENABLED_COMPARISON,
  displayType: 'overview',
}) {
  setStoreIds(storeIds: ImmutableSet<number>, isAllStoreIds: boolean) {
    return this.merge({ storeIds, isAllStoreIds });
  }
}

export class GbpPerformanceSearchCondition extends ImmutableRecord<{
  filter: FilterStatus;
}>({
  filter: new FilterStatus(),
}) {
  /**
   * ページのURLパラメータから検索条件を生成する
   * @param search URLパラメータ
   * @returns 検索条件
   */
  static fromURLSearchParams(search: string): GbpPerformanceSearchCondition {
    const condition = new GbpPerformanceSearchCondition();
    let { filter } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連

    // グループ
    const group = params.get(URLSearchParamsMapping.group);
    if (group) {
      if (group === 'all' || group === 'my_store') {
        // すべての店舗の場合
        filter = filter.set('group', group);
      } else if (group.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('group', parseInt(group, 10));
      }
    }

    // 店舗ID
    const storeIds = params.get(URLSearchParamsMapping.storeIds) || 'all';
    if (storeIds === 'all') {
      filter = filter.setStoreIds(ImmutableSet<number>(), true);
    } else {
      const values = storeIds
        .split(',')
        .filter((v) => v.match(/^\d+$/))
        .map((v) => Number.parseInt(v, 10));
      filter = filter.setStoreIds(ImmutableSet<number>(values), false);
    }

    // 閉店店舗
    filter = filter.set('showClosedStores', parseBooleanParam(params.get(URLSearchParamsMapping.showClosedStores)));

    const { MAX_DATE } = defaultDates();

    // 集計期間
    const aggregateUnit = params.get(URLSearchParamsMapping.aggregateUnit);
    if (aggregateUnit && ['day', 'week', 'month'].includes(aggregateUnit)) {
      filter = filter.set('aggregateUnit', aggregateUnit as AggregateUnit);
    }
    const startDateStr = parseDateParameter(params.get(URLSearchParamsMapping.startDate));
    const endDateStr = parseDateParameter(params.get(URLSearchParamsMapping.endDate));
    if (startDateStr && endDateStr) {
      const startDate = dayjs(startDateStr);
      const endDate = dayjs(endDateStr);
      if (
        startDate.isValid() &&
        endDate.isValid() &&
        startDate.isSameOrBefore(endDate) &&
        endDate.isSameOrBefore(MAX_DATE)
      ) {
        filter = filter.merge({ startDate, endDate });
      }
    }

    // 比較期間
    const comparisonStartDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonStartDate));
    const comparisonEndDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonEndDate));
    if (comparisonStartDateStr && comparisonEndDateStr) {
      const comparisonStartDate = dayjs(comparisonStartDateStr);
      const comparisonEndDate = dayjs(comparisonEndDateStr);
      if (
        comparisonStartDate.isValid() &&
        comparisonEndDate.isValid() &&
        comparisonStartDate.isSameOrBefore(comparisonEndDate) &&
        comparisonEndDate.isSameOrBefore(MAX_DATE)
      ) {
        filter = filter.merge({ comparisonStartDate, comparisonEndDate, isEnabledComparison: true });
      }
    }

    const displayType = params.get(URLSearchParamsMapping.displayType) as DisplayType;
    if (DISPLAY_TYPES.includes(displayType)) {
      filter = filter.merge({ displayType });
    }

    return condition.merge({ filter });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    // フィルタ関連

    if (this.filter.group !== DEFAULT_GROUP) {
      params.append(URLSearchParamsMapping.group, String(this.filter.group));
    }

    if (!this.filter.isAllStoreIds && this.filter.storeIds.size > 0) {
      params.append(URLSearchParamsMapping.storeIds, this.filter.storeIds.sort(numberComparator).join(','));
    }

    if (this.filter.showClosedStores !== DEFAULT_SHOW_CLOSED_STORES) {
      params.append(URLSearchParamsMapping.showClosedStores, booleanToSearchParam(this.filter.showClosedStores));
    }

    params.append(URLSearchParamsMapping.aggregateUnit, this.filter.aggregateUnit);
    params.append(URLSearchParamsMapping.startDate, dateToSearchParam(this.filter.startDate));
    params.append(URLSearchParamsMapping.endDate, dateToSearchParam(this.filter.endDate));

    if (this.filter.isEnabledComparison) {
      params.append(URLSearchParamsMapping.comparisonStartDate, dateToSearchParam(this.filter.comparisonStartDate));
      params.append(URLSearchParamsMapping.comparisonEndDate, dateToSearchParam(this.filter.comparisonEndDate));
    }

    if (this.filter.displayType !== DEFAULT_DISPLAY_TYPE) {
      params.append(URLSearchParamsMapping.displayType, this.filter.displayType);
    }

    // 見栄え悪いので、'%2Cは','に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * グラフ・表データ取得用のAPIパラメータを取得する
   * @param forComparison
   * @returns
   */
  toGraphAndTableDataRequestParams(forComparison = false): GbpPerformanceGetParams {
    let startDate: Dayjs, endDate: Dayjs;
    if (forComparison) {
      startDate = this.filter.comparisonStartDate;
      endDate = this.filter.comparisonEndDate;
    } else {
      startDate = this.filter.startDate;
      endDate = this.filter.endDate;
    }

    return {
      unit: this.filter.aggregateUnit,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      store_ids: this.filter.storeIds.sort().join(),
    };
  }

  /**
   * 月別表データ取得用のAPIパラメータを取得する
   * @returns
   */
  toMonthlyDataRequestParams(): GbpPerformanceGetMonthlyParams {
    const startDate = this.filter.startDate;
    const endDate = this.filter.endDate;

    let month: string; // "YYYY-MM" 形式
    if (startDate.year() === endDate.year() && startDate.month() === endDate.month()) {
      // 対象期間の開始日・終了日が同月の場合は、その月を設定する
      month = endDate.format('YYYY-MM');
    } else {
      if (endDate.date() === endDate.endOf('month').date()) {
        // 対象期間の最終日が月末の場合、その月を設定する
        month = endDate.format('YYYY-MM');
      } else {
        // それ以外の場合、前月を設定する
        month = endDate.subtract(1, 'month').format('YYYY-MM');
      }
    }
    return {
      month,
      store_ids: this.filter.storeIds.sort().join(),
    };
  }

  isValid() {
    return this.isValidStoreIds() && this.isValidDateRange() && this.isValidComparisonDateRange();
  }

  isValidStoreIds() {
    return this.filter.storeIds.size > 0;
  }

  isValidDateRange() {
    const startDate = this.filter.startDate;
    const endDate = this.filter.endDate;
    if (startDate && endDate) {
      return startDate.isSameOrBefore(endDate);
    }
    return !startDate && !endDate;
  }

  isValidComparisonDateRange() {
    if (!this.filter.isEnabledComparison) {
      return true;
    }
    const startDate = this.filter.comparisonStartDate;
    const endDate = this.filter.comparisonEndDate;
    if (startDate && endDate) {
      return startDate.isSameOrBefore(endDate);
    }
    return !startDate && !endDate;
  }

  /**
   * ２つの検索条件を比較して、データ更新が必要な変更が行われているかを返す
   * @param other
   * @returns
   */
  needsUpdate(other: GbpPerformanceSearchCondition) {
    const checkProperties: (keyof FilterStatusType)[] = [
      'storeIds',
      'aggregateUnit',
      'startDate',
      'endDate',
      'comparisonStartDate',
      'comparisonEndDate',
      'isEnabledComparison',
    ];

    for (const property of checkProperties) {
      if (!is(this.filter[property], other.filter[property])) {
        return true;
      }
    }
    return false;
  }
}
