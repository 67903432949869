import React, { useCallback, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Set } from 'immutable';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { ProgressCircle } from 'components/molecules/ProgressCircle';
import { GbpChecklistModal } from 'components/pageComponents/GbpChecklist/GbpChecklistModal';
import { DashboardSearchCondition } from 'components/pageComponents/Top/DashboardSearchCondition';
import { createGBPPerformancePath } from 'helpers/path';
import { useStorage } from 'hooks/useStorage';
import { GbpChecklist, GbpChecklistItem } from 'models/Domain/GbpChecklist';
import { Organization } from 'models/Domain/Organization';
import { User } from 'models/Domain/User';
import { CONTAINS_CLOSED_STORES, ONLY_GBP_CONNECTED_STORES, ONLY_MY_STORE } from 'modules/Top';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { Group } from 'types/Common';

import { DashboardCountPanel } from './DashboardStoreCountPanel';

type DashboardChecklistProps = {
  className?: string;
  isLoading: boolean;
  storeIds: Set<number>;
  group: Group;
  checklist: GbpChecklist;
  onChangeGroup: (group: Group) => void;
  onChangeStoreIds: (storeIds: Set<number>) => void;
  commitStoreIds: () => void;
  organization: Organization;
  currentUser: User;
};

const DASHBOARD_COUNT_ITEMS = ['diffGbp', 'newPhoto', 'localPostPosted', 'repliedReview'] as const;
type DashboardCountItem = (typeof DASHBOARD_COUNT_ITEMS)[number];

const isHiddenItem = (expiredAt: string | undefined) => {
  if (expiredAt === undefined) {
    return false;
  }
  return dayjs(expiredAt).isAfter(dayjs());
};

export const DashboardChecklist: React.FC<DashboardChecklistProps> = ({
  className,
  isLoading,
  group,
  storeIds,
  checklist,
  onChangeGroup,
  onChangeStoreIds,
  commitStoreIds,
  organization,
  currentUser,
}) => {
  const [selectedItem, setSelectedItem] = useState<GbpChecklistItem | null>();

  const periodText = useMemo(
    () => `${checklist.period.startDate.format('YYYY/MM/DD')}〜${checklist.period.endDate.format('YYYY/MM/DD')}`,
    [checklist.period],
  );

  const {
    items: {
      basicInformation: { diffGbp },
      photo: { newPhoto },
      localPost: { newPost: localPostPosted },
      review: { replied: repliedReview },
    },
  } = checklist;

  const checklistItems: { [key in DashboardCountItem]: GbpChecklistItem } = useMemo(
    () => ({
      diffGbp: {
        title: 'Googleの更新に対応した店舗数',
        status: diffGbp,
        url: 'diff' as const,
      },
      newPhoto: {
        title: '写真を追加した店舗数',
        status: newPhoto,
        url: 'photo' as const,
      },
      localPostPosted: {
        title: '投稿した店舗数',
        status: localPostPosted,
        url: 'localPost' as const,
      },
      repliedReview: {
        title: 'クチコミに対応した店舗数',
        status: repliedReview,
        url: 'review' as const,
      },
    }),
    [diffGbp, localPostPosted, newPhoto, repliedReview],
  );

  const handleOnClickCountDetail = useCallback(
    (target: DashboardCountItem) => {
      const item = checklistItems[target];
      if (item) {
        setSelectedItem(item);
      }
    },
    [checklistItems],
  );

  const [hiddenItems, setHiddenItems] = useStorage<{ [key in DashboardCountItem]?: string }>(
    'DASHBOARD_HIDDEN_ITEMS',
    {},
  );

  /**
   * 項目を非表示にする
   */
  const hideItem = useCallback(
    (target: DashboardCountItem) => {
      hiddenItems[target] = dayjs().add(30, 'day').toISOString();
      if (
        window.confirm(
          '項目を非表示にしますか？\n\n非表示にした項目は30日経過するか、「非表示の項目を表示する」をクリックすると再度表示されるようになります。',
        )
      ) {
        setHiddenItems(hiddenItems);
      }
    },
    [hiddenItems, setHiddenItems],
  );

  /**
   * 非表示の項目をリセットする
   */
  const resetHiddenItems = useCallback(() => {
    setHiddenItems({});
  }, [setHiddenItems]);

  const hasHiddenItem = DASHBOARD_COUNT_ITEMS.find((key) => isHiddenItem(hiddenItems[key])) !== undefined;

  const gbpPerformenceLinkPath = useMemo(
    () =>
      createGBPPerformancePath({
        storeIds: storeIds.toArray(),
        startDate: checklist.period.startDate,
        endDate: checklist.period.endDate,
        displayType: 'activities',
      }),
    [checklist.period.endDate, checklist.period.startDate, storeIds],
  );

  return (
    <Wrapper className={className}>
      <DashboardSearchCondition
        isLoading={isLoading}
        storeIds={storeIds}
        onChangeStoreIds={onChangeStoreIds}
        onChangeGroup={onChangeGroup}
        commit={commitStoreIds}
        groupStoreSelectOptions={{
          group: group,
          showClosedStores: CONTAINS_CLOSED_STORES,
          showGbpConnectedStoresOnly: ONLY_GBP_CONNECTED_STORES,
          showMyStoreOnly: ONLY_MY_STORE,
          showClosedStoreCheckbox: false,
        }}
      />
      <ProgressContainer>
        <FlexContainer>
          <ProgressWrapper>
            <ProgressTitle>店舗情報の充足度</ProgressTitle>
            <ProgressCircle value={checklist.getStoreInfoProgress()} responsive={true} />
          </ProgressWrapper>
          <ProgressWrapper>
            <ProgressTitle>GBPの運用状況</ProgressTitle>
            <ProgressCircle value={checklist.getOperationProgress()} responsive={true} />
          </ProgressWrapper>
        </FlexContainer>
        <LinkContainer>
          <Link to={Path.gbpChecklist.index}>
            <DetailLink>詳細を見る</DetailLink>
          </Link>
        </LinkContainer>
      </ProgressContainer>
      <PeriodWrapper>
        <Period>{periodText}</Period>
        <PeriodLabel>の運用実績</PeriodLabel>
        {currentUser.canUseGbpPerformance && (
          <Link to={gbpPerformenceLinkPath}>
            <DetailLink>GBPパフォーマンスで確認する</DetailLink>
          </Link>
        )}
      </PeriodWrapper>
      <CountPanelWrapper>
        {!isHiddenItem(hiddenItems.diffGbp) && (
          <DashboardCountPanel
            title='Googleの更新に対応した店舗数'
            helpText={'Googleが更新した情報との差分に対応した店舗数'}
            value={diffGbp.doneCount}
            total={diffGbp.total}
            onClickDetail={() => handleOnClickCountDetail('diffGbp')}
            onClickClose={() => hideItem('diffGbp')}
          />
        )}
        {!isHiddenItem(hiddenItems.newPhoto) && (
          <DashboardCountPanel
            title='写真を追加した店舗数'
            helpText={'対象期間にGBPに写真を追加した店舗数'}
            value={newPhoto.doneCount}
            total={newPhoto.total}
            onClickDetail={() => handleOnClickCountDetail('newPhoto')}
            onClickClose={() => hideItem('newPhoto')}
          />
        )}
        {!isHiddenItem(hiddenItems.localPostPosted) && (
          <DashboardCountPanel
            title='投稿した店舗数'
            helpText={'対象期間に投稿をした店舗数'}
            value={localPostPosted.doneCount}
            total={localPostPosted.total}
            onClickDetail={() => handleOnClickCountDetail('localPostPosted')}
            onClickClose={() => hideItem('localPostPosted')}
          />
        )}
        {!isHiddenItem(hiddenItems.repliedReview) && (
          <DashboardCountPanel
            title='クチコミに対応した店舗数'
            helpText={`対象期間のクチコミに${organization.gbpCheckDaysOfReviewReplyDeadline}日以内に対応している店舗数`}
            value={repliedReview.doneCount}
            total={repliedReview.total}
            onClickDetail={() => handleOnClickCountDetail('repliedReview')}
            onClickClose={() => hideItem('repliedReview')}
          />
        )}
      </CountPanelWrapper>
      {hasHiddenItem && (
        <SectionTitleFlexWrapper>
          <ResetHiddenItem onClick={resetHiddenItems}>非表示の項目を表示する</ResetHiddenItem>
        </SectionTitleFlexWrapper>
      )}
      {selectedItem && <GbpChecklistModal isOpen={true} onClose={() => setSelectedItem(null)} item={selectedItem} />}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ProgressContainer = styled.div`
  background: white;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;

  position: relative;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProgressWrapper = styled.div`
  flex: 1;

  svg {
    max-height: 240px;
  }
`;

const ProgressTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
  flex: 1;
`;

const LinkContainer = styled.div`
  text-align: right;
`;
const DetailLink = styled.span`
  color: ${COLOR.GREEN};
  font-weight: bold;
`;

const PeriodWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
  align-items: baseline;
  margin-bottom: 8px;
`;

const PeriodLabel = styled.span`
  font-weight: bold;
`;

const Period = styled.span`
  font-family: monospace;
  font-weight: bold;
`;

const SectionTitleFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CountPanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    width: calc((100% - 16px) / 2);
  }
`;

const ResetHiddenItem = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 2;
  cursor: pointer;
  margin-left: auto;
`;
