import React, { useCallback, useMemo, useState } from 'react';

import { Dayjs } from 'dayjs';
import { List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { transparentize } from 'polished';
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon, legendWrapperStyle, renderLegend } from 'components/molecules/Graph';
import { getCurrentMonthStartDay, getCurrentWeekStartDay } from 'helpers/date';
import { MemoIconData, convertDateLabel, getDateLabelWithWeekOfDay } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { MAX_RANK } from 'models/Domain/MapSearchRank/MapSearchRankCompetitor';
import {
  MapSearchRankCompetitorAverageGraphData,
  MapSearchRankCompetitorAverageGraphItem,
} from 'models/Domain/MapSearchRank/MapSearchRankCompetitorAverage';
import { AggregateUnit } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { COLOR } from 'style/color';

import { INACTIVE_TRANSPARENCY, getDataSize } from '../MapSearchRankGraph';

type Props = {
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  isEnabledComparison: boolean;
  graphData: MapSearchRankCompetitorAverageGraphData;
  tagColors: Record<string, string>;
  selectedTags: ImmutableSet<string>;
  onClickLegend?: (tag: string) => void;
  onClickMemo?: (ids: number[]) => void;
  memoData?: MemoIconData[];
  graphSettings?: GraphSettings;
};

const COMPARISON_KEY_SUFFIX = `（比較）`;
const UNRANKED_KEY_SUFFIX = `（圏外）`;

const getTargetDataKey = (key: string) => {
  return key.endsWith('（比較）') ? key.replace('（比較）', '') : key;
};

const getComparisonKey = (key: string) => {
  return `${key}${COMPARISON_KEY_SUFFIX}`;
};

const getUnrankedKey = (key: string) => {
  return `${key}${UNRANKED_KEY_SUFFIX}`;
};

export const MapSearchRankCompetitorAverageGraph = React.memo<Props>(
  ({
    isEnabledComparison,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    graphData,
    aggregateUnit,
    tagColors,
    selectedTags,
    onClickLegend,
    onClickMemo,
    memoData,
    graphSettings = new GraphSettings({ min: 'auto', max: 'auto' }),
  }) => {
    // ホバーしたときにアクティブにするitemKey
    const [activeItemKey, setActiveItemKey] = useState<string | null>(null);

    const filteredGraphItems: ImmutableList<MapSearchRankCompetitorAverageGraphItem> = useMemo(() => {
      // テーブルで選択されていなければ表示しない
      return graphData.items.filter((item) => selectedTags.contains(item.tag)) ?? ImmutableList();
    }, [graphData, selectedTags]);

    // 検索条件の期間から、最大のデータサイズを取得する
    const maxSize = useMemo(
      () =>
        Math.max(
          getDataSize(startDate, endDate, aggregateUnit),
          isEnabledComparison && comparisonStartDate && comparisonEndDate
            ? getDataSize(comparisonStartDate, comparisonEndDate, aggregateUnit)
            : 0,
        ),
      [aggregateUnit, comparisonEndDate, comparisonStartDate, endDate, isEnabledComparison, startDate],
    );

    // rechartsのdataに設定できる形式に変換する
    const composedGraphData = useMemo(() => {
      // 対象期間と比較期間の長さが一致していない場合は始端を揃える
      // 指定された期間内に欠落したデータがある場合はnullが入るので、すべてのエリア×キーワードでデータの期間は揃っている想定
      const { items, comparisonItems } = graphData;

      // 「店舗名」ごとの、日付とデータのリストのリスト
      // 例（immutableではなくした場合）
      /*
        [
          // A. 集計期間のデータ
          [
            {itemDate: "2022年1月1日", "競合1": 1, "競合1（圏外）": false},
            {itemDate: "2022年2月1日", "競合1": 1, "競合1（圏外）": false},
          ],
          [
            {itemDate: "2022年1月1日", "競合2": 1, "競合2（圏外）": false},
            {itemDate: "2022年2月1日", "競合2": 1, "競合2（圏外）": false},
          ],
          // B. 比較期間のデータ
          [
            {comparisonDate: "2021年1月1日", "競合1（比較）": 1, "競合1（比較）（圏外）": false},
            {comparisonDate: "2021年2月1日", "競合1（比較）": 1, "競合1（比較）（圏外）": false},
          ],
          [
            {comparisonDate: "2021年1月1日", "競合2（比較）": 1, "競合2（比較）（圏外）": false},
            {comparisonDate: "2021年2月1日", "競合2（比較）": 1, "競合2（比較）（圏外）": false},
          ],
        ]
      */
      let data: ImmutableList<ImmutableList<any>> = ImmutableList();
      // A. 集計期間のデータ
      items.forEach((item) => {
        data = data.push(
          item.stats.map((stat) => {
            return {
              itemDate: convertDateLabel(stat.startDate, aggregateUnit),
              [item.key]: stat.isUnranked() ? MAX_RANK : stat.rank,
              [getUnrankedKey(item.key)]: stat.isUnranked(),
              tag: item.tag,
            };
          }),
        );
      });
      // B. 比較期間のデータ
      isEnabledComparison &&
        comparisonItems.forEach((item) => {
          data = data.push(
            item.stats.map((stat) => {
              return {
                comparisonDate: convertDateLabel(stat.startDate, aggregateUnit),
                [getComparisonKey(item.key)]: stat.isUnranked() ? MAX_RANK : stat.rank,
                [getUnrankedKey(getComparisonKey(item.key))]: stat.isUnranked(),
                tag: item.tag,
              };
            }),
          );
        });
      // 検索条件の期間の開始日を基準とした集計期間の最初の日付
      let firstDate = startDate;
      let comparisonFirstDate = comparisonStartDate;
      switch (aggregateUnit) {
        case 'month':
          firstDate = getCurrentMonthStartDay(startDate);
          if (comparisonStartDate) {
            comparisonFirstDate = getCurrentMonthStartDay(comparisonStartDate);
          }
          break;
        case 'week':
          firstDate = getCurrentWeekStartDay(startDate);
          if (comparisonStartDate) {
            comparisonFirstDate = getCurrentWeekStartDay(comparisonStartDate);
          }
          break;
      }
      // dataをindex順に取得して、日付ごとのデータに変換する
      // X軸表示用に、firstDateにindex * aggregateUnit分足した日付をdateとして設定する
      /*
        [
          {
            "date": "2022年1月",           // X軸に表示する日付
            "itemDate": "2022年1月"        // 集計期間のデータの日付(Tooltip用)
            "comparisonDate": "2021年1月"  // 比較期間のデータの日付(Tooltip用、比較期間が指定されている場合のみ）
            "競合1": 1                     // 店舗名
            "競合1（圏外）": false          // 圏外フラグ(Tooltip用)
            "競合1（比較）": 1              // 店舗名（比較期間が指定されている場合のみ）
            "競合1（比較）（圏外）": false   // 圏外フラグ（Tooltip用、比較期間が指定されている場合のみ）
            "競合2": 2                     // 店舗名
            "競合2（圏外）": false          // 圏外フラグ(Tooltip用)
            "競合2（比較）": 2              // 店舗名（比較期間が指定されている場合のみ）
            "競合2（比較）（圏外）": false   // 圏外フラグ（Tooltip用、比較期間が指定されている場合のみ）
          },
          {
            "date": "2022年2月",           // X軸に表示する日付
            "itemDate": "2022年2月"        // 集計期間のデータの日付(Tooltip用)
            "comparisonDate": "2021年2月"  // 比較期間のデータの日付(Tooltip用、比較期間が指定されている場合のみ）
            ...
          },
        ]
       */
      return ImmutableList([...Array(maxSize)])
        .map((_, index) => {
          return data.reduce(
            (result, d) => result.merge(d.get(index) ?? ImmutableMap()),
            ImmutableMap({
              date: convertDateLabel(firstDate.add(index, aggregateUnit), aggregateUnit),
              itemDate: convertDateLabel(firstDate.add(index, aggregateUnit), aggregateUnit),
              comparisonDate: comparisonFirstDate
                ? convertDateLabel(comparisonFirstDate.add(index, aggregateUnit), aggregateUnit)
                : undefined,
            }),
          );
        })
        .toJS();
    }, [graphData, isEnabledComparison, startDate, comparisonStartDate, aggregateUnit, maxSize]);

    const renderTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        return (
          <ChartTooltip>
            <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
            {items.map((item) => {
              const isActive = activeItemKey == null || activeItemKey == item.name;
              const textColor = transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, COLOR.BLACK);
              const isUnranked = item.payload[getUnrankedKey(item.dataKey as string)];
              return (
                <ChartTooltip.Item key={item.dataKey}>
                  <ChartTooltip.Label color={item.color}>{item.name}</ChartTooltip.Label>
                  <ChartTooltip.Value color={textColor}>
                    {/*グラフ上は同じ60位でも圏外フラグがtrueなら「圏外」、falseなら「60.0」と表示される*/}
                    {isUnranked ? '圏外' : item.value != null ? item.value.toFixed(1) : 'ー'}
                  </ChartTooltip.Value>
                </ChartTooltip.Item>
              );
            })}
          </ChartTooltip>
        );
      },
      [activeItemKey],
    );

    const renderComparisonTooltip = useCallback(
      (data: TooltipProps<number, string>) => {
        const items = data.payload ?? [];
        // タイトル→指定期間のデータ→比較期間のデータの順番で、表示するべき内容だけを表示する
        let usedTitle = ImmutableSet<string>(); // 使用済みのタイトル
        return (
          <ChartTooltip>
            {items.map((item) => {
              const dataKey = (item.dataKey ?? '') as string;
              const title = (item.name ?? '').replace(COMPARISON_KEY_SUFFIX, '');
              const isComparison = (item.name ?? '').endsWith(COMPARISON_KEY_SUFFIX);
              const showTitle = !usedTitle.has(title);
              usedTitle = usedTitle.add(title);
              const isActive = activeItemKey == null || activeItemKey == title;
              const textColor = transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, COLOR.BLACK);
              const isUnranked = item.payload[getUnrankedKey(dataKey)];
              return (
                <React.Fragment key={dataKey}>
                  {showTitle && <ChartTooltip.Title color={item.color}>{title}</ChartTooltip.Title>}
                  <ChartTooltip.Item key={item.dataKey}>
                    <ChartTooltip.Label color={textColor}>
                      {getDateLabelWithWeekOfDay(isComparison ? item.payload.comparisonDate : item.payload.itemDate)}
                    </ChartTooltip.Label>
                    <ChartTooltip.Value color={textColor}>
                      {/*グラフ上は同じ60位でも圏外フラグがtrueなら「圏外」、falseなら「60.0」と表示される*/}
                      {isUnranked ? '圏外' : item.value != null ? item.value.toFixed(1) : 'ー'}
                    </ChartTooltip.Value>
                  </ChartTooltip.Item>
                </React.Fragment>
              );
            })}
          </ChartTooltip>
        );
      },
      [activeItemKey],
    );

    // 表示中のグラフデータの中で、最も値が大きいrankを取得する
    const maxRank = useMemo(() => {
      const itemsMaxRank =
        graphData?.items
          .filter((item) => selectedTags.contains(item.tag))
          .map((item) => item.stats.map((stat) => (stat.isUnranked() ? MAX_RANK : stat.rank || 0)))
          .flatten()
          .reduce((max, value) => Math.max(value, max), 0) ?? 0;
      const comparisonItemsMaxRank =
        graphData?.comparisonItems
          .filter((item) => selectedTags.contains(item.tag))
          .map((item) => item.stats.map((stat) => (stat.isUnranked() ? MAX_RANK : stat.rank || 0)))
          .flatten()
          .reduce((max, value) => Math.max(value, max), 0) ?? 0;
      return Math.max(itemsMaxRank, comparisonItemsMaxRank);
    }, [graphData, selectedTags]);

    // 表示中のグラフデータの中で、最も値が小さいrankを取得する
    const minRank = useMemo(() => {
      // 初期値を順位の範囲外の大きな数字にしておく
      const MAX_RANK = 100;
      const itemsMinRank =
        graphData?.items
          .filter((item) => selectedTags.contains(item.tag))
          .map((item) => item.stats.map((stat) => (stat.isUnranked() ? MAX_RANK : stat.rank || MAX_RANK)))
          .flatten()
          .reduce((min, value) => Math.min(value, min), MAX_RANK) ?? MAX_RANK;
      const comparisonItemsMinRank =
        graphData?.comparisonItems
          .filter((item) => selectedTags.contains(item.tag))
          .map((item) => item.stats.map((stat) => (stat.isUnranked() ? MAX_RANK : stat.rank || MAX_RANK)))
          .flatten()
          .reduce((min, value) => Math.min(value, min), MAX_RANK) ?? MAX_RANK;
      const result = Math.min(itemsMinRank, comparisonItemsMinRank);
      // ここまできても範囲外の数値の場合は、データがないので0にしておく
      return result === MAX_RANK ? 0 : result;
    }, [graphData, selectedTags]);

    const ticks = useMemo(() => {
      return graphSettings.getRankTicks(minRank, maxRank);
    }, [maxRank, minRank, graphSettings]);

    const handleOnClickLegend = useCallback(
      (dataKey: string) => {
        const item = graphData.findByKey(dataKey);
        if (item) {
          onClickLegend && onClickLegend(item.tag);
        }
      },
      [graphData, onClickLegend],
    );

    const handleOnMouseEnter = useCallback(
      (value: string | null) => () => {
        setActiveItemKey(value);
      },
      [],
    );

    const handleOnMouseLeave = useCallback(() => {
      setActiveItemKey(null);
    }, []);

    // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
    let displayedMemoDate = ImmutableSet<string>();

    return (
      <ResponsiveContainer minHeight={300}>
        <LineChart
          data={composedGraphData}
          margin={{ top: 48, right: 32, bottom: 16 }}
          onMouseLeave={() => setActiveItemKey(null)}
        >
          {/* テーブルより下にTooltipが表示されないようにz-indexを設定 */}
          <Tooltip
            wrapperStyle={{ zIndex: 10 }}
            content={isEnabledComparison ? renderComparisonTooltip : renderTooltip}
            filterNull={false}
            isAnimationActive={false}
          />
          <Legend
            verticalAlign={'top'}
            wrapperStyle={legendWrapperStyle}
            onClick={(o: any) => handleOnClickLegend(getTargetDataKey(o.dataKey))}
            content={(props: any) => renderLegend(props)}
          />
          <XAxis
            dataKey={'date'}
            tick={{ fontSize: 12, fontWeight: 'bold' }}
            tickMargin={8}
            interval={'equidistantPreserveStart'}
          />
          <YAxis
            reversed={true}
            tick={{ fontSize: 12, fontWeight: 'bold' }}
            tickMargin={8}
            allowDecimals={false}
            ticks={ticks}
            interval={'preserveStart'}
            allowDataOverflow={true}
            domain={graphSettings.rankDomain}
          />
          {filteredGraphItems.map((item, index) => {
            const itemKey = item.key;
            const comparisonItemKey = getComparisonKey(itemKey);
            const color = tagColors[item.tag] ?? COLOR.GRAY;
            const isActive = activeItemKey == null || activeItemKey === itemKey;
            return (
              <React.Fragment key={index}>
                {itemKey && (
                  <Line
                    key={itemKey}
                    dataKey={itemKey}
                    stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, color)}
                    strokeWidth={2}
                    onMouseEnter={handleOnMouseEnter(itemKey)}
                    onMouseLeave={handleOnMouseLeave}
                    dot={{ clipDot: false }}
                    activeDot={{
                      cursor: 'pointer',
                      onMouseEnter: handleOnMouseEnter(itemKey),
                      onMouseLeave: handleOnMouseLeave,
                    }}
                    cursor='pointer'
                  />
                )}
                {isEnabledComparison && comparisonItemKey && (
                  <Line
                    key={comparisonItemKey}
                    dataKey={comparisonItemKey}
                    stroke={transparentize(isActive ? 0 : INACTIVE_TRANSPARENCY, color)}
                    strokeDasharray={'3 3'}
                    strokeWidth={2}
                    onMouseEnter={handleOnMouseEnter(itemKey || null)}
                    onMouseLeave={handleOnMouseLeave}
                    dot={{ clipDot: false }}
                    activeDot={{
                      cursor: 'pointer',
                      onMouseEnter: handleOnMouseEnter(comparisonItemKey),
                      onMouseLeave: handleOnMouseLeave,
                    }}
                    cursor='pointer'
                  />
                )}
              </React.Fragment>
            );
          })}
          {/*Y軸が間引かれてても順位が分かりやすくなるように、5ごとに補助線を引く*/}
          {ticks?.map((value) => <ReferenceLine key={value} y={value} strokeDasharray={'1 1'} />)}

          {memoData?.map((item) => {
            // すでにメモを表示している日付ならスキップ
            if (displayedMemoDate.has(item.date)) {
              return null;
            }
            // メモを表示している日付リストに追加
            displayedMemoDate = displayedMemoDate.add(item.date);
            return (
              <ReferenceLine
                key={item.date}
                x={item.date}
                stroke={'#9994'}
                label={(props) =>
                  MemoIcon({
                    ...props,
                    title: getDateLabelWithWeekOfDay(item.date),
                    content: item.contents,
                    onClick: () => onClickMemo && onClickMemo(item.ids),
                  }) as any
                }
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  },
);
