import { Record as ImmutableRecord } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { SearchVolumeSearchCondition } from 'models/Domain/SearchVolume/SearchVolumeSearchCondition';
import { SearchVolumeSearchHistory } from 'models/Domain/SearchVolume/SearchVolumeSearchHistory';
import { SearchVolumeSearchResults } from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import { SearchVolumeSearchStatus } from 'models/Domain/SearchVolume/SearchVolumeSearchStatus';

import { SearchVolumeActions } from './actions';

export class SearchVolumeState extends ImmutableRecord<{
  isPreparedIndexPage: boolean;
  isPreparedResultPage: boolean;
  isInitializedSearchCondition: boolean;
  searchCondition: SearchVolumeSearchCondition;
  committedSearchCondition: SearchVolumeSearchCondition;
  searchHistory: SearchVolumeSearchHistory;
  searchStatus: SearchVolumeSearchStatus;
  searchResults: SearchVolumeSearchResults;
  isCommitDisabled: boolean;
  isLoadingSearchHistory: boolean;
  isLoadingSearchStatus: boolean;
}>({
  isPreparedIndexPage: false,
  isPreparedResultPage: false,
  isInitializedSearchCondition: false,
  searchCondition: new SearchVolumeSearchCondition(),
  committedSearchCondition: new SearchVolumeSearchCondition(),
  searchHistory: new SearchVolumeSearchHistory(),
  searchStatus: new SearchVolumeSearchStatus(),
  searchResults: new SearchVolumeSearchResults(),
  isCommitDisabled: false,
  isLoadingSearchHistory: true,
  isLoadingSearchStatus: true,
}) {}

export const searchVolumeReducer = reducerWithInitialState(new SearchVolumeState())
  .case(SearchVolumeActions.setIsPreparedIndexPage, (state, payload) => {
    return state.set('isPreparedIndexPage', payload);
  })
  .case(SearchVolumeActions.setIsPreparedResultPage, (state, payload) => {
    return state.set('isPreparedResultPage', payload);
  })
  .case(SearchVolumeActions.setIsInitializedSearchCondition, (state, payload) => {
    return state.set('isInitializedSearchCondition', payload);
  })
  .case(SearchVolumeActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(SearchVolumeActions.setSearchHistory, (state, payload) => {
    return state.set('searchHistory', payload);
  })
  .case(SearchVolumeActions.setSearchStatus, (state, payload) => {
    return state.set('searchStatus', payload);
  })
  .case(SearchVolumeActions.setIsCommitDisabled, (state, payload) => {
    return state.set('isCommitDisabled', payload);
  })
  .case(SearchVolumeActions.setIsLoadingSearchHistory, (state, payload) => {
    return state.set('isLoadingSearchHistory', payload);
  })
  .case(SearchVolumeActions.setIsLoadingSearchStatus, (state, payload) => {
    return state.set('isLoadingSearchStatus', payload);
  })
  .case(SearchVolumeActions.setSearchResults, (state, payload) => {
    return state.set('searchResults', payload);
  });
