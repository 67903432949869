import { List, Record } from 'immutable';

import { Competitor } from 'models/Domain/Competitor/Competitor';
import { JSObject } from 'types/Common';

export class Point extends Record<{ latitude: number; longitude: number }>({ latitude: 0.0, longitude: 0.0 }) {
  static fromJSON(data: JSObject): Point {
    return new Point({ latitude: data.lat, longitude: data.lng });
  }
}

export class CompetitorSearchResultItem extends Record<{
  storeId: number;
  storeName: string;
  address: string;
  point: Point;
  placeId: string;
}>({
  storeId: 0,
  storeName: '',
  address: '',
  point: new Point(),
  placeId: '',
}) {
  static fromJSON(data: JSObject): CompetitorSearchResultItem {
    return new CompetitorSearchResultItem({
      storeId: data.store_id,
      storeName: data.store_name,
      address: data.address,
      point: Point.fromJSON(data.point),
      placeId: data.place_id,
    });
  }

  toCompetitor(): Competitor {
    return new Competitor({
      storeId: this.storeId,
      placeId: this.placeId,
      name: this.storeName,
    });
  }

  get mapUrl(): string {
    return `https://www.google.com/maps/place/?q=place_id:${this.placeId}`;
  }
}

export class CompetitorSearchResult extends Record<{
  storeId: number;
  keyword: string;
  items: List<CompetitorSearchResultItem>;
  isLoading: boolean;
  isError: boolean;
}>({
  storeId: 0,
  keyword: '',
  items: List(),
  isLoading: true,
  isError: false,
}) {
  static fromJSON(data: JSObject): CompetitorSearchResult {
    return new CompetitorSearchResult({
      storeId: data.store_id,
      keyword: data.keyword,
      items: List(
        data.items.map((item: JSObject) => CompetitorSearchResultItem.fromJSON({ ...item, store_id: data.store_id })),
      ),
      isLoading: data.is_loading || false,
      isError: data.is_error || false,
    });
  }
}
