import styled from 'styled-components';

import { COLOR } from 'style/color';

export const GuideOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 17px 8px;
  border: solid 1px #eb5181;
  color: #eb5181;
  background-color: ${COLOR.WHITE};
`;
