import { Map, Record, is } from 'immutable';

import { JSObject } from 'types/Common';

import { MAX_DATE } from './GbpInsight/GbpInsightSearchCondition';

export type SelectedDataType =
  | 'SEARCH_COUNT'
  | 'MEDIA_VIEW_DIVISION'
  | 'ACTION_RATE'
  | 'MEDIA_POST_COUNT'
  | 'REVIEW_REPLY_COUNT'
  | 'RATE_COUNT'
  | 'COMMENT_COUNT';

/** インサイトの集計単位、集計期間などを保持する */
export class InsightMetrics extends Record<{
  aggregateUnit: 'week' | 'month';
  weekDuration: number;
  monthDuration: number;
  searchActionWebsite: boolean;
  searchActionRoute: boolean;
  searchActionTel: boolean;
  searchRateCount: boolean;
  searchCommentCount: boolean;
  selectedAreaData: SelectedDataType;
  selectedBarData: SelectedDataType;
}>({
  aggregateUnit: 'week',
  weekDuration: 4,
  monthDuration: 3,
  searchActionWebsite: true,
  searchActionRoute: true,
  searchActionTel: true,
  searchRateCount: true,
  searchCommentCount: true,
  selectedAreaData: 'SEARCH_COUNT',
  selectedBarData: 'MEDIA_POST_COUNT',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get duration() {
    return this.aggregateUnit === 'week' ? this.weekDuration : this.monthDuration;
  }

  get selectedAreaDataLabel() {
    return this.getSelectedLabel(this.selectedAreaData);
  }

  get selectedBarDataLabel() {
    return this.getSelectedLabel(this.selectedBarData);
  }

  get selectedDataOptions(): { value: SelectedDataType; text: string }[] {
    return [
      { value: 'SEARCH_COUNT', text: '検索数' },
      { value: 'MEDIA_VIEW_DIVISION', text: '画像閲覧数/検索数' },
      { value: 'ACTION_RATE', text: 'アクション率' },
      { value: 'MEDIA_POST_COUNT', text: '写真投稿数' },
      { value: 'REVIEW_REPLY_COUNT', text: 'クチコミ返信数' },
      { value: 'RATE_COUNT', text: 'クチコミ数（評価のみ）' },
      { value: 'COMMENT_COUNT', text: 'クチコミ数（コメントあり）' },
    ];
  }

  get requestParams() {
    const endDate = MAX_DATE;
    const startDate = MAX_DATE.subtract(this.duration, this.aggregateUnit).format('YYYY/MM/DD');

    return {
      aggregate_unit: this.aggregateUnit,
      start_date: startDate,
      end_date: endDate.format('YYYY/MM/DD'),
    };
  }

  changeDuration(duration: number) {
    return this.aggregateUnit === 'week' ? this.set('weekDuration', duration) : this.set('monthDuration', duration);
  }

  changeSelectedAreaData(value: SelectedDataType) {
    return this.set('selectedAreaData', value);
  }

  changeSelectedBarData(value: SelectedDataType) {
    return this.set('selectedBarData', value);
  }

  hasDiff(targetMetrics: InsightMetrics) {
    return !is(Map(this.requestParams), Map(targetMetrics.requestParams));
  }

  private getSelectedLabel(selectedData: SelectedDataType) {
    return this.selectedDataOptions.find((option) => option.value === selectedData)?.text || '';
  }
}
