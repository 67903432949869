import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Store, Stores } from 'models/Domain/Store';

export type PromotionFormStoresProps = {
  className?: string;
  storeIds: List<number>;
  stores: Stores;
  displayCount?: number;
};

export const PromotionFormStores: React.FC<PromotionFormStoresProps> = ({
  className,
  storeIds,
  stores,
  displayCount = 5,
}) => {
  const promotionStores = storeIds.map((storeId) => stores.findStore(storeId)).filter((store) => store) as List<Store>;

  return (
    <Wrapper className={className}>
      {promotionStores.slice(0, displayCount).map((store) => (
        <StoreName key={store.id}>{store.shortName}</StoreName>
      ))}
      {promotionStores.size > displayCount && <RestCount>他{promotionStores.size - displayCount}店舗</RestCount>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: #393939;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 16px;
  width: 100%;
  min-height: 60px;
  border-radius: 8px;
  border: solid 1px #b5b5b5;
  background-color: #fff;
  outline: none;
`;

const StoreName = styled.div`
  display: inline-block;
  white-space: normal;
  font-size: 1em;
  box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) inset;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.14285714em 0.14285714em;
  background-color: #e8e8e8;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
`;

const RestCount = styled.div`
  display: inline-block;
  white-space: normal;
  font-size: 1em;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.14285714em 0.14285714em;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
`;
