import * as Sentry from '@sentry/browser';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';

import CONFIG from 'config';
import createRootReducer, { State } from 'modules/reducers';
import { default as rootSaga } from 'modules/sagas';

export const history = createBrowserHistory();

export default function configureStore(preloadedState?: any) {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware({
    onError(error) {
      throw error;
    },
  });
  const sentryMiddleware = createSentryMiddleware<State>(Sentry, {
    stateTransformer: (state) => null, // 413 Entity Too Large を避けるため
  });
  const middleWares = [routerMiddleware(history), sagaMiddleware, sentryMiddleware];

  if (CONFIG.REDUX_LOGGER_ENABLE) {
    const logger = createLogger({ collapsed: true });
    middleWares.push(logger);
  }

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(...middleWares)),
  );
  sagaMiddleware.run(rootSaga);

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules/reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}
