import dayjs from 'dayjs';
import { List, OrderedMap, Record } from 'immutable';

import { JSObject } from 'types/Common';

import { InsightMetrics } from './GmbInsightMetrics';
import StoreList from './StoreList';

export interface Option {
  text: string;
  value: string;
}

/**
 * APIから受け取るデータ用共通インターフェース
 */
interface DefaultData {
  store_id: number;
  first_date: string;
  ACTIONS_DRIVING_DIRECTIONS: number;
  ACTIONS_PHONE: number;
  ACTIONS_WEBSITE: number;
  QUERIES_CHAIN: number;
  QUERIES_DIRECT: number;
  QUERIES_INDIRECT: number;
  VIEWS_MAPS: number;
  VIEWS_SEARCH: number;
  MEDIA_VIEW_COUNT: number;
  MEDIA_POST_COUNT: number;
  REVIEW_REPLY_COUNT: number;
  RATE_COUNT: number; // クチコミ数（評価のみ）
  COMMENT_COUNT: number; // クチコミ数（コメントあり）
}

interface GmbInsightDataType extends DefaultData {
  last_date: string;
  period_label: string;
}

/**
 * インサイトトップのテーブル用インターフェース
 */
interface TableData extends GmbInsightDataType {
  sumSearch: number;
  sumAction: number;
  sumReview: number;
}

export type GraphDataType = {
  ACTION_SUM: number;
  SEARCH_COUNT: number;
  MEDIA_VIEW_DIVISION: number;
  ACTION_RATE: number;
} & Pick<GmbInsightDataType, keyof GmbInsightDataType>;

const ActionKeys = ['ACTIONS_WEBSITE', 'ACTIONS_DRIVING_DIRECTIONS', 'ACTIONS_PHONE'] as const;

export class GmbInsightData extends Record<GmbInsightDataType>({
  store_id: 0,
  first_date: '',
  last_date: '',
  period_label: '',
  ACTIONS_DRIVING_DIRECTIONS: 0,
  ACTIONS_PHONE: 0,
  ACTIONS_WEBSITE: 0,
  QUERIES_CHAIN: 0,
  QUERIES_DIRECT: 0,
  QUERIES_INDIRECT: 0,
  VIEWS_MAPS: 0,
  VIEWS_SEARCH: 0,
  MEDIA_VIEW_COUNT: 0,
  MEDIA_POST_COUNT: 0,
  REVIEW_REPLY_COUNT: 0,
  RATE_COUNT: 0,
  COMMENT_COUNT: 0,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get SEARCH_COUNT() {
    return this.QUERIES_DIRECT + this.QUERIES_INDIRECT + this.QUERIES_CHAIN;
  }

  sumAction(metrics: InsightMetrics) {
    const website = metrics.searchActionWebsite ? this.ACTIONS_WEBSITE : 0;
    const route = metrics.searchActionRoute ? this.ACTIONS_DRIVING_DIRECTIONS : 0;
    const tel = metrics.searchActionTel ? this.ACTIONS_PHONE : 0;
    return website + route + tel;
  }

  sumReview(metrics: InsightMetrics) {
    const rateCount = metrics.searchRateCount ? this.RATE_COUNT : 0;
    const commentCount = metrics.searchCommentCount ? this.COMMENT_COUNT : 0;
    return rateCount + commentCount;
  }
}

type SortType = 'sumSearch' | 'QUERIES_DIRECT' | 'QUERIES_INDIRECT' | 'QUERIES_CHAIN' | 'sumAction' | 'sumReview';

export class GmbInsight extends Record<{
  sortType: SortType;
  isSortOrderDesc: boolean;
  dataList: List<GmbInsightData>;
  selectedStoreList: List<number>; //比較画面のグラフ集計対象のグループ
  storeList?: StoreList; // 集計対象のグループ
}>({
  dataList: List(),
  sortType: 'sumSearch',
  isSortOrderDesc: true,
  selectedStoreList: List(),
  storeList: undefined,
}) {
  constructor(data: JSObject[] = []) {
    super({ dataList: List(data && data.map((d) => new GmbInsightData(d))) });
  }

  get sumSearch() {
    let result = 0;
    this.dataList.forEach((data) => {
      if (this.storeList && !this.storeList.contains(data.store_id)) {
        return;
      }
      result = result + (data.QUERIES_CHAIN + data.QUERIES_DIRECT + data.QUERIES_INDIRECT);
    });
    return result;
  }

  get latestDate() {
    const dateList = this.dataList.map((data) => dayjs(data.last_date, 'YYYY/MM/DD'));
    if (dateList.isEmpty()) {
      return null;
    }
    return dayjs.max(dateList.toArray())?.format('YYYY/MM/DD');
  }

  get oldestDate() {
    const dateList = this.dataList.map((data) => dayjs(data.first_date, 'YYYY/MM/DD'));
    if (dateList.isEmpty()) {
      return null;
    }
    return dayjs.min(dateList.toArray())?.format('YYYY/MM/DD');
  }

  get targetPeriod() {
    return this.oldestDate + '～' + this.latestDate;
  }

  tableDataList(metrics: InsightMetrics) {
    const dataList = this.dataList
      // 表データを選択中の店舗で絞り込み
      .filter((data) => {
        if (this.storeList && !this.storeList.contains(data.store_id)) return false;
        return true;
      });
    return this.aggregateTableData(metrics, dataList);
  }

  selectTableDataList(metrics: InsightMetrics, active: boolean, storeIdList?: number[]) {
    const dataList = this.dataList
      // 表データを選択中の店舗で絞り込み
      .filter((data) => {
        if (storeIdList && !storeIdList.includes(data.store_id)) return false;
        if (this.storeList && !this.storeList.contains(data.store_id)) return false;
        if (active) return this.selectedStoreList.includes(data.store_id);
        return !this.selectedStoreList.includes(data.store_id);
      });
    return this.aggregateTableData(metrics, dataList);
  }

  graphDataList(metrics: InsightMetrics) {
    // 集計処理などを行う
    const dateAggregateList = Object.values(this.dateKeyObject).map((dataList) =>
      this.aggregateInsight(dataList, metrics),
    );

    return List(dateAggregateList)
      .sortBy((aggregateData) => dayjs(aggregateData.first_date, 'YYYY/MM/DD'))
      .toArray();
  }

  /** 店舗比較グラフ用のデータを返す */
  selectedGraphDataList(metrics: InsightMetrics) {
    const storeList = this.selectedStoreListForDataGraph;
    const figure = {
      areaKey: metrics.selectedAreaData,
      barKey: metrics.selectedBarData,
      areaLabel: metrics.selectedAreaDataLabel,
      barLabel: metrics.selectedBarDataLabel,
    };

    const actionMetrics = {
      ACTIONS_WEBSITE: metrics.searchActionWebsite,
      ACTIONS_DRIVING_DIRECTIONS: metrics.searchActionRoute,
      ACTIONS_PHONE: metrics.searchActionTel,
    };

    const data = Object.values(this.dateKeyObject).map((dataList) => {
      let result = {};
      dataList.forEach((data) => {
        const aggregateData = {
          SEARCH_COUNT: data.SEARCH_COUNT,
          MEDIA_VIEW_DIVISION: 0,
          ACTION_RATE: 0,
          MEDIA_POST_COUNT: data.MEDIA_POST_COUNT,
          REVIEW_REPLY_COUNT: data.REVIEW_REPLY_COUNT,
          RATE_COUNT: data.RATE_COUNT,
          COMMENT_COUNT: data.COMMENT_COUNT,
        };

        if (data.SEARCH_COUNT !== 0) {
          let actionSum = 0;
          // actionKeysを全て足してACTION_SUMを算出
          ActionKeys.forEach((key) => {
            if (actionMetrics[key]) {
              actionSum += Number(data[key]);
            }
          });
          aggregateData['ACTION_RATE'] = Number(((actionSum / data.SEARCH_COUNT) * 100).toFixed(2));
          aggregateData['MEDIA_VIEW_DIVISION'] = Number((data.MEDIA_VIEW_COUNT / data.SEARCH_COUNT).toFixed(2));
        }

        result = {
          ...result,
          period_label: data.period_label,
          [`area${data.store_id}`]: aggregateData[figure.areaKey],
          [`bar${data.store_id}`]: aggregateData[figure.barKey],
        };
      });
      return result;
    });

    return { storeList, ...figure, data };
  }

  sumAction(metrics: InsightMetrics) {
    let result = 0;
    this.dataList.forEach((data) => {
      if (this.storeList && !this.storeList.contains(data.store_id)) {
        return;
      }
      result += data.sumAction(metrics);
    });
    return result;
  }

  sumReview(metrics: InsightMetrics) {
    let result = 0;
    this.dataList.forEach((data) => {
      if (this.storeList && !this.storeList.contains(data.store_id)) {
        return;
      }
      result += data.sumReview(metrics);
    });
    return result;
  }

  changeSortType(sortType: SortType) {
    if (sortType === this.sortType) {
      return this.set('isSortOrderDesc', !this.isSortOrderDesc);
    }
    return this.set('sortType', sortType);
  }

  changeStoreList(storeList: StoreList | undefined) {
    return this.set('storeList', storeList);
  }

  addSelectedStoreList(storeId: number) {
    if (this.selectedStoreList.includes(storeId)) {
      return this;
    }
    return this.set('selectedStoreList', this.selectedStoreList.push(storeId));
  }

  removeSelectedStoreList(storeId: number) {
    return this.set(
      'selectedStoreList',
      this.selectedStoreList.filter((target) => target !== storeId),
    );
  }

  sortTableData(data: List<TableData>) {
    return data.sort((data1, data2) => {
      if (this.isSortOrderDesc) {
        return data1[this.sortType] < data2[this.sortType] ? 1 : -1;
      }
      return data1[this.sortType] > data2[this.sortType] ? 1 : -1;
    });
  }

  isChecked(storeId: number) {
    return this.selectedStoreList.indexOf(storeId) >= 0;
  }

  changeDataList(data: JSObject[]) {
    return this.set('dataList', List(data && data.map((d) => new GmbInsightData(d))));
  }

  private aggregateInsight(dataList: GmbInsightData[], metrics: InsightMetrics) {
    const aggregateData: GraphDataType = {
      ...this.aggregateDataList(dataList),
      ACTION_SUM: 0,
      SEARCH_COUNT: 0,
      MEDIA_VIEW_DIVISION: 0,
      ACTION_RATE: 0,
    };

    // actionKeysを全て足してACTION_SUMを算出
    const actionMetrics = {
      ACTIONS_WEBSITE: metrics.searchActionWebsite,
      ACTIONS_DRIVING_DIRECTIONS: metrics.searchActionRoute,
      ACTIONS_PHONE: metrics.searchActionTel,
    } as const;
    ActionKeys.forEach((key) => {
      if (actionMetrics[key]) {
        aggregateData['ACTION_SUM'] = dataList.map((data) => data[key]).reduce((sum, value) => sum + value, 0);
      }
    });

    return aggregateData;
  }

  /** 比較画面の選択中店舗のデータを出力する */
  private get selectedStoreListForDataGraph() {
    return this.selectedStoreList
      .map((storeId) => ({
        storeId,
        areaKey: `area${storeId}`,
        barKey: `bar${storeId}`,
      }))
      .toArray();
  }

  private get dateKeyObject() {
    // 週次、月次の期間ラベル(period_label)をkeyにまとめる
    const dateKeyObject: { [period_label: string]: GmbInsightData[] } = {};
    this.dataList.forEach((data) => {
      const key = data.period_label;
      if (this.storeList && !this.storeList.contains(data.store_id)) {
        return;
      }
      if (!dateKeyObject[key]) {
        dateKeyObject[key] = [];
      }
      dateKeyObject[key].push(data);
    });
    return dateKeyObject;
  }

  private aggregateTableData(metrics: InsightMetrics, dataList: List<GmbInsightData>) {
    // 店舗IDでグルーピング
    // groupbyの型定義が実際の挙動と違うため上書きしている
    const groupedDataList = dataList.groupBy((data) => data.store_id) as unknown as OrderedMap<
      number,
      List<GmbInsightData>
    >;

    const actionKeys: ('ACTIONS_WEBSITE' | 'ACTIONS_DRIVING_DIRECTIONS' | 'ACTIONS_PHONE')[] = [];
    if (metrics.searchActionWebsite) actionKeys.push('ACTIONS_WEBSITE');
    if (metrics.searchActionRoute) actionKeys.push('ACTIONS_DRIVING_DIRECTIONS');
    if (metrics.searchActionTel) actionKeys.push('ACTIONS_PHONE');

    const reviewKeys: ('RATE_COUNT' | 'COMMENT_COUNT')[] = [];
    if (metrics.searchRateCount) reviewKeys.push('RATE_COUNT');
    if (metrics.searchCommentCount) reviewKeys.push('COMMENT_COUNT');

    return groupedDataList
      .map((dataList) => {
        const aggregateData: TableData = {
          ...this.aggregateDataList(dataList.toArray()),
          sumAction: 0,
          sumSearch: 0,
          sumReview: 0,
        };

        const sumSearch = (['QUERIES_CHAIN', 'QUERIES_DIRECT', 'QUERIES_INDIRECT'] as const).reduce(
          (sum, key) => sum + aggregateData[key],
          0,
        );

        let sumAction = 0;
        actionKeys.forEach((key) => {
          sumAction += aggregateData[key];
        });

        let sumReview = 0;
        reviewKeys.forEach((key) => {
          sumReview += aggregateData[key];
        });

        aggregateData.sumSearch = sumSearch;
        aggregateData.sumAction = sumAction;
        aggregateData.sumReview = sumReview;

        return aggregateData;
      })
      .toList();
  }

  private aggregateDataList(dataList: GmbInsightData[]) {
    const firstData = dataList[0];
    const aggregateData = firstData.toObject();

    // 数値については合計を算出
    // 数値以外については最初のデータを使用
    const keys = Object.keys(firstData.toObject()) as (keyof GmbInsightDataType)[];
    keys.forEach((key) => {
      switch (key) {
        case 'store_id':
          aggregateData[key] = firstData[key];
          break;
        case 'first_date':
        case 'last_date':
        case 'period_label':
          aggregateData[key] = firstData[key];
          break;
        // 同じkeyの数字の合計を出す
        default: {
          const values = dataList.map((data) => data[key]);
          aggregateData[key] = values.reduce((sum, value) => sum + value, 0);
          break;
        }
      }
    });

    return aggregateData;
  }
}
