import { toast } from 'react-semantic-toasts';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ServiceApi } from 'ApiClient/ServiceApi';
import { replaceWithOrganizationId } from 'helpers/router';
import { ServiceGroup, ServiceSummary } from 'models/Domain/Service/Service';
import { AppActions } from 'modules/app/actions';
import { GmbActions } from 'modules/gmb/actions';
import { waitForCategoriesInitialized, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';

import { ServiceActions } from './actions';

export default function* saga() {
  yield takeLatest(ServiceActions.initializeIndexPage, initializeIndexPage);
  yield takeLatest(ServiceActions.initializeCreatePage, initializeCreatePage);
  yield takeLatest(ServiceActions.initializeEditPage, initializeEditPage);
  yield takeLatest(ServiceActions.updateServiceSummary, updateServiceSummary);
  yield takeLatest(ServiceActions.batchDelete, batchDelete);
  yield takeLatest(ServiceActions.applyToGbp, applyToGbp);
  yield takeLatest(ServiceActions.createServiceGroup, createServiceGroup);
  yield takeLatest(ServiceActions.updateServiceGroup, updateServiceGroup);
}

// サービスグループ一覧を取得する
export function* getServiceSummary() {
  // TODO: First Releaseでは絞り込み条件がないため、空の条件で取得する
  const response: YieldReturn<typeof ServiceApi.getSummary> = yield ServiceApi.getSummary({});
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'サービスグループ一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
    yield put(ServiceActions.setIsLoading(false));
    return;
  }
  const serviceSummary = ServiceSummary.fromJSON(response.data);
  yield put(ServiceActions.setServiceSummary(serviceSummary));
}

// 一覧ページを初期化する
function* initializeIndexPage() {
  yield put(ServiceActions.setIsLoading(true));

  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // カテゴリ情報が取得されるまで待機する
  yield put(GmbActions.getGmbCategoryList());
  yield waitForCategoriesInitialized();

  // サービスグループ一覧を取得する
  yield call(getServiceSummary);

  yield put(ServiceActions.setIsLoading(false));
}

// 作成ページを初期化する
function* initializeCreatePage(action: ReturnType<typeof ServiceActions.initializeCreatePage>) {
  yield put(ServiceActions.setIsLoading(true));

  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // カテゴリ情報が取得されるまで待機する
  yield put(GmbActions.getGmbCategoryList());
  yield waitForCategoriesInitialized();

  // idが指定されていた場合は、複製元のデータを取得し、そうでなければ新規のサービスグループを作成する
  const id = action.payload;
  if (!id) {
    yield put(ServiceActions.setServiceGroup(new ServiceGroup()));
    yield put(ServiceActions.setIsLoading(false));
    return;
  }

  const response: YieldReturn<typeof ServiceApi.get> = yield ServiceApi.get(id);
  if (!response.isSuccess) {
    // エラーの場合は、メッセージを表示して空のデータをセットする
    toast({
      type: 'error',
      title: 'サービスグループのデータを取得できませんでした',
      description: String(response.error.message),
      time: 3000,
    });
    yield put(ServiceActions.setServiceGroup(new ServiceGroup()));
    yield put(ServiceActions.setIsLoading(false));
    return;
  }

  // 取得したサービスグループをcloneする（idはすべてnullになる）
  const serviceGroup = ServiceGroup.fromJSON(response.data).clone();
  yield put(ServiceActions.setServiceGroup(serviceGroup));
  yield put(ServiceActions.setIsLoading(false));
}

// 編集ページを初期化する
function* initializeEditPage(action: ReturnType<typeof ServiceActions.initializeEditPage>) {
  yield put(ServiceActions.setIsLoading(true));

  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // カテゴリ情報が取得されるまで待機する
  yield put(GmbActions.getGmbCategoryList());
  yield waitForCategoriesInitialized();

  const id = action.payload;
  const response: YieldReturn<typeof ServiceApi.get> = yield ServiceApi.get(id);
  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: 'サービスグループのデータを取得できませんでした',
      description: String(response.error.message),
      time: 3000,
    });
    // 取得できなかった場合は、一覧画面に遷移する
    yield put(ServiceActions.setIsLoading(false));
    yield put(replaceWithOrganizationId(Path.service.index));
    return;
  }

  const serviceGroup = ServiceGroup.fromJSON(response.data);
  yield put(ServiceActions.setServiceGroup(serviceGroup));
  yield put(ServiceActions.setIsLoading(false));
}

// サービスを一括更新する
function* updateServiceSummary(action: ReturnType<typeof ServiceActions.updateServiceSummary>) {
  yield put(ServiceActions.setIsLoading(true));
  const serviceSummary = action.payload;
  const params = serviceSummary.toUpdateParams();
  const response: YieldReturn<typeof ServiceApi.batchUpdateSummary> = yield ServiceApi.batchUpdateSummary(params);
  if (response.isSuccess) {
    yield put(ServiceActions.initializeIndexPage());
  } else {
    toast({
      type: 'error',
      title: '保存に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(ServiceActions.setIsLoading(false));
}

// サービスを一括削除する
function* batchDelete(action: ReturnType<typeof ServiceActions.batchDelete>) {
  yield put(ServiceActions.setIsLoading(true));

  const response: YieldReturn<typeof ServiceApi.batchDelete> = yield ServiceApi.batchDelete({
    ids: action.payload.toArray(),
  });
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: '削除しました',
    });
    yield put(ServiceActions.initializeIndexPage());
  } else {
    toast({
      type: 'error',
      title: '削除に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(ServiceActions.setIsLoading(false));
}

// GBPに反映する
function* applyToGbp() {
  yield put(ServiceActions.setIsLoading(true));
  const response: YieldReturn<typeof ServiceApi.applyToGbp> = yield ServiceApi.applyToGbp();
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'GBPへの反映を開始しました',
    });
    yield put(ServiceActions.initializeIndexPage());
    yield put(AppActions.getGmbLocationDiffs()); // サイドバーのGBPとの差分のバッジを更新する
  } else {
    toast({
      type: 'error',
      title: 'GBPへの反映に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(ServiceActions.setIsLoading(false));
}

// サービスグループを新規作成する
function* createServiceGroup(action: ReturnType<typeof ServiceActions.createServiceGroup>) {
  yield put(ServiceActions.setIsLoading(true));

  const serviceGroup = action.payload;
  const params = serviceGroup.toCreateParams();
  const response: YieldReturn<typeof ServiceApi.post> = yield ServiceApi.post(params);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'サービスグループを作成しました',
    });
    yield put(AppActions.moveTo(Path.service.index));
  } else {
    toast({
      type: 'error',
      title: 'サービスグループの作成に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(ServiceActions.setIsLoading(false));
}

// サービスグループを更新する
function* updateServiceGroup(action: ReturnType<typeof ServiceActions.updateServiceGroup>) {
  yield put(ServiceActions.setIsLoading(true));

  const serviceGroup = action.payload;
  if (!serviceGroup.id) {
    toast({
      type: 'error',
      title: 'サービスグループの更新に失敗しました',
      time: 3000,
    });
    yield put(ServiceActions.setIsLoading(false));
    return;
  }

  const params = serviceGroup.toUpdateParams();
  const response: YieldReturn<typeof ServiceApi.put> = yield ServiceApi.put(serviceGroup.id, params);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'サービスグループを更新しました',
    });
    yield put(AppActions.moveTo(Path.service.index));
  } else {
    toast({
      type: 'error',
      title: 'サービスグループの更新に失敗しました',
      description: String(response.error.message),
      time: 3000,
    });
  }
  yield put(ServiceActions.setIsLoading(false));
}
