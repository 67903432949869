import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { CompositeOmoInsightSummary } from 'models/Domain/OmoInsight/InsightSummary';
import { CompositeOMOInsightGraphData } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { OmoInsightSearchCondition } from 'models/Domain/OmoInsight/OmoInsightSearchCondition';
import { ProductStatus } from 'models/Domain/OmoInsight/ProductStatusSummary';

import { OmoInsightActions } from './actions';

export interface OmoInsightRecord {
  searchCondition: OmoInsightSearchCondition | null;
  prevSearchCondition: OmoInsightSearchCondition | null;

  productStatus: ProductStatus;
  insight: CompositeOmoInsightSummary;
  graph: CompositeOMOInsightGraphData;

  isFirstSearch: boolean;
  // 読み込み中か
  loadingStatus: {
    productStatus: boolean;
    insight: boolean;
    graph: boolean;
  };
}

export class OmoInsightState extends Record<OmoInsightRecord>({
  searchCondition: null,
  prevSearchCondition: null,

  productStatus: new ProductStatus(),
  insight: new CompositeOmoInsightSummary(),
  graph: new CompositeOMOInsightGraphData(),

  isFirstSearch: true,

  loadingStatus: {
    productStatus: false,
    insight: false,
    graph: false,
  },
}) {}

export const omoInsightReducer = reducerWithInitialState(new OmoInsightState())
  .case(OmoInsightActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(OmoInsightActions.updatePrevSearchCondition, (state, payload) => {
    return state.set('prevSearchCondition', state.searchCondition);
  })
  .case(OmoInsightActions.setProductStatus, (state, payload) => {
    return state.set('productStatus', payload);
  })
  .case(OmoInsightActions.setInsight, (state, payload) => {
    return state.set('insight', payload);
  })
  .case(OmoInsightActions.setGraphData, (state, payload) => {
    return state.set('graph', payload);
  })
  .case(OmoInsightActions.setLoadingStatus, (state, payload) => {
    const { target, value } = payload;
    return state.set('loadingStatus', { ...state.loadingStatus, [target]: value });
  })
  .case(OmoInsightActions.setIsFirstSearch, (state, payload) => {
    return state.set('isFirstSearch', payload);
  });
