import React, { useCallback, useRef, useState } from 'react';

import { toast } from 'react-semantic-toasts';

import { SearchVolumesApi, SearchVolumesCsvStartParams } from 'ApiClient/AdsApi';
import { CsvDownloadButton } from 'components/molecules/CsvDownloadButton';

type SearchVolumeCSVDownloadButtonProps = {
  className?: string;
  disabled?: boolean;
  condition: SearchVolumesCsvStartParams;
};

export const SearchVolumeCSVDownloadButton: React.FC<SearchVolumeCSVDownloadButtonProps> = ({
  className,
  disabled = false,
  condition,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const timerId = useRef<number | undefined>(undefined);
  const startDownload = useCallback(async () => {
    setIsProcessing(true);

    const response = await SearchVolumesApi.csvStart(condition);
    if (!response.isSuccess) {
      toast({
        type: 'error',
        title: 'CSVファイルのダウンロードに失敗しました',
        description: response.error.message,
        time: 10000,
      });
      return;
    }
    const { executionArn } = response.data;

    const checkStatus = async () => {
      const statusResponse = await SearchVolumesApi.csvStatus({ executionArn });
      if (statusResponse.isSuccess && statusResponse.data.status === 'RUNNING') {
        // 実行中なのでスキップ
        return;
      }

      setIsProcessing(false);
      window.clearInterval(timerId.current);
      timerId.current = undefined;

      // ファイルのダウンロード
      if (statusResponse.isSuccess) {
        if (statusResponse.data.status === 'SUCCEEDED') {
          const downloadUrl = statusResponse.data.download_url;
          window.location.assign(downloadUrl);
        } else {
          toast({
            type: 'error',
            title: 'CSVファイルのダウンロードに失敗しました',
            description: statusResponse.data.message,
            time: 10000,
          });
        }
      } else {
        toast({
          type: 'error',
          title: 'CSVファイルのダウンロードに失敗しました',
          description: statusResponse.error.message,
          time: 10000,
        });
      }
    };

    timerId.current = window.setInterval(checkStatus, 3000);

    return () => {
      window.clearInterval(timerId.current);
      timerId.current = undefined;
    };
  }, [condition]);

  return (
    <CsvDownloadButton
      className={className}
      label='CSVダウンロード'
      disabled={disabled || isProcessing}
      isProcessing={isProcessing}
      onClick={startDownload}
    />
  );
};
