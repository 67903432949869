import React, { useCallback, useEffect, useMemo } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ServiceGroupEditForm } from 'components/pageComponents/Service/ServiceGroupEditForm';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { ServiceGroup } from 'models/Domain/Service/Service';
import { ServiceActions } from 'modules/service/actions';
import { COLOR } from 'style/color';

export const ServiceCreate = () => {
  const dispatch = useDispatch();
  const { initializeCreatePage, createServiceGroup } = useMemo(
    () => bindActionCreators(ServiceActions, dispatch),
    [dispatch],
  );
  const { isLoading, serviceGroup } = useSelector((state) => state.service);
  const { stores } = useSelector((state) => state.store);
  const { storeLists } = useSelector((state) => state.storeList);
  const { categoryList } = useSelector((state) => state.gmb);

  // 複製元のサービスグループIDがあれば取得
  const location = useLocation<{ baseId: number }>();
  const baseServiceGroupId = location.state?.baseId;

  const handleOnSave = useCallback(
    (serviceGroup: ServiceGroup) => {
      createServiceGroup(serviceGroup);
    },
    [createServiceGroup],
  );

  const handleCancel = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  useEffect(() => {
    // baseServiceGroupIdがあれば複製元を取得して初期値として設定
    initializeCreatePage(baseServiceGroupId);
  }, [baseServiceGroupId, initializeCreatePage]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('サービスグループ作成')}></Helmet>
      <StickyHeader>
        <Title>サービスグループ作成</Title>
      </StickyHeader>
      <StyledBody>
        {isLoading && (
          <LoadingWrapper>
            <LoadingWrapperBase />
            <Loader active={true} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
        {!isLoading && (
          <ServiceGroupEditForm
            key={serviceGroup.id} // サービスグループIDが変更された場合に再描画して、フォームの初期値を更新するため
            serviceGroup={serviceGroup}
            stores={stores}
            storeLists={storeLists}
            categoryList={categoryList}
            onSave={handleOnSave}
            onCancel={handleCancel}
          />
        )}
      </StyledBody>
    </MainWrapper>
  );
};

const StyledBody = styled(WideBody)`
  position: relative;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  z-index: 1; /** カルーセルの矢印より上に表示されるようにする */
`;
