import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

export class GbpAvailableUrlTypes extends Record<{
  storeId: number;
  availableUrlTypes: List<string>;
}>({
  storeId: 0,
  availableUrlTypes: List(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new GbpAvailableUrlTypes({
      storeId: data.store_id,
      availableUrlTypes: List(data.available_url_types),
    });
  }

  /**
   * 店舗の予約リンクを編集できるか
   */
  get canEditReservationLink() {
    return this.availableUrlTypes.includes('url_appointment') || this.availableUrlTypes.includes('url_reservations');
  }
}
