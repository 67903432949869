import React, { useCallback, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Map, is } from 'immutable';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Arrow } from 'components/atoms/Arrow';
import { Button } from 'components/atoms/Button';
import { DatePicker } from 'components/atoms/DatePicker';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { InventoryHelp } from 'helpers/ContextHelp';
import { Inventory, InventoryAvailability } from 'models/Domain/Omo/Inventory';
import { GMCProduct, Product, ProductGroup, ProductGroups } from 'models/Domain/Omo/Product';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

/**
 * 文字列から数字(半角/全角)を抽出する（区切りの半角カンマは許容する）
 * @param value 文字列
 */
export const extractNumber = (value: string): string => {
  return value.replace(/[^0-9０-９,]/g, '');
};

/**
 * 文字列を数値にする
 */
export const parseNumber = (value: string): number | null => {
  // 半角/全角の数字のみにする
  const numStr = value.replace(/[^0-9０-９]/g, '');
  // 全角数字を半角数字に変換する
  const halfWidthNumStr = numStr.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
  // 数字の文字列を数値に変換する
  const num = parseInt(halfWidthNumStr, 10);
  // NaNだったらnullを返す
  return !isNaN(num) ? num : null;
};

type Props = {
  className?: string;
  storeId: number;
  isEditable: boolean;
  productGroups: ProductGroups;
  committedProductGroups: ProductGroups;
  changeInventory: (groupIndex: number, productIndex: number, inventory: Inventory) => void;
  addEditedInventory: (inventory: Inventory) => void;
  removeEditedInventory: (inventory: Inventory) => void;
  canUseOnDisplayToOrder: boolean;
  editedInventories: Map<string, Inventory>;
};

type AvailabilityOption = { text: string; value: InventoryAvailability | null; disabled?: boolean };

const getAvailabilityOptions = (
  canUseDisplayToOrder: boolean,
  availability: InventoryAvailability | null,
): AvailabilityOption[] => {
  // 「見本展示のみ」を利用可能な場合のみオプションに追加する
  // ただし、データが既に「見本展示のみ」の場合は選択不可のオプションとして表示する
  const in_stock: AvailabilityOption = {
    text: '在庫あり',
    value: 'in_stock',
  };
  const outOfStock: AvailabilityOption = {
    text: '在庫なし',
    value: 'out_of_stock',
  };
  const onDisplayToOrder: AvailabilityOption = {
    text: '見本展示のみ',
    value: 'on_display_to_order',
    disabled: !canUseDisplayToOrder && availability === 'on_display_to_order',
  };
  const noData: AvailabilityOption = {
    text: '（データなし）',
    value: null,
  };
  return canUseDisplayToOrder || availability === 'on_display_to_order'
    ? [in_stock, outOfStock, onDisplayToOrder, noData]
    : [in_stock, outOfStock, noData];
};

const InventoryTableHeader = React.memo(() => (
  <StyledTableHeader>
    <Table.Row>
      <Table.HeaderCell>
        <ArrowWrapper />
      </Table.HeaderCell>
      <Table.HeaderCell>商品ID</Table.HeaderCell>
      <Table.HeaderCell>画像</Table.HeaderCell>
      <Table.HeaderCell>商品タイトル</Table.HeaderCell>
      <Table.HeaderCell>
        <HeaderCellContent>
          店頭在庫状況
          <ContextHelp content={InventoryHelp.availability} />
        </HeaderCellContent>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <HeaderCellContent>
          店頭在庫数
          <ContextHelp content={InventoryHelp.quantity} />
        </HeaderCellContent>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <HeaderCellContent>
          店頭価格(円)
          <ContextHelp content={InventoryHelp.price} />
        </HeaderCellContent>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <HeaderCellContent>
          セール価格(円)
          <ContextHelp content={InventoryHelp.salePrice} />
        </HeaderCellContent>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <HeaderCellContent>
          セール開始日
          <ContextHelp content={InventoryHelp.salePriceEffectiveDate} />
        </HeaderCellContent>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <HeaderCellContent>
          セール終了日
          <ContextHelp content={InventoryHelp.salePriceEffectiveDate} />
        </HeaderCellContent>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <HeaderCellContent>
          有効期限
          <ContextHelp content={InventoryHelp.expiration} />
        </HeaderCellContent>
      </Table.HeaderCell>
    </Table.Row>
  </StyledTableHeader>
));

type ProductGroupRowsProps = {
  storeId: number;
  groupIndex: number;
  productGroup: ProductGroup;
  committedProductGroups: ProductGroups;
  onChangeInventory: (groupIndex: number, productIndex: number, inventory: Inventory) => void;
  addEditedInventory: (inventory: Inventory) => void;
  removeEditedInventory: (inventory: Inventory) => void;
  isEditable: boolean;
  canUseOnDisplayToOrder: boolean;
  editedInventories: Map<string, Inventory>;
};

const ProductGroupRows = React.memo<ProductGroupRowsProps>(
  ({
    storeId,
    groupIndex,
    productGroup,
    committedProductGroups,
    onChangeInventory,
    addEditedInventory,
    removeEditedInventory,
    isEditable,
    canUseOnDisplayToOrder,
    editedInventories,
  }) => {
    // 商品グループが１つしかなければ初期は開いた状態、それ以外なら初期は閉じた状態
    const [isClosed, setIsClosed] = useState<boolean>(committedProductGroups.items.size !== 1);
    const onClickHeader = useCallback(() => setIsClosed((prevValue) => !prevValue), []);
    return (
      <React.Fragment>
        {productGroup.type === 'group' && (
          <ProductGroupHeader
            isClosed={isClosed}
            onClick={onClickHeader}
            title={productGroup.title}
            imageLink={productGroup.imageLink}
            productGroupCode={productGroup.key}
            productCount={productGroup.items.size}
          />
        )}
        {productGroup.items.map((productItem, productIndex) => {
          const product = productItem.onlineOrLocalProduct;
          if (!product) {
            return null;
          }
          const inventory = productItem.getInventoryByStoreId(storeId);
          const committedProduct = committedProductGroups.getProduct(groupIndex, productIndex);
          const committedInventory = committedProduct.getInventoryByStoreId(storeId);
          return (
            <ProductRow
              key={productItem.offerId}
              product={product}
              inventory={inventory}
              committedInventory={committedInventory}
              storeId={storeId}
              groupIndex={groupIndex}
              productIndex={productIndex}
              isEditable={isEditable}
              isClosed={productGroup.type === 'group' && isClosed}
              isGrouped={productGroup.type === 'group'}
              productItem={productItem}
              committedProduct={committedProduct}
              onChangeInventory={onChangeInventory}
              addEditedInventory={addEditedInventory}
              removeEditedInventory={removeEditedInventory}
              canUseOnDisplayToOrder={canUseOnDisplayToOrder}
              editedInventory={editedInventories.get(inventory.key, inventory)}
            />
          );
        })}
      </React.Fragment>
    );
  },
);

type ProductGroupHeaderProps = {
  isClosed: boolean;
  onClick: () => void;
  title: string | null;
  imageLink: string | null;
  productGroupCode: string;
  productCount: number;
};

const ProductGroupHeader = React.memo<ProductGroupHeaderProps>(
  ({ isClosed, onClick, productGroupCode, title, imageLink, productCount }) => {
    return (
      <GroupHeaderRow onClick={onClick}>
        <StyledTableCell>
          <CellContent>
            <ArrowWrapper>
              <Arrow
                direction={isClosed ? 'right' : 'down'}
                color={COLOR.CHARCOAL_GRAY}
                weight={3}
                transitionDuration='0.2s'
              />
            </ArrowWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <Code>{productGroupCode}</Code>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            {imageLink && (
              <ImageWrapper>
                <Img src={imageLink} />
              </ImageWrapper>
            )}
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <TitleWrapper>
              {title && <Title>{title}</Title>}
              <OtherItems>{productCount > 1 && `他${productCount - 1}商品`}</OtherItems>
            </TitleWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
      </GroupHeaderRow>
    );
  },
);

type ProductRowProps = {
  storeId: number;
  product: GMCProduct;
  inventory: Inventory;
  committedInventory: Inventory;
  groupIndex: number;
  productIndex: number;
  isEditable: boolean;
  isClosed: boolean;
  isGrouped: boolean;
  productItem: Product;
  committedProduct: Product;
  onChangeInventory: (groupIndex: number, productIndex: number, inventory: Inventory) => void;
  addEditedInventory: (inventory: Inventory) => void;
  removeEditedInventory: (inventory: Inventory) => void;
  canUseOnDisplayToOrder: boolean;
  editedInventory: Inventory;
};

const ProductRow = React.memo<ProductRowProps>(
  ({
    product,
    inventory,
    committedInventory,
    groupIndex,
    productIndex,
    isEditable,
    isClosed,
    isGrouped,
    onChangeInventory,
    addEditedInventory,
    removeEditedInventory,
    canUseOnDisplayToOrder,
    editedInventory,
  }) => {
    const isChanged = inventory.expirationDate
      ? !inventory.expirationDate.isSame(committedInventory.expirationDate)
      : false;
    const disabled = !isEditable || (inventory.availability == null && inventory.quantity == null);
    const statusIconType =
      inventory.status === 'expiring_soon'
        ? 'status_warning'
        : inventory.status === 'expired'
          ? 'status_error'
          : 'status_active';

    // 商品に設定された価格（在庫情報の店頭価格とは別物）
    // 在庫状況が「データなし」から変更されたときや、保存時に空のときに入力される
    const productPrice = product?.price?.value ? parseInt(product.price.value, 10) : null;

    /**
     * 在庫情報を更新する
     *
     * @param inventory 在庫情報
     * @param updateExpirationDate 有効期限更新フラグ（入力の途中など有効期限の更新処理をしたくない場合はfalse）
     */
    const changeInventory = useCallback(
      (inventory: Inventory, updateExpirationDate) => {
        let newInventory = inventory;
        // 有効期限の延長フラグがtrueなら有効期限を更新し、変更した在庫情報リストに追加する
        if (updateExpirationDate) {
          newInventory = updateExpirationDate ? inventory.updateExpirationDate() : inventory;
        }
        // 変更があれば変更済み在庫情報のリストに追加、なければ削除
        if (is(committedInventory, newInventory)) {
          removeEditedInventory(newInventory);
        } else {
          let editedInventory = newInventory;
          // 価格が設定されていなければ、登録用データに商品情報の価格を設定する
          if (newInventory.price == null && productPrice != null) {
            editedInventory = editedInventory.changePrice(productPrice);
          }
          addEditedInventory(editedInventory);
        }
        onChangeInventory(groupIndex, productIndex, newInventory);
      },
      [
        committedInventory,
        onChangeInventory,
        groupIndex,
        productIndex,
        removeEditedInventory,
        addEditedInventory,
        productPrice,
      ],
    );

    const onChangeAvailability = useCallback(
      (value: InventoryAvailability) => {
        let newInventory = inventory.changeAvailability(value);
        // 在庫状況が「データなし」から変更されたら、価格に商品価格を設定する
        const price =
          inventory.availability == null && newInventory.availability != null ? productPrice : inventory.price;
        // 選択された状態によって、在庫数と価格を自動で変更する
        if (value === 'out_of_stock') {
          // 「在庫なし」の場合は在庫数「0」
          newInventory = newInventory.merge({
            quantity: 0,
            price,
          });
        } else if (value === 'on_display_to_order') {
          // 「見本展示のみ」の場合は在庫数「1」
          newInventory = newInventory.merge({
            quantity: 1,
            price,
          });
        } else if (value === 'in_stock' && !inventory.quantity) {
          // 「在庫あり」で在庫数未入力の場合は「1」を入れておく
          newInventory = newInventory.merge({
            quantity: 1,
            price,
          });
        } else if (!value) {
          // 在庫状況が空の場合はその他の情報を空にする
          newInventory = newInventory.merge({
            availability: null,
            quantity: null,
            price: null,
            salePrice: null,
            salePriceEffectiveDate: null,
          });
        }
        changeInventory(newInventory, true);
      },
      [changeInventory, inventory, productPrice],
    );

    const onChangeQuantity = useCallback(
      (value: any) => {
        // 数値への変換などはonBlurで行う
        changeInventory(inventory.changeQuantity(value), false);
      },
      [inventory, changeInventory],
    );

    const onBlurQuantity = useCallback(
      (value: string) => {
        const quantity = parseNumber(value);
        const isChanged = committedInventory.quantity !== quantity;
        let newInventory = inventory.changeQuantity(quantity);
        // 在庫状況が「データなし」から変更されたら、価格に商品価格を設定する
        const price = inventory.availability == null && newInventory.quantity != null ? productPrice : inventory.price;
        if (quantity == null) {
          // 在庫数が空の場合はその他の情報を空にする
          newInventory = newInventory.merge({
            availability: null,
            quantity: null,
            price: null,
            salePrice: null,
            salePriceEffectiveDate: null,
          });
        } else if (quantity === 0) {
          // 在庫数が「0」の場合は「在庫なし」
          newInventory = newInventory.merge({
            availability: 'out_of_stock',
            price,
          });
        } else {
          // 在庫数が1以上の場合は「在庫あり」
          newInventory = newInventory.merge({
            availability: 'in_stock',
            price,
          });
        }
        changeInventory(newInventory, isChanged);
      },
      [changeInventory, inventory, committedInventory, productPrice],
    );

    const onChangePrice = useCallback(
      (value: any) => {
        // 数値への変換などはonBlurで行う
        changeInventory(inventory.changePrice(value), false);
      },
      [changeInventory, inventory],
    );

    const onBlurPrice = useCallback(
      (value: string) => {
        const price = parseNumber(value);
        const isChanged = committedInventory.price !== price;
        const newInventory = inventory.changePrice(price);
        changeInventory(newInventory, isChanged);
      },
      [changeInventory, inventory, committedInventory],
    );

    const onChangeSalePrice = useCallback(
      (value: any) => {
        // 数値への変換などはonBlurで行う
        changeInventory(inventory.changeSalePrice(value), false);
      },
      [changeInventory, inventory],
    );

    const onBlurSalePrice = useCallback(
      (value: string) => {
        const salePrice = parseNumber(value);
        const isChanged = committedInventory.salePrice !== salePrice;
        changeInventory(inventory.changeSalePrice(salePrice), isChanged);
      },
      [changeInventory, inventory, committedInventory],
    );

    const onChangeStartDate = useCallback(
      (value) => {
        const startDate = value ? dayjs(value).startOf('day') : null;
        changeInventory(inventory.changeSalePriceStartDate(startDate), true);
      },
      [inventory, changeInventory],
    );

    const onChangeEndDate = useCallback(
      (value) => {
        const endDate = value ? dayjs(value).endOf('day') : null;
        changeInventory(inventory.changeSalePriceEndDate(endDate), true);
      },
      [inventory, changeInventory],
    );

    const onUpdateExpirationDate = useCallback(() => {
      // データはそのまま有効期限を更新する
      changeInventory(inventory, true);
    }, [inventory, changeInventory]);

    const resetInventory = useCallback(() => {
      // 変更された在庫情報を、変更前の在庫情報で上書きし、変更した在庫情報リストから削除する
      changeInventory(committedInventory, false);
      removeEditedInventory(inventory);
    }, [changeInventory, committedInventory, inventory, removeEditedInventory]);

    const quantityText = inventory.quantity?.toLocaleString() ?? '';
    const priceText = inventory.price?.toLocaleString() ?? '';
    const salePriceText = inventory.salePrice?.toLocaleString() ?? '';

    const quantityError = editedInventory.validateQuantity().name;
    const priceError = editedInventory.validatePrice().name;
    const salePriceError = editedInventory.validateSalePrice().name;
    const startDateError = editedInventory.validateStartDate().name;
    const endDateError = editedInventory.validateEndDate().name;

    return (
      <GroupItemRow key={product.offerId} changed={isChanged} closed={isClosed}>
        <StyledTableCell />
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>商品ID</MobileOnlyLabel>
            <Code grouped={isGrouped}>{product.offerId}</Code>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            {product.imageLink && (
              <ImageWrapper>
                <Img src={product.imageLink} />
              </ImageWrapper>
            )}
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>商品タイトル</MobileOnlyLabel>
            <TitleWrapper>{product.title}</TitleWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>店頭在庫状況</MobileOnlyLabel>
            <PullDown
              options={getAvailabilityOptions(canUseOnDisplayToOrder, inventory.availability)}
              value={inventory.availability}
              onChange={onChangeAvailability}
              changed={!is(inventory.availability, committedInventory.availability)}
              disabled={!isEditable}
            />
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>店頭在庫数</MobileOnlyLabel>
            <InputWrapper>
              <StyledInput
                value={quantityText}
                onChange={(value) => onChangeQuantity(extractNumber(value))}
                onBlur={(event) => onBlurQuantity(event.target.value)}
                disabled={!isEditable || inventory.availability === 'on_display_to_order'}
                changed={!is(parseNumber(quantityText), committedInventory.quantity)}
                error={!!quantityError}
              />
            </InputWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>店頭価格(円)</MobileOnlyLabel>
            <InputWrapper>
              <StyledInput
                placeholder={productPrice ? productPrice.toLocaleString() : ''}
                value={priceText}
                onChange={(value) => onChangePrice(extractNumber(value))}
                onBlur={(event) => onBlurPrice(event.target.value)}
                disabled={disabled}
                changed={!is(parseNumber(priceText), committedInventory.price)}
                error={!!priceError}
              />
            </InputWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>セール価格(円)</MobileOnlyLabel>
            <InputWrapper>
              <StyledInput
                value={salePriceText}
                onChange={(value) => onChangeSalePrice(extractNumber(value))}
                onBlur={(event) => onBlurSalePrice(extractNumber(event.target.value))}
                disabled={disabled}
                changed={!is(parseNumber(salePriceText), committedInventory.salePrice)}
                error={!!salePriceError}
              />
            </InputWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>セール開始日</MobileOnlyLabel>
            <InputWrapper>
              <StyledDatePicker
                dateFormat={'yyyy/MM/dd'}
                selected={inventory.salePriceEffectiveDate?.startDate?.toDate()}
                onChange={(value) => onChangeStartDate(value)}
                disabled={disabled}
                isClearable
                changed={
                  !is(inventory.salePriceEffectiveDate?.startDate, committedInventory.salePriceEffectiveDate?.startDate)
                }
                error={!!startDateError}
              />
            </InputWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>セール終了日</MobileOnlyLabel>
            <InputWrapper>
              <StyledDatePicker
                dateFormat={'yyyy/MM/dd'}
                selected={inventory.salePriceEffectiveDate?.endDate?.toDate()}
                onChange={(value) => onChangeEndDate(value)}
                disabled={disabled}
                isClearable
                changed={
                  !is(inventory.salePriceEffectiveDate?.endDate, committedInventory.salePriceEffectiveDate?.endDate)
                }
                error={!!endDateError}
              />
            </InputWrapper>
          </CellContent>
        </StyledTableCell>
        <StyledTableCell>
          <CellContent>
            <MobileOnlyLabel>有効期限</MobileOnlyLabel>
            <ExpirationDateWrapper>
              {inventory.availability != null && (
                <ExpirationDate changed={isChanged}>
                  {inventory.expirationDate?.format('YYYY/MM/DD') ?? ''}
                </ExpirationDate>
              )}
              {inventory.expirationDate && (
                <StatusWrapper>
                  {isChanged || (
                    <Status>
                      <StatusIcon type={statusIconType} />
                      <StatusText>{inventory.daysUntilExpirationText ?? ''}</StatusText>
                    </Status>
                  )}
                  <StatusButton
                    disabled={disabled}
                    onClick={() => (isChanged ? resetInventory() : onUpdateExpirationDate())}
                  >
                    {isChanged ? '変更を取り消す' : '延長'}
                  </StatusButton>
                </StatusWrapper>
              )}
            </ExpirationDateWrapper>
          </CellContent>
        </StyledTableCell>
      </GroupItemRow>
    );
  },
);

export const InventoryTable = React.memo<Props>(
  ({
    className,
    storeId,
    productGroups,
    committedProductGroups,
    changeInventory,
    addEditedInventory,
    removeEditedInventory,
    isEditable,
    canUseOnDisplayToOrder,
    editedInventories,
  }) => {
    const InventoryTableWrapper = useMemo(
      (): React.FC<{ children: React.ReactNode }> =>
        ({ children }) => (
          <StyledTable>
            <InventoryTableHeader />
            <Table.Body>{children}</Table.Body>
          </StyledTable>
        ),
      [],
    );

    return (
      <Wrapper className={className}>
        <InventoryTableWrapper>
          {productGroups.items.map((item, groupIndex) => {
            return (
              <ProductGroupRows
                key={groupIndex}
                storeId={storeId}
                groupIndex={groupIndex}
                productGroup={item}
                committedProductGroups={committedProductGroups}
                onChangeInventory={changeInventory}
                addEditedInventory={addEditedInventory}
                removeEditedInventory={removeEditedInventory}
                isEditable={isEditable}
                canUseOnDisplayToOrder={canUseOnDisplayToOrder}
                editedInventories={editedInventories}
              />
            );
          })}
          <SpacerRow />
        </InventoryTableWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  overflow-x: scroll;
  overflow-y: visible;
  border: 1px solid rgba(34, 36, 38, 0.1);
  background: white;
  border-radius: 0.28571429rem;
`;

const StyledTable = styled(Table)`
  &&& {
    font-size: 13px;
    white-space: nowrap;
    /* separateにしないと、stickyでborderが消える */
    border-collapse: separate;
    border-spacing: 0;
    border: none;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  position: sticky;
  top: 0;
  z-index: 15; /* 検索コンポーネント(20)より下、プルダウン(10)より上に表示するように設定 */
  &&& {
    /* tableのborderをnoneに変更しているので、デフォルトのborderの設定を上書きする */
    background: #f8f8f8;
    th {
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    }
  }
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    &&& {
      /* モバイル表示はテーブルのヘッダを表示しない（セル内に、ラベルを表示させる）*/
      display: none !important;
    }
  }
`;

const HeaderCellContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTableCell = styled(Table.Cell)`
  vertical-align: middle;
  &&& {
    padding: 4px 8px;
    /* tableのborderをnoneに変更しているので、デフォルトのborderの設定を上書きする */
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    border-top: none;
  }
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const GroupHeaderRow = styled(Table.Row)`
  background: #f7f7f7;
  cursor: pointer;
`;

const GroupItemRow = styled(Table.Row)<{ changed: boolean; closed: boolean }>`
  background: ${({ changed }) => (changed ? '#CFF2E680' : COLOR.WHITE)};
  visibility: ${({ closed }) => (closed ? 'collapse' : 'inherit')};
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    visibility: inherit;
  }

  // Safariでは visibility: collapse が hidden と同じになるのグループ内商品が多い場合大きな空白ができてしまう
  // したがってSafariの場合のみ、display: none で非表示にする。ただし、グループ開閉でヘッダの幅が変わってしまうようになる。
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      display: ${({ closed }) => (closed ? 'none' : 'table-row')};
    }
  }
`;

const ImageWrapper = styled.div`
  height: 64px;
`;

const SpacerRow = styled(Table.Row)`
  height: 130px;
`;

const Img = styled.img.attrs(() => ({ width: 64 }))`
  height: 64px;
  width: 64px;
  object-fit: contain;
`;

const ArrowWrapper = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    display: none;
  }
`;

const Code = styled.span<{ grouped?: boolean }>`
  font-weight: ${({ grouped = false }) => (grouped ? 'normal' : 'bold')};
  padding-left: ${({ grouped = false }) => (grouped ? '24' : '0')}px;
`;

const TitleWrapper = styled.div`
  white-space: break-spaces;
  min-width: 200px;
`;

const Title = styled.span`
  font-weight: bold;
`;

const OtherItems = styled.span`
  font-size: 11px;
  margin-left: 8px;
`;

const PullDown = styled(PullDownNarrow)<{ changed: boolean; disabled: boolean }>`
  &&& {
    width: 140px;
    & .ui.search.selection.dropdown {
      border-color: ${({ changed, disabled }) => (changed ? COLOR.GREEN : disabled ? COLOR.LIGHT_GRAY : COLOR.GRAY)};
      padding: 4px 6px 4px 16px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
      font-weight: normal;
    }
    & .ui.search.selection.dropdown > .search {
      min-width: 33px;
      padding: 4px 6px !important;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)<{ changed: boolean; error: boolean }>`
  width: 140px;
  .react-datepicker__input-container > input[type='text'] {
    border-color: ${({ changed, error }) => (error ? COLOR.ERROR : changed ? COLOR.GREEN : COLOR.GRAY)};
    text-align: inherit;
    padding-left: 16px;
    background: ${({ error }) => (error ? '#fff6f6' : COLOR.WHITE)};
    color: ${({ error }) => (error ? COLOR.ERROR : COLOR.BLACK)};
  }
`;

const StyledInput = styled(Input)<{ changed: boolean; error: boolean }>`
  &&& {
    width: 140px;
    & > .ui.input {
      & > input {
        border-color: ${({ changed, error }) => (error ? COLOR.ERROR : changed ? COLOR.GREEN : COLOR.GRAY)};
        height: 33px;
        padding: 0 16px;
        font-size: 13px;
        font-family: monospace !important;
      }
    }

    & > .ui.disabled.input {
      & > input {
        border: 1px solid ${COLOR.LIGHT_GRAY};
      }
    }
  }
`;

const ExpirationDateWrapper = styled.div`
  min-width: 160px;
  font-family: monospace !important;
  display: flex;
  flex-direction: column;
`;

const ExpirationDate = styled.div<{ changed: boolean }>`
  font-size: 13px;
  color: ${({ changed }) => (changed ? COLOR.GREEN : COLOR.CHARCOAL_GRAY)};
  font-weight: normal;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const StatusText = styled.div`
  font-size: 13px;
  color: ${COLOR.CHARCOAL_GRAY};
  font-weight: normal;
`;

const StatusIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 4px;
`;

const StatusButton = styled(Button).attrs(() => ({ priority: 'low' }))`
  &&& {
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    width: auto;
    padding: 0;
    height: 24px;
  }
`;

const MobileOnly = styled.div`
  display: none;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    display: inline-block;
  }
`;

const MobileOnlyLabel = styled(MobileOnly)`
  width: 100px;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  padding: 8px 0;
`;
