import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { CsvDownloadApi, StoreBulkEditApi, StoresApi } from 'ApiClient/StoreApi';
import { hasDiffSearch, replaceWithOrganizationId } from 'helpers/router';
import { Store, Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { StoreSearchCondition } from 'models/Domain/StoreSearchCondition';
import { User } from 'models/Domain/User';
import { BulkEditStoreState } from 'models/Other/BulkEditStoreState';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { JSObject } from 'types/Common';

import { StoreActions } from './actions';

export default function* saga() {
  yield takeLatest(StoreActions.initializeStorePage, initializeStorePage);
  yield takeLatest(StoreActions.getStores, () => getStores());
  yield takeLatest(StoreActions.registerSingleStore, registerSingleStore);
  yield takeLatest(StoreActions.bulkEditStore, bulkEditStore);
  yield takeLatest(StoreActions.downloadStoresCsv, downloadStoresCsv);
  yield takeLatest(StoreActions.setSearchCondition, setSearchCondition);
  yield takeLatest(LOCATION_CHANGE, storeLocationChange);
}

// 店舗管理ページにおけるURL(パラメータ)変更時の処理
function* storeLocationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // パスが店舗管理ページ以外の場合は対象外
  if (location.pathname !== Path.store.index) {
    return;
  }

  // 店舗管理ページの初期化処理が完了していない場合は何もしない
  const isPreparedForStorePage: boolean = yield select((state: State) => state.store.isPreparedForStorePage);
  if (!isPreparedForStorePage) {
    return;
  }

  yield call(updateStoreByLocation);
}

/**
 * URLから店舗の検索条件を更新する
 *
 * 本処理は、以下の２処理から呼ばれる
 * - initializeStorePage
 * - storeLocationChange
 */
function* updateStoreByLocation() {
  const location: Location = yield select((state: State) => state.router.location);
  const storeLists: StoreLists = yield select((state: State) => state.storeList.storeLists);
  // URLパラメータから検索条件を復元する
  let searchCondition = StoreSearchCondition.fromURLSearchParams(location.search);
  const currentUser: User = yield select((state: State) => state.app.currentUser);
  const defaultStoreListId = currentUser.isSvUser || currentUser.isMemberUser ? 'managed_list' : 'all';
  const storeListId = searchCondition.filter.storeListId;
  if (storeListId === null || storeListId === 'managed_list') {
    // storeListIdが空または'managed_list'(ロールによって設定不可)の場合、デフォルト値に上書きする
    searchCondition = searchCondition.setIn(['filter', 'storeListId'], defaultStoreListId);
  } else if (typeof storeListId === 'number') {
    // グループのIDの場合、指定されたIDのグループが存在しないなら、デフォルト値に変更する
    if (!storeLists.findStoreList(storeListId)) {
      searchCondition = searchCondition.setIn(['filter', 'storeListId'], defaultStoreListId);
    }
  }

  // URLと検索条件から生成されるURLが異なる場合、正しいURLにreplace
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // 検索条件のセット
  yield put(StoreActions.setSearchCondition(searchCondition));
}

// 店舗管理ページを初期化する
export function* initializeStorePage() {
  yield put(StoreActions.setIsPreparedForStorePage(true));
  yield call(updateStoreByLocation);
}

export function* getStores() {
  const response: YieldReturn<typeof StoresApi.get> = yield StoresApi.get();
  if (response.isSuccess) {
    yield put(StoreActions.setStores(response.data));
  } else {
    toast({
      type: 'error',
      title: '店舗一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* registerSingleStore(action: ReturnType<typeof StoreActions.registerSingleStore>) {
  yield put(AppActions.setLoading(true));

  const registerStore: Store = yield select((state: State) => state.store.registerStore);

  const response: JSObject = yield StoresApi.post(registerStore.requestParams());

  if (response.isSuccess) {
    yield put(StoreActions.getStores());
    yield put(StoreActions.clearRegisterStore());
    yield put(AppActions.moveTo(Path.store.index));
    toast({
      type: 'success',
      title: '登録が完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: '登録に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* bulkEditStore(action: ReturnType<typeof StoreActions.bulkEditStore>) {
  yield put(AppActions.setLoading(true));

  let bulkEditStoreState: BulkEditStoreState = yield select((state: State) => state.store.bulkEditStoreState);
  const stores: Stores = yield select((state: State) => state.store.stores);
  const response: YieldReturn<typeof StoreBulkEditApi.patch> = yield StoreBulkEditApi.patch(
    bulkEditStoreState.requestParams(stores),
  );

  if (response.isSuccess) {
    const {
      data: { success_store_ids, failure_store_ids },
    } = response;
    bulkEditStoreState = bulkEditStoreState
      .changeBulkEditState('result')
      .changeResultStoreIds(success_store_ids, failure_store_ids);
    yield put(StoreActions.setBulkEditStoreState(bulkEditStoreState));
    yield put(StoreActions.getStores());
  } else {
    toast({
      type: 'error',
      title: '一括操作に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* downloadStoresCsv(action: ReturnType<typeof StoreActions.downloadStoresCsv>) {
  const storeIds: Set<number> = yield select((state: State) => state.store.bulkEditStoreState.selectedStoreIds);

  const response: YieldReturn<typeof CsvDownloadApi.get> = yield CsvDownloadApi.get({
    store_ids: storeIds.toArray(),
  });

  if (response.isSuccess) {
    window.location.assign(response.data.download_url);
    toast({
      type: 'success',
      title: 'CSVファイルをダウンロードしました',
    });
  } else {
    toast({
      type: 'error',
      title: 'CSVファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* setSearchCondition(action: ReturnType<typeof StoreActions.setSearchCondition>) {
  const searchCondition = action.payload;
  const location: Location = yield select((state: RouterRootState) => state.router.location);
  // SearchConditionが変更されて既存とURLに差分がある場合、URLの更新を行う
  const currentPath = `${location.pathname}${location.search}`;
  const path = `${location.pathname}?${searchCondition.toURLSearchParams()}`;
  if (path !== currentPath) {
    yield put(replaceWithOrganizationId(path));
  }
}
