import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import {
  BatchUpdateSummaryParams,
  GetServiceResponse,
  GetServiceSummaryResponse,
  PostServiceParams,
  PutServiceParams,
  Service as ServiceItemData,
} from 'ApiClient/ServiceApi';
import { assertNever } from 'types/Common';

export const MAX_SERVICE_GROUP_NAME_LENGTH = 140 as const;
export const MAX_SERVICE_NAME_LENGTH = 120 as const;
export const MAX_SERVICE_DESCRIPTION_LENGTH = 300 as const;

/** 価格の種類 */
export type PriceType = 'CONSTANT' | 'FREE' | 'NO_PRICE';

/** 価格の種類のラベル */
export const PRICE_TYPE_LABEL = {
  FREE: '無料',
  CONSTANT: '固定',
  NO_PRICE: '設定しない',
} satisfies Record<PriceType, string>;

/** 価格 */
export class Price extends ImmutableRecord<{
  value: number | null;
  type: PriceType;
}>({
  value: null,
  type: 'NO_PRICE',
}) {
  static fromJSON(data: ServiceItemData['price']) {
    return new Price({
      value: data.price_value,
      type: data.price_type,
    });
  }

  /**
   * valueの変更
   * @param value
   */
  changeValue(value: number | null) {
    return this.set('value', value);
  }

  /**
   * typeの変更
   * @param type
   */
  changeType(type: PriceType) {
    // valueが不要なtypeの場合、valueをnullにする
    if (type === 'FREE' || type === 'NO_PRICE') {
      return this.merge({ value: null, type });
    }
    return this.set('type', type);
  }

  /** 有効な値かどうか */
  isValid() {
    switch (this.type) {
      case 'CONSTANT':
        return this.value !== null;
      case 'FREE':
        return this.value == null;
      case 'NO_PRICE':
        return this.value == null;
      default:
        return assertNever(this.type);
    }
  }

  /** 更新用のパラメータ */
  toUpdateParams(): ServiceItemData['price'] {
    return {
      price_value: this.value,
      price_type: this.type,
    };
  }

  /** 価格の表示 */
  get displayValue() {
    switch (this.type) {
      case 'CONSTANT':
        return this.value != null ? '￥' + this.value.toLocaleString() : '';
      case 'FREE':
        return '無料';
      case 'NO_PRICE':
        return '';
      default:
        return assertNever(this.type);
    }
  }
}

type ServiceRecord = {
  id?: number;
  name: string;
  serviceTypeId: string | null;
  description: string | null;
  price: Price;
};

/** サービス */
export class Service extends ImmutableRecord<ServiceRecord>({
  id: undefined,
  name: '',
  serviceTypeId: null,
  description: '',
  price: new Price(),
}) {
  static fromJSON(data: ServiceItemData) {
    return new Service({
      id: data.id || undefined,
      name: data.name,
      serviceTypeId: data.service_type_id,
      description: data.description,
      price: Price.fromJSON(data.price),
    });
  }

  /** ユーザー定義（カスタムサービス）かどうか */
  get isCustom() {
    return this.serviceTypeId == null;
  }

  /**
   * 値を変更する
   * @param key
   * @param value
   */
  changeValue<T extends ServiceRecord, K extends keyof ServiceRecord>(key: K, value: T[K]) {
    return this.set(key, value);
  }

  /** 更新用のパラメータ */
  toUpdateParams(): ServiceItemData {
    return {
      id: this.id ?? undefined,
      name: this.name,
      service_type_id: this.serviceTypeId,
      description: this.description,
      price: this.price.toUpdateParams(),
    };
  }
}

/** サービスのリスト */
export class ServiceList extends ImmutableRecord<{ items: ImmutableList<Service> }>({
  items: ImmutableList(),
}) {
  static fromJSON(data: GetServiceResponse['service_items']) {
    return new ServiceList({
      items: ImmutableList(data.map((item) => Service.fromJSON(item))),
    });
  }

  /** 更新用のパラメータ */
  toUpdateParams(): ServiceItemData[] {
    return this.items.map((service) => service.toUpdateParams()).toArray();
  }

  /**
   * アイテムを追加する
   * @param items
   */
  concatItems(items: ImmutableList<Service>) {
    return this.update('items', (prevItems) => prevItems.concat(items));
  }

  /**
   * アイテムを削除する
   * @param index
   */
  removeItem(index: number) {
    return this.deleteIn(['items', index]);
  }

  /**
   * アイテムを更新する
   * @param index
   * @param service
   */
  updateItem(index: number, service: Service) {
    return this.setIn(['items', index], service);
  }

  /**
   * アイテムを指定したindexに移動できるか
   * @param fromIndex
   * @param toIndex
   */
  canMoveItem(fromIndex: number, toIndex: number) {
    const item = this.items.get(fromIndex);
    // listの範囲外のindexには移動できない
    if (!item || toIndex < 0 || toIndex >= this.items.size) {
      return false;
    }
    // カスタムサービスでなければ移動できない
    if (!item.isCustom) {
      return false;
    }
    // カスタムサービスの開始indexを取得
    const customStartIndex = this.items.findIndex((service) => service.isCustom);
    // カスタムサービスであり、カスタムサービスの開始indexより後に移動しようとした場合のみ移動できる
    return item.isCustom && customStartIndex <= toIndex;
  }

  /**
   * アイテムを指定したindexに移動する
   * @param fromIndex
   * @param toIndex
   */
  moveItem(fromIndex: number, toIndex: number) {
    return this.update('items', (items) => {
      // 移動可能なら移動する
      const item = this.items.get(fromIndex);
      if (item && this.canMoveItem(fromIndex, toIndex)) {
        return items.delete(fromIndex).insert(toIndex, item);
      } else {
        return items;
      }
    });
  }

  /**
   * サービスIDでサービスを取得する
   * @param serviceTypeId
   */
  findByServiceTypeId(serviceTypeId: string) {
    return this.items.find((service) => service.serviceTypeId === serviceTypeId);
  }

  /**
   * サービス名でサービスを取得する
   * @param name
   */
  findByName(name: string) {
    return this.items.find((service) => service.name === name);
  }

  /**
   * プリセットのサービスを除外する
   */
  removeStructuredService() {
    return this.update('items', (items) => items.filter((service) => service.isCustom));
  }

  get structuredServices() {
    return this.items.filter((service) => !service.isCustom);
  }

  get customServices() {
    return this.items.filter((service) => service.isCustom);
  }
}

/** サービスグループ */
export class ServiceGroup extends ImmutableRecord<{
  id?: number;
  storeIds: ImmutableList<number> | null;
  groupIds: ImmutableList<number> | null;
  name: string;
  categoryId: string;
  applyToGbp: boolean;
  services: ServiceList;
  createAt: Dayjs | null;
  updateAt: Dayjs | null;
}>({
  id: undefined,
  storeIds: null,
  groupIds: null,
  name: '',
  categoryId: '',
  applyToGbp: true,
  services: new ServiceList(),
  createAt: null,
  updateAt: null,
}) {
  static fromJSON(data: GetServiceResponse) {
    return new ServiceGroup({
      id: data.id,
      storeIds: data.store_ids ? ImmutableList(data.store_ids) : null,
      groupIds: data.group_ids ? ImmutableList(data.group_ids) : null,
      name: data.name,
      categoryId: data.category_id,
      applyToGbp: data.apply_to_gbp,
      services: ServiceList.fromJSON(data.service_items),
      createAt: data.create_at ? dayjs(data.create_at) : null,
      updateAt: data.update_at ? dayjs(data.update_at) : null,
    });
  }

  // 複製する（idはすべてundefinedにする）
  clone() {
    return new ServiceGroup({
      id: undefined,
      storeIds: this.storeIds,
      groupIds: this.groupIds,
      name: this.name,
      categoryId: this.categoryId,
      applyToGbp: this.applyToGbp,
      services: this.services.update('items', (items) => items.map((service) => service.set('id', undefined))),
      createAt: null,
      updateAt: null,
    });
  }

  /** 新規作成用のパラメータ */
  toCreateParams(): PostServiceParams {
    return {
      store_ids: this.storeIds?.toArray() ?? null,
      group_ids: this.groupIds?.toArray() ?? null,
      name: this.name,
      category_id: this.categoryId,
      apply_to_gbp: this.applyToGbp,
      service_items: this.services.toUpdateParams(),
    };
  }

  /** 更新用のパラメータ */
  toUpdateParams(): PutServiceParams {
    return {
      id: this.id ?? null,
      store_ids: this.storeIds?.toArray() ?? null,
      group_ids: this.groupIds?.toArray() ?? null,
      name: this.name,
      category_id: this.categoryId,
      apply_to_gbp: this.applyToGbp,
      service_items: this.services.toUpdateParams(),
    };
  }
}

/** サービス一覧のアイテム */
export class ServiceSummaryItem extends ImmutableRecord<{
  id: number;
  storeIds: ImmutableList<number> | null;
  groupIds: ImmutableList<number> | null;
  name: string;
  categoryId: string;
  applyToGbp: boolean;
  syncedToGbp: boolean | null;
  isDeleted: boolean;
  createAt: Dayjs | null;
  updateAt: Dayjs | null;
}>({
  id: 0,
  storeIds: null,
  groupIds: null,
  name: '',
  categoryId: '',
  applyToGbp: true,
  syncedToGbp: null,
  isDeleted: false,
  createAt: null,
  updateAt: null,
}) {
  static fromJSON(data: GetServiceSummaryResponse['items'][number]) {
    return new ServiceSummaryItem({
      id: data.id,
      storeIds: data.store_ids ? ImmutableList(data.store_ids) : null,
      groupIds: data.group_ids ? ImmutableList(data.group_ids) : null,
      name: data.name,
      categoryId: data.category_id,
      applyToGbp: data.apply_to_gbp,
      syncedToGbp: data.synced_to_gbp,
      isDeleted: data.is_deleted,
      createAt: data.create_at ? dayjs(data.create_at) : null,
      updateAt: data.update_at ? dayjs(data.update_at) : null,
    });
  }

  /** 更新用のパラメータ */
  toUpdateParams(): BatchUpdateSummaryParams['items'][number] {
    return {
      id: this.id,
      store_ids: this.storeIds?.toArray() ?? null,
      group_ids: this.groupIds?.toArray() ?? null,
      name: this.name,
      category_id: this.categoryId,
      apply_to_gbp: this.applyToGbp,
    };
  }
}

/** サービス一覧 */
export class ServiceSummary extends ImmutableRecord<{ items: ImmutableList<ServiceSummaryItem> }>({
  items: ImmutableList(),
}) {
  static fromJSON(data: GetServiceSummaryResponse) {
    return new ServiceSummary({
      items: ImmutableList(data.items.map((item) => ServiceSummaryItem.fromJSON(item))),
    });
  }

  /** アイテム数 */
  get size() {
    return this.items.size;
  }

  /** GBPに反映されてないアイテムのリスト */
  get itemsNotSyncedToGbp() {
    return this.items.filter((item) => item.syncedToGbp === false);
  }

  /** 削除済みでないアイテムのリスト */
  get itemsNotDeleted() {
    return this.items.filter((item) => item.isDeleted === false);
  }

  /** 削除済みでないアイテムのみにフィルタ */
  get filterNotDeleted() {
    return this.update('items', (items) => items.filter((item) => !item.isDeleted));
  }

  /**
   * GBP連携の有効/無効を切り替える
   * @param index
   */
  toggleApplyToGbp(index: number) {
    return this.updateIn(['items', index, 'applyToGbp'], (value) => !value);
  }

  /**
   * アイテムを削除する
   * @param index
   */
  removeItem(index: number) {
    return this.deleteIn(['items', index]);
  }

  /**
   * アイテムを指定したindexに移動する
   * @param fromIndex
   * @param toIndex
   */
  moveItem(fromIndex: number, toIndex: number) {
    return this.update('items', (items) => {
      const item = items.get(fromIndex);
      if (!item || toIndex < 0 || toIndex >= items.size) {
        return items;
      }
      return items.delete(fromIndex).insert(toIndex, item);
    });
  }

  /** 更新用のパラメータ */
  toUpdateParams(): BatchUpdateSummaryParams {
    return {
      items: this.items.map((item) => item.toUpdateParams()).toArray(),
    };
  }
}
