import React, { useCallback } from 'react';

import { DimmerDimmable } from 'semantic-ui-react';
import styled from 'styled-components';

import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import { Loader } from 'components/atoms/Loader';
import { PullDown } from 'components/atoms/PullDown';
import { TextArea } from 'components/atoms/TextArea';
import { TranslationLanguage } from 'models/Domain/Organization';
import {
  BODY_MAX_LENGTH,
  CallToAction,
  PromotionActionTypeKey,
  TITLE_MAX_LENGTH,
} from 'models/Domain/Promotion/Promotion';
import {
  PromotionGroup,
  PromotionTranslationData,
  PromotionTranslationDataType,
} from 'models/Domain/Promotion/PromotionGroup';
import { Stores } from 'models/Domain/Store';

import { BODY_LABELS, TITLE_LABELS, selectButtonOptions } from '../PromotionEditForm';

import { PromotionFormErrorMessages, PromotionFormWarningMessages } from './PromotionFormMessages';

type Props = {
  language: TranslationLanguage;
  translationPost: PromotionTranslationData;
  isLoading: boolean;
  onChangePromotionGroup: (promotionGroup: PromotionGroup) => void;
  showErrorMessage: boolean;
  stores: Stores;
  promotionGroup: PromotionGroup;
};

export const PromotionFormTranslationItem: React.FC<Props> = ({
  language,
  translationPost,
  isLoading,
  onChangePromotionGroup,
  showErrorMessage,
  stores,
  promotionGroup,
}) => {
  const { promotion } = promotionGroup;
  const { title, body, call_to_action } = translationPost;
  const topicType = promotion.topic_type;
  const titleLabel = TITLE_LABELS[topicType];
  const bodyLabel = BODY_LABELS[topicType];

  const aliasWarning = translationPost.getWarningMessages(stores.list);
  const aliasError = translationPost.getErrorMessages(stores.list);

  const handleOnChange = useCallback(
    <T extends PromotionTranslationDataType, K extends keyof PromotionTranslationDataType>(key: K, value: T[K]) => {
      const childPost = translationPost.changeValue(key, value);
      onChangePromotionGroup(promotionGroup.setChild(language, childPost));
    },
    [language, onChangePromotionGroup, promotionGroup, translationPost],
  );

  const handleChangeTitle = useCallback(
    (value: string) => {
      handleOnChange('title', value);
    },
    [handleOnChange],
  );

  const handleChangeBody = useCallback(
    (value: string) => {
      handleOnChange('body', value);
    },
    [handleOnChange],
  );

  const handleChangeCallToActionType = useCallback(
    (value: PromotionActionTypeKey) => {
      const newCallToAction =
        call_to_action?.changeValue('action_type', value) || new CallToAction({ action_type: value });
      handleOnChange('call_to_action', newCallToAction);
    },
    [call_to_action, handleOnChange],
  );

  const handleChangeCallToActionUrl = useCallback(
    (value: string) => {
      const newCallToAction = call_to_action?.changeValue('url', value) || null;
      handleOnChange('call_to_action', newCallToAction);
    },
    [call_to_action, handleOnChange],
  );

  // コンテンツポリシーに反する可能性のあるキーワードを抽出
  const contentsWarnings = translationPost.getContentsPolicyWarningMessages();

  return (
    <Wrapper>
      {titleLabel && (
        <FormContent name={titleLabel} required={topicType === 'EVENT' || topicType === 'OFFER'}>
          <DimmerDimmable>
            <StyledInput
              value={title ?? ''}
              disabled={isLoading}
              onChange={handleChangeTitle}
              error={showErrorMessage && translationPost.validateTitle(topicType).error}
            />
            <Loader active={isLoading} />
          </DimmerDimmable>
          <StyledCounter size={title?.length ?? 0} maxSize={TITLE_MAX_LENGTH} />
        </FormContent>
      )}
      {bodyLabel && (
        <FormContent name={bodyLabel} required={topicType === 'STANDARD'}>
          <DimmerDimmable>
            <StyledTextArea
              value={body ?? ''}
              onChange={handleChangeBody}
              disabled={isLoading}
              error={showErrorMessage && translationPost.validateBody(topicType).error}
            />
            <Loader active={isLoading} />
          </DimmerDimmable>
          <StyledCounter size={body?.length ?? 0} maxSize={BODY_MAX_LENGTH} />
          <PromotionFormWarningMessages messages={[...aliasWarning.body, ...contentsWarnings]} />
        </FormContent>
      )}
      {promotion.canUseCallToAction && (
        <FormContent name={'ボタンの設定'}>
          <StyledPullDown
            value={call_to_action?.action_type ?? 'ACTION_TYPE_UNSPECIFIED'}
            options={selectButtonOptions}
            onChange={(value) => handleChangeCallToActionType(value)}
            disabled={isLoading}
          />
        </FormContent>
      )}
      {call_to_action?.canInputUrl && (
        <FormContent name={'URL'} required>
          <StyledInput
            value={call_to_action?.url ?? ''}
            onChange={handleChangeCallToActionUrl}
            disabled={isLoading}
            error={showErrorMessage && translationPost.validateCallToAction().error}
          />
          <Loader active={isLoading} />
          <PromotionFormErrorMessages messages={aliasError.url} />
          <PromotionFormWarningMessages messages={aliasWarning.url} />
        </FormContent>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const StyledInput = styled(Input)``;
const StyledCounter = styled(Counter)`
  &&& {
    width: 100%;
    text-align: right;
  }
`;
const StyledPullDown = styled(PullDown)``;
const StyledTextArea = styled(TextArea)``;
