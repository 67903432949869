import React, { useCallback, useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { Paging } from 'components/molecules/Paging';
import { MapSearchRankConfigSearchCondition } from 'components/pageComponents/MapSearchRankConfig/MapSearchRankConfigSearchCondition';
import { MapSearchRankConfigTable } from 'components/pageComponents/MapSearchRankConfig/MapSearchRankConfigTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { MapSearchRankConfigSearchCondition as SearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankConfigSearchCondition';
import { MapSearchRankConfigActions } from 'modules/mapSearchRank/config/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const MapSearchRankConfig = React.memo(() => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.app);
  const { searchCondition, isInitializedSearchCondition, data, isLoading, isProcessingCsvDownload } = useSelector(
    (state) => state.mapSearchRankConfig,
  );
  const { stores } = useSelector((state) => state.store);
  const { commitSearchCondition, initializePage, startCsvDownload } = useMemo(
    () => bindActionCreators(MapSearchRankConfigActions, dispatch),
    [dispatch],
  );

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const handleOnCommitSearchCondition = useCallback(
    (condition: SearchCondition, pageReset = true) => {
      const newCondition = pageReset ? condition.setIn(['pagination', 'page'], 1) : condition;
      commitSearchCondition(newCondition);
    },
    [commitSearchCondition],
  );

  const handleOnChangePage = useCallback(
    (page: number) => {
      const newSearchCondition = searchCondition.setIn(['pagination', 'page'], page);
      handleOnCommitSearchCondition(newSearchCondition, false);
    },
    [handleOnCommitSearchCondition, searchCondition],
  );

  const mapSearchRankConfigLimit = currentUser.organization?.mapSearchRankConfigLimit;
  return (
    <>
      <MainWrapper>
        <Helmet title={getPageTitle('検索順位監視設定')} />
        <StickyHeader>
          <Title>検索順位監視設定</Title>
          <Link to={Path.mapSearchRank.import}>
            <StyledButton>まとめて更新</StyledButton>
          </Link>
        </StickyHeader>
        <WideBody>
          <MapSearchRankConfigSearchCondition
            // URLから検索条件の初期化が完了してから検索条件を刷新するようにkeyを切り替える
            key={isInitializedSearchCondition ? 1 : 0}
            isCommitDisabled={isLoading}
            committedSearchCondition={searchCondition}
            commitSearchCondition={handleOnCommitSearchCondition}
            isProcessingCsvDownload={isProcessingCsvDownload}
            startCsvDownload={startCsvDownload}
          />
          <InfoContainer>
            <Label>{data.pagination.total_count}件の検索結果</Label>
            {mapSearchRankConfigLimit !== undefined && (
              <>
                <Label style={{ marginLeft: 'auto' }}>
                  設定上限数： {mapSearchRankConfigLimit}件
                  <ContextHelp
                    header='設定上限数とは？'
                    content='有効化されている設定のみが対象。設定上限数を増やしたい場合はサポートへご相談ください'
                  ></ContextHelp>
                </Label>
              </>
            )}
          </InfoContainer>

          <TableWrapper>
            <MapSearchRankConfigTable
              configs={data.items}
              stores={stores}
              containsStatus={searchCondition.filter.containsDisabled}
            />
            {data.items.isEmpty() && <Notification>指定した条件の検索順位監視設定はありません</Notification>}
            {isLoading && (
              <>
                <LoadingWrapperBase />
                <LoadingWrapper>
                  <Loader active={true} size='big' inline={true} />
                </LoadingWrapper>
              </>
            )}
          </TableWrapper>
          <Paging
            currentPage={data.pagination.current_page}
            viewContentSize={data.pagination.limit}
            totalContentSize={data.pagination.total_count}
            viewMaxPage={5}
            fixedPage={1}
            onChangeNo={handleOnChangePage}
          />
        </WideBody>
      </MainWrapper>
    </>
  );
});

const TableWrapper = styled.div`
  position: relative;
  margin-bottom: 32px;
`;

const Notification = styled.div`
  text-align: center;
  background: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top-width: 0;

  min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;

  color: ${COLOR.GRAY};
  font-size: 1.2em;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: #f8f8f8;
  mix-blend-mode: hard-light;
`;

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    font-size: 14px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
`;

const Label = styled.div`
  color: #757575;
  margin-bottom: 10px;
`;
