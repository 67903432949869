import { List as ImmutableList, Map as ImmutableMap, Record as ImmutableRecord } from 'immutable';

import { validateWebsiteUrl } from 'helpers/utils';

import { TranslationLanguage } from '../Organization';
import { Store } from '../Store';

import Promotion, {
  AliasLinkType,
  AvailableAliases,
  CallToAction,
  PostStatus,
  PromotionTopicType,
  checkBodyContentsPolicy,
  getAnnotatedBody,
  removeAll,
} from './Promotion';

export type PostStatusType = 'LIVE' | 'PROCESSING' | 'REJECTED' | 'SCHEDULED' | 'DRAFT' | 'UNSPECIFIED';
export const POST_STATUS_LABELS: { [key in PostStatusType]: string } = {
  LIVE: '公開済み',
  PROCESSING: '投稿中',
  REJECTED: '公開拒否',
  SCHEDULED: '予約投稿',
  DRAFT: '下書き',
  UNSPECIFIED: 'ー',
};
export const TITLE_MAX_LENGTH = 58;
export const BODY_MAX_LENGTH = 1500;

export type PostStatusSummary = {
  storeId: number;
  status: PostStatusType;
  livePostCount: number;
  totalPostCount: number;
  postStatuses: ImmutableList<{
    language: string;
    status: PostStatusType;
  }>;
};

export type PromotionTranslationDataType = {
  id: string | null;
  language: TranslationLanguage | null;
  title: string;
  body: string;
  call_to_action: CallToAction | null;
};

export class PromotionTranslationData extends ImmutableRecord<PromotionTranslationDataType>({
  id: null,
  language: null,
  title: '',
  body: '',
  call_to_action: null,
}) {
  static fromPromotionJSON(data: any) {
    return new PromotionTranslationData({
      id: data._id,
      language: (data.translations?.language as TranslationLanguage) || null,
      title: data.event?.title || '',
      body: data.body || '',
      call_to_action: data.call_to_action ? new CallToAction(data.call_to_action) : null,
    });
  }

  /**
   * bodyのtype(Alias, Text, PhoneNumber, NGWord)の注釈付きデータを返す
   */
  getAnnotatedBody(stores: ImmutableList<Store>) {
    // コンテンツポリシーに反する可能性のあるワード
    const contentsWarnings = checkBodyContentsPolicy(this.body);
    return getAnnotatedBody(this.body, stores, contentsWarnings);
  }

  generateAliasLabel(store: Store) {
    const targetBody = this.getAnnotatedBody(ImmutableList([store]));
    return targetBody.map((data) => {
      if (data.type == 'Alias') {
        if (data.text === '{{店名}}') {
          return { type: data.type, text: store.name };
        } else if (data.text === '{{支店名}}') {
          return { type: data.type, text: store.branch };
        } else if (data.text === '{{予約リンク}}') {
          // 予約リンクが複数設定されている場合は１つ目のURLを表示する
          const appointmentUrls = store.location.urlAttributes.find('url_appointment')?.urlValues;
          const firstAppointmentUrl = appointmentUrls?.getIn([0, 'url']) ?? '';
          return { type: data.type, text: firstAppointmentUrl };
        } else if (data.text === '{{ウェブサイト}}') {
          return { type: data.type, text: store.location.websiteUrl };
        }
      }
      return data;
    });
  }

  getContentsPolicyWarningMessages() {
    const checkResults = checkBodyContentsPolicy(this.body);

    const warnings: string[] = [];

    const phoneNumbers = Object.entries(checkResults)
      .filter(([text, type]) => type === 'PhoneNumber')
      .map(([text, type]) => text);
    if (phoneNumbers.length > 0) {
      warnings.push(
        `電話番号 ${phoneNumbers
          .map((t) => `「${t}」`)
          .join('、')} が含まれています。投稿コンテンツに電話番号を含めることは許可されていません。`,
      );
    }

    const ngWords = Object.entries(checkResults)
      .filter(([text, type]) => type === 'NGWord')
      .map(([text, type]) => text);
    if (ngWords.length > 0) {
      warnings.push(`${ngWords.map((t) => `「${t}」`).join('、')} を含む投稿は非公開になる可能性があります。`);
    }

    return warnings;
  }

  changeValue<T extends PromotionTranslationDataType, K extends keyof PromotionTranslationDataType>(
    key: K,
    value: T[K],
  ) {
    return this.set(key, value);
  }

  isValid(topicType: PromotionTopicType) {
    const titleValidation = this.validateTitle(topicType);
    const bodyValidation = this.validateBody(topicType);
    const callToActionValidation = this.validateCallToAction();
    return titleValidation.isValid && bodyValidation.isValid && callToActionValidation.isValid;
  }

  validateTitle(topicType: PromotionTopicType) {
    if (topicType === 'EVENT' || topicType === 'OFFER') {
      if (this.title == null || this.title.length === 0) {
        return { isValid: false, error: '「タイトル」は必須です' };
      } else if (this.title.length > TITLE_MAX_LENGTH) {
        return { isValid: false, error: `「タイトル」は${TITLE_MAX_LENGTH}文字以内にしてください` };
      }
    }
    return { isValid: true };
  }

  validateBody(topicType: PromotionTopicType) {
    if (topicType === 'STANDARD' && this.body.length === 0) {
      return { isValid: false, error: '「本文」は必須です' };
    }
    if (this.body.length > BODY_MAX_LENGTH) {
      return { isValid: false, error: `「本文」は${BODY_MAX_LENGTH}文字以内にしてください` };
    }
    return { isValid: true };
  }

  validateCallToAction() {
    if (
      this.call_to_action == null ||
      this.call_to_action.action_type == 'ACTION_TYPE_UNSPECIFIED' ||
      this.call_to_action.action_type == 'CALL'
    ) {
      return { isValid: true };
    }
    const url = this.call_to_action.url;
    if (validateWebsiteUrl(url)) {
      // AliasLinkTypeは単体で有効なURLなので、httpから始まるURLで使われているとエラー
      if (AliasLinkType.some((alias) => url.includes(alias))) {
        return { isValid: false, error: '有効なリンクを入力してください' };
      }
    } else {
      // AliasLinkTypeから始まる場合はエラーにはならない
      // パラメータなどを付与するために、差し込み変数の後の文字列は許容する
      if (!AliasLinkType.some((alias) => url.startsWith(alias))) {
        return { isValid: false, error: '有効なリンクを入力してください' };
      }
    }
    return { isValid: true };
  }

  /**
   * 選択されている店舗で利用されているエイリアスが存在しない場合の警告メッセージを生成する
   */
  getWarningMessages(stores: ImmutableList<Store>) {
    const bodyWarnings = [];
    const bodyAvailableAliases = Promotion.getAvailableAliasTypes('body', stores);

    // 利用できないエイリアスが含まれていないか
    const aliasRemovedBody = removeAll(this.body, bodyAvailableAliases);
    if (aliasRemovedBody.includes('{{') || aliasRemovedBody.includes('}}')) {
      bodyWarnings.push(`利用可能な差し込み変数は、${bodyAvailableAliases.join('、')} です。`);
    }

    const bodyAliases = bodyAvailableAliases.filter((alias) => this.body.includes(alias));
    if (bodyAliases.includes('{{支店名}}')) {
      const warningStores = stores.filter((s) => !s.branch);
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{支店名}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size === 1 ? 'に' : `など${warningStores.size}店舗で`
          }支店名が設定されていません。支店名が設定されていない店舗に関しては、{{支店名}} に値は入力されません。`,
        );
      }
    }

    if (bodyAliases.includes('{{予約リンク}}')) {
      const warningStores = stores.filter((s) => !s.location.urlAttributes.find('url_appointment')?.hasValue());
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{予約リンク}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }予約リンクが設定されていません。予約リンクが設定されていない店舗に関しては、{{予約リンク}} に値は入力されません。`,
        );
      }
    }

    if (bodyAliases.includes('{{予約リンク}}')) {
      const warningStores = stores.filter((s) => {
        const urlAppointmentSize = s.location.urlAttributes.find('url_appointment')?.urlValues?.size;
        return urlAppointmentSize && urlAppointmentSize > 1;
      });
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{予約リンク}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }予約リンクが複数設定されています。予約リンクが複数設定されている店舗に関しては、１つ目に設定した予約リンクが入力されます。`,
        );
      }
    }

    if (bodyAliases.includes('{{ウェブサイト}}')) {
      const warningStores = stores.filter((s) => !s.location.websiteUrl);
      if (warningStores.size > 0) {
        bodyWarnings.push(
          `{{ウェブサイト}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }ウェブサイトが設定されていません。ウェブサイトが設定されていない店舗に関しては、{{ウェブサイト}} に値は入力されません。`,
        );
      }
    }

    const urlWarnings: string[] = [];

    const url = this.call_to_action?.url ?? '';
    const urlAvailableAliases = Promotion.getAvailableAliasTypes('url', stores);

    // 利用できないエイリアスが含まれていないか
    // bodyから利用可能なエイリアスを削除
    const aliasRemovedText = removeAll(url, urlAvailableAliases);
    if (aliasRemovedText.includes('{{') || aliasRemovedText.includes('}}')) {
      urlWarnings.push(`利用可能な差し込み変数は、${urlAvailableAliases.join('、')} です。`);
    }

    const urlAliases = urlAvailableAliases.filter((alias) => url.includes(alias));
    if (urlAliases.includes('{{店舗コード}}')) {
      const warningStores = stores.filter((s) => !s.code);
      if (warningStores.size > 0) {
        urlWarnings.push(
          `{{店舗コード}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }店舗コードが設定されていません。店舗コードが設定されていない店舗に関しては、{{店舗コード}} に値は入力されません。`,
        );
      }
    }

    if (urlAliases.includes('{{予約リンク}}')) {
      const warningStores = stores.filter((s) => {
        const urlAppointmentSize = s.location.urlAttributes.find('url_appointment')?.urlValues?.size;
        return urlAppointmentSize && urlAppointmentSize > 1;
      });
      if (warningStores.size > 0) {
        urlWarnings.push(
          `{{予約リンク}} が含まれていますが、「${warningStores.get(0)?.fullName}」${
            warningStores.size == 1 ? 'に' : `など${warningStores.size}店舗で`
          }予約リンクが複数設定されています。予約リンクが複数設定されている店舗に関しては、１つ目に設定した予約リンクが入力されます。`,
        );
      }
    }

    return { body: bodyWarnings, url: urlWarnings };
  }

  getErrorMessages(stores: ImmutableList<Store>) {
    const urlErrors: string[] = [];

    const url = this.call_to_action?.url ?? '';

    const urlAliases = AvailableAliases.url.filter((alias) => url.includes(alias));

    if (urlAliases.includes('{{予約リンク}}')) {
      const errorStores = stores.filter((s) => !s.location.urlAttributes.find('url_appointment')?.hasValue());
      if (errorStores.size > 0) {
        urlErrors.push(
          `{{予約リンク}} が含まれていますが、「${errorStores.get(0)?.fullName}」${
            errorStores.size == 1 ? 'に' : `など${errorStores.size}店舗で`
          }予約リンクが設定されていません。投稿先のすべての店舗に予約リンクの設定が必要です。`,
        );
      }
    }

    if (urlAliases.includes('{{ウェブサイト}}')) {
      const errorStores = stores.filter((s) => s.location.websiteUrl === '');
      if (errorStores.size > 0) {
        urlErrors.push(
          `{{ウェブサイト}} が含まれていますが、「${errorStores.get(0)?.fullName}」${
            errorStores.size == 1 ? 'に' : `など${errorStores.size}店舗で`
          }ウェブサイトが設定されていません。投稿先のすべての店舗にウェブサイトの設定が必要です。`,
        );
      }
    }
    return { url: urlErrors };
  }

  updateParams() {
    return {
      _id: this.id ?? undefined,
      language: this.language,
      title: this.title ?? undefined,
      body: this.body ?? '',
      call_to_action: this.call_to_action?.requestParams() ?? undefined,
    };
  }
}

export class PostStatuses extends ImmutableRecord<{
  list: ImmutableList<PostStatus>;
  isInitialized: boolean;
}>({
  list: ImmutableList(),
  isInitialized: false,
}) {
  static fromPostStatusList(list: PostStatus[]) {
    return new PostStatuses({ list: ImmutableList(list), isInitialized: true });
  }
}

export class PromotionGroup extends ImmutableRecord<{
  promotion: Promotion;
  children: ImmutableList<PromotionTranslationData>;
  statuses: PostStatuses;
}>({
  promotion: new Promotion(),
  children: ImmutableList(),
  statuses: new PostStatuses(),
}) {
  /**
   * 親投稿、子投稿の投稿IDを取得する
   */
  get postIds() {
    return ImmutableList([this.promotion._id])
      .concat(this.children.map((child) => child.id))
      .filter((id) => id) as ImmutableList<string>;
  }

  /**
   * 子投稿の言語一覧を取得する
   */
  get languages() {
    return this.children
      .map((child) => child.language)
      .map((language) => language) as ImmutableList<TranslationLanguage>;
  }

  /**
   * 子投稿データを取得する
   * @param language
   * @returns
   */
  findChild(language: string) {
    return this.children.find((child) => child.language === language);
  }

  /**
   * 子投稿データを追加する
   * @param data
   * @returns
   */
  addChild(data: PromotionTranslationData) {
    return (
      this.set('children', this.children.push(data))
        // 現状は並び順固定なので、追加されたらソートする
        .sortChildren()
    );
  }

  /**
   * 子投稿データを削除する
   * @param language
   * @returns
   */
  deleteChild(language: TranslationLanguage) {
    // 指定された言語に一致する子投稿を除外する
    return this.set(
      'children',
      this.children.filterNot((child) => child.language === language),
    );
  }

  /**
   * 指定した言語に子投稿のデータを設定する
   * @param language
   * @param data
   */
  setChild(language: TranslationLanguage, data: PromotionTranslationData) {
    return this.set(
      'children',
      this.children.map((child) => {
        // 指定の言語と一致する子投稿の値を変更する
        if (child.language === language) {
          return data;
        }
        return child;
      }),
    );
  }

  /**
   * 子投稿データをソートする
   * @param languages 言語の優先順位
   * @returns
   */
  sortChildren(languages: string[] = ['en', 'zh-CN', 'ko']) {
    return this.set(
      'children',
      this.children.sort((a, b) => {
        let aIndex: number = -1;
        if (a.language !== null) {
          aIndex = languages.indexOf(a.language);
        }
        if (aIndex === -1) {
          aIndex = languages.length;
        }

        let bIndex: number = -1;
        if (b.language !== null) {
          bIndex = languages.indexOf(b.language);
        }
        if (bIndex === -1) {
          bIndex = languages.length;
        }

        return aIndex - bIndex;
      }),
    );
  }

  /**
   * 子投稿の投稿ステータスを設定する
   * @param statuses
   * @returns
   */
  setStatuses(statuses: PostStatuses) {
    return this.set('statuses', statuses);
  }

  /**
   * 子投稿が存在するか
   */
  get hasChildren() {
    return this.children.size > 0;
  }

  /**
   * 投稿ステータスを店舗ごとに集計を取得する
   */
  getPostStatusSummaries(): ImmutableList<PostStatusSummary> {
    // 親投稿に指定されている店舗IDのリスト
    const storeIds = this.promotion.stores.map((store) => store.store_id);

    // 投稿IDの一覧を取得
    const postIds = this.postIds;

    const postLanguages = ImmutableMap(
      postIds.map((postId) => {
        const child = this.children.find((child) => child.id === postId);
        return [postId, child?.language];
      }),
    );

    if (this.promotion.isScheduled) {
      // 予約投稿の場合は、すべてステータスSCHEDULEDにする
      return storeIds.map((storeId) => ({
        storeId,
        status: 'SCHEDULED',
        livePostCount: 0,
        totalPostCount: postIds.size,
        postStatuses: postIds.map((postId) => ({
          language: postLanguages.get(postId) || '',
          status: 'SCHEDULED',
        })),
      }));
    } else if (this.promotion.isDraft) {
      // 予約投稿の場合は、すべてステータスSCHEDULEDにする
      return storeIds.map((storeId) => ({
        storeId,
        status: 'DRAFT',
        livePostCount: 0,
        totalPostCount: postIds.size,
        postStatuses: postIds.map((postId) => ({
          language: postLanguages.get(postId) || '',
          status: 'DRAFT',
        })),
      }));
    } else {
      let storeStatuses = ImmutableMap<number, ImmutableList<PostStatus>>();
      // 店舗ごとにステータスをリスト化する
      this.statuses.list.forEach((status) => {
        const storeId = status.store_id;
        const storeStatusList = storeStatuses.get(storeId) || ImmutableList();
        storeStatuses = storeStatuses.set(storeId, storeStatusList.push(status));
      });

      const statusMap: { [key: string]: PostStatusType } = {
        LIVE: 'LIVE',
        PROCESSING: 'PROCESSING',
        LOCAL_POST_STATE_UNSPECIFIED: 'PROCESSING', // 未指定は投稿中として扱う
        REJECTED: 'REJECTED',
      };

      return storeIds.map((storeId) => {
        const postStatuses = storeStatuses.get(storeId) || ImmutableList<PostStatus>();
        const livePostCount = postStatuses.filter((status) => status.gmb_state === 'LIVE').size;

        const statues = postStatuses.map((status) => status.gmb_state);
        // 店舗ごとの代表ステータスの優先度は 公開拒否 > 投稿中 > 公開済み
        const status = statues.includes('REJECTED')
          ? statusMap['REJECTED']
          : statues.includes('PROCESSING')
            ? statusMap['PROCESSING']
            : statues.includes('LOCAL_POST_STATE_UNSPECIFIED')
              ? statusMap['LOCAL_POST_STATE_UNSPECIFIED']
              : statues.includes('LIVE')
                ? statusMap['LIVE']
                : 'UNSPECIFIED';

        return {
          storeId,
          status,
          livePostCount,
          totalPostCount: postStatuses.size,
          postStatuses: postStatuses.map((status) => ({
            language: postLanguages.get(status.local_post_doc_id) || '',
            status: statusMap[status.gmb_state] || 'UNSPECIFIED',
          })),
        };
      });
    }
  }

  /** 投稿が編集可能か */
  get canEdit() {
    return this.promotion.canEdit && this.isStatusInitialized && !this.hasGmbStatusReject;
  }

  /** 投稿ステータスが初期化済みか */
  get isStatusInitialized() {
    return this.statuses.isInitialized;
  }

  /** 公開拒否の投稿を含んでいるか */
  get hasGmbStatusReject() {
    return !this.statuses.list.filter((status) => status.gmb_state === 'REJECTED').isEmpty();
  }

  /** promotionを置き換える */
  setPromotion(value: Promotion) {
    return this.set('promotion', value);
  }

  isValid() {
    const topicType = this.promotion.topic_type;
    const isPromotionValid = this.promotion.isValid;
    const isChildrenValid = this.children.every((child) => child.isValid(topicType));
    return isPromotionValid && isChildrenValid;
  }

  translationParams() {
    return this.children.map((childPost) => childPost.updateParams()).toArray();
  }
}
