import React, { useCallback } from 'react';

import { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import {
  getDateRangeDays,
  getDateRangeLastMonth,
  getDateRangeLastWeek,
  getDateRangeMonths,
  getDateRangeWeeks,
  isSameDateRange,
} from 'helpers/date';
import { AggregateUnit } from 'types/Common';

// 比較期間のタイプ（プルダウンの選択肢）
export type ComparisonDateRangeOptionValue = '前の期間' | '前年' | 'カスタム';

export type NumberUnit = `${number} ${AggregateUnit}`;
export type LastUnit = `last ${Exclude<AggregateUnit, 'day'>}`;
export type DateRangeOptionValue = NumberUnit | LastUnit | 'カスタム';
export type DateRangeOption = { text: string; value: DateRangeOptionValue };
export type ComparisonDateRangeOption = { text: string; value: ComparisonDateRangeOptionValue };

const defaultComparisonDateRangeOptions: ComparisonDateRangeOption[] = [
  { text: '前の期間', value: '前の期間' },
  { text: '前年', value: '前年' },
  { text: 'カスタム', value: 'カスタム' },
];

// オプションのvalueには文字列しか設定できないので、文字列からlast, value, unitを取得する
const getDateRangeOptionValues = (
  optionValue: Exclude<DateRangeOptionValue, 'カスタム'>,
): [last: boolean, value: number, unit: AggregateUnit] => {
  if (optionValue.startsWith('last')) {
    // `last ${week | month}`の場合
    const [, unit] = optionValue.split(' ') as ['last', AggregateUnit];
    return [true, 1, unit];
  } else {
    // `${number} ${day | week | month}`の場合
    const [valueStr, unit] = optionValue.split(' ') as [`${number}`, AggregateUnit];
    const value = Number.parseInt(valueStr, 10);
    return [false, value, unit];
  }
};

/**
 * 開始日、終了日から期間のタイプを判別して返す
 * @param dateRangeOptions オプション
 * @param startDate 開始日
 * @param endDate 終了日
 * @param maxDate 終了日
 */
export const getDateRangeOption = (
  dateRangeOptions: DateRangeOption[],
  startDate: Dayjs,
  endDate: Dayjs,
  maxDate: Dayjs,
): DateRangeOptionValue => {
  const range = [startDate, endDate] as [Dayjs, Dayjs];
  let result: DateRangeOptionValue = 'カスタム';
  dateRangeOptions.forEach((option) => {
    if (option.value === 'カスタム') {
      return;
    }
    const [last, value, unit] = getDateRangeOptionValues(option.value);
    switch (unit) {
      case 'day':
        if (isSameDateRange(range, getDateRangeDays(maxDate, value))) {
          result = option.value;
        }
        break;
      case 'week':
        if (isSameDateRange(range, last ? getDateRangeLastWeek(maxDate) : getDateRangeWeeks(maxDate, value))) {
          result = option.value;
        }
        break;
      case 'month':
        if (isSameDateRange(range, last ? getDateRangeLastMonth(maxDate) : getDateRangeMonths(maxDate, value))) {
          result = option.value;
        }
        break;
    }
  });
  return result;
};

/**
 * 集計期間のオプションに合った開始日〜終了日を返す
 * @param dateRangeOption 集計期間のオプション
 * @param startDate 開始日
 * @param endDate 終了日
 * @param maxDate 設定可能な最も新しい日
 * @returns [Dayjs, Dayjs] 開始日, 終了日
 */
export const getDateRangeFromDateRangeOption = (
  dateRangeOption: DateRangeOptionValue,
  startDate: Dayjs,
  endDate: Dayjs,
  maxDate: Dayjs,
): [Dayjs, Dayjs] => {
  if (dateRangeOption === 'カスタム') {
    return [startDate, endDate];
  }

  const [last, value, unit] = getDateRangeOptionValues(dateRangeOption);
  // 選択された集計期間のオプションに合わせて開始日〜終了日を返す
  switch (unit) {
    case 'day':
      return getDateRangeDays(maxDate, value);
    case 'week':
      return last ? getDateRangeLastWeek(maxDate) : getDateRangeWeeks(maxDate, value);
    case 'month':
      return last ? getDateRangeLastMonth(maxDate) : getDateRangeMonths(maxDate, value);
  }
};

/**
 * 与えられた日付（開始日、終了日）と比較の期間のタイプを元に、比較期間の開始日、終了日を返す
 * @param startDate 基準となる期間の開始日
 * @param endDate 基準となる期間の終了日
 * @param comparisonDateRangeOption 比較の期間のタイプ
 * @param dateRangeOption 期間のタイプ
 * @returns [比較期間の開始日, 比較期間の終了日]
 */
export const getComparisonDateRange = (
  startDate: Dayjs,
  endDate: Dayjs,
  comparisonDateRangeOption: Exclude<ComparisonDateRangeOptionValue, 'カスタム'>,
  dateRangeOption: DateRangeOptionValue,
): [Dayjs, Dayjs] => {
  switch (comparisonDateRangeOption) {
    case '前の期間': {
      const days = endDate.diff(startDate, 'day', false) + 1; // 集計期間の日数
      const lastDate = startDate.subtract(1, 'day'); // 比較期間の最終日は、startDateの前日
      if (dateRangeOption === 'カスタム') {
        // 比較期間の最終日までのdays日間の期間
        return getDateRangeDays(lastDate, days);
      } else {
        const [, value, unit] = getDateRangeOptionValues(dateRangeOption);
        switch (unit) {
          case 'day':
            return getDateRangeDays(lastDate, value);
          case 'week':
            return getDateRangeWeeks(lastDate, value);
          case 'month':
            return getDateRangeMonths(lastDate, value);
        }
      }
      return [startDate, endDate];
    }
    case '前年': {
      const csd = startDate.subtract(1, 'year');
      const ced = endDate.subtract(1, 'year');
      return [csd, ced];
    }
  }
};

/**
 * 開始日、終了日、比較期間の開始日、比較期間の終了日から比較期間のタイプを判別して返す
 * @param dateRangeOption 期間のオプション
 * @param startDate 開始日
 * @param endDate 終了日
 * @param comparisonStartDate 比較期間の開始日
 * @param comparisonEndDate 比較期間の終了日
 * @returns 比較期間のタイプ
 */
export const getComparisonDateRangeOption = (
  dateRangeOption: DateRangeOptionValue,
  startDate: Dayjs,
  endDate: Dayjs,
  comparisonStartDate: Dayjs,
  comparisonEndDate: Dayjs,
) => {
  if (
    isSameDateRange(
      [comparisonStartDate, comparisonEndDate],
      getComparisonDateRange(startDate, endDate, '前の期間', dateRangeOption),
    )
  ) {
    return '前の期間';
  } else if (
    isSameDateRange(
      [comparisonStartDate, comparisonEndDate],
      getComparisonDateRange(startDate, endDate, '前年', dateRangeOption),
    )
  ) {
    return '前年';
  } else {
    return 'カスタム';
  }
};

type Props = {
  className?: string;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate?: Dayjs | null;
  comparisonEndDate?: Dayjs | null;
  maxDate: Dayjs;
  showComparisonCheckBox?: boolean;
  hideComparison?: boolean; // 比較期間のUIを表示しない
  isEnabledComparison: boolean;
  dateRangeOption: DateRangeOptionValue;
  dateRangeOptions: DateRangeOption[];
  comparisonDateRangeOption?: ComparisonDateRangeOptionValue;
  comparisonDateRangeOptions?: ComparisonDateRangeOption[];
  onChangeDateRange: (startDate: Date | null, endDate: Date | null) => void;
  onChangeComparisonDateRange?: (startDate: Date | null, endDate: Date | null) => void;
  onChangeIsEnabledComparison?: (isEnabledComparison: boolean) => void;
  onChangeDateRangeOption: (value: DateRangeOptionValue) => void;
  onChangeComparisonDateRangeOption?: (value: ComparisonDateRangeOptionValue) => void;
};

export const AggregateDateRangePicker = React.memo<Props>(
  ({
    className,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    isEnabledComparison,
    maxDate,
    showComparisonCheckBox = true,
    hideComparison,
    dateRangeOption,
    dateRangeOptions,
    comparisonDateRangeOption,
    comparisonDateRangeOptions = defaultComparisonDateRangeOptions,
    onChangeDateRange,
    onChangeComparisonDateRange = null,
    onChangeIsEnabledComparison,
    onChangeDateRangeOption,
    onChangeComparisonDateRangeOption,
  }) => {
    const handleOnChangeIsEnabledComparison = useCallback(
      (_, data) => {
        onChangeIsEnabledComparison && onChangeIsEnabledComparison(data.checked);
      },
      [onChangeIsEnabledComparison],
    );

    return (
      <Wrapper className={className}>
        <Container>
          <Label>期間</Label>
          <CheckboxWrapper />
          <PullDown
            value={dateRangeOption}
            options={dateRangeOptions}
            placeholder={'対象期間'}
            onChange={onChangeDateRangeOption}
          />
          <StyledDateRangePicker
            startDate={startDate.toDate()}
            endDate={endDate.toDate()}
            onChange={onChangeDateRange}
            maxDate={maxDate.toDate()}
          />
        </Container>
        {!hideComparison && (
          <Container>
            <Label>比較</Label>
            <CheckboxWrapper>
              {showComparisonCheckBox && (
                <SmallCheckBox checked={isEnabledComparison} onChange={handleOnChangeIsEnabledComparison} />
              )}
            </CheckboxWrapper>
            <PullDown
              value={isEnabledComparison ? comparisonDateRangeOption : null}
              options={comparisonDateRangeOptions}
              placeholder={'比較期間'}
              onChange={onChangeComparisonDateRangeOption}
              disabled={!isEnabledComparison}
            />
            <StyledDateRangePicker
              startDate={isEnabledComparison && comparisonStartDate ? comparisonStartDate.toDate() : null}
              endDate={isEnabledComparison && comparisonEndDate ? comparisonEndDate.toDate() : null}
              onChange={onChangeComparisonDateRange}
              maxDate={maxDate.toDate()}
              disabled={!isEnabledComparison}
            />
          </Container>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const Container = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 0;

  & + & {
    margin-top: 8px;
  }
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #707070;
  word-break: keep-all;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 14px;
  margin-left: 8px;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 120px;
    min-width: 120px;
    margin: 0 16px;
    & .ui.search.selection.dropdown {
      min-height: 33px;
      padding: 8px 14px;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 8px 14px;
    }
  }
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  &&& {
    input {
      height: 33px;
      max-width: 120px;
      min-width: 90px;
    }
  }
`;
