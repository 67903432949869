import React, { useCallback } from 'react';

import { Set } from 'immutable';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { SwitchContent } from 'components/molecules/SwitchContent';
import { SimpleGroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { DiffItemFilterType, allDiffItemFilters } from 'modules/gmbLocationUpdates/types';
import { SIZE } from 'style/size';
import { Group } from 'types/Common';

export type DiffItemFilterProps = {
  className?: string;
  values: Set<DiffItemFilterType>;
  toggleFilter: (value: DiffItemFilterType) => void;
  onChangeStoreFilter: (storeIds: Set<number>) => void;
};

export const DiffItemFilter: React.FC<DiffItemFilterProps> = ({
  className,
  values,
  toggleFilter,
  onChangeStoreFilter,
}) => {
  let selectBoxLabel: string;
  if (values.size === allDiffItemFilters.length) {
    selectBoxLabel = 'すべて';
  } else if (values.size > 0) {
    selectBoxLabel = allDiffItemFilters
      .map((v) => (values.has(v) ? v : null))
      .filter((v) => v)
      .join('、');
  } else {
    selectBoxLabel = '絞り込み条件を選択してください';
  }

  const handleOnChangeSelectStores = useCallback(
    (params: { group: Group; storeIds: Set<number>; isAllStores: boolean; showClosedStores: boolean }) => {
      onChangeStoreFilter(params.storeIds);
    },
    [onChangeStoreFilter],
  );

  return (
    <Wrapper className={className}>
      <SwitchContent openLabel='絞り込み条件を閉じる' closedLabel='絞り込み条件を開く'>
        <SwitchContentWrapper>
          <MultijSelectBoxWrapper>
            <ContentLabel>情報の種類</ContentLabel>
            <StyledMultiSelectBox value={selectBoxLabel}>
              <SelectContent>
                <CheckLabel
                  onChange={() => {
                    toggleFilter('基本情報');
                  }}
                  checked={values.has('基本情報')}
                  label='基本情報(店舗名、住所、電話番号、WEBサイト）'
                />
              </SelectContent>
              <SelectContent>
                <CheckLabel
                  onChange={() => {
                    toggleFilter('営業情報');
                  }}
                  checked={values.has('営業情報')}
                  label='営業情報(営業時間、特別営業時間、営業状態）'
                />
              </SelectContent>
              <SelectContent>
                <CheckLabel
                  onChange={() => {
                    toggleFilter('属性情報');
                  }}
                  checked={values.has('属性情報')}
                  label='属性情報'
                />
              </SelectContent>
              <SelectContent>
                <CheckLabel
                  onChange={() => {
                    toggleFilter('サービス情報');
                  }}
                  checked={values.has('サービス情報')}
                  label='サービス情報'
                />
              </SelectContent>
            </StyledMultiSelectBox>
          </MultijSelectBoxWrapper>
          <GroupStoreSelectWrapper>
            <SimpleGroupStoreSelect
              initialIsAllStores={true}
              showGbpConnectedStoresOnly={false}
              showMyStoreOnly={true}
              onChange={handleOnChangeSelectStores}
            />
          </GroupStoreSelectWrapper>
        </SwitchContentWrapper>
      </SwitchContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 60px;

  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    flex-direction: column;
  }
`;

const SwitchContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    flex-direction: column;
  }
`;
const StyledMultiSelectBox = styled(MultiSelectBox)`
  width: 100%;
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

const SelectContent = styled.div`
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const MultijSelectBoxWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 400px;

  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    max-width: 100%;
  }
  margin: 8px 0;
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const GroupStoreSelectWrapper = styled.div`
  margin-left: 24px;
  display: flex;

  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    margin-left: 0;
    flex-direction: column;
    width: 100%;
  }
`;
