import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { Prompt } from 'components/atoms/Prompt';
import { PromotionEditForm } from 'components/pageComponents/Promotion/PromotionEditForm';
import { PromotionEditHeader } from 'components/pageComponents/Promotion/PromotionEditHeader';
import { PromotionPreviewModal } from 'components/pageComponents/Promotion/PromotionPreviewModal';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useConfirmLeave } from 'hooks/useConfirmLeave';
import { TranslationLanguage } from 'models/Domain/Organization';
import Promotion, { PromotionTopicType } from 'models/Domain/Promotion/Promotion';
import { PromotionGroup } from 'models/Domain/Promotion/PromotionGroup';
import { Store } from 'models/Domain/Store';
import { PromotionActions } from 'modules/promotion/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

const TOPIC_TYPE_LABEL: { [key in PromotionTopicType]: string } = {
  STANDARD: '最新情報',
  ALERT: 'COVID-19の最新情報',
  OFFER: '特典',
  EVENT: 'イベント',
};

// 未保存の変更がある場合に、他のページに遷移しようとした場合のメッセージ
const promptMessage = 'ページから離れようとしています。\n公開されていない内容は失われてしまいますが、よろしいですか？';

export const PromotionEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ source: string }>();
  const { state } = location;
  const dispatch = useDispatch();
  const {
    clearMediaForEdit,
    getPromotionForEdit,
    updatePromotion,
    translatePost,
    setPromotionForEdit,
    setPromotionGroupForEdit,
  } = useMemo(() => bindActionCreators(PromotionActions, dispatch), [dispatch]);

  const [changed, setChanged] = useState(false);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // 必須項目などのエラーメッセージを表示させるかのフラグ
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const { currentUser, stores, storeLists, mediaForEdit, isLoading, loadingLanguages, promotionGroup } = useSelector(
    (state: State) => ({
      currentUser: state.app.currentUser,
      mediaForEdit: state.promotion.mediaForEdit,
      stores: state.store.stores,
      storeLists: state.storeList.storeLists,
      isLoading: state.promotion.isLoadingPromotion,
      loadingLanguages: state.promotion.loadingLanguages,
      promotionGroup: state.promotion.promotionGroupForEdit,
    }),
    shallowEqual,
  );

  const promotionForEdit = promotionGroup.promotion;

  const handleChangePromotionGroup = useCallback(
    (promotionGroup: PromotionGroup) => {
      setChanged(true);
      setPromotionGroupForEdit(promotionGroup);
    },
    [setPromotionGroupForEdit],
  );

  useConfirmLeave(changed);

  useEffect(() => {
    getPromotionForEdit(id);
    clearMediaForEdit();
    // エラーメッセージの表示フラグをリセットする
    setShowErrorMessage(false);
    setChanged(false);
    return () => {
      setPromotionGroupForEdit(new PromotionGroup());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // 次の場合、詳細画面にリダイレクト
    // * 拒否された投稿が含まれる
    // * 投稿された投稿に複数枚の画像または動画が含まれている
    if (!promotionForEdit.canEdit) {
      dispatch(replaceWithOrganizationId(Path.localpost.detail.replace(':id', id)));
      return;
    }
  }, [dispatch, id, promotionForEdit.canEdit]);

  // 写真がアップロードされてmediaForEditが変更されたら、それを編集中のpromotionに取り込む
  useEffect(() => {
    const promotion = promotionForEdit.changeMedia(mediaForEdit);
    setPromotionForEdit(promotion);
  }, [mediaForEdit, setPromotionForEdit, promotionForEdit]);

  const previewTargetStoreId = promotionForEdit.stores.find((store) => !!stores.findStore(store.store_id))?.store_id;
  const previewTargetStore = stores.findStore(previewTargetStoreId) || new Store();

  const handleOnBack = useCallback(() => {
    // 遷移元が投稿なら検索条件やスクロール位置を保持するために戻る、それ以外なら投稿詳細に遷移
    if (state?.source && state.source.startsWith(Path.localpost.index)) {
      dispatch(goBack());
    } else if (promotionForEdit.isDraft) {
      // 下書きの場合は詳細はないので、一覧に戻る
      dispatch(pushWithOrganizationId(Path.localpost.index));
    } else {
      dispatch(pushWithOrganizationId(Path.localpost.detail.replace(':id', id)));
    }
  }, [dispatch, state, promotionForEdit, id]);

  const changePromotion = useCallback(
    (promotion: Promotion) => {
      setPromotionForEdit(promotion);
      setChanged(true);
    },
    [setPromotionForEdit],
  );

  const handleOnDelete = useCallback(() => {
    if (!window.confirm('削除してよろしいですか？')) return;
    dispatch(PromotionActions.deletePromotion({ id: promotionForEdit._id ?? '' }));
  }, [dispatch, promotionForEdit._id]);

  const onChangeStoreList = useCallback(
    (storeIds: number[]) => {
      setPromotionForEdit(promotionForEdit.changeStores(storeIds));
      // 投稿先店舗が変更されたらchangedをtrueにする
      if (storeIds.length > 0) {
        setChanged(true);
      }
    },
    [promotionForEdit, setPromotionForEdit],
  );

  const handleTranslatePost = useCallback(
    (language: TranslationLanguage) => {
      translatePost({ language, promotion: promotionForEdit });
    },
    [promotionForEdit, translatePost],
  );

  return (
    <MainWrapper>
      <Helmet title={getPageTitle(`投稿を編集 - ${TOPIC_TYPE_LABEL[promotionForEdit.topic_type]}`)} />
      <PromotionEditHeader
        title={`投稿を編集 - ${TOPIC_TYPE_LABEL[promotionForEdit.topic_type]}`}
        onBack={handleOnBack}
        formType={'update'}
        onDelete={handleOnDelete}
      />
      <StyledBody>
        <PromotionEditForm
          formType='update'
          promotion={promotionForEdit}
          setPromotion={changePromotion}
          setSelectedStoreList={(v) => onChangeStoreList(v)}
          storeLists={storeLists}
          stores={stores}
          currentUser={currentUser}
          onUpdate={() => {
            // 送信して画面遷移するときにダイアログが出ないように、changedをここでfalseにする
            setChanged(false);
            setModalOpen(true);
          }}
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          onSaveDraft={() => {
            setChanged(false);
            updatePromotion(promotionForEdit.changeIsDraft(true));
          }}
          loadingLanguages={loadingLanguages}
          translatePost={handleTranslatePost}
          promotionGroup={promotionGroup}
          onChangePromotionGroup={handleChangePromotionGroup}
        />
        {isLoading && (
          <LoadingWrapper>
            <LoadingWrapperBase />
            <Loader active={true} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
      </StyledBody>
      <PromotionPreviewModal
        promotionGroup={promotionGroup}
        open={modalOpen}
        store={previewTargetStore}
        onClose={() => {
          // ダイアログを出すときにchangedをfalseにしているのでtrueに戻す
          setChanged(true);
          setModalOpen(false);
        }}
        onSubmit={() => {
          setChanged(false);
          updatePromotion(promotionForEdit.changeIsDraft(false));
          setModalOpen(false);
        }}
      />
      <Prompt when={changed} message={promptMessage} />
    </MainWrapper>
  );
};

const StyledBody = styled(Body)`
  position: relative;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  z-index: 1; /** カルーセルの矢印より上に表示されるようにする */
`;
