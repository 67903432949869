import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { TextArea } from 'components/atoms/TextArea';
import { SmallUploadButton } from 'components/molecules/FileUploader';
import { Comment } from 'models/Domain/Comment';
import { AppActions } from 'modules/app/actions';
import { OfferActions } from 'modules/offer/actions';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

import { StampPortal } from './StampPortal';

export const CommentEdit: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const sendNewComment = useCallback(() => dispatch(OfferActions.sendNewComment()), [dispatch]);
  const setNewComment = useCallback((comment: Comment) => dispatch(OfferActions.setNewComment(comment)), [dispatch]);
  const uploadNewCommentFile = useCallback(
    (files: File[]) => dispatch(OfferActions.uploadNewCommentFile(files)),
    [dispatch],
  );
  const sendOfferStamp = useCallback((v: number) => dispatch(OfferActions.sendOfferStamp(v)), [dispatch]);
  const onDropRejected = useCallback((v: File[]) => dispatch(AppActions.onDropRejected(v)), [dispatch]);

  const offer = useSelector((state) => state.offer);
  const {
    newComment,
    offerItem: {
      offer: { store_id },
    },
  } = offer;
  const {
    content: { text, files },
  } = newComment;

  const store = useSelector((state) => state.store.stores.findStore(store_id));

  if (!store) return null;

  return (
    <>
      <CommentWrapper>
        <Form>
          <Form.Field>
            {newComment.content.files.list.size > 0 && (
              <CommentFileList>
                {files.list.map((file, fileIndex) => {
                  return (
                    <CommentFileWrapper key={fileIndex}>
                      <DeleteButton
                        circular
                        size='mini'
                        icon='close'
                        color='black'
                        onClick={() => setNewComment(newComment.deleteFile(fileIndex))}
                      />
                      <CommentFile src={file.url} />
                    </CommentFileWrapper>
                  );
                })}
              </CommentFileList>
            )}
          </Form.Field>
          <TextArea
            value={text}
            placeholder='コメントを送る'
            priority='small'
            onChange={(value: string) => setNewComment(newComment.changeText(value))}
          />
          <ButtonsWrapper>
            <FlexField>
              <CommentSmallUploadButton
                onDropAccepted={(files) => uploadNewCommentFile(files)}
                onDropRejected={(files) => onDropRejected(files)}
              />
              <CommentSmileIconButton type='smile' onClick={() => setModalOpen(true)} />
            </FlexField>
            <CommentButton priority='high' onClick={() => sendNewComment()} disabled={!newComment.isValid}>
              送信
            </CommentButton>
          </ButtonsWrapper>
        </Form>
      </CommentWrapper>

      <StampPortal isModalOpen={isModalOpen} setModalOpen={setModalOpen} sendOfferStamp={sendOfferStamp} />
    </>
  );
};

const CommentWrapper = styled.div`
  padding: 20px 14px;
  background-color: ${COLOR.WHITE};
  position: fixed;
  bottom: 0;
  right: 0;
  left: ${SIZE.SIDEBAR};
  @media (max-width: 600px) {
    left: 0;
  }
`;

const CommentFileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const CommentFileWrapper = styled.div`
  position: relative;
  margin: 10px 10px 0 0;
`;

const CommentFile = styled.img`
  object-fit: cover;
  width: 64px;
  height: 64px;
`;

const CommentSmallUploadButton = styled(SmallUploadButton)`
  width: 35px;
  height: 35px;
  border-style: none;
`;

const CommentSmileIconButton = styled(Icon)`
  margin-top: 6px;
  cursor: pointer;
`;

const DeleteButton = styled(SemanticButton)`
  &&& {
    position: absolute;
    top: -12px;
    right: -12px;
    border-radius: 50% !important;
    padding: 6px !important;
  }
`;

const CommentButton = styled(Button)`
  &&& {
    margin-top: 10px;
    width: 80px;
    padding: 8px 8px;
  }
`;

const FlexField = styled.div`
  display: flex;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
