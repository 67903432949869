import { Loader as SemanticLoader } from 'semantic-ui-react';
import styled from 'styled-components';

// semantic-ui-reactのLoaderのz-indexは1000
// StickyHeaderのz-indexは100
// そのまま使うと、ヘッダーより上にLoaderが表示されてしまうので調整する
export const Loader = styled(SemanticLoader)`
  &&& {
    z-index: 1;
  }
`;
