import { List, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Competitor } from 'models/Domain/Competitor/Competitor';
import { CompetitorRegisterSearchCondition } from 'models/Domain/Competitor/CompetitorRegisterSearchCondition';
import { CompetitorSearchResult } from 'models/Domain/Competitor/CompetitorSearchResult';

import { CompetitorRegisterActions as Actions } from './actions';

export class CompetitorRegisterState extends Record<{
  isLoading: boolean;
  searchCondition: CompetitorRegisterSearchCondition;
  filterWords: List<string>;
  tags: List<string>;
  searchResults: List<CompetitorSearchResult> | null;
  selectedCompetitors: List<Competitor>;
  registeredCompetitors: List<Competitor>;
}>({
  isLoading: false,
  searchCondition: new CompetitorRegisterSearchCondition(),
  filterWords: List(),
  tags: List(),
  searchResults: null,
  selectedCompetitors: List(),
  registeredCompetitors: List(),
}) {}

export const competitorRegisterReducer = reducerWithInitialState(new CompetitorRegisterState())
  .case(Actions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  })
  .case(Actions.setFilterWords, (state, payload) => {
    return state.set('filterWords', payload);
  })
  .case(Actions.setTags, (state, payload) => {
    return state.set('tags', payload);
  })
  .case(Actions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(Actions.setSearchResults, (state, payload) => {
    return state.set('searchResults', payload);
  })
  .case(Actions.setSelectedCompetitors, (state, payload) => {
    return state.set('selectedCompetitors', payload);
  })
  .case(Actions.clearState, (state, payload) => {
    return new CompetitorRegisterState();
  })
  .case(Actions.setRegisteredCompetitors, (state, payload) => {
    return state.set('registeredCompetitors', payload);
  });
