import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  InventoryCsvDownloadApi,
  InventoryCsvDownloadStatusCheckApi,
  InventoryCsvImportApi,
  InventoryCsvImportStatusCheckApi,
} from 'ApiClient/OmoApi';
import { InventoryCsvDownloadFilter } from 'models/Domain/Omo/InventoryCsvDownloadFilter';
import { AppActions } from 'modules/app/actions';
import { getInitialGroup, getInitialStores } from 'modules/utils';
import { Group, JSObject } from 'types/Common';

import { State } from '../reducers';

import { InventoriesImportActions } from './actions';

export default function* saga() {
  yield takeLatest(InventoriesImportActions.importInventories, importInventories);
  yield takeLatest(InventoriesImportActions.checkInventoryCsvDownloadStatus, checkInventoryCsvDownloadStatus);
  yield takeLatest(InventoriesImportActions.checkCsvImportStatus, checkCsvImportStatus);
  yield takeLatest(InventoriesImportActions.downloadInventoriesCsv, downloadInventoriesCsv);
  yield takeLatest(InventoriesImportActions.initializeInventoriesImportPage, initializeInventoriesImportPage);
}

/**
 * csvファイルインポート（バリデーション）を実行する
 */
function* importInventories(action: ReturnType<typeof InventoriesImportActions.importInventories>) {
  yield put(InventoriesImportActions.setIsProcessing(true));

  const { file: csv, validateOnly: validate_only } = action.payload;
  const response: JSObject = yield InventoryCsvImportApi.post({ csv, validate_only });

  if (response.isSuccess) {
    yield put(InventoriesImportActions.setExecutionArn(response.data.execution_arn));
  } else {
    const title = validate_only ? 'ファイルのバリデーションに失敗しました' : '在庫情報の更新に失敗しました';
    toast({
      type: 'error',
      title,
      description: String(response.error.message),
      time: 10000,
    });
    yield put(InventoriesImportActions.setIsProcessing(false));
  }
}

/**
 * csvファイルのインポート（バリデーション）の状態をAPI経由で確認
 */
function* checkCsvImportStatus(action: ReturnType<typeof InventoriesImportActions.checkCsvImportStatus>) {
  const executionArn: string = yield select((state: State) => state.inventoriesImport.executionArn);
  const { validateOnly } = action.payload;

  if (!executionArn) {
    return;
  }

  const response: YieldReturn<typeof InventoryCsvImportStatusCheckApi.post> =
    yield InventoryCsvImportStatusCheckApi.post({
      executionArn,
    });

  // リクエスト失敗時
  if (!response.isSuccess) {
    yield put(InventoriesImportActions.setIsProcessing(false));
    yield put(InventoriesImportActions.setExecutionArn(''));
    toast({
      type: 'error',
      title: '在庫情報の更新ステータス取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }

  // リクエスト成功時
  if (response.data.status === 'RUNNING') {
    // status === RUNNING の場合、バックエンド処理中なので何もしない
  } else if (response.data.status === 'SUCCEEDED') {
    yield put(InventoriesImportActions.setIsProcessing(false));
    yield put(InventoriesImportActions.setExecutionArn(''));

    // バリデーションのみの実行の場合
    if (validateOnly) {
      yield put(InventoriesImportActions.setValidateResult(response.data));
      return;
    }

    // インポート処理が完了した場合
    toast({
      type: 'success',
      title: '在庫情報を更新しました',
    });
    yield put(InventoriesImportActions.setImportStatus({ importStatus: response.data.status }));
    yield put(InventoriesImportActions.clearValidateResult());
  } else {
    // バックエンド実行エラー時
    toast({
      type: 'error',
      title: String(response.data.result.message),
      description: '',
      time: 10000,
    });
    yield put(InventoriesImportActions.setExecutionArn(''));
    yield put(InventoriesImportActions.setImportStatus({ importStatus: response.data.status }));
    yield put(InventoriesImportActions.setIsProcessing(false));
  }
}

/**
 * 在庫情報のcsvファイルをダウンロードする
 */
function* downloadInventoriesCsv() {
  yield put(AppActions.setLoading(true));

  const filter: InventoryCsvDownloadFilter = yield select(
    (state: State) => state.inventoriesImport.inventoryCsvDownloadFilter,
  );
  const params = filter.toRequestParams();

  const response: YieldReturn<typeof InventoryCsvDownloadApi.post> = yield InventoryCsvDownloadApi.post(params);

  if (response.isSuccess) {
    yield put(InventoriesImportActions.setDownloadExecutionArn(response.data.executionArn));
  } else {
    toast({
      type: 'error',
      title: 'CSVファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

/**
 * 在庫情報のCSVファイルのダウンロードの状態をAPI経由で確認
 */
function* checkInventoryCsvDownloadStatus() {
  const executionArn: string = yield select((state: State) => state.inventoriesImport.downloadExecutionArn);

  if (!executionArn) {
    return;
  }

  const response: YieldReturn<typeof InventoryCsvDownloadStatusCheckApi.post> =
    yield InventoryCsvDownloadStatusCheckApi.post({
      executionArn,
    });

  // リクエスト失敗時
  if (!response.isSuccess) {
    yield put(InventoriesImportActions.setDownloadExecutionArn(''));
    toast({
      type: 'error',
      title: '在庫情報ダウンロードステータス取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }

  // リクエスト成功時
  if (response.data.status === 'RUNNING') {
    // status === RUNNING の場合、バックエンド処理中なので何もしない
  } else if (response.data.status === 'SUCCEEDED') {
    yield put(InventoriesImportActions.setDownloadExecutionArn(''));
    if (response.data.download_url) {
      window.location.assign(response.data.download_url);
    } else {
      // ダウンロードURLが空の場合 (データが存在しなかった時など)
      toast({
        type: 'error',
        title: 'CSVファイルのダウンロードに失敗しました',
        description: String(response.data.message),
        time: 10000,
      });
    }
    return;
  } else {
    // バックエンド実行エラー時
    toast({
      type: 'error',
      title: String(response.data.message),
      description: '',
      time: 10000,
    });
    yield put(InventoriesImportActions.setDownloadExecutionArn(''));
  }
}

/**
 * 在庫情報をまとめて更新ページを初期化する
 * - 在庫情報CSVダウンロードのフィルターを初期化する
 * @param {object} [payload] actionのペイロード storeIdが存在する場合、その店舗が選択された状態でフィルターを初期化
 */
function* initializeInventoriesImportPage(action: { type: string; payload?: string }) {
  const storeId = action.payload ? parseInt(action.payload, 10) : undefined;

  // SVまたは店舗スタッフの場合は管理 or 所属店舗、それ以外は全ての店舗を設定
  const group: Group = yield call(getInitialGroup, {});

  let availableStoreIds: Set<number> = yield call(getInitialStores, {
    group,
    containsClosedStores: false,
    onlyGbpConnectedStores: true,
  });

  const currentStoreIds = Set(storeId ? [storeId] : []);
  if (!currentStoreIds.isEmpty()) {
    availableStoreIds = currentStoreIds.intersect(availableStoreIds);
  }

  // フィルターを初期化して店舗情報を設定
  const filter = new InventoryCsvDownloadFilter({ store: group, storeIds: availableStoreIds });
  yield put(InventoriesImportActions.setInventoryCsvDownloadFilter(filter));
}
