/**
 * インサイトの指標についてツールチップで表示する説明
 */
export const InsightHelp = {
  // 検索数
  sumSearch: {
    content: '直接検索、間接検索を足したすべての検索された回数',
  },
  queriesDirect: {
    content: 'ビジネスの名前や住所を直接検索したユーザーの数',
  },
  queriesIndirect: {
    content:
      '提供している商品やサービス、またそのカテゴリーを検索し、リスティングが表示されたユーザーの数（ブランド検索のユーザーの数を含む）',
  },
  queriesChain: {
    content: 'ブランドや、ビジネスと関連のあるブランドを検索したユーザーの数（間接検索のユーザーの数に含まれる）',
  },
  // アクション数
  sumAction: {
    content: 'ウェブサイトへのアクセス、電話をかける、ルートの検索をしたユーザーの合計',
  },
  actionsWebsite: {
    content: 'ウェブサイトを閲覧したユーザーの数',
  },
  actionsPhone: {
    content: 'ビジネスに電話をかけたユーザーの数',
  },
  actionsDrivingDirections: {
    content: 'ビジネスまでのルートをリクエストしたユーザーの数',
  },
  // アクション率
  overallActionRate: {
    content:
      '全ての検索数のうち、ウェブサイトへのアクセス、ビジネスに電話をかける、ルートのリクエストをしたユーザーの割合',
  },
  actionsWebsiteRate: {
    content: '全ての検索数のうち、ウェブサイトを閲覧したユーザーの割合',
  },
  actionsPhoneRate: {
    content: '全ての検索数のうち、ビジネスに電話をかけたユーザーの割合',
  },
  actionsDrivingDirectionsRate: {
    content: '全ての検索数のうち、ビジネスまでのルートをリクエストしたユーザーの割合',
  },
  // クチコミ
  sumReview: {
    content: '評価のみのクチコミ、コメントのあるクチコミの合計',
  },
  rateCount: {
    content: 'コメントのない点数だけのクチコミの数',
  },
  commentCount: {
    content: 'コメントのあるクチコミの数',
  },
};

/**
 * 店頭在庫についてツールチップで表示する説明
 */
export const InventoryHelp = {
  // 有効期限別の商品数
  valid: '在庫情報がGoogleに掲載されている商品の数',
  expiringSoon: '在庫情報がGoogleにまもなく掲載されなくなる商品の数',
  expired: '在庫情報がGoogleに掲載されなくなった商品の数',
  // 在庫情報
  availability: '商品の在庫の有無',
  quantity: '商品在庫数',
  price: '商品の通常価格',
  salePrice: '一時的なセール価格',
  salePriceEffectiveDate: 'セール価格が適用される期間',
  // 有効期限
  expiration: '在庫情報がGoogleに掲載されなくなる日',
};

/**
 * 検索キーワード分析についてツールチップで表示する説明
 */
export const SearchKeywordHelp = {
  avg: '集計期間内の月間の平均検索数',
  count: '表示月として選択している月の検索数',
  momDiff: '表示月の前月からの検索数の差',
  momRatio: '表示月の前月からの検索数の変化率',
  threeMomRatio: '表示月の3ヶ月前の月からの検索数の変化率',
  yoyRatio: '表示月の1年前の月からの検索数の変化率',
  copy: 'クリップボードにコピー',
  changeKeyword: '含むキーワードと除外キーワードを入れ替えます',
};

/**
 * 検索順位監視画面についてツールチップで表示する説明
 */
export const MapSearchRankHelp = {
  rank: '集計期間内の平均の掲載順位（60位以内に含まれない場合は60位扱い）',
  comparisonRank: '比較期間内の平均の掲載順位（60位以内に含まれない場合は60位扱い）',
  diff: '集計期間の開始時と終了時の掲載順位の差分',
  diffComparison: '平均の掲載順位の比較期間との差分',
  latestRank: '集計期間内の終了時の掲載順位（60位以内に含まれない場合は60位扱い）',
};

/**
 * 競合店舗についてツールチップで表示する説明
 */
export const CompetitorHelp = {
  tags: 'キーワードのカテゴリや施策などをタグに設定することで、絞り込んで分析しやすくなります',
  aggregateType: '「直近」は先月/今月または直近１ヶ月のデータ、「全期間」は取得可能なすべてのデータを集計します',
  hasMore: '取得できたデータの数です。数字の後に「+」がある場合は、GBPに値以上のデータが存在する可能性があります',
  frequency:
    '集計期間内の値によって色分けされます。「1〜4」の場合は緑、「5〜9」の場合は黄、「10以上」の場合は赤で表示されます。',
  hasMoreAndFrequency:
    '取得できたデータの数です。数字の後に「+」がある場合は、GBPに値以上のデータが存在する可能性があります。' +
    '「1〜4」の場合は緑、「5〜9」の場合は黄、「10以上」の場合は赤で表示されます。',
};

/**
 * インスタグラム連携についてツールチップで表示する説明
 */
export const InstagramHelp = {
  excludeWords: '指定されたキーワードを含む投稿は、GBPへ自動反映されません。この設定はすべての投稿に反映されます。',
  targets:
    '自動反映を行う店舗を設定します。いずれかの条件に合致した店舗に自動反映が行われます。複数の条件に一致する場合は、上の条件が適用されます。',
  actionType:
    'GBPへの投稿にボタンを追加できます。「Instagramへのリンク」を選択した場合は、元投稿のURLになります。この設定はすべての投稿に反映されます。',
  actionUrl: 'GBPへの投稿に表示するボタンのリンク先。この設定はすべての投稿に反映されます。',
};

export const GbpPerformanceHelp = {
  businessImpressions:
    '「Googleマップ - パソコン」「Googleマップ - モバイル」「Google検索 - パソコン」「Google検索 - モバイル」の４項目の値の合計',
  interactions: '「通話」「メッセージ」「予約」「ルート」「ウェブサイトのクリック」の５項目の値の合計',
  interactionsRate: 'インタラクション数のユーザー数に対する割合',
  reviews: '集計期間内の「コメントあり」と「評価のみ」のクチコミの合計',
  totalReviews: '集計期間までのすべてのクチコミの合計',
  periodReviews: '集計期間内のクチコミの合計',
  totalReviewReplyCount: '集計期間までに返信をしたクチコミ',
  periodReviewReplyCount: '集計期間内に返信をしたクチコミ',
  totalReviewAverageRating: '集計期間までのすべてのクチコミの平均評価',
  periodReviewAverageRating: '集計期間内のクチコミの平均評価',
  promotionTotalCount: '集計期間内の投稿数',
  promotionCount: '集計期間内の公開された投稿数',
  // promotionStandardCount: '集計期間内の「最新情報」の投稿数',
  // promotionEventCount: '集計期間内の「イベント」の投稿数',
  // promotionOfferCount: '集計期間内の「特典」の投稿数',
  // promotionAlertCount: '集計期間内のその他（「COVID-19の最新情報」など）の投稿数',
  promotionRejectedCount: '集計期間内の公開拒否された投稿数',
  imageCount: '集計期間内の写真追加数',
  // imageInteriorCount: '集計期間内の「内装」の写真追加数',
  // imageExteriorCount: '集計期間内の「外観」の写真追加数',
  // imageProductCount: '集計期間内の「商品」の写真追加数',
  // imageAdditionalCount: '集計期間内のその他の写真追加数',
  overviewActiveStats: 'グラフに表示する項目を選択できます。「概要」ではグラフに2つまで表示できます',
  activeStats: 'グラフに表示する項目を選択できます',
};

export const GbpPerformanceMAHelp = {
  impressions:
    '「Googleマップ - パソコン」「Googleマップ - モバイル」「Google検索 - パソコン」「Google検索 - モバイル」の４項目の値の合計',
  interactions: '「通話」「メッセージ」「予約」「ルート」「ウェブサイトのクリック」の５項目の値の合計',
  interactionsRate: 'インタラクション数のユーザー数に対する割合',
  reviews: '集計期間内の「コメントあり」と「評価のみ」のクチコミの合計',
  totalReviews: '集計期間までのすべてのクチコミの合計',
  periodReviews: '集計期間内のクチコミの合計',
  totalReplyCount: '集計期間までに返信をしたクチコミ',
  periodReplyCount: '集計期間内に返信をしたクチコミ',
  totalAverageRating: '集計期間までのすべてのクチコミの平均評価',
  periodAverageRating: '集計期間内のクチコミの平均評価',
  overviewActiveStats: 'グラフに表示する項目を選択できます。「概要」ではグラフに2つまで表示できます',
  activeStats: 'グラフに表示する項目を選択できます',
};

export const GraphSettingsHelp = {
  max: 'グラフの最大値。表示範囲は自動で調整され、入力した値にならないことがあります。',
  min: 'グラフの最小値。表示範囲は自動で調整され、入力した値にならないことがあります。',
};

export const MenuHelp = {
  applyToGbp: 'GBP連携が有効なメニューのみGBPに反映されます',
  menuName: 'STORECAST内でメニューを識別するための名前。GBPには表示されません',
  menuNameForMenuIndex: '関連する複数のメニュー (例: ランチ、ディナーなど) を1つにまとめたもの',
  menuSectionName: 'メニューの見出し',
  menuItemName: 'メニューに表示する料理の名前',
  groupStore: 'メニューを反映する店舗',
  price: '料理の価格（円）',
  image: '料理の写真',
  description: '料理の説明文',
  dietaryRestrictions: '料理に含まれるアレルギー物質や制限食についての情報。１つのみ選択できます',
};

export const ServiceHelp = {
  applyToGbp: 'GBP連携が有効なサービスのみGBPに反映されます',
  serviceGroupName: '関連する複数のサービスを1つにまとめたもの',
  groupStore: 'サービスを反映する店舗',
  category: 'サービスを反映する店舗のビジネスカテゴリ',
  serviceSort: 'カスタムサービスを表示する順番を変更できます。プリセットサービスの順番は変更できません',
  structuredService: 'Googleでサービスを編集する際に選択可能なサービスの候補',
  customService: 'サービスを独自に入力したもの',
  serviceName: 'サービスの名前。プリセットサービスの場合、名前は変更できません',
  price: 'サービスの価格。価格は「設定しない」「無料」「固定の価格」を入力できます',
  description: 'サービスの説明文',
  addStructuredService: 'Googleでサービスを編集する際に選択可能なサービスの候補',
  addCustomService: '追加したいサービスがプリセットサービスに含まれていない場合は、サービスを独自に入力できます',
};
