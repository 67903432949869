import React, { useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';

import { CheckPostRuleResponse, InstagramApi } from 'ApiClient/InstagramApi';
import { Button } from 'components/atoms/Button';
import { TextArea } from 'components/atoms/TextArea';
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'components/molecules/Table';
import { nonNullable } from 'helpers/utils';
import { useDebounce } from 'hooks/useDebounce';
import { useHoldOn } from 'hooks/useHoldOn';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { InstagramAccount, InstagramPostTarget, PERMALINK_ACTION_URL } from 'models/Domain/instagram';
import { COLOR } from 'style/color';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  account: InstagramAccount;
  stores: Stores;
  storeLists: StoreLists;
};

const checkInstagramPostTarget = async (caption: string, account: InstagramAccount) => {
  let data: CheckPostRuleResponse | undefined = undefined;
  // captionが入力されていなければ、APIを実行しない
  if (!caption) {
    return data;
  }
  const params = account.getCheckPostRuleParams(caption);
  const response = await InstagramApi.checkPostRule(params);
  if (response.isSuccess) {
    data = response.data;
  }
  return data;
};

const useCheckInstagramPostTarget = (caption: string, account: InstagramAccount) => {
  const { data, error } = useSWRImmutable([caption, account], checkInstagramPostTarget);
  // dataが取得できなかった場合は、結果が空のデータを返す
  const emptyData = {
    items:
      account.updateParams.post_rule.targets.map((target) => {
        return { target, store_ids: [] };
      }) ?? [],
  };
  // captionが未入力の場合はAPIを叩かずにemptyDataを返すので、isLoadingはfalse
  return { data: data || emptyData, isLoading: !!caption && !data && !error };
};

export const InstagramPostTargetPreviewModal = React.memo<Props>(({ isOpen, onClose, account, stores, storeLists }) => {
  const [caption, setCaption] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const debouncedCaption = useDebounce(caption, 500);
  const checkResult = useCheckInstagramPostTarget(debouncedCaption, account);
  const isDebounced = caption !== debouncedCaption;
  const isLoading = useHoldOn(checkResult.isLoading || isDebounced, 500);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title>投稿対象のプレビュー</Title>
          <ContentWrapper>
            <Content>
              <Description>テキストを入力すると、どの条件によって自動反映されるか確認できます。</Description>
            </Content>
            <Content>
              <Label>テキスト</Label>
              <StyledTextArea
                value={caption}
                onChange={setCaption}
                placeholder={'投稿対象を確認したいテキストを入力してください'}
              />
            </Content>
            <Content>
              <Header>
                <Label>投稿対象</Label>
                <Button priority={'low'} onClick={() => setShowDetail(!showDetail)}>
                  {showDetail ? '条件の詳細を非表示' : '条件の詳細を表示'}
                </Button>
              </Header>
              <Table>
                {showDetail && (
                  <TableHeader>
                    <StyledTableHeaderCell />
                    <StyledTableHeaderCell>店舗</StyledTableHeaderCell>
                    <StyledTableHeaderCell>条件</StyledTableHeaderCell>
                    <StyledTableHeaderCell>URL</StyledTableHeaderCell>
                  </TableHeader>
                )}
                <TableBody>
                  {checkResult.data.items.map((item, index) => {
                    const target = InstagramPostTarget.fromJSON(item.target);
                    const storeNames =
                      target.storeIds?.map((id) => stores.findStore(id)?.fullName).filter(nonNullable) ??
                      ImmutableList();
                    const groupNames =
                      target.groupIds?.map((id) => storeLists.findStoreList(id)?.name).filter(nonNullable) ??
                      ImmutableList();
                    const storeLabel =
                      groupNames.size > 0
                        ? groupNames.join('、')
                        : storeNames.size > 0
                          ? storeNames.join('、')
                          : '全ての店舗';
                    const matchWords = target.matchWords.map((word) => `「${word}」`);
                    const excludeWords = target.excludeWords.map((word) => `「${word}」`);
                    const resultStores = item.store_ids.map((id) => stores.findStore(id)?.fullName).filter(nonNullable);
                    return (
                      <React.Fragment key={index}>
                        {showDetail && (
                          <TableRow>
                            <IndexTableCell rowSpan={2}>{`条件${index + 1}`}</IndexTableCell>
                            <StyledTableCell>{storeLabel}</StyledTableCell>
                            <StyledTableCell>
                              {matchWords.size > 0 && <KeywordRule>{matchWords.join('')}を含む</KeywordRule>}
                              {excludeWords.size > 0 && <KeywordRule>{excludeWords.join('')}を含まない</KeywordRule>}
                              {matchWords.size === 0 && excludeWords.size === 0 && (
                                <KeywordRule>全ての投稿</KeywordRule>
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.target.action_type === 'ACTION_TYPE_UNSPECIFIED'
                                ? 'なし'
                                : item.target.action_url === PERMALINK_ACTION_URL
                                  ? '投稿のURL'
                                  : item.target.action_url}
                            </StyledTableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          {!showDetail && <IndexTableCell>{`条件${index + 1}`}</IndexTableCell>}
                          <StyledTableCell colSpan={3}>
                            {showDetail && <Target>投稿対象：</Target>}
                            {isLoading ? (
                              <Status>取得中...</Status>
                            ) : (
                              resultStores.join('、') || <Result>この条件により投稿される店舗はありません</Result>
                            )}
                          </StyledTableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Content>
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>閉じる</StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
});

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const Description = styled.div``;

const Label = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
`;

const StyledTextArea = styled(TextArea)``;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    border-right: 1px solid ${COLOR.TABLE_BORDER};
    text-align: left;
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    border-right: 1px solid ${COLOR.TABLE_BORDER};
    word-break: break-all;
    max-width: 200px;
    min-width: 64px;
  }
`;

const IndexTableCell = styled(StyledTableCell)`
  &&& {
    width: 72px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const KeywordRule = styled.div``;

const Target = styled.span`
  font-weight: bold;
`;

const Status = styled.span`
  color: ${COLOR.DARK_GRAY};
`;

const Result = styled.span`
  color: ${COLOR.DARK_GRAY};
`;
