import { Record as ImmutableRecord } from 'immutable';

import { JSObject, ValidationResult } from 'types/Common';

/** 開業日 */
export class OpeningDate extends ImmutableRecord<{
  year: number | null;
  month: number | null;
  day: number | null;
}>({
  year: null,
  month: null,
  day: null,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    return new OpeningDate(params);
  }

  get displayDate() {
    // yearかmonthがnullなら未設定扱い
    if (this.year == null || this.month == null) {
      return '未設定';
    }
    return `${this.year}年${this.month}月${this.day ? `${this.day}日` : ''}`;
  }

  changeYear(value: number | null) {
    return this.set('year', value);
  }

  changeMonth(value: number | null) {
    return this.set('month', value);
  }

  changeDay(value: number | null) {
    return this.set('day', value);
  }

  isValid() {
    return Object.values(this.validate()).every((result) => result.isValid);
  }

  validate(): Record<string, ValidationResult> {
    return {
      year: this.validateYear(),
      month: this.validateMonth(),
      day: this.validateDay(),
    };
  }

  validateYear(): ValidationResult {
    if (this.year == null) {
      return { isValid: false, error: '年を入力してください' };
    }
    if (this.year < 1 || 9999 < this.year) {
      return { isValid: false, error: '年は1〜9999の範囲で入力してください' };
    }
    return { isValid: true };
  }

  validateMonth(): ValidationResult {
    if (this.month == null) {
      return { isValid: false, error: '月を入力してください' };
    }
    if (this.month < 1 || 12 < this.month) {
      return { isValid: false, error: '月は1〜12の範囲で入力してください' };
    }
    return { isValid: true };
  }

  validateDay(): ValidationResult {
    const [min, max] = this.availableDateRange();
    if (this.day != null && (this.day < min || max < this.day)) {
      return { isValid: false, error: `日は${min}〜${max}の範囲で入力してください` };
    }
    return { isValid: true };
  }

  /// 選択可能な日の範囲
  availableDateRange() {
    // 2月の場合は、閏年ならば29日まで、そうでなければ28日まで
    if (this.month === 2) {
      return [1, this.isLeapYear() ? 29 : 28];
    }
    // 4月、6月、9月、11月は30日まで
    if (this.month && [4, 6, 9, 11].includes(this.month)) {
      return [1, 30];
    }
    // それ以外の場合は31日まで
    return [1, 31];
  }

  /// 閏年判定
  isLeapYear(): boolean {
    // 4で割り切れるかつ100で割り切れない、または、400で割り切れる場合は閏年
    return this.year == null || (this.year % 4 === 0 && this.year % 100 !== 0) || this.year % 400 === 0;
  }

  updateParams() {
    return {
      year: this.year,
      month: this.month,
      day: this.day,
    };
  }
}
