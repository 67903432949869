import actionCreatorFactory from 'typescript-fsa';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { GbpInsightSearchCondition } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { Insight, InsightGraphData, InsightTableData, SortType } from 'models/Domain/GbpInsight/Insight';
import { GmbInsightDownloadModalState } from 'models/Other/GmbInsightDownloadModalState';

const actionCreator = actionCreatorFactory('GbpInsight');

export const GbpInsightActions = {
  initializePage: actionCreator('initializePage'),

  updateInsight: actionCreator<Insight>('updateInsight'),
  setSearchCondition: actionCreator<{ searchCondition: GbpInsightSearchCondition; updateLocation?: boolean }>(
    'setSearchCondition',
  ),
  setCommittedSearchCondition: actionCreator<GbpInsightSearchCondition>('setCommittedSearchCondition'),
  setDownloadModalState: actionCreator<GmbInsightDownloadModalState>('setDownloadModalState'),

  clearInsightData: actionCreator('clearInsightData'),
  getInsightGraphData: actionCreator('getInsightGraphData'),
  getInsightTableData: actionCreator('getInsightTableData'),
  setInsightGraphData: actionCreator<[InsightGraphData, InsightGraphData]>('setInsightGraphData'),
  setInsightTableData: actionCreator<[InsightTableData, InsightTableData]>('setInsightTableData'),
  changeSortType: actionCreator<SortType>('changeSortType'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),
  setIsLoadingGraphData: actionCreator<boolean>('setIsLoadingGraphData'),
  setIsLoadingTableData: actionCreator<boolean>('setIsLoadingTableData'),

  getCsvDownload: actionCreator('getCsvDownload'),
  setCsvDownload: actionCreator<ExecutionArnStatus>('setCsvDownload'),
  clearCsvDownload: actionCreator('clearCsvDownload'),
  checkCsvDownloadStatus: actionCreator<number>('checkCsvDownloadStatus'),
};
