import React from 'react';

import Slider from 'react-slick';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { ImageList } from 'models/Domain/Image/Image';
import { COLOR } from 'style/color';

type Props = {
  imageList: ImageList;
  onRemove: (index: number) => void;
};

export const MultiPreview = React.memo<Props>(({ imageList, onRemove }) => {
  return (
    <Wrapper>
      <Viewer dots={true}>
        {imageList.list.map((image, i) => (
          <ImgWrapper key={i}>
            <RemoveIcon onClick={() => onRemove(i)} type='cancel' />
            <Img key={i} src={image.url} />
          </ImgWrapper>
        ))}
      </Viewer>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  background-color: ${COLOR.LIGHT_GRAY};
`;

const Viewer = styled(Slider)`
  &&& {
    .slick-arrow {
      width: fit-content;
      height: fit-content;
      z-index: 1;
      &:before {
        font-size: 40px;
        width: 100%;
      }
    }
    .slick-prev {
      left: 16px;
    }
    .slick-next {
      right: 16px;
    }
  }
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const Img = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
`;

const RemoveIcon = styled(Icon)`
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
`;
