import dayjs from 'dayjs';
import { Record } from 'immutable';

const HOUR_REGEX = /([0-9]{1,2}):[0-9]{1,2}/;
const HOURS_AND_MINUTES_REGEX = /([01][0-9]|2[0-3]):[0-5][0-9]/;

export class GmbTimePeriodString extends Record<{
  value: string;
}>({
  value: '00:00',
}) {
  constructor(value = '00:00') {
    const params = { value };
    super(params);
  }

  static fromDate(date: Date) {
    return new GmbTimePeriodString(dayjs(date).format('HH:mm'));
  }

  get hour() {
    const match = this.value.match(HOUR_REGEX);
    if (!match) return 0;
    return Number(match[1]);
  }

  get hourString() {
    const match = this.value.match(HOUR_REGEX);
    if (!match) return '';
    return match[1];
  }

  toString() {
    return this.value;
  }

  get toDayjs() {
    if (this.isValid) {
      return dayjs(this.value, 'HH:mm');
    }
    return null;
  }

  /** 時分形式のバリデーション */
  get isValid() {
    return HOURS_AND_MINUTES_REGEX.test(this.value);
  }

  isBefore(time: GmbTimePeriodString) {
    return this.toDayjs?.isBefore(time.toDayjs);
  }
}
