import styled from 'styled-components';

import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';

export const TabContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 72px;
  display: flex;
  margin-bottom: 24px;
  border-bottom: 2px solid ${COLOR.LIGHT_GRAY};
  z-index: 2;
  background: ${COLOR.BACKGROUND};
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled.div<{ active: boolean }>`
  ${Mixins.flex('center')}
  font-weight: bold;
  height: 68px;
  padding: 0 24px;
  border-bottom: ${(props) => `3px solid ${props.active ? COLOR.GREEN : COLOR.BACKGROUND}`};
  cursor: pointer;
  color: ${(props) => (props.active ? COLOR.BLACK : COLOR.GRAY)};
  white-space: nowrap;
  &:hover {
    color: ${COLOR.BLACK};
    border-bottom: ${(props) => `3px solid ${props.active ? COLOR.DARK_GREEN : COLOR.LIGHT_GREEN}`};
  }
`;
