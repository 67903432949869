import React from 'react';

import { Form } from 'semantic-ui-react';

import { Input } from 'components/atoms/Input';
import { Store } from 'models/Domain/Store';

type Props = { store: Store; changeStore: (store: Store) => void };

const SingleRegister: React.FC<Props> = ({ store, changeStore }) => {
  const validateResult = store.validate();

  return (
    <Form>
      <Form.Field>
        <label>店舗コード</label>
        <Input
          value={store.code}
          error={!validateResult.code.isValid && validateResult.code.error}
          onChange={(value: string) => changeStore(store.changeCode(value))}
        />
      </Form.Field>
      <Form.Field required>
        <label>店名</label>
        <Input
          value={store.name}
          error={!validateResult.name.isValid && validateResult.name.error}
          onChange={(value: string) => changeStore(store.changeName(value))}
        />
      </Form.Field>
      <Form.Field>
        <label>支店名</label>
        <Input
          value={store.branch}
          error={!validateResult.branch.isValid && validateResult.branch.error}
          onChange={(value: string) => changeStore(store.changeBranchName(value))}
        />
      </Form.Field>
    </Form>
  );
};

export default SingleRegister;
