import React, { useCallback, useEffect, useState } from 'react';

import { Icon as SemanticIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import {
  InstagramPostTargetKeywordRule,
  InstagramPostTargetKeywordRuleItem,
  KeywordRuleType,
} from 'models/Domain/instagram';
import { COLOR } from 'style/color';
import { SelectOption } from 'types/Common';

type Props = {
  keywordRule: InstagramPostTargetKeywordRule;
  onChange?: (value: InstagramPostTargetKeywordRule) => void;
};

export const keywordOptions: SelectOption<KeywordRuleType>[] = [
  { text: '次を含む', value: 'match' },
  { text: '次を含まない', value: 'exclude' },
];

export const InstagramPostTargetKeywordRuleEdit = React.memo<Props>(({ keywordRule, onChange }) => {
  const [editingKeywordRule, setEditingKeywordRule] = useState(keywordRule);

  useEffect(() => {
    setEditingKeywordRule(
      keywordRule.items.isEmpty()
        ? keywordRule.update('items', (items) => items.push(new InstagramPostTargetKeywordRuleItem()))
        : keywordRule,
    );
  }, [keywordRule]);

  const onChangeKeywordRule = useCallback(
    (value: InstagramPostTargetKeywordRule) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  const handleOnChangeType = useCallback(
    (index: number, value: KeywordRuleType) => {
      const newValue = editingKeywordRule.setIn(['items', index, 'type'], value);
      onChangeKeywordRule(newValue);
    },
    [editingKeywordRule, onChangeKeywordRule],
  );

  const handleOnChangeWord = useCallback((index: number, value: string) => {
    setEditingKeywordRule((editingKeywordRule) => editingKeywordRule.setIn(['items', index, 'word'], value));
  }, []);

  const handleOnBlurWord = useCallback(
    (index: number, value: string) => {
      const newValue = editingKeywordRule.setIn(['items', index, 'word'], value);
      onChangeKeywordRule(newValue);
    },
    [editingKeywordRule, onChangeKeywordRule],
  );

  const handleOnDelete = useCallback(
    (index: number) => {
      const newValue = editingKeywordRule.update('items', (items: InstagramPostTargetKeywordRule['items']) =>
        items.remove(index),
      );
      onChangeKeywordRule(newValue);
    },
    [editingKeywordRule, onChangeKeywordRule],
  );

  const handleOnAdd = useCallback(() => {
    const newValue = editingKeywordRule.update('items', (items: InstagramPostTargetKeywordRule['items']) =>
      items.push(new InstagramPostTargetKeywordRuleItem()),
    );
    onChangeKeywordRule(newValue);
  }, [editingKeywordRule, onChangeKeywordRule]);

  return (
    <Wrapper>
      {editingKeywordRule.items.map((rule, index) => {
        const isDuplicated =
          rule.word && editingKeywordRule.items.slice(0, index).filter((item) => item.word === rule.word).size > 0;
        return (
          <FlexContent key={index}>
            {editingKeywordRule.items.size > 1 && <Label>{index !== 0 && 'かつ'}</Label>}
            <StyledPullDown
              options={keywordOptions}
              value={rule.type}
              onChange={(value) => handleOnChangeType(index, value)}
            />
            <StyledInput
              value={rule.word}
              onChange={(value) => handleOnChangeWord(index, value)}
              onBlur={(e) => handleOnBlurWord(index, e.target.value)}
            />
            <DeleteButton onClick={() => handleOnDelete(index)}>
              <StyledIcon name={'trash alternate outline'} />
            </DeleteButton>
            <Error>
              {isDuplicated && (
                <>
                  <StatusIcon type={'status_error'} />
                  重複
                </>
              )}
            </Error>
          </FlexContent>
        );
      })}
      <ButtonWrapper>
        <AddButton onClick={handleOnAdd}>
          <StyledIcon name={'add'} />
          条件を追加する
        </AddButton>
        {editingKeywordRule.isValid || <Error>同じキーワードは１つのみ設定できます</Error>}
      </ButtonWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-top: 16px;
`;

const Label = styled.div`
  font-weight: bold;
  min-width: 40px;
  text-align: center;
`;

const FlexContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const StyledPullDown = styled(PullDown)`
  &&&& {
    .ui.dropdown.search {
      min-width: 10em;
    }
  }
`;

const StyledInput = styled(Input)`
  &&& {
    flex: 1;
    input {
      height: 60px;
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
    }
  }
`;

const AddButton = styled(Button)`
  &&& {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 12px 27px;
    width: auto;
  }
`;
const DeleteButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    color: ${COLOR.CHARCOAL_GRAY};
  }
`;
const StyledIcon = styled(SemanticIcon)`
  &&& {
    line-height: 1;
    height: auto;
    color: ${COLOR.GREEN};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const Error = styled.div`
  color: ${COLOR.ERROR};
  display: flex;
  min-width: 64px;
`;

const StatusIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 4px;
`;
