import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

import { Pagination } from '../Pagination';

export class MapSearchRankConfig extends Record<{
  id: number;
  storeId: number;
  areaName: string;
  searchWord: string;
  latitude: number;
  longitude: number;
  zoom: number;
  isDeleted: boolean;
  createAt: Dayjs;
  updateAt: Dayjs;
}>({
  id: 0,
  storeId: 0,
  areaName: '',
  searchWord: '',
  latitude: 0,
  longitude: 0,
  zoom: 0,
  isDeleted: false,
  createAt: dayjs(),
  updateAt: dayjs(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankConfig({
      id: data.id,
      storeId: data.store_id,
      areaName: data.area_name,
      searchWord: data.search_word,
      latitude: data.latitude,
      longitude: data.longitude,
      zoom: data.zoom,
      isDeleted: data.is_deleted,
      createAt: dayjs.utc(data.create_at).local(),
      updateAt: dayjs.utc(data.update_at).local(),
    });
  }

  get coordinateMapURL() {
    return `https://www.google.com/maps?q=${this.latitude},${this.longitude}`;
  }
}

export class MapSearchRankConfigData extends Record<{
  pagination: Pagination;
  items: List<MapSearchRankConfig>;
}>({
  pagination: new Pagination(),
  items: List(),
}) {
  static fromJSON(data: JSObject) {
    return new MapSearchRankConfigData({
      pagination: Pagination.fromJSON(data.pagination),
      items: List(data.items.map((item: JSObject) => MapSearchRankConfig.fromJSON(item))),
    });
  }
}
