export const SIZE = {
  FONT: {
    SMALL: 12,
    BASE: 16,
    LARGE: 20,
    EXTRA_LARGE: 24,
  },
  LINE_HEIGHT: 1.8,
  LETTER_SPACING: 0.025,
  MAX_WIDTH: 100,
  MAX_HEIGHT: 100,
  WIDTH: {
    WIDTH_100: 100,
    WIDTH_200: 200,
    WIDTH_300: 300,
    WIDTH_400: 400,
    WIDTH_500: 500,
    WIDTH_600: 600,
  },
  MARGIN: {
    MARGIN_20: 20,
    MARGIN_40: 40,
    MARGIN_80: 80,
    MARGIN_100: 100,
    MARGIN_120: 120,
    MARGIN_140: 140,
  },
  SIDEBAR: '320px',
  MOBILE_TABLE: '767px', // SP用とPC用のテーブル表示が切り替わるサイズ
  MOBILE: '600px', // サイドバーの有無が切り替わるサイズ
  MOBILE_WITH_SIDEBAR: '920px', // モバイル表示+サイドバーのサイズ、
};
