import React, { useState } from 'react';

import { Dimmer, Sidebar } from 'semantic-ui-react';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import Logo from 'components/atoms/Logo';
import { UserThumbnail } from 'components/molecules/UserThumbnail';
import { AccountIndexModal } from 'components/organisms/AccountIndexModal';
import { NotificationModal } from 'components/organisms/NotificationModal';
import { User } from 'models/Domain/User';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

import { NotificationIcon } from './NotificationIcon';
import { SidebarContent } from './SidebarContent';

type Props = {
  currentUser: User;
  signOut: () => void;
  children: React.ReactNode;
};

const MenuLayout = React.memo<Props>(({ currentUser, signOut, children }) => {
  const [isOpenSideBar, setIsOpenSidebar] = useState<boolean>(false);
  const [isOpenAccountModal, setIsOpenAccountModal] = useState<boolean>(false);
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState<boolean>(false);

  const sidebarContent = (
    <SidebarContent
      setIsOpenSidebar={setIsOpenSidebar}
      currentUser={currentUser}
      setIsOpenAccountModal={setIsOpenAccountModal}
      isOpenNotificationModal={isOpenNotificationModal}
      setIsOpenNotificationModal={setIsOpenNotificationModal}
    />
  );

  return (
    <>
      <Wrapper>
        <TopMenu>
          <TopMenuLeft>
            <TopMenuIconWrapper onClick={() => setIsOpenSidebar(!isOpenSideBar)}>
              <Icon type='dot_menu' />
            </TopMenuIconWrapper>
          </TopMenuLeft>

          <TopMenuCenter to={Path.top}>
            <Logo />
          </TopMenuCenter>

          <Notification onClick={() => setIsOpenNotificationModal(true)} isSelected={isOpenNotificationModal} />

          <TopMenuRight>
            <TopMenuIconWrapper onClick={() => setIsOpenAccountModal(true)}>
              <UserThumbnail src={currentUser.profile_image_url} name={currentUser.last_name} />
            </TopMenuIconWrapper>
          </TopMenuRight>
        </TopMenu>

        <PcSidebar>{sidebarContent}</PcSidebar>

        <PhoneSidebar animation='overlay' visible={isOpenSideBar} onHide={() => setIsOpenSidebar(false)}>
          {sidebarContent}
        </PhoneSidebar>

        <ContentWrapper>{children}</ContentWrapper>

        <SidebarDimmer active={isOpenSideBar} page />
      </Wrapper>

      <NotificationModal open={isOpenNotificationModal} onClose={() => setIsOpenNotificationModal(false)} />

      <AccountIndexModal
        open={isOpenAccountModal}
        onClose={() => setIsOpenAccountModal(false)}
        currentUser={currentUser}
        signOut={signOut}
      />
    </>
  );
});

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background-color: inherit;
`;

const ContentWrapper = styled.div`
  width: calc(100vw - ${SIZE.SIDEBAR});
  height: 100%;
  min-height: 100vh;
  background-color: inherit;
  @media (max-width: ${SIZE.MOBILE}) {
    width: 100%;
    padding-top: 60px;
  }
`;

const TopMenu = styled.div`
  display: none;
  @media (max-width: ${SIZE.MOBILE}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 60px;
    background-color: ${COLOR.WHITE};
  }
`;

const TopMenuLeft = styled.div``;

const TopMenuIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const TopMenuCenter = styled(Link)`
  display: flex;
  margin: 0 auto;
`;

const TopMenuRight = styled.div`
  padding: 12px 0;
  @media (max-width: ${SIZE.MOBILE}) {
    display: flex;
    align-items: center;
    padding: 0;
  }
`;

const PcSidebar = styled.div`
  display: block;
  width: ${SIZE.SIDEBAR};
  flex: none;
  @media (max-width: ${SIZE.MOBILE}) {
    display: none;
  }
`;

const PhoneSidebar = styled(Sidebar)`
  &&& {
    display: none;
    @media (max-width: ${SIZE.MOBILE}) {
      display: block;
      width: ${SIZE.SIDEBAR} !important;
    }
  }
`;

const SidebarDimmer = styled(Dimmer)`
  &&& {
    z-index: 101;
    opacity: 0.6 !important;
  }
`;

const Notification = styled(NotificationIcon)`
  position: absolute;
  right: 58px;
`;

export default MenuLayout;
