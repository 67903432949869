import { Record } from 'immutable';

import { OfferCommon } from 'models/Composite/OfferCommon';
import { OfferType } from 'models/Domain/Offer';
import { User } from 'models/Domain/User';
import { JSObject } from 'types/Common';

export enum DestinationType {
  Unclosed = 'unclosed',
  All = 'all',
  StoreList = 'store_list',
  Store = 'store',
}

enum EditFormType {
  Default = 'default',
  Office = 'office',
  Store = 'store',
}

/**
 * OfferCommon と storeId, storeIds, storeListId のコンポジットモデル
 * オファー作成画面で利用
 *
 * FIXME: よりドメインに即したクラス名にしたい
 */
export class OfferStores extends Record<{
  offer: OfferCommon;
  storeId: number; // 送信先店舗を個別（複数NG）で選択する時
  storeIds: number[]; // 送信先を個別（複数OK）で選択する時
  storeListId: number; // 送信先をグループで選択する時
  destinationType: DestinationType;
}>({
  offer: new OfferCommon(),
  storeId: 0,
  storeIds: [],
  storeListId: 0,
  destinationType: DestinationType.Unclosed,
}) {
  isValid(user: User) {
    const {
      offer: { offer_type },
    } = this;
    switch (offer_type) {
      case 'task': {
        let isValidDestination = true;

        if (this.isHeadToStore) {
          isValidDestination = this.storeIds.length > 0;
        } else if (this.isHeadToStoreList) {
          isValidDestination = this.storeListId !== 0;
        }

        return this.offer.task.isValid && isValidDestination;
      }
      case 'report':
        return this.offer.report.isValid;
      case 'other_offer': {
        let isValidDestination = true;

        if (!user.isMemberUser) {
          isValidDestination = this.storeId !== 0;
        }

        return this.offer.other_offer.isValid && isValidDestination;
      }
      default:
        return false;
    }
  }

  isValidOfferTypeFromUser(user: User) {
    const {
      offer: { offer_type },
    } = this;

    if (user.isMemberUser && offer_type === 'task') {
      return false;
    }
    if (!user.isMemberUser && offer_type === 'report') {
      return false;
    }
    return true;
  }

  get isTask() {
    return this.offer.offer_type === 'task';
  }

  get destination() {
    return this.isTask ? this.storeIds : this.storeId;
  }

  get isHeadToStore() {
    return this.destinationType === DestinationType.Store;
  }

  get isHeadToStoreList() {
    return this.destinationType === DestinationType.StoreList;
  }

  editFormType(user: User) {
    return user.isMemberUser ? EditFormType.Store : EditFormType.Office;
  }

  get destinationTypeOptions() {
    return this.offer.offer_type === 'task'
      ? [
          { value: DestinationType.Unclosed, text: '全ての店舗（閉業店舗を除く）' },
          { value: DestinationType.All, text: '全ての店舗（閉業店舗を含む）' },
          { value: DestinationType.StoreList, text: 'グループから選ぶ' },
          { value: DestinationType.Store, text: '店舗を個別に選ぶ' },
        ]
      : [{ value: DestinationType.Store, text: '店舗を個別に選ぶ' }];
  }

  requestParams() {
    const {
      offer: { offer_type },
    } = this;
    const params: JSObject = { offer: { offer_type }, task: null, report: null, other_offer: null };
    switch (offer_type) {
      case 'task':
        // taskの時のみ、task単体で返す
        return this.offer.task.requestParams();
      case 'report':
        params.report = this.offer.report.requestParams();
        break;
      case 'other_offer':
        params.other_offer = this.offer.other_offer.requestParams();
        break;
    }
    return params;
  }

  requestBulkParams() {
    return {
      destination_type: this.destinationType,
      store_list_id: this.storeListId,
      store_ids: this.storeIds,
    };
  }

  changeOffer(offerCommon: OfferCommon) {
    return this.set('offer', offerCommon);
  }

  changeStoreId(storeId: number) {
    return this.set('storeId', storeId);
  }

  changeStoreIds(storeIds: number[]) {
    return this.set('storeIds', storeIds);
  }

  changeStoreListId(storeListId: number) {
    return this.set('storeListId', storeListId);
  }

  changeDestinationType(destinationType: DestinationType) {
    return this.set('destinationType', destinationType);
  }

  changeOfferType(offer_type: OfferType) {
    let newOfferStores;

    if (offer_type === 'task') {
      newOfferStores = this.update('offer', (offer) => offer.changeOfferType(offer_type));
    } else {
      newOfferStores = this.update('offer', (offer) => offer.changeOfferType(offer_type)).changeDestinationType(
        DestinationType.Store,
      );
    }

    return newOfferStores;
  }

  changeDefaultSenderStoreFromUser(user: User) {
    const defaultStore = user.stores.get(0) || 0;
    return this.set('storeId', defaultStore);
  }

  changeOfferTypeFromUser(user: User) {
    const {
      offer: { offer_type },
    } = this;

    if (user.isMemberUser && offer_type === 'task') {
      return this.update('offer', (offer) => offer.changeOfferType('report'));
    }

    if (user.isMemberUser && offer_type === 'report') {
      return this.update('offer', (offer) => offer.changeOfferType('task'));
    }

    return this;
  }

  changeName(name: string) {
    return this.set('offer', this.offer.changeName(name));
  }

  changeText(text: string) {
    return this.set('offer', this.offer.changeText(text));
  }

  changeTags(tags: string[]) {
    return this.set('offer', this.offer.changeTags(tags));
  }

  addFiles(urls: string[]) {
    return this.set('offer', this.offer.addFiles(urls));
  }

  deleteFile(index: number) {
    return this.set('offer', this.offer.deleteFile(index));
  }

  changeFileCaption(index: number, caption: string) {
    return this.set('offer', this.offer.changeFileCaption(index, caption));
  }

  changeStores(value: number | number[]) {
    return this.isTask ? this.changeStoreIds(value as number[]) : this.changeStoreId(value as number);
  }
}
