import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List as ImmutableList, Set as ImmutableSet } from 'immutable';
import { useForm } from 'react-hook-form';
import { Checkbox, Icon as SemanticUIIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import { Prompt } from 'components/atoms/Prompt';
import { PullDown } from 'components/atoms/PullDown';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { ServiceHelp as Help } from 'helpers/ContextHelp';
import { nonNullable } from 'helpers/utils';
import { useConfirmLeave } from 'hooks/useConfirmLeave';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import {
  MAX_SERVICE_GROUP_NAME_LENGTH,
  ServiceGroup,
  ServiceList as ServiceListModel,
} from 'models/Domain/Service/Service';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';

import { ServiceList } from './ServiceList';
import { ServiceSortModal } from './ServiceSortModal';

const promptMessage = 'ページから離れようとしています。\n保存されていない内容は失われてしまいますが、よろしいですか？';

type Props = {
  serviceGroup: ServiceGroup;
  stores: Stores;
  storeLists: StoreLists;
  categoryList: GmbCategories;
  onSave: (serviceGroup: ServiceGroup) => void;
  onCancel: () => void;
};

type FormInputs = {
  name: string;
  applyToGbp: boolean;
  storeIds: ImmutableList<number> | null;
  groupIds: ImmutableList<number> | null;
  categoryId: string;
  services: ServiceListModel;
};

export const ServiceGroupEditForm: React.FC<Props> = ({
  serviceGroup: defaultServiceGroup,
  stores,
  storeLists,
  categoryList,
  onSave,
  onCancel,
}) => {
  const [showSortModal, setShowSortModal] = useState(false);
  const [showGroupSelectOnly, setShowGroupSelectOnly] = useState(defaultServiceGroup.storeIds === null);
  const [showPrompt, setShowPrompt] = useState<boolean>(true);
  const {
    register,
    formState: { errors, isDirty, isValid, isSubmitted, defaultValues },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<FormInputs>({
    mode: 'onSubmit',
    defaultValues: {
      name: defaultServiceGroup.name,
      applyToGbp: defaultServiceGroup.applyToGbp,
      storeIds: defaultServiceGroup.storeIds,
      groupIds: defaultServiceGroup.groupIds,
      categoryId: defaultServiceGroup.categoryId,
      services: defaultServiceGroup.services,
    },
  });
  const services = watch('services');

  // データが変更されている場合、タブを閉じる前に確認ダイアログを表示
  useConfirmLeave(showPrompt && isDirty);

  const selectedStores = (() => {
    // 選択中の店舗があればそのIDで絞り込む、なければ全店舗を対象にする
    const storeIds = watch('storeIds')?.toArray() ?? null;
    const groupId = watch('groupIds')?.get(0) ?? null;
    let selectedStores = storeIds ? stores.filterStoresById(storeIds) : stores;
    // グループが選択されている場合はグループに絞り込む
    if (groupId != null) {
      selectedStores = selectedStores.filterStoresByStoreListId(storeLists, groupId);
    }
    // 営業中のGBP連携店舗のみに絞り込む
    // selectedStores = selectedStores.filterByIsConnectedGmb().filterByUnclosed();
    return selectedStores;
  })();

  const selectableCategoryIds = useMemo(() => {
    let categoryIds = ImmutableSet<string>();
    selectedStores.list.forEach((store) => {
      // 店舗の主カテゴリと追加カテゴリを重複しないように追加
      const primaryCategoryId = store.location.primaryCategory.categoryId;
      categoryIds = categoryIds.add(primaryCategoryId);
      const additionalCategoryIds = store.location.additionalCategories.list.map((c) => c.categoryId);
      categoryIds = categoryIds.union(additionalCategoryIds);
    });
    return categoryIds;
  }, [selectedStores]);

  const categoryOptions = useMemo(() => {
    return selectableCategoryIds
      .map((categoryId) => {
        const category = categoryList.findByCategoryId(categoryId);
        if (!category) return null;
        return {
          text: category.displayName,
          value: category.categoryId,
        };
      })
      .filter(nonNullable)
      .toArray();
  }, [selectableCategoryIds, categoryList]);

  const onSubmit = useCallback(
    (data: FormInputs) => {
      const serviceGroupForEdit = new ServiceGroup({
        id: defaultServiceGroup.id,
        name: data.name,
        applyToGbp: data.applyToGbp,
        storeIds: data.storeIds,
        groupIds: data.groupIds,
        categoryId: data.categoryId,
        services: data.services,
      });
      onSave(serviceGroupForEdit);
      setShowPrompt(false);
    },
    [onSave, defaultServiceGroup.id],
  );

  const onError = useCallback(() => {
    window.alert('入力内容に誤りがあります。入力内容をご確認ください。');
  }, []);

  useEffect(() => {
    register('name', {
      required: { value: true, message: 'サービスグループ名は必須です' },
      maxLength: {
        value: MAX_SERVICE_GROUP_NAME_LENGTH,
        message: `サービスグループ名は${MAX_SERVICE_GROUP_NAME_LENGTH}文字以内で入力してください`,
      },
    });
    register('storeIds', {
      validate: (value) => {
        // 店舗選択が可能な状態で１つも選択していなければエラー
        if (!showGroupSelectOnly && (!value || value.isEmpty())) {
          return '店舗を選択してください';
        }
        return true;
      },
    });
    register('categoryId', { validate: (value) => (value ? true : 'カテゴリは必須です') });
    register('services', { validate: (value) => (value.items.size > 0 ? true : 'サービスを１つ以上設定してください') });
  }, [register, showGroupSelectOnly]);

  const categoryWarning = (() => {
    const selectedCategory = watch('categoryId');
    const categoryName = categoryOptions.find((option) => option.value === selectedCategory)?.text;
    if (!selectedCategory) {
      return null;
    }
    const filteredStores = selectedStores.list.filter((store) => {
      const primaryCategory = store.location.primaryCategory;
      const additionalCategories = store.location.additionalCategories;
      const categories = ImmutableSet([
        primaryCategory.categoryId,
        ...additionalCategories.list.map((category) => category.categoryId).toArray(),
      ]);
      return !categories.has(selectedCategory);
    });
    const firstStoreName = filteredStores.get(0)?.fullName;
    if (firstStoreName && categoryName) {
      return `「${firstStoreName}」${
        filteredStores.size > 1 ? `ほか${filteredStores.size}店舗` : ''
      }には「${categoryName}」カテゴリは設定されていません。カテゴリが設定されていない店舗には、この設定は反映されません。`;
    }
    return null;
  })();

  return (
    <Wrapper>
      <Header>
        <CheckboxWrapper>
          GBP連携
          <ContextHelp content={Help.applyToGbp} />
          <StyledCheckbox
            toggle
            onChange={async () => {
              const currentValue = watch('applyToGbp');
              setValue('applyToGbp', !currentValue, { shouldDirty: true });
              await trigger('applyToGbp');
            }}
            checked={watch('applyToGbp')}
          />
        </CheckboxWrapper>
        {defaultServiceGroup.updateAt && (
          <UpdateAt>最終更新日時：{defaultServiceGroup.updateAt.format('YYYY/MM/DD')}</UpdateAt>
        )}
      </Header>
      <FormContent name={'サービスグループ名'} required informationText={Help.serviceGroupName}>
        <StyledInput
          name={'name'}
          onChange={async (value: string) => {
            setValue('name', value, { shouldDirty: true });
            await trigger('name');
          }}
          defaultValue={defaultValues?.name}
          error={isSubmitted && errors.name?.message}
          data-testid={'nameInput'}
        />
        <StyledTextCount maxSize={MAX_SERVICE_GROUP_NAME_LENGTH} size={watch('name').length} />
      </FormContent>
      <FormContent name={'対象店舗・グループ'} required informationText={Help.groupStore}>
        <GroupStoreSelect
          group={watch('groupIds')?.get(0) ?? 'all'}
          storeIds={watch('storeIds')?.toSet() ?? ImmutableSet([])}
          showClosedStores={false}
          showClosedStoreCheckbox={false}
          onChange={async (group, storeIds, isAllStores, showClosedStores, showGroupSelectOnly) => {
            setValue('groupIds', typeof group === 'number' ? ImmutableList([group]) : null, { shouldDirty: true });
            setValue('storeIds', showGroupSelectOnly ? null : ImmutableList(storeIds), { shouldDirty: true });
            setShowGroupSelectOnly(showGroupSelectOnly);
            await trigger('groupIds');
            await trigger('storeIds');
          }}
          sizeVariant={'large'}
          errors={{ store: errors.storeIds?.message }}
          showGroupSelectOnly={showGroupSelectOnly}
          canSwitchSelectionMode={true}
          storeFilter={(store) => !!store}
        />
      </FormContent>
      <FormContent name={'対象カテゴリ'} required>
        <PullDown
          options={categoryOptions}
          value={watch('categoryId')}
          onChange={async (value: string) => {
            // プリセットのカテゴリが既に設定されている場合、削除されてしまうが問題ないか確認する
            if (
              watch('categoryId') &&
              watch('services').structuredServices.size > 0 &&
              !window.confirm(
                'カテゴリを変更すると、カテゴリに紐づくプリセットのサービスが削除されますがよろしいですか？',
              )
            ) {
              return;
            }
            setValue('categoryId', value, { shouldDirty: true });
            setValue('services', watch('services').removeStructuredService(), { shouldDirty: true });
            await trigger('categoryId');
            await trigger('services');
          }}
        />
        {isSubmitted && errors.categoryId?.message && <DummyInput error={errors.categoryId.message} />}
        {categoryWarning && (
          <Warning>
            <SemanticUIIcon name={'exclamation triangle'} />
            {categoryWarning}
          </Warning>
        )}
      </FormContent>
      <FormContent
        name={'サービスの内容'}
        required
        additionalContents={
          <SortContainer>
            <TextButton onClick={() => setShowSortModal(true)}>並び替え</TextButton>
            <ContextHelp content={Help.serviceSort} />
          </SortContainer>
        }
      >
        <ServiceList
          services={services}
          categoryId={watch('categoryId')}
          onChange={async (value: ServiceListModel) => {
            setValue('services', value, { shouldDirty: true });
            await trigger('services');
          }}
        />
        {isSubmitted && errors.services?.message && <DummyInput error={errors.services?.message} />}
      </FormContent>
      <ButtonWrapper>
        <StyledButton onClick={onCancel}>キャンセル</StyledButton>
        <StyledButton priority={'high'} onClick={handleSubmit(onSubmit, onError)} disabled={isSubmitted && !isValid}>
          保存
        </StyledButton>
      </ButtonWrapper>

      {showSortModal && (
        <ServiceSortModal
          services={services}
          onClose={() => setShowSortModal(false)}
          onChange={async (value: ServiceListModel) => {
            setValue('services', value, { shouldDirty: true });
            await trigger('services');
          }}
          isOpen={true}
        />
      )}
      <Prompt when={showPrompt && isDirty} message={promptMessage} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 24px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
  &&& {
    input:focus:checked ~ label:before,
    input:checked ~ label:before {
      background-color: #05ccad !important;
    }
  }
`;

const UpdateAt = styled.div``;

const StyledInput = styled(Input)``;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const SortContainer = styled.div`
  margin-left: auto;
`;

const TextButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    padding: 1px 0;
  }
`;

const DummyInput = styled(Input)`
  margin-top: -8px;
  &&& {
    input {
      display: none;
    }
  }
`;

const Warning = styled.div`
  font-size: 14px;
  margin: 4px 8px;

  color: #f09061;

  i {
    height: auto;
  }
`;
