import React, { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import { StoreSearchCondition } from 'models/Domain/Omo/StoreSearchCondition';
import { StoreLists } from 'models/Domain/StoreList';
import { Mixins } from 'style/mixin';

type Props = {
  className?: string;
  searchCondition: StoreSearchCondition;
  storeLists: StoreLists;
};

/**
 * 店舗の検索条件を指定するコンポーネント
 */
export const StoreSearchConditionSummary: React.FC<Props> = ({ className, searchCondition, storeLists }) => {
  const sortTypeLabel = useMemo(() => {
    if (searchCondition.sort.key === 'id') {
      return searchCondition.sort.order === 'desc' ? '登録日が新しい順' : '登録日が古い順';
    }
    if (searchCondition.sort.key === 'code') {
      return searchCondition.sort.order === 'desc' ? '店舗コード逆順' : '店舗コード順';
    }
    return '指定なし';
  }, [searchCondition]);
  const getSelectedStoreListConditionText = useCallback((): string => {
    const storeListId = searchCondition.filter.storeListId;
    if (storeListId === 'all') {
      return '全ての店舗';
    } else if (storeListId === 'my_store') {
      return 'あなたの所属/管理店舗';
    } else if (storeListId) {
      const storeList = storeLists.findStoreList(storeListId);
      if (storeList) {
        return storeList.name;
      }
    }
    return '';
  }, [searchCondition.filter.storeListId, storeLists]);

  const searchValue = searchCondition.filter.searchValue;
  const group = getSelectedStoreListConditionText();

  return (
    <Wrapper className={className}>
      {searchValue && (
        <div>
          <span>検索ワード</span>: {searchValue}
        </div>
      )}
      {group && (
        <div>
          <span>グループ</span>: {getSelectedStoreListConditionText()}
        </div>
      )}
      <div>
        <span>並び順</span>: {sortTypeLabel}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  ${Mixins.flex('center')}
  gap: 8px;
  border-radius: 5px;
  padding: 8px 16px;
  background: #fff;

  flex-direction: column;
  align-items: unset;

  span {
    font-weight: bold;
  }
`;
