import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { Arrow } from 'components/atoms/Arrow';
import { BusinessStatusFilter } from 'components/pageComponents/StoreIndex/BusinessStatusFilter';
import { KeywordFilter } from 'components/pageComponents/StoreIndex/KeywordFilter';
import { SortStoreFilter, SortTypeOptions } from 'components/pageComponents/StoreIndex/SortStoreFilter';
import { StoreListFilter } from 'components/pageComponents/StoreIndex/StoreListFilter';
import { SortType } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { SortKey, SortOrder, StoreListIdType, StoreSearchCondition } from 'models/Domain/StoreSearchCondition';
import { BulkEditStoreState } from 'models/Other/BulkEditStoreState';
import { COLOR } from 'style/color';

/**
 * 店舗の検索条件を指定するコンポーネント
 */
export const StoreFilter: React.FC<{
  className?: string;
  storeLists: StoreLists;
  displayManagedList: boolean;
  setBulkEditStoreState: (v: BulkEditStoreState) => void;
  searchCondition: StoreSearchCondition;
  setSearchCondition: (searchCondition: StoreSearchCondition) => void;
  onChangeSearchCondition: (searchCondition: StoreSearchCondition) => void;
  showSwitch: boolean;
}> = ({
  className,
  storeLists,
  displayManagedList,
  searchCondition,
  setSearchCondition,
  onChangeSearchCondition,
  showSwitch,
}) => {
  // 店舗一覧のサイズによってレイアウトが違うが、画面幅だけではなくコンテンツにも依存するため、メディアクエリだけでは実装できなかったので、
  // 折りたたみのSwitchを表示しているかのフラグ(showSwitch)も組み合わせて対応

  const [showFilter, setShowFilter] = useState(false);

  const { key: sortKey, order: sortOrder } = searchCondition.sort;
  const filter = searchCondition.filter;
  const { searchValue, storeListId, showClosedStore } = filter;
  const selectedSortId = `${sortKey}_${sortOrder}` as SortType;

  const onChangeSearchValue = useCallback(
    (value: string) => {
      const updatedCondition = searchCondition.setIn(['filter', 'searchValue'], value);
      onChangeSearchCondition(updatedCondition);
    },
    [searchCondition, onChangeSearchCondition],
  );

  const onChangeStoreListId = useCallback(
    (value: StoreListIdType) => {
      if (!value) {
        value = 'all';
      }
      const updatedCondition = searchCondition.setIn(['filter', 'storeListId'], value);
      onChangeSearchCondition(updatedCondition);
    },
    [onChangeSearchCondition, searchCondition],
  );

  const onChangeShowClosedStore = useCallback(
    (value: boolean) => {
      const updatedCondition = searchCondition.setIn(['filter', 'showClosedStore'], value);
      onChangeSearchCondition(updatedCondition);
    },
    [onChangeSearchCondition, searchCondition],
  );

  const changeSort = useCallback(
    (value: string) => {
      const splitParam = value.split('_');
      const sortKey = splitParam[0] as SortKey;
      const sortOrder = splitParam[1] as SortOrder;
      const updatedCondition = searchCondition.setSortCondition(sortKey, sortOrder);
      setSearchCondition(updatedCondition);
    },
    [searchCondition, setSearchCondition],
  );

  const getSelectedStoreListConditionText = (): string => {
    if (storeListId === 'all') {
      return 'すべて';
    } else if (storeListId === 'managed_list') {
      return 'あなたの所属/管理店舗';
    } else {
      const selectedOption = storeLists.options.find((option) => option.value === storeListId);
      if (selectedOption) {
        return selectedOption.text;
      }
    }
    return '';
  };

  const getSortConditionText = (): string => {
    const selectedOption = SortTypeOptions.find((option) => option.value === selectedSortId) || SortTypeOptions[0];
    return selectedOption.text;
  };

  return (
    <Wrapper className={className}>
      <FilterWrapper>
        <StyledKeywordFilter onChange={onChangeSearchValue} value={searchValue} showSwitch={showSwitch} />
        {(!showSwitch || showFilter) && (
          <>
            <ContentWrapper showSwitch={showSwitch}>
              <StoreListFilter
                selectedStoreListId={storeListId}
                storeLists={storeLists}
                setStoreListId={onChangeStoreListId}
                displayManagedList={displayManagedList}
              />
            </ContentWrapper>
            <ContentWrapper showSwitch={showSwitch}>
              <BusinessStatusFilter onChange={onChangeShowClosedStore} selectedValue={showClosedStore} />
            </ContentWrapper>
            <ContentWrapper showSwitch={showSwitch}>
              <SortStoreFilter selectedSortId={selectedSortId} changeSort={changeSort} />
            </ContentWrapper>
          </>
        )}
      </FilterWrapper>
      {(!showSwitch || showFilter) && (
        <ConditionWrapper>
          {getSelectedStoreListConditionText() && (
            <Condition>
              <ConditionLabel>グループ:</ConditionLabel>
              {getSelectedStoreListConditionText()}
            </Condition>
          )}
          <Condition>
            <ConditionLabel>閉業店舗:</ConditionLabel>
            {showClosedStore ? '表示する' : '表示しない'}
          </Condition>
          <Condition>
            <ConditionLabel>並び順:</ConditionLabel>
            {getSortConditionText()}
          </Condition>
        </ConditionWrapper>
      )}
      {showSwitch && (
        <Switch open={showFilter} onClick={() => setShowFilter(!showFilter)}>
          <SwitchLabel>{showFilter ? '絞り込み条件を閉じる' : '絞り込み条件を開く'}</SwitchLabel>
          <SwitchArrow direction={showFilter ? 'up' : 'down'} />
        </Switch>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div<{ showSwitch: boolean }>`
  display: flex;
  margin-right: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    margin: 4px 0 0;
  }

  ${({ showSwitch }) =>
    showSwitch &&
    `
    width: 100%;
    flex-direction: column;
    margin: 4px 0;
  `}
`;

const ConditionWrapper = styled.div`
  background: rgba(219, 219, 219, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 8px 16px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Condition = styled.div`
  margin-right: 18px;
  font-size: 14px;
`;
const ConditionLabel = styled.span`
  font-weight: bold;
  margin-right: 4px;
  font-size: 14px;
`;

const StyledKeywordFilter = styled(KeywordFilter)<{ showSwitch: boolean }>`
  width: 100%;
  max-width: 330px;
  padding: 8px 0;
  margin-right: ${({ showSwitch }) => (showSwitch ? '0' : '32px')};
  @media (max-width: 800px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const Switch = styled.div<{ open: boolean }>`
  background: ${COLOR.WHITE};
  height: 36px;
  cursor: pointer;
  padding: 0 16px;
  margin-bottom: 8px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.2px;
  color: ${COLOR.BLACK};
`;

const SwitchArrow = styled(Arrow).attrs(() => ({
  color: COLOR.BLACK,
  length: 12,
  weight: 2,
}))`
  margin-left: auto;
`;
