import { Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { validatePostalCode } from 'helpers/utils';
import { JSObject } from 'types/Common';

// https://developers.google.com/my-business/reference/rest/v4/PostalAddress
export class GmbAddress extends Record<{
  postalCode: string;
  administrativeArea: string;
  address1: string;
  address2: string;
}>({
  postalCode: '',
  administrativeArea: '',
  address1: '',
  address2: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.address1 = params.addressLines && params.addressLines[0] ? params.addressLines[0] : '';
    params.address2 = params.addressLines && params.addressLines[1] ? params.addressLines[1] : '';
    super(params);
  }

  get isExist() {
    return Boolean(this.postalCode && this.administrativeArea && this.address1);
  }

  get searchWord() {
    return this.administrativeArea + this.address1 + this.address2;
  }

  changePostalCode(postalCode: string) {
    return this.set('postalCode', postalCode);
  }

  changeAdministrativeArea(administrativeArea: string) {
    return this.set('administrativeArea', administrativeArea);
  }

  changeAddress1(address: string) {
    return this.set('address1', address);
  }
  changeAddress2(address: string) {
    return this.set('address2', address);
  }

  validate() {
    const errors = {
      postalCode: this.validatePostalCode(),
      administrativeArea: this.validateAdministrativeArea(),
      address1: this.validateAddress1(),
      address2: this.validateAddress2(),
    };
    return errors;
  }

  validateAdministrativeArea() {
    let result = {
      isValid: true,
      error: '',
    };
    if (!this.administrativeArea) {
      result = {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    return result;
  }

  validatePostalCode() {
    if (!this.postalCode) {
      return {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    if (!validatePostalCode(this.postalCode)) {
      return {
        isValid: false,
        error: ErrorType.POSTAL_CODE_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  validateAddress1() {
    if (!this.address1) {
      return {
        isValid: false,
        error: ErrorType.REQUIRED_ERROR,
      };
    }
    return {
      isValid: true,
      error: '',
    };
  }

  validateAddress2() {
    return {
      isValid: true,
      error: '',
    };
  }

  updateParams() {
    const addressLines = [this.address1];
    if (this.address2) {
      addressLines.push(this.address2);
    }
    const params = {
      address: {
        postalCode: this.postalCode,
        administrativeArea: this.administrativeArea,
        addressLines,
        regionCode: 'JP',
      },
    };
    return params;
  }
}
