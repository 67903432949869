import dayjs, { Dayjs } from 'dayjs';
import { Record, Set } from 'immutable';

import { SearchStoreImagesParams } from 'ApiClient/StoreImagesApi';
import {
  booleanToSearchParam,
  numberComparator,
  parseBooleanParam,
  parseDateParameter,
  parseNumberParameter,
} from 'helpers/search';

export const SORT_KEYS = ['date'] as const;
export type SortKey = (typeof SORT_KEYS)[number];

export const SORT_ORDERS = ['asc', 'desc'] as const;
export type SortOrder = (typeof SORT_ORDERS)[number];

export const GBP_STATES = ['published', 'unpublished'] as const;
export type GBPState = (typeof GBP_STATES)[number];

export interface ImageSearchConditionRecord {
  // 絞り込み条件
  // グループ/店舗関連の条件
  store: number | 'all' | 'my_store' | null;
  storeIds: Set<number>;
  isAllStores: boolean; // store_ids が store で指定されているグループのすべての店舗か
  showClosedStores: boolean; // 閉店店舗を表示するか

  // タグ
  tag: string;

  // 写真登録日の開始日、終了日条件
  startDate: Dayjs | null;
  endDate: Dayjs | null;

  // GBP掲載状態の条件
  gbpState: Set<GBPState>;

  // ソート条件
  sortKey: SortKey;
  sortOrder: SortOrder;

  // ページ条件
  page: number;
  perPage: number;

  // カテゴリー
  category: string;
}

const DEFAULT_STORE_VALUE = null;
const DEFAULT_IS_ALL_STORE_IDS_VALUE = true;
const DEFAULT_SHOW_CLOSED_STORE_VALUE = false;
const DEFAULT_GBP_STATE = Set(['published', 'unpublished']) as Set<GBPState>;
const DEFAULT_SORT_KEY = 'date';
const DEFAULT_SORT_ORDER = 'desc';
const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;

// URLパラメータのマッピング
const URLSearchParamsMapping = {
  store: 'st',
  store_ids: 'si',
  show_closed_store: 'cs',
  tag: 'tg',
  start_date: 'sd',
  end_date: 'ed',
  gbp_state: 'gs',
  sort_key: 'sk',
  sort_order: 'so',
  page: 'pg',
  per_page: 'pp',
  category: 'cg',
};

export default class ImageSearchCondition extends Record<ImageSearchConditionRecord>({
  store: DEFAULT_STORE_VALUE,
  storeIds: Set<number>(),
  isAllStores: DEFAULT_IS_ALL_STORE_IDS_VALUE,
  showClosedStores: DEFAULT_SHOW_CLOSED_STORE_VALUE,
  tag: '',
  startDate: null,
  endDate: null,
  gbpState: DEFAULT_GBP_STATE,
  sortKey: DEFAULT_SORT_KEY,
  sortOrder: DEFAULT_SORT_ORDER,
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PER_PAGE,
  category: '',
}) {
  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({
      storeIds: storeIds,
      isAllStores: isAllStoreIds,
    });
  }

  /**
   * APIリクエスト用のパラメータに変換する
   */
  toRequestParams(): SearchStoreImagesParams {
    return {
      store_ids: this.storeIds.toArray().sort(numberComparator),
      tag: this.tag,
      start_date: this.startDate ? this.startDate.format('YYYY-MM-DD') : '',
      end_date: this.endDate ? this.endDate.format('YYYY-MM-DD') : '',
      state: this.gbpState.toArray().sort(),
      sort_key: this.sortKey,
      sort_order: this.sortOrder,
      page: this.page,
      limit: this.perPage,
      gbp_category: this.category,
    };
  }

  /**
   * 検索条件をページURL用のパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();
    if (this.store != DEFAULT_STORE_VALUE) {
      params.append(URLSearchParamsMapping.store, String(this.store));
    }

    if (!this.isAllStores && this.storeIds.size > 0) {
      params.append(URLSearchParamsMapping.store_ids, this.storeIds.toArray().sort(numberComparator).join(','));
    }

    if (this.showClosedStores != DEFAULT_SHOW_CLOSED_STORE_VALUE) {
      params.append(URLSearchParamsMapping.show_closed_store, booleanToSearchParam(this.showClosedStores));
    }

    if (this.tag) {
      params.append(URLSearchParamsMapping.tag, this.tag);
    }

    if (this.startDate !== null) {
      params.append(URLSearchParamsMapping.start_date, this.startDate.format('YYYY-MM-DD'));
    }

    if (this.endDate !== null) {
      params.append(URLSearchParamsMapping.end_date, this.endDate.format('YYYY-MM-DD'));
    }

    if (this.gbpState.size > 0 && !this.gbpState.equals(DEFAULT_GBP_STATE)) {
      params.append(URLSearchParamsMapping.gbp_state, this.gbpState.sort().join(','));
    }

    if (this.sortKey !== DEFAULT_SORT_KEY) {
      params.append(URLSearchParamsMapping.sort_key, this.sortKey);
    }

    if (this.sortOrder !== DEFAULT_SORT_ORDER) {
      params.append(URLSearchParamsMapping.sort_order, this.sortOrder);
    }

    if (this.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.page));
    }
    if (this.perPage !== DEFAULT_PER_PAGE) {
      params.append(URLSearchParamsMapping.per_page, String(this.perPage));
    }

    if (this.category) {
      params.append(URLSearchParamsMapping.category, this.category);
    }

    // 見栄え悪いので '%2C' は ',' に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * URLパラメータから検索条件を生成する
   * @param search
   */
  static fromURLSearchParams(search: string): ImageSearchCondition {
    let condition = new ImageSearchCondition();
    const searchParams = new URLSearchParams(search);

    const store = searchParams.get(URLSearchParamsMapping.store);
    if (store) {
      if (store === 'all' || store === 'my_store') {
        // すべての店舗もしくは担当店舗の場合
        condition = condition.set('store', store);
      } else if (store.match(/^\d+$/)) {
        // グループの場合
        condition = condition.set('store', parseInt(store, 10));
      }
    }

    const store_ids = searchParams.get(URLSearchParamsMapping.store_ids) || 'all';
    if (store_ids === 'all') {
      condition = condition.setStoreIds(Set<number>(), true);
    } else {
      const values = store_ids.split(',');
      const storeIds = values.filter((v) => v.match(/^\d+$/)).map((v) => parseInt(v, 10));
      condition = condition.setStoreIds(Set(storeIds), false);
    }

    const showClosedStore = parseBooleanParam(
      searchParams.get(URLSearchParamsMapping.show_closed_store),
      DEFAULT_SHOW_CLOSED_STORE_VALUE,
    );
    condition = condition.set('showClosedStores', showClosedStore);

    const tag = searchParams.get(URLSearchParamsMapping.tag) || '';
    condition = condition.set('tag', tag);

    const startDate = parseDateParameter(searchParams.get(URLSearchParamsMapping.start_date));
    if (startDate) {
      condition = condition.set('startDate', dayjs(startDate));
    }

    const endDate = parseDateParameter(searchParams.get(URLSearchParamsMapping.end_date));
    if (endDate) {
      condition = condition.set('endDate', dayjs(endDate));
    }

    const gbpStates = (searchParams.get(URLSearchParamsMapping.gbp_state) || '')
      .split(',')
      .filter((gs: any) => GBP_STATES.includes(gs)) as GBPState[];
    if (gbpStates.length > 0) {
      condition = condition.set('gbpState', Set(gbpStates));
    }

    // ソート関連
    const sortKey = (searchParams.get(URLSearchParamsMapping.sort_key) || '') as any;
    if (SORT_KEYS.includes(sortKey)) {
      condition = condition.set('sortKey', sortKey);
    }
    const sortOrder = (searchParams.get(URLSearchParamsMapping.sort_order) || '') as any;
    if (SORT_ORDERS.includes(sortOrder)) {
      condition = condition.set('sortOrder', sortOrder);
    }

    // ページネーション関連
    condition = condition.set(
      'page',
      parseNumberParameter(searchParams.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1),
    );
    condition = condition.set(
      'perPage',
      parseNumberParameter(searchParams.get(URLSearchParamsMapping.per_page), DEFAULT_PER_PAGE, 1, 100),
    );

    const category = searchParams.get(URLSearchParamsMapping.category) || '';
    condition = condition.set('category', category);

    return condition;
  }

  setPage(page: number) {
    return this.set('page', page);
  }
}
