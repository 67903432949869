import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

type Props = {
  className?: string;
  error?: string | boolean;
};

export const ErrorText = React.memo<Props>(({ className, error }) => {
  return <Wrapper className={className}>{error}</Wrapper>;
});

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;

  // TextArea のエラー文字列のサイズに合わせる
  font-size: 0.85714286rem;

  margin: 0 0.14285714em;
  background-image: none;
  padding: 0.5833em 0.833em;
  text-transform: none;
  font-weight: 700;
  border-radius: 0.28571429rem;
  transition: background 0.1s ease;
  margin-top: 1em;
  background: ${COLOR.WHITE};
  border: 1px solid ${COLOR.ERROR};
  color: ${COLOR.ERROR};
  border-radius: 0.28571429rem;
  &::before {
    background-color: inherit;
    border-width: 1px 0 0 1px;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 50%;
    position: absolute;
    content: '';
    background-image: none;
    z-index: 2;
    width: 0.6666em;
    height: 0.6666em;
    border-width: none;
    border-style: solid;
    border-color: inherit;
  }
`;
