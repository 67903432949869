import React, { useCallback, useEffect, useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { PullDown } from 'components/atoms/PullDown';
import { DisplayToGraphOption, MemoDisplaySettings } from 'helpers/graph';
import { MemoTag } from 'models/Domain/Memo/Memo';
import { COLOR } from 'style/color';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setting: MemoDisplaySettings;
  tags: ImmutableList<MemoTag>;
  onSave: (setting: MemoDisplaySettings) => void;
  isLoadingMemoTagList: boolean;
};

// グラフへの表示プルダウンの選択肢
const DISPLAY_TO_GRAPH_OPTIONS: { text: string; value: DisplayToGraphOption }[] = [
  { text: '開始日と終了日', value: 'PERIOD' },
  { text: '開始日のみ表示', value: 'START_DAY_ONLY' },
  { text: '表示しない', value: 'HIDE' },
];

/**
 * メモの表示設定モーダル
 *
 * @param isOpen モーダルの開閉状態
 * @param onClose モーダル外がクリックされた
 * @param setting 表示設定
 * @param tags タグの選択肢
 * @param onSave 表示設定が保存された
 * @param isLoadingMemoTagList タグリストが読み込み中か
 */
export const MemoDisplaySettingModal: React.FC<Props> = ({
  isOpen,
  onClose,
  setting,
  tags,
  onSave,
  isLoadingMemoTagList,
}) => {
  // 変更中の設定内容
  const [editingDisplaySetting, setEditingDisplaySetting] = useState<MemoDisplaySettings>(setting);
  // タグの選択肢をPullDownコンポーネントの型に変換
  const tagOptions = tags.map((tag) => ({ text: tag.tag, value: tag.tag }));

  // 表示設定が変更されたらモーダルの入力状態を合わせる
  useEffect(() => {
    setEditingDisplaySetting(setting);
  }, [setting, setEditingDisplaySetting]);

  // タグが変更された
  const onChangeTags = useCallback(
    (tags: string[]) => {
      const newSetting: MemoDisplaySettings = editingDisplaySetting.set('tags', ImmutableList(tags));
      setEditingDisplaySetting(newSetting);
    },
    [editingDisplaySetting, setEditingDisplaySetting],
  );

  // グラフへの表示が変更された
  const onChangeDisplayToGraph = useCallback(
    (displayToGraph: DisplayToGraphOption) => {
      const newSetting: MemoDisplaySettings = editingDisplaySetting.set('displayToGraph', displayToGraph);
      setEditingDisplaySetting(newSetting);
    },
    [editingDisplaySetting, setEditingDisplaySetting],
  );

  // 保存するボタンが押された
  const onClickSave = useCallback(() => {
    onSave(editingDisplaySetting);
    onClose();
  }, [onSave, onClose, editingDisplaySetting]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Content>
        <Wrapper>
          <Title>施策メモの表示設定</Title>
          <Divider />
          <Header>タグ</Header>
          <PullDown
            multiple={true}
            closeOnChange={false}
            value={editingDisplaySetting.tags.toArray()}
            options={tagOptions.toArray()}
            placeholder={'指定されたタグを含む施策メモが表示されます'}
            onChange={onChangeTags}
            loading={isLoadingMemoTagList}
          />
          <Header>グラフへの表示</Header>
          <PullDown
            value={editingDisplaySetting.displayToGraph}
            options={DISPLAY_TO_GRAPH_OPTIONS}
            onChange={onChangeDisplayToGraph}
          />
          <ActionWrapper>
            <CloseButton onClick={onClose}>閉じる</CloseButton>
            <SaveButton priority='high' onClick={onClickSave}>
              保存する
            </SaveButton>
          </ActionWrapper>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Divider = styled.hr`
  margin: 16px 0;
  border-top: 1px solid ${COLOR.GRAY};
  border-bottom: none;
`;

const Header = styled.div`
  font-size: 16px;
  margin: 30px 0 8px;
  font-weight: bold;
`;

const ActionWrapper = styled(Card)`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;

  &&& {
    padding-right: 0;
  }
`;

const CloseButton = styled(Button)`
  &&& {
    width: 180px;
  }
`;

const SaveButton = styled(Button)`
  margin-left: 16px;

  &&& {
    width: 180px;
  }
`;
