import React, { ReactNode, useCallback, useEffect, useRef } from 'react';

import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { COLOR } from 'style/color';

type Props = {
  className?: string;
  value: string;
  children: ReactNode;
  error?: boolean;
  sizeVariant?: 'large' | 'narrow';
  onClose?: () => void;
};

export const MultiSelectBox = React.memo<Props>(
  ({ className, children, value, error, sizeVariant = 'narrow', onClose }) => {
    // 開閉状態
    const [isOpenMenu, setIsOpenMenu] = React.useState(false);

    // コンポーネント全体の要素のref
    // クリックされたのがコンポーネント内外どちらなのかのチェックで利用する
    const wrapperRef = useRef<HTMLDivElement>(null);

    // メニューを閉じる際の処理
    const closeMenu = useCallback(() => {
      setIsOpenMenu(false);
      onClose && onClose();
    }, [onClose]);

    // コンポーネント内、かつラベル部分をクリックした場合、開閉処理
    const handleOnClickTrigger = useCallback(
      (event: React.SyntheticEvent<HTMLDivElement>) => {
        if (isOpenMenu) {
          closeMenu();
          event.stopPropagation();
        } else {
          setIsOpenMenu(true);
        }
      },
      [isOpenMenu, closeMenu],
    );

    // コンポーネント外をクリックした場合、閉じる
    const handleOnClickOutsideMenu = useCallback(
      (event: Event) => {
        // クリックされたのがコンポーネント内の場合は、対象外
        if (event.target instanceof Node && wrapperRef?.current?.contains(event.target)) {
          return;
        }

        closeMenu();
      },
      [closeMenu],
    );

    // 開閉状態、ハンドラ（辿っていくとonClose）に変更があった場合のイベントリスナーの付け替え
    useEffect(() => {
      if (isOpenMenu) {
        // メニューを開いた場合
        document.addEventListener('click', handleOnClickOutsideMenu);
      } else {
        // メニューを閉じた場合
        document.removeEventListener('click', handleOnClickOutsideMenu);
      }
      return () => {
        // ハンドラが変更された際の付け替えのためのイベントリスナー削除
        document.removeEventListener('click', handleOnClickOutsideMenu);
      };
    }, [isOpenMenu, handleOnClickOutsideMenu]);

    return (
      <TriggerWrapper className={className} ref={wrapperRef} tabIndex={0} active={isOpenMenu} error={error}>
        <Trigger onClick={handleOnClickTrigger} active={isOpenMenu} sizeVariant={sizeVariant}>
          <TriggerText sizeVariant={sizeVariant}>{value}</TriggerText>
          <DropdownIcon size='small' name='dropdown' sizeVariant={sizeVariant} />
        </Trigger>
        <SelectMenu onClick={(e) => e.stopPropagation()} active={isOpenMenu} error={error} sizeVariant={sizeVariant}>
          {children}
        </SelectMenu>
      </TriggerWrapper>
    );
  },
);

const ActiveCss = css`
  border-color: ${COLOR.GREEN};
`;

const ErrorCss = css`
  background-color: #fff6f6;
  border-color: ${COLOR.ERROR};
  color: ${COLOR.ERROR};
`;

const TriggerWrapper = styled.div<{ active: boolean; error?: boolean }>`
  position: relative;
  border-radius: 8px;
  border: solid 1px ${COLOR.GRAY};
  background: ${COLOR.WHITE};
  ${(props) =>
    props.active &&
    `
    border-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  `};
  ${(props) => (props.error ? ErrorCss : props.active && ActiveCss)};
  &:focus {
    outline: none;
  }
  width: 176px;
`;
const Trigger = styled.div<{ active: boolean; sizeVariant: Props['sizeVariant'] }>`
  color: ${COLOR.BLACK};
  font-size: 16px;
  padding: ${(props) => (props.sizeVariant === 'large' ? '20px 16px' : '4px 6px')};
  width: 100%;
  cursor: pointer;
  height: ${(props) => (props.sizeVariant === 'large' ? '60px' : '31px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.active &&
    `
    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
    `};
`;

const SelectMenu = styled.div<{ active: boolean; error?: boolean; sizeVariant: Props['sizeVariant'] }>`
  z-index: 10;
  position: absolute;
  top: ${(props) => (props.sizeVariant === 'large' ? '61px' : '32px')};
  left: -1px;
  right: -1px;
  background-color: ${COLOR.WHITE};
  max-height: 360px;
  overflow-y: auto;
  border-top: solid 1px ${COLOR.WHITE};
  border-left: solid 1px ${COLOR.GREEN};
  border-right: solid 1px ${COLOR.GREEN};
  border-bottom: solid 1px ${COLOR.GREEN};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  ${(props) => !props.active && 'display: none;'};
  ${(props) =>
    props.error &&
    `
    color: ${COLOR.ERROR};
    background-color: #fff6f6;
    border-top: solid 1px #fff6f6;
    border-left: solid 1px ${COLOR.ERROR};
    border-right: solid 1px ${COLOR.ERROR};
    border-bottom: solid 1px ${COLOR.ERROR};
  `};
`;

const DropdownIcon = styled(Icon)<{ sizeVariant: Props['sizeVariant'] }>`
  &&& {
    width: 13px;
    font-size: ${(props) => (props.sizeVariant === 'large' ? '0.8em' : '0.5em')};
  }
`;

const TriggerText = styled.div<{ sizeVariant: Props['sizeVariant'] }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${(props) => (props.sizeVariant === 'large' ? '16px' : '13px')};
  font-weight: bold;
`;
