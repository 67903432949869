import React, { useState } from 'react';

import { Checkbox, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { CommaSeparate } from 'components/atoms/CommaSeparate';
import {
  StyledTable,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderLabel,
  TableRow,
} from 'components/pageComponents/GmbInsight/GmbInsightTable';
import { GmbInsight } from 'models/Domain/GmbInsight';
import { InsightMetrics } from 'models/Domain/GmbInsightMetrics';
import { Stores } from 'models/Domain/Store';

export const SelectStoreListTable: React.FC<{
  insight: GmbInsight;
  metrics: InsightMetrics;
  stores: Stores;
  isActive: boolean;
  updateGmbInsight: (insight: GmbInsight) => void;
  storeIdList?: number[];
}> = ({ insight, stores, updateGmbInsight, isActive, metrics, storeIdList }) => {
  const [hoverIdx, setHoverIdx] = useState<number | null>();
  const tableData = insight.selectTableDataList(metrics, isActive, storeIdList);

  // 表示するデータがない場合はテーブルが項目名だけになってしまうので、表示しない
  if (tableData.isEmpty()) {
    return null;
  }

  return (
    <StyledTable>
      <TableHeader>
        <Table.Row>
          <CheckBoxTableHead>{''}</CheckBoxTableHead>
          <StoreNameTableHead>
            <FlexWrapper>
              <TableHeaderLabel>店舗名</TableHeaderLabel>
            </FlexWrapper>
          </StoreNameTableHead>
          <TableHeaderCell>
            <FlexWrapper>
              <TableHeaderLabel>検索数</TableHeaderLabel>
            </FlexWrapper>
          </TableHeaderCell>
          <TableHeaderCell>
            <FlexWrapper>
              <TableHeaderLabel>画像閲覧数/検索数</TableHeaderLabel>
            </FlexWrapper>
          </TableHeaderCell>
          <TableHeaderCell>
            <FlexWrapper>
              <TableHeaderLabel>アクション率</TableHeaderLabel>
            </FlexWrapper>
          </TableHeaderCell>
          <TableHeaderCell>
            <FlexWrapper>
              <TableHeaderLabel>写真投稿数</TableHeaderLabel>
            </FlexWrapper>
          </TableHeaderCell>
          <TableHeaderCell>
            <FlexWrapper>
              <TableHeaderLabel>クチコミ返信数</TableHeaderLabel>
            </FlexWrapper>
          </TableHeaderCell>
          <TableHeaderCell />
        </Table.Row>
      </TableHeader>

      <Table.Body>
        {tableData.map((data, idx) => {
          const store = stores.findStore(data.store_id);
          return (
            <TableRow
              key={idx}
              onMouseEnter={() => {
                setHoverIdx(idx);
              }}
              onMouseLeave={() => {
                setHoverIdx(null);
              }}
              isHover={idx === hoverIdx}
            >
              <CheckBoxTableCell>
                <Checkbox
                  onChange={() => {
                    if (insight.isChecked(data.store_id)) {
                      updateGmbInsight(insight.removeSelectedStoreList(data.store_id));
                    } else {
                      updateGmbInsight(insight.addSelectedStoreList(data.store_id));
                    }
                  }}
                  checked={insight.isChecked(data.store_id)}
                />
              </CheckBoxTableCell>
              <StoreNameTableCell>{store ? `${store.name} ${store.branch}` : ''}</StoreNameTableCell>
              <TableCell>
                <CommaSeparate value={data.sumSearch} />
              </TableCell>
              <TableCell>
                <CommaSeparate value={Number((data.MEDIA_VIEW_COUNT / data.sumSearch).toFixed(2))} />
              </TableCell>
              <TableCell>
                <CommaSeparate value={Number(((data.sumAction / data.sumSearch) * 100).toFixed(2))} />
              </TableCell>
              <TableCell>
                <CommaSeparate value={data.MEDIA_POST_COUNT} />
              </TableCell>
              <TableCell>
                <CommaSeparate value={data.REVIEW_REPLY_COUNT} />
              </TableCell>
              <TableCell />
            </TableRow>
          );
        })}
      </Table.Body>
    </StyledTable>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 80px;
`;

const CheckBoxTableCell = styled(TableCell)`
  vertical-align: middle;

  &&& {
    width: 50px;
  }
`;

const CheckBoxTableHead = styled(TableHeaderCell)`
  &&& {
    width: 50px;
  }
`;

const StoreNameTableCell = styled(TableCell)`
  &&& {
    width: 180px;
    text-align: left;
  }
`;

const StoreNameTableHead = styled(TableHeaderCell)`
  &&& {
    width: 180px;
  }
`;
