import actionCreatorFactory from 'typescript-fsa';

import { Organization } from 'models/Domain/Organization';

const actionCreator = actionCreatorFactory('Organization');

export const OrganizationActions = {
  initializePage: actionCreator('initializePage'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
  setOrganization: actionCreator<Organization>('setOrganization'),
  updateOrganization: actionCreator<Organization>('updateOrganization'),
  resetData: actionCreator('resetData'),
};
