import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { AccountsApiResponse } from 'ApiClient/AccountApi';
import { ArrayElement } from 'types/Common';

import { User } from './User';

// アカウント切り替えに最低限必要な表示するための、簡易的なアカウントモデル
export class Account extends ImmutableRecord<{
  organizationId: number;
  name: string;
  user: User;
}>({
  organizationId: 0,
  name: '',
  user: new User(),
}) {
  static fromJSON(data: ArrayElement<AccountsApiResponse['items']>): Account {
    return new Account({
      organizationId: data.organization_id,
      name: (data as any).name ?? data.organization?.name, // NOTE 後方互換性を持たせている リリース後、後者のみにする
      user: new User(data),
    });
  }
}

export class AccountList extends ImmutableRecord<{
  items: ImmutableList<Account>;
}>({
  items: ImmutableList(),
}) {
  static fromJSON(data: AccountsApiResponse): AccountList {
    return new AccountList({
      items: ImmutableList(data.items.map((item) => Account.fromJSON(item))),
    });
  }

  find(organizationId: number): Account | undefined {
    return this.items.find((item) => item.organizationId === organizationId);
  }

  contains(organizationId: number): boolean {
    return !!this.find(organizationId);
  }

  first(): Account | undefined {
    return this.items.get(0);
  }
}

export class AccountSingleton {
  private static instance: AccountSingleton;

  // 0は未設定として扱う
  organizationId: number;

  private constructor() {
    this.organizationId = 0;
  }

  public static getInstance(): AccountSingleton {
    if (!AccountSingleton.instance) {
      AccountSingleton.instance = new AccountSingleton();
    }
    return AccountSingleton.instance;
  }

  set(organizationId: number) {
    this.organizationId = organizationId;
  }

  clear() {
    this.organizationId = 0;
  }
}
