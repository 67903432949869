import { toast } from 'react-semantic-toasts';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { GmbAttributeListApi } from 'ApiClient/GmbApi';
import { StoreBulkEditBusinessInfoApi } from 'ApiClient/StoreApi';
import { sliceArrayByNumber } from 'helpers/utils';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { Stores } from 'models/Domain/Store';
import { Path } from 'routes';

import { AppActions } from '../app/actions';
import { State } from '../reducers';
import { StoreActions } from '../store/actions';

import { BulkEditStoresActions } from './actions';
import { BulkEditStoresState } from './reducers';

export default function* saga() {
  yield takeLatest(BulkEditStoresActions.initialize, initialize);
  yield takeLatest(BulkEditStoresActions.fetchAttributeMetadatas, fetchAttributeMetadatas);
  yield takeLatest(BulkEditStoresActions.updateBulkEditBusinessInfo, updateBulkEditBusinessInfo);
}

function* initialize() {
  yield put(BulkEditStoresActions.resetStateWithoutStores());
  yield put(BulkEditStoresActions.fetchAttributeMetadatas());
}

/**
 * ストア一覧に含まれる全てのカテゴリーに対する属性メタデータを取得し、ストアにセットする
 */
function* fetchAttributeMetadatas() {
  yield put(AppActions.setLoading(true));
  yield put(BulkEditStoresActions.setInitialized(false));
  const stores: Stores = yield select((state: State) => state.bulkEditStores.stores);
  const categoryIds = stores.list
    .map((store) => store.location.primaryCategory.categoryId)
    .filter((categoryId) => !!categoryId)
    .toArray();

  // yield allで一括でAPI実行する
  const requests = categoryIds.reduce<{ [key: string]: any }>((current, categoryId) => {
    current[categoryId] = call(GmbAttributeListApi.get, categoryId);
    return current;
  }, {});
  const responses: { [key: string]: any } = yield all(requests);

  // 1つでもエラーがある場合はメッセージ表示
  const hasError = !!Object.values(responses).find((response) => !response.isSuccess);
  if (hasError) {
    console.error('fetch attributeMetadatas error', responses);
    toast({
      type: 'error',
      title: '属性メタデータの取得に失敗しました',
      time: 10000,
    });
    return;
  }

  // カテゴリーIDごとの属性メタデータに変換してストアにセットする
  const attributeMetadatas = Object.entries(responses).reduce<{ [key: string]: GmbAttributeMetadatas }>(
    (current, [categoryId, response]) => {
      current[categoryId] = new GmbAttributeMetadatas(response.data);
      return current;
    },
    {},
  );
  yield put(BulkEditStoresActions.setAttributeMetadatas({ attributeMetadatas }));

  yield put(BulkEditStoresActions.setInitialized(true));
  yield put(AppActions.setLoading(false));
}

function* updateBulkEditBusinessInfo(action: ReturnType<typeof BulkEditStoresActions.updateBulkEditBusinessInfo>) {
  yield put(AppActions.setLoading(true));

  const bulkEditStoresState: BulkEditStoresState = yield select((state: State) => state.bulkEditStores);
  const requestParams = bulkEditStoresState.requestParams();
  const splittedRequestParams = sliceArrayByNumber(requestParams.store_info_list, 100).map((store_info_list) => ({
    ...requestParams,
    store_info_list,
  }));

  for (const params of splittedRequestParams) {
    const response: YieldReturn<typeof StoreBulkEditBusinessInfoApi.post> =
      yield StoreBulkEditBusinessInfoApi.post(params);

    // 更新に失敗した場合
    if (!response.isSuccess) {
      toast({
        type: 'error',
        title: '一部の店舗情報の更新に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
      yield put(AppActions.setLoading(false));
      return;
    }
  }

  yield put(StoreActions.getStores());
  toast({
    type: 'success',
    title: '店舗情報を更新しました',
  });
  yield put(AppActions.moveTo(Path.store.index));
  yield put(AppActions.setLoading(false));
}
