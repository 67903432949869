import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { List, Map } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  InventorySummaryApi,
  SearchProductsApi,
  UpdateExpirationApi,
  UpdateExpirationParams,
  UpdateInventoriesApi,
} from 'ApiClient/OmoApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { Inventory, InventoryAvailability } from 'models/Domain/Omo/Inventory';
import { InventorySummary } from 'models/Domain/Omo/InventorySummary';
import { ProductGroups } from 'models/Domain/Omo/Product';
import { ProductSearchCondition } from 'models/Domain/Omo/ProductSearchCondition';
import { StoreSearchCondition } from 'models/Domain/Omo/StoreSearchCondition';
import { Stores } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { State } from 'modules/reducers';
import { getInitialGroup, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { InventoryActions } from './actions';

export default function* saga() {
  yield takeLatest(InventoryActions.fetchInventorySummaryList, fetchInventorySummaryList);
  yield takeLatest(InventoryActions.initializeInventoryStoreList, initializeInventoryStoreList);
  yield takeLatest(InventoryActions.initializeInventoryList, initializeInventoryList);
  yield takeLatest(InventoryActions.setStoreSearchCondition, setSearchCondition);
  yield takeLatest(InventoryActions.setProductSearchCondition, setProductSearchCondition);
  yield takeLatest(InventoryActions.updateInventories, updateInventories);
  yield takeLatest(InventoryActions.bulkUpdateInventories, bulkUpdateInventories);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

/**
 * 単一店舗の在庫サマリー（有効期限別の商品数）を取得する
 */
function* fetchInventorySummary(storeId: number) {
  yield put(InventoryActions.setIsLoadingInventorySummary(true));

  const stores: Stores = yield select((state: State) => state.store.stores);
  // 店舗IDを指定
  const params = { store_id: [storeId] };
  const response: YieldReturn<typeof InventorySummaryApi.get> = yield InventorySummaryApi.get(params);

  if (response.isSuccess) {
    // サマリー情報をリクエストした店舗IDの情報を取得
    const summary = response.data.stores.find((item) => item.store_id === storeId);

    if (summary) {
      // 対応する店舗情報をstateから取得
      const store = stores.findStore(summary.store_id);

      if (store) {
        // 在庫サマリーと店舗情報をまとめて保存
        const inventorySummary = InventorySummary.fromJSON({ ...summary, store });
        yield put(InventoryActions.setInventorySummary(inventorySummary));
      }
    }
    yield put(InventoryActions.setIsLoadingInventorySummary(false));
  } else {
    toast({
      type: 'error',
      title: '在庫情報の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(InventoryActions.setIsLoadingInventorySummary(false));
  }
}

/**
 * 複数の店舗の在庫サマリー（有効期限別の商品数）を取得する
 */
function* fetchInventorySummaryList() {
  yield put(InventoryActions.setIsLoadingInventorySummary(true));
  const stores: Stores = yield select((state: State) => state.store.stores);
  const response: YieldReturn<typeof InventorySummaryApi.get> = yield InventorySummaryApi.get({});
  if (response.isSuccess) {
    let inventorySummaryList = List<InventorySummary>();
    response.data.stores.map((item) => {
      const store = stores.findStore(item.store_id);
      if (store) {
        const inventorySummary = InventorySummary.fromJSON({ ...item, store });
        inventorySummaryList = inventorySummaryList.push(inventorySummary);
      }
    });
    yield put(InventoryActions.setInventorySummaryList(inventorySummaryList));
    yield put(InventoryActions.setIsLoadingInventorySummary(false));
  } else {
    toast({
      type: 'error',
      title: '在庫情報の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(InventoryActions.setIsLoadingInventorySummary(false));
  }
}

function* fetchProducts(storeId: number) {
  yield put(InventoryActions.setIsLoadingProducts(true));
  const currentUser: User = yield select((state: State) => state.app.currentUser);

  const searchCondition: ProductSearchCondition = yield select((state) => state.inventory.productSearchCondition);
  const params = {
    ...searchCondition.toRequestParams(currentUser.canUseOnDisplayToOrder),
    store_id: [storeId].join(','),
  };

  const response: YieldReturn<typeof SearchProductsApi.get> = yield SearchProductsApi.get(params);
  if (response.isSuccess) {
    const productGroups = ProductGroups.fromJSON(response.data, storeId);
    yield put(InventoryActions.setProductGroups(productGroups));
    yield put(InventoryActions.setCommittedProductGroups(productGroups));
    yield put(InventoryActions.setEditedInventories(Map<string, Inventory>()));
  } else {
    toast({
      type: 'error',
      title: '商品情報の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(InventoryActions.setIsLoadingProducts(false));
}

// 店舗一覧または店頭在庫一覧ページにおけるURL(パラメータ)変更時の処理
function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // パスが店頭在庫の店舗一覧ページの場合
  if (location.pathname === Path.omo.inventoryStoreList) {
    yield call(updateStoresByLocation);
    return;
  }

  // パスが店頭在庫の在庫一覧ページの場合
  const inventoryListPath = Path.omo.inventoryList.replace(':storeId', '');
  if (location.pathname.startsWith(inventoryListPath)) {
    const isPreparedForInventoryListPage: boolean = yield select(
      (state: State) => state.inventory.isPreparedForInventoryListPage,
    );
    if (!isPreparedForInventoryListPage) {
      return;
    }
    yield call(updateProductsByLocation);
    return;
  }
}

// 店舗一覧ページを初期化する
function* initializeInventoryStoreList() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);
  // URLパラメータから検索条件を復元する
  yield call(updateStoresByLocation);

  yield put(InventoryActions.fetchInventorySummaryList());
}

// 在庫一覧ページを初期化する
function* initializeInventoryList(action: ReturnType<typeof InventoryActions.initializeInventoryList>) {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const storeId = action.payload;

  // 指定されたstoreIdの表示権限がなければ、店舗一覧に遷移する
  const currentUser: User = yield select((state: State) => state.app.currentUser);
  const stores: Stores = yield select((state: State) => state.store.stores);
  if (storeId && !stores.filterByUserRole(currentUser).findStore(storeId)) {
    yield put(replaceWithOrganizationId(Path.omo.inventoryStoreList));
    return;
  }

  yield fork(fetchInventorySummary, storeId);

  // 期限延長実行済み状態をリセットする
  yield put(InventoryActions.setIsUpdateExpirationExecuted(false));

  const isPreparedForInventoryListPage: boolean = yield select(
    (state: State) => state.inventory.isPreparedForInventoryListPage,
  );
  // 初期化済みの場合は以降の処理を行わない
  if (isPreparedForInventoryListPage) {
    return;
  }

  yield put(InventoryActions.setIsPreparedForInventoryListPage(true));

  // URLパラメータから検索条件を復元する
  yield call(updateProductsByLocation);
}

function* updateStoresByLocation() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  let searchCondition = StoreSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.storeListId });
  searchCondition = searchCondition.setIn(['filter', 'storeListId'], group);

  // URLパラメータから復元した検索条件をセットする
  yield put(InventoryActions.setStoreSearchCondition({ searchCondition, updateLocation: false }));
  yield put(InventoryActions.setCommittedStoreSearchCondition(searchCondition));
}

/**
 * URLパラメータから商品の検索条件を復元する
 */
function* updateProductsByLocation() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  const searchCondition = ProductSearchCondition.fromURLSearchParams(location.search);

  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // URLパラメータから復元した検索条件をセットする
  yield put(InventoryActions.setProductSearchCondition({ searchCondition, updateLocation: false }));
  yield put(InventoryActions.setCommittedProductSearchCondition(searchCondition));

  // 店舗IDをルーティングから取得
  const storeId = Number(location.pathname.split('/').pop());

  yield call(fetchProducts, storeId);
}

function* setSearchCondition(action: ReturnType<typeof InventoryActions.setStoreSearchCondition>) {
  const { searchCondition, updateLocation } = action.payload;
  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // updateLocationがtrueの場合、かつ既存とURLに差分がある場合、URLの更新を行う
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
    yield put(InventoryActions.setCommittedStoreSearchCondition(searchCondition));
  }
}

/**
 * 在庫一覧ページでの検索条件をURLに反映し、URLが変わったらデータの取得を行う
 * @param action 検索条件
 */
function* setProductSearchCondition(action: ReturnType<typeof InventoryActions.setProductSearchCondition>) {
  const { searchCondition, updateLocation } = action.payload;
  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // updateLocationがtrueの場合、かつ既存とURLに差分がある場合、URLの更新を行う
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
    yield put(InventoryActions.setCommittedProductSearchCondition(searchCondition));
  }
}

function* updateInventories(action: ReturnType<typeof InventoryActions.updateInventories>) {
  const storeId = action.payload;
  const editedInventories: Map<string, Inventory> = yield select((state: State) => state.inventory.editedInventories);
  const params = {
    inventories: editedInventories
      .toIndexedSeq()
      .map((inventory) => inventory.updateParams())
      .toArray(),
  };
  yield put(InventoryActions.setIsLoadingProducts(true));
  const response: YieldReturn<typeof UpdateInventoriesApi.post> = yield UpdateInventoriesApi.post(params);
  if (response.isSuccess) {
    toast({ type: 'success', title: '在庫情報を更新しました' });
    // データを更新したら取得し直す
    yield fork(fetchInventorySummary, storeId);
    yield call(fetchProducts, storeId);
  } else {
    toast({
      type: 'error',
      title: '在庫情報の更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(InventoryActions.setIsLoadingProducts(false));
  }
}

function* bulkUpdateInventories(action: ReturnType<typeof InventoryActions.updateInventories>) {
  yield put(InventoryActions.setIsLoadingProducts(true));
  const currentUser: User = yield select((state: State) => state.app.currentUser);

  const storeId = action.payload;
  const searchCondition: ProductSearchCondition = yield select(
    (state: State) => state.inventory.committedProductSearchCondition,
  );
  const params: UpdateExpirationParams = { store_id: storeId };
  if (searchCondition.filter.searchValue) {
    params['query'] = searchCondition.filter.searchValue;
  }

  const availabilities = searchCondition.filter.getAvailabilityTypes(currentUser.canUseOnDisplayToOrder);
  params['availabilities'] = availabilities
    ? (availabilities.filter((value) => value !== 'null').toArray() as InventoryAvailability[]) // データなしは含めない
    : undefined;

  const expiration = searchCondition.filter.expiration;
  // 「すべて」の場合は指定なし
  if (!expiration.every((value) => value) && !expiration.every((value) => !value)) {
    params['local_inventory_statuses'] = expiration
      .filter((value) => value)
      .keySeq()
      .toArray();
  }

  toast({ type: 'success', title: '有効期限の延長を受け付けました' });
  yield put(InventoryActions.setIsLoadingProducts(false));
  yield put(InventoryActions.setIsUpdateExpirationExecuted(true));
  const response: YieldReturn<typeof UpdateExpirationApi.post> = yield UpdateExpirationApi.post(params);
  if (response.isSuccess) {
    toast({ type: 'success', title: '有効期限を延長しました' });
    yield put(InventoryActions.setIsUpdateExpirationExecuted(false));
  } else {
    console.error('update_expiration error', response.error);
  }
}
