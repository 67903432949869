import ApiClient from './index';

const apiClient = new ApiClient({});

const MENU_API_ENDPOINT = '/menus';

export type GetMenuSummaryParams = {
  store_ids?: number[] | null;
  group_ids?: number[] | null;
  is_included_deactivate_gbp?: boolean;
};

export type GetMenuSummaryResponse = {
  conditions: {
    organization_id: number;
    store_ids: number[] | null;
    group_ids: number[] | null;
    is_included_deactivate_gbp: boolean | null;
  };
  items: {
    id: number;
    store_ids: number[] | null;
    group_ids: number[] | null;
    name: string;
    apply_to_gbp: boolean;
    synced_to_gbp: boolean | null;
    is_deleted: boolean; // GBP未反映のメニューグループは削除済みでもレスポンスに含まれる
    create_at: string;
    update_at: string;
  }[];
};

export type GetMenuResponse = {
  id: number;
  organization_id: number;
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  apply_to_gbp: boolean;
  create_at: string;
  update_at: string;
  sections: {
    id: number;
    name: string;
    items: {
      id: number;
      name: string;
      description: string | null;
      price: number | null;
      dietary_restrictions: string[];
      image_url: string | null;
    }[];
  }[];
};

export type PostMenuParams = {
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  apply_to_gbp: boolean;
  sections: {
    name: string;
    items: {
      name: string;
      description: string | null;
      price: number | null;
      dietary_restrictions: string[] | null;
      image_url: string | null;
    }[];
  }[];
};

export type PostMenuResponse = {
  id: number;
  organization_id: number;
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  apply_to_gbp: boolean;
  difference_detected: boolean;
  sections: {
    id: number;
    name: string;
    items: {
      id: number;
      name: string;
      description: string | null;
      price: number | null;
      dietary_restrictions: string[] | null;
      image_url: string | null;
    }[];
  }[];
};

export type PutMenuParams = {
  id: number | null;
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  apply_to_gbp: boolean;
  sections: {
    id: number | null;
    name: string;
    items: {
      id: number | null;
      name: string;
      description: string | null;
      price: number | null;
      dietary_restrictions: string[] | null;
      image_url: string | null;
    }[];
  }[];
};

export type PutMenuResponse = {
  organization_id: number;
  store_ids: number[] | null;
  group_ids: number[] | null;
  id: number;
  name: string;
  apply_to_gbp: boolean;
  sections: {
    id: number;
    name: string;
    items: {
      id: number;
      name: string;
      description: string | null;
      price: number | null;
      dietary_restrictions: string[] | null;
      image_url: string | null;
    }[];
  }[];
};

export type BatchDeleteMenusParams = {
  ids: number[];
};

export type BatchUpdateMenuSummaryParams = {
  items: {
    id: number;
    store_ids: number[] | null;
    group_ids: number[] | null;
    name: string;
    apply_to_gbp: boolean;
  }[];
};

export class MenuApi {
  static getSummary(params: GetMenuSummaryParams) {
    return apiClient.get<GetMenuSummaryResponse>(`${MENU_API_ENDPOINT}:summary`, params);
  }

  static get(id: number) {
    return apiClient.get<GetMenuResponse>(`${MENU_API_ENDPOINT}/${id}`);
  }

  static post(params: PostMenuParams) {
    return apiClient.post<PostMenuResponse>(`${MENU_API_ENDPOINT}`, params);
  }

  static put(id: number, params: PutMenuParams) {
    return apiClient.put<PutMenuResponse>(`${MENU_API_ENDPOINT}/${id}`, params);
  }

  static batchDelete(params: BatchDeleteMenusParams) {
    return apiClient.post(`${MENU_API_ENDPOINT}:batch_delete`, params);
  }

  static batchUpdateSummary(params: BatchUpdateMenuSummaryParams) {
    return apiClient.post(`${MENU_API_ENDPOINT}:batch_update_summary`, params);
  }

  static applyToGbp() {
    return apiClient.post(`${MENU_API_ENDPOINT}:apply_to_gbp`, {});
  }
}
