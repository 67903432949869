import React from 'react';

import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';

type EditableCellProps = {
  className?: string;
  children: React.ReactNode;
  canStartEdit?: boolean;
  canCopy?: boolean;
  canPaste?: boolean;
  displayLabel?: boolean;
  onStartEdit?: () => void;
  onCopy?: () => void;
  onPaste?: () => void;
};

export const EditableCell: React.FC<EditableCellProps> = ({
  className,
  children,
  canStartEdit = false,
  canCopy = false,
  canPaste = false,
  displayLabel = true,
  onStartEdit,
  onCopy,
  onPaste,
}) => {
  return (
    <Wrapper className={className}>
      <Contents>
        {children}
        <EditMenu>
          {canCopy && (
            <Button onClick={onCopy}>
              <CopyButton />
              {displayLabel && <ButtonLabel>コピー</ButtonLabel>}
            </Button>
          )}
          {canPaste && (
            <Button onClick={onPaste}>
              <PasteButton />
              {displayLabel && <ButtonLabel>貼付</ButtonLabel>}
            </Button>
          )}
          {canStartEdit && (
            <Button onClick={onStartEdit} style={{ marginLeft: 'auto' }}>
              <EditButton />
              {displayLabel && <ButtonLabel>編集</ButtonLabel>}
            </Button>
          )}
        </EditMenu>
      </Contents>
    </Wrapper>
  );
};

const EditMenu = styled.div`
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 4px;
  z-index: 1;

  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled(Table.Cell)`
  &&& {
    padding-bottom: 28px;
  }
  &:hover ${EditMenu} {
    visibility: visible;
  }
`;

const Contents = styled.div`
  display: flex;
`;

const Button = styled.a`
  cursor: pointer;
  padding: 0;
  font-size: 10px;
  line-height: 2;
  height: 20px;

  &:hover {
    background-color: #cff2e6;
    border-radius: 5px;
  }
`;

const ButtonIcon = styled(Icon)`
  &&& {
    width: 20px;
    height: 20px;
    padding: 4px;
    margin: 0;
    vertical-align: middle;
  }
`;

const ButtonLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
`;

const CopyButton = styled(ButtonIcon).attrs(() => ({ type: 'copy_for_store_business_info' }))``;
const PasteButton = styled(ButtonIcon).attrs(() => ({ type: 'paste_for_store_business_info' }))``;
const EditButton = styled(ButtonIcon).attrs(() => ({ type: 'pencil_for_store_business_info' }))``;
