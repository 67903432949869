import React from 'react';

import styled from 'styled-components';

export const MainWrapper = styled.div`
  padding-bottom: 50px;
`;

export const Body = React.memo<{ className?: string; children: React.ReactNode }>(({ className, children }) => {
  return (
    <BodyWrapper className={className}>
      <BodyContent>{children}</BodyContent>
    </BodyWrapper>
  );
});

export const WideBody = React.memo<{ className?: string; children: React.ReactNode }>(({ className, children }) => {
  return (
    <WideBodyWrapper className={className}>
      <WideBodyContent>{children}</WideBodyContent>
    </WideBodyWrapper>
  );
});

const BodyWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const WideBodyWrapper = styled.div`
  padding: 32px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  @media (max-width: 860px) {
    padding: 32px 16px;
  }
`;

const BodyContent = styled.div`
  margin: 20px 18px;
`;

const WideBodyContent = styled.div`
  margin: 0;
`;
