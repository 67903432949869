import { Set } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { GbpChecklist } from 'models/Domain/GbpChecklist';
import { Group } from 'types/Common';

const actionCreator = actionCreatorFactory('Top');

export const TopActions = {
  initializePage: actionCreator('initializePage'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
  setChecklist: actionCreator<GbpChecklist>('setChecklist'),
  setStoreIds: actionCreator<Set<number>>('setStoreIds'),
  setGroup: actionCreator<Group>('setGroup'),
  commitStoreIds: actionCreator('commitStoreIds'),
};
