import React, { useCallback } from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { GbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbUrlAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { COLOR } from 'style/color';

import { AttributeUrl } from './AttributeUrl';

const RESERVATION_LINK_ATTRIBUTE_IDS = ['url_appointment', 'url_reservations'];

type Props = {
  className?: string;
  attributeMetadatas: GmbAttributeMetadatas;
  attributes: GmbUrlAttributes;
  availableUrlTypes: GbpAvailableUrlTypes;
  setAttributes: (attributes: GmbUrlAttributes) => void;
};

export const AttributesUrlForm = React.memo<Props>(
  ({ className, attributeMetadatas, attributes, availableUrlTypes, setAttributes }) => {
    const groupedCategoryAttributes = attributeMetadatas.groupByUrlGroupDisplayName();

    // 空の値を含めた属性データに変換
    const complementedAttributes = attributes.complement(attributeMetadatas);

    // 値を含めた除くデータに変換してハンドラに渡す
    const handleChangeAttributes = useCallback(
      (attributes: GmbUrlAttributes) => {
        setAttributes(attributes.removeNoValueAttributes());
      },
      [setAttributes],
    );

    return (
      <Wrapper className={className}>
        {List(groupedCategoryAttributes)
          .filter(([_, attributeMetadataList], idx) => {
            const targetList = attributeMetadataList.toList();
            const isReservationLink = targetList.some((attributeMetadata) =>
              RESERVATION_LINK_ATTRIBUTE_IDS.includes(attributeMetadata.attributeId),
            );
            const editable = !isReservationLink || availableUrlTypes.canEditReservationLink;
            return !targetList.isEmpty() && editable;
          })
          .map(([groupName, attributeMetadataList], idx) => {
            return (
              <GroupWrapper key={idx}>
                <GroupName>{groupName}</GroupName>
                <ToggleWrapper>
                  {attributeMetadataList.map((attributeMetadata, idx2) => {
                    const targetUrlAttribute = complementedAttributes.getUrlAttribute(attributeMetadata.attributeId);
                    if (!targetUrlAttribute) {
                      return <React.Fragment key={idx2}></React.Fragment>;
                    } else if (attributeMetadata.isUrl) {
                      return (
                        <AttributeUrl
                          key={idx2}
                          onChange={(updateId, idx, value) =>
                            handleChangeAttributes(complementedAttributes.updateUrlAttribute(updateId, idx, value))
                          }
                          attributeMetadatas={attributeMetadata}
                          urlAttribute={targetUrlAttribute}
                          addUrl={() =>
                            handleChangeAttributes(complementedAttributes.addUrl(attributeMetadata.attributeId))
                          }
                          onRemove={(idx) =>
                            handleChangeAttributes(complementedAttributes.removeUrl(attributeMetadata.attributeId, idx))
                          }
                        />
                      );
                    }
                    return;
                  })}
                </ToggleWrapper>
              </GroupWrapper>
            );
          })}
        <Notice>注: 編集内容の品質確認が行われることがあり、公開されるまでに 3 日ほどかかる場合があります。</Notice>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const GroupWrapper = styled.div`
  border-bottom: 1px solid rgba(181, 181, 181, 0.5);
  padding-bottom: 18px;

  & + & {
    margin-top: 18px;
  }
`;

const GroupName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Notice = styled.p`
  font-size: 13px;
  color: ${COLOR.GRAY};
  margin: 16px 0;
`;
