import React from 'react';

import { TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';

type Props = {
  className?: string;
  isLoading: boolean;
};

export const Loading: React.FC<Props> = ({ className, isLoading }) => {
  return (
    <TransitionablePortal
      open={isLoading}
      transition={{
        animation: 'fade',
        duration: 500,
      }}
    >
      <PortalContent>
        <LoaderWrapper>
          <Loader active={isLoading} size='big' />
        </LoaderWrapper>
      </PortalContent>
    </TransitionablePortal>
  );
};

const PortalContent = styled.div``;

const LoaderWrapper = styled.div`
  &&& {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 1000;
  }
`;
