import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Icon, Modal, Radio } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import {
  MapSearchRankSearchCondition,
  SortKey,
  SortOrder,
} from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';
import { SelectOption } from 'types/Common';

const sortKeyDefaultOptions: SelectOption<SortKey>[] = [
  { value: 'store_name', text: '店舗名' },
  { value: 'search_word', text: 'キーワード' },
  { value: 'area_name', text: '検索地点' },
  { value: 'rank', text: '平均掲載順位' },
  { value: 'latest_rank', text: '最新掲載順位' },
  { value: 'diff', text: '集計期間の推移' },
];

const sortKeyComparisonOptions: SelectOption<SortKey>[] = [
  { value: 'store_name', text: '店舗名' },
  { value: 'search_word', text: 'キーワード' },
  { value: 'area_name', text: '検索地点' },
  { value: 'rank', text: '平均掲載順位' },
  { value: 'rank_comparison', text: '平均掲載順位（比較期間）' },
  { value: 'diff_comparison', text: '比較期間との差分' },
];

/** 検索順位監視テーブルの並べ替え設定モーダルのパラメータ */
export type Props = {
  isOpen: boolean;
  onClose: () => void;
  condition: MapSearchRankSearchCondition;
  setCondition: (condition: MapSearchRankSearchCondition) => void;
};

/**
 * 検索順位監視テーブルの並べ替え設定モーダル
 * @param isOpen モーダルの開閉状態
 * @param onClose モーダルを閉じる
 * @param condition 検索条件
 * @param setCondition 検索条件が変更された
 * @returns
 */
export const MapSearchRankTableSortConditionModal: React.FC<Props> = ({ isOpen, onClose, condition, setCondition }) => {
  const [editingSearchCondition, setEditingSearchCondition] = useState<MapSearchRankSearchCondition>(condition);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setEditingSearchCondition(condition);
  }, [isOpen, condition]);

  /**
   * ソート条件を追加する
   */
  const handleOnAddSortCondition = useCallback(
    (value: SortKey) => {
      const newEditingSearchCondition = editingSearchCondition.update('sort', (sort) => sort.addBySortKey(value));
      setEditingSearchCondition(newEditingSearchCondition);
    },
    [editingSearchCondition, setEditingSearchCondition],
  );

  /**
   * ソート条件を削除する
   */
  const handleOnDeleteSortCondition = useCallback(
    (index: number) => {
      const newEditingSearchCondition = editingSearchCondition.update('sort', (sort) => sort.deleteByIndex(index));
      setEditingSearchCondition(newEditingSearchCondition);
    },
    [editingSearchCondition, setEditingSearchCondition],
  );

  /**
   * ソート条件を並べ替える
   */
  const handleOnChangeSortConditionOrder = useCallback(
    (indexFrom: number, indexTo: number) => {
      const newEditingSearchCondition = editingSearchCondition.update('sort', (sort) =>
        sort.changeItemOrderByIndex(indexFrom, indexTo),
      );
      setEditingSearchCondition(newEditingSearchCondition);
    },
    [editingSearchCondition, setEditingSearchCondition],
  );

  /**
   * ソート条件のキーを変更する
   */
  const handleOnChangeSortKey = useCallback(
    (index: number, key: SortKey) => {
      const newEditingSearchCondition = editingSearchCondition.update('sort', (sort) => sort.updateSortKey(index, key));
      setEditingSearchCondition(newEditingSearchCondition);
    },
    [editingSearchCondition, setEditingSearchCondition],
  );

  /**
   * ソート条件の昇順/降順を変更する
   */
  const handleOnChangeSortOrder = useCallback(
    (index: number, order: SortOrder) => {
      const newEditingSearchCondition = editingSearchCondition.update('sort', (sort) =>
        sort.updateSortOrder(index, order),
      );
      setEditingSearchCondition(newEditingSearchCondition);
    },
    [editingSearchCondition, setEditingSearchCondition],
  );

  // 比較期間のあり/なしで選択可能な項目が異なる
  const sortKeyOptions = useMemo(() => {
    return editingSearchCondition.filter.isEnabledComparison ? sortKeyComparisonOptions : sortKeyDefaultOptions;
  }, [editingSearchCondition]);

  // 既に選択されているソートキーを除外する
  const selectableSortKeyOptions = sortKeyOptions.filter(
    (option) => !editingSearchCondition.sort.sortKeys.includes(option.value),
  );

  const showAddButton = selectableSortKeyOptions.length > 0;

  return (
    <Modal open={isOpen} onClose={onClose} size='small'>
      <Modal.Content>
        <Wrapper>
          <Title>並べ替えの設定</Title>
          <FlexWrapper>
            {editingSearchCondition.sort.items.map((sortItem, index) => {
              const isDescOrder = sortItem.order === 'desc';
              const selectableFilteredSortKeyOptions = sortKeyOptions.filter(
                (option) =>
                  option.value === sortItem.key || !editingSearchCondition.sort.sortKeys.includes(option.value),
              );
              const showDeleteButton = editingSearchCondition.sort.items.size > 1;
              const showChangeOrderButton = editingSearchCondition.sort.items.size !== index + 1;
              return (
                <>
                  <FlexContent>
                    <ItemTitle>{index === 0 ? `並べ替え条件` : '次の条件'}</ItemTitle>
                    <PullDown
                      value={sortItem.key}
                      options={selectableFilteredSortKeyOptions}
                      onChange={(value: SortKey) => handleOnChangeSortKey(index, value)}
                    />
                    <RadioContainer>
                      <StyledRadio
                        label='昇順'
                        checked={!isDescOrder}
                        onChange={() => handleOnChangeSortOrder(index, 'asc')}
                      />
                      <StyledRadio
                        label='降順'
                        checked={isDescOrder}
                        onChange={() => handleOnChangeSortOrder(index, 'desc')}
                      />
                    </RadioContainer>
                    {showDeleteButton && (
                      <DeleteButtonWrapper>
                        <DeleteButton priority='low' onClick={() => handleOnDeleteSortCondition(index)}>
                          <DeleteIcon name='trash alternate outline' />
                        </DeleteButton>
                      </DeleteButtonWrapper>
                    )}
                  </FlexContent>
                  <DivideWrapper>
                    {showChangeOrderButton ? (
                      <ChangeOrderButtonWrapper>
                        <ChangeOrderButton
                          priority='low'
                          onClick={() => handleOnChangeSortConditionOrder(index, index + 1)}
                        >
                          <ChangeOrderIcon name='exchange' />
                        </ChangeOrderButton>
                      </ChangeOrderButtonWrapper>
                    ) : (
                      <ChangeOrderButtonWrapper></ChangeOrderButtonWrapper>
                    )}
                    <Divider />
                  </DivideWrapper>
                </>
              );
            })}
          </FlexWrapper>
          {showAddButton && (
            <AddButtonWrapper>
              <AddButton onClick={() => handleOnAddSortCondition(selectableSortKeyOptions[0].value)}>
                条件を追加する
              </AddButton>
            </AddButtonWrapper>
          )}
          <ActionWrapper>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
            <ApplyButton priority='high' onClick={() => setCondition(editingSearchCondition)}>
              適用する
            </ApplyButton>
          </ActionWrapper>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const Wrapper = styled.div`
  margin: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const DivideWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const Divider = styled.hr`
  width: 100%;
  margin: 16px 0;
  border-top: 1px solid ${COLOR.GRAY};
  border-bottom: none;
`;

const FlexWrapper = styled.div`
  width: 100%;
`;

const FlexContent = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 5px 15px;
`;

const ItemTitle = styled.span`
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 40px;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 200px;
    margin-right: 40px;
    & .ui.search.selection.dropdown {
      padding: 0 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 0 6px !important;
    }
    & .ui.search.selection.dropdown > .text {
      font-size: 14px;
      top: 1px;
    }
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 16px;
  margin-right: 40px;
`;

const StyledRadio = styled(Radio)`
  &&& {
    label {
      padding-left: 24px;
    }
    input {
      &:checked ~ label:before {
        border: 1px solid ${COLOR.GREEN};
      }
      &:checked ~ label:after {
        background: ${COLOR.GREEN};
      }
      &:focus {
        &:checked ~ label:after {
          background: ${COLOR.GREEN};
        }
      }
    }
  }
`;

const DeleteButtonWrapper = styled.div`
  color: #f00;
  font-size: 16px;
  font-weight: bold;
  ${Mixins.flex('center')}
  justify-content: center;
  margin: auto;
`;

const DeleteButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    color: ${COLOR.DARK_GRAY};
  }
`;

const DeleteIcon = styled(Icon)`
  &&& {
    line-height: 1;
    height: auto;
  }
`;

const ChangeOrderButtonWrapper = styled.div`
  width: 30px;
  color: #f00;
  font-size: 20px;
  font-weight: bold;
  ${Mixins.flex('center')}
  justify-content: center;
  margin-left: -15px;
`;

const ChangeOrderButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    color: ${COLOR.GRAY};
  }
`;

const ChangeOrderIcon = styled(Icon)`
  &&& {
    line-height: 1;
    height: auto;
    transform: rotate(90deg);
  }
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  padding-top: 10px;
`;

const AddButton = styled(Button)`
  &&& {
    width: 165px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${COLOR.DARK_GREEN};
    padding: 12px 27px;
  }
`;

const ActionWrapper = styled(Card)`
  display: flex;
  justify-content: flex-end;
  &&& {
    padding-right: 0;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    width: 180px;
  }
`;

const ApplyButton = styled(Button)`
  margin-left: 16px;
  &&& {
    width: 180px;
  }
`;
