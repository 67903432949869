import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

import { CommonContent } from './CommonContent';
import { FileContent } from './FileContent';

export interface CommentRecord {
  id: number;
  offer_id: number;
  content: CommonContent;
  timestamp: Dayjs;
}

export class Comment extends Record<CommentRecord>({
  id: 0,
  offer_id: 0,
  content: new CommonContent(),
  timestamp: dayjs(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.content = new CommonContent(params.content);
    params.timestamp = dayjs.unix(params.timestamp);
    super(params);
  }

  get firstFileUrl() {
    if (this.content.files.list.size === 0) {
      return null;
    }
    return this.content.files.list.first<FileContent>().url;
  }

  get provisionalHashtag() {
    return this.content.provisionalHashtag;
  }

  changeText(text: string) {
    return this.update('content', (content) => content.changeText(text));
  }

  deleteFile(index: number) {
    return this.update('content', (content) => content.deleteFile(index));
  }

  addFiles(urls: string[]) {
    return this.update('content', (content) => content.addFiles(urls));
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  validate() {
    const errors: JSObject = {};

    if (!this.content.text && this.content.files.list.size === 0) {
      errors.text = '画像もしくはコメントが必要です';
    }

    return errors;
  }

  requestParams() {
    const { id, offer_id } = this.toObject();
    const params = {
      id: id < 1 ? null : id,
      offer_id,
      content: this.content.requestParams(),
    };
    return params;
  }
}

interface CommentListRecord {
  list: List<Comment>;
}

export class Comments extends Record<CommentListRecord>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data.map((p) => new Comment(p)));
    super({ list });
  }
}
