import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import Menu from 'components/atoms/Menu';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { Table, TableBody } from 'components/molecules/Table';
import { AccountList } from 'models/Domain/AccountList';
import {
  AGGREGATE_METHOD_TYPES,
  AggregateMethod,
  DisplayType,
  GbpPerformanceMATableData,
  ReviewType,
  SortKey,
  SortOrder,
  SortTarget,
  SortType,
  aggregateMethodLabel,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { GbpPerformanceMASearchCondition as SearchCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMASearchCondition';
import { COLOR } from 'style/color';
import { SelectOption, assertNever } from 'types/Common';

import {
  ImpressionsTableHeader,
  InteractionTableHeader,
  OverviewTableHeader,
  PeriodReviewTableHeader,
  TotalReviewTableHeader,
} from './TableHeader';
import {
  ImpressionsTableRow,
  ImpressionsWithComparisonTableRow,
  InteractionsTableRow,
  InteractionsWithComparisonTableRow,
  OverviewTableRow,
  OverviewWithComparisonTableRow,
  ReviewsTableRow,
  ReviewsWithComparisonTableRow,
} from './TableRow';

export type SortCondition = { key: SortKey; type: SortType; order: SortOrder } | null;

// ソート条件がnullの場合に適用されるソート条件
const defaultSortCondition: SortCondition = { key: 'organizationId', type: 'number', order: 'asc' };

const RateAvailableSortKey = [
  'interactions',
  'callClicks',
  'directionRequests',
  'websiteClicks',
  'conversations',
  'bookings',
];

const sortTargetOptions: SelectOption<SortTarget>[] = [
  { value: 'current', text: '集計期間の値' },
  { value: 'comparison', text: '比較期間の値' },
  { value: 'diffRate', text: '変化率' },
];

type Props = {
  className?: string;
  data: GbpPerformanceMATableData;
  organizations: AccountList;
  displayType: DisplayType;
  searchCondition: SearchCondition;
  isLoading: boolean;
  reviewType: ReviewType;
  aggregateMethod: AggregateMethod;
  onChangeReviewType: (value: ReviewType) => void;
  onChangeAggregateMethod: (value: AggregateMethod) => void;
};

export const GbpPerformanceMAAccountTable: React.FC<Props> = ({
  className,
  data,
  organizations,
  displayType,
  searchCondition,
  reviewType,
  aggregateMethod,
  onChangeReviewType,
  onChangeAggregateMethod,
  isLoading,
}) => {
  const [sortCondition, setSortCondition] = useState<SortCondition>(null);
  const [sortTarget, setSortTarget] = useState<SortTarget>('current');

  const items = useMemo(() => {
    const defaultItems = data.getDataPairList();

    // ソート条件がnullの場合、デフォルトのソート条件を適用
    const condition = sortCondition || defaultSortCondition;

    if (condition.key === 'organizationId' || condition.key === 'organizationName') {
      // 組織名、組織IDでソートする場合
      return defaultItems.sortByOrganizationInfo(condition.key, condition.order, organizations);
    }

    // それ以外（値）でソートする場合
    // 並べ替えの種類は、比較期間のデータがない場合は集計期間の値にする
    const target = data.hasComparisonData() ? sortTarget : 'current';
    return defaultItems.sortBy(condition.key, condition.type, condition.order, target);
  }, [data, sortCondition, sortTarget, organizations]);

  const total = useMemo(() => data.getTotalDataPair(), [data]);

  const toggleSortKey = useCallback(
    (key: SortKey, type?: SortType) =>
      setSortCondition((sortCondition) => {
        if (sortCondition == null || sortCondition.key !== key) {
          // type指定がある場合はそのタイプを利用。それ以外はnumber
          return { key, type: type || 'number', order: 'desc' };
        }

        // ソートタイプの指定がある場合
        if (type !== undefined) {
          if (sortCondition.type === type) {
            if (sortCondition.order === 'asc') {
              return null;
            }
            return { key, type, order: 'asc' };
          } else {
            return { key, type, order: 'desc' };
          }
        }

        if (displayType === 'interactions' && RateAvailableSortKey.includes(key)) {
          // 「インタラクション数」タブで、ソートキーが「率」ソートが可能な場合、以下の順で繰り返す
          // {type: 'number', order: 'desc'}
          // {type: 'number', order: 'asc'}
          // {type: 'rate', order: 'desc'}
          // {type: 'rate', order: 'asc'}
          // null
          if (sortCondition.order === 'desc') {
            return { key, type: sortCondition.type, order: 'asc' };
          } else {
            if (sortCondition.type === 'number') {
              return { key, type: 'rate', order: 'desc' };
            } else {
              return null;
            }
          }
        } else {
          // それ以外の場合
          if (sortCondition.order === 'desc') {
            return { key, type: 'number', order: 'asc' };
          } else {
            return null;
          }
        }
      }),
    [displayType],
  );

  useEffect(() => {
    // タブが変更された場合に、ソート対象が新しいタブで表示できる場合は維持し、そうでない場合はデフォルトのソート条件に戻す
    if (
      sortCondition == null ||
      ['organizationId', 'organizationName', 'impressions', 'interactions'].includes(sortCondition.key)
    ) {
      return;
    }
    setSortCondition(null);
    // displayTypeが変わった場合だけ対応したいので、depsの警告はここでは無視する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayType]);

  // 比較が有効かつの比較期間のデータが存在するか
  const hasComparisonData = searchCondition.filter.isEnabledComparison && data.hasComparisonData();
  return (
    <Wrapper className={className}>
      <Header>
        <Title>アカウント別（{aggregateMethod === 'average' ? '平均' : '合計'}）</Title>
        <PullDownContainer>
          <MenuWrapper>
            <MenuLabel>集計方法</MenuLabel>
            <Menu>
              {AGGREGATE_METHOD_TYPES.map((type) => (
                <Menu.Item
                  key={type}
                  name={type}
                  active={aggregateMethod === type}
                  onClick={() => onChangeAggregateMethod(type)}
                  style={{ padding: '0 12px' }}
                >
                  {aggregateMethodLabel[type]}
                </Menu.Item>
              ))}
            </Menu>
          </MenuWrapper>
          {displayType === 'activities' && (
            <MenuWrapper>
              <MenuLabel>クチコミの集計対象</MenuLabel>
              <Menu>
                <Menu.Item
                  name={'period'}
                  active={reviewType === 'period'}
                  onClick={() => onChangeReviewType('period')}
                  style={{ padding: '0 12px' }}
                >
                  期間
                </Menu.Item>
                <Menu.Item
                  name={'total'}
                  active={reviewType === 'total'}
                  onClick={() => onChangeReviewType('total')}
                  style={{ padding: '0 12px' }}
                >
                  累計
                </Menu.Item>
              </Menu>
            </MenuWrapper>
          )}
          <MenuWrapper>
            <PullDownLabel>並べ替えの種類</PullDownLabel>
            <PullDown
              disabled={!hasComparisonData /* 比較期間がない場合は変更不可 */}
              value={hasComparisonData ? sortTarget : 'current' /* 比較期間がない場合は「集計期間の値」固定 */}
              options={sortTargetOptions}
              onChange={(value: any) => setSortTarget(value)}
            />
          </MenuWrapper>
        </PullDownContainer>
      </Header>
      <TableWrapper>
        <Table unstackable>
          {displayType === 'overview' && (
            <OverviewTableHeader sortCondition={sortCondition} toggleSortKey={toggleSortKey} />
          )}
          {displayType === 'impressions' && (
            <ImpressionsTableHeader sortCondition={sortCondition} toggleSortKey={toggleSortKey} />
          )}
          {displayType === 'interactions' && (
            <InteractionTableHeader sortCondition={sortCondition} toggleSortKey={toggleSortKey} />
          )}
          {displayType === 'activities' && reviewType === 'total' && (
            <TotalReviewTableHeader sortCondition={sortCondition} toggleSortKey={toggleSortKey} />
          )}
          {displayType === 'activities' && reviewType === 'period' && (
            <PeriodReviewTableHeader sortCondition={sortCondition} toggleSortKey={toggleSortKey} />
          )}
          <TableBody>
            {(() => {
              const props = { item: total, total: true as const, sortCondition, searchCondition };
              if (!hasComparisonData) {
                switch (displayType) {
                  case 'overview':
                    return <OverviewTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsTableRow {...props} />;
                  case 'activities':
                    return <ReviewsTableRow {...props} reviewType={reviewType} />;
                  default:
                    return assertNever(displayType);
                }
              } else {
                switch (displayType) {
                  case 'overview':
                    return <OverviewWithComparisonTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsWithComparisonTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsWithComparisonTableRow {...props} />;
                  case 'activities':
                    return <ReviewsWithComparisonTableRow {...props} reviewType={reviewType} />;
                  default:
                    return assertNever(displayType);
                }
              }
            })()}

            {items.list.map((item) => {
              const organizationId = item.target.organizationId;
              const organization = organizations.find(organizationId);
              if (!organization) {
                return;
              }

              const props = { key: organizationId, item, organization, sortCondition, searchCondition };
              if (!hasComparisonData) {
                switch (displayType) {
                  case 'overview':
                    return <OverviewTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsTableRow {...props} />;
                  case 'activities':
                    return <ReviewsTableRow {...props} reviewType={reviewType} />;
                  default:
                    return assertNever(displayType);
                }
              } else {
                switch (displayType) {
                  case 'overview':
                    return <OverviewWithComparisonTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsWithComparisonTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsWithComparisonTableRow {...props} />;
                  case 'activities':
                    return <ReviewsWithComparisonTableRow {...props} reviewType={reviewType} />;
                  default:
                    return assertNever(displayType);
                }
              }
            })}
          </TableBody>
        </Table>
        {isLoading && (
          <LoadingWrapper>
            <Loader active={isLoading} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  gap: 16px;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;
const PullDownLabel = styled.div`
  padding-top: 4px;
  margin-right: 8px;
`;
const PullDownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  gap: 16px;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 0 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 0 6px !important;
    }
    & .ui.search.selection.dropdown > .text {
      font-size: 14px;
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
  min-height: 300px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const MenuLabel = styled.div`
  font-size: 14px;
`;
