import { LocationDescriptorObject } from 'history';
import actionCreatorFactory from 'typescript-fsa';

import { Account, AccountList } from 'models/Domain/AccountList';
import { GmbLocationDiffs } from 'models/Domain/GmbLocationDiffs';
import { User } from 'models/Domain/User';

const actionCreator = actionCreatorFactory('App');

export const AppActions = {
  initialize: actionCreator('initialize'),
  watchLatestRevision: actionCreator('watchLatestRevision'),
  setNeedsUpdate: actionCreator<boolean>('setNeedsUpdate'),
  moveTo: actionCreator<string | LocationDescriptorObject>('moveTo'),
  backHistory: actionCreator('backHistory'),
  getCurrentUser: actionCreator('getCurrentUser'),
  setCurrentUser: actionCreator<User>('setCurrentUser'),
  clearCurrentUser: actionCreator('clearCurrentUser'),
  setLogoLoading: actionCreator<boolean>('setLogoLoading'),
  setLoading: actionCreator<boolean>('setLoading'),

  // trueを指定した場合、同じメールアドレスのユーザーのセッションを破棄する
  signOut: actionCreator<boolean>('signOut'),

  onDropRejected: actionCreator<File[]>('onDropRejected'),
  getGmbLocationDiffs: actionCreator('getGmbLocationDiffs'),
  setGmbLocationDiffs: actionCreator<GmbLocationDiffs>('setGmbLocationDiffs'),
  reset: actionCreator('reset'),
  setIsInitialized: actionCreator<boolean>('setIsInitialized'),
  setAccountList: actionCreator<AccountList>('setAccountList'),
  setCurrentAccount: actionCreator<Account>('setCurrentAccount'),
  changeCurrentAccount: actionCreator<{ account: Account; resetPage: boolean }>('changeCurrentAccount'),
};
