import React, { useCallback, useEffect } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { OfferEditForm } from 'components/organisms/OfferEditForm';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { OfferCommon } from 'models/Composite/OfferCommon';
import { AppActions } from 'modules/app/actions';
import { OfferActions } from 'modules/offer/actions';

type Props = { match: { params: { offerId: string } } };

export const OfferEdit: React.FC<Props> = ({
  match: {
    params: { offerId },
  },
}) => {
  const { editOffer } = useSelector((state) => ({
    editOffer: state.offer.editOffer,
  }));

  const dispatch = useDispatch();
  const changeEditOffer = useCallback((v: OfferCommon) => dispatch(OfferActions.changeEditOffer(v)), [dispatch]);

  useEffect(() => {
    dispatch(OfferActions.getEditOffer(Number(offerId)));

    return () => {
      dispatch(OfferActions.clearEditOffer());
    };
  }, [dispatch, offerId]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('依頼 / 報告を編集')} />
      <StickyHeader title='依頼 / 報告を編集' />
      <Body>
        <OfferEditForm
          offerCommon={editOffer}
          changeOfferCommon={changeEditOffer}
          handleAcceptedFiles={(files) => dispatch(OfferActions.handleEditOfferAcceptedFiles(files))}
          handleRejectedFiles={(files) => dispatch(AppActions.onDropRejected(files))}
        />
        <ButtonsWrapper>
          <CreateButton
            priority='high'
            onClick={() => dispatch(OfferActions.updateEditOffer())}
            disabled={!editOffer.isValid}
          >
            保存
          </CreateButton>
          <CancelButton onClick={() => dispatch(goBack())}>キャンセル</CancelButton>
        </ButtonsWrapper>
      </Body>
    </MainWrapper>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-flow: row-reverse;
  margin-top: 32px;
  @media (max-width: 1024px) {
    display: block;
    margin-top: 0;
  }
`;

const CreateButton = styled(Button)`
  &&& {
    margin-left: 16px;
    @media (max-width: 1024px) {
      margin-top: 16px;
      margin-left: 0;
      width: 100%;
    }
  }
`;

const CancelButton = styled(Button)`
  &&& {
    @media (max-width: 1024px) {
      margin-top: 16px;
      width: 100%;
    }
  }
`;
