import React, { useMemo } from 'react';

import { COLOR } from 'style/color';

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) => Math.round(((100 - Math.min(val, 100)) / 100) * diameter);
const transition = `stroke-dashoffset 1s ease-out`;

type Props = {
  value: number;
  size?: string;
  responsive?: boolean;
  thresholds?: [red: number, orange: number, green: number];
};

export const ProgressCircle = React.memo<Props>(
  ({ value, size = '100', responsive = false, thresholds = [50, 75, 100] }) => {
    const svgSize = responsive ? '100%' : size;

    // progressによって色を変更する
    const progressColor = useMemo(() => {
      if (value < thresholds[0]) {
        return COLOR.ERROR;
      } else if (value < thresholds[1]) {
        return 'orange';
      } else {
        return COLOR.GREEN;
      }
    }, [value, thresholds]);

    const strokeDashoffset = useMemo(() => getOffset(value || 0), [value]);

    return (
      <svg width={svgSize} height={svgSize} viewBox='-25 -25 400 400'>
        <circle stroke={COLOR.LIGHT_GRAY} cx='175' cy='175' r='175' strokeWidth='25' fill='none' />
        <circle
          stroke={progressColor}
          transform='rotate(-90 175 175)'
          cx='175'
          cy='175'
          r='175'
          strokeDasharray='1100'
          strokeWidth='25'
          strokeDashoffset='1100'
          strokeLinecap='butt'
          fill='none'
          style={{ strokeDashoffset, transition }}
        />
        <text style={{ font: 'bold 5rem Helvetica, Arial, sans-serif' }} fill='#757575' x={radius} y={radius}>
          <tspan dx={8} textAnchor='middle' dominantBaseline='central'>
            {Number.isNaN(value) ? 0 : Math.floor(value)}%
          </tspan>
        </text>
      </svg>
    );
  },
);
