import { Record as ImmutableRecord } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { MemoList, MemoTagList } from 'models/Domain/Memo/Memo';
import { MemoSearchCondition } from 'models/Domain/Memo/MemoSearchCondition';
import { Pagination } from 'models/Domain/Pagination';

import { MemoActions } from './actions';

export class MemoState extends ImmutableRecord<{
  memoList: MemoList;
  memoTagList: MemoTagList;
  searchCondition: MemoSearchCondition;
  pagination: Pagination;
  isPreparedPage: boolean;
  isLoadingMemos: boolean;
  isLoadingTags: boolean;
}>({
  memoList: new MemoList(),
  memoTagList: new MemoTagList(),
  searchCondition: new MemoSearchCondition(),
  pagination: new Pagination(),
  isPreparedPage: false,
  isLoadingMemos: false,
  isLoadingTags: false,
}) {}

export const memoReducer = reducerWithInitialState(new MemoState())
  .case(MemoActions.setMemoList, (state, payload) => {
    return state.set('memoList', payload);
  })
  .case(MemoActions.setMemoTagList, (state, payload) => {
    return state.set('memoTagList', payload);
  })
  .case(MemoActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(MemoActions.setPagination, (state, payload) => {
    return state.set('pagination', payload);
  })
  .case(MemoActions.setIsPreparedPage, (state, payload) => {
    return state.set('isPreparedPage', payload);
  })
  .case(MemoActions.setIsLoadingMemos, (state, payload) => {
    return state.set('isLoadingMemos', payload);
  })
  .case(MemoActions.setIsLoadingTags, (state, payload) => {
    return state.set('isLoadingTags', payload);
  });
