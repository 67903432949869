import React from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { ExcludedKeyword } from 'models/Domain/SearchKeyword/ExcludedKeyword';

type ExcludedKeywordDeleteModalProps = {
  isOpen: boolean;
  excludedKeyword: ExcludedKeyword | null;
  onCancel: () => void;
  onCommit: () => void;
};

export const ExcludedKeywordDeleteModal = ({
  isOpen,
  excludedKeyword,
  onCancel,
  onCommit,
}: ExcludedKeywordDeleteModalProps) => (
  <Modal open={isOpen} size='small'>
    <Wrapper>
      以下の除外キーワードを削除します。よろしいですか？
      <StyledTable>
        <StyledTableHeader>
          <TableHeaderRow>
            <StyledTableHeaderCell width={12}>除外キーワード</StyledTableHeaderCell>
            <StyledTableHeaderCell width={4}>除外判定タイプ</StyledTableHeaderCell>
          </TableHeaderRow>
        </StyledTableHeader>
        <TableBody>
          <TableRow>
            <StyledTableCell>{excludedKeyword?.keyword}</StyledTableCell>
            <StyledTableCell>{excludedKeyword?.exactMatch ? '完全一致' : '部分一致'}</StyledTableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
      <DeleteActionsWrapper>
        <CloseButton onClick={onCancel}>キャンセル</CloseButton>
        <CommitDeleteButton negative priority='high' onClick={onCommit}>
          削除
        </CommitDeleteButton>
      </DeleteActionsWrapper>
    </Wrapper>
  </Modal>
);

const Wrapper = styled.div`
  margin: 32px;
`;

const StyledTable = styled(Table)`
  &&& {
    margin: 32px 0;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  position: sticky;
  top: -16px; // ヘッダが画面上に固定された時に余白ができてしまうため上に詰める
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    font-size: 15px;
    height: 52px; // ヘッダ以外の行の高さと合わせる
    padding: 8px 10px;
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    height: 48px;
    font-size: 15px;
    padding: 8px 10px;
  }
`;

const DeleteActionsWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
`;

const CommitDeleteButton = styled(Button)`
  &&& {
    width: 150px;
  }
`;

const CloseButton = styled(Button)`
  &&& {
    width: 150px;
  }
`;
