import React, { useCallback, useMemo, useState } from 'react';

import { Set } from 'immutable';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { Input } from 'components/atoms/Input';
import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { OfferType } from 'models/Domain/Offer';
import { OfferGroupStatus } from 'models/Domain/OfferGroup/OfferGroup';
import { FilterStatus, SortKey, SortOrder, SortStatus } from 'models/Domain/OfferGroup/OfferGroupSearchCondition';
import { User, UserList } from 'models/Domain/User';
import { COLOR } from 'style/color';

type OfferGroupsFilterProps = {
  className?: string;
  filter: FilterStatus;
  sort: SortStatus;
  currentUser: User;
  userList: UserList;
  onChangeFilter: (filter: FilterStatus) => void;
  onChangeSort: (sort: SortStatus) => void;
  onCommitFilter?: () => void;
  disableCommitButton?: boolean;
};

export const OfferGroupsFilter: React.FC<OfferGroupsFilterProps> = ({
  className,
  filter,
  sort,
  currentUser,
  userList,
  onChangeFilter,
  onChangeSort,
  onCommitFilter,
  disableCommitButton,
}) => {
  const [userSearchQuery, setUserSearchQuery] = useState<string>('');

  /**
   * ソートを変更する
   * @param key 変更するソートのキー
   * @param value 変更するソートの値
   */
  const handleChangeSort = useCallback(
    (key: SortKey, sortOrder: SortOrder) => {
      let updatedSort = sort.set('sort_key', key);
      updatedSort = updatedSort.set('sort_order', sortOrder);
      onChangeSort(updatedSort);
    },
    [sort, onChangeSort],
  );

  /**
   * 種別のフィルターを変更する
   * @param key 更新対象
   * @param value チェックon/off
   */
  const handleChangeType = useCallback(
    (key: OfferType, value: boolean) => {
      const updatedFilter = filter.setType(key, value);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * 状態のフィルターを変更する
   * @param key 更新対象
   * @param value チェックon/off
   */
  const handleChangeStatus = useCallback(
    (key: OfferGroupStatus, value: boolean) => {
      const updatedFilter = filter.setStatus(key, value);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  // 作成者選択ドロップダウンのオプションを生成する
  const userOptions = useMemo(() => {
    return userList
      .getSortedListByRole('desc')
      .sort((user) => (user.id === currentUser.id ? -1 : 0)) // 自分を一番上に持ってくる
      .map((user) => ({
        value: user.id,
        text: user.id === currentUser.id ? `${user.fullName}（自分）` : user.fullName, // 自分の場合「（自分）」を追加
      }))
      .toArray();
  }, [currentUser.id, userList]);

  /**
   * 「作成者」項目で「すべて」を選んだ場合の処理
   *
   * @param value 変更後のチェック状態
   */
  const handleChangeAllCreatedByUsers = useCallback(
    (value: boolean) => {
      if (value !== true) {
        const updatedFilter = filter.setCreatedByUserIds(Set(), false);
        onChangeFilter(updatedFilter);
        return;
      }

      const updatedFilter = filter.setCreatedByUserIds(Set(userOptions.map((target) => target.value)), true);
      onChangeFilter(updatedFilter);
    },
    [filter, userOptions, onChangeFilter],
  );

  /**
   * 「作成者」項目でユーザーを選んだ場合の処理
   *
   * @param value 変更対象のユーザーのID
   */
  const handleChangeCreatedByUser = useCallback(
    (userId: number) => {
      let updatedCreatedByUserIds: Set<number>;
      if (filter.created_by.has(userId)) {
        // 選択解除の場合
        updatedCreatedByUserIds = filter.created_by.delete(userId);
      } else {
        // 選択追加の場合
        updatedCreatedByUserIds = filter.created_by.add(userId);
      }
      const isAllUserIds = updatedCreatedByUserIds.equals(Set(userOptions.map((op) => op.value)));
      const updatedFilter = filter.setCreatedByUserIds(updatedCreatedByUserIds, isAllUserIds);
      onChangeFilter(updatedFilter);
    },
    [filter, userOptions, onChangeFilter],
  );

  const createdByUserLabel = useMemo(() => {
    if (filter.created_by.size === 0) {
      return 'すべて';
    }
    const availableUserIds = Set(userList.getUserIds());
    if (availableUserIds.equals(filter.created_by)) {
      return 'すべて';
    }

    const nameLabel = filter.created_by
      .toArray()
      .sort()
      .map((userId) => userList.findUser(userId)?.fullName || '')
      .join('、');

    // 1人の場合は、名前をそのまま
    if (filter.created_by.size === 1) {
      return nameLabel;
    }
    return `${filter.created_by.size}人 （${nameLabel}）`;
  }, [filter.created_by, userList]);

  /**
   * グループ、店舗のフィルターを変更する
   * @param group グループ
   * @param storeIds 店舗のID
   * @param isAllStores すべての店舗が選択されているか
   * @param showClosedStores 閉店店舗を表示するか
   */
  const handleChangeGroupStore = useCallback(
    (group: FilterStatus['store'], storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
      const updatedFilter = filter.merge({
        store: group,
        store_ids: storeIds,
        is_all_store_ids: isAllStores,
        show_closed_store: showClosedStores,
      });
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  return (
    <Card className={className}>
      <FilterWrapper>
        <GroupStoreSelect
          group={filter.store}
          storeIds={filter.store_ids}
          showClosedStores={filter.show_closed_store}
          onChange={handleChangeGroupStore}
        />
        <ContentWrapper>
          <ContentLabel>種別</ContentLabel>
          <NormalMultiSelectBox value={filter.getTypeOptionLabel()}>
            {filter.type.map((type) => (
              <CheckLabel
                onChange={() => handleChangeType(type.key, !type.value)}
                checked={type.value}
                key={type.key}
                label={filter.getTypeLabel(type.key)}
              />
            ))}
          </NormalMultiSelectBox>
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>進捗</ContentLabel>
          <NormalMultiSelectBox value={filter.getStatusOptionLabel()}>
            {filter.status.map((status) => (
              <CheckLabel
                onChange={() => handleChangeStatus(status.key, !status.value)}
                checked={status.value}
                key={status.key}
                label={filter.getStatusLabel(status.key)}
              />
            ))}
          </NormalMultiSelectBox>
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>作成者</ContentLabel>
          <WideMultiSelectBox value={createdByUserLabel}>
            <StyledInput placeholder='スタッフを検索' value={userSearchQuery} onChange={(v) => setUserSearchQuery(v)} />
            <CheckLabel
              checked={filter.created_by.size === userOptions.length}
              onChange={() => handleChangeAllCreatedByUsers(!(filter.created_by.size === userOptions.length))}
              label='すべて'
            />
            {userOptions
              .filter((target) => target.text.indexOf(userSearchQuery) > -1)
              .map((target, idx) => (
                <CheckLabel
                  key={idx}
                  onChange={() => handleChangeCreatedByUser(target.value)}
                  checked={filter.created_by.some((id) => id === target.value)}
                  label={target.text}
                />
              ))}
          </WideMultiSelectBox>
        </ContentWrapper>

        <ContentWrapper>
          <ContentLabel>並び順の対象</ContentLabel>
          <PullDown
            value={sort.sort_key}
            options={sort.sortTypeOptions}
            placeholder={'並び順の対象'}
            onChange={(value: any) => handleChangeSort(value, sort.sort_order)}
          />
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>並び順</ContentLabel>
          <PullDown
            value={sort.sort_order}
            options={sort.sortKeyOptions}
            placeholder={'並び順'}
            onChange={(value: any) => handleChangeSort(sort.sort_key, value)}
          />
        </ContentWrapper>
        <SearchButtonWrapper>
          <SearchButton priority='high' disabled={disableCommitButton || !filter.isValid()} onClick={onCommitFilter}>
            検索
          </SearchButton>
        </SearchButtonWrapper>
      </FilterWrapper>
    </Card>
  );
};

const FilterWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0px;
  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0px;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const NormalMultiSelectBox = styled(MultiSelectBox)`
  width: 176px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const WideMultiSelectBox = styled(NormalMultiSelectBox)`
  width: 380px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SearchButtonWrapper = styled(ContentWrapper)`
  margin-left: auto;
  padding-top: 28px;
`;

const SearchButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const StyledInput = styled(Input)`
  &&& {
    display: inline-block;
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    @media (max-width: 600px) {
      width: calc(100% - 32px);
      margin-bottom: 8px;
    }
    & > div.ui > input {
      padding: 4px 6px;
    }
  }
`;
