import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StoreListEditForm } from 'components/organisms/StoreListEditForm';
import StoreList from 'models/Domain/StoreList';
import { StoreState } from 'modules/store/reducers';
import { StoreListState } from 'modules/storeList/reducers';

type Props = { setStoreListForCreate: (v: StoreList) => void; createStoreList: () => void } & Pick<
  StoreListState,
  'storeListForCreate'
> &
  Pick<StoreState, 'stores'>;

export const CreateTabContent = React.memo<Props>(
  ({ storeListForCreate, stores, setStoreListForCreate, createStoreList }) => {
    return (
      <Wrapper>
        <StoreListEditForm storeList={storeListForCreate} stores={stores} setStoreList={setStoreListForCreate} />

        <ButtonWrapper>
          <StyledButton priority='high' onClick={() => createStoreList()} disabled={!storeListForCreate.isValid}>
            登録
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    @media (max-width: 860px) {
      width: 100%;
    }
  }
`;
