import actionCreatorFactory from 'typescript-fsa';

import { SearchVolumeSearchCondition } from 'models/Domain/SearchVolume/SearchVolumeSearchCondition';
import { SearchVolumeSearchHistory } from 'models/Domain/SearchVolume/SearchVolumeSearchHistory';
import { SearchVolumeSearchResults } from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import { SearchVolumeSearchStatus } from 'models/Domain/SearchVolume/SearchVolumeSearchStatus';

const actionCreator = actionCreatorFactory('searchVolume');

export const SearchVolumeActions = {
  clearState: actionCreator('clearState'),
  initializeIndexPage: actionCreator('initializeIndexPage'),
  initializeResultPage: actionCreator<string>('initializeResultPage'),
  setIsPreparedIndexPage: actionCreator<boolean>('setIsPreparedIndexPage'),
  setIsPreparedResultPage: actionCreator<boolean>('setIsPreparedResultPage'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),
  setSearchCondition: actionCreator<SearchVolumeSearchCondition>('setSearchCondition'),
  setSearchHistory: actionCreator<SearchVolumeSearchHistory>('setSearchHistory'),
  deleteSearchHistory: actionCreator<string>('deleteSearchHistory'),
  commitSearchCondition: actionCreator<SearchVolumeSearchCondition>('commitSearchCondition'),
  setIsCommitDisabled: actionCreator<boolean>('setIsCommitDisabled'),
  setIsLoadingSearchHistory: actionCreator<boolean>('setIsLoadingSearchHistory'),
  fetchSearchHistory: actionCreator<number>('fetchSearchHistory'),
  setSearchStatus: actionCreator<SearchVolumeSearchStatus>('setSearchStatus'),
  fetchSearchStatus: actionCreator<string>('fetchSearchStatus'),
  setIsLoadingSearchStatus: actionCreator<boolean>('setIsLoadingSearchStatus'),
  setSearchResults: actionCreator<SearchVolumeSearchResults>('setSearchResults'),
};
