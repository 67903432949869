import React, { useCallback } from 'react';

import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';
import { Label, Popup, Icon as SemanticIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { MenuHelp as Help } from 'helpers/ContextHelp';
import { MenuSummaryItem as Item, MenuSummary } from 'models/Domain/Menu/Menu';
import { Path } from 'routes';
import { COLOR } from 'style/color';

import { ContextHelp } from '../../molecules/ContextHelp';

type Props = {
  items: MenuSummary['items'];
  onChange: (items: MenuSummary['items']) => void;
};

const DISPLAY_STORE_COUNT = 3;

export const MenuListEditTable = React.memo<Props>(({ items, onChange }) => {
  const { stores } = useSelector((state) => state.store);
  const { storeLists } = useSelector((state) => state.storeList);

  const targetLabel = useCallback(
    (item: Item) => {
      const groupIdsCount = item.groupIds?.size ?? 0;
      const storeIdsCount = item.storeIds?.size ?? 0;

      // グループが選択されている場合、グループ名
      if (groupIdsCount > 0) {
        return (
          <>
            <GroupLabel circular size={'small'}>
              グループ
            </GroupLabel>
            {item.groupIds
              ?.map((groupId) => storeLists.findStoreList(groupId)?.name)
              .filter((group) => group)
              .join('、')}
          </>
        );
      }

      // 店舗が選択されている場合、店舗名（4店舗目以降省略）
      if (storeIdsCount > DISPLAY_STORE_COUNT) {
        const storeNames =
          item.storeIds?.map((storeId) => stores.findStore(storeId)?.fullName).filter((store) => store) ??
          ImmutableList();
        return (
          <>
            {storeNames.slice(0, DISPLAY_STORE_COUNT).join('、')}{' '}
            <Popup
              content={
                <PopupContent>
                  {storeNames.map((name, index) => (
                    <div key={index}>{name}</div>
                  ))}
                </PopupContent>
              }
              trigger={<PopupTrigger>{`他 ${storeIdsCount - DISPLAY_STORE_COUNT} 店舗`}</PopupTrigger>}
            />
          </>
        );
      }

      if (storeIdsCount > 0) {
        return item.storeIds
          ?.map((storeId) => stores.findStore(storeId)?.fullName)
          .filter((store) => store)
          .join('、');
      }

      // グループも店舗も選択されていない場合、全店舗
      return '全ての店舗';
    },
    [stores, storeLists],
  );

  const handleOnChangeOrder = useCallback(
    (index: number) => {
      const target = items.list.get(index);
      if (target == null) {
        return;
      }
      onChange(items.update('list', (list) => list.delete(index).insert(index - 1, target)));
    },
    [onChange, items],
  );

  const showChangeOrderIcon = items.list.size > 1;

  return (
    <Wrapper showChangeOrderIcon={showChangeOrderIcon}>
      <TableWrapper>
        <StyledTable>
          <TableHeader>
            <TableHeaderRow>
              <SortHeaderCell />
              <StyledTableHeaderCell>メニュー名</StyledTableHeaderCell>
              <StyledTableHeaderCell>対象店舗・グループ</StyledTableHeaderCell>
              <StyledTableHeaderCell>
                GBP連携
                <ContextHelp content={Help.applyToGbp} />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>更新日</StyledTableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {items.list.map((item, index) => (
              <TableRow key={item.id}>
                <SortCell>
                  {index > 0 && (
                    <ChangeOrderButtonWrapper>
                      <ChangeOrderButton onClick={() => handleOnChangeOrder(index)}>
                        <ChangeOrderIcon />
                      </ChangeOrderButton>
                    </ChangeOrderButtonWrapper>
                  )}
                </SortCell>
                <StyledTableCell>
                  <DetailLink to={Path.menu.edit.replace(':id', `${item.id}`)} target={'_blank'}>
                    {item.name}
                  </DetailLink>
                </StyledTableCell>
                <StyledTableCell>{targetLabel(item)}</StyledTableCell>
                <StyledTableCell>
                  <ApplyToGbp>
                    <StyledIcon type={item.applyToGbp ? 'active' : 'disabled'} />
                    <ApplyToGbpLabel>{item.applyToGbp ? '有効' : '無効'}</ApplyToGbpLabel>
                  </ApplyToGbp>
                </StyledTableCell>
                <StyledTableCell>
                  <UpdateAt>{item.updateAt?.format('YYYY/MM/DD') ?? 'ー'}</UpdateAt>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ showChangeOrderIcon: boolean }>`
  margin-left: ${({ showChangeOrderIcon }) => (showChangeOrderIcon ? '24px' : '0')};
`;

const TableWrapper = styled.div``;
const StyledTable = styled(Table)``;
const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    text-align: left;
  }
`;
const StyledTableCell = styled(TableCell)`
  &&& {
    text-align: left;
  }
`;

const GroupLabel = styled(Label)`
  &&& {
    margin-right: 4px;
  }
`;

const PopupContent = styled.div`
  font-size: 12px;
`;

const PopupTrigger = styled.span`
  display: inline-block;
  margin-left: 8px;
  text-decoration: underline;
  font-size: 12px;
`;

const DetailLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`;

const UpdateAt = styled.div`
  font-family: monospace;
`;

const SortHeaderCell = styled(TableHeaderCell)`
  &&& {
    width: 0;
    max-width: 0;
    padding: 0;
  }
`;

const SortCell = styled(TableCell)`
  &&& {
    position: relative;
    width: 0;
    max-width: 0;
    padding: 0;
  }
`;

const ChangeOrderButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.CHARCOAL_GRAY};
  position: absolute;
  top: -7px;
  left: -32px;
`;

const ChangeOrderButton = styled(Button).attrs({ priority: 'low' })`
  &&& {
    color: ${COLOR.CHARCOAL_GRAY};
  }
`;

const ChangeOrderIcon = styled(SemanticIcon).attrs({ name: 'exchange' })`
  &&& {
    line-height: 1;
    height: auto;
    transform: rotate(90deg);
  }
`;

const ApplyToGbp = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ApplyToGbpLabel = styled.div``;

const StyledIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  padding: 0;
`;
