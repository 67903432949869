import React, { useEffect, useMemo, useState } from 'react';

import { List, is } from 'immutable';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { Competitor } from 'models/Domain/Competitor/Competitor';
import { CompetitorSearchResult as SearchResult } from 'models/Domain/Competitor/CompetitorSearchResult';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  isLoading: boolean;
  isError: boolean;
  stores: Stores;
  searchResult: SearchResult;
  filterWords: List<string>;
  selectedCompetitors: List<Competitor>;
  registeredCompetitors: List<Competitor>;
  onSelectCompetitor: (competitor: Competitor) => void;
};

const FOLDED_TABLE_ITEMS = 3;

export const CompetitorTable = React.memo<Props>(
  ({
    className,
    isLoading,
    isError,
    stores,
    searchResult,
    filterWords,
    onSelectCompetitor,
    selectedCompetitors,
    registeredCompetitors,
  }) => {
    const [showMoreData, setShowMoreData] = useState(false);
    useEffect(() => {
      setShowMoreData(false);
    }, [isLoading]);
    const store = useMemo(() => stores.findStore(searchResult.storeId), [searchResult, stores]);
    const filteredSearchResultItems = useMemo(() => {
      let items = searchResult.items;
      // 自店舗がPlaceIDを持っている場合は、PlaceIDをもとに検索結果から自店舗を除外する
      if (store?.placeId) {
        items = items.filterNot((item) => item.placeId === store.placeId);
      }
      // 絞り込みワードが指定されていたら、店舗名に絞り込みワードを含むものだけにする
      return filterWords.isEmpty()
        ? items
        : items.filter((item) => filterWords.some((filterWord) => item.storeName.includes(filterWord)));
    }, [filterWords, searchResult.items, store]);

    const notificationMessage = useMemo(() => {
      if (isLoading) {
        return 'データ取得中';
      } else if (isError) {
        if (!store?.location.latlng.isValid()) {
          return '対象の店舗に緯度経度が設定されていないため、取得できませんでした';
        }
        return '店舗の取得に失敗しました';
      } else if (searchResult.items.isEmpty()) {
        return '条件に一致する店舗がありませんでした';
      } else if (filteredSearchResultItems.isEmpty()) {
        return '条件に一致する店舗がありませんでした';
      }
      return '';
    }, [filteredSearchResultItems, isError, isLoading, searchResult.items, store]);

    if (!store) {
      return null;
    }

    return (
      <Wrapper className={className}>
        <TableTitle>
          <StoreName>{store.fullName}</StoreName>
          <StatusIcon>
            {isLoading ? (
              <LoaderWrapper>
                <Loader active={true} size='mini' inline />
              </LoaderWrapper>
            ) : isError ? (
              <IconWrapper>
                <StyledIcon type='status_error' />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <StyledIcon type='status_active' />
              </IconWrapper>
            )}
          </StatusIcon>
        </TableTitle>
        <TableWrapper>
          <Table unstackable>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell />
                <TableHeaderCell>店舗名</TableHeaderCell>
                <TableHeaderCell>住所</TableHeaderCell>
                <TableHeaderCell>店舗からの距離</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {filteredSearchResultItems.map((item, index) => {
                if (!showMoreData && index >= FOLDED_TABLE_ITEMS) {
                  return null;
                }
                const distance = store.distanceFrom(item.point.latitude, item.point.longitude, 'km');
                const distanceStr =
                  distance !== undefined
                    ? distance >= 1
                      ? `${distance.toFixed(1)}km`
                      : `${(distance * 1000).toFixed()}m`
                    : '';
                const competitor = item.toCompetitor();
                const isRegistered = !!registeredCompetitors.find(
                  (c) => c.placeId === item.placeId && c.storeId === item.storeId,
                );
                return (
                  <StyledTableRow
                    key={item.placeId}
                    isRegistered={isRegistered}
                    onClick={() => !isRegistered && onSelectCompetitor(competitor)}
                  >
                    <TableCell style={{ minWidth: '60px' }}>
                      {isRegistered ? (
                        <Popup
                          content={<PopupContent>競合登録済みの店舗です。削除は一覧画面から行えます。</PopupContent>}
                          trigger={<div>登録中</div>}
                        />
                      ) : (
                        <CheckboxWrapper>
                          <SmallCheckBox
                            checked={isRegistered || !!selectedCompetitors.find((c) => is(c, competitor))}
                          />
                        </CheckboxWrapper>
                      )}
                    </TableCell>
                    <TableCell>
                      <StoreName>{item.storeName}</StoreName>
                      <LinkWrapper>
                        <ExternalLink onClick={(e) => e.stopPropagation()} href={item.mapUrl} />
                      </LinkWrapper>
                    </TableCell>
                    <TableCell>{item.address}</TableCell>
                    <TableCell style={{ minWidth: '120px' }}>{distanceStr}</TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          {!!notificationMessage && <Notification>{notificationMessage}</Notification>}
          {!isLoading && filteredSearchResultItems.size > FOLDED_TABLE_ITEMS && (
            <ViewMore onClick={() => setShowMoreData(!showMoreData)}>
              {showMoreData ? '表示を減らす' : 'もっと見る'}
            </ViewMore>
          )}
        </TableWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const TableTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
`;

const StyledTableRow = styled(TableRow)<{ isRegistered: boolean }>`
  cursor: pointer;
  &&& {
    background: ${({ isRegistered }) => (isRegistered ? '#f8f8f8' : 'white')};
    color: red !important;
  }
`;

const TableWrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 14px;
  margin-left: 8px;
`;

const ViewMore = styled.div`
  width: 100%;
  margin: 8px 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

// テーブル要素が空の場合に表示する
export const Notification = styled.div`
  text-align: center;
  background: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top-width: 0;

  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  color: ${COLOR.GRAY};
  font-size: 16px;
`;

const StoreName = styled.span`
  margin-right: 8px;
`;
const LinkWrapper = styled.span``;

const StatusIcon = styled.span``;

const LoaderWrapper = styled.span``;

const PopupContent = styled.div`
  font-size: 12px;
`;

const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  background: red;
  height: 20px;
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  top: 4px;
`;
