import React, { useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { ActivityContent } from 'components/pageComponents/Top/ActivityContent';
import { DashboardChecklist } from 'components/pageComponents/Top/DashboardChecklist';
import { SectionWrapper } from 'components/pageComponents/Top/Section';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { Organization } from 'models/Domain/Organization';
import { TopActions as Actions } from 'modules/Top/actions';

export const Top: React.FC = () => {
  const dispatch = useDispatch();

  const offerActivities = useSelector((state) => state.offer.offerActivities);
  const stores = useSelector((state) => state.store.stores);
  const { storeIds, group, checklist, isLoading } = useSelector((state) => state.top);
  const organization = useSelector((state) => state.app.currentUser.organization) || new Organization();
  const currentUser = useSelector((state) => state.app.currentUser);
  const { initializePage, setStoreIds, setGroup, commitStoreIds } = useMemo(
    () => bindActionCreators(Actions, dispatch),
    [dispatch],
  );

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  return (
    <>
      <MainWrapper>
        <Helmet title={'STORECAST'} />
        <StickyHeader title='ダッシュボード' />
        <Body>
          {!offerActivities.offers.isEmpty() && <ActivityContent offerActivities={offerActivities} stores={stores} />}
          <SectionWrapper>
            <DashboardChecklist
              isLoading={isLoading}
              storeIds={storeIds}
              group={group}
              checklist={checklist}
              onChangeGroup={setGroup}
              onChangeStoreIds={setStoreIds}
              commitStoreIds={commitStoreIds}
              organization={organization}
              currentUser={currentUser}
            />
          </SectionWrapper>
        </Body>
      </MainWrapper>
    </>
  );
};
