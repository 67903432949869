import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import ChannelService from 'helpers/ChannelService';
import { COLOR } from 'style/color';

export const ChannelServiceButton: React.FC<{ className?: string }> = ({ className }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    ChannelService.onBadgeChanged((unreadCount: number) => setUnreadCount(unreadCount));
  });

  const hasUnread = unreadCount > 0;
  return (
    <Wrapper className={className}>
      <SupportButtonWrapper onClick={() => ChannelService.showMessenger()} hasUnread={hasUnread}>
        <SupportIcon type='comment' />
        {hasUnread ? '新着あり' : 'サポート'}
      </SupportButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 5px;
  left: 10px;
  padding: 10px;
  @media (max-width: 600px) {
    left: 10px;
    bottom: 10px;
  }
`;

const SupportButtonWrapper = styled.div<{ hasUnread: boolean }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  border: solid 2px;
  width: 101px;
  border-color: ${(props) => (props.hasUnread ? COLOR.PURPLE : COLOR.GREEN)};
  background-color: ${COLOR.WHITE};
  padding: 0px 10px;
  cursor: pointer;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.16);

  &:after {
    display: ${(props) => (props.hasUnread ? 'inline-block;' : 'none')};
    position: absolute;
    top: 15px;
    left: 32px;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${COLOR.PURPLE};
  }
`;

const SupportIcon = styled(Icon)`
  &&& {
    position: relative;
    padding: 0;
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
`;
