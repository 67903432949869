import React from 'react';

import { is } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Prompt } from 'components/atoms/Prompt';
import { UserEditForm } from 'components/organisms/UserEditForm';
import { useConfirmLeave } from 'hooks/useConfirmLeave';
import { User } from 'models/Domain/User';

type Props = {
  editUser: User;
  targetUser: User;
  isMyAccountEdit: boolean;
  canEdit: boolean;
  canDelete: boolean;
  setIsOpenDeleteModal?: (v: boolean) => void;
  setIsOpenPasswordChangeModal?: () => void;
  setEditUser: (v: User) => void;
  updateEditUser: () => void;
  uploadEditUserImage: (v: File) => void;
};

export const UserEditContent: React.FC<Props> = ({
  editUser,
  targetUser,
  isMyAccountEdit,
  canEdit,
  canDelete,
  setEditUser,
  updateEditUser,
  uploadEditUserImage,
  setIsOpenDeleteModal,
  setIsOpenPasswordChangeModal,
}) => {
  const changed = !is(editUser, targetUser);

  // 未保存の変更がある場合に、他のページに遷移しようとした場合のメッセージ
  const promptMessage =
    '変更を保存せずにページから離れようとしています。\n保存されていない内容は失われてしまいますが、よろしいですか？';

  // 未保存の変更がある場合に、ブラウザを閉じようとした場合の警告メッセージ対応(カスタムメッセージ設定不可)
  useConfirmLeave(changed);

  return (
    <Wrapper>
      <UserEditForm
        isMyAccountEdit={isMyAccountEdit}
        user={editUser}
        changePassword={setIsOpenPasswordChangeModal}
        changeUser={setEditUser}
        uploadUserImage={uploadEditUserImage}
        canEdit={canEdit}
      />

      {canEdit && (
        <ButtonWrapper>
          <Button onClick={() => updateEditUser()} priority='high' disabled={!changed || !editUser.isValid}>
            更新
          </Button>
        </ButtonWrapper>
      )}

      {canDelete && setIsOpenDeleteModal && (
        <ButtonWrapper>
          <Button negative={true} onClick={() => setIsOpenDeleteModal(true)}>
            削除
          </Button>
        </ButtonWrapper>
      )}

      <Prompt when={changed} message={promptMessage} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;
