import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { Input } from 'components/atoms/Input';
import { isNonNegativeInteger } from 'helpers/utils';
import { OpeningDate } from 'models/Domain/GmbLocation/OpeningDate';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

import { ContextHelp } from '../../molecules/ContextHelp';

type Props = {
  storeForEdit: Store;
  changeStore: (v: Store) => void;
};

export const EditOpeningDate = React.memo<Props>(({ storeForEdit, changeStore }) => {
  const [inputYear, setInputYear] = useState<string>('');
  const [inputMonth, setInputMonth] = useState<string>('');
  const [inputDay, setInputDay] = useState<string>('');
  const [editingOpeningDate, setEditingOpeningDate] = useState<OpeningDate>(new OpeningDate());

  useEffect(() => {
    const openingDate = storeForEdit.location.openingDate ?? new OpeningDate();
    const { year, month, day } = openingDate;
    setInputYear(year != null ? String(year) : '');
    setInputMonth(month != null ? String(month) : '');
    setInputDay(day != null ? String(day) : '');
    setEditingOpeningDate(openingDate || new OpeningDate());
  }, [storeForEdit.location.openingDate]);

  const handleOnChangeOpeningDate = useCallback(
    (openingDate: OpeningDate | null) => {
      const isDeleted = openingDate == null || (!inputYear && !inputMonth && !inputDay);
      if (isDeleted) {
        changeStore(storeForEdit.changeOpeningDate(null));
      } else {
        changeStore(storeForEdit.changeOpeningDate(openingDate));
      }
    },
    [changeStore, inputDay, inputMonth, inputYear, storeForEdit],
  );

  const handleOnChangeYear = useCallback((value: string) => {
    setInputYear(value);
  }, []);

  const handleOnBlurYear = useCallback(
    (value: string) => {
      if (isNonNegativeInteger(value)) {
        const year = Number.parseInt(value, 10);
        handleOnChangeOpeningDate(editingOpeningDate.changeYear(year));
      } else {
        setInputYear('');
        handleOnChangeOpeningDate(editingOpeningDate.changeYear(null));
      }
    },
    [editingOpeningDate, handleOnChangeOpeningDate],
  );

  const handleOnChangeMonth = useCallback((value: string) => {
    setInputMonth(value);
  }, []);

  const handleOnBlurMonth = useCallback(
    (value: string) => {
      if (isNonNegativeInteger(value)) {
        const month = Number.parseInt(value, 10);
        handleOnChangeOpeningDate(editingOpeningDate.changeMonth(month));
      } else {
        setInputMonth('');
        handleOnChangeOpeningDate(editingOpeningDate.changeMonth(null));
      }
    },
    [editingOpeningDate, handleOnChangeOpeningDate],
  );

  const handleOnChangeDay = useCallback((value: string) => {
    setInputDay(value);
  }, []);

  const handleOnBlurDay = useCallback(
    (value: string) => {
      if (isNonNegativeInteger(value)) {
        const day = Number.parseInt(value, 10);
        handleOnChangeOpeningDate(editingOpeningDate.changeDay(day));
      } else {
        setInputDay('');
        handleOnChangeOpeningDate(editingOpeningDate.changeDay(null));
      }
    },
    [editingOpeningDate, handleOnChangeOpeningDate],
  );

  const errorMessages = useMemo(() => {
    const validateResult = editingOpeningDate.validate();
    return [validateResult.year.error, validateResult.month.error, validateResult.day.error].filter((x) => x);
  }, [editingOpeningDate]);

  const showErrorMessage = useMemo(() => {
    return storeForEdit.location.openingDate != null && errorMessages.length > 0;
  }, [errorMessages.length, storeForEdit.location.openingDate]);

  return (
    <Wrapper>
      <Title>
        開業日
        <ContextHelp content={'この住所で開業した日付、または、開業する日付を入力してください。'} />{' '}
      </Title>
      <FlexWrapper>
        <ItemWrapper>
          <ItemLabel>年</ItemLabel>
          <Input
            value={inputYear}
            onChange={handleOnChangeYear}
            onBlur={(e) => handleOnBlurYear(e.target.value)}
            error={showErrorMessage && !editingOpeningDate.validateYear().isValid}
            placeholder={'2000'}
          />
        </ItemWrapper>
        <ItemWrapper>
          <ItemLabel>月</ItemLabel>
          <Input
            value={inputMonth}
            onChange={handleOnChangeMonth}
            onBlur={(e) => handleOnBlurMonth(e.target.value)}
            error={showErrorMessage && !editingOpeningDate.validateMonth().isValid}
            placeholder={'1'}
          />
        </ItemWrapper>
        <ItemWrapper>
          <ItemLabel>日（省略可）</ItemLabel>
          <Input
            value={inputDay}
            onChange={handleOnChangeDay}
            onBlur={(e) => handleOnBlurDay(e.target.value)}
            error={showErrorMessage && !editingOpeningDate.validateDay().isValid}
            placeholder={'1'}
          />
        </ItemWrapper>
      </FlexWrapper>
      <ErrorWrapper>
        {showErrorMessage && errorMessages.map((message, i) => <Error key={i}>{message}</Error>)}
      </ErrorWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div``;
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
`;
const FlexWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
`;
const ItemWrapper = styled.div``;
const ItemLabel = styled.div`
  color: ${COLOR.BLACK};
  display: flex;
  font-weight: bold;
  margin-bottom: 8px;
  align-items: center;
`;

const ErrorWrapper = styled.div`
  min-height: 24px;
`;

const Error = styled.div`
  color: ${COLOR.ERROR};
`;
