import React, { useEffect, useRef } from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Linkify } from 'components/atoms/Linkify';
import { ResizeImage } from 'components/atoms/ResizeImage';
import { ImageCommentOrderIcon } from 'components/molecules/ImageCommentOrderIcon';
import { UserThumbnail } from 'components/molecules/UserThumbnail';
import { OfferTimeline, OfferTimelines } from 'models/Composite/OfferTimeline';
import { FileContent } from 'models/Domain/FileContent';
import { Store } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { COLOR } from 'style/color';

type Props = {
  offerTimelines: OfferTimelines;
  currentUser: User;
  store: Store;
  onClickReadCount: (commentIndex: number) => void;
  openImageViewerModal: (imageIndex: number, timelineIndex: number) => void;
  openImageCommentViewerModal: (timeline: OfferTimeline) => void;
};

export const Timeline: React.FC<Props> = ({
  offerTimelines,
  currentUser,
  store,
  onClickReadCount,
  openImageViewerModal,
  openImageCommentViewerModal,
}) => {
  const refs = useRef<Array<HTMLDivElement | null>>([]);

  let unreadIndex = offerTimelines.findLatestUnreadTimelineIndex(currentUser);
  // 全て既読の場合は最後のindexにする
  if (unreadIndex < 0) {
    unreadIndex = offerTimelines.list.size - 1;
  }

  useEffect(() => {
    // タイムラインの要素の数の配列を作成する
    refs.current = refs.current.slice(0, offerTimelines.list.size);

    // 未読の要素にスクロールする
    const targetRef = refs.current[unreadIndex];
    if (targetRef) {
      window.scrollTo({ top: targetRef.offsetTop, behavior: 'smooth' });
    }
  }, [unreadIndex, offerTimelines.list.size]);

  if (offerTimelines.list.size === 0) {
    // コメントがなくても余白として描画
    return <Wrapper />;
  }

  return (
    <Wrapper>
      {offerTimelines.sortedList().map((timeline: OfferTimeline, index) => {
        const newTimeline = timeline.exceptCurrentUserRead(currentUser);
        const { type, isDone, user } = newTimeline;
        if (type === 'status_change' && isDone) {
          return <CompleteTimeline key={index} ref={(el) => (refs.current[index] = el)} store={store} />;
        }
        if (type === 'stamp') {
          return (
            <StampTimeline
              key={index}
              ref={(el) => (refs.current[index] = el)}
              index={index}
              timeline={newTimeline}
              isSelf={user.id === currentUser.id}
              onClickReadCount={(commentIndex) => onClickReadCount(commentIndex)}
            />
          );
        }
        if (type === 'comment') {
          return (
            <CommentTimeline
              key={index}
              ref={(el) => (refs.current[index] = el)}
              index={index}
              timeline={newTimeline}
              isSelf={user.id === currentUser.id}
              onClickReadCount={(commentIndex) => onClickReadCount(commentIndex)}
              openImageViewerModal={(imageIndex, timelineIndex) => openImageViewerModal(imageIndex, timelineIndex)}
            />
          );
        }
        if (type === 'image_comment') {
          return (
            <ImageCommentTimeline
              key={index}
              ref={(el) => (refs.current[index] = el)}
              index={index}
              timeline={newTimeline}
              isSelf={user.id === currentUser.id}
              onClickReadCount={(commentIndex) => onClickReadCount(commentIndex)}
              openImageCommentViewerModal={(targetTimeline) => openImageCommentViewerModal(targetTimeline)}
            />
          );
        }
        return null;
      })}
    </Wrapper>
  );
};

/**
 * コメントのUI
 */
const CommentTimeline = React.forwardRef<
  HTMLDivElement,
  {
    index: number;
    timeline: OfferTimeline;
    isSelf: boolean;
    onClickReadCount: (commentIndex: number) => void;
    openImageViewerModal: (imageIndex: number, timelineIndex: number) => void;
  }
>(({ index, timeline, isSelf, onClickReadCount, openImageViewerModal }, ref) => {
  const {
    comment: {
      content: { files, text },
    },
    user,
  } = timeline;

  const pdfFiles = files.list.filter((file) => file.isPDF);
  const imageFiles = files.list.filter((file) => !file.isPDF);
  // PDF -> 画像の順に並べ替えられたものを表示に使用
  const displayingFiles = pdfFiles.concat(imageFiles);

  const onClick = (fileIndex: number, file: FileContent) => {
    if (file.isPDF) {
      window.open(file.url);
      return;
    }
    const clickedImageIndex = fileIndex - pdfFiles.size;
    openImageViewerModal(clickedImageIndex, index);
  };

  return (
    <TimelineWrapper isSelf={isSelf} ref={ref}>
      {!isSelf && <TimelineUserLabel user={user} />}
      <MainContentWrapper>
        <MainContent isSelf={isSelf}>
          {displayingFiles.size > 0 && (
            <FileList>
              {displayingFiles.map((file, fileIndex) => (
                <CommentFile onClick={() => onClick(fileIndex, file)} key={fileIndex} src={file.url} />
              ))}
            </FileList>
          )}
          <TimelineText>
            <Linkify>{text}</Linkify>
          </TimelineText>
        </MainContent>
        <TimelineStatus isSelf={isSelf} timeline={timeline} onClickReadCount={() => onClickReadCount(index)} />
      </MainContentWrapper>
    </TimelineWrapper>
  );
});

const CompleteTimeline = React.forwardRef<HTMLDivElement, { store: Store }>(({ store }, ref) => {
  return (
    <CompleteWrapper ref={ref}>
      <CompleteContent>
        <CheckIcon name='check circle' size='huge' />
        <CompleteText>{`${store.shortName}のタスクを完了しました`}</CompleteText>
      </CompleteContent>
    </CompleteWrapper>
  );
});

/**
 * スタンプのUI
 */
const StampTimeline = React.forwardRef<
  HTMLDivElement,
  {
    index: number;
    timeline: OfferTimeline;
    isSelf: boolean;
    onClickReadCount: (commentIndex: number) => void;
  }
>(({ index, timeline, isSelf, onClickReadCount }, ref) => {
  const { stamp, user } = timeline;
  if (!stamp) return null;

  return (
    <TimelineWrapper isSelf={isSelf} ref={ref}>
      {!isSelf && <TimelineUserLabel user={user} />}
      <MainContentWrapper>
        <MainContent clearBackground isSelf={isSelf}>
          <StampImage src={stamp.url} />
        </MainContent>
        <TimelineStatus isSelf={isSelf} timeline={timeline} onClickReadCount={() => onClickReadCount(index)} />
      </MainContentWrapper>
    </TimelineWrapper>
  );
});

/**
 * 画像コメントのUI
 */
const ImageCommentTimeline = React.forwardRef<
  HTMLDivElement,
  {
    index: number;
    timeline: OfferTimeline;
    isSelf: boolean;
    onClickReadCount: (commentIndex: number) => void;
    openImageCommentViewerModal: (timeline: OfferTimeline) => void;
  }
>(({ index, timeline, isSelf, onClickReadCount, openImageCommentViewerModal }, ref) => {
  const { offer_image_comment, user } = timeline;

  return (
    <TimelineWrapper isSelf={isSelf} ref={ref}>
      {!isSelf && <TimelineUserLabel user={user} />}
      <MainContentWrapper>
        <MainContent isSelf={isSelf}>
          <FileList>
            <CommentFile onClick={() => openImageCommentViewerModal(timeline)} src={offer_image_comment.image_url} />
          </FileList>
          {offer_image_comment.sortedImageComments().map((image_comment) => {
            return (
              <ImageCommentWrapper key={image_comment.id}>
                <ImageCommentOrderIcon order_num={image_comment.order_num} />
                <ImageCommentText>{image_comment.text}</ImageCommentText>
              </ImageCommentWrapper>
            );
          })}
        </MainContent>
        <TimelineStatus isSelf={isSelf} timeline={timeline} onClickReadCount={() => onClickReadCount(index)} />
      </MainContentWrapper>
    </TimelineWrapper>
  );
});

const TimelineUserLabel: React.FC<{ user: User }> = ({ user: { profile_image_url, fullName, last_name } }) => {
  return (
    <TimelineUserLabelWrapper>
      <UserThumbnail src={profile_image_url} name={last_name} size={24} />
      <TimelineUserName>{fullName}</TimelineUserName>
    </TimelineUserLabelWrapper>
  );
};

const TimelineStatus: React.FC<{
  isSelf: boolean;
  timeline: OfferTimeline;
  onClickReadCount: () => void;
}> = ({ isSelf, timeline: { create_at, read_users }, onClickReadCount }) => {
  return (
    <TimelineStatusWrapper isSelf={isSelf}>
      <TimelineTimestamp>{create_at.fromNow()}</TimelineTimestamp>
      {!read_users.list.isEmpty() && (
        <TimelineReadCount onClick={() => onClickReadCount()}>既読 {read_users.list.size}</TimelineReadCount>
      )}
    </TimelineStatusWrapper>
  );
};

// やりとり全体の wrapper
const Wrapper = styled.div`
  margin: 24px auto 200px;
  max-width: 600px;
`;

// やりとり単体の投稿者、本文、投稿日時の wrapper
const TimelineWrapper = styled.div<{ isSelf: boolean }>`
  position: relative;
  transition: 0.25s;

  /* ブラウザの横幅が広い場合表示領域を広く取る */
  width: max-content;
  max-width: 450px;

  /* ユーザ自身の投稿の場合右寄せ、そうでない場合左寄せで表示 */
  margin: ${(props) => (props.isSelf ? '32px 0 32px auto' : '32px auto 32px 0')};

  /* ブラウザの横幅が通常以下の場合は、表示領域を絞る */
  @media (max-width: 1024px) {
    max-width: 256px;
  }
`;

// 本文と投稿日時の wrapper
const MainContentWrapper = styled.div`
  width: max-content;
  max-width: 100%;
`;

// 本文
const MainContent = styled.div<{ clearBackground?: boolean; isSelf?: boolean }>`
  background-color: ${(props) => (props.clearBackground ? 'transparent' : props.isSelf ? '#cff2e6' : `${COLOR.WHITE}`)};
  padding: 18px;
  border-radius: 8px;
  margin-top: 11px;
  width: max-content;
  max-width: 100%;
`;

const FileList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px;
`;

const CommentFile = styled(ResizeImage).attrs(() => ({ width: 200 }))`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
`;

const TimelineText = styled.p`
  text-align: left;
  margin-top: 12px;
  font-size: 14px;
`;
const TimelineTimestamp = styled.div`
  font-size: 12px;
  color: ${COLOR.DARK_GRAY};
`;

const TimelineReadCount = styled.div`
  font-size: 12px;
  margin-left: 16px;
  cursor: pointer;
  color: ${COLOR.DARK_GRAY};
`;

const TimelineStatusWrapper = styled.div<{ isSelf?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isSelf ? 'flex-start' : 'flex-end')};
`;

// 依頼／報告の完了
const CompleteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.WHITE};
  width: 256px;
  height: 240px;
  padding: 16px;
  border-radius: 8px;
  margin-left: auto;
`;

const CompleteContent = styled.div`
  text-align: center;
`;

const CompleteText = styled.div`
  font-weight: bold;
  margin-top: 24px;
`;

const CheckIcon = styled(Icon)`
  &&& {
    color: ${COLOR.GREEN};
  }
`;

const StampImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const ImageCommentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
`;

const ImageCommentText = styled.p`
  margin: 4px 0 0 8px;
  font-size: 14px;
`;

const TimelineUserLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TimelineUserName = styled.div`
  margin-left: 6px;
  font-weight: bold;
`;
