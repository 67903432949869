import React, { useCallback, useMemo, useState } from 'react';

import { List } from 'immutable';
import { Checkbox, Dropdown, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { CompetitorList, MAX_COMPETITOR_SIZE } from 'models/Domain/MapCompetitorResearch/Competitor';
import { MapSearchResultDetailItem, StoreKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';

import {
  ActionItemLabel,
  ActionMenuItem,
  AdTag,
  ColorCell,
  ColorHeaderCell,
  MenuCell,
  MenuHeaderCell,
  Notification,
  Rank,
  SettingsIcon,
  ShopIcon,
  StoreName,
} from './Common';

interface Props {
  className?: string;
  data: List<MapSearchResultDetailItem> | undefined;
  onSelectStore: (storeKey: StoreKey) => void;
  onChangeCompetitor: (storeKey: StoreKey) => void;
  competitors: CompetitorList;
  managedStoreKeys: List<StoreKey>;
}

export const AreaKeywordRankTable = React.memo<Props>(
  ({ className, data, onSelectStore, onChangeCompetitor, competitors, managedStoreKeys }) => {
    const [showOnlyCompetitor, setShowOnlyCompetitor] = useState(false);
    const [isExcludeAds, setIsExcludeAds] = useState(true);
    const filteredTableData = useMemo(() => {
      if (!data) {
        return List();
      }
      return data
        .filter((item) => (isExcludeAds ? !item.isAds : true))
        .filter((item) => (showOnlyCompetitor ? competitors.contains(item.getStoreKey()) : true));
    }, [competitors, data, isExcludeAds, showOnlyCompetitor]);
    return (
      <Wrapper className={className}>
        <Header>
          <CheckboxContainer>
            <StyledCheckbox
              onChange={() => setIsExcludeAds((value) => !value)}
              checked={isExcludeAds}
              label='広告を除外する'
            />
            <StyledCheckbox
              onChange={() => setShowOnlyCompetitor((value) => !value)}
              checked={showOnlyCompetitor}
              label='比較店舗のみ表示する'
            />
          </CheckboxContainer>
        </Header>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <ColorHeaderCell />
              <StyledTableHeaderCell>順位</StyledTableHeaderCell>
              <StyledTableHeaderCell weight={1}>店舗名</StyledTableHeaderCell>
              <MenuHeaderCell />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {filteredTableData.map((item, index) => {
              return (
                <AreaKeywordRankTableRow
                  key={index}
                  onSelectStore={onSelectStore}
                  onChangeCompetitor={onChangeCompetitor}
                  storeKey={item.getStoreKey()}
                  rank={isExcludeAds ? item.rankExcludeAds : item.rank}
                  isAds={item.isAds}
                  disableAddCompetitor={competitors.size >= MAX_COMPETITOR_SIZE}
                  isCompetitor={competitors.contains(item.getStoreKey())}
                  isManaged={managedStoreKeys.contains(item.getStoreKey())}
                  color={competitors.getColor(item.getStoreKey())}
                />
              );
            })}
          </TableBody>
        </Table>
        {data == null && <Notification>データを取得中</Notification>}
        {showOnlyCompetitor && competitors.size === 0 && <Notification>比較店舗が設定されていません</Notification>}
      </Wrapper>
    );
  },
);

export const AreaKeywordRankTableRow = React.memo<{
  storeKey: StoreKey;
  onChangeCompetitor: (storeKey: StoreKey) => void;
  onSelectStore: (storeKey: StoreKey) => void;
  rank: number | null;
  isAds: boolean;
  isCompetitor: boolean;
  isManaged: boolean;
  disableAddCompetitor: boolean;
  color: string | null;
}>(
  ({
    storeKey,
    onChangeCompetitor,
    onSelectStore,
    rank,
    isAds,
    isCompetitor,
    isManaged,
    disableAddCompetitor,
    color,
  }) => {
    const handleOnClick = useCallback(() => {
      onSelectStore(storeKey);
    }, [onSelectStore, storeKey]);

    const handleOnChangeCompetitor = useCallback(() => {
      onChangeCompetitor(storeKey);
    }, [onChangeCompetitor, storeKey]);

    return (
      <StyledTableRow>
        <ColorCell color={isCompetitor ? color : 'transparent'} />
        <RankCell>
          <RankCellContainer>
            {isAds && <AdTag />}
            <Rank>{rank}</Rank>
          </RankCellContainer>
        </RankCell>
        <StyledTableCell>
          <StoreName onClick={handleOnClick}>{storeKey.storeName}</StoreName>
          {isManaged && <ShopIcon />}
        </StyledTableCell>
        <MenuCell>
          <Dropdown trigger={<SettingsIcon />} icon={null}>
            <Dropdown.Menu direction={'left'}>
              {!isCompetitor && disableAddCompetitor ? (
                <ActionMenuItem>
                  <Popup
                    content={<PopupContent>比較店舗に設定できるのは{MAX_COMPETITOR_SIZE}店舗までです</PopupContent>}
                    trigger={<ActionItemLabel disabled>比較店舗に追加</ActionItemLabel>}
                  />
                </ActionMenuItem>
              ) : (
                <ActionMenuItem onClick={handleOnChangeCompetitor}>
                  <ActionItemLabel>{isCompetitor ? '比較店舗から除外' : '比較店舗に追加'}</ActionItemLabel>
                </ActionMenuItem>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </MenuCell>
      </StyledTableRow>
    );
  },
);

const Wrapper = styled.div``;

const StyledTableRow = styled(TableRow)``;

const StyledTableHeaderCell = styled(TableHeaderCell)``;

const StyledTableCell = styled(TableCell)``;

const RankCell = styled(TableCell)`
  width: 100px;
`;

const RankCellContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 14px;
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;
