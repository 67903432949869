import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export const MAX_LENGTH = 750;

export interface GmbLatLngUpdateParams {
  latlng: {
    latitude: number;
    longitude: number;
  };
}

/**
 * Gmbから受け取る緯度経度情報を管理
 */
export class GmbLatlng extends Record<{
  latitude: number;
  longitude: number;
}>({
  latitude: 0,
  longitude: 0,
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    return new GmbLatlng(params);
  }

  updateParams(): GmbLatLngUpdateParams {
    return {
      latlng: {
        latitude: this.latitude,
        longitude: this.longitude,
      },
    };
  }

  isValid() {
    // 緯度経度が(0, 0)の地点は扱わないはずなので、無効扱い
    return !!this.latitude && !!this.longitude;
  }
}
