import actionCreatorFactory from 'typescript-fsa';

import StoreList from 'models/Domain/StoreList';
import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('StoreList');

export const StoreListActions = {
  getStoreList: actionCreator<JSObject[]>('getStoreList'),
  setStoreList: actionCreator<JSObject[]>('setStoreList'),
  setStoreListForCreate: actionCreator<StoreList>('setStoreListForCreate'),
  setStoreListForEdit: actionCreator<StoreList>('setStoreListForEdit'),
  clearStoreListForCreate: actionCreator('clearStoreListForCreate'),
  createStoreList: actionCreator('createStoreList'),
  updateStoreList: actionCreator('updateStoreList'),
  deleteStoreList: actionCreator<number>('deleteStoreList'),
};
