import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';

export interface ChangePasswordParam {
  oldPassword: string;
  newPassword: string;
}

export const PasswordChangeModal = React.memo<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (param: ChangePasswordParam) => void;
}>(({ isOpen, onClose, onSubmit }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [isError, setError] = useState(false);
  const successChangePassword = useSelector((state: State) => state.user.successChangePassword);

  const clearInput = () => {
    setOldPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
  };

  useEffect(() => {
    // パスワード変更成功したら閉じる
    if (successChangePassword) {
      clearInput();
      onClose();
    }
    // 入力できる文字列は半角英字大文字小文字数字記号、必須条件は大文字小文字数字それぞれ1つ以上かつ8文字以上256文字以下
    const PASSWORD_POLICY_REGEX = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[!-~]{8,256}$/;
    if (newPassword && !PASSWORD_POLICY_REGEX.test(newPassword)) {
      setLocalErrorMessage('新しいパスワードがパスワードの条件を満たしていません。');
      setError(true);
    } else if (!oldPassword || !newPassword || !newPasswordConfirm) {
      setLocalErrorMessage('');
      setError(true);
    } else if (newPassword !== newPasswordConfirm) {
      setLocalErrorMessage('新しいパスワードと確認用パスワードが一致しません。');
      setError(true);
    } else {
      setLocalErrorMessage('');
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successChangePassword, oldPassword, newPassword, newPasswordConfirm]);

  return (
    <Modal size='tiny' open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>パスワードを変更</Modal.Header>
      <Modal.Content>
        {localErrorMessage && <Error>{localErrorMessage}</Error>}
        <Label>現在のパスワード</Label>
        <InputWrapper>
          <InputPassword
            value={oldPassword}
            onChange={(value) => {
              setOldPassword(value);
            }}
            type='password'
          />
        </InputWrapper>
        <Label>新しいパスワード</Label>
        <SubLabel>アルファベットの大文字・小文字・数字の全てを含む8文字以上</SubLabel>
        <InputWrapper>
          <InputPassword
            value={newPassword}
            onChange={(value) => {
              setNewPassword(value);
            }}
            placeholder=''
            type='password'
          />
        </InputWrapper>
        <Label>新しいパスワード（確認）</Label>
        <InputWrapper>
          <InputPassword
            value={newPasswordConfirm}
            onChange={(value) => {
              setNewPasswordConfirm(value);
            }}
            placeholder=''
            type='password'
          />
        </InputWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Buttons>
          <ButtonWrapper>
            <CloseButton
              onClick={() => {
                clearInput();
                onClose();
              }}
            >
              キャンセル
            </CloseButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <ApplyButton
              priority='high'
              onClick={() =>
                onSubmit({
                  oldPassword: oldPassword,
                  newPassword: newPassword,
                })
              }
              disabled={isError}
            >
              変更
            </ApplyButton>
          </ButtonWrapper>
        </Buttons>
      </Modal.Actions>
    </Modal>
  );
});

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;

  @media (max-width: 600px) {
    margin: 0 0 16px;
  }
`;

const ButtonWrapper = styled.div`
  width: calc(50% - 8px);
`;

const ButtonCommonStyle = css`
  width: 100%;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const InputPassword = styled(Input)`
  &&& {
    width: 100%;
    height: 32px;
    & > .ui.input {
      height: 100%;
      & > input {
        height: 100%;
        padding: 0 4px;
      }
    }
  }
`;

const Label = styled.label`
  font-weight: bold;
  color: ${COLOR.BLACK};
  display: block;
  margin-top: 8px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const SubLabel = styled(Label)`
  font-weight: normal;
  font-size: 12px;
  color: ${COLOR.DARK_GRAY};
`;

const InputWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Error = styled.div`
  color: ${COLOR.ERROR};
  text-align: center;
`;
