import React, { useCallback, useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { Paging } from 'components/molecules/Paging';
import { SwitchContent } from 'components/molecules/SwitchContent';
import { OfferGroupsList } from 'components/pageComponents/OfferGroups/OfferGroupList';
import { OfferGroupsFilter } from 'components/pageComponents/OfferGroups/OfferGroupsFilter';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { FilterStatus, SortStatus } from 'models/Domain/OfferGroup/OfferGroupSearchCondition';
import { OfferGroupsActions } from 'modules/offerGroups/actions';
import { Path } from 'routes';

type Props = RouteComponentProps;

export const OfferGroups: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { setSearchCondition, initializeOfferGroupsPage } = useMemo(
    () => bindActionCreators(OfferGroupsActions, dispatch),
    [dispatch],
  );

  const stores = useSelector((state) => state.store.stores);
  const currentUser = useSelector((state) => state.app.currentUser);
  const userList = useSelector((state) => state.user.userList);
  const searchCondition = useSelector((state) => state.offerGroups.offerGroupsSearchCondition);
  const committedSearchCondition = useSelector((state) => state.offerGroups.committedOfferGroupsSearchCondition);
  const offerGroups = useSelector((state) => state.offerGroups.offerGroups);
  const isLoading = useSelector((state) => state.offerGroups.isLoading);

  // 初期化処理の実行
  useEffect(() => {
    initializeOfferGroupsPage();
  }, [initializeOfferGroupsPage]);

  // 検索を実行
  const commitFilter = useCallback(() => {
    // URLを変更するため
    setSearchCondition({ searchCondition, updateLocation: true });
  }, [searchCondition, setSearchCondition]);

  /**
   * 絞り込み条件を変更する。OfferGroupsの取得/URL変更は行わない
   */
  const changeFilter = useCallback(
    (filter: FilterStatus) => {
      let updatedCondition = searchCondition.set('filter', filter);
      // 絞り込み条件変更時はページを1に戻す
      updatedCondition = updatedCondition.setPage(1);
      // 検索条件のセットのみ実施する（依頼報告の取得はcommitFilterで実施）
      setSearchCondition({ searchCondition: updatedCondition, updateLocation: false });
    },
    [searchCondition, setSearchCondition],
  );

  /**
   * ソート条件を変更する。OfferGroupsの取得/URL変更は行わない
   */
  const changeSort = useCallback(
    (sort: SortStatus) => {
      let updatedCondition = searchCondition.set('sort', sort);
      // ソート条件変更時はページを1に戻す
      updatedCondition = updatedCondition.setPage(1);
      // 検索条件のセットのみ実施する（依頼報告の取得はcommitFilterで実施）
      setSearchCondition({ searchCondition: updatedCondition, updateLocation: false });
    },
    [searchCondition, setSearchCondition],
  );

  /**
   * ページ条件を変更する
   */
  const changePage = useCallback(
    (page: number) => {
      // ページ条件の変更は確定済み検索条件から変更する
      const updatedCondition = committedSearchCondition.setPage(page);
      // 検索条件の変更と依頼報告の取得
      setSearchCondition({ searchCondition: updatedCondition, updateLocation: true });
      // 表示位置をページの一番上に
      window.scrollTo({ top: 0 });
    },
    [committedSearchCondition, setSearchCondition],
  );

  if (!offerGroups) {
    return null;
  }

  return (
    <Wrapper>
      <Helmet title={getPageTitle('依頼 / 報告')} />
      <StickyHeader>
        <Title>依頼 / 報告</Title>
        <Link to={Path.offer.create}>
          <OfferCreateButton priority='high'>
            <SendIcon type='send_white' />
          </OfferCreateButton>
        </Link>
      </StickyHeader>
      <WideBody>
        <SwitchContent openLabel='絞り込み条件を閉じる' closedLabel='絞り込み条件を開く'>
          <OfferGroupsFilter
            filter={searchCondition.filter}
            sort={searchCondition.sort}
            currentUser={currentUser}
            userList={userList}
            onChangeFilter={changeFilter}
            onChangeSort={changeSort}
            onCommitFilter={commitFilter}
            disableCommitButton={isLoading}
          />
        </SwitchContent>
        <Content>
          <OfferGroupsListWrapper>
            <OfferGroupsList offerGroups={offerGroups} stores={stores} userList={userList} />
          </OfferGroupsListWrapper>
          {!offerGroups.items.isEmpty() && (
            <PagingWrapper>
              <Paging
                currentPage={offerGroups.pagination.current_page}
                viewContentSize={20}
                totalContentSize={offerGroups.pagination.total_count}
                viewMaxPage={5}
                fixedPage={1}
                onChangeNo={changePage}
              />
            </PagingWrapper>
          )}
          {isLoading && (
            <>
              <LoadingWrapperBase />
              <LoadingWrapper>
                <Loader active={true} size='big' inline={true} />
              </LoadingWrapper>
            </>
          )}
        </Content>
      </WideBody>
    </Wrapper>
  );
};

const Wrapper = styled(MainWrapper)`
  padding-bottom: 80px;
`;

const PagingWrapper = styled.div`
  margin-top: 30px;
`;

const OfferGroupsListWrapper = styled.div`
  margin-top: 24px;
`;

const Content = styled.div`
  position: relative;
`;

const OfferCreateButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 13px;
    min-width: 160px;
    margin-left: 10px;

    ::after {
      content: '依頼/報告を作成';
    }
  }

  @media (max-width: 800px) {
    &&& {
      width: auto;
      min-width: 80px;

      ::after {
        content: '作成';
      }
    }
  }
`;

const SendIcon = styled(Icon)`
  &&& {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 8px;
    padding: 0 0 2px 0;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: #f8f8f8;
  mix-blend-mode: hard-light;
`;
