import React, { useMemo } from 'react';

import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { PullDown } from 'components/atoms/PullDown';
import { getCategoryOptions } from 'models/Domain/Image/Image';

type Props = {
  isOpen: boolean;
  setPublishToGmb: (v: boolean) => void;
  category: string;
  setCategory: (category: string) => void;
  disabled: boolean;
  shouldDisplayWarning: boolean;
  canUseProductCategory: boolean;
};

export const PublishToGmb = React.memo<Props>(
  ({ isOpen, setPublishToGmb, category, setCategory, disabled, shouldDisplayWarning, canUseProductCategory }) => {
    const categoryOptions = useMemo(
      () => getCategoryOptions({ canUseProduct: canUseProductCategory }),
      [canUseProductCategory],
    );

    return (
      <Wrapper>
        <CheckLabel
          onChange={() => setPublishToGmb(!isOpen)}
          checked={isOpen}
          label='Googleビジネスプロフィールに掲載する'
          disabled={disabled}
        />
        {isOpen && (
          <>
            {shouldDisplayWarning && <Warning>Googleビジネスプロフィール未連携の店舗には掲載されません</Warning>}
            <Label>カテゴリー</Label>
            <CustomPullDown
              value={category}
              options={categoryOptions}
              onChange={(v) => setCategory(v)}
              placeholder={'カテゴリー'}
            />
          </>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-top: 24px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const Warning = styled.div`
  font-size: 13px;
  color: #f09061;
  margin-bottom: 4px;
`;

const CustomPullDown = styled(PullDown)`
  margin-top: 16px;
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    display: block;
    font-size: 14px;
    color: #707070;
    margin: 8px 8px 8px 0;
  }
`;
