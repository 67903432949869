import dayjs, { Dayjs } from 'dayjs';
import { Record as ImmutableRecord, Set as ImmutableSet, is } from 'immutable';

import { GbpPerformanceMAMonthlyStartParams, GbpPerformanceMAStartParams } from 'ApiClient/GbpPerformanceMAApi';
import {
  booleanToSearchParam,
  dateToSearchParam,
  numberComparator,
  parseBooleanParam,
  parseDateParameter,
} from 'helpers/search';
import { AggregateUnit } from 'types/Common';

import { YearMonth } from '../YearMonth';

import { DISPLAY_TYPES, DisplayType } from './GbpPerformanceMA';

export type AggregateMethod = 'total' | 'average';

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType, 'isAllOrganizationIds' | 'isEnabledComparison'>]: string;
} = {
  organizationIds: 'og',
  aggregateUnit: 'au',
  startDate: 'sd',
  endDate: 'ed',
  comparisonStartDate: 'csd',
  comparisonEndDate: 'ced',
  targetMonth: 'tm',
  aggregateMethod: 'am',
  excludeIncompleteStores: 'eis',
  displayType: 'dt',
};

// FIXME
// デフォルト値をモジュールレベルで定義してしまうと、テスト時に値(maxDateのdayjs())が固定できないので
// 関数の形にして利用するときに生成するようにしている
export const defaultDates = () => {
  const MAX_DATE = dayjs().startOf('day').subtract(1, 'day');
  const DEFAULT_START_DATE = MAX_DATE.subtract(DEFAULT_DAY_OPTION - 1, 'day');
  const DEFAULT_END_DATE = MAX_DATE;
  // 比較期間は「前の期間」DEFAULT_START_DATEの28日前からDEFAULT_START_DATEの前日まで
  const DEFAULT_COMPARISON_START_DATE = DEFAULT_START_DATE.subtract(DEFAULT_DAY_OPTION, 'day');
  const DEFAULT_COMPARISON_END_DATE = DEFAULT_START_DATE.subtract(1, 'day');
  return {
    MAX_DATE,
    DEFAULT_START_DATE,
    DEFAULT_END_DATE,
    DEFAULT_COMPARISON_START_DATE,
    DEFAULT_COMPARISON_END_DATE,
  };
};
const DEFAULT_ORGANIZATION_IDS = ImmutableSet<number>();
const DEFAULT_IS_ALL_ORGANIZATION_IDS = true;
// 初期値は集計単位「日」の「過去28日間」
const DEFAULT_AGGREGATE_UNIT: AggregateUnit = 'day';
const DEFAULT_DAY_OPTION = 28;
// 選択可能な期間は前日まで
export const DEFAULT_AGGREGATE_METHOD: AggregateMethod = 'total';
export const DEFAULT_EXCLUDE_INCOMPLETE_STORES = false;
export const DEFAULT_IS_ENABLED_COMPARISON = false;
export const DEFAULT_DISPLAY_TYPE: DisplayType = 'overview';

type FilterStatusType = {
  organizationIds: ImmutableSet<number>;
  isAllOrganizationIds: boolean;
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs;
  comparisonEndDate: Dayjs;
  aggregateMethod: AggregateMethod;
  targetMonth: YearMonth;
  excludeIncompleteStores: boolean;
  isEnabledComparison: boolean;
  displayType: DisplayType;
};

export class FilterStatus extends ImmutableRecord<FilterStatusType>({
  organizationIds: DEFAULT_ORGANIZATION_IDS,
  isAllOrganizationIds: DEFAULT_IS_ALL_ORGANIZATION_IDS,
  aggregateUnit: DEFAULT_AGGREGATE_UNIT,
  startDate: defaultDates().DEFAULT_START_DATE,
  endDate: defaultDates().DEFAULT_END_DATE,
  comparisonStartDate: defaultDates().DEFAULT_COMPARISON_START_DATE,
  comparisonEndDate: defaultDates().DEFAULT_COMPARISON_END_DATE,
  excludeIncompleteStores: DEFAULT_EXCLUDE_INCOMPLETE_STORES,
  targetMonth: YearMonth.fromDate(defaultDates().DEFAULT_END_DATE),
  aggregateMethod: DEFAULT_AGGREGATE_METHOD,
  isEnabledComparison: DEFAULT_IS_ENABLED_COMPARISON,
  displayType: DEFAULT_DISPLAY_TYPE,
}) {
  setOrganizationIds(organizationIds: ImmutableSet<number>, isAllOrganizationIds: boolean) {
    return this.merge({ organizationIds, isAllOrganizationIds });
  }

  /**
   * startDateとendDateから、デフォルトのtargetDateを取得する
   */
  getDefaultTargetMonth() {
    const { startDate, endDate } = this;
    if (startDate.year() === endDate.year() && startDate.month() === endDate.month()) {
      // 対象期間の開始日・終了日が同月の場合は、その月を設定する
      return YearMonth.fromDate(endDate);
    } else {
      if (endDate.date() === endDate.endOf('month').date()) {
        // 対象期間の最終日が月末の場合、その月を設定する
        return YearMonth.fromDate(endDate);
      } else {
        // それ以外の場合、前月を設定する
        return YearMonth.fromDate(endDate.subtract(1, 'month'));
      }
    }
  }
}

export class GbpPerformanceMASearchCondition extends ImmutableRecord<{
  filter: FilterStatus;
}>({
  filter: new FilterStatus(),
}) {
  /**
   * ページのURLパラメータから検索条件を生成する
   */
  static fromURLSearchParams(search: string): GbpPerformanceMASearchCondition {
    const condition = new GbpPerformanceMASearchCondition();
    let { filter } = condition;
    const params = new URLSearchParams(search);

    const { MAX_DATE } = defaultDates();

    // 組織ID
    const organizationIds = params.get(URLSearchParamsMapping.organizationIds) || 'all';
    if (organizationIds === 'all') {
      filter = filter.setOrganizationIds(ImmutableSet<number>(), true);
    } else {
      const values = organizationIds
        .split(',')
        .filter((v) => v.match(/^\d+$/))
        .map((v) => Number.parseInt(v, 10));
      filter = filter.setOrganizationIds(ImmutableSet(values), false);
    }

    // 集計期間
    const aggregateUnit = params.get(URLSearchParamsMapping.aggregateUnit);
    if (aggregateUnit && ['day', 'week', 'month'].includes(aggregateUnit)) {
      filter = filter.set('aggregateUnit', aggregateUnit as AggregateUnit);
    }
    const startDateStr = parseDateParameter(params.get(URLSearchParamsMapping.startDate));
    const endDateStr = parseDateParameter(params.get(URLSearchParamsMapping.endDate));
    if (startDateStr && endDateStr) {
      const startDate = dayjs(startDateStr);
      const endDate = dayjs(endDateStr);

      if (
        startDate.isValid() &&
        endDate.isValid() &&
        startDate.isSameOrBefore(endDate) &&
        endDate.isSameOrBefore(MAX_DATE)
      ) {
        filter = filter.merge({ startDate, endDate });
      }
    }

    // 比較期間
    const comparisonStartDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonStartDate));
    const comparisonEndDateStr = parseDateParameter(params.get(URLSearchParamsMapping.comparisonEndDate));
    if (comparisonStartDateStr && comparisonEndDateStr) {
      const comparisonStartDate = dayjs(comparisonStartDateStr);
      const comparisonEndDate = dayjs(comparisonEndDateStr);
      if (
        comparisonStartDate.isValid() &&
        comparisonEndDate.isValid() &&
        comparisonStartDate.isSameOrBefore(comparisonEndDate) &&
        comparisonEndDate.isSameOrBefore(MAX_DATE)
      ) {
        filter = filter.merge({ comparisonStartDate, comparisonEndDate, isEnabledComparison: true });
      }
    }

    // 表示月
    const targetMonthStr = params.get(URLSearchParamsMapping.targetMonth);
    const targetMonth =
      targetMonthStr && YearMonth.isMatchFormat(targetMonthStr) ? YearMonth.fromString(targetMonthStr) : null;
    if (targetMonth) {
      const targetDate = targetMonth.toDayjs();
      if (
        targetDate.isValid() &&
        filter.startDate.isSameOrBefore(targetDate) &&
        targetDate.isSameOrBefore(filter.endDate)
      ) {
        filter = filter.set('targetMonth', targetMonth);
      } else {
        // targetMonthがstartDate〜endDateの間でなければ、デフォルト値
        filter = filter.set('targetMonth', filter.getDefaultTargetMonth());
      }
    } else {
      // 未指定または不正値の場合はデフォルト値
      filter = filter.set('targetMonth', filter.getDefaultTargetMonth());
    }

    // 集計方法
    const aggregateMethod = params.get(URLSearchParamsMapping.aggregateMethod);
    if (aggregateMethod && ['total', 'average'].includes(aggregateMethod)) {
      filter = filter.set('aggregateMethod', aggregateMethod as AggregateMethod);
    }

    // 欠損データの除外
    const excludeIncompleteStores = params.get(URLSearchParamsMapping.excludeIncompleteStores);
    filter = filter.set('excludeIncompleteStores', parseBooleanParam(excludeIncompleteStores));

    const displayType = params.get(URLSearchParamsMapping.displayType);
    if (displayType && DISPLAY_TYPES.includes(displayType as DisplayType)) {
      filter = filter.set('displayType', displayType as DisplayType);
    }

    return condition.merge({ filter });
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    if (this.filter.displayType !== DEFAULT_DISPLAY_TYPE) {
      params.append(URLSearchParamsMapping.displayType, this.filter.displayType);
    }

    if (!this.filter.isAllOrganizationIds && this.filter.organizationIds.size > 0) {
      params.append(
        URLSearchParamsMapping.organizationIds,
        this.filter.organizationIds.sort(numberComparator).join(','),
      );
    }

    params.append(URLSearchParamsMapping.aggregateUnit, this.filter.aggregateUnit);
    params.append(URLSearchParamsMapping.startDate, dateToSearchParam(this.filter.startDate));
    params.append(URLSearchParamsMapping.endDate, dateToSearchParam(this.filter.endDate));

    if (this.filter.isEnabledComparison) {
      params.append(URLSearchParamsMapping.comparisonStartDate, dateToSearchParam(this.filter.comparisonStartDate));
      params.append(URLSearchParamsMapping.comparisonEndDate, dateToSearchParam(this.filter.comparisonEndDate));
    }

    // 月ごとの比較で、デフォルトのtargetMonthと異なる場合は表示する
    if (
      !this.filter.isEnabledComparison &&
      this.filter.targetMonth &&
      !this.filter.targetMonth.isSame(this.filter.getDefaultTargetMonth())
    ) {
      params.append(URLSearchParamsMapping.targetMonth, this.filter.targetMonth.toString());
    }

    params.append(URLSearchParamsMapping.aggregateMethod, this.filter.aggregateMethod);
    if (this.filter.excludeIncompleteStores) {
      params.append(
        URLSearchParamsMapping.excludeIncompleteStores,
        booleanToSearchParam(this.filter.excludeIncompleteStores),
      );
    }

    // 見栄え悪いので、'%2C'は','に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * データ取得用のAPIパラメータを取得する
   */
  toRequestParams(forComparison = false): GbpPerformanceMAStartParams {
    let startDate: Dayjs, endDate: Dayjs;
    if (forComparison) {
      startDate = this.filter.comparisonStartDate;
      endDate = this.filter.comparisonEndDate;
    } else {
      startDate = this.filter.startDate;
      endDate = this.filter.endDate;
    }

    return {
      aggregate_unit: this.filter.aggregateUnit,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      organization_ids: this.filter.organizationIds.sort().toArray(),
      aggregate_method: this.filter.aggregateMethod,
      exclude_incomplete_stores: this.filter.excludeIncompleteStores,
    };
  }

  toMonthlyDataRequestParams(): GbpPerformanceMAMonthlyStartParams {
    return {
      month: this.filter.targetMonth.toString(),
      start_date: this.filter.startDate.format('YYYY-MM-DD'),
      end_date: this.filter.endDate.format('YYYY-MM-DD'),
      organization_ids: this.filter.organizationIds.sort().toArray(),
      aggregate_method: this.filter.aggregateMethod,
      exclude_incomplete_stores: this.filter.excludeIncompleteStores,
    };
  }

  isValid() {
    return this.isValidOrganizationIds() && this.isValidDateRange() && this.isValidComparisonDateRange();
  }

  isValidOrganizationIds() {
    return this.filter.organizationIds.size > 0;
  }

  isValidDateRange() {
    const startDate = this.filter.startDate;
    const endDate = this.filter.endDate;
    if (startDate && endDate) {
      return startDate.isSameOrBefore(endDate);
    }
    return !startDate && !endDate;
  }

  isValidComparisonDateRange() {
    if (!this.filter.isEnabledComparison) {
      return true;
    }
    const startDate = this.filter.comparisonStartDate;
    const endDate = this.filter.comparisonEndDate;
    if (startDate && endDate) {
      return startDate.isSameOrBefore(endDate);
    }
    return !startDate && !endDate;
  }

  // 指定された検索条件とtargetMonthが異なるか
  hasDiffTargetMonth(other: this) {
    return (
      !this.filter.targetMonth.isSame(other.filter.targetMonth) &&
      is(this, other.setIn(['filter', 'targetMonth'], this.filter.targetMonth))
    );
  }

  // 指定された検索条件とdisplayTypeが異なるか
  hasDiffDisplayType(other: this) {
    return (
      this.filter.displayType !== other.filter.displayType &&
      is(this, other.setIn(['filter', 'displayType'], this.filter.displayType))
    );
  }
}
