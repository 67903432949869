import React from 'react';

import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

import CONFIG from 'config';

type Props = {
  className?: string;
  latitude: number;
  longitude: number;
  zoom: number;
};

export const GoogleMap: React.FC<Props> = ({ className, latitude, longitude, zoom }) => {
  const center = {
    lat: latitude,
    lng: longitude,
    zoom,
  };

  const handleApiLoaded = ({ map, maps }: any) => {
    new maps.Marker({
      map,
      position: center,
    });
  };

  return (
    <Wrapper className={className}>
      {latitude && longitude && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: CONFIG.GOOGLE_API_KEY }}
          center={center}
          defaultZoom={zoom}
          onGoogleApiLoaded={handleApiLoaded}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  height: 400px;
`;
