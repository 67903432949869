import { Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { trimForGmbText } from 'helpers/utils';
import { JSObject } from 'types/Common';

export const MAX_LENGTH = 750;

export class GmbProfile extends Record<{
  description: string;
}>({
  description: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }
  changeDescription(value: string) {
    return this.set('description', value);
  }

  validate() {
    const errors = {
      description: this.validateDescription(),
    };
    return errors;
  }

  validateDescription() {
    let error;
    // ビジネス情報が規定文字数を超過していないか
    if (this.description.length > MAX_LENGTH) {
      error = { isValid: false, error: ErrorType.PROFILE_DESCRIPTION_LENGTH_ERROR };
    }
    if (this.description.toLowerCase().includes('http')) {
      error = { isValid: false, error: ErrorType.PROFILE_DESCRIPTION_CONTAINS_URL_ERROR };
    }
    if (this.description.includes('<') || this.description.includes('>')) {
      error = { isValid: false, error: ErrorType.PROFILE_DESCRIPTION_CONTAINS_BRACKET_ERROR };
    }
    return error || { isValid: true, error: '' };
  }

  updateParams() {
    // Googleビジネスプロフィールの画面で編集する時と同じように整形する
    return {
      profile: {
        description: trimForGmbText(this.description),
      },
    };
  }
}
