import actionCreatorFactory from 'typescript-fsa';

import { CompositeOmoInsightSummary } from 'models/Domain/OmoInsight/InsightSummary';
import { CompositeOMOInsightGraphData } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { OmoInsightSearchCondition } from 'models/Domain/OmoInsight/OmoInsightSearchCondition';
import { ProductStatus } from 'models/Domain/OmoInsight/ProductStatusSummary';

const actionCreator = actionCreatorFactory('OmoInsight');

export const OmoInsightActions = {
  initializePage: actionCreator('initializePage'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),
  commitSearchCondition: actionCreator<OmoInsightSearchCondition>('commitSearchCondition'),
  setSearchCondition: actionCreator<OmoInsightSearchCondition>('setSearchCondition'),
  updatePrevSearchCondition: actionCreator('updatePrevSearchCondition'),
  setIsLoadingData: actionCreator<boolean>('setIsLoadingData'),
  setProductStatus: actionCreator<ProductStatus>('setProductStatus'),
  setInsight: actionCreator<CompositeOmoInsightSummary>('setInsight'),
  setGraphData: actionCreator<CompositeOMOInsightGraphData>('setGraphData'),
  commitSelectedMerchantId: actionCreator<string>('commitSelectedMerchantId'),
  setLoadingStatus: actionCreator<{ target: 'productStatus' | 'insight' | 'graph'; value: boolean }>(
    'setLoadingStatus',
  ),
  setIsFirstSearch: actionCreator<boolean>('setIsFirstSearch'),
};
