import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { GMCProductCounts } from '../Omo/common';

export class Products extends Record<{
  shopping_ads: GMCProductCounts;
  local_inventory_ads: GMCProductCounts;
  free_listings: GMCProductCounts;
  free_local_listings: GMCProductCounts;
}>({
  shopping_ads: new GMCProductCounts(),
  local_inventory_ads: new GMCProductCounts(),
  free_listings: new GMCProductCounts(),
  free_local_listings: new GMCProductCounts(),
}) {
  static fromJson(data: any = {}) {
    return new Products({
      shopping_ads: GMCProductCounts.fromJson(data.shopping_ads),
      local_inventory_ads: GMCProductCounts.fromJson(data.local_inventory_ads),
      free_listings: GMCProductCounts.fromJson(data.free_listings),
      free_local_listings: GMCProductCounts.fromJson(data.free_local_listings),
    });
  }
}
export class ProductStatusSummary extends Record<{
  organizationId: number;
  merchantId: string;
  date: Dayjs;
  products: Products;
  createAt: Dayjs;
  updateAt: Dayjs;
}>({
  organizationId: 0,
  merchantId: '',
  date: dayjs(),
  products: new Products(),
  createAt: dayjs(),
  updateAt: dayjs(),
}) {
  static fromJson(data: any = {}) {
    return new ProductStatusSummary({
      organizationId: data.organization_id,
      merchantId: data.merchant_id,
      date: dayjs(data.date),
      products: Products.fromJson(data || {}),
      // タイムゾーン情報がないので、UTC時刻として読み込んでローカルタイムゾーンモードに変更
      createAt: dayjs.utc(data.create_at).local(),
      updateAt: dayjs.utc(data.update_at).local(),
    });
  }
}

export class ProductStatusItem extends Record<{
  name: string;
  status: ProductStatusSummary;
}>({
  name: '',
  status: new ProductStatusSummary(),
}) {
  static fromJson(data: any = {}) {
    return new ProductStatusItem({
      name: data.name,
      status: ProductStatusSummary.fromJson(data.status),
    });
  }
}

export class ProductStatus extends Record<{
  date: Dayjs;
  items: List<ProductStatusItem>;
}>({
  date: dayjs(),
  items: List(),
}) {
  static fromJson(data: any = {}) {
    return new ProductStatus({
      date: dayjs(data.date),
      items: List(data.items.map((item: any) => ProductStatusItem.fromJson(item))),
    });
  }
}
