import { useCallback } from 'react';

import { Memo, MemoList, MemoTagList } from 'models/Domain/Memo/Memo';

import { MEMO_DISPLAY_SETTING_DEFAULT, MemoDisplaySettings, MemoDisplaySettingsObject } from '../helpers/graph';
import { MemoSearchCondition } from '../models/Domain/Memo/MemoSearchCondition';

import {
  createMemo as _createMemo,
  deleteMemo as _deleteMemo,
  updateMemo as _updateMemo,
  useAllMemos,
} from './useAllMemos';
import { useMemoTags } from './useMemoTags';
import { useStorage } from './useStorage';

// LocalStorageに保存するキーに指定するページの種別
type PageType = 'GBP_PERFORMANCE' | 'MAP_SEARCH_RANK' | 'SEARCH_KEYWORDS' | 'OMO_INSIGHT';

// メモの表示設定を取得/保存する
export const useMemoDisplaySettings = (page: PageType) => {
  // ページごとに設定内容を保持できるように、keyにページ情報を加える
  const [settingsObject, setSettingsObject] = useStorage<MemoDisplaySettingsObject>(
    `ANALYSIS_MEMO_DISPLAY_SETTING_${page}`,
    MEMO_DISPLAY_SETTING_DEFAULT,
  );

  // MemoDisplaySettingsからMemoDisplaySettingsObjectへの変換
  const setSettings = useCallback(
    (settings: MemoDisplaySettings) => {
      setSettingsObject(settings.toSaveParams);
    },
    [setSettingsObject],
  );

  return [MemoDisplaySettings.fromObject(settingsObject), setSettings] as const;
};

const memoSearchCondition = new MemoSearchCondition();

export const useAnalysisMemos = () => {
  // 組織に紐づく全ての施策メモを取得する
  const {
    data: memoList = new MemoList(),
    mutate: mutateMemoList,
    isValidating: isLoadingMemoList,
  } = useAllMemos(memoSearchCondition);

  // 組織に紐づく施策メモのタグを取得する
  const {
    data: memoTagList = new MemoTagList(),
    mutate: mutateTags,
    isValidating: isLoadingMemoTagList,
  } = useMemoTags();

  // メモを作成
  const createMemo = useCallback(
    async (memo: Memo) => {
      await _createMemo(memo);
      mutateMemoList();
      mutateTags();
    },
    [mutateMemoList, mutateTags],
  );

  // メモを更新
  const updateMemo = useCallback(
    async (memo: Memo) => {
      await _updateMemo(memo);
      mutateMemoList();
      mutateTags();
    },
    [mutateMemoList, mutateTags],
  );

  // メモを削除
  const deleteMemo = useCallback(
    async (memo: Memo) => {
      await _deleteMemo(memo);
      mutateMemoList();
      mutateTags();
    },
    [mutateMemoList, mutateTags],
  );

  return {
    memoList,
    memoTagList,
    createMemo,
    updateMemo,
    deleteMemo,
    isLoadingMemoList,
    isLoadingMemoTagList,
  };
};
