import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { ReviewReplyGenerateResponse, ReviewReplyGenerateResponseItem } from 'ApiClient/ReviewApi';

export class GeneratedReviewReply extends ImmutableRecord<{
  body: string;
}>({
  body: '',
}) {
  static fromJSON(data: ReviewReplyGenerateResponseItem) {
    return new GeneratedReviewReply({
      body: data.body,
    });
  }
}

export class GeneratedReviewReplyList extends ImmutableRecord<{
  items: ImmutableList<GeneratedReviewReply>;
}>({
  items: ImmutableList(),
}) {
  static fromJSON(data: ReviewReplyGenerateResponse) {
    return new GeneratedReviewReplyList({
      items: data.items && ImmutableList(data.items.map((item) => GeneratedReviewReply.fromJSON(item))),
    });
  }
}
