import React from 'react';

import { JS } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import Logo from 'components/atoms/Logo';
import { getPageTitle } from 'helpers/utils';
import { COLOR } from 'style/color';

import { AuthPieceProps } from './AuthPiece';

type State = {
  username: string;
  password: string;
};
export default class CustomSignIn extends React.Component<AuthPieceProps, State> {
  readonly state: State = {
    username: '',
    password: '',
  };

  triggerAuthEvent = (event: any) => {
    const state = this.props.authState;
    if (this.props.onAuthEvent) {
      this.props.onAuthEvent(state, event);
    }
  };

  changeState = (state: string, data?: any) => {
    if (this.props.onStateChange) {
      this.props.onStateChange(state, data);
    }

    this.triggerAuthEvent({
      type: 'stateChange',
      data: state,
    });
  };

  error = (err: any) => {
    this.triggerAuthEvent({
      type: 'error',
      data: this.errorMessage(err),
    });
  };

  errorMessage = (err: any) => {
    if (typeof err === 'string') {
      return err;
    }
    return err.message ? err.message : JSON.stringify(err);
  };

  checkContact = (user: any) => {
    Auth.verifiedContact(user).then((data) => {
      if (!JS.isEmpty(data.verified)) {
        this.changeState('signedIn', user);
      } else {
        user = Object.assign(user, data);
        this.changeState('verifyContact', user);
      }
    });
  };

  signIn = async () => {
    this.changeState('loading');

    const { username, password } = this.state;

    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.changeState('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        this.changeState('TOTPSetup', user);
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
        user.challengeParam &&
        user.challengeParam.trigger === 'true'
      ) {
        this.changeState('customConfirmSignIn', user);
      } else {
        this.checkContact(user);
      }
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        this.changeState('confirmSignUp', { username });
      } else if (err.code === 'PasswordResetRequiredException') {
        this.changeState('forgotPassword', { username });
      } else {
        this.changeState('signIn');
        this.error(err);
      }
    }
  };

  render() {
    const { authState } = this.props;
    if (authState !== 'signIn') {
      return null;
    }
    const { username, password } = this.state;
    return (
      <Wrapper>
        <Helmet title={getPageTitle('ログイン')} />
        <LogoWrapper>
          <Logo width='260px' />
        </LogoWrapper>
        <FormWrapper>
          <FormItemWrapper>
            <FormInput
              type='email'
              icon='mail'
              iconPosition='left'
              value={username}
              placeholder='メールアドレス'
              onChange={(username: string) => this.setState({ username })}
            />
          </FormItemWrapper>

          <FormItemWrapper>
            <FormInput
              type='password'
              icon='lock'
              iconPosition='left'
              value={password}
              placeholder='パスワード'
              onChange={(password: string) => this.setState({ password })}
            />
          </FormItemWrapper>

          <FormItemWrapper>
            <Button priority='high' onClick={this.signIn}>
              ログイン
            </Button>
          </FormItemWrapper>
        </FormWrapper>
        <ForgotPassword>
          パスワードをお忘れの方は
          <ForgotPasswordLink onClick={() => this.changeState('forgotPassword')}>こちら</ForgotPasswordLink>
        </ForgotPassword>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

const LogoWrapper = styled.div`
  margin-bottom: 54px;
  text-align: center;
`;

const FormWrapper = styled.form`
  background-color: ${COLOR.WHITE};
  width: 340px;
  padding: 18px;
`;

const FormItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
  width: 100%;
  &:first-of-type {
    margin-top: 0;
  }
`;

const ForgotPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

const ForgotPasswordLink = styled.span`
  margin-left: 4px;
  font-size: 14px;
  cursor: pointer;
  color: ${COLOR.GREEN};
  text-decoration: underline;
`;

const FormInput = styled(Input)`
  &&& {
    width: 280px;
  }
`;
