import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord, OrderedSet as ImmutableSet } from 'immutable';

import { Store } from 'models/Domain/Store';
import { Task } from 'models/Domain/Task';
import { JSObject } from 'types/Common';

const VALID_IMAGE_FILE_TYPES = ['image/jpeg', 'image/png'];

export type SortType = 'create_at' | 'gmb_count' | 'date_range';

export type ImageStore = { store_id: number; store_name: string; is_connected_gmb: boolean };

const gbpImageCategories = [
  'CATEGORY_UNSPECIFIED',
  'COVER',
  'PROFILE',
  'LOGO',
  'EXTERIOR',
  'INTERIOR',
  'PRODUCT',
  'AT_WORK',
  'FOOD_AND_DRINK',
  'MENU',
  'COMMON_AREA',
  'ROOMS',
  'TEAMS',
  'ADDITIONAL',
] as const;

export type GbpImageCategory = (typeof gbpImageCategories)[number];

// GBPカテゴリの日本語表記（設定画面での表記）
export const gbpImageCategoryLabel = {
  CATEGORY_UNSPECIFIED: 'なし',
  COVER: 'カバー',
  PROFILE: 'ロゴ',
  LOGO: 'プロフィール', // なぜかロゴにPROFILEが使われているので、こちらをプロフィールとしておく
  EXTERIOR: '外観',
  INTERIOR: '店内',
  PRODUCT: '商品',
  AT_WORK: '職場',
  FOOD_AND_DRINK: '食品と飲料', // マップでは「料理、飲み物」で表示？
  MENU: 'メニュー',
  COMMON_AREA: '共用エリア', // 日本語表記未確認
  ROOMS: '室内', // 日本語表記未確認
  TEAMS: 'チーム',
  ADDITIONAL: 'その他',
} satisfies Record<GbpImageCategory, string> as Record<string, string>;

export interface ImageRecord {
  url: string;
  tags: ImmutableList<string>;
  image_resource_id: number;
  stores_count: number;
  published_gmb_stores_count: number;
  gmb_category: GbpImageCategory;
  stores: ImmutableList<ImageStore>;
  task: Task;
  create_at: Dayjs;
  gmb_create_at: Dayjs | null;
  imageFile: ImageFileMeta | null; // 画像アップロードから登録される画像の検証用の情報。APIリクエストには使わない
  gbp_categories: ImmutableList<GbpImageCategory>; // 写真一覧取得用で使用する読み取り専用の情報。複数取得する必要があるため定義。
}

export const getSelectableImageCategories = ({
  canUseProfile = true,
  canUseProduct = true,
  currentCategory,
}: {
  canUseProfile: boolean;
  canUseProduct: boolean;
  currentCategory?: GbpImageCategory | null;
}) => {
  // デフォルトの選択可能なカテゴリ
  const DEFAULT_SELECTABLE_IMAGE_CATEGORIES: GbpImageCategory[] = ['INTERIOR', 'EXTERIOR'];
  // デフォルトの選択不可なカテゴリ
  const DEFAULT_UNSELECTABLE_IMAGE_CATEGORIES: GbpImageCategory[] = ['COVER'];
  // その他の設定可能なカテゴリ（末尾に追加するためにDEFAULTと分けてる）
  const ADDITIONAL_SELECTABLE_IMAGE_CATEGORIES: GbpImageCategory[] = ['ADDITIONAL', 'CATEGORY_UNSPECIFIED'];

  // デフォルトの選択可能な項目を追加する
  let result = ImmutableSet(DEFAULT_SELECTABLE_IMAGE_CATEGORIES);
  // 現在設定されているカテゴリはカバー以外は設定可能なはずなので追加する
  if (
    currentCategory &&
    !DEFAULT_UNSELECTABLE_IMAGE_CATEGORIES.includes(currentCategory) &&
    !ADDITIONAL_SELECTABLE_IMAGE_CATEGORIES.includes(currentCategory)
  ) {
    result = result.add(currentCategory);
  }
  // 「商品」を選択可能なら追加する
  if (canUseProduct) {
    result = result.add('PRODUCT');
  }
  // 「ロゴ」を選択可能なら追加する
  if (canUseProfile) {
    result = result.add('PROFILE');
  }
  // 「その他」「なし」を最後に追加する
  result = result.concat(ADDITIONAL_SELECTABLE_IMAGE_CATEGORIES);

  // 配列に変換して返す
  return result.toArray();
};

export const getCategoryOptions = ({
  canUseProfile = true,
  canUseProduct = true,
  currentCategory,
}: {
  canUseProfile?: boolean;
  canUseProduct?: boolean;
  currentCategory?: GbpImageCategory | null;
}) => {
  // その店舗の設定可能なカテゴリからオプションを生成
  const selectableImageCategories = getSelectableImageCategories({ canUseProfile, canUseProduct, currentCategory });
  const result = selectableImageCategories.map((category) => ({
    text: gbpImageCategoryLabel[category] || category,
    value: category,
  }));
  // 現在設定されているカテゴリは、選択不可の場合もオプションは追加する
  // 変更されなければ更新されないので、disabledは指定しない
  if (currentCategory && !selectableImageCategories.includes(currentCategory)) {
    result.unshift({ text: gbpImageCategoryLabel[currentCategory] || currentCategory, value: currentCategory });
  }
  return result;
};

export const mapCategoriesToLabels = ({ categories }: { categories?: ImmutableList<GbpImageCategory> | null }) => {
  if (!categories) {
    return [];
  }

  const uniqueCategoriesArray = ImmutableSet(categories).toArray();
  return uniqueCategoriesArray.map((category: string) => ({
    text: gbpImageCategoryLabel[category] || category,
    value: category,
  }));
};

export default class Image extends ImmutableRecord<ImageRecord>({
  url: '',
  tags: ImmutableList(),
  image_resource_id: 0,
  stores_count: 0,
  published_gmb_stores_count: 0,
  gmb_category: 'CATEGORY_UNSPECIFIED',
  stores: ImmutableList(),
  task: new Task(),
  create_at: dayjs(),
  gmb_create_at: null,
  imageFile: null,
  gbp_categories: ImmutableList(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };

    params.stores = data.stores_data
      ? ImmutableList(data.stores_data.map((d: JSObject) => new Store(d)))
      : ImmutableList();
    params.task = new Task(params.task);
    params.tags = ImmutableList(params.tags);
    params.stores = params.stores ? ImmutableList(data.stores) : ImmutableList();
    params.gbp_categories = ImmutableList(params.gbp_categories) as ImmutableList<GbpImageCategory>;
    if (params.create_at) params.create_at = dayjs.unix(params.create_at);
    if (params.gmb_create_at) params.gmb_create_at = dayjs(params.gmb_create_at);
    super(params);
  }

  get publishedGmbStoresCountLabel() {
    return this.published_gmb_stores_count > 0 ? this.published_gmb_stores_count : '';
  }

  get formatCreateAt() {
    return this.gmb_create_at != null && this.gmb_create_at.isBefore(this.create_at)
      ? this.gmb_create_at.format('YYYY年MM月DD日')
      : this.create_at.format('YYYY年MM月DD日');
  }

  get categoryLabel() {
    // 未知のカテゴリの場合はそのまま表示する
    return gbpImageCategoryLabel[this.gmb_category] ?? this.gmb_category;
  }

  get storeNamesLabel() {
    if (this.stores.isEmpty()) return '';
    return this.stores.map((store) => store.store_name).join('、');
  }

  /**
   * GBPに掲載可能な画像かどうか
   */
  get isValidToGBP() {
    return this.validateToGBP.length === 0;
  }

  /**
   * 写真のGBP掲載フォーマット検証結果を返す
   * https://support.google.com/business/answer/6103862?hl=ja
   */
  get validateToGBP() {
    const errors: string[] = [];
    if (this.imageFile === null) {
      return errors;
    }
    if (!VALID_IMAGE_FILE_TYPES.includes(this.imageFile.type)) {
      errors.push('ファイルの形式はJPEGまたはPNGのみです');
    }
    if (this.imageFile.size < 10 * 1024) {
      errors.push('ファイルのサイズは10KB以上である必要があります');
    }
    if (this.imageFile.width < 250) {
      errors.push('ファイルの幅は250px以上である必要があります');
    }
    if (this.imageFile.width > 10000) {
      errors.push('ファイルの幅が10,000pxを超えています');
    }
    if (this.imageFile.height < 250) {
      errors.push('ファイルの高さは250px以上である必要があります');
    }
    if (this.imageFile.height > 10000) {
      errors.push('ファイルの高さが10,000pxを超えています');
    }
    return errors;
  }

  /**
   * 画像検証用のメタデータを更新する
   */
  changeImageFileMeta(imageFile: ImageFileMeta) {
    return this.set('imageFile', imageFile);
  }
}

interface ImageListRecord {
  list: ImmutableList<Image>;
}

export class ImageList extends ImmutableRecord<ImageListRecord>({
  list: ImmutableList(),
}) {
  static fromJSON(images: JSObject[]) {
    return new ImageList({ list: ImmutableList(images.map((image: JSObject) => new Image(image))) });
  }

  get isEmpty() {
    return this.list.isEmpty();
  }

  /**
   * すべての写真がGBP掲載可能かどうか
   */
  get isValidToGBP() {
    return this.list.every((image) => image.isValidToGBP);
  }

  /**
   * GBP掲載のバリデーション結果を返す
   */
  get validateToGBP() {
    // 写真のインデックスごとに、エラーの配列を入れる
    const errors: string[][] = [];
    this.list.forEach((image) => {
      errors.push(image.validateToGBP);
    });
    return errors;
  }

  /** Googleビジネスプロフィールに投稿済の画像のみを返す */
  get selectGmbPublished() {
    return this.set(
      'list',
      this.list.filter((image) => image.published_gmb_stores_count),
    );
  }

  static fromUrls(urls: string[]) {
    return ImageList.fromJSON(urls.map((url) => ({ url })));
  }

  /**
   * 画像ファイルの検証用情報を更新する
   * @param imageFile 画像のメタデータの配列。同じインデックスの画像のメタデータを置き換える
   */
  changeImageFiles(imageFile: ImageFileMeta[]) {
    let list = this.list;
    for (let i = 0; i < imageFile.length; i++) {
      const image = this.list.get(i);
      if (!image) {
        continue;
      }
      list = list.set(i, image.changeImageFileMeta(imageFile[i]));
    }
    return this.set('list', list);
  }

  /**
   * 該当のインデックスの写真を削除する
   * @param index 写真のインデックス
   */
  removeIndex(index: number) {
    return this.set('list', this.list.delete(index));
  }
}
