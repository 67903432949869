import dayjs, { Dayjs } from 'dayjs';
import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import { GetMemoParams } from 'ApiClient/MemoApi';
import { parseDateParameter, parseNumberParameter } from 'helpers/search';

type FilterStatusType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  query: ImmutableSet<string>;
  tags: ImmutableSet<string>;
};

export class FilterStatus extends ImmutableRecord<FilterStatusType>({
  startDate: null,
  endDate: null,
  query: ImmutableSet(),
  tags: ImmutableSet(),
}) {}

export type SortKey = 'date';
export type SortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY: SortKey = 'date';
const DEFAULT_SORT_ORDER: SortOrder = 'desc';

type SortStatusType = {
  key: SortKey;
  order: SortOrder;
};

export class SortStatus extends ImmutableRecord<SortStatusType>({
  key: DEFAULT_SORT_KEY,
  order: DEFAULT_SORT_ORDER,
}) {}

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 100;

type PaginationType = {
  page: number;
  limit: number;
};

export class Pagination extends ImmutableRecord<PaginationType>({
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
}) {}

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in keyof FilterStatusType | `sort_${keyof SortStatusType}` | keyof PaginationType]: string;
} = {
  startDate: 'sd',
  endDate: 'ed',
  query: 'q',
  tags: 'tg',
  sort_key: 'sk',
  sort_order: 'so',
  page: 'pg',
  limit: 'li',
};

export class MemoSearchCondition extends ImmutableRecord<{
  filter: FilterStatus;
  sort: SortStatus;
  pagination: Pagination;
}>({
  filter: new FilterStatus(),
  sort: new SortStatus(),
  pagination: new Pagination(),
}) {
  /**
   * 条件からページのURLパラメータを生成する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    if (this.filter.startDate) {
      params.append(URLSearchParamsMapping.startDate, this.filter.startDate.format('YYYY-MM-DD'));
    }

    if (this.filter.endDate) {
      params.append(URLSearchParamsMapping.endDate, this.filter.endDate.format('YYYY-MM-DD'));
    }

    if (this.filter.query.size > 0) {
      params.append(URLSearchParamsMapping.query, this.filter.query.join(','));
    }

    if (this.filter.tags.size > 0) {
      params.append(URLSearchParamsMapping.tags, this.filter.tags.join(','));
    }

    if (!(this.sort.key === DEFAULT_SORT_KEY && this.sort.order === DEFAULT_SORT_ORDER)) {
      params.append(URLSearchParamsMapping.sort_key, this.sort.key);
      params.append(URLSearchParamsMapping.sort_order, this.sort.order);
    }

    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }

    if (this.pagination.limit !== DEFAULT_LIMIT) {
      params.append(URLSearchParamsMapping.limit, String(this.pagination.limit));
    }

    // 見栄え悪いので '%2C' は ',' に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * ページのURLパラメータから検索条件を生成する
   * @param search
   */
  static fromURLSearchParams(search: string): MemoSearchCondition {
    const condition = new MemoSearchCondition();
    const params = new URLSearchParams(search);
    let { filter, sort, pagination } = condition;

    const startDateStr = parseDateParameter(params.get(URLSearchParamsMapping.startDate));
    if (startDateStr) {
      const startDate = dayjs(startDateStr);
      filter = filter.set('startDate', startDate);
    }

    const endDateStr = parseDateParameter(params.get(URLSearchParamsMapping.endDate));
    if (endDateStr) {
      const endDate = dayjs(endDateStr);
      filter = filter.set('endDate', endDate);
    }

    const query = params.get(URLSearchParamsMapping.query);
    if (query) {
      filter = filter.set('query', ImmutableSet(query.split(',')));
    }

    const tags = params.get(URLSearchParamsMapping.tags);
    if (tags) {
      filter = filter.set('tags', ImmutableSet(tags.split(',')));
    }

    const sortKey = params.get(URLSearchParamsMapping.sort_key);
    if (sortKey && ['date'].includes(sortKey)) {
      sort = sort.set('key', sortKey as SortKey);
    }

    const sortOrder = params.get(URLSearchParamsMapping.sort_order);
    if (sortOrder && ['asc', 'desc'].includes(sortOrder)) {
      sort = sort.set('order', sortOrder as SortOrder);
    }

    pagination = pagination.set('page', parseNumberParameter(params.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1));
    pagination = pagination.set(
      'limit',
      parseNumberParameter(params.get(URLSearchParamsMapping.limit), DEFAULT_LIMIT, 1, 100),
    );

    return condition.merge({ filter, sort, pagination });
  }

  toRequestParams(): GetMemoParams {
    return {
      start_date: this.filter.startDate?.format('YYYY-MM-DD') || undefined,
      end_date: this.filter.endDate?.format('YYYY-MM-DD') || undefined,
      query: this.filter.query.join(',') || undefined,
      tags: this.filter.tags.join(',') || undefined,
      sort_key: this.sort.key,
      sort_order: this.sort.order,
      page: this.pagination.page,
      limit: this.pagination.limit,
    };
  }
}
