import { Set as ImmutableSet, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { TranslationLanguage } from 'models/Domain/Organization';
import Promotion, { Media } from 'models/Domain/Promotion/Promotion';
import { PromotionGroup } from 'models/Domain/Promotion/PromotionGroup';
import PromotionList from 'models/Domain/Promotion/PromotionList';
import { PromotionSearchCondition } from 'models/Domain/Promotion/PromotionSearchCondition';

import { PromotionActions } from './actions';

export interface PromotionRecord {
  promotion: Promotion;
  promotionGroup: PromotionGroup;
  promotionGroupForEdit: PromotionGroup;

  promotionList: PromotionList;
  promotionSearchCondition: PromotionSearchCondition;
  committedPromotionSearchCondition: PromotionSearchCondition;
  loadingLanguages: ImmutableSet<TranslationLanguage>;

  // 作成・編集画面でアップロードしたメディア情報
  mediaForEdit: Media;

  // 投稿一覧ページの初期処理に必要な情報が準備できているか
  // （currentUserなど必要な情報が揃ってから初期処理が実行される）
  isPreparedForPromotionListPage: boolean;
  // 投稿実績のCSVダウンロード処理の進捗に関するデータ
  csvDownload: ExecutionArnStatus;
  isOpenDownloadModal: boolean;
  isLoadingPromotionList: boolean;
  isLoadingPromotion: boolean;
}

export class PromotionState extends Record<PromotionRecord>({
  promotion: new Promotion(),
  promotionGroup: new PromotionGroup(),
  promotionGroupForEdit: new PromotionGroup(),

  promotionList: new PromotionList(),
  promotionSearchCondition: new PromotionSearchCondition(),
  committedPromotionSearchCondition: new PromotionSearchCondition(),
  mediaForEdit: new Media(),
  isPreparedForPromotionListPage: false,
  csvDownload: new ExecutionArnStatus(),
  isOpenDownloadModal: false,
  isLoadingPromotionList: false,
  isLoadingPromotion: false,
  loadingLanguages: ImmutableSet(),
}) {}

export const promotionReducer = reducerWithInitialState(new PromotionState())
  .case(PromotionActions.setPromotion, (state, data) => {
    return state.set('promotion', data);
  })
  .case(PromotionActions.setPromotionGroup, (state, data) => {
    return state.set('promotionGroup', data);
  })
  .case(PromotionActions.setPromotionGroupForEdit, (state, data) => {
    return state.set('promotionGroupForEdit', data);
  })
  .case(PromotionActions.setPromotionForEdit, (state, data) => {
    return state.update('promotionGroupForEdit', (promotionGroup) => promotionGroup.setPromotion(data));
  })
  .case(PromotionActions.setPromotionList, (state, data) => {
    return state.set('promotionList', data);
  })
  .case(PromotionActions.setMediaForEdit, (state, data) => {
    return state.set('mediaForEdit', data);
  })
  .case(PromotionActions.setSearchCondition, (state, data) => {
    return state.set('promotionSearchCondition', data.searchCondition);
  })
  .case(PromotionActions.setCommittedSearchCondition, (state, payload) => {
    return state.set('committedPromotionSearchCondition', payload);
  })
  .case(PromotionActions.deleteMediaForEdit, (state, payload) => {
    return state.update('mediaForEdit', (mediaForEdit) => mediaForEdit.deleteItem(payload));
  })
  .case(PromotionActions.clearMediaForEdit, (state) => {
    return state.set('mediaForEdit', new Media());
  })
  .case(PromotionActions.setIsPreparedForPromotionListPage, (state, payload) => {
    return state.set('isPreparedForPromotionListPage', payload);
  })
  .case(PromotionActions.setCsvDownload, (state, payload) => {
    return state.set('csvDownload', payload);
  })
  // 投稿実績データのCSVダウンロードの進捗情報を削除する
  .case(PromotionActions.clearCsvDownload, (state) => {
    return state.set('csvDownload', new ExecutionArnStatus());
  })
  .case(PromotionActions.setIsOpenDownloadModal, (state, payload) => {
    return state.set('isOpenDownloadModal', payload);
  })
  .case(PromotionActions.setIsLoadingPromotionList, (state, payload) => {
    return state.set('isLoadingPromotionList', payload);
  })
  .case(PromotionActions.setIsLoadingPromotion, (state, payload) => {
    return state.set('isLoadingPromotion', payload);
  })
  .case(PromotionActions.setLoadingLanguages, (state, payload) => {
    const { language, isLoading } = payload;
    return state.set(
      'loadingLanguages',
      isLoading ? state.loadingLanguages.add(language) : state.loadingLanguages.delete(language),
    );
  });
