import React from 'react';

import { is } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StoreListEditForm } from 'components/organisms/StoreListEditForm';
import StoreList from 'models/Domain/StoreList';
import { StoreState } from 'modules/store/reducers';
import { StoreListState } from 'modules/storeList/reducers';

type Props = {
  targetStoreList: StoreList;
  setStoreListForEdit: (v: StoreList) => void;
  updateStoreList: () => void;
  setIsOpenModal: (v: boolean) => void;
} & Pick<StoreListState, 'storeListForEdit'> &
  Pick<StoreState, 'stores'>;

export const EditTabContent = React.memo<Props>(
  ({ storeListForEdit, targetStoreList, stores, setStoreListForEdit, updateStoreList, setIsOpenModal }) => {
    return (
      <Wrapper>
        <StoreListEditForm storeList={storeListForEdit} stores={stores} setStoreList={setStoreListForEdit} />

        <ButtonWrapper>
          <Button
            priority='high'
            onClick={() => updateStoreList()}
            disabled={is(storeListForEdit, targetStoreList) || !storeListForEdit.isValid}
          >
            更新
          </Button>
        </ButtonWrapper>

        <ButtonWrapper>
          <Button negative={true} onClick={() => setIsOpenModal(true)}>
            削除
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;
