import { useEffect } from 'react';

export const useScrollLock = (enabled = true) => {
  useEffect(() => {
    if (window) {
      document.body.style.overflow = enabled ? 'hidden' : '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [enabled]);
};
