import ApiClient from 'ApiClient';
import { JSObject } from 'types/Common';

const apiClient = new ApiClient({});

const STORE_LIST_PATH = '/store_lists';
const STORE_LIST_DETAIL_PATH = (storeListId: number) => `${STORE_LIST_PATH}/${String(storeListId)}`;

// FIXME: レスポンスの中身
export type GetStoreListApiResponse = JSObject[];

// FIXME: レスポンスの中身
export type PostStoreListApiResponse = JSObject[];

export class StoreListApi {
  static get() {
    return apiClient.get<GetStoreListApiResponse>(STORE_LIST_PATH);
  }

  static post(params: JSObject) {
    return apiClient.post<PostStoreListApiResponse>(STORE_LIST_PATH, params);
  }
}

// FIXME: レスポンスの中身
export type PutStoreListDetailApiResponse = JSObject[];

// FIXME: レスポンスの中身
export type DeleteStoreListDetailApiResponse = JSObject[];

export class StoreListDetailApi {
  static put(params: JSObject) {
    return apiClient.put<PutStoreListDetailApiResponse>(STORE_LIST_DETAIL_PATH(params.id), params);
  }

  static delete(storeListId: number) {
    return apiClient.delete<DeleteStoreListDetailApiResponse>(STORE_LIST_DETAIL_PATH(storeListId));
  }
}
