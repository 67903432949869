import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Set as ImmutableSet } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { SmallCheckBox } from 'components/atoms/CheckBox';
import {
  AggregateDateRangePicker,
  DateRangeOptionValue,
  getDateRangeFromDateRangeOption,
  getDateRangeOption,
} from 'components/molecules/AggregateDateRangePicker';
import { AggregateUnitGroup } from 'components/molecules/AggregateUnitGroup';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { dateRangeOptions } from 'components/pageComponents/GbpPerformance/GbpPerformanceSearchCondition';
import {
  COLUMNS,
  COLUMNS_MAP,
  ColumnGroup,
  Column as ColumnType,
  GbpPerformanceCsvDownloadCondition,
} from 'models/Domain/GbpPerformance/GbpPerformanceCsvDownloadCondition';
import {
  GbpPerformanceSearchCondition as SearchCondition,
  defaultDates,
} from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';
import { COLOR } from 'style/color';
import { AggregateUnit } from 'types/Common';

const { MAX_DATE } = defaultDates();

/** GBPパフォーマンスCSVダウンロード設定モーダルのパラメータ */
export type Props = {
  isOpen: boolean;
  onClose: () => void;
  condition: GbpPerformanceCsvDownloadCondition;
  setCondition: (condition: GbpPerformanceCsvDownloadCondition) => void;
  onStartCsvDownload: () => void;
};

/**
 * GBPパフォーマンスCSVダウンロード設定モーダル
 * @param isOpen モーダルの開閉状態
 * @param onClose モーダルを閉じる
 * @param condition ダウンロード条件
 * @param setCondition ダウンロード条件が変更された
 * @param onStartCsvDownload ダウンロードを開始
 * @returns
 */
export const GbpPerformanceCsvDownloadModal: React.FC<Props> = ({
  isOpen,
  onClose,
  condition,
  setCondition,
  onStartCsvDownload,
}) => {
  const { group, storeIds, showClosedStores, aggregateUnit, startDate, endDate } = condition.searchCondition.filter;

  const [dateRangeOption, setDateRangeOption] = useState<DateRangeOptionValue>('カスタム');

  // モーダルを開いた時の条件を元に集計期間のラベルを更新
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const dateRangeOption = getDateRangeOption(dateRangeOptions[aggregateUnit], startDate, endDate, MAX_DATE);
    setDateRangeOption(dateRangeOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const aggregateDurationOptions = useMemo(() => dateRangeOptions[aggregateUnit], [aggregateUnit]);

  /**
   * 集計対象の店舗が変更された
   * @param group 選択されているグループ
   * @param storeIds 店舗ID
   * @param isAllStoreIds storeIdsがgroupで指定されているグループの全店舗か
   * @param showClosedStores 閉店店舗を表示するか
   * */
  const handleOnChangeStores = useCallback(
    (
      group: SearchCondition['filter']['group'],
      storeIds: SearchCondition['filter']['storeIds'],
      isAllStoreIds: SearchCondition['filter']['isAllStoreIds'],
      showClosedStores: SearchCondition['filter']['showClosedStores'],
    ) => {
      const newSearchCondition = condition.mergeIn(['searchCondition', 'filter'], {
        group,
        storeIds,
        isAllStoreIds,
        showClosedStores,
      });
      setCondition(newSearchCondition);
    },
    [condition, setCondition],
  );

  /**
   * 集計単位が変更された
   * @param value 集計単位
   */
  const handleOnChangeAggregateUnit = useCallback(
    (value: AggregateUnit) => {
      // 集計期間が変更されたら、期間はそのまま「カスタム」に変更する
      if (value !== aggregateUnit) {
        setDateRangeOption('カスタム');
        const newSearchCondition = condition.setIn(['searchCondition', 'filter', 'aggregateUnit'], value);
        setCondition(newSearchCondition);
      }
    },
    [aggregateUnit, setCondition, condition],
  );

  /**
   * 集計期間が変更された
   * @param startDate 集計期間 (開始日)
   * @param endDate 集計期間 (終了日)
   */
  const handleOnChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        const sd = dayjs(startDate);
        const ed = dayjs(endDate);

        const dateRangeOption = getDateRangeOption(dateRangeOptions[aggregateUnit], sd, ed, MAX_DATE);
        setDateRangeOption(dateRangeOption);

        const newSearchCondition = condition.mergeIn(['searchCondition', 'filter'], {
          startDate: sd,
          endDate: ed,
        });
        setCondition(newSearchCondition);
      }
    },
    [aggregateUnit, condition, setCondition],
  );

  /**
   * 集計期間のプルダウンの選択肢が変更された
   * @param value プルダウンの選択肢
   */
  const handleOnChangeDateRangeOption = useCallback(
    (value: DateRangeOptionValue) => {
      setDateRangeOption(value);
      // 集計期間のオプションから開始日〜終了日を取得
      const [sd, ed] = getDateRangeFromDateRangeOption(value, startDate, endDate, MAX_DATE);
      // 設定を反映
      const newSearchCondition = condition.mergeIn(['searchCondition', 'filter'], {
        startDate: sd,
        endDate: ed,
      });

      setCondition(newSearchCondition);
    },
    [endDate, condition, startDate, setCondition],
  );

  /**
   * 取得対象を変更した (グループのトグル)
   * @param ColumnGroup トグル対象の項目グループ
   */
  const handleOnToggleColumnGroup = useCallback(
    (columnGroup: ColumnGroup) => {
      let newSearchCondition;
      if (condition.isSelectedAllColumns(columnGroup)) {
        // すべてチェックされている場合は、すべてチェックをはずす
        newSearchCondition = condition.update('columns', (columns) => columns.subtract(COLUMNS_MAP[columnGroup]));
      } else {
        // いずれか、または何もチェックされていない場合は、すべてチェックする
        newSearchCondition = condition.update('columns', (columns) => columns.concat(COLUMNS_MAP[columnGroup]));
      }

      setCondition(newSearchCondition);
    },
    [condition, setCondition],
  );

  /**
   * 取得対象を変更した
   * @param ColumnType 取得対象の項目
   */
  const handleOnChangeColumn = useCallback(
    (value: ColumnType) => {
      // 選択された項目を取得対象のsetから追加or削除
      let newColumns: ImmutableSet<ColumnType>;
      if (condition.columns.has(value)) {
        newColumns = condition.columns.delete(value);
      } else {
        newColumns = condition.columns.add(value);
      }
      // 変更を反映
      const newCondition = condition.set('columns', newColumns);
      setCondition(newCondition);
    },
    [condition, setCondition],
  );

  /**
   * すべての取得対象項目を選択または解除するボタンが押された
   */
  const handleOnToggleAllColumn = useCallback(() => {
    // すべての項目を選択中の場合全解除、そうでなければ全選択
    if (condition.columns.size === COLUMNS.length) {
      setCondition(condition.set('columns', ImmutableSet()));
    } else {
      setCondition(condition.set('columns', ImmutableSet(COLUMNS)));
    }
  }, [condition, setCondition]);

  /** すべての取得項目を選択または解除するボタンのラベル */
  const toggleAllButtonLabel = useMemo(() => {
    const isSelectingAllColumns = condition.columns.size === COLUMNS.length;
    return isSelectingAllColumns ? 'すべて解除する' : 'すべて選択する';
  }, [condition]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Content>
        <Wrapper>
          <Title>CSVダウンロード</Title>
          <Divider />
          <FlexWrapper>
            <GroupStoreSelect
              group={group}
              storeIds={storeIds}
              showClosedStores={showClosedStores}
              showGbpConnectedStoresOnly={true}
              showMyStoreOnly={true}
              onChange={handleOnChangeStores}
            />
          </FlexWrapper>
          <FlexWrapper>
            <FlexContent>
              <Label>集計単位</Label>
              <AggregateUnitGroup value={aggregateUnit} onChange={handleOnChangeAggregateUnit} />
            </FlexContent>
            <FlexContent>
              <FlexLabel>
                集計期間
                <ContextHelp content='直近のパフォーマンスは、通常2〜3日後に反映されます。' />
              </FlexLabel>
              <PeriodContainer>
                <AggregateDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={MAX_DATE}
                  hideComparison={true}
                  isEnabledComparison={false}
                  dateRangeOption={dateRangeOption}
                  dateRangeOptions={aggregateDurationOptions}
                  onChangeDateRange={handleOnChangeDateRange}
                  onChangeDateRangeOption={handleOnChangeDateRangeOption}
                />
              </PeriodContainer>
            </FlexContent>
          </FlexWrapper>
          <Header>
            取得対象 <ToggleAllButton onClick={handleOnToggleAllColumn}>{toggleAllButtonLabel}</ToggleAllButton>
          </Header>
          <ColumnsGroupTitle>
            <SmallCheckBox
              checked={condition.isSelectedAllColumns('user')}
              indeterminate={!condition.isSelectedAllColumns('user') && condition.isSelectedSomeColumn('user')}
              onClick={() => handleOnToggleColumnGroup('user')}
            />
            ユーザー数
          </ColumnsGroupTitle>
          <Columns>
            <Column onClick={() => handleOnChangeColumn('business_impressions_desktop_maps')}>
              <SmallCheckBox checked={condition.columns.has('business_impressions_desktop_maps')} /> Googleマップ -
              パソコン
            </Column>
            <Column onClick={() => handleOnChangeColumn('business_impressions_mobile_maps')}>
              <SmallCheckBox checked={condition.columns.has('business_impressions_mobile_maps')} /> Googleマップ -
              モバイル
            </Column>
            <Column onClick={() => handleOnChangeColumn('business_impressions_desktop_search')}>
              <SmallCheckBox checked={condition.columns.has('business_impressions_desktop_search')} /> Google検索 -
              パソコン
            </Column>
            <Column onClick={() => handleOnChangeColumn('business_impressions_mobile_search')}>
              <SmallCheckBox checked={condition.columns.has('business_impressions_mobile_search')} /> Google検索 -
              モバイル
            </Column>
          </Columns>
          <Divider />
          <ColumnsGroupTitle>
            <SmallCheckBox
              checked={condition.isSelectedAllColumns('interaction')}
              indeterminate={
                !condition.isSelectedAllColumns('interaction') && condition.isSelectedSomeColumn('interaction')
              }
              onClick={() => handleOnToggleColumnGroup('interaction')}
            />
            インタラクション数
          </ColumnsGroupTitle>
          <Columns>
            <Column onClick={() => handleOnChangeColumn('call_clicks')}>
              <SmallCheckBox checked={condition.columns.has('call_clicks')} /> 通話
            </Column>
            <Column onClick={() => handleOnChangeColumn('business_conversations')}>
              <SmallCheckBox checked={condition.columns.has('business_conversations')} /> メッセージ
            </Column>
            <Column onClick={() => handleOnChangeColumn('business_bookings')}>
              <SmallCheckBox checked={condition.columns.has('business_bookings')} /> 予約
            </Column>
            <Column onClick={() => handleOnChangeColumn('business_direction_requests')}>
              <SmallCheckBox checked={condition.columns.has('business_direction_requests')} /> ルート
            </Column>
            <Column onClick={() => handleOnChangeColumn('website_clicks')}>
              <SmallCheckBox checked={condition.columns.has('website_clicks')} /> ウェブサイトのクリック
            </Column>
          </Columns>
          <Divider />
          <ColumnsGroupTitle>
            <SmallCheckBox
              checked={condition.isSelectedAllColumns('image')}
              indeterminate={!condition.isSelectedAllColumns('image') && condition.isSelectedSomeColumn('image')}
              onClick={() => handleOnToggleColumnGroup('image')}
            />
            写真追加数
          </ColumnsGroupTitle>
          <Columns>
            <Column onClick={() => handleOnChangeColumn('image_interior_count')}>
              <SmallCheckBox checked={condition.columns.has('image_interior_count')} /> 店内
            </Column>
            <Column onClick={() => handleOnChangeColumn('image_exterior_count')}>
              <SmallCheckBox checked={condition.columns.has('image_exterior_count')} /> 外観
            </Column>
            <Column onClick={() => handleOnChangeColumn('image_product_count')}>
              <SmallCheckBox checked={condition.columns.has('image_product_count')} /> 商品
            </Column>
            <Column onClick={() => handleOnChangeColumn('image_additional_count')}>
              <SmallCheckBox checked={condition.columns.has('image_additional_count')} /> その他
            </Column>
          </Columns>
          <Divider />
          <ColumnsGroupTitle>
            <SmallCheckBox
              checked={condition.isSelectedAllColumns('promotion')}
              indeterminate={
                !condition.isSelectedAllColumns('promotion') && condition.isSelectedSomeColumn('promotion')
              }
              onClick={() => handleOnToggleColumnGroup('promotion')}
            />
            投稿数
          </ColumnsGroupTitle>
          <Columns>
            <Column onClick={() => handleOnChangeColumn('promotion_standard_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_standard_count')} /> 最新情報
            </Column>
            <Column onClick={() => handleOnChangeColumn('promotion_event_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_event_count')} /> イベント
            </Column>
            <Column onClick={() => handleOnChangeColumn('promotion_offer_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_offer_count')} /> 特典
            </Column>
            <Column onClick={() => handleOnChangeColumn('promotion_alert_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_alert_count')} /> その他
            </Column>
            <Column onClick={() => handleOnChangeColumn('promotion_standard_rejected_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_standard_rejected_count')} />{' '}
              最新情報（公開拒否）
            </Column>
            <Column onClick={() => handleOnChangeColumn('promotion_event_rejected_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_event_rejected_count')} /> イベント（公開拒否）
            </Column>
            <Column onClick={() => handleOnChangeColumn('promotion_offer_rejected_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_offer_rejected_count')} /> 特典（公開拒否）
            </Column>
            <Column onClick={() => handleOnChangeColumn('promotion_alert_rejected_count')}>
              <SmallCheckBox checked={condition.columns.has('promotion_alert_rejected_count')} /> その他（公開拒否）
            </Column>
          </Columns>
          <Divider />
          <ColumnsGroupTitle>
            <SmallCheckBox
              checked={condition.isSelectedAllColumns('review')}
              indeterminate={!condition.isSelectedAllColumns('review') && condition.isSelectedSomeColumn('review')}
              onClick={() => handleOnToggleColumnGroup('review')}
            />
            クチコミ数
          </ColumnsGroupTitle>
          <Columns>
            <Column onClick={() => handleOnChangeColumn('review_comment_count')}>
              <SmallCheckBox checked={condition.columns.has('review_comment_count')} /> クチコミ数（コメントあり）
            </Column>
            <Column onClick={() => handleOnChangeColumn('review_rate_count')}>
              <SmallCheckBox checked={condition.columns.has('review_rate_count')} /> クチコミ数（評価のみ）
            </Column>
            <Column onClick={() => handleOnChangeColumn('review_reply_count')}>
              <SmallCheckBox checked={condition.columns.has('review_reply_count')} /> 返信数
            </Column>
            <Column onClick={() => handleOnChangeColumn('review_period_average_rating')}>
              <SmallCheckBox checked={condition.columns.has('review_period_average_rating')} /> 評価（期間平均）
            </Column>
            <Column onClick={() => handleOnChangeColumn('review_average_rating')}>
              <SmallCheckBox checked={condition.columns.has('review_average_rating')} /> 評価（累計平均）
            </Column>
          </Columns>
          <ActionWrapper>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
            <DownloadButton priority='high' disabled={!condition.isValid()} onClick={onStartCsvDownload}>
              ダウンロード
            </DownloadButton>
          </ActionWrapper>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
};

const Wrapper = styled.div`
  margin: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Divider = styled.hr`
  margin: 16px 0;
  border-top: 1px solid ${COLOR.GRAY};
  border-bottom: none;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FlexContent = styled.div`
  margin-right: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  margin: 7px 0;
`;

const FlexLabel = styled(Label)`
  display: flex;
  align-items: center;
`;

const PeriodContainer = styled.div``;

const Header = styled.div`
  font-size: 16px;
  margin: 24px 0;
`;

const ToggleAllButton = styled.span`
  margin-left: 24px;
  font-size: 14px;
  font-weight: bold;
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

const ColumnsGroupTitle = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Columns = styled.div`
  margin: 16px 0 16px 24px;
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 8px;
  cursor: pointer;
`;

const ActionWrapper = styled(Card)`
  display: flex;
  justify-content: flex-end;

  &&& {
    padding-right: 0;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    width: 180px;
  }
`;

const DownloadButton = styled(Button)`
  margin-left: 16px;

  &&& {
    width: 180px;
  }
`;
