import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

type Priority = 'high' | 'low';

interface Props {
  className?: string;
  priority?: Priority;
  negative?: boolean;
  children?: React.ReactNode;
}

export const Button: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  priority,
  negative = false,
  children,
  ...props
}) => {
  switch (priority) {
    case 'high':
      return (
        <HighPriorityButton className={className} negative={negative} {...props}>
          {children}
        </HighPriorityButton>
      );

    case 'low':
      return (
        <LowPriorityButton className={className} negative={negative} {...props}>
          {children}
        </LowPriorityButton>
      );

    default:
      return (
        <NormalButton className={className} negative={negative} {...props}>
          {children}
        </NormalButton>
      );
  }
};

type ButtonProps = { negative: boolean };

const HighPriorityButton = styled.button<ButtonProps>`
  &&& {
    appearance: none;
    color: ${COLOR.WHITE};
    font-weight: bold;
    letter-spacing: 0.8px;
    width: 280px;
    padding: 17px 27px;
    border-radius: 12px;
    background-color: ${(props) => (props.negative ? COLOR.ERROR : COLOR.GREEN)};
    border: 1px solid ${(props) => (props.negative ? COLOR.ERROR : COLOR.GREEN)};
    outline: none;
    cursor: pointer;
    transition: all 0.4s;
    box-sizing: border-box;

    &:hover {
      background-color: ${(props) => (props.negative ? COLOR.DARK_ERROR : COLOR.DARK_GREEN)};
      border: 1px solid ${(props) => (props.negative ? COLOR.DARK_ERROR : COLOR.DARK_GREEN)};
    }

    &:disabled {
      background-color: ${(props) => (props.negative ? COLOR.LIGHT_ERROR : COLOR.LIGHT_GREEN)};
      border: 1px solid ${(props) => (props.negative ? COLOR.LIGHT_ERROR : COLOR.LIGHT_GREEN)};
      cursor: not-allowed;
    }
  }
`;

export const NormalButton = styled.button<ButtonProps>`
  &&& {
    color: ${COLOR.BLACK};
    font-weight: bold;
    letter-spacing: 0.8px;
    width: 280px;
    padding: 17px 27px;
    border-radius: 12px;
    border: solid 2px ${(props) => (props.negative ? COLOR.ERROR : COLOR.GREEN)};
    background-color: ${COLOR.WHITE};
    outline: none;
    cursor: pointer;
    transition: all 0.4s;
    box-sizing: border-box;

    &:hover {
      background-color: ${(props) => (props.negative ? COLOR.LIGHT_ERROR : COLOR.LIGHT_GREEN)};
    }

    &:disabled {
      color: ${COLOR.GRAY};
      background-color: ${COLOR.WHITE};
      border-color: ${(props) => (props.negative ? COLOR.LIGHT_ERROR : COLOR.LIGHT_GREEN)};
      cursor: not-allowed;
    }
  }
`;

const LowPriorityButton = styled.button<ButtonProps>`
  &&& {
    color: ${(props) => (props.negative ? COLOR.ERROR : COLOR.GREEN)};
    font-weight: bold;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.4s;
    box-sizing: border-box;
    background-color: inherit;

    &:hover {
      color: ${(props) => (props.negative ? COLOR.DARK_ERROR : COLOR.DARK_GREEN)};
    }

    &:disabled {
      color: ${(props) => (props.negative ? COLOR.LIGHT_ERROR : COLOR.LIGHT_GREEN)};
      cursor: not-allowed;
      background-color: transparent;
    }
  }
`;

export const ActionButton = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  background-color: ${COLOR.GREEN};
  color: ${COLOR.WHITE};
  padding: 10px 20px;
  border-radius: 35px;
  cursor: pointer;
  box-shadow: 0 12px 12px 0 rgba(4, 203, 172, 0.2);
`;

export const ActionButtonText = styled.div`
  padding: 10px;
`;
