import React, { useMemo } from 'react';

import styled from 'styled-components';

import ImageSearchCondition from 'models/Domain/Image/ImageSearchCondition';
import { Stores } from 'models/Domain/Store';
import { Mixins } from 'style/mixin';

type ImageSearchConditionSummaryProps = {
  className?: string;
  filter: ImageSearchCondition;
  stores: Stores;
};

export const ImageSearchConditionSummary: React.FC<ImageSearchConditionSummaryProps> = ({
  className,
  filter,
  stores,
}) => {
  const selectedStores = stores.filterStoresById(filter.storeIds.toArray()).list;

  // GBP掲載状態のラベル
  const gbpStateLabel = useMemo(() => {
    if (filter.gbpState.has('published') && !filter.gbpState.has('unpublished')) {
      return '掲載中のみ';
    }

    if (!filter.gbpState.has('published') && filter.gbpState.has('unpublished')) {
      return '未掲載のみ';
    }

    return '';
  }, [filter]);

  // 写真登録日のラベル
  const dateRangeLabel = useMemo(() => {
    if (filter.startDate == null && filter.endDate == null) {
      return '';
    }

    const startDateStr = filter.startDate ? filter.startDate.format('YYYY/MM/DD') : '';
    const endDateStr = filter.endDate ? filter.endDate.format('YYYY/MM/DD') : '';

    return `${startDateStr} 〜 ${endDateStr}`;
  }, [filter]);

  // GBP掲載状態のラベル
  const sortTypeLabel = useMemo(() => {
    if (filter.sortKey === 'date') {
      return filter.sortOrder === 'desc' ? '登録日が新しい順' : '登録日が古い順';
    }
    if (filter.sortKey === 'view_count') {
      return filter.sortOrder === 'desc' ? '表示回数が多い順' : '表示回数が少ない順';
    }
  }, [filter]);

  return (
    <Wrapper className={className}>
      <div>
        <span>選択店舗</span>: {selectedStores.get(0)?.shortName}{' '}
        {selectedStores.size > 1 && `他${selectedStores.size - 1}店舗`}
      </div>
      {gbpStateLabel && (
        <div>
          <span>GBP掲載</span>: {gbpStateLabel}
        </div>
      )}
      {dateRangeLabel && (
        <div>
          <span>写真登録日</span>: {dateRangeLabel}
        </div>
      )}
      {filter.tag && (
        <div>
          <span>タグ</span>: {filter.tag}
        </div>
      )}
      <div>
        <span>並び順</span>: {sortTypeLabel}
      </div>
      {filter.category && (
        <div>
          <span>カテゴリー</span>: {filter.category}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  ${Mixins.flex('center')}
  gap: 8px;
  border-radius: 5px;
  padding: 8px 16px;
  background: #fff;

  span {
    font-weight: bold;
  }

  @media (max-width: 685px) {
    flex-direction: column;
    align-items: unset;
  }
`;
