import React from 'react';

import { Auth } from '@aws-amplify/auth';
import { JS } from '@aws-amplify/core';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import Logo from 'components/atoms/Logo';
import { COLOR } from 'style/color';

import { AuthPieceProps } from './AuthPiece';

type State = {
  username: string;
  code: string;
  password: string;
  delivery: any;
  isClickedFromSendView: boolean;
};

export class CustomForgotPassword extends React.Component<AuthPieceProps, State> {
  readonly state: State = {
    username: '',
    code: '',
    password: '',
    delivery: null,
    isClickedFromSendView: false,
  };

  triggerAuthEvent = (event: any) => {
    const state = this.props.authState;
    if (this.props.onAuthEvent) {
      this.props.onAuthEvent(state, event);
    }
  };

  changeState = (state: string, data?: any) => {
    if (this.props.onStateChange) {
      this.props.onStateChange(state, data);
    }

    this.triggerAuthEvent({
      type: 'stateChange',
      data: state,
    });
  };

  error = (err: any) => {
    this.triggerAuthEvent({
      type: 'error',
      data: this.errorMessage(err),
    });
  };

  errorMessage = (err: any) => {
    if (typeof err === 'string') {
      return err;
    }
    return err.message ? err.message : JSON.stringify(err);
  };

  checkContact = (user: any) => {
    Auth.verifiedContact(user).then((data) => {
      if (!JS.isEmpty(data.verified)) {
        this.changeState('signedIn', user);
      } else {
        user = Object.assign(user, data);
        this.changeState('verifyContact', user);
      }
    });
  };

  signIn = async () => {
    this.changeState('loading');

    const { username, password } = this.state;

    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.changeState('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        this.changeState('TOTPSetup', user);
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
        user.challengeParam &&
        user.challengeParam.trigger === 'true'
      ) {
        this.changeState('customConfirmSignIn', user);
      } else {
        this.checkContact(user);
      }
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        this.changeState('confirmSignUp', { username });
      } else if (err.code === 'PasswordResetRequiredException') {
        this.changeState('forgotPassword', { username });
      } else {
        this.changeState('signIn');
        this.error(err);
      }
    }
  };

  sendViaSendView = () => {
    this.setState({ isClickedFromSendView: true });
  };

  sendViaSubmitView = async () => {
    const { username } = this.state;
    const data = await Auth.forgotPassword(username);
    this.setState({ delivery: data.CodeDeliveryDetails });
  };

  submit = async () => {
    const { username, code, password } = this.state;

    await Auth.forgotPasswordSubmit(username, code, password).catch((err) => {
      this.error(err);
      return;
    });

    await this.signIn();
    this.setState({ delivery: null });
  };

  render() {
    const { authState } = this.props;
    if (authState !== 'forgotPassword') {
      return null;
    }

    const { username, code, password } = this.state;
    return (
      <Wrapper>
        <LogoWrapper>
          <Logo width='260px' />
        </LogoWrapper>

        <FormWrapper>
          <FormTitle>パスワードのリセット</FormTitle>
          {this.state.isClickedFromSendView ? (
            <SubmitView
              username={username}
              code={code}
              password={password}
              send={this.sendViaSubmitView}
              submit={this.submit}
              changeCode={(code: string) => this.setState({ code })}
              changePassword={(password: string) => this.setState({ password })}
            />
          ) : (
            <SendView
              username={username}
              send={this.sendViaSendView}
              changeUsername={(username: string) => this.setState({ username })}
            />
          )}
          <FormItemWrapper margin='50'>
            <Button
              onClick={() => {
                this.setState({ isClickedFromSendView: false });
                this.changeState('signIn', this.state);
              }}
            >
              戻る
            </Button>
          </FormItemWrapper>
        </FormWrapper>
      </Wrapper>
    );
  }
}

const SendView: React.FC<{
  username: string;
  changeUsername: (v: string) => void;
  send: () => void;
}> = ({ username, changeUsername, send }) => {
  return (
    <>
      <FormItemWrapper>
        <FormInput
          type='email'
          icon='mail'
          iconPosition='left'
          value={username}
          placeholder='メールアドレス'
          onChange={(username: string) => changeUsername(username)}
        />
      </FormItemWrapper>
      <FormItemWrapper>
        <Button
          priority='high'
          onClick={(e) => {
            e.preventDefault();
            send();
          }}
        >
          確認メールを送信
        </Button>
      </FormItemWrapper>
    </>
  );
};

const SubmitView: React.FC<{
  username: string;
  code: string;
  password: string;
  send: () => void;
  submit: () => void;
  changeCode: (v: string) => void;
  changePassword: (v: string) => void;
}> = ({ username, code, password, send, submit, changeCode, changePassword }) => {
  React.useEffect(() => {
    send();
  }, [send]);

  return (
    <>
      <InputWrapper>
        <InputLabel>検証コード</InputLabel>
        <FormText>
          入力されたメールアドレス({username})宛に検証コードを送信しました
          <br />
          メールに記載されている検証コードを入力してください
        </FormText>
        <FormInput
          icon='font'
          iconPosition='left'
          value={code}
          placeholder='検証コード'
          autoComplete='off'
          onChange={changeCode}
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>新しいパスワード</InputLabel>
        <FormText>
          パスワードは次の条件を全て満たす必要があります
          <br />
          - アルファベット・数字の両方を含む
          <br />- アルファベットは大文字・小文字の両方を含む
          <br />- 8文字以上
        </FormText>
        <FormInput
          icon='lock'
          iconPosition='left'
          value={password}
          placeholder='新しいパスワード'
          type='password'
          autoComplete='off'
          onChange={changePassword}
        />
      </InputWrapper>
      <FormItemWrapper margin='50'>
        <Button
          priority='high'
          onClick={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          パスワードの更新
        </Button>
      </FormItemWrapper>

      <FormItemWrapper>
        <Button onClick={send}>確認メールを再送信</Button>
      </FormItemWrapper>
    </>
  );
};

const Wrapper = styled.div``;

const LogoWrapper = styled.div`
  margin-bottom: 80px;
  text-align: center;
`;

const FormTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
`;

const FormText = styled.p`
  margin-bottom: 10px;
`;

const FormInput = styled(Input)`
  &&& {
    width: 280px;
    margin: 0 auto;
  }
`;

const FormWrapper = styled.form`
  background-color: ${COLOR.WHITE};
  padding: 18px;
`;

const FormItemWrapper = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.margin ? `${props.margin}px` : '18px')};
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-top: 50px;
  max-width: 400px;
`;

const InputLabel = styled.label`
  display: block;
  font-weight: bold;
  font-size: 18px;
`;
