import React from 'react';

import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import { InsightHelp as Help } from 'helpers/ContextHelp';
import { convertNumberText } from 'helpers/utils';
import { InsightGraphData, InsightGraphDataDiffRate } from 'models/Domain/GbpInsight/Insight';
import { COLOR } from 'style/color';

type Props = {
  data: InsightGraphData;
  metricsType: string;
  period: string;
  isEnabledComparison: boolean;
  comparisonPeriod?: string;
  isLoading: boolean;
  // （アクションのグラフの場合のみ）表示種別 (アクション数 or アクション率)
  showActionRate?: boolean;
  // （比較が有効な場合のみ）各項目の変化率
  diffRate?: InsightGraphDataDiffRate | null;
};

/** インサイトの期間全体の数値を表示するコンポーネント */
export const SumArea: React.FC<Props> = ({
  data,
  metricsType,
  period,
  isEnabledComparison,
  comparisonPeriod,
  isLoading,
  showActionRate,
  diffRate,
}) => {
  /** 指標種別ごとの表示項目のマッピング */
  const sumValues =
    metricsType === 'search'
      ? [
          {
            title: '直接検索',
            value: data.getSumByKey('queriesDirect'),
            help: Help.queriesDirect,
            rate: diffRate?.queriesDirect,
          },
          {
            title: '間接検索',
            value: data.getSumByKey('queriesIndirect'),
            help: Help.queriesIndirect,
            rate: diffRate?.queriesIndirect,
          },
          {
            title: 'ブランド検索',
            value: data.getSumByKey('queriesChain'),
            help: Help.queriesChain,
            rate: diffRate?.queriesChain,
          },
          { title: '検索数', value: data.sumSearch, help: Help.sumSearch, rate: diffRate?.searchCount },
        ]
      : metricsType === 'action'
        ? showActionRate
          ? [
              {
                title: 'ウェブサイトへのアクセス',
                value: data.overallActionsWebsiteRate,
                help: Help.actionsWebsiteRate,
                rate: diffRate?.actionsWebsite,
              },
              {
                title: '電話をかける',
                value: data.overallActionsPhoneRate,
                help: Help.actionsPhoneRate,
                rate: diffRate?.actionsPhone,
              },
              {
                title: 'ルートの検索',
                value: data.overallActionsDrivingDirectionsRate,
                help: Help.actionsDrivingDirectionsRate,
                rate: diffRate?.actionsDrivingDirections,
              },
              {
                title: 'アクション率',
                value: data.overallActionsRate,
                help: Help.overallActionRate,
                rate: diffRate?.actionCount,
              },
            ]
          : [
              {
                title: 'ウェブサイトへのアクセス',
                value: data.getSumByKey('actionsWebsite'),
                help: Help.actionsWebsite,
                rate: diffRate?.actionsWebsite,
              },
              {
                title: '電話をかける',
                value: data.getSumByKey('actionsPhone'),
                help: Help.actionsPhone,
                rate: diffRate?.actionsPhone,
              },
              {
                title: 'ルートの検索',
                value: data.getSumByKey('actionsDrivingDirections'),
                help: Help.actionsDrivingDirections,
                rate: diffRate?.actionsDrivingDirections,
              },
              { title: 'アクション数', value: data.sumAction, help: Help.sumAction, rate: diffRate?.actionCount },
            ]
        : metricsType === 'review'
          ? [
              {
                title: 'クチコミ数 (評価のみ)',
                value: data.getSumByKey('rateCount'),
                help: Help.rateCount,
                rate: diffRate?.rateCount,
              },
              {
                title: 'クチコミ数 (コメントあり)',
                value: data.getSumByKey('commentCount'),
                help: Help.commentCount,
                rate: diffRate?.commentCount,
              },
              { title: 'クチコミ数', value: data.sumReview, help: Help.sumReview, rate: diffRate?.reviewCount },
            ]
          : null;

  if (sumValues === null) {
    return <></>;
  }

  return (
    <SumWrapper>
      <SumValues>
        {sumValues.map((item) => (
          <SumValueWrapper key={item.title}>
            <SumTitle>
              {item.title}
              <ContextHelpWrapper>
                <ContextHelp {...item.help} />
              </ContextHelpWrapper>
            </SumTitle>
            <SumValue>
              {isLoading ? (
                // 読み込み中は ー を表示
                <Loading>ー</Loading>
              ) : metricsType === 'action' && showActionRate ? (
                // アクション率指定の場合は全体アクション率（小数第2位まで）、アクション数の場合は合計アクション数を合計値に使用
                new Intl.NumberFormat('en', { style: 'percent', maximumFractionDigits: 2 }).format(item.value)
              ) : (
                // それ以外は数値を大きさに応じて丸めて表示
                convertNumberText(item.value)
              )}
            </SumValue>
            {item.rate !== undefined && (
              <DiffRate>
                <Rate value={item.rate} />
              </DiffRate>
            )}
          </SumValueWrapper>
        ))}
      </SumValues>
      <Periods>
        <Period>対象期間: {period}</Period>
        {isEnabledComparison && <Period>比較期間: {comparisonPeriod}</Period>}
      </Periods>
    </SumWrapper>
  );
};

const SumWrapper = styled.div`
  display: flex;
  gap: 16px 0;
  flex-direction: column;
  align-items: center;
`;

const SumValues = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SumValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  padding: 8px;
  margin-top: auto;

  text-align: right;
  font-size: 24px;
  font-weight: bold;
  min-width: min(25%, 200px);
  max-width: min(33%, 240px);

  /* 数値同士の仕切り線 */
  & + & {
    border-left: 2px solid #c4c4c4;
  }

  @media (max-width: 1000px) {
    padding: 8px;
    font-size: 16px;
  }
`;

const SumTitle = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
  margin-right: 4px;
`;

const ContextHelpWrapper = styled.div`
  /* ツールチップのdivにもalign-items: centerをかける（うまく上下中央にならなかったため） */
  div {
    display: flex;
    align-items: center;
  }
`;

const SumValue = styled.div``;

const DiffRate = styled.div`
  font-size: 14px;
`;

const Rate = styled.span<{ value: number | null }>`
  color: ${({ value }) => (!value ? COLOR.DARK_GRAY : value >= 0 ? COLOR.GREEN : COLOR.RED)};
  &:before {
    content: '${({ value }) => (value === null ? '' : value === 0 ? '→' : value > 0 ? '↑' : '↓')}';
    margin-right: 4px;
  }
  &:after {
    content: '${({ value }) => (value === null ? '-' : `${(Math.abs(value) * 100).toFixed(1)}%`)}';
  }
`;

const Periods = styled.div`
  display: flex;
  gap: 0 32px;

  font-size: 14px;
  font-weight: bold;
  margin-left: 18px;
  color: ${COLOR.DARK_GRAY};
`;

const Period = styled.div``;

const Loading = styled.span`
  display: inline-block;
  color: ${COLOR.GRAY};
  padding-top: 22px;
  font-size: 14px;
`;
