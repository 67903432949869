import { Set as ImmutableSet } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { ServiceGroup, ServiceSummary } from '../../models/Domain/Service/Service';

const actionCreator = actionCreatorFactory('Service');

export const ServiceActions = {
  initializeIndexPage: actionCreator('initializeIndexPage'),
  initializeCreatePage: actionCreator<number | null>('initializeCreatePage'),
  initializeEditPage: actionCreator<number>('initializeEditPage'),
  updateServiceSummary: actionCreator<ServiceSummary>('updateServiceSummary'),
  batchDelete: actionCreator<ImmutableSet<number>>('batchDelete'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
  setServiceGroup: actionCreator<ServiceGroup>('setServiceGroup'),
  setServiceSummary: actionCreator<ServiceSummary>('setServiceSummary'),
  applyToGbp: actionCreator('applyToGbp'),
  setIsApplyingToGbp: actionCreator<boolean>('setIsApplyingToGbp'),
  createServiceGroup: actionCreator<ServiceGroup>('createServiceGroup'),
  updateServiceGroup: actionCreator<ServiceGroup>('updateServiceGroup'),
};
