import React, { useCallback, useEffect, useState } from 'react';

import { Set } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { GbpChecklistSearchCondition as SearchCondition } from 'models/Domain/GbpChecklist/GpbChecklistSearchCondition';
import { CONTAINS_CLOSED_STORES, ONLY_GBP_CONNECTED_STORES } from 'modules/gbpChecklist';
import { Group } from 'types/Common';

type Props = {
  className?: string;
  isLoading: boolean;
  committedSearchCondition: SearchCondition;
  commitSearchCondition: (searchCondition: SearchCondition) => void;
};

export const GbpChecklistSearchCondition = React.memo<Props>(
  ({ className, isLoading, committedSearchCondition, commitSearchCondition }) => {
    const [searchCondition, setSearchCondition] = useState<SearchCondition>(committedSearchCondition);

    // committedSearchConditionが変更されたら更新する
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    const {
      filter: { group, storeIds },
    } = searchCondition;

    const handleOnChangeGroupStore = useCallback(
      (group: Group, storeIds: Set<number>, isAllStoreIds: boolean) => {
        const newSearchCondition = searchCondition.mergeIn(['filter'], {
          group,
          storeIds,
          isAllStoreIds,
        });
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleOnClickChange = useCallback(() => {
      commitSearchCondition(searchCondition);
    }, [commitSearchCondition, searchCondition]);

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <GroupStoreSelect
            group={group}
            storeIds={storeIds}
            showClosedStores={CONTAINS_CLOSED_STORES}
            showGbpConnectedStoresOnly={ONLY_GBP_CONNECTED_STORES}
            showClosedStoreCheckbox={false}
            onChange={handleOnChangeGroupStore}
          />
          <StyledButton
            priority={'high'}
            onClick={handleOnClickChange}
            disabled={isLoading || !searchCondition.isValid()}
          >
            変更
          </StyledButton>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 87px;
    height: 32px;
    padding: 0;
    box-shadow: none;
    margin-top: 36px;
  }
`;
