import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { PaginationWithoutLimit } from 'models/Domain/Pagination';
import { JSObject } from 'types/Common';

import { Review } from './Review';

import { GmbReviewStatus } from '.';

export class GmbReview extends Record<{
  reviews: List<{
    store_id: number;
    review: Review;
    status: GmbReviewStatus;
    update_at: Dayjs;
    deleteAt: Dayjs | null;
    isDeleted: boolean;
  }>;
  pagination: PaginationWithoutLimit;
}>({
  reviews: List(),
  pagination: new PaginationWithoutLimit(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.reviews =
      data.reviews?.map((target: JSObject) => {
        return {
          store_id: target.store_id,
          status: target.status,
          review: new Review(target.review),
          update_at: dayjs(target.update_at),
          deleteAt: target.delete_at ? dayjs(target.delete_at) : null,
          isDeleted: target.is_deleted,
        };
      }) || List();
    super(params);
  }
}
