import { List, OrderedMap as Map, Record } from 'immutable';

import { SearchProductsParams } from 'ApiClient/OmoApi';
import { parseNumberParameter } from 'helpers/search';
import { InventoryAvailability, InventoryStatus } from 'models/Domain/Omo/Inventory';

export type ExpirationType = InventoryStatus;
export type AvailabilityType = InventoryAvailability | 'null';

/**
 * 店頭在庫検索の絞り込み条件部分
 */
export type FilterStatusType = {
  // 検索ワード
  searchValue: string;
  // 「有効期限」のステータス
  expiration: Map<ExpirationType, boolean>;
  // 「在庫状況」
  availability: Map<AvailabilityType, boolean>;
};

const DEFAULT_SEARCH_VALUE = '';
const DEFAULT_EXPIRATION_VALUE = Map({ valid: true, expiring_soon: true, expired: true }) as Map<
  ExpirationType,
  boolean
>;
const DEFAULT_AVAILABILITY_VALUE = Map({
  in_stock: true,
  out_of_stock: true,
  on_display_to_order: true,
  null: true,
}) as Map<AvailabilityType, boolean>;

// 'create': 商品情報の追加日 'update': 商品情報の更新日
export type SortKey = 'create' | 'update';
export type SortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY: SortKey = 'create';
const DEFAULT_SORT_ORDER: SortOrder = 'desc';

/**
 * 店頭在庫検索の並び替え部分
 */
export type SortStatusType = {
  key: SortKey;
  order: SortOrder;
};

/**
 * 店頭在庫検索の絞り込み条件
 */
export class FilterStatus extends Record<FilterStatusType>({
  searchValue: DEFAULT_SEARCH_VALUE,
  expiration: DEFAULT_EXPIRATION_VALUE,
  availability: DEFAULT_AVAILABILITY_VALUE,
}) {
  /**
   * 検索ワードを更新する
   * @param searchValue
   */
  setSearchValue(searchValue: string) {
    return this.set('searchValue', searchValue);
  }

  /**
   * 有効期限の選択状態を更新する
   * @param key
   * @param value
   */
  setExpiration(key: ExpirationType, value: boolean) {
    return this.setIn(['expiration', key], value);
  }

  /**
   * 在庫状況の選択状態を更新する
   * @param key
   * @param value
   */
  setAvailability(key: AvailabilityType, value: boolean) {
    return this.setIn(['availability', key], value);
  }

  /**
   * 有効期限のラベルを返す
   * @param key
   */
  getExpirationLabel(key: ExpirationType): string {
    switch (key) {
      case 'valid':
        return '有効';
      case 'expiring_soon':
        return '期限切れ間近';
      case 'expired':
        return '有効期限切れ';
    }
  }

  /**
   * 在庫状況のラベルを返す
   * @param key
   */
  getAvailabilityLabel(key: AvailabilityType): string {
    switch (key) {
      case 'in_stock':
        return '在庫あり';
      case 'out_of_stock':
        return '在庫なし';
      case 'on_display_to_order':
        return '見本展示のみ';
      case 'null':
        return 'データなし';
    }
  }

  /**
   * 現在の有効期限の絞り込み条件から、有効期限ラベルを返す
   */
  getExpirationOptionLabel(): string {
    // すべてtrue(falseを含まない)か、すべてfalse(trueを含まない)の場合
    const values = this.expiration.valueSeq();
    if (!values.includes(false) || !values.includes(true)) {
      return 'すべて';
    }

    // その他の場合は、有効なキーのラベルに変換して「、」で区切る
    return this.expiration
      .filter((value) => value)
      .map((_, key) => this.getExpirationLabel(key))
      .join('、');
  }

  /**
   * 現在の在庫状況の絞り込み条件を返す（「店頭展示のみ」の利用可否を考慮）
   *
   * 利用可能な条件の全ての場合は null を返す
   */
  getAvailabilityTypes(includesOnDisplayToOrder: boolean): List<AvailabilityType> | null {
    // 「店頭展示のみ」の利用可否によって
    const availabilityEntries = this.availability
      .entrySeq()
      .filter(([_type, value]) => _type !== 'on_display_to_order' || includesOnDisplayToOrder);
    // すべてtrue(falseを含まない)かすべてfalse(trueを含まない)の場合
    const values = availabilityEntries.map(([_type, value]) => value);
    if (!values.includes(false) || !values.includes(true)) {
      return null;
    }
    return availabilityEntries
      .filter(([_, value]) => value)
      .map(([_type, _]) => _type)
      .toList();
  }

  /**
   * 現在の在庫状況の絞り込み条件から、在庫状況ラベルを返す
   */
  getAvailabilityOptionLabel(includesOnDisplayToOrder: boolean): string {
    const availabilityTypes = this.getAvailabilityTypes(includesOnDisplayToOrder);

    if (availabilityTypes === null) {
      return 'すべて';
    }

    // その他の場合は、有効なキーのラベルに変換して「、」で区切る
    return availabilityTypes.map((_type) => this.getAvailabilityLabel(_type)).join('、');
  }
}

/**
 * 店頭在庫検索の並び替え
 */
export class SortStatus extends Record<SortStatusType>({
  key: DEFAULT_SORT_KEY,
  order: DEFAULT_SORT_ORDER,
}) {}

export type PaginationType = {
  page: number;
  per_page: number;
};

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;

export class Pagination extends Record<PaginationType>({
  page: DEFAULT_PAGE,
  per_page: DEFAULT_PER_PAGE,
}) {}

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in keyof Omit<FilterStatusType, 'storeIds'> | keyof SortStatusType | keyof PaginationType]: string;
} = {
  searchValue: 'sv',
  expiration: 'ex',
  availability: 'av',
  key: 'sk',
  order: 'so',
  page: 'pg',
  per_page: 'pp',
};

export class ProductSearchCondition extends Record<{
  filter: FilterStatus;
  sort: SortStatus;
  pagination: Pagination;
}>({
  filter: new FilterStatus(),
  sort: new SortStatus(),
  pagination: new Pagination(),
}) {
  /**
   * ソート条件をセットする
   * @param key
   * @param order
   */
  setSortCondition(key: SortKey, order: SortOrder) {
    return this.mergeIn(['sort'], { key, order });
  }

  /**
   * ページを変更する
   */
  setPage(page: number) {
    return this.setIn(['pagination', 'page'], page);
  }

  /**
   * ProductSearchConditionをAPIのパラメータに変換する
   */
  toRequestParams(includesOnDisplayToOrder: boolean): SearchProductsParams {
    // 在庫状況がすべて選択/すべて非選択の場合は指定なし, そうでなければカンマ区切り
    const availabilityTypes = this.filter.getAvailabilityTypes(includesOnDisplayToOrder);
    const availability = availabilityTypes === null ? undefined : availabilityTypes.join(',');
    // 有効期限がすべて選択/すべて非選択の場合は指定なし, そうでなければカンマ区切り
    const expiration =
      !this.filter.expiration.every((value) => value) && !this.filter.expiration.every((value) => !value)
        ? this.filter.expiration
            .filter((value) => value)
            .map((_, key) => key)
            .join(',')
        : undefined;
    return {
      query: this.filter.searchValue,
      sort_key: this.sort.key,
      sort_order: this.sort.order,
      availability,
      local_inventory_status: expiration,
      page: this.pagination.page,
      limit: this.pagination.per_page,
    };
  }

  /**
   * 検索条件をURLのパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();

    const searchValue = this.filter.searchValue;
    if (searchValue !== DEFAULT_SEARCH_VALUE) {
      params.append(URLSearchParamsMapping.searchValue, searchValue);
    }

    const expiration = this.filter.expiration.filter((value) => value);
    if (expiration.size > 0 && !expiration.keySeq().toSet().equals(DEFAULT_EXPIRATION_VALUE.keySeq().toSet())) {
      params.append(URLSearchParamsMapping.expiration, expiration.keySeq().join(','));
    }

    const availability = this.filter.availability.filter((value) => value);
    if (availability.size > 0 && !availability.keySeq().toSet().equals(DEFAULT_AVAILABILITY_VALUE.keySeq().toSet())) {
      params.append(URLSearchParamsMapping.availability, availability.keySeq().join(','));
    }

    const sortKey = this.sort.key;
    if (sortKey !== DEFAULT_SORT_KEY) {
      params.append(URLSearchParamsMapping.key, sortKey);
    }

    const sortOrder = this.sort.order;
    if (sortOrder !== DEFAULT_SORT_ORDER) {
      params.append(URLSearchParamsMapping.order, sortOrder);
    }

    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }
    if (this.pagination.per_page !== DEFAULT_PER_PAGE) {
      params.append(URLSearchParamsMapping.per_page, String(this.pagination.per_page));
    }

    // 見栄え悪いので '%2C' は ',' に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * URLのパラメータから検索条件を生成する
   * @param search
   */
  static fromURLSearchParams(search: string): ProductSearchCondition {
    const condition = new ProductSearchCondition();
    let { filter, sort, pagination } = condition;
    const params = new URLSearchParams(search);

    // フィルタ関連
    const searchValue = params.get(URLSearchParamsMapping.searchValue);
    filter = filter.set('searchValue', searchValue || DEFAULT_SEARCH_VALUE);

    const expiration = params.get(URLSearchParamsMapping.expiration);
    if (expiration) {
      const expirationStrs = expiration.split(',');
      const expirationKeys = expirationStrs.filter((v) => v.match(/^valid|expiring_soon|expired$/));
      filter = filter.update('expiration', (value) => value.map((_, key) => expirationKeys.includes(key)));
    }

    const availability = params.get(URLSearchParamsMapping.availability);
    if (availability) {
      const availabilityStrs = availability.split(',');
      const availabilityKeys = availabilityStrs.filter((v) =>
        v.match(/^in_stock|out_of_stock|on_display_to_order|null$/),
      );
      filter = filter.update('availability', (value) => value.map((_, key) => availabilityKeys.includes(key)));
    }

    // ソート関連
    const sortKey = params.get(URLSearchParamsMapping.key);
    if (sortKey) {
      if (['create', 'update'].includes(sortKey)) {
        sort = sort.set('key', sortKey as SortKey);
      }
    }
    const sortOrder = params.get(URLSearchParamsMapping.order);
    if (sortOrder) {
      if (['asc', 'desc'].includes(sortOrder)) {
        sort = sort.set('order', sortOrder as SortOrder);
      }
    }

    // ページネーション関連
    pagination = pagination.set('page', parseNumberParameter(params.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1));
    pagination = pagination.set(
      'per_page',
      parseNumberParameter(params.get(URLSearchParamsMapping.per_page), DEFAULT_PER_PAGE, 1, 100),
    );

    return condition.merge({ filter, sort, pagination });
  }
}
