import React from 'react';

import ReactLinkify from 'react-linkify';
import styled from 'styled-components';

const componentDecorator = (href: string, text: string, key: number) => (
  <TextLink href={href} key={key} target='_blank' rel='noopener noreferrer'>
    {text}
  </TextLink>
);

export const Linkify = React.memo<{ children: React.ReactNode }>(({ children }) => {
  return <ReactLinkify componentDecorator={componentDecorator}>{children}</ReactLinkify>;
});

const TextLink = styled.a`
  &,
  &:hover {
    color: #4183c4;
    text-decoration: underline;
  }
`;
