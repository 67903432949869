import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Input } from 'components/atoms/Input';
import { ErrorLabel } from 'components/atoms/Label';
import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { SIZE } from 'style/size';
import { SelectOption } from 'types/Common';

type OrganizationSelectProps = {
  className?: string;
  organizationIds: ImmutableSet<number>;
  onChange: (organizationIds: ImmutableSet<number>, isAllOrganizationIds: boolean) => void;
};

export const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ className, organizationIds, onChange }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  // 組織の選択をコンポーネントで完結できるように、ストアの情報を直接参照して利用する
  const { accountList } = useSelector((state) => state.app);

  // ドロップダウンのオプションを生成する
  const options = useMemo((): SelectOption<number>[] => {
    return accountList.items
      .map((account) => ({
        value: account.organizationId,
        text: account.name,
      }))
      .toArray();
  }, [accountList.items]);

  const isValidOrganizationIds = organizationIds.size > 0;

  const label = useMemo(() => {
    if (organizationIds.isEmpty()) {
      return '';
    }
    if (organizationIds.size === accountList.items.size) {
      return 'すべて';
    }
    const organizationLabel = organizationIds
      .sort()
      .map((organizationId) => accountList.find(organizationId)?.name)
      .filter(Boolean)
      .join('、');
    return `${organizationIds.size}アカウント （${organizationLabel}）`;
  }, [accountList, organizationIds]);

  /**
   * アカウントを選択した場合の処理
   *
   * @param organizationId 変更対象の組織ID
   */
  const handleOnChangeOrganization = useCallback(
    (organizationId: number) => {
      let updatedOrganizationIds: ImmutableSet<number>;
      if (organizationIds.has(organizationId)) {
        // 選択解除の場合
        updatedOrganizationIds = organizationIds.delete(organizationId);
      } else {
        // 選択追加の場合
        updatedOrganizationIds = organizationIds.add(organizationId);
      }
      const updatedIsAllOrganizationIds = updatedOrganizationIds.equals(
        ImmutableSet(accountList.items.map((account) => account.organizationId)),
      );
      onChange(updatedOrganizationIds, updatedIsAllOrganizationIds);
    },
    [accountList.items, onChange, organizationIds],
  );

  /**
   * 「すべて」を選択した場合の処理
   *
   * @param organizationId 変更対象の組織ID
   */
  const handleOnChangeAllOrganization = useCallback(
    (value: boolean) => {
      if (value) {
        onChange(ImmutableSet(options.map((target) => target.value)), true);
      } else {
        onChange(ImmutableSet(), false);
      }
    },
    [onChange, options],
  );

  return (
    <Wrapper className={className}>
      <Label>アカウント</Label>
      <WideMultiSelectBox value={label} error={!isValidOrganizationIds}>
        <StyledInput placeholder={'組織を検索'} value={searchQuery} onChange={(v) => setSearchQuery(v)} />
        <CheckLabel
          checked={organizationIds.size === options.length}
          label={'すべて'}
          onChange={() => handleOnChangeAllOrganization(!(organizationIds.size === options.length))}
        />
        {options
          .filter((target) => target.text.indexOf(searchQuery) > -1)
          .map((target, index) => (
            <CheckLabel
              key={index}
              label={target.text}
              checked={organizationIds.has(target.value)}
              onChange={() => handleOnChangeOrganization(target.value)}
            />
          ))}
      </WideMultiSelectBox>
      {!isValidOrganizationIds && <ErrorLabel pointing>アカウントを選択してください</ErrorLabel>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const Label = styled.div``;

const WideMultiSelectBox = styled(MultiSelectBox)`
  width: 380px;
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  &&& {
    display: inline-block;
    width: calc(100% - 16px);
    margin: 8px;
    & > div.ui > input {
      padding: 4px 6px;
    }
  }
`;
const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;
