import { Menu as SemanticMenu } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';

const StyledMenu = styled(SemanticMenu)`
  height: 32px;
  &&& {
    display: inline-flex;
    min-height: auto;
    margin: 0;
    box-shadow: none;
    border-color: ${COLOR.GRAY};
  }
`;

const StyledMenuItem = styled(SemanticMenu.Item)`
  &&& {
    padding: 8px 9px;
    color: ${COLOR.GRAY};
    font-size: 12px;
    &.active {
      background-color: ${COLOR.GREEN};
      color: ${COLOR.WHITE};
      &:hover {
        background-color: ${COLOR.GREEN};
        color: ${COLOR.WHITE};
      }
    }
  }
`;

type MenuType = typeof StyledMenu & {
  Item: typeof StyledMenuItem;
};

const Menu: MenuType = StyledMenu as any;
Menu.Item = StyledMenuItem as any;

export default Menu;
