import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { GbpChecklistItem, Status } from 'models/Domain/GbpChecklist';
import { Store, Stores } from 'models/Domain/Store';
import { Path } from 'routes';

export type Props = {
  className?: string;
  stores: Stores;
  editableStores: Stores;
  item: GbpChecklistItem;
};

export const GbpChecklistStoreTable = React.memo<Props>(({ className, item, stores }) => {
  return (
    <Wrapper className={className}>
      <Table unstackable={true}>
        <Header />
        <TableBody>
          {stores.list.map((store, i) => (
            <ItemRow key={i} store={store} status={item.status} urlType={item.url} />
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
});

const Header = () => (
  <TableHeader>
    <TableHeaderRow>
      <TableHeaderCell>店舗名</TableHeaderCell>
      <TableHeaderCell>店舗コード</TableHeaderCell>
      <StyledTableHeaderCell>達成</StyledTableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
);

type ItemRowProps = {
  store: Store;
  status: Status;
  urlType: GbpChecklistItem['url'];
};

const ItemRow = React.memo<ItemRowProps>(({ store, status, urlType }) => {
  const link = useMemo((): string => {
    switch (urlType) {
      case 'store':
        return `${Path.store.detail.replace(':storeId', `${store.id}`)}`;
      case 'diff':
        return `${Path.gmb.locationUpdates}`;
      case 'photo':
        return `${Path.album.index}?st=all&si=${store.id}`;
      case 'photoInterior':
        return `${Path.album.index}?st=all&cg=INTERIOR&si=${store.id}`;
      case 'photoExterior':
        return `${Path.album.index}?st=all&&cg=EXTERIOR&si=${store.id}`;
      case 'localPost':
        return `${Path.localpost.index}?st=all&si=${store.id}`;
      case 'review':
        return `${Path.gmb.review}?st=all&si=${store.id}&dn=0&nr=0`;
    }
  }, [store.id, urlType]);

  return (
    <TableRow>
      <TableCell>
        <StyledLink onClick={(e) => e.stopPropagation()} to={link}>
          {store.fullName}
        </StyledLink>
      </TableCell>
      <TableCell>{store.code}</TableCell>
      <TableCell>{status.done.includes(store.id) ? '○' : 'X'}</TableCell>
    </TableRow>
  );
});

const Wrapper = styled.div``;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  width: 120px;
`;

const StyledLink = styled(Link).attrs({ target: '_blank' })`
  font-weight: bold;
  text-decoration: underline;
  font-size: 14px;
`;
