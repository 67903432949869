import React, { useCallback } from 'react';

import { Dropdown, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import {
  ActionItemLabel,
  ActionMenuItem,
  MenuCell,
  MenuHeaderCell,
  Notification,
  SettingsIcon,
} from 'components/pageComponents/MapCompetitorResearch/Common';
import { MapCompetitorResearchSearchCondition as SearchCondition } from 'models/Domain/MapCompetitorResearch/MapCompetitorResearchSearchCondition';
import { SearchHistory, SearchHistoryList } from 'models/Domain/MapCompetitorResearch/SearchHistory';
import { Path } from 'routes';

interface Props {
  className?: string;
  searchHistoryList: SearchHistoryList;
  deleteHistory: (id: string) => void;
  restoreSearchCondition: (searchCondition: SearchCondition) => void;
}

// 検索地点、検索ワードを省略せずに表示する件数
const DISPLAY_CONDITION_COUNT = 5;

export const SearchHistoryTable = React.memo<Props>(
  ({ className, searchHistoryList, deleteHistory, restoreSearchCondition }) => {
    const onClickReSearch = useCallback(
      (condition: Pick<SearchHistory, 'areaNames' | 'searchWords'>) => {
        restoreSearchCondition(new SearchCondition(condition));
      },
      [restoreSearchCondition],
    );
    return (
      <Wrapper className={className}>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <StyledTableHeaderCell weight={1}>検索地点</StyledTableHeaderCell>
              <StyledTableHeaderCell weight={1}>検索ワード</StyledTableHeaderCell>
              <DateHeaderCell>検索日時</DateHeaderCell>
              <MenuHeaderCell />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {searchHistoryList.histories.map((searchHistory) => (
              <SearchHistoryTableRow
                key={searchHistory.id}
                searchHistory={searchHistory}
                onReSearch={onClickReSearch}
                onDeleteHistory={deleteHistory}
              />
            ))}
          </TableBody>
        </Table>
        {searchHistoryList.histories.size === 0 && <Notification>検索履歴がありません</Notification>}
      </Wrapper>
    );
  },
);

export const SearchHistoryTableRow = React.memo<{
  searchHistory: SearchHistory;
  onDeleteHistory: (id: string) => void;
  onReSearch: (condition: Pick<SearchHistory, 'areaNames' | 'searchWords'>) => void;
}>(({ searchHistory, onDeleteHistory, onReSearch }) => {
  const resultPagePath = Path.mapCompetitorResearch.result.replace(':id', searchHistory.id);
  return (
    <StyledTableRow>
      <StyledTableCell>
        <StyledLink to={resultPagePath}>
          {searchHistory.areaNames.slice(0, DISPLAY_CONDITION_COUNT).join(' / ')}
          {searchHistory.areaNames.size > DISPLAY_CONDITION_COUNT && (
            <Popup
              content={
                <PopupContent>
                  {searchHistory.areaNames.map((areaName) => (
                    <div key={areaName}>{areaName}</div>
                  ))}
                </PopupContent>
              }
              trigger={<PopupTrigger>{`他${searchHistory.areaNames.size - DISPLAY_CONDITION_COUNT}件`}</PopupTrigger>}
            />
          )}
        </StyledLink>
      </StyledTableCell>
      <StyledTableCell>
        <StyledLink to={resultPagePath}>
          {searchHistory.searchWords.slice(0, DISPLAY_CONDITION_COUNT).join(' / ')}
          {searchHistory.searchWords.size > DISPLAY_CONDITION_COUNT && (
            <Popup
              content={
                <PopupContent>
                  {searchHistory.searchWords.map((searchWord) => (
                    <div key={searchWord}>{searchWord}</div>
                  ))}
                </PopupContent>
              }
              trigger={<PopupTrigger>{`他${searchHistory.searchWords.size - DISPLAY_CONDITION_COUNT}件`}</PopupTrigger>}
            />
          )}
        </StyledLink>
      </StyledTableCell>
      <DateCell>
        <StyledLink to={resultPagePath}>
          <Date>{searchHistory.date.format('YYYY/MM/DD HH:mm')}</Date>
        </StyledLink>
      </DateCell>
      <MenuCell>
        <Dropdown trigger={<SettingsIcon />} icon={null}>
          <Dropdown.Menu direction={'left'}>
            <ActionMenuItem
              onClick={() => onReSearch({ areaNames: searchHistory.areaNames, searchWords: searchHistory.searchWords })}
            >
              <ActionItemLabel>同じ条件で検索</ActionItemLabel>
            </ActionMenuItem>
            <ActionMenuItem onClick={() => onDeleteHistory(searchHistory.id)}>
              <ActionItemLabel>履歴から削除</ActionItemLabel>
            </ActionMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </MenuCell>
    </StyledTableRow>
  );
});

const Wrapper = styled.div``;

const StyledTableRow = styled(TableRow)``;

const StyledTableHeaderCell = styled(TableHeaderCell)``;

const StyledTableCell = styled(TableCell)`
  &&& {
    padding: 0;
  }
`;

const DateHeaderCell = styled(TableHeaderCell)`
  width: 160px;
`;

const DateCell = styled(StyledTableCell)`
  width: 160px;
`;

const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  padding: 8px;
`;

const PopupTrigger = styled.span`
  display: inline-block;
  margin-left: 8px;
  text-decoration: underline;
  font-size: 12px;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;

const Date = styled.div`
  font-family: monospace !important;
  font-size: 14px;
`;
