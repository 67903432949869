import ApiClient from 'ApiClient';

const apiClient = new ApiClient({});
const apiClientForAccounts = new ApiClient({ useCurrentOrganization: false });

const ACCOUNT_PATH = '/account';
const ACCOUNTS_PATH = '/accounts';

type AccountData = {
  id: number;
  is_setup: boolean;
  email: string;
  first_name: string;
  last_name: string;
  notify_location_update: boolean;
  notify_offer_update: boolean;
  notify_review_update: boolean;
  organization: {
    name: string;
    root_pb_id: string;
    id: number | null;
    features: {
      plan: string;
      included_features: string[];
      excluded_features: string[];
    };
    params: {
      map_search_rank_config_limit?: number;
      can_use_gbp_media_product_category?: boolean;
    };
    contracted_stores: number;
  };
  organization_id: number;
  pb_id: string;
  profile_image_url: string;
  role: string;
  stores: {
    id: number;
    name: string;
    branch: string;
    code: string;
    organization_id: number;
  }[];
  managing_stores: {
    id: number;
    name: string;
    branch: string;
    code: string;
    organization_id: number;
  }[];
};
export type AccountApiResponse = AccountData;

export class AccountApi {
  static get() {
    return apiClient.get<AccountApiResponse>(ACCOUNT_PATH);
  }
}

export type AccountsApiResponse = {
  items: AccountData[];
};

export class AccountsApi {
  static get() {
    return apiClientForAccounts.get<AccountsApiResponse>(ACCOUNTS_PATH);
  }
}
