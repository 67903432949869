import React, { useCallback } from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { CheckBox } from 'components/atoms/CheckBox';
import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { COLOR } from 'style/color';

import { AttributeEnum } from './AttributeEnum';
import { AttributeRepeatedEnum } from './AttributeRepeatedEnum';

type Props = {
  className?: string;
  attributeMetadatas: GmbAttributeMetadatas;
  attributes: GmbAttributes;
  individualUpdate: boolean;
  setAttributes: (attributes: GmbAttributes) => void;
  targetAttributeIds: List<string>;
  setTargetAttributeIds: (attributeId: string) => void;
};

export const AttributesForm = React.memo<Props>(
  ({
    className,
    attributeMetadatas,
    attributes,
    individualUpdate,
    setAttributes,
    targetAttributeIds,
    setTargetAttributeIds,
  }) => {
    const groupedCategoryAttributes = attributeMetadatas.groupByRemovedUrlGroupDisplayName();

    // 空の値を含めた属性データに変換
    const complementedAttributes = attributes.complement(attributeMetadatas);

    // 値を含めた除くデータに変換してハンドラに渡す
    const handleChangeAttributes = useCallback(
      (attributes: GmbAttributes, attributeId: string) => {
        setAttributes(attributes.removeNoValueAttributes());
      },
      [setAttributes],
    );

    return (
      <Wrapper className={className}>
        {List(groupedCategoryAttributes)
          .filter(([_, attributeMetadataList], idx) => {
            const targetList = attributeMetadataList.toList();
            return !targetList.isEmpty();
          })
          .map(([groupName, attributeMetadataList], idx) => {
            return (
              <GroupWrapper key={idx}>
                <GroupName>{groupName}</GroupName>
                <ToggleWrapper>
                  {attributeMetadataList.map((attributeMetadata, idx2) => {
                    const targetAttribute = complementedAttributes.getAttribute(attributeMetadata.attributeId);
                    if (!targetAttribute) {
                      return <React.Fragment key={idx2}></React.Fragment>;
                    } else if (attributeMetadata.isBool) {
                      return (
                        <CheckWrapper key={idx2} alignItemsOnCenter={true}>
                          {individualUpdate && (
                            <StyledCheckBox
                              checked={!!targetAttributeIds.find((t) => t === targetAttribute.attributeId)}
                              onChange={() => setTargetAttributeIds(targetAttribute.attributeId)}
                            />
                          )}
                          <OptionalToggle
                            key={idx2}
                            label={attributeMetadata.displayName}
                            checked={targetAttribute.booleanValues.get(0)}
                            onClick={() => {
                              handleChangeAttributes(
                                complementedAttributes.toggleBoolAttribute(attributeMetadata.attributeId),
                                targetAttribute.attributeId,
                              );
                              if (!targetAttributeIds.find((t) => t === targetAttribute.attributeId)) {
                                setTargetAttributeIds(targetAttribute.attributeId);
                              }
                            }}
                          />
                        </CheckWrapper>
                      );
                    } else if (attributeMetadata.isEnum) {
                      return (
                        <CheckWrapper key={idx2}>
                          {individualUpdate && (
                            <StyledCheckBox
                              checked={!!targetAttributeIds.find((t) => t === targetAttribute.attributeId)}
                              onChange={() => setTargetAttributeIds(targetAttribute.attributeId)}
                            />
                          )}
                          <AttributeEnum
                            toggle={(value) => {
                              handleChangeAttributes(
                                complementedAttributes.toggleEnumAttribute(attributeMetadata.attributeId, value),
                                targetAttribute.attributeId,
                              );
                              if (!targetAttributeIds.find((t) => t === targetAttribute.attributeId)) {
                                setTargetAttributeIds(targetAttribute.attributeId);
                              }
                            }}
                            key={idx2}
                            attribute={targetAttribute}
                            attributeMetadatas={attributeMetadata}
                          />
                        </CheckWrapper>
                      );
                    } else if (attributeMetadata.isRepeatedEnum) {
                      return (
                        <CheckWrapper key={idx2}>
                          {individualUpdate && (
                            <StyledCheckBox
                              checked={!!targetAttributeIds.find((t) => t === targetAttribute.attributeId)}
                              onChange={() => setTargetAttributeIds(targetAttribute.attributeId)}
                            />
                          )}
                          <AttributeRepeatedEnum
                            key={idx2}
                            attribute={targetAttribute}
                            attributeMetadatas={attributeMetadata}
                            toggle={(value) => {
                              handleChangeAttributes(
                                complementedAttributes.toggleRepeatedEnumAttribute(
                                  attributeMetadata.attributeId,
                                  value,
                                ),
                                targetAttribute.attributeId,
                              );
                              if (!targetAttributeIds.find((t) => t === targetAttribute.attributeId)) {
                                setTargetAttributeIds(targetAttribute.attributeId);
                              }
                            }}
                          />
                        </CheckWrapper>
                      );
                    }
                    return;
                  })}
                </ToggleWrapper>
              </GroupWrapper>
            );
          })}
        <Notice>注: 編集内容の品質確認が行われることがあり、公開されるまでに 3 日ほどかかる場合があります。</Notice>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const GroupWrapper = styled.div`
  border-bottom: 1px solid rgba(181, 181, 181, 0.5);
  padding: 25px 0 18px;
`;

const GroupName = styled.div`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 4px;
`;

const CheckWrapper = styled.div<{ alignItemsOnCenter?: boolean }>`
  display: flex;
  margin-bottom: 8px;
  ${(props) => props.alignItemsOnCenter && 'align-items: center;'}
`;

const StyledCheckBox = styled(CheckBox)`
  margin-right: 8px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const Notice = styled.p`
  font-size: 13px;
  color: ${COLOR.GRAY};
  margin: 16px 0;
`;
