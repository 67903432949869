import { parse } from 'querystring';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import styled from 'styled-components';

import { GmbAccountApi, GmbAuthApi } from 'ApiClient/GmbApi';
import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { StickyHeader } from 'components/atoms/StickyHeader';
import GmbList from 'components/pageComponents/GmbIndex/GmbListContent';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useStorage } from 'hooks/useStorage';
import { GmbActions } from 'modules/gmb/actions';
import { COLOR } from 'style/color';

/**
 * NOTE スコープ追加時の注意
 *
 * - 新しいスコープを追加する際、それが制限されたスコープまたは機密性の高いスコープの場合、
 *   GBPのOAuth認証画面の設定から利用するスコープを追加して審査を通過してから使うこと
 * - 審査を受けず未認証のまま制限または機密スコープを要求すると警告画面が挟まってしまう
 */
const scopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/plus.business.manage',
  'https://www.googleapis.com/auth/analytics.readonly',
  'https://www.googleapis.com/auth/content',
];

const ConnectGmbPanel = ({ onClick }: any) => {
  return (
    <Wrapper>
      <Title>Googleアカウントの追加</Title>
      <PanelDescription>次のボタンを押してアプリを認証すると、Googleアカウントを追加できます。</PanelDescription>
      <figure onClick={onClick}>
        <img src={'/images/google_signin_button.png'} style={{ width: '280px' }} />
      </figure>
    </Wrapper>
  );
};

const DuplicateGmbPanel = ({ onClick }: { onClick: (email: string) => void }) => {
  const [email, setEmail] = useState('@pathee.com');
  const onChangeEmail = useCallback((value: string) => {
    setEmail(value);
  }, []);
  const canRegister = useMemo(() => email.match(/.+@pathee\.com/), [email]);
  const onClickButton = useCallback(() => {
    onClick(email);
  }, [email, onClick]);

  return (
    <Wrapper>
      <Title>Googleアカウントのコピー</Title>
      <PanelDescription>
        同一メールアドレスを多く新規登録すると古いものから認証情報が無効になってしまうため、メールアドレスを指定して認証情報をコピーを利用してください。特定のアカウントのみコピー可能です。
      </PanelDescription>
      <InputWrapper>
        <Input value={email} onChange={onChangeEmail} placeholder='xxxxx@pathee.com' />
        <Button priority='high' disabled={!canRegister} onClick={onClickButton}>
          追加
        </Button>
      </InputWrapper>
    </Wrapper>
  );
};

export const GmbIndex: React.FC = () => {
  const [selectedAccountId, setSelectedAccountId] = useStorage<number | null>('EDITING_ACCOUNT_ID', null, {
    changeDetection: false,
    storageType: 'session',
  });
  const changeSelectedAccountId = useCallback(
    (accountId: number | null) => setSelectedAccountId(accountId),
    [setSelectedAccountId],
  );

  const getGoogleAuthorizeUrl = async () => {
    try {
      // リダイレクトURLにパラメータが含まれるとログイン画面が正確に表示されないのでクエリパラメータを含めない
      const response = await GmbAuthApi.post({
        redirect_uri: `${window.location.origin}${window.location.pathname}`,
        scopes,
      });
      return response.isSuccess && response.data.url;
    } catch (e) {
      toast({
        type: 'error',
        title: 'Googleアカウントの認証用URLの取得に失敗しました',
        description: String(e),
        time: 10000,
      });
      return null;
    }
  };

  // TODO: 子ウィンドウを開いて認証処理を行うように修正する
  const signInGoogle = async (accountId?: number) => {
    changeSelectedAccountId(accountId ? accountId : null);
    const authorizeUrl = await getGoogleAuthorizeUrl();
    if (authorizeUrl) {
      window.location.href = authorizeUrl;
    }
  };

  const dispatch = useDispatch();
  const gmbList = useSelector((state) => state.gmb.gmbList);

  const updateGbpList = async () => {
    const params = parse(window.location.search.slice(1));

    // TODO: エラーの場合に対応する
    if (params.state && params.code && params.scope) {
      // 認証パラメーターを送ることで連携が完了
      if (selectedAccountId) {
        // account_idをリクエストパラメータに含めることで、既存のレコードを更新する
        params.account_id = String(selectedAccountId);
      }
      params.redirect_uri = `${window.location.origin}${window.location.pathname}`;
      // Googleアカウントの認証情報を、DB（gmb.account）に追加/更新する
      await GmbAccountApi.post(params);
      // URLパラメーターを削除する
      dispatch(replaceWithOrganizationId(window.location.pathname));
    }

    dispatch(GmbActions.getGmbList());
  };

  useEffect(() => {
    updateGbpList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUser = useSelector((state) => state.app.currentUser);

  const duplicateAccount = useCallback(
    async (email: string) => {
      const response = await GmbAccountApi.duplicate({ email });
      if (response.isSuccess) {
        dispatch(GmbActions.getGmbList());
      } else {
        toast({
          type: 'warning',
          title: 'Googleアカウントの複製ができませんでした',
          time: 10000,
        });
      }
    },
    [dispatch],
  );

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('Googleアカウント一覧')} />
      <StickyHeader title='Googleアカウント一覧' />
      <Body>
        <GmbList gmbList={gmbList} onAuthorize={signInGoogle} />
        <ConnectGmbPanel onClick={signInGoogle} />
        {currentUser.canUseDuplicateGbpAccount && <DuplicateGmbPanel onClick={duplicateAccount} />}
      </Body>
    </MainWrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 30px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  flex-shrink: 0;
  margin-bottom: 5px;
`;

const PanelDescription = styled.p`
  color: ${COLOR.DARK_GRAY};
  font-size: 13px;
  margin: 0 0 10px 4px;
`;

const InputWrapper = styled.div`
  display: flex;
  *:not(:first-child) {
    margin-left: 16px;
  }
`;
