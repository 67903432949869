import React, { useMemo } from 'react';

import styled from 'styled-components';

import Grid from 'components/atoms/Grid';
import GridArea from 'components/atoms/GridArea';
import { StatsCard } from 'components/pageComponents/OmoInsight/StatsCard';
import { useDisplayType } from 'hooks/useDisplayType';
import { PerformanceMetric } from 'models/Domain/OmoInsight';
import { CompositeOmoInsightSummaryItem } from 'models/Domain/OmoInsight/InsightSummary';
import { COLOR } from 'style/color';

import { METRIC_LABELS } from '..';

type Props = {
  className?: string;
  insight: CompositeOmoInsightSummaryItem;
  selectedMetric: PerformanceMetric | null;
  selectMetric: (metric: PerformanceMetric) => void;
  activeMetrics: PerformanceMetric[];
};

const statsCardPrefixSuffix: { [key in PerformanceMetric]?: { prefix?: string; suffix?: string; digits?: number } } = {
  ctr: { digits: 2, suffix: '%' },
  revenues: { prefix: '¥ ' },
};

export const OmoInsightPeformanceMetricsList = React.memo<Props>(
  ({ className, insight, selectedMetric, selectMetric: selectMetric, activeMetrics }) => {
    // PC表示・SP表示のGridの設定
    const isDesktop = useDisplayType('1100px').isDesktop;
    const pcColumns = ['1fr', '1fr', '1fr', '1fr', '1fr', '1fr'];
    const spColumns = ['1fr', '1fr'];
    const pcRows = ['1fr'];
    const spRows = ['1fr', '1fr', '1fr'];
    // areasは有効なメトリックのリストで、6つに満たない分は"."で埋める
    // PCは1行に6つ表示し、SPは2つずつ3行表示する
    const areas = (activeMetrics as string[]).concat(['.', '.', '.', '.', '.', '.']).slice(0, 6);
    const pcAreas = [areas];
    const spAreas = new Array(3).fill([]).map((_, i) => areas.slice(i * 2, i * 2 + 2));

    const mergedData = useMemo(() => insight.getMergedData(), [insight]);

    return (
      <Wrapper className={className}>
        <CardContainer
          columns={isDesktop ? pcColumns : spColumns}
          rows={isDesktop ? pcRows : spRows}
          areas={isDesktop ? pcAreas : spAreas}
        >
          {activeMetrics.map((metric) => {
            return (
              <GridArea key={metric} name={metric}>
                <CardWrapper selected={selectedMetric === metric} onClick={() => selectMetric(metric)}>
                  <StyledStatsCard
                    label={METRIC_LABELS[metric]}
                    value={mergedData.target[metric] || 0}
                    diff={mergedData.rate ? mergedData.rate[metric] || undefined : undefined}
                    prefix={statsCardPrefixSuffix[metric]?.prefix}
                    suffix={statsCardPrefixSuffix[metric]?.suffix}
                    digits={statsCardPrefixSuffix[metric]?.digits}
                  />
                </CardWrapper>
              </GridArea>
            );
          })}
        </CardContainer>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const CardContainer = styled(Grid)`
  grid-gap: 8px 0;
  margin-bottom: 8px;
  background: ${COLOR.WHITE};
  @media (max-width: 700px) {
    background: none;
  }
`;

const StyledStatsCard = styled(StatsCard)`
  outline: none;
  border: none;
`;

const CardWrapper = styled.div<{ selected: boolean }>`
  height: 100%;
  cursor: pointer;
  position: relative;

  ${StyledStatsCard} {
    :after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      border: 2px solid ${COLOR.GREEN};
      display: ${({ selected }) => (selected ? 'block' : 'none')};
    }
  }

  :hover {
    background-color: ${COLOR.LIGHT_GREEN};
    ${StyledStatsCard} {
      background: none;
    }
  }
`;
