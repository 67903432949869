import { useCallback, useEffect, useState } from 'react';

import { StorageType, getStorageItem, getStorageKey, setStorageItem } from 'helpers/storage';

/**
 * ローカルストレージの情報を利用するためのカスタムHooks
 *
 * @param key 扱う対象の情報を格納するキー
 * @param initialValue 値が存在しない場合の初期値
 * @param options.storageType 対象のストレージタイプ (デフォルト: 'local)
 * @param options.isGlobal 全アカウント共通の設定か（デフォルト: false）
 * @param options.changeDetection 値の変更を検知するか（デフォルト: true）
 * @returns
 */
export const useStorage = <T>(
  key: string,
  initialValue: T,
  options: {
    storageType?: StorageType;
    isGlobal?: boolean;
    changeDetection?: boolean;
  } = {},
): [T, (value: T) => void] => {
  const { changeDetection = true, isGlobal = false, storageType = 'local' } = options;
  const [storedValue, setStoredValue] = useState<T>(() => {
    return getStorageItem(key, initialValue, { storageType, isGlobal });
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        setStoredValue(value);
        setStorageItem(key, value, { storageType, isGlobal });
      } catch (error) {
        // ローカルストレージを利用できない環境など
        console.log(error);
      }
    },
    [isGlobal, key, storageType],
  );

  // 変更検知処理
  useEffect(() => {
    if (changeDetection) {
      const handler = (event: StorageEvent) => {
        // キーが監視対象、かつ新旧値が異なる場合、新しい値を利用する
        if (event.key === getStorageKey(key, isGlobal) && event.oldValue !== event.newValue) {
          const item = event.newValue;
          setStoredValue(item ? JSON.parse(item) : initialValue);
        }
      };

      // StorageEventをリスナー登録
      // 変更検知は、LocalStorageのデータ全体が対象で、特定キーの指定はできない
      window.addEventListener('storage', handler);
      return () => {
        window.removeEventListener('storage', handler);
      };
    }
  }, [changeDetection, initialValue, isGlobal, key, setValue]);

  return [storedValue, setValue];
};
