import React, { useCallback } from 'react';

import dayjs from 'dayjs';
import { Set } from 'immutable';
import { Search } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { DatePickerWrapper, DateRangePicker } from 'components/molecules/DateRangePicker';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { PromotionTopicTypeWithALL } from 'models/Domain/Promotion/Promotion';
import { SortType, SortTypeOptions } from 'models/Domain/Promotion/PromotionList';
import { ParamsStatus } from 'models/Domain/Promotion/PromotionSearchCondition';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

type SelectOption<T> = {
  text: string;
  value: T;
};

type PromotionFilterProps = {
  className?: string;
  filter: ParamsStatus;
  onChangeFilter: (filter: ParamsStatus) => void;
  onCommitFilter: () => void;
  disableCommitButton?: boolean;
};

export const PromotionFilter: React.FC<PromotionFilterProps> = ({
  className,
  filter,
  onChangeFilter,
  onCommitFilter,
  disableCommitButton,
}) => {
  const topicTypeOptions: SelectOption<PromotionTopicTypeWithALL>[] = [
    { value: 'ALL', text: 'すべて' },
    { value: 'STANDARD', text: '最新情報' },
    { value: 'OFFER', text: '特典' },
    { value: 'EVENT', text: 'イベント' },
  ];

  const handleChangeTopicType = useCallback(
    (topicType: PromotionTopicTypeWithALL) => {
      const updatedFilter = filter.set('topic_type', topicType);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  const handleChangeFreeWord = useCallback(
    (_: React.SyntheticEvent, data: any) => {
      const updatedFilter = filter.setFreeWord(data.value || '');
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  const handleChangeSortType = useCallback(
    (sortType: SortType) => {
      const updatedFilter = filter.setSort(sortType);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * グループ、店舗のフィルターを変更する
   * @param group グループ
   * @param storeIds 店舗のID
   * @param isAllStores すべての店舗が選択されているか
   * @param showClosedStores 閉店店舗を表示するか
   */
  const handleChangeGroupStore = useCallback(
    (group: ParamsStatus['store'], storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
      const updatedFilter = filter.merge({
        store: group,
        store_ids: storeIds,
        is_all_store_ids: isAllStores,
        show_closed_store: showClosedStores,
      });
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * フィルターを変更する
   */
  const handleChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      const updatedFilter = filter
        .set('start_date', startDate ? dayjs(startDate) : null)
        .set('end_date', endDate ? dayjs(endDate) : null);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  return (
    <Card className={className}>
      <FilterWrapper>
        <GroupStoreSelect
          group={filter.store}
          storeIds={filter.store_ids}
          showClosedStores={filter.show_closed_store}
          onChange={handleChangeGroupStore}
        />
        <ContentWrapper>
          <ContentLabel>投稿のタイプ</ContentLabel>
          <PullDown
            value={filter.topic_type}
            options={topicTypeOptions}
            onChange={(value: any) => handleChangeTopicType(value)}
          />
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>フリーワード検索</ContentLabel>
          <SearchField
            onSearchChange={handleChangeFreeWord}
            input={{ fluid: true }}
            placeholder='投稿に含まれる言葉'
            showNoResults={false}
            value={filter.free_word}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onCommitFilter();
              }
            }}
            icon={null}
          />
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>投稿日</ContentLabel>
          <RangeWrapper>
            <StyledDateRangePicker
              startDate={filter.start_date ? filter.start_date.toDate() : null}
              endDate={filter.end_date ? filter.end_date.toDate() : null}
              onChange={handleChangeDateRange}
              startPlaceholderText='開始日:未指定'
              endPlaceholderText='終了日:未指定'
              isClearable
            />
          </RangeWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>並び順</ContentLabel>
          <PullDown
            value={filter.sort_type}
            options={SortTypeOptions}
            onChange={(value: any) => handleChangeSortType(value)}
          />
        </ContentWrapper>

        {!disableCommitButton && (
          <SearchButtonWrapper>
            <SearchButton priority='high' onClick={onCommitFilter}>
              検索
            </SearchButton>
          </SearchButtonWrapper>
        )}
      </FilterWrapper>
    </Card>
  );
};

const FilterWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 3; /* タブ(z-index=2)とカルーセル(z-index=1)より上に表示する */
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0;
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const PullDown = styled(PullDownNarrow).attrs(() => ({ search: false }))`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;

const SearchButtonWrapper = styled(ContentWrapper)`
  margin-left: auto;
  padding-top: 28px;
`;

const SearchButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;

const SearchField = styled(Search)`
  width: 380px;
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
  }

  &&& {
    input {
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
      font-weight: bold;
      height: 33px;
    }
  }
`;

const RangeWrapper = styled.div`
  display: block;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  @media (min-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    ${DatePickerWrapper} {
      min-width: 176px;
    }
  }
`;
