import React from 'react';

import styled from 'styled-components';

import { PopupIcon } from 'components/molecules/PopupIcon';
import { convertNumberText } from 'helpers/utils';
import { COLOR } from 'style/color';

import { getDiffText } from '.';

type Props = {
  className?: string;
  label: string;
  value: number;
  diff?: number;
  currency?: boolean;
  prefix?: string;
  suffix?: string;
  digits?: number; // 小数点以下の桁数
  help?: React.ReactNode;
};

export const StatsCard = React.memo<Props>(
  ({ className, label, value, diff = undefined, currency = false, prefix, suffix, digits, help }) => {
    return (
      <Wrapper className={className}>
        <Label>
          {label}
          {help && <StyledPopupIcon header={label} content={help} />}
        </Label>
        <Value>
          {prefix}
          {currency && '¥'}
          {convertNumberText(digits !== undefined ? parseFloat(value.toFixed(digits)) : value)}
          {suffix}
        </Value>
        {diff != null && <Diff diff={diff}>{getDiffText(diff)}</Diff>}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid ${COLOR.LIGHT_GRAY};
  padding: 8px;
  background: ${COLOR.WHITE};
`;
const Label = styled.div`
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  @media (max-width: 700px) {
    white-space: pre-wrap;
  }
`;
const Value = styled.div`
  font-weight: bold;
  font-size: 24px;
  white-space: nowrap;
`;

const Diff = styled.div<{ diff: number }>`
  font-size: 16px;
  color: ${({ diff }) => (!isFinite(diff) ? COLOR.DARK_GRAY : diff >= 0 ? COLOR.GREEN : COLOR.RED)};
`;

export const LargeStatsCard = styled(StatsCard)`
  min-width: 200px;

  ${Label} {
    font-size: 16px;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ${Value} {
    font-size: 32px;
    line-height: 44px;
  }
  ${Diff} {
    font-size: 18px;
    line-height: 20px;
  }
`;

const StyledPopupIcon = styled(PopupIcon)`
  margin-left: 4px;
`;
