import React, { useCallback } from 'react';

import { List as ImmutableList } from 'immutable';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { InstagramAccount } from 'models/Domain/instagram';
import { COLOR } from 'style/color';

export type Props = {
  isLoading: boolean;
  accounts: ImmutableList<InstagramAccount>;
  onDelete: (id: number) => void;
  onClick: (id: number) => void;
};

export const InstagramIndexTable = React.memo<Props>(({ isLoading, accounts, onDelete, onClick }) => {
  const handleOnDelete = useCallback(
    (id: number) => {
      if (window.confirm('本当に連携解除しますか？')) {
        onDelete(id);
      }
    },
    [onDelete],
  );

  if (!isLoading && accounts.isEmpty()) {
    return (
      <Message onClick={() => onClick(1)}>
        連携されているInstagramアカウントがありません。
        <br />
        右上の「追加」ボタンから、Instagramアカウントを追加してください。
      </Message>
    );
  }

  return (
    <Wrapper>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>アカウント</TableHeaderCell>
            <TableHeaderCell>GBPへの投稿</TableHeaderCell>
            <TableHeaderCell />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {accounts.map((account) => (
            <StyledTableRow key={account.id} onClick={() => onClick(account.id)}>
              <TableCell>
                <FlexContainer>
                  <NameContainer>
                    <Name>{account.name}</Name>
                    <UserName>{account.userName}</UserName>
                  </NameContainer>
                </FlexContainer>
              </TableCell>
              <StatusCell>
                <StatusWrapper>
                  <StatusIcon type={account.isValid ? 'status_active' : 'status_error'} />
                  <StatusText>{account.isValid ? '連携中' : '停止中'}</StatusText>
                </StatusWrapper>
              </StatusCell>
              <MenuCell>
                <Dropdown trigger={<SettingsIcon />} icon={null}>
                  <Dropdown.Menu direction={'left'}>
                    <ActionMenuItem onClick={() => handleOnDelete(account.id)}>
                      <ActionItemLabel>連携解除</ActionItemLabel>
                    </ActionMenuItem>
                  </Dropdown.Menu>
                </Dropdown>
              </MenuCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {isLoading && (
        <>
          <LoadingWrapperBase />
          <LoadingWrapper>
            <Loader active={true} size={'big'} inline={true} />
          </LoadingWrapper>
        </>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
`;

const Name = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-right: 8px;
  word-break: break-all;
`;
const UserName = styled.span`
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
  word-break: break-all;
  &:before {
    content: '@';
    margin-right: 0.05em;
  }
`;

const NameContainer = styled.div``;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SettingsIcon = styled(Icon).attrs({
  type: 'settings',
})`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  margin-bottom: -4px;
  margin-left: 16px;
`;

export const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

export const ActionItemLabel = styled.div<{ disabled?: boolean }>`
  padding: 4px 8px;
  font-size: 14px;
  color: ${({ disabled = false }) => (disabled ? COLOR.GRAY : COLOR.BLACK)};
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
`;
const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  mix-blend-mode: hard-light;
`;

const Message = styled.div`
  padding: 16px 0;
`;

const StatusCell = styled(TableCell)`
  width: 120px;
`;

const MenuCell = styled(TableCell)`
  &&& {
    width: 24px;
  }
`;

const StatusText = styled.div`
  font-size: 13px;
  color: ${COLOR.CHARCOAL_GRAY};
  font-weight: normal;
`;

const StatusIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 4px;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
