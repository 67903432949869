import { Record, Set } from 'immutable';

import { numberComparator } from 'helpers/search';

// 有効期限の種類
export type ExpirationType = 'valid' | 'expiringSoon' | 'expired';

export type FilterType = {
  // グループの選択されている値
  store: number | 'all' | 'my_store' | null;
  // storeIds が store で指定されているグループのすべての店舗か
  isAllStoreIds: boolean;
  // 閉店店舗を表示するか
  showClosedStore: boolean;
  // 選択済の店舗ID
  storeIds: Set<number>;
  // 検索ワード
  searchValue: string;
  // 「有効期限」>「有効」の選択状態
  valid: boolean;
  // 「有効期限」>「期限切れ間近」の選択状態
  expiringSoon: boolean;
  // 「有効期限」>「有効期限切れ」の選択状態
  expired: boolean;
  // 在庫情報のある商品のみダウンロードするかどうか
  onlyInventoryExists: boolean;
};

/**
 * 在庫情報CSVダウンロードのリクエスト形式
 */
export type InventoryCsvDownloadParams = {
  store_ids: number[];
  query: string;
  local_inventory: boolean;
  local_inventory_status: string[];
};

/**
 * 在庫情報のCSVダウンロードの絞り込み条件
 */
export class InventoryCsvDownloadFilter extends Record<FilterType>({
  store: null,
  valid: true,
  expiringSoon: true,
  expired: true,
  storeIds: Set(),
  searchValue: '',
  isAllStoreIds: true,
  showClosedStore: false,
  onlyInventoryExists: false,
}) {
  /**
   * ユーザの入力した値が有効かどうか
   */
  isValid() {
    return this.storeIds.size > 0;
  }

  /**
   * 現在の有効期限の絞り込み条件から、有効期限ラベルを取得
   */
  getExpirationLabel() {
    const expiration = [];
    if ((this.valid && this.expiringSoon && this.expired) || (!this.valid && !this.expiringSoon && !this.expired)) {
      expiration.push('すべて');
    } else {
      if (this.valid) expiration.push('有効');
      if (this.expiringSoon) expiration.push('期限切れ間近');
      if (this.expired) expiration.push('有効期限切れ');
    }

    return expiration.join('、');
  }

  /**
   * CSVダウンロードAPI用のリクエストパラメータを生成する
   */
  toRequestParams(): InventoryCsvDownloadParams {
    const local_inventory_status = [];
    if (this.valid && this.expiringSoon && this.expired) {
      // すべて選択の場合、何もしない
    } else {
      // 1〜2つの有効期限を選択している場合、リクエストパラメータを設定
      if (this.valid) local_inventory_status.push('valid');
      if (this.expiringSoon) local_inventory_status.push('expiring_soon');
      if (this.expired) local_inventory_status.push('expired');
    }

    const params = {
      store_ids: this.storeIds.toArray().sort(numberComparator),
      query: this.searchValue,
      local_inventory: this.onlyInventoryExists,
      local_inventory_status,
    };

    return params;
  }
}
