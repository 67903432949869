import React, { useMemo, useState } from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import Menu from 'components/atoms/Menu';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import { MemoDisplaySettings, getMemoData } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { MemoList } from 'models/Domain/Memo/Memo';
import { SearchKeywordGraphData, getGraphTypeLabel } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SearchKeywordGraphType } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { YearMonth } from 'models/Domain/YearMonth';
import { COLOR } from 'style/color';

import { SearchKeywordsGraph as Graph } from './Graph';

interface Props {
  className?: string;
  graphData: SearchKeywordGraphData;
  graphType: SearchKeywordGraphType;
  onChangeGraphType: (graphType: SearchKeywordGraphType) => void;
  // FIXME: Propsで渡す値もYearMonthにする
  startMonth: string;
  endMonth: string;
  selectedMonth: string;
  onChangeSelectedMonth: (month: string) => void;
  isLoading?: boolean;
  memoList: MemoList;
  memoDisplaySettings: MemoDisplaySettings;
  onClickMemo: (ids: number[]) => void;
}

export const SearchKeywordsGraph: React.FC<Props> = ({
  className,
  graphData,
  graphType,
  onChangeGraphType,
  startMonth: _startMonth,
  endMonth: _endMonth,
  selectedMonth: _selectedMonth,
  onChangeSelectedMonth,
  isLoading = false,
  memoList,
  memoDisplaySettings,
  onClickMemo,
}) => {
  // ダウンロードモーダル関連
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

  // 設定モーダル
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState<boolean>(false);
  const [graphSettings, setGraphSettings] = useState(new GraphSettings());

  const startMonth = YearMonth.fromString(_startMonth);
  const endMonth = YearMonth.fromString(_endMonth);
  const selectedMonth = YearMonth.fromString(_selectedMonth);

  const memoData = useMemo(() => {
    return getMemoData(
      memoList,
      startMonth.toDayjs().startOf('month'),
      endMonth.toDayjs().endOf('month'),
      'month',
      memoDisplaySettings.displayToGraph,
    );
  }, [endMonth, memoDisplaySettings.displayToGraph, memoList, startMonth]);

  return (
    <Wrapper className={className}>
      <TitleContainer>
        <GraphTitle>{getGraphTypeLabel(graphType)}の推移</GraphTitle>
        <Menu>
          <Menu.Item
            name={'search_count'}
            active={graphType === 'search_count'}
            onClick={() => onChangeGraphType('search_count')}
          >
            {getGraphTypeLabel('search_count')}
          </Menu.Item>
          <Menu.Item
            name={'keyword_count'}
            active={graphType === 'keyword_count'}
            onClick={() => onChangeGraphType('keyword_count')}
          >
            {getGraphTypeLabel('keyword_count')}
          </Menu.Item>
        </Menu>
      </TitleContainer>
      <GraphWrapper>
        <Graph
          graphData={graphData}
          graphType={graphType}
          startMonth={startMonth}
          endMonth={endMonth}
          selectedMonth={selectedMonth}
          onChangeSelectedMonth={onChangeSelectedMonth}
          showReferenceLine={true}
          onClickMemo={onClickMemo}
          memoData={memoData}
          graphSettings={graphSettings}
        />

        {isLoading && (
          <LoadingWrapper>
            <Loader active={isLoading} size='big' inline={true} />
          </LoadingWrapper>
        )}
      </GraphWrapper>
      <ButtonWrapper>
        <StyledButton onClick={() => setIsOpenSettingsModal(true)}>グラフの表示設定</StyledButton>
        <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
      </ButtonWrapper>
      <GraphDownloadModal
        isOpen={isOpenDownloadModal}
        fileName={graphType}
        onClose={() => setIsOpenDownloadModal(false)}
      >
        <Graph
          graphData={graphData}
          graphType={graphType}
          startMonth={startMonth}
          endMonth={endMonth}
          selectedMonth={selectedMonth}
          showReferenceLine={false}
          graphSettings={graphSettings}
        />
      </GraphDownloadModal>
      {isOpenSettingsModal && (
        <GraphSettingsModal
          settings={graphSettings}
          isOpen={isOpenSettingsModal}
          onClose={() => setIsOpenSettingsModal(false)}
          onChange={setGraphSettings}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GraphTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledButton = styled(Button).attrs({ priority: 'low' })`
  font-size: 16px;
`;

const GraphWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
  position: relative;
`;
