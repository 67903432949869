import React from 'react';

import styled from 'styled-components';

import Grid from 'components/atoms/Grid';
import GridArea from 'components/atoms/GridArea';
import { useDisplayType } from 'hooks/useDisplayType';
import { GMCProductCounts } from 'models/Domain/Omo/common';
import { PRODUCT_STATUSES, ProductStatusStatus } from 'models/Domain/OmoInsight';

import { PRODUCT_STATUS_COLORS, PRODUCT_STATUS_LABELS } from '..';

import { ProgramStatusCard } from './ProgramStatusCard';

type Props = {
  className?: string;
  data: GMCProductCounts;
  selectedStatuses: ProductStatusStatus[];
  toggleStatus: (status: ProductStatusStatus) => void;
};

export const ProductStatusStatusList = React.memo<Props>(({ className, data, selectedStatuses, toggleStatus }) => {
  // PC表示・SP表示のGridの設定
  const isDesktop = useDisplayType('1100px').isDesktop;
  const pcColumns = ['1fr', '1fr', '1fr', '1fr'];
  const spColumns = ['1fr', '1fr'];
  const pcRows = ['1fr'];
  const spRows = ['1fr'];
  // areasは有効な掲載プログラム名のリストで、4つに満たない分は"."で埋める
  // PCは1列に4つ表示し、SPは2列に2つずつ表示する
  const areas = PRODUCT_STATUSES;
  const pcAreas = [areas];
  const spAreas = new Array(2).fill([]).map((_, i) => areas.slice(i * 2, i * 2 + 2));

  const total = PRODUCT_STATUSES.reduce((acc, status) => acc + data[status], 0);

  return (
    <Wrapper className={className}>
      <CardContainer
        columns={isDesktop ? pcColumns : spColumns}
        rows={isDesktop ? pcRows : spRows}
        areas={isDesktop ? pcAreas : spAreas}
      >
        {PRODUCT_STATUSES.map((status) => {
          const value = data[status];
          const ratio = total ? value / total : null;
          const selected = selectedStatuses.includes(status);
          return (
            <GridArea key={status} name={status}>
              <CardWrapper selected={selected} onClick={() => toggleStatus(status)}>
                <ProgramStatusCard
                  label={PRODUCT_STATUS_LABELS[status]}
                  value={value}
                  ratio={ratio}
                  selected={selected}
                  color={PRODUCT_STATUS_COLORS[status]}
                  showRatio={true}
                  showIcon={true}
                />
              </CardWrapper>
            </GridArea>
          );
        })}
      </CardContainer>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const CardContainer = styled(Grid)`
  grid-gap: 8px 0;
  margin-bottom: 8px;
  background: none;
`;

const CardWrapper = styled.div<{ selected: boolean }>`
  height: 100%;
  cursor: pointer;
`;
