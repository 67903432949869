import React from 'react';

import ja from 'date-fns/locale/ja';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import { COLOR } from 'style/color';

registerLocale('ja', ja);

export type DatePickerProps = { className?: string } & React.ComponentProps<typeof ReactDatePicker>;

const _ReactDatePicker = ReactDatePicker as any;

export const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const {
    className,
    calendarStartDay = 1, // デフォルトは月曜始まり
    ...restProps
  } = props;

  return (
    <Wrapper className={className}>
      <_ReactDatePicker locale={'ja'} calendarStartDay={calendarStartDay} {...restProps} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .date_picker_error > .react-datepicker__input-container > input {
    border-color: ${COLOR.ERROR} !important;
  }

  .date_picker_inactive > .react-datepicker__input-container > input {
    background: ${COLOR.LIGHT_GRAY} !important;
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 13px;
    border: 1px solid ${COLOR.BLACK};
    text-align: center;
    width: 100%;
    height: 33px;
    border-radius: 8px;
    border-color: ${COLOR.GRAY};
    font-family: monospace !important;

    &:focus {
      border-color: ${COLOR.GREEN};
      outline: ${COLOR.GREEN};
    }

    &:disabled {
      color: ${COLOR.GRAY};
      border-color: ${COLOR.LIGHT_GRAY};
      outline: ${COLOR.LIGHT_GRAY};
      background: #f8f8f8;
    }
  }

  .react-datepicker__time-list-item {
    color: ${COLOR.BLACK};
    background-color: ${COLOR.WHITE};
    font-family: monospace;
  }

  .react-datepicker__current-month {
    font-family: monospace !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    color: ${COLOR.WHITE};
    background-color: ${COLOR.GREEN};
  }

  .react-datepicker__day {
    font-family: monospace !important;
  }

  .react-datepicker__day--selected {
    color: ${COLOR.WHITE};
    background-color: ${COLOR.GREEN};
  }

  .react-datepicker__day--in-selecting-range {
    color: ${COLOR.BLACK};
    background-color: ${COLOR.LIGHT_GREEN};
  }

  .react-datepicker__day--in-range {
    color: ${COLOR.WHITE};
    background-color: ${COLOR.GREEN};
  }

  .react-datepicker__close-icon::after {
    background-color: ${COLOR.GRAY};
  }
`;
