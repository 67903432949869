import React, { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { List, Set } from 'immutable';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { getCategoryOptions } from 'models/Domain/Image/Image';
import ImageSearchCondition, { GBPState } from 'models/Domain/Image/ImageSearchCondition';
import { COLOR } from 'style/color';
import { SelectOption } from 'types/Common';

type SortType = 'date_desc' | 'date_asc';
const SortTypeOptions: SelectOption<SortType>[] = [
  {
    text: '登録日が新しい順',
    value: 'date_desc',
  },
  {
    text: '登録日が古い順',
    value: 'date_asc',
  },
];

const pageLimiOptions: SelectOption<number>[] = [
  {
    text: '20',
    value: 20,
  },
  {
    text: '50',
    value: 50,
  },
  {
    text: '100',
    value: 100,
  },
];

type ImageFilterProps = {
  className?: string;
  filter: ImageSearchCondition;
  hashtagList: List<string>;
  onChangeFilter: (filter: ImageSearchCondition) => void;
  onCommitFilter: () => void;
  disableCommitButton?: boolean;
};

export const ImageFilter: React.FC<ImageFilterProps> = ({
  className,
  filter,
  hashtagList,
  onChangeFilter,
  onCommitFilter,
  disableCommitButton,
}) => {
  /**
   * グループ、店舗のフィルターを変更する
   * @param group グループ
   * @param storeIds 店舗のID
   * @param isAllStores すべての店舗が選択されているか
   * @param showClosedStores 閉店店舗を表示するか
   */
  const handleChangeGroupStore = useCallback(
    (group: ImageSearchCondition['store'], storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
      const updatedFilter = filter.merge({
        store: group,
        storeIds: storeIds,
        isAllStores: isAllStores,
        showClosedStores: showClosedStores,
      });
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * GBP掲載状態を変更する
   * @param label 変更対象の状態
   * @param value 変更内容(true:選択、false:未選択)
   */
  const handleChangeGBPState = useCallback(
    (label: GBPState, value: boolean) => {
      let updatedStates: Set<GBPState>;
      if (value === true) {
        updatedStates = filter.gbpState.add(label);
      } else {
        updatedStates = filter.gbpState.delete(label);
      }
      onChangeFilter(filter.set('gbpState', updatedStates));
    },
    [filter, onChangeFilter],
  );

  /**
   * 写真登録日を変更する
   * @param startDate 開始日
   * @param endDate 終了日
   */
  const handleChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      const updatedFilter = filter.merge({
        startDate: startDate ? dayjs(startDate) : null,
        endDate: endDate ? dayjs(endDate) : null,
      });

      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * タグを変更する
   */
  const handleChangeTag = useCallback(
    (tag: string) => {
      const updatedFilter = filter.set('tag', tag || '');
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  /**
   * 並び順を変更する
   */
  const handleChangeSortType = useCallback(
    (sortType: SortType) => {
      let updatedFilter: ImageSearchCondition;
      switch (sortType) {
        case 'date_desc':
          updatedFilter = filter.merge({ sortKey: 'date', sortOrder: 'desc' });
          break;
        case 'date_asc':
          updatedFilter = filter.merge({ sortKey: 'date', sortOrder: 'asc' });
          break;
      }
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  const handleChangePerPage = useCallback(
    (value: number) => {
      const updatedFilter = filter.set('perPage', value);
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );

  const tagOptions: SelectOption<string>[] = useMemo(
    () => hashtagList.map((tag) => ({ text: tag, value: tag })).toArray(),
    [hashtagList],
  );

  // GBP掲載状態のラベル
  const gbpStateLabel = useMemo(() => {
    if (filter.gbpState.has('published') && !filter.gbpState.has('unpublished')) {
      return '掲載中';
    }

    if (!filter.gbpState.has('published') && filter.gbpState.has('unpublished')) {
      return '未掲載';
    }

    return 'すべて';
  }, [filter]);

  /**
   * カテゴリを変更する
   */
  const handleChangeCategory = useCallback(
    (category: string) => {
      const updatedFilter = filter.set('category', category || '');
      onChangeFilter(updatedFilter);
    },
    [filter, onChangeFilter],
  );
  // カテゴリのラベル
  const categoryOptions = useMemo(() => getCategoryOptions({ currentCategory: null }), []);

  return (
    <Card className={className}>
      <FilterWrapper>
        <GroupStoreSelect
          group={filter.store}
          storeIds={filter.storeIds}
          showClosedStores={filter.showClosedStores}
          onChange={handleChangeGroupStore}
        />

        <ContentWrapper>
          <ContentLabel>GBP掲載</ContentLabel>
          <NormalMultiSelectBox value={gbpStateLabel}>
            <SearchContent>
              <CheckLabel
                onChange={() => handleChangeGBPState('published', !filter.gbpState.has('published'))}
                checked={filter.gbpState.has('published')}
                label='掲載中'
              />
            </SearchContent>
            <SearchContent>
              <CheckLabel
                onChange={() => handleChangeGBPState('unpublished', !filter.gbpState.has('unpublished'))}
                checked={filter.gbpState.has('unpublished')}
                label='未掲載'
              />
            </SearchContent>
          </NormalMultiSelectBox>
        </ContentWrapper>

        <ContentWrapper>
          <ContentLabel>写真登録日</ContentLabel>
          <StyledDateRangePicker
            startDate={filter.startDate?.toDate() ?? null}
            endDate={filter.endDate?.toDate() ?? null}
            onChange={handleChangeDateRange}
            startPlaceholderText='開始日:未指定'
            endPlaceholderText='終了日:未指定'
            isClearable
          />
        </ContentWrapper>

        <ContentWrapper>
          <ContentLabel>カテゴリー</ContentLabel>
          <PullDown
            value={filter.category}
            options={categoryOptions}
            onChange={handleChangeCategory}
            placeholder={'カテゴリー'}
            clearable
          />
        </ContentWrapper>

        <ContentWrapper>
          <ContentLabel>タグ</ContentLabel>
          <PullDown
            value={filter.tag}
            options={tagOptions}
            onChange={handleChangeTag}
            placeholder='写真に付与されたタグ'
            clearable
          />
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>並び順 </ContentLabel>
          <PullDown
            value={`${filter.sortKey}_${filter.sortOrder}`}
            options={SortTypeOptions}
            onChange={handleChangeSortType}
          />
        </ContentWrapper>
        <ContentWrapper>
          <ContentLabel>１ページの写真表示数 </ContentLabel>
          <PullDown value={filter.perPage} options={pageLimiOptions} onChange={handleChangePerPage} />
        </ContentWrapper>

        <SearchButtonWrapper>
          <SearchButton priority='high' onClick={onCommitFilter} disabled={disableCommitButton}>
            検索
          </SearchButton>
        </SearchButtonWrapper>
      </FilterWrapper>
    </Card>
  );
};

const FilterWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  margin: 8px 27px 8px 0px;
  @media (max-width: 774px) {
    width: 100%;
    margin: 8px 0px;
  }
`;

const ContentLabel = styled.div`
  height: 28px;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;

    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;

      > .divider {
         {
          white-space: nowrap;
          overflow: hidden;
          margin-right: 20px;
        }
      }
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: 774px) {
      width: 100%;
    }
  }
`;

const SearchButtonWrapper = styled(ContentWrapper)`
  margin-left: auto;
  padding-top: 28px;
`;

const SearchButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    box-shadow: none;

    @media (max-width: 774px) {
      width: 100%;
    }
  }
`;

const NormalMultiSelectBox = styled(MultiSelectBox)`
  width: 176px;
  @media (max-width: 774px) {
    width: 100%;
  }
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

const SearchContent = styled.div`
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  width: 380px;
  @media (max-width: 774px) {
    width: 100%;
  }

  &&& {
    & input {
      padding-left: 8px;
      text-align: left;
    }
  }
`;
