import React, { useMemo, useState } from 'react';

import { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { GuideOverlay } from 'components/molecules/GuideOverlay';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import { getCurrentMonthStartDay, getCurrentWeekStartDay } from 'helpers/date';
import { MemoDisplaySettings, getMemoData } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { MapSearchRankGraphData } from 'models/Domain/MapSearchRank/MapSearchRank';
import { AggregateUnit } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { MemoList } from 'models/Domain/Memo/Memo';
import { COLOR } from 'style/color';

import { MapSearchRankGraph as Graph } from './Graph';

export const INACTIVE_TRANSPARENCY = 0.8;

interface Props {
  className?: string;
  isLoading: boolean;
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs;
  comparisonEndDate: Dayjs;
  isEnabledComparison: boolean;
  graphData: MapSearchRankGraphData;
  memoList: MemoList;
  memoDisplaySettings: MemoDisplaySettings;
  onClickMemo: (ids: number[]) => void;
  onClickLegendAverage?: () => void;
  onClickLegendConfig?: (configId: number) => void;
}

/** 指定された期間からデータのサイズを取得する */
export const getDataSize = (startDate: Dayjs, endDate: Dayjs, aggregateUnit: AggregateUnit): number => {
  switch (aggregateUnit) {
    case 'day':
      return endDate.diff(startDate, 'day') + 1;
    case 'week':
      return getCurrentWeekStartDay(endDate).diff(getCurrentWeekStartDay(startDate), 'week') + 1;
    case 'month':
      return getCurrentMonthStartDay(endDate).diff(getCurrentMonthStartDay(startDate), 'month') + 1;
  }
};

export const MapSearchRankGraph = React.memo<Props>(
  ({
    className,
    isLoading,
    isEnabledComparison,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    graphData,
    aggregateUnit,
    memoList,
    memoDisplaySettings,
    onClickMemo,
    onClickLegendAverage,
    onClickLegendConfig,
  }) => {
    // ダウンロードモーダル関連
    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

    // 設定モーダル
    const [isOpenSettingsModal, setIsOpenSettingsModal] = useState<boolean>(false);
    const [graphSettings, setGraphSettings] = useState(new GraphSettings({ min: 'auto', max: 'auto' }));

    // データのkeyのリスト
    const itemKeys = useMemo(() => {
      // 「店舗名×キーワード」を追加
      let result = graphData.items.list.map((item) => item.key);
      // 平均が含まれる場合は「平均」を追加
      if (graphData.average && graphData.average.stats.size > 0) {
        result = result.insert(0, '平均');
      }
      return result;
    }, [graphData]);

    const memoData = useMemo(
      () => getMemoData(memoList, startDate, endDate, aggregateUnit, memoDisplaySettings.displayToGraph),
      [startDate, endDate, aggregateUnit, memoDisplaySettings.displayToGraph, memoList],
    );

    return (
      <Wrapper className={className}>
        <Title>掲載順位</Title>
        <GraphWrapper>
          <Graph
            aggregateUnit={aggregateUnit}
            startDate={startDate}
            endDate={endDate}
            comparisonStartDate={comparisonStartDate}
            comparisonEndDate={comparisonEndDate}
            isEnabledComparison={isEnabledComparison}
            graphData={graphData}
            onClickMemo={onClickMemo}
            onClickLegendAverage={onClickLegendAverage}
            onClickLegendConfig={onClickLegendConfig}
            memoData={memoData}
            graphSettings={graphSettings}
          />
          {isLoading && (
            <LoadingWrapper>
              <Loader active={isLoading} size={'big'} inline={true} />
            </LoadingWrapper>
          )}
          {!isLoading && itemKeys.isEmpty() && <GuideOverlay>表示するデータを選択してください</GuideOverlay>}
        </GraphWrapper>
        <ButtonWrapper>
          <StyledButton onClick={() => setIsOpenSettingsModal(true)}>グラフの表示設定</StyledButton>
          <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
        </ButtonWrapper>
        <GraphDownloadModal
          isOpen={isOpenDownloadModal}
          fileName={'MapSearchRank'}
          onClose={() => setIsOpenDownloadModal(false)}
        >
          <Graph
            aggregateUnit={aggregateUnit}
            startDate={startDate}
            endDate={endDate}
            comparisonStartDate={comparisonStartDate}
            comparisonEndDate={comparisonEndDate}
            isEnabledComparison={isEnabledComparison}
            graphData={graphData}
            graphSettings={graphSettings}
          />
        </GraphDownloadModal>
        {isOpenSettingsModal && (
          <GraphSettingsModal
            settings={graphSettings}
            isOpen={isOpenSettingsModal}
            onClose={() => setIsOpenSettingsModal(false)}
            onChange={setGraphSettings}
          />
        )}
      </Wrapper>
    );
  },
);

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;

export const GraphWrapper = styled.div`
  position: relative;
  font-size: 12px;
  font-weight: bold;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`;

export const StyledButton = styled(Button).attrs({ priority: 'low' })`
  font-size: 16px;
`;
