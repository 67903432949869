import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Awaited } from 'ApiClient';
import { StoreCheckApi } from 'ApiClient/StoreApi';
import { GbpChecklist as Checklist } from 'models/Domain/GbpChecklist';
import { getInitialStores } from 'modules/utils';

import { TopActions as Actions } from './actions';
import { TopSelectors as Selectors } from './selectors';

import { CONTAINS_CLOSED_STORES, INITIAL_GROUP, ONLY_GBP_CONNECTED_STORES, ONLY_MY_STORE } from '.';

export default function* saga() {
  yield takeLatest(Actions.initializePage, initializePage);
  yield takeLatest(Actions.commitStoreIds, commitStoreIds);
}

function* initializePage(action: ReturnType<typeof Actions.initializePage>) {
  const storeIds: Set<number> = yield call(getInitialStores, {
    group: INITIAL_GROUP,
    containsClosedStores: CONTAINS_CLOSED_STORES,
    onlyGbpConnectedStores: ONLY_GBP_CONNECTED_STORES,
    onlyMyStore: ONLY_MY_STORE,
  });

  yield put(Actions.setGroup(INITIAL_GROUP));
  yield put(Actions.setStoreIds(storeIds));
  yield put(Actions.commitStoreIds());
}

function* commitStoreIds(action: ReturnType<typeof Actions.commitStoreIds>) {
  const storeIds: YieldReturn<typeof Selectors.getStoreIds> = yield select(Selectors.getStoreIds);
  if (storeIds.isEmpty()) {
    return;
  }

  const response: Awaited<ReturnType<typeof StoreCheckApi.get>> = yield StoreCheckApi.get({
    store_ids: storeIds.sort().join(','),
  });

  if (!response.isSuccess) {
    toast({
      type: 'error',
      title: '運用状況が取得できませんでした',
      description: String(response.error.message),
      time: 10000,
    });
    return;
  }

  const checklist = Checklist.fromJSON(response.data);
  yield put(Actions.setChecklist(checklist));
}
