import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { Tab, TabContainer } from 'components/atoms/Tab';
import { GbpPerformanceMAAccountTable } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAAccountTable';
import { GbpPerformanceMACsvDownloadModal } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMACsvDownloadModal';
import { GbpPerformanceMAGraph, GraphType } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAGraph';
import { GbpPerformanceMAMonthlyTable } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAMonthlyTable';
import { GbpPerformanceMASearchCondition } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMASearchCondition';
import { GbpPerformanceMAStatsComparisonTable } from 'components/pageComponents/GbpPerformanceMA/GbpPerformanceMAStatsComparisonTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { ComparisonGraphType } from 'helpers/graph';
import { getPageTitle } from 'helpers/utils';
import { useDisplayType } from 'hooks/useDisplayType';
import { useHoldOn } from 'hooks/useHoldOn';
import { useStorage } from 'hooks/useStorage';
import {
  DISPLAY_TYPES,
  DisplayType,
  GbpPerformanceMAActiveStats,
  InteractionType,
  ReviewType,
  StatsType,
  displayTypeLabel,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { GbpPerformanceMACsvDownloadCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMACsvDownloadCondition';
import {
  AggregateMethod,
  GbpPerformanceMASearchCondition as SearchCondition,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMASearchCondition';
import { YearMonth } from 'models/Domain/YearMonth';
import { GbpPerformanceMAActions } from 'modules/gbpPerformanceMA/actions';
import { assertNever } from 'types/Common';

// タブごとのグラフに表示する項目
const getAvailableDisplayStats = (displayType: DisplayType, reviewType: ReviewType = 'period'): StatsType[] => {
  switch (displayType) {
    case 'overview':
      return ['impressions', 'interactions', 'periodReviews'];
    case 'impressions':
      return ['desktopMaps', 'mobileMaps', 'desktopSearch', 'mobileSearch', 'interactions'];
    case 'interactions':
      return ['impressions', 'callClicks', 'directionRequests', 'websiteClicks', 'conversations', 'bookings'];
    case 'activities':
      return reviewType === 'total'
        ? ['totalCommentCount', 'totalRateCount', 'totalReplyCount', 'totalAverageRating']
        : ['periodCommentCount', 'periodRateCount', 'periodReplyCount', 'periodAverageRating'];
    default:
      return assertNever(displayType);
  }
};

const displayGraphTypes = (displayType: DisplayType): GraphType[] => {
  switch (displayType) {
    case 'overview':
      return ['overview'];
    case 'impressions':
      return ['impressions'];
    case 'interactions':
      return ['interactions'];
    case 'activities':
      return ['reviews'];
    default:
      return assertNever(displayType);
  }
};

export const GbpPerformanceMA = React.memo(() => {
  const {
    isLoadingData,
    isLoadingComparisonData,
    isLoadingMonthlyData,
    graphData,
    tableData,
    monthlyData,
    searchCondition: _searchCondition,
    isInitializedSearchCondition,
    csvDownloadCondition,
  } = useSelector((state) => state.gbpPerformanceMA);

  const searchCondition = useMemo(() => _searchCondition || new SearchCondition(), [_searchCondition]);
  const displayType = searchCondition.filter.displayType;

  const [interactionType, setInteractionType] = useState<InteractionType>('value');
  const [reviewType, setReviewType] = useStorage<ReviewType>('GBP_PERFORMANCE_REVIEW_TYPE', 'period');

  const prevReviewType = useRef<ReviewType>(reviewType);
  const [activeStats, setActiveStatsTypes] = useState<GbpPerformanceMAActiveStats>(
    GbpPerformanceMAActiveStats.getDefaultByReviewType(reviewType),
  );

  const { accountList } = useSelector((state) => state.app);

  const displayStats = useMemo(() => {
    return ImmutableList(getAvailableDisplayStats(displayType, reviewType))
      .filter((x) => activeStats.get(displayType).includes(x))
      .toArray();
  }, [activeStats, displayType, reviewType]);

  const isMobile = useDisplayType('1100px').isMobile;

  const dispatch = useDispatch();

  const { initializePage, commitSearchCondition, downloadCsv, setCsvDownloadCondition } = useMemo(
    () => bindActionCreators(GbpPerformanceMAActions, dispatch),
    [dispatch],
  );

  const [isOpenCsvDownloadModal, setIsOpenCsvDownloadModal] = useState<boolean>(false);

  const isCommitDisabled = useHoldOn(isLoadingData || isLoadingComparisonData || isLoadingMonthlyData, 1000);

  const onChangeDisplayType = useCallback(
    (displayType: DisplayType) => {
      const newSearchCondition = searchCondition.setIn(['filter', 'displayType'], displayType);
      commitSearchCondition(newSearchCondition);
    },
    [commitSearchCondition, searchCondition],
  );

  const onChangeInteractionType = useCallback((interactionType: InteractionType) => {
    setInteractionType(interactionType);
  }, []);

  const onChangeReviewType = useCallback(
    (reviewType: ReviewType) => {
      setReviewType(reviewType);
      // periodからtotalに変更した際に、periodReviewの設定を引き継ぐ
      if (prevReviewType.current === 'period' && reviewType === 'total') {
        setActiveStatsTypes((activeStats) => activeStats.replaceReviewType('period', 'total'));
      }
      // totalからperiodに変更した際に、totalReviewの設定を引き継ぐ
      if (prevReviewType.current === 'total' && reviewType === 'period') {
        setActiveStatsTypes((activeStats) => activeStats.replaceReviewType('total', 'period'));
      }
      prevReviewType.current = reviewType;
    },
    [setReviewType],
  );

  const onChangeAggregateMethod = useCallback(
    (aggregateMethod: AggregateMethod) => {
      const newSearchCondition = searchCondition.setIn(['filter', 'aggregateMethod'], aggregateMethod);
      commitSearchCondition(newSearchCondition);
    },
    [commitSearchCondition, searchCondition],
  );

  const onChangeActiveStats = useCallback(
    (value: StatsType) => {
      setActiveStatsTypes((activeStats) => activeStats.change(displayType, value));
    },
    [displayType],
  );

  const onChangeTargetMonth = useCallback(
    (value: string) => {
      // 不正な文字列なら何もしない
      if (!YearMonth.isMatchFormat(value)) {
        return;
      }
      const newSearchCondition = searchCondition.setIn(['filter', 'targetMonth'], YearMonth.fromString(value));
      commitSearchCondition(newSearchCondition);
    },
    [commitSearchCondition, searchCondition],
  );

  /** CSVダウンロードモーダルを開く */
  const onClickOpenCsvDownloadModal = useCallback(() => {
    setCsvDownloadCondition(GbpPerformanceMACsvDownloadCondition.fromSearchCondition(searchCondition));
    setIsOpenCsvDownloadModal(true);
  }, [searchCondition, setCsvDownloadCondition]);

  /** CSVダウンロードの開始 */
  const onStartCsvDownload = useCallback(() => {
    downloadCsv();
    setIsOpenCsvDownloadModal(false);
  }, [downloadCsv, setIsOpenCsvDownloadModal]);

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  // 比較期間のグラフのタイプ
  const [comparisonGraphType, setComparisonGraphType] = useState<ComparisonGraphType>('combined');

  useEffect(() => {
    const { startDate, comparisonEndDate } = searchCondition.filter;
    // 対象期間と比較期間が連続していない場合は、比較期間のグラフの種類をseparatedに変更する
    if (comparisonGraphType !== 'combined') {
      return;
    } else if (startDate.isSame(comparisonEndDate.add(1, 'day'))) {
      return;
    } else {
      setComparisonGraphType('separated');
    }
  }, [comparisonGraphType, searchCondition.filter, setComparisonGraphType]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('GBPパフォーマンス')} />
      <StickyHeader title={'GBPパフォーマンス'} />
      <WideBody>
        {isMobile && (
          <Alert type={'info'}>
            <Alert.Title>このページはPCでの利用を推奨しております</Alert.Title>
            <Alert.Section>
              <Alert.Content>
                スマートフォンやタブレットなど狭い画面では正しく表示されない場合がありますのでご了承ください。
              </Alert.Content>
            </Alert.Section>
          </Alert>
        )}
        <GbpPerformanceMASearchCondition
          // URLから検索条件の初期化が完了してから検索条件を刷新するようにkeyを切り替える
          key={isInitializedSearchCondition ? 1 : 0}
          isCommitDisabled={isCommitDisabled}
          committedSearchCondition={searchCondition}
          onCommitSearchCondition={commitSearchCondition}
        />
        <DownloadButtonWrapper>
          <DownloadButton onClick={onClickOpenCsvDownloadModal}>CSVダウンロード</DownloadButton>
        </DownloadButtonWrapper>
        <TabContainer>
          {DISPLAY_TYPES.map((dt) => (
            <Tab key={dt} active={displayType === dt} onClick={() => onChangeDisplayType(dt)}>
              {displayTypeLabel[dt]}
            </Tab>
          ))}
        </TabContainer>
        {displayGraphTypes(displayType).map((graphType) => (
          <GbpPerformanceMAGraph
            key={graphType}
            isLoading={isLoadingData || isLoadingComparisonData}
            startDate={searchCondition.filter.startDate}
            endDate={searchCondition.filter.endDate}
            comparisonStartDate={searchCondition.filter.comparisonStartDate}
            comparisonEndDate={searchCondition.filter.comparisonEndDate}
            aggregateUnit={searchCondition.filter.aggregateUnit}
            isEnabledComparison={searchCondition.filter.isEnabledComparison}
            graphData={graphData}
            graphType={graphType}
            displayStats={displayStats}
            interactionType={interactionType}
            reviewType={reviewType}
            aggregateMethod={searchCondition.filter.aggregateMethod}
            onChangeInteractionType={onChangeInteractionType}
            onChangeReviewType={onChangeReviewType}
            onChangeAggregateMethod={onChangeAggregateMethod}
            onChangeActiveStats={onChangeActiveStats}
            comparisonGraphType={comparisonGraphType}
            setComparisonGraphType={setComparisonGraphType}
          />
        ))}
        {searchCondition.filter.isEnabledComparison ? (
          <GbpPerformanceMAStatsComparisonTable
            data={tableData}
            displayType={displayType}
            searchCondition={searchCondition}
            onChangeDisplayType={onChangeDisplayType}
            isLoading={isLoadingData || isLoadingComparisonData}
            onChangeActiveStats={onChangeActiveStats}
            activeStats={activeStats[displayType]}
            interactionType={interactionType}
            reviewType={reviewType}
          />
        ) : (
          <GbpPerformanceMAMonthlyTable
            data={monthlyData}
            displayType={displayType}
            onChangeDisplayType={onChangeDisplayType}
            isLoading={isLoadingMonthlyData}
            onChangeActiveStats={onChangeActiveStats}
            activeStats={activeStats[displayType]}
            interactionType={interactionType}
            reviewType={reviewType}
            searchCondition={searchCondition}
            onChangeTargetMonth={onChangeTargetMonth}
          />
        )}
        <GbpPerformanceMAAccountTableWrapper>
          <GbpPerformanceMAAccountTable
            data={tableData}
            organizations={accountList}
            displayType={displayType}
            searchCondition={searchCondition}
            isLoading={isLoadingData || isLoadingComparisonData}
            reviewType={reviewType}
            aggregateMethod={searchCondition.filter.aggregateMethod}
            onChangeReviewType={onChangeReviewType}
            onChangeAggregateMethod={onChangeAggregateMethod}
          />
        </GbpPerformanceMAAccountTableWrapper>
      </WideBody>
      <GbpPerformanceMACsvDownloadModal
        isOpen={isOpenCsvDownloadModal}
        onClose={() => setIsOpenCsvDownloadModal(false)}
        condition={csvDownloadCondition}
        setCondition={setCsvDownloadCondition}
        onStartCsvDownload={onStartCsvDownload}
      />
    </MainWrapper>
  );
});

const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const DownloadButton = styled(Button).attrs({ priority: 'low' })``;

const GbpPerformanceMAAccountTableWrapper = styled.div`
  &&& {
    thead {
      top: 142px;
    }
  }
`;
