import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest } from '@aws-sdk/protocol-http';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';
import axios, { AxiosAdapter, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

import CONFIG from 'config';
import { AccountSingleton } from 'models/Domain/AccountList';

export type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

export type ApiSuccessResponse<T> = {
  isSuccess: true;
  status: number;
  headers: any;
  data: T;
};

export type ApiFailedResponse<U> = {
  isSuccess: false;
  status?: number;
  headers?: any;
  error: AxiosError<U>;
};

export type LambdaFunctionApiFailedResponse<U> = {
  isSuccess: false;
  status?: number;
  headers?: any;
  error: U;
};

export type ApiResponse<T, U = null> = ApiSuccessResponse<T> | ApiFailedResponse<U>;

export default class ApiClient {
  axiosInstance: AxiosInstance;
  constructor({
    baseURL = CONFIG.API_ENDPOINT,
    headers = {},
    useToken = true,
    useCurrentOrganization = true,
    timeout = 30000,
  }) {
    const config: AxiosRequestConfig = {
      timeout,
      baseURL,
      headers,
      // キャッシュ設定を追加（デフォルトは利用しない）
      adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { enabledByDefault: false }),
    };
    this.axiosInstance = axios.create(config);

    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        if (useToken) {
          try {
            const data = await Auth.currentSession();
            const idToken = data.getIdToken().getJwtToken();
            if (config.headers) {
              config.headers.Authorization = idToken;
            }
          } catch (e) {
            console.warn(e);
          }
        }

        if (useCurrentOrganization) {
          // 現在ログイン中のアカウントのorganizationIdをヘッダーに含める
          const currentOrganizationId = AccountSingleton.getInstance().organizationId;
          if (currentOrganizationId) {
            if (config.headers) {
              config.headers['X-Storecast-Organization'] = currentOrganizationId;
            }
          }
        }
        return config;
      },
      (err: AxiosError) => {
        return Promise.reject(err);
      },
    );
  }
  async get<T, U = null>(
    path = '',
    params = {},
    options: { cache?: boolean } = {},
  ): Promise<ApiSuccessResponse<T> | ApiFailedResponse<U>> {
    try {
      const response = await this.axiosInstance.get(path, { params, ...options });
      return this.createSuccessPromise<T>(response);
    } catch (e: any) {
      return this.createFailurePromise<U>(e);
    }
  }
  async post<T, U = null>(path: string, params = {}) {
    try {
      const response = await this.axiosInstance.post<T>(path, params);
      return this.createSuccessPromise<T>(response);
    } catch (e: any) {
      return this.createFailurePromise<U>(e);
    }
  }
  async put<T, U = null>(path: string, params = {}) {
    try {
      const response = await this.axiosInstance.put<T>(path, params);
      return this.createSuccessPromise<T>(response);
    } catch (e: any) {
      return this.createFailurePromise<U>(e);
    }
  }
  async delete<T, U = null>(path: string) {
    try {
      const response = await this.axiosInstance.delete(path);
      return this.createSuccessPromise<T>(response);
    } catch (e: any) {
      return this.createFailurePromise<U>(e);
    }
  }
  async patch<T, U = null>(path: string, params = {}) {
    try {
      const response = await this.axiosInstance.patch<T>(path, params);
      return this.createSuccessPromise<T>(response);
    } catch (e: any) {
      return this.createFailurePromise<U>(e);
    }
  }
  private createSuccessPromise<T>(response: AxiosResponse<T>): Promise<ApiSuccessResponse<T>> {
    return Promise.resolve({
      data: response.data,
      status: response.status,
      headers: response.headers,
      isSuccess: true as const,
    });
  }
  private createFailurePromise<U>(error: AxiosError<U>): Promise<ApiFailedResponse<U>> {
    Sentry.withScope((scope) => {
      scope.setExtra('axiosError', error);
      Sentry.captureException(error);
    });
    return Promise.resolve({
      error,
      status: error.response?.status,
      headers: error.response?.headers,
      isSuccess: false as const,
    });
  }
}

// storecast-faas-apiのAPI Gatewayに向いたAPIクライアント
export class FaasApiClient extends ApiClient {
  constructor({ headers = {}, useToken = true, useCurrentOrganization = true, timeout = 30000 }) {
    super({ baseURL: CONFIG.FAAS_API_ENDPOINT, headers, useToken, useCurrentOrganization, timeout });
  }
}

// デモ環境のデータリセット用のAPI Gatewayに向いたAPIクライアント
export class DataResetApiClient extends ApiClient {
  constructor({ headers = {}, useToken = true, useCurrentOrganization = true, timeout = 30000 }) {
    super({ baseURL: CONFIG.DATA_RESET_API_ENDPOINT, headers, useToken, useCurrentOrganization, timeout });
  }
}
export const createResponse = <T>(data: T): Response<T> => {
  return {
    isSuccess: true,
    data,
  };
};

export const createErrorResponse = <U = { message: string }>(error: U): ErrorResponse<U> => {
  return {
    isSuccess: false,
    error,
  };
};

export type Response<T> = {
  isSuccess: true;
  data: T;
};

export type ErrorResponse<U = { message: string }> = {
  isSuccess: false;
  error: U;
};

/**
 * Lambda Function URLにより公開されたAPIのクライアント
 */
export class LambdaFunctionApiClient {
  endpoint = '';
  useToken = true;
  useCurrentOrganization = true;

  constructor(params?: { endpoint?: string; useToken?: boolean; useCurrentOrganization?: boolean }) {
    const { endpoint, useToken, useCurrentOrganization } = params ?? {};
    this.endpoint = endpoint ?? this.endpoint;
    this.useToken = useToken ?? this.useToken;
    this.useCurrentOrganization = useCurrentOrganization ?? this.useCurrentOrganization;
  }

  async request<T, U = null>({
    method,
    path = '/',
    body,
  }: {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    path?: string;
    body?: any;
  }): Promise<ApiSuccessResponse<T> | LambdaFunctionApiFailedResponse<U>> {
    const parsedEndpoint = new URL(this.endpoint);
    const protocol = parsedEndpoint.protocol;
    const hostname = parsedEndpoint.hostname;

    const currentSession = await Auth.currentSession();
    const credentials = await Auth.currentCredentials();
    const signer = new SignatureV4({
      region: 'ap-northeast-1',
      service: 'lambda',
      sha256: Sha256,
      credentials,
    });

    // Lambda側の許可ヘッダーとして、以下を設定しておく必要がある。
    // - authorization
    // - host
    // - x-amz-content-sha256
    // - x-amz-date
    // - x-amz-security-token
    // - x-storecast-organization
    // - x-storecast-token
    const headers: { [key: string]: any } = {
      host: hostname,
    };

    // useTokenがtrueの場合、セッションのJWTトークンを x-storecast-token に設定する
    // この情報は、Lambda Function側で検証され、API実行ユーザーを特定するのに利用される想定です。
    // - SignatureV4でサインするとCognito IDプールに紐づくIAM Roleしか判定できなくなり、
    //   ユーザーを特定する情報が消失してしまうため、トークンを付与することで対応する。
    if (this.useToken) {
      const idToken = currentSession.getIdToken().getJwtToken();
      headers['x-storecast-token'] = idToken;
    }

    if (this.useCurrentOrganization) {
      // 現在ログイン中のアカウントのorganizationIdをヘッダーに含める
      const currentOrganizationId = AccountSingleton.getInstance().organizationId;
      if (currentOrganizationId) {
        headers['x-storecast-organization'] = `${currentOrganizationId}`;
      }
    }

    const requestParams: { [key: string]: any } = {
      method,
      protocol,
      hostname,
      path,
      headers,
    };

    if (body !== null) {
      requestParams.body = JSON.stringify(body);
    }

    const req = await signer.sign(new HttpRequest(requestParams));
    const url = `${protocol}//${hostname}${path}`;
    const response = await fetch(url, {
      method: req.method,
      body: req.body,
      headers: req.headers,
    });

    if (Math.floor(response.status / 100) == 2) {
      return {
        data: await response.json(),
        status: response.status,
        headers: response.headers,
        isSuccess: true as const,
      };
    } else {
      return {
        error: await response.json(),
        status: response.status,
        headers: response.headers,
        isSuccess: false as const,
      };
    }
  }

  async get<T, U = null>(
    path = '/',
    body: any = null,
  ): Promise<ApiSuccessResponse<T> | LambdaFunctionApiFailedResponse<U>> {
    return this.request({ method: 'GET', path, body });
  }
  async post<T, U = null>(
    path = '/',
    body: any = null,
  ): Promise<ApiSuccessResponse<T> | LambdaFunctionApiFailedResponse<U>> {
    return this.request({ method: 'POST', path, body });
  }
  async put<T, U = null>(
    path = '/',
    body: any = null,
  ): Promise<ApiSuccessResponse<T> | LambdaFunctionApiFailedResponse<U>> {
    return this.request({ method: 'PUT', path, body });
  }
  async patch<T, U = null>(
    path = '/',
    body: any = null,
  ): Promise<ApiSuccessResponse<T> | LambdaFunctionApiFailedResponse<U>> {
    return this.request({ method: 'PATCH', path, body });
  }
  async delete<T, U = null>(
    path = '/',
    body: any = null,
  ): Promise<ApiSuccessResponse<T> | LambdaFunctionApiFailedResponse<U>> {
    return this.request({ method: 'DELETE', path, body });
  }
}
