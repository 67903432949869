import React from 'react';

import styled from 'styled-components';

import { Props, PullDown } from './PullDown';

export const PullDownNarrow: React.FC<Props> = (props) => <Wrapper {...props} />;

const Wrapper = styled(PullDown)`
  .ui.search.selection.dropdown {
    min-width: initial;
    min-height: 40px;
    font-size: 13px;
    padding: 10px 16px;

    > .search {
      padding: 10px 34px 11px 13px !important;
    }

    > .text {
      top: 2px;
    }

    > i {
      padding-top: 11px !important;
    }

    > .menu {
      > .item {
        padding: 10px 16px !important;
        font-size: 13px;
      }
    }
  }
`;
