import dayjs from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

export class MapSearchResultStatusItem extends Record<{
  condition: {
    areaName: string;
    searchWord: string;
  };
  url: string | null;
}>({
  condition: {
    areaName: '',
    searchWord: '',
  },
  url: null,
}) {
  static fromJSON(data: JSObject = {}) {
    return new MapSearchResultStatusItem({
      condition: {
        areaName: data.condition.area_name,
        searchWord: data.condition.search_word,
      },
      url: data.url,
    });
  }

  /**
   * データを取得中かどうか
   */
  isProcessing() {
    return this.url == null;
  }
}

/**
 * マップ検索結果取得の状態確認APIの結果データ
 */
export class MapSearchResultStatus extends Record<{
  status: 'RUNNING' | 'SUCCEEDED';
  date: dayjs.Dayjs;
  conditions: {
    areaNames: string[];
    searchWords: string[];
  };
  result: {
    items: List<MapSearchResultStatusItem>;
  };
}>({
  status: 'RUNNING',
  date: dayjs(),
  conditions: {
    areaNames: [],
    searchWords: [],
  },
  result: { items: List() },
}) {
  static fromJSON(data: JSObject = {}) {
    return new MapSearchResultStatus({
      status: data.status,
      date: dayjs(data.date),
      conditions: {
        areaNames: data.conditions.area_names,
        searchWords: data.conditions.search_words,
      },
      result: {
        items: List(data.result.items.map((item: any) => MapSearchResultStatusItem.fromJSON(item))),
      },
    });
  }

  /**
   * 取得対象の項目数を返す
   */
  getItemCount() {
    return this.result.items.size;
  }
}
