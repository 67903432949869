import { Table as _Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';

export const Table = styled(_Table)`
  &&& {
    margin: 0;
    border-collapse: collapse;
  }
`;
export const TableHeader = styled(_Table.Header)``;
export const TableHeaderRow = styled(_Table.Row)`
  background: #f9fafb;
`;
export const TableHeaderCell = styled(_Table.HeaderCell)`
  &&& {
    padding: 8px;
    color: ${COLOR.BLACK};
    font-size: 14px;
    vertical-align: middle;
    height: 40px;
    text-align: center;
  }
`;
export const TableBody = styled(_Table.Body)``;
export const TableRow = styled(_Table.Row)`
  &&& {
    background: ${COLOR.WHITE};
    color: ${COLOR.BLACK};
    &:hover {
      background: #f2f2f2;
    }
  }
`;
export const TableCell = styled(_Table.Cell)`
  &&& {
    color: ${COLOR.BLACK};
    font-size: 14px;
    vertical-align: middle;
    padding: 8px;
  }
`;

export const SortableTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    cursor: pointer;
    &:hover {
      background: #ebeced;
    }
  }
`;

export const SortTriangle = styled.div<{ isSortOrderDesc: boolean }>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${(props) => (props.isSortOrderDesc ? '5px 5px 0 5px' : '0px 5px 5px 5px')};
  border-color: ${(props) =>
    props.isSortOrderDesc
      ? `${COLOR.GREEN} transparent transparent transparent`
      : `transparent transparent ${COLOR.GREEN} transparent`};
`;
