import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { SearchKeywordState, searchKeywordReducer } from './SearchKeyword/reducers';
import { TopState, topReducer } from './Top/reducers';
import { AppActions } from './app/actions';
import { AppState, appReducer } from './app/reducers';
import { BulkEditStoresState, bulkEditStoresReducer } from './bulkEditStores/reducers';
import { CompetitorState, competitorReducer } from './competitor/reducers';
import { CompetitorRegisterState, competitorRegisterReducer } from './competitor/register/reducers';
import { GbpChecklistState, gbpChecklistReducer } from './gbpChecklist/reducers';
import { GbpInsightState, gbpInsightReducer } from './gbpInsight/reducers';
import { GbpPerformanceState, gbpPerformanceReducer } from './gbpPerformance/reducers';
import { GbpPerformanceMAState, gbpPerformanceMAReducer } from './gbpPerformanceMA/reducers';
import { GmbState, gmbReducer } from './gmb/reducers';
import { GmbLocationUpdatesState, gmbLocationUpdatesReducer } from './gmbLocationUpdates/reducers';
import { ImageState, imageReducer } from './image/reducers';
import { InstagramState, instagramReducer } from './instagram/reducers';
import { InventoriesImportState, inventoriesImportReducer } from './inventoriesImport/reducers';
import { InventoryState, inventoryReducer } from './inventory/reducers';
import { MapCompetitorResearchState, mapCompetitorResearchReducer } from './mapCompetitorResearch/reducers';
import { MapSearchRankConfigState, mapSearchRankConfigReducer } from './mapSearchRank/config/reducers';
import { MapSearchRankConfigImportState, mapSearchRankConfigImportReducer } from './mapSearchRank/import/reducers';
import { MapSearchRankState, mapSearchRankReducer } from './mapSearchRank/reducers';
import { MemoState, memoReducer } from './memo/reducers';
import { MenuState, menuReducer } from './menu/reducers';
import { NotificationState, notificationReducer } from './notification/reducers';
import { OfferState, offerReducer } from './offer/reducers';
import { OfferGroupsState, offerGroupReducer } from './offerGroups/reducers';
import { OmoInsightState, omoInsightReducer } from './omoInsight/reducers';
import { OrganizationState, organizationReducer } from './organization/reducers';
import { PromotionState, promotionReducer } from './promotion/reducers';
import { SearchVolumeState, searchVolumeReducer } from './searchVolume/reducers';
import { ServiceState, serviceReducer } from './service/reducers';
import { StoreState, storeReducer } from './store/reducers';
import { StoreDetailState, storeDetailReducer } from './storeDetail/reducers';
import { StoreListReducer, StoreListState } from './storeList/reducers';
import { StoresImportState, storesImportReducer } from './storesImport/reducers';
import { TaskDetailState, taskDetailReducer } from './taskDetail/reducers';
import { UserState, userReducer } from './user/reducers';

export interface State {
  router: RouterState;
  app: AppState;
  top: TopState;
  store: StoreState;
  storeDetail: StoreDetailState;
  storeList: StoreListState;
  image: ImageState;
  user: UserState;
  gmb: GmbState;
  notification: NotificationState;
  offer: OfferState;
  promotion: PromotionState;
  bulkEditStores: BulkEditStoresState;
  gmbLocationUpdates: GmbLocationUpdatesState;
  storesImport: StoresImportState;
  offerGroups: OfferGroupsState;
  taskDetail: TaskDetailState;
  gbpInsight: GbpInsightState;
  inventory: InventoryState;
  inventoriesImport: InventoriesImportState;
  searchKeyword: SearchKeywordState;
  mapSearchRank: MapSearchRankState;
  mapSearchRankConfig: MapSearchRankConfigState;
  mapSearchRankConfigImport: MapSearchRankConfigImportState;
  mapCompetitorResearch: MapCompetitorResearchState;
  competitor: CompetitorState;
  competitorRegister: CompetitorRegisterState;
  gbpChecklist: GbpChecklistState;
  organization: OrganizationState;
  gbpPerformance: GbpPerformanceState;
  gbpPerformanceMA: GbpPerformanceMAState;
  searchVolume: SearchVolumeState;
  instagram: InstagramState;
  memo: MemoState;
  omoInsight: OmoInsightState;
  menu: MenuState;
  service: ServiceState;
}

const rootReducer = (history: History) => {
  const reducers = combineReducers({
    router: connectRouter(history),
    app: appReducer,
    top: topReducer,
    store: storeReducer,
    storeDetail: storeDetailReducer,
    storeList: StoreListReducer,
    image: imageReducer,
    user: userReducer,
    gmb: gmbReducer,
    notification: notificationReducer,
    offer: offerReducer,
    promotion: promotionReducer,
    bulkEditStores: bulkEditStoresReducer,
    gmbLocationUpdates: gmbLocationUpdatesReducer,
    storesImport: storesImportReducer,
    offerGroups: offerGroupReducer,
    taskDetail: taskDetailReducer,
    gbpInsight: gbpInsightReducer,
    inventory: inventoryReducer,
    inventoriesImport: inventoriesImportReducer,
    searchKeyword: searchKeywordReducer,
    mapSearchRank: mapSearchRankReducer,
    mapSearchRankConfig: mapSearchRankConfigReducer,
    mapSearchRankConfigImport: mapSearchRankConfigImportReducer,
    mapCompetitorResearch: mapCompetitorResearchReducer,
    competitor: competitorReducer,
    competitorRegister: competitorRegisterReducer,
    gbpChecklist: gbpChecklistReducer,
    organization: organizationReducer,
    gbpPerformance: gbpPerformanceReducer,
    gbpPerformanceMA: gbpPerformanceMAReducer,
    searchVolume: searchVolumeReducer,
    instagram: instagramReducer,
    memo: memoReducer,
    omoInsight: omoInsightReducer,
    menu: menuReducer,
    service: serviceReducer,
  });
  return (state: Parameters<typeof reducers>[0], action: Parameters<typeof reducers>[1]) => {
    if (action.type === AppActions.reset.type) {
      // undefinedを設定することで初期値になる
      return reducers(undefined, action);
    }
    return reducers(state, action);
  };
};

export default rootReducer;
