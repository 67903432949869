import React, { useCallback, useMemo, useState } from 'react';

import { toast } from 'react-semantic-toasts';
import { Dimmer, Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';

import { PromotionGenerateApi } from 'ApiClient/PromotionApi';
import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { TextArea } from 'components/atoms/TextArea';
import { GeneratedPromotionList } from 'models/Domain/Promotion/GeneratedPromotion';
import { PromotionGenerateCondition } from 'models/Domain/Promotion/PromotionGenerateCondition';
import { COLOR } from 'style/color';

type Props = {
  condition: PromotionGenerateCondition;
  generateTime: string;
  onBack: () => void;
  onGenerate: () => void;
  onApply: (result: string) => void;
};

const errorMessage = '投稿文の自動生成に失敗しました。再生成または条件を変更して再度実行してください。';

const generatePromotion = async (condition: PromotionGenerateCondition) => {
  const params = condition.toGenerateParams();
  return await PromotionGenerateApi.post(params);
};

const useGeneratePromotion = (condition: PromotionGenerateCondition, generateTime: string) => {
  // conditionのみをkeyとすると、キャッシュが利用され再生成が実行されないので実行日時を含める
  const { data: response, error } = useSWRImmutable([condition, generateTime], generatePromotion, {
    shouldRetryOnError: false,
  });
  if (response?.isSuccess) {
    const data = GeneratedPromotionList.fromJSON(response.data);
    return { data, error };
  }
  if ((response && !response.isSuccess) || error != null) {
    toast({
      type: 'error',
      title: '投稿文の自動生成に失敗しました',
      time: 3000,
    });
    return { data: null, error };
  } else {
    return { data: undefined, error };
  }
};

export const PromotionGenerateResultContents: React.FC<Props> = ({
  condition,
  generateTime,
  onApply,
  onBack,
  onGenerate,
}) => {
  const [editingText, setEditingText] = useState<string | null>(null);

  const handleOnClickBack = useCallback(() => {
    onBack();
  }, [onBack]);

  const handleOnClickApply = useCallback(
    (value: string) => {
      onApply(value);
    },
    [onApply],
  );

  const { data, error } = useGeneratePromotion(condition, generateTime);
  const isLoading = data === undefined && error == null;
  const isError = !isLoading && !data;
  // 内容を変更していない場合は、Warningを表示する
  const showWarning = !isLoading && !isError && editingText == null;

  const handleOnChange = useCallback((value: string) => {
    setEditingText(value);
  }, []);

  const generatedText = useMemo(() => data?.items.get(0)?.body, [data]);

  const displayText = useMemo(() => {
    return editingText != null ? editingText : generatedText || '';
  }, [generatedText, editingText]);

  const handleOnGenerate = useCallback(() => {
    setEditingText(null);
    onGenerate();
  }, [onGenerate]);

  return (
    <ModalContent>
      <Wrapper>
        <Title>投稿文の自動生成</Title>
        <ContentWrapper>
          <LoadingWrapper>
            <Dimmer active={isLoading} inverted>
              <Loader inverted>文章の生成には5〜15秒かかります</Loader>
            </Dimmer>
            <StyledTextArea
              minRows={10}
              value={isError ? errorMessage : displayText}
              onChange={handleOnChange}
              disabled={isError}
            />
          </LoadingWrapper>
          {showWarning && (
            <Warning>
              この文章はAIで作成されているため、事実と異なる内容が含まれる可能性があります。
              <br />
              必ず内容を確認してから適用してください。
            </Warning>
          )}
        </ContentWrapper>
        <ButtonWrapper>
          <StyledButton onClick={handleOnGenerate} style={{ marginRight: 'auto' }} disabled={isLoading}>
            <StyledIcon name={'redo'} />
            再生成
          </StyledButton>
          <StyledButton onClick={handleOnClickBack}>戻る</StyledButton>
          <StyledButton onClick={() => handleOnClickApply(displayText)} priority={'high'} disabled={!displayText}>
            適用
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    </ModalContent>
  );
};

const ModalContent = styled(Modal.Content)``;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    min-width: 140px;
    width: auto;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Warning = styled.div`
  color: ${COLOR.DARK_GRAY};
`;

const StyledIcon = styled(Icon)`
  &&& {
    margin-right: 4px;
  }
`;
