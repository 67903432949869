import React from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import { COLOR } from 'style/color';

type DashboardCountPanelProps = {
  className?: string;
  title: string;
  helpText?: string;
  value: number;
  total: number;
  unit?: string;
  onClickDetail: () => void;
  onClickClose?: () => void;
};

export const DashboardCountPanel: React.FC<DashboardCountPanelProps> = ({
  className,
  title,
  helpText,
  value,
  total,
  unit = '店舗',
  onClickDetail,
  onClickClose,
}) => {
  const color = total > 0 ? (value === total ? COLOR.GREEN : value / total > 0.5 ? '#ffa500' : '#dd5252') : COLOR.BLACK;
  return (
    <Wrapper className={className}>
      <Title>
        {title}
        {helpText && <ContextHelp content={helpText} />}
      </Title>
      <ValueContainer>
        <Value style={{ color }}>
          {value} / {total}
        </Value>
        <Unit>{unit}</Unit>
      </ValueContainer>
      <LinkContainer>
        <DetailLink onClick={onClickDetail}>詳細を見る</DetailLink>
      </LinkContainer>
      {onClickClose && (
        <CloseIconWrapper onClick={onClickClose}>
          <CloseIcon name='close' />
        </CloseIconWrapper>
      )}
    </Wrapper>
  );
};

const CloseIconWrapper = styled.div`
  position: absolute;
  color: ${COLOR.BLACK};
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  background: white;
  padding: 16px;
  position: relative;

  ${CloseIconWrapper} {
    visibility: hidden;
  }
  :hover {
    ${CloseIconWrapper} {
      visibility: visible;
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${COLOR.BLACK};
  margin-bottom: 16px;
`;
const ValueContainer = styled.div`
  text-align: right;
  margin-bottom: 16px;
`;

const Value = styled.span`
  line-height: 48px;
  font:
    bold 3rem Helvetica,
    Arial,
    sans-serif;
`;
const Unit = styled.span`
  margin-left: 16px;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
`;
const LinkContainer = styled.div`
  text-align: right;
`;
const DetailLink = styled.span`
  color: ${COLOR.GREEN};
  font-weight: bold;
  cursor: pointer;
`;

const CloseIcon = styled(Icon)`
  color: ${COLOR.BLACK};
`;
