import styled from 'styled-components';

import { COLOR } from 'style/color';

type AlertType = 'error' | 'caution' | 'info';

const TYPE_COLORS: { [key in AlertType]: { background: string; border: string } } = {
  error: {
    background: '#f7d8d8',
    border: '#ff7a7a',
  },
  caution: {
    background: '#f7efd8',
    border: '#ffd27a',
  },
  info: {
    background: COLOR.BACKGROUND,
    border: COLOR.GRAY,
  },
};

const AlertContainer = styled.div<{ type: AlertType }>`
  background: ${({ type }) => TYPE_COLORS[type].background};
  border: 1px solid ${({ type }) => TYPE_COLORS[type].border};
  padding: 8px 16px;
  vertical-align: baseline;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
`;

const Section = styled.div`
  font-size: 12px;
  margin: 8px 0;
`;

const Header = styled.div`
  font-size: 12px;
  margin: 4px 0;
`;

const Content = styled.p`
  margin: 4px 0;
`;

type CompoundAlert = typeof AlertContainer & {
  Title: typeof Title;
  Section: typeof Section;
  Header: typeof Header;
  Content: typeof Content;
};

const Alert: CompoundAlert = AlertContainer as any;
Alert.Title = Title;
Alert.Section = Section;
Alert.Header = Header;
Alert.Content = Content;

export { Alert };
