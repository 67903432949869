import React, { useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { GuideOverlay } from 'components/molecules/GuideOverlay';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import { GraphKey } from 'components/pageComponents/SearchKeywords/SearchKeywordsDetailTable';
import { MemoDisplaySettings, getMemoData } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { MemoList } from 'models/Domain/Memo/Memo';
import { KeywordGraphData } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SearchKeywordSearchCondition } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { YearMonth } from 'models/Domain/YearMonth';
import { COLOR } from 'style/color';

import { SearchKeywordsDetailGraph as Graph } from './Graph';

type Props = {
  className?: string;
  isLoading?: boolean;
  searchCondition: SearchKeywordSearchCondition;
  graphData?: KeywordGraphData;
  showSummary?: boolean;
  selectedStoreIds?: ImmutableSet<number>;
  memoList: MemoList;
  memoDisplaySettings: MemoDisplaySettings;
  onClickMemo: (ids: number[]) => void;
  onClickLegend?: (key: GraphKey) => void;
};

export const SearchKeywordsDetailGraph = React.memo<Props>(
  ({
    className,
    isLoading = false,
    searchCondition,
    graphData = new KeywordGraphData(),
    showSummary = true,
    selectedStoreIds,
    memoList,
    memoDisplaySettings,
    onClickMemo,
    onClickLegend,
  }) => {
    // ダウンロードモーダル関連
    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

    // 設定モーダル
    const [isOpenSettingsModal, setIsOpenSettingsModal] = useState<boolean>(false);
    const [graphSettings, setGraphSettings] = useState(new GraphSettings());

    const startMonth = YearMonth.fromString(searchCondition.filter.startMonth);
    const endMonth = YearMonth.fromString(searchCondition.filter.endMonth);

    const memoData = useMemo(
      () =>
        getMemoData(
          memoList,
          startMonth.toDayjs().startOf('month'),
          endMonth.toDayjs().endOf('month'),
          'month',
          memoDisplaySettings.displayToGraph,
        ),
      [endMonth, memoDisplaySettings.displayToGraph, memoList, startMonth],
    );

    return (
      <Wrapper className={className}>
        <GraphWrapper>
          <Graph
            isLoading={isLoading}
            startMonth={startMonth}
            endMonth={endMonth}
            graphData={graphData}
            showSummary={showSummary}
            selectedStoreIds={selectedStoreIds}
            onClickLegend={onClickLegend}
            memoData={memoData}
            onClickMemo={onClickMemo}
            graphSettings={graphSettings}
          />
          {isLoading && (
            <LoadingWrapper>
              <StyledLoader active={isLoading} size={'big'} inline={true} />
            </LoadingWrapper>
          )}
          {!isLoading && !showSummary && selectedStoreIds?.isEmpty() && (
            <GuideOverlay>表示するデータを選択してください</GuideOverlay>
          )}
          <ButtonWrapper>
            <StyledButton onClick={() => setIsOpenSettingsModal(true)}>グラフの表示設定</StyledButton>
            <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
          </ButtonWrapper>
        </GraphWrapper>
        <GraphDownloadModal
          isOpen={isOpenDownloadModal}
          fileName={'SearchKeywords'}
          onClose={() => setIsOpenDownloadModal(false)}
        >
          <Graph
            startMonth={startMonth}
            endMonth={endMonth}
            graphData={graphData}
            showSummary={showSummary}
            selectedStoreIds={selectedStoreIds}
            graphSettings={graphSettings}
          />
        </GraphDownloadModal>
        {isOpenSettingsModal && (
          <GraphSettingsModal
            settings={graphSettings}
            isOpen={isOpenSettingsModal}
            onClose={() => setIsOpenSettingsModal(false)}
            onChange={setGraphSettings}
          />
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledLoader = styled(Loader)`
  &&&& {
    &:before {
      border-color: rgba(0, 0, 0, 0.1);
    }
    &:after {
      border-color: #767676 transparent transparent;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledButton = styled(Button).attrs({ priority: 'low' })`
  font-size: 16px;
`;

const GraphWrapper = styled.div`
  position: relative;
  font-size: 12px;
  font-weight: bold;
`;
