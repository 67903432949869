import { LOCATION_CHANGE, LocationChangeAction, RouterRootState, goBack } from 'connected-react-router';
import { List, Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  SearchStoreImagesApi,
  StoreImagesApi,
  StoreImagesDetailApi,
  StoreImagesDisconnectApi,
  StoreImagesPostGmbApi,
  StoreImagesTagsApi,
  StoreImagesUpdateCategoryApi,
  StoreImagesUpdateHashtag,
} from 'ApiClient/StoreImagesApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { GbpImageCategory, ImageList } from 'models/Domain/Image/Image';
import ImageDetail from 'models/Domain/Image/ImageDetail';
import ImageSearchCondition from 'models/Domain/Image/ImageSearchCondition';
import { AppActions } from 'modules/app/actions';
import { onDropAccepted } from 'modules/app/fileUpload';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { State } from '../reducers';

import { ImageActions } from './actions';

export default function* saga() {
  yield takeLatest(ImageActions.initializeAlbumListIndexPage, initializeAlbumListIndexPage);
  yield takeLatest(ImageActions.getHashtagList, getHashtagList);
  yield takeLatest(ImageActions.getImage, getImage);
  yield takeLatest(ImageActions.deleteImage, deleteImage);
  yield takeLatest(ImageActions.uploadGmb, uploadGmb);
  yield takeLatest(ImageActions.disconnectImages, disconnectImages);
  yield takeLatest(ImageActions.uploadNewImageFile, uploadNewImageFile);
  yield takeLatest(ImageActions.updateImageHashtagList, updateImageHashtagList);
  yield takeLatest(ImageActions.updateImageCategory, updateImageCategory);
  yield takeLatest(ImageActions.createImage, createImage);
  yield takeLatest(ImageActions.fetchImages, fetchImages);
  yield takeLatest(ImageActions.setSearchCondition, setSearchCondition);
  yield takeLatest(LOCATION_CHANGE, albumListIndexLocationChange);
}

// ImageIndexページを初期化する
function* initializeAlbumListIndexPage(action: ReturnType<typeof ImageActions.initializeAlbumListIndexPage>) {
  // ハッシュタグのリストの取得
  yield put(ImageActions.getHashtagList());

  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // URLパラメータから検索条件を復元する
  yield put(ImageActions.setIsPreparedForImageIndex(true));
  yield call(updateImageIndexByLocation);
}

/**
 * URLからImageIndexページを更新する
 *
 * 本処理は、以下の２処理から呼ばれる
 * - initializeAlbumListIndexPage
 * - albumListIndexLocationChange
 */
function* updateImageIndexByLocation() {
  const location: Location = yield select((state: RouterRootState) => state.router.location);

  // URLパラメータから検索条件を復元する
  let searchCondition = ImageSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.store });
  searchCondition = searchCondition.setIn(['store'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.store,
    containsClosedStores: searchCondition.showClosedStores,
  });

  const currentStoreIds = searchCondition.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.setStoreIds(availableStoreIds, true);
  } else {
    searchCondition = searchCondition.setStoreIds(
      currentStoreIds.intersect(availableStoreIds),
      currentStoreIds.equals(availableStoreIds),
    );
  }

  // URLと検索条件から生成されるURLが異なる場合（不要なパラメータがある場合など）、正しいURLにreplace
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // 検索条件のセットと、画像の取得
  yield put(ImageActions.setSearchCondition({ searchCondition, updateLocation: false }));
  yield put(ImageActions.fetchImages());
}

function* getHashtagList(action: ReturnType<typeof ImageActions.getHashtagList>) {
  const response: YieldReturn<typeof StoreImagesTagsApi.get> = yield StoreImagesTagsApi.get();

  if (response.isSuccess) {
    yield put(ImageActions.setHashtagList(response.data));
  } else {
    console.warn(response.error);
    toast({
      type: 'error',
      title: 'ハッシュタグ一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getImage(action: ReturnType<typeof ImageActions.getImage>) {
  const response: YieldReturn<typeof StoreImagesDetailApi.get> = yield StoreImagesDetailApi.get(action.payload);

  if (response.isSuccess) {
    yield put(ImageActions.setImage(response.data));
    yield put(
      ImageActions.setEditCategory(
        (response.data?.image_detail?.image_info[0]?.gmb_category as GbpImageCategory) || null,
      ),
    );
    if (response.data.tags) {
      yield put(ImageActions.setEditHashtagList(response.data.tags));
    }
  } else {
    console.warn(response.error);
    if (response.status !== 403 && response.status !== 404) {
      toast({
        type: 'error',
        title: '画像の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
    // 詳細ページを表示できないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.album.index));
  }
}

function* deleteImage(action: ReturnType<typeof ImageActions.deleteImage>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof StoreImagesDetailApi.delete> = yield StoreImagesDetailApi.delete(action.payload);

  if (response.isSuccess) {
    toast({
      type: 'success',
      title: '写真を削除しました',
    });
    yield put(goBack());
  } else {
    console.warn(response.error);
    toast({
      type: 'error',
      title: '画像の削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

/**
 * Googleビジネスプロフィールに写真を掲載する
 */
function* uploadGmb(action: ReturnType<typeof ImageActions.uploadGmb>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof StoreImagesPostGmbApi.post> = yield StoreImagesPostGmbApi.post({
    image_resource_ids: action.payload.imageResourceIds,
    category: action.payload.category,
  });
  if (response.isSuccess) {
    const getImages = action.payload.imageResourceIds.map((imageResourceId) =>
      put(ImageActions.getImage(imageResourceId)),
    );
    yield all(getImages);
    toast({
      type: 'success',
      title: 'Googleビジネスプロフィールへの掲載を開始しました',
    });
  } else {
    console.warn(response.error);
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィールへの掲載に失敗しました',
      description: response.error.message,
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* disconnectImages(action: ReturnType<typeof ImageActions.disconnectImages>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof StoreImagesDisconnectApi.post> = yield StoreImagesDisconnectApi.post(
    action.payload,
  );
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: 'Googleビジネスプロフィールからの削除を受け付けました',
    });
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィールからの削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* uploadNewImageFile(action: ReturnType<typeof ImageActions.uploadNewImageFile>) {
  const { files, metadata } = action.payload;
  const urls: string[] = yield call(onDropAccepted, files);
  const imageList = ImageList.fromUrls(urls).changeImageFiles(metadata);
  yield put(ImageActions.setImageListForCreate(imageList));
  yield put(AppActions.moveTo(Path.album.create));
}

function* updateImageHashtagList() {
  yield put(AppActions.setLoading(true));

  const detailImage: ImageDetail = yield select((state: State) => state.image.detailImage);
  const editHashtagList: List<string> = yield select((state: State) => state.image.editHashtagList);

  const imageResourceId = detailImage.image_resource_id;
  const params = {
    imageResourceId,
    tags: editHashtagList.toArray(),
  };

  const response: YieldReturn<typeof StoreImagesUpdateHashtag.patch> = yield StoreImagesUpdateHashtag.patch(
    params.imageResourceId,
    params,
  );
  if (response.isSuccess) {
    yield put(ImageActions.getImage(imageResourceId));
    toast({
      type: 'success',
      title: '紐づいているタグを更新しました',
    });
  } else {
    console.warn(response.error);
    toast({
      type: 'error',
      title: 'ハッシュタグの更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* updateImageCategory() {
  yield put(AppActions.setLoading(true));

  const detailImage: ImageDetail = yield select((state: State) => state.image.detailImage);
  const editCategory: string = yield select((state: State) => state.image.editCategory);

  const imageResourceId = detailImage.image_resource_id;
  const params = {
    imageResourceId,
    category: editCategory,
  };

  const response: YieldReturn<typeof StoreImagesUpdateCategoryApi.post> = yield StoreImagesUpdateCategoryApi.post(
    params.imageResourceId,
    params,
  );
  if (response.isSuccess) {
    yield put(ImageActions.getImage(imageResourceId));
    toast({
      type: 'success',
      title: '紐づいているカテゴリーを更新しました',
    });
  } else {
    console.warn(response.error);
    toast({
      type: 'error',
      title: 'カテゴリーの更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* createImage(action: ReturnType<typeof ImageActions.createImage>) {
  yield put(AppActions.setLoading(true));
  const params = action.payload;
  const response: YieldReturn<typeof StoreImagesApi.post> = yield StoreImagesApi.post(params);
  if (response.isSuccess) {
    yield put(goBack());
    toast({
      type: 'success',
      title: '写真を追加しました',
    });
    if (params.publish_to_gmb) {
      toast({ type: 'success', title: 'Googleビジネスプロフィールへの掲載を開始しました' });
    }
  } else {
    toast({
      type: 'error',
      title: '写真の追加に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* fetchImages(action: ReturnType<typeof ImageActions.fetchImages>) {
  yield put(ImageActions.setIsLoadingImageList(true));

  // 新しいAPIを利用するときはこうなる;
  const searchCondition: ImageSearchCondition = yield select((state: State) => state.image.searchCondition);
  const response: YieldReturn<typeof SearchStoreImagesApi.get> = yield SearchStoreImagesApi.get(
    searchCondition.toRequestParams(),
  );

  if (response.isSuccess) {
    yield put(ImageActions.setCommittedSearchCondition(searchCondition));
    yield put(ImageActions.setImageList({ images: response.data.images, pagination: response.data.pagination }));
  } else {
    toast({
      type: 'error',
      title: '店舗画像一覧の取得に失敗しました',
      description: String(response.error.name),
      time: 10000,
    });
  }

  yield put(ImageActions.setIsLoadingImageList(false));
}

/**
 *
 * @param action
 * @returns
 */
function* albumListIndexLocationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // パスが画像一覧ページ以外の場合は対象外
  if (location.pathname !== Path.album.index) {
    return;
  }

  // 画像一覧ページの初期処理が完了していない場合は何もしない
  const isPreparedForOfferGroupsPage: boolean = yield select((state) => state.image.isPreparedForImageIndex);
  if (!isPreparedForOfferGroupsPage) {
    return;
  }

  yield call(updateImageIndexByLocation);
}

function* setSearchCondition(action: ReturnType<typeof ImageActions.setSearchCondition>) {
  const { searchCondition, updateLocation = true } = action.payload;

  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // updateLocationがtrueの場合、かつ既存とURLに差分がある場合、URLの更新を行う
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
  }
}
