import { Record as ImmutableRecord } from 'immutable';

import { JSObject } from 'types/Common';

export class ServiceType extends ImmutableRecord<{
  serviceTypeId: string;
  displayName: string;
}>({
  serviceTypeId: '',
  displayName: '',
}) {
  static fromJSON(data: JSObject) {
    return new ServiceType({ ...data });
  }
}

// ServiceList, ServiceItemは必要になったら定義する
