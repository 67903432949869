import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { List, Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  SearchKeywordsCSVCreateApi,
  SearchKeywordsCSVStatusApi,
  SearchKeywordsGraphApi,
  SearchKeywordsTableApi,
} from 'ApiClient/GmbApi/SearchKeywordApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { SearchKeywordGraphData, SearchKeywordTableData } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SearchKeywordSearchCondition } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { SearchKeywordActions } from './action';

export default function* saga() {
  yield takeLatest(SearchKeywordActions.initializePage, initializePage);
  yield takeLatest(SearchKeywordActions.getSearchKeywordGraphData, getSearchKeywordGraphDate);
  yield takeLatest(SearchKeywordActions.getSearchKeywordTableData, getSearchKeywordTableDate);
  yield takeLatest(SearchKeywordActions.setSearchCondition, setSearchCondition);
  yield takeLatest(SearchKeywordActions.setIsUpdatedExcludedKeywords, reloadPageByExcludedKeywordsChange);
  yield takeLatest(SearchKeywordActions.startCsvDownload, startCsvDownload);
  yield takeLatest(SearchKeywordActions.checkCsvDownloadStatus, checkCsvDownloadStatus);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const isPreparedPage: boolean = yield select((state: State) => state.searchKeyword.isPreparedPage);

  // 初期化済みの場合は以降の処理を行わない
  if (isPreparedPage) {
    return;
  }

  yield put(SearchKeywordActions.setIsPreparedPage(true));
  yield put(SearchKeywordActions.clearSearchKeyword());
  yield put(SearchKeywordActions.setIsInitializedSearchCondition(true));

  // URLパラメータから検索条件を復元する
  yield call(updateSearchKeyword);
}

/**
 * URLパラメータから検索条件を復元する
 */
function* updateSearchKeyword() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  // URLの置き換え
  let searchCondition = SearchKeywordSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: searchCondition.filter.showClosedStore,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // URLパラメータから復元した検索条件をセットする
  yield put(SearchKeywordActions.setSearchCondition({ searchCondition, updateLocation: false }));
  yield put(SearchKeywordActions.setCommittedSearchCondition(searchCondition));

  yield call(fetchSearchKeyword);
}

function* fetchSearchKeyword(ignoreCache = false) {
  // 条件を反映して、データを取得する
  yield put(SearchKeywordActions.getSearchKeywordGraphData({ ignoreCache }));
  yield put(SearchKeywordActions.getSearchKeywordTableData({ ignoreCache }));
}

function* getSearchKeywordGraphDate(action: ReturnType<typeof SearchKeywordActions.getSearchKeywordGraphData>) {
  const { ignoreCache } = action.payload;
  yield put(SearchKeywordActions.setIsLoadingGraphData(true));
  const searchCondition: SearchKeywordSearchCondition = yield select(
    (state: State) => state.searchKeyword.searchCondition,
  );
  const params = searchCondition.toGraphDataRequestParams();
  // グラフデータを取得する
  const response: YieldReturn<typeof SearchKeywordsGraphApi.get> = yield SearchKeywordsGraphApi.get(
    params,
    ignoreCache,
  );
  if (response.isSuccess) {
    const graphData = SearchKeywordGraphData.fromJSON(response.data);
    yield put(SearchKeywordActions.setSearchKeywordGraphData(graphData));
  } else {
    toast({
      type: 'error',
      title: 'グラフデータの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(SearchKeywordActions.setIsLoadingGraphData(false));
}

function* getSearchKeywordTableDate(action: ReturnType<typeof SearchKeywordActions.getSearchKeywordTableData>) {
  // 取得前にtableDataのitemsをクリアする
  const { ignoreCache } = action.payload;
  const currentTableData: SearchKeywordTableData = yield select(
    (state: State) => state.searchKeyword.searchKeyword.tableData,
  );
  yield put(SearchKeywordActions.setSearchKeywordTableData(currentTableData.set('items', List())));
  yield put(SearchKeywordActions.setIsLoadingTableData(true));

  const searchCondition: SearchKeywordSearchCondition = yield select(
    (state: State) => state.searchKeyword.searchCondition,
  );
  const params = searchCondition.toTableDataRequestParams();
  // テーブルデータを取得する
  const response: YieldReturn<typeof SearchKeywordsTableApi.get> = yield SearchKeywordsTableApi.get(
    params,
    ignoreCache,
  );
  if (response.isSuccess) {
    const tableData = SearchKeywordTableData.fromJSON(response.data);
    yield put(SearchKeywordActions.setSearchKeywordTableData(tableData));
  } else {
    toast({
      type: 'error',
      title: 'テーブルデータの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(SearchKeywordActions.setIsLoadingTableData(false));
}

function* setSearchCondition(action: ReturnType<typeof SearchKeywordActions.setSearchCondition>) {
  const { searchCondition, updateLocation } = action.payload;
  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // updateLocationがtrueの場合、かつ既存とURLに差分がある場合、URLの更新を行う
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
    yield put(SearchKeywordActions.setCommittedSearchCondition(searchCondition));
  }
}

function* reloadPageByExcludedKeywordsChange(
  action: ReturnType<typeof SearchKeywordActions.setIsUpdatedExcludedKeywords>,
) {
  const isUpdatedExcludedKeywords = action.payload;
  if (!isUpdatedExcludedKeywords) {
    return;
  }

  yield call(fetchSearchKeyword, true);
  yield put(SearchKeywordActions.setIsUpdatedExcludedKeywords(false));
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // Pathが検索キーワード分析画面以外の場合は何もしない
  if (location.pathname !== Path.searchKeywords.index) {
    return;
  }

  // 検索キーワード分析画面の初期化処理が完了していない場合は何もしない
  const isPreparedPage: boolean = yield select((state: State) => state.searchKeyword.isPreparedPage);
  if (!isPreparedPage) {
    return;
  }

  yield call(updateSearchKeyword);
}

function* startCsvDownload(action: ReturnType<typeof SearchKeywordActions.startCsvDownload>) {
  yield put(SearchKeywordActions.setIsProcessingCsvDownload(true));
  const searchCondition = action.payload;
  const params = searchCondition.toCSVCreateRequestParams();
  const response: YieldReturn<typeof SearchKeywordsCSVCreateApi.post> = yield SearchKeywordsCSVCreateApi.post(params);
  if (response.isSuccess) {
    yield put(
      SearchKeywordActions.setCsvDownload(new ExecutionArnStatus({ executionArn: response.data.executionArn })),
    );
  } else {
    yield put(SearchKeywordActions.setIsProcessingCsvDownload(false));
    toast({
      type: 'error',
      title: 'ファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* checkCsvDownloadStatus(action: ReturnType<typeof SearchKeywordActions.checkCsvDownloadStatus>) {
  const csvDownload: ExecutionArnStatus = yield select((state) => state.searchKeyword.csvDownload);
  if (!csvDownload.executionArn) {
    return;
  }

  const response: YieldReturn<typeof SearchKeywordsCSVStatusApi.post> = yield SearchKeywordsCSVStatusApi.post({
    executionArn: csvDownload.executionArn,
  });
  if (response.isSuccess) {
    if (response.data.status === 'RUNNING') {
      // do nothing
    } else if (response.data.status === 'SUCCEEDED') {
      const downloadUrl = response.data.download_url;
      if (downloadUrl) {
        window.location.assign(downloadUrl);
        toast({
          type: 'success',
          title: 'ファイルをダウンロードしました',
        });
      } else {
        toast({
          type: 'info',
          title: String(response.data.message),
          time: 10000,
        });
      }
      yield put(SearchKeywordActions.clearCsvDownload());
      yield clearInterval(action.payload);
      yield put(SearchKeywordActions.setIsProcessingCsvDownload(false));
    } else {
      yield put(SearchKeywordActions.clearCsvDownload());
      toast({
        type: 'error',
        title: String(response.data.message),
        description: '',
        time: 10000,
      });
      yield clearInterval(action.payload);
      yield put(SearchKeywordActions.setIsProcessingCsvDownload(false));
    }
  } else {
    yield put(SearchKeywordActions.setIsProcessingCsvDownload(false));
    toast({
      type: 'error',
      title: 'ファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}
