import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { User, UserList, UserSortStatus } from 'models/Domain/User';

import { UserActions } from './actions';

export interface StateRecord {
  userList: UserList;
  newUser: User;
  editUser: User;
  errorMessage: string;
  // パスワード変更リクエストが成功したかどうか
  successChangePassword: boolean;
  userSortStatus: UserSortStatus;
}

export class UserState extends Record<StateRecord>({
  userList: new UserList(),
  newUser: new User(),
  editUser: new User(),
  errorMessage: '',
  successChangePassword: false,
  userSortStatus: new UserSortStatus(),
}) {
  findUser(userId: string | number) {
    return this.userList.list.find((user) => user.id === Number(userId));
  }
}

export const userReducer = reducerWithInitialState(new UserState())
  .case(UserActions.setUserList, (state, data) => {
    return state.set('userList', new UserList(data, true));
  })
  .case(UserActions.changeNewUser, (state, newUser) => {
    return state.set('newUser', newUser);
  })
  .case(UserActions.clearNewUser, (state) => {
    return state.set('newUser', new User());
  })
  .case(UserActions.setEditUser, (state, editUser) => {
    return state.set('editUser', editUser);
  })
  .case(UserActions.setErrorMessage, (state, errorMessage) => {
    return state.set('errorMessage', errorMessage);
  })
  .case(UserActions.setSuccessChangePassword, (state, successChangePassword) => {
    return state.set('successChangePassword', successChangePassword);
  })
  .case(UserActions.setUserSortStatus, (state, userSortStatus) => {
    return state.set('userSortStatus', userSortStatus);
  });
