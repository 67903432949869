import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { CreateTabContent } from 'components/pageComponents/StoreListCreate/CreateTabContent';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import StoreList from 'models/Domain/StoreList';
import { StoreListActions } from 'modules/storeList/actions';

export const StoreListCreate = React.memo(() => {
  const dispatch = useDispatch();
  const setStoreListForCreate = useCallback(
    (v: StoreList) => dispatch(StoreListActions.setStoreListForCreate(v)),
    [dispatch],
  );
  const createStoreList = useCallback(() => dispatch(StoreListActions.createStoreList()), [dispatch]);

  const { storeListState, storeState } = useSelector((state) => ({
    storeListState: state.storeList,
    storeState: state.store,
  }));
  const { storeListForCreate } = storeListState;
  const { stores } = storeState;

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('新しいグループを登録')} />
      <StickyHeader title='新しいグループを登録' />
      <Body>
        <CreateTabContent
          storeListForCreate={storeListForCreate}
          stores={stores}
          setStoreListForCreate={setStoreListForCreate}
          createStoreList={createStoreList}
        />
      </Body>
    </MainWrapper>
  );
});
