import React, { useCallback } from 'react';

import { Dayjs } from 'dayjs';
import { Set } from 'immutable';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { DropdownMenu, DropdownMenuItem } from 'components/molecules/DropdownMenu';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { MapSearchRankHelp as Help } from 'helpers/ContextHelp';
import {
  MapSearchRankCompetitorTableData,
  MapSearchRankCompetitorTableItem,
} from 'models/Domain/MapSearchRank/MapSearchRankCompetitor';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';

import {
  CheckboxCell,
  CheckboxWrapper,
  Diff,
  LoadingWrapper,
  NumberTableCell,
  RankContainer,
  RankDateLabel,
  TableHeaderFlexWrapper,
  convertNumberToText,
} from './MapSearchRankTable';

type Props = {
  className?: string;
  isLoading: boolean;
  isEnabledComparison: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  tableData: MapSearchRankCompetitorTableData | undefined;
  stores: Stores;
  storeColors: { [key: string]: string };
  selectedStores: Set<string>;
  onSelectStore: (placeId: string) => void;
  onClickRegisterCompetitor: (competitorPlaceId: string, competitorStoreName: string) => void;
  additionalCompetitorPlaceIds: Set<string>;
  canRegisterCompetitor: boolean;
};

const DATE_FORMAT = 'YYYY/MM/DD';

export const MapSearchRankCompetitorTable = React.memo<Props>(
  ({
    className,
    isLoading,
    isEnabledComparison,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    tableData,
    stores,
    storeColors,
    selectedStores,
    onSelectStore,
    onClickRegisterCompetitor,
    additionalCompetitorPlaceIds,
    canRegisterCompetitor,
  }) => {
    return (
      <Wrapper className={className}>
        <TableWrapper>
          <StyledTable>
            <StyledTableHeader>
              <TableHeaderRow>
                <ColorHeaderCell />
                <TableHeaderCell>
                  <TableHeaderFlexWrapper position={'left'}>表示</TableHeaderFlexWrapper>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderFlexWrapper position={'left'}>店舗名</TableHeaderFlexWrapper>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderFlexWrapper position={'right'}>
                    <RankContainer>
                      平均掲載順位
                      {isEnabledComparison && (
                        <RankDateLabel>
                          {startDate.format(DATE_FORMAT)}〜{endDate.format(DATE_FORMAT)}
                        </RankDateLabel>
                      )}
                    </RankContainer>
                    <ContextHelp content={Help.rank} />
                  </TableHeaderFlexWrapper>
                </TableHeaderCell>
                {!isEnabledComparison && (
                  <>
                    <TableHeaderCell>
                      <TableHeaderFlexWrapper position={'right'}>
                        <RankContainer>最新掲載順位</RankContainer>
                        <ContextHelp content={Help.latestRank} />
                      </TableHeaderFlexWrapper>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <TableHeaderFlexWrapper position={'right'}>
                        差分
                        <ContextHelp content={Help.diff} />
                      </TableHeaderFlexWrapper>
                    </TableHeaderCell>
                  </>
                )}
                {isEnabledComparison && (
                  <>
                    <TableHeaderCell>
                      <TableHeaderFlexWrapper position={'right'}>
                        <RankContainer>
                          平均掲載順位
                          <RankDateLabel>
                            {comparisonStartDate && comparisonStartDate.format(DATE_FORMAT)}〜
                            {comparisonEndDate && comparisonEndDate.format(DATE_FORMAT)}
                          </RankDateLabel>
                        </RankContainer>
                        <ContextHelp content={Help.comparisonRank} />
                      </TableHeaderFlexWrapper>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <TableHeaderFlexWrapper position={'right'}>
                        比較期間との差分
                        <ContextHelp content={Help.diffComparison} />
                      </TableHeaderFlexWrapper>
                    </TableHeaderCell>
                  </>
                )}
              </TableHeaderRow>
            </StyledTableHeader>
            <TableBody>
              {tableData?.items.map((item, index) => {
                return (
                  <ItemRow
                    key={index}
                    isEnabledComparison={isEnabledComparison}
                    isSelected={selectedStores.contains(item.placeId)}
                    isMyStore={!!stores.findStoreByPlaceId(item.placeId)}
                    onSelect={onSelectStore}
                    tableItem={item}
                    color={storeColors[item.placeId] ?? COLOR.WHITE}
                    onClickRegisterCompetitor={onClickRegisterCompetitor}
                    additionalCompetitorPlaceIds={additionalCompetitorPlaceIds}
                    canRegisterCompetitor={canRegisterCompetitor}
                  />
                );
              })}
            </TableBody>
          </StyledTable>
          {tableData && tableData.items.isEmpty() && (
            <Notification>
              指定期間のデータがありませんでした
              <br />
              検索条件を変更してください
            </Notification>
          )}
          {isLoading && (
            <Notification>
              <StyledLoadingWrapper>
                <Loader active={true} size='big' inline={true} />
              </StyledLoadingWrapper>
            </Notification>
          )}
        </TableWrapper>
      </Wrapper>
    );
  },
);

type RowProps = {
  isEnabledComparison: boolean;
  isSelected: boolean;
  isMyStore: boolean;
  onSelect: (placeId: string) => void;
  tableItem: MapSearchRankCompetitorTableItem;
  color: string;
  onClickRegisterCompetitor: (competitorPlaceId: string, competitorStoreName: string) => void;
  additionalCompetitorPlaceIds: Set<string>;
  canRegisterCompetitor: boolean;
};

const ItemRow: React.FC<RowProps> = ({
  isEnabledComparison,
  isSelected,
  isMyStore,
  onSelect,
  tableItem,
  color,
  onClickRegisterCompetitor,
  additionalCompetitorPlaceIds,
  canRegisterCompetitor,
}) => {
  const handleOnClick = useCallback(() => {
    onSelect(tableItem.placeId);
  }, [onSelect, tableItem.placeId]);

  // 競合設定されているか、追加で競合設定した(additionalCompetitorPlaceIdsに含まれている)場合が競合店舗
  const isCompetitor = tableItem.isCompetitor || additionalCompetitorPlaceIds.has(tableItem.placeId);

  const menuItems: DropdownMenuItem[] = [{ label: 'Googleマップで見る', link: tableItem.mapUrl, target: '_blank' }];
  if (canRegisterCompetitor) {
    menuItems.push({
      label: '競合店舗を登録する',
      onClick: !isCompetitor ? () => onClickRegisterCompetitor(tableItem.placeId, tableItem.storeName) : undefined,
      disabled: isCompetitor,
    });
  }

  return (
    <StyledTableRow onClick={handleOnClick}>
      <ColorCell color={color} />
      <CheckboxCell>
        <CheckboxWrapper>
          <SmallCheckBox checked={isSelected} />
        </CheckboxWrapper>
      </CheckboxCell>
      <TableCell>
        {tableItem.storeName}
        {isMyStore ? <ShopIcon isCompetitor={false} /> : isCompetitor && <ShopIcon isCompetitor={true} />}
        <DropdownMenu trigger={<SettingsIcon />} direction='right' items={menuItems} />
      </TableCell>
      <NumberTableCell>{tableItem.isRankUnranked() ? '圏外' : convertNumberToText(tableItem.rank)}</NumberTableCell>
      {!isEnabledComparison && (
        <>
          <NumberTableCell>
            {/* 最新期間の順位はデータのある期間の最新なので、rankがnullの場合は「圏外」の場合のみ（データ欠落ではない） */}
            {convertNumberToText(tableItem.latestRank, '圏外')}
          </NumberTableCell>
          <NumberTableCell>
            <Diff value={tableItem.diff}>{convertNumberToText(tableItem.diff)}</Diff>
          </NumberTableCell>
        </>
      )}
      {isEnabledComparison && (
        <>
          <NumberTableCell>
            {tableItem.isRankComparisonUnranked() ? '圏外' : convertNumberToText(tableItem.rankComparison)}
          </NumberTableCell>
          <NumberTableCell>
            <Diff value={tableItem.diffComparison}>{convertNumberToText(tableItem.diffComparison)}</Diff>
          </NumberTableCell>
        </>
      )}
    </StyledTableRow>
  );
};

export const ShopIcon = ({ isCompetitor }: { isCompetitor: boolean }) => (
  <CustomIconWrapper>
    <Popup
      content={<PopupContent>{isCompetitor ? <>競合設定されている店舗</> : <>あなたの所属組織の店舗</>}</PopupContent>}
      trigger={
        <PopupTrigger>
          <CustomIcon type={isCompetitor ? 'shop_yellow' : 'shop_green'} />
        </PopupTrigger>
      }
    />
  </CustomIconWrapper>
);
const Wrapper = styled.div``;

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${COLOR.BACKGROUND};
`;

const StyledTable = styled(Table)`
  &&& {
    border-collapse: separate;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
`;

const SettingsIcon = styled(Icon).attrs({
  type: 'settings',
})`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  margin-bottom: -4px;
  margin-left: 8px;

  visibility: hidden;
`;

const StyledTableRow = styled(TableRow)`
  &&& {
    cursor: pointer;

    :hover {
      ${SettingsIcon} {
        visibility: visible;
      }
    }
  }
`;

// 色付けのためのセル
const ColorHeaderCell = styled(TableHeaderCell)`
  width: 8px;
  padding: 0 !important;
`;

const ColorCell = styled(TableCell)<{ color?: string }>`
  width: 8px;
  padding: 0 !important;
  background: ${({ color }) => color ?? 'transparent'};
`;

const Notification = styled.div`
  width: 100%;
  padding: 32px 0;
  color: ${COLOR.DARK_GRAY};
  font-size: 16px;
  text-align: center;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
  line-height: 200%;
  background: ${COLOR.WHITE};
`;

export const StyledLoadingWrapper = styled(LoadingWrapper)`
  background: ${COLOR.GRAY};
`;

const CustomIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 0;
  vertical-align: middle;
`;

const CustomIconWrapper = styled.span`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

const PopupTrigger = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-family: monospace !important;
  font-weight: normal;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;
