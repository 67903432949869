import { List } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  OfferApi,
  OfferCommentApi,
  OfferDoneApi,
  OfferImageCommentApi,
  OfferLatestApi,
  OfferReadApi,
  OfferStampApi,
  OfferStoreApi,
  OfferTimelineApi,
} from 'ApiClient/OfferApi';
import { TasksApi, TasksOfferBulkApi } from 'ApiClient/TasksApi';
import { replaceWithOrganizationId } from 'helpers/router';
import { OfferActivities } from 'models/Composite/OfferActivity';
import { OfferCommon } from 'models/Composite/OfferCommon';
import { OfferItem } from 'models/Composite/OfferItem';
import { DestinationType, OfferStores } from 'models/Composite/OfferStores';
import { Comment } from 'models/Domain/Comment';
import { Stores as StoresModel } from 'models/Domain/Store';
import { Path } from 'routes';

import { AppActions } from '../app/actions';
import { onDropAccepted } from '../app/fileUpload';
import { State } from '../reducers';

import { OfferActions } from './actions';

export default function* saga() {
  yield takeLatest(OfferActions.getStoreOffers, () => getStoreOffers());
  yield takeLatest(OfferActions.getOffer, getOffer);
  yield takeLatest(OfferActions.getEditOffer, getEditOffer);
  yield takeLatest(OfferActions.getOfferTimeline, getOfferTimeline);
  yield takeLatest(OfferActions.sendNewComment, sendNewComment);
  yield takeLatest(OfferActions.acceptTask, acceptTask);
  yield takeLatest(OfferActions.uploadNewCommentFile, uploadNewCommentFile);
  yield takeLatest(OfferActions.getOfferActivity, () => getOfferActivity());
  yield takeLatest(OfferActions.getNextOfferActivity, getNextOfferActivity);
  yield takeLatest(OfferActions.sendOfferStamp, sendOfferStamp);
  yield takeLatest(OfferActions.sendOfferImageComment, sendOfferImageComment);
  yield takeLatest(OfferActions.readOffer, readOffer);
  yield takeLatest(OfferActions.handleNewOfferAcceptedFiles, handleNewOfferAcceptedFiles);
  yield takeLatest(OfferActions.handleEditOfferAcceptedFiles, handleEditOfferAcceptedFiles);
  yield takeLatest(OfferActions.sendNewOffer, sendNewOffer);
  yield takeLatest(OfferActions.updateEditOffer, updateEditOffer);
  yield takeLatest(OfferActions.getStoreCompletedOffers, () => getStoreCompletedOffers());
  yield takeLatest(OfferActions.getStoreCompletedOfferAddRead, getStoreCompletedOfferAddRead);
}

const PAGE_LIMIT = 20;

export function* getStoreOffers() {
  const response: YieldReturn<typeof OfferStoreApi.get> = yield OfferStoreApi.get();
  if (response.isSuccess) {
    yield put(OfferActions.setStoreOffers(response.data));
  } else {
    toast({
      type: 'error',
      title: '依頼 / 報告の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getOffer(action: ReturnType<typeof OfferActions.getOffer>) {
  const response: YieldReturn<typeof OfferApi.get> = yield OfferApi.get(action.payload);
  if (response.isSuccess) {
    yield put(OfferActions.setOffer(response.data));
  } else {
    if (response.status !== 403 && response.status !== 404) {
      toast({
        type: 'error',
        title: '依頼の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }

    // 詳細ページを表示できないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.offerGroups.index));
  }
}

function* getEditOffer(action: ReturnType<typeof OfferActions.getEditOffer>) {
  const response: YieldReturn<typeof OfferApi.get> = yield OfferApi.get(action.payload);
  if (response.isSuccess) {
    yield put(OfferActions.setEditOffer(response.data));
  } else {
    if (response.status !== 403 && response.status !== 404) {
      toast({
        type: 'error',
        title: '依頼の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }

    // 詳細ページを表示できないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.offerGroups.index));
  }
}

function* getOfferTimeline(action: ReturnType<typeof OfferActions.getOfferTimeline>) {
  const offerId = action.payload;
  const response: YieldReturn<typeof OfferTimelineApi.get> = yield OfferTimelineApi.get(offerId);
  if (response.isSuccess) {
    yield put(OfferActions.setOfferTimeline(response.data));
  } else if (response.status !== 403 && response.status !== 404) {
    // 403か404の場合は依頼自体の取得に失敗して一覧に遷移しているので、エラーメッセージを表示しない
    toast({
      type: 'error',
      title: 'メッセージの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* sendNewComment(action: ReturnType<typeof OfferActions.sendNewComment>) {
  yield put(AppActions.setLoading(true));

  const { offerItem, newComment }: { offerItem: OfferItem; newComment: Comment } = yield select((state: State) => ({
    offerItem: state.offer.offerItem,
    newComment: state.offer.newComment,
  }));

  const offerId = offerItem.offer.id;
  const params = newComment
    .set('offer_id', offerId)
    .setIn(['content', 'tags'], List(offerItem.provisionalHashtag))
    .requestParams();
  const response: YieldReturn<typeof OfferCommentApi.post> = yield OfferCommentApi.post(offerId, params);

  if (response.isSuccess) {
    yield put(OfferActions.getOffer(offerId));
    yield put(OfferActions.getOfferTimeline(offerId));
    yield put(OfferActions.clearNewComment());
    yield put(OfferActions.readOffer(offerId));
    toast({
      type: 'success',
      title: '送信が完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'コメントに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* acceptTask(action: ReturnType<typeof OfferActions.acceptTask>) {
  yield put(AppActions.setLoading(true));

  const offerItem: OfferItem = yield select((state: State) => state.offer.offerItem);
  const offerId = offerItem.offer.id;
  const response: YieldReturn<typeof OfferDoneApi.post> = yield OfferDoneApi.post(offerId);
  if (response.isSuccess) {
    yield put(OfferActions.getOffer(offerId));
    yield put(OfferActions.getOfferTimeline(offerId));
    yield put(OfferActions.getOfferActivity());
    toast({
      type: 'success',
      title: 'タスクを完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'タスクの完了に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* uploadNewCommentFile(action: ReturnType<typeof OfferActions.uploadNewCommentFile>) {
  const files = action.payload;
  const urls: string[] = yield call(onDropAccepted, files);
  let newComment: Comment = yield select((state: State) => state.offer.newComment);
  newComment = newComment.addFiles(urls);
  yield put(OfferActions.setNewComment(newComment));
}

export function* getOfferActivity() {
  const response: YieldReturn<typeof OfferLatestApi.get> = yield OfferLatestApi.get();

  if (response.isSuccess) {
    yield put(OfferActions.setOfferActivity(new OfferActivities(response.data)));
  } else {
    toast({
      type: 'error',
      title: '直近のやりとりの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getNextOfferActivity(_: ReturnType<typeof OfferActions.getNextOfferActivity>) {
  yield put(AppActions.setLoading(true));
  const offerActivities: OfferActivities = yield select((state: State) => state.offer.offerActivities);
  const params = { page: offerActivities.current_page + 1 };
  const response: YieldReturn<typeof OfferLatestApi.get> = yield OfferLatestApi.get(params);

  if (response.isSuccess) {
    let nextOfferActivities = new OfferActivities(response.data);
    nextOfferActivities = nextOfferActivities.update('offers', (offers) => offerActivities.offers.merge(offers));
    yield put(OfferActions.setNextOfferActivity(nextOfferActivities));
  } else {
    toast({
      type: 'error',
      title: '直近のやりとりの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

function* sendOfferStamp(action: ReturnType<typeof OfferActions.sendOfferStamp>) {
  const stamp_id = action.payload;
  const offerItem: OfferItem = yield select((state: State) => state.offer.offerItem);
  const offerId = offerItem.offer.id;
  const params = { stamp_id };
  const response: YieldReturn<typeof OfferStampApi.post> = yield OfferStampApi.post(offerId, params);
  if (response.isSuccess) {
    yield put(OfferActions.getOfferTimeline(offerId));
    yield put(OfferActions.readOffer(offerId));
    toast({
      type: 'success',
      title: 'スタンプを送信しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'スタンプの送信に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* sendOfferImageComment(action: ReturnType<typeof OfferActions.sendOfferImageComment>) {
  const offerImageComment = action.payload;
  const offerItem: OfferItem = yield select((state: State) => state.offer.offerItem);
  const offerId = offerItem.offer.id;
  const params = offerImageComment.requestParams();
  const response: YieldReturn<typeof OfferImageCommentApi.post> = yield OfferImageCommentApi.post(offerId, params);
  if (response.isSuccess) {
    yield put(OfferActions.getOfferTimeline(offerId));
    yield put(OfferActions.readOffer(offerId));
    toast({
      type: 'success',
      title: '画像コメントを送信しました',
    });
  } else {
    toast({
      type: 'error',
      title: '画像コメントの送信に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* readOffer(action: ReturnType<typeof OfferActions.readOffer>) {
  const offerId = action.payload;
  const response: YieldReturn<typeof OfferReadApi.post> = yield OfferReadApi.post(offerId);
  if (response.isSuccess) {
    yield put(OfferActions.getOfferActivity());
  } else {
    toast({
      type: 'error',
      title: '既読の送信に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* handleNewOfferAcceptedFiles(action: ReturnType<typeof OfferActions.handleNewOfferAcceptedFiles>) {
  const files = action.payload;
  const urls: string[] = yield call(onDropAccepted, files);
  const newOffer: OfferStores = yield select((state: State) => state.offer.newOffer);
  yield put(OfferActions.changeNewOffer(newOffer.addFiles(urls)));
}

function* handleEditOfferAcceptedFiles(action: ReturnType<typeof OfferActions.handleEditOfferAcceptedFiles>) {
  const files = action.payload;
  const urls: string[] = yield call(onDropAccepted, files);
  const editOffer: OfferCommon = yield select((state: State) => state.offer.editOffer);
  yield put(OfferActions.changeEditOffer(editOffer.addFiles(urls)));
}

function* sendNewOffer(_: ReturnType<typeof OfferActions.sendNewOffer>) {
  yield put(AppActions.setLoading(true));

  const newOffer: OfferStores = yield select((state: State) => state.offer.newOffer);
  const params = newOffer.requestParams();

  let response: YieldReturn<typeof TasksApi.post> | YieldReturn<typeof OfferStoreApi.post>;
  switch (newOffer.offer.offer_type) {
    case 'task':
      response = yield TasksApi.post(params);
      break;
    case 'report': {
      const storeId = newOffer.storeId;
      response = yield OfferStoreApi.post(storeId, params);
      break;
    }
    case 'other_offer': {
      const storeId = newOffer.storeId;
      response = yield OfferStoreApi.post(storeId, params);
      break;
    }
  }

  const failureToast = (response: any) => ({
    type: 'error' as const,
    title: '依頼・報告の送信に失敗しました',
    description: String(response.error.message),
    time: 10000,
  });

  if (!response.isSuccess) {
    toast(failureToast(response));
    yield put(AppActions.setLoading(false));
    return;
  }

  if (newOffer.offer.offer_type === 'task') {
    const taskId = (response.data as any).id;
    const requestBulkParams = newOffer.requestBulkParams();

    if (newOffer.destinationType === DestinationType.Unclosed) {
      // 送信先が「全ての店舗（閉業店舗を除く）」の場合、
      // 全ての店舗から閉業店舗を除いた店舗のstore_idを、パラメータのstore_idsにセットする
      const Stores: StoresModel = yield select((state: State) => state.store.stores);
      requestBulkParams.store_ids = Stores.getUnclosedStoreIds();
    }

    const bulkResponse: YieldReturn<typeof TasksOfferBulkApi.post> = yield TasksOfferBulkApi.post(
      taskId,
      requestBulkParams,
    );

    if (!bulkResponse.isSuccess) {
      toast(failureToast(bulkResponse));
      yield put(AppActions.setLoading(false));
      return;
    }
  }

  yield put(OfferActions.getStoreOffers());

  // USE_OLD_VERSION_UIの値によって遷移先を変える
  yield put(AppActions.moveTo(Path.offerGroups.index));

  yield put(OfferActions.clearEditOffer());
  toast({
    type: 'success',
    title: '依頼・報告を作成しました',
  });

  yield put(AppActions.setLoading(false));
}

function* updateEditOffer(_: ReturnType<typeof OfferActions.updateEditOffer>) {
  yield put(AppActions.setLoading(true));

  const editOffer: OfferCommon = yield select((state: State) => state.offer.editOffer);
  const params = editOffer.requestParams();
  const response: YieldReturn<typeof OfferApi.put> = yield OfferApi.put(editOffer.offer.id, params);

  if (response.isSuccess) {
    yield put(OfferActions.getStoreOffers());
    yield put(OfferActions.getOffer(editOffer.offer.id));
    // 依頼の場合、依頼詳細画面に戻す
    const path = editOffer.isTaskType
      ? Path.task.detail.replace(/:taskId\([^)]*\)/g, String(editOffer.task.id))
      : Path.offer.detail.replace(':offerId', String(editOffer.offer.id));
    yield put(AppActions.moveTo(path));
    yield put(OfferActions.clearEditOffer());
  } else {
    toast({
      type: 'error',
      title: '依頼・報告の更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

export function* getStoreCompletedOffers() {
  const response: YieldReturn<typeof OfferStoreApi.get> = yield OfferStoreApi.get({
    status: 'done',
    limit: PAGE_LIMIT,
  });
  if (response.isSuccess) {
    yield put(OfferActions.setStoreCompletedOffers(response.data));
  } else {
    toast({
      type: 'error',
      title: '完了済みタスクの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getStoreCompletedOfferAddRead(action: ReturnType<typeof OfferActions.getStoreCompletedOfferAddRead>) {
  yield put(AppActions.setLoading(true));
  const response: YieldReturn<typeof OfferStoreApi.get> = yield OfferStoreApi.get(
    {
      status: 'done',
      limit: 10,
      page: action.payload.currentPage + 1,
    },
    action.payload.storeId,
  );
  if (response.isSuccess) {
    yield put(OfferActions.setStoreCompletedOfferAddRead(response.data));
  } else {
    toast({
      type: 'error',
      title: '完了済みタスクの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}
