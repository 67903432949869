import { List, Map, Record, Set } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { GbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';
import { ServiceType } from 'models/Domain/GmbLocation/Service';
import { GmbLocationDiff, LocationDiffKey, diffGroupMap } from 'models/Domain/GmbLocationDiffs';
import { GmbLocationUpdates } from 'models/Domain/GmbLocationUpdates';

import { GmbLocationUpdatesActions } from './actions';
import { DiffItemFilterType, UpdateType, allDiffItemFilters } from './types';

export type CheckedItemsType = Set<GmbLocationDiff>;

// filterがどのキーに対応しているか
const filterKeysMap: Map<DiffItemFilterType, Set<LocationDiffKey>> = Object.entries(diffGroupMap).reduce(
  (current, [key, group]) => {
    return current.update(group, (s) => s.add(key as LocationDiffKey));
  },
  Map({
    基本情報: Set(),
    営業情報: Set(),
    属性情報: Set(),
    サービス情報: Set(),
  }) as Map<DiffItemFilterType, Set<LocationDiffKey>>,
);

/**
 * 絞り込み条件から差分情報を絞り込むキーを取得する
 * @param filters 絞り込み条件
 * @returns 差分情報を絞り込むキーのセット
 */
const getFilterKeys = (filters: Set<DiffItemFilterType>): Set<LocationDiffKey> =>
  filters
    .reduce((current, filter) => current.push(filterKeysMap.get(filter) ?? Set()), List<Set<LocationDiffKey>>())
    .flatten()
    .toSet();

export interface StateRecord {
  // 初期化が完了したか
  initialized: boolean;
  // 差分情報
  locationUpdates: GmbLocationUpdates;
  // チェックボックスの選択状態(非表示状態の項目を含む)
  checkedItems: CheckedItemsType;
  // 更新済みの項目
  updatedItems: Map<GmbLocationDiff, UpdateType>;
  // 選択済みのフィルター
  filters: Set<DiffItemFilterType>;
  storeFilter: Set<number>;

  // 属性のラベル表示用
  attributeMetadatas: { [categoryId: string]: GmbAttributeMetadatas };
  availableUrlTypes: { [storeId: number]: GbpAvailableUrlTypes };
  moreHoursTypes: { [categoryId: string]: List<MoreHoursType> };
  serviceTypes: { [categoryId: string]: List<ServiceType> };
}

export class GmbLocationUpdatesState extends Record<StateRecord>({
  initialized: false,
  locationUpdates: new GmbLocationUpdates(),
  checkedItems: Set<GmbLocationDiff>(),
  updatedItems: Map<GmbLocationDiff, UpdateType>(),
  filters: Set(allDiffItemFilters),
  storeFilter: Set(),
  attributeMetadatas: {},
  availableUrlTypes: {},
  moreHoursTypes: {},
  serviceTypes: {},
}) {
  /**
   * 絞り込まれた項目
   */
  get filterItems() {
    // 全ての差分項目を、絞り込み条件で絞り込んだ上で、更新済み項目を抜いたもの
    const filterKeys = getFilterKeys(this.filters);
    return this.locationUpdates.allStoresDiffs
      .filter((v) => filterKeys.has(v.key))
      .filter((v) => this.storeFilter.isEmpty() || this.storeFilter.has(v.store_id))
      .toSet();
  }

  /**
   * 選択可能な項目
   */
  get selectableItems() {
    return this.filterItems.subtract(this.updatedItems.keySeq().toSet()).filter((item) => item.key !== 'serviceItems');
  }

  get filteredCheckedItems() {
    const filterKeys = getFilterKeys(this.filters);
    let filteredCheckedItems = this.checkedItems;
    filteredCheckedItems = filteredCheckedItems.filter((checkedItem) => filterKeys.has(checkedItem.key));
    if (!this.storeFilter.isEmpty()) {
      filteredCheckedItems = filteredCheckedItems.filter((checkedItem) => this.storeFilter.has(checkedItem.store_id));
    }

    return filteredCheckedItems;
  }
}

export const gmbLocationUpdatesReducer = reducerWithInitialState(new GmbLocationUpdatesState())
  .case(GmbLocationUpdatesActions.setInitialized, (state, payload) => {
    return state.set('initialized', true);
  })
  .case(GmbLocationUpdatesActions.setGmbLocationUpdates, (state, payload) => {
    return state.set('locationUpdates', payload);
  })
  .case(GmbLocationUpdatesActions.setCheckedItems, (state, payload) => {
    return state.set('checkedItems', payload);
  })
  .case(GmbLocationUpdatesActions.setAttributeMetadatas, (state, payload) => {
    const { attributeMetadatas } = payload;
    return state.set('attributeMetadatas', attributeMetadatas);
  })
  .case(GmbLocationUpdatesActions.setAvailableURLTypes, (state, payload) => {
    return state.set('availableUrlTypes', payload);
  })
  .case(GmbLocationUpdatesActions.setCategoryDatas, (state, payload) => {
    const { moreHoursTypes, serviceTypes } = payload;
    return state.merge({ moreHoursTypes, serviceTypes });
  })
  .case(
    GmbLocationUpdatesActions.setUpdatedItems,
    (state, payload: { diffs: List<GmbLocationDiff>; type: UpdateType }) => {
      const { diffs, type } = payload;
      // 更新した項目はチェック状態を解除する
      const checkedItems = diffs.reduce((current, diff) => current.delete(diff), state.checkedItems);
      // 更新済みにマーク
      const updatedItems = diffs.reduce((current, diff) => current.set(diff, type), state.updatedItems);
      return state.merge({ checkedItems, updatedItems });
    },
  )
  .case(GmbLocationUpdatesActions.resetItem, (state, payload) => {
    const { target } = payload;
    return state.update('updatedItems', (updatedItems) => updatedItems.delete(target));
  })
  .case(GmbLocationUpdatesActions.toggleFilter, (state, payload) => {
    const { value } = payload;
    const filters = state.filters.has(value) ? state.filters.delete(value) : state.filters.add(value);
    return state.set('filters', filters);
  })
  .case(GmbLocationUpdatesActions.setStoreFilter, (state, payload) => {
    const { storeIds } = payload;
    return state.set('storeFilter', storeIds);
  });
