import { List, Record, is } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { GmbAttributeMetadata, GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttributes } from 'models/Domain/GmbLocation/GmbAttributes';
import { GmbProfile } from 'models/Domain/GmbLocation/GmbProfile';
import { GmbRegularHours } from 'models/Domain/GmbLocation/GmbRegularHours';
import { GmbSpecialHours } from 'models/Domain/GmbLocation/GmbSpecialHours';
import { DayOfWeek } from 'models/Domain/GmbLocation/GmbTimePeriod';
import { Store, Stores } from 'models/Domain/Store';

import { BulkEditStoresActions } from '../bulkEditStores/actions';

type BulkEditStoresStateType = {
  initialized: boolean;
  stores: Stores;
  initialStores: Stores; // 変更検知用 受け取った時点のstoresを保っておく
  attributeMetadatas: { [categoryId: string]: GmbAttributeMetadatas };
};

const initialState = {
  initialized: false,
  stores: new Stores(),
  initialStores: new Stores(),
  attributeMetadatas: {},
};

export class BulkEditStoresState extends Record<BulkEditStoresStateType>(initialState) {
  resetWithoutStores() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { stores, initialStores, ...initialStateWithoutStores } = initialState;
    return this.merge(initialStateWithoutStores);
  }

  /** 引数に受け取ったstoresと違いがあるかどうか */
  get hasDifference() {
    return this.numberOfModifiedStores > 0;
  }

  /** 変更がある店舗の数 */
  get numberOfModifiedStores() {
    return this.stores.list.filter((store, index) => !is(store, this.initialStores.list.get(index))).size;
  }

  /** カテゴリごとの属性をマージ */
  get mergedAttributesMetadatas() {
    const result = new GmbAttributeMetadatas();
    const targetList = List(Object.keys(this.attributeMetadatas).map((t) => this.attributeMetadatas[t].list));
    const resultList = targetList.reduce((accumulator: List<GmbAttributeMetadata>, currentValue) => {
      const filtered = currentValue.filter((t) => {
        return accumulator.find((a) => a.attributeId === t.attributeId) === undefined;
      });
      if (!accumulator) {
        return currentValue;
      }
      return accumulator.concat(filtered);
    });
    return result.setList(resultList);
  }

  changeStores(stores: Stores) {
    // 店舗コード順で表示・編集するため、初期化時点でソートを行う
    return this.set('stores', stores.sortBy('code_asc')).set('initialStores', stores.sortBy('code_asc'));
  }

  changeRegularHours(index: number, editType: DayOfWeek, regularHours: GmbRegularHours) {
    const periods = regularHours.getPeriods(editType);
    return this.setIn(
      ['stores', 'list', index, 'location', 'regularHours'],
      regularHours.changePeriods(editType, periods),
    );
  }

  changeSpecialHours(index: number, specialHours: GmbSpecialHours) {
    return this.setIn(['stores', 'list', index, 'location', 'specialHours'], specialHours);
  }

  bulkChangeSpecialHours(specialHours: GmbSpecialHours) {
    return this.setIn(
      ['stores', 'list'],
      this.stores.list.map((store, idx) => {
        return store.setIn(
          ['location', 'specialHours'],
          // 過去の日付のデータを除外し、既存の設定と新しい設定をマージする
          store.location.specialHours.removePastDate().mergeSpecialHours(specialHours),
        );
      }),
    );
  }

  /**
   * ビジネスの情報を一括で変更する
   * @param profile 設定したいビジネスの情報
   */
  bulkChangeProfile(profile: GmbProfile) {
    return this.setIn(
      ['stores', 'list'],
      this.stores.list.map((store) => store.setIn(['location', 'profile'], profile)),
    );
  }

  changeOpenInfo(index: number, status: string) {
    return this.setIn(['stores', 'list', index, 'location', 'openInfo', 'status'], status);
  }

  /**
   * ビジネスの情報を変更する
   * @param index 対象店舗のリスト内の位置
   * @param value 設定するビジネスの情報
   */
  changeProfile(index: number, value: GmbProfile) {
    return this.setIn(['stores', 'list', index, 'location', 'profile'], value);
  }

  requestParams() {
    return {
      store_info_list: this.stores.list
        .filter((store, index) => !is(store, this.initialStores.list.get(index))) // 変更ありの店舗に限定する
        .map((store: Store) => {
          // ビジネス情報のエイリアスを実際のテキストに変換する
          const profile = store.location.updateProfileParams().profile;

          return {
            store_id: store.id,
            store_info: {
              regularHours: store.location.regularHours.updateParams,
              specialHours: store.location.specialHours.updateParams,
              openInfo: store.location.updateOpenInfoParams().openInfo,
              attributes: store.location.updateAttributesParams().attributes,
              profile: { ...profile, description: store.getAliasRestoredText(profile.description) },
            },
          };
        })
        .toArray(),
    };
  }

  /**
   * 店舗の属性情報を変更する (URL属性は更新しない)
   * @param index 対象店舗のリスト内の位置
   * @param attributes 設定する属性のリスト
   * @returns
   */
  changeAttributes(index: number, attributes: GmbAttributes) {
    return this.updateIn(['stores', 'list', index], (store: Store) => store.changeAttributes(attributes));
  }

  /**
   * 店舗の属性情報を一括で変更する (URL属性は更新しない)
   * @param index 対象店舗のリスト内の位置
   * @param attributes 設定する属性のリスト
   * @returns
   */
  bulkChangeAttributes(targetAttributeIds: List<string>, attributes: GmbAttributes) {
    // 推測される型で問題ないことを確認したいので、あえてupdate x updateで更新している
    // stores.listを更新する
    return this.update('stores', (stores) =>
      stores.updateList((storeList) =>
        storeList.map((store) => {
          const metaData = this.attributeMetadatas[store.location.primaryCategory.categoryId];

          if (!metaData) {
            return store;
          }
          // 対象のカテゴリー以外の属性を除外
          const updateList = attributes.list.filter((attribute) => {
            return (
              !!metaData.list.find((data) => data.attributeId === attribute.attributeId) &&
              targetAttributeIds.indexOf(attribute.attributeId) !== -1
            );
          });

          // 更新対象の属性を取り除く
          const target = new GmbAttributes(store.location.attributes);
          const targetList = target.list.filter((t) => targetAttributeIds.indexOf(t.attributeId) === -1);

          // カテゴリーが同一の場合、属性を更新する
          return store.changeAttributes(target.set('list', targetList.merge(updateList)));
        }),
      ),
    );
  }

  /**
   * グループ内で最頻出のカテゴリーのIDを返す
   * @returns 最頻出のカテゴリーのIDを返す
   */
  getMostCommonCategoryId() {
    return this.stores.getMostCommonCategoryId();
  }

  /**
   * 最も店舗名が長い店舗を返す
   * @return 最も店舗名が長い店舗を1件返す
   */
  getStoreHavingLongestStoreName() {
    return this.stores.list
      .sortBy((store) => store.fullName.length)
      .reverse()
      .get(0);
  }
}

export const bulkEditStoresReducer = reducerWithInitialState(new BulkEditStoresState())
  .case(BulkEditStoresActions.setInitialized, (state, payload) => {
    return state.set('initialized', payload);
  })
  .case(BulkEditStoresActions.setStores, (state, payload) => {
    const { stores } = payload;
    return state.changeStores(stores);
  })
  .case(BulkEditStoresActions.resetStateWithoutStores, (state, payload) => {
    return state.resetWithoutStores();
  })
  .case(BulkEditStoresActions.changeOpenInfo, (state, payload) => {
    const { index, value } = payload;
    return state.changeOpenInfo(index, value);
  })
  .case(BulkEditStoresActions.changeSpecialHours, (state, payload) => {
    const { index, specialHours } = payload;
    return state.changeSpecialHours(index, specialHours);
  })
  .case(BulkEditStoresActions.bulkChangeSpecialHours, (state, payload) => {
    const { specialHours } = payload;
    return state.bulkChangeSpecialHours(specialHours);
  })
  .case(BulkEditStoresActions.changeRegularHours, (state, payload) => {
    const { index, dayOfWeek, regularHours } = payload;
    return state.changeRegularHours(index, dayOfWeek, regularHours);
  })
  .case(BulkEditStoresActions.setAttributeMetadatas, (state, payload) => {
    const { attributeMetadatas } = payload;
    return state.set('attributeMetadatas', attributeMetadatas);
  })
  .case(BulkEditStoresActions.changeAttributes, (state, payload) => {
    const { index, attributes } = payload;
    return state.changeAttributes(index, attributes);
  })
  .case(BulkEditStoresActions.bulkChangeAttributes, (state, payload) => {
    const { targetAttributeIds, attributes } = payload;
    return state.bulkChangeAttributes(targetAttributeIds, attributes);
  })
  .case(BulkEditStoresActions.changeProfile, (state, payload) => {
    const { index: row, profile } = payload;
    return state.changeProfile(row, profile);
  })
  .case(BulkEditStoresActions.bulkChangeProfile, (state, payload) => {
    const { profile } = payload;
    return state.bulkChangeProfile(profile);
  });
