import ApiClient from 'ApiClient';
import { JSObject } from 'types/Common';
import { StoreImagesDetailGetResponse, StoreImagesTagsGetResponse } from 'types/responses/StoreImages';

const apiClient = new ApiClient({});

const STORE_IMAGE_PATH = '/storeimages';
const STORE_IMAGE_TAGS_PATH = `${STORE_IMAGE_PATH}/tags`;
const STORE_IMAGE_DETAIL_PATH = (imageId: number) => `${STORE_IMAGE_PATH}/${String(imageId)}`;
const STORE_IMAGE_POST_GMB_PATH = `${STORE_IMAGE_PATH}/bulk_post_gmb`;
const STORE_IMAGE_UPDATE_HASHTAG = (imageId: number) => `${STORE_IMAGE_PATH}/${String(imageId)}`;
const STORE_IMAGE_UPDATE_CATEGORY_PATH = (imageId: number) => `${STORE_IMAGE_PATH}/${String(imageId)}/update_gmb`;
const STORE_IMAGE_DISCONNECT_MULTI_PATH = `${STORE_IMAGE_PATH}/disconnect_gbp`;

export class StoreImagesDetailApi {
  static get(imageId: number) {
    return apiClient.get<StoreImagesDetailGetResponse>(STORE_IMAGE_DETAIL_PATH(imageId));
  }

  static delete(imageId: number) {
    return apiClient.delete<null>(STORE_IMAGE_DETAIL_PATH(imageId));
  }
}

export class StoreImagesPostGmbApi {
  static post(params: JSObject) {
    return apiClient.post<null>(STORE_IMAGE_POST_GMB_PATH, params);
  }
}

export class StoreImagesDisconnectApi {
  static post(imageIds: number[]) {
    return apiClient.post(STORE_IMAGE_DISCONNECT_MULTI_PATH, { image_ids: imageIds });
  }
}

export class StoreImagesTagsApi {
  static get() {
    return apiClient.get<StoreImagesTagsGetResponse>(STORE_IMAGE_TAGS_PATH);
  }
}

export class StoreImagesUpdateHashtag {
  static patch(imageResourceId: number, params: JSObject) {
    return apiClient.patch(STORE_IMAGE_UPDATE_HASHTAG(imageResourceId), params);
  }
}

export class StoreImagesUpdateCategoryApi {
  static post(imageResourceId: number, params: JSObject) {
    return apiClient.post<null>(STORE_IMAGE_UPDATE_CATEGORY_PATH(imageResourceId), params);
  }
}

export class StoreImagesApi {
  static post(params: JSObject) {
    return apiClient.post(STORE_IMAGE_PATH, params);
  }
}

/**
 * 画像検索APIのパラメータ
 */
export type SearchStoreImagesParams = {
  store_ids?: number[];
  tag?: string;
  start_date?: string;
  end_date?: string;
  state?: ('published' | 'unpublished')[];
  sort_key?: 'date';
  sort_order?: 'asc' | 'desc';
  page?: number;
  limit?: number;
  gbp_category?: string;
};

/**
 * 画像検索APIのレスポンス
 */
export type SearchStoreImagesResponse = {
  images: {
    organization_id: number;
    image_resource_id: number;
    url: string;
    tags: string[];
    create_at: number;
    gbp_categories: string[];
    stores: {
      store_id: number;
      store_name: string;
      is_connected_gmb: boolean;
    }[];
    stores_count: number;
    published_gmb_stores_count: number;
  }[];
  pagination: {
    current_page: number;
    has_next: boolean;
    total_count: number;
    limit: number;
  };
};

export class SearchStoreImagesApi {
  /**
   * 画像検索APIの実行
   * @param params パラメータ
   * @returns
   */
  static get(params: SearchStoreImagesParams) {
    const _params: any = { ...params };
    if (params.store_ids) {
      _params.store_ids = params.store_ids.join(',');
    }
    if (params.state) {
      _params.state = params.state.join(',');
    }
    return apiClient.get<SearchStoreImagesResponse>(STORE_IMAGE_PATH, _params);
  }
}
