import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

/**
 * テンプレート本文に入る埋め込みテキストor店舗記入欄のラベル
 * 置換対象であることを {{ }} で囲んで表す
 **/
export const TEMPLATE_ALIASES = {
  storeName: '{{店舗名}}',
  storeManagerName: '{{アカウント名}}',
  reviewerName: '{{レビュワー氏名}}',
};

export type ParsedTemplate =
  | {
      type: 'Text';
      value: string;
    }
  | {
      type: 'StoreEntry'; // 店舗記入欄
      value: string;
    }
  | {
      type: 'Alias';
      value: string;
      alias: string;
    };

/**
 * クチコミの返信テンプレート
 */
export class GmbReviewTemplate extends Record<{
  template_id: string;
  organization_id: number;
  title: string;
  body: string;
}>({
  template_id: '',
  organization_id: 0,
  title: '',
  body: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  createParam() {
    return {
      title: this.title,
      body: this.body,
    };
  }

  updateParam() {
    return {
      template_id: this.template_id,
      organization_id: this.organization_id,
      title: this.title,
      body: this.body,
    };
  }

  setTitle(value: string) {
    return this.set('title', value);
  }

  setBody(value: string) {
    return this.set('body', value);
  }
  isSaved() {
    return this.template_id !== '';
  }

  /**
   * テンプレートを「テキスト」「店舗記入欄」「エイリアス」の要素に分解する
   * @param params エイリアスに埋め込むテキスト
   */
  parse(params: { [key in keyof typeof TEMPLATE_ALIASES]: string }): ParsedTemplate[] {
    // {{xxx}}で分割(結果にそれ自身を含む)
    const regexp = new RegExp(/({{(?:(?!{{)[^])+}})/);

    return this.body
      .split(regexp)
      .filter((value) => value)
      .map((value) => {
        for (const entry of Object.entries(TEMPLATE_ALIASES)) {
          const aliasKey = entry[0] as keyof typeof TEMPLATE_ALIASES;
          const alias = entry[1];
          if (value == alias) {
            return { type: 'Alias', value: params[aliasKey], alias };
          }
        }

        if (value.match(regexp)) {
          return { type: 'StoreEntry', value };
        }

        return { type: 'Text', value };
      });
  }

  /**
   * テンプレートのエイリアスに値を埋め込んだ文章を返す
   * @param params エイリアスに埋め込むテキスト
   */
  fill(params: { [key in keyof typeof TEMPLATE_ALIASES]: string }) {
    return this.parse(params)
      .map((pt) => pt.value)
      .join('');
  }
}

export class GmbReviewTemplates extends Record<{
  list: List<GmbReviewTemplate>;
}>({
  list: List(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.list = List(
      data.templates
        ? data.templates.map((target: JSObject) => {
            return new GmbReviewTemplate(target);
          })
        : [],
    );
    super(params);
  }
}
