import React, { useCallback, useState } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Arrow } from 'components/atoms/Arrow';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { Stars } from 'components/atoms/Stars';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { TextArea } from 'components/atoms/TextArea';
import { GmbReviewReplyConfirmModal } from 'components/molecules/GmbReviewReplyConfirmModal';
import { GmbReviewTemplateModal } from 'components/organisms/GmbReviewTemplateModal';
import { ReviewReplyGenerateModal } from 'components/pageComponents/GmbReview/ReviewGenerateModal';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyOnce';
import { GmbActions } from 'modules/gmb/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';

const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export const GmbReviewDetail = React.memo(() => {
  const { reviewId } = useParams<{ reviewId: string }>();
  const { state } = useLocation();
  const {
    gmb: {
      reviewDetail: { store_id, replied_by, review, status, can_update, isDeleted, deleteAt },
      isLoadingReview,
    },
    store: { stores },
    app: { currentUser },
  } = useSelector((state) => ({
    gmb: state.gmb,
    store: state.store,
    app: state.app,
  }));

  const [replyComment, setReplyComment] = useState<string>('');
  const [isReplyEdit, setIsReplyEdit] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState<boolean>(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState<boolean>(false);
  const [showValidationError, setShowValidationError] = useState(false);

  const dispatch = useDispatch();

  useEffectOnlyOnce(() => {
    dispatch(GmbActions.getReview(reviewId));
  });

  const validateSendReplyComment =
    (!replyComment && '返信内容を記入してください') ||
    ((replyComment.includes('{{') || replyComment.includes('}}')) &&
      '{{  }}で囲われた箇所について、{{  }}を削除し該当箇所に店舗の返信内容を記入してください') ||
    '';

  const sendReplyComment = useCallback(
    (text) => dispatch(GmbActions.sendReviewReply({ reviewId: review.reviewId, text })),
    [dispatch, review.reviewId],
  );

  const changeStatus = useCallback(
    (status: string) => dispatch(GmbActions.changeReviewStatus({ gmb_review_id: review.reviewId, status })),
    [dispatch, review.reviewId],
  );

  const store = stores.findStore(store_id);

  const onApplyTemplate = useCallback(
    (body: string) => {
      if (
        replyComment &&
        !window.confirm(`返信用コメントを選択したテンプレートの内容で上書きします。\nよろしいですか？`)
      ) {
        return;
      }
      setReplyComment(body);
      setShowValidationError(true);
      setIsTemplateModalOpen(false);
    },
    [replyComment, setReplyComment],
  );

  const handleOnClickBackButton = useCallback(() => {
    // 遷移元がクチコミ一覧なら検索条件やスクロール位置を保持するために戻る、それ以外ならクチコミ一覧に遷移
    if (state?.source === Path.gmb.review) {
      dispatch(goBack());
    } else {
      dispatch(replaceWithOrganizationId(Path.gmb.review));
    }
  }, [dispatch, state?.source]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('クチコミ詳細')} />
      <StickyHeader>
        <TitleWrapper onClick={handleOnClickBackButton}>
          <Arrow direction={'left'} color={'#393939'} length={16} weight={3} />
          <Title>{store?.fullName ?? 'クチコミ'}</Title>
        </TitleWrapper>
      </StickyHeader>
      <Body>
        {isLoadingReview ? (
          <CardWrapper>
            <LoadingWrapper>
              <LoadingWrapperBase />
              <Loader active={true} size={'big'} inline={true} />
            </LoadingWrapper>
          </CardWrapper>
        ) : (
          <CardWrapper>
            <ReviewStatusWrapper>
              <ChangeStatusWrapper>
                <PullDown
                  value={status}
                  options={[
                    { text: '未対応', value: 'todo' },
                    { text: '対応済', value: 'done' },
                    { text: '対応しない', value: 'not_replied' },
                  ]}
                  onChange={(value: string) => {
                    changeStatus(value);
                  }}
                  disabled={!can_update}
                />
              </ChangeStatusWrapper>
              {isDeleted && (
                <ReviewDeleted>
                  <DeleteIcon />
                  削除されたクチコミ
                </ReviewDeleted>
              )}
            </ReviewStatusWrapper>

            <StatusWrapper>
              <StatusContainer>
                <TitleLabel>{review.reviewer.name} さん</TitleLabel>
                <Stars starRating={review.starRating} />
              </StatusContainer>
              <StatusContainer>
                <DateWrapper>
                  <DateLabel>投稿日</DateLabel>
                  <DateValue>{review.createTime.format(DATE_FORMAT)}</DateValue>
                </DateWrapper>
                {/*投稿日と更新日が異なる場合は、最終更新日を表示*/}
                {!review.updateTime.isSame(review.createTime) && (
                  <DateWrapper>
                    <DateLabel>最終更新日</DateLabel>
                    <DateValue>{review.updateTime.format(DATE_FORMAT)}</DateValue>
                  </DateWrapper>
                )}
                {/*削除されている場合は、削除確認日を表示*/}
                {deleteAt && (
                  <DateWrapper>
                    <DateLabel>削除確認日</DateLabel>
                    <DateValue>{deleteAt.format(DATE_FORMAT)}</DateValue>
                  </DateWrapper>
                )}
              </StatusContainer>
            </StatusWrapper>
            <MainText>{review.comment}</MainText>
            {review.hasReply && (
              <ReplyWrapper>
                {replied_by.fullName}
                <Reply>{review.reviewReply.comment}</Reply>
                <DateValue>{review.reviewReply.updateTime.format(DATE_FORMAT)}</DateValue>
                {can_update && !isReplyEdit && (
                  <ButtonWrapper>
                    {isDeleted && <DisabledMessage>対象のクチコミが削除済みのため、編集できません</DisabledMessage>}
                    <StyledButton
                      priority='high'
                      onClick={() => {
                        setIsReplyEdit(true);
                        setReplyComment(review.reviewReply.comment);
                      }}
                      disabled={isDeleted}
                    >
                      編集する
                    </StyledButton>
                  </ButtonWrapper>
                )}
              </ReplyWrapper>
            )}
            {(isReplyEdit || !review.hasReply) && (
              <>
                {!isDeleted && (
                  <TextAreaWrapper>
                    <LabelWrapper>
                      <Label>返信文</Label>
                      {currentUser.canUseReviewAi && (
                        <GenerateButton onClick={() => setIsGenerateModalOpen(true)}>
                          <StyledIcon type={'robot'} />
                          AIアシスト
                        </GenerateButton>
                      )}
                    </LabelWrapper>
                    <TextArea
                      value={replyComment}
                      placeholder='返信文を入力してください'
                      onChange={(value: string) => setReplyComment(value)}
                      onBlur={() => setShowValidationError(true)}
                      error={showValidationError && validateSendReplyComment !== '' ? validateSendReplyComment : false}
                      iconType='template'
                      iconText='テンプレートを選択'
                      onClickIcon={() => setIsTemplateModalOpen(true)}
                    />
                  </TextAreaWrapper>
                )}
                <ButtonWrapper>
                  {isDeleted && <DisabledMessage>対象のクチコミが削除済みのため、返信できません</DisabledMessage>}
                  <StyledButton
                    priority='high'
                    onClick={() => setIsModalOpen(true)}
                    disabled={!!validateSendReplyComment}
                  >
                    送信する
                  </StyledButton>
                </ButtonWrapper>
              </>
            )}

            <GmbReviewReplyConfirmModal
              text={replyComment}
              onClose={() => setIsModalOpen(false)}
              sendReplyComment={() => {
                setIsModalOpen(false);
                setIsReplyEdit(false);
                sendReplyComment(replyComment);
                setReplyComment('');
              }}
              open={isModalOpen}
            />

            {isGenerateModalOpen && (
              <ReviewReplyGenerateModal
                isOpen={isGenerateModalOpen}
                onClose={() => setIsGenerateModalOpen(false)}
                review={review}
                onApply={setReplyComment}
              />
            )}
          </CardWrapper>
        )}
      </Body>
      <GmbReviewTemplateModal
        open={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        onApply={onApplyTemplate}
        review={review}
        store={store}
        canApply={true}
      />
    </MainWrapper>
  );
});

const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  gap: 16px;
`;

const TitleLabel = styled.div`
  font-size: 18px;
`;

const CardWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  font-size: 14px;
  padding: 24px;
  min-height: 300px;
  position: relative;
`;

const MainText = styled.p`
  margin-bottom: 32px;
`;

const ReplyWrapper = styled.div`
  margin: 32px 0 32px auto;
  max-width: 468px;
`;

const Reply = styled.p`
  background-color: #cff2e6;
  padding: 18px;
  border-radius: 8px;
  margin-top: 4px;
  width: 100%;
  word-wrap: break-word;
`;

const ButtonWrapper = styled.div`
  &&& {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
    gap: 16px;
    flex-wrap: wrap;
  }
`;

const ChangeStatusWrapper = styled.div`
  width: 200px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 120px;
  }
`;

const DisabledMessage = styled.div`
  margin-left: auto;
  color: ${COLOR.DARK_GRAY};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ReviewStatusWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const ReviewDeleted = styled.div`
  color: ${COLOR.DARK_GRAY};
`;

const DeleteIcon = styled(SemanticIcon).attrs({ name: 'trash alternate outline' })`
  &&& {
    height: auto;
    margin-right: 4px;
  }
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    margin: 0;

    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DateLabel = styled.div`
  flex: 1;
  text-align: right;
  font-weight: normal;
  &:after {
    content: ':';
    font-weight: bold;
    margin: 0 4px;
  }
`;
const DateValue = styled.div`
  font-family: monospace !important;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: #f8f8f8;
  mix-blend-mode: hard-light;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const GenerateButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    display: flex;
    align-items: self-end;
    margin-left: 8px;
  }
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
  padding: 0;
`;

const TextAreaWrapper = styled.div``;
