import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import {
  AggregateDateRangePicker,
  ComparisonDateRangeOptionValue,
  DateRangeOption,
  DateRangeOptionValue,
  getComparisonDateRange,
  getComparisonDateRangeOption,
  getDateRangeFromDateRangeOption,
  getDateRangeOption,
} from 'components/molecules/AggregateDateRangePicker';
import { AggregateUnitGroup } from 'components/molecules/AggregateUnitGroup';
import {
  OmoInsightSearchCondition as SearchCondition,
  defaultDates,
} from 'models/Domain/OmoInsight/OmoInsightSearchCondition';
import { SIZE } from 'style/size';
import { AggregateUnit } from 'types/Common';

const { MAX_DATE } = defaultDates();
// 期間オプション選択時の最大日付
// 当日はカスタムで選択できるようにはしたいが、「過去7日間」とかの場合は前日を基準にする
const DATE_RANGE_OPTION_MAX_DATE = MAX_DATE.subtract(1, 'day');

export const dateRangeOptions: Record<AggregateUnit, DateRangeOption[]> = {
  day: [
    { text: '過去7日間', value: '7 day' },
    { text: '過去28日間', value: '28 day' },
    { text: '過去90日間', value: '90 day' },
    { text: '前週', value: 'last week' },
    { text: '先月', value: 'last month' },
    { text: 'カスタム', value: 'カスタム' },
  ],
  week: [
    { text: '過去4週間', value: '4 week' },
    { text: '過去8週間', value: '8 week' },
    { text: 'カスタム', value: 'カスタム' },
  ],
  month: [
    { text: '3ヶ月', value: '3 month' },
    { text: '半年', value: '6 month' },
    { text: '1年', value: '12 month' },
    { text: 'カスタム', value: 'カスタム' },
  ],
};
export const OmoInsightSearchCondition: React.FC<{
  isCommitDisabled: boolean;
  committedSearchCondition: SearchCondition;
  onCommitSearchCondition: (searchCondition: SearchCondition) => void;
}> = ({ isCommitDisabled, committedSearchCondition, onCommitSearchCondition }) => {
  const [searchCondition, setSearchCondition] = useState<SearchCondition>(committedSearchCondition);
  const {
    filter: { aggregateUnit, startDate, endDate, comparisonStartDate, comparisonEndDate, isEnabledComparison },
  } = searchCondition;

  // committedSearchConditionが変更されたら更新する
  useEffect(() => {
    setSearchCondition(committedSearchCondition);
  }, [committedSearchCondition]);

  const onChangeSearchCondition = useCallback((searchCondition: SearchCondition) => {
    setSearchCondition(searchCondition);
  }, []);

  const [dateRangeOption, setDateRangeOption] = useState<DateRangeOptionValue>(() =>
    getDateRangeOption(dateRangeOptions[aggregateUnit], startDate, endDate, DATE_RANGE_OPTION_MAX_DATE),
  );

  // 比較期間のタイプ
  const [comparisonDateRangeOption, setComparisonDateRangeOption] = useState<ComparisonDateRangeOptionValue>(
    isEnabledComparison
      ? getComparisonDateRangeOption(dateRangeOption, startDate, endDate, comparisonStartDate, comparisonEndDate)
      : '前の期間',
  );

  const aggregateDurationOptions = useMemo(() => dateRangeOptions[aggregateUnit], [aggregateUnit]);

  const handleOnChangeAggregateUnit = useCallback(
    (value: AggregateUnit) => {
      // 集計期間が変更されたら、「カスタム」に変更する
      if (value !== aggregateUnit) {
        setDateRangeOption('カスタム');
        const newSearchCondition = searchCondition.setIn(['filter', 'aggregateUnit'], value);
        onChangeSearchCondition(newSearchCondition);
      }
    },
    [aggregateUnit, onChangeSearchCondition, searchCondition],
  );

  const handleOnChangeIsEnabledComparison = useCallback(
    (value: boolean) => {
      let newSearchCondition = searchCondition.setIn(['filter', 'isEnabledComparison'], value);
      if (comparisonDateRangeOption !== 'カスタム') {
        const [csd, ced] = getComparisonDateRange(
          searchCondition.filter.startDate,
          searchCondition.filter.endDate,
          comparisonDateRangeOption,
          dateRangeOption,
        );
        newSearchCondition = newSearchCondition.mergeIn(['filter'], {
          comparisonStartDate: csd,
          comparisonEndDate: ced,
        });
      }
      onChangeSearchCondition(newSearchCondition);
    },
    [comparisonDateRangeOption, dateRangeOption, searchCondition, onChangeSearchCondition],
  );

  const handleOnChangeDateRangeOption = useCallback(
    (value: DateRangeOptionValue) => {
      setDateRangeOption(value);
      // 集計期間のオプションから開始日〜終了日を取得
      const [sd, ed] = getDateRangeFromDateRangeOption(value, startDate, endDate, DATE_RANGE_OPTION_MAX_DATE);
      // 設定を反映
      let newSearchCondition = searchCondition.mergeIn(['filter'], {
        startDate: sd,
        endDate: ed,
      });

      // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
      if (comparisonDateRangeOption !== 'カスタム') {
        const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, value);
        newSearchCondition = newSearchCondition.mergeIn(['filter'], {
          comparisonStartDate: csd,
          comparisonEndDate: ced,
        });
      }
      onChangeSearchCondition(newSearchCondition);
    },
    [comparisonDateRangeOption, endDate, searchCondition, startDate, onChangeSearchCondition],
  );

  const handleOnChangeComparisonDateRangeOption = useCallback(
    (value: ComparisonDateRangeOptionValue) => {
      setComparisonDateRangeOption(value);

      if (value !== 'カスタム') {
        const [csd, ced] = getComparisonDateRange(startDate, endDate, value, dateRangeOption);
        const newSearchCondition = searchCondition.mergeIn(['filter'], {
          comparisonStartDate: csd,
          comparisonEndDate: ced,
        });
        onChangeSearchCondition(newSearchCondition);
      }
    },
    [dateRangeOption, endDate, searchCondition, startDate, onChangeSearchCondition],
  );

  const handleOnChangeDateRange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        const newDateRangeOption = 'カスタム';
        setDateRangeOption(newDateRangeOption);

        const sd = dayjs(startDate);
        const ed = dayjs(endDate);

        let newSearchCondition = searchCondition.mergeIn(['filter'], {
          startDate: sd,
          endDate: ed,
        });

        // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
        if (comparisonDateRangeOption !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, newDateRangeOption);
          newSearchCondition = newSearchCondition.mergeIn(['filter'], {
            comparisonStartDate: csd,
            comparisonEndDate: ced,
          });
        }
        onChangeSearchCondition(newSearchCondition);
      }
    },
    [comparisonDateRangeOption, searchCondition, onChangeSearchCondition],
  );

  const handleOnChangeComparisonDateRange = useCallback(
    (comparisonStartDate: Date | null, comparisonEndDate: Date | null) => {
      setComparisonDateRangeOption('カスタム');
      const csd = dayjs(comparisonStartDate);
      const ced = dayjs(comparisonEndDate);
      const newSearchCondition = searchCondition.mergeIn(['filter'], {
        comparisonStartDate: csd,
        comparisonEndDate: ced,
      });
      onChangeSearchCondition(newSearchCondition);
    },
    [searchCondition, onChangeSearchCondition],
  );

  const handleOnCommitSearchCondition = useCallback(() => {
    onCommitSearchCondition(searchCondition);
  }, [onCommitSearchCondition, searchCondition]);

  return (
    <SearchWrapper>
      <FlexWrapper>
        <FlexContent>
          <Label>集計単位</Label>
          <AggregateUnitGroup value={aggregateUnit} onChange={handleOnChangeAggregateUnit} />
        </FlexContent>
        <FlexContent>
          <FlexLabel>集計期間</FlexLabel>
          <PeriodContainer>
            <AggregateDateRangePicker
              startDate={startDate}
              endDate={endDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              maxDate={MAX_DATE}
              isEnabledComparison={isEnabledComparison}
              dateRangeOption={dateRangeOption}
              dateRangeOptions={aggregateDurationOptions}
              comparisonDateRangeOption={comparisonDateRangeOption}
              onChangeDateRange={handleOnChangeDateRange}
              onChangeComparisonDateRange={handleOnChangeComparisonDateRange}
              onChangeIsEnabledComparison={handleOnChangeIsEnabledComparison}
              onChangeDateRangeOption={handleOnChangeDateRangeOption}
              onChangeComparisonDateRangeOption={handleOnChangeComparisonDateRangeOption}
            />
          </PeriodContainer>
        </FlexContent>
        <ActionWrapper>
          <StyledButton
            disabled={isCommitDisabled || !searchCondition.isValid()}
            onClick={handleOnCommitSearchCondition}
          >
            適用
          </StyledButton>
        </ActionWrapper>
      </FlexWrapper>
    </SearchWrapper>
  );
};

const SearchWrapper = styled(Card)`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 24px;
  z-index: 3; /* タブ(z-index=2)より上に表示する */
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FlexContent = styled.div`
  margin-right: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Label = styled.div`
  font-size: 16px;
`;

const FlexLabel = styled(Label)`
  display: flex;
  align-items: center;
`;

const PeriodContainer = styled.div``;

const ActionWrapper = styled(Card)`
  margin-left: auto;
  margin-top: auto;
  padding-right: 0;
  min-width: 176px;
`;

const StyledButton = styled(Button).attrs(() => ({ priority: 'high' }))`
  &&& {
    height: 32px;
    padding: 0;
    width: 176px;

    @media (max-width: ${SIZE.MOBILE}) {
      width: 100%;
    }
  }
`;
