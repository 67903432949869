import React, { useEffect, useMemo, useState } from 'react';

import { List, Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { AnalysisMemoTable } from 'components/pageComponents/Analysis/AnalysisMemoTable';
import { OmoInsightItem } from 'components/pageComponents/OmoInsight/OmoInsightItem';
import { OmoInsightSearchCondition } from 'components/pageComponents/OmoInsight/OmoInsightSearchCondition';
import { LargeStatsCard } from 'components/pageComponents/OmoInsight/StatsCard';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getMemoData } from 'helpers/graph';
import { getPageTitle } from 'helpers/utils';
import { useAnalysisMemos, useMemoDisplaySettings } from 'hooks/useAnalysisMemos';
import { CompositeOmoInsightSummaryItem } from 'models/Domain/OmoInsight/InsightSummary';
import { OmoInsightSearchCondition as SearchCondition } from 'models/Domain/OmoInsight/OmoInsightSearchCondition';
import { ProductStatusItem } from 'models/Domain/OmoInsight/ProductStatusSummary';
import { OmoInsightActions } from 'modules/omoInsight/actions';
import { COLOR } from 'style/color';

type OmoInsightItemData = {
  name: string;
  merchantId: string;
  insight: CompositeOmoInsightSummaryItem;
  productStatus: ProductStatusItem;
};

export const OmoInsight: React.FC = () => {
  const dispatch = useDispatch();
  const { initializePage, commitSearchCondition, commitSelectedMerchantId } = useMemo(
    () => bindActionCreators(OmoInsightActions, dispatch),
    [dispatch],
  );

  // 施策メモの表示設定
  const [memoDisplaySettings, setMemoDisplaySettings] = useMemoDisplaySettings('OMO_INSIGHT');

  const { memoList, memoTagList, createMemo, updateMemo, deleteMemo, isLoadingMemoList, isLoadingMemoTagList } =
    useAnalysisMemos();

  const [selectingMemoIds, setSelectingMemoIds] = useState<Set<number>>(Set());

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const { searchCondition, insight, productStatus, graph, loadingStatus } = useSelector((state) => state.omoInsight);

  // insightとproductStatusのitemsをnameで結合する
  const omoInsightItemList: List<OmoInsightItemData> = useMemo(() => {
    let items = List<OmoInsightItemData>();
    if (insight && productStatus) {
      insight.items.forEach((insightItem) => {
        const productStatusItem = productStatus.items.find((ps) => insightItem.merchantId === ps.status.merchantId);
        if (productStatusItem) {
          items = items.push({
            name: insightItem.name,
            merchantId: insightItem.merchantId,
            insight: insightItem,
            productStatus: productStatusItem,
          });
        }
      });
    }
    return items;
  }, [insight, productStatus]);

  // 収益の合計の前期比
  const totalRevenuesDiff = useMemo((): number | null => insight.totalRevenueDiff, [insight]);

  const omoInsightItem = useMemo(() => {
    return (
      omoInsightItemList.find((item) => item.merchantId === searchCondition?.filter.selectedMerchantId) ??
      omoInsightItemList.get(0)
    );
  }, [omoInsightItemList, searchCondition?.filter.selectedMerchantId]);

  const dateRangeConfig = (searchCondition || new SearchCondition()).dateRangeConfig;

  // 表示設定のタグでの絞り込みと、開始日終了日のフィルターを適用した施策メモのリスト
  const filteredMemoList = useMemo(() => {
    return memoList.displayMemoList(memoDisplaySettings, dateRangeConfig);
  }, [dateRangeConfig, memoDisplaySettings, memoList]);

  const memoData = useMemo(() => {
    const { targetPeriod, aggregateUnit } = dateRangeConfig;
    if (targetPeriod == null) return [];
    return getMemoData(
      memoList,
      targetPeriod.start,
      targetPeriod.end,
      aggregateUnit,
      memoDisplaySettings.displayToGraph,
    );
  }, [dateRangeConfig, memoDisplaySettings.displayToGraph, memoList]);

  const showMemoList = useMemo(() => {
    return graph.target.items.size > 0 || (graph.comparison && graph.comparison.items.size > 0);
  }, [graph.comparison, graph.target.items.size]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('OMOインサイト')} />
      <StickyHeader title={'OMOインサイト'} />
      <WideBody>
        <Container>
          <SearchConditionWrapper>
            {searchCondition && (
              <OmoInsightSearchCondition
                isCommitDisabled={loadingStatus.graph || loadingStatus.insight || loadingStatus.productStatus}
                committedSearchCondition={searchCondition}
                onCommitSearchCondition={commitSearchCondition}
              />
            )}
          </SearchConditionWrapper>
          <Separator />
          <TotalRevenueContainer>
            <CardWrapper>
              <LargeStatsCard
                label={'収益の合計'}
                value={insight.currentTotalRevenues}
                diff={totalRevenuesDiff || undefined}
                currency={true}
              />
            </CardWrapper>
            {insight.items.size > 1 && (
              <TotalRevenueListContainer>
                {insight.items.map((item) => (
                  <TotalRevenueCardWrapper
                    key={item.merchantId}
                    selected={omoInsightItem?.merchantId === item.merchantId}
                    onClick={() => commitSelectedMerchantId(item.merchantId)}
                  >
                    <LargeStatsCard
                      label={item.name}
                      value={item.current.totalRevenues || 0}
                      diff={item.totalRevenueDiff || undefined}
                      currency={true}
                    />
                  </TotalRevenueCardWrapper>
                ))}
              </TotalRevenueListContainer>
            )}
          </TotalRevenueContainer>
          <Separator />
          {omoInsightItem && (
            <OmoInsightItemContainer>
              <OmoInsightItem
                key={omoInsightItem.name}
                insight={omoInsightItem.insight}
                productStatus={omoInsightItem.productStatus.status}
                graphData={graph}
                memoData={memoData}
                onClickMemo={(ids) => setSelectingMemoIds(Set(ids))}
              />
              {showMemoList && (
                <AnalysisMemoTable
                  memoList={filteredMemoList}
                  activeMemoIds={selectingMemoIds}
                  clearActiveMemoIds={() => setSelectingMemoIds(Set())}
                  tags={memoTagList}
                  memoDisplaySettings={memoDisplaySettings}
                  setMemoDisplaySettings={setMemoDisplaySettings}
                  onCreateMemo={createMemo}
                  onUpdateMemo={updateMemo}
                  onDeleteMemo={deleteMemo}
                  isLoadingMemoList={isLoadingMemoList}
                  isLoadingMemoTagList={isLoadingMemoTagList}
                  stickyPosition={'Title'}
                />
              )}
            </OmoInsightItemContainer>
          )}
        </Container>
      </WideBody>
    </MainWrapper>
  );
};

const Separator = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #bababa;
`;

const Container = styled.div``;

const SearchConditionWrapper = styled.div`
  border: 1px solid ${COLOR.LIGHT_GRAY};
  margin-bottom: 16px;
`;

const TotalRevenueContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const CardWrapper = styled.div`
  margin-bottom: 16px;
  width: 200px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const TotalRevenueListContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 8px 0;

  @media (max-width: 600px) {
    display: block;
  }
`;

const TotalRevenueCardWrapper = styled(CardWrapper)<{ selected: boolean }>`
  cursor: pointer;
  position: relative;
  margin-bottom: 0;

  ${LargeStatsCard} {
    border: none;
    outline: none;

    :after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: ${({ selected }) => (selected ? 'block' : 'none')};
      border: 2px solid ${COLOR.GREEN};
    }
  }

  :hover {
    ${LargeStatsCard} {
      background-color: ${COLOR.LIGHT_GREEN};
    }
  }
`;

const OmoInsightItemContainer = styled.div`
  width: 100%;
`;
