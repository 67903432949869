import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GbpMenuSyncAlert } from 'components/molecules/MenuIndex/GbpMenuSyncAlert';
import { MenuIndexFooter } from 'components/pageComponents/Menu/MenuIndexFooter';
import { MenuIndexTable } from 'components/pageComponents/Menu/MenuIndexTable';
import { MenuListModal } from 'components/pageComponents/Menu/MenuListModal';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { MenuHelp as Help } from 'helpers/ContextHelp';
import { getPageTitle } from 'helpers/utils';
import { useHoldOn } from 'hooks/useHoldOn';
import { MenuSummary } from 'models/Domain/Menu/Menu';
import { AppActions } from 'modules/app/actions';
import { MenuActions } from 'modules/menu/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const MenuIndex = () => {
  const [selectedMenuIds, setSelectedMenuIds] = useState(ImmutableSet<number>([]));
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const { initializeIndexPage, applyToGbp, updateMenuSummary, deleteMenuSummary } = useMemo(
    () => bindActionCreators(MenuActions, dispatch),
    [dispatch],
  );
  const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
  const { menuSummary, isLoadingMenu, isApplyingToGbp } = useSelector((state) => state.menu);

  const menuSummaryItemsNotSynced = menuSummary.itemsNotSyncedToGbp;

  const handleOnApply = useCallback(() => {
    if (!window.confirm('STORECASTで設定したメニューをGBPに反映しますか？GBPで設定したメニューは上書きされます'))
      return;
    applyToGbp();
  }, [applyToGbp]);

  const handleOnDelete = useCallback(
    (menuIds: ImmutableSet<number>) => {
      if (!window.confirm('本当に削除しますか？')) return;
      deleteMenuSummary(menuIds);
      // 選択を解除する
      setSelectedMenuIds(ImmutableSet());
    },
    [deleteMenuSummary],
  );

  const handleOnClone = useCallback(
    (menuId: number) => {
      // 選択されたメニューのIDをbaseIdとして作成画面に渡す
      moveTo({
        pathname: Path.menu.create,
        state: { baseId: menuId },
      });
    },
    [moveTo],
  );

  const handleOnChangeMenuItems = useCallback(
    (items: MenuSummary['items']) => {
      const newMenuSummary = menuSummary.set('items', items);
      updateMenuSummary(newMenuSummary);
    },
    [updateMenuSummary, menuSummary],
  );

  const isEmpty = menuSummary.itemsNotDeleted.list.size === 0;

  const applyButtonDisabled = useHoldOn(isApplyingToGbp, 3000);
  const disableApplyButton = applyButtonDisabled || menuSummary.items.size === 0;

  useEffect(() => {
    initializeIndexPage();
  }, [initializeIndexPage]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('飲食メニュー一覧')} />
      <StickyHeader>
        <Title>飲食メニュー一覧</Title>
        <StyledButton onClick={handleOnApply} disabled={disableApplyButton}>
          GBPに反映する
        </StyledButton>
      </StickyHeader>
      <WideBody>
        {
          // NOTE: GBPに反映していないメニューグループがある場合のアラート
          menuSummaryItemsNotSynced.list.size > 0 && (
            <AlertWrapper>
              <GbpMenuSyncAlert menuSummaryItemsNotSynced={menuSummaryItemsNotSynced.list}>
                <StyledInnerButton onClick={handleOnApply} disabled={disableApplyButton}>
                  GBPに反映する
                </StyledInnerButton>
              </GbpMenuSyncAlert>
            </AlertWrapper>
          )
        }
        <Header>
          <Count>{menuSummary.itemsNotDeleted.list.size}件のメニューグループ</Count>
          <ButtonContainer>
            <TextButton onClick={() => setShowModal(true)} disabled={isEmpty}>
              並び替え
            </TextButton>
            <StyledLink to={Path.menu.create}>
              <TextButton>メニューグループを作成</TextButton>
            </StyledLink>
          </ButtonContainer>
        </Header>
        {!isLoadingMenu && isEmpty && (
          <EmptyMessage>
            メニューグループ
            <StyledContextHelp content={Help.menuNameForMenuIndex} />
            が登録されていません。
            <br />
            右上の「メニューグループを作成」から追加してください。
          </EmptyMessage>
        )}
        {!isEmpty && (
          <MenuIndexTable
            items={menuSummary.itemsNotDeleted}
            isLoading={isLoadingMenu}
            selectedMenuIds={selectedMenuIds}
            onClone={handleOnClone}
            onDelete={handleOnDelete}
            onChange={handleOnChangeMenuItems}
            onChangeSelectedMenuIds={setSelectedMenuIds}
          />
        )}
        {!isEmpty && menuSummary.items.size > 1 && (
          <Description>
            同じ商品名のメニューアイテムが複数のメニューグループに設定されている場合、順番が下にあるメニューグループの内容がGBPに反映されます。
          </Description>
        )}
        {selectedMenuIds.size > 0 && (
          <MenuIndexFooter
            onCancel={() => setSelectedMenuIds(ImmutableSet())}
            selectedSize={selectedMenuIds.size}
            onDelete={() => handleOnDelete(selectedMenuIds)}
          />
        )}
      </WideBody>
      {showModal && (
        <MenuListModal
          isOpen={true}
          onClose={() => setShowModal(false)}
          menuSummary={menuSummary}
          onChange={handleOnChangeMenuItems}
        />
      )}
    </MainWrapper>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const AlertWrapper = styled.div`
  margin-bottom: 20px;
`;

const Count = styled.div`
  color: ${COLOR.DARK_GRAY};
`;
const StyledLink = styled(Link)`
  color: ${COLOR.GREEN};
  font-weight: bold;
`;

const Description = styled.div`
  color: ${COLOR.DARK_GRAY};
  margin-top: 16px;
`;

const StyledInnerButton = styled(Button)`
  &&& {
    width: 128px;
    height: 32px;
    font-size: 11px;
    padding: 8px 19px;
  }
`;

const TextButton = styled(Button).attrs({ priority: 'low' })``;

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const EmptyMessage = styled.div`
  padding: 32px 0;
  text-align: center;
  line-height: 2;
`;

const StyledContextHelp = styled(ContextHelp)`
  margin-right: 4px;
`;
