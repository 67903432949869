import React from 'react';

import styled from 'styled-components';

import { GmbTimePeriods } from 'models/Domain/GmbLocation/GmbTimePeriod';

type RegularHoursProps = {
  className?: string;
  periods: GmbTimePeriods;
};

export const RegularHours: React.FC<RegularHoursProps> = ({ className, periods }) => {
  return (
    <Wrapper className={className}>
      {periods.periodsForDisplay.map((period, index) => (
        <TimeLabel key={index}>{period}</TimeLabel>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TimeLabel = styled.p`
  white-space: nowrap;
`;
