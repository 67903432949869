import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { GAInsightMetrics, GMCInsightMetrics } from '../Omo/common';

import { calcDiff } from '.';

export class GMCInsightSummary extends Record<{
  shopping_ads: GMCInsightMetrics;
  free_listings: GMCInsightMetrics;
  free_local_listings: GMCInsightMetrics;
}>({
  shopping_ads: new GMCInsightMetrics(),
  free_listings: new GMCInsightMetrics(),
  free_local_listings: new GMCInsightMetrics(),
}) {
  static fromJson(data: any = {}) {
    return new GMCInsightSummary({
      shopping_ads: GMCInsightMetrics.fromJson(data.shopping_ads || {}),
      free_listings: GMCInsightMetrics.fromJson(data.free_listings || {}),
      free_local_listings: GMCInsightMetrics.fromJson(data.free_local_listings || {}),
    });
  }
}

export class GAInsightSummary extends Record<{
  free: GAInsightMetrics;
  ads: GAInsightMetrics;
}>({
  free: new GAInsightMetrics(),
  ads: new GAInsightMetrics(),
}) {
  static fromJson(data: any = {}) {
    return new GAInsightSummary({
      free: GAInsightMetrics.fromJson(data.free || {}),
      ads: GAInsightMetrics.fromJson(data.ads || {}),
    });
  }
}

export type FunnelTransitionRate = {
  gmc: {
    free_listings: { clicks: number };
    free_local_listings: { clicks: number };
    shopping_ads: { clicks: number };
  };
  ga: {
    free: {
      sessions: number;
      conversions: number;
    };
    ads: {
      sessions: number;
      conversions: number;
    };
  };
};
export class OmoInsightSummaryItem extends Record<{
  name: string;
  merchantId: string;
  gmc: GMCInsightSummary;
  ga: GAInsightSummary;
}>({
  name: '',
  merchantId: '',
  gmc: new GMCInsightSummary(),
  ga: new GAInsightSummary(),
}) {
  static fromJson(data: any = {}) {
    return new OmoInsightSummaryItem({
      name: data.name,
      merchantId: data.merchant_id || '',
      gmc: GMCInsightSummary.fromJson(data.gmc || {}),
      ga: GAInsightSummary.fromJson(data.ga || {}),
    });
  }

  /**
   * ファネルの遷移率を返す
   */
  get transitionRates(): FunnelTransitionRate {
    return {
      gmc: {
        free_listings: {
          clicks: (this.gmc.free_listings.clicks / this.gmc.free_listings.impressions) * 100,
        },
        free_local_listings: {
          clicks: (this.gmc.free_local_listings.clicks / this.gmc.free_local_listings.impressions) * 100,
        },
        shopping_ads: {
          clicks: (this.gmc.shopping_ads.clicks / this.gmc.shopping_ads.impressions) * 100,
        },
      },
      ga: {
        free: {
          sessions:
            (this.ga.free.sessions / (this.gmc.free_listings.clicks + this.gmc.free_local_listings.clicks)) * 100,
          conversions: (this.ga.free.conversions / this.ga.free.sessions) * 100,
        },
        ads: {
          sessions: (this.ga.ads.sessions / this.gmc.shopping_ads.clicks) * 100,
          conversions: (this.ga.ads.conversions / this.ga.ads.sessions) * 100,
        },
      },
    };
  }

  /// 収益の合計を返す
  get totalRevenues(): number | null {
    if (this.ga.ads.revenues === null || this.ga.free.revenues === null) {
      return null;
    }
    return this.ga.ads.revenues + this.ga.free.revenues;
  }

  getMergedPerformanceData() {
    const impressions =
      this.gmc.free_listings.impressions + this.gmc.free_local_listings.impressions + this.gmc.shopping_ads.impressions;
    const clicks = this.gmc.free_listings.clicks + this.gmc.free_local_listings.clicks + this.gmc.shopping_ads.clicks;
    return {
      impressions,
      clicks,
      ctr: impressions ? (clicks / impressions) * 100 : null,
      sessions: this.ga.free.sessions + this.ga.ads.sessions,
      conversions: this.ga.free.conversions + this.ga.ads.conversions,
      revenues: this.totalRevenues || 0,
    };
  }
}

type OmoInsightSummaryType = {
  fromDate: Dayjs;
  toDate: Dayjs;
  items: List<OmoInsightSummaryItem>;
};

export class OmoInsightSummary extends Record<OmoInsightSummaryType>({
  fromDate: dayjs(),
  toDate: dayjs(),
  items: List(),
}) {
  static fromJson(data: any = {}) {
    return new OmoInsightSummary({
      fromDate: dayjs(data.from_date),
      toDate: dayjs(data.to_date),
      items: List(data.items.map((item: any) => OmoInsightSummaryItem.fromJson(item || {}))),
    });
  }
}

type CompositeOmoInsightSummaryItemType = {
  name: string;
  merchantId: string;
  current: OmoInsightSummaryItem;
  comparison: OmoInsightSummaryItem | null;
};

/**
 * インサイトデータと比較対象データを合わせたデータ
 */
export class CompositeOmoInsightSummaryItem extends Record<CompositeOmoInsightSummaryItemType>({
  name: '',
  merchantId: '',
  current: new OmoInsightSummaryItem(),
  comparison: new OmoInsightSummaryItem(),
}) {
  /**
   * OmoInsightSummaryItemの各項目を前期比(%)で返す
   *
   * 比較対象のデータが0の場合はInfinityとなるので扱いに注意
   */
  get diff(): OmoInsightSummaryItem {
    // OmoInsightSummaryItemの各項目を前期比(%)で返す
    // 比較対象のデータが0の場合はInfinityとなるので扱いに注意
    return OmoInsightSummaryItem.fromJson({
      name: this.name,
      merchantId: this.merchantId,
      gmc: this.comparison
        ? {
            free_listings: {
              impressions: calcDiff(
                this.current.gmc.free_listings.impressions,
                this.comparison.gmc.free_listings.impressions,
              ),
              clicks: calcDiff(this.current.gmc.free_listings.clicks, this.comparison.gmc.free_listings.clicks),
            },
            free_local_listings: {
              impressions: calcDiff(
                this.current.gmc.free_local_listings.impressions,
                this.comparison.gmc.free_local_listings.impressions,
              ),
              clicks: calcDiff(
                this.current.gmc.free_local_listings.clicks,
                this.comparison.gmc.free_local_listings.clicks,
              ),
            },
            shopping_ads: {
              impressions: calcDiff(
                this.current.gmc.shopping_ads.impressions,
                this.comparison.gmc.shopping_ads.impressions,
              ),
              clicks: calcDiff(this.current.gmc.shopping_ads.clicks, this.comparison.gmc.shopping_ads.clicks),
            },
          }
        : {
            free_listings: { impressions: null, clicks: null },
            free_local_listings: { impressions: null, clicks: null },
            shopping_ads: { impressions: null, clicks: null },
          },
      ga: this.comparison
        ? {
            free: {
              sessions: calcDiff(this.current.ga.free.sessions, this.comparison.ga.free.sessions),
              conversions: calcDiff(this.current.ga.free.conversions, this.comparison.ga.free.conversions),
              revenues: calcDiff(this.current.ga.free.revenues, this.comparison.ga.free.revenues),
            },
            ads: {
              sessions: calcDiff(this.current.ga.ads.sessions, this.comparison.ga.ads.sessions),
              conversions: calcDiff(this.current.ga.ads.conversions, this.comparison.ga.ads.conversions),
              revenues: calcDiff(this.current.ga.ads.revenues, this.comparison.ga.ads.revenues),
            },
          }
        : {
            free: { sessions: null, conversions: null, revenues: null },
            ads: { sessions: null, conversions: null, revenues: null },
          },
    });
  }

  /**
   * 遷移率の各項目を前期比(%)で返す
   *
   * 比較対象のデータが0の場合はInfinityとなるので扱いに注意
   */
  get transitionRateDiff(): FunnelTransitionRate | null {
    if (this.comparison === null) {
      return null;
    }
    const currentRate = this.current.transitionRates;
    const comparisonRate = this.comparison.transitionRates;

    return {
      gmc: {
        free_listings: {
          clicks: calcDiff(currentRate.gmc.free_listings.clicks, comparisonRate.gmc.free_listings.clicks),
        },
        free_local_listings: {
          clicks: calcDiff(currentRate.gmc.free_local_listings.clicks, comparisonRate.gmc.free_local_listings.clicks),
        },
        shopping_ads: {
          clicks: calcDiff(currentRate.gmc.shopping_ads.clicks, comparisonRate.gmc.shopping_ads.clicks),
        },
      },
      ga: {
        free: {
          sessions: calcDiff(currentRate.ga.free.sessions, comparisonRate.ga.free.sessions),
          conversions: calcDiff(currentRate.ga.free.conversions, comparisonRate.ga.free.conversions),
        },
        ads: {
          sessions: calcDiff(currentRate.ga.ads.sessions, comparisonRate.ga.ads.sessions),
          conversions: calcDiff(currentRate.ga.ads.conversions, comparisonRate.ga.ads.conversions),
        },
      },
    };
  }

  get totalRevenueDiff(): number | null {
    if (this.current.totalRevenues == null || this.comparison?.totalRevenues == null) {
      return null;
    }

    return (100 * (this.current.totalRevenues - this.comparison.totalRevenues)) / this.comparison.totalRevenues;
  }

  getMergedData() {
    const target = this.current.getMergedPerformanceData();
    const comparison = this.comparison ? this.comparison.getMergedPerformanceData() : null;
    const rate =
      comparison !== null
        ? {
            impressions: comparison.impressions ? calcDiff(target.impressions, comparison.impressions) : null,
            clicks: comparison.clicks ? calcDiff(target.clicks, comparison.clicks) : null,
            ctr: target.ctr && comparison.ctr ? calcDiff(target.ctr, comparison.ctr!) : null,
            sessions: comparison.sessions ? calcDiff(target.sessions, comparison.sessions) : null,
            conversions: comparison.conversions ? calcDiff(target.conversions, comparison.conversions) : null,
            revenues: comparison.revenues ? calcDiff(target.revenues, comparison.revenues) : null,
          }
        : null;
    return { target, comparison, rate };
  }
}

// CompositeOmoInsightSummaryのデータ構造
/*
{
  periods: {
    current: { from: '2021-10-19T15:00:00.000Z', to: '2021-10-25T15:00:00.000Z' },
    comparison: { from: '2021-10-12T15:00:00.000Z', to: '2021-10-18T15:00:00.000Z' },
  },
  items: [
    {
      name: 'デフォルト',
      current: {
        name: 'デフォルト',
        gmc: {
          free_listings: { clicks: 0, impressions: 0 },
          free_local_listings: { clicks: 0, impressions: 0 },
          shopping_ads: { clicks: 0, impressions: 0 },
        },
        ga: { ads: { conversions: 0, revenues: 0, sessions: 0 }, free: { conversions: 0, revenues: 0, sessions: 0 } },
      },
      comparison: {
        name: 'デフォルト',
        gmc: {
          free_listings: { clicks: 0, impressions: 0 },
          free_local_listings: { clicks: 0, impressions: 0 },
          shopping_ads: { clicks: 0, impressions: 0 },
        },
        ga: { ads: { conversions: 0, revenues: 0, sessions: 0 }, free: { conversions: 0, revenues: 0, sessions: 0 } },
      },
    },
  ],
};
*/

export type CompositeOmoInsightSummaryType = {
  periods: {
    current: {
      from: Dayjs;
      to: Dayjs;
    };
    comparison: {
      from: Dayjs;
      to: Dayjs;
    } | null;
  };
  items: List<CompositeOmoInsightSummaryItem>;
};

/**
 * インサイトデータと比較対象データを設定ごとに集約したインサイト
 */
export class CompositeOmoInsightSummary extends Record<CompositeOmoInsightSummaryType>({
  periods: {
    current: {
      from: dayjs(),
      to: dayjs(),
    },
    comparison: {
      from: dayjs(),
      to: dayjs(),
    },
  },
  items: List(),
}) {
  static fromInsightSummary(current: OmoInsightSummary, comparison: OmoInsightSummary | null) {
    const items: CompositeOmoInsightSummaryItem[] = [];
    current.items.forEach((currentItem) => {
      // データをまとめるキーとして設定名を利用する
      const merchantId = currentItem.merchantId;

      // 比較対象のデータが存在する場合、リストに追加する
      const comparisonItem = comparison?.items.find((item) => item.merchantId === merchantId) ?? null;
      items.push(
        new CompositeOmoInsightSummaryItem({
          name: currentItem.name,
          merchantId,
          current: currentItem,
          comparison: comparisonItem,
        }),
      );
    });

    const periods = {
      current: { from: current.fromDate, to: current.toDate },
      comparison: comparison ? { from: comparison.fromDate, to: comparison.toDate } : null,
    };
    return new CompositeOmoInsightSummary({ periods, items: List(items) });
  }

  /// 収益の合計を返す
  get currentTotalRevenues(): number {
    return this.items.reduce((sum, item) => {
      return sum + (item.current.totalRevenues || 0);
    }, 0);
  }

  /// 収益の合計(比較対象)を返す
  get comparisonTotalRevenues(): number | null {
    if (this.periods.comparison === null) {
      return null;
    }
    return this.items.reduce((sum, item) => {
      return sum + (item.comparison?.totalRevenues ?? 0);
    }, 0);
  }

  // 収益の合計の比
  get totalRevenueDiff(): number | null {
    return this.comparisonTotalRevenues
      ? (100 * (this.currentTotalRevenues - this.comparisonTotalRevenues)) / this.comparisonTotalRevenues
      : null;
  }
}
