import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { UserEditContent } from 'components/organisms/UserEditContent';
import { ChangePasswordParam, PasswordChangeModal } from 'components/pageComponents/AccountEdit/PasswordChangeModal';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { UserActions } from 'modules/user/actions';

export const AccountEdit: React.FC = () => {
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const { currentUser, editUser, errorMessage } = useSelector((state) => ({
    currentUser: state.app.currentUser,
    editUser: state.user.editUser,
    errorMessage: state.user.errorMessage,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserActions.setEditUser(currentUser));
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (!errorMessage) {
      setIsPasswordChangeModalOpen(false);
    }
  }, [errorMessage]);

  const onSubmit = (param: ChangePasswordParam) => {
    dispatch(UserActions.changePassword(param));
  };

  const onClickChangePassword = () => {
    setIsPasswordChangeModalOpen(true);
    dispatch(UserActions.setSuccessChangePassword(false));
  };

  // 編集・削除権限
  const canEdit = currentUser.canEditUser(editUser);
  const canDelete = currentUser.canDeleteUser(editUser);

  if (!editUser.id) return null;
  return (
    <MainWrapper>
      <Helmet title={getPageTitle('アカウント管理')} />
      <StickyHeader title='アカウント管理' />
      <Body>
        <UserEditContent
          editUser={editUser}
          targetUser={currentUser}
          isMyAccountEdit={true}
          canEdit={canEdit}
          canDelete={canDelete}
          setEditUser={(user) => dispatch(UserActions.setEditUser(user))}
          updateEditUser={() => dispatch(UserActions.updateEditUser())}
          uploadEditUserImage={(file) => dispatch(UserActions.uploadEditUserImage(file))}
          setIsOpenPasswordChangeModal={() => onClickChangePassword()}
        />
        <PasswordChangeModal
          isOpen={isPasswordChangeModalOpen}
          onClose={() => setIsPasswordChangeModalOpen(false)}
          onSubmit={(param) => onSubmit(param)}
        />
      </Body>
    </MainWrapper>
  );
};
