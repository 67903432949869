import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { TaskDetail } from 'models/Domain/TaskDetail';
import { User } from 'models/Domain/User';

import { TaskDetailActions } from './actions';

interface TaskDetailStateRecord {
  isLoading: boolean;
  task: TaskDetail;
  author: User;
}

export class TaskDetailState extends Record<TaskDetailStateRecord>({
  isLoading: true,
  task: new TaskDetail(),
  author: new User(),
}) {}

export const taskDetailReducer = reducerWithInitialState(new TaskDetailState())
  .case(TaskDetailActions.clearState, (state, payload) => {
    return new TaskDetailState();
  })
  .case(TaskDetailActions.setTask, (state, payload) => {
    return state.set('task', payload);
  })
  .case(TaskDetailActions.setAuthor, (state, payload) => {
    return state.set('author', payload);
  })
  .case(TaskDetailActions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  });
