import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { Link } from 'components/atoms/Link';
import { ActivityCardList } from 'components/organisms/ActivityCard';
import { SectionTitle, SectionWrapper } from 'components/pageComponents/Top/Section';
import { OfferActivities } from 'models/Composite/OfferActivity';
import { Stores } from 'models/Domain/Store';
import { AppActions } from 'modules/app/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const ActivityContent: React.FC<{
  offerActivities: OfferActivities;
  stores: Stores;
}> = ({ offerActivities, stores }) => {
  const dispatch = useDispatch();
  return (
    <SectionWrapper>
      <SectionTitle>直近のやりとり</SectionTitle>
      <CustomActivityCardList
        offerActivities={offerActivities}
        stores={stores}
        limit={3}
        onClickCard={(offerId) => dispatch(AppActions.moveTo(Path.offer.detail.replace(':offerId', String(offerId))))}
      />
      <ActivityBottomLink to={Path.offer.activity}>
        <ActivityBottomCard>一覧を見る</ActivityBottomCard>
      </ActivityBottomLink>
    </SectionWrapper>
  );
};

const CustomActivityCardList = styled(ActivityCardList)`
  margin-top: 12px;
`;

const ActivityBottomLink = styled(Link)``;

const ActivityBottomCard = styled(Card)`
  text-align: center;
  color: ${COLOR.GREEN};
  font-weight: bold;
`;
