import React, { useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GuideOverlay } from 'components/molecules/GuideOverlay';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { SearchVolumeSearchResult } from 'models/Domain/SearchVolume/SearchVolumeSearchResult';

import { SearchVolumeDetailGraph as Graph } from './Graph';

type Props = {
  className?: string;
  searchResult: SearchVolumeSearchResult;
  selectedKeywords: ImmutableSet<string>;
  onClickLegend?: (keyword: string) => void;
};

export const SearchVolumeDetailGraph = React.memo<Props>(({ searchResult, selectedKeywords, onClickLegend }) => {
  // ダウンロードモーダル関連
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

  // 設定モーダル
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [graphSettings, setGraphSettings] = useState(new GraphSettings());

  return (
    <Wrapper>
      <GraphWrapper>
        <Graph
          searchResult={searchResult}
          selectedKeywords={selectedKeywords}
          onClickLegend={onClickLegend}
          graphSettings={graphSettings}
        />
        {selectedKeywords.isEmpty() && <GuideOverlay>表示するデータを選択してください</GuideOverlay>}
      </GraphWrapper>
      <ButtonWrapper>
        <StyledButton onClick={() => setIsOpenSettingsModal(true)}>グラフの表示設定</StyledButton>
        <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
      </ButtonWrapper>
      <GraphDownloadModal
        isOpen={isOpenDownloadModal}
        fileName={'SearchVolume'}
        onClose={() => setIsOpenDownloadModal(false)}
      >
        <Graph searchResult={searchResult} selectedKeywords={selectedKeywords} graphSettings={graphSettings} />
      </GraphDownloadModal>
      {isOpenSettingsModal && (
        <GraphSettingsModal
          settings={graphSettings}
          isOpen={isOpenSettingsModal}
          onClose={() => setIsOpenSettingsModal(false)}
          onChange={setGraphSettings}
        />
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const GraphWrapper = styled.div`
  position: relative;
  font-size: 12px;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledButton = styled(Button).attrs({ priority: 'low' })`
  font-size: 16px;
`;
