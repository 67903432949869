import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

export type ExternalLinkProps = {
  className?: string;
  color?: string;
  children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'a'>;

export const ExternalLink: React.FC<ExternalLinkProps> = ({ className, color = COLOR.GREEN, children, ...props }) => (
  <Link className={className} color={color} target='_blank' {...props}>
    {children}
    <Svg viewBox='0 0 12 12' color={color}>
      <use xlinkHref='/images/external_link.svg#link' />
    </Svg>
  </Link>
);

const Link = styled.a<ExternalLinkProps>`
  :link,
  :active,
  :visited,
  :hover {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
`;

const Svg = styled.svg<{ color: string }>`
  width: 0.9em;
  height: 0.9em;
  margin: 0 0.05em;
  fill: ${({ color }) => color};
`;
