import React, { useCallback, useEffect, useMemo, useState } from 'react';

import FacebookLogin, { FacebookLoginClient, SuccessResponse } from '@greatsumini/react-facebook-login/';
import { goBack } from 'connected-react-router';
import { Set as ImmutableSet } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { BackArrow, StickyHeader, Title } from 'components/atoms/StickyHeader';
import { StepManual, StepManualHeader, StepManualMain, StepManualTitle } from 'components/organisms/StepManual';
import { InstagramAccountTable } from 'components/pageComponents/Instagram/InstagramAccountTable';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import CONFIG from 'config';
import { getPageTitle } from 'helpers/utils';
import { FacebookAccount, FacebookProfile } from 'models/Domain/instagram';
import { InstagramActions } from 'modules/instagram/actions';
import { COLOR } from 'style/color';

const FACEBOOK_APP_ID = CONFIG.FACEBOOK_APP_ID;

const REQUIRED_SCOPES = ImmutableSet([
  // Facebookログイン時に 'public_profile' をscopeに含まなくなったため除外。
  // トークンを元にスコープを確認すると入っているので問題にはならない。
  // 'public_profile',
  'instagram_basic',
  'pages_show_list',
  'pages_read_engagement',
  'business_management',
]);

export const InstagramAccountRegister = React.memo(() => {
  const dispatch = useDispatch();
  const { accountsForRegister: accounts, accountList: registeredAccounts } = useSelector((state) => state.instagram);
  const { registerAccounts, getInstagramAccounts, initializeRegisterPage } = useMemo(
    () => bindActionCreators(InstagramActions, dispatch),
    [dispatch],
  );
  const [facebookProfile, setFacebookProfile] = useState<FacebookProfile | undefined>(undefined);
  const [facebookAccount, setFacebookAccount] = useState<FacebookAccount | undefined>(undefined);
  const [selectedUserIds, setSelectedUserIds] = useState<ImmutableSet<string>>(ImmutableSet());

  // 連携済みアカウントのID
  const registeredUserIds = useMemo(
    () => registeredAccounts.map((account) => account.userId).toSet(),
    [registeredAccounts],
  );

  useEffect(() => {
    initializeRegisterPage();
  }, [initializeRegisterPage]);

  useEffect(() => {
    setSelectedUserIds(accounts.map((account) => account.userId).toSet());
  }, [accounts]);

  const handleOnBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const handleOnSelectAccount = useCallback(
    (userId: string) => {
      // 連携済みの場合は何もしない
      if (registeredUserIds.has(userId)) {
        return;
      }
      // 選択済みに含まれている場合は除外し、含まれていない場合は追加する
      setSelectedUserIds((selectedUserIds) => {
        if (selectedUserIds.has(userId)) {
          return selectedUserIds.remove(userId);
        } else {
          return selectedUserIds.add(userId);
        }
      });
    },
    [registeredUserIds],
  );

  const handleOnRegister = useCallback(() => {
    // 選択したアカウントの情報を登録・更新する（連携中の場合は選択済み扱い）
    // 連携されていない場合は新規登録、連携したことがある場合は有効なアカウントとして更新、連携中の場合はアカウント情報を更新する
    registerAccounts(accounts.filter((account) => selectedUserIds.contains(account.userId)));
  }, [accounts, registerAccounts, selectedUserIds]);

  const handleOnSuccessLogin = useCallback(
    (response: SuccessResponse) => {
      const account = FacebookAccount.fromJSON(response);
      setFacebookAccount(account);
      // 必要なアクセス許可がある場合に、アカウントを取得する
      if (account.hasAuthority(REQUIRED_SCOPES)) {
        getInstagramAccounts({ userId: response.userID, userAccessToken: response.accessToken });
      }
    },
    [getInstagramAccounts],
  );

  return (
    <MainWrapper>
      <Helmet title={getPageTitle(`Instagramアカウント詳細`)} />
      <StickyHeader>
        <TitleWrapper onClick={handleOnBack}>
          <BackArrow />
          <Title>Instagramアカウント連携</Title>
        </TitleWrapper>
      </StickyHeader>
      <Body>
        <StepManual>
          <StepManualTitle>InstagramアカウントとFacebookページを連携する</StepManualTitle>
          <StepManualDescription>
            Instagramを連携するには、事前にInstagramアカウントとFacebookページを連携しておく必要があります。
            <br />
            すでに連携している場合は「STORECASTにInstagramを連携する」まで進んでください
          </StepManualDescription>
          <StepManualHeader step={1} title={'Instagramのアカウントを用意する'} />
          <StepManualMain>Instagramをご利用でない場合は、Instagramアカウントを作成します</StepManualMain>
          <StepManualHeader step={2} title={'Instagramをプロアカウントに切り替える'} />
          <StepManualMain>
            Instagramと連携するには、Instagramアカウントがプロアカウントである必要があります。
            <br />
            プロアカウントではない場合は、Instagramのアプリから、「設定」 &gt; 「アカウント」 &gt;
            「プロアカウントに切り替える」で変更できます
          </StepManualMain>
          <StepManualHeader step={3} title={'Facebookページを用意する'} />
          <StepManualMain>
            Facebookページをお持ちでない場合は、
            <Link href={'https://www.facebook.com/business/learn/set-up-facebook-page'}>こちら</Link>
            からFacebookページを作成します
          </StepManualMain>
          <StepManualHeader step={4} title={'FacebookページにInstagramを追加する'} />
          <StepManualMain>
            Facebookページ &gt; 「管理」 &gt; 「リンク済みのアカウント」 &gt; 「Instagram」
            からInstagramにログインすることで、FacebookページにInstagramを追加できます
          </StepManualMain>
        </StepManual>
        <Hr />

        <Heading>STORECASTにInstagramを連携する</Heading>
        <Step>STEP 1. ボタンを押して、Facebookにログインする</Step>
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          scope={REQUIRED_SCOPES.join(',')}
          onSuccess={(response) => {
            // ログインできたら、Instagramのアカウント情報を取得する
            handleOnSuccessLogin(response);
          }}
          onFail={(error) =>
            // 未ログイン状態でキャンセルした場合は、loginCancelledが返ってくるので、エラーとして扱わない（何もしない）
            // ログイン状態でキャンセルした場合は、onSuccessが実行される
            // それ以外のエラーは
            error.status !== 'loginCancelled' && toast({ type: 'error', title: 'Facebookログインに失敗しました' })
          }
          onProfileSuccess={(response) => {
            // onProfileSuccessなので成功時のみレスポンスが返ってくるように見えるが、
            // 実際は失敗したときにresponse.dataにエラー情報が返ってくる
            // ログイン後に再度ログインボタンを押して、ダイアログを閉じた場合などに発生するが、
            // 「〇〇としてログイン中」の表示に使うだけなので、エラーでも取得できていた情報を消さない
            if (!response.error) {
              setFacebookProfile(FacebookProfile.fromJSON(response));
            }
          }}
          language={'ja_JP'}
          loginOptions={{ return_scopes: true }}
          render={({ onClick }) => (
            <>
              <FacebookLoginButton onClick={onClick}>
                <FacebookIconWrapper>
                  <FacebookIcon />
                </FacebookIconWrapper>
                Facebookでログイン
              </FacebookLoginButton>
            </>
          )}
        />
        {facebookProfile && facebookAccount?.hasAuthority(REQUIRED_SCOPES) && (
          <FacebookLoginStatus>
            <div>{facebookProfile.name}としてログイン中</div>
            <Button
              priority={'low'}
              onClick={() => {
                FacebookLoginClient.logout(() => {
                  setFacebookProfile(undefined);
                  setFacebookAccount(undefined);
                });
              }}
            >
              ログアウト
            </Button>
          </FacebookLoginStatus>
        )}
        {facebookAccount && !facebookAccount.hasAuthority(REQUIRED_SCOPES) && (
          <FacebookLoginStatus>
            Instagramを連携するのに必要なアクセスが許可されていません。
            <br />
            再度「Facebookでログイン」をクリックして、アクセスを許可してください
          </FacebookLoginStatus>
        )}
        {facebookAccount?.hasAuthority(REQUIRED_SCOPES) && (
          <React.Fragment>
            <Step>STEP 2. 連携したいInstagramアカウントを選択する</Step>
            <InstagramAccountTable
              isLoading={false}
              accounts={accounts}
              registeredUserIds={registeredUserIds}
              selectedUserIds={selectedUserIds}
              onSelect={handleOnSelectAccount}
            />
            <ButtonWrapper>
              <StyledButton onClick={handleOnRegister}>追加</StyledButton>
            </ButtonWrapper>
          </React.Fragment>
        )}
      </Body>
    </MainWrapper>
  );
});

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Link = styled.a`
  color: ${COLOR.GREEN};
  text-decoration: underline;

  &:hover {
    color: ${COLOR.DARK_GREEN};
  }
`;

const StepManualDescription = styled.div`
  margin-left: 10px;
  margin-bottom: 16px;
  font-size: 14px;
  word-wrap: break-word;
`;

const Hr = styled.hr`
  margin: 32px 0;
`;

const Step = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 16px 0;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const FacebookLoginButton = styled(Button)`
  &&& {
    background-color: #1877f2;
    color: white;
    display: flex;
    align-items: center;
    gap: 4px;
    border: none;
    width: auto;

    &:hover {
      background-color: #1877f2;
      border: none;
      opacity: 0.7;
    }
  }
`;

const FacebookIconWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const FacebookIcon = styled(Icon).attrs({ type: 'facebook' })`
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 4px;
`;

const FacebookLoginStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
`;

const StyledButton = styled(Button).attrs({ priority: 'high' })`
  &&& {
    width: 170px;
    padding: 16px;
  }
`;
