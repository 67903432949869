import React, { useMemo } from 'react';

import { List as ImmutableList } from 'immutable';
import styled from 'styled-components';

import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import {
  DiffServiceItem,
  GmbServiceItem,
  STRUCTURED_SERVICE_GROUP_KEY,
} from 'models/Domain/GmbLocation/GmbServiceItems';
import { ServiceType } from 'models/Domain/GmbLocation/Service';

export type Props = {
  gmbCategoryList: GmbCategories;
  diffServiceItems: ImmutableList<DiffServiceItem>;
  target: 'location' | 'location_updated';
  serviceTypes: { [categoryId: string]: ImmutableList<ServiceType> };
  showDetail?: boolean;
};

export const GmbLocationServiceItemsValue: React.FC<Props> = ({
  gmbCategoryList,
  diffServiceItems,
  target,
  serviceTypes,
  showDetail = false,
}) => {
  // serviceTypeId から displayName を取得するための map
  const serviceTypeNames = useMemo(() => {
    const names: { [key: string]: string } = {};
    Object.keys(serviceTypes).forEach((categoryId) => {
      serviceTypes[categoryId].forEach((serviceType) => {
        names[serviceType.serviceTypeId] = serviceType.displayName;
      });
    });
    return names;
  }, [serviceTypes]);

  return (
    <>
      {diffServiceItems.map((diffServiceItem) => {
        const groupItems = diffServiceItem.items
          .map((item) => (target === 'location' ? item.location : item.locationUpdated))
          .filter((item) => item) as ImmutableList<GmbServiceItem>;

        if (groupItems.isEmpty()) {
          return <React.Fragment key={diffServiceItem.groupKey} />;
        }

        const groupLabel =
          diffServiceItem.type !== STRUCTURED_SERVICE_GROUP_KEY
            ? gmbCategoryList.generateDisplayName(diffServiceItem.groupKey)
            : diffServiceItem.groupKey;

        return (
          <GroupWrapper key={diffServiceItem.groupKey}>
            {groupLabel && <GroupLabel>{groupLabel}</GroupLabel>}
            <ServiceItemContainer>
              {groupItems.map((item) => (
                <React.Fragment key={item.key}>
                  <ServiceLabel>
                    {item.freeFormServiceItem?.label?.displayName ??
                      serviceTypeNames[item.structuredServiceItem?.serviceTypeId || '']}
                  </ServiceLabel>
                  {showDetail && (
                    <>
                      {item.price && <ServicePrice>価格: {item.price.label}</ServicePrice>}
                      {item.description && <ServiceDescription>{item.description}</ServiceDescription>}
                    </>
                  )}
                </React.Fragment>
              ))}
            </ServiceItemContainer>
          </GroupWrapper>
        );
      })}
    </>
  );
};

const GroupWrapper = styled.div`
  :not(:first-of-type) {
    margin-top: 32px;
  }
`;
const GroupLabel = styled.div`
  font-weight: bold;
`;
const ServiceItemContainer = styled.div``;
const ServiceLabel = styled.div``;
const ServiceDescription = styled.div`
  font-size: 14px;
`;
const ServicePrice = styled.div``;
