import React, { useMemo } from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import Menu from 'components/atoms/Menu';
import { AggregateUnit } from 'types/Common';

type Props = {
  className?: string;
  value: AggregateUnit;
  disableDay?: boolean;
  onChange: (aggregateUnit: AggregateUnit) => void;
};

const aggregateUnits = List(['day', 'week', 'month'] as AggregateUnit[]);

/**
 * 集計期間を日本語表記にする
 * @param aggregateUnit
 */
const getAggregateUnitLabel = (aggregateUnit: AggregateUnit) => {
  switch (aggregateUnit) {
    case 'month':
      return '月';
    case 'week':
      return '週';
    case 'day':
      return '日';
  }
};

/**
 * 集計期間の選択のコンポーネント
 */
export const AggregateUnitGroup = React.memo<Props>(({ className, value, disableDay = false, onChange }) => {
  const filteredAggregateUnits = useMemo(() => {
    // disableDay = true の場合は「日」を表示しない
    if (disableDay) {
      return aggregateUnits.filterNot((aggregateUnit) => aggregateUnit === 'day');
    }
    return aggregateUnits;
  }, [disableDay]);

  return (
    <Wrapper className={className}>
      <Menu>
        {filteredAggregateUnits.map((aggregateUnit) => (
          <MenuItem
            key={aggregateUnit}
            name={aggregateUnit}
            active={aggregateUnit === value}
            onClick={() => onChange(aggregateUnit)}
          >
            {getAggregateUnitLabel(aggregateUnit)}
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const MenuItem = styled(Menu.Item)`
  &&& {
    font-size: 14px;
  }
`;
