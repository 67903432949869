import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { Set } from 'immutable';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox, Icon, Popup, Radio } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { CsvDownloadButton } from 'components/molecules/CsvDownloadButton';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TagInput } from 'components/molecules/TagInput';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { SearchKeywordHelp as Help } from 'helpers/ContextHelp';
import { SearchKeywordSearchCondition } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { SearchKeywordActions } from 'modules/SearchKeyword/action';
import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';
import { Group } from 'types/Common';

export type SearchKeywordsSearchConditionProps = {
  className?: string;
  committedSearchCondition: SearchKeywordSearchCondition;
  commitSearchCondition: (searchCondition: SearchKeywordSearchCondition) => void;
  isCommitDisabled: boolean;
  openExcludedSearchKeywordsConfig: () => void;
  isProcessingCsvDownload: boolean;
  startCsvDownload: (searchCondition: SearchKeywordSearchCondition) => void;
  checkCsvDownloadStatus: (timerId: number) => void;
  clearCsvDownload: () => void;
  isOpenExcludedKeywordsStickyFooter: boolean;
  setIsOpenExcludedKeywordsStickyFooter: (isOpen: boolean) => void;
  excludedKeywordCount?: number;
};

const PERIOD_MAX_DATE = dayjs().add(-1, 'month').toDate();

export const SearchKeywordsSearchCondition = React.memo(
  ({
    className,
    committedSearchCondition,
    commitSearchCondition,
    isCommitDisabled,
    openExcludedSearchKeywordsConfig,
    isProcessingCsvDownload,
    startCsvDownload,
    checkCsvDownloadStatus,
    clearCsvDownload,
    isOpenExcludedKeywordsStickyFooter,
    setIsOpenExcludedKeywordsStickyFooter,
    excludedKeywordCount = 0,
  }: SearchKeywordsSearchConditionProps) => {
    const [searchCondition, setSearchCondition] = useState<SearchKeywordSearchCondition>(committedSearchCondition);
    const dispatch = useDispatch();
    const { setIsUpdatedExcludedKeywords } = useMemo(
      () => bindActionCreators(SearchKeywordActions, dispatch),
      [dispatch],
    );
    useEffect(() => {
      setSearchCondition(committedSearchCondition);
    }, [committedSearchCondition]);

    /**
     * グループ、店舗のフィルターを変更する
     * @param group グループ
     * @param storeIds 店舗のID
     * @param isAllStores すべての店舗が選択されているか
     * @param showClosedStores 閉店店舗を表示するか
     */
    const handleChangeGroupStore = useCallback(
      (group: Group, storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.merge({
            group: group,
            storeIds: storeIds,
            isAllStoreIds: isAllStores,
            showClosedStore: showClosedStores,
          }),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    /**
     * 期間を変更する
     * @param startDate 期間の開始月(Date型でdayは常に1)
     * @param endDate 期間の終了月(Date型でdayは常に1)
     */
    const handleChangePeriod = useCallback(
      (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
          return;
        }
        const startMonth = dayjs(startDate).format('YYYY-MM');
        const endMonth = dayjs(endDate).format('YYYY-MM');
        const newSearchCondition = searchCondition.update('filter', (filter) => filter.merge({ startMonth, endMonth }));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    /**
     * 検索数の少ないキーワードを除外のオンオフ
     */
    const handleChangeExcludeLessThanCount = useCallback(() => {
      const newSearchCondition = searchCondition.update('filter', (filter) =>
        filter.update('excludeLessThanCount', (excludeLessThanCount) => !excludeLessThanCount),
      );
      setSearchCondition(newSearchCondition);
    }, [searchCondition]);

    /**
     * 除外設定を無視して検索するのオンオフ
     */
    const handleChangeContainsExcluded = useCallback(() => {
      const newSearchCondition = searchCondition.update('filter', (filter) =>
        filter.update('containsExcluded', (containsExcluded) => !containsExcluded),
      );
      setSearchCondition(newSearchCondition);
    }, [searchCondition]);

    /**
     * 含むキーワードを変更する
     */
    const handleChangeFilterWords = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.merge({ filterWords: Set(values) }),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    /**
     * 含むキーワードの部分一致、完全一致を変更する
     */
    const handleChangefilterWordsExactMatch = useCallback(
      (value: boolean) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.set('filterWordsExactMatch', value),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    /**
     * 除外キーワードを変更する
     */
    const handleChangeExcludeWords = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.merge({ excludeWords: Set(values) }),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    /**
     * 除外キーワードの部分一致、完全一致を変更する
     */
    const handleChangeexcludeWordsExactMatch = useCallback(
      (value: boolean) => {
        const newSearchCondition = searchCondition.update('filter', (filter) =>
          filter.set('excludeWordsExactMatch', value),
        );
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    /**
     * 検索条件を確定する
     */
    const handleCommitSearchCondition = useCallback(() => {
      // targetMonthが新しい集計期間に含まれていなければ、終了月に合わせる
      const targetMonth = committedSearchCondition.filter.targetMonth;
      const { startMonth, endMonth } = searchCondition.filter;
      if (targetMonth < startMonth || endMonth < targetMonth) {
        commitSearchCondition(searchCondition.setIn(['filter', 'targetMonth'], endMonth));
      } else {
        commitSearchCondition(searchCondition);
      }

      // 上記で常に除外するキーワードが更新された場合、リロードを実施するために以下を実行（キャッシュ更新の問題）
      if (isOpenExcludedKeywordsStickyFooter) {
        setIsUpdatedExcludedKeywords(true);
        setIsOpenExcludedKeywordsStickyFooter(false);
      }
    }, [
      committedSearchCondition.filter.targetMonth,
      searchCondition,
      commitSearchCondition,
      isOpenExcludedKeywordsStickyFooter,
      setIsUpdatedExcludedKeywords,
      setIsOpenExcludedKeywordsStickyFooter,
    ]);

    /**
     * 含むキーワードや除外キーワードをクリップボードにコピーする
     *
     * @param keywordsSet コピーするキーワードの種類（含むキーワード：filterWords または 除外キーワード：excludeWords）
     */
    const onClickKeywordsToClipboard = useCallback(
      (keywordsSet: 'filterWords' | 'excludeWords') => {
        const keywords = searchCondition.filter[keywordsSet].toArray();
        const keywordsText = keywords.join('\n');
        navigator.clipboard.writeText(keywordsText);
      },
      [searchCondition.filter],
    );

    /**
     * 含むキーワードと除外キーワードを入れ替える
     */
    const onClickExchangeKeywords = useCallback(() => {
      const newSearchCondition = searchCondition.update('filter', (filter) =>
        filter.merge({
          filterWords: searchCondition.filter.excludeWords,
          excludeWords: searchCondition.filter.filterWords,
          filterWordsExactMatch: searchCondition.filter.excludeWordsExactMatch,
          excludeWordsExactMatch: searchCondition.filter.filterWordsExactMatch,
        }),
      );
      setSearchCondition(newSearchCondition);
    }, [searchCondition]);

    // CSVダウンロード中の進捗取得処理
    useEffect(() => {
      // 3秒おきに進捗を取得する
      if (isProcessingCsvDownload) {
        const intervalId = window.setInterval(() => {
          checkCsvDownloadStatus(intervalId);
        }, 3000);

        // 戻るボタンなどで遷移した場合にタイマーを停止
        return () => {
          window.clearInterval(intervalId);
          // 再度ダウンロードした場合に以前のExecutionArnが参照されないように削除
          clearCsvDownload();
        };
      }
    }, [checkCsvDownloadStatus, clearCsvDownload, isProcessingCsvDownload]);

    const includeKeywordsRef = useRef<HTMLDivElement>(null);
    const excludeKeywordsRef = useRef<HTMLDivElement>(null);

    const handleSelectionChange = useCallback((ref) => {
      const selection = window.getSelection();
      if (
        selection &&
        selection.rangeCount > 0 &&
        ref.current &&
        ref.current.contains(selection.getRangeAt(0).commonAncestorContainer)
      ) {
        const range = selection.getRangeAt(0);
        const fragment = range.cloneContents();
        const links = fragment.querySelectorAll('a');
        const texts = Array.from(links)
          .map((link) => link.textContent || '')
          .filter((text) => text)
          .join('\n');
        if (texts) {
          navigator.clipboard
            .writeText(texts)
            .then(() => console.log('Links copied to clipboard'))
            .catch((err) => console.error('Failed to copy links', err));
        }
      }
    }, []);

    useEffect(() => {
      const handleSelection = () => {
        handleSelectionChange(includeKeywordsRef);
        handleSelectionChange(excludeKeywordsRef);
      };
      document.addEventListener('selectionchange', handleSelection);
      return () => {
        document.removeEventListener('selectionchange', handleSelection);
      };
    }, [handleSelectionChange]);

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <GroupStoreSelect
            group={searchCondition.filter.group}
            storeIds={searchCondition.filter.storeIds}
            showClosedStores={searchCondition.filter.showClosedStore}
            onChange={handleChangeGroupStore}
          />
          <FlexContentGroup>
            <FlexContent>
              <Label>集計期間</Label>
              <InnerContent>
                <StyledDateRangePicker
                  startDate={dayjs(searchCondition.filter.startMonth).toDate()}
                  endDate={dayjs(searchCondition.filter.endMonth).toDate()}
                  onChange={handleChangePeriod}
                  maxDate={PERIOD_MAX_DATE}
                />
              </InnerContent>
            </FlexContent>
            <FlexContent>
              <Label>集計対象</Label>
              <CheckboxInnerContent>
                <StyledCheckbox
                  onChange={handleChangeExcludeLessThanCount}
                  checked={!searchCondition.filter.excludeLessThanCount}
                  label='検索数の少ないキーワードを含めて検索する'
                />
                <ContextHelpWrapper>
                  <ContextHelp content='検索数15回未満のキーワードをキーワード数の合計に含める（流入数では0扱い）' />
                </ContextHelpWrapper>
              </CheckboxInnerContent>
              <CheckboxInnerContent>
                <StyledCheckbox
                  onChange={handleChangeContainsExcluded}
                  checked={searchCondition.filter.containsExcluded}
                  label='除外キーワードを含めて検索する'
                />
              </CheckboxInnerContent>
            </FlexContent>
          </FlexContentGroup>
          <KeywordInputContent>
            <Label>
              含むキーワード
              <RadioContainer>
                <StyledRadio
                  label='部分一致'
                  checked={!searchCondition.filter.filterWordsExactMatch}
                  onChange={() => handleChangefilterWordsExactMatch(false)}
                />
                <StyledRadio
                  label='完全一致'
                  checked={searchCondition.filter.filterWordsExactMatch}
                  onChange={() => handleChangefilterWordsExactMatch(true)}
                />
              </RadioContainer>
              <Popup
                style={{ fontSize: '14px' }}
                content={Help.copy}
                trigger={
                  <CopyIconButtonWrapper>
                    <StyledIconButton priority='low' onClick={() => onClickKeywordsToClipboard('filterWords')}>
                      <CopyIcon name='copy outline' />
                    </StyledIconButton>
                  </CopyIconButtonWrapper>
                }
              />
            </Label>
            <StyledClipBoardComponent ref={includeKeywordsRef}>
              <TagInput values={searchCondition.filter.filterWords.toArray()} onChange={handleChangeFilterWords} />
            </StyledClipBoardComponent>
          </KeywordInputContent>
          <RightAlignedWrapper>
            <Popup
              style={{ fontSize: '14px' }}
              content={Help.changeKeyword}
              trigger={
                <ExchangeIconButtonWrapper>
                  <StyledIconButton priority='low' onClick={onClickExchangeKeywords}>
                    <ExchangeIcon name='exchange' />
                  </StyledIconButton>
                </ExchangeIconButtonWrapper>
              }
            />
          </RightAlignedWrapper>
          <KeywordInputContent>
            <Label>
              除外キーワード
              <RadioContainer>
                <StyledRadio
                  label='部分一致'
                  checked={!searchCondition.filter.excludeWordsExactMatch}
                  onChange={() => handleChangeexcludeWordsExactMatch(false)}
                />
                <StyledRadio
                  label='完全一致'
                  checked={searchCondition.filter.excludeWordsExactMatch}
                  onChange={() => handleChangeexcludeWordsExactMatch(true)}
                />
              </RadioContainer>
              <Popup
                style={{ fontSize: '14px' }}
                content={Help.copy}
                trigger={
                  <CopyIconButtonWrapper>
                    <StyledIconButton priority='low' onClick={() => onClickKeywordsToClipboard('excludeWords')}>
                      <CopyIcon name='copy outline' />
                    </StyledIconButton>
                  </CopyIconButtonWrapper>
                }
              />
              <OpenExcludedKeywordsConfig onClick={openExcludedSearchKeywordsConfig}>
                常に適用する除外キーワードを設定する{excludedKeywordCount > 0 && `（${excludedKeywordCount}件 設定中）`}
              </OpenExcludedKeywordsConfig>
            </Label>
            <StyledClipBoardComponent ref={excludeKeywordsRef}>
              <TagInput values={searchCondition.filter.excludeWords.toArray()} onChange={handleChangeExcludeWords} />
            </StyledClipBoardComponent>
          </KeywordInputContent>
          <CommitButtonContent>
            <CsvDownloadButton
              label='この条件でCSVをダウンロード'
              disabled={isCommitDisabled || isProcessingCsvDownload}
              isProcessing={isProcessingCsvDownload}
              onClick={() => startCsvDownload(searchCondition)}
            />
            <CommitButton priority='high' disabled={isCommitDisabled} onClick={handleCommitSearchCondition}>
              検索
            </CommitButton>
          </CommitButtonContent>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  padding: 18px;
  background: white;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FlexContent = styled.div``;

const FlexContentGroup = styled(FlexContent)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  margin-bottom: 8px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 7px;
  flex-wrap: wrap;
  gap: 4px 16px;
  margin-bottom: 4px;
`;

const InnerContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledDateRangePicker = styled(DateRangePicker).attrs(() => ({
  dateFormat: 'yyyy年M月',
  showMonthYearPicker: true,
}))`
  &&& {
    input {
      height: 33px;
      max-width: 120px;
      min-width: 90px;
    }
  }
`;

const CheckboxInnerContent = styled(InnerContent)`
  margin-top: 4px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 12px;
    color: #707070;
    cursor: pointer;
    margin-top: 4px;
  }
`;

const ContextHelpWrapper = styled.div`
  display: inline-block;
  margin-bottom: -12px;
`;

const KeywordInputContent = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const OpenExcludedKeywordsConfig = styled.span`
  font-size: 13px;
  color: ${COLOR.GREEN};
  padding-left: 16px;
  cursor: pointer;
`;

const CommitButtonContent = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const CommitButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    align-self: end;
    margin-left: 8px;
    box-shadow: none;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 16px;
`;

const StyledRadio = styled(Radio)`
  &&& {
    label {
      padding-left: 24px;
    }
    input {
      &:checked ~ label:before {
        border: 1px solid ${COLOR.GREEN};
      }
      &:checked ~ label:after {
        background: ${COLOR.GREEN};
      }
      &:focus {
        &:checked ~ label:after {
          background: ${COLOR.GREEN};
        }
      }
    }
  }
`;

const RightAlignedWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CopyIconButtonWrapper = styled.div`
  width: 30px;
  color: #f00;
  font-size: 20px;
  font-weight: bold;
  ${Mixins.flex('center')}
  justify-content: center;
`;

const ExchangeIconButtonWrapper = styled.div`
  width: 30px;
  color: #f00;
  font-size: 20px;
  font-weight: bold;
  ${Mixins.flex('center')}
  justify-content: center;
  margin-top: 12px;
`;

const StyledIconButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    color: ${COLOR.GRAY};
  }
`;

const ExchangeIcon = styled(Icon)`
  &&& {
    line-height: 1;
    height: auto;
    transform: rotate(90deg);
  }
`;

const CopyIcon = styled(Icon)`
  &&& {
    line-height: 1;
    height: auto;
  }
`;

const StyledClipBoardComponent = styled.div`
  &&& {
    .ui.multiple.dropdown > .label {
      user-select: text;
    }
  }
`;
