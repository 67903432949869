import ApiClient from 'ApiClient';
import { JSObject } from 'types/Common';

const apiClient = new ApiClient({});

const USER_LIST_PATH = '/users';
const USER_DETAIL_PATH = (userId: number) => `${USER_LIST_PATH}/${String(userId)}`;

type Role = 'none' | 'member' | 'sv' | 'vmd' | 'admin';

type User = {
  first_name: string;
  last_name: string;
  pb_id: string;
  email: string;
  role: Role;
  id: number | null;
  organization_id: number | null;
  organization: JSObject;
  managing_stores: JSObject[];
  stores: JSObject[];
  is_setup: boolean | null;
  notify_review_update: boolean | null;
  notify_offer_update: boolean | null;
  notify_location_update: boolean | null;
  mcc_user_id: boolean | null;
  is_deleted: boolean | null;
  is_hidden: boolean | null;
  last_active_time: string | null;
};

export type GetUserListApiResponse = User[];
export type GetUserApiResponse = User;

export class UserListApi {
  static get() {
    return apiClient.get<GetUserListApiResponse>(USER_LIST_PATH);
  }

  static post(params: JSObject) {
    return apiClient.post(USER_LIST_PATH, params);
  }
}

export class UserApi {
  static get(id: number) {
    return apiClient.get<GetUserApiResponse>(USER_DETAIL_PATH(id));
  }

  static put(params: JSObject) {
    return apiClient.put(USER_DETAIL_PATH(params.id), params);
  }

  static delete(userId: number) {
    return apiClient.delete(USER_DETAIL_PATH(userId));
  }
}
