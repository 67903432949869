import { CallHistoryMethodAction, push, replace } from 'connected-react-router';
import { Hash, LocationDescriptorObject, LocationState, Path, Search } from 'history';

import { AccountSingleton } from 'models/Domain/AccountList';
import { ORGANIZATION_ID_QUERY_KEY } from 'modules/app/const';

const EmptyAction = { type: null };
type EmptyActionType = typeof EmptyAction;

/**
 * 組織IDをクエリパラメータに含めてパスに遷移するReduxのアクションを生成する
 * @param path 遷移先のパス Path または LocationDescriptorObject
 * @param state replaceにスルーする状態
 * @returns
 */
export function replaceWithOrganizationId<S = LocationState>(
  path?: Path,
  state?: S,
): CallHistoryMethodAction<[Path, S?]> | EmptyActionType;

export function replaceWithOrganizationId<S = LocationState>(
  location: LocationDescriptorObject<S>,
): CallHistoryMethodAction<[LocationDescriptorObject<S>]> | EmptyActionType;

export function replaceWithOrganizationId<S = LocationState>(path?: Path | LocationDescriptorObject<S>, state?: S) {
  if (!hasDiffPathFromCurrent(path)) {
    return EmptyAction;
  }
  const updatedPath = getPathWithOrganizationId(path);

  if (typeof updatedPath === 'string') {
    return replace(updatedPath, state);
  } else {
    return replace(updatedPath);
  }
}

/**
 * 組織IDをクエリパラメータに含めてパスに遷移するReduxのアクションを生成する
 * @param path 遷移先のパス Path または LocationDescriptorObject
 * @param state pushにスルーする状態
 * @returns
 */
export function pushWithOrganizationId<S = LocationState>(
  path?: Path,
  state?: S,
): CallHistoryMethodAction<[Path, S?]> | EmptyActionType;

export function pushWithOrganizationId<S = LocationState>(
  location: LocationDescriptorObject<S>,
): CallHistoryMethodAction<[LocationDescriptorObject<S>]> | EmptyActionType;

export function pushWithOrganizationId<S = LocationState>(path?: Path | LocationDescriptorObject<S>, state?: S) {
  if (!hasDiffPathFromCurrent(path)) {
    return EmptyAction;
  }
  const updatedPath = getPathWithOrganizationId(path);

  if (typeof updatedPath === 'string') {
    return push(updatedPath, state);
  } else {
    return push(updatedPath);
  }
}

/**
 * 組織IDを含めた（存在しない場合は付加する）パスを返す
 * @param path Path または LocationDescriptorObject
 * @returns
 */
const getPathWithOrganizationId = <T extends Path | LocationDescriptorObject<S>, S = LocationState>(path?: T): T => {
  if (!path) {
    const currentPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    return buildPath<S>(currentPath) as T;
  } else if (typeof path === 'string') {
    return buildPath<S>(path) as T;
  }
  return buildLocationDescriptor<S>(path) as T;
};

type BuildPathOptions = {
  params?: { [key: string]: any };
  query?: { [key: string]: any };
  withOrganizationId?: boolean;
};

/**
 * 各種情報を元にURLを生成する
 * @param path 元になるパス
 * @param options.params パスパラメータ. パス中の `:key` 部分を置き換える
 * @param options.query クエリパラメータ. パス中に同一キーのクエリパラメータがある場合上書きする
 * @param options.withOrganizationId 組織IDを含めてURLを生成するか
 * @returns
 */
export const buildPath = <S = LocationState>(
  path: string | LocationDescriptorObject<S>,
  options: BuildPathOptions = {},
): Path => {
  const locationDescriptor = buildLocationDescriptor(path, options);
  let result = '';
  if (locationDescriptor.pathname) {
    result += locationDescriptor.pathname;
  }
  if (locationDescriptor.search) {
    result += `?${locationDescriptor.search}`;
  }
  if (locationDescriptor.hash) {
    result += `#${locationDescriptor.hash}`;
  }
  return result;
};

/**
 * 各種情報を元にLocationDescriptorObjectを生成する
 * @param path 元になるパス
 * @param options.params パスパラメータ. パス中の `:key` 部分を置き換える
 * @param options.query クエリパラメータ. パス中に同一キーのクエリパラメータがある場合上書きする
 * @param options.withOrganizationId 組織IDを含めてURLを生成するか
 * @returns
 */
export const buildLocationDescriptor = <S = LocationState>(
  path: Path | LocationDescriptorObject<S>,
  options: BuildPathOptions = {},
): LocationDescriptorObject => {
  const { params = {}, query = {}, withOrganizationId = true } = options;

  let pathname: Path | undefined = undefined;
  let search: Search | undefined = undefined;
  let hash: Hash | undefined = undefined;

  if (typeof path === 'string') {
    const { pathname: _pathname, search: _search, hash: _hash } = extractPath(path);
    pathname = _pathname;
    search = _search;
    hash = _hash;
  } else {
    pathname = path.pathname;
    search = path.search === undefined ? undefined : path.search.replace(/^\?/, '');
    hash = path.hash === undefined ? undefined : path.hash.replace(/^#/, '');
  }

  let locationDescriptor: LocationDescriptorObject;

  // パス内のパラメータを設定する
  if (pathname !== undefined) {
    for (const [key, value] of Object.entries(params)) {
      const _key = `:${key.replace(/^:+/, '')}`; // keyは「:」あってもなくても対応
      pathname = pathname.replace(_key, value);
    }
  }

  // クエリパラメータを設定
  if ((search !== undefined && search.length > 0) || query.length > 0 || withOrganizationId) {
    const searchParams = new URLSearchParams(search);

    // クエリパラメータを設定する
    for (const [key, value] of Object.entries(query)) {
      searchParams.set(key, value);
    }

    if (withOrganizationId) {
      // ログインしている組織IDを取得し、クエリパラメータに設定する
      const organizationId = AccountSingleton.getInstance().organizationId;
      if (organizationId) {
        searchParams.set(ORGANIZATION_ID_QUERY_KEY, String(organizationId));
      } else {
        searchParams.delete(ORGANIZATION_ID_QUERY_KEY);
      }
    }

    const _search = searchParams.toString();
    locationDescriptor = { pathname, search: _search, hash };
  } else {
    locationDescriptor = { pathname, hash };
  }

  if (typeof path === 'string') {
    return locationDescriptor;
  }

  return { ...path, ...locationDescriptor };
};

/**
 * 指定されたパスが現在のパスと比べて差分があるかどうかを返す
 * @param path
 * @returns
 */
const hasDiffPathFromCurrent = <T extends Path | LocationDescriptorObject<S>, S = LocationState>(path?: T): boolean => {
  const currentPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  const updatedPath = buildPath(path || currentPath);
  return hasDiffPath(currentPath, updatedPath, []);
};

/**
 * パス（パス名、クエリパラメータ、ハッシュ）の差分有無を返す
 * @param path1 パス1
 * @param path2 パス2
 * @param excludeSearches 差分チェックで除外するクエリパラメータのキー
 * @returns
 */
export const hasDiffPath = (path1: string, path2: string, excludeSearches: string[] = [ORGANIZATION_ID_QUERY_KEY]) => {
  const { pathname: pathname1, search: search1, hash: hash1 } = extractPath(path1);
  const { pathname: pathname2, search: search2, hash: hash2 } = extractPath(path2);

  // パス名が異なる場合は差分あり
  if (pathname1 !== pathname2) {
    return true;
  }

  // ハッシュが異なる場合は差分あり
  if (hash1 !== hash2) {
    return true;
  }

  // クエリパラメータが異なる場合は差分あり
  return hasDiffSearch(search1 || '', search2 || '', excludeSearches);
};

/**
 * クエリパラメータの差分有無を返す
 * @param s1 クエリパラメータ1
 * @param s2 クエリパラメータ2
 * @param excludeSearches 差分チェックで除外するクエリパラメータのキー
 * @returns
 */
export const hasDiffSearch = (s1: Search, s2: Search, excludeSearches: string[] = [ORGANIZATION_ID_QUERY_KEY]) => {
  // クエリパラメータの取得
  const search1 = new URLSearchParams(s1.split('?').at(-1));
  const search2 = new URLSearchParams(s2.split('?').at(-1));

  for (const excludeSearch of excludeSearches) {
    search1.delete(excludeSearch);
    search2.delete(excludeSearch);
  }

  // クエリパラメータの数が異なる場合、差分あり
  if (Array.from(search1.entries()).length !== Array.from(search2.entries()).length) {
    return true;
  }

  // 同一キーのクエリパラメータが異なる場合、差分あり
  for (const param of Array.from(search1.keys())) {
    if (search1.get(param) !== search2.get(param)) {
      return true;
    }
  }

  return false;
};

/**
 * パスを分解してパス名、クエリパラメータ、ハッシュを取得する
 *
 * FIXME new URL(path) でパースしたかったが、なぜかエラーになるので手動でパースしている
 *
 * @param path
 * @returns
 */
export const extractPath = (path: string): LocationDescriptorObject => {
  const hash = path.split('#')[1] || undefined;
  const rest = path.split('#')[0] || '';
  const pathname = rest.split('?')[0];
  const search = rest.split('?')[1] || undefined;
  return { pathname, search, hash };
};
