import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export const OpenInfoStatusArray = [
  'OPEN_FOR_BUSINESS_UNSPECIFIED',
  'OPEN',
  'CLOSED_PERMANENTLY',
  'CLOSED_TEMPORARILY',
];
export type OpenInfoStatus = (typeof OpenInfoStatusArray)[number];

interface OptionInterface {
  name: string;
  value: OpenInfoStatus;
}

export interface OptionsInterface {
  OPEN: OptionInterface;
  CLOSED_PERMANENTLY: OptionInterface;
  CLOSED_TEMPORARILY: OptionInterface;
}

export const openInfoOptions: OptionsInterface = {
  OPEN: {
    name: '営業中',
    value: 'OPEN',
  },
  CLOSED_PERMANENTLY: {
    name: '閉業',
    value: 'CLOSED_PERMANENTLY',
  },
  CLOSED_TEMPORARILY: {
    name: '臨時休業',
    value: 'CLOSED_TEMPORARILY',
  },
};

export const selectOpenInfoOptions = [
  { text: openInfoOptions.OPEN.name, value: openInfoOptions.OPEN.value },
  { text: openInfoOptions.CLOSED_PERMANENTLY.name, value: openInfoOptions.CLOSED_PERMANENTLY.value },
  { text: openInfoOptions.CLOSED_TEMPORARILY.name, value: openInfoOptions.CLOSED_TEMPORARILY.value },
];

// https://developers.google.com/my-business/reference/rest/v4/accounts.locations?hl=ja#Location.OpenInfo
export class GmbOpenInfo extends Record<{
  status: OpenInfoStatus;
  canReopen: boolean;
  openingDate: JSObject;
}>({
  status: 'OPEN',
  canReopen: false,
  openingDate: {},
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get statusName() {
    if (this.status === openInfoOptions.OPEN.value) {
      return openInfoOptions.OPEN.name;
    }
    if (this.status === openInfoOptions.CLOSED_PERMANENTLY.value) {
      return openInfoOptions.CLOSED_PERMANENTLY.name;
    }
    if (this.status === openInfoOptions.CLOSED_TEMPORARILY.value) {
      return openInfoOptions.CLOSED_TEMPORARILY.name;
    }
    return '';
  }

  get isOpen() {
    return this.status === 'OPEN';
  }

  get isClose() {
    return this.status === 'CLOSED_PERMANENTLY';
  }

  changeStatus(status: OpenInfoStatus) {
    return this.set('status', status);
  }

  validate() {
    return {
      status: this.validateStatus(),
    };
  }

  validateStatus() {
    return {
      isValid: true,
      error: '',
    };
  }

  updateParams() {
    return {
      openInfo: {
        status: this.status,
        openingDate: this.openingDate,
      },
    };
  }
}
