import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { CompetitorList } from 'models/Domain/MapCompetitorResearch/Competitor';
import { MapCompetitorResearchSearchCondition } from 'models/Domain/MapCompetitorResearch/MapCompetitorResearchSearchCondition';
import { MapSearchResultDetails } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { MapSearchResultStatus } from 'models/Domain/MapCompetitorResearch/MapSearchResultStatus';

import { MapCompetitorResearchActions as Actions } from './actions';

export class MapCompetitorResearchState extends Record<{
  searchCondition: MapCompetitorResearchSearchCondition;

  // 結果ページデータ
  isLoadingResult: boolean;
  executionArn: ExecutionArnStatus | null;
  resultStatus: MapSearchResultStatus | null;
  resultDetails: MapSearchResultDetails;

  competitors: CompetitorList;
}>({
  searchCondition: new MapCompetitorResearchSearchCondition(),

  isLoadingResult: true,
  executionArn: null,
  resultStatus: null,
  resultDetails: new MapSearchResultDetails(),

  competitors: new CompetitorList(),
}) {}

export const mapCompetitorResearchReducer = reducerWithInitialState(new MapCompetitorResearchState())
  .case(Actions.clearState, (state, payload) => {
    return new MapCompetitorResearchState();
  })
  .case(Actions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(Actions.setIsLoadingResult, (state, payload) => {
    return state.set('isLoadingResult', payload);
  })
  .case(Actions.setExecutionArn, (state, payload) => {
    return state.set('executionArn', payload);
  })
  .case(Actions.setResultStatus, (state, payload) => {
    return state.set('resultStatus', payload);
  })
  .case(Actions.setResultDetails, (state, payload) => {
    return state.set('resultDetails', payload);
  })
  .case(Actions.setCompetitors, (state, payload) => {
    return state.set('competitors', payload);
  });
