import React, { useCallback, useState } from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { ServiceHelp as Help } from 'helpers/ContextHelp';
import { ServiceList } from 'models/Domain/Service/Service';
import { COLOR } from 'style/color';

import { ServiceSortItem } from './ServiceSortItem';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  services: ServiceList;
  onChange: (services: ServiceList) => void;
};

export const ServiceSortModal = React.memo<Props>(({ isOpen, onClose, services: defaultServices, onChange }) => {
  const [servicesForEdit, setServicesForEdit] = useState<ServiceList>(defaultServices);

  const structuredServices = servicesForEdit.structuredServices;
  const customServices = servicesForEdit.customServices;

  const handleSave = useCallback(() => {
    onChange(servicesForEdit);
    onClose();
  }, [onChange, onClose, servicesForEdit]);

  const customStartIndex = servicesForEdit.items.findIndex((service) => service.isCustom);

  const handleChangeIndex = useCallback((index: number) => {
    setServicesForEdit((services) => services.moveItem(index, index - 1));
  }, []);

  const handleDelete = useCallback((index: number) => {
    setServicesForEdit((services) => services.removeItem(index));
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title>サービスの並び替えと削除</Title>
          <ContentWrapper>
            <Description>
              サービスを表示する順番を変更できます。
              <br />
              並び替えできるサービスはカスタムサービスのみです。
            </Description>
            <Content>
              {structuredServices.size > 0 && (
                <ServiceContainer>
                  <ServiceType>
                    プリセットサービス
                    <ContextHelp content={Help.structuredService} />
                  </ServiceType>
                  {structuredServices.map((service, index) => (
                    <ServiceSortItem
                      key={service.id}
                      service={service}
                      canSort={false}
                      onDelete={() => handleDelete(index)}
                      showDivider={0 < index}
                    />
                  ))}
                </ServiceContainer>
              )}
              {customServices.size > 0 && (
                <ServiceContainer>
                  <ServiceType>
                    カスタムサービス
                    <ContextHelp content={Help.customService} />
                  </ServiceType>
                  {customServices.map((service, i) => {
                    const index = i + customStartIndex;
                    return (
                      <ServiceSortItem
                        key={service.id}
                        service={service}
                        canSort={i > 0}
                        onDelete={() => handleDelete(index)}
                        onChangeIndex={() => handleChangeIndex(index)}
                        showDivider={0 < i}
                      />
                    );
                  })}
                </ServiceContainer>
              )}
              {servicesForEdit.items.size === 0 && (
                <Alert type={'info'}>
                  <Alert.Title>サービスが登録されていません</Alert.Title>
                </Alert>
              )}
            </Content>
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>キャンセル</StyledButton>
            <StyledButton onClick={handleSave} priority={'high'} disabled={false}>
              適用
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
});

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

const Description = styled.div`
  margin-bottom: 24px;
  color: ${COLOR.DARK_GRAY};
`;

const ServiceType = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const ServiceContainer = styled.div`
  margin-bottom: 24px;
`;
