import { Set as ImmutableSet } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { Menu, MenuSummary } from 'models/Domain/Menu/Menu';

const actionCreator = actionCreatorFactory('Menu');

export const MenuActions = {
  initializeIndexPage: actionCreator('initializeIndexPage'),
  initializeCreatePage: actionCreator<number | null>('initializeCreatePage'),
  initializeEditPage: actionCreator<number>('initializeEditPage'),
  setIsLoadingMenu: actionCreator<boolean>('setIsLoadingMenu'),
  setMenu: actionCreator<Menu>('setMenu'),
  uploadImage: actionCreator<File[]>('uploadImage'),
  setUploadedImageUrl: actionCreator<string>('setUploadedImageUrl'),
  clearUploadedImageUrl: actionCreator('deleteUploadedImageUrl'),
  fetchMenuSummary: actionCreator('fetchMenuSummary'),
  setMenuSummary: actionCreator<MenuSummary>('setMenuSummary'),
  updateMenuSummary: actionCreator<MenuSummary>('updateMenuSummary'),
  deleteMenuSummary: actionCreator<ImmutableSet<number>>('deleteMenuSummary'),
  createMenu: actionCreator<Menu>('createMenu'),
  updateMenu: actionCreator<Menu>('updateMenu'),
  deleteMenu: actionCreator<number>('deleteMenu'),
  applyToGbp: actionCreator('applyToGbp'),
  setIsApplyingToGbp: actionCreator<boolean>('setIsApplyingToGbp'),
};
