import React, { useCallback, useEffect, useMemo } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { MenuEditForm } from 'components/pageComponents/Menu/MenuEditForm';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle, isNonNegativeInteger } from 'helpers/utils';
import { Menu } from 'models/Domain/Menu/Menu';
import { AppActions } from 'modules/app/actions';
import { MenuActions } from 'modules/menu/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const MenuEdit: React.FC = () => {
  const dispatch = useDispatch();
  const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
  const { initializeEditPage, deleteMenu, updateMenu } = useMemo(
    () => bindActionCreators(MenuActions, dispatch),
    [dispatch],
  );
  const { menu } = useSelector((state) => state.menu);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (isNonNegativeInteger(id)) {
      initializeEditPage(parseInt(id, 10));
    } else {
      // メニューIDが不正な場合はメニュー一覧に遷移
      moveTo(Path.menu.index);
    }
  }, [id, initializeEditPage, moveTo]);

  const handleOnSave = useCallback(
    (menu: Menu) => {
      updateMenu(menu);
    },
    [updateMenu],
  );

  const handleOnDelete = useCallback(() => {
    if (!window.confirm('本当に削除しますか？') || !menu.id) return;
    deleteMenu(menu.id);
  }, [deleteMenu, menu.id]);

  const handleOnClone = useCallback(() => {
    moveTo({
      pathname: Path.menu.create,
      state: { baseId: menu.id },
    });
  }, [menu.id, moveTo]);

  const handleOnCancel = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('メニューグループの編集')} />
      <StickyHeader>
        <Title>メニューグループの編集</Title>
        <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
          <Dropdown.Menu direction='left'>
            <ActionMenuItem onClick={handleOnClone}>
              <ActionItemLabel>複製して新規作成</ActionItemLabel>
            </ActionMenuItem>
            <ActionMenuItem onClick={handleOnDelete}>
              <ActionItemLabel>削除</ActionItemLabel>
            </ActionMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </StickyHeader>
      <WideBody>
        <MenuEditForm menu={menu} onSave={handleOnSave} onCancel={handleOnCancel} />
      </WideBody>
    </MainWrapper>
  );
};

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

const ActionItemLabel = styled.div`
  padding: 10px;
`;

const Trigger = styled.div`
  cursor: pointer;
  letter-spacing: -10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${COLOR.GREEN};
  transform: rotate(90deg) translateX(-4px);
`;
