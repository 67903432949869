import CONFIG from 'config';
import { User } from 'models/Domain/User';

interface Window {
  ChannelIO: any;
  ChannelIOInitialized: any;
  attachEvent: any;
  addEventListener: any;
  console: any;
}

declare let window: Window;

// https://developers.channel.io/docs/web-installation#single-page-application
class ChannelService {
  constructor() {
    this.loadScript();
  }

  loadScript() {
    const w = window;
    if (w.ChannelIO) {
      return (
        window.console.error ||
        window.console.log ||
        function () {
          // pass
        }
      )('ChannelIO script included twice.');
    }
    const ch: any = function () {
      // eslint-disable-next-line prefer-rest-params
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args: any) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      const x = document.getElementsByTagName('script')[0];
      if (x && x.parentNode) {
        x.parentNode.insertBefore(s, x);
      }
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  boot(settings = {}) {
    window.ChannelIO('boot', {
      ...settings,
      pluginKey: CONFIG.CHANNEL_TALK_PLUGIN_KEY,
      hideChannelButtonOnBoot: true,
    });
  }

  updateUser(settings: User, memberIdHash: string) {
    this.shutdown();
    window.ChannelIO('boot', {
      ...settings,
      profile: {
        name: settings.fullName,
        email: settings.email,
        st_client_id: settings.organization ? settings.organization.id : '',
        st_client_name: settings.organization ? settings.organization.name : '',
      },
      pluginKey: CONFIG.CHANNEL_TALK_PLUGIN_KEY,
      memberId: memberIdHash,
      hideChannelButtonOnBoot: true,
    });
  }

  shutdown() {
    window.ChannelIO('shutdown');
  }

  showMessenger() {
    window.ChannelIO('showMessenger');
  }

  onBadgeChanged(callbackFunction: (unreadCount: number) => void) {
    window.ChannelIO('onBadgeChanged', callbackFunction);
  }
}

export default new ChannelService();
