import React, { useCallback, useMemo, useState } from 'react';

import { List, Set } from 'immutable';
import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Input } from 'components/atoms/Input';
import { ErrorLabel } from 'components/atoms/Label';
import { MultiSelectBox } from 'components/atoms/MultiSelectBox';
import { PullDown } from 'components/atoms/PullDown';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { Store } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { SIZE } from 'style/size';
import { Group } from 'types/Common';

type SelectOption<T> = {
  text: string;
  value: T;
};

/**
 * TODO: onChangeの値をオブジェクトとして保持するように変更
 * 呼び出し側で必要な値を選択できるようにして分割代入をできるように対応
 */
type GroupStoreSelectProps = {
  group: Group;
  storeIds?: Set<number>;
  showClosedStores: boolean;
  showClosedStoreCheckbox?: boolean;
  showGbpConnectedStoresOnly?: boolean;
  showGroupSelectOnly?: boolean;
  showMyStoreOnly?: boolean;
  canSwitchSelectionMode?: boolean;
  sizeVariant?: 'narrow' | 'large';
  onChange: (
    group: Group,
    storeIds: Set<number>,
    isAllStores: boolean,
    showClosedStores: boolean,
    showGroupSelectOnly: boolean,
  ) => void;
  errors?: { group?: string; store?: string } | null;
  storeFilter?: (store: Store) => boolean;
};

export const GroupStoreSelect: React.FC<GroupStoreSelectProps> = ({
  group,
  storeIds = Set(),
  showClosedStores,
  showClosedStoreCheckbox = true,
  showGbpConnectedStoresOnly = false,
  showGroupSelectOnly = false,
  showMyStoreOnly = false,
  canSwitchSelectionMode = false,
  sizeVariant = 'narrow',
  onChange,
  errors,
  storeFilter,
}) => {
  // よく使われるグループ、店舗選択の複雑な処理をこのコンポーネントで完結できるように、
  // ストアの情報を直接参照して利用する
  const { stores, storeLists, currentUser } = useSelector((state) => ({
    stores: state.store.stores,
    storeLists: state.storeList.storeLists,
    currentUser: state.app.currentUser,
  }));
  const [storeSearchQuery, setStoreSearchQuery] = useState<string>('');
  const showMyStore = currentUser.isSvUser || currentUser.isMemberUser;
  const myStores: List<number> = currentUser.isSvUser
    ? currentUser.managing_stores
    : currentUser.isMemberUser
      ? currentUser.stores
      : stores.list.map((store) => store.id);

  const filteredStoreLists = useMemo(() => {
    let filteredStoreLists: StoreLists = storeLists;

    // showGbpConnectedStoresOnlyフラグがtrueのとき、GBP連携済み店舗を含まないグループを表示しない
    if (showGbpConnectedStoresOnly) {
      filteredStoreLists = filteredStoreLists.filterByIncludingConnectedGmbStore(stores);
    }

    // showMyStoreOnlyフラグがtrueのとき、店舗スタッフ・SVは所属/管理店舗以外の店舗を表示しない
    if (showMyStore && showMyStoreOnly) {
      filteredStoreLists = filteredStoreLists.filterByMyStore(myStores);
    }

    if (storeFilter) {
      const filteredStoreIds = stores.filter(storeFilter).list.map((store) => store.id);
      filteredStoreLists = filteredStoreLists.filterByMyStore(filteredStoreIds);
    }

    // 空のグループを除外する
    return filteredStoreLists.excludeEmptyStoreList();
  }, [myStores, showGbpConnectedStoresOnly, showMyStore, showMyStoreOnly, storeLists, stores, storeFilter]);

  const groupOptions = useMemo(() => {
    // 絞り込まれたグループからオプションを生成する
    const groupOptions: SelectOption<Group>[] = [...filteredStoreLists.options];

    // showMyStoreOnlyフラグがtrueの店舗スタッフ・SVでなければ、「全ての店舗」オプションを先頭に追加する
    if (!(showMyStore && showMyStoreOnly)) {
      groupOptions.unshift({ text: '全ての店舗', value: 'all' });
    }

    // 店舗スタッフには「あなたの所属店舗」、SVには「あなたの管理店舗」オプションを先頭に追加する
    if (showMyStore) {
      const myStoreLabel = currentUser.isMemberUser ? 'あなたの所属店舗' : 'あなたの管理店舗';
      groupOptions.unshift({ text: myStoreLabel, value: 'my_store' });
    }

    return groupOptions;
  }, [currentUser, filteredStoreLists, showMyStore, showMyStoreOnly]);

  /** 店舗IDが不正な形式になっていないか
   *  errorsが何も定義されていない場合はデフォルトとしてstoreIdsの値でバリデーションを行う
   *  errorsがnullの場合はバリデーションを行わない
   *  errorsに何らかの値が含まれいてる場合は、渡された値の文字列がセットされる
   */
  const { /* group: groupError, */ store: storeError } = useMemo(() => {
    if (errors !== undefined) {
      if (errors === null) {
        return {};
      }
      return errors;
    }

    if (storeIds.size === 0) {
      return { store: '店舗を指定してください' };
    }

    return {};
  }, [errors, storeIds]);

  // groupの値ごとに選択可能な店舗のstore_idを取得する
  const getAvailableStoreIds = useCallback(
    (group: Group, showClosedStores: boolean, showGbpConnectedStoresOnly: boolean) => {
      let targetFilterStores = stores;
      if (typeof group === 'number') {
        // グループIDの場合
        const storelist = filteredStoreLists.findStoreList(group);
        targetFilterStores = targetFilterStores.filterStoresById(storelist ? storelist.stores.toArray() : []);
      } else if (group === 'my_store') {
        // 所属店舗(店舗ユーザー)、管理店舗(SVユーザー)の場合
        targetFilterStores = targetFilterStores.filterStoresById(myStores.toArray());
      }
      // 閉店店舗を表示しないための絞り込み
      if (!showClosedStores) {
        targetFilterStores = targetFilterStores.filterByUnclosed();
      }
      // GBP連携済みのみを表示するための絞り込み
      if (showGbpConnectedStoresOnly) {
        targetFilterStores = targetFilterStores.filterByIsConnectedGmb();
      }
      // 管理/所属店舗のみ表示するための絞り込み
      if (showMyStore && showMyStoreOnly) {
        targetFilterStores = targetFilterStores.filterStoresById(myStores.toArray());
      }
      if (storeFilter) {
        targetFilterStores = targetFilterStores.filter(storeFilter);
      }
      return targetFilterStores.list.map((target) => target.id).toArray();
    },
    [filteredStoreLists, myStores, showMyStore, showMyStoreOnly, stores, storeFilter],
  );

  // 店舗選択ドロップダウンのオプションを生成する
  const storeOptions = useMemo(() => {
    const storeIds = getAvailableStoreIds(group, showClosedStores, showGbpConnectedStoresOnly);

    return [
      ...storeIds.map((storeId) => {
        const targetStore = stores.findStore(storeId);
        return {
          value: storeId,
          text: targetStore ? targetStore.fullName : '',
        };
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, showClosedStores, showGbpConnectedStoresOnly, getAvailableStoreIds]);

  /**
   * 「グループ」項目の値を変更する
   *
   * @param value 変更するフィルターの値
   */
  const handleChangeGroup = useCallback(
    (value: Group) => {
      const availableStoreIds = Set(getAvailableStoreIds(value, showClosedStores, showGbpConnectedStoresOnly));
      onChange(value, availableStoreIds, true, showClosedStores, showGroupSelectOnly);
    },
    [getAvailableStoreIds, onChange, showClosedStores, showGbpConnectedStoresOnly, showGroupSelectOnly],
  );

  /**
   * 個別選択モードとグループ選択モードの切り替え
   */
  const handleChangeGroupSelectOnly = useCallback(() => {
    const updatedStoreIds = Set(getAvailableStoreIds(group, !showClosedStores, showGbpConnectedStoresOnly));
    const updatedShowGroupStoreSelect = !showGroupSelectOnly;
    const updatedIsAllStores = updatedStoreIds.equals(Set(storeOptions.map((op) => op.value)));
    onChange(group, updatedStoreIds, updatedIsAllStores, showClosedStores, updatedShowGroupStoreSelect);
  }, [
    getAvailableStoreIds,
    group,
    onChange,
    showClosedStores,
    showGbpConnectedStoresOnly,
    showGroupSelectOnly,
    storeOptions,
  ]);

  /**
   * 「店舗」項目で「すべて」を選んだ場合の処理
   *
   * @param value 変更後のチェック状態
   */
  const handleChangeAllStores = useCallback(
    (value: boolean) => {
      if (value !== true) {
        onChange(group, Set(), false, showClosedStores, showGroupSelectOnly);
        return;
      }

      onChange(group, Set(storeOptions.map((target) => target.value)), true, showClosedStores, showGroupSelectOnly);
    },
    [group, onChange, showClosedStores, storeOptions, showGroupSelectOnly],
  );

  /**
   * 「店舗」項目で店舗を選んだ場合の処理
   *
   * @param value 変更対象の店舗のID
   */
  const handleChangeStore = useCallback(
    (storeId: number) => {
      let updatedStoreIds: Set<number>;
      if (storeIds.has(storeId)) {
        // 選択解除の場合
        updatedStoreIds = storeIds.delete(storeId);
      } else {
        // 選択追加の場合
        updatedStoreIds = storeIds.add(storeId);
      }
      const updatedIsAllStores = updatedStoreIds.equals(Set(storeOptions.map((op) => op.value)));
      onChange(group, updatedStoreIds, updatedIsAllStores, showClosedStores, showGroupSelectOnly);
    },
    [group, onChange, showClosedStores, storeIds, storeOptions, showGroupSelectOnly],
  );

  /**
   * 閉店店舗を表示するかを変更する
   *
   * @param value 変更するフィルターの値
   */
  const handleChangeShowClosedStores = useCallback(() => {
    const updatedStoreIds = Set(getAvailableStoreIds(group, !showClosedStores, showGbpConnectedStoresOnly));
    const updatedShowClosedStores = !showClosedStores;
    const updatedIsAllStores = updatedStoreIds.equals(Set(storeOptions.map((op) => op.value)));
    onChange(group, updatedStoreIds, updatedIsAllStores, updatedShowClosedStores, showGroupSelectOnly);
  }, [
    getAvailableStoreIds,
    group,
    onChange,
    showClosedStores,
    showGbpConnectedStoresOnly,
    storeOptions,
    showGroupSelectOnly,
  ]);

  const storeLabel = useMemo(() => {
    if (storeIds.size === 0) {
      return '';
    }
    const availableStoreIds = Set(getAvailableStoreIds(group, showClosedStores, showGbpConnectedStoresOnly));
    if (availableStoreIds.equals(storeIds)) {
      return 'すべて';
    }

    const nameLabel = storeIds
      .toArray()
      .sort()
      .map((storeId) => stores.findStore(storeId)?.fullName || '')
      .join('、');

    return `${storeIds.size}店舗 （${nameLabel}）`;
  }, [storeIds, getAvailableStoreIds, group, showClosedStores, showGbpConnectedStoresOnly, stores]);

  const CustomPullDown = sizeVariant === 'large' ? CustomPullDownLarge : CustomPullDownNarrow;
  return (
    <>
      <ContentWrapper sizeVariant={sizeVariant}>
        <ContentLabel sizeVariant={sizeVariant}>グループ</ContentLabel>
        <CustomPullDown
          value={group}
          options={groupOptions}
          placeholder={'全ての店舗'}
          onChange={(value: any) => handleChangeGroup(value)}
        />
      </ContentWrapper>
      {canSwitchSelectionMode && (
        <StyledCheckBox
          onChange={() => handleChangeGroupSelectOnly()}
          checked={!showGroupSelectOnly}
          label='個別で店舗を選択する'
        />
      )}
      {showGroupSelectOnly === false && (
        <ContentWrapper sizeVariant={sizeVariant}>
          <FlexContentLabel sizeVariant={sizeVariant}>
            <ContentLabel sizeVariant={sizeVariant}>店舗</ContentLabel>
            {showClosedStoreCheckbox && (
              <StoreCheckLabel
                onChange={() => handleChangeShowClosedStores()}
                checked={showClosedStores}
                label='閉業店舗を含める'
                sizeVariant={sizeVariant}
              />
            )}
          </FlexContentLabel>
          <StyledMultiSelectBox value={storeLabel} error={!!storeError} sizeVariant={sizeVariant}>
            <StyledInput placeholder='店舗を検索' value={storeSearchQuery} onChange={(v) => setStoreSearchQuery(v)} />
            <CheckLabel
              checked={storeIds.size === storeOptions.length}
              onChange={() => handleChangeAllStores(!(storeIds.size === storeOptions.length))}
              label='すべて'
            />
            {storeOptions
              .filter((target) => target.text.indexOf(storeSearchQuery) > -1)
              .map((target, idx) => (
                <CheckLabel
                  key={idx}
                  onChange={() => handleChangeStore(target.value)}
                  checked={storeIds.some((id) => id === target.value)}
                  label={target.text}
                />
              ))}
          </StyledMultiSelectBox>
          {storeError && <ErrorLabel pointing>{storeError}</ErrorLabel>}
        </ContentWrapper>
      )}
    </>
  );
};

const ContentWrapper = styled.div<{ sizeVariant: GroupStoreSelectProps['sizeVariant'] }>`
  margin: ${(props) => (props.sizeVariant === 'large' ? '24px 0 0 0' : '8px 27px 8px 0')};
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ContentLabel = styled.div<{ sizeVariant: GroupStoreSelectProps['sizeVariant'] }>`
  ${(props) => (props.sizeVariant === 'large' ? 'font-weight: bold' : 'height:  28px;')};
`;

const FlexContentLabel = styled.div<{ sizeVariant: GroupStoreSelectProps['sizeVariant'] }>`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.sizeVariant === 'large' ? 'flex-start' : 'space-between')};
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #707070;
    margin: 0;
    padding: 8px;
    cursor: pointer;
  }
`;

const CustomPullDownLarge = styled(PullDown)`
  margin-top: 16px;
`;

const CustomPullDownNarrow = styled(PullDownNarrow)`
  width: 176px;
  & .ui.search.selection.dropdown {
    padding: 4px 6px !important;
    min-height: 33px;
    display: flex;
    align-items: center;
  }
  & .ui.search.selection.dropdown > .search {
    min-height: 33px;
    padding: 4px 6px !important;
  }

  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  margin-top: 24px;
`;

const StyledMultiSelectBox = styled(MultiSelectBox)<{ sizeVariant: GroupStoreSelectProps['sizeVariant'] }>`
  ${(props) =>
    props.sizeVariant === 'large'
      ? `  &&& {
    width: 100%;
    min-height: 60px;
    margin-top: 16px;
  }`
      : `  width: 380px;
  @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
    width: 100%;
  }`}
`;

const StyledInput = styled(Input)`
  &&& {
    display: inline-block;
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: calc(100% - 32px);
      margin-bottom: 8px;
    }
    & > div.ui > input {
      padding: 4px 6px;
    }
  }
`;

const StoreCheckLabel = styled(Checkbox)<{ sizeVariant: GroupStoreSelectProps['sizeVariant'] }>`
  &&& {
    font-size: 12px;
    color: #707070;
    margin-left: 16px;
    cursor: pointer;
    margin-top: ${(props) => (props.sizeVariant === 'large' ? '0' : '4px')};
  }
`;
