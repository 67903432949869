import html2canvas from 'html2canvas';

/**
 * コンポーネントをjpg形式でダウンロードする
 * 参考: https://github.com/im-salman/react-component-export-image/blob/master/index.js
 *
 * @param element ダウンロードしたいコンポーネント
 * @param filename ファイル名
 */
export const downloadComponent = (element: HTMLElement | null, filename: string) => {
  if (!element) {
    return;
  }

  html2canvas(element).then((canvas) => {
    const link = document.createElement('a');
    const uri = canvas.toDataURL('image/jpeg');

    if (typeof link.download === 'string') {
      link.href = canvas.toDataURL('image/jpeg');
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  });
};
