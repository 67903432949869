import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

import { Pagination } from '../Pagination';

import { Inventory } from './Inventory';

// NOTE: 現状は在庫一覧画面で一部のデータの表示のみにしか使われないので、GMCの商品データほぼそのままで定義している

interface Price {
  value: string | null;
  currency: string | null;
}

interface ProductDimension {
  value: number | null;
  unit: 'in' | 'cm' | null;
}

interface ProductWeight {
  value: number | null;
  unit: 'g' | 'kg' | 'oz' | 'lb' | null;
}

interface ProductShipping {
  price: Price | null;
  country: string | null;
  region: string | null;
  service: string | null;
  locationId: string | null;
  locationGroupName: string | null;
  postalCode: string | null;
  minHandlingTime: string | null;
  maxHandlingTime: string | null;
  minTransitTime: string | null;
  maxTransitTime: string | null;
}

interface ProductShippingWeight {
  value: number | null;
  unit: string | null;
}

interface ProductTax {
  rate: number | null;
  country: string | null;
  region: string | null;
  taxShip: boolean | null;
  locationId: string | null;
  postalCode: string | null;
}

interface Installment {
  months: string | null;
  amount: Price | null;
}

interface LoyaltyPoints {
  name: string | null;
  pointsValue: string | null;
  ratio: number | null;
}

interface ProductUnitPricingMeasure {
  value: number | null;
  unit: string | null;
}

interface ProductUnitPricingBaseMeasure {
  value: string | null;
  unit: string | null;
}

interface ProductShippingDimension {
  value: number | null;
  unit: string | null;
}

interface ProductDetail {
  sectionName: string | null;
  attributeName: string | null;
  attributeValue: string | null;
}

interface ProductSubscriptionCost {
  period: string | null;
  periodLength: string | null;
  amount: Price | null;
}

interface CustomAttribute {
  name: string | null;
  value: string | null;
  groupValues: CustomAttribute[] | null;
}

export interface GMCProductRecord {
  id: string;
  offerId: string;
  title: string | null;
  description: string | null;
  link: string | null;
  imageLink: string | null;
  additionalImageLinks: string[] | null;
  contentLanguage: string;
  targetCountry: string;
  channel: 'local' | 'online';
  expirationDate: Dayjs | null;
  adult: boolean | null;
  kind: string | null;
  brand: string | null;
  color: string | null;
  googleProductCategory: string | null;
  gtin: string | null;
  itemGroupId: string | null;
  material: string | null;
  mpn: string | null;
  pattern: string | null;
  price: Price | null;
  salePrice: Price | null;
  salePriceEffectiveDate: string | null;
  productHeight: ProductDimension | null;
  productLength: ProductDimension | null;
  productWidth: ProductDimension | null;
  productWeight: ProductWeight | null;
  shipping: ProductShipping[] | null;
  shippingWeight: ProductShippingWeight | null;
  sizes: string[] | null;
  taxes: ProductTax[] | null;
  customAttributes: CustomAttribute[] | null;
  identifierExists: boolean | null;
  installment: Installment | null;
  loyaltyPoints: LoyaltyPoints | null;
  multipack: string | null;
  customLabel0: string | null;
  customLabel1: string | null;
  customLabel2: string | null;
  customLabel3: string | null;
  customLabel4: string | null;
  isBundle: boolean | null;
  mobileLink: string | null;
  availabilityDate: string | null;
  shippingLabel: string | null;
  unitPricingMeasure: ProductUnitPricingMeasure | null;
  unitPricingBaseMeasure: ProductUnitPricingBaseMeasure | null;
  shippingLength: ProductShippingDimension | null;
  shippingWidth: ProductShippingDimension | null;
  shippingHeight: ProductShippingDimension | null;
  displayAdsId: string | null;
  displayAdsSimilarIds: string | null;
  displayAdsTitle: string | null;
  displayAdsLink: string | null;
  displayAdsValue: string | null;
  sellOnGoogleQuantity: string | null;
  promotionIds: string[] | null;
  maxHandlingTime: string | null;
  minHandlingTime: string | null;
  costOfGoodsSold: Price | null;
  source: 'api' | 'crawl' | 'feed' | null;
  includedDestinations: string[] | null;
  excludedDestinations: string[] | null;
  adsGrouping: string | null;
  adsLabels: string[] | null;
  adsRedirect: string | null;
  productTypes: string[] | null;
  ageGroup: string | null;
  availability: string | null;
  condition: string | null;
  gender: string | null;
  sizeSystem: string | null;
  sizeType: string | null;
  additionalSizeType: string | null;
  energyEfficiencyClass: string | null;
  minEnergyEfficiencyClass: string | null;
  maxEnergyEfficiencyClass: string | null;
  taxCategory: string | null;
  transitTimeLabel: string | null;
  shoppingAdsExcludedCountries: string[] | null;
  pickupMethod: 'buy' | 'reserve' | 'ship to store' | null;
  pickupSla: 'same day' | 'next day' | '2-day' | '3-day' | '4-day' | '5-day' | '6-day' | '7-day' | 'multi-week' | null;
  linkTemplate: string | null;
  mobileLinkTemplate: string | null;
  productDetails: ProductDetail[] | null;
  productHighlights: string[] | null;
  subscriptionCost: ProductSubscriptionCost | null;
  canonicalLink: string | null;
  externalSellerId: string | null;
}

export class GMCProduct extends Record<GMCProductRecord>({
  id: '',
  offerId: '',
  title: null,
  description: null,
  link: null,
  imageLink: null,
  additionalImageLinks: null,
  contentLanguage: '',
  targetCountry: '',
  channel: 'local',
  expirationDate: null,
  adult: null,
  kind: null,
  brand: null,
  color: null,
  googleProductCategory: null,
  gtin: null,
  itemGroupId: null,
  material: null,
  mpn: null,
  pattern: null,
  price: null,
  salePrice: null,
  salePriceEffectiveDate: null,
  productHeight: null,
  productLength: null,
  productWidth: null,
  productWeight: null,
  shipping: null,
  shippingWeight: null,
  sizes: null,
  taxes: null,
  customAttributes: null,
  identifierExists: null,
  installment: null,
  loyaltyPoints: null,
  multipack: null,
  customLabel0: null,
  customLabel1: null,
  customLabel2: null,
  customLabel3: null,
  customLabel4: null,
  isBundle: null,
  mobileLink: null,
  availabilityDate: null,
  shippingLabel: null,
  unitPricingMeasure: null,
  unitPricingBaseMeasure: null,
  shippingLength: null,
  shippingWidth: null,
  shippingHeight: null,
  displayAdsId: null,
  displayAdsSimilarIds: null,
  displayAdsTitle: null,
  displayAdsLink: null,
  displayAdsValue: null,
  sellOnGoogleQuantity: null,
  promotionIds: null,
  maxHandlingTime: null,
  minHandlingTime: null,
  costOfGoodsSold: null,
  source: null,
  includedDestinations: null,
  excludedDestinations: null,
  adsGrouping: null,
  adsLabels: null,
  adsRedirect: null,
  productTypes: null,
  ageGroup: null,
  availability: null,
  condition: null,
  gender: null,
  sizeSystem: null,
  sizeType: null,
  additionalSizeType: null,
  energyEfficiencyClass: null,
  minEnergyEfficiencyClass: null,
  maxEnergyEfficiencyClass: null,
  taxCategory: null,
  transitTimeLabel: null,
  shoppingAdsExcludedCountries: null,
  pickupMethod: null,
  pickupSla: null,
  linkTemplate: null,
  mobileLinkTemplate: null,
  productDetails: null,
  productHighlights: null,
  subscriptionCost: null,
  canonicalLink: null,
  externalSellerId: null,
}) {
  static fromJSON(data: JSObject) {
    const product = data;
    return new GMCProduct({
      id: product.id,
      offerId: product.offerId,
      title: product.title ?? null,
      description: product.description ?? null,
      link: product.link ?? null,
      imageLink: product.imageLink ?? null,
      additionalImageLinks: product.additionalImageLinks ?? null,
      contentLanguage: product.contentLanguage,
      targetCountry: product.targetCountry,
      channel: product.channel,
      expirationDate: product.expirationDate ?? null,
      adult: product.adult ?? null,
      kind: product.kind ?? null,
      brand: product.brand ?? null,
      color: product.color ?? null,
      googleProductCategory: product.googleProductCategory ?? null,
      gtin: product.gtin ?? null,
      itemGroupId: product.itemGroupId ?? null,
      material: product.material ?? null,
      mpn: product.mpn ?? null,
      pattern: product.pattern ?? null,
      price: product.price ?? null,
      salePrice: product.salePrice ?? null,
      salePriceEffectiveDate: product.salePriceEffectiveDate ?? null,
      productHeight: product.productHeight ?? null,
      productLength: product.productLength ?? null,
      productWidth: product.productWidth ?? null,
      productWeight: product.productWeight ?? null,
      shipping: product.shipping ?? null,
      shippingWeight: product.shippingWeight ?? null,
      sizes: product.sizes ?? null,
      taxes: product.taxes ?? null,
      identifierExists: product.identifierExists ?? null,
      installment: product.installment ?? null,
      loyaltyPoints: product.loyaltyPoints ?? null,
      multipack: product.multipack ?? null,
      customLabel0: product.customLabel0 ?? null,
      customLabel1: product.customLabel1 ?? null,
      customLabel2: product.customLabel2 ?? null,
      customLabel3: product.customLabel3 ?? null,
      customLabel4: product.customLabel4 ?? null,
      isBundle: product.isBundle ?? null,
      mobileLink: product.mobileLink ?? null,
      availabilityDate: product.availabilityDate ?? null,
      shippingLabel: product.shippingLabel ?? null,
      unitPricingMeasure: product.unitPricingMeasure ?? null,
      unitPricingBaseMeasure: product.unitPricingBaseMeasure ?? null,
      shippingLength: product.shippingLength ?? null,
      shippingWidth: product.shippingWidth ?? null,
      shippingHeight: product.shippingHeight ?? null,
      displayAdsId: product.displayAdsId ?? null,
      displayAdsSimilarIds: product.displayAdsSimilarIds ?? null,
      displayAdsTitle: product.displayAdsTitle ?? null,
      displayAdsLink: product.displayAdsLink ?? null,
      displayAdsValue: product.displayAdsValue ?? null,
      sellOnGoogleQuantity: product.sellOnGoogleQuantity ?? null,
      promotionIds: product.promotionIds ?? null,
      maxHandlingTime: product.maxHandlingTime ?? null,
      minHandlingTime: product.minHandlingTime ?? null,
      costOfGoodsSold: product.costOfGoodsSold ?? null,
      source: product.source ?? null,
      includedDestinations: product.includedDestinations ?? null,
      excludedDestinations: product.excludedDestinations ?? null,
      adsGrouping: product.adsGrouping ?? null,
      adsLabels: product.adsLabel ?? null,
      adsRedirect: product.adsRedirect ?? null,
      productTypes: product.productTypes ?? null,
      ageGroup: product.ageGroup ?? null,
      availability: product.availability ?? null,
      condition: product.condition ?? null,
      gender: product.gender ?? null,
      sizeSystem: product.sizeSystem ?? null,
      additionalSizeType: product.additionalSizeType ?? null,
      energyEfficiencyClass: product.energyEfficiencyClass ?? null,
      minEnergyEfficiencyClass: product.minEnergyEfficiencyClass ?? null,
      maxEnergyEfficiencyClass: product.maxEnergyEfficiencyClass ?? null,
      taxCategory: product.taxCategory ?? null,
      transitTimeLabel: product.transitTimeLabel ?? null,
      shoppingAdsExcludedCountries: product.shoppingAdsExcludedCountries ?? null,
      pickupMethod: product.pickupMethod ?? null,
      pickupSla: product.pickupSla ?? null,
      linkTemplate: product.linkTemplate ?? null,
      mobileLinkTemplate: product.mobileLinkTemplate ?? null,
      productDetails: product.productDetails ?? null,
      productHighlights: product.productHighlights ?? null,
      subscriptionCost: product.subscriptionCost ?? null,
      canonicalLink: product.canonicalLink ?? null,
      externalSellerId: product.externalSellerId ?? null,
    });
  }
}

export interface ProductRecord {
  offerId: string;
  product: GMCProduct | null;
  localProduct: GMCProduct | null;
  localInventories: List<Inventory>;
  productUpdateAt: Dayjs | null;
  search: string;
}

export class Product extends Record<ProductRecord>({
  offerId: '',
  product: new GMCProduct(),
  localProduct: null,
  localInventories: List(),
  productUpdateAt: null,
  search: '',
}) {
  static fromJSON(data: JSObject = {}, merchantId = '', storeId: number) {
    // merchantIdはこのJSON内に含まれていないので外から渡す
    const offerId = data.offer_id;
    let localInventories = List<Inventory>(
      data.local_inventories.map((d: JSObject) => Inventory.fromJSON({ ...d, merchant_id: merchantId })),
    );
    // 店舗の在庫情報がなければ新規で追加する
    if (!localInventories.find((inventory) => inventory.storeId === storeId)) {
      localInventories = localInventories.push(new Inventory({ merchantId, offerId, storeId }));
    }

    return new Product({
      offerId,
      product: data.product ? GMCProduct.fromJSON(data.product) : null,
      localProduct: data.local_product ? GMCProduct.fromJSON(data.local_product) : null,
      localInventories,
      productUpdateAt: data.product_update_at ? dayjs(data.product_update_at) : null,
      search: data.search,
    });
  }

  // オンライン商品が存在しない場合はローカル商品を返す
  get onlineOrLocalProduct(): GMCProduct | null {
    return this.product ?? this.localProduct;
  }

  getInventoryByStoreId(storeId: number): Inventory {
    // storeIdが一致する在庫情報があればそれを返す、なければ空の情報を返す
    return this.localInventories.find((inventory) => inventory.storeId === storeId) || new Inventory({ storeId });
  }

  changeInventory(storeId: number, inventory: Inventory) {
    let newLocalInventories = this.localInventories;
    // storeIdが一致する在庫情報があれば更新する、なければ追加する
    const index = this.localInventories.findIndex((inventory) => inventory.storeId === storeId);
    newLocalInventories = index >= 0 ? newLocalInventories.set(index, inventory) : newLocalInventories.push(inventory);
    return this.set('localInventories', newLocalInventories);
  }
}

export interface ProductGroupRecord {
  key: string;
  merchantId: string;
  type: 'item' | 'group';
  items: List<Product>;
}

export class ProductGroup extends Record<ProductGroupRecord>({
  key: '',
  merchantId: '',
  type: 'item',
  items: List(),
}) {
  static fromJSON(data: JSObject = {}, storeId: number) {
    const merchantId = data.merchant_id;
    return new ProductGroup({
      key: data.key,
      merchantId: merchantId,
      type: data.items.length > 1 ? 'group' : 'item', // data.typeが"group"でも、商品数が1つのみならitemとする
      items: List(data.items.map((item: JSObject) => Product.fromJSON(item, merchantId, storeId))),
    });
  }

  get title(): string | null {
    // １つ目の商品のタイトルを商品グループのタイトルとする
    const firstItem = this.items.first(null);
    return firstItem?.onlineOrLocalProduct?.title ?? null;
  }

  get imageLink(): string | null {
    // １つ目の商品の画像を商品グループの画像とする
    const firstItem = this.items.first(null);
    return firstItem?.onlineOrLocalProduct?.imageLink ?? null;
  }
}

export class ProductGroups extends Record<{
  items: List<ProductGroup>;
  pagination: Pagination;
}>({
  items: List(),
  pagination: new Pagination(),
}) {
  static fromJSON(data: JSObject = {}, storeId: number) {
    return new ProductGroups({
      items: List(data.products.map((item: JSObject) => ProductGroup.fromJSON(item, storeId))),
      pagination: Pagination.fromJSON(data.pagination),
    });
  }

  getProduct(groupIndex: number, productIndex: number): Product {
    return this.getIn(['items', groupIndex, 'items', productIndex]);
  }

  changeInventory(groupIndex: number, productIndex: number, storeId: number, inventory: Inventory) {
    return this.updateIn(['items', groupIndex, 'items', productIndex], (product: Product) =>
      product.changeInventory(storeId, inventory),
    );
  }
}
