import { toast } from 'react-semantic-toasts';
import { put, select, takeLatest } from 'redux-saga/effects';

import { CompetitorApi } from 'ApiClient/CompetitorApi';
import {
  CategoryApi,
  GbpAvailableUrlTypesApi,
  GmbAccountApi,
  GmbAttributeListApi,
  GmbLocationLatlngApi,
  GmbLocationValidateApi,
} from 'ApiClient/GmbApi';
import { StoreApi } from 'ApiClient/StoreApi';
import { GUIDE_LINKS } from 'helpers/utils';
import { Competitors } from 'models/Domain/Competitor/Competitor';
import { GbpAvailableUrlTypes } from 'models/Domain/GbpAvailableUrlTypes';
import { GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbLocationCategory } from 'models/Domain/GmbLocation/GmbLocationCategories';
import { Store } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { StoreActions } from 'modules/store/actions';
import { Path } from 'routes';
import { JSObject } from 'types/Common';

import { StoreDetailActions } from './actions';

export default function* saga() {
  yield takeLatest(StoreDetailActions.getStore, getStore);
  yield takeLatest(StoreDetailActions.updateStoreOpenInfo, updateStoreOpenInfo);
  yield takeLatest(StoreDetailActions.updateStoreOpeningDate, updateStoreOpeningDate);
  yield takeLatest(StoreDetailActions.updateStoreCode, updateStoreCode);
  yield takeLatest(StoreDetailActions.updatePhone, updatePhone);
  yield takeLatest(StoreDetailActions.updateStoreNameBranch, updateStoreNameBranch);
  yield takeLatest(StoreDetailActions.updateStoreWebsiteUrl, updateStoreWebsiteUrl);
  yield takeLatest(StoreDetailActions.updateStoreRegularHours, updateStoreRegularHours);
  yield takeLatest(StoreDetailActions.updateStoreSpecialHours, updateStoreSpecialHours);
  yield takeLatest(StoreDetailActions.updateMoreHours, updateMoreHours);
  yield takeLatest(StoreDetailActions.updateStoreAddress, updateStoreAddress);
  yield takeLatest(StoreDetailActions.updateStoreGmbCategories, updateStoreGmbCategories);
  yield takeLatest(StoreDetailActions.updateStoreGmbProfile, updateStoreGmbProfile);
  yield takeLatest(StoreDetailActions.updateStoreAttributes, updateStoreAttributes);
  yield takeLatest(StoreDetailActions.removeGmbConnect, removeGmbConnect);
  yield takeLatest(StoreDetailActions.deleteStore, deleteStore);
  yield takeLatest(StoreDetailActions.importGmbMapLatlng, importGmbMapLatlng);
}

function* getStore(action: ReturnType<typeof StoreDetailActions.getStore>) {
  // 店舗情報と属性情報のメタデータは連携する必要があるので、両方を取得する

  // 店舗情報の取得
  const storeId = action.payload;
  const storeResponse: JSObject = yield StoreApi.get(storeId);
  if (!storeResponse.isSuccess) {
    toast({
      type: 'error',
      title: '店舗の取得に失敗しました',
      description: String(storeResponse.error.message),
      time: 10000,
    });
    return;
  }

  let store = new Store(storeResponse.data);

  // 属性メタ情報の取得
  const categoryId = store.location.primaryCategory.categoryId;
  const attributesResponse: JSObject = yield GmbAttributeListApi.get(categoryId);

  if (!attributesResponse.isSuccess) {
    toast({
      type: 'error',
      title: '属性情報の取得に失敗しました',
      description: String(attributesResponse.error.message),
      time: 10000,
    });
    return;
  }
  const attributeMetadatas = new GmbAttributeMetadatas(attributesResponse.data);

  // カテゴリ情報の更新
  if (categoryId) {
    const categoryResponse: YieldReturn<typeof CategoryApi.get> = yield CategoryApi.get(categoryId);
    if (!categoryResponse.isSuccess) {
      toast({
        type: 'error',
        title: 'カテゴリ情報の取得に失敗しました',
        description: String(attributesResponse.error.message),
        time: 10000,
      });
      return;
    }

    // メインカテゴリの情報をAPIから取得した内容で更新する
    const primaryCategory = new GmbLocationCategory(categoryResponse.data);
    store = store.updatePrimaryCategory(primaryCategory);
  }

  // 競合店舗情報の取得
  const currentUser: User = yield select((state: State) => state.app.currentUser);
  if (currentUser.organization?.canUseCompetitor() && !currentUser.isMemberUser) {
    const response: YieldReturn<typeof CompetitorApi.getCompetitors> = yield CompetitorApi.getCompetitors({
      store_id: `${storeId}`,
      limit: 5,
    });
    let competitors: Competitors;
    if (response.isSuccess) {
      competitors = Competitors.fromJSON(response.data);
    } else {
      // 競合店舗情報の取得ができなくても店舗情報の更新には影響ないのでなしで進める
      toast({
        type: 'error',
        title: '競合店舗の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
      competitors = new Competitors();
    }

    yield put(StoreDetailActions.setCompetitors(competitors));
  }

  // 利用可能なリンク種別情報の取得
  const availableUrlTypesResponse: YieldReturn<typeof GbpAvailableUrlTypesApi.get> = yield GbpAvailableUrlTypesApi.get({
    store_ids: storeId,
  });

  if (!availableUrlTypesResponse.isSuccess) {
    toast({
      type: 'error',
      title: '利用可能なリンク種別情報の取得に失敗しました',
      description: String(availableUrlTypesResponse.error.message),
      time: 10000,
    });
    return;
  }
  if (availableUrlTypesResponse.data.items.length > 0) {
    const availableUrlTypes = GbpAvailableUrlTypes.fromJSON(availableUrlTypesResponse.data.items[0]);
    yield put(StoreDetailActions.setAvailableUrlTypes(availableUrlTypes));
  }

  yield put(StoreDetailActions.setStoreForDetail(store));
  yield put(StoreDetailActions.setStoreForEdit(store));
  yield put(StoreDetailActions.setAttributeMetadatas(attributeMetadatas));
}

function* updateStoreOpenInfo(action: ReturnType<typeof StoreDetailActions.updateStoreOpenInfo>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const updateParams = storeForEdit.location.updateOpenInfoParams();
  const validateParams = storeForEdit.validateOpenInfoParams();
  yield updateSingleParams('営業ステータス', storeForEdit, StoreApi.patchGmbOpenInfo, updateParams, validateParams);
  yield put(AppActions.setLoading(false));
}

function* updateStoreOpeningDate(action: ReturnType<typeof StoreDetailActions.updateStoreOpeningDate>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updateOpeningDateParams();
  yield updateSingleParams('開業日', storeForEdit, StoreApi.patchGmbOpeningDate, params);
  yield put(AppActions.setLoading(false));
}

function* updateStoreGmbProfile(action: ReturnType<typeof StoreDetailActions.updateStoreGmbProfile>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updateProfileParams();
  yield updateSingleParams('ビジネス情報', storeForEdit, StoreApi.patchProfile, params);
  yield put(AppActions.setLoading(false));
}

function* updateStoreCode(action: ReturnType<typeof StoreDetailActions.updateStoreCode>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const updateParams = storeForEdit.updateCodeParams();
  const validateParams = storeForEdit.validateStoreCodeParams();
  yield updateSingleParams('店舗コード', storeForEdit, StoreApi.patchCode, updateParams, validateParams);
  yield put(AppActions.setLoading(false));
}

function* updatePhone(action: ReturnType<typeof StoreDetailActions.updatePhone>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updatePhoneParams();
  yield updateSingleParams('電話番号', storeForEdit, StoreApi.patchPhone, params);
  yield put(AppActions.setLoading(false));
}

function* updateStoreNameBranch(action: ReturnType<typeof StoreDetailActions.updateStoreNameBranch>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const updateParams = storeForEdit.updateNameBranchParams();
  const validateParams = storeForEdit.validateNameBranchParams();
  yield updateSingleParams('店舗名', storeForEdit, StoreApi.patchNameBranch, updateParams, validateParams);
  yield put(AppActions.setLoading(false));
}

function* updateStoreWebsiteUrl(action: ReturnType<typeof StoreDetailActions.updateStoreWebsiteUrl>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updateWebsiteUrlParams();
  yield updateSingleParams('ウェブサイト', storeForEdit, StoreApi.patchWebsiteUrl, params);
  yield put(AppActions.setLoading(false));
}

function* updateStoreRegularHours(action: ReturnType<typeof StoreDetailActions.updateStoreRegularHours>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updateRegularHoursParams();
  yield updateSingleParams('営業時間', storeForEdit, StoreApi.patchRegularHours, params);
  yield put(AppActions.setLoading(false));
}

function* updateStoreAddress(action: ReturnType<typeof StoreDetailActions.updateStoreAddress>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updateAddressParams();
  const { requireManualSetting } = yield updateSingleParams('所在地', storeForEdit, StoreApi.patchAddress, params);
  yield put(StoreDetailActions.setRequireManualSettingItem({ item: 'address', requireManualSetting }));
  yield put(StoreDetailActions.setIsSuccessAddressValidation(!requireManualSetting));
  yield put(AppActions.setLoading(false));
}

function* updateStoreSpecialHours(_: ReturnType<typeof StoreDetailActions.updateStoreSpecialHours>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  // 過去の日付のデータが含まれていたら除外する
  const params = storeForEdit.location.removePastSpecialHours().updateSpecialHoursParams();
  yield updateSingleParams('特別営業時間', storeForEdit, StoreApi.patchSpecialHours, params);
  yield put(AppActions.setLoading(false));
}

function* updateMoreHours(_: ReturnType<typeof StoreDetailActions.updateMoreHours>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updateMoreHoursParams();
  yield updateSingleParams('その他の営業時間', storeForEdit, StoreApi.patchMoreHours, params);
  yield put(AppActions.setLoading(false));
}

function* updateStoreGmbCategories(_: ReturnType<typeof StoreDetailActions.updateStoreSpecialHours>) {
  yield put(AppActions.setLoading(true));
  let storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const primaryCategoryId = storeForEdit.location.primaryCategory.categoryId;
  // 更新前にメインカテゴリの情報を選択されたcategoryIdのカテゴリ情報をAPIから取得して上書きする
  if (primaryCategoryId) {
    const categoryResponse: YieldReturn<typeof CategoryApi.get> = yield CategoryApi.get(primaryCategoryId);
    if (categoryResponse.isSuccess) {
      // メインカテゴリの情報をAPIから取得した内容で更新する
      const primaryCategory = new GmbLocationCategory(categoryResponse.data);
      storeForEdit = storeForEdit.updatePrimaryCategory(primaryCategory);
    }
  }

  const params = storeForEdit.location.updateCategoriesParams();
  yield updateSingleParams('カテゴリー', storeForEdit, StoreApi.patchGmbCategories, params);
  yield put(AppActions.setLoading(false));
}

function* updateStoreAttributes(_: ReturnType<typeof StoreDetailActions.updateStoreAttributes>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const params = storeForEdit.location.updateAttributesParams();
  yield updateSingleParams('属性', storeForEdit, StoreApi.patchAttributes, params, undefined, false);
  yield put(AppActions.setLoading(false));
}

/**
 * 店舗の緯度経度情報をGBPの緯度経度情報で上書きする
 * @param _
 */
function* importGmbMapLatlng(_: ReturnType<typeof StoreDetailActions.importGmbMapLatlng>) {
  yield put(AppActions.setLoading(true));
  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const response: JSObject = yield GmbLocationLatlngApi.get({ store_id: storeForEdit.id });
  if (response.isSuccess) {
    yield put(StoreDetailActions.getStore(storeForEdit.id));
    yield put(StoreActions.getStores());
    toast({
      type: 'success',
      title: `緯度経度を取り込みました`,
    });
  } else {
    toast({
      type: 'error',
      title: `緯度経度の取り込みに失敗しました`,
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(AppActions.setLoading(false));
}

/**
 * 店舗情報を更新する
 * @param label 更新対象の名前（メッセージ表示時に利用）
 * @param store 更新対象の店舗情報
 * @param api 更新に利用するAPIメソッド
 * @param updateParams 更新パラメータ
 * @param validateParams バリデーションに利用するパラメータ（更新パラメータと同じ場合は不要）
 * @param isRequiredValidation バリデーションが必要か
 * @returns
 */
export function* updateSingleParams(
  label: string,
  store: Store,
  api: (storeId: number, params: any) => Promise<any>,
  updateParams: JSObject,
  validateParams?: JSObject,
  isRequiredValidation = true,
) {
  // バリデーション結果で手動反映が必要かどうか
  let requireManualSetting = false;
  if (store.is_connected_gmb && isRequiredValidation) {
    const validateResponse: YieldReturn<typeof validateSingleParams> = yield validateSingleParams(
      store.id,
      validateParams ?? updateParams,
    );

    // バリデーションAPIがエラーになった場合
    if (!validateResponse.isSuccess) {
      toast({
        type: 'error',
        title: `${label}の更新に失敗しました`,
        description: validateResponse.error.message,
        time: 10000,
      });
      return { isSuccess: false };
    }

    // バリデーションNGかつ、修正可能判定の場合エラーを表示する
    // (バリデーションNGでも、修正不可能な場合はSTORECASTとしては保存するためスキップ)
    if (!validateResponse.isValid && validateResponse.isCorrectable) {
      toast({
        type: 'error',
        title: `${label}の更新に失敗しました`,
        description: String(validateResponse.error.message),
        time: 10000,
      });
      return { isSuccess: false };
    }

    requireManualSetting = !!validateResponse.requireManualSetting;
  }

  // ロケーション情報の保存
  const response: JSObject = yield api(store.id, updateParams);
  if (response.isSuccess) {
    yield put(StoreDetailActions.getStore(store.id));
    yield put(StoreActions.getStores());
    yield put(AppActions.getGmbLocationDiffs()); // サイドバーのGBPとの差分のバッジを更新する

    if (requireManualSetting) {
      // 保存成功、ただしバリデーション結果は「手動反映が必要」だった場合、その旨をメッセージで表示する
      // クリックした場合、説明のページなどにリンクする
      toast(
        {
          type: 'warning',
          title: `${label}を保存しましたが、Googleビジネスプロフィールに手動での反映が必要です`,
          description:
            `入力された${label}はGoogleビジネスプロフィールに自動反映できない内容のため、手動で反映する必要があります。\n` +
            '手動で反映する方法については、こちらをクリックしてSTORECAST利用ガイドを確認してください。',
          time: 30000,
        },
        undefined,
        () => {
          window.open(GUIDE_LINKS.gmbPatchFailed);
        },
      );
    } else {
      // 保存成功
      toast({
        type: 'success',
        title: `${label}を更新しました`,
      });
    }
    return { isSuccess: true, requireManualSetting };
  } else {
    // 保存失敗
    toast({
      type: 'error',
      title: `${label}の更新に失敗しました`,
      description: String(response.error.message),
      time: 10000,
    });
    return { isSuccess: false };
  }
}

export async function validateSingleParams(storeId: number, params: JSObject) {
  const response = await GmbLocationValidateApi.post({
    store_id: storeId,
    validate_params: params,
  });

  if (response.isSuccess) {
    const validateResult = response.data;
    if (validateResult.validate_status === 'success') {
      return {
        isSuccess: true as const,
        isValid: true as const,
      };
    }

    const errorSummaries = validateResult.error.summaries;

    // 手動設定が必要かどうか(エラー全てがrequire_manual_setting: trueの場合)
    const requireManualSetting = errorSummaries.every((summary) => summary.require_manual_setting === true);

    // 修正可能なエラーが含まれているか(修正可能な場合は、修正して再度更新してもらいたい)
    // isCorrectable === falseの場合、保存する。
    const isCorrectable = errorSummaries.find((summary) => summary.require_manual_setting === false) !== undefined;

    return {
      isSuccess: true as const,
      isValid: false as const,
      isCorrectable,
      requireManualSetting,
      error: validateResult.error,
    };
  } else {
    return {
      isSuccess: false as const,
      error: {
        message: String(response.error.message),
      },
    };
  }
}

function* removeGmbConnect(_: ReturnType<typeof StoreDetailActions.removeGmbConnect>) {
  yield put(AppActions.setLoading(true));

  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const storeId = storeForEdit.id;
  const response: JSObject = yield GmbAccountApi.deleteStoreConnect(storeId);

  if (response.isSuccess) {
    yield put(StoreDetailActions.getStore(storeId));
    yield put(StoreActions.getStores());
    toast({
      type: 'success',
      title: 'Googleビジネスプロフィールの連携を解除しました',
    });
  } else {
    toast({
      type: 'error',
      title: 'Googleビジネスプロフィールの連携解除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

function* deleteStore(_: ReturnType<typeof StoreDetailActions.deleteStore>) {
  yield put(AppActions.setLoading(true));

  const storeForEdit: Store = yield select((state: State) => state.storeDetail.storeForEdit);
  const storeId = storeForEdit.id;
  const response: JSObject = yield StoreApi.delete(storeId);

  if (response.isSuccess) {
    yield put(StoreActions.getStores());
    yield put(AppActions.moveTo(Path.store.index));
    toast({
      type: 'success',
      title: '店舗情報を削除しました',
    });
  } else {
    toast({
      type: 'error',
      title: '店舗情報の削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}
