import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { Pagination } from 'models/Domain/Pagination';
import { JSObject } from 'types/Common';

import { AggregateUnit } from './MapSearchRankSearchCondition';

export class MapSearchRankTableItem extends Record<{
  configId: number;
  storeId: number;
  storeName: string;
  areaName: string;
  searchWord: string;
  mapUrl: string;
  rank: number | null;
  rankComparison: number | null;
  diff: number | null;
  diffComparison: number | null;
  latestRank: number | null;
}>({
  configId: 0,
  storeId: 0,
  storeName: '',
  areaName: '',
  searchWord: '',
  mapUrl: '',
  rank: null,
  rankComparison: null,
  diff: null,
  diffComparison: null,
  latestRank: null,
}) {
  static fromJSON(data: JSObject): MapSearchRankTableItem {
    return new MapSearchRankTableItem({
      configId: data.config_id,
      storeId: data.store_id,
      storeName: data.store_name,
      areaName: data.area_name,
      searchWord: data.search_word,
      mapUrl: data.map_url,
      rank: data.rank,
      rankComparison: data.rank_comparison ?? null,
      diff: data.diff,
      diffComparison: data.diff_comparison ?? null,
      latestRank: data.latest_rank,
    });
  }

  get key() {
    // 店舗とエリアと検索ワードの組み合わせ
    return `${this.storeName}-${this.areaName}-${this.searchWord}`;
  }
}

export class MapSearchRankTableData extends Record<{
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  average: MapSearchRankTableItem;
  items: List<MapSearchRankTableItem>;
  pagination: Pagination;
}>({
  aggregateUnit: 'month',
  startDate: dayjs(),
  endDate: dayjs(),
  comparisonStartDate: null,
  comparisonEndDate: null,
  average: new MapSearchRankTableItem(),
  items: List(),
  pagination: new Pagination(),
}) {
  static fromJSON(data: JSObject): MapSearchRankTableData {
    return new MapSearchRankTableData({
      aggregateUnit: data.aggregateUnit,
      startDate: dayjs(data.period.start_date),
      endDate: dayjs(data.period.end_date),
      comparisonStartDate: data.comparison_period ? dayjs(data.comparison_period.start_date) : null,
      comparisonEndDate: data.comparison_period ? dayjs(data.comparison_period.end_date) : null,
      average: MapSearchRankTableItem.fromJSON(data.average).set('storeName', '平均'),
      items: List(data.items.map((item: JSObject) => MapSearchRankTableItem.fromJSON(item))),
      pagination: Pagination.fromJSON(data.pagination),
    });
  }
}

export class MapSearchRankGraphItemStats extends Record<{
  startDate: Dayjs;
  endDate: Dayjs;
  rank: number | null; // 圏外または欠損の場合はnull
}>({
  startDate: dayjs(),
  endDate: dayjs(),
  rank: null,
}) {
  static fromJSON(data: JSObject): MapSearchRankGraphItemStats {
    return new MapSearchRankGraphItemStats({
      startDate: dayjs(data.period.start_date),
      endDate: dayjs(data.period.end_date),
      rank: data.rank,
    });
  }
}

export class MapSearchRankGraphItem extends Record<{
  configId: number;
  storeId: number;
  storeName: string;
  searchWord: string;
  areaName: string;
  stats: List<MapSearchRankGraphItemStats>;
}>({
  configId: 0,
  storeId: 0,
  storeName: '',
  searchWord: '',
  areaName: '',
  stats: List(),
}) {
  static fromJSON(data: JSObject): MapSearchRankGraphItem {
    return new MapSearchRankGraphItem({
      configId: data.config_id,
      storeId: data.store_id,
      storeName: data.store_name,
      searchWord: data.search_word,
      areaName: data.area_name,
      stats: List(data.stats.map((s: JSObject) => MapSearchRankGraphItemStats.fromJSON(s))),
    });
  }

  get key(): string {
    return `${this.storeName}×${this.searchWord}`;
  }
}

export class MapSearchRankGraphItemList extends Record<{ list: List<MapSearchRankGraphItem> }>({ list: List() }) {
  findByKey(key: string): MapSearchRankGraphItem | undefined {
    return this.list.find((item) => item.key === key);
  }
}

export class MapSearchRankGraphAverage extends Record<{
  aggregateUnit: AggregateUnit;
  stats: List<MapSearchRankGraphItemStats>;
}>({
  aggregateUnit: 'month',
  stats: List(),
}) {
  static fromJSON(data: JSObject): MapSearchRankGraphAverage {
    return new MapSearchRankGraphAverage({
      aggregateUnit: data.aggregate_unit as AggregateUnit,
      stats: List(data.stats.map((s: JSObject) => MapSearchRankGraphItemStats.fromJSON(s))),
    });
  }
}

export class MapSearchRankGraphData extends Record<{
  average: MapSearchRankGraphAverage | null;
  comparisonAverage: MapSearchRankGraphAverage | null;
  items: MapSearchRankGraphItemList;
  comparisonItems: MapSearchRankGraphItemList;
}>({
  average: null,
  comparisonAverage: null,
  items: new MapSearchRankGraphItemList(),
  comparisonItems: new MapSearchRankGraphItemList(),
}) {
  // グラフデータの最大の順位を求める
  get maxRank() {
    const averageMaxRank = this.average?.stats.reduce((max, stat) => Math.max(stat.rank || 0, max), 0) || 0;
    const comparisonAverageMaxRank =
      this.comparisonAverage?.stats.reduce((max, stat) => Math.max(stat.rank || 0, max), 0) || 0;
    const itemsMaxRank = this.items.list
      .map((item) => item.stats.map((stat) => stat.rank || 0))
      .flatten()
      .reduce((max, value) => Math.max(value, max), 0);
    const comparisonItemsMaxRank = this.comparisonItems.list
      .map((item) => item.stats.map((stat) => stat.rank || 0))
      .flatten()
      .reduce((max, value) => Math.max(value, max), 0);
    return Math.max(averageMaxRank, comparisonAverageMaxRank, itemsMaxRank, comparisonItemsMaxRank);
  }

  // グラフデータの最小の順位を求める
  get minRank() {
    // 初期値を順位の範囲外の大きな数字にしておく
    const MAX_RANK = 100;
    const averageMinRank =
      this.average?.stats.reduce((min, stat) => Math.min(stat.rank || MAX_RANK, min), MAX_RANK) || MAX_RANK;
    const comparisonAverageMinRank =
      this.comparisonAverage?.stats.reduce((min, stat) => Math.min(stat.rank || MAX_RANK, min), MAX_RANK) || MAX_RANK;
    const itemsMinRank = this.items.list
      .map((item) => item.stats.map((stat) => stat.rank || MAX_RANK))
      .flatten()
      .reduce((min, value) => Math.min(value, min), MAX_RANK);
    const comparisonItemsMinRank = this.comparisonItems.list
      .map((item) => item.stats.map((stat) => stat.rank || MAX_RANK))
      .flatten()
      .reduce((min, value) => Math.min(value, min), MAX_RANK);
    const result = Math.min(averageMinRank, comparisonAverageMinRank, itemsMinRank, comparisonItemsMinRank);
    // ここまできても範囲外の数値の場合は、データがないので0にしておく
    return result === MAX_RANK ? 0 : result;
  }
}
