import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GbpServiceDiffsAlert } from 'components/molecules/ServiceIndex/GbpServiceDiffsAlert';
import { GbpServiceSyncAlert } from 'components/molecules/ServiceIndex/GbpServiceSyncAlert';
import { ServiceIndexFooter } from 'components/pageComponents/Service/ServiceIndexFooter';
import { ServiceIndexTable } from 'components/pageComponents/Service/ServiceIndexTable';
import { ServiceListModal } from 'components/pageComponents/Service/ServiceListModal';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { ServiceHelp as Help } from 'helpers/ContextHelp';
import { getPageTitle } from 'helpers/utils';
import { ServiceSummary } from 'models/Domain/Service/Service';
import { AppActions } from 'modules/app/actions';
import { ServiceActions } from 'modules/service/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const ServiceIndex = () => {
  const dispatch = useDispatch();
  const [selectedServiceIds, setSelectedServiceIds] = useState(ImmutableSet<number>([]));
  const [showModal, setShowModal] = useState(false);
  const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
  const {
    applyToGbp,
    initializeIndexPage,
    updateServiceSummary,
    batchDelete: deleteService,
  } = useMemo(() => bindActionCreators(ServiceActions, dispatch), [dispatch]);
  const { isLoading: isServiceSummaryLoading, serviceSummary } = useSelector((state) => state.service);
  const { isLoading: isGmbLocationDiffsLoading, gmbLocationDiffs } = useSelector((state) => state.app);

  const isEmpty = serviceSummary.itemsNotDeleted.size === 0;
  const isLoading = isServiceSummaryLoading || isGmbLocationDiffsLoading;
  const showEmptyMessage = isEmpty && !isServiceSummaryLoading && !isGmbLocationDiffsLoading;
  const disableApplyButton = serviceSummary.items.size === 0;
  const showCautiousMessage = serviceSummary.items.size > 1;
  const serviceSummaryItemsNotSynced = serviceSummary.itemsNotSyncedToGbp;
  const serviceDiffs = gmbLocationDiffs.list.filter((diff) => diff.key === 'serviceItems');

  const handleOnApply = useCallback(() => {
    if (!window.confirm('STORECASTで設定したサービスをGBPに反映しますか？GBPで設定したサービスは上書きされます'))
      return;
    applyToGbp();
  }, [applyToGbp]);

  const handleOnClone = useCallback(
    (serviceId: number) => {
      // 選択されたサービスのIDをbaseIdとして作成画面に渡す
      moveTo({
        pathname: Path.service.create,
        state: { baseId: serviceId },
      });
    },
    [moveTo],
  );

  const handleOnDelete = useCallback(
    (serviceIds: ImmutableSet<number>) => {
      if (!window.confirm('本当に削除しますか？')) return;
      deleteService(serviceIds);
      // 選択を解除する
      setSelectedServiceIds(ImmutableSet());
    },
    [deleteService],
  );

  const handleOnChangeServiceItems = useCallback(
    (items: ServiceSummary['items']) => {
      const newServiceSummary = serviceSummary.set('items', items);
      updateServiceSummary(newServiceSummary);
    },
    [updateServiceSummary, serviceSummary],
  );

  useEffect(() => {
    initializeIndexPage();
  }, [initializeIndexPage]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('サービス一覧')}></Helmet>
      <StickyHeader>
        <Title>サービス一覧</Title>
        <StyledButton onClick={handleOnApply} disabled={disableApplyButton}>
          GBPに反映する
        </StyledButton>
      </StickyHeader>
      <WideBody>
        {
          // NOTE: GBPと差分が存在するサービスグループがある場合のアラート
          serviceDiffs.size > 0 && (
            <AlertWrapper>
              <GbpServiceDiffsAlert serviceDiffs={serviceDiffs} />
            </AlertWrapper>
          )
        }
        {
          // NOTE: GBPに反映していないサービスグループがある場合のアラート
          serviceSummaryItemsNotSynced.size > 0 && (
            <AlertWrapper>
              <GbpServiceSyncAlert serviceSummaryItemsNotSynced={serviceSummaryItemsNotSynced}>
                <StyledInnerButton onClick={handleOnApply} disabled={isEmpty}>
                  GBPに反映する
                </StyledInnerButton>
              </GbpServiceSyncAlert>
            </AlertWrapper>
          )
        }
        <Header>
          <Count>{serviceSummary.itemsNotDeleted.size}件のサービスグループ</Count>
          <ButtonContainer>
            <TextButton onClick={() => setShowModal(true)}>並び替え</TextButton>
            <StyledLink to={Path.service.create}>
              <TextButton>サービスグループを作成</TextButton>
            </StyledLink>
          </ButtonContainer>
        </Header>
        {showEmptyMessage && (
          <EmptyMessage>
            サービスグループ
            <StyledContextHelp content={Help.serviceGroupName} />
            が登録されていません。
            <br />
            右上の「サービスグループを作成」から追加してください。
          </EmptyMessage>
        )}
        {!isEmpty && (
          <ServiceIndexTable
            isLoading={isLoading}
            serviceSummary={serviceSummary}
            selectedServiceIds={selectedServiceIds}
            onClone={handleOnClone}
            onDelete={handleOnDelete}
            onChange={handleOnChangeServiceItems}
            onChangeSelectedServiceIds={setSelectedServiceIds}
          />
        )}
        {showCautiousMessage && (
          <Description>
            同じ名称のサービスが複数のサービスグループに設定されている場合、順番が下にあるサービスグループの内容がGBPに反映されます。
          </Description>
        )}
        {selectedServiceIds.size > 0 && (
          <ServiceIndexFooter
            onCancel={() => setSelectedServiceIds(ImmutableSet())}
            selectedSize={selectedServiceIds.size}
            onDelete={() => handleOnDelete(selectedServiceIds)}
          />
        )}
      </WideBody>
      {showModal && (
        <ServiceListModal
          serviceSummary={serviceSummary}
          isOpen={true}
          onClose={() => setShowModal(false)}
          onChange={handleOnChangeServiceItems}
        />
      )}
    </MainWrapper>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const AlertWrapper = styled.div`
  margin-bottom: 20px;
`;

const Count = styled.div`
  color: ${COLOR.DARK_GRAY};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-weight: bold;
  color: ${COLOR.GREEN};
`;

const Description = styled.div`
  color: ${COLOR.DARK_GRAY};
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 14px;
  }
`;

const StyledInnerButton = styled(Button)`
  &&& {
    width: 128px;
    height: 32px;
    font-size: 11px;
    padding: 8px 19px;
  }
`;

const TextButton = styled(Button).attrs({ priority: 'low' })``;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const EmptyMessage = styled.div`
  padding: 32px 0;
  text-align: center;
  line-height: 2;
`;

const StyledContextHelp = styled(ContextHelp)`
  margin-right: 4px;
`;
