import React from 'react';

import styled from 'styled-components';

type RatingStarProps = {
  className?: string;
  rating: number;
  maxRating?: number;
};

export const RatingStar = React.memo(({ className, rating, maxRating = 5 }: RatingStarProps) => (
  <Wrapper className={className}>
    <Value style={{ width: `${(100 * rating) / 5}%` }}>
      {Array(maxRating)
        .fill(0)
        .map((_) => '★')
        .join('')}
    </Value>
    <Max>
      {Array(maxRating)
        .fill(0)
        .map((_) => '☆')
        .join('')}
    </Max>
  </Wrapper>
));

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  color: #fcbd05;
`;
const Value = styled.div`
  position: absolute;
  z-index: 1;
  overflow: hidden;
`;
const Max = styled.div``;
