import { List, Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { CompetitorApi, CompetitorBatchInsertParams } from 'ApiClient/CompetitorApi';
import { Competitor, Competitors } from 'models/Domain/Competitor/Competitor';
import { CompetitorRegisterSearchCondition } from 'models/Domain/Competitor/CompetitorRegisterSearchCondition';
import { CompetitorSearchResult } from 'models/Domain/Competitor/CompetitorSearchResult';
import { AppActions } from 'modules/app/actions';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { CompetitorRegisterActions as Actions } from './actions';
import { CompetitorRegisterState } from './reducers';
import { CompetitorRegisterSelectors } from './selectors';

export default function* saga() {
  yield takeLatest(Actions.initializePage, initializePage);
  yield takeLatest(Actions.searchCompetitors, searchCompetitors);
  yield takeLatest(Actions.bulkInsertCompetitors, bulkInsertCompetitors);
}

function* initializePage() {
  yield call(waitForUserAndStoresInitialized);

  yield put(Actions.clearState());

  yield put(Actions.setIsLoading(true));

  let searchCondition = new CompetitorRegisterSearchCondition();

  const group: Group = yield call(getInitialGroup, { group: searchCondition.group });
  searchCondition = searchCondition.setIn(['group'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.group,
    containsClosedStores: searchCondition.showClosedStores,
  });

  const currentStoreIds = searchCondition.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.setStoreIds(availableStoreIds, true);
  } else {
    searchCondition = searchCondition.setStoreIds(
      currentStoreIds.intersect(availableStoreIds),
      currentStoreIds.equals(availableStoreIds),
    );
  }

  yield put(Actions.setSearchCondition(searchCondition));
  yield put(Actions.setIsLoading(false));
}

function* fetchRegisteredCompetitors(storeIds: List<number>) {
  // 設定済みの競合店舗のplaceIdsを返す
  let result = List<Competitor>();
  let page = 1;
  while (true) {
    const params = { store_id: `${storeIds.join(',')}`, page };
    const response: YieldReturn<typeof CompetitorApi.getCompetitors> = yield CompetitorApi.getCompetitors(params);
    if (response.isSuccess) {
      const competitors = Competitors.fromJSON(response.data);
      result = result.concat(competitors.items);
      if (competitors.pagination.has_next) {
        page += 1;
      } else {
        break;
      }
    } else {
      break;
    }
  }
  return result;
}

function* searchCompetitors(action: ReturnType<typeof Actions.searchCompetitors>) {
  const searchCondition = action.payload;
  yield put(Actions.setSearchCondition(searchCondition));

  const { storeIds, keyword } = searchCondition;

  // 各店舗の検索結果を入れるための初期データ
  const searchResults = List(
    storeIds.map((storeId) =>
      CompetitorSearchResult.fromJSON({
        store_id: storeId,
        keyword: keyword,
        items: [],
        is_loading: true,
        is_error: false,
      }),
    ),
  );

  // 初期化する
  yield put(Actions.setSearchResults(searchResults));
  yield put(Actions.setIsLoading(true));
  yield put(Actions.setFilterWords(List()));
  yield put(Actions.setSelectedCompetitors(List()));
  yield put(Actions.setRegisteredCompetitors(List()));

  const storeIdsList = storeIds.toList();

  const registeredCompetitors: List<Competitor> = yield call(fetchRegisteredCompetitors, storeIdsList);
  yield put(Actions.setRegisteredCompetitors(registeredCompetitors));

  let index = 0;
  // 5店舗ずつ競合店舗を取得する
  while (true) {
    const targetStoreIds = storeIdsList.slice(index, index + 5);
    yield all(targetStoreIds.toArray().map((storeId) => call(updateSearchResult, storeId, keyword)));
    index = index + 5;
    if (index >= storeIds.size) {
      break;
    } else {
      // 5店舗ごとに1秒空ける
      yield delay(1000);
    }
  }
  yield put(Actions.setIsLoading(false));
}

function* updateSearchResult(storeId: number, keyword: string) {
  // 指定された店舗の競合候補の店舗を取得する
  const response: YieldReturn<typeof CompetitorApi.search> = yield CompetitorApi.search({
    store_id: storeId,
    keyword,
  });

  const state: CompetitorRegisterState = yield select(CompetitorRegisterSelectors.selectState);
  let { searchResults, selectedCompetitors } = state;

  // searchResultsがnullの場合は更新対象がないので何もしない（通常は検索実行時に空データが作られる）
  if (!searchResults) {
    return;
  }
  const { registeredCompetitors } = state;

  const searchWord = state.searchCondition.keyword;
  // 検索条件が一致しているsearchResultのindexを取得し、更新する
  const index = searchResults.findIndex((s) => s.storeId === storeId && s.keyword === keyword);
  if (response.isSuccess) {
    const searchResult = CompetitorSearchResult.fromJSON(response.data);
    searchResults = searchResults.set(index, searchResult);
    // 初期表示される3件のうちに店舗名に検索条件のキーワードが含まれていたら、あらかじめ選択状態にしておく
    const selectedCompetitor = searchResult.items.find(
      (item, index) => item.storeName.includes(searchWord) && index <= 3,
    );
    if (
      selectedCompetitor &&
      !registeredCompetitors.find(
        (c) => c.placeId === selectedCompetitor.placeId && c.storeId === selectedCompetitor.storeId,
      )
    ) {
      selectedCompetitors = selectedCompetitors.push(selectedCompetitor.toCompetitor());
      yield put(Actions.setSelectedCompetitors(selectedCompetitors));
    }
  } else {
    console.error(response.error);
    searchResults = searchResults.mergeIn([index], { isLoading: false, isError: true });
  }
  yield put(Actions.setSearchResults(searchResults));
}

function* bulkInsertCompetitors() {
  const { selectedCompetitors, tags }: CompetitorRegisterState = yield select(CompetitorRegisterSelectors.selectState);
  const params: CompetitorBatchInsertParams = {
    items: selectedCompetitors
      .map((competitor) => ({
        store_id: competitor.storeId,
        name: competitor.name,
        place_id: competitor.placeId,
        tags: tags.toArray(),
      }))
      .toArray(),
  };
  const response: YieldReturn<typeof CompetitorApi.bulkInsert> = yield CompetitorApi.bulkInsert(params);
  if (response.isSuccess) {
    toast({
      type: 'success',
      title: '競合店舗を登録しました',
    });
    yield put(AppActions.moveTo(Path.competitors.index));
  } else {
    toast({
      type: 'success',
      title: '競合店舗の登録に失敗しました',
      time: 10000,
    });
  }
}
