export default {
  REDUX_LOGGER_ENABLE: false,
  AWS_CONFIG: {
    Auth: {
      identityPoolId: 'ap-northeast-1:25cf2909-f6ae-48a1-a2a7-23d99be209b2',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_NOzoLoimq',
      userPoolWebClientId: '7c692kf7cjiaemk2otl9tvmrek',
    },
  },
  API_ENDPOINT: 'https://wt6dlla8x4.execute-api.ap-northeast-1.amazonaws.com/prod/vmd',
  FAAS_API_ENDPOINT: 'https://storecast-faas-api.pathee.com',
  DATA_RESET_API_ENDPOINT: '',
  GENERATE_LOCALPOST_TEXT_ENDPOINT: 'https://kyf27qd2uyp53u6sbqubxp6amm0rsfgo.lambda-url.ap-northeast-1.on.aws',
  GENERATE_LOCALPOST_TRASNLATION_ENDPOINT: 'https://fqaubsnuo22rdmftw6tifzyeke0widzg.lambda-url.ap-northeast-1.on.aws',
  GENERATE_REVIEW_REPLY_ENDPOINT: 'https://zu4divnuravkuioi3gkwy4cfsy0nvwuy.lambda-url.ap-northeast-1.on.aws',
  GA_TRACKING_ID: 'UA-40618786-17',
  USER_ID_DIMENSION: 'dimension1',
  CHANNEL_TALK_PLUGIN_KEY: '1bde1989-40bd-4c60-b0a6-0589e8c54d3d',
  SENTRY_DATA_SOURCE_NAME: 'https://de4105b45f4b408ab861f8f6ccc48949@sentry.io/1776352',
  SENTRY_IGNORE_ERRORS: [],
  GOOGLE_API_KEY: 'AIzaSyCb8r2pNvWchFI1cGuZ_4QJLUcDJ1AMeV8',
  REVISION_URL: 'https://sc-prod-storecast-web-revision.s3.ap-northeast-1.amazonaws.com/revision.json',
  LOCALPOST_NGWORDS_URL: 'https://sc-prod-storecast-web-revision.s3.ap-northeast-1.amazonaws.com/localpost_ngwords',
  // storecastのアプリID、本番環境用の設定なのでテストアプリのIDには変更しない
  FACEBOOK_APP_ID: '1284330752440175',
  RESET_DATA_ENABLED: false,
};
