import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { PromotionGenerateCondition } from 'models/Domain/Promotion/PromotionGenerateCondition';

import { PromotionGenerateConditionContents } from './PromotionGenerateConditionContents';
import { PromotionGenerateResultContents } from './PromotionGenerateResultContents';

type ModalMode = 'condition' | 'result';

type Props = {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onApply: (body: string, url?: string | null) => void;
};

export const PromotionGenerateModal: React.FC<Props> = ({ isOpen, onClose, onApply }) => {
  const [mode, setMode] = useState<ModalMode>('condition');
  const [condition, setCondition] = useState<PromotionGenerateCondition>(new PromotionGenerateCondition());
  // 同一条件で再生成する際にキャッシュを使われないように、実行時の日時を保持して一緒に渡す
  const [generateTime, setGenerateTime] = useState('');
  const [applyUrl, setApplyUrl] = useState(true);

  const handleOnGenerate = useCallback(() => {
    setCondition(condition);
    setMode('result');
    setGenerateTime(dayjs().format());
  }, [condition]);

  const handleOnBack = useCallback(() => {
    setMode('condition');
  }, []);

  const handleOnApply = useCallback(
    (body: string) => {
      onApply(body, applyUrl ? condition.url : undefined);
      onClose();
    },
    [applyUrl, condition.url, onApply, onClose],
  );

  const handleOnChangeApplyUrl = useCallback((value: boolean) => {
    setApplyUrl(value);
  }, []);

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      {mode === 'condition' && (
        <PromotionGenerateConditionContents
          condition={condition}
          onChangeCondition={setCondition}
          onClose={onClose}
          onGenerate={handleOnGenerate}
          applyUrl={applyUrl}
          onChangeApplyUrl={handleOnChangeApplyUrl}
        />
      )}
      {mode === 'result' && (
        <PromotionGenerateResultContents
          condition={condition}
          onBack={handleOnBack}
          onApply={handleOnApply}
          generateTime={generateTime}
          onGenerate={handleOnGenerate}
        />
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)``;
