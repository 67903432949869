import React from 'react';

import styled from 'styled-components';

import Promotion from 'models/Domain/Promotion/Promotion';
import { PromotionTranslationData } from 'models/Domain/Promotion/PromotionGroup';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

interface PromotionPreviewProps {
  className?: string;
  promotion: Promotion;
  childPost?: PromotionTranslationData | null;
  store: Store;
}

export const PromotionPreview: React.FC<PromotionPreviewProps> = ({ className, promotion, store, childPost }) => {
  // childPostが指定されている場合は、子投稿として扱う
  const isChildPost = !!childPost;
  const title = isChildPost ? childPost?.title : promotion.event?.title;
  const body = isChildPost ? childPost?.body : promotion.body;
  const aliasLabel = isChildPost ? childPost?.generateAliasLabel(store) : promotion.generateAliasLabel(store);
  const callToAction = isChildPost ? childPost?.call_to_action : promotion.call_to_action;
  return (
    <Wrapper className={className}>
      {/* 子投稿は画像なし */}
      {!isChildPost && promotion.media?.imageUrl && (
        <ImgWrapper>
          <Img src={promotion.media?.imageUrl} />
        </ImgWrapper>
      )}
      {promotion.event && <EventTitle>{title}</EventTitle>}
      {promotion.event && <EventPeriod>{promotion.event.scheduleText}</EventPeriod>}
      {body && (
        <BodyText>
          {aliasLabel.map((data, i) => {
            if (data.type == 'Alias') {
              return <Alias key={i}>{data.text}</Alias>;
            } else if (data.type === 'PhoneNumber' || data.type === 'NGWord') {
              return <Warning key={i}>{data.text}</Warning>;
            }
            return data.text;
          })}
        </BodyText>
      )}
      {callToAction && callToAction.action_type !== 'ACTION_TYPE_UNSPECIFIED' && (
        <StyledButton target='_blank' rel='noreferrer' href={callToAction.urlWithoutAlias}>
          {callToAction.actionTypeLabel}
        </StyledButton>
      )}
      {promotion.offer?.redeemOnlineUrl && (
        <RedeemOnlineUrl>
          <Link href={promotion.offer.redeemOnlineUrl} target={'_blank'} rel='noreferrer'>
            オンラインで特典を利用
          </Link>
        </RedeemOnlineUrl>
      )}
      {promotion.offer?.couponCode && (
        <CouponCodeWrapper>
          <CouponCodeText>このコードをお店で提示してください</CouponCodeText>
          <CouponCode>{promotion.offer.couponCode}</CouponCode>
          <CouponCodeText>有効期限：{promotion.event?.scheduleTextForCoupon}</CouponCodeText>
        </CouponCodeWrapper>
      )}
      {promotion.offer?.termsConditions && <TermsConditions>{promotion.offer.termsConditions}</TermsConditions>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  line-height: 150%;
  white-space: pre-wrap;
  border-radius: 8px;
  border: 1px solid #dadce0;
  box-shadow: none;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
`;

const Alias = styled.span`
  font-weight: bold;
  color: ${COLOR.GREEN};
  vertical-align: baseline;
`;

const Warning = styled.span`
  font-weight: bold;
  color: ${COLOR.LIGHT_RED};
  vertical-align: baseline;
`;

const BodyText = styled.div`
  margin: 20px;
`;

const ImgWrapper = styled.div`
  margin-bottom: -12px;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
`;

const StyledButton = styled.a`
  background-color: ${COLOR.GOOGLE};
  color: ${COLOR.WHITE};
  border-radius: 4px;
  text-align: center;
  padding: 4px 16px;
  display: inline-block;
  margin-bottom: 16px;
  margin-left: 20px;
  &:hover {
    color: ${COLOR.WHITE};
  }
`;

const EventTitle = styled.div`
  margin: 20px 20px 0;
`;
const EventPeriod = styled.div`
  color: ${COLOR.DARK_GRAY};
  margin: 0 20px 20px;
`;

const CouponCodeWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 2px dashed #dadce0;
  background: #f8f9fa;
  border-radius: 6px;
  padding: 15px 5px;
`;

const CouponCode = styled.div`
  color: #202124;
  font-size: 24px;
  font-weight: bold;
  margin: 15px 5px;
  word-break: break-all;
`;

const CouponCodeText = styled.div`
  color: #5f6368;
  font-size: 12px;
  text-align: center;
`;

const RedeemOnlineUrl = styled.div`
  margin: 12px 20px 20px;
`;
const TermsConditions = styled.div`
  margin: 20px;
  color: #5f6368;
  font-size: 11px;
`;

const Link = styled.a`
  font-size: 13px;
  color: #1a73e8;
  cursor: pointer;
  word-break: break-all;
  &:hover {
    color: #1a73e8;
    text-decoration: underline;
  }
  &:active {
    color: #1a73e8;
  }
  &:visited {
    color: #1a73e8;
  }
`;
