import React, { useState } from 'react';

import { List } from 'immutable';
import { useDispatch } from 'react-redux';
import { Dropdown, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { ConfirmModal } from 'components/molecules/ConfirmModal';
import Gmb from 'models/Domain/Gmb';
import { AppActions } from 'modules/app/actions';
import { GmbActions } from 'modules/gmb/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export interface Props {
  gmbList: List<Gmb>;
  onAuthorize: (value: any) => void;
}

const GmbListContent: React.FC<Props> = ({ gmbList, onAuthorize }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>();
  const [selectedAccountName, setSelectedAccountName] = useState<string>('');

  return (
    <Wrapper>
      <GmbTable verticalAlign='middle'>
        <Table.Body>
          {gmbList.map((gmb, index) => {
            const { name, email } = gmb;
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <CellContent>
                    <Name>{name}</Name>
                  </CellContent>
                </Table.Cell>
                <Table.Cell>
                  <CellContent>{email}</CellContent>
                </Table.Cell>
                <Table.Cell>
                  <Dropdown trigger={<DropDownTriggerContent>•••</DropDownTriggerContent>} icon={null}>
                    <Dropdown.Menu direction='left'>
                      <ActionMenuItem
                        onClick={() =>
                          dispatch(AppActions.moveTo(Path.gmb.importStores.replace(':accountId', String(gmb.id))))
                        }
                      >
                        <ActionItemLabel warning={false}>店舗を取り込む</ActionItemLabel>
                      </ActionMenuItem>
                      <ActionMenuItem onClick={() => onAuthorize(gmb.id)}>
                        <ActionItemLabel warning={false}>再認証する</ActionItemLabel>
                      </ActionMenuItem>
                      <ActionMenuItem
                        onClick={() => {
                          setModalOpen(true);
                          setSelectedAccountName(name);
                          setSelectedAccountId(gmb.id);
                        }}
                      >
                        <ActionItemLabel warning={true}>連携を解除する</ActionItemLabel>
                      </ActionMenuItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </GmbTable>
      <ConfirmModal
        type='small'
        open={isModalOpen}
        text={`${selectedAccountName} のGoogleビジネスプロフィール連携を解除しますか？`}
        contentText='解除'
        onNegativeExecution={() => {
          setModalOpen(false);
        }}
        onExecution={() => {
          if (selectedAccountId) {
            dispatch(GmbActions.removeGmbAccount(selectedAccountId));
          }
          setModalOpen(false);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const GmbTable = styled(Table)`
  &&& {
    margin-top: 20px;
  }
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  margin-left: 10px;
`;

const DropDownTriggerContent = styled.div`
  color: ${COLOR.GRAY};
  padding: 0 8px;
  font-size: 16px;
  font-weight: bold;
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY} !important;
    &:first-of-type {
      border-top: none !important;
    }
  }
`;

const ActionItemLabel = styled.div<{ warning: boolean }>`
  padding: 10px;
  cursor: pointer;
  color: ${(props) => (props.warning ? COLOR.RED : COLOR.BLACK)};
`;

export default GmbListContent;
