import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { StoreCheckApi } from 'ApiClient/StoreApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { GbpChecklist as Checklist } from 'models/Domain/GbpChecklist';
import { GbpChecklistSearchCondition as SearchCondition } from 'models/Domain/GbpChecklist/GpbChecklistSearchCondition';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { GbpChecklistActions } from './actions';

import { CONTAINS_CLOSED_STORES, ONLY_GBP_CONNECTED_STORES } from './index';

export default function* saga() {
  yield takeLatest(GbpChecklistActions.initializePage, initializePage);
  yield takeLatest(GbpChecklistActions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // 初期化処理が完了している場合は何もしない
  const isPreparedPage: boolean = yield select((state: State) => state.gbpChecklist.isPreparedPage);
  if (isPreparedPage) {
    return;
  }

  yield put(GbpChecklistActions.setIsPreparedPage(true));

  yield call(updateChecklist);
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // PathがGBP診断画面以外の場合は何もしない
  if (location.pathname !== Path.gbpChecklist.index) {
    return;
  }

  // GBP診断画面の初期化処理が完了していない場合は何もしない
  const isPreparedPage: boolean = yield select((state: State) => state.gbpChecklist.isPreparedPage);
  if (!isPreparedPage) {
    return;
  }

  yield call(updateChecklist);
}

function* commitSearchCondition(action: ReturnType<typeof GbpChecklistActions.commitSearchCondition>) {
  const searchCondition = action.payload;
  yield put(GbpChecklistActions.setSearchCondition(searchCondition));
  const location: Location = yield select((state: State) => state.router.location);
  // 既存とURLに差分がある場合、URLの更新を行う
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(pushWithOrganizationId(path));
  }
}

function* updateChecklist() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  // URLの置き換え
  let searchCondition = SearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: CONTAINS_CLOSED_STORES,
    onlyGbpConnectedStores: ONLY_GBP_CONNECTED_STORES,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  yield put(GbpChecklistActions.setSearchCondition(searchCondition));

  yield call(fetchChecklist);
}

function* fetchChecklist() {
  const searchCondition: SearchCondition = yield select((state: State) => state.gbpChecklist.searchCondition);
  yield put(GbpChecklistActions.setIsLoading(true));
  const response: YieldReturn<typeof StoreCheckApi.get> = yield StoreCheckApi.get(searchCondition.toRequestParams());
  if (response.isSuccess) {
    const checkList = Checklist.fromJSON(response.data);
    yield put(GbpChecklistActions.setChecklist(checkList));
  } else {
    toast({
      type: 'error',
      title: '運用状況が取得できませんでした',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(GbpChecklistActions.setIsLoading(false));
}
