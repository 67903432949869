import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { Paging } from 'components/molecules/Paging';
import { SwitchContent } from 'components/molecules/SwitchContent';
import { GmbReviewTemplateModal } from 'components/organisms/GmbReviewTemplateModal';
import { GmbReviewCard } from 'components/pageComponents/GmbReview/GmbReviewCard';
import { GmbReviewDownloadModal } from 'components/pageComponents/GmbReview/GmbReviewDownloadModal';
import { GmbReviewFilter } from 'components/pageComponents/GmbReview/GmbReviewFilter';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { useDisplayType } from 'hooks/useDisplayType';
import { FilterStatus, SortKey, SortOrder } from 'models/Domain/GmbReview';
import { GmbActions } from 'modules/gmb/actions';
import { COLOR } from 'style/color';

export const GmbReview: React.FC = () => {
  const dispatch = useDispatch();
  const { setSearchCondition, initializeReviewListPage, setIsOpenReviewDownloadModal } = useMemo(
    () => bindActionCreators(GmbActions, dispatch),
    [dispatch],
  );
  const [templateModalOpen, setTemplateModalOpen] = useState<boolean>(false);

  const {
    searchCondition,
    committedSearchCondition,
    stores,
    reviewList,
    storeLists,
    currentUser,
    isOpenDownloadModal,
    isLoadingReviews,
  } = useSelector((state) => ({
    searchCondition: state.gmb.reviewSearchCondition,
    committedSearchCondition: state.gmb.committedReviewSearchCondition,
    stores: state.store.stores,
    reviewList: state.gmb.reviewList,
    storeLists: state.storeList.storeLists,
    currentUser: state.app.currentUser,
    isOpenDownloadModal: state.gmb.isOpenReviewDownloadModal,
    isLoadingReviews: state.gmb.isLoadingReviews,
  }));

  // 初期処理に必要な情報が揃ってから初期処理を実行する
  useEffect(() => {
    // FIXME 他に良い判断方法がないか
    if (currentUser.id !== 0 && stores.initialized && storeLists.initialized) {
      initializeReviewListPage();
    }
  }, [currentUser, stores, storeLists, initializeReviewListPage]);

  const isDesktop = useDisplayType('600px').isDesktop;

  /**
   * 絞り込み条件を確定し、クチコミの取得/URL変更を行う
   */
  const commitFilter = useCallback(() => {
    // URLを変更するため
    setSearchCondition({ searchCondition, updateLocation: true });

    // ページトップにスクロールする
    window.scrollTo(0, 0);
  }, [searchCondition, setSearchCondition]);

  /**
   * 絞り込み条件を変更する。クチコミの取得/URL変更は行わない
   */
  const changeFilter = useCallback(
    (filter: FilterStatus) => {
      let updatedCondition = searchCondition.set('filter', filter);
      // 絞り込み条件変更時はページを1に戻す
      updatedCondition = updatedCondition.setPage(1);
      // 検索条件のセットのみ実施する（クチコミの取得はcommitFilterで実施）
      setSearchCondition({ searchCondition: updatedCondition, updateLocation: false });
    },
    [searchCondition, setSearchCondition],
  );

  /**
   * ソート条件を変更する
   */
  const changeSort = useCallback(
    (value: string) => {
      const splitParam = value.split('_');
      const sortKey = splitParam[0] as SortKey;
      const sortOrder = splitParam[1] as SortOrder;

      // ソート条件の変更は確定済み検索条件から変更する
      const updatedCondition = committedSearchCondition.setSortCondition(sortKey, sortOrder);

      // 検索条件の変更とクチコミの取得
      setSearchCondition({ searchCondition: updatedCondition, updateLocation: true });
    },
    [committedSearchCondition, setSearchCondition],
  );

  /**
   * ページ条件を変更する
   */
  const changePage = useCallback(
    (page: number) => {
      // ページ条件の変更は確定済み検索条件から変更する
      const updatedCondition = committedSearchCondition.setPage(page);
      // 検索条件の変更とクチコミの取得
      setSearchCondition({ searchCondition: updatedCondition, updateLocation: true });
      // 表示位置をページの一番上に
      window.scrollTo({ top: 0 });
    },
    [committedSearchCondition, setSearchCondition],
  );

  const { key: sortKey, order: sortOrder } = searchCondition.sort;

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('クチコミ')} />
      <StickyHeader title='クチコミ' />
      <WideBody>
        <SwitchContent openLabel='絞り込み条件を閉じる' closedLabel='絞り込み条件を開く'>
          <GmbReviewFilter
            filter={searchCondition.filter}
            onChangeFilter={changeFilter}
            onCommitFilter={commitFilter}
          />
        </SwitchContent>

        <SortPullDownWrapper>
          <MiddleWrapper>
            <ReviewCount>{reviewList.pagination.total_count}件のクチコミ</ReviewCount>
            <Separate />
            <TemplateOpen onClick={() => setTemplateModalOpen(true)}>
              <TemplateIcon type='template' />
              {isDesktop ? '返信用テンプレートを見る' : '返信用テンプレート'}
            </TemplateOpen>
          </MiddleWrapper>
          <FlexWrapper>
            <SortPullDown
              value={`${sortKey}_${sortOrder}`}
              options={[
                { text: '新着順', value: 'date_desc' },
                { text: '古い順', value: 'date_asc' },
                { text: '高評価順', value: 'rating_desc' },
                { text: '低評価順', value: 'rating_asc' },
              ]}
              onChange={changeSort}
            />
            <DownloadButton
              priority='low'
              onClick={() => {
                setIsOpenReviewDownloadModal(true);
              }}
            >
              CSVダウンロード
            </DownloadButton>
          </FlexWrapper>
        </SortPullDownWrapper>

        <GmbReviewCardList>
          {reviewList.reviews.map((gmbReview, idx) => {
            const targetStore = stores.findStore(gmbReview.store_id);

            return (
              <div key={idx}>
                {targetStore && (
                  <GmbReviewCard
                    status={gmbReview.status}
                    store={targetStore}
                    review={gmbReview.review}
                    updateAt={gmbReview.update_at}
                    isDeleted={gmbReview.isDeleted}
                  />
                )}
              </div>
            );
          })}
          {!isLoadingReviews && reviewList.pagination.total_count === 0 && (
            <CompleteMessage>クチコミが存在しません</CompleteMessage>
          )}
          {isLoadingReviews && (
            <>
              <LoadingWrapperBase />
              <LoadingWrapper>
                <Loader active={true} size={'big'} inline={true} />
              </LoadingWrapper>
            </>
          )}
        </GmbReviewCardList>
        <PagingWrapper>
          <Paging
            currentPage={searchCondition.pagination.page}
            viewContentSize={searchCondition.pagination.per_page}
            totalContentSize={reviewList.pagination.total_count}
            onChangeNo={changePage}
          />
        </PagingWrapper>
      </WideBody>
      <GmbReviewTemplateModal open={templateModalOpen} onClose={() => setTemplateModalOpen(false)} canApply={false} />
      <GmbReviewDownloadModal
        open={isOpenDownloadModal}
        filter={committedSearchCondition.filter}
        onClose={() => setIsOpenReviewDownloadModal(false)}
      />
    </MainWrapper>
  );
};

const CompleteMessage = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${COLOR.GRAY};
  margin: 18px 0;
`;

const GmbReviewCardList = styled.div`
  margin: 16px 0;
  position: relative;
  min-height: 200px;
`;

const SortPullDownWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
  }
`;

const SortPullDown = styled(PullDownNarrow)`
  &&& {
    width: 175px;
    margin-right: 16px;
    @media (max-width: 600px) {
      margin-top: 8px;
    }
  }
`;

const TemplateOpen = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: ${COLOR.BLACK};
  display: inline-block;
  text-decoration: underline;

  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const TemplateIcon = styled(Icon)`
  cursor: pointer;
  vertical-align: middle;
`;

const PagingWrapper = styled.div`
  margin-top: 30px;
`;

const ReviewCount = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Separate = styled.div`
  width: 24px;
  height: 0px;
  border: 1px solid ${COLOR.GRAY};
  transform: rotate(90deg);
`;

const DownloadButton = styled(Button)`
  &&& {
    padding: 0;
    height: 40px;
    width: 155px;
    box-shadow: none;
    @media (max-width: 600px) {
      margin-top: 8px;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: #f8f8f8;
  mix-blend-mode: hard-light;
`;
