import React, { useState } from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import { MemoIconData } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { ProductStatusStatus } from 'models/Domain/OmoInsight';
import { ProductStatusGraphItem } from 'models/Domain/OmoInsight/OmoInsightGraphData';

import { ProductStatusGraph as Graph } from './Graph';

type ProductStatusGraphProps = {
  data: ProductStatusGraphItem[];
  statuses: ProductStatusStatus[];
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
};

export const ProductStatusGraph = React.memo<ProductStatusGraphProps>(({ data, statuses, memoData, onClickMemo }) => {
  // ダウンロードモーダル関連
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

  // 設定モーダル関連
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState<boolean>(false);
  const [graphSettings, setGraphSettings] = useState(new GraphSettings({ min: 'auto', max: 'auto' }));

  return (
    <Wrapper>
      <Graph
        data={data}
        statuses={statuses}
        graphSettings={graphSettings}
        memoData={memoData}
        onClickMemo={onClickMemo}
        showLegend={false}
      />
      <ButtonWrapper>
        <StyledButton onClick={() => setIsOpenSettingsModal(true)}>グラフの表示設定</StyledButton>
        <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
      </ButtonWrapper>
      <GraphDownloadModal
        isOpen={isOpenDownloadModal}
        fileName={'OmoInsightPerformance'}
        onClose={() => setIsOpenDownloadModal(false)}
      >
        <Graph data={data} statuses={statuses} graphSettings={graphSettings} showLegend={true} />
      </GraphDownloadModal>
      {isOpenSettingsModal && (
        <GraphSettingsModal
          isOpen={isOpenSettingsModal}
          settings={graphSettings}
          onClose={() => setIsOpenSettingsModal(false)}
          onChange={setGraphSettings}
        />
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`;

export const StyledButton = styled(Button).attrs({ priority: 'low' })`
  font-size: 16px;
`;
