import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { FinishedContent } from 'components/molecules/FinishedContent';
import { ActivityCardList } from 'components/organisms/ActivityCard';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { AppActions } from 'modules/app/actions';
import { OfferActions } from 'modules/offer/actions';
import { Path } from 'routes';

export const OfferActivity: React.FC = () => {
  const dispatch = useDispatch();

  const { stores, offerActivities } = useSelector((state) => ({
    stores: state.store.stores,
    offerActivities: state.offer.offerActivities,
  }));

  useEffect(() => {
    dispatch(OfferActions.getOfferActivity());
  }, [dispatch]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('直近のやりとり')} />
      <StickyHeader title='直近のやりとり' />
      {offerActivities.offers.size < 1 ? (
        <FinishedContent />
      ) : (
        <Body>
          <ActivityCardList
            offerActivities={offerActivities}
            stores={stores}
            onClickCard={(offerId) =>
              dispatch(AppActions.moveTo(Path.offer.detail.replace(':offerId', String(offerId))))
            }
            onClickMoreButton={() => dispatch(OfferActions.getNextOfferActivity())}
          />
        </Body>
      )}
    </MainWrapper>
  );
};
