import React from 'react';

import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';

import { DropdownMenu, DropdownMenuItem } from 'components/molecules/DropdownMenu';
import {
  createAlbumIndexPath,
  createCompetitorsIndexPath,
  createGBPChecklistPath,
  createGBPPerformancePath,
  createLocalInventoryPath,
  createLocalpostIndexPath,
  createMapSearchRankPath,
  createOfferIndexPath,
  createReviewIndexPath,
  createSearchKeywordsPath,
  createStoreDetailPath,
} from 'helpers/path';
import { Group } from 'types/Common';

const StoreMenuItems = [
  'storeDetail',
  'album',
  'localpost',
  'review',
  'offer',
  'localInventory',
  'searchKeyword',
  'mapSearchRank',
  'gbpChecklist',
  'performance',
  'competitors',
] as const;
type StoreMenuItem = (typeof StoreMenuItems)[number];
type StoreMenuProps = {
  group?: Group;
  storeId?: number;
  storeIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  items?: StoreMenuItem[];
  excludeItems?: StoreMenuItem[];
  additionalItems?: {
    start?: DropdownMenuItem[];
    end?: DropdownMenuItem[];
  };
} & Pick<React.ComponentProps<typeof DropdownMenu>, 'trigger' | 'direction'>;

export const StoreMenu: React.FC<StoreMenuProps> = ({
  trigger,
  direction,
  group,
  storeId,
  storeIds: _storeIds,
  startDate,
  endDate,
  items = StoreMenuItems,
  excludeItems = [],
  additionalItems,
}) => {
  // NOTE 本コンポーネントが位置するのはコンポーネント階層構造の末端の方なので、
  // ユーザー情報をリレーして渡すよりもシンプルな実装にするために、コンポーネント内で直接ストアからユーザー情報を取得している。
  const currentUser = useSelector((state) => state.app.currentUser);

  const storeIds = _storeIds ?? (storeId ? [storeId] : undefined);
  const menuItems: DropdownMenuItem[] = items
    .filter((item) => !excludeItems.includes(item))
    .map((item) => {
      switch (item) {
        case 'storeDetail':
          if (!storeId) {
            return null;
          }
          return {
            label: '店舗詳細',
            link: createStoreDetailPath({ storeId }),
            target: '_blank',
          };
        case 'album':
          return {
            label: '写真',
            link: createAlbumIndexPath({ group, storeIds, startDate, endDate }),
            target: '_blank',
          };
        case 'localpost':
          return {
            label: '投稿',
            link: createLocalpostIndexPath({ group, storeIds, startDate, endDate }),
            target: '_blank',
          };
        case 'review':
          return {
            label: 'クチコミ',
            link: createReviewIndexPath({ group, storeIds, startDate, endDate }),
            target: '_blank',
          };
        case 'offer':
          return {
            label: '依頼 / 報告',
            link: createOfferIndexPath({ group, storeIds }),
            target: '_blank',
          };
        case 'localInventory':
          if (!currentUser.canUseLocalInventoryPage) {
            return null;
          }
          if (!storeId) {
            return null;
          }
          return {
            label: '店頭在庫',
            link: createLocalInventoryPath({ storeId }),
            target: '_blank',
          };
        case 'searchKeyword':
          if (!currentUser.canUseSearchKeywords) {
            return null;
          }
          return {
            label: '検索キーワード',
            link: createSearchKeywordsPath({ group, storeIds, startDate, endDate }),
            target: '_blank',
          };
        case 'mapSearchRank':
          if (!currentUser.canUseMapSearchRank) {
            return null;
          }
          return {
            label: '検索順位監視',
            link: createMapSearchRankPath({ group, storeIds, startDate, endDate }),
            target: '_blank',
          };
        case 'gbpChecklist':
          return {
            label: 'GBP診断',
            link: createGBPChecklistPath({ group, storeIds }),
            target: '_blank',
          };
        case 'performance':
          if (!currentUser.canUseGbpPerformance) {
            return null;
          }
          return {
            label: 'GBPパフォーマンス',
            link: createGBPPerformancePath({ group, storeIds, startDate, endDate }),
            target: '_blank',
          };
        case 'competitors':
          if (!currentUser.canRegisterCompetitor) {
            return null;
          }
          return {
            label: '競合店舗',
            link: createCompetitorsIndexPath({ storeIds }),
            target: '_blank',
          };
      }
    })
    .filter((item) => item) as DropdownMenuItem[];

  const additionalStartItems = additionalItems?.start ?? [];
  const additionalEndItems = additionalItems?.end ?? [];
  const totalItems = additionalStartItems.concat(menuItems).concat(additionalEndItems);

  return <DropdownMenu trigger={trigger} direction={direction} items={totalItems} />;
};
