import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Set as ImmutableSet, is } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { SearchVolumesApi } from 'ApiClient/AdsApi';
import { buildPath, hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { SearchVolumeSearchCondition } from 'models/Domain/SearchVolume/SearchVolumeSearchCondition';
import { SearchVolumeSearchHistory } from 'models/Domain/SearchVolume/SearchVolumeSearchHistory';
import {
  SearchVolumeSearchResult,
  SearchVolumeSearchResults,
} from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import { SearchVolumeSearchStatus } from 'models/Domain/SearchVolume/SearchVolumeSearchStatus';
import { Stores } from 'models/Domain/Store';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { SearchVolumeActions as Actions } from './actions';
import { SearchVolumeSelectors as Selectors } from './selectors';

export default function* saga() {
  yield takeLatest(Actions.initializeIndexPage, initializeIndexPage);
  yield takeLatest(Actions.initializeResultPage, initializeResultPage);
  yield takeLatest(LOCATION_CHANGE, locationChange);
  yield takeLatest(Actions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(Actions.fetchSearchHistory, fetchSearchHistory);
  yield takeLatest(Actions.deleteSearchHistory, deleteSearchHistory);
  yield takeLatest(Actions.fetchSearchStatus, fetchSearchStatus);
}

function* initializeIndexPage() {
  yield call(waitForUserAndStoresInitialized);

  const state: ReturnType<typeof Selectors.selectState> = yield select(Selectors.selectState);
  const isPreparedPage = state.isPreparedIndexPage;

  // 検索履歴の1ページ目を取得する
  yield put(Actions.fetchSearchHistory(1));

  // 初期化済みの場合は以降の処理を行わない
  if (isPreparedPage) {
    return;
  }

  yield call(updateSearchCondition);
}

function* initializeResultPage(action: ReturnType<typeof Actions.initializeResultPage>) {
  const state: ReturnType<typeof Selectors.selectState> = yield select(Selectors.selectState);
  const isPreparedPage = state.isPreparedResultPage;

  const executionId = action.payload;

  // 初期化する
  yield put(Actions.setSearchStatus(new SearchVolumeSearchStatus()));
  yield put(Actions.setSearchResults(new SearchVolumeSearchResults()));

  yield put(Actions.fetchSearchStatus(executionId));

  // 初期化済みの場合は以降の処理を行わない
  if (isPreparedPage) {
    return;
  }

  let searchCondition = state.searchCondition;

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: ImmutableSet<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: false,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  yield put(Actions.setSearchCondition(searchCondition));
  yield put(Actions.setIsPreparedResultPage(true));
}

function* updateSearchCondition() {
  // URLパラメータから検索条件を生成する
  const location: Location = yield select((state: State) => state.router.location);
  let searchCondition = SearchVolumeSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: ImmutableSet<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: false,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  yield put(Actions.setSearchCondition(searchCondition));

  // 正規化したURLに置き換える
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    yield put(replaceWithOrganizationId(`${Path.searchVolume.index}?${search}`));
  }
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // インデックスページ以外の場合は何もしない
  if (location.pathname !== Path.searchVolume.index) {
    return;
  }

  // インデックスページ初期化処理が完了していない場合は何もしない
  const state: ReturnType<typeof Selectors.selectState> = yield select(Selectors.selectState);

  if (!state.isPreparedIndexPage) {
    return;
  }

  yield call(updateSearchCondition);
}

function* commitSearchCondition(action: ReturnType<typeof Actions.commitSearchCondition>) {
  yield put(Actions.setIsCommitDisabled(true));

  const searchCondition = action.payload;
  const stores: Stores = yield select((state: State) => state.store.stores);

  // 検索ボリュームの取得を開始する
  const params = searchCondition.toRequestParams(stores);
  const response: YieldReturn<typeof SearchVolumesApi.start> = yield SearchVolumesApi.start(params);

  if (response.isSuccess) {
    const executionId = response.data.execution_id;
    yield put(pushWithOrganizationId(buildPath(Path.searchVolume.result, { params: { id: executionId } })));
  } else {
    toast({
      type: 'error',
      title: '検索ボリュームの取得を開始できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }
  yield put(Actions.setIsCommitDisabled(false));
}

function* fetchSearchHistory(action: ReturnType<typeof Actions.fetchSearchHistory>) {
  yield put(Actions.setIsLoadingSearchHistory(true));

  // 検索ボリュームの取得を開始する
  const params = {
    page: action.payload,
    limit: 20,
  };
  const response: YieldReturn<typeof SearchVolumesApi.get> = yield SearchVolumesApi.get(params);
  if (response.isSuccess) {
    const searchHistory = SearchVolumeSearchHistory.fromJSON(response.data);
    yield put(Actions.setSearchHistory(searchHistory));
  } else {
    toast({
      type: 'error',
      title: '検索ボリュームの検索履歴を取得できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }
  yield put(Actions.setIsLoadingSearchHistory(false));
}

function* deleteSearchHistory(action: ReturnType<typeof Actions.deleteSearchHistory>) {
  yield put(Actions.setIsLoadingSearchHistory(true));

  const state: ReturnType<typeof Selectors.selectState> = yield select(Selectors.selectState);
  const {
    pagination: { current_page: currentPage },
    items,
  } = state.searchHistory;

  // 検索履歴を削除する
  const params = { executionId: action.payload };
  const response: YieldReturn<typeof SearchVolumesApi.delete> = yield SearchVolumesApi.delete(params);

  if (response.isSuccess) {
    toast({
      type: 'success',
      title: '検索履歴を削除しました',
      time: 10000,
    });
    // itemsが1件で削除したら空になるので、2ページ目以降の場合は前のページの結果を取得する
    const page = items.size === 1 && currentPage > 1 ? currentPage - 1 : currentPage;
    // 検索履歴を取得し直す
    yield put(Actions.fetchSearchHistory(page));
  } else {
    toast({
      type: 'error',
      title: '検索履歴を削除できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
    yield put(Actions.setIsLoadingSearchHistory(false));
  }
}

function* fetchSearchStatus(action: ReturnType<typeof Actions.fetchSearchStatus>) {
  const executionId = action.payload;

  // 検索ボリュームの取得を開始する
  const params = { executionId };
  const response: YieldReturn<typeof SearchVolumesApi.status> = yield SearchVolumesApi.status(params);

  if (response.isSuccess) {
    const state: ReturnType<typeof Selectors.selectState> = yield select(Selectors.selectState);
    const { searchStatus: currentSearchStatus } = state;
    let { searchResults } = state;

    const searchStatus = SearchVolumeSearchStatus.fromJSON(response.data);

    let hasUpdate = false; // データの更新があったか

    for (const item of searchStatus.items.toArray()) {
      const url = item.url;
      // まだデータが準備できていない場合はスキップ
      if (!url) {
        continue;
      }

      // すでに取得済みの場合はスキップ
      if (searchResults.hasData(url)) {
        continue;
      }

      const dataResponse: YieldReturn<typeof SearchVolumesApi.getData> = yield SearchVolumesApi.getData(url);

      if (dataResponse.data) {
        hasUpdate = true;
        searchResults = searchResults.setData(url, SearchVolumeSearchResult.fromJSON(dataResponse.data));
      }
    }

    if (!is(currentSearchStatus, searchStatus)) {
      yield put(Actions.setSearchStatus(searchStatus));
    }

    if (hasUpdate) {
      yield put(Actions.setSearchResults(searchResults));
    }

    if (searchStatus.isRunning()) {
      // 完了していなかったら、3秒後再び取得する
      yield delay(3000);
      yield put(Actions.fetchSearchStatus(executionId));
    }
  } else {
    toast({
      type: 'error',
      title: '検索ボリュームを取得できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }
  yield put(Actions.setIsLoadingSearchHistory(false));
}
