import { List, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import Gmb from 'models/Domain/Gmb';
import GmbGroup from 'models/Domain/GmbGroup';
import { GmbInsight } from 'models/Domain/GmbInsight';
import { InsightMetrics } from 'models/Domain/GmbInsightMetrics';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { GmbLocationUpdates } from 'models/Domain/GmbLocationUpdates';
import { GmbReview, GmbReviewSearchCondition, ReviewDetail } from 'models/Domain/GmbReview';
import { GmbReviewTemplates } from 'models/Domain/GmbReviewTemplate';
import { Store } from 'models/Domain/Store';
import { GmbInsightDownloadModalState } from 'models/Other/GmbInsightDownloadModalState';
import { JSObject } from 'types/Common';

import { GmbActions } from './actions';

export interface StateRecord {
  gmbList: List<Gmb>;
  groupList: List<GmbGroup>;
  locationList: List<GmbLocation>;
  targetStore: Store | null;
  currentAccountId: string;
  currentGroupId: string;
  currentLocationId: string;
  categoryList: GmbCategories;
  insight: GmbInsight;
  metrics: InsightMetrics;
  insightDownloadModalState: GmbInsightDownloadModalState;
  importedStores: List<string>; // 取り込み済みのロケーションの識別子 (/accounts/xxx/locations/yyy) のList
  reviewSearchCondition: GmbReviewSearchCondition;
  committedReviewSearchCondition: GmbReviewSearchCondition;
  reviewList: GmbReview;
  reviewDetail: ReviewDetail;
  reviewTemplateList: GmbReviewTemplates;
  csvDownload: ExecutionArnStatus;
  locationUpdates: GmbLocationUpdates;
  isOpenReviewDownloadModal: boolean;
  importStoresFromGBPStatus: ExecutionArnStatus;
  isLoadingReviews: boolean;
  isLoadingReview: boolean;

  // クチコミ一覧ページの初期処理に必要な情報が準備できているか
  // （currentUserなど必要な情報が揃ってから初期処理が実行される）
  isPreparedForReviewListPage: boolean;
}

export class GmbState extends Record<StateRecord>({
  gmbList: List(),
  groupList: List(),
  locationList: List(),
  targetStore: null,
  currentAccountId: '',
  currentGroupId: '',
  currentLocationId: '',
  categoryList: new GmbCategories(),
  insight: new GmbInsight(),
  metrics: new InsightMetrics(),
  insightDownloadModalState: new GmbInsightDownloadModalState(),
  importedStores: List(),
  reviewSearchCondition: new GmbReviewSearchCondition(),
  committedReviewSearchCondition: new GmbReviewSearchCondition(),
  reviewList: new GmbReview(),
  reviewDetail: new ReviewDetail(),
  reviewTemplateList: new GmbReviewTemplates(),
  csvDownload: new ExecutionArnStatus(),
  isPreparedForReviewListPage: false,
  locationUpdates: new GmbLocationUpdates(),
  isOpenReviewDownloadModal: false,
  importStoresFromGBPStatus: new ExecutionArnStatus(),
  isLoadingReviews: false,
  isLoadingReview: false,
}) {
  findGmb(gmbId: string | number) {
    return this.gmbList.find((gmb) => gmb.id === Number(gmbId));
  }

  // STORECASTに取り込んでいないロケーションを返す
  // 別のアカウントから紐づけたロケーションは識別子 (location.name) のaccountの値が異なるが、
  // importedStoresには、api側でlocationのみで取り込み済みの判定を行った識別子が含まれているので、
  // webではincludeのみで判定を行う
  get notImportedLocationList() {
    return this.locationList.filter((location) => !this.importedStores.includes(location.name));
  }
}

export const gmbReducer = reducerWithInitialState(new GmbState())
  .case(GmbActions.setGmbList, (state, data) => {
    const gmbList: List<Gmb> = List(data.map((d: JSObject) => new Gmb(d)));
    return state.set('gmbList', gmbList);
  })
  .case(GmbActions.setGmbCategoryList, (state, data) => {
    const gmbCategoryList: GmbCategories = new GmbCategories(data);
    return state.set('categoryList', gmbCategoryList);
  })
  .case(GmbActions.openConnectModal, (state, data) => {
    return state.set('targetStore', data);
  })
  .case(GmbActions.setCurrentAccountId, (state, data) => {
    return state.set('currentAccountId', data);
  })
  .case(GmbActions.setCurrentGroupId, (state, data) => {
    return state.set('currentGroupId', data);
  })
  .case(GmbActions.setCurrentLocationId, (state, data) => {
    return state.set('currentLocationId', data);
  })
  .case(GmbActions.setGroupList, (state, payload) => {
    const { data, currentAccountId } = payload;
    const groupList: List<GmbGroup> = List(data.map((d: JSObject) => new GmbGroup(d)));
    return state.merge({
      groupList,
      currentAccountId,
      currentGroupId: '',
      currentLocationId: '',
      locationList: List(),
    });
  })
  .case(GmbActions.setLocationList, (state, payload) => {
    const { data, currentGroupId, importedStores } = payload;
    const locationList: List<GmbLocation> = List(data.map((d: JSObject) => new GmbLocation(d)));
    return state.merge({
      locationList,
      currentGroupId,
      currentLocationId: '',
      importedStores,
    });
  })
  .case(GmbActions.clearTargetStore, (state) => {
    return state.merge({
      targetStore: null,
      currentAccountId: '',
      currentGroupId: '',
      currentLocationId: '',
      locationList: List(),
      groupList: List(),
    });
  })
  .case(GmbActions.updateGmbInsight, (state, insight) => {
    return state.set('insight', insight);
  })
  .case(GmbActions.updateInsightMetrics, (state, metrics) => {
    return state.set('metrics', metrics);
  })
  .case(GmbActions.setDownloadModalState, (state, payload) => {
    return state.set('insightDownloadModalState', payload);
  })
  .case(GmbActions.setGmbReview, (state, payload) => {
    return state.set('reviewList', payload);
  })
  .case(GmbActions.setReview, (state, payload) => {
    return state.set('reviewDetail', payload);
  })
  .case(GmbActions.setGmbReviewTemplateList, (state, payload) => {
    return state.set('reviewTemplateList', payload);
  })
  .case(GmbActions.setCsvDownload, (state, payload) => {
    return state.set('csvDownload', payload);
  })
  // CSVダウンロードの進捗情報を削除する
  .case(GmbActions.clearCsvDownload, (state) => {
    return state.set('csvDownload', new ExecutionArnStatus());
  })
  .case(GmbActions.setSearchCondition, (state, payload) => {
    const { searchCondition } = payload;
    return state.set('reviewSearchCondition', searchCondition);
  })
  .case(GmbActions.setCommittedSearchCondition, (state, payload) => {
    const { searchCondition } = payload;
    return state.set('committedReviewSearchCondition', searchCondition);
  })
  .case(GmbActions.setIsPreparedForReviewListPage, (state, payload) => {
    return state.set('isPreparedForReviewListPage', payload);
  })
  .case(GmbActions.setIsOpenReviewDownloadModal, (state, payload) => {
    return state.set('isOpenReviewDownloadModal', payload);
  })
  .case(GmbActions.setImportStoresFromGbpStatus, (state, payload) => {
    return state.set('importStoresFromGBPStatus', payload);
  })
  .case(GmbActions.clearImportStoresFromGbpStatus, (state, payload) => {
    return state.set('importStoresFromGBPStatus', new ExecutionArnStatus());
  })
  .case(GmbActions.setIsLoadingReviews, (state, payload) => {
    return state.set('isLoadingReviews', payload);
  })
  .case(GmbActions.setIsLoadingReview, (state, payload) => {
    return state.set('isLoadingReview', payload);
  });
