import React from 'react';

import styled from 'styled-components';

import { UserThumbnail } from 'components/molecules/UserThumbnail';

export const NameLabel: React.FC<{ className?: string; profileImageUrl?: string; userName: string }> = ({
  className,
  profileImageUrl,
  userName,
}) => {
  return (
    <User className={className}>
      <UserThumbnail src={profileImageUrl} name={userName} size={24} />
      <Name>{userName}</Name>
    </User>
  );
};

const User = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-weight: bold;
  margin-left: 6px;
`;
