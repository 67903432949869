import React from 'react';

import { Modal, Icon as SemanticUIIcon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Counter } from 'components/atoms/Counter';
import { TextArea } from 'components/atoms/TextArea';
import { GmbProfile, MAX_LENGTH as PROFILE_DESCRIPTION_MAX_LENGTH } from 'models/Domain/GmbLocation/GmbProfile';
import { STORE_ALIASES } from 'models/Domain/Store';
import { COLOR } from 'style/color';

export type EditProfileModalProps = {
  open: boolean;
  profile: GmbProfile;

  /**
   * エイリアスを展開したデータ
   * エイリアス展開の際に文字数がGBPの規定を超えないようにするため
   * 一括編集中は、編集中の最も店舗名が長い店舗の GmbProfile が入る
   */
  profileAliasRestored: GmbProfile;

  onChange: (profile: GmbProfile) => void;
  onApply: () => void;
  onClose: () => void;
};

export const EditProfileModal: React.FC<EditProfileModalProps> = ({
  open,
  profile,
  profileAliasRestored,
  onChange,
  onApply,
  onClose,
}) => {
  // エイリアスを展開したデータで検証する
  const validateResult = profileAliasRestored.validateDescription();

  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>ビジネス情報の変更</Modal.Header>
      <Modal.Content>
        <ItemLabel>変更内容</ItemLabel>
        <Divider />
        <EditContentWrapper>
          <StyledTextArea
            value={profile.description}
            onChange={(value) => onChange(profile.set('description', value))}
            placeholder='ビジネス情報'
            error={!validateResult.isValid ? validateResult.error : false}
          />
          {/* エイリアスを展開した文字数を表示 */}
          <StyledTextCount size={profileAliasRestored.description.length} maxSize={PROFILE_DESCRIPTION_MAX_LENGTH} />
          <Notice>
            <SemanticUIIcon name='info circle' />
            {`${Object.keys(STORE_ALIASES).join('、')} と記述すると、値が自動で入力されます。`}
          </Notice>
        </EditContentWrapper>
        <Divider />
      </Modal.Content>
      <Modal.Actions>
        <ButtonWrapper>
          <CloseButton onClick={onClose}>キャンセル</CloseButton>
          <ApplyButton priority='high' onClick={onApply} disabled={!validateResult.isValid}>
            入力
          </ApplyButton>
        </ButtonWrapper>
      </Modal.Actions>
    </Modal>
  );
};

const ItemLabel = styled.label`
  display: block;
  font-weight: bold;
`;

const Divider = styled.hr`
  border-top: 1px solid ${COLOR.LIGHT_GRAY};
  border-bottom: none;
  margin: 20px 0;
`;

const EditContentWrapper = styled.div`
  margin-top: 12px;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;

const Notice = styled.div`
  font-size: 14px;
  margin: 4px 8px;

  i {
    height: auto;
  }
`;

const ButtonCommonStyle = css`
  width: fit-content;
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
    margin-left: 20px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;
