import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { Search } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { COLOR } from 'style/color';
import { JSObject } from 'types/Common';

/**
 * 店舗のキーワードによるフィルタ
 * 検索対象は店舗コード/店名/支店名/住所
 */
export const KeywordFilter: React.FC<{
  className?: string;
  onChange: (value: string) => void;
  value: string;
}> = (props) => {
  const { className, onChange, value } = props;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    // inputValueの初期値はvalue(searchCondition.filter.searchValue)だが
    // valueの初期値は空文字なので、valueが更新されたらその値でinputValueを上書きする
    setInputValue(value);
  }, [value]);

  const handleOnClickSearch = useCallback(() => {
    onChange(inputValue);
  }, [inputValue, onChange]);

  const handleOnKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      // Enterキーが入力されたら検索を実行
      if (e.key === 'Enter') {
        onChange(inputValue);
      }
    },
    [inputValue, onChange],
  );

  return (
    <FlexWrapper className={className}>
      <SearchField
        onSearchChange={(_: SyntheticEvent, data: JSObject) => {
          setInputValue(data.value || '');
        }}
        input={{ fluid: true }}
        placeholder='店舗コード / 店舗名 / 住所'
        showNoResults={false}
        value={inputValue}
        onKeyPress={handleOnKeyPress}
      />
      <StyledButton priority={'high'} onClick={handleOnClickSearch}>
        検索
      </StyledButton>
    </FlexWrapper>
  );
};

const SearchField = styled(Search)`
  &&& {
    width: 100%;
    input {
      border-radius: 5px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
      font-weight: bold;
      height: 40px;

      &:focus {
        border: 2px solid ${COLOR.GREEN};
      }
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 336px;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    cursor: pointer;
    width: 68px;
    min-width: 68px;
    padding: 10px 15px;
    margin-left: 8px;
  }
`;
