import React from 'react';

import styled from 'styled-components';

import Menu from 'components/atoms/Menu';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ComparisonGraphType, getComparisonGraphOptions } from 'helpers/graph';
import {
  AGGREGATE_METHOD_TYPES,
  AggregateMethod,
  INTERACTION_TYPES,
  InteractionType,
  ReviewType,
  aggregateMethodLabel,
  interactionTypeLabel,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { assertNever } from 'types/Common';

import { GraphType, graphTypeLabel } from './index';

type Props = {
  className?: string;
  graphType: GraphType;
  isEnabledComparison: boolean;
  disableCombined: boolean;
  isStacked: boolean;
  reviewType: ReviewType;
  interactionType: InteractionType;
  aggregateMethod: AggregateMethod;
  comparisonGraphType: ComparisonGraphType;
  onChangeComparisonGraphType: (value: ComparisonGraphType) => void;
  onChangeInteractionType: (value: InteractionType) => void;
  onChangeIsStacked: (value: boolean) => void;
  onChangeReviewType: (value: ReviewType) => void;
  onChangeAggregateMethod: (value: AggregateMethod) => void;
};

const ComparisonGraphOptionSelect = React.memo<{
  disableCombined: boolean;
  comparisonGraphType: ComparisonGraphType;
  onChange: (value: ComparisonGraphType) => void;
}>(({ disableCombined, comparisonGraphType, onChange }) => (
  <PullDownWrapper>
    <PullDownLabel>比較期間のグラフ</PullDownLabel>
    <StyledPullDown
      value={comparisonGraphType}
      options={getComparisonGraphOptions(disableCombined)}
      onChange={onChange}
    />
  </PullDownWrapper>
));

export const Header = React.memo<Props>(
  ({
    className,
    graphType,
    isEnabledComparison,
    disableCombined,
    comparisonGraphType,
    interactionType,
    isStacked,
    reviewType,
    aggregateMethod,
    onChangeComparisonGraphType,
    onChangeIsStacked,
    onChangeInteractionType,
    onChangeReviewType,
    onChangeAggregateMethod,
  }) => {
    return (
      <Wrapper className={className}>
        <Title>{graphTypeLabel[graphType]}</Title>
        <MenuContainer>
          {isEnabledComparison && (
            <ComparisonGraphOptionSelect
              disableCombined={disableCombined}
              comparisonGraphType={comparisonGraphType}
              onChange={(value) => onChangeComparisonGraphType(value)}
            />
          )}
          <MenuWrapper>
            <MenuLabel>集計方法</MenuLabel>
            <Menu>
              {AGGREGATE_METHOD_TYPES.map((type) => (
                <Menu.Item
                  key={type}
                  name={type}
                  active={aggregateMethod === type}
                  onClick={() => onChangeAggregateMethod(type)}
                  style={{ padding: '0 12px' }}
                >
                  {aggregateMethodLabel[type]}
                </Menu.Item>
              ))}
            </Menu>
          </MenuWrapper>
          {(() => {
            switch (graphType) {
              case 'overview':
                return (
                  <Menu>
                    {INTERACTION_TYPES.map((type) => (
                      <Menu.Item
                        key={type}
                        name={type}
                        active={interactionType === type}
                        onClick={() => onChangeInteractionType(type)}
                      >
                        {interactionTypeLabel[type]}
                      </Menu.Item>
                    ))}
                  </Menu>
                );
              case 'impressions':
              case 'interactions':
                return (
                  <>
                    <Menu>
                      {INTERACTION_TYPES.map((type) => (
                        <Menu.Item
                          key={type}
                          name={type}
                          active={interactionType === type}
                          onClick={() => onChangeInteractionType(type)}
                        >
                          {interactionTypeLabel[type]}
                        </Menu.Item>
                      ))}
                    </Menu>
                    <Menu>
                      <Menu.Item name={'unstacked'} active={!isStacked} onClick={() => onChangeIsStacked(false)}>
                        折れ線グラフ
                      </Menu.Item>
                      <Menu.Item name={'stacked'} active={isStacked} onClick={() => onChangeIsStacked(true)}>
                        積み上げグラフ
                      </Menu.Item>
                    </Menu>
                  </>
                );
              case 'reviews':
                return (
                  <MenuWrapper>
                    <MenuLabel>クチコミの集計対象</MenuLabel>
                    <Menu>
                      <Menu.Item
                        name={'period'}
                        active={reviewType === 'period'}
                        onClick={() => onChangeReviewType('period')}
                        style={{ padding: '0 12px' }}
                      >
                        期間
                      </Menu.Item>
                      <Menu.Item
                        name={'total'}
                        active={reviewType === 'total'}
                        onClick={() => onChangeReviewType('total')}
                        style={{ padding: '0 12px' }}
                      >
                        累計
                      </Menu.Item>
                    </Menu>
                  </MenuWrapper>
                );
              default:
                return assertNever(graphType);
            }
          })()}
        </MenuContainer>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 40px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
`;

const PullDownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PullDownLabel = styled.div`
  font-size: 14px;
  margin-right: 8px;
`;

const StyledPullDown = styled(PullDownNarrow)`
  width: 176px;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MenuLabel = styled.div`
  font-size: 14px;
`;
