import { Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { JSObject } from 'types/Common';

export interface GmbGroupRecord {
  accountName: string;
  name: string;
}

export default class GmbGroup extends Record<GmbGroupRecord>({
  accountName: '',
  name: '',
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  validate() {
    const errors: JSObject = {};

    if (!this.name || !this.name.trim()) {
      errors.name = ErrorType.REQUIRED_ERROR;
    }

    if (!this.accountName || !this.accountName.trim()) {
      errors.email = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }
}
