import React from 'react';

import { Table } from 'semantic-ui-react';

import { ExternalLink } from 'components/atoms/ExternalLink';
import { GmbLocationValue } from 'components/molecules/GmbLocationUpdates/GmbLocationValue';
import { GUIDE_LINKS } from 'helpers/utils';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { GmbLocationDiff, LocationDiffKey } from 'models/Domain/GmbLocationDiffs';
import { GmbLocationUpdate } from 'models/Domain/GmbLocationUpdates';
import { Store } from 'models/Domain/Store';
import { SimpleUpdateType } from 'modules/gmbLocationUpdates/types';

import { StoreCell } from './Cell/StoreCell';

import {
  ButtonsWrapper,
  CheckLabel,
  ResetButton,
  StatusLabel,
  StyledButton,
  StyledCancelButton,
  StyledCell,
  StyledCheckbox,
  TypeLabel,
  WarningMessage,
} from '.';

type UpdatesTableRowProps = {
  locationKey: Exclude<LocationDiffKey, 'attributes' | 'moreHours' | 'serviceItems'>;
  isFirstRowInStore: boolean;
  checked: boolean;
  store: Store;
  updateStatus: SimpleUpdateType | undefined;
  diff: GmbLocationDiff;
  gmbLocationUpdate: GmbLocationUpdate;
  gmbCategoryList: GmbCategories;
  onAdaptationStorecast: (target: GmbLocationDiff) => void;
  onAdaptationGmb: (target: GmbLocationDiff) => void;
  onChangeCheckBox: (diff: GmbLocationDiff) => void;
  onResetItem: (target: GmbLocationDiff) => void;
};

export const UpdatesTableRow: React.FC<UpdatesTableRowProps> = ({
  locationKey,
  isFirstRowInStore,
  checked,
  store,
  updateStatus,
  diff,
  gmbLocationUpdate,
  gmbCategoryList,
  onAdaptationStorecast,
  onAdaptationGmb,
  onChangeCheckBox,
  onResetItem,
}) => {
  const updated = updateStatus !== undefined;
  const identifier = `${diff.store_id}-${diff.key}`;
  const first = isFirstRowInStore.toString();

  // 「STORECASTを保持」が押せるかどうか
  // 1. 差分キーが住所、緯度経度以外ならOK
  //    緯度経度はAPI経由で更新できないため false を返す
  // 2. 差分キーが住所の場合、STORECASTロケーションが住所の更新に失敗している状態でないならOK
  const canApplyStorecastData =
    (diff.key != 'address' && diff.key != 'latlng') ||
    (diff.key === 'address' && store.location_state.canApplyAddressToGmb);

  if (locationKey != diff.key) {
    console.warn(`locationKey mismatch! locationKey=${locationKey}, diff.key=${diff.key}`);
  }
  return (
    <Table.Row key={identifier}>
      <StoreCell isFirstRowInStore={isFirstRowInStore} store={store} />
      <StyledCell first={first}>
        <CheckLabel htmlFor={identifier}>
          <StyledCheckbox
            id={identifier}
            checked={checked}
            disabled={updated}
            onChange={() => onChangeCheckBox(diff)}
          />
          {diff.label}
        </CheckLabel>
      </StyledCell>
      <StyledCell first={first}>
        <TypeLabel isGoogleUpdated={diff.isGoogleUpdated}>{diff.typeLabel}</TypeLabel>
      </StyledCell>
      <StyledCell first={first} isselected={(updateStatus !== undefined && updateStatus === 'STORECAST').toString()}>
        <GmbLocationValue
          gmbLocation={gmbLocationUpdate.location}
          locationKey={locationKey}
          gmbCategoryList={gmbCategoryList}
        />
      </StyledCell>
      <StyledCell
        first={first}
        isselected={(updateStatus !== undefined && updateStatus === 'GOOGLE_UPDATED').toString()}
      >
        <GmbLocationValue
          gmbLocation={gmbLocationUpdate.location_updated}
          locationKey={locationKey}
          gmbCategoryList={gmbCategoryList}
        />
      </StyledCell>
      <StyledCell first={first}>
        {!updated ? (
          <>
            <ButtonsWrapper>
              <StyledCancelButton onClick={() => onAdaptationStorecast(diff)} disabled={!canApplyStorecastData}>
                STORECAST
              </StyledCancelButton>
              <StyledButton onClick={() => onAdaptationGmb(diff)}>Google</StyledButton>
            </ButtonsWrapper>
            {!canApplyStorecastData && (
              <WarningMessage>
                {diff.key === 'address' ? (
                  <>
                    Googleビジネスプロフィールに自動反映できない内容のため、STORECASTのデータは選択できません。手動で反映する方法については、
                    <ExternalLink href={GUIDE_LINKS.gmbPatchFailed}>STORECAST利用ガイド</ExternalLink>
                    を確認してください。
                  </>
                ) : diff.key === 'latlng' ? (
                  <>
                    緯度経度はSTORECASTから更新できないため、STORECASTのデータは選択できません。手動で反映する方法については、
                    <ExternalLink href={GUIDE_LINKS.handleLatlngDiff}>STORECAST利用ガイド</ExternalLink>
                    を確認してください。
                  </>
                ) : (
                  <></>
                )}
              </WarningMessage>
            )}
          </>
        ) : (
          <ButtonsWrapper>
            <StatusLabel>{updateStatus === 'STORECAST' ? '却下済み' : '承認済み'}</StatusLabel>
            <ResetButton onClick={() => onResetItem(diff)}>リセット</ResetButton>
          </ButtonsWrapper>
        )}
      </StyledCell>
    </Table.Row>
  );
};
