import React from 'react';

import { Button, Grid, Header, Image, TransitionablePortal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Link } from 'components/atoms/Link';
import Logo from 'components/atoms/Logo';
import { useScrollLock } from 'hooks/useScrollLock';
import { User } from 'models/Domain/User';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Props = { open: boolean; onClose: () => void; currentUser: User; signOut: () => void };

export const AccountIndexModal: React.FC<Props> = (props) => {
  const { open, currentUser, signOut, onClose } = props;

  // モーダルが開いている時はbodyのスクロール禁止
  useScrollLock(open);

  return (
    <TransitionablePortal
      open={open}
      onClose={() => onClose()}
      transition={{
        animation: 'fade',
        duration: 500,
      }}
    >
      <Wrapper onClick={() => onClose()}>
        <Inner>
          <RightMenu>
            <HeadContainer>
              <HeaderTitle as='h2'>プロフィールと設定</HeaderTitle>
              <Button size='small' icon='close' onClick={() => onClose()} />
            </HeadContainer>
            <MenuGrid columns={1} celled padded='horizontally'>
              <Cell>
                <UserWrapper>
                  <UserCell>
                    <Image circular size='tiny' src={currentUser.get('profile_image_url')} />
                  </UserCell>
                  <UserCell>
                    <UserNameCell>{currentUser.fullName}</UserNameCell>
                    <UserOrganizationCell>
                      {currentUser.organization ? currentUser.organization.name : ''}
                    </UserOrganizationCell>
                  </UserCell>
                </UserWrapper>
              </Cell>

              <Cell>
                <MenuItem to={Path.account.edit}>アカウント管理</MenuItem>
              </Cell>
              <Cell>
                <MenuItemExternal target='_blank' href='/privacy.html'>
                  <LinkLabel>プライバシーポリシー</LinkLabel>
                </MenuItemExternal>
                <MenuItemExternal target='_blank' href='/terms.html'>
                  <LinkLabel>ご利用規約</LinkLabel>
                </MenuItemExternal>
                <MenuItemExternal href='mailto:support@pathee.com'>
                  <LinkLabel>フィードバック</LinkLabel>
                </MenuItemExternal>
              </Cell>
              <LogoutCell signOut={signOut} />
              <LogoCell>
                <Logo gray width='137px' />
              </LogoCell>
            </MenuGrid>
          </RightMenu>
        </Inner>
      </Wrapper>
    </TransitionablePortal>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 415px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const RightMenu = styled.div`
  margin: auto;
  background-color: white;
  max-width: 414px;
  width: 100%;
  padding-bottom: 12px;
  color: ${COLOR.BLACK};
  overflow-y: scroll;
  @media (max-width: 414px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media (min-width: 415px) {
    position: static;
    border-radius: 12px;
  }
`;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  padding: 0 12px 0 24px;
  background-color: ${COLOR.LIGHT_GRAY};
  border-radius: 12px 0 0 0;
`;

const MenuGrid = styled(Grid)`
  box-shadow: none !important;
  margin: 0 !important;
`;

const HeaderTitle = styled(Header)`
  &&& {
    margin-bottom: 0;
    padding: 0px 8px;
    font-size: 18px;
  }
`;

const Cell: React.FC<{ onClick?: () => void; children: React.ReactNode }> = (props) => (
  <MenuGridRow onClick={props.onClick}>
    <WhiteColumn>{props.children}</WhiteColumn>
  </MenuGridRow>
);

const MenuGridRow = styled(Grid.Row)`
  margin-left: 32px !important;
`;

const MenuItemCss = css`
  display: block;
  padding: 16px 0;
  font-size: 18px;
  color: ${COLOR.BLACK};
`;

const MenuItem = styled(Link)`
  ${MenuItemCss}
`;

const MenuItemExternal = styled.a`
  ${MenuItemCss}
`;

const LogoutCell: React.FC<{ signOut: () => void }> = (props) => (
  <MenuGridRow>
    <WhiteColumn onClick={() => props.signOut()}>
      <LogoutLabel>ログアウト</LogoutLabel>
    </WhiteColumn>
  </MenuGridRow>
);

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
`;

const UserCell = styled.div`
  height: 100%;
  margin-right: 16px;
`;
const UserOrganizationCell = styled.div`
  margin-right: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.72;
`;
const UserNameCell = styled.div`
  margin-right: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.72;
`;

const WhiteColumn = styled(Grid.Column)`
  background-color: ${COLOR.WHITE};
`;

const LogoutLabel = styled.div`
  color: inherit;
  cursor: pointer;
`;

const LinkLabel = styled.span`
  color: ${COLOR.BLACK};
`;

const LogoCell = styled(Cell)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 122px;
`;
