import dayjs, { Dayjs } from 'dayjs';
import { Record as ImmutableRecord } from 'immutable';

export class YearMonth extends ImmutableRecord<{
  year: number;
  month: number;
}>({
  year: 0,
  month: 0,
}) {
  /**
   * 日付型から生成する
   * @param date
   */
  static fromDate(date: Date | Dayjs): YearMonth {
    const _date = dayjs(date);
    return new YearMonth({ year: _date.year(), month: _date.month() + 1 });
  }

  /**
   * 文字列から生成する
   * @param yearMonthStr
   */
  static fromString(yearMonthStr: string): YearMonth {
    if (!this.isMatchFormat(yearMonthStr)) {
      throw new Error(`${yearMonthStr}は「年-月」形式ではありません`);
    }
    return YearMonth.fromDate(dayjs(yearMonthStr));
  }

  /**
   * 指定された期間に含まれる月を返す
   * @param startMonth
   * @param endMonth
   */
  static range(startMonth: YearMonth, endMonth: YearMonth): YearMonth[] {
    if (startMonth.isAfter(endMonth)) {
      throw new Error(`endMonthはstartMonthより後の月を指定してください`);
    }
    const list: YearMonth[] = [];
    let month = startMonth;
    while (month.isSameOrBefore(endMonth)) {
      list.push(month);
      month = month.add({ months: 1 });
    }
    return list;
  }

  /**
   * 文字列として返す（YYYY-MM形式）
   */
  toString() {
    return this.toDayjs().format('YYYY-MM');
  }

  /**
   * dateとして返す
   */
  toDate(): Date {
    return new Date(this.year, this.month - 1);
  }

  /**
   * Dayjsとして返す
   */
  toDayjs() {
    return dayjs(this.toDate());
  }

  /**
   * 指定された形式(dayjsに準拠)の文字列に変更する
   * @param template
   */
  format(template = 'YYYY年M月') {
    return this.toDayjs().format(template);
  }

  /**
   * 年・月を加えた結果を返す
   * @param years
   * @param months
   */
  add({ years = 0, months = 0 }): YearMonth {
    const newDate = this.toDayjs().add(years, 'year').add(months, 'month');
    return YearMonth.fromDate(newDate);
  }

  /**
   * 指定された月と同じか
   * @param other
   */
  isSame(other: YearMonth) {
    return this.toDayjs().isSame(other.toDayjs());
  }

  /**
   * 指定された月より前か
   * @param other
   */
  isBefore(other: YearMonth) {
    return this.toDayjs().isBefore(other.toDayjs());
  }

  /**
   * 指定された月より後か
   * @param other
   */
  isAfter(other: YearMonth) {
    return this.toDayjs().isAfter(other.toDayjs());
  }

  /**
   * 指定された月と同じまたは前か
   * @param other
   */
  isSameOrBefore(other: YearMonth) {
    return this.isSame(other) || this.isBefore(other);
  }

  static isMatchFormat(str: string) {
    const regExp = new RegExp(/^(\d{4})-(0?[1-9]|1[012])$/);
    return regExp.test(str);
  }
}
