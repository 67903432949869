import React from 'react';

import styled from 'styled-components';

import { Counter } from 'components/atoms/Counter';
import { FormContent } from 'components/atoms/FormContent';
import { Input } from 'components/atoms/Input';
import { TextArea } from 'components/atoms/TextArea';
import Promotion, { COUPON_CODE_MAX_LENGTH, TERMS_CONDITIONS_MAX_LENGTH } from 'models/Domain/Promotion/Promotion';

type Props = {
  promotion: Promotion;
  setPromotion: (promotion: Promotion) => void;
  showErrorMessage: boolean;
};

export const PromotionFormOffer: React.FC<Props> = ({ promotion, setPromotion, showErrorMessage }) => {
  return (
    <>
      <FormContent name='クーポンコード' informationText={'店頭やページで提示するコードを入力します'}>
        <StyledInput
          value={promotion.offer?.couponCode ?? ''}
          onChange={(value) => setPromotion(promotion.changeOfferCouponCode(value))}
          placeholder={'ABCDEF'}
          error={showErrorMessage && promotion.offer?.validateCouponCode().name}
        />
        <StyledTextCount size={promotion.offer?.couponCode.length ?? 0} maxSize={COUPON_CODE_MAX_LENGTH} />
      </FormContent>
      <FormContent name='特典利用へのリンク' informationText={'クーポンページのリンクを入力します'}>
        <StyledInput
          value={promotion.offer?.redeemOnlineUrl ?? ''}
          onChange={(value) => setPromotion(promotion.changeOfferRedeemOnlineUrl(value))}
          placeholder={'https://example.com'}
          error={false}
        />
      </FormContent>
      <FormContent name='利用規約' informationText={'クーポンの利用に条件などがある場合に入力します'}>
        <StyledTextArea
          value={promotion.offer?.termsConditions ?? ''}
          onChange={(value) => setPromotion(promotion.changeOfferTermsConditions(value))}
          placeholder={'他のクーポンとは併用できません'}
          error={showErrorMessage && promotion.offer?.validateTermsConditions().name}
        />
        <StyledTextCount size={promotion.offer?.termsConditions.length ?? 0} maxSize={TERMS_CONDITIONS_MAX_LENGTH} />
      </FormContent>
    </>
  );
};

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
  }
`;

const StyledTextCount = styled(Counter)`
  margin-top: 4px;
  text-align: right;
`;
