import React, { useCallback } from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { Counter } from 'components/atoms/Counter';
import { TagInput } from 'components/molecules/TagInput';
import { MapCompetitorResearchSearchCondition as SearchCondition } from 'models/Domain/MapCompetitorResearch/MapCompetitorResearchSearchCondition';
interface Props {
  className?: string;
  searchCondition: SearchCondition;
  setSearchCondition: (searchCondition: SearchCondition) => void;
  commitSearchCondition: () => void;
}

export const SEARCH_CONDITION_LIMIT = 100;

export const MapCompetitorResearchSearchCondition = React.memo<Props>(
  ({ className, searchCondition, setSearchCondition, commitSearchCondition }) => {
    const handleChangeAreaNames = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.set('areaNames', List(values));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition, setSearchCondition],
    );

    const handleChangeSearchWords = useCallback(
      (values: string[]) => {
        const newSearchCondition = searchCondition.set('searchWords', List(values));
        setSearchCondition(newSearchCondition);
      },
      [searchCondition, setSearchCondition],
    );

    const conditionCount = searchCondition.areaNames.size * searchCondition.searchWords.size;
    const canSearch = conditionCount > 0 && conditionCount <= SEARCH_CONDITION_LIMIT;

    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <FlexContent>
            <Label>検索地点</Label>
            <TagInput
              placeholder='駅名、商業施設名など'
              values={searchCondition.areaNames.toArray()}
              onChange={handleChangeAreaNames}
            />
          </FlexContent>
          <FlexContent>
            <Label>検索ワード</Label>
            <TagInput values={searchCondition.searchWords.toArray()} onChange={handleChangeSearchWords} />
          </FlexContent>
          <ButtonContainer>
            <CounterWrapper>
              検索条件数 <StyledCounter size={conditionCount} maxSize={SEARCH_CONDITION_LIMIT} />
            </CounterWrapper>
            <StyledButton priority={'high'} onClick={commitSearchCondition} disabled={!canSearch}>
              検索
            </StyledButton>
          </ButtonContainer>
        </FlexWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled(Card)`
  width: 100%;
  margin-bottom: 32px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FlexContent = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 176px;
    height: 32px;
    padding: 0;
    align-self: end;
    margin-left: 8px;
    box-shadow: none;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const CounterWrapper = styled.div`
  margin-left: auto;
  margin-right: 8px;
`;

const StyledCounter = styled(Counter)`
  display: inline-block;
`;
