import React, { useCallback, useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SemanticToastContainer } from 'react-semantic-toasts';
import styled from 'styled-components';

import { Loading } from 'components/atoms/Loading';
import ChannelService from 'helpers/ChannelService';
import { useHoldOn } from 'hooks/useHoldOn';
import { useStorage } from 'hooks/useStorage';
import { AccountSingleton } from 'models/Domain/AccountList';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';

import { ChannelServiceButton } from './ChannelServiceButton';
import { LogoLoading } from './LogoLoading';
import MenuLayout from './MenuLayout';

export const App = React.memo((props) => {
  const location = useLocation();
  const { pathname, search } = location;
  const [organizationId, setOrganizationId] = useStorage('LATEST_LOGIN_ORGANIZATION_ID', 0, {
    changeDetection: false,
    isGlobal: true,
  });

  const dispatch = useDispatch();
  const signOut = useCallback(() => {
    // ログアウトしたら、シングルトンとLocalStorageの組織IDはリセットする
    AccountSingleton.getInstance().clear();
    if (organizationId) {
      setOrganizationId(0);
    }
    dispatch(AppActions.signOut(false)); // 同じメールアドレスのユーザーのセッションはそのまま
  }, [dispatch, organizationId, setOrganizationId]);

  const { currentUser, isLogoLoading, isLoading } = useSelector(
    (state: State) => ({
      currentUser: state.app.currentUser,
      isLogoLoading: state.app.isLogoLoading,
      isLoading: state.app.isLoading,
    }),
    shallowEqual,
  );

  // Mount時
  useEffect(() => {
    ChannelService.boot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pathname, searchが変更された時
  useEffect(() => {
    // ただし、店舗管理ページから遷移したときにスクロール位置を保持するために、
    // pathnameが/storeで、searchが空以外の場合はスクロールしない
    // FIXME: 他のページではスクロール位置を保持できているので、店舗管理ページでも別の方法で実装できそう
    if (pathname === '/store' && search !== '') {
      return;
    }

    // 写真一覧ページではスクロールタイミングを個別に制御
    if (pathname === Path.album.index) {
      return;
    }

    // 検索キーワード画面ではsearchが変更されてもページ上部にスクロールしない
    if (pathname === Path.searchKeywords.index) {
      return;
    }

    // 検索順位監視画面ではsearchが変更されてもページ上部にスクロールしない
    if (pathname === Path.mapSearchRank.index) {
      return;
    }

    // GBPパフォーマンス画面ではsearchが変更されてもページ上部にスクロールしない
    if (pathname === Path.gbp.performanceMA) {
      return;
    }

    // OMOインサイト画面ではsearchが変更されてもページ上部にスクロールしない
    if (pathname === Path.omo.insight) {
      return;
    }

    // pathnameが変更されたとき、ページ上部にスクロールする
    window.scrollTo(0, 0);
  }, [pathname, search]);

  const displayLogoLoading = useHoldOn(isLogoLoading, 1000);

  return (
    <>
      {currentUser.isExist ? (
        <>
          <MainLayoutWrapper isLogoLoading={displayLogoLoading}>
            <MenuLayout currentUser={currentUser} signOut={signOut}>
              {props.children}
            </MenuLayout>
          </MainLayoutWrapper>
        </>
      ) : (
        <>
          {props.children}
          <ChannelServiceButton />
        </>
      )}

      <Loading isLoading={isLoading} />
      <LogoLoading isLogoLoading={displayLogoLoading} />
      <SemanticToastContainer position='top-center' />
    </>
  );
});

const MainLayoutWrapper = styled.div<{ isLogoLoading: boolean }>`
  opacity: ${({ isLogoLoading }) => (isLogoLoading ? 0 : 1)};
`;

export const UnLoginWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px;
`;
