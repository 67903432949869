import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

type StickyFooterProps = {
  className?: string;
  children: React.ReactNode;
};

export const StickyFooter: React.FC<StickyFooterProps> = React.memo(({ className, children }) => {
  return (
    <>
      <MarginContent />
      <Footer className={className}>{children}</Footer>
    </>
  );
});

// ページの最後の要素とフッターがかぶらないようにする
const MarginContent = styled.div`
  height: 100px;
`;

const Footer = styled.div`
  position: sticky;
  background-color: ${COLOR.WHITE};
  bottom: 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 64px;
  box-shadow: 0px 0px 8px rgba(137, 126, 126, 0.25);
  border-radius: 2px;
  @media (max-width: 860px) {
    padding: 24px 18px;
    height: auto;
  }
`;
