import React from 'react';

import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StoreListState } from 'modules/storeList/reducers';
import { Path } from 'routes';

type Props = { moveTo: (v: string) => void; deleteStoreList: (v: number) => void } & Pick<StoreListState, 'storeLists'>;

export const ListTabContent = React.memo<Props>(({ storeLists, moveTo, deleteStoreList }) => {
  return (
    <Wrapper>
      <UserTable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>グループ名</Table.HeaderCell>
            <Table.HeaderCell>店舗数</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {storeLists.list.map((storeList) => {
            const { id, name, stores } = storeList;
            return (
              <TableBodyRow key={id} onClick={() => moveTo(Path.storeList.edit.replace(':storeListId', String(id)))}>
                <Table.Cell>
                  <CellContent>{name}</CellContent>
                </Table.Cell>

                <Table.Cell>
                  <CellContent>{stores.size}</CellContent>
                </Table.Cell>

                <Table.Cell collapsing>
                  <EditButton>編集</EditButton>
                </Table.Cell>

                <Table.Cell collapsing>
                  <DeleteButton
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteStoreList(id);
                    }}
                  >
                    削除
                  </DeleteButton>
                </Table.Cell>
              </TableBodyRow>
            );
          })}
        </Table.Body>
      </UserTable>
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const UserTable = styled(Table)`
  &&& {
    margin-top: 20px;
  }
`;

const TableBodyRow = styled(Table.Row)`
  &&& {
    cursor: pointer;
  }
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

const EditButton = styled(Button)`
  &&& {
    width: fit-content;
    padding: 10px 15px;
  }
`;

const DeleteButton = styled(Button).attrs({ negative: true })`
  &&& {
    width: fit-content;
    padding: 10px 15px;
  }
`;
