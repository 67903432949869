import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { List as ImmutableList, Set as ImmutableSet } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { Title as HeaderTitle, StickyHeader } from 'components/atoms/StickyHeader';
import { Tab, TabContainer } from 'components/atoms/Tab';
import { AnalysisMemoTable } from 'components/pageComponents/Analysis/AnalysisMemoTable';
import { GbpPerformanceCsvDownloadModal } from 'components/pageComponents/GbpPerformance/GbpPerformanceCsvDownloadModal';
import { GbpPerformanceGraph, GraphType } from 'components/pageComponents/GbpPerformance/GbpPerformanceGraph';
import { GbpPerformanceMonthlyTable } from 'components/pageComponents/GbpPerformance/GbpPerformanceMonthlyTable';
import { GbpPerformanceSearchCondition } from 'components/pageComponents/GbpPerformance/GbpPerformanceSearchCondition';
import { GbpPerformanceStatsComparisonTable } from 'components/pageComponents/GbpPerformance/GbpPerformanceStatsComparisonTable';
import { GbpPerformanceStoreTable } from 'components/pageComponents/GbpPerformance/GbpPerformanceStoreTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { ComparisonGraphType } from 'helpers/graph';
import { getPageTitle } from 'helpers/utils';
import { useDisplayType } from 'hooks/useDisplayType';
import { useStorage } from 'hooks/useStorage';
import {
  DisplayType,
  GbpPerformanceActiveStats,
  InteractionType,
  ReviewType,
  StatsType,
  displayTypeLabel,
} from 'models/Domain/GbpPerformance/GbpPerformance';
import { GbpPerformanceCsvDownloadCondition } from 'models/Domain/GbpPerformance/GbpPerformanceCsvDownloadCondition';
import { GbpPerformanceActions } from 'modules/gbpPerformance/actions';
import { assertNever } from 'types/Common';

import { useAnalysisMemos, useMemoDisplaySettings } from '../../hooks/useAnalysisMemos';

const displayTypes = [
  'overview',
  'impressions',
  'interactions',
  'activities',
] as const satisfies readonly DisplayType[];

// タブごとのグラフに表示する項目
const getAvailableDisplayStats = (displayType: DisplayType, reviewType: ReviewType = 'period'): StatsType[] => {
  switch (displayType) {
    case 'overview':
      return ['businessImpressions', 'interactions', 'imageCount', 'promotionCount', 'periodReviews'];
    case 'impressions':
      return [
        'businessImpressionsDesktopMaps',
        'businessImpressionsMobileMaps',
        'businessImpressionsDesktopSearch',
        'businessImpressionsMobileSearch',
        'interactions',
      ];
    case 'interactions':
      return [
        'businessImpressions',
        'callClicks',
        'businessDirectionRequests',
        'websiteClicks',
        'businessConversations',
        'businessBookings',
      ];
    case 'activities':
      return reviewType === 'total'
        ? [
            'promotionStandardCount',
            'promotionEventCount',
            'promotionOfferCount',
            'promotionAlertCount',
            'imageInteriorCount',
            'imageExteriorCount',
            'imageProductCount',
            'imageAdditionalCount',
            'totalReviewCommentCount',
            'totalReviewRateCount',
            'totalReviewReplyCount',
            'totalReviewAverageRating',
          ]
        : [
            'promotionStandardCount',
            'promotionEventCount',
            'promotionOfferCount',
            'promotionAlertCount',
            'imageInteriorCount',
            'imageExteriorCount',
            'imageProductCount',
            'imageAdditionalCount',
            'periodReviewCommentCount',
            'periodReviewRateCount',
            'periodReviewReplyCount',
            'periodReviewAverageRating',
          ];
    default:
      return assertNever(displayType);
  }
};

const displayGraphTypes = (displayType: DisplayType): GraphType[] => {
  switch (displayType) {
    case 'overview':
      return ['overview'];
    case 'impressions':
      return ['impressions'];
    case 'interactions':
      return ['interactions'];
    case 'activities':
      return ['images', 'promotions', 'reviews'];
    default:
      return assertNever(displayType);
  }
};

export const GbpPerformance = React.memo(() => {
  const {
    searchCondition,
    graphData,
    tableData,
    isInitializedSearchCondition,
    isLoadingGraphData,
    isLoadingTableData,
    isLoadingMonthlyData,
    monthlyData,
    csvDownloadCondition,
  } = useSelector((state) => state.gbpPerformance);
  const displayType = searchCondition.filter.displayType;

  const [interactionType, setInteractionType] = useState<InteractionType>('value');
  // クチコミの集計方法(localStorageに保持)
  const [reviewType, setReviewType] = useStorage<ReviewType>('GBP_PERFORMANCE_REVIEW_TYPE', 'period');
  const prevReviewType = useRef<ReviewType>(reviewType);
  const [activeStats, setActiveStatsTypes] = useState<GbpPerformanceActiveStats>(
    GbpPerformanceActiveStats.getDefaultByReviewType(reviewType),
  );

  const displayStats = useMemo(() => {
    return ImmutableList(getAvailableDisplayStats(displayType, reviewType))
      .filter((x) => activeStats.get(displayType).includes(x))
      .toArray();
  }, [activeStats, displayType, reviewType]);

  const isMobile = useDisplayType('1100px').isMobile;

  const dispatch = useDispatch();

  // CSVダウンロードモーダルの開閉状態
  const [isOpenCsvDownloadModal, setIsOpenCsvDownloadModal] = useState<boolean>(false);

  // 施策メモの表示設定
  const [memoDisplaySettings, setMemoDisplaySettings] = useMemoDisplaySettings('GBP_PERFORMANCE');
  const { memoList, memoTagList, createMemo, updateMemo, deleteMemo, isLoadingMemoList, isLoadingMemoTagList } =
    useAnalysisMemos();

  // 選択中の施策メモ
  const [selectingMemoIds, setSelectingMemoIds] = useState<ImmutableSet<number>>(ImmutableSet());

  const { stores } = useSelector((state) => state.store);
  const { initializePage, commitSearchCondition, downloadCsv, setCsvDownloadCondition } = useMemo(
    () => bindActionCreators(GbpPerformanceActions, dispatch),
    [dispatch],
  );
  /** CSVダウンロードモーダルを開く */
  const onClickOpenCsvDownloadModal = useCallback(() => {
    setCsvDownloadCondition(GbpPerformanceCsvDownloadCondition.fromSearchCondition(searchCondition));
    setIsOpenCsvDownloadModal(true);
  }, [searchCondition, setCsvDownloadCondition]);

  /** CSVダウンロードの開始 */
  const onStartCsvDownload = useCallback(() => {
    downloadCsv();
    setIsOpenCsvDownloadModal(false);
  }, [downloadCsv, setIsOpenCsvDownloadModal]);

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const onChangeDisplayType = useCallback(
    (displayType: DisplayType) => {
      commitSearchCondition(searchCondition.setIn(['filter', 'displayType'], displayType));
    },
    [commitSearchCondition, searchCondition],
  );

  const onChangeActiveStats = useCallback(
    (statsType: StatsType) => {
      setActiveStatsTypes((activeStats) => activeStats.change(displayType, statsType));
    },
    [displayType],
  );

  const onChangeInteractionType = useCallback((interactionType: InteractionType) => {
    setInteractionType(interactionType);
  }, []);

  const onChangeReviewType = useCallback(
    (reviewType: ReviewType) => {
      setReviewType(reviewType);
      // periodからtotalに変更した際に、periodReviewの設定を引き継ぐ
      if (prevReviewType.current === 'period' && reviewType === 'total') {
        setActiveStatsTypes((activeStats) => activeStats.replaceReviewType('period', 'total'));
      }
      // totalからperiodに変更した際に、totalReviewの設定を引き継ぐ
      if (prevReviewType.current === 'total' && reviewType === 'period') {
        setActiveStatsTypes((activeStats) => activeStats.replaceReviewType('total', 'period'));
      }
      prevReviewType.current = reviewType;
    },
    [setReviewType],
  );

  // 表示設定のタグでの絞り込みと、開始日終了日のフィルターを適用した施策メモのリスト
  const filteredMemoList = useMemo(() => {
    // タグで絞り込み
    let _filteredMemoList = memoList.filterByTags(memoDisplaySettings.tags.toSet());
    // 開始日終了日のフィルター
    // 集計期間と比較期間が一致している場合は、比較期間も含める
    const { startDate, endDate, aggregateUnit, comparisonStartDate, comparisonEndDate, isEnabledComparison } =
      searchCondition.filter;
    // 比較期間の終了日の翌日が集計期間の開始日の場合は結合できる
    const isCombined = isEnabledComparison && startDate.isSame(comparisonEndDate.add(1, 'day'));
    _filteredMemoList = _filteredMemoList.filterByDates(
      isCombined ? comparisonStartDate : startDate,
      endDate,
      aggregateUnit,
    );
    return _filteredMemoList;
  }, [searchCondition, memoList, memoDisplaySettings]);

  // 比較期間のグラフのタイプ
  const [comparisonGraphType, setComparisonGraphType] = useState<ComparisonGraphType>('combined');

  useEffect(() => {
    const { startDate, comparisonEndDate } = searchCondition.filter;
    // 対象期間と比較期間が連続していない場合は、比較期間のグラフの種類をseparatedに変更する
    if (comparisonGraphType !== 'combined') {
      return;
    } else if (startDate.isSame(comparisonEndDate.add(1, 'day'))) {
      return;
    } else {
      setComparisonGraphType('separated');
    }
  }, [comparisonGraphType, searchCondition.filter, setComparisonGraphType]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('GBPパフォーマンス')} />
      <StickyHeader>
        <HeaderTitle>GBPパフォーマンス</HeaderTitle>
      </StickyHeader>
      <WideBody>
        {isMobile && (
          <Alert type={'info'}>
            <Alert.Title>このページはPCでの利用を推奨しております</Alert.Title>
            <Alert.Section>
              <Alert.Content>
                スマートフォンやタブレットなど狭い画面では正しく表示されない場合がありますのでご了承ください。
              </Alert.Content>
            </Alert.Section>
          </Alert>
        )}
        <GbpPerformanceSearchCondition
          // URLから検索条件の初期化が完了してから検索条件を刷新するようにkeyを切り替える
          key={isInitializedSearchCondition ? 1 : 0}
          committedSearchCondition={searchCondition}
          onCommitSearchCondition={commitSearchCondition}
        />
        <DownloadButtonWrapper>
          <DownloadButton onClick={onClickOpenCsvDownloadModal}>CSVダウンロード</DownloadButton>
        </DownloadButtonWrapper>
        <TabContainer>
          {displayTypes.map((dt) => (
            <Tab key={dt} active={displayType === dt} onClick={() => onChangeDisplayType(dt)}>
              {displayTypeLabel[dt]}
            </Tab>
          ))}
        </TabContainer>
        {displayGraphTypes(displayType).map((graphType) => (
          <GbpPerformanceGraph
            key={graphType}
            isLoading={isLoadingGraphData}
            startDate={searchCondition.filter.startDate}
            endDate={searchCondition.filter.endDate}
            comparisonStartDate={searchCondition.filter.comparisonStartDate}
            comparisonEndDate={searchCondition.filter.comparisonEndDate}
            aggregateUnit={searchCondition.filter.aggregateUnit}
            isEnabledComparison={searchCondition.filter.isEnabledComparison}
            graphData={graphData}
            graphType={graphType}
            displayStats={displayStats}
            interactionType={interactionType}
            reviewType={reviewType}
            onChangeInteractionType={onChangeInteractionType}
            onChangeReviewType={onChangeReviewType}
            memoList={filteredMemoList}
            memoDisplaySettings={memoDisplaySettings}
            onClickMemo={(ids) => setSelectingMemoIds(ImmutableSet(ids))}
            comparisonGraphType={comparisonGraphType}
            setComparisonGraphType={setComparisonGraphType}
            storeIds={searchCondition.filter.storeIds}
            onChangeActiveStats={onChangeActiveStats}
          />
        ))}
        <AnalysisMemoTable
          memoList={filteredMemoList}
          activeMemoIds={selectingMemoIds}
          clearActiveMemoIds={() => setSelectingMemoIds(ImmutableSet())}
          tags={memoTagList}
          memoDisplaySettings={memoDisplaySettings}
          setMemoDisplaySettings={setMemoDisplaySettings}
          onCreateMemo={createMemo}
          onUpdateMemo={updateMemo}
          onDeleteMemo={deleteMemo}
          isLoadingMemoList={isLoadingMemoList}
          isLoadingMemoTagList={isLoadingMemoTagList}
          stickyPosition={'Tab'}
        />
        {searchCondition.filter.isEnabledComparison ? (
          <GbpPerformanceStatsComparisonTable
            data={tableData}
            displayType={displayType}
            searchCondition={searchCondition}
            isLoading={isLoadingTableData}
            onChangeDisplayType={onChangeDisplayType}
            activeStats={activeStats[displayType]}
            onChangeActiveStats={onChangeActiveStats}
            interactionType={interactionType}
            reviewType={reviewType}
          />
        ) : (
          <GbpPerformanceMonthlyTable
            isLoading={isLoadingMonthlyData}
            data={monthlyData}
            displayType={displayType}
            onChangeDisplayType={onChangeDisplayType}
            activeStats={activeStats[displayType]}
            onChangeActiveStats={onChangeActiveStats}
            interactionType={interactionType}
            reviewType={reviewType}
          />
        )}
        <GbpPerformanceStoreTableWrapper>
          <GbpPerformanceStoreTable
            data={tableData}
            stores={stores}
            displayType={displayType}
            searchCondition={searchCondition}
            isLoading={isLoadingTableData}
            reviewType={reviewType}
            onChangeReviewType={onChangeReviewType}
          />
        </GbpPerformanceStoreTableWrapper>
      </WideBody>
      <GbpPerformanceCsvDownloadModal
        isOpen={isOpenCsvDownloadModal}
        onClose={() => setIsOpenCsvDownloadModal(false)}
        condition={csvDownloadCondition}
        setCondition={setCsvDownloadCondition}
        onStartCsvDownload={onStartCsvDownload}
      />
    </MainWrapper>
  );
});

const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const DownloadButton = styled(Button).attrs({ priority: 'low' })``;

const GbpPerformanceStoreTableWrapper = styled.div`
  &&& {
    thead {
      top: 142px;
    }
  }
`;
