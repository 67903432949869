import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Set as ImmutableSet } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  PromotionApi,
  PromotionCsvDownloadApi,
  PromotionCsvStatusCheckApi,
  PromotionListApi,
  PromotionSummaryDetailApi,
  PromotionTranslateApi,
} from 'ApiClient/PromotionApi';
import { showEditMenu } from 'components/pages/PromotionDetail';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import Promotion, { Media, PostStatus } from 'models/Domain/Promotion/Promotion';
import { PostStatuses, PromotionGroup, PromotionTranslationData } from 'models/Domain/Promotion/PromotionGroup';
import PromotionList from 'models/Domain/Promotion/PromotionList';
import { PromotionSearchCondition } from 'models/Domain/Promotion/PromotionSearchCondition';
import { Stores } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { onDropAccepted } from 'modules/app/fileUpload';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { PromotionActions } from './actions';

export default function* saga() {
  yield takeLatest(PromotionActions.getPromotion, getPromotion);
  yield takeLatest(PromotionActions.getPromotionForEdit, getPromotionForEdit);
  yield takeLatest(PromotionActions.getBasePromotion, getBasePromotion);
  yield takeLatest(PromotionActions.uploadFile, uploadFile);
  yield takeLatest(PromotionActions.createPromotion, createPromotion);
  yield takeLatest(PromotionActions.updatePromotion, updatePromotion);
  yield takeLatest(PromotionActions.deletePromotion, deletePromotion);
  yield takeLatest(PromotionActions.fetchPromotionList, fetchPromotionList);
  yield takeLatest(PromotionActions.initializePromotionListPage, initializePromotionListPage);
  yield takeLatest(LOCATION_CHANGE, promotionListLocationChange);
  yield takeLatest(PromotionActions.setSearchCondition, setSearchCondition);
  yield takeLatest(PromotionActions.getCsvDownload, getCsvDownload);
  yield takeLatest(PromotionActions.checkCsvDownloadStatus, checkCsvDownloadStatus);
  yield takeEvery(PromotionActions.translatePost, translatePost);
}

// 投稿一覧ページを初期化する
function* initializePromotionListPage(action: ReturnType<typeof PromotionActions.initializePromotionListPage>) {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // URLパラメータから検索条件を復元する
  yield put(PromotionActions.setIsPreparedForPromotionListPage(true));
  yield call(updatePromotionListByLocation);
}

export function* getPromotion(action: ReturnType<typeof PromotionActions.getPromotion>) {
  yield put(PromotionActions.setIsLoadingPromotion(true));
  // 投稿グループを初期化
  yield put(PromotionActions.setPromotionGroup(new PromotionGroup()));
  const response: YieldReturn<typeof PromotionApi.get> = yield PromotionApi.get(action.payload);

  if (response.isSuccess) {
    const promotion = new Promotion(response.data);
    const currentUser: User = yield select((state) => state.app.currentUser);
    if (promotion.isDraft && promotion._id && showEditMenu(currentUser, promotion)) {
      // 下書きかつ編集権限がある場合は、編集画面にリダイレクトする
      yield put(replaceWithOrganizationId(Path.localpost.edit.replace(':id', promotion._id)));
      return;
    }

    // 親投稿が存在する場合は、親投稿にリダイレクトする
    const parent = promotion.translations?.parent;
    if (parent) {
      yield put(replaceWithOrganizationId(Path.localpost.detail.replace(':id', parent)));
      return;
    }

    let promotionGroup = new PromotionGroup({ promotion });
    yield put(PromotionActions.setPromotionGroup(promotionGroup));

    // 子投稿がある場合、子投稿を取得して追加する
    const childPosts = promotion.translations?.children?.toArray() ?? [];
    if (childPosts.length > 0) {
      for (const childPost of childPosts) {
        // idが取得できなければスキップ
        if (!childPost.id) {
          continue;
        }
        const childResponse: YieldReturn<typeof PromotionApi.get> = yield PromotionApi.get(childPost.id);
        if (childResponse.isSuccess) {
          const childPromotionData = PromotionTranslationData.fromPromotionJSON(childResponse.data);
          promotionGroup = promotionGroup.addChild(childPromotionData);
        } else {
          toast({
            type: 'error',
            title: '投稿の取得に失敗しました',
            description: String(childResponse.error.message),
            time: 10000,
          });

          return;
        }
      }
      yield put(PromotionActions.setPromotionGroup(promotionGroup));
    }

    // 投稿が取得できたところで表示するため、loadingはfalseにする
    yield put(PromotionActions.setIsLoadingPromotion(false));

    // 投稿ステータスを取得する
    const statuses: PostStatus[] = [];
    for (const postId of promotionGroup.postIds.toArray()) {
      const summaryResponse: YieldReturn<typeof PromotionSummaryDetailApi.get> =
        yield PromotionSummaryDetailApi.get(postId);

      if (summaryResponse.isSuccess) {
        statuses.push(...summaryResponse.data);
      } else {
        toast({
          type: 'error',
          title: '投稿ステータスの取得に失敗しました',
          description: String(summaryResponse.error.message),
          time: 10000,
        });
        return;
      }
    }
    promotionGroup = promotionGroup.setStatuses(PostStatuses.fromPostStatusList(statuses));
    yield put(PromotionActions.setPromotionGroup(promotionGroup));
  } else {
    if (response.status !== 403 && response.status !== 404) {
      toast({
        type: 'error',
        title: '投稿の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
    // 詳細ページを表示できないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.localpost.index));
  }
  yield put(PromotionActions.setIsLoadingPromotion(false));
}

export function* getPromotionForEdit(action: ReturnType<typeof PromotionActions.getPromotionForEdit>) {
  yield put(PromotionActions.setIsLoadingPromotion(true));
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const response: YieldReturn<typeof PromotionApi.get> = yield PromotionApi.get(action.payload);

  if (response.isSuccess) {
    const promotion = new Promotion(response.data);
    const currentUser: User = yield select((state) => state.app.currentUser);
    // 編集権限がないなら、詳細画面に遷移する
    if (!showEditMenu(currentUser, promotion) && promotion._id) {
      yield put(replaceWithOrganizationId(Path.localpost.detail.replace(':id', promotion._id)));
      return;
    }

    // 親投稿が存在する場合は、親投稿にリダイレクトする
    const parent = promotion.translations?.parent;
    if (parent) {
      yield put(replaceWithOrganizationId(Path.localpost.edit.replace(':id', parent)));
      return;
    }

    let promotionGroup = new PromotionGroup({ promotion });

    // 子投稿がある場合、子投稿の内容を取得して追加する
    const childPosts = promotion.translations?.children?.toArray() ?? [];
    if (childPosts.length > 0) {
      for (const childPost of childPosts) {
        // idが取得できないか、設定で有効な言語でない場合はスキップ
        if (!childPost.id || !currentUser.organization?.canUseLocalPostTranslationLanguage(childPost.language)) {
          continue;
        }
        const childResponse: YieldReturn<typeof PromotionApi.get> = yield PromotionApi.get(childPost.id);
        if (childResponse.isSuccess) {
          const childPromotionData = PromotionTranslationData.fromPromotionJSON(childResponse.data);
          promotionGroup = promotionGroup.addChild(childPromotionData);
        } else {
          toast({
            type: 'error',
            title: '投稿の取得に失敗しました',
            description: String(childResponse.error.message),
            time: 10000,
          });
        }
      }
    }

    yield put(PromotionActions.setPromotionGroupForEdit(promotionGroup));
    yield put(PromotionActions.setMediaForEdit(promotion.media));
    yield put(PromotionActions.setIsLoadingPromotion(false));
  } else {
    if (response.status !== 403 && response.status !== 404) {
      toast({
        type: 'error',
        title: '投稿の取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
    // 編集できないので、一覧ページに遷移する
    yield put(replaceWithOrganizationId(Path.localpost.index));
  }
  yield put(PromotionActions.setIsLoadingPromotion(false));
}

/** 複製対象の投稿データを取得 */
export function* getBasePromotion(action: ReturnType<typeof PromotionActions.getBasePromotion>) {
  const basePromotionId = action.payload;
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);
  yield put(PromotionActions.setIsLoadingPromotion(true));
  const response: YieldReturn<typeof PromotionApi.get> = yield PromotionApi.get(basePromotionId);

  if (response.isSuccess) {
    // ベースとして取得した投稿情報からIDと投稿日時 (posted_at) を削除する
    const promotion = new Promotion({
      ...response.data,
      _id: null,
      posted_at: null,
      // is_scheduledがfalseの場合、scheduled_atはnullを設定する
      scheduled_at: response.data.is_scheduled ? response.data.scheduled_at : null,
    });
    const currentUser: User = yield select((state) => state.app.currentUser);

    let promotionGroup = new PromotionGroup({ promotion });

    // 子投稿がある場合、子投稿を取得して追加する
    const childPosts = promotion.translations?.children?.toArray() ?? [];
    if (childPosts.length > 0) {
      for (const childPost of childPosts) {
        // idが取得できないか、設定で有効な言語でない場合はスキップ
        if (!childPost.id || !currentUser.organization?.canUseLocalPostTranslationLanguage(childPost.language)) {
          continue;
        }
        const childResponse: YieldReturn<typeof PromotionApi.get> = yield PromotionApi.get(childPost.id);
        if (childResponse.isSuccess) {
          const childPromotionData = PromotionTranslationData.fromPromotionJSON(childResponse.data);
          promotionGroup = promotionGroup.addChild(childPromotionData);
        } else {
          toast({
            type: 'error',
            title: '投稿の取得に失敗しました',
            description: String(childResponse.error.message),
            time: 10000,
          });

          return;
        }
      }
    }
    yield put(PromotionActions.setPromotionGroupForEdit(promotionGroup));
    yield put(PromotionActions.setMediaForEdit(promotion.media));
    yield put(PromotionActions.setIsLoadingPromotion(false));
  } else {
    toast({
      type: 'error',
      title: '投稿の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    // ベースの投稿の取得に失敗したら、空の新規作成画面になる
    yield put(PromotionActions.setIsLoadingPromotion(false));
  }
}

function* uploadFile(action: ReturnType<typeof PromotionActions.uploadFile>) {
  const files = action.payload;
  const urls: string[] = yield call(onDropAccepted, files);
  const media = Media.fromJSON(urls.map((url: string) => ({ source_url: url, media_format: 'PHOTO' })));
  yield put(PromotionActions.setMediaForEdit(media));
}

export function* createPromotion(action: ReturnType<typeof PromotionActions.createPromotion>) {
  yield put(AppActions.setLoading(true));

  const mediaForEdit: Media = yield select((state: State) => state.promotion.mediaForEdit);
  const stores: Stores = yield select((state: State) => state.store.stores);

  const createPromotion =
    !mediaForEdit.isEmpty() && action.payload.topic_type !== 'ALERT'
      ? action.payload.changeMedia(mediaForEdit)
      : action.payload;

  const promotionGroup: PromotionGroup = yield select((state: State) => state.promotion.promotionGroupForEdit);

  const params = {
    promotions: createPromotion.requestParams(stores),
    translations: promotionGroup.translationParams(),
  };

  const response: YieldReturn<typeof PromotionApi.post> = yield PromotionApi.post(params);

  yield put(AppActions.setLoading(false));
  if (response.isSuccess) {
    yield put(PromotionActions.fetchPromotionList());
    yield put(PromotionActions.clearMediaForEdit());
    yield put(AppActions.moveTo(Path.localpost.index));
    toast({
      type: 'success',
      title: `${createPromotion.isDraft ? '下書き保存' : '登録'}が完了しました`,
    });
  } else {
    toast({
      type: 'error',
      title: `${createPromotion.isDraft ? '下書き保存' : '登録'}に失敗しました`,
      description: String(response.error.message),
      time: 10000,
    });
  }
}

export function* updatePromotion(action: ReturnType<typeof PromotionActions.updatePromotion>) {
  yield put(AppActions.setLoading(true));

  const mediaForEdit: Media = yield select((state: State) => state.promotion.mediaForEdit);
  const stores: Stores = yield select((state: State) => state.store.stores);

  const promotion =
    !mediaForEdit.isEmpty() && action.payload.topic_type !== 'ALERT'
      ? action.payload.changeMedia(mediaForEdit)
      : action.payload;

  const id = promotion._id;
  if (!id) {
    toast({
      type: 'error',
      title: `${promotion.isDraft ? '下書き保存' : '登録'}に失敗しました`,
      time: 10000,
    });
    yield put(AppActions.setLoading(false));
    return;
  }

  const promotionGroup: PromotionGroup = yield select((state: State) => state.promotion.promotionGroupForEdit);

  const params = {
    promotions: promotion.requestParams(stores),
    translations: promotionGroup.translationParams(),
  };

  const response: YieldReturn<typeof PromotionApi.batchUpdate> = yield PromotionApi.batchUpdate(id, params);

  yield put(AppActions.setLoading(false));

  if (response.isSuccess) {
    yield put(PromotionActions.fetchPromotionList());
    yield put(AppActions.moveTo(Path.localpost.detail.replace(':id', action.payload._id ?? '')));
    toast({
      type: 'success',
      title: `${promotion.isDraft ? '下書き保存' : '更新'}が完了しました`,
    });
  } else {
    toast({
      type: 'error',
      title: `${promotion.isDraft ? '下書き保存' : '登録'}に失敗しました`,
      description: String(response.error.message),
      time: 10000,
    });
  }
}

export function* deletePromotion(action: ReturnType<typeof PromotionActions.deletePromotion>) {
  yield put(AppActions.setLoading(true));

  if (!action.payload) return;

  const { id, search = '' } = action.payload;

  const response: YieldReturn<typeof PromotionApi.delete> = yield PromotionApi.delete(id);

  yield put(AppActions.setLoading(false));
  if (response.isSuccess) {
    yield put(PromotionActions.fetchPromotionList());
    // 移動先のパスの指定がある場合は、指定されたパスへ遷移
    yield put(AppActions.moveTo(`${Path.localpost.index}${search}`));
    toast({
      type: 'success',
      title: '削除が完了しました',
    });
  } else {
    toast({
      type: 'error',
      title: '削除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* fetchPromotionList(action: ReturnType<typeof PromotionActions.fetchPromotionList>) {
  yield put(PromotionActions.setIsLoadingPromotionList(true));
  const searchCondition: PromotionSearchCondition = yield select(
    (state: State) => state.promotion.promotionSearchCondition,
  );
  const response: YieldReturn<typeof PromotionListApi.get> = yield PromotionListApi.get(
    searchCondition.toRequestParams(),
  );
  if (response.isSuccess) {
    yield put(PromotionActions.setCommittedSearchCondition(searchCondition));
    const promotionList = new PromotionList(response.data);
    yield put(PromotionActions.setPromotionList(promotionList));
  } else {
    toast({
      type: 'error',
      title: '投稿一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(PromotionActions.setIsLoadingPromotionList(false));
}

/**
 * URLから投稿一覧を更新する
 *
 * 本処理は、以下の２処理から呼ばれる
 * - initializeReviewListPage
 * - promotionListLocationChange
 */
function* updatePromotionListByLocation() {
  const location: Location = yield select((state: RouterRootState) => state.router.location);

  // URLパラメータから検索条件を復元する
  let searchCondition = PromotionSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.params.store });
  searchCondition = searchCondition.setIn(['params', 'store'], group);

  const availableStoreIds: ImmutableSet<number> = yield call(getInitialStores, {
    group: searchCondition.params.store,
    containsClosedStores: searchCondition.params.show_closed_store,
  });

  const currentStoreIds = searchCondition.params.store_ids;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('params', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('params', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  // URLと検索条件から生成されるURLが異なる場合（不要なパラメータがある場合など）、正しいURLにreplace
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // 検索条件のセットと、投稿一覧の取得
  yield put(PromotionActions.setSearchCondition({ searchCondition, updateLocation: false }));
  yield put(PromotionActions.fetchPromotionList());
}

// 投稿一覧ページにおけるURL（パラメータ）変更時の処理
function* promotionListLocationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // パスが投稿一覧ページ以外の場合は対象外
  if (location.pathname !== Path.localpost.index) {
    return;
  }

  // 投稿一覧ページの初期処理が完了していない場合は何もしない
  const isPreparedForPromotionListPage: boolean = yield select(
    (state) => state.promotion.isPreparedForPromotionListPage,
  );
  if (!isPreparedForPromotionListPage) {
    return;
  }

  yield call(updatePromotionListByLocation);
}

function* setSearchCondition(action: ReturnType<typeof PromotionActions.setSearchCondition>) {
  const { searchCondition, updateLocation = true } = action.payload;

  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // updateLocationがtrueの場合、かつ既存とURLに差分がある場合、URLの更新を行う
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
  }
}

function* getCsvDownload(action: ReturnType<typeof PromotionActions.getCsvDownload>) {
  yield put(AppActions.setLoading(true));

  const response: YieldReturn<typeof PromotionCsvDownloadApi.post> = yield PromotionCsvDownloadApi.post(action.payload);

  if (response.isSuccess) {
    const csvDownload: ExecutionArnStatus = yield select((state) => state.promotion.csvDownload);
    yield put(PromotionActions.setCsvDownload(csvDownload.setExecutionArn(response.data.executionArn)));
  } else {
    yield put(AppActions.setLoading(false));
    toast({
      type: 'error',
      title: 'ファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* checkCsvDownloadStatus(action: ReturnType<typeof PromotionActions.checkCsvDownloadStatus>) {
  const csvDownload: ExecutionArnStatus = yield select((state) => state.promotion.csvDownload);

  if (csvDownload.executionArn) {
    const response: YieldReturn<typeof PromotionCsvStatusCheckApi.post> = yield PromotionCsvStatusCheckApi.post({
      executionArn: csvDownload.executionArn,
    });

    if (response.isSuccess) {
      if (response.data.status === 'RUNNING') {
        // do nothing
      } else if (response.data.status === 'SUCCEEDED') {
        const downloadUrl = response.data.download_url;

        if (downloadUrl) {
          window.location.assign(downloadUrl);
          toast({
            type: 'success',
            title: 'ファイルをダウンロードしました',
          });
        } else {
          toast({
            type: 'info',
            title: String(response.data.message),
            time: 10000,
          });
        }
        yield put(PromotionActions.clearCsvDownload());
        yield clearInterval(action.payload);
        yield put(PromotionActions.setIsOpenDownloadModal(false));
        yield put(AppActions.setLoading(false));
      } else {
        yield put(AppActions.setLoading(false));
        yield put(PromotionActions.clearCsvDownload());
        toast({
          type: 'error',
          title: String(response.data.message),
          description: '',
          time: 10000,
        });
        yield clearInterval(action.payload);
        yield put(AppActions.setLoading(false));
      }
    } else {
      yield put(AppActions.setLoading(false));
      toast({
        type: 'error',
        title: 'ファイルのダウンロードに失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
  }
}

function* translatePost(action: ReturnType<typeof PromotionActions.translatePost>) {
  const { language, promotion } = action.payload;
  // 翻訳中の言語の子投稿は読込中としておき手動で編集できなくする
  yield put(PromotionActions.setLoadingLanguages({ language, isLoading: true }));
  const response: YieldReturn<typeof PromotionTranslateApi.post> = yield PromotionTranslateApi.post({
    title: promotion.event?.title ?? undefined,
    body: promotion.body,
    language: language,
  });
  if (response.isSuccess) {
    const { title, body } = response.data;
    // 対象言語の子投稿を取得し、存在するならば投稿内容を更新する
    const promotionGroup: PromotionGroup = yield select((state: State) => state.promotion.promotionGroupForEdit);
    let childPost = promotionGroup.findChild(language);
    if (childPost) {
      childPost = childPost.changeValue('title', title).changeValue('body', body);
      yield put(PromotionActions.setPromotionGroupForEdit(promotionGroup.setChild(language, childPost)));
    }
  } else {
    toast({
      type: 'error',
      title: '投稿の翻訳に失敗しました',
      description: String(response.error),
      time: 3000,
    });
  }
  yield put(PromotionActions.setLoadingLanguages({ language, isLoading: false }));
}
