import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';
export class GmbAttributeMetadata extends Record<{
  attributeId: string;
  displayName: string;
  groupDisplayName: string;
  valueMetadata: { value: string | boolean; displayName: string }[];
  valueType: 'BOOL' | 'ENUM' | 'REPEATED_ENUM' | 'URL';
  isDeprecated: boolean;
  isRepeatable?: boolean;
}>({
  attributeId: '',
  displayName: '',
  groupDisplayName: '',
  valueMetadata: [],
  valueType: 'BOOL',
  isRepeatable: false,
  isDeprecated: false,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  get isBool() {
    return this.valueType === 'BOOL';
  }

  get isEnum() {
    return this.valueType === 'ENUM';
  }

  get isRepeatedEnum() {
    return this.valueType === 'REPEATED_ENUM';
  }

  get isUrl() {
    return this.valueType === 'URL';
  }

  /**
   * value に一致した valueMetadata を返す
   * @param value 属性値
   */
  findValueMetadata(value: string | boolean) {
    return this.valueMetadata.find((metadata) => metadata.value === value);
  }
}

export class GmbAttributeMetadatas extends Record<{
  list: List<GmbAttributeMetadata>;
}>({
  list: List(),
}) {
  constructor(data: JSObject[] = []) {
    const list = List(data && data.map((p) => new GmbAttributeMetadata(p)));
    super({ list });
  }

  setList(list: List<GmbAttributeMetadata>) {
    return this.set('list', list);
  }

  find(attributeId: string) {
    return this.list.find((attributeMetadata) => attributeMetadata.attributeId === attributeId);
  }

  add(attributeMetadata: GmbAttributeMetadata) {
    return this.update('list', (list) => list.push(attributeMetadata));
  }

  /**
   * 非推奨な属性とvalueTypeがURLのものを除く属性のメタデータのリストを取得する
   * @returns
   */
  getListWithoutIsDeprecatedAndUrl() {
    return this.list
      .filter((attributeMetadata) => !attributeMetadata.isDeprecated)
      .filter((attributeMetadata) => attributeMetadata.valueType !== 'URL');
  }

  /**
   * 非推奨な属性と、valueTypeがURL以外を除く属性のメタデータのリストを取得する
   * @returns
   */
  getListWithoutIsDeprecatedOtherThanUrl() {
    return this.list
      .filter((attributeMetadata) => !attributeMetadata.isDeprecated)
      .filter((attributeMetadata) => attributeMetadata.valueType === 'URL');
  }

  /**
   * 属性メタデータをvalueTypeがURLのものを除外した上でグループ名でグルーピングする
   *
   * @returns グループ化された属性メタデータのリスト
   */
  groupByRemovedUrlGroupDisplayName() {
    return this.getListWithoutIsDeprecatedAndUrl().groupBy((attribute) => attribute.groupDisplayName);
  }

  /**
   * 属性メタデータをvalueTypeがURLのもののみグループ名でグルーピングする
   *
   * 属性名(ex. 予約リンク)をグループ名に設定した上でグルーピングする。
   * @returns グループ化された属性メタデータのリスト
   */
  groupByUrlGroupDisplayName() {
    return this.getListWithoutIsDeprecatedOtherThanUrl()
      .map((attributeMetadata) => attributeMetadata.set('groupDisplayName', attributeMetadata.displayName))
      .groupBy((attribute) => attribute.groupDisplayName);
  }

  /**
   * 属性(URL属性を除く)が存在するか
   */
  hasAttribute() {
    return !!this.list.find((attributeMetadata) => attributeMetadata.valueType !== 'URL');
  }

  /**
   * URL属性が存在するか
   */
  hasUrlAttribute() {
    return !!this.list.find((attributeMetadata) => attributeMetadata.valueType === 'URL');
  }

  /**
   * 属性IDの一覧を取得する
   */
  getAttributeIds() {
    return this.list.map((attribute) => attribute.attributeId);
  }
}
