import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Link } from 'components/atoms/Link';
import { MenuSummaryItem } from 'models/Domain/Menu/Menu';
import { Path } from 'routes';

/** Googleビジネスプロフィール反映漏れのメニューグループの反映をユーザに確認を促す */
export const GbpMenuSyncAlert: React.FC<{
  menuSummaryItemsNotSynced: List<MenuSummaryItem>;
  children: React.ReactNode;
}> = ({ menuSummaryItemsNotSynced, children }) => {
  return (
    <Alert type='caution'>
      <Alert.Title>GBPに反映していないメニューグループがあります</Alert.Title>
      <Alert.Section>
        <Alert.Header>
          {menuSummaryItemsNotSynced.size}
          件のメニューグループがGBPに未反映です。
        </Alert.Header>
        <Alert.Header>編集をGBPに反映していないメニューグループ</Alert.Header>
        {menuSummaryItemsNotSynced.map((item) => (
          <Alert.Content key={item.id}>
            <Content>
              {/* 削除済みのメニューグループがGBP未反映の場合、リンクさせず、削除済みであることを表示 */}
              {item.isDeleted === false ? (
                <>
                  ・<DetailLink to={Path.menu.edit.replace(':id', `${item.id}`)}>{item.name}</DetailLink>
                </>
              ) : (
                <>・{item.name}（削除済み）</>
              )}
            </Content>
          </Alert.Content>
        ))}
        <ChildrenContent>{children}</ChildrenContent>
      </Alert.Section>
    </Alert>
  );
};

const DetailLink = styled(Link)`
  text-decoration: underline;
`;

const Content = styled.span`
  & + & {
    margin-left: 8px;
  }
`;

const ChildrenContent = styled.div`
  margin-top: 10px;
`;
