import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import { MemoIconData } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { PERFORMANCE_PROGRAMS, PerformanceMetric, PerformanceProgram } from 'models/Domain/OmoInsight';
import { PerformanceGraphItem } from 'models/Domain/OmoInsight/OmoInsightGraphData';

import { OmoInsightPerformanceGraph as Graph } from './Graph';

type OmoInsightPerformanceGraphProps = {
  metric: PerformanceMetric | null;
  graphData: PerformanceGraphItem[];
  showComparisonGraph: boolean;
  onChangeActiveKeys?: (dataKey: PerformanceProgram) => void;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
};

export const OmoInsightPerformanceGraph = React.memo<OmoInsightPerformanceGraphProps>(
  ({ metric, graphData, showComparisonGraph, memoData, onClickMemo, onChangeActiveKeys: onChangeActiveStats }) => {
    // ダウンロードモーダル関連
    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

    // 設定モーダル関連
    const [isOpenSettingsModal, setIsOpenSettingsModal] = useState<boolean>(false);
    const [graphSettings, setGraphSettings] = useState(new GraphSettings({ min: 'auto', max: 'auto' }));

    const dataKeys = useMemo(
      () =>
        (graphData.length > 0
          ? Object.keys(graphData[0]).filter((key) => PERFORMANCE_PROGRAMS.includes(key as PerformanceProgram))
          : []) as PerformanceProgram[],
      [graphData],
    );

    const [displayDataKeys, setDisplayDataKeys] = useState<ImmutableSet<PerformanceProgram>>(ImmutableSet(dataKeys));
    const toggleDataKey = useCallback((dataKey: PerformanceProgram) => {
      setDisplayDataKeys((prev) => {
        if (prev.has(dataKey)) {
          return prev.delete(dataKey);
        } else {
          return prev.add(dataKey);
        }
      });
    }, []);

    useEffect(() => {
      setDisplayDataKeys(ImmutableSet(dataKeys));
    }, [dataKeys]);

    return (
      <Wrapper>
        <Graph
          metric={metric}
          graphData={graphData}
          showComparisonGraph={showComparisonGraph}
          showInactiveLegend={true}
          graphSettings={graphSettings}
          memoData={memoData}
          onClickMemo={onClickMemo}
          dataKeys={dataKeys}
          displayDataKeys={displayDataKeys}
          toggleDataKey={toggleDataKey}
        />
        <ButtonWrapper>
          <StyledButton onClick={() => setIsOpenSettingsModal(true)}>グラフの表示設定</StyledButton>
          <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
        </ButtonWrapper>
        <GraphDownloadModal
          isOpen={isOpenDownloadModal}
          fileName={'OmoInsightPerformance'}
          onClose={() => setIsOpenDownloadModal(false)}
        >
          <Graph
            metric={metric}
            graphData={graphData}
            showComparisonGraph={showComparisonGraph}
            showInactiveLegend={false}
            graphSettings={graphSettings}
            displayDataKeys={displayDataKeys}
            dataKeys={dataKeys}
          />
        </GraphDownloadModal>
        {isOpenSettingsModal && (
          <GraphSettingsModal
            isOpen={isOpenSettingsModal}
            settings={graphSettings}
            onClose={() => setIsOpenSettingsModal(false)}
            onChange={setGraphSettings}
          />
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`;

export const StyledButton = styled(Button).attrs({ priority: 'low' })`
  font-size: 16px;
`;
