import React, { useMemo, useState } from 'react';

import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { PullDown } from 'components/atoms/PullDown';
import { getCategoryOptions } from 'models/Domain/Image/Image';

export const CategorySelectModal = React.memo<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
  canUseProductCategory: boolean;
}>(({ isOpen, onClose, onSubmit, canUseProductCategory }) => {
  const [value, setValue] = useState('INTERIOR');

  const options = useMemo(() => getCategoryOptions({ canUseProduct: canUseProductCategory }), [canUseProductCategory]);

  return (
    <Modal size='tiny' open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>カテゴリーの選択</Modal.Header>
      <Modal.Content>
        <ContentTitle>カテゴリー</ContentTitle>
        <PullDown value={value} options={options} onChange={(v) => setValue(v)} placeholder={'カテゴリー'} />
      </Modal.Content>
      <Buttons>
        <ButtonWrapper>
          <CloseButton onClick={() => onClose()}>キャンセル</CloseButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <ApplyButton priority='high' onClick={() => onSubmit(value)}>
            適用
          </ApplyButton>
        </ButtonWrapper>
      </Buttons>
    </Modal>
  );
});

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 24px 24px;

  @media (max-width: 600px) {
    margin: 8px 16px 16px;
  }
`;

const ButtonWrapper = styled.div`
  width: calc(50% - 8px);
`;

const ButtonCommonStyle = css`
  width: 100%;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ContentTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;
