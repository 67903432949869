import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { Tab, TabContainer } from 'components/atoms/Tab';
import { AnalysisMemoTable } from 'components/pageComponents/Analysis/AnalysisMemoTable';
import { MapSearchRankCompetitorAverageGraph } from 'components/pageComponents/MapSearchRank/MapSearchRankCompetitorAverage';
import { MapSearchRankCompetitorAverageTable } from 'components/pageComponents/MapSearchRank/MapSearchRankCompetitorAverageTable';
import { MapSearchRankCompetitorModal } from 'components/pageComponents/MapSearchRank/MapSearchRankCompetitorModal';
import { MapSearchRankFooter } from 'components/pageComponents/MapSearchRank/MapSearchRankFooter';
import { MapSearchRankGraph } from 'components/pageComponents/MapSearchRank/MapSearchRankGraph';
import { MapSearchRankSearchCondition } from 'components/pageComponents/MapSearchRank/MapSearchRankSearchCondition';
import { MapSearchRankTable } from 'components/pageComponents/MapSearchRank/MapSearchRankTable';
import { MapSearchRankTableSortConditionModal } from 'components/pageComponents/MapSearchRank/MapSearchRankTableSortConditionModal';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { useAnalysisMemos, useMemoDisplaySettings } from 'hooks/useAnalysisMemos';
import { MapSearchRankTableItem } from 'models/Domain/MapSearchRank/MapSearchRank';
import {
  MapSearchRankSearchCondition as SearchCondition,
  SortKey,
} from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { MapSearchRankActions } from 'modules/mapSearchRank/action';
import { Path } from 'routes';

export const MapSearchRank = React.memo(() => {
  const [selectedTableItem, setSelectedTableItem] = useState<MapSearchRankTableItem | null>(null);

  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.app);
  const {
    searchCondition,
    isInitializedSearchCondition,
    graphData,
    tableData,
    competitorAverageData,
    isSelectedAverage,
    selectedConfigIds,
    selectedTags,
    isLoadingTableData,
    isLoadingGraphData,
  } = useSelector((state) => state.mapSearchRank);
  const {
    commitSearchCondition,
    initializePage,
    updateSelectedTableData,
    setSelectedTags,
    setNeedsUpdateCompetitorAverageData,
  } = useMemo(() => bindActionCreators(MapSearchRankActions, dispatch), [dispatch]);

  // 施策メモの表示設定
  const [memoDisplaySettings, setMemoDisplaySettings] = useMemoDisplaySettings('MAP_SEARCH_RANK');

  const { memoList, memoTagList, createMemo, updateMemo, deleteMemo, isLoadingMemoList, isLoadingMemoTagList } =
    useAnalysisMemos();

  // 選択中の施策メモ
  const [selectingMemoIds, setSelectingMemoIds] = useState<Set<number>>(Set());
  // 並べ替え設定モーダルの開閉状態
  const [isOpenSortConditionModal, setIsOpenSortConditionModal] = useState<boolean>(false);

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const handleOnCommitSearchCondition = useCallback(
    (condition: SearchCondition, pageReset = true) => {
      const newCondition = pageReset ? condition.setIn(['pagination', 'page'], 1) : condition;
      commitSearchCondition(newCondition);
    },
    [commitSearchCondition],
  );

  const handleOnChangeSearchCondition = useCallback(
    (searchCondition: SearchCondition) => {
      setNeedsUpdateCompetitorAverageData(true);
      handleOnCommitSearchCondition(searchCondition);
      setIsOpenSortConditionModal(false);
    },
    [handleOnCommitSearchCondition, setNeedsUpdateCompetitorAverageData],
  );

  const handleOnChangeSort = useCallback(
    (sortKey: SortKey) => {
      const newSearchCondition = searchCondition.update('sort', (sort) => sort.updateFirstBySortKey(sortKey));
      handleOnCommitSearchCondition(newSearchCondition);
    },
    [handleOnCommitSearchCondition, searchCondition],
  );

  const handleOnChangePage = useCallback(
    (page: number) => {
      const newSearchCondition = searchCondition.setIn(['pagination', 'page'], page);
      handleOnCommitSearchCondition(newSearchCondition, false);
    },
    [handleOnCommitSearchCondition, searchCondition],
  );

  const handleOnChangeAggregateType = useCallback(
    (aggregateType: SearchCondition['filter']['aggregateType']) => {
      const newSearchCondition = searchCondition.setIn(['filter', 'aggregateType'], aggregateType);
      handleOnCommitSearchCondition(newSearchCondition);
    },
    [handleOnCommitSearchCondition, searchCondition],
  );

  const handleSelectAverage = useCallback(() => {
    updateSelectedTableData({ isSelectedAverage: !isSelectedAverage });
  }, [isSelectedAverage, updateSelectedTableData]);

  const handleSelectConfig = useCallback(
    (configId: number) => {
      let newIsSelectedAverage = isSelectedAverage;
      let newSelectedConfigIds: Set<number>;
      if (selectedConfigIds.has(configId)) {
        newSelectedConfigIds = selectedConfigIds.remove(configId);
        // 選択されているデータが1ｰ>0になったとき、平均を表示する
        if (newSelectedConfigIds.isEmpty()) {
          newIsSelectedAverage = true;
        }
      } else {
        newSelectedConfigIds = selectedConfigIds.add(configId);
        // 選択されているデータが0ｰ>1になったとき、平均を非表示にする
        if (selectedConfigIds.isEmpty()) {
          newIsSelectedAverage = false;
        }
      }
      updateSelectedTableData({ isSelectedAverage: newIsSelectedAverage, selectedConfigIds: newSelectedConfigIds });
    },
    [isSelectedAverage, selectedConfigIds, updateSelectedTableData],
  );

  const handleSelectAllConfig = useCallback(() => {
    let newIsSelectedAverage: boolean;
    let newSelectedConfigIds: Set<number>;
    if (selectedConfigIds.size === tableData.items.size) {
      newSelectedConfigIds = Set();
      newIsSelectedAverage = true;
    } else {
      newSelectedConfigIds = tableData.items.map((item) => item.configId).toSet();
      newIsSelectedAverage = false;
    }
    updateSelectedTableData({ isSelectedAverage: newIsSelectedAverage, selectedConfigIds: newSelectedConfigIds });
  }, [tableData, selectedConfigIds, updateSelectedTableData]);

  const handleSelectTag = useCallback(
    (tag: string) => {
      let newSelectedTags: Set<string>;
      if (selectedTags.has(tag)) {
        newSelectedTags = selectedTags.remove(tag);
        // 選択されているタグがなくなったら、自店舗のみ選択された状態にする
        if (newSelectedTags.isEmpty()) {
          const myStoreItem = competitorAverageData.tableData.items.find((item) => !item.isCompetitor);
          if (myStoreItem) {
            newSelectedTags = newSelectedTags.add(myStoreItem.tag);
          }
        }
      } else {
        newSelectedTags = selectedTags.add(tag);
      }
      setSelectedTags(newSelectedTags);
    },
    [competitorAverageData.tableData.items, selectedTags, setSelectedTags],
  );

  const handleOnCancel = useCallback(() => {
    // 「全て解除」を選択したら、「平均」のみが選択された状態にする
    updateSelectedTableData({ isSelectedAverage: true, selectedConfigIds: Set() });
  }, [updateSelectedTableData]);

  const isCompetitorTab = searchCondition.filter.aggregateType === 'competitor';

  // 表示設定のタグでの絞り込みと、開始日終了日のフィルターを適用した施策メモのリスト
  const filteredMemoList = useMemo(() => {
    // タグで絞り込み
    let _filteredMemoList = memoList.filterByTags(memoDisplaySettings.tags.toSet());
    // 開始日終了日のフィルター
    // 集計期間と比較期間が一致している場合は、比較期間も含める
    const { startDate, endDate, aggregateUnit, comparisonStartDate, comparisonEndDate, isEnabledComparison } =
      searchCondition.filter;
    // 比較期間の終了日の翌日が集計期間の開始日の場合は結合できる
    const isCombined = isEnabledComparison && startDate.isSame(comparisonEndDate.add(1, 'day'));
    _filteredMemoList = _filteredMemoList.filterByDates(
      isCombined ? comparisonStartDate : startDate,
      endDate,
      aggregateUnit,
    );
    return _filteredMemoList;
  }, [searchCondition, memoList, memoDisplaySettings]);

  /** 並べ替え設定モーダルを開く */
  const onClickOpenSortConditionModal = useCallback(() => {
    setIsOpenSortConditionModal(true);
  }, [setIsOpenSortConditionModal]);

  return (
    <>
      <MainWrapper>
        <Helmet title={getPageTitle('検索順位')} />
        <StickyHeader>
          <Title>検索順位監視</Title>
          {currentUser.canUseMapSearchRankConfig && (
            <Link to={Path.mapSearchRank.config}>
              <StyledButton priority='high'>監視設定を変更</StyledButton>
            </Link>
          )}
        </StickyHeader>
        <WideBody>
          <MapSearchRankSearchCondition
            // URLから検索条件の初期化が完了してから検索条件を刷新するようにkeyを切り替える
            key={isInitializedSearchCondition ? 1 : 0}
            isCommitDisabled={isLoadingGraphData || isLoadingTableData}
            committedSearchCondition={searchCondition}
            commitSearchCondition={handleOnChangeSearchCondition}
          />
          <TabContainer>
            <Tab active={!isCompetitorTab} onClick={() => handleOnChangeAggregateType(undefined)}>
              キーワード
            </Tab>
            <Tab active={isCompetitorTab} onClick={() => handleOnChangeAggregateType('competitor')}>
              競合比較
            </Tab>
          </TabContainer>
          {/* FIXME: 分割する */}
          {searchCondition.filter.aggregateType === 'competitor' ? (
            <>
              <MapSearchRankCompetitorAverageGraph
                isLoading={isLoadingGraphData}
                aggregateUnit={searchCondition.filter.aggregateUnit}
                startDate={searchCondition.filter.startDate}
                endDate={searchCondition.filter.endDate}
                comparisonStartDate={searchCondition.filter.comparisonStartDate}
                comparisonEndDate={searchCondition.filter.comparisonEndDate}
                isEnabledComparison={searchCondition.filter.isEnabledComparison}
                graphData={competitorAverageData.graphData}
                tagColors={competitorAverageData.tagColors()}
                selectedTags={selectedTags}
                memoList={filteredMemoList}
                memoDisplaySettings={memoDisplaySettings}
                onClickMemo={(ids) => setSelectingMemoIds(Set(ids))}
                onClickLegend={handleSelectTag}
              />
              <AnalysisMemoTable
                memoList={filteredMemoList}
                activeMemoIds={selectingMemoIds}
                clearActiveMemoIds={() => setSelectingMemoIds(Set())}
                tags={memoTagList}
                memoDisplaySettings={memoDisplaySettings}
                setMemoDisplaySettings={setMemoDisplaySettings}
                onCreateMemo={createMemo}
                onUpdateMemo={updateMemo}
                onDeleteMemo={deleteMemo}
                isLoadingMemoList={isLoadingMemoList}
                isLoadingMemoTagList={isLoadingMemoTagList}
                stickyPosition={'Tab'}
              />
              <MapSearchRankCompetitorAverageTable
                isLoading={isLoadingTableData}
                isEnabledComparison={searchCondition.filter.isEnabledComparison}
                startDate={searchCondition.filter.startDate}
                endDate={searchCondition.filter.endDate}
                comparisonStartDate={searchCondition.filter.comparisonStartDate}
                comparisonEndDate={searchCondition.filter.comparisonEndDate}
                selectedTags={selectedTags}
                sort={searchCondition.sort}
                tableData={competitorAverageData.tableData}
                onChangeSort={handleOnChangeSort}
                onSelectTag={handleSelectTag}
                tagColors={competitorAverageData.tagColors()}
              />
            </>
          ) : (
            <>
              <MapSearchRankGraph
                isLoading={isLoadingGraphData}
                aggregateUnit={searchCondition.filter.aggregateUnit}
                startDate={searchCondition.filter.startDate}
                endDate={searchCondition.filter.endDate}
                comparisonStartDate={searchCondition.filter.comparisonStartDate}
                comparisonEndDate={searchCondition.filter.comparisonEndDate}
                isEnabledComparison={searchCondition.filter.isEnabledComparison}
                graphData={graphData}
                memoList={filteredMemoList}
                memoDisplaySettings={memoDisplaySettings}
                onClickMemo={(ids) => setSelectingMemoIds(Set(ids))}
                onClickLegendAverage={handleSelectAverage}
                onClickLegendConfig={handleSelectConfig}
              />
              <AnalysisMemoTable
                memoList={filteredMemoList}
                activeMemoIds={selectingMemoIds}
                clearActiveMemoIds={() => setSelectingMemoIds(Set())}
                tags={memoTagList}
                memoDisplaySettings={memoDisplaySettings}
                setMemoDisplaySettings={setMemoDisplaySettings}
                onCreateMemo={createMemo}
                onUpdateMemo={updateMemo}
                onDeleteMemo={deleteMemo}
                isLoadingMemoList={isLoadingMemoList}
                isLoadingMemoTagList={isLoadingMemoTagList}
                stickyPosition={'Tab'}
              />
              <MapSearchRankTable
                isLoading={isLoadingTableData}
                isSelectedAverage={isSelectedAverage}
                selectedConfigIds={selectedConfigIds}
                isEnabledComparison={searchCondition.filter.isEnabledComparison}
                tableData={tableData}
                sort={searchCondition.sort}
                displaySearchVolumeLink={currentUser.canUseSearchVolume}
                onChangeSort={handleOnChangeSort}
                onChangePage={handleOnChangePage}
                onSelectAverage={handleSelectAverage}
                onSelectConfig={handleSelectConfig}
                onSelectAllConfig={handleSelectAllConfig}
                onClickTableItem={(item) => setSelectedTableItem(item)}
                onClickOpenSortConditionModal={onClickOpenSortConditionModal}
              />
              {selectedConfigIds.size > 0 && (
                <MapSearchRankFooter selectedSize={selectedConfigIds.size} onCancel={handleOnCancel} />
              )}
            </>
          )}
        </WideBody>
        {selectedTableItem && (
          <MapSearchRankCompetitorModal
            isOpen={true}
            onClose={() => setSelectedTableItem(null)}
            configId={selectedTableItem.configId}
            storeId={selectedTableItem.storeId}
            storeName={selectedTableItem.storeName}
            areaName={selectedTableItem.areaName}
            searchWord={selectedTableItem.searchWord}
            aggregateUnit={searchCondition.filter.aggregateUnit}
            startDate={searchCondition.filter.startDate}
            endDate={searchCondition.filter.endDate}
            comparisonStartDate={searchCondition.filter.comparisonStartDate}
            comparisonEndDate={searchCondition.filter.comparisonEndDate}
            isEnabledComparison={searchCondition.filter.isEnabledComparison}
            memoList={filteredMemoList}
            memoTagList={memoTagList}
            memoDisplaySettings={memoDisplaySettings}
            setMemoDisplaySettings={setMemoDisplaySettings}
            onCreateMemo={createMemo}
            onUpdateMemo={updateMemo}
            onDeleteMemo={deleteMemo}
            isLoadingMemoList={isLoadingMemoList}
            isLoadingMemoTagList={isLoadingMemoTagList}
          />
        )}
        <MapSearchRankTableSortConditionModal
          isOpen={isOpenSortConditionModal}
          onClose={() => setIsOpenSortConditionModal(false)}
          condition={searchCondition}
          setCondition={handleOnChangeSearchCondition}
        />
      </MainWrapper>
    </>
  );
});

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    font-size: 14px;
  }
`;
