import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Card, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { UploadButton } from 'components/molecules/FileUploader';
import { UserThumbnail } from 'components/molecules/UserThumbnail';
import { NotificationEdit } from 'components/pageComponents/AccountEdit/NotificationEdit';
import { NotificationType, User, UserRole } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { COLOR } from 'style/color';

type Props = {
  user: User;
  canChangeEmail?: boolean;
  isMyAccountEdit: boolean;
  canEdit: boolean;
  changeUser: (user: User) => void;
  uploadUserImage: (file: File) => void;
  changePassword?: () => void;
};

export const UserEditForm: React.FC<Props> = ({
  user,
  changeUser,
  uploadUserImage,
  changePassword,
  isMyAccountEdit = false,
  canChangeEmail = false,
  canEdit,
}) => {
  const { stores, currentUser, accountList } = useSelector((state) => ({
    stores: state.store.stores,
    currentUser: state.app.currentUser,
    accountList: state.app.accountList,
  }));
  const dispatch = useDispatch();
  const errors = user.validate();
  const options = stores.list.map((store) => ({ text: store.fullName, value: store.id })).toArray();

  // 管理店舗の編集権限 編集権限がある、かつ自分のアカウント編集の場合SVは除く
  // (自分のアカウントの編集権限はありにしているのでSVを除く必要がある)
  const canEditManagedStores = canEdit && !(isMyAccountEdit && user.isSvUser);
  return (
    <Wrapper>
      <CardWrapper>
        <Card fluid>
          <Card.Content>
            <ThumbnailWrapper>
              <UserThumbnail src={user.profile_image_url} name={user.last_name} />
            </ThumbnailWrapper>
            <Card.Header>{user.fullName ? user.fullName : '氏名'}</Card.Header>
            <Card.Meta>{user.roleLabel && user.roleLabel.text}</Card.Meta>
            <Card.Meta>{user.email ? user.email : 'メールアドレス'}</Card.Meta>
            <Card.Description>
              {options
                .filter((option) => user.stores.includes(option.value))
                .map((option) => {
                  return <p key={option.value}>{option.text}</p>;
                })}
            </Card.Description>
          </Card.Content>
        </Card>
      </CardWrapper>
      <Form>
        <Title>プロフィール設定</Title>
        <Form.Field required>
          <label>姓</label>
          <Input
            value={user.last_name}
            error={errors.last_name}
            disabled={!canEdit}
            onChange={(value: string) => changeUser(user.changeLastName(value))}
          />
        </Form.Field>

        <Form.Field>
          <label>名</label>
          <Input
            value={user.first_name}
            error={errors.first_name}
            disabled={!canEdit}
            onChange={(value: string) => changeUser(user.changeFirstName(value))}
          />
        </Form.Field>

        {canEdit && (
          <Form.Field>
            <label>プロフィール画像</label>
            <UploadButton
              multiple={false}
              onDropAccepted={(files) => uploadUserImage(files[0])}
              onDropRejected={(files) => dispatch(AppActions.onDropRejected(files))}
            />
          </Form.Field>
        )}

        <Title>権限設定</Title>
        <Form.Field required>
          <label>役割</label>
          <PullDown
            value={user.role}
            options={currentUser.getRoleOptions()}
            placeholder='役割を選択してください'
            disabled={!canEdit || isMyAccountEdit}
            onChange={(value: UserRole) => changeUser(user.changeRole(value))}
          />
        </Form.Field>

        {user.isMemberUser && (
          <Form.Field required>
            <label>所属店舗</label>
            <PullDown
              value={user.stores.toArray()}
              options={options}
              placeholder='複数選択できます'
              multiple={true}
              closeOnChange={false}
              disabled={!canEdit || isMyAccountEdit}
              onChange={(value: number[]) => changeUser(user.changeStores(value))}
            />
          </Form.Field>
        )}

        {user.isCompanyUser && (
          <Form.Field>
            <label>管理店舗</label>
            <PullDown
              value={user.managing_stores.toArray()}
              options={options}
              placeholder='複数選択できます'
              multiple={true}
              closeOnChange={false}
              disabled={!canEditManagedStores}
              onChange={(value: number[]) => changeUser(user.changeManagingStores(value))}
            />
          </Form.Field>
        )}

        {/* 編集権限がないなら見せる必要もないので非表示にする */}
        {canEdit && (
          <>
            <Title>通知設定</Title>
            <Form.Field>
              <NotificationEdit
                currentUser={user}
                onChange={(type: NotificationType) => changeUser(user.changeNotification(type))}
              />
            </Form.Field>
          </>
        )}

        <Divider />

        <Title>アカウント設定</Title>

        {user.isMccUser && accountList.items.size > 1 && (
          <AlertWrapper>
            <Alert type={'info'}>
              <Alert.Content>
                このアカウントは複数の組織に紐付けられています。
                <br />
                アカウント設定は組織に関わらず共通で利用されます。
              </Alert.Content>
            </Alert>
          </AlertWrapper>
        )}

        <Form.Field required>
          <label>メールアドレス</label>
          <Input
            type='email'
            value={user.email}
            error={errors.email}
            disabled={!canChangeEmail || !canEdit}
            onChange={(value: string) => changeUser(user.changeEmail(value))}
          />
        </Form.Field>

        {isMyAccountEdit && changePassword && (
          <Form.Field>
            <label>パスワード</label>
            <ModalButton onClick={() => changePassword()}>パスワードを変更</ModalButton>
          </Form.Field>
        )}
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const CardWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 36px;
`;

const ThumbnailWrapper = styled.div`
  float: left;
  margin-right: 10px;
`;

const ModalButton = styled(Button)`
  &&& {
    width: 100%;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 25px 0;
`;

const Divider = styled.hr`
  border-top: 1px solid ${COLOR.LIGHT_GRAY};
  border-bottom: none;
  margin: 20px 0;
`;

const AlertWrapper = styled.div`
  margin-bottom: 32px;
`;
