import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { ImageCommentModal } from 'components/molecules/ImageCommentModal';
import { ImageCommentViewerModal } from 'components/molecules/ImageCommentViewerModal';
import { ImageViewerModal } from 'components/molecules/ImageViewerModal';
import { CommentEdit } from 'components/pageComponents/OfferDetail/CommentEdit';
import { OfferDetailContent } from 'components/pageComponents/OfferDetail/OfferDetailContent';
import { OfferHeader } from 'components/pageComponents/OfferDetail/OfferHeader';
import { ReadUsersPortal } from 'components/pageComponents/OfferDetail/ReadUsersPortal';
import { Timeline } from 'components/pageComponents/OfferDetail/Timeline';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { OfferTimeline } from 'models/Composite/OfferTimeline';
import { OfferActions } from 'modules/offer/actions';

type ReaderModalTargetType = {
  isOpen: boolean;
  type: 'offer' | 'timeline';
  timelineIndex: number;
};

type ImageViewerModalTargetType = {
  isOpen: boolean;
  type: 'offer' | 'timeline';
  timelineIndex: number;
  imageIndex: number;
};

type ImageCommentModalTargetType = {
  isOpen: boolean;
  imageUrl: string;
};

type ImageCommentViewerModalTargetType = {
  isOpen: boolean;
  timeline: OfferTimeline;
};

type Props = RouteComponentProps<{ offerId: string }>;

export const OfferDetail = React.memo<Props>(
  ({
    match: {
      params: { offerId },
    },
  }) => {
    const [readerModalTarget, setReaderModalTarget] = useState<ReaderModalTargetType>({
      isOpen: false,
      type: 'offer',
      timelineIndex: 0,
    });

    const [imageViewerModalTarget, setImageViewerModalTarget] = useState<ImageViewerModalTargetType>({
      isOpen: false,
      type: 'offer',
      timelineIndex: 0,
      imageIndex: 0,
    });

    const [imageCommentViewerModalTarget, setImageCommentViewerModalTarget] =
      useState<ImageCommentViewerModalTargetType>({
        isOpen: false,
        timeline: new OfferTimeline(),
      });

    const [imageCommentModalTarget, setImageCommentModalTarget] = useState<ImageCommentModalTargetType>({
      isOpen: false,
      imageUrl: '',
    });

    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);

    const {
      offerState: { offerItem, offerTimelines },
      appState: { currentUser },
      storeState: { stores },
    } = useSelector((state) => ({
      offerState: state.offer,
      appState: state.app,
      storeState: state.store,
    }));
    const { offer, readers } = offerItem;
    const targetStore = stores.findStore(offer.store_id);

    const dispatch = useDispatch();
    const acceptOffer = useCallback(() => dispatch(OfferActions.acceptTask()), [dispatch]);

    const onClickReadCount = useCallback(
      (type: 'offer' | 'timeline', timelineIndex = 0) => {
        setReaderModalTarget({ ...readerModalTarget, isOpen: true, type, timelineIndex });
      },
      [readerModalTarget],
    );

    const onClickCloseModalButton = useCallback(() => {
      setReaderModalTarget({ ...readerModalTarget, isOpen: false });
    }, [readerModalTarget]);

    const openImageViewerModal = useCallback(
      (type: 'offer' | 'timeline', imageIndex: number, timelineIndex = 0) => {
        setImageViewerModalTarget({ ...readerModalTarget, isOpen: true, type, imageIndex, timelineIndex });
      },
      [readerModalTarget],
    );

    const closeImageViewerModal = useCallback(() => {
      setImageViewerModalTarget({ ...imageViewerModalTarget, isOpen: false });
    }, [imageViewerModalTarget]);

    const openImageCommentModal = useCallback((imageUrl: string) => {
      setImageCommentModalTarget({ isOpen: true, imageUrl });
    }, []);

    const closeImageCommentModal = useCallback(() => {
      setImageCommentModalTarget((prev) => ({ ...prev, isOpen: false }));
    }, []);

    const openImageCommentViewerModal = useCallback((timeline) => {
      setImageCommentViewerModalTarget({ isOpen: true, timeline });
    }, []);

    const closeImageCommentViewerModal = useCallback(() => {
      setImageCommentViewerModalTarget((prev) => ({ ...prev, isOpen: false }));
    }, []);

    useEffect(() => {
      if (!offer.id || offer.id !== Number(offerId)) {
        dispatch(OfferActions.getOffer(Number(offerId)));
      }
      dispatch(OfferActions.getOfferTimeline(Number(offerId)));
    }, [dispatch, offerId, offer.id]);

    useEffect(() => {
      // Offer取得後に既読を送る
      if (offer.id && offer.id === Number(offerId)) {
        dispatch(OfferActions.readOffer(offer.id));
      }
    }, [dispatch, offer.id, offerId]);

    // データ取得前などの場合は非表示
    if (!offer.id || offer.id !== Number(offerId) || !targetStore) return null;

    const readUsers =
      readerModalTarget.type === 'offer'
        ? readers.getUserswithoutTarget(currentUser)
        : offerTimelines.getReadUsersExceptUser(readerModalTarget.timelineIndex, currentUser);

    const images =
      imageViewerModalTarget.type === 'offer'
        ? offerItem.fileContents
        : offerTimelines.getFileContents(imageViewerModalTarget.timelineIndex);

    return (
      <>
        <Helmet title={getPageTitle(`${offerItem.title}`)} />
        <MainWrapper>
          <OfferHeader
            offerItem={offerItem}
            store={targetStore}
            acceptOffer={() => {
              setIsAcceptModalOpen(true);
            }}
          />
          <Body>
            <OfferDetailContent
              stores={stores}
              offerItem={offerItem}
              currentUser={currentUser}
              onClickReadCount={() => onClickReadCount('offer')}
              openImageViewerModal={(imageIndex) => openImageViewerModal('offer', imageIndex)}
            />

            <Timeline
              offerTimelines={offerTimelines}
              currentUser={currentUser}
              store={targetStore}
              onClickReadCount={(timelineIndex) => onClickReadCount('timeline', timelineIndex)}
              openImageViewerModal={(imageIndex, timelineIndex) =>
                openImageViewerModal('timeline', imageIndex, timelineIndex)
              }
              openImageCommentViewerModal={(timeline) => openImageCommentViewerModal(timeline)}
            />
          </Body>
          <CommentEdit />
        </MainWrapper>

        <ReadUsersPortal
          readUsers={readUsers}
          isModalOpen={readerModalTarget.isOpen}
          onClose={onClickCloseModalButton}
        />

        <ImageViewerModal
          open={imageViewerModalTarget.isOpen}
          initialIndex={imageViewerModalTarget.imageIndex}
          images={images}
          onClose={closeImageViewerModal}
          openImageCommentModal={(imageUrl) => openImageCommentModal(imageUrl)}
        />

        <ImageCommentModal
          open={imageCommentModalTarget.isOpen}
          imageUrl={imageCommentModalTarget.imageUrl}
          onClose={() => closeImageCommentModal()}
        />

        <ImageCommentViewerModal
          timeline={imageCommentViewerModalTarget.timeline}
          open={imageCommentViewerModalTarget.isOpen}
          openImageCommentModal={(imageUrl) => openImageCommentModal(imageUrl)}
          onClose={() => closeImageCommentViewerModal()}
        />
        <ConfirmModal
          type='small'
          open={isAcceptModalOpen}
          text={`${targetStore.fullName} の ${offerItem.targetContent.name} を完了にしますか？`}
          onNegativeExecution={() => {
            setIsAcceptModalOpen(false);
          }}
          onExecution={() => {
            setIsAcceptModalOpen(false);
            acceptOffer();
          }}
          contentText='完了'
        />
      </>
    );
  },
);
