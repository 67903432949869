import React, { useCallback, useEffect, useMemo } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle, isNonNegativeInteger } from 'helpers/utils';
import { ServiceGroup } from 'models/Domain/Service/Service';
import { AppActions } from 'modules/app/actions';
import { ServiceActions } from 'modules/service/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

import { ServiceGroupEditForm } from '../pageComponents/Service/ServiceGroupEditForm';

export const ServiceEdit = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { initializeEditPage, updateServiceGroup } = useMemo(
    () => bindActionCreators(ServiceActions, dispatch),
    [dispatch],
  );
  const { moveTo } = useMemo(() => bindActionCreators(AppActions, dispatch), [dispatch]);
  const { isLoading, serviceGroup } = useSelector((state) => state.service);
  const { stores } = useSelector((state) => state.store);
  const { storeLists } = useSelector((state) => state.storeList);
  const { categoryList } = useSelector((state) => state.gmb);

  const handleOnSave = useCallback(
    (serviceGroup: ServiceGroup) => {
      updateServiceGroup(serviceGroup);
    },
    [updateServiceGroup],
  );

  const handleCancel = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  useEffect(() => {
    if (isNonNegativeInteger(id)) {
      initializeEditPage(parseInt(id, 10));
    } else {
      // サービスグループIDが不正な場合はサービスグループ一覧に遷移
      moveTo(Path.service.index);
    }
  }, [id, initializeEditPage, moveTo]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('サービスグループ編集')}></Helmet>
      <StickyHeader>
        <Title>サービスグループ編集</Title>
      </StickyHeader>
      <StyledBody>
        {isLoading && (
          <LoadingWrapper>
            <LoadingWrapperBase />
            <Loader active={true} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
        {!isLoading && (
          <ServiceGroupEditForm
            key={serviceGroup.id} // サービスグループIDが変更された場合に再描画して、フォームの初期値を更新するため
            serviceGroup={serviceGroup}
            stores={stores}
            storeLists={storeLists}
            categoryList={categoryList}
            onSave={handleOnSave}
            onCancel={handleCancel}
          />
        )}
      </StyledBody>
    </MainWrapper>
  );
};

const StyledBody = styled(WideBody)`
  position: relative;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  z-index: 1; /** カルーセルの矢印より上に表示されるようにする */
`;
