import React, { useState } from 'react';

import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Arrow } from 'components/atoms/Arrow';
import { Link } from 'components/atoms/Link';
import { Store } from 'models/Domain/Store';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type GmbLocationErrorAlertProps = {
  className?: string;
  stores: Store[];
};

const INITIAL_DISPLAY_STORES = 3;

export const GmbLocationErrorAlert: React.FC<GmbLocationErrorAlertProps> = ({ className, stores }) => {
  const displayMoreStoresLink = stores.length > INITIAL_DISPLAY_STORES;
  const [isOpen, setIsOpen] = useState<boolean>(!displayMoreStoresLink);
  return (
    <Alert className={className} type='error'>
      <Alert.Title>一部機能が利用できない店舗があります</Alert.Title>
      <Alert.Section>
        <Alert.Content>
          {stores.length}
          店舗が、Googleビジネスプロフィールにおける問題のため、
          <B>投稿やクチコミ、画像連携などが実施できない状態になっています</B>
          。これらの操作を実施するためには、原因を確認して対応してください。
        </Alert.Content>
      </Alert.Section>
      <Alert.Section>
        <Alert.Header>問題のある店舗</Alert.Header>
        <Alert.Content>
          <StoreList>
            {stores.slice(0, isOpen ? undefined : 3).map((store) => (
              <StoreItem key={store.id}>
                {store.fullName}（
                <StoreLink to={Path.store.detail.replace(':storeId', String(store.id))}>
                  確認する
                  <Svg viewBox='0 0 12 12' color='#ff7a7a'>
                    <use xlinkHref='/images/external_link.svg#link' />
                  </Svg>
                </StoreLink>
                ）
              </StoreItem>
            ))}
          </StoreList>
          {displayMoreStoresLink && (
            <MoreStoresLink onClick={() => setIsOpen(!isOpen)}>
              <StyledArrow direction={isOpen ? 'up' : 'down'} />
              {isOpen ? '閉じる' : `他${stores.length - INITIAL_DISPLAY_STORES}店舗を表示する`}
            </MoreStoresLink>
          )}
        </Alert.Content>
      </Alert.Section>
    </Alert>
  );
};

const StoreList = styled.ul`
  list-style: disc;
  padding-left: 20px;
`;
const StoreItem = styled.li``;
const StoreLink = styled(Link).attrs(() => ({ target: '_blank' }))`
  color: #ff7a7a;
  text-decoration: underline;
  &:hover {
    color: #ff7a7a;
    text-decoration: underline;
  }
`;

const B = styled.b`
  font-weight: bold;
`;
const MoreStoresLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

const StyledArrow = styled(Arrow).attrs(() => ({ color: COLOR.BLACK, length: 10, weight: 2 }))`
  margin: 0 4px;
`;

const Svg = styled.svg<{ color: string }>`
  width: 0.9em;
  height: 0.9em;
  margin: 0 0.05em;
  fill: ${({ color }) => color};
`;
