import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Organization } from 'models/Domain/Organization';

import { OrganizationActions } from './actions';

export class OrganizationState extends Record<{
  organization: Organization;
  isLoading: boolean;
}>({
  organization: new Organization(),
  isLoading: false,
}) {}

export const organizationReducer = reducerWithInitialState(new OrganizationState())
  .case(OrganizationActions.setOrganization, (state, payload) => {
    return state.set('organization', payload);
  })
  .case(OrganizationActions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  });
