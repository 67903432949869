import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { OfferActivities } from 'models/Composite/OfferActivity';
import { OfferCommon } from 'models/Composite/OfferCommon';
import { OfferItem } from 'models/Composite/OfferItem';
import { OfferStores } from 'models/Composite/OfferStores';
import { OfferTimelines } from 'models/Composite/OfferTimeline';
import { StoreOffers } from 'models/Composite/StoreOffer';
import { Comment } from 'models/Domain/Comment';
import { StoreOfferFilter } from 'models/Other/StoreOfferFilter';

import { OfferActions } from './actions';

export interface StateRecord {
  storeOffers: StoreOffers;
  storeOfferFilter: StoreOfferFilter;
  storeCompletedOffers: StoreOffers;
  offerItem: OfferItem;
  offerTimelines: OfferTimelines;
  newComment: Comment;
  offerActivities: OfferActivities;
  newOffer: OfferStores;
  editOffer: OfferCommon;
}

export class OfferState extends Record<StateRecord>({
  storeOffers: new StoreOffers(),
  storeOfferFilter: new StoreOfferFilter(),
  storeCompletedOffers: new StoreOffers(),
  offerItem: new OfferItem(),
  offerTimelines: new OfferTimelines(),
  newComment: new Comment(),
  offerActivities: new OfferActivities(),
  newOffer: new OfferStores(),
  editOffer: new OfferCommon(),
}) {}

export const offerReducer = reducerWithInitialState(new OfferState())
  .case(OfferActions.setStoreOffers, (state, payload) => {
    return state.set('storeOffers', new StoreOffers(payload));
  })
  .case(OfferActions.setStoreCompletedOffers, (state, payload) => {
    return state.set('storeCompletedOffers', new StoreOffers(payload));
  })
  .case(OfferActions.setStoreCompletedOfferAddRead, (state, payload) => {
    return state.update('storeCompletedOffers', (storeCompletedOffers) => storeCompletedOffers.margeOffers(payload));
  })
  .case(OfferActions.setOffer, (state, data) => {
    const offer = new OfferItem(data);
    return state.set('offerItem', offer);
  })
  .case(OfferActions.setEditOffer, (state, data) => {
    const offer = new OfferCommon(data);
    return state.set('editOffer', offer);
  })
  .case(OfferActions.setOfferTimeline, (state, payload) => {
    return state.set('offerTimelines', new OfferTimelines(payload));
  })
  .case(OfferActions.setNewComment, (state, payload) => {
    return state.set('newComment', payload);
  })
  .case(OfferActions.clearNewComment, (state, payload) => {
    return state.set('newComment', new Comment());
  })
  .case(OfferActions.setOfferActivity, (state, payload) => {
    return state.set('offerActivities', payload);
  })
  .case(OfferActions.setNextOfferActivity, (state, payload) => {
    return state.set('offerActivities', payload);
  })
  .case(OfferActions.changeNewOffer, (state, payload) => {
    return state.set('newOffer', payload);
  })
  .case(OfferActions.clearNewOffer, (state, _) => {
    return state.set('newOffer', new OfferStores());
  })
  .case(OfferActions.changeEditOffer, (state, payload) => {
    return state.set('editOffer', payload);
  })
  .case(OfferActions.clearEditOffer, (state, _) => {
    return state.set('editOffer', new OfferCommon());
  })
  .case(OfferActions.setStoreOfferFilter, (state, payload) => {
    return state.set('storeOfferFilter', state.storeOfferFilter.merge(payload));
  });
