import React, { useCallback, useEffect, useState } from 'react';

import { List } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { TagInput } from 'components/molecules/TagInput';
import { Competitor } from 'models/Domain/Competitor/Competitor';

type Props = {
  className?: string;
  isOpen: boolean;
  competitor: Competitor;
  onClose: () => void;
  onSave: (id: number, tags: List<string>) => void;
};

export const TagEditModal = React.memo<Props>(({ className, isOpen, competitor, onClose, onSave }) => {
  const [tags, setTags] = useState<List<string>>(List());
  useEffect(() => {
    setTags(competitor.tags);
  }, [competitor]);

  const handleOnChangeTags = useCallback((values: string[]) => {
    setTags(List(values));
  }, []);

  const handleOnSave = useCallback(() => {
    onSave(competitor.id, tags);
  }, [onSave, tags, competitor.id]);

  return (
    <Modal className={className} open={isOpen} onClose={onClose}>
      <Modal.Content>
        <Wrapper>
          <Title>タグの編集</Title>
          <StoreName>{competitor.name}</StoreName>
          <Label>タグ</Label>
          <TagInput values={tags.toArray()} onChange={handleOnChangeTags} />
        </Wrapper>
        <ButtonWrapper>
          <StyledButton onClick={onClose}>閉じる</StyledButton>
          <StyledButton onClick={handleOnSave} priority={'high'}>
            保存
          </StyledButton>
        </ButtonWrapper>
      </Modal.Content>
    </Modal>
  );
});

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const StoreName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  ${StyledButton} {
    :not(:first-child) {
      margin-left: 16px;
    }
  }
`;
