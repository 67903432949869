import ApiClient from './index';

const apiClient = new ApiClient({});

const SERVICE_API_ENDPOINT = '/services';

type PriceType = 'FREE' | 'CONSTANT' | 'NO_PRICE';

type Price = {
  price_value: number | null;
  price_type: PriceType;
};

export type Service = {
  id?: number;
  name: string;
  service_type_id: string | null;
  description: string | null;
  price: Price;
};

export type GetServiceSummaryParams = {
  store_ids?: number[];
  group_ids?: number[];
  is_included_deactivate_gbp?: boolean;
};

export type GetServiceSummaryResponse = {
  items: {
    id: number;
    store_ids: number[] | null;
    group_ids: number[] | null;
    name: string;
    category_id: string;
    category_name: string;
    apply_to_gbp: boolean;
    synced_to_gbp: boolean | null;
    is_deleted: boolean; // GBP未反映のサービスグループは削除済みでもレスポンスに含まれる
    create_at: string;
    update_at: string;
  }[];
};

export type GetServiceResponse = {
  id: number;
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  category_id: string;
  apply_to_gbp: boolean;
  synced_to_gbp: boolean | null;
  service_items: Service[];
  create_at: string;
  update_at: string;
};

export type PostServiceParams = {
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  category_id: string;
  apply_to_gbp: boolean;
  service_items: Service[];
};

export type PostServiceResponse = {
  id: number;
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  category_id: string;
  apply_to_gbp: boolean;
  service_items: Service[];
};

export type PutServiceParams = {
  id: number | null;
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  category_id: string;
  apply_to_gbp: boolean;
  service_items: Service[];
};

export type PutServiceResponse = {
  id: number;
  store_ids: number[] | null;
  group_ids: number[] | null;
  name: string;
  category_id: string;
  apply_to_gbp: boolean;
  service_items: Service[];
};

type BatchDeleteServiceParams = {
  ids: number[];
};

type BatchDeleteServiceResponse = string;

export type BatchUpdateSummaryParams = {
  items: {
    id: number;
    store_ids: number[] | null;
    group_ids: number[] | null;
    name: string;
    category_id: string;
    apply_to_gbp: boolean;
  }[];
};

type BatchUpdateSummaryResponse = string;

type GetPresetServicesParams = {
  category_id: string;
};

type GetPresetServicesResponse = {
  category: {
    id: string;
    name: string;
  };
  structured_services: {
    service_type_id: string;
    name: string;
  }[];
};

export class ServiceApi {
  static getSummary(params: GetServiceSummaryParams) {
    return apiClient.get<GetServiceSummaryResponse>(`${SERVICE_API_ENDPOINT}:summary`, params);
  }

  static get(serviceGroupId: number) {
    return apiClient.get<GetServiceResponse>(`${SERVICE_API_ENDPOINT}/${serviceGroupId}`);
  }

  static post(params: PostServiceParams) {
    return apiClient.post<PostServiceResponse>(`${SERVICE_API_ENDPOINT}`, params);
  }

  static put(serviceGroupId: number, params: PutServiceParams) {
    return apiClient.put<PutServiceResponse>(`${SERVICE_API_ENDPOINT}/${serviceGroupId}`, params);
  }

  static batchDelete(params: BatchDeleteServiceParams) {
    return apiClient.post<BatchDeleteServiceResponse>(`${SERVICE_API_ENDPOINT}:batch_delete`, params);
  }

  static batchUpdateSummary(params: BatchUpdateSummaryParams) {
    return apiClient.post<BatchUpdateSummaryResponse>(`${SERVICE_API_ENDPOINT}:batch_update_summary`, params);
  }

  static getStructured(params: GetPresetServicesParams) {
    return apiClient.get<GetPresetServicesResponse>(`${SERVICE_API_ENDPOINT}:structured`, params);
  }

  static applyToGbp() {
    return apiClient.post(`${SERVICE_API_ENDPOINT}:apply_to_gbp`);
  }
}
