import React, { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { Set as ImmutableSet } from 'immutable';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { ChartTooltip } from 'components/atoms/ChartTooltip';
import { MemoIcon } from 'components/molecules/Graph';
import { MemoIconData, getDateLabelWithWeekOfDay, getValueText } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { SearchKeywordGraphData, getGraphTypeLabel } from 'models/Domain/SearchKeyword/SearchKeyword';
import { SearchKeywordGraphType } from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { YearMonth } from 'models/Domain/YearMonth';
import { COLOR } from 'style/color';

const GRAPH_COLOR = {
  GREEN: '#458a6a',
  LIGHT_GREEN: '#E2F3EB',
  ORANGE: '#dc8459',
  LIGHT_ORANGE: '#F8EEE9',
  PURPLE: '#6A42D2',
  LIGHT_PURPLE: '#EAE6F5',
};
interface Props {
  className?: string;
  graphData: SearchKeywordGraphData;
  graphType: SearchKeywordGraphType;
  startMonth: YearMonth;
  endMonth: YearMonth;
  selectedMonth: YearMonth;
  onChangeSelectedMonth?: (month: string) => void;
  isLoading?: boolean;
  onClickMemo?: (ids: number[]) => void;
  showReferenceLine: boolean;
  memoData?: MemoIconData[];
  graphSettings?: GraphSettings;
}

export const SearchKeywordsGraph: React.FC<Props> = ({
  graphData,
  graphType,
  startMonth,
  endMonth,
  selectedMonth,
  onChangeSelectedMonth,
  onClickMemo,
  showReferenceLine,
  memoData,
  graphSettings = new GraphSettings(),
}) => {
  const data = useMemo(
    () =>
      YearMonth.range(startMonth, endMonth).map((month) => ({
        period: month.format(),
        value: graphData.findByPeriod(month),
      })),
    [startMonth, endMonth, graphData],
  );
  const [yearAgo, threeMonthsAgo, monthAgo] = useMemo(() => {
    return [12, 3, 1].map((months) => selectedMonth.add({ months: -months }));
  }, [selectedMonth]);

  const renderTooltip = useCallback(
    (data: TooltipProps<number, string>) => {
      return (
        <ChartTooltip>
          <ChartTooltip.Title>{getDateLabelWithWeekOfDay(data.label)}</ChartTooltip.Title>
          <StyledTooltipItem>
            <ChartTooltip.Label color={GRAPH_COLOR.GREEN}>{getGraphTypeLabel(graphType)}</ChartTooltip.Label>
            <ChartTooltip.Value>{data.payload?.map((item) => getValueText(item.value))}</ChartTooltip.Value>
          </StyledTooltipItem>
        </ChartTooltip>
      );
    },
    [graphType],
  );

  // なぜか同じ日付に複数のメモアイコンを描画しようとしてしまうので、一度表示した日付を保持しておく
  let displayedMemoDate = ImmutableSet<string>();

  return (
    <ResponsiveContainer minHeight={300}>
      <ComposedChart data={data} margin={{ top: 64, left: 32, right: 32, bottom: 16 }}>
        <Tooltip
          formatter={(value: number) => value.toLocaleString()}
          content={renderTooltip}
          filterNull={false}
          isAnimationActive={false}
        />
        <XAxis
          dataKey='period'
          tick={{ fontSize: 12, fontWeight: 'bold' }}
          tickMargin={8}
          interval={'equidistantPreserveStart'}
        />
        <YAxis
          label={{
            value: getGraphTypeLabel(graphType),
            position: 'top',
            offset: 24,
          }}
          tickFormatter={(value) => getValueText(value)}
          tick={{ fontSize: 12, fontWeight: 'bold' }}
          domain={graphSettings.domain}
          allowDataOverflow={true}
          tickMargin={8}
          allowDecimals={false}
        />
        <CartesianGrid stroke={COLOR.GRAY} vertical={false} strokeDasharray='1 1' />

        {/* １年前、３ヶ月前、１ヶ月前および選択中の月の参照線 */}
        {showReferenceLine && (
          <>
            <ReferenceLine x={yearAgo.format()} stroke={COLOR.GRAY} strokeWidth={1} strokeDasharray='2 2' />
            <ReferenceLine x={threeMonthsAgo.format()} stroke={COLOR.GRAY} strokeWidth={1} strokeDasharray='2 2' />
            <ReferenceLine x={monthAgo.format()} stroke={COLOR.GRAY} strokeWidth={1} strokeDasharray='2 2' />
            {selectedMonth && (
              <ReferenceLine x={selectedMonth.format()} stroke={COLOR.RED} strokeWidth={2} strokeDasharray='2 2' />
            )}
          </>
        )}

        <Area
          dataKey='value'
          stroke={GRAPH_COLOR.GREEN}
          strokeWidth={2}
          fill={GRAPH_COLOR.LIGHT_GREEN}
          fillOpacity={0.2}
          activeDot={{
            r: 6,
            style: { cursor: 'pointer' },
            onClick: (_, event) => {
              // event.payload.periodでクリックされたドットの期間を取得できる
              // YYYY年M月形式なので、YYYY-MMに変換する
              const { period } = (event as any).payload;
              const targetMonth = dayjs(period, 'YYYY年M月').format('YYYY-MM');
              onChangeSelectedMonth && onChangeSelectedMonth(targetMonth);
            },
            clipDot: false,
          }}
          isAnimationActive={false}
        />

        {memoData?.map((item) => {
          // すでにメモを表示している日付ならスキップ
          if (displayedMemoDate.has(item.date)) {
            return null;
          }
          // メモを表示している日付リストに追加
          displayedMemoDate = displayedMemoDate.add(item.date);
          return (
            <ReferenceLine
              key={item.date}
              x={item.date}
              stroke={'#9994'}
              label={(props) =>
                MemoIcon({
                  ...props,
                  title: getDateLabelWithWeekOfDay(item.date),
                  content: item.contents,
                  onClick: () => onClickMemo && onClickMemo(item.ids),
                }) as any
              }
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const StyledTooltipItem = styled(ChartTooltip.Item)`
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
`;
