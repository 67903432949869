import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { AlbumColumn, DummyAlbumColumn } from 'components/organisms/AlbumColumn';
import { range } from 'helpers/utils';
import CheckedImageList from 'models/Domain/CheckedImageList';
import Image from 'models/Domain/Image/Image';

type Props = {
  imageList: List<Image>;
  checkedImageList: CheckedImageList;
  showCheckbox: boolean;
  onChangeCheck: (image: Image) => void;
};

export const AlbumImageListContent = React.memo<Props>(
  ({ imageList, checkedImageList, showCheckbox, onChangeCheck }) => {
    return (
      <ImageList>
        {imageList.map((image, idx) => {
          const checked = checkedImageList.includes(image.image_resource_id);
          return (
            <AlbumColumn
              checked={checked}
              showCheckbox={showCheckbox}
              onChangeCheck={onChangeCheck}
              key={image.url}
              image={image}
            />
          );
        })}
        {range(0, 10).map((idx) => (
          <DummyAlbumColumn key={idx} />
        ))}
      </ImageList>
    );
  },
);

const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 6px auto;
`;
