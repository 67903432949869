import React from 'react';

import styled from 'styled-components';

import { GmbReviewStatus } from 'models/Domain/GmbReview';
import { COLOR } from 'style/color';

interface StarsProps {
  className?: string;
  status: GmbReviewStatus | null;
}

export const ReviewStatusLabel: React.FC<StarsProps> = ({ className, status }) => {
  const statusColor = [
    { key: 'todo', color: COLOR.PURPLE, value: '●未対応' },
    { key: 'done', color: COLOR.GREEN, value: '●対応済' },
    { key: 'not_replied', color: COLOR.GRAY, value: '●対応しない' },
  ];
  const target = statusColor.find((color) => color.key === status);
  return (
    <Status className={className} color={target ? target.color : COLOR.WHITE}>
      {target?.value}
    </Status>
  );
};

const Status = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: bold;
`;
