import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ListTabContent } from 'components/pageComponents/StoreListIndex/ListTabContent';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { AppActions } from 'modules/app/actions';
import { StoreListActions } from 'modules/storeList/actions';
import { Path } from 'routes';

export const StoreListIndex = React.memo(() => {
  const dispatch = useDispatch();
  const moveTo = useCallback((v: string) => dispatch(AppActions.moveTo(v)), [dispatch]);
  const deleteStoreList = useCallback(
    (v: number) => {
      if (window.confirm('削除してよろしいですか？')) {
        dispatch(StoreListActions.deleteStoreList(v));
      }
    },
    [dispatch],
  );

  const storeListState = useSelector((state) => state.storeList);
  const { storeLists } = storeListState;

  return (
    <StyledMainWrapper>
      <Helmet title={getPageTitle('グループ一覧')} />
      <StickyHeader>
        <Title>グループ一覧</Title>
        <Link to={Path.storeList.create}>
          <GroupRegisterButton priority='high'>グループを作成</GroupRegisterButton>
        </Link>
      </StickyHeader>
      <Body>
        <ListTabContent storeLists={storeLists} moveTo={moveTo} deleteStoreList={deleteStoreList} />
      </Body>
    </StyledMainWrapper>
  );
});

const GroupRegisterButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 13px;
  }
`;

const StyledMainWrapper = styled(MainWrapper)`
  &&& {
    margin: 0;
  }
`;
