import React, { useCallback, useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { InstagramIndexTable } from 'components/pageComponents/Instagram/InstagramIndexTable';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { pushWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { InstagramActions } from 'modules/instagram/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const InstagramIndex = React.memo(() => {
  const dispatch = useDispatch();
  const { isLoadingAccounts: isLoading, accountList } = useSelector((state) => state.instagram);
  const { initializeIndexPage, deleteAccountForIndex: deleteAccount } = useMemo(
    () => bindActionCreators(InstagramActions, dispatch),
    [dispatch],
  );

  useEffect(() => {
    initializeIndexPage();
  }, [initializeIndexPage]);

  const handleOnClickAdd = useCallback(() => {
    dispatch(pushWithOrganizationId({ pathname: Path.instagram.register, state: { source: Path.instagram.index } }));
  }, [dispatch]);

  const handleOnDelete = useCallback(
    (id: number) => {
      deleteAccount(id);
    },
    [deleteAccount],
  );

  const showEdit = useCallback(
    (id: number) => {
      dispatch(
        pushWithOrganizationId({
          pathname: Path.instagram.edit.replace(':id', `${id}`),
          state: { source: Path.instagram.index },
        }),
      );
    },
    [dispatch],
  );

  const invalidAccounts = useMemo(() => accountList.filter((account) => !account.isValid), [accountList]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle(`Instagramアカウント一覧`)} />
      <StickyHeader>
        <Title>Instagramアカウント一覧</Title>
        <StyledButton onClick={handleOnClickAdd}>追加</StyledButton>
      </StickyHeader>
      <Body>
        {!isLoading && !invalidAccounts.isEmpty() && (
          <StyledAlert type={'error'}>
            <Alert.Title>再連携が必要なアカウントがあります</Alert.Title>
            <Alert.Section>
              <Alert.Content>
                Instagramアカウントとの連携に問題があるため、GBPへの投稿の自動反映が停止されています。
                <br />
                以下のアカウントの連携を解除して、再度連携してください。
              </Alert.Content>
            </Alert.Section>
            <Alert.Section>
              <Alert.Header>問題のあるアカウント</Alert.Header>
              <Alert.Content>
                <InvalidAccountList>
                  {invalidAccounts.map((account) => (
                    <InvalidAccountListItem key={account.id}>
                      {account.name} @{account.userName}（
                      <InvalidAccountListLink to={Path.instagram.edit.replace(':id', `${account.id}`)}>
                        確認する
                      </InvalidAccountListLink>
                      ）
                    </InvalidAccountListItem>
                  ))}
                </InvalidAccountList>
              </Alert.Content>
            </Alert.Section>
          </StyledAlert>
        )}
        <InstagramIndexTable
          isLoading={isLoading}
          accounts={accountList}
          onClick={showEdit}
          onDelete={handleOnDelete}
        />
      </Body>
    </MainWrapper>
  );
});

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 13px;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 32px;
`;

const InvalidAccountList = styled.ul`
  list-style: disc;
  padding-left: 16px;
`;
const InvalidAccountListItem = styled.li``;
const InvalidAccountListLink = styled(Link)`
  color: #ff7a7a;
  text-decoration: underline;
  :hover {
    color: ${COLOR.ERROR};
  }
`;
