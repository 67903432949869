import React, { useState } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { CheckBoxButton } from 'components/atoms/CheckBox';
import { PullDown } from 'components/atoms/PullDown';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { ImportModal } from 'components/pageComponents/GmbImportStores/ImportModal';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyOnce';
import GmbGroup from 'models/Domain/GmbGroup';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { GmbActions } from 'modules/gmb/actions';
import { State } from 'modules/reducers';
import { COLOR } from 'style/color';

// 契約店舗数を超えて取り込みできない時に表示するメッセージ
const REACHED_CONTRACTED_STORE_LIMIT_MESSAGE =
  '契約店舗数の上限を超えるため、選択した店舗を取り込みできません。上限の変更につきましては、メニューの「サポート」よりお問い合わせください。';

type Props = RouteComponentProps<{ accountId: string }>;

export const GmbImportStores = React.memo<Props>(
  ({
    match: {
      params: { accountId },
    },
  }) => {
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const { gmbState, appState, storeState } = useSelector(
      (state: State) => ({
        gmbState: state.gmb,
        appState: state.app,
        storeState: state.store,
      }),
      shallowEqual,
    );

    const dispatch = useDispatch();
    useEffectOnlyOnce(() => {
      dispatch(GmbActions.selectGmbAccount(accountId));
    });

    const { groupList, currentGroupId, notImportedLocationList } = gmbState;

    const groupOptions = groupList
      .map((group: GmbGroup) => ({ text: group.accountName, value: group.name.split('/')[1] }))
      .toArray();

    const checkLocationList = notImportedLocationList.map((location: GmbLocation) => ({
      text: location.locationName,
      value: location.name,
      checked: checkedList.some((target) => target === location.name),
    }));

    const changeChecked = (value: string) => {
      if (checkedList.some((target) => target === value)) {
        setCheckedList(checkedList.filter((target) => target !== value));
        return;
      }
      const newList = checkedList.map((e) => e);
      newList.push(value);
      setCheckedList(newList);
    };

    const allCheck = () => {
      setCheckedList(checkLocationList.map((target) => target.value).toArray());
    };

    const emptyCheck = () => {
      setCheckedList([]);
    };

    const onApply = () => {
      setIsModalOpen(true);
    };

    const organization = appState.currentUser.organization;
    if (organization === null) {
      return <></>;
    }

    const gmbConnectedStoresCount = storeState.stores.filterByIsConnectedGmb().list.size;

    // 連携ボタンの活性/非活性
    // 取り込みたい店舗が選択されている かつ チェック中の店舗とGBP連携店舗数の合計が契約店舗数上限を超えていない
    const reachedContractedStoreLimit = organization.contractedStores < gmbConnectedStoresCount + checkedList.length;
    const canImportGmbLocations = checkedList.length > 0 && !reachedContractedStoreLimit;

    return (
      <MainWrapper>
        <Helmet title={getPageTitle('店舗の取り込み')} />
        <StickyHeader title='Googleビジネスプロフィールの店舗の取り込み' />
        <Body>
          <ContentLabel>店舗情報が所属するグループ</ContentLabel>
          <CustomPullDown
            value={currentGroupId}
            options={groupOptions}
            placeholder={'Googleビジネスプロフィール グループ選択'}
            multiple={false}
            onChange={(value: string) => dispatch(GmbActions.selectGmbGroup(value))}
            disabled={groupList.isEmpty()}
          />
          {checkLocationList.size > 0 && currentGroupId && (
            <>
              <FlexWrapper>
                <ContentLabel>店舗</ContentLabel>
                <SelectText onClick={() => allCheck()}>すべて選択</SelectText>
                <SelectText onClick={() => emptyCheck()}>すべて非選択</SelectText>
              </FlexWrapper>
              <StoreListWrapper>
                {checkLocationList.map((location, idx) => (
                  <CustomCheckBox
                    key={idx}
                    id={idx}
                    checked={location.checked}
                    text={location.text}
                    onClick={() => changeChecked(location.value)}
                  />
                ))}
              </StoreListWrapper>
              {/* 選択中の店舗数が契約店舗数を超える場合メッセージを表示 */}
              {reachedContractedStoreLimit && <StyledMessage error content={REACHED_CONTRACTED_STORE_LIMIT_MESSAGE} />}
              <ButtonWrapper>
                <CloseButton onClick={() => dispatch(goBack())}>キャンセル</CloseButton>
                <ApplyButton priority='high' onClick={() => onApply()} disabled={!canImportGmbLocations}>
                  適用
                </ApplyButton>
              </ButtonWrapper>
            </>
          )}
          {checkLocationList.size == 0 && currentGroupId && (
            <TextWrapper>{'新規に取り込みができる店舗がありません。'}</TextWrapper>
          )}
        </Body>
        <ImportModal
          checkLocationList={checkLocationList}
          checkedList={checkedList}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onImport={() => dispatch(GmbActions.importStoresFromGbp(checkedList))}
        />
      </MainWrapper>
    );
  },
);

const TextWrapper = styled.div`
  margin-top: 24px;
`;

const CustomPullDown = styled(PullDown)`
  margin-top: 8px;
`;

const CustomCheckBox = styled(CheckBoxButton)`
  &&& {
    border: none;
    font-size: 14px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    align-items: center;
  }
`;

const ContentLabel = styled.div`
  font-weight: bold;
  margin-top: 24px;
`;

const StoreListWrapper = styled.div``;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectText = styled.div`
  color: ${COLOR.GREEN};
  margin-top: 24px;
  margin-left: 24px;
  cursor: pointer;
`;

const StyledMessage = styled(Message)`
  &&& {
    margin-bottom: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const ButtonCommonStyle = css`
  width: fit-content;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
    margin-left: 20px;
  }
`;
