import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Competitor, Competitors } from 'models/Domain/Competitor/Competitor';
import { CompetitorSearchCondition as SearchCondition } from 'models/Domain/Competitor/CompetitorSearchCondition';

import { CompetitorActions as Actions } from './actions';

export class CompetitorState extends Record<{
  isPreparedPage: boolean;
  isLoading: boolean;
  searchCondition: SearchCondition;
  competitors: Competitors;
  isPreparedDetailPage: boolean;
  competitor: Competitor | null;
}>({
  isPreparedPage: false,
  isPreparedDetailPage: false,
  isLoading: true,
  searchCondition: new SearchCondition(),
  competitors: new Competitors(),
  competitor: null,
}) {}

export const competitorReducer = reducerWithInitialState(new CompetitorState())
  .case(Actions.setIsPreparedPage, (state, payload) => {
    return state.set('isPreparedPage', payload);
  })
  .case(Actions.setIsLoading, (state, payload) => {
    return state.set('isLoading', payload);
  })
  .case(Actions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(Actions.setCompetitors, (state, payload) => {
    return state.set('competitors', payload);
  })
  .case(Actions.setIsPreparedDetailPage, (state, payload) => {
    return state.set('isPreparedDetailPage', payload);
  })
  .case(Actions.setCompetitor, (state, payload) => {
    return state.set('competitor', payload);
  });
