import React, { useCallback, useMemo } from 'react';

import { Dayjs } from 'dayjs';
import { Set as ImmutableSet } from 'immutable';
import { Icon as SemanticUIIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { MapSearchRankHelp as Help } from 'helpers/ContextHelp';
import {
  MapSearchRankCompetitorAverageTableData,
  MapSearchRankCompetitorAverageTableItem,
} from 'models/Domain/MapSearchRank/MapSearchRankCompetitorAverage';
import { Sort, SortKey } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { COLOR } from 'style/color';

import {
  CheckboxCell,
  CheckboxWrapper,
  CountText,
  Diff,
  LoadingWrapper,
  NumberTableCell,
  RankContainer,
  RankDateLabel,
  TableHeaderFlexWrapper,
  convertNumberToText,
} from './MapSearchRankTable';

type Props = {
  className?: string;
  isLoading: boolean;
  isEnabledComparison: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  selectedTags: ImmutableSet<string>;
  sort: Sort;
  tableData: MapSearchRankCompetitorAverageTableData;
  onChangeSort: (sortKey: SortKey) => void;
  onSelectTag: (tag: string) => void;
  tagColors: Record<string, string>;
};

const DATE_FORMAT = 'YYYY/MM/DD';

export const MapSearchRankCompetitorAverageTable = React.memo<Props>(
  ({
    className,
    isLoading,
    isEnabledComparison,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    selectedTags,
    sort,
    onChangeSort,
    onSelectTag,
    tableData,
    tagColors,
  }) => {
    const sortedTableItems = useMemo(() => {
      const sortStatus = sort.items.first(null);
      return sortStatus ? tableData.sortedItems(sortStatus.key, sortStatus.order) : tableData.items;
    }, [sort, tableData]);
    const totalCount = sortedTableItems.size;

    return (
      <Wrapper className={className}>
        <CountText>{`${totalCount.toLocaleString()}件の検索結果`}</CountText>
        <TableWrapper>
          <StyledTable unstackable={true} isLoading={isLoading} totalCount={totalCount}>
            <StyledTableHeader>
              <TableHeaderRow>
                <ColorHeaderCell />
                <CheckboxHeaderCell>表示</CheckboxHeaderCell>
                <StyledSortableTableHeaderCell onClick={() => onChangeSort('tag')}>
                  <TableHeaderFlexWrapper position={'left'}>
                    {sort.hasSortKey('tag') && <SortTriangle isSortOrderDesc={sort.getSortOrder('tag') === 'desc'} />}
                    集計対象
                  </TableHeaderFlexWrapper>
                </StyledSortableTableHeaderCell>
                <StoreCountHeaderCell>
                  <TableHeaderFlexWrapper position={'right'}>店舗数</TableHeaderFlexWrapper>
                </StoreCountHeaderCell>
                <StyledSortableTableHeaderCell onClick={() => onChangeSort('rank')}>
                  <TableHeaderFlexWrapper position={'right'}>
                    {sort.hasSortKey('rank') && <SortTriangle isSortOrderDesc={sort.getSortOrder('rank') === 'desc'} />}
                    <RankContainer>
                      平均掲載順位
                      {isEnabledComparison && (
                        <RankDateLabel>
                          {startDate && startDate.format(DATE_FORMAT)}〜{endDate && endDate.format(DATE_FORMAT)}
                        </RankDateLabel>
                      )}
                    </RankContainer>
                    <ContextHelp content={Help.rank} />
                  </TableHeaderFlexWrapper>
                </StyledSortableTableHeaderCell>
                {!isEnabledComparison && (
                  <>
                    <StyledSortableTableHeaderCell onClick={() => onChangeSort('latest_rank')}>
                      <TableHeaderFlexWrapper position={'right'}>
                        {sort.hasSortKey('latest_rank') && (
                          <SortTriangle isSortOrderDesc={sort.getSortOrder('latest_rank') === 'desc'} />
                        )}
                        <RankContainer>最新掲載順位</RankContainer>
                        <ContextHelp content={Help.latestRank} />
                      </TableHeaderFlexWrapper>
                    </StyledSortableTableHeaderCell>
                    <StyledSortableTableHeaderCell onClick={() => onChangeSort('diff')}>
                      <TableHeaderFlexWrapper position={'right'}>
                        {sort.hasSortKey('diff') && (
                          <SortTriangle isSortOrderDesc={sort.getSortOrder('diff') === 'desc'} />
                        )}
                        集計期間の推移
                        <ContextHelp content={Help.diff} />
                      </TableHeaderFlexWrapper>
                    </StyledSortableTableHeaderCell>
                  </>
                )}
                {isEnabledComparison && (
                  <>
                    <StyledSortableTableHeaderCell onClick={() => onChangeSort('rank_comparison')}>
                      <TableHeaderFlexWrapper position={'right'}>
                        {sort.hasSortKey('rank_comparison') && (
                          <SortTriangle isSortOrderDesc={sort.getSortOrder('rank_comparison') === 'desc'} />
                        )}
                        <RankContainer>
                          平均掲載順位
                          <RankDateLabel>
                            {comparisonStartDate && comparisonStartDate.format(DATE_FORMAT)}〜
                            {comparisonEndDate && comparisonEndDate.format(DATE_FORMAT)}
                          </RankDateLabel>
                        </RankContainer>
                        <ContextHelp content={Help.comparisonRank} />
                      </TableHeaderFlexWrapper>
                    </StyledSortableTableHeaderCell>
                    <StyledSortableTableHeaderCell onClick={() => onChangeSort('diff_comparison')}>
                      <TableHeaderFlexWrapper position={'right'}>
                        {sort.hasSortKey('diff_comparison') && (
                          <SortTriangle isSortOrderDesc={sort.getSortOrder('diff_comparison') === 'desc'} />
                        )}
                        比較期間との差分
                        <ContextHelp content={Help.diffComparison} />
                      </TableHeaderFlexWrapper>
                    </StyledSortableTableHeaderCell>
                  </>
                )}
              </TableHeaderRow>
            </StyledTableHeader>
            <TableBody>
              {sortedTableItems.map((item, index) => (
                <ItemRow
                  key={index}
                  isEnabledComparison={isEnabledComparison}
                  isSelected={selectedTags.contains(item.tag)}
                  tableItem={item}
                  onSelect={onSelectTag}
                  color={tagColors[item.tag]}
                />
              ))}
            </TableBody>
          </StyledTable>
          {isLoading && (
            <StyledLoadingWrapper>
              <Loader active={true} size='big' inline={true} />
            </StyledLoadingWrapper>
          )}
        </TableWrapper>
      </Wrapper>
    );
  },
);

type RowProps = {
  isEnabledComparison: boolean;
  isSelected: boolean;
  tableItem: MapSearchRankCompetitorAverageTableItem;
  onSelect: (tag: string) => void;
  color: string;
};

const ItemRow = React.memo<RowProps>(({ isEnabledComparison, isSelected, tableItem, onSelect, color }) => {
  const handleOnClick = useCallback(() => {
    onSelect(tableItem.tag);
  }, [onSelect, tableItem.tag]);
  return (
    <TableRow style={{ background: tableItem.isCompetitor ? 'white' : 'rgba(207, 242, 230, 0.4)' }}>
      <ColorCell color={color} />
      <CheckboxCell>
        <CheckboxWrapper>
          <SmallCheckBox checked={isSelected} onClick={handleOnClick} />
        </CheckboxWrapper>
      </CheckboxCell>
      <TableCell>
        {tableItem.isCompetitor ? (
          <Tag>
            <SemanticUIIcon name='tag' color='grey' />
            {tableItem.tag}
          </Tag>
        ) : (
          '自店舗'
        )}
      </TableCell>
      <StoreCountCell>{tableItem.storeCount}</StoreCountCell>
      <NumberTableCell>{tableItem.isRankUnranked() ? '圏外' : convertNumberToText(tableItem.rank)}</NumberTableCell>
      {!isEnabledComparison && (
        <>
          <NumberTableCell>
            {tableItem.isRankUnranked() ? '圏外' : convertNumberToText(tableItem.latestRank, '圏外')}
          </NumberTableCell>
          <NumberTableCell>
            <Diff value={tableItem.diff}>{convertNumberToText(tableItem.diff)}</Diff>
          </NumberTableCell>
        </>
      )}
      {isEnabledComparison && (
        <>
          <NumberTableCell>
            {/* 最新期間の順位はデータのある期間の最新なので、rankがnullの場合は「圏外」の場合のみ（データ欠落ではない） */}
            {convertNumberToText(tableItem.rankComparison)}
          </NumberTableCell>
          <NumberTableCell>
            <Diff value={tableItem.diffComparison}>{convertNumberToText(tableItem.diffComparison)}</Diff>
          </NumberTableCell>
        </>
      )}
    </TableRow>
  );
});

const Wrapper = styled.div``;

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100px;
`;

const StyledTable = styled(Table)<{ isLoading: boolean; totalCount: number }>`
  &&& {
    border-collapse: separate;
    padding-bottom: ${(props) => (props.isLoading && props.totalCount === 0 ? '200px' : '0')};
  }
`;

const StyledTableHeader = styled(TableHeader)`
  &&& {
    position: sticky;
    top: 141px;
    z-index: 1;
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)`
  min-width: 180px;
  max-width: 180px;
`;

export const CheckboxHeaderCell = styled(TableHeaderCell)`
  &&& {
    min-width: 54px;
  }
`;

// 色付けのためのセル
const ColorHeaderCell = styled(TableHeaderCell)`
  width: 8px;
  padding: 0 !important;
`;

const ColorCell = styled(TableCell)<{ color?: string }>`
  width: 8px;
  padding: 0 !important;
  background: ${({ color }) => color ?? 'transparent'};
`;

const StoreCountHeaderCell = styled(TableHeaderCell)``;

const StoreCountCell = styled(NumberTableCell)`
  &&& {
    min-width: 72px;
    max-width: 72px;
  }
`;

export const StyledLoadingWrapper = styled(LoadingWrapper)`
  background: ${COLOR.GRAY};
`;

const Tag = styled.div`
  display: inline-block;
  margin-right: 16px;
`;
