import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { GraphSettingsHelp as Help } from 'helpers/ContextHelp';
import { GraphSettings } from 'models/Domain/GraphSettings';
import { COLOR } from 'style/color';
import { SelectOption } from 'types/Common';

type MinValueOption = 'auto' | 'dataMin' | 'value';
type MaxValueOption = 'auto' | 'dataMax' | 'value';

type Props = {
  settings: GraphSettings;
  secondarySettings?: GraphSettings;
  primaryLabel?: string;
  secondaryLabel?: string;
  isOpen: boolean;
  onClose: () => void;
  onChange?: (primarySettings: GraphSettings, secondarySettings?: GraphSettings) => void;
  multiple?: boolean;
};

const minValueOptions: SelectOption<MinValueOption>[] = [
  { text: '自動', value: 'auto' },
  { text: '任意の値', value: 'value' },
  { text: 'データの最小値', value: 'dataMin' },
];

const maxValueOptions: SelectOption<MaxValueOption>[] = [
  { text: '自動', value: 'auto' },
  { text: '任意の値', value: 'value' },
  { text: 'データの最大値', value: 'dataMax' },
];

const isValidValue = (value: string) => {
  return /^(0|-?[1-9]\d*)$/.test(value);
};

export const GraphSettingsModal = React.memo<Props>(
  ({
    settings: primarySettings,
    secondarySettings,
    primaryLabel = '左軸',
    secondaryLabel = '右軸',
    multiple = false,
    isOpen,
    onClose,
    onChange,
  }) => {
    // 最小値・最大値の種別
    const [primaryMinValueType, setPrimaryMinValueType] = useState<MinValueOption>('auto');
    const [primaryMaxValueType, setPrimaryMaxValueType] = useState<MaxValueOption>('auto');
    // 入力中の最小値・最大値（不正な値を含む）
    const [primaryInputMin, setPrimaryInputMin] = useState('');
    const [primaryInputMax, setPrimaryInputMax] = useState('');
    // 入力された最小値・最大値（不正な値の場合は"自動"）
    const [primaryMinValue, setPrimaryMinValue] = useState<number | null>(null);
    const [primaryMaxValue, setPrimaryMaxValue] = useState<number | null>(null);

    // 最小値・最大値の種別
    const [secondaryMinValueType, setSecondaryMinValueType] = useState<MinValueOption>('auto');
    const [secondaryMaxValueType, setSecondaryMaxValueType] = useState<MaxValueOption>('auto');
    // 入力中の最小値・最大値（不正な値を含む）
    const [secondaryInputMin, setSecondaryInputMin] = useState('');
    const [secondaryInputMax, setSecondaryInputMax] = useState('');
    // 入力された最小値・最大値（不正な値の場合は"自動"）
    const [secondaryMinValue, setSecondaryMinValue] = useState<number | null>(null);
    const [secondaryMaxValue, setSecondaryMaxValue] = useState<number | null>(null);

    useEffect(() => {
      // primary-min
      if (typeof primarySettings.min === 'number') {
        setPrimaryMinValueType('value');
        setPrimaryInputMin(`${primarySettings.min}`);
        setPrimaryMinValue(primarySettings.min);
      } else {
        setPrimaryMinValueType(primarySettings.min);
        setPrimaryInputMin('');
        setPrimaryMinValue(null);
      }

      // primary-max
      if (typeof primarySettings.max === 'number') {
        setPrimaryMaxValueType('value');
        setPrimaryInputMax(`${primarySettings.max}`);
        setPrimaryMaxValue(primarySettings.max);
      } else {
        setPrimaryMaxValueType(primarySettings.max);
        setPrimaryInputMax('');
        setPrimaryMaxValue(null);
      }

      if (!secondarySettings) {
        return;
      }

      // secondary-min
      if (typeof secondarySettings.min === 'number') {
        setSecondaryMinValueType('value');
        setSecondaryInputMin(`${secondarySettings.min}`);
        setSecondaryMinValue(secondarySettings.min);
      } else {
        setSecondaryMinValueType(secondarySettings.min);
        setSecondaryInputMin('');
        setSecondaryMinValue(null);
      }

      // secondary-max
      if (typeof secondarySettings.max === 'number') {
        setSecondaryMaxValueType('value');
        setSecondaryInputMax(`${secondarySettings.max}`);
        setSecondaryMaxValue(secondarySettings.max);
      } else {
        setSecondaryMaxValueType(secondarySettings.max);
        setSecondaryInputMax('');
        setSecondaryMaxValue(null);
      }
    }, [primarySettings, secondarySettings]);

    const handleOnApply = useCallback(() => {
      // 任意の値がからの場合は「自動」扱い
      const primaryResultMin =
        primaryMinValueType === 'value' ? (primaryMinValue != null ? primaryMinValue : 'auto') : primaryMinValueType;
      const primaryResultMax =
        primaryMaxValueType === 'value' ? (primaryMaxValue != null ? primaryMaxValue : 'auto') : primaryMaxValueType;
      const secondaryResultMin =
        secondaryMinValueType === 'value'
          ? secondaryMinValue != null
            ? secondaryMinValue
            : 'auto'
          : secondaryMinValueType;
      const secondaryResultMax =
        secondaryMaxValueType === 'value'
          ? secondaryMaxValue != null
            ? secondaryMaxValue
            : 'auto'
          : secondaryMaxValueType;
      onChange &&
        onChange(
          primarySettings.merge({ min: primaryResultMin, max: primaryResultMax }),
          secondarySettings?.merge({ min: secondaryResultMin, max: secondaryResultMax }),
        );
      onClose();
    }, [
      primaryMaxValue,
      primaryMaxValueType,
      primaryMinValue,
      primaryMinValueType,
      primarySettings,
      secondaryMaxValue,
      secondaryMaxValueType,
      secondaryMinValue,
      secondaryMinValueType,
      secondarySettings,
      onChange,
      onClose,
    ]);

    const handleOnChangePrimaryMinValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定
      setPrimaryInputMin(inputValue);
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setPrimaryMinValue(value);
      } else {
        setPrimaryMinValue(null);
      }
    }, []);

    const handleOnChangePrimaryMaxValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定
      setPrimaryInputMax(inputValue);
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setPrimaryMaxValue(value);
      } else {
        setPrimaryMaxValue(null);
      }
    }, []);

    const handleOnAddPrimaryMinValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定、そうでなければ自動に切り替える
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setPrimaryMinValue(value);
        setPrimaryMinValueType('value');
        setPrimaryInputMin(inputValue);
      } else {
        setPrimaryMinValue(null);
        setPrimaryMinValueType('auto');
      }
    }, []);

    const handleOnAddPrimaryMaxValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定、そうでなければ自動に切り替える
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setPrimaryMaxValue(value);
        setPrimaryMaxValueType('value');
        setPrimaryInputMax(inputValue);
      } else {
        setPrimaryMaxValue(null);
        setPrimaryMaxValueType('auto');
      }
    }, []);

    const handleOnBlurPrimaryMinValue = useCallback(() => {
      // onBlurのタイミングで入力値を有効な数値または空にする
      setPrimaryInputMin(primaryMinValue == null ? '' : `${primaryMinValue}`);
    }, [primaryMinValue]);

    const handleOnBlurPrimaryMaxValue = useCallback(() => {
      // onBlurのタイミングで入力値を有効な数値または空にする
      setPrimaryInputMax(primaryMaxValue == null ? '' : `${primaryMaxValue}`);
    }, [primaryMaxValue]);

    const handleOnChangeSecondaryMinValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定
      setSecondaryInputMin(inputValue);
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setSecondaryMinValue(value);
      } else {
        setSecondaryMinValue(null);
      }
    }, []);

    const handleOnChangeSecondaryMaxValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定
      setSecondaryInputMax(inputValue);
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setSecondaryMaxValue(value);
      } else {
        setSecondaryMaxValue(null);
      }
    }, []);

    const handleOnAddSecondaryMinValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定、そうでなければ自動に切り替える
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setSecondaryMinValue(value);
        setSecondaryMinValueType('value');
        setSecondaryInputMin(inputValue);
      } else {
        setSecondaryMinValue(null);
        setSecondaryMinValueType('auto');
      }
    }, []);

    const handleOnAddSecondaryMaxValue = useCallback((inputValue: string) => {
      // 有効な値の場合は任意の値として設定、そうでなければ自動に切り替える
      if (isValidValue(inputValue)) {
        const value = Number.parseInt(inputValue, 10);
        setSecondaryMaxValue(value);
        setSecondaryMaxValueType('value');
        setSecondaryInputMax(inputValue);
      } else {
        setSecondaryMaxValue(null);
        setSecondaryMaxValueType('auto');
      }
    }, []);

    const handleOnBlurSecondaryMinValue = useCallback(() => {
      // onBlurのタイミングで入力値を有効な数値または空にする
      setSecondaryInputMin(secondaryMinValue == null ? '' : `${secondaryMinValue}`);
    }, [secondaryMinValue]);

    const handleOnBlurSecondaryMaxValue = useCallback(() => {
      // onBlurのタイミングで入力値を有効な数値または空にする
      setSecondaryInputMax(secondaryMaxValue == null ? '' : `${secondaryMaxValue}`);
    }, [secondaryMaxValue]);

    return (
      <StyledModal open={isOpen}>
        <ModalContent>
          <Title>グラフの表示設定</Title>
          <ContentWrapper>
            <Group>
              {multiple && <Heading>{primaryLabel}</Heading>}
              <FlexContent>
                <Label>
                  最小値
                  <ContextHelp content={Help.min} />
                </Label>
                <PullDown
                  options={minValueOptions}
                  value={primaryMinValueType}
                  allowAdditions={true}
                  additionLabel={'任意の値: '}
                  onChange={setPrimaryMinValueType}
                  onAddItem={handleOnAddPrimaryMinValue}
                />
                {primaryMinValueType === 'value' && (
                  <StyledInput
                    value={primaryInputMin}
                    onBlur={handleOnBlurPrimaryMinValue}
                    onChange={handleOnChangePrimaryMinValue}
                  />
                )}
              </FlexContent>
              <FlexContent>
                <Label>
                  最大値
                  <ContextHelp content={Help.max} />
                </Label>
                <PullDown
                  options={maxValueOptions}
                  value={primaryMaxValueType}
                  allowAdditions={true}
                  additionLabel={'任意の値: '}
                  onChange={setPrimaryMaxValueType}
                  onAddItem={handleOnAddPrimaryMaxValue}
                />
                {primaryMaxValueType === 'value' && (
                  <StyledInput
                    value={primaryInputMax}
                    onBlur={handleOnBlurPrimaryMaxValue}
                    onChange={handleOnChangePrimaryMaxValue}
                  />
                )}
              </FlexContent>
            </Group>
            {multiple && (
              <Group>
                <Heading>{secondaryLabel}</Heading>
                <FlexContent>
                  <Label>
                    最小値
                    <ContextHelp content={Help.min} />
                  </Label>
                  <PullDown
                    options={minValueOptions}
                    value={secondaryMinValueType}
                    allowAdditions={true}
                    additionLabel={'任意の値: '}
                    onChange={setSecondaryMinValueType}
                    onAddItem={handleOnAddSecondaryMinValue}
                  />
                  {secondaryMinValueType === 'value' && (
                    <StyledInput
                      value={secondaryInputMin}
                      onBlur={handleOnBlurSecondaryMinValue}
                      onChange={handleOnChangeSecondaryMinValue}
                    />
                  )}
                </FlexContent>
                <FlexContent>
                  <Label>
                    最大値
                    <ContextHelp content={Help.max} />
                  </Label>
                  <PullDown
                    options={maxValueOptions}
                    value={secondaryMaxValueType}
                    allowAdditions={true}
                    additionLabel={'任意の値: '}
                    onChange={setSecondaryMaxValueType}
                    onAddItem={handleOnAddSecondaryMaxValue}
                  />
                  {secondaryMaxValueType === 'value' && (
                    <StyledInput
                      value={secondaryInputMax}
                      onBlur={handleOnBlurSecondaryMaxValue}
                      onChange={handleOnChangeSecondaryMaxValue}
                    />
                  )}
                </FlexContent>
              </Group>
            )}
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>閉じる</StyledButton>
            <StyledButton onClick={handleOnApply} priority={'high'}>
              適用
            </StyledButton>
          </ButtonWrapper>
        </ModalContent>
      </StyledModal>
    );
  },
);

const StyledModal = styled(Modal)``;

const StyledButton = styled(Button)`
  &&& {
    width: 140px;
  }
`;

const ModalContent = styled(Modal.Content)``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    & .ui.search.selection.dropdown {
      padding: 0 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
      width: 144px;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 0 6px !important;
    }
    & .ui.search.selection.dropdown > .text {
      font-size: 14px;
      top: 1px;
    }
  }
`;

const StyledInput = styled(Input)`
  &&& {
    width: 144px;
    input {
      height: 33px;
      padding-left: 8px;
      border-radius: 8px;
      border: solid 1px ${COLOR.GRAY};
      font-size: 13px;
    }
  }
`;

const FlexContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Group = styled.div``;
