import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { OfferGroupsApi } from 'ApiClient/OfferGroupsApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { OfferGroups } from 'models/Domain/OfferGroup/OfferGroup';
import { OfferGroupsSearchCondition } from 'models/Domain/OfferGroup/OfferGroupSearchCondition';
import { UserList } from 'models/Domain/User';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { State } from '../reducers';

import { OfferGroupsActions } from './actions';

export default function* saga() {
  yield takeLatest(OfferGroupsActions.fetchOfferGroups, fetchOfferGroups);
  yield takeLatest(OfferGroupsActions.initializeOfferGroupsPage, initializePromotionListPage);
  yield takeLatest(LOCATION_CHANGE, promotionListLocationChange);
  yield takeLatest(OfferGroupsActions.setSearchCondition, setSearchCondition);
}

function* fetchOfferGroups(action: ReturnType<typeof OfferGroupsActions.fetchOfferGroups>) {
  yield put(OfferGroupsActions.setIsLoading(true));
  const searchCondition: OfferGroupsSearchCondition = yield select(
    (state: State) => state.offerGroups.offerGroupsSearchCondition,
  );
  const response: YieldReturn<typeof OfferGroupsApi.get> = yield OfferGroupsApi.get(searchCondition.toRequestParams());
  if (response.isSuccess) {
    const offerGroups = new OfferGroups(response.data);
    yield put(OfferGroupsActions.setCommittedSearchCondition(searchCondition));
    yield put(OfferGroupsActions.setOfferGroups(offerGroups));
  } else {
    toast({
      type: 'error',
      title: '依頼報告一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(OfferGroupsActions.setIsLoading(false));
}

// OfferGroups一覧ページを初期化する
function* initializePromotionListPage(action: ReturnType<typeof OfferGroupsActions.initializeOfferGroupsPage>) {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  // URLパラメータから検索条件を復元する
  yield put(OfferGroupsActions.setIsPreparedForOfferGroupsPage(true));
  yield call(updatePromotionsByLocation);
}

/**
 * URLからOfferGroups一覧を更新する
 *
 * 本処理は、以下の２処理から呼ばれる
 * - initializeOfferGroupsPage
 * - offerGroupsLocationChange
 */
function* updatePromotionsByLocation() {
  const location: Location = yield select((state: RouterRootState) => state.router.location);
  const userList: UserList = yield select((state) => state.user.userList);

  // URLパラメータから検索条件を復元する
  let searchCondition = OfferGroupsSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.store });
  searchCondition = searchCondition.setIn(['filter', 'store'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.filter.store,
    containsClosedStores: searchCondition.filter.show_closed_store,
  });

  const currentStoreIds = searchCondition.filter.store_ids;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  // 組織に属する全てのユーザー
  const allUserIds = Set(userList.getUserIds());
  // 指定されているユーザーは組織に属するユーザー以外を除外する
  const createdByUserIds = searchCondition.filter.created_by.intersect(allUserIds);
  if (searchCondition.filter.is_all_users_created_by || createdByUserIds.equals(allUserIds)) {
    // 作成者が全ての場合、または個別指定されているユーザーが組織に存在する全員の場合、created_byでの絞り込みの値は指定しない（空にする）
    searchCondition = searchCondition.update('filter', (filter) => filter.setCreatedByUserIds(Set<number>(), true));
  } else {
    // ユーザーが個別指定の場合、組織の人間に絞り込まれた値で更新する
    searchCondition = searchCondition.update('filter', (filter) => filter.setCreatedByUserIds(createdByUserIds, false));
  }

  // URLと検索条件から生成されるURLが異なる場合（不要なパラメータがある場合など）、正しいURLにreplace
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // 検索条件のセットと、OfferGroupsの取得
  yield put(OfferGroupsActions.setSearchCondition({ searchCondition, updateLocation: false }));
  yield put(OfferGroupsActions.fetchOfferGroups());
}

// OfferGroupsページにおけるURL（パラメータ）変更時の処理
function* promotionListLocationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // パスがOfferGroupsページ以外の場合は対象外
  if (location.pathname !== Path.offerGroups.index) {
    return;
  }

  // OfferGroupsページの初期処理が完了していない場合は何もしない
  const isPreparedForOfferGroupsPage: boolean = yield select((state) => state.offerGroups.isPreparedForOfferGroupsPage);
  if (!isPreparedForOfferGroupsPage) {
    return;
  }

  yield call(updatePromotionsByLocation);
}

function* setSearchCondition(action: ReturnType<typeof OfferGroupsActions.setSearchCondition>) {
  const { searchCondition, updateLocation = true } = action.payload;

  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // updateLocationがtrueの場合、かつ既存とURLに差分がある場合、URLの更新を行う
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
  }
}
