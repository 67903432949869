import { useEffect } from 'react';

/**
 * 関数を1度だけ実行する
 *
 * useEffectの第2引数を空にするとcomponentDidMount相当になるが、
 * 依存している変数があるとESLintのexhaustive-depsに引っかかるため、関数を渡して回避する
 * 参考: https://css-tricks.com/run-useeffect-only-once/
 *
 * @param func 1度だけ実行したい関数
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useEffectOnlyOnce = (func: () => void) => useEffect(func, []);
