import { useEffect, useRef, useState } from 'react';

/**
 * 値がON(true)を指定した時間は保持するようにする
 * @param value 値
 * @param minimumHoldMilliSeconds 最低保証時間
 * @returns
 */
export const useHoldOn = (value: boolean, minimumHoldMilliSeconds: number) => {
  const [currentValue, setCurrentValue] = useState(value);

  const valueRef = useRef(value);
  valueRef.current = value;

  const timerEnabled = useRef(false);

  useEffect(() => {
    if (value) {
      // すでにcurrentValueがtrueになっている場合は何もしない
      if (currentValue) {
        return;
      }

      // currentValueにtrueをセットし、最低時間保証タイマーを作動させる
      setCurrentValue(true);
      timerEnabled.current = true;
      setTimeout(() => {
        timerEnabled.current = false;
        // タイマー終了時に入力されている値がfalseになっている場合、currentValueにfalseをセットする
        if (!valueRef.current) {
          setCurrentValue(false);
        }
      }, minimumHoldMilliSeconds);
    } else {
      // 入力されている値がfalseの場合、currentValueにtrueで最低時間保証タイマーが動いていない場合、currentValueeにfalseをセットする
      if (currentValue && !timerEnabled.current) {
        setCurrentValue(false);
      }
    }
  }, [currentValue, minimumHoldMilliSeconds, value]);

  return currentValue;
};
