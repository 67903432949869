import React from 'react';

import { List as ImmutableList } from 'immutable';
import styled from 'styled-components';

import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';

export type Props = {
  gmbLocation: GmbLocation;
  filterMoreHoursTypes: ImmutableList<MoreHoursType>;
  moreHoursTypes: ImmutableList<MoreHoursType>;
};
export const GmbLocationMoreHoursValue: React.FC<Props> = ({ gmbLocation, filterMoreHoursTypes, moreHoursTypes }) => {
  return (
    <>
      {moreHoursTypes
        .filter((hoursType) => filterMoreHoursTypes.includes(hoursType))
        .map((hoursType, index) => {
          const item = gmbLocation.moreHours.find(hoursType);
          if (!item) {
            return;
          }
          return (
            <MoreHoursWrapper key={index}>
              <MoreHoursTypeLabel>{hoursType.localizedDisplayName}</MoreHoursTypeLabel>
              {item.moreHoursForDisplay.map((text: string, idx: number) => (
                <MoreHoursItem key={idx}>{text}</MoreHoursItem>
              ))}
            </MoreHoursWrapper>
          );
        })}
    </>
  );
};

const MoreHoursWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const MoreHoursTypeLabel = styled.div`
  font-weight: bold;
`;

const MoreHoursItem = styled.div``;
