import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import Menu from 'components/atoms/Menu';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { Table, TableBody } from 'components/molecules/Table';
import {
  DisplayType,
  GbpPerformanceTableData,
  ReviewType,
  SortKey,
  SortOrder,
  SortTarget,
  SortType,
} from 'models/Domain/GbpPerformance/GbpPerformance';
import { GbpPerformanceSearchCondition as SearchCondition } from 'models/Domain/GbpPerformance/GbpPerformanceSearchCondition';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';
import { SelectOption, assertNever } from 'types/Common';

import {
  ActivityTableHeader,
  ImpressionsTableHeader,
  InteractionTableHeader,
  OverviewTableHeader,
} from './TableHeader';
import {
  ActivitiesTableRow,
  ActivitiesWithComparisonTableRow,
  ImpressionsTableRow,
  ImpressionsWithComparisonTableRow,
  InteractionsTableRow,
  InteractionsWithComparisonTableRow,
  OverviewTableRow,
  OverviewWithComparisonTableRow,
} from './TableRow';

export type SortCondition = { key: SortKey; type: SortType; order: SortOrder } | null;

// 開閉可能なカラム
export type OpenableColumn = 'image' | 'promotionLive' | 'promotionRejected' | 'review';

// ソート条件がnullの場合に適用されるソート条件
const defaultSortCondition: SortCondition = { key: 'storeCode', type: 'number', order: 'asc' };

const RateAvailableSortKey = [
  'interactions',
  'callClicks',
  'businessDirectionRequests',
  'websiteClicks',
  'businessConversations',
  'businessBookings',
];

export type GbpPerformanceStoreTableProps = {
  className?: string;
  data: GbpPerformanceTableData;
  stores: Stores;
  displayType: DisplayType;
  searchCondition: SearchCondition;
  isLoading: boolean;
  reviewType: ReviewType;
  onChangeReviewType: (value: ReviewType) => void;
};

const sortTargetOptions: SelectOption<SortTarget>[] = [
  { value: 'current', text: '集計期間の値' },
  { value: 'comparison', text: '比較期間の値' },
  { value: 'diffRate', text: '変化率' },
];

export const GbpPerformanceStoreTable: React.FC<GbpPerformanceStoreTableProps> = ({
  className,
  data,
  stores,
  displayType,
  searchCondition,
  reviewType,
  onChangeReviewType,
  isLoading,
}) => {
  const [sortCondition, setSortCondition] = useState<SortCondition>(null);
  const [sortTarget, setSortTarget] = useState<SortTarget>('current');
  const [openedColumns, setOpendColumns] = useState<Set<OpenableColumn>>(new Set());
  const toggleOpenedColumn = useCallback((column: OpenableColumn) => {
    setOpendColumns((currentOpenedColumns) => {
      const columns = new Set(currentOpenedColumns); // 別オブジェクトにするために新規作成
      if (columns.has(column)) {
        columns.delete(column);
      } else {
        columns.add(column);
      }
      return columns;
    });
  }, []);

  const items = useMemo(() => {
    const defaultItems = data.getDataPairList();

    // ソート条件がnullの場合、デフォルトのソート条件を適用
    const condition = sortCondition || defaultSortCondition;

    if (condition.key === 'storeName' || condition.key === 'storeCode') {
      // 店舗名、店舗コードでソートする場合
      return defaultItems.sortByStoreInfo(condition.key, condition.order, stores);
    }

    // それ以外（値）でソートする場合
    // 並べ替えの種類は、比較期間のデータがない場合は集計期間の値にする
    const target = data.hasComparisonData() ? sortTarget : 'current';
    return defaultItems.sortBy(condition.key, condition.type, condition.order, target);
  }, [data, sortCondition, sortTarget, stores]);

  const total = useMemo(() => data.getTotalDataPair(), [data]);

  const toggleSortKey = useCallback(
    (key: SortKey, type?: SortType) =>
      setSortCondition((sortCondition) => {
        if (sortCondition == null || sortCondition.key !== key) {
          // type指定がある場合はそのタイプを利用。それ以外はnumber
          return { key, type: type || 'number', order: 'desc' };
        }

        // ソートタイプの指定がある場合
        if (type !== undefined) {
          if (sortCondition.type === type) {
            if (sortCondition.order === 'asc') {
              return null;
            }
            return { key, type, order: 'asc' };
          } else {
            return { key, type, order: 'desc' };
          }
        }

        if (displayType === 'interactions' && RateAvailableSortKey.includes(key)) {
          // 「インタラクション数」タブで、ソートキーが「率」ソートが可能な場合、以下の順で繰り返す
          // {type: 'number', order: 'desc'}
          // {type: 'number', order: 'asc'}
          // {type: 'rate', order: 'desc'}
          // {type: 'rate', order: 'asc'}
          // null
          if (sortCondition.order === 'desc') {
            return { key, type: sortCondition.type, order: 'asc' };
          } else {
            if (sortCondition.type === 'number') {
              return { key, type: 'rate', order: 'desc' };
            } else {
              return null;
            }
          }
        } else {
          // それ以外の場合
          if (sortCondition.order === 'desc') {
            return { key, type: 'number', order: 'asc' };
          } else {
            return null;
          }
        }
      }),
    [displayType],
  );

  useEffect(() => {
    // タブが変更された場合に、ソート対象が新しいタブで表示できる場合は維持し、そうでない場合はデフォルトのソート条件に戻す
    const tabColumns: { [displayType in DisplayType]: SortKey[] } = {
      overview: [
        'storeName',
        'storeCode',
        'businessImpressions',
        'interactions',
        'imageCount',
        'promotionCount',
        'periodReviews',
      ],
      impressions: ['storeName', 'storeCode', 'businessImpressions'],
      interactions: ['storeName', 'storeCode', 'interactions'],
      activities: ['storeName', 'storeCode', 'imageCount', 'promotionCount', 'periodReviews'],
    };
    if (sortCondition == null || tabColumns[displayType].includes(sortCondition.key)) {
      return;
    }
    setSortCondition(null);
    // displayTypeが変わった場合だけ対応したいので、depsの警告はここでは無視する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayType]);

  const hasComparisonData = data.hasComparisonData();

  return (
    <Wrapper className={className}>
      <Header>
        <Title>店舗別</Title>
        <PullDownContainer>
          {displayType === 'activities' && (
            <MenuWrapper>
              <MenuLabel>クチコミの集計対象</MenuLabel>
              <Menu>
                <Menu.Item
                  name={'period'}
                  active={reviewType === 'period'}
                  onClick={() => onChangeReviewType('period')}
                  style={{ padding: '0 12px' }}
                >
                  期間
                </Menu.Item>
                <Menu.Item
                  name={'total'}
                  active={reviewType === 'total'}
                  onClick={() => onChangeReviewType('total')}
                  style={{ padding: '0 12px' }}
                >
                  累計
                </Menu.Item>
              </Menu>
            </MenuWrapper>
          )}
          <PullDownLabel>並べ替えの種類</PullDownLabel>
          <PullDown
            disabled={!hasComparisonData /* 比較期間がない場合は変更不可 */}
            value={hasComparisonData ? sortTarget : 'current' /* 比較期間がない場合は「集計期間の値」固定 */}
            options={sortTargetOptions}
            onChange={(value: any) => setSortTarget(value)}
          />
        </PullDownContainer>
      </Header>
      <TableWrapper>
        <Table unstackable>
          {(() => {
            const props = { sortCondition, toggleSortKey };
            switch (displayType) {
              case 'overview':
                return <OverviewTableHeader {...props} />;
              case 'impressions':
                return <ImpressionsTableHeader {...props} />;
              case 'interactions':
                return <InteractionTableHeader {...props} />;
              case 'activities':
                return (
                  <ActivityTableHeader
                    {...props}
                    openedColumns={openedColumns}
                    toggleOpenedColumn={toggleOpenedColumn}
                    reviewType={reviewType}
                  />
                );
              default:
                return assertNever(displayType);
            }
          })()}

          <TableBody>
            {(() => {
              const props = { item: total, total: true as const, sortCondition, searchCondition };
              if (!hasComparisonData) {
                switch (displayType) {
                  case 'overview':
                    return <OverviewTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsTableRow {...props} />;
                  case 'activities':
                    return <ActivitiesTableRow {...props} reviewType={reviewType} openedColumns={openedColumns} />;
                  default:
                    return assertNever(displayType);
                }
              } else {
                switch (displayType) {
                  case 'overview':
                    return <OverviewWithComparisonTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsWithComparisonTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsWithComparisonTableRow {...props} />;
                  case 'activities':
                    return (
                      <ActivitiesWithComparisonTableRow
                        {...props}
                        reviewType={reviewType}
                        openedColumns={openedColumns}
                      />
                    );
                  default:
                    return assertNever(displayType);
                }
              }
            })()}

            {items.list.map((item) => {
              const storeId = item.target.storeId;
              const store = stores.findStore(storeId);
              if (!store) {
                return;
              }

              const props = { key: storeId, item, store, sortCondition, searchCondition };
              if (!hasComparisonData) {
                switch (displayType) {
                  case 'overview':
                    return <OverviewTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsTableRow {...props} />;
                  case 'activities':
                    return <ActivitiesTableRow {...props} reviewType={reviewType} openedColumns={openedColumns} />;
                  default:
                    return assertNever(displayType);
                }
              } else {
                switch (displayType) {
                  case 'overview':
                    return <OverviewWithComparisonTableRow {...props} />;
                  case 'impressions':
                    return <ImpressionsWithComparisonTableRow {...props} />;
                  case 'interactions':
                    return <InteractionsWithComparisonTableRow {...props} />;
                  case 'activities':
                    return (
                      <ActivitiesWithComparisonTableRow
                        {...props}
                        reviewType={reviewType}
                        openedColumns={openedColumns}
                      />
                    );
                  default:
                    return assertNever(displayType);
                }
              }
            })}
          </TableBody>
        </Table>
        {isLoading && (
          <LoadingWrapper>
            <Loader active={isLoading} size={'big'} inline={true} />
          </LoadingWrapper>
        )}
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Header = styled.div`
  display: flex;
  align-items: center;
  height: 33px;
  margin-bottom: 16px;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;
const PullDownLabel = styled.div`
  padding-top: 4px;
  margin-right: 8px;
`;
const PullDownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 14px;
`;

const PullDown = styled(PullDownNarrow)`
  &&& {
    width: 176px;
    & .ui.search.selection.dropdown {
      padding: 0 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 0 6px !important;
    }
    & .ui.search.selection.dropdown > .text {
      font-size: 14px;
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  gap: 8px;
`;

const MenuLabel = styled.div`
  font-size: 14px;
`;
