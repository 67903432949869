import { useCallback, useEffect, useState } from 'react';

/**
 * デスクトップ表示かモバイル表示か
 */
export class DisplayType {
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  get isDesktop(): boolean {
    return this.name === 'desktop';
  }

  get isMobile(): boolean {
    return this.name === 'mobile';
  }
}

/**
 * ウィンドウサイズからデバイス判定をする
 *
 * ページコンテンツによって最適なウィンドウサイズが異なるので閾値は指定できるようにしている
 * （デフォルトはグローバルメニューが非表示になる600px）
 * STORECASTではmax-widthでモバイル表示の場合のスタイルを指定している箇所が多いので、
 * 指定した数字より大きい場合にデスクトップ表示と判定する（指定された数字の場合はモバイル表示判定となる）
 *
 * @param width デスクトップ判定とモバイル判定を切り替えるサイズ（指定値まではモバイル表示）
 */
export const useDisplayType = (width = '600px') => {
  const [device, setDevice] = useState(getDevice(width));

  // ウィンドウサイズが変わった際の処理（デバイス判定の更新）
  const handleResize = useCallback(() => {
    setDevice(getDevice(width));
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return device;
};

function getDevice(width: string): DisplayType {
  const isMobile = window.matchMedia(`(max-width: ${width})`).matches;
  return new DisplayType(isMobile ? 'mobile' : 'desktop');
}
