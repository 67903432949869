import React, { useCallback, useEffect } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { OfferNewForm } from 'components/pageComponents/OfferCreate/OfferNewForm';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { OfferStores } from 'models/Composite/OfferStores';
import { AppActions } from 'modules/app/actions';
import { OfferActions } from 'modules/offer/actions';
import { State } from 'modules/reducers';

export const OfferCreate = React.memo(() => {
  const currentUser = useSelector((state: State) => state.app.currentUser);
  const newOffer = useSelector((state: State) => state.offer.newOffer);

  const dispatch = useDispatch();
  const changeNewOffer = useCallback((v: OfferStores) => dispatch(OfferActions.changeNewOffer(v)), [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(OfferActions.clearNewOffer());
    };
  }, [dispatch]);

  useEffect(() => {
    const isValidOfferType = newOffer.isValidOfferTypeFromUser(currentUser);
    if (!isValidOfferType) {
      changeNewOffer(newOffer.changeOfferTypeFromUser(currentUser));
    }
  }, [changeNewOffer, currentUser, newOffer]);

  useEffect(() => {
    if (!newOffer.storeId && currentUser.stores.size > 0) {
      changeNewOffer(newOffer.changeDefaultSenderStoreFromUser(currentUser));
    }
  }, [changeNewOffer, currentUser, newOffer]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('依頼 / 報告を作成')} />
      <StickyHeader title='依頼 / 報告を作成' />
      <Body>
        <OfferNewForm
          offerStores={newOffer}
          changeOfferStores={changeNewOffer}
          handleAcceptedFiles={(files) => dispatch(OfferActions.handleNewOfferAcceptedFiles(files))}
          handleRejectedFiles={(files) => dispatch(AppActions.onDropRejected(files))}
        />
        <ButtonsWrapper>
          <CreateButton
            priority='high'
            onClick={() => dispatch(OfferActions.sendNewOffer())}
            disabled={!newOffer.isValid(currentUser)}
          >
            作成
          </CreateButton>
          <CancelButton onClick={() => dispatch(goBack())}>キャンセル</CancelButton>
        </ButtonsWrapper>
      </Body>
    </MainWrapper>
  );
});

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-flow: row-reverse;
  margin-top: 32px;
  @media (max-width: 1024px) {
    display: block;
    margin-top: 0;
  }
`;

const CreateButton = styled(Button)`
  &&& {
    margin-left: 16px;
    @media (max-width: 1024px) {
      margin-top: 16px;
      margin-left: 0;
      width: 100%;
    }
  }
`;

const CancelButton = styled(Button)`
  &&& {
    @media (max-width: 1024px) {
      margin-top: 16px;
      width: 100%;
    }
  }
`;
