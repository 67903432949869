import dayjs, { Dayjs } from 'dayjs';
import { Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { createHashtagText, createUniqueArray } from 'helpers/utils';
import { JSObject } from 'types/Common';

import { CommonContent } from './CommonContent';

interface OtherOfferRecord {
  id: number;
  name: string;
  due_date: Dayjs;
  content: CommonContent;
}

export class OtherOffer extends Record<OtherOfferRecord>({
  id: 0,
  name: '',
  due_date: dayjs().add(1, 'week').hour(23).minute(59), // 1週間後の23:59に設定
  content: new CommonContent(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.content) {
      params.content = new CommonContent(params.content);
    }
    if (params.due_date) {
      params.due_date = dayjs.unix(params.due_date);
    }
    super(params);
  }

  get dueDateString() {
    return this.due_date && this.due_date.format('MM/DD');
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  get provisionalHashtag() {
    let tags = [];
    const name = createHashtagText(this.name);
    if (name) {
      tags.push(name);
    }
    tags = tags.concat(this.content.provisionalHashtag);
    return createUniqueArray(tags);
  }

  getLabel(key: 'name' | 'due_date') {
    switch (key) {
      case 'name':
        return 'タイトル';
      case 'due_date':
        return '期限';
      default:
        return '';
    }
  }

  changeName(name: string) {
    return this.set('name', name);
  }

  changeText(text: string) {
    return this.update('content', (content) => content.changeText(text));
  }

  changeTags(tags: string[]) {
    return this.update('content', (content) => content.changeTags(tags));
  }

  addFiles(urls: string[]) {
    return this.update('content', (content) => content.addFiles(urls));
  }

  deleteFile(index: number) {
    return this.update('content', (content) => content.deleteFile(index));
  }

  changeFileCaption(index: number, caption: string) {
    return this.update('content', (content) => content.changeFileCaption(index, caption));
  }

  changeDueDate(date: Dayjs) {
    return this.set('due_date', date);
  }

  validate() {
    const errors: JSObject = {};

    if (!this.name || !this.name.trim()) {
      errors.name = ErrorType.REQUIRED_ERROR;
    }

    if (!this.due_date) {
      errors.due_date = ErrorType.REQUIRED_ERROR;
    } else if (this.due_date < dayjs()) {
      errors.due_date = ErrorType.PAST_TIME_ERROR;
    }

    return errors;
  }

  requestParams() {
    const { id, name } = this.toObject();
    const params = {
      id: id < 1 ? null : id,
      name,
      due_date: this.due_date.unix(),
      content: this.content.changeTags(this.provisionalHashtag).requestParams(),
    };
    return params;
  }
}
