import React from 'react';

import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { SIZE } from 'style/size';

export const StyledTable = styled(Table)`
  &&& {
    width: 100%;
  }
`;

export const TableRow = styled(({ isHover, ...rest }) => <Table.Row {...rest} />)`
  background-color: ${(props) => (props.isHover ? '#f2f2f2' : 'unset')};
`;

export const TableHeader = styled(Table.Header)`
  height: 40px;
  @media (max-width: ${SIZE.MOBILE_TABLE}) {
    height: unset;
  }
`;

export const TableHeaderLabel = styled.div`
  font-family: monospace;
  font-size: 14px;
`;

export const TableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    font-size: 10px;
    padding: 0 8px;
    font-weight: 500;
    text-align: right;
    vertical-align: middle;
    background: white;
  }
`;

export const TableCell = styled(Table.Cell)`
  &&& {
    font-size: 13px;
    padding: 8px;
    text-align: right;
    font-family: monospace;
  }
`;
