import React, { useMemo } from 'react';

import { List as ImmutableList } from 'immutable';
import styled from 'styled-components';

import { MoreHoursList as MoreHoursListModel, MoreHoursType } from 'models/Domain/GmbLocation/MoreHours';

type Props = {
  moreHours: MoreHoursListModel;
  moreHoursTypes: ImmutableList<MoreHoursType>;
};

export const MoreHoursList: React.FC<Props> = ({ moreHours, moreHoursTypes }) => {
  const availableMoreHours = useMemo(
    () =>
      moreHours.list.filter((item) => moreHoursTypes.find((hoursType) => hoursType.hoursTypeId === item.hoursTypeId)),
    [moreHours.list, moreHoursTypes],
  );

  // メインカテゴリに紐づくその他の営業時間の項目が１つもない場合
  if (moreHoursTypes.isEmpty()) {
    return <Wrapper>設定可能な項目がありません</Wrapper>;
  }

  // メインカテゴリに紐づくその他の営業時間が１つも設定されていない場合
  if (availableMoreHours.isEmpty()) {
    return <Wrapper>設定されていません</Wrapper>;
  }

  // メインカテゴリに紐づくその他の営業時間の項目のみ営業時間を表示する
  return (
    <Wrapper>
      {availableMoreHours.map((item) => {
        const hoursType = moreHoursTypes.find((hoursType) => hoursType.hoursTypeId === item.hoursTypeId);
        if (!hoursType) {
          return;
        }
        return (
          <MoreHoursWrapper key={item.hoursTypeId}>
            <Label>{hoursType.localizedDisplayName}</Label>
            {item.moreHoursForDisplay.map((businessHour, index) => (
              <Value key={index}>{businessHour}</Value>
            ))}
          </MoreHoursWrapper>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MoreHoursWrapper = styled.div``;

const Label = styled.label`
  font-weight: bold;
`;

const Value = styled.div``;
