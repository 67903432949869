import React, { useCallback, useEffect, useState } from 'react';

import { replace } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { StickyHeader } from 'components/atoms/StickyHeader';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { EditTabContent } from 'components/pageComponents/StoreListEdit/EditTabContent';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import StoreList from 'models/Domain/StoreList';
import { StoreListActions } from 'modules/storeList/actions';
import { Path } from 'routes';

type Props = RouteComponentProps<{ storeListId: string }>;

export const StoreListEdit = React.memo<Props>(
  ({
    match: {
      params: { storeListId },
    },
  }) => {
    const { storeListState, storeState } = useSelector((state) => ({
      storeListState: state.storeList,
      storeState: state.store,
    }));
    const { storeLists, storeListForEdit } = storeListState;
    const { stores } = storeState;
    const targetStoreList = storeLists.findStoreList(Number(storeListId));

    const dispatch = useDispatch();
    const setStoreListForEdit = useCallback(
      (v: StoreList) => dispatch(StoreListActions.setStoreListForEdit(v)),
      [dispatch],
    );
    const updateStoreList = useCallback(() => dispatch(StoreListActions.updateStoreList()), [dispatch]);
    const deleteStoreList = useCallback((v: number) => dispatch(StoreListActions.deleteStoreList(v)), [dispatch]);

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    useEffect(() => {
      if (targetStoreList && storeListForEdit.id !== targetStoreList.id) {
        setStoreListForEdit(targetStoreList);
      }
      if (!targetStoreList && storeLists.initialized) {
        // storeListsが取得済みなのに、targetStoreListがないなら、
        // 指定されたグループが存在しない/表示権限がないので、一覧ページに遷移する
        dispatch(replace(Path.storeList.index));
      }
    }, [dispatch, setStoreListForEdit, storeListForEdit, storeLists, targetStoreList]);

    if (!targetStoreList) {
      return null;
    }

    return (
      <>
        <Helmet title={getPageTitle('グループを編集')} />
        <MainWrapper>
          <StickyHeader title='グループを編集' />
          <Body>
            <EditTabContent
              storeListForEdit={storeListForEdit}
              targetStoreList={targetStoreList}
              stores={stores}
              setStoreListForEdit={setStoreListForEdit}
              updateStoreList={updateStoreList}
              setIsOpenModal={setIsOpenModal}
            />
          </Body>
        </MainWrapper>

        <ConfirmModal
          text={`グループ「${targetStoreList.name}」を削除しますか？`}
          type='small'
          open={isOpenModal}
          onNegativeExecution={() => setIsOpenModal(false)}
          onExecution={() => deleteStoreList(storeListForEdit.id)}
          contentText='削除'
          negativeText='キャンセル'
        />
      </>
    );
  },
);
