import { Record } from 'immutable';

import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { User } from 'models/Domain/User';
import { JSObject } from 'types/Common';

type StoresFilterType = 'all' | 'managing_stores' | 'belonging_stores' | 'store_list' | 'store';

const storeFilterAllOption = { text: 'すべて', value: { type: 'all' } };
const managingStoresOption = { text: 'あなたの管理店舗', value: { type: 'managing_stores' } };
const belongingStoresOption = { text: 'あなたの所属店舗', value: { type: 'belonging_stores' } };

export class StoresFilter extends Record<{
  type: StoresFilterType;
  storeListId: number;
  storeId: number;
}>({
  type: 'all',
  storeListId: 0,
  storeId: 0,
}) {
  get value() {
    let filterValue;
    switch (this.type) {
      case 'all':
        filterValue = storeFilterAllOption.value;
        break;
      case 'managing_stores':
        filterValue = managingStoresOption.value;
        break;
      case 'belonging_stores':
        filterValue = belongingStoresOption.value;
        break;
      case 'store_list':
        filterValue = { type: 'store_list', store_list_id: this.storeListId };
        break;
      case 'store':
        filterValue = { type: 'store', store_id: this.storeId };
        break;
      default:
        return null;
    }

    // FIXME: semantic-uiのDropDownのoptionsにobjectは
    //        入らないのでJSON.stringifyでstringにしている
    return JSON.stringify(filterValue);
  }

  // FIXME: semantic-uiのDropDownのoptionsにobjectは入らないので
  //        JSON.stringifyでstringにしたものをパースしている
  update(value: string) {
    const { type, store_id, store_list_id } = JSON.parse(value);
    switch (type) {
      case 'all':
      case 'managing_stores':
      case 'belonging_stores':
        return this.set('type', type);
      case 'store_list':
        return this.merge({ type, storeListId: store_list_id });
      case 'store':
        return this.merge({ type, storeId: store_id });
      default:
        return this;
    }
  }

  changeType(type: StoresFilterType) {
    return this.set('type', type);
  }

  changeStoreId(storeId: number) {
    return this.set('storeId', storeId);
  }

  changeStoreListId(storeListId: number) {
    return this.set('storeListId', storeListId);
  }

  static generateStoreFilterOption(storeLists: StoreLists, stores: Stores, currentUser: User) {
    const storeListOptions = storeLists.list
      .map((storeList) => ({
        text: storeList.name,
        value: { type: 'store_list', store_list_id: storeList.id },
      }))
      .toArray();
    const storeFilterSpecifyOptions = stores.list
      .map((store) => ({ text: store.shortName, value: { type: 'store', store_id: store.id } }))
      .toArray();

    const options: { text: string; value: JSObject }[] = [];

    options.push(storeFilterAllOption);
    currentUser.isMemberUser && !currentUser.stores.isEmpty() && options.push(belongingStoresOption);
    !currentUser.isMemberUser && !currentUser.managing_stores.isEmpty() && options.push(managingStoresOption);
    !currentUser.isMemberUser && options.push(...storeListOptions);
    options.push(...storeFilterSpecifyOptions);

    // FIXME: semantic-uiのDropDownのoptionsにobjectは
    //        入らないのでJSON.stringifyでstringにしている
    return options.map((option) => ({ text: option.text, value: JSON.stringify(option.value) }));
  }
}
