import React from 'react';

import { Set } from 'immutable';
import styled from 'styled-components';

import { ImageCarousel } from 'components/pageComponents/PromotionIndex/ImageCarousel';
import { ImageWrapper } from 'components/pageComponents/PromotionIndex/PromotionInfoCard';
import Promotion from 'models/Domain/Promotion/Promotion';
import { PromotionTranslationData } from 'models/Domain/Promotion/PromotionGroup';
import { Stores } from 'models/Domain/Store';
import { User } from 'models/Domain/User';
import { COLOR } from 'style/color';

/** 投稿に対して編集・削除メニューを表示するかどうか */
export const showEditMenu = (currentUser: User, promotion: Promotion) => {
  // 本社スタッフ・管理者は可
  if (currentUser.isVmdUser || currentUser.isAdminUser) {
    return true;
  }

  // 以下、SV、店舗スタッフの場合

  // 送信先店舗が空の場合、下書きでなければ非表示
  if (promotion.stores.isEmpty()) {
    return promotion.isDraft;
  }

  const promotionStoreIds = Set(promotion.stores.map((store) => store.store_id));
  // SV: 管理店舗
  // 店舗スタッフ: 所属店舗
  const userStoreIds = Set(currentUser.isSvUser ? currentUser.managing_stores : currentUser.stores);

  // 投稿の送信先店舗が全てユーザの店舗に含まれている場合、表示
  // 投稿の送信先店舗にユーザの店舗外の店舗がある場合、非表示
  return promotionStoreIds.isSubset(userStoreIds);
};

type PromotionContentProps = {
  className?: string;
  promotion: Promotion;
  stores: Stores;
};

export const PromotionContent: React.FC<PromotionContentProps> = ({ className, promotion, stores }) => {
  const callToAction = promotion.call_to_action;
  return (
    <Wrapper className={className}>
      <MainContent>
        {promotion.event && (
          <EventContents>
            <EventTitle>{promotion.event.title}</EventTitle>
            <EventPeriod>{promotion.event.scheduleText}</EventPeriod>
          </EventContents>
        )}
        {promotion.body && (
          <PromotionBody>
            {promotion.getAnnotatedBody(stores.list).map((data, i) => {
              if (data.type == 'Alias') {
                return <Alias key={i}>{data.text}</Alias>;
              } else if (data.type === 'PhoneNumber' || data.type === 'NGWord') {
                return <Warning key={i}>{data.text}</Warning>;
              }
              return data.text;
            })}
          </PromotionBody>
        )}
        <DetailWrapper>
          {promotion.call_to_action.action_type !== 'ACTION_TYPE_UNSPECIFIED' && (
            <CallToAction>
              <ActionType>
                <DetailItemLabel>ボタンの種別</DetailItemLabel> {callToAction.actionTypeLabel}
              </ActionType>
              {callToAction.canInputUrl && (
                <LinkUrl>
                  <DetailItemLabel>リンク先</DetailItemLabel>
                  <A target='_blank' rel='noreferrer' href={callToAction.urlWithoutAlias}>
                    {callToAction.url}
                  </A>
                </LinkUrl>
              )}
            </CallToAction>
          )}
          {promotion.offer?.redeemOnlineUrl && (
            <DetailItem>
              <DetailItemLabel>特典へのリンク</DetailItemLabel>
              <A target={'_blank'} rel={'noreferrer'} href={promotion.offer.redeemOnlineUrl}>
                {promotion.offer.redeemOnlineUrl}
              </A>
            </DetailItem>
          )}
          {promotion.offer?.couponCode && (
            <DetailItem>
              <DetailItemLabel>クーポンコード</DetailItemLabel>
              {promotion.offer.couponCode}
            </DetailItem>
          )}
          {promotion.offer?.termsConditions && (
            <DetailItem>
              <DetailItemLabel>利用規約</DetailItemLabel>
              {promotion.offer.termsConditions}
              <br />
            </DetailItem>
          )}
        </DetailWrapper>
      </MainContent>
      {!promotion.media.isEmpty() && (
        <ImageWrapper>
          <ImageCarousel urls={promotion.media.sourceUrls} />
        </ImageWrapper>
      )}
    </Wrapper>
  );
};

type PromotionTranslationContentProps = {
  className?: string;
  translationData: PromotionTranslationData;
  promotion: Promotion;
  stores: Stores;
};

export const PromotionTranslationContent: React.FC<PromotionTranslationContentProps> = ({
  className,
  translationData,
  promotion,
  stores,
}) => {
  const callToAction = translationData.call_to_action || promotion.call_to_action;
  return (
    <Wrapper className={className}>
      <MainContent>
        {translationData.title && (
          <EventContents>
            <EventTitle>{translationData.title}</EventTitle>
          </EventContents>
        )}
        {translationData.body && (
          <PromotionBody>
            {translationData.getAnnotatedBody(stores.list).map((data, i) => {
              if (data.type == 'Alias') {
                return <Alias key={i}>{data.text}</Alias>;
              } else if (data.type === 'PhoneNumber' || data.type === 'NGWord') {
                return <Warning key={i}>{data.text}</Warning>;
              }
              return data.text;
            })}
          </PromotionBody>
        )}
        <DetailWrapper>
          {callToAction.action_type !== 'ACTION_TYPE_UNSPECIFIED' && (
            <CallToAction>
              <ActionType>
                <DetailItemLabel>ボタンの種別</DetailItemLabel> {callToAction.actionTypeLabel}
              </ActionType>
              {callToAction.canInputUrl && (
                <LinkUrl>
                  <DetailItemLabel>リンク先</DetailItemLabel>
                  <A target='_blank' rel='noreferrer' href={callToAction.urlWithoutAlias}>
                    {callToAction.url}
                  </A>
                </LinkUrl>
              )}
            </CallToAction>
          )}
        </DetailWrapper>
      </MainContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 16px;

  @media (max-width: 860px) {
    flex-direction: column-reverse;
  }
`;

const PromotionBody = styled.div`
  line-height: 150%;
  padding: 8px 0;
  white-space: pre-wrap;
`;

const Alias = styled.span`
  font-weight: bold;
  color: ${COLOR.GREEN};
  vertical-align: baseline;
`;

const Warning = styled.span`
  font-weight: bold;
  color: ${COLOR.LIGHT_RED};
  vertical-align: baseline;
`;

const MainContent = styled.div`
  width: 100%;
`;

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;

  b {
    font-weight: bold;
  }
`;

const ActionType = styled.div``;

const LinkUrl = styled.div``;

const A = styled.a`
  color: blue;
  cursor: pointer;
  &:hover {
    color: blue;
  }
  &:active {
    color: red;
  }
  &:visited {
    color: #551b8c;
  }
  word-break: break-all;
`;

export const DateWrapper = styled.div`
  color: ${COLOR.DARK_GRAY};
  text-align: end;
  font-size: 16px;
`;

const DetailWrapper = styled.div`
  margin-top: 16px;
`;

const DetailItem = styled.div`
  font-size: 16px;

  :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const DetailItemLabel = styled.span`
  font-weight: bold;
  margin-right: 16px;
`;

export const EventContents = styled.div`
  margin-bottom: 8px;
`;

export const EventTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const EventPeriod = styled.div`
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
`;
