import React from 'react';

import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  onClick?: () => void;
  checked?: boolean;
  label: string;
};

export const OptionalToggle = React.memo<Props>(({ className, onClick, checked, label }) => {
  return (
    <Wrapper className={className} onClick={onClick} checked={checked}>
      <FlexWrapper>
        {checked ? (
          <StyledIcon type='check' />
        ) : checked === undefined ? (
          <StyledIcon type='plus' />
        ) : (
          <StyledIcon type='cancel_red' />
        )}

        {label}
      </FlexWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ checked?: boolean }>`
  cursor: pointer;
  padding: 3px 12px;
  display: inline-block;
  background: ${(props) =>
    props.checked ? 'rgba(207, 242, 230, 0.3)' : props.checked === undefined ? COLOR.WHITE : 'rgba(255, 1, 2, 0.1);'};
  border: 1px solid;
  border-color: ${(props) => (props.checked ? `${COLOR.GREEN}` : props.checked === undefined ? '#DFDFDF' : '#FF0102')};
  box-sizing: border-box;
  border-radius: 60px;
  color: ${(props) => (props.checked ? COLOR.GREEN : COLOR.BLACK)};
  user-select: none;
  font-size: 14px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 4px;
`;
