export type JSObject = { [p: string]: any };

export type Group =
  | number // グループID
  | 'all' // 全ての店舗
  | 'my_store' // あなたの所属 or 管理店舗
  | null;

export type AggregateUnit = 'day' | 'week' | 'month';

export type SortOrder = 'asc' | 'desc';

export type SelectOption<T> = { text: string; value: T; disabled?: boolean };

export type ArrayElement<T> = T extends readonly (infer U)[] ? U : never;

/**
 * 網羅チェックのための関数
 * 漏れがある場合は引数がnever以外のため、代入できないのでエラーとなる
 * 参考：https://basarat.gitbook.io/typescript/type-system/discriminated-unions#exhaustive-checks
 * @param _
 */
export const assertNever = (_: never): never => {
  throw new Error('Unexpected value. Should have been never.');
};

export type ValidationResult = { isValid: true; error?: null } | { isValid: false; error: string };
