import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Icon, TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { NotificationActions } from 'modules/notification/actions';
import { COLOR } from 'style/color';

type Props = { open: boolean; onClose: () => void };

export const NotificationModal: React.FC<Props> = (props) => {
  const { open, onClose } = props;

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { notificationList } = useSelector((state) => ({
    notificationList: state.notification.notificationList,
  }));
  const dispatch = useDispatch();

  // すべてのお知らせを既読にする処理
  const executeBulkRead = useCallback(() => {
    dispatch(NotificationActions.postNotificationBulkRead());
    setIsOpenModal(false);
  }, [dispatch]);

  return (
    <>
      <TransitionablePortal
        open={open}
        onClose={() => !isOpenModal && onClose()}
        transition={{
          animation: 'fade',
          duration: 0,
        }}
      >
        <Wrapper>
          <Header>
            <Title>お知らせ</Title>
            <CloseIcon size='large' name='close' onClick={() => onClose()} />
          </Header>
          <FunctionSection>
            <BulkReadWrapper>
              <BulkRead onClick={() => setIsOpenModal(true)}>
                <BulkReadIcon name='envelope open outline' />
                <BulkReadLabel>すべてのお知らせを既読にする</BulkReadLabel>
              </BulkRead>
            </BulkReadWrapper>
          </FunctionSection>
          <ContentWrapper>
            {notificationList.map((noti) => {
              return (
                <Link
                  key={noti.id}
                  to={noti.url}
                  onClick={() => {
                    dispatch(NotificationActions.postNotificationRead(Number(noti.id)));
                    onClose();
                  }}
                >
                  <ListWrapper isRead={noti.is_read}>
                    <CreateTime>{noti.createDateString}</CreateTime>
                    <Message>{noti.message}</Message>
                  </ListWrapper>
                </Link>
              );
            })}
          </ContentWrapper>
        </Wrapper>
      </TransitionablePortal>
      <ConfirmModal
        title='すべてのお知らせを既読にしてもよいですか？'
        type='small'
        open={isOpenModal}
        onNegativeExecution={() => setIsOpenModal(false)}
        onExecution={executeBulkRead}
        negativeText='キャンセル'
        contentText='既読にする'
      />
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  background-color: inherit;
  overflow-y: scroll;
`;

const Header = styled(StickyHeader)`
  top: 0px;
`;

const CloseIcon = styled(Icon)`
  &&& {
    color: ${COLOR.GREEN};
    cursor: pointer;
  }
`;

const FunctionSection = styled(StickyHeader)`
  top: 60px;
  background-color: ${COLOR.WHITE};
  justify-content: center;
`;

const BulkReadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 0;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
`;

const BulkRead = styled.div`
  display: flex;
  cursor: pointer;
`;

const BulkReadIcon = styled(Icon)`
  &&& {
    color: ${COLOR.BLACK};
    line-height: 1.3;
  }
`;

const BulkReadLabel = styled.div`
  width: auto;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 1px;
`;

const ContentWrapper = styled.div`
  padding: 20px 10px 40px;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
`;

const ListWrapper = styled.div<{ isRead: boolean }>`
  display: block;
  padding: 18px;
  background-color: ${COLOR.WHITE};
  margin-bottom: 24px;
  color: ${(props) => (props.isRead ? COLOR.GRAY : COLOR.BLACK)};
`;

const CreateTime = styled.div`
  font-size: 14px;
`;

const Message = styled.p`
  font-size: 14px;
  margin-top: 12px;
`;
