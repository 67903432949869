import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { GetMenuSummaryParams } from 'ApiClient/MenuApi';

const DEFAULT_STORE_IDS = null;
const DEFAULT_GROUP_IDS = null;
const DEFAULT_IS_INCLUDED_DEACTIVATE_GBP = true;

type FilterStatusType = {
  storeIds: ImmutableList<number> | null;
  groupIds: ImmutableList<number> | null;
  isIncludedDeactivateGbp: boolean;
};

export class FilterStatus extends ImmutableRecord<FilterStatusType>({
  storeIds: DEFAULT_STORE_IDS,
  groupIds: DEFAULT_GROUP_IDS,
  isIncludedDeactivateGbp: DEFAULT_IS_INCLUDED_DEACTIVATE_GBP,
}) {}

export class MenuSearchCondition extends ImmutableRecord<{
  filter: FilterStatus;
}>({
  filter: new FilterStatus(),
}) {
  toParams(): GetMenuSummaryParams {
    return {
      store_ids: this.filter.storeIds?.toArray(),
      group_ids: this.filter.groupIds?.toArray(),
      is_included_deactivate_gbp: this.filter.isIncludedDeactivateGbp ?? undefined,
    };
  }
}
