import React from 'react';

import styled from 'styled-components';

import { COLOR } from 'style/color';

export const StepManual = styled.div`
  padding: 8px;
  border: solid 1px ${COLOR.GREEN};
  border-radius: 10px;
  width: 100%;
`;

export const StepManualTitle = styled.div`
  padding: 8px;
  width: 100%;
  font-weight: bold;
`;

type StepManualHeaderProps = {
  className?: string;
  title?: string;
  step: number;
};

export const StepManualHeader = React.memo<StepManualHeaderProps>(({ className, title, step }) => {
  return (
    <StepWrapper>
      <Step className={className}>STEP{step}</Step>
      {!!title && <StepTitle>{title}</StepTitle>}
    </StepWrapper>
  );
});

const Step = styled.div`
  background-color: ${COLOR.GREEN};
  border: solid 1px ${COLOR.GREEN};
  border-radius: 20px;
  display: inline-block;
  padding: 2px 8px;
  color: ${COLOR.WHITE};
  text-align: center;
  font-weight: bold;
`;

const StepWrapper = styled.div`
  display: flex;
`;

const StepTitle = styled.div`
  text-align: center;
  font-weight: bold;
  word-wrap: break-word;
  padding-top: 2px;
  margin-left: 10px;
`;

export const StepManualGuideLink = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  word-wrap: break-word;
`;

export const StepManualMain = styled.div`
  margin-left: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 16px;
  border-left: solid 5px ${COLOR.DARK_GRAY};
  font-size: 14px;
  word-wrap: break-word;
`;
