import React, { useCallback } from 'react';

import { List as ImmutableList, Set as ImmutableSet } from 'immutable';
import { useSelector } from 'react-redux';
import { Checkbox, Dropdown, Label, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { SmallCheckBox } from 'components/atoms/CheckBox';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { MenuHelp as Help } from 'helpers/ContextHelp';
import { MenuSummaryItem as Item, MenuSummary } from 'models/Domain/Menu/Menu';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Props = {
  items: MenuSummary['items'];
  isLoading: boolean;
  selectedMenuIds: ImmutableSet<number>;
  onDelete: (menuIds: ImmutableSet<number>) => void;
  onChange: (items: MenuSummary['items']) => void;
  onClone: (menuId: number) => void;
  onChangeSelectedMenuIds: (menuIds: ImmutableSet<number>) => void;
};

const DISPLAY_STORE_COUNT = 3;

export const MenuIndexTable = React.memo<Props>(
  ({ isLoading, items, selectedMenuIds, onDelete, onChange, onClone, onChangeSelectedMenuIds }) => {
    const { stores } = useSelector((state) => state.store);
    const { storeLists } = useSelector((state) => state.storeList);

    const targetLabel = useCallback(
      (item: Item) => {
        const groupIdsCount = item.groupIds?.size ?? 0;
        const storeIdsCount = item.storeIds?.size ?? 0;

        // グループが選択されている場合、グループ名
        if (groupIdsCount > 0) {
          return (
            <>
              <GroupLabel circular size={'small'}>
                グループ
              </GroupLabel>
              {item.groupIds
                ?.map((groupId) => storeLists.findStoreList(groupId)?.name)
                .filter((group) => group)
                .join('、')}
            </>
          );
        }

        // 店舗が選択されている場合、店舗名（4店舗目以降省略）
        if (storeIdsCount > DISPLAY_STORE_COUNT) {
          const storeNames =
            item.storeIds?.map((storeId) => stores.findStore(storeId)?.fullName).filter((store) => store) ??
            ImmutableList();
          return (
            <>
              {storeNames.slice(0, DISPLAY_STORE_COUNT).join('、')}{' '}
              <Popup
                content={
                  <PopupContent>
                    {storeNames.map((name, index) => (
                      <div key={index}>{name}</div>
                    ))}
                  </PopupContent>
                }
                trigger={<PopupTrigger>{`他 ${storeIdsCount - DISPLAY_STORE_COUNT} 店舗`}</PopupTrigger>}
              />
            </>
          );
        }

        if (storeIdsCount > 0) {
          return item.storeIds
            ?.map((storeId) => stores.findStore(storeId)?.fullName)
            .filter((store) => store)
            .join('、');
        }

        // グループも店舗も選択されていない場合、全店舗
        return '全ての店舗';
      },
      [stores, storeLists],
    );

    const handleOnChangeAllCheckbox = useCallback(() => {
      if (selectedMenuIds.size === items.list.size) {
        onChangeSelectedMenuIds(ImmutableSet<number>());
      } else {
        onChangeSelectedMenuIds(ImmutableSet<number>(items.list.map((item) => item.id)));
      }
    }, [items, onChangeSelectedMenuIds, selectedMenuIds.size]);

    const handleOnChangeCheckbox = useCallback(
      (menuId: number) => () => {
        if (selectedMenuIds.contains(menuId)) {
          onChangeSelectedMenuIds(selectedMenuIds.remove(menuId));
        } else {
          onChangeSelectedMenuIds(selectedMenuIds.add(menuId));
        }
      },
      [onChangeSelectedMenuIds, selectedMenuIds],
    );

    const handleOnChangeApplyToGbp = useCallback(
      (index: number) => {
        onChange(items.toggleApplyToGbp(index));
      },
      [onChange, items],
    );

    return (
      <Wrapper>
        <TableWrapper>
          <StyledTable>
            <TableHeader>
              <TableHeaderRow>
                <SmallTableHeaderCell>
                  <CheckboxWrapper>
                    <SmallCheckBox
                      checked={selectedMenuIds.size === items.list.size}
                      onChange={handleOnChangeAllCheckbox}
                    />
                  </CheckboxWrapper>
                </SmallTableHeaderCell>
                <StyledTableHeaderCell>
                  メニューグループ名
                  <ContextHelp content={Help.menuNameForMenuIndex} />
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  対象店舗・グループ
                  <ContextHelp content={Help.groupStore} />
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  GBP連携
                  <ContextHelp content={Help.applyToGbp} />
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>更新日</StyledTableHeaderCell>
                <SmallTableHeaderCell />
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {items.list.map((item, index) => (
                <TableRow key={item.id}>
                  <StyledTableCell>
                    <CheckboxWrapper>
                      <SmallCheckBox
                        checked={selectedMenuIds.contains(item.id)}
                        onChange={handleOnChangeCheckbox(item.id)}
                      />
                    </CheckboxWrapper>
                  </StyledTableCell>
                  <StyledTableCell>
                    <DetailLink to={Path.menu.edit.replace(':id', `${item.id}`)}>{item.name}</DetailLink>
                  </StyledTableCell>
                  <StyledTableCell>{targetLabel(item)}</StyledTableCell>
                  <StyledTableCell>
                    <CheckboxWrapper>
                      <StyledCheckbox
                        toggle
                        checked={item.applyToGbp}
                        disabled={isLoading}
                        onChange={() => handleOnChangeApplyToGbp(index)}
                      />
                    </CheckboxWrapper>
                  </StyledTableCell>
                  <StyledTableCell>
                    <UpdateAt>{item.updateAt?.format('YYYY/MM/DD') ?? 'ー'}</UpdateAt>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
                      <Dropdown.Menu direction={'left'}>
                        <ActionMenuItem onClick={() => onClone(item.id)}>
                          <ActionMenuLabel>複製</ActionMenuLabel>
                        </ActionMenuItem>
                        <ActionMenuItem onClick={() => onDelete(ImmutableSet([item.id]))}>
                          <ActionMenuLabel>削除</ActionMenuLabel>
                        </ActionMenuItem>
                      </Dropdown.Menu>
                    </Dropdown>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
          {isLoading && (
            <LoadingWrapper>
              <Loader size={'big'} active inline />
            </LoadingWrapper>
          )}
        </TableWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const TableWrapper = styled.div``;
const StyledTable = styled(Table)``;
const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    text-align: left;
  }
`;
const SmallTableHeaderCell = styled(StyledTableHeaderCell)`
  &&& {
    width: 64px;
  }
`;
const StyledTableCell = styled(TableCell)`
  &&& {
    text-align: left;
  }
`;

const GroupLabel = styled(Label)`
  &&& {
    margin-right: 4px;
  }
`;

const PopupContent = styled.div`
  font-size: 12px;
`;

const PopupTrigger = styled.span`
  display: inline-block;
  margin-left: 8px;
  text-decoration: underline;
  font-size: 12px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Trigger = styled.div`
  cursor: pointer;
  letter-spacing: -10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${COLOR.GREEN};
  margin-top: -8px;
  transform: rotate(90deg);
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY} !important;
    &:first-of-type {
      border-top: none !important;
    }
  }
`;

const ActionMenuLabel = styled.div`
  padding: 10px;
`;

const DetailLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
  &&& {
    input:focus:checked ~ label:before,
    input:checked ~ label:before {
      background-color: #05ccad !important;
    }
  }
`;

const UpdateAt = styled.div`
  font-family: monospace;
`;

const LoadingWrapper = styled.div`
  background: ${COLOR.BACKGROUND};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
