import React, { useState } from 'react';

import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { Service } from 'models/Domain/Service/Service';
import { COLOR } from 'style/color';

import { ServiceEditModal } from './ServiceEditModal';

type Props = {
  service: Service;
  onChange: (service: Service) => void;
  onDelete: () => void;
};

export const ServiceListItem: React.FC<Props> = ({ service, onChange, onDelete }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <StyledCard onClick={() => setShowModal(true)}>
        <FlexContainer>
          <InfoContainer>
            <Name>{service.name}</Name>
            <Description>{service.description ?? ''}</Description>
            {service.price.displayValue && <Price>{service.price.displayValue}</Price>}
          </InfoContainer>
          <DropdownContainer>
            <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
              <Dropdown.Menu direction={'left'}>
                <ActionMenuItem onClick={() => setShowModal(true)}>
                  <ActionMenuLabel>編集</ActionMenuLabel>
                </ActionMenuItem>
                <ActionMenuItem onClick={onDelete}>
                  <ActionMenuLabel>削除</ActionMenuLabel>
                </ActionMenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </DropdownContainer>
        </FlexContainer>
      </StyledCard>
      {showModal && (
        <ServiceEditModal
          isOpen={showModal}
          service={service}
          onClose={() => {
            setShowModal(false);
          }}
          onChange={onChange}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 2px;
`;

const Name = styled.div`
  font-size: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
`;

const Price = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 0 16px;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`;

const DropdownContainer = styled.div`
  width: 32px;
`;

const Trigger = styled.div`
  cursor: pointer;
  letter-spacing: -10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${COLOR.GREEN};
  margin-top: -8px;
  transform: rotate(90deg);
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY} !important;
    &:first-of-type {
      border-top: none !important;
    }
  }
`;

const ActionMenuLabel = styled.div`
  padding: 10px;
`;
