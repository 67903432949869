import React, { useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { UpdateModal } from 'components/organisms/UpdateModal';
import { withCustomAuthenticator } from 'components/templates/LoginOnly/withCustomAuthenticator';
import CONFIG from 'config';
import { AppActions } from 'modules/app/actions';

// リビジョンの確認は本番環境でのみ実行する
const isProduction = CONFIG.ENVIRONMENT === 'production';

const LoginOnlyWrapper = React.memo((props) => {
  const { currentUser, needsUpdate, isInitialized } = useSelector((state) => state.app, shallowEqual);

  // 本番環境の場合のみ、リビジョンの違いによる更新メッセージを表示する
  const showUpdateMessage = isProduction && needsUpdate;

  const dispatch = useDispatch();

  useEffect(() => {
    // 実行済みの場合は何もせず終了
    if (isInitialized) {
      return;
    }

    dispatch(AppActions.initialize());
  }, [dispatch, isInitialized]);

  useEffect(() => {
    // 本番環境の場合のみ、最新のリビジョンを監視する
    if (isProduction) {
      dispatch(AppActions.watchLatestRevision());
    }
  }, [dispatch]);

  if (!currentUser.isExist) {
    return null;
  }

  return (
    <>
      {showUpdateMessage && <UpdateModal />}
      <>{props.children}</>
    </>
  );
});

export const LoginOnly = withCustomAuthenticator(LoginOnlyWrapper);
