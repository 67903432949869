import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import UserList from 'components/pageComponents/UserIndex/UserListContent';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { Path } from 'routes';

export const UserIndex: React.FC = () => {
  const { userList, userSortStatus } = useSelector((state) => ({
    userList: state.user.userList,
    userSortStatus: state.user.userSortStatus,
  }));

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('スタッフ一覧')} />
      <StickyHeader>
        <Title>スタッフ一覧</Title>
        <Link to={Path.user.register}>
          <UserCreateButton priority='high'>スタッフを登録</UserCreateButton>
        </Link>
      </StickyHeader>
      <Contents>
        <UserList userList={userList} userSortStatus={userSortStatus} />
      </Contents>
    </MainWrapper>
  );
};

const UserCreateButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 13px;
  }
`;

const Contents = styled(Body)`
  max-width: 100%;
`;
