import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import { Stores } from 'models/Domain/Store';
import { JSObject } from 'types/Common';

export default class StoreList extends Record<{
  id: number;
  name: string;
  stores: List<number>;
}>({
  id: 0,
  name: '',
  stores: List(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.stores) {
      params.stores = List(params.stores.map((store: JSObject) => store.id));
    }
    super(params);
  }

  get isValid() {
    const validates = this.validate();
    return Object.keys(validates).length === 0;
  }

  validate() {
    const errors: JSObject = {};

    if (!this.name || !this.name.trim()) {
      errors.name = ErrorType.REQUIRED_ERROR;
    }

    if (this.stores.size == 0) {
      errors.store = ErrorType.REQUIRED_ERROR;
    }

    return errors;
  }

  setName(name: string) {
    return this.set('name', name);
  }

  contains(storeId: number) {
    return this.stores.contains(storeId);
  }

  /** 1件以上閉店以外の店舗を含んでいるかどうか */
  includesUnclosedStore(stores: Stores) {
    return this.stores.some((storeId) => {
      const store = stores.findStore(storeId);
      return !!store && !store.location.openInfo.isClose;
    });
  }

  /** 1件以上gmb連携中の店舗を含んでいるかどうか */
  includesConnectedGmbStore(stores: Stores) {
    return this.stores.some((storeId) => {
      const store = stores.findStore(storeId);
      return !!store && store.is_connected_gmb;
    });
  }

  /** 1件以上管理/所属店舗を含んでいるか */
  includesMyStore(myStoreIds: List<number>) {
    return this.stores.some((storeId) => {
      return myStoreIds.contains(storeId);
    });
  }

  toggleStores(selectedStoreId: number) {
    const targetIndex = this.stores.findIndex((storeId) => storeId === selectedStoreId);
    if (targetIndex >= 0) {
      return this.update('stores', (stores) => stores.delete(targetIndex));
    }
    return this.update('stores', (stores) => stores.push(selectedStoreId));
  }

  requestParams() {
    const { id, name } = this.toObject();
    const params = {
      id: id < 1 ? null : id,
      name,
      stores: this.stores.map((storeId: number) => ({ id: storeId })).toArray(),
    };
    return params;
  }
}

export class StoreLists extends Record<{
  list: List<StoreList>;
  initialized: boolean;
}>({
  list: List(),
  initialized: false,
}) {
  constructor(data: JSObject[] = [], initialized = false) {
    const list = List(data.map((d) => new StoreList(d)));
    super({ list, initialized });
  }

  // フォームのPullDown用の配列に整形する
  get options() {
    return this.list
      .map((storeList) => {
        return {
          text: storeList.name,
          value: storeList.id,
        };
      })
      .toArray();
  }

  /**
   * 1件以上閉店以外の店舗を含んでいるグループのリストを返す
   * @param {Stores} stores 全店舗の情報（開業情報の参照に必要）
   */
  filterByIncludingUnclosedStore(stores: Stores) {
    return this.set(
      'list',
      this.list.filter((storeList) => storeList.includesUnclosedStore(stores)),
    );
  }

  /**
   * 1件以上gmb連携中の店舗を含んでいるグループのリストを返す
   * @param {Stores} stores 全店舗の情報（gmb連携情報の参照に必要）
   */
  filterByIncludingConnectedGmbStore(stores: Stores) {
    return this.set(
      'list',
      this.list.filter((storeList) => storeList.includesConnectedGmbStore(stores)),
    );
  }

  /**
   * 管理店舗 / 所属店を含んでいるグループのリストを返す
   * @param myStoreIds {List<number>} 全店舗の情報）
   */
  filterByMyStore(myStoreIds: List<number>) {
    return this.set(
      'list',
      this.list.filter((storeList) => storeList.includesMyStore(myStoreIds)),
    );
  }

  /**
   * 店舗数が0のグループを含まないリストを返す
   */
  excludeEmptyStoreList() {
    return this.set(
      'list',
      this.list.filterNot((storeList) => storeList.stores.isEmpty()),
    );
  }

  findStoreList(storeListId: number | undefined) {
    if (!storeListId) {
      return undefined;
    }
    return this.list.find((storeList) => storeList.id === Number(storeListId));
  }

  getStoreIdList(storeListId: number | undefined) {
    if (!storeListId) return;
    const target = this.list.find((storeList) => storeList.id === Number(storeListId));
    if (!target) {
      return [];
    }
    return target.stores.toArray();
  }
}
