import React, { useMemo } from 'react';

import { rgba } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { DropdownMenuItem } from 'components/molecules/DropdownMenu';
import { TableCell, TableRow } from 'components/molecules/Table';
import { AccountMenu } from 'components/organisms/AccountMenu';
import { getRateText, getValueText } from 'helpers/table';
import { Account } from 'models/Domain/AccountList';
import {
  AggregateMethod,
  GbpPerformanceMATableDataPair,
  ReviewType,
  SortKey,
  SortType,
} from 'models/Domain/GbpPerformanceMA/GbpPerformanceMA';
import { GbpPerformanceMASearchCondition as SearchCondition } from 'models/Domain/GbpPerformanceMA/GbpPerformanceMASearchCondition';
import { ORGANIZATION_ID_QUERY_KEY } from 'modules/app/const';
import { Path } from 'routes';
import { COLOR } from 'style/color';

import { SortCondition } from '.';

type TableRowProps = {
  item: GbpPerformanceMATableDataPair;
  sortCondition: SortCondition;
  searchCondition: SearchCondition;
} & (
  | {
      total?: never;
      organization: Account;
    }
  | {
      total: true;
      organization?: never;
    }
);

type ReviewTableRowProps = TableRowProps & { reviewType: ReviewType };

const IMPRESSIONS_ITEMS = ['impressions', 'desktopMaps', 'mobileMaps', 'desktopSearch', 'mobileSearch'] as const;

const INTERACTIONS_ITEMS = [
  'interactions',
  'callClicks',
  'directionRequests',
  'websiteClicks',
  'conversations',
  'bookings',
] as const;

const PERIOD_REVIEWS_ITEMS = [
  'periodReviews',
  'periodCommentCount',
  'periodRateCount',
  'periodReplyCount',
  'periodAverageRating',
  'totalAverageRating',
] as const;

const TOTAL_REVIEWS_ITEMS = [
  'totalReviews',
  'totalCommentCount',
  'totalRateCount',
  'totalReplyCount',
  'periodAverageRating',
  'totalAverageRating',
] as const;

const DATE_FORMAT = 'YYYY/MM/DD';

const OrganizationColumns: React.FC<{
  organization: Account;
  sortCondition: SortCondition;
  searchCondition: SearchCondition;
  storeCount: number;
}> = ({ organization, sortCondition, searchCondition, storeCount }) => {
  const { isEnabledComparison } = searchCondition.filter;
  const canUseGbpPerformance = organization.user.canUseGbpPerformance;

  const additionalItems = useMemo(() => {
    const items: DropdownMenuItem[] = [];
    // AccountMenuにはすでに単一アカウント版GBPパフォーマンスへのリンクが用意されているが、
    // 現在開いている複数アカウント版GBPパフォーマンス画面との関係がわかりにくいので、
    // 「店舗別で見る」という名で単一アカウント版GBPパフォーマンス画面に遷移させる
    if (canUseGbpPerformance) {
      const { startDate, endDate, aggregateUnit, isEnabledComparison, comparisonStartDate, comparisonEndDate } =
        searchCondition.filter;
      const searchParams = new URLSearchParams();
      if (startDate && endDate) {
        searchParams.set('au', aggregateUnit);
        searchParams.set('sd', startDate.format('YYYY-MM-DD'));
        searchParams.set('ed', endDate.format('YYYY-MM-DD'));
      }
      if (isEnabledComparison && comparisonStartDate && comparisonEndDate) {
        searchParams.set('csd', comparisonStartDate.format('YYYY-MM-DD'));
        searchParams.set('ced', comparisonEndDate.format('YYYY-MM-DD'));
      }
      searchParams.set(ORGANIZATION_ID_QUERY_KEY, organization.organizationId.toString());
      items.push({
        label: '店舗別で見る',
        link: `${Path.gbp.performance}?${searchParams.toString()}`,
        target: '_blank',
      });
    }
    // タイトル扱いにするため、選択不可能にしたメニューを追加
    items.push({ label: 'アカウント詳細', disabled: true });

    return { start: items };
  }, [organization, searchCondition, canUseGbpPerformance]);

  return (
    <>
      <StyledTableCell colSpan={2} border={2} highlited={isSortedColumn(sortCondition, 'organizationName')}>
        <AccountMenu
          trigger={
            <Name>
              {organization.name}
              {isEnabledComparison ? '' : `（${storeCount}店舗）`}
              <StatsIcon />
            </Name>
          }
          organizationId={organization.organizationId}
          startDate={searchCondition.filter.startDate}
          endDate={searchCondition.filter.endDate}
          items={['store', 'searchKeyword', 'mapSearchRank', 'review']}
          additionalItems={additionalItems}
          dividerIndex={canUseGbpPerformance ? [0] : undefined}
        />
      </StyledTableCell>
    </>
  );
};

const TotalLabelColumns: React.FC<{ sortCondition: SortCondition; storeCount?: number }> = ({
  sortCondition,
  storeCount,
}) => {
  return (
    <>
      <StyledTableCell border={2} colSpan={2} highlited={isSortedColumn(sortCondition, 'organizationName')}>
        <Name>すべて{storeCount != null ? `（${storeCount}店舗）` : ''}</Name>
      </StyledTableCell>
    </>
  );
};

export const OverviewTableRow: React.FC<TableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} storeCount={item.target.storeCount} />
      ) : (
        <OrganizationColumns
          organization={organization}
          sortCondition={sortCondition}
          searchCondition={searchCondition}
          storeCount={item.target.storeCount}
        />
      )}
      <NumberCell highlited={isSortedColumn(sortCondition, 'impressions')}>
        <Number value={item.target.stats.impressions} aggregateMethod={aggregateMethod} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
        <Number value={item.target.stats.interactions} aggregateMethod={aggregateMethod} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
        <Rate value={item.target.stats.getRate('interactions')} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'periodReviews')}>
        <Number value={item.target.stats.periodReviews} aggregateMethod={aggregateMethod} />
      </NumberCell>
    </StyledTableRow>
  );
};

export const ImpressionsTableRow: React.FC<TableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} storeCount={item.target.storeCount} />
      ) : (
        <OrganizationColumns
          organization={organization}
          sortCondition={sortCondition}
          searchCondition={searchCondition}
          storeCount={item.target.storeCount}
        />
      )}
      {IMPRESSIONS_ITEMS.map((key) => (
        <React.Fragment key={key}>
          <NumberCell highlited={isSortedColumn(sortCondition, key)}>
            <Number value={item.target.stats[key]} aggregateMethod={aggregateMethod} />
          </NumberCell>
        </React.Fragment>
      ))}
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
        <Number value={item.target.stats.interactions} aggregateMethod={aggregateMethod} />
      </NumberCell>
      <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
        <Rate value={item.target.stats.getRate('interactions')} />
      </NumberCell>
    </StyledTableRow>
  );
};

export const InteractionsTableRow: React.FC<TableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} storeCount={item.target.storeCount} />
      ) : (
        <OrganizationColumns
          organization={organization}
          sortCondition={sortCondition}
          searchCondition={searchCondition}
          storeCount={item.target.storeCount}
        />
      )}
      <NumberCell>
        <Number value={item.target.stats.impressions} aggregateMethod={aggregateMethod} />
      </NumberCell>
      {INTERACTIONS_ITEMS.map((key) => (
        <React.Fragment key={key}>
          <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
            <Number value={item.target.stats[key]} aggregateMethod={aggregateMethod} />
          </NumberCell>
          <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
            <Rate value={item.target.stats.getRate(key)} />
          </NumberCell>
        </React.Fragment>
      ))}
    </StyledTableRow>
  );
};

export const ReviewsTableRow: React.FC<ReviewTableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
  reviewType,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  const REVIEW_ITEMS = reviewType === 'total' ? TOTAL_REVIEWS_ITEMS : PERIOD_REVIEWS_ITEMS;
  return (
    <StyledTableRow total={total ? '1' : '0'}>
      {total ? (
        <TotalLabelColumns sortCondition={sortCondition} storeCount={item.target.storeCount} />
      ) : (
        <OrganizationColumns
          organization={organization}
          sortCondition={sortCondition}
          searchCondition={searchCondition}
          storeCount={item.target.storeCount}
        />
      )}
      {REVIEW_ITEMS.map((key) => (
        <React.Fragment key={key}>
          <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
            {(() => {
              switch (key) {
                case 'totalAverageRating':
                case 'periodAverageRating': {
                  return <Rating value={item.target.stats[key]} />;
                }
                default:
                  return <Number value={item.target.stats[key]} aggregateMethod={aggregateMethod} />;
              }
            })()}
          </NumberCell>
        </React.Fragment>
      ))}
    </StyledTableRow>
  );
};

export const OverviewWithComparisonTableRow: React.FC<TableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} />
        ) : (
          <OrganizationColumns
            organization={organization}
            sortCondition={sortCondition}
            searchCondition={searchCondition}
            storeCount={item.target.storeCount}
          />
        )}
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'impressions')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'periodReviews')} />
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        <StoresCell border={2}>{item.target.storeCount}店舗</StoresCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'impressions')}>
          <Number value={item.target.stats.impressions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.target.stats.interactions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.target.stats.getRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'periodReviews')}>
          <Number value={item.target.stats.periodReviews} aggregateMethod={aggregateMethod} />
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        <StoresCell border={2}>{item.comparison ? `${item.comparison.storeCount}店舗` : ''}</StoresCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'impressions')}>
          <Number value={item.comparison?.stats.impressions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.comparison?.stats.interactions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.comparison?.stats.getRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'periodReviews')}>
          <Number value={item.comparison?.stats.periodReviews} aggregateMethod={aggregateMethod} />
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell>
          <Label>変化率</Label>
        </RightAlignCell>
        <NumberCell border={2}>
          <DiffRate value={item.getStoreCountDiffRate()} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'impressions')}>
          <DiffRate value={item.getDiffRate('impressions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <DiffRate value={item.getDiffRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <DiffRate value={item.getRateDiffRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'totalReviews')}>
          <DiffRate value={item.getDiffRate('periodReviews')} />
        </NumberCell>
      </NarrowRow>
    </>
  );
};

export const ImpressionsWithComparisonTableRow: React.FC<TableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} />
        ) : (
          <OrganizationColumns
            organization={organization}
            sortCondition={sortCondition}
            searchCondition={searchCondition}
            storeCount={item.target.storeCount}
          />
        )}
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <StyledTableCell highlited={isSortedColumn(sortCondition, key)} />
          </React.Fragment>
        ))}
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')} />
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')} />
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        <StoresCell border={2}>{item.target.storeCount}店舗</StoresCell>
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key)}>
              <Number value={item.target.stats[key]} aggregateMethod={aggregateMethod} />
            </NumberCell>
          </React.Fragment>
        ))}
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.target.stats.interactions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.target.stats.getRate('interactions')} />
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        <StoresCell border={2}>{item.comparison ? `${item.comparison.storeCount}店舗` : ''}</StoresCell>
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key)}>
              <Number value={item.comparison?.stats[key]} aggregateMethod={aggregateMethod} />
            </NumberCell>
          </React.Fragment>
        ))}
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <Number value={item.comparison?.stats.interactions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <Rate value={item.comparison?.stats.getRate('interactions')} />
        </NumberCell>
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell border={2}>
          <Label>変化率</Label>
        </RightAlignCell>
        <NumberCell border={2}>
          <DiffRate value={item.getStoreCountDiffRate()} />
        </NumberCell>
        {IMPRESSIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key)}>
              <DiffRate value={item.getDiffRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'number')}>
          <DiffRate value={item.getDiffRate('interactions')} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'interactions', 'rate')}>
          <DiffRate value={item.getRateDiffRate('interactions')} />
        </NumberCell>
      </NarrowRow>
    </>
  );
};

export const InteractionsWithComparisonTableRow: React.FC<TableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} />
        ) : (
          <OrganizationColumns
            organization={organization}
            sortCondition={sortCondition}
            searchCondition={searchCondition}
            storeCount={item.target.storeCount}
          />
        )}
        <StyledTableCell highlited={isSortedColumn(sortCondition, 'impressions')} />
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <StyledTableCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0} />
            <StyledTableCell highlited={isSortedColumn(sortCondition, key, 'rate')} />
          </React.Fragment>
        ))}
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        <StoresCell border={2}>{item.target.storeCount}店舗</StoresCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'impressions')}>
          <Number value={item.target.stats.impressions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
              <Number value={item.target.stats[key]} aggregateMethod={aggregateMethod} />
            </NumberCell>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
              <Rate value={item.target.stats.getRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        <StoresCell border={2}>{item.comparison ? `${item.comparison.storeCount}店舗` : ''}</StoresCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'impressions')}>
          <Number value={item.comparison?.stats.impressions} aggregateMethod={aggregateMethod} />
        </NumberCell>
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
              <Number value={item.comparison?.stats[key]} aggregateMethod={aggregateMethod} />
            </NumberCell>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
              <Rate value={item.comparison?.stats.getRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell border={2}>
          <Label>変化率</Label>
        </RightAlignCell>
        <NumberCell border={2}>
          <DiffRate value={item.getStoreCountDiffRate()} />
        </NumberCell>
        <NumberCell highlited={isSortedColumn(sortCondition, 'impressions')}>
          <DiffRate value={item.getDiffRate('impressions')} />
        </NumberCell>
        {INTERACTIONS_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')} border={0}>
              <DiffRate value={item.getDiffRate(key)} />
            </NumberCell>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'rate')}>
              <DiffRate value={item.getRateDiffRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
    </>
  );
};

export const ReviewsWithComparisonTableRow: React.FC<ReviewTableRowProps> = ({
  item,
  organization,
  total,
  sortCondition,
  searchCondition,
  reviewType,
}) => {
  const { aggregateMethod } = searchCondition.filter;
  const REVIEW_ITEMS = reviewType === 'total' ? TOTAL_REVIEWS_ITEMS : PERIOD_REVIEWS_ITEMS;
  return (
    <>
      <NarrowHeaderRow total={total ? '1' : '0'}>
        {total ? (
          <TotalLabelColumns sortCondition={sortCondition} />
        ) : (
          <OrganizationColumns
            organization={organization}
            sortCondition={sortCondition}
            searchCondition={searchCondition}
            storeCount={item.target.storeCount}
          />
        )}
        {REVIEW_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <StyledTableCell highlited={isSortedColumn(sortCondition, key, 'number')} />
          </React.Fragment>
        ))}
      </NarrowHeaderRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell border={2}>
          {item.target.period.startDate.format(DATE_FORMAT)}〜{item.target.period.endDate.format(DATE_FORMAT)}
        </PeriodCell>
        <StoresCell border={2}>{item.target.storeCount}店舗</StoresCell>
        {REVIEW_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
              {(() => {
                switch (key) {
                  case 'totalAverageRating':
                  case 'periodAverageRating': {
                    return <Rating value={item.target.stats[key]} />;
                  }
                  default:
                    return <Number value={item.target.stats[key]} aggregateMethod={aggregateMethod} />;
                }
              })()}
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <PeriodCell>
          {item.comparison === null
            ? '比較期間のデータがありません'
            : `${item.comparison.period.startDate.format(DATE_FORMAT)}〜${item.comparison.period.endDate.format(
                DATE_FORMAT,
              )}`}
        </PeriodCell>
        <StoresCell border={2}>{item.comparison ? `${item.comparison.storeCount}店舗` : ''}</StoresCell>
        {REVIEW_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
              {(() => {
                switch (key) {
                  case 'totalAverageRating':
                  case 'periodAverageRating': {
                    return <Rating value={item.comparison?.stats[key]} />;
                  }
                  default:
                    return <Number value={item.comparison?.stats[key]} aggregateMethod={aggregateMethod} />;
                }
              })()}
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
      <NarrowRow total={total ? '1' : '0'}>
        <RightAlignCell border={2}>
          <Label>変化率</Label>
        </RightAlignCell>
        <NumberCell border={2}>
          <DiffRate value={item.getStoreCountDiffRate()} />
        </NumberCell>
        {REVIEW_ITEMS.map((key) => (
          <React.Fragment key={key}>
            <NumberCell highlited={isSortedColumn(sortCondition, key, 'number')}>
              <DiffRate value={item.getDiffRate(key)} />
            </NumberCell>
          </React.Fragment>
        ))}
      </NarrowRow>
    </>
  );
};

const isSortedColumn = (sortCondition: SortCondition, sortKey: SortKey, sortType: SortType = 'number') =>
  sortCondition?.key === sortKey && sortCondition?.type === sortType ? '1' : '0';

const Number = ({ value, aggregateMethod }: { value: number | null | undefined; aggregateMethod: AggregateMethod }) => {
  return <>{getValueText(value, aggregateMethod === 'average' ? 1 : 0)}</>;
};

const Rating = ({ value }: { value: number | null | undefined }) => {
  return <>{getValueText(value, 1)}</>;
};

const Rate = ({ value }: { value: number | null | undefined }) => {
  return <>{getRateText(value)}</>;
};

const DiffRate = styled.span<{ value: number | null | undefined }>`
  color: ${({ value }) => (!value ? COLOR.BLACK : value >= 0 ? COLOR.GREEN : COLOR.RED)};
  &:before {
    content: '${({ value }) => (value == null ? '' : value === 0 ? '' : value > 0 ? '↑' : '↓')}';
    margin-right: 4px;
  }
  &:after {
    content: '${({ value }) => (value == null ? 'ー' : `${(Math.abs(value) * 100).toFixed(1)}%`)}';
  }
`;

const StatsIcon = styled(Icon).attrs(() => ({ type: 'assessment' }))`
  width: 18px;
  height: 18px;
  padding: 0;
  vertical-align: middle;
  margin-left: 4px;
`;

const Name = styled.span`
  font-weight: bold;
`;

const StyledTableCell = styled(TableCell)<{ highlited?: '0' | '1'; border?: number }>`
  &&& {
    background: ${({ highlited = '0' }) => (highlited === '1' ? rgba(COLOR.GREEN, 0.1) : 'inherit')};
    font-weight: ${({ highlited = '0' }) => (highlited === '1' ? 'bold' : 'inherit')};

    border-right-style: solid;
    border-right-color: rgba(0.34, 0.36, 0.38, 0.1);
    border-right-width: ${({ border = 1 }) => `${border}px`};
  }
`;

const StyledTableRow = styled(TableRow)<{ total: '0' | '1' }>`
  &&& {
    background: ${({ total = '0' }) => (total === '1' ? '#f9fafb' : 'inherit')};
  }

  ${StatsIcon} {
    visibility: hidden;
  }

  :hover {
    ${Name} {
      text-decoration: underline;
    }
    ${StatsIcon} {
      visibility: visible;
    }
  }
`;

const NarrowRow = styled(StyledTableRow)`
  &&& {
    min-height: 23px;

    ${StyledTableCell} {
      padding-top: 0;
      padding-bottom: 0;

      border-top-style: dashed;
      border-top-color: rgba(0.34, 0.36, 0.38, 0.1);
    }
    &:first-child {
      ${StyledTableCell} {
        border-top-width: 0;
      }
    }
  }
`;

const NarrowHeaderRow = styled(NarrowRow)`
  &&& {
    ${StyledTableCell} {
      border-top-style: solid;
      border-top-width: 2px;
    }

    &:first-child {
      ${StyledTableCell} {
        border-top-width: 1px;
      }
    }
  }
`;

const RightAlignCell = styled(StyledTableCell)`
  &&& {
    text-align: right;
  }
`;

const NumberCell = styled(RightAlignCell)`
  &&& {
    font-weight: bold;
    font-size: 14px;
    font-family: monospace;
  }
`;

const StoresCell = styled(StyledTableCell)`
  &&& {
    color: ${COLOR.DARK_GRAY};
    text-align: right;
    font-family: monospace;
  }
`;

const PeriodCell = styled(StyledTableCell)`
  &&& {
    color: ${COLOR.DARK_GRAY};
    text-align: right;
    font-family: monospace;
  }
`;

const Label = styled.span`
  font-weight: bold;
`;
