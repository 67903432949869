import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { SegmentUpload } from 'components/molecules/FileUploader';
import { StepManual, StepManualHeader, StepManualMain, StepManualTitle } from 'components/organisms/StepManual';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { MapSearchRankConfigImportActions as Actions } from 'modules/mapSearchRank/import/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const MapSearchRankConfigImport = React.memo(() => {
  const dispatch = useDispatch();
  const { clearStatus, validateCsv, importCsv } = useMemo(() => bindActionCreators(Actions, dispatch), [dispatch]);

  const { importStatus, validationResult, isAcceptedImport } = useSelector(
    (state: State) => state.mapSearchRankConfigImport,
    shallowEqual,
  );

  // 再度インポート画面に来た際に
  useEffect(() => {
    // ページから離れる際にステータスをクリアする (なぜか型がエラーになるのでas anyしている)
    return () => clearStatus() as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ユーザがアップロードするCSVファイル
  const [file, setFile] = useState<File>();

  // ファイルの選択が解除された
  const onDelete = useCallback(() => {
    setFile(undefined);
    clearStatus();
  }, [clearStatus]);

  // バリデーションを実行（アップロード対象のファイルが選択されたとき）
  const onValidate = useCallback(
    (targetFile: File) => {
      setFile(targetFile);
      validateCsv({ file: targetFile });
    },
    [validateCsv],
  );

  // 「検索順位監視設定を更新」ボタンが押された
  const onClickImport = useCallback(() => {
    if (!file) {
      return;
    }
    importCsv({ file });
  }, [file, importCsv]);

  return (
    <>
      <MainWrapper>
        <Helmet title={getPageTitle('検索順位監視設定（まとめて更新）')} />
        <StickyHeader>
          <Title>検索順位監視設定（まとめて更新）</Title>
        </StickyHeader>
        <WideBody>
          {/* ステップマニュアル */}
          <StyledStepManual>
            <StepManualTitle>検索順位監視設定をまとめて更新するには</StepManualTitle>
            <StepManualHeader step={1} title='テンプレートのダウンロード' />
            <StepManualMain>
              <StyledLink to={Path.mapSearchRank.config} target='_blank'>
                こちら
              </StyledLink>
              からCSVをダウンロード
            </StepManualMain>
            <StepManualHeader step={2} title='CSVを編集' />
            <StepManualMain>
              CSVに検索順位監視設定を追加、または記載されている検索順位監視設定を編集
              <br />※ 編集する場合、検索ワード・緯度・経度・縮尺は変更できません。
            </StepManualMain>
            <StepManualHeader step={3} title='検索順位監視設定を更新' />
            <StepManualMain>
              編集したCSVをアップロード。エラー情報が表示された場合、CSVを再度編集してアップロード
            </StepManualMain>
          </StyledStepManual>

          {/* ファイルアップロード部分 */}
          {file ? (
            <>
              <MessageTitle>対象ファイル</MessageTitle>
              <FileText>
                <FileName>{file.name}</FileName>
                {!(importStatus === 'validating' || importStatus === 'importing') && <CancelIcon onClick={onDelete} />}
              </FileText>
            </>
          ) : (
            <StyledSegmentUpload
              multiple={false}
              uploadType={'file'}
              accept={['text/csv', 'application/octet-stream', 'application/vnd.ms-excel']}
              iconName='paperclip'
              buttonText='CSVを追加'
              onDropAccepted={(files: File[]) => onValidate(files[0])}
              onDropRejected={(files: File[]) => {
                console.error(files);
              }}
            />
          )}

          {/* CSVファイルのバリデーション中 */}
          {importStatus === 'validating' && (
            <MessageWrapper>
              <ProcessingTitle>
                <Loader active={true} size='small' inline={true} />
                <ProcessingTitleLabel>CSVファイルを検査中...</ProcessingTitleLabel>
              </ProcessingTitle>
            </MessageWrapper>
          )}

          {/* CSVファイルのバリデーション結果 */}
          {(importStatus === 'validationCompleted' || importStatus === 'validationFailed') && validationResult && (
            <ValidationCountWrapper>
              <ValidationCountTitle>検証結果</ValidationCountTitle>
              <ValidationCount>
                合計件数：{validationResult.totalSucceed + validationResult.totalWarning + validationResult.totalFailed}
              </ValidationCount>
              {validationResult.totalFailed > 0 && (
                <ValidationCount>問題が見つかった件数：{validationResult.totalFailed}</ValidationCount>
              )}
              {validationResult.totalWarning > 0 && (
                <ValidationCount>確認が必要な件数：{validationResult.totalWarning}</ValidationCount>
              )}
            </ValidationCountWrapper>
          )}

          {/* CSVファイルのバリデーションエラー */}
          {importStatus === 'validationFailed' && validationResult && validationResult.validationErrors && (
            <MessageWrapper>
              <ErrorTitle>
                <StatusIcon type={'status_error'} />
                {validationResult.validationErrors.message}
              </ErrorTitle>
              <Message>
                CSVファイルの検査の結果、内容の不備が
                {!validationResult.validationErrors.errors.isEmpty() &&
                  `${validationResult.validationErrors.errors.size}件`}
                見つかりました。
                <br />
                CSVファイルを修正後、再度アップロードしてください。
              </Message>
              {!validationResult.validationErrors.errors.isEmpty() ? (
                // errorsが空でない場合は、行単位でエラーがある
                <>
                  <MessageTitle>修正が必要な項目</MessageTitle>
                  {validationResult.validationErrors.errors.map((ve, idx) =>
                    ve.messages.map((message, idx2) => (
                      <Error key={`${idx}_${idx2}`}>・{`行 ${ve.row}: ${message}`}</Error>
                    )),
                  )}
                </>
              ) : (
                // errorsが空の場合は、CSV形式、ヘッダーカラムなどにエラーがある
                <Error>・{validationResult.validationErrors.message}</Error>
              )}
            </MessageWrapper>
          )}

          {/* CSVファイルのバリデーションに成功した（警告なしの場合） */}
          {importStatus === 'validationCompleted' && !validationResult?.validationWarnings && (
            <MessageWrapper>
              <MessageTitle>
                <StatusIcon type={'status_active'} />
                CSVファイルを使って検索順位監視設定を更新できます
              </MessageTitle>
              <Message>
                CSVファイルの検査の結果、特に問題は見つかりませんでした。
                <br />
                「検索順位監視設定を更新」ボタンをクリックして、検索順位監視設定の更新を開始してください。
              </Message>
            </MessageWrapper>
          )}

          {/* CSVファイルの順位監視設定を反映中 */}
          {importStatus === 'importing' && (
            <MessageWrapper>
              <ProcessingTitle>
                <Loader active={true} size='small' inline={true} />
                <ProcessingTitleLabel>検索順位監視設定の更新を実行中...</ProcessingTitleLabel>
              </ProcessingTitle>

              {isAcceptedImport && (
                <Message>
                  CSVファイルでの検索順位監視設定の更新は正常に受け付けられました。
                  <br />
                  対象件数が多い場合、処理完了まで時間がかかる場合があります。
                </Message>
              )}
            </MessageWrapper>
          )}

          {/* CSVファイルのバリデーション結果（警告あり） */}
          {/* エラーがある場合は、エラーの下に表示される */}
          {(importStatus === 'validationCompleted' || importStatus === 'validationFailed') &&
            validationResult?.validationWarnings && (
              <MessageWrapper>
                <WarningTitle>
                  <StatusIcon type={'status_warning'} />
                  {validationResult.validationWarnings.message}
                </WarningTitle>
                <Message>
                  CSVファイルの検査の結果、確認が必要な項目が
                  {`${validationResult?.validationWarnings?.warnings.size}件`}
                  見つかりました。
                  <br />
                  入力されたデータで問題がなければ、修正は必要ありません。
                  {/* バリデーションに成功している場合は、次の作業の文章を追加 */}
                  {/* バリデーションに失敗している場合は、エラー箇所の修正が必須なので非表示 */}
                  {importStatus === 'validationCompleted' && (
                    <>
                      <br />
                      「検索順位監視設定を更新」ボタンをクリックして、検索順位監視設定の更新を開始してください。
                      <br />
                      修正する場合は、編集したCSVファイルを再度アップロードしてください。
                    </>
                  )}
                </Message>
                {!validationResult?.validationWarnings?.warnings.isEmpty() && (
                  <MessageWrapper>
                    <MessageTitle>確認が必要な項目</MessageTitle>
                    {validationResult?.validationWarnings?.warnings.map((vw, idx) =>
                      vw.messages.map((message, idx2) => (
                        <Error key={`${idx}_${idx2}`}>・{`行 ${vw.row}: ${message}`}</Error>
                      )),
                    )}
                  </MessageWrapper>
                )}
              </MessageWrapper>
            )}

          {/* CSVファイルの反映に失敗した */}
          {importStatus === 'importFailed' && (
            <MessageWrapper>
              <ErrorTitle>検索順位監視設定の更新失敗しました</ErrorTitle>
              <Message>繰り返し試しても反映されない場合、画面左下の「サポート」からお問い合わせください。</Message>
            </MessageWrapper>
          )}

          {/* CSVファイルのバリデーションに成功した */}
          {importStatus === 'importCompleted' && (
            <MessageWrapper>
              <MessageTitle>検索順位監視設定の更新が完了しました</MessageTitle>
            </MessageWrapper>
          )}

          {/* 検索順位監視設定を更新 */}
          <ButtonsWrapper>
            <StyledButton
              onClick={onClickImport}
              priority='high'
              disabled={!file || importStatus != 'validationCompleted'}
            >
              検索順位監視設定を更新
            </StyledButton>
          </ButtonsWrapper>
        </WideBody>
      </MainWrapper>
    </>
  );
});

const StyledStepManual = styled(StepManual)`
  margin-bottom: 16px;
`;

const StyledLink = styled(Link)`
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

/**
 * ファイルアップロード部分
 */
const MessageTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const FileText = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  border: 2px solid ${COLOR.GRAY};
`;

const FileName = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CancelIcon = styled(Icon).attrs({ type: 'cancel' })`
  margin-left: auto;
  cursor: pointer;
`;

const StyledSegmentUpload = styled(SegmentUpload)`
  width: 100%;
`;

/**
 * ファイル選択後のメッセージ部分
 */
const MessageWrapper = styled.div`
  margin-top: 16px;
`;

const ProcessingTitle = styled(MessageTitle)`
  display: flex;
  align-items: center;
`;

const ProcessingTitleLabel = styled.div`
  margin-left: 8px;
  font-weight: bold;
`;

const Message = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
`;

/**
 * バリデーション結果部分
 */
const ValidationCountWrapper = styled.div``;

const ValidationCount = styled.div``;

const ValidationCountTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ErrorTitle = styled(MessageTitle)`
  color: ${COLOR.ERROR};
`;

const WarningTitle = styled(MessageTitle)`
  color: ${COLOR.WARNING};
`;

const Error = styled.div`
  font-size: 14px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 22px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  height: 54px;
  &&& {
    width: 100%;
  }
`;

const StatusIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 4px;
`;
