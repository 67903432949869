import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { useTextSelection } from 'hooks/useTextSelection';
import { Memo, MemoList } from 'models/Domain/Memo/Memo';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  memoList: MemoList;
  activeMemoIds?: ImmutableSet<number>; // 選択中のメモID
  onSelectMemo: (memo: Memo) => void;
};

export const MemoTable: React.FC<Props> = ({ className, memoList, activeMemoIds, onSelectMemo }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showDescriptionMemoIds, setShowDescriptionMemoIds] = useState<ImmutableSet<number>>(ImmutableSet());
  const [hoverIdx, setHoverIdx] = useState<number | null>(null);
  const closeAll = !showDescriptionMemoIds.isEmpty();
  const userList = useSelector((state) => state.user.userList);
  const currentUser = useSelector((state) => state.app.currentUser);
  const isTextSelected = useTextSelection();

  // memoListが更新されたらリセット
  useEffect(() => {
    setShowDescriptionMemoIds(ImmutableSet());
  }, [memoList]);

  // activeMemoIdsが更新されたらその行にスクロール
  useEffect(() => {
    if (activeMemoIds === undefined || activeMemoIds.isEmpty() || !ref.current) {
      return;
    }
    // 最もIDが大きい (より上に表示されている) メモの行にスクロール
    const itemId = activeMemoIds?.sort().last();
    // NOTE オンモーダルの際にモーダル下の要素を取得しないようuseRefを使ってモーダル内の要素に絞っている
    const row = ref.current.querySelector(`.memoTableRow_${itemId}`);
    if (!row) {
      return;
    }
    row.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, [activeMemoIds, ref]);

  const handleOnClickMemo = useCallback(
    (memo: Memo) => {
      // 文字列選択していたら発火しない
      if (isTextSelected) {
        return;
      }
      setShowDescriptionMemoIds((value) => (value.has(memo.id) ? value.remove(memo.id) : value.add(memo.id)));
    },
    [isTextSelected],
  );

  return (
    <Wrapper ref={ref}>
      <TableWrapper>
        <StyledTable unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <IconHeaderCell
                onClick={() =>
                  setShowDescriptionMemoIds(closeAll ? ImmutableSet() : memoList.items.map((item) => item.id).toSet())
                }
              >
                {closeAll ? '▼' : '▶'}
              </IconHeaderCell>
              <StyledTableHeaderCell>開始日</StyledTableHeaderCell>
              <StyledTableHeaderCell>終了日</StyledTableHeaderCell>
              <StyledTableHeaderCell>タイトル</StyledTableHeaderCell>
              <StyledTableHeaderCell>タグ</StyledTableHeaderCell>
              <StyledTableHeaderCell>作成者</StyledTableHeaderCell>
              <StyledTableHeaderCell />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {memoList.items.map((item, idx) => (
              <React.Fragment key={item.id}>
                <StyledTableRow
                  className={`memoTableRow_${item.id}`} // グラフのアイコン押下でスクロールするため指定
                  onClick={() => handleOnClickMemo(item)}
                  onMouseDown={() => {
                    window.getSelection()?.empty();
                  }}
                  isHover={hoverIdx === idx}
                  isActive={activeMemoIds?.has(item.id)}
                  onMouseOver={() => setHoverIdx(idx)}
                  onMouseLeave={() => setHoverIdx(null)}
                >
                  <IconCell rowSpan={showDescriptionMemoIds.has(item.id) ? 2 : 1}>
                    {showDescriptionMemoIds.has(item.id) ? '▼' : '▶'}
                  </IconCell>
                  <DateCell>{item.startDate?.format('YYYY/MM/DD')}</DateCell>
                  <DateCell>{item.endDate?.format('YYYY/MM/DD')}</DateCell>
                  <StyledTableCell>{item.title}</StyledTableCell>
                  <StyledTableCell>{item.tags.map((tag) => tag.tag).join(' / ')}</StyledTableCell>
                  <StyledTableCell>
                    {(item.createdBy && userList.findUser(item.createdBy)?.fullName) ?? ''}
                  </StyledTableCell>
                  <DetailCell rowSpan={showDescriptionMemoIds.has(item.id) ? 2 : 1}>
                    <EditButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelectMemo(item);
                      }}
                      disabled={
                        !(currentUser.isAdminUser || currentUser.isVmdUser || item.createdBy === currentUser.id)
                      }
                    >
                      編集
                    </EditButton>
                  </DetailCell>
                </StyledTableRow>
                {showDescriptionMemoIds.has(item.id) && (
                  <StyledTableRow
                    onClick={() => handleOnClickMemo(item)}
                    isHover={hoverIdx === idx}
                    isActive={activeMemoIds?.has(item.id)}
                    onMouseDown={() => {
                      window.getSelection()?.empty();
                    }}
                    onMouseOver={() => setHoverIdx(idx)}
                    onMouseLeave={() => setHoverIdx(null)}
                  >
                    <StyledTableCell colSpan={'5'}>
                      {item.description || <NoDescription>（本文がありません）</NoDescription>}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const StyledTable = styled(Table)``;
const StyledTableHeaderCell = styled(TableHeaderCell)`
  border: 1px solid ${COLOR.TABLE_BORDER};
`;

const StyledTableCell = styled(TableCell)`
  border: 1px solid ${COLOR.TABLE_BORDER};
  white-space: pre-wrap;
  &&& {
    min-width: 100px;
  }
`;

const DateCell = styled(StyledTableCell)`
  &&& {
    text-align: center;
    font-family: monospace;
    min-width: 120px;
    width: 120px;
  }
`;

const DetailCell = styled(StyledTableCell)`
  &&& {
    text-align: center;
    min-width: 64px;
    width: 64px;
  }
`;

const EditButton = styled(Button).attrs({ priority: 'low' })``;

const IconHeaderCell = styled(StyledTableHeaderCell)`
  &&& {
    text-align: center;
    color: ${COLOR.DARK_GRAY};
    min-width: 60px;
    width: 64px;
    user-select: none;
    cursor: pointer;

    &:hover {
      color: ${COLOR.CHARCOAL_GRAY};
    }
  }
`;

const IconCell = styled(StyledTableCell)`
  &&& {
    text-align: center;
    color: ${COLOR.DARK_GRAY};
    min-width: 60px;
    width: 64px;
    user-select: none;

    &:hover {
      color: ${COLOR.CHARCOAL_GRAY};
    }
  }
`;

const StyledTableRow = styled(TableRow)<{ isHover: boolean; isActive: boolean }>`
  &&& {
    cursor: pointer;
    background-color: ${({ isHover, isActive }) => (isHover ? '#f2f2f2' : isActive ? '#e1f4f0' : 'unset')};
    &:hover {
      background-color: ${({ isHover, isActive }) => (isHover ? '#f2f2f2' : isActive ? '#e1f4f0' : 'unset')};
    }
  }
`;

const NoDescription = styled.div`
  color: ${COLOR.GRAY};
`;
