import React from 'react';

import styled from 'styled-components';

import Menu from 'components/atoms/Menu';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { ComparisonGraphType, getComparisonGraphOptions } from 'helpers/graph';
import { InteractionType, InteractionTypeLabel, ReviewType } from 'models/Domain/GbpPerformance/GbpPerformance';
import { assertNever } from 'types/Common';

import { GraphType, graphTypeLabel, graphTypeLabelContextHelp } from './index';

const interactionTypes: InteractionType[] = ['value', 'rate'];

const ComparisonGraphOptionSelect = React.memo<{
  disableCombined: boolean;
  comparisonGraphType: ComparisonGraphType;
  onChange: (value: ComparisonGraphType) => void;
}>(({ disableCombined, comparisonGraphType, onChange }) => (
  <PullDownWrapper>
    <PullDownLabel>比較期間のグラフ</PullDownLabel>
    <StyledPullDown
      value={comparisonGraphType}
      options={getComparisonGraphOptions(disableCombined)}
      onChange={onChange}
    />
  </PullDownWrapper>
));

type GraphHeaderProps = {
  graphType: GraphType;
  isEnabledComparison: boolean;
  disableCombined: boolean;
  isStacked: boolean;
  reviewType: ReviewType;
  interactionType: InteractionType;
  comparisonGraphType: ComparisonGraphType;
  setComparisonGraphType: (value: ComparisonGraphType) => void;
  onChangeInteractionType: (value: InteractionType) => void;
  setIsStacked: (value: boolean) => void;
  onChangeReviewType: (value: ReviewType) => void;
};

export const GraphHeader = React.memo<GraphHeaderProps>(
  ({
    graphType,
    isEnabledComparison,
    disableCombined,
    comparisonGraphType,
    interactionType,
    isStacked,
    reviewType,
    setComparisonGraphType,
    setIsStacked,
    onChangeInteractionType,
    onChangeReviewType,
  }) => {
    // 比較期間のグラフのタイプ
    return (
      <GraphHeaderWrapper>
        <GraphTypeLabel>
          {graphTypeLabel[graphType]}
          {graphTypeLabelContextHelp[graphType] && (
            <ContextHelp header={graphTypeLabel[graphType]} content={graphTypeLabelContextHelp[graphType]} />
          )}
        </GraphTypeLabel>

        {isEnabledComparison && (
          <ComparisonGraphOptionSelect
            disableCombined={disableCombined}
            comparisonGraphType={comparisonGraphType}
            onChange={setComparisonGraphType}
          />
        )}
        {(() => {
          switch (graphType) {
            case 'overview':
              return (
                <MenuContainer>
                  <Menu>
                    {interactionTypes.map((type) => (
                      <Menu.Item
                        key={type}
                        name={type}
                        active={interactionType === type}
                        onClick={() => onChangeInteractionType(type)}
                      >
                        {InteractionTypeLabel[type]}
                      </Menu.Item>
                    ))}
                  </Menu>
                </MenuContainer>
              );
            case 'impressions':
            case 'interactions':
              return (
                <MenuContainer>
                  <Menu>
                    {interactionTypes.map((type) => (
                      <Menu.Item
                        key={type}
                        name={type}
                        active={interactionType === type}
                        onClick={() => onChangeInteractionType(type)}
                      >
                        {InteractionTypeLabel[type]}
                      </Menu.Item>
                    ))}
                  </Menu>
                  <Menu>
                    <Menu.Item name={'unstacked'} active={!isStacked} onClick={() => setIsStacked(false)}>
                      折れ線グラフ
                    </Menu.Item>
                    <Menu.Item name={'stacked'} active={isStacked} onClick={() => setIsStacked(true)}>
                      積み上げグラフ
                    </Menu.Item>
                  </Menu>
                </MenuContainer>
              );
            case 'reviews':
              return (
                <MenuWrapper>
                  <MenuLabel>クチコミの集計対象</MenuLabel>
                  <Menu>
                    <Menu.Item
                      name={'period'}
                      active={reviewType === 'period'}
                      onClick={() => onChangeReviewType('period')}
                      style={{ padding: '0 12px' }}
                    >
                      期間
                    </Menu.Item>
                    <Menu.Item
                      name={'total'}
                      active={reviewType === 'total'}
                      onClick={() => onChangeReviewType('total')}
                      style={{ padding: '0 12px' }}
                    >
                      累計
                    </Menu.Item>
                  </Menu>
                </MenuWrapper>
              );
            case 'promotions':
            case 'images':
              return <MenuWrapper />;
            default:
              return assertNever(graphType);
          }
        })()}
      </GraphHeaderWrapper>
    );
  },
);

const GraphHeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  min-height: 40px;
`;

const PullDownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PullDownLabel = styled.div`
  font-size: 14px;
  margin-right: 8px;
`;

const StyledPullDown = styled(PullDownNarrow)`
  width: 176px;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MenuLabel = styled.div`
  font-size: 14px;
`;

const GraphTypeLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
  gap: 8px;
  flex-wrap: wrap;
`;
