import React from 'react';

import styled from 'styled-components';

import { StarRatingType } from 'models/Domain/GmbReview';

interface StarsProps {
  className?: string;
  starRating?: StarRatingType;
  count?: number;
}

export const Stars: React.FC<StarsProps> = ({ className, starRating, count }) => {
  const starCount = {
    STAR_RATING_UNSPECIFIED: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
  };
  return (
    <StarsWrapper className={className}>
      <Star starCount={count || starCount[starRating || `STAR_RATING_UNSPECIFIED`]} />
    </StarsWrapper>
  );
};

const StarsWrapper = styled.span`
  background-size: 14px 13px;
  height: 13px;
  width: 69px;
  background-image: url(/images/star_back.svg);
  background-repeat: repeat-x;
  display: inline-block;
  overflow: hidden;
  position: relative;
`;

const Star = styled.span<{ starCount: number }>`
  background-size: 14px 13px;
  height: 13px;
  width: ${(props) => props.starCount * 14}px;
  background-image: url(/images/star.svg);
  background-repeat: repeat-x;
  display: block;
`;
