import { useCallback, useMemo } from 'react';

import { useStorage } from 'hooks/useStorage';
import { CompetitorSettings } from 'models/Domain/MapCompetitorResearch/Competitor';
import { SearchHistory, SearchHistoryList } from 'models/Domain/MapCompetitorResearch/SearchHistory';
import { JSObject } from 'types/Common';

/**
 * 検索順位調査の履歴をローカルストレージで取り扱うためのhooks
 */
export const useSearchHistory = (): [SearchHistoryList, (history: SearchHistory) => void, (id: string) => void] => {
  const [rawHistories, saveRawHistories] = useStorage<JSObject>(
    'TOOLS_SERPS_HISTORY',
    new SearchHistoryList().toJSON(),
  );

  const histories = useMemo(() => SearchHistoryList.fromJSON(rawHistories), [rawHistories]);

  const setHistories = useCallback(
    (newHistories: SearchHistoryList) => {
      if (JSON.stringify(newHistories.toJSON()) !== JSON.stringify(histories.toJSON())) {
        saveRawHistories(newHistories.toJSON());
      }
    },
    [histories, saveRawHistories],
  );

  const pushHistory = useCallback(
    (history: SearchHistory) => {
      setHistories(histories.push(history));
    },
    [histories, setHistories],
  );

  const deleteHistory = useCallback(
    (id: string) => {
      setHistories(histories.delete(id));
    },
    [histories, setHistories],
  );

  return [histories, pushHistory, deleteHistory];
};

/**
 * 比較店舗設定をローカルストレージで取り扱うためのhooks
 * FIXME: 履歴と一緒に保存したい
 */
export const useCompetitorSettings = (): [
  CompetitorSettings,
  (settings: CompetitorSettings) => void,
  (id: string) => void,
] => {
  const [rawSettings, saveRawSettings] = useStorage<JSObject>(
    'TOOLS_SERPS_COMPETITOR_SETTINGS',
    new CompetitorSettings().toJSON(),
  );

  const competitorSettings = useMemo(() => CompetitorSettings.fromJSON(rawSettings), [rawSettings]);
  const updateCompetitorSettings = useCallback(
    (newSettings: CompetitorSettings) => {
      if (JSON.stringify(newSettings.toJSON()) !== JSON.stringify(competitorSettings.toJSON())) {
        saveRawSettings(newSettings.toJS());
      }
    },
    [competitorSettings, saveRawSettings],
  );

  const deleteCompetitorSetting = useCallback(
    (id: string) => {
      updateCompetitorSettings(competitorSettings.deleteItem(id));
    },
    [competitorSettings, updateCompetitorSettings],
  );

  return [competitorSettings, updateCompetitorSettings, deleteCompetitorSetting];
};
