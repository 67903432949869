import React, { useCallback, useMemo, useState } from 'react';

import { goBack } from 'connected-react-router';
import { List, Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { PublishToGmb } from 'components/organisms/PublishToGmb';
import { MultiPreview } from 'components/pageComponents/AlbumImageCreate/MultiPreview';
import { SelectHashtag } from 'components/pageComponents/AlbumImageCreate/SelectHashtag';
import { SinglePreview } from 'components/pageComponents/AlbumImageCreate/SinglePreview';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { AppActions } from 'modules/app/actions';
import { ImageActions } from 'modules/image/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { Group, JSObject } from 'types/Common';

type SelectedGroupStore = {
  group: Group;
  storeIds: Set<number>;
  showClosedStores: boolean;
  isAllStores: boolean;
};

export const AlbumImageCreate: React.FC = () => {
  /**
   * グループ、店舗のフィルターを変更する
   * @param group グループ
   * @param storeIds 店舗のID
   * @param isAllStores すべての店舗が選択されているか
   * @param showClosedStores 閉店店舗を表示するか
   */

  const dispatch = useDispatch();

  const { setImageListForCreate } = useMemo(() => bindActionCreators(ImageActions, dispatch), [dispatch]);

  const { imageListForCreate, hashtagList, stores, currentUser } = useSelector(
    (state: State) => ({
      imageListForCreate: state.image.imageListForCreate,
      hashtagList: state.image.hashtagList,
      stores: state.store.stores,
      currentUser: state.app.currentUser,
    }),
    shallowEqual,
  );

  const initialGroupStoreState: SelectedGroupStore = {
    group: null,
    storeIds: Set(),
    showClosedStores: false,
    isAllStores: false,
  };

  const canUseProductCategory = currentUser.organization?.params.can_use_gbp_media_product_category ?? false;

  // 画像情報が存在しない場合は写真の一覧へリダイレクト
  if (imageListForCreate.list.size === 0) {
    dispatch(AppActions.moveTo(Path.album.index));
  }

  const [selectedHashTagList, setSelectedHashTagList] = useState<List<string>>(List());
  const [selectedGroupStore, setSelectedGroupStore] = useState<SelectedGroupStore>(initialGroupStoreState);

  const [publishToGmb, setPublishToGmb] = useState<boolean>(true);
  const [category, setCategory] = useState<string>('INTERIOR');

  const firstImage = imageListForCreate.list.get(0);

  // 「STORECASTに画像を追加する」ボタンが押せるかどうか
  const isValid = useMemo(() => {
    // 写真をGBPに掲載する場合は、GBP掲載要件を満たしていること
    if (publishToGmb && !imageListForCreate.isValidToGBP) {
      return false;
    }
    return selectedGroupStore.storeIds.size;
  }, [publishToGmb, imageListForCreate, selectedGroupStore.storeIds]);

  const hasGmbConnectedStore = stores.hasGmbConnectedStoreInStoreIds(selectedGroupStore.storeIds.toArray());

  const handleChangeGroupStore = useCallback(
    (group: Group, storeIds: Set<number>, isAllStores: boolean, showClosedStores: boolean) => {
      setSelectedGroupStore({ group, storeIds, isAllStores, showClosedStores });
    },
    [],
  );

  const handleOnClickAddButton = () => {
    const params: JSObject = {
      image_urls: imageListForCreate.list.map((image) => image.url).toArray(),
      store_ids: selectedGroupStore.storeIds.toArray(),
      tags: selectedHashTagList.toArray(),
      publish_to_gmb: publishToGmb && hasGmbConnectedStore,
    };
    if (publishToGmb && hasGmbConnectedStore) params.gmb_category = category;

    dispatch(ImageActions.createImage(params));
  };

  /**
   * 写真のプレビューのバツボタンが押された
   * 該当インデックスの写真を削除する
   */
  const handleOnRemoveButton = useCallback(
    (index: number) => {
      // 確認をはさむ
      if (!window.confirm(`${index + 1}枚目の写真の追加を取りやめます。よろしいですか？`)) {
        return;
      }
      setImageListForCreate(imageListForCreate.removeIndex(index));
    },
    [imageListForCreate, setImageListForCreate],
  );

  React.useEffect(() => {
    dispatch(ImageActions.getHashtagList());
  }, [dispatch]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('写真を追加')} />
      {/* 画像のプレビュー */}
      {imageListForCreate.list.size === 1 && firstImage && <SinglePreview image={firstImage} />}
      {imageListForCreate.list.size > 1 && (
        <MultiPreview imageList={imageListForCreate} onRemove={handleOnRemoveButton} />
      )}
      <Body>
        <GroupStoreSelect
          group={selectedGroupStore.group}
          storeIds={selectedGroupStore.storeIds}
          showClosedStores={selectedGroupStore.showClosedStores}
          sizeVariant={'large'}
          onChange={handleChangeGroupStore}
        />
        <SelectHashtag
          hashtagList={hashtagList}
          selectedHashTagList={selectedHashTagList}
          setSelectedHashTagList={setSelectedHashTagList}
        />
        <PublishToGmb
          isOpen={publishToGmb && hasGmbConnectedStore}
          setPublishToGmb={(v) => setPublishToGmb(v)}
          category={category}
          setCategory={(v) => setCategory(v)}
          disabled={!hasGmbConnectedStore}
          shouldDisplayWarning={
            hasGmbConnectedStore && stores.hasGmbUnconnectedStoreInStoreIds(selectedGroupStore.storeIds.toArray())
          }
          canUseProductCategory={canUseProductCategory}
        />
        {/* GBP掲載しようとしていて、写真がバリデーションNGの場合、エラーを表示 */}
        {publishToGmb && !imageListForCreate.isValidToGBP && (
          <ValidationError>
            <ValidationErrorTitle>
              以下の理由で写真をGoogleビジネスプロフィールに掲載することができません。
              <br />
              写真を修正して、再度アップロードしてください。
            </ValidationErrorTitle>
            {imageListForCreate.validateToGBP.map((errors, index) => (
              <div key={index}>
                {/* 写真が2枚以上ある場合は何枚目に対するエラーかを表示 */}
                {imageListForCreate.list.size > 1 ? (
                  <>
                    {errors.length > 0 && <ErrorIndex>{index + 1}枚目の写真</ErrorIndex>}
                    {errors.map((error, index) => (
                      <ErrorMessage key={index}>{error}</ErrorMessage>
                    ))}
                  </>
                ) : (
                  errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
                )}
              </div>
            ))}
          </ValidationError>
        )}
        <ButtonsWrapper>
          <AddButton priority='high' disabled={!isValid} onClick={() => handleOnClickAddButton()}>
            STORECASTに画像を追加する
          </AddButton>
          <BackButton onClick={() => dispatch(goBack())}>キャンセル</BackButton>
        </ButtonsWrapper>
      </Body>
    </MainWrapper>
  );
};

const ValidationError = styled.div`
  margin-top: 32px;
`;

const ValidationErrorTitle = styled.div`
  font-size: 14px;
  color: ${COLOR.ERROR};
  margin-bottom: 16px;
`;

const ErrorIndex = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const ErrorMessage = styled.div`
  display: list-item;
  list-style: inside;
  text-indent: 0.5em;
  font-size: 14px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-flow: row-reverse;
  margin-top: 32px;
  @media (max-width: 1024px) {
    display: block;
    margin-top: 0;
  }
`;

const AddButton = styled(Button)`
  &&& {
    display: block;
    width: 400px;
    @media (max-width: 1024px) {
      margin-top: 32px;
      width: 100%;
    }
  }
`;

const BackButton = styled(Button)`
  &&& {
    display: block;
    width: 170px;
    margin-right: 16px;
    @media (max-width: 1024px) {
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
    }
  }
`;
