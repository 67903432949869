import React from 'react';

import { Icon, TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';

import { UserThumbnail } from 'components/molecules/UserThumbnail';
import { UserList } from 'models/Domain/User';
import { COLOR } from 'style/color';

type Props = {
  readUsers: UserList;
  isModalOpen: boolean;
  onClose: () => void;
};

export const ReadUsersPortal: React.FC<Props> = ({ readUsers, isModalOpen, onClose }) => {
  return (
    <TransitionablePortal
      open={isModalOpen}
      transition={{
        animation: 'fade',
        duration: 500,
      }}
    >
      <PortalWrapper onClick={() => onClose()}>
        <PortalContent>
          <Header>
            <Title>既読</Title>
            <CloseIcon name='close' />
          </Header>
          <Body>
            <UserListWrapper>
              {readUsers.list.map((user) => {
                return (
                  <UserWrapper key={user.id}>
                    <UserThumbnail src={user.profile_image_url} name={user.last_name} />
                    <UserName>{user.fullName}</UserName>
                  </UserWrapper>
                );
              })}
            </UserListWrapper>
          </Body>
        </PortalContent>
      </PortalWrapper>
    </TransitionablePortal>
  );
};

const PortalWrapper = styled.div`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 200;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.35);
  }
`;

const PortalContent = styled.div`
  height: 80%;
  width: 100%;
  max-height: 800px;
  max-width: 600px;
  background-color: ${COLOR.WHITE};
  @media (max-width: 600px) {
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  background-color: ${COLOR.LIGHT_GRAY};
`;

const Title = styled.div`
  font-weight: bold;
`;

const CloseIcon = styled(Icon)`
  &&& {
    font-size: 24px;
    line-height: 1;
    color: ${COLOR.GREEN};
  }
`;

const Body = styled.div`
  overflow-y: scroll;
  padding: 10px 10px 60px 10px;
  height: calc(100% - 60px);
`;

const UserListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const UserName = styled.div`
  margin-left: 10px;
`;
