import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';

type CsvDownloadButtonProps = {
  className?: string;
  label: string;
  disabled: boolean;
  isProcessing: boolean;
  onClick: () => void;
};

export const CsvDownloadButton = ({ className, label, disabled, isProcessing, onClick }: CsvDownloadButtonProps) => (
  <Wrapper className={className}>
    <LoaderContainer>{isProcessing && <StyledLoader active={true} size='tiny' inline={true} />}</LoaderContainer>
    <Button priority='low' disabled={disabled} onClick={onClick}>
      {label}
    </Button>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  vertical-align: baseline;
`;
const LoaderContainer = styled.div`
  margin-top: -6px;
  margin-right: -4px;
`;
const StyledLoader = styled(Loader)`
  // モーダル上で表示すると線が見えないため調整
  &&&& {
    &:before {
      border-color: rgba(0, 0, 0, 0.1);
    }
    &:after {
      border-color: #767676 transparent transparent;
    }
  }
`;
