import React from 'react';

import { Dropdown, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { TableCell, TableHeaderCell } from 'components/molecules/Table';
import { COLOR } from 'style/color';

// テーブル要素が空の場合に表示する
export const Notification = styled.div`
  text-align: center;
  background: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top-width: 0;

  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  color: ${COLOR.GRAY};
  font-size: 16px;
`;

const Tag = styled.div`
  display: inline-block;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.GRAY};
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 10px;
`;

// 広告のタグ
export const AdTag = () => <Tag>広告</Tag>;

// 設定アイコン
export const SettingsIcon = styled(Icon).attrs({
  type: 'settings',
})`
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  margin-bottom: -4px;
  margin-left: 16px;
`;

// 順位
export const Rank = styled.div`
  font-family: monospace !important;
`;

// 店舗名
export const StoreName = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

// 検索ワード
export const SearchWord = styled.div`
  font-weight: bold;
  cursor: pointer;
`;

// 色付けのためのセル
export const ColorHeaderCell = styled(TableHeaderCell)`
  width: 8px;
  padding: 0 !important;
`;

export const ColorCell = styled(TableCell)<{ color?: string }>`
  width: 8px;
  padding: 0 !important;
  background: ${({ color }) => color ?? 'transparent'};
`;

// メニュー
export const MenuHeaderCell = styled(TableHeaderCell)`
  width: 32px;
`;

export const MenuCell = styled(TableCell)`
  width: 48px;
`;

export const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY};
  }
`;

export const ActionItemLabel = styled.div<{ disabled?: boolean }>`
  padding: 4px 8px;
  font-size: 14px;
  color: ${({ disabled = false }) => (disabled ? COLOR.GRAY : COLOR.BLACK)};
`;

const AreaKeywordWrapper = styled.div``;

const SearchWordWrapper = styled.span<{ isLink: boolean }>`
  text-decoration: ${({ isLink }) => (isLink ? 'underline' : 'none')};

  &:hover {
    text-decoration: none;
  }
`;

export const AreaNameWrapper = styled.span`
  margin: 3px 0 0 5px;
  font-size: max(0.8em, 12px);
  color: ${COLOR.GRAY};
`;

export const AreaIcon = styled(Icon)`
  width: 10px;
  height: 10px;
  padding: 0;
`;

type AreaKeywordLabelProps = {
  areaName: string;
  searchWord: string;
  isLink?: boolean;
};

export const AreaKeywordLabel = ({ areaName, searchWord, isLink = false }: AreaKeywordLabelProps) => (
  <AreaKeywordWrapper>
    <SearchWordWrapper isLink={isLink}>{searchWord}</SearchWordWrapper>
    <AreaNameWrapper>
      <AreaIcon type='pin_gray' />
      {areaName}
    </AreaNameWrapper>
  </AreaKeywordWrapper>
);

type RankCellProps = {
  className?: string;
  rank?: number | null;
  color?: boolean;
  ads?: boolean;
};

export const RankCell = ({ className, rank, color = false, ads = false }: RankCellProps) => {
  let backgroundColor = 'transparent';
  if (rank && color) {
    if (rank <= 3) {
      backgroundColor = '#f4cccc';
    } else if (rank <= 10) {
      backgroundColor = '#fce5cd';
    } else if (rank <= 20) {
      backgroundColor = '#fff2cc';
    } else if (rank <= 40) {
      backgroundColor = '#d9ead3';
    } else if (rank <= 60) {
      backgroundColor = '#d0e0e3';
    }
  }
  return (
    <TableCell className={className} style={{ backgroundColor, textAlign: 'right' }}>
      {ads && (
        <div style={{ display: 'inline-block', marginRight: 8 }}>
          <AdTag />
        </div>
      )}
      <Rank style={{ display: 'inline' }}>{rank || '-'}</Rank>
    </TableCell>
  );
};

const CustomIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 0;
`;

const CustomIconWrapper = styled.span`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

export const ShopIcon = () => (
  <CustomIconWrapper>
    <Popup
      content={
        <PopupContent>
          <div>あなたの所属組織の店舗</div>
        </PopupContent>
      }
      trigger={
        <PopupTrigger>
          <CustomIcon type={'shop_green'} />
        </PopupTrigger>
      }
    />
  </CustomIconWrapper>
);

const PopupTrigger = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-family: monospace !important;
  font-weight: normal;
`;

const PopupContent = styled.div`
  font-size: 12px;
`;
