import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { downloadComponent } from 'helpers/export';
import { isNonNegativeInteger } from 'helpers/utils';
import { usePrevious } from 'hooks/usePrevious';
import { useStorage } from 'hooks/useStorage';
import { COLOR } from 'style/color';

// ダウンロードするグラフの幅と高さは、一旦GBPパフォーマンス画面で設定したものを他のグラフでも共通で利用する
// 将来的にはグラフ別に保持するかもしれない
const DOWNLOAD_GRAPH_WIDTH_KEY = 'GBP_PERFORMANCE_DOWNLOAD_GRAPH_WIDTH';
const DOWNLOAD_GRAPH_HEIGHT_KEY = 'GBP_PERFORMANCE_DOWNLOAD_GRAPH_HEIGHT';

const MIN_WIDTH = 300;
const MIN_HEIGHT = 300;

const DEFAULT_WIDTH = 720;
const DEFAULT_HEIGHT = 360;

type Props = {
  className?: string;
  isOpen: boolean;
  fileName: string;
  children: React.ReactElement;
  onClose: () => void;
};

export const GraphDownloadModal = React.memo<Props>(({ className, isOpen, fileName, children, onClose }) => {
  const [graphWidth, setGraphWidth] = useStorage(DOWNLOAD_GRAPH_WIDTH_KEY, DEFAULT_WIDTH);
  const [graphHeight, setGraphHeight] = useStorage(DOWNLOAD_GRAPH_HEIGHT_KEY, DEFAULT_HEIGHT);
  const [inputWidth, setInputWidth] = useState<string>(`${graphWidth}`);
  const [inputHeight, setInputHeight] = useState<string>(`${graphHeight}`);
  const ref = useRef<HTMLDivElement>(null);
  // childrenで渡されたグラフコンポーネントに指定した高さを設定
  const graph = React.cloneElement(children, { height: graphHeight });
  const prevIsOpen = usePrevious(isOpen);

  const handleOnChangeWidth = useCallback(
    (value: string) => {
      // inputWidthは入力された文字列をそのまま、graphWidthには有効な数値の場合のみセットする
      setInputWidth(value);
      if (isNonNegativeInteger(value)) {
        const width = Number.parseInt(value, 10);
        setGraphWidth(width < MIN_WIDTH ? MIN_WIDTH : width);
      }
    },
    [setGraphWidth],
  );

  const handleOnBlurWidth = useCallback(() => {
    // onBlurのタイミングで実際のグラフのサイズに揃える
    const width = graphWidth < MIN_WIDTH ? MIN_WIDTH : graphWidth;
    setInputWidth(`${width}`);
    setGraphWidth(width);
  }, [graphWidth, setGraphWidth]);

  const handleOnChangeHeight = useCallback(
    (value: string) => {
      // inputHeightは入力された文字列をそのまま、graphHeightには有効な数値の場合のみセットする
      setInputHeight(value);
      if (isNonNegativeInteger(value)) {
        const height = Number.parseInt(value, 10);
        setGraphHeight(height < MIN_HEIGHT ? MIN_HEIGHT : height);
      }
    },
    [setGraphHeight],
  );

  const handleOnBlurHeight = useCallback(() => {
    // onBlurのタイミングで実際のグラフのサイズに揃える
    const height = graphHeight < MIN_HEIGHT ? MIN_HEIGHT : graphHeight;
    setInputHeight(`${height}`);
    setGraphHeight(height);
  }, [graphHeight, setGraphHeight]);

  useEffect(() => {
    // モーダルが開いたタイミングで、inputHeightとgraphWidthをgraphHeightとgraphWidthに揃える
    if (!prevIsOpen && isOpen) {
      // graphHeightとinputHeightが異なれば、inputHeightをgraphHeightに揃える
      if (inputHeight !== `${graphHeight}`) {
        setInputHeight(`${graphHeight}`);
      }
      // graphWidthとinputWidthが異なれば、inputWidthをgraphWidthに揃える
      if (inputWidth !== `${graphWidth}`) {
        setInputWidth(`${graphWidth}`);
      }
    }
  }, [graphHeight, graphWidth, inputHeight, inputWidth, isOpen, prevIsOpen]);

  return (
    <StyledModal className={className} open={isOpen}>
      <ModalContent>
        <Wrapper>
          <Title>グラフをダウンロード</Title>
          <ContentWrapper>
            <InputContainer>
              <InputContent>
                <Label>幅</Label>
                <NumberInput
                  type={'number'}
                  value={inputWidth}
                  onChange={handleOnChangeWidth}
                  onBlur={handleOnBlurWidth}
                  min={MIN_WIDTH}
                />
              </InputContent>
              <InputContent>
                <Label>高さ</Label>
                <NumberInput
                  type={'number'}
                  value={inputHeight}
                  onChange={handleOnChangeHeight}
                  onBlur={handleOnBlurHeight}
                  min={MIN_HEIGHT}
                />
              </InputContent>
            </InputContainer>
            <GraphContainer>
              <GraphWrapper style={{ width: graphWidth, height: graphHeight }}>
                <DownloadWrapper style={{ width: graphWidth, height: graphHeight }} ref={ref}>
                  {graph}
                </DownloadWrapper>
              </GraphWrapper>
            </GraphContainer>
          </ContentWrapper>
          <ButtonWrapper>
            <StyledButton onClick={onClose}>キャンセル</StyledButton>
            <StyledButton
              onClick={() => {
                downloadComponent(ref.current, fileName);
                onClose();
              }}
              priority={'high'}
            >
              ダウンロード
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContent>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)``;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const StyledButton = styled(Button)`
  &&& {
    max-width: 180px;
    width: calc(50% - 8px);
    padding: 14px 8px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 16px;
  height: 50px;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
`;
const InputContent = styled.div``;
const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 7px;
  margin-bottom: 4px;
  font-weight: bold;
`;
const StyledInput = styled(Input)`
  &&& {
    padding: 0;
    height: 44px;
  }
`;

const NumberInput = styled(StyledInput)`
  &&& {
    width: 100px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const GraphContainer = styled.div`
  display: flex;
  flex: 1;
  padding-right: 16px;
  padding-bottom: 16px;
  max-height: 50vh;
  min-height: 300px;
  box-sizing: content-box;
`;
const GraphWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
  box-sizing: content-box;
  border: 1px solid ${COLOR.LIGHT_GRAY};
  /* ダウンロード用なので、グラフのホバーなどに反応しないようにする */
  pointer-events: none;
  user-select: none;
`;
const DownloadWrapper = styled.div`
  background: ${COLOR.WHITE};
`;
