/* eslint-disable @typescript-eslint/no-use-before-define */

// タスク詳細ページ用で取得されるタスクデータに対応するモデル
// APIのデータ形式自体は src/types/responses/Tasks.ts を参照
// オファーデータの形式は ./Offer.ts に定義されているものと異なるため、別途定義する（本ファイル内）
// その際、status_changes, offer_image_comments, read_atsあたりは現状不要なのでモデルに含めない

import dayjs, { Dayjs } from 'dayjs';
import { List, Record } from 'immutable';

import { JSObject } from 'types/Common';

import { CommonContent } from './CommonContent';
import { FileContent } from './FileContent';
import { OfferStatus, OfferType } from './Offer';

interface TaskDetailRecord {
  id: number;
  name: string;
  content_id: number;
  create_at: Dayjs;
  update_at: Dayjs;
  due_date: Dayjs;
  latest_activity_time: Dayjs;
  content: CommonContent;
  offers: List<TaskOffer>;
}

export class TaskDetail extends Record<TaskDetailRecord>({
  id: 0,
  name: '',
  content_id: 0,
  create_at: dayjs(),
  update_at: dayjs(),
  due_date: dayjs(),
  latest_activity_time: dayjs(),
  content: new CommonContent(),
  offers: List(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new TaskDetail({
      ...data,
      create_at: dayjs(data.create_at),
      update_at: dayjs(data.update_at),
      due_date: dayjs(data.due_date),
      latest_activity_time: dayjs(data.latest_activity_time),
      content: new CommonContent(data.content),
      offers: List(data.offers.map((v: any) => TaskOffer.fromJSON(v))),
    });
  }

  /**
   * 依頼の編集ページのパス
   */
  get editPagePath() {
    // NOTE 2021/09現在、タスクの編集はofferの編集ページで代替するので、1つ目のオファーの編集ページを返す
    const offerId = this.offers.get(0)?.id;

    if (offerId === undefined) {
      return '';
    }

    // FIXME
    // 以下のように実装しようとしたが、Pathを'route'からインポートすると、
    // 巡り巡って全てのコンポーネントをimportすることになり、結果テストがエラーになる
    // return Path.offer.edit.replace(':offerId', String(offerId));
    // のでしょうがなく、文字列で処理している
    return `/offer/${offerId}/edit`;
  }

  /**
   * 依頼のステータスのラベル
   *
   * '完了' or 進捗を表す文字列 ex. '12 / 30'
   */
  get statusLabel() {
    const doneCount = this.offers.filter((v) => v.status == 'done').count();
    const totalCount = this.offers.count();

    if (doneCount === totalCount) {
      return '完了' as const;
    }

    return `${doneCount} / ${totalCount}`;
  }

  /**
   * 完了しているか
   */
  get isDone() {
    return this.statusLabel === '完了';
  }

  /**
   * 期限を過ぎているかどうか
   */
  get isOverdue() {
    return this.due_date.isBefore(dayjs());
  }
}

interface TaskOfferRecord {
  id: number;
  organization_id: number;
  store_id: number;
  status: OfferStatus;
  offer_type: OfferType;
  create_at: Dayjs;
  update_at: Dayjs;
  comment_count: number;
  comments: List<TaskOfferComment>;
  stamps: List<TaskOfferStamp>;
  status_changes: List<TaskOfferStatusChange>;
  offer_image_comments: List<TaskOfferImageComment>;
  read_ats: List<TaskOfferReadAt>;
}

export class TaskOffer extends Record<TaskOfferRecord>({
  id: 0,
  organization_id: 0,
  store_id: 0,
  status: 'none',
  offer_type: 'task',
  create_at: dayjs(),
  update_at: dayjs(),
  comment_count: 0,
  comments: List(),
  stamps: List(),
  status_changes: List(),
  offer_image_comments: List(),
  read_ats: List(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new TaskOffer({
      ...data,
      create_at: dayjs(data.create_at),
      update_at: dayjs(data.update_at),
      comments: List(data.comments.map((v: any) => TaskOfferComment.fromJSON(v))),
      stamps: List(data.stamps.map((v: any) => TaskOfferStamp.fromJSON(v))),
      status_changes: List(data.status_changes.map((v: any) => TaskOfferStatusChange.fromJSON(v))),
      offer_image_comments: List(data.offer_image_comments.map((v: any) => TaskOfferImageComment.fromJSON(v))),
      read_ats: List(data.read_ats.map((v: any) => TaskOfferReadAt.fromJSON(v))),
    });
  }

  /**
   * 最後にそのユーザーがオファーを開いてから、コメントの追加などがされているか
   *
   * 調査対象は、コメント・スタンプ・画像コメント
   * @param userId 取得対象のユーザーID
   */
  hasUnreadComments(userId: number) {
    // 取得対象ユーザーの最終確認日時
    const readAt = this.read_ats.find((v) => v.user_id === userId)?.last_access_time || dayjs(0);

    // 未読コメントがあるか
    const hasUnreadComments = this.comments
      .filter((v) => v.content.created_by !== userId)
      .find((v) => v.create_at.isAfter(readAt));
    if (hasUnreadComments !== undefined) {
      return true;
    }

    // 未読スタンプがあるか
    const hasUnreadnStamps = this.stamps
      .filter((v) => v.stamped_by !== userId)
      .find((v) => v.create_at.isAfter(readAt));
    if (hasUnreadnStamps !== undefined) {
      return true;
    }

    // 未読画像コメントがあるか
    const hasUnreadImageComments = this.offer_image_comments
      .filter((v) => v.created_by !== userId)
      .find((v) => v.create_at.isAfter(readAt));
    if (hasUnreadImageComments !== undefined) {
      return true;
    }

    return false;
  }
}

interface TaskOfferCommentRecord {
  id: number;
  offer_id: number;
  content: CommonContent;
  create_at: Dayjs;
}

export class TaskOfferComment extends Record<TaskOfferCommentRecord>({
  id: 0,
  offer_id: 0,
  content: new CommonContent(),
  create_at: dayjs(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new TaskOfferComment({
      ...data,
      content: new CommonContent(data.content),
      create_at: dayjs(data.create_at),
    });
  }
}

interface TaskOfferStampRecord {
  id: number;
  offer_id: number;
  stamp_id: number;
  stamped_by: number;
  stamp: FileContent;
  create_at: Dayjs;
}

export class TaskOfferStamp extends Record<TaskOfferStampRecord>({
  id: 0,
  offer_id: 0,
  stamp_id: 0,
  stamped_by: 0,
  stamp: new FileContent(),
  create_at: dayjs(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new TaskOfferStamp({
      ...data,
      stamp: new FileContent(data.stamp),
      create_at: dayjs(data.create_at),
    });
  }
}

interface TaskOfferStatusChangeRecord {
  id: number;
  offer_id: number;
  user_id: number;
  status: OfferStatus;
  create_at: Dayjs;
}

export class TaskOfferStatusChange extends Record<TaskOfferStatusChangeRecord>({
  id: 0,
  offer_id: 0,
  user_id: 0,
  status: 'none',
  create_at: dayjs(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new TaskOfferStatusChange({
      ...data,
      create_at: dayjs(data.create_at),
    });
  }
}

interface TaskOfferImageCommentRecord {
  id: number;
  offer_id: number;
  created_by: number;
  image_url: string;
  image_comments: {
    id: number;
    text: string;
    x_axis_ratio: number;
    y_axis_ratio: number;
    order_num: number;
    offer_image_comment_id: number;
  }[];
  create_at: Dayjs;
}

export class TaskOfferImageComment extends Record<TaskOfferImageCommentRecord>({
  id: 0,
  offer_id: 0,
  created_by: 0,
  image_url: '',
  image_comments: [],
  create_at: dayjs(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new TaskOfferImageComment({
      ...data,
      create_at: dayjs(data.create_at),
    });
  }
}

interface TaskOfferReadAtRecord {
  offer_id: number;
  user_id: number;
  last_access_time: Dayjs;
}

export class TaskOfferReadAt extends Record<TaskOfferReadAtRecord>({
  offer_id: 0,
  user_id: 0,
  last_access_time: dayjs(),
}) {
  static fromJSON(data: JSObject = {}) {
    return new TaskOfferReadAt({
      ...data,
      last_access_time: dayjs(data.last_access_time),
    });
  }
}
