import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Map as ImmutableMap, Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import {
  GbpPerformanceGetMonthlyResponse,
  GbpPerformanceGetResponse,
  GbpPerformanceStats as GbpPerformanceStatsParams,
} from 'ApiClient/GbpPerformanceApi';
import { ImageGraphDataItem } from 'components/pageComponents/GbpPerformance/GbpPerformanceGraph/ImageGraph';
import { OverviewGraphDataItem } from 'components/pageComponents/GbpPerformance/GbpPerformanceGraph/OverviewGraph';
import { PerformanceGraphDataItem } from 'components/pageComponents/GbpPerformance/GbpPerformanceGraph/PerformanceGraph';
import { PromotionGraphDataItem } from 'components/pageComponents/GbpPerformance/GbpPerformanceGraph/PromotionGraph';
import { ReviewGraphDataItem } from 'components/pageComponents/GbpPerformance/GbpPerformanceGraph/ReviewGraph';
import { convertDateLabel } from 'helpers/graph';
import { addNullableValues } from 'helpers/utils';
import { Stores } from 'models/Domain/Store';
import { AggregateUnit, ArrayElement } from 'types/Common';

export const DISPLAY_TYPES = ['overview', 'impressions', 'interactions', 'activities'] as const;
export type DisplayType = (typeof DISPLAY_TYPES)[number];
export const displayTypeLabel = {
  overview: '概要',
  impressions: 'ユーザー',
  interactions: 'インタラクション',
  activities: 'アクティビティ',
} satisfies Record<DisplayType, string>;

const aggregateStatsTypes = [
  'businessImpressions',
  'interactions',
  'totalReviews',
  'periodReviews',
  'promotionCount',
  'promotionRejectedCount',
  'imageCount',
] as const;
type AggregateStatsType = (typeof aggregateStatsTypes)[number];

export type StatsType = keyof GbpPerformanceStatsRecord | AggregateStatsType;

const interactionStatsTypes = [
  'websiteClicks',
  'callClicks',
  'businessDirectionRequests',
  'businessBookings',
  'businessConversations',
  'interactions',
] as const satisfies readonly StatsType[];
type InteractionStatsType = (typeof interactionStatsTypes)[number];

export const isInteractionStatsType = (statsType: StatsType): statsType is InteractionStatsType => {
  return (interactionStatsTypes as readonly StatsType[]).includes(statsType);
};

export const isAggregateStatsType = (statsType: StatsType): statsType is AggregateStatsType => {
  return (aggregateStatsTypes as readonly StatsType[]).includes(statsType);
};

export const gbpPerformanceStatsKeyMapping = {
  businessImpressions: 'ユーザー数',
  interactions: 'インタラクション数',
  periodReviews: 'クチコミ数',
  totalReviews: 'クチコミ数（累計）',
  businessImpressionsDesktopMaps: 'Google マップ - パソコン',
  businessImpressionsMobileMaps: 'Google マップ - モバイル',
  businessImpressionsDesktopSearch: 'Google 検索 - パソコン',
  businessImpressionsMobileSearch: 'Google 検索 - モバイル',
  callClicks: '通話',
  businessConversations: 'メッセージ',
  businessBookings: '予約',
  businessDirectionRequests: 'ルート',
  websiteClicks: 'ウェブサイトのクリック',
  periodReviewCommentCount: 'クチコミ数（コメントあり）',
  periodReviewRateCount: 'クチコミ数（評価のみ）',
  periodReviewReplyCount: '返信数',
  periodReviewAverageRating: '評価（期間平均）',
  totalReviewCommentCount: 'クチコミ数（コメントあり）',
  totalReviewRateCount: 'クチコミ数（評価のみ）',
  totalReviewReplyCount: '返信数',
  totalReviewAverageRating: '評価（累計平均）',
  promotionCount: '投稿数',
  promotionStandardCount: '投稿数（最新情報）',
  promotionEventCount: '投稿数（イベント）',
  promotionOfferCount: '投稿数（特典）',
  promotionAlertCount: '投稿数（その他）',
  promotionRejectedCount: '投稿数（拒否）',
  // 公開拒否された投稿数はグラフ、比較の表で見せていないため未指定
  promotionStandardRejectedCount: '',
  promotionEventRejectedCount: '',
  promotionOfferRejectedCount: '',
  promotionAlertRejectedCount: '',
  imageCount: '写真追加数',
  imageInteriorCount: '写真追加数（店内）',
  imageExteriorCount: '写真追加数（外観）',
  imageProductCount: '写真追加数（商品）',
  imageAdditionalCount: '写真追加数（その他）',
} as const satisfies Record<StatsType, string>;

export type SortKey = 'storeName' | 'storeCode' | StatsType;

export type SortType = 'number' | 'rate';
export type SortOrder = 'asc' | 'desc';
export type SortTarget = 'current' | 'comparison' | 'diffRate';

class Period extends ImmutableRecord<{
  startDate: Dayjs;
  endDate: Dayjs;
}>({
  startDate: dayjs(),
  endDate: dayjs(),
}) {
  static fromJSON(data: { start_date: string; end_date: string }) {
    return new Period({ startDate: dayjs(data.start_date), endDate: dayjs(data.end_date) });
  }

  mergePeriod(other: Period) {
    return new Period({
      startDate: other.startDate.isBefore(this.startDate) ? other.startDate : this.startDate,
      endDate: other.endDate.isAfter(this.endDate) ? other.endDate : this.endDate,
    });
  }
}

export type GbpPerformanceStatsImpressionRecord = {
  desktopMaps: number;
  desktopSearch: number;
  mobileMaps: number;
  mobileSearch: number;
};

export class GbpPerformanceStatsImpression extends ImmutableRecord<GbpPerformanceStatsImpressionRecord>({
  desktopMaps: 0,
  desktopSearch: 0,
  mobileMaps: 0,
  mobileSearch: 0,
}) {
  // Googleマップによるユーザー数の合計
  get maps() {
    return this.desktopMaps + this.mobileMaps;
  }

  // Google検索によるユーザー数の合計
  get search() {
    return this.desktopSearch + this.mobileSearch;
  }

  // ユーザー数の合計
  get total() {
    return this.maps + this.search;
  }
}

export type GbpPerformanceStatsInteractionRecord = {
  callClicks: number;
  businessConversations: number;
  businessBookings: number;
  businessDirectionRequests: number;
  websiteClicks: number;
};

export class GbpPerformanceStatsInteraction extends ImmutableRecord<GbpPerformanceStatsInteractionRecord>({
  callClicks: 0,
  businessConversations: 0,
  businessBookings: 0,
  businessDirectionRequests: 0,
  websiteClicks: 0,
}) {
  // インタラクション数の合計
  get total() {
    return (
      this.callClicks +
      this.businessConversations +
      this.businessDirectionRequests +
      this.businessBookings +
      this.websiteClicks
    );
  }
}

export type GbpPerformanceStatsReviewRecord = {
  commentCount: number;
  rateCount: number;
  replyCount: number;
  averageRating: number | null;
};

export class GbpPerformanceStatsReview extends ImmutableRecord<GbpPerformanceStatsReviewRecord>({
  commentCount: 0,
  rateCount: 0,
  replyCount: 0,
  averageRating: null,
}) {
  // クチコミ数の合計（コメントあり＋評価のみ）
  get total() {
    return this.commentCount + this.rateCount;
  }

  // 指定されたクチコミデータを追加したときの平均評価を求める
  addAverageRating(other: GbpPerformanceStatsReview) {
    // 平均評価は、存在するクチコミの合計点 / 存在するクチコミ数とし、クチコミがなければnullを返す

    // thisの平均評価がnullの場合、thisにはデータはないのでotherの値を返す
    if (this.averageRating == null || this.total === 0) {
      return other.averageRating;
    }
    // otherの平均評価がnullの場合、otherにはデータはないのでthisの値を返す
    if (other.averageRating == null || other.total === 0) {
      return this.averageRating;
    }

    // クチコミ数と平均評価をかけて評価の合計点を求め、クチコミ数で割る
    const totalRating = this.averageRating * this.total + other.averageRating * other.total;
    const reviewCount = this.total + other.total;
    return totalRating / reviewCount;
  }
}

export type GbpPerformanceStatsPromotionRecord = {
  standard: number | null;
  alert: number | null;
  event: number | null;
  offer: number | null;
  standardRejected: number | null;
  alertRejected: number | null;
  eventRejected: number | null;
  offerRejected: number | null;
};

export class GbpPerformanceStatsPromotion extends ImmutableRecord<GbpPerformanceStatsPromotionRecord>({
  standard: null,
  alert: null,
  event: null,
  offer: null,
  standardRejected: null,
  alertRejected: null,
  eventRejected: null,
  offerRejected: null,
}) {
  // 投稿数（公開済み）の合計
  get total() {
    return addNullableValues(this.standard, this.alert, this.event, this.offer);
  }

  // 投稿数（公開拒否）の合計
  get totalRejected() {
    return addNullableValues(this.standardRejected, this.alertRejected, this.eventRejected, this.offerRejected);
  }
}

export type GbpPerformanceStatsImageRecord = {
  interior: number | null;
  exterior: number | null;
  product: number | null;
  additional: number | null;
};

export class GbpPerformanceStatsImage extends ImmutableRecord<GbpPerformanceStatsImageRecord>({
  interior: null,
  exterior: null,
  product: null,
  additional: null,
}) {
  // 写真追加数の合計
  get total() {
    return addNullableValues(this.interior, this.exterior, this.product, this.additional);
  }
}

export type GbpPerformanceStatsRecord = {
  businessImpressionsDesktopMaps: number;
  businessImpressionsDesktopSearch: number;
  businessImpressionsMobileMaps: number;
  businessImpressionsMobileSearch: number;
  websiteClicks: number;
  callClicks: number;
  businessDirectionRequests: number;
  businessConversations: number;
  businessBookings: number;
  periodReviewCommentCount: number;
  periodReviewRateCount: number;
  periodReviewReplyCount: number;
  periodReviewAverageRating: number | null;
  totalReviewCommentCount: number;
  totalReviewRateCount: number;
  totalReviewReplyCount: number;
  totalReviewAverageRating: number | null;
  promotionStandardCount: number;
  promotionEventCount: number;
  promotionOfferCount: number;
  promotionAlertCount: number;
  promotionStandardRejectedCount: number;
  promotionEventRejectedCount: number;
  promotionOfferRejectedCount: number;
  promotionAlertRejectedCount: number;
  imageInteriorCount: number;
  imageExteriorCount: number;
  imageProductCount: number;
  imageAdditionalCount: number;
};

export class GbpPerformanceStats extends ImmutableRecord<{
  impression: GbpPerformanceStatsImpression;
  interaction: GbpPerformanceStatsInteraction;
  periodReview: GbpPerformanceStatsReview;
  totalReview: GbpPerformanceStatsReview;
  promotion: GbpPerformanceStatsPromotion;
  image: GbpPerformanceStatsImage;
}>({
  impression: new GbpPerformanceStatsImpression(),
  interaction: new GbpPerformanceStatsInteraction(),
  periodReview: new GbpPerformanceStatsReview(),
  totalReview: new GbpPerformanceStatsReview(),
  promotion: new GbpPerformanceStatsPromotion(),
  image: new GbpPerformanceStatsImage(),
}) {
  static fromJSON(data: GbpPerformanceStatsParams) {
    return new GbpPerformanceStats({
      impression: new GbpPerformanceStatsImpression({
        desktopMaps: data.business_impressions_desktop_maps,
        desktopSearch: data.business_impressions_desktop_search,
        mobileMaps: data.business_impressions_mobile_maps,
        mobileSearch: data.business_impressions_mobile_search,
      }),
      interaction: new GbpPerformanceStatsInteraction({
        callClicks: data.call_clicks,
        businessConversations: data.business_conversations,
        businessBookings: data.business_bookings,
        businessDirectionRequests: data.business_direction_requests,
        websiteClicks: data.website_clicks,
      }),
      periodReview: new GbpPerformanceStatsReview({
        commentCount: data.review_comment_count,
        rateCount: data.review_rate_count,
        replyCount: data.review_reply_count,
        averageRating: data.review_period_average_rating,
      }),
      totalReview: new GbpPerformanceStatsReview({
        commentCount: data.total_review_comment_count,
        rateCount: data.total_review_rate_count,
        replyCount: data.total_review_reply_count,
        averageRating: data.review_average_rating,
      }),
      promotion: new GbpPerformanceStatsPromotion({
        standard: data.promotion_standard_count,
        alert: data.promotion_alert_count,
        event: data.promotion_event_count,
        offer: data.promotion_offer_count,
        standardRejected: data.promotion_standard_rejected_count,
        alertRejected: data.promotion_alert_rejected_count,
        eventRejected: data.promotion_event_rejected_count,
        offerRejected: data.promotion_offer_rejected_count,
      }),
      image: new GbpPerformanceStatsImage({
        interior: data.image_interior_count,
        exterior: data.image_exterior_count,
        product: data.image_product_count,
        additional: data.image_additional_count,
      }),
    });
  }

  // FIXME: keyでアクセスしている箇所を置き換えできていないので、一旦全要素をgetで取得できるようにしている

  get businessImpressionsDesktopMaps() {
    return this.impression.desktopMaps;
  }

  get businessImpressionsMobileMaps() {
    return this.impression.mobileMaps;
  }

  get businessImpressionsDesktopSearch() {
    return this.impression.desktopSearch;
  }

  get businessImpressionsMobileSearch() {
    return this.impression.mobileSearch;
  }

  get callClicks() {
    return this.interaction.callClicks;
  }

  get businessConversations() {
    return this.interaction.businessConversations;
  }

  get businessBookings() {
    return this.interaction.businessBookings;
  }

  get businessDirectionRequests() {
    return this.interaction.businessDirectionRequests;
  }

  get websiteClicks() {
    return this.interaction.websiteClicks;
  }

  get periodReviewCommentCount() {
    return this.periodReview.commentCount;
  }

  get totalReviewCommentCount() {
    return this.totalReview.commentCount;
  }

  get periodReviewRateCount() {
    return this.periodReview.rateCount;
  }

  get totalReviewRateCount() {
    return this.totalReview.rateCount;
  }

  get periodReviewReplyCount() {
    return this.periodReview.replyCount;
  }

  get totalReviewReplyCount() {
    return this.totalReview.replyCount;
  }

  get periodReviewAverageRating() {
    return this.periodReview.averageRating;
  }
  get totalReviewAverageRating() {
    return this.totalReview.averageRating;
  }

  // ユーザー数の合計
  get businessImpressions() {
    return this.impression.total;
  }

  // インタラクション数の合計
  get interactions() {
    return this.interaction.total;
  }

  // 期間のクチコミ数の合計（コメントあり＋評価のみ）
  get periodReviews() {
    return this.periodReview.total;
  }

  // 累計のクチコミ数の合計（コメントあり＋評価のみ）
  get totalReviews() {
    return this.totalReview.total;
  }

  get promotionCount() {
    return this.promotion.total;
  }

  get promotionStandardCount() {
    return this.promotion.standard;
  }

  get promotionAlertCount() {
    return this.promotion.alert;
  }

  get promotionEventCount() {
    return this.promotion.event;
  }

  get promotionOfferCount() {
    return this.promotion.offer;
  }

  get promotionRejectedCount() {
    return this.promotion.totalRejected;
  }

  get promotionStandardRejectedCount() {
    return this.promotion.standardRejected;
  }

  get promotionAlertRejectedCount() {
    return this.promotion.alertRejected;
  }

  get promotionEventRejectedCount() {
    return this.promotion.eventRejected;
  }

  get promotionOfferRejectedCount() {
    return this.promotion.offerRejected;
  }

  get imageCount() {
    return this.image.total;
  }

  get imageInteriorCount() {
    return this.image.interior;
  }

  get imageExteriorCount() {
    return this.image.exterior;
  }

  get imageProductCount() {
    return this.image.product;
  }

  get imageAdditionalCount() {
    return this.image.additional;
  }

  /**
   * 対象キー項目のインプレッション数に対する割合を返す
   * @param key
   * @returns
   */
  getRate(key: InteractionStatsType) {
    if (this.businessImpressions === 0) {
      return null;
    }

    return this[key] / this.businessImpressions;
  }

  // ユーザー数に対する各インタラクション数の割合を返す
  getInteractionsRatio(statsType: InteractionStatsType) {
    return this.getRate(statsType);
  }

  add(other: GbpPerformanceStats) {
    return new GbpPerformanceStats({
      impression: new GbpPerformanceStatsImpression({
        desktopMaps: this.impression.desktopMaps + other.impression.desktopMaps,
        mobileMaps: this.impression.mobileMaps + other.impression.mobileMaps,
        desktopSearch: this.impression.desktopSearch + other.impression.desktopSearch,
        mobileSearch: this.impression.mobileSearch + other.impression.mobileSearch,
      }),
      interaction: new GbpPerformanceStatsInteraction({
        callClicks: this.interaction.callClicks + other.interaction.callClicks,
        businessConversations: this.interaction.businessConversations + other.interaction.businessConversations,
        businessBookings: this.interaction.businessBookings + other.interaction.businessBookings,
        businessDirectionRequests:
          this.interaction.businessDirectionRequests + other.interaction.businessDirectionRequests,
        websiteClicks: this.interaction.websiteClicks + other.interaction.websiteClicks,
      }),
      periodReview: new GbpPerformanceStatsReview({
        commentCount: this.periodReview.commentCount + other.periodReview.commentCount,
        rateCount: this.periodReview.rateCount + other.periodReview.rateCount,
        replyCount: this.periodReview.replyCount + other.periodReview.replyCount,
        averageRating: this.periodReview.addAverageRating(other.periodReview),
      }),
      totalReview: new GbpPerformanceStatsReview({
        commentCount: this.totalReview.commentCount + other.totalReview.commentCount,
        rateCount: this.totalReview.rateCount + other.totalReview.rateCount,
        replyCount: this.totalReview.replyCount + other.totalReview.replyCount,
        averageRating: this.totalReview.addAverageRating(other.totalReview),
      }),
      promotion: new GbpPerformanceStatsPromotion({
        standard: addNullableValues(this.promotion.standard, other.promotion.standard),
        alert: addNullableValues(this.promotion.alert, other.promotion.alert),
        event: addNullableValues(this.promotion.event, other.promotion.event),
        offer: addNullableValues(this.promotion.offer, other.promotion.offer),
        standardRejected: addNullableValues(this.promotion.standardRejected, other.promotion.standardRejected),
        alertRejected: addNullableValues(this.promotion.alertRejected, other.promotion.alertRejected),
        eventRejected: addNullableValues(this.promotion.eventRejected, other.promotion.eventRejected),
        offerRejected: addNullableValues(this.promotion.offerRejected, other.promotion.offerRejected),
      }),
      image: new GbpPerformanceStatsImage({
        interior: addNullableValues(this.image.interior, other.image.interior),
        exterior: addNullableValues(this.image.exterior, other.image.exterior),
        product: addNullableValues(this.image.product, other.image.product),
        additional: addNullableValues(this.image.additional, other.image.additional),
      }),
    });
  }
}

export class GbpPerformanceGraphItem extends ImmutableRecord<{
  period: Period;
  stats: GbpPerformanceStats;
}>({
  period: new Period(),
  stats: new GbpPerformanceStats(),
}) {
  static fromJSON(data: ArrayElement<GbpPerformanceGetResponse['graph_items']>) {
    return new GbpPerformanceGraphItem({
      period: Period.fromJSON(data.period),
      stats: GbpPerformanceStats.fromJSON(data.stats),
    });
  }
}

export class GbpPerformanceGraphItemList extends ImmutableRecord<{
  list: ImmutableList<GbpPerformanceGraphItem>;
}>({
  list: ImmutableList(),
}) {
  static fromJSON(data: GbpPerformanceGetResponse['graph_items']) {
    return new GbpPerformanceGraphItemList({
      list: ImmutableList(data.map((item) => GbpPerformanceGraphItem.fromJSON(item))),
    });
  }
}

export type InteractionType = 'value' | 'rate';
export type ReviewType = 'period' | 'total';
export const InteractionTypeLabel = {
  value: 'インタラクション数',
  rate: 'インタラクション率',
} satisfies Record<InteractionType, string>;

export class GbpPerformanceGraphData extends ImmutableRecord<{
  target: GbpPerformanceGraphItemList;
  comparison: GbpPerformanceGraphItemList;
}>({
  target: new GbpPerformanceGraphItemList(),
  comparison: new GbpPerformanceGraphItemList(),
}) {
  get combined(): GbpPerformanceGraphItemList {
    return new GbpPerformanceGraphItemList({
      list: this.comparison.list.concat(this.target.list),
    });
  }

  getOverviewGraphData = (
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    interactionType: InteractionType,
    aggregateUnit: AggregateUnit,
  ): OverviewGraphDataItem[] => {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceStats>(),
    );
    return [...Array(targetDates.length)].map((_, index) => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;
      return {
        date: targetDate,
        comparisonDate: comparisonDate,
        businessImpressions: targetData?.businessImpressions,
        interactions: interactionType === 'rate' ? targetData?.getRate('interactions') : targetData?.interactions,
        imageCount: targetData?.imageCount,
        promotionCount: targetData?.promotionCount,
        periodReviews: targetData?.periodReviews,
        comparisonBusinessImpressions: comparisonData?.businessImpressions,
        comparisonInteractions:
          interactionType === 'rate' ? comparisonData?.getRate('interactions') : comparisonData?.interactions,
        comparisonImageCount: comparisonData?.imageCount,
        comparisonPromotionCount: comparisonData?.promotionCount,
        comparisonPeriodReviews: comparisonData?.periodReviews,
      };
    });
  };

  getPerformanceGraphData = (
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    interactionType: InteractionType,
    aggregateUnit: AggregateUnit,
  ): PerformanceGraphDataItem[] => {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceStats>(),
    );
    return [...Array(targetDates.length)].map((_, index) => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;
      if (interactionType === 'rate') {
        return {
          date: targetDate,
          comparisonDate: comparisonDate,
          businessImpressions: targetData?.businessImpressions,
          interactions: targetData?.getRate('interactions'),
          businessImpressionsDesktopMaps: targetData?.businessImpressionsDesktopMaps,
          businessImpressionsMobileMaps: targetData?.businessImpressionsMobileMaps,
          businessImpressionsDesktopSearch: targetData?.businessImpressionsDesktopSearch,
          businessImpressionsMobileSearch: targetData?.businessImpressionsMobileSearch,
          callClicks: targetData?.getRate('callClicks'),
          businessConversations: targetData?.getRate('businessConversations'),
          businessBookings: targetData?.getRate('businessBookings'),
          businessDirectionRequests: targetData?.getRate('businessDirectionRequests'),
          websiteClicks: targetData?.getRate('websiteClicks'),
          comparisonBusinessImpressions: comparisonData?.businessImpressions,
          comparisonInteractions: comparisonData?.getRate('interactions'),
          comparisonBusinessImpressionsDesktopMaps: comparisonData?.businessImpressionsDesktopMaps,
          comparisonBusinessImpressionsMobileMaps: comparisonData?.businessImpressionsMobileMaps,
          comparisonBusinessImpressionsDesktopSearch: comparisonData?.businessImpressionsDesktopSearch,
          comparisonBusinessImpressionsMobileSearch: comparisonData?.businessImpressionsMobileSearch,
          comparisonCallClicks: comparisonData?.getRate('callClicks'),
          comparisonBusinessConversations: comparisonData?.getRate('businessConversations'),
          comparisonBusinessBookings: comparisonData?.getRate('businessBookings'),
          comparisonBusinessDirectionRequests: comparisonData?.getRate('businessDirectionRequests'),
          comparisonWebsiteClicks: comparisonData?.getRate('websiteClicks'),
        };
      } else {
        return {
          date: targetDate,
          comparisonDate: comparisonDate,
          businessImpressions: targetData?.businessImpressions,
          interactions: targetData?.interactions,
          businessImpressionsDesktopMaps: targetData?.businessImpressionsDesktopMaps,
          businessImpressionsMobileMaps: targetData?.businessImpressionsMobileMaps,
          businessImpressionsDesktopSearch: targetData?.businessImpressionsDesktopSearch,
          businessImpressionsMobileSearch: targetData?.businessImpressionsMobileSearch,
          callClicks: targetData?.callClicks,
          businessConversations: targetData?.businessConversations,
          businessBookings: targetData?.businessBookings,
          businessDirectionRequests: targetData?.businessDirectionRequests,
          websiteClicks: targetData?.websiteClicks,
          comparisonBusinessImpressions: comparisonData?.businessImpressions,
          comparisonInteractions: comparisonData?.interactions,
          comparisonBusinessImpressionsDesktopMaps: comparisonData?.businessImpressionsDesktopMaps,
          comparisonBusinessImpressionsMobileMaps: comparisonData?.businessImpressionsMobileMaps,
          comparisonBusinessImpressionsDesktopSearch: comparisonData?.businessImpressionsDesktopSearch,
          comparisonBusinessImpressionsMobileSearch: comparisonData?.businessImpressionsMobileSearch,
          comparisonCallClicks: comparisonData?.callClicks,
          comparisonBusinessConversations: comparisonData?.businessConversations,
          comparisonBusinessBookings: comparisonData?.businessBookings,
          comparisonBusinessDirectionRequests: comparisonData?.businessDirectionRequests,
          comparisonWebsiteClicks: comparisonData?.websiteClicks,
        };
      }
    });
  };

  getImageGraphData = (
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    aggregateUnit: AggregateUnit,
  ): ImageGraphDataItem[] => {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceStats>(),
    );
    return [...Array(targetDates.length)].map((_, index): ImageGraphDataItem => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;

      return {
        date: targetDate,
        comparisonDate: comparisonDate,
        imageInteriorCount: targetData?.imageInteriorCount,
        imageExteriorCount: targetData?.imageExteriorCount,
        imageProductCount: targetData?.imageProductCount,
        imageAdditionalCount: targetData?.imageAdditionalCount,
        comparisonImageInteriorCount: comparisonData?.imageInteriorCount,
        comparisonImageExteriorCount: comparisonData?.imageExteriorCount,
        comparisonImageProductCount: comparisonData?.imageProductCount,
        comparisonImageAdditionalCount: comparisonData?.imageAdditionalCount,
      };
    });
  };

  getPromotionGraphData = (
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    aggregateUnit: AggregateUnit,
  ): PromotionGraphDataItem[] => {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceStats>(),
    );
    return [...Array(targetDates.length)].map((_, index): PromotionGraphDataItem => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;

      return {
        date: targetDate,
        comparisonDate: comparisonDate,
        promotionStandardCount: targetData?.promotionStandardCount,
        promotionAlertCount: targetData?.promotionAlertCount,
        promotionEventCount: targetData?.promotionEventCount,
        promotionOfferCount: targetData?.promotionOfferCount,
        comparisonPromotionStandardCount: comparisonData?.promotionStandardCount,
        comparisonPromotionAlertCount: comparisonData?.promotionAlertCount,
        comparisonPromotionEventCount: comparisonData?.promotionEventCount,
        comparisonPromotionOfferCount: comparisonData?.promotionOfferCount,
      };
    });
  };

  getReviewGraphData = (
    targetDates: Dayjs[],
    comparisonDates: Dayjs[],
    aggregateUnit: AggregateUnit,
  ): ReviewGraphDataItem[] => {
    // 日付をキーにしたデータに変換する
    const dataMap = this.combined.list.reduce(
      (dataMap, data) => dataMap.set(convertDateLabel(data.period.startDate, aggregateUnit), data.stats),
      ImmutableMap<string, GbpPerformanceStats>(),
    );
    return [...Array(targetDates.length)].map((_, index): ReviewGraphDataItem => {
      const targetDate = convertDateLabel(targetDates[index], aggregateUnit);
      const comparisonDate = convertDateLabel(comparisonDates[index], aggregateUnit);
      const targetData = dataMap.get(targetDate) ?? null;
      const comparisonData = dataMap.get(comparisonDate) ?? null;

      return {
        date: targetDate,
        comparisonDate: comparisonDate,
        totalReviews: targetData?.totalReviews,
        totalReviewCommentCount: targetData?.totalReviewCommentCount,
        totalReviewRateCount: targetData?.totalReviewRateCount,
        totalReviewReplyCount: targetData?.totalReviewReplyCount,
        totalReviewAverageRating: targetData?.totalReviewAverageRating,
        periodReviews: targetData?.periodReviews,
        periodReviewCommentCount: targetData?.periodReviewCommentCount,
        periodReviewRateCount: targetData?.periodReviewRateCount,
        periodReviewReplyCount: targetData?.periodReviewReplyCount,
        periodReviewAverageRating: targetData?.periodReviewAverageRating,
        comparisonTotalReviews: comparisonData?.totalReviews,
        comparisonTotalReviewCommentCount: comparisonData?.totalReviewCommentCount,
        comparisonTotalReviewRateCount: comparisonData?.totalReviewRateCount,
        comparisonTotalReviewReplyCount: comparisonData?.totalReviewReplyCount,
        comparisonTotalReviewAverageRating: comparisonData?.totalReviewAverageRating,
        comparisonPeriodReviews: comparisonData?.periodReviews,
        comparisonPeriodReviewCommentCount: comparisonData?.periodReviewCommentCount,
        comparisonPeriodReviewRateCount: comparisonData?.periodReviewRateCount,
        comparisonPeriodReviewReplyCount: comparisonData?.periodReviewReplyCount,
        comparisonPeriodReviewAverageRating: comparisonData?.periodReviewAverageRating,
      };
    });
  };
}

export class GbpPerformanceTableItem extends ImmutableRecord<{
  storeId: number;
  period: Period;
  stats: GbpPerformanceStats;
}>({
  storeId: 0,
  period: new Period(),
  stats: new GbpPerformanceStats(),
}) {
  static fromJSON(data: ArrayElement<GbpPerformanceGetResponse['table_items']>) {
    return new GbpPerformanceTableItem({
      storeId: data.store_id,
      period: Period.fromJSON(data.period),
      stats: GbpPerformanceStats.fromJSON(data.stats),
    });
  }
}

export class GbpPerformanceTableItemList extends ImmutableRecord<{
  list: ImmutableList<GbpPerformanceTableItem>;
}>({
  list: ImmutableList(),
}) {
  static fromJSON(data: GbpPerformanceGetResponse['table_items']) {
    return new GbpPerformanceTableItemList({
      list: ImmutableList(data.map((item) => GbpPerformanceTableItem.fromJSON(item))),
    });
  }

  /**
   * パフォーマンスの各指標の合計値を返す
   * @param statsType
   */
  getStatsSumValue(statsType: StatsType) {
    const totalStats = this.list.reduce((sum, item) => sum.add(item.stats), new GbpPerformanceStats());
    return totalStats[statsType];
  }

  /**
   * パフォーマンステーブルデータの合計を返す
   * @returns
   */
  getTotal() {
    return this.list.reduce<GbpPerformanceTableItem>((current, item, index) => {
      if (index === 0) {
        return item;
      }
      return new GbpPerformanceTableItem({
        period: current.period.mergePeriod(item.period),
        stats: current.stats.add(item.stats),
      });
    }, new GbpPerformanceTableItem());
  }
}

export class GbpPerformanceTableData extends ImmutableRecord<{
  target: GbpPerformanceTableItemList;
  comparison: GbpPerformanceTableItemList | null;
}>({
  target: new GbpPerformanceTableItemList(),
  comparison: null,
}) {
  hasComparisonData() {
    return this.comparison !== null;
  }

  getDataPairList() {
    // 比較対象期間のデータがない場合は、comparisonにnullを設定して返す
    if (this.comparison === null) {
      return new GbpPerformanceTableDataPairList({
        list: this.target.list.map((item) => new GbpPerformanceTableDataPair({ target: item, comparison: null })),
      });
    }

    // 比較対象のデータを店舗IDをキーにデータを持つ
    const comparisonMap = this.comparison.list.reduce((reduction, value) => {
      return reduction.set(value.storeId, value);
    }, ImmutableMap<number, GbpPerformanceTableItem>());

    return new GbpPerformanceTableDataPairList({
      list: this.target.list.map((item) => {
        return new GbpPerformanceTableDataPair({ target: item, comparison: comparisonMap.get(item.storeId) ?? null });
      }),
    });
  }

  /**
   * 各
   * @returns
   */
  getTotalDataPair() {
    return new GbpPerformanceTableDataPair({
      target: this.target.getTotal(),
      comparison: this.comparison ? this.comparison.getTotal() : null,
    });
  }

  /**
   * 集計期間におけるパフォーマンスの各指標の合計値を返す
   * @param statsType
   */
  getTargetStatsSumValue(statsType: StatsType): number | null {
    return this.target ? this.target.getStatsSumValue(statsType) : null;
  }

  /**
   * 比較期間におけるパフォーマンスの各指標の合計値を返す
   * @param statsType
   */
  getComparisonStatsSumValue(statsType: StatsType): number | null {
    return this.comparison ? this.comparison.getStatsSumValue(statsType) : null;
  }

  getStatsComparisonData(statsType: StatsType) {
    const targetPeriodSumValue = this.getTargetStatsSumValue(statsType);
    const comparisonPeriodSumValue = this.getComparisonStatsSumValue(statsType);
    const diffValue =
      targetPeriodSumValue != null && comparisonPeriodSumValue != null
        ? targetPeriodSumValue - comparisonPeriodSumValue
        : null;
    const growthRatio =
      targetPeriodSumValue != null && comparisonPeriodSumValue
        ? targetPeriodSumValue / comparisonPeriodSumValue - 1.0
        : null;

    return { targetPeriodSumValue, comparisonPeriodSumValue, diffValue, growthRatio };
  }
}

export class GbpPerformanceTableDataPair extends ImmutableRecord<{
  target: GbpPerformanceTableItem;
  comparison: GbpPerformanceTableItem | null;
}>({
  target: new GbpPerformanceTableItem(),
  comparison: null,
}) {
  hasComparisonData() {
    return this.comparison !== null;
  }

  /**
   * 対象キー項目の対象期間と比較期間の変化率を返す
   * @param key
   * @returns
   */
  getDiffRate(key: StatsType) {
    const targetStats = this.target.stats[key] ?? 0;
    const comparisonStats = this.comparison?.stats[key];
    if (!comparisonStats) {
      return null;
    }
    return targetStats / comparisonStats - 1;
  }

  /**
   * 対象キー項目のインプレッション数に対する割合の対象期間と比較期間の変化率を返す
   * @param key
   * @returns
   */
  getRateDiffRate(key: InteractionStatsType) {
    if (!this.comparison) {
      return null;
    }

    const comparisonRate = this.comparison.stats.getRate(key);
    if (!comparisonRate) {
      return null;
    }
    return (this.target.stats.getRate(key) || 0) / comparisonRate - 1;
  }
}

/**
 * 同一店舗のデータ(対象期間、比較期間)のペアのリスト
 */
export class GbpPerformanceTableDataPairList extends ImmutableRecord<{
  list: ImmutableList<GbpPerformanceTableDataPair>;
}>({
  list: ImmutableList(),
}) {
  /**
   * パフォーマンスの表データをソートする
   * @param sortKey ソートキー（値のキー）
   * @param sortType ソートタイプ
   * @param sortOrder ソート順
   * @param sortTarget ソート対象
   * @returns
   */
  sortBy(
    sortKey: Exclude<SortKey, 'storeName' | 'storeCode'>,
    sortType: SortType,
    sortOrder: SortOrder,
    sortTarget: SortTarget,
  ) {
    return this.update('list', (list) =>
      list.sort((a, b) => {
        let valueA: number | null | undefined;
        let valueB: number | null | undefined;

        if (sortType === 'number') {
          if (sortTarget === 'current') {
            valueA = a.target.stats[sortKey];
            valueB = b.target.stats[sortKey];
          } else if (sortTarget === 'comparison') {
            valueA = a.comparison?.stats[sortKey];
            valueB = b.comparison?.stats[sortKey];
          } else {
            valueA = a.getDiffRate(sortKey);
            valueB = b.getDiffRate(sortKey);
          }
        } else {
          if (
            'websiteClicks' !== sortKey &&
            'callClicks' !== sortKey &&
            'businessDirectionRequests' !== sortKey &&
            'businessConversations' !== sortKey &&
            'businessBookings' !== sortKey &&
            'interactions' !== sortKey
          ) {
            return 0;
          }
          if (sortTarget === 'current') {
            valueA = a.target.stats.getRate(sortKey);
            valueB = b.target.stats.getRate(sortKey);
          } else if (sortTarget === 'comparison') {
            valueA = a.comparison?.stats.getRate(sortKey);
            valueB = b.comparison?.stats.getRate(sortKey);
          } else {
            valueA = a.getRateDiffRate(sortKey);
            valueB = b.getRateDiffRate(sortKey);
          }
        }

        if (valueA === valueB) {
          return 0;
        }
        if (valueA == null) {
          return 1;
        }
        if (valueB == null) {
          return -1;
        }

        if (sortOrder === 'asc') {
          return valueA < valueB ? -1 : 1;
        }
        return valueA < valueB ? 1 : -1;
      }),
    );
  }

  /**
   * パフォーマンスの表データを店舗データを元にソートする
   * @param sortKey ソートキー（storeName, storeCode）
   * @param sortOrder ソート順
   * @param sortTarget ソート対象
   * @returns
   */
  sortByStoreInfo(sortKey: SortKey, sortOrder: SortOrder, stores: Stores) {
    return this.update('list', (list) =>
      list.sort((a, b) => {
        const storeA = stores.findStore(a.target.storeId);
        const storeB = stores.findStore(b.target.storeId);

        let valueA: string | undefined;
        let valueB: string | undefined;

        if (sortKey === 'storeName') {
          valueA = storeA?.shortName;
          valueB = storeB?.shortName;
        } else {
          valueA = storeA?.code;
          valueB = storeB?.code;
        }

        if (valueA === valueB) {
          return 0;
        }
        if (valueA == null) {
          return 1;
        }
        if (valueB == null) {
          return -1;
        }

        if (sortOrder === 'asc') {
          return valueA < valueB ? -1 : 1;
        }
        return valueA < valueB ? 1 : -1;
      }),
    );
  }
}

export class GbpPerformanceMonthlyCondition extends ImmutableRecord<{
  target: Dayjs;
  storeIds: ImmutableList<number>;
}>({
  target: dayjs(),
  storeIds: ImmutableList(),
}) {
  static fromJSON(data: GbpPerformanceGetMonthlyResponse['condition']) {
    return new GbpPerformanceMonthlyCondition({
      // 扱いやすいように指定月の1日をdayjsにする
      target: dayjs(`${data.month}-01`),
      storeIds: ImmutableList(data.store_ids),
    });
  }
}

export class GbpPerformanceMonthlyItems extends ImmutableRecord<{
  targetMonth: GbpPerformanceStats | null;
  lastMonth: GbpPerformanceStats | null;
  threeMonthsAgo: GbpPerformanceStats | null;
  twelveMonthsAgo: GbpPerformanceStats | null;
}>({
  targetMonth: new GbpPerformanceStats(),
  lastMonth: new GbpPerformanceStats(),
  threeMonthsAgo: new GbpPerformanceStats(),
  twelveMonthsAgo: new GbpPerformanceStats(),
}) {
  static fromJSON(data: GbpPerformanceGetMonthlyResponse['items']) {
    return new GbpPerformanceMonthlyItems({
      targetMonth: data.month ? GbpPerformanceStats.fromJSON(data.month) : null,
      lastMonth: data.last_month ? GbpPerformanceStats.fromJSON(data.last_month) : null,
      threeMonthsAgo: data.three_months_ago ? GbpPerformanceStats.fromJSON(data.three_months_ago) : null,
      twelveMonthsAgo: data.twelve_months_ago ? GbpPerformanceStats.fromJSON(data.twelve_months_ago) : null,
    });
  }

  /**
   * 対象月の前月からの変化率を返す
   * @param statsType
   */
  getMomGrowth(statsType: StatsType): number | null {
    return this.getGrowthRatio(statsType, this.targetMonth, this.lastMonth);
  }

  /**
   * 対象月の前月からの変化率の増減率を返す
   * @param statsType
   */
  getMomGrowthRatio(statsType: InteractionStatsType): number | null {
    return this.getInteractionRateGrowthRatio(statsType, this.targetMonth, this.lastMonth);
  }

  /**
   * 対象月の3ヶ月前からの変化率を返す
   * @param statsType
   */
  getThreeMomGrowth(statsType: StatsType): number | null {
    return this.getGrowthRatio(statsType, this.targetMonth, this.threeMonthsAgo);
  }

  /**
   * 対象月の3ヶ月前からの変化率の増減率を返す
   * @param statsType
   */
  getThreeMomGrowthRatio(statsType: InteractionStatsType): number | null {
    return this.getInteractionRateGrowthRatio(statsType, this.targetMonth, this.threeMonthsAgo);
  }

  /**
   * 対象月の12ヶ月前（前年同月）からの変化率を返す
   * @param statsType
   */
  getYoyGrowth(statsType: StatsType): number | null {
    return this.getGrowthRatio(statsType, this.targetMonth, this.twelveMonthsAgo);
  }

  /**
   * 対象月の12ヶ月前（前年同月）からの変化率の増減率を返す
   * @param statsType
   */
  getYoyGrowthRatio(statsType: InteractionStatsType): number | null {
    return this.getInteractionRateGrowthRatio(statsType, this.targetMonth, this.twelveMonthsAgo);
  }

  /**
   * 前期の値からの変化率（パーセント）を求める
   * @param statsType
   * @param target
   * @param comparison
   * @private
   */
  private getGrowthRatio(
    statsType: StatsType,
    target: GbpPerformanceStats | null,
    comparison: GbpPerformanceStats | null,
  ): number | null {
    const targetValue = target?.[statsType];
    const comparisonValue = comparison?.[statsType];
    if (targetValue == null || !comparisonValue) {
      return null;
    }
    return targetValue / comparisonValue - 1.0;
  }

  /**
   * 前期のインタラクション率との変化率（パーセント）を求める
   * @param statsType
   * @param target
   * @param comparison
   * @private
   */
  private getInteractionRateGrowthRatio(
    statsType: InteractionStatsType,
    target: GbpPerformanceStats | null,
    comparison: GbpPerformanceStats | null,
  ): number | null {
    const targetRatio = target?.getInteractionsRatio(statsType);
    const comparisonRatio = comparison?.getInteractionsRatio(statsType);
    if (targetRatio == null || !comparisonRatio) {
      return null;
    }
    return targetRatio / comparisonRatio - 1.0;
  }
}

export class GbpPerformanceMonthlyData extends ImmutableRecord<{
  condition: GbpPerformanceMonthlyCondition;
  items: GbpPerformanceMonthlyItems;
}>({
  condition: new GbpPerformanceMonthlyCondition(),
  items: new GbpPerformanceMonthlyItems(),
}) {
  static fromJSON(data: GbpPerformanceGetMonthlyResponse) {
    return new GbpPerformanceMonthlyData({
      condition: GbpPerformanceMonthlyCondition.fromJSON(data.condition),
      items: GbpPerformanceMonthlyItems.fromJSON(data.items),
    });
  }
}

// 選択中のStatsTypeを保持する
export class GbpPerformanceActiveStats extends ImmutableRecord<{
  overview: ImmutableSet<StatsType>;
  impressions: ImmutableSet<StatsType>;
  interactions: ImmutableSet<StatsType>;
  activities: ImmutableSet<StatsType>;
}>({
  overview: ImmutableSet(['businessImpressions', 'interactions'] as const),
  impressions: ImmutableSet([
    'businessImpressionsDesktopMaps',
    'businessImpressionsMobileMaps',
    'businessImpressionsDesktopSearch',
    'businessImpressionsMobileSearch',
  ] as const),
  interactions: ImmutableSet(['callClicks', 'businessDirectionRequests', 'websiteClicks'] as const),
  activities: ImmutableSet([
    'periodReviewCommentCount',
    'periodReviewRateCount',
    'periodReviewReplyCount',
    'periodReviewAverageRating',
    'promotionCount',
    'promotionStandardCount',
    'promotionEventCount',
    'promotionOfferCount',
    'promotionAlertCount',
    'imageCount',
    'imageInteriorCount',
    'imageExteriorCount',
    'imageProductCount',
    'imageAdditionalCount',
  ] as const),
}) {
  static getDefaultByReviewType(reviewType: ReviewType): GbpPerformanceActiveStats {
    // newの場合のデフォルト値はperiodだが、reviewTypeを指定してデフォルト値を取得できるようにする
    if (reviewType === 'total') {
      return new GbpPerformanceActiveStats().replaceReviewType('period', 'total');
    } else {
      return new GbpPerformanceActiveStats().replaceReviewType('total', 'period');
    }
  }

  change(displayType: DisplayType, statsType: StatsType) {
    return this.update(displayType, (activeStats) =>
      activeStats.has(statsType) ? activeStats.delete(statsType) : activeStats.add(statsType),
    );
  }

  // 選択中の項目のReviewTypeのみ変更する
  replaceReviewType(currentReviewType: ReviewType, newReviewType: ReviewType) {
    return this.update('activities', (activeStats) =>
      activeStats.map((statsType) => statsType.replace(currentReviewType, newReviewType) as StatsType),
    );
  }
}
