import dayjs, { Dayjs } from 'dayjs';
import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export interface NotificationRecord {
  id: number;
  user_id: number | null;
  url: string;
  message: string;
  create_at: Dayjs;
  is_read: boolean;
}

export default class Notification extends Record<NotificationRecord>({
  id: 0,
  user_id: null,
  url: '',
  message: '',
  create_at: dayjs(),
  is_read: false,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.create_at) {
      params.create_at = dayjs.unix(params.create_at);
    }
    super(params);
  }

  get createDateString() {
    return this.create_at && this.create_at.format('YYYY/MM/DD HH:mm');
  }
}
