import dayjs, { Dayjs } from 'dayjs';
import { Record as ImmutableRecord, List } from 'immutable';

import { COLOR, UNIVERSAL_COLORS } from 'style/color';
import { JSObject } from 'types/Common';

import { SortKey, SortOrder } from './MapSearchRankSearchCondition';

export class MapSearchRankCompetitorAverageTableItem extends ImmutableRecord<{
  tag: string;
  storeCount: number;
  isCompetitor: boolean;
  rank: number | null;
  rankHasData: boolean;
  rankComparison: number | null;
  rankComparisonHasData: boolean;
  diff: number | null;
  diffComparison: number | null;
  latestRank: number | null;
}>({
  tag: '',
  storeCount: 0,
  isCompetitor: false,
  rank: null,
  rankHasData: false,
  rankComparison: null,
  rankComparisonHasData: false,
  diff: null,
  diffComparison: null,
  latestRank: null,
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorAverageTableItem {
    return new MapSearchRankCompetitorAverageTableItem({
      tag: data.tag,
      storeCount: data.store_count,
      isCompetitor: data.is_competitor,
      rank: data.rank,
      rankHasData: data.rank_has_data == null ? data.rank != null : data.rank_has_data,
      rankComparison: data.rank_comparison ?? null,
      rankComparisonHasData:
        data.rank_comparison_has_data == null ? data.rank_comparison != null : data.rank_comparison_has_data,
      diff: data.diff,
      diffComparison: data.diff_comparison ?? null,
      latestRank: data.latest_rank,
    });
  }

  /**
   * ランキング圏外か（データ欠落ではなくrankがnull）
   */
  isRankUnranked() {
    return this.rankHasData && this.rank == null;
  }

  /**
   * 比較のランキング圏外か（データ欠落ではなくrankがnull）
   */
  isRankComparisonUnranked() {
    return this.rankComparisonHasData && this.rankComparison == null;
  }
}

export class MapSearchRankCompetitorAverageTableData extends ImmutableRecord<{
  items: List<MapSearchRankCompetitorAverageTableItem>;
}>({ items: List() }) {
  static fromJSON(items: JSObject[]): MapSearchRankCompetitorAverageTableData {
    return new MapSearchRankCompetitorAverageTableData({
      items: List(items.map((item) => MapSearchRankCompetitorAverageTableItem.fromJSON(item))),
    });
  }

  sortedItems(sortKey: SortKey, sortOrder: SortOrder): List<MapSearchRankCompetitorAverageTableItem> {
    let _items = this.items;

    // 並び替えるため、圏外とデータなしは以下の順位として扱う
    // 昇順の場合：1→2→...→59→60→圏外(70)→データなし(80)
    // 降順の場合：圏外(70)→60→59→...→2→1→データなし(0)
    const getRankForSort = (rank: number | null, hasData = false) => {
      return rank ? rank : hasData ? 70 : sortOrder === 'asc' ? 0 : 80;
    };

    switch (sortKey) {
      case 'tag':
        // 昇順で最初に表示されるように自店舗名は空文字として扱う
        _items = _items.sortBy((item) => (item.isCompetitor ? item.tag : ''));
        break;
      case 'rank':
        _items = _items.sortBy((item) => getRankForSort(item.rank, item.rankHasData));
        break;
      case 'rank_comparison':
        _items = _items.sortBy((item) => getRankForSort(item.rankComparison, item.rankComparisonHasData));
        break;
      case 'diff':
        _items = _items.sortBy((item) => getRankForSort(item.diff));
        break;
      case 'diff_comparison':
        _items = _items.sortBy((item) => getRankForSort(item.diffComparison));
        break;
      default:
        break;
    }
    if (sortOrder == 'asc') {
      _items = _items.reverse();
    }
    return _items;
  }
}

export class MapSearchRankCompetitorAverageGraphItemStats extends ImmutableRecord<{
  startDate: Dayjs;
  endDate: Dayjs;
  rank: number | null;
  hasData: boolean;
}>({
  startDate: dayjs(),
  endDate: dayjs(),
  rank: null,
  hasData: false,
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorAverageGraphItemStats {
    return new MapSearchRankCompetitorAverageGraphItemStats({
      startDate: dayjs(data.period.start_date),
      endDate: dayjs(data.period.end_data),
      rank: data.rank,
      hasData: data.has_data == null ? data.rank != null : data.has_data,
    });
  }

  /**
   * ランキング圏外か（データ欠落ではなくrankがnull）
   */
  isUnranked() {
    return this.hasData && this.rank == null;
  }
}

export class MapSearchRankCompetitorAverageGraphItem extends ImmutableRecord<{
  tag: string;
  isCompetitor: boolean;
  stats: List<MapSearchRankCompetitorAverageGraphItemStats>;
}>({
  tag: '',
  isCompetitor: false,
  stats: List(),
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorAverageGraphItem {
    return new MapSearchRankCompetitorAverageGraphItem({
      tag: data.tag,
      isCompetitor: data.is_competitor,
      stats: List(data.stats.map((s: JSObject) => MapSearchRankCompetitorAverageGraphItemStats.fromJSON(s))),
    });
  }

  get key(): string {
    return this.isCompetitor ? this.tag : '自店舗';
  }
}

export class MapSearchRankCompetitorAverageGraphData extends ImmutableRecord<{
  items: List<MapSearchRankCompetitorAverageGraphItem>;
  comparisonItems: List<MapSearchRankCompetitorAverageGraphItem>;
}>({ items: List(), comparisonItems: List() }) {
  static fromJSON(items: JSObject[], comparisonItems: JSObject[]): MapSearchRankCompetitorAverageGraphData {
    return new MapSearchRankCompetitorAverageGraphData({
      items: List(items.map((item) => MapSearchRankCompetitorAverageGraphItem.fromJSON(item))),
      comparisonItems: List(
        (comparisonItems || []).map((item) => MapSearchRankCompetitorAverageGraphItem.fromJSON(item)),
      ),
    });
  }

  findByKey(key: string): MapSearchRankCompetitorAverageGraphItem | undefined {
    return this.items.find((item) => item.key === key);
  }
}

export class MapSearchRankCompetitorAverageData extends ImmutableRecord<{
  tableData: MapSearchRankCompetitorAverageTableData;
  graphData: MapSearchRankCompetitorAverageGraphData;
}>({
  tableData: new MapSearchRankCompetitorAverageTableData(),
  graphData: new MapSearchRankCompetitorAverageGraphData(),
}) {
  static fromJSON(data: JSObject): MapSearchRankCompetitorAverageData {
    return new MapSearchRankCompetitorAverageData({
      tableData: MapSearchRankCompetitorAverageTableData.fromJSON(data.table_items),
      graphData: MapSearchRankCompetitorAverageGraphData.fromJSON(data.graph_items, data.comparison_graph_items),
    });
  }

  tagColors() {
    const sortedItems = this.tableData.items.sortBy((item) => item.tag);
    let index = 0;
    const result: Record<string, string> = {};
    sortedItems.forEach((item) => {
      if (item.isCompetitor) {
        result[item.tag] = UNIVERSAL_COLORS.get(index % UNIVERSAL_COLORS.size) ?? COLOR.GRAY;
        index += 1;
      } else {
        result[item.tag] = COLOR.GREEN;
      }
    });
    return result;
  }
}
