import actionCreatorFactory from 'typescript-fsa';

import { Memo, MemoList, MemoTagList } from 'models/Domain/Memo/Memo';
import { MemoSearchCondition } from 'models/Domain/Memo/MemoSearchCondition';
import { Pagination } from 'models/Domain/Pagination';

const actionCreator = actionCreatorFactory('Memo');

export const MemoActions = {
  initializePage: actionCreator('initializePage'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  setIsLoadingMemos: actionCreator<boolean>('setIsLoadingMemos'),
  setIsLoadingTags: actionCreator<boolean>('setIsLoadingTags'),
  commitSearchCondition: actionCreator<MemoSearchCondition>('commitSearchCondition'),
  setSearchCondition: actionCreator<MemoSearchCondition>('setSearchCondition'),
  setPagination: actionCreator<Pagination>('setPagination'),
  setMemoList: actionCreator<MemoList>('setMemoList'),
  setMemoTagList: actionCreator<MemoTagList>('setMemoTagList'),
  createMemo: actionCreator<Memo>('createMemo'),
  updateMemo: actionCreator<Memo>('updateMemo'),
  deleteMemo: actionCreator<Memo>('deleteMemo'),
};
