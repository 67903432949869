import React, { useCallback } from 'react';

import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLOR } from 'style/color';

interface InputProps {
  className?: string;
  icon?: string;
  iconPosition?: string;
  error?: boolean | string;
  onChange?: (v: string) => void;
  trimOnBlur?: boolean;
}

export const Input: React.FC<InputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>> = ({
  className,
  icon = false,
  iconPosition = null,
  error = false,
  onChange,
  onBlur,
  trimOnBlur = true,
  ...props
}) => {
  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      // trimOnBlurがtrueの場合、Blur時に文字列をトリムした上でonChangeハンドラを実行する
      if (trimOnBlur && onChange) {
        onChange(e.target.value.trim());
      }

      // onBlurが指定されている場合は、onBlurハンドラを実行する
      if (onBlur) {
        onBlur(e);
      }
    },
    [onChange, onBlur, trimOnBlur],
  );

  return (
    <DefaultInput
      className={className}
      icon={icon}
      iconPosition={iconPosition}
      error={error}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.value)}
      onBlur={handleOnBlur}
      {...props}
    />
  );
};

const DefaultInput = styled(Form.Input)`
  &&& {
    opacity: 1 !important;
    width: 100%;

    > div {
      &.input {
        display: block;
        opacity: 1;

        > input {
          color: ${COLOR.BLACK};
          font-size: 16px;
          padding: 16px;
          border-radius: 8px;
          border: solid 1px ${COLOR.GRAY};
          background-color: ${COLOR.WHITE};
          outline: none;
          width: 100% !important;

          ${(props) =>
            props.error &&
            `
              background-color: #fff6f6;
              border-color: ${COLOR.ERROR};
              color: ${COLOR.ERROR};
            `}

          &:read-only {
            cursor: default;
          }

          &:disabled {
            color: ${COLOR.GRAY};
            background-color: ${COLOR.BACKGROUND};
            border-color: ${COLOR.LIGHT_GRAY};
            border-color: ${COLOR.LIGHT_GRAY};
            cursor: not-allowed;
          }

          ${(props) =>
            props.error &&
            `
            background-color: #fff6f6;
            border-color: ${COLOR.ERROR};
            color: ${COLOR.ERROR};
          `}
        }
      }

      &.pointing {
        opacity: 1;
        background: ${COLOR.WHITE} !important;
        border: 1px solid ${COLOR.ERROR} !important;
        color: ${COLOR.ERROR} !important;
      }
    }
  }
`;
