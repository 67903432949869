import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { StatusLabel } from 'components/atoms/StatusLabel';
import { OfferTypeIcon } from 'components/molecules/OfferTypeIcon';
import { OfferActivities, OfferActivity } from 'models/Composite/OfferActivity';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';
import { Mixins } from 'style/mixin';

export const ActivityCard: React.FC<{
  className?: string;
  offerActivity: OfferActivity;
  stores: Stores;
  onClick: (offerId: number) => void;
}> = ({ className, offerActivity, stores, onClick }) => {
  const {
    offer: { id, offer_type, store_id, isDone },
    last_activity,
    has_unread,
    text,
  } = offerActivity;
  const targetStore = stores.findStore(store_id);
  return (
    <CardWrapper className={className} isDone={isDone} onClick={() => onClick(id)}>
      <TopContent>
        <OfferTypeIcon offer_type={offer_type} />
        <OfferTitle>{offerActivity.title}</OfferTitle>
      </TopContent>
      <SecondContent>
        <UserName>{targetStore && targetStore.shortName}</UserName>
        {last_activity && <TimeStamp>{last_activity.timestamp.fromNow()}</TimeStamp>}
      </SecondContent>
      {text && <MainText>{text}</MainText>}
      <BottomContent>
        {has_unread && <StatusLabel type='unread' />}
        {isDone && <StatusLabel type='completed' />}
      </BottomContent>
    </CardWrapper>
  );
};

const CardWrapper = styled.div<{ isDone: boolean }>`
  padding: 18px;
  cursor: pointer;
  color: ${(props) => props.isDone && 'rgba(57, 57, 57, 0.35)'};
  background-color: ${(props) => (props.isDone ? 'rgba(255, 255, 255, 0.35)' : COLOR.WHITE)};
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
`;

const OfferTitle = styled.div`
  font-weight: bold;
  margin-left: 6px;
  ${Mixins.ellipsis};
`;

const SecondContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

const UserName = styled.div``;

const TimeStamp = styled.div`
  flex: none;
`;

const MainText = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 12px;
`;

const BottomContent = styled.div`
  text-align: right;
  > * {
    margin-left: 10px;
  }
`;

export const ActivityCardList: React.FC<{
  className?: string;
  offerActivities: OfferActivities;
  stores: Stores;
  limit?: number;
  onClickCard: (offerId: number) => void;
  onClickMoreButton?: () => void;
}> = ({ className, offerActivities: { offers, has_next }, stores, limit, onClickCard, onClickMoreButton }) => {
  let limitOffers = offers;
  if (limit) {
    limitOffers = offers.take(limit);
  }
  return (
    <Wrapper className={className}>
      <CardListWrapper>
        {limitOffers.map((offerActivity) => (
          <ActivityCard
            key={offerActivity.offer.id}
            offerActivity={offerActivity}
            stores={stores}
            onClick={(offerId) => onClickCard(offerId)}
          />
        ))}
      </CardListWrapper>
      {has_next && onClickMoreButton && (
        <OpenButton priority='low' onClick={() => onClickMoreButton()}>
          もっと見る
        </OpenButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const CardListWrapper = styled.div`
  ${CardWrapper} {
    border-bottom: 1px solid ${COLOR.GRAY};
  }
`;

const OpenButton = styled(Button)`
  &&& {
    display: flex;
    justify-content: center;
    margin: 18px auto 0 auto;
  }
`;
