import React from 'react';

import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { InstagramAccount } from 'models/Domain/instagram';
import { COLOR } from 'style/color';

type Props = {
  account: InstagramAccount;
};

export const InstagramAccountCard = React.memo<Props>(({ account }) => (
  <Wrapper>
    <Name>{account.name}</Name>
    <UserName>{account.userName}</UserName>
  </Wrapper>
));

const Wrapper = styled(Card)`
  border: 1px solid ${COLOR.TABLE_BORDER};
  margin-bottom: 32px;
`;

const UserName = styled.div`
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
  &:before {
    content: '@';
    margin-right: 0.05em;
  }
`;
const Name = styled.div`
  font-weight: bold;
  font-size: 18px;
`;
