import { List, Record } from 'immutable';

import { StoreKey } from 'models/Domain/MapCompetitorResearch/MapSearchResultDetail';
import { JSObject } from 'types/Common';

// 比較店舗の最大設定数
export const MAX_COMPETITOR_SIZE = 10;

// 比較店舗それぞれに設定される色
// Googleスプレッドシートで要素数10のグラフを生成したときの色での色の組み合わせをもとに、
// 透明度80%をアルファチャンネルを使わずに表現したときのRGB
const colors = List([
  '#4B72F1',
  '#D04144',
  '#F1AB2D',
  '#399758',
  '#FA612C',
  '#53B5BB',
  '#9B1EB8',
  '#B6B738',
  '#43469F',
  '#F3629D',
]);

export class Competitor extends Record<{
  storeName: string;
  placeId: string;
  color: string;
}>({
  storeName: '',
  placeId: '',
  color: '#05ccad',
}) {
  static fromJSON(data: JSObject) {
    return new Competitor({
      storeName: data.storeName,
      placeId: data.placeId,
      color: data.color,
    });
  }

  getStoreKey(): StoreKey {
    return new StoreKey({ storeName: this.storeName, placeId: this.placeId });
  }

  isSameStoreName(storeName: string): boolean {
    return this.storeName === storeName;
  }
}

export class CompetitorList extends Record<{
  list: List<Competitor>;
  id: string;
}>({
  list: List(),
  id: '',
}) {
  static fromJSON(data: JSObject) {
    return new CompetitorList({
      list: List((data.list || []).map((x: JSObject) => Competitor.fromJSON(x))),
      id: data.id,
    });
  }

  get size() {
    return this.list.size;
  }

  getAvailableColors() {
    const usedColors = this.list.map((x) => x.color);
    return colors.filterNot((color) => usedColors.contains(color));
  }

  push(storeKey: StoreKey) {
    // 既に存在しているならば特に何もしない
    if (this.list.find((x) => x.getStoreKey().key === storeKey.key) !== undefined) {
      return this;
    }
    return this.update('list', (list) =>
      list.push(
        new Competitor({
          storeName: storeKey.storeName,
          placeId: storeKey.placeId,
          color: this.getAvailableColors().first(),
        }),
      ),
    );
  }

  deleteItem(storeKey: StoreKey) {
    const index = this.list.findIndex((x) => x.getStoreKey().key === storeKey.key);
    if (index >= 0) {
      return this.update('list', (list) => list.remove(index));
    }
    return this;
  }

  contains(storeKey: StoreKey) {
    return !!this.list.find((x) => x.getStoreKey().key === storeKey.key);
  }

  concat(storeKeys: List<StoreKey>) {
    return this.update('list', (list) =>
      list.concat(
        storeKeys.map((storeKey, index) => {
          return new Competitor({
            storeName: storeKey.storeName,
            placeId: storeKey.placeId,
            color: this.getAvailableColors().get(index),
          });
        }),
      ),
    );
  }

  getColor(storeKey: StoreKey) {
    return this.list.find((x) => x.getStoreKey().key === storeKey.key)?.color ?? 'transparent';
  }
}

export class CompetitorSettings extends Record<{
  items: List<CompetitorList>;
}>({
  items: List(),
}) {
  static fromJSON(data: JSObject) {
    return new CompetitorSettings({
      items: List((data.items || []).map((x: JSObject) => CompetitorList.fromJSON(x))),
    });
  }

  updateSettings(id: string, competitors: CompetitorList) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index < 0) {
      return this.update('items', (items) => items.push(competitors));
    }
    return this.setIn(['items', index], competitors);
  }

  findById(id: string) {
    return this.items.find((item) => item.id === id);
  }

  deleteItem(id: string) {
    return this.set(
      'items',
      this.items.filterNot((item) => item.id === id),
    );
  }
}
