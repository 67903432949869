import { Dayjs } from 'dayjs';
import { Record } from 'immutable';

export type FilterStatusType = {
  // 「期間」の開始日
  startDate: Dayjs | null;
  // 「期間」の終了日
  endDate: Dayjs | null;
  // 投稿本文を全文含めるか
  fullText: boolean;
};

/**
 * 投稿のCSVダウンロードの絞り込み条件
 */
export class FilterStatus extends Record<FilterStatusType>({
  startDate: null,
  endDate: null,
  fullText: false,
}) {
  /**
   * 正しい絞り込み条件かを返す
   */
  isValid() {
    return this.isValidDateRange();
  }

  /**
   * 期間が正しい期間を表しているかを返す
   */
  isValidDateRange() {
    if (this.startDate && this.endDate) {
      return this.endDate.isAfter(this.startDate);
    }

    return true;
  }

  /**
   * FilterStatusをCsvDownloadAPIのパラメータに変換する
   */
  getCsvDownloadRequestParams() {
    return {
      date_range: {
        start_date: this.startDate ? this.startDate.format('YYYY-MM-DD') : null,
        end_date: this.endDate ? this.endDate.format('YYYY-MM-DD') : null,
      },
      full_text: this.fullText,
    };
  }
}
