import React from 'react';

import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { Stores } from 'models/Domain/Store';
import { TaskDetail } from 'models/Domain/TaskDetail';
import { User } from 'models/Domain/User';
import { Path } from 'routes';

import { TaskContent } from './TaskContent';
import { TaskOfferList } from './TaskOfferList';

type TaskDetailTemplateProps = {
  isLoading: boolean;
  task: TaskDetail;
  author: User;
  stores: Stores;
  currentUser: User;
};

export const TaskDetailTemplate: React.FC<TaskDetailTemplateProps> = React.memo(
  ({ isLoading, task, author, stores, currentUser }) => {
    // テーブル用データの作成
    const taskOfferDatas = task.offers
      .map((taskOffer) => ({
        id: taskOffer.id,
        store_id: taskOffer.store_id,
        storeName: stores.findStore(taskOffer.store_id)?.fullName || '',
        offerPath: Path.offer.detail.replace(':offerId', String(taskOffer.id)),
        commentCount: taskOffer.comment_count,
        hasUnreadComments: taskOffer.hasUnreadComments(currentUser.id),
        status: taskOffer.status,
        updateAt: taskOffer.update_at,
      }))
      .toArray();

    // 自分に関連するオファーデータの抽出
    let availableStoreIds: Set<number>;
    let myTaskOfferTitle: string;
    if (currentUser.isSvUser) {
      availableStoreIds = new Set(currentUser.managing_stores.toArray());
      myTaskOfferTitle = 'あなたの管理店舗';
    } else if (currentUser.isMemberUser) {
      availableStoreIds = new Set(currentUser.stores.toArray());
      myTaskOfferTitle = 'あなたの所属店舗';
    } else {
      availableStoreIds = new Set();
      myTaskOfferTitle = '';
    }
    const myTaskOfferDatas = taskOfferDatas.filter((taskOffer) => availableStoreIds.has(taskOffer.store_id));

    // SVユーザー、店舗ユーザー以外の場合、かつ、依頼の作成者の場合、「全ての店舗」のリストに更新バッジを表示する
    const displayUpdateBadgeForAllOffers =
      !(currentUser.isSvUser || currentUser.isMemberUser) && currentUser.isSameUser(author);

    // ログインユーザーと作成者が同じ場合だけ編集可能
    const canEdit = currentUser.isSameUser(author);

    if (isLoading) {
      return (
        <Wrapper>
          <StickyHeader title='依頼詳細' />
          <LoadingWrapper>
            <Loader active={true} size='big' inline={true} />
          </LoadingWrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <StickyHeader title='依頼詳細' />
        <Content>
          <TaskContent task={task} author={author} canEdit={canEdit} />
          {myTaskOfferDatas.length > 0 && (
            <TaskOfferListContainer>
              <TaskOfferList title={myTaskOfferTitle} offers={myTaskOfferDatas} displayUpdateBadge={true} />
            </TaskOfferListContainer>
          )}
          <TaskOfferListContainer>
            <TaskOfferList
              title='全ての店舗'
              offers={taskOfferDatas}
              displayUpdateBadge={displayUpdateBadgeForAllOffers}
            />
          </TaskOfferListContainer>
        </Content>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;
const Content = styled.div`
  margin: 0;

  @media (min-width: 415px) {
    margin: 24px 30px;
  }
`;

const TaskOfferListContainer = styled.div`
  margin-top: 48px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
`;
