/* eslint-disable @typescript-eslint/no-use-before-define */

import dayjs, { Dayjs } from 'dayjs';
import { List, Record, Set } from 'immutable';

import { GetGmbReviewRequestParams } from 'ApiClient/GmbApi';
import {
  booleanToSearchParam,
  numberComparator,
  parseBooleanParam,
  parseDateParameter,
  parseNumberParameter,
} from 'helpers/search';
import { JSObject } from 'types/Common';

export type StarRating = 1 | 2 | 3 | 4 | 5;

export type StarRatingFilterType = {
  key: StarRating;
  value: boolean;
};

/**
 * クチコミ絞り込みの条件
 */
export class StarRatingFilter extends Record<StarRatingFilterType>({
  key: 5,
  value: true,
}) {}

export type FilterStatusType = {
  // 「店舗」の選択されている値
  store: number | 'all' | 'my_store' | null;

  // 選択済の店舗
  store_ids: Set<number>;
  is_all_store_ids: boolean; // store_ids が store で指定されているグループのすべての店舗か

  // 「コメント」 → 「コメントあり」の選択状態
  has_comment: boolean;

  // 「コメント」 → 「評価のみ」の選択状態
  no_comment: boolean;

  // 「対応状態」>「対応済」の選択状態
  done: boolean;

  // 「対応状態」>「未対応」の選択状態
  todo: boolean;

  // 「対応状態」>「対応しない」の選択状態
  not_replied: boolean;

  // 「評価」（1~5）の選択状態
  ratings: List<StarRatingFilter>;

  // 「期間」の開始日
  start_date: Dayjs | null;

  // 「期間」の終了日
  end_date: Dayjs | null;

  // 閉店店舗を表示するか
  show_closed_store: boolean;
};

const DEFAULT_STORE_VALUE = null;
const DEFAULT_IS_ALL_STORE_IDS_VALUE = true;
const DEFAULT_NO_COMMENT_VALUE = true;
const DEFAULT_HAS_COMMENT_VALUE = true;
const DEFAULT_TODO_VALUE = true;
const DEFAULT_DONE_VALUE = true;
const DEFAULT_NOT_REPLIED_VALUE = true;
const DEFAULT_SHOW_CLOSED_STORE_VALUE = false;
const DEFAULT_RATINGS_VALUE = Set([5, 4, 3, 2, 1]);

// クチコミ検索の絞り込み条件部分
export type FilterParamsType = Omit<FilterStatusType, 'store' | 'ratings'> & {
  store_id: number[];
  star_ratings: number[];
};

/**
 * クチコミ絞り込みの条件
 */
export class FilterStatus extends Record<FilterStatusType>({
  store: DEFAULT_STORE_VALUE,
  store_ids: Set<number>(),
  is_all_store_ids: DEFAULT_IS_ALL_STORE_IDS_VALUE,
  no_comment: DEFAULT_NO_COMMENT_VALUE,
  has_comment: DEFAULT_HAS_COMMENT_VALUE,
  todo: DEFAULT_TODO_VALUE,
  done: DEFAULT_DONE_VALUE,
  not_replied: DEFAULT_NOT_REPLIED_VALUE,
  ratings: List(
    [5, 4, 3, 2, 1].map(
      (k) =>
        new StarRatingFilter({
          key: k as StarRating,
          value: DEFAULT_RATINGS_VALUE.has(k),
        }),
    ),
  ),
  start_date: null,
  end_date: null,
  show_closed_store: DEFAULT_SHOW_CLOSED_STORE_VALUE,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    super(params);
  }

  /**
   * 評価を更新する
   */
  setRating(key: StarRating, value: boolean) {
    const index = this.ratings.findIndex((rating) => rating.key === key);
    return this.setIn(['ratings', index, 'value'], value);
  }

  getCommentLabel() {
    if ((this.has_comment && this.no_comment) || (!this.has_comment && !this.no_comment)) {
      return 'すべて';
    }

    if (this.has_comment) {
      return 'コメントあり';
    }

    return '評価のみ';
  }

  getStatusLabel() {
    const status = [];
    if ((this.todo && this.done && this.not_replied) || (!this.todo && !this.done && !this.not_replied)) {
      status.push('すべて');
    } else {
      if (this.done) status.push('対応済');
      if (this.todo) status.push('未対応');
      if (this.not_replied) status.push('対応しない');
    }

    return status.join('、');
  }

  /**
   * 評価のラベルを返す
   */
  getRatingLabel() {
    const values = this.ratings.map((rating) => rating.value);

    // すべてtrue(falseを含まない)か、すべてfalse(trueを含まない)の場合
    if (!values.includes(false) || !values.includes(true)) {
      return 'すべて';
    }

    return this.ratings
      .filter((rating) => rating.value === true)
      .map((rating) => `星${rating.key}つ`)
      .join('、');
  }

  setStoreIds(storeIds: Set<number>, isAllStoreIds: boolean) {
    return this.merge({
      store_ids: storeIds,
      is_all_store_ids: isAllStoreIds,
    });
  }

  /**
   * 正しい絞り込み条件かを返す
   */
  isValid() {
    return this.isValidStoreIds() && this.isValidDateRange();
  }

  /**
   * store_idsが正しい形か？
   */
  isValidStoreIds() {
    return this.store_ids.size > 0;
  }

  /**
   * 期間が正しい期間を表しているかを返す
   */
  isValidDateRange() {
    const startDate = this.start_date ? dayjs(this.start_date) : null;
    const endDate = this.end_date ? dayjs(this.end_date) : null;
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        return false;
      }
    }

    return true;
  }

  /**
   * FilterStatusをCsvDownloadAPIのパラメータに変換する
   */
  getCsvDownloadRequestParams(targetColumns: string[]) {
    const comment = this.has_comment === this.no_comment ? null : this.has_comment ? true : false;
    const status = [];
    if (this.done) status.push('done');
    if (this.todo) status.push('todo');
    if (this.not_replied) status.push('not_replied');
    return {
      store_ids: this.store_ids.toArray().sort(numberComparator),
      has_comment: comment,
      status: status,
      date_range: {
        start_date: this.start_date ? this.start_date.format('YYYY-MM-DD') : null,
        end_date: this.end_date ? this.end_date.format('YYYY-MM-DD') : null,
      },
      rating: this.ratings
        .filter((r) => r.value)
        .map((r) => r.key)
        .toArray(),
      target_columns: targetColumns,
    };
  }
}

export type SortKey = 'date' | 'rating';
export type SortOrder = 'asc' | 'desc';

const DEFAULT_SORT_KEY: SortKey = 'date';
const DEFAULT_SORT_ORDER = 'desc';

export type SortStatusType = {
  key: SortKey;
  order: SortOrder;
};

export class SortStatus extends Record<SortStatusType>({
  key: DEFAULT_SORT_KEY,
  order: DEFAULT_SORT_ORDER,
}) {}

export type PaginationType = {
  page: number;
  per_page: number;
};

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;

export class Pagination extends Record<PaginationType>({
  page: DEFAULT_PAGE,
  per_page: DEFAULT_PER_PAGE,
}) {}

// URLパラメータのマッピング
const URLSearchParamsMapping: {
  [key in Exclude<keyof FilterStatusType | keyof SortStatusType | keyof PaginationType, 'is_all_store_ids'>]: string;
} = {
  store: 'st',
  store_ids: 'si',
  no_comment: 'nc',
  has_comment: 'hc',
  todo: 'td',
  done: 'dn',
  not_replied: 'nr',
  ratings: 'rt',
  start_date: 'sd',
  end_date: 'ed',
  key: 'sk',
  order: 'so',
  page: 'pg',
  per_page: 'pp',
  show_closed_store: 'cs',
};

export class GmbReviewSearchCondition extends Record<{
  filter: FilterStatus;
  sort: SortStatus;
  pagination: Pagination;
}>({
  filter: new FilterStatus(),
  sort: new SortStatus(),
  pagination: new Pagination(),
}) {
  // ソート条件を変更する
  setSortCondition(key: SortKey, order: SortOrder) {
    return this.mergeIn(['sort'], { key, order });
  }

  setPage(page: number) {
    return this.setIn(['pagination', 'page'], page);
  }
  /**
   * GmbReviewSearchConditionをAPIのパラメータに変換する
   */
  toRequestParams(): GetGmbReviewRequestParams {
    return {
      store_id: this.filter.store_ids.toArray().sort(numberComparator),
      has_comment: this.filter.has_comment,
      no_comment: this.filter.no_comment,
      done: this.filter.done,
      todo: this.filter.todo,
      not_replied: this.filter.not_replied,
      start_date: this.filter.start_date ? this.filter.start_date.format('YYYY-MM-DD') : '',
      end_date: this.filter.end_date ? this.filter.end_date.format('YYYY-MM-DD') : '',
      star_ratings: this.filter.ratings
        .filter((r) => r.value)
        .map((r) => r.key)
        .toArray(),
      sort_key: this.sort.key,
      sort_order: this.sort.order,
      page: this.pagination.page,
      limit: this.pagination.per_page,
    };
  }

  /**
   * 検索条件をページURL用のパラメータに変換する
   */
  toURLSearchParams(): string {
    const params = new URLSearchParams();
    if (this.filter.store != DEFAULT_STORE_VALUE) {
      params.append(URLSearchParamsMapping.store, String(this.filter.store));
    }

    if (!this.filter.is_all_store_ids && this.filter.store_ids.size > 0) {
      params.append(URLSearchParamsMapping.store_ids, this.filter.store_ids.toArray().sort(numberComparator).join(','));
    }

    // boolean値はtrue/falseを'1'/'0'に変換する
    if (this.filter.no_comment != DEFAULT_NO_COMMENT_VALUE) {
      params.append(URLSearchParamsMapping.no_comment, booleanToSearchParam(this.filter.no_comment));
    }
    if (this.filter.has_comment != DEFAULT_HAS_COMMENT_VALUE) {
      params.append(URLSearchParamsMapping.has_comment, booleanToSearchParam(this.filter.has_comment));
    }
    if (this.filter.todo != DEFAULT_TODO_VALUE) {
      params.append(URLSearchParamsMapping.todo, booleanToSearchParam(this.filter.todo));
    }
    if (this.filter.done != DEFAULT_DONE_VALUE) {
      params.append(URLSearchParamsMapping.done, booleanToSearchParam(this.filter.done));
    }
    if (this.filter.not_replied != DEFAULT_NOT_REPLIED_VALUE) {
      params.append(URLSearchParamsMapping.not_replied, booleanToSearchParam(this.filter.not_replied));
    }
    if (this.filter.show_closed_store != DEFAULT_SHOW_CLOSED_STORE_VALUE) {
      params.append(URLSearchParamsMapping.show_closed_store, booleanToSearchParam(this.filter.show_closed_store));
    }

    const ratings = this.filter.ratings
      .filter((r) => r.value)
      .map((r) => r.key)
      .toArray();
    if (ratings.length > 0 && !Set(ratings).equals(DEFAULT_RATINGS_VALUE)) {
      params.append(URLSearchParamsMapping.ratings, ratings.join(','));
    }

    if (this.filter.start_date) {
      params.append(URLSearchParamsMapping.start_date, this.filter.start_date.format('YYYY-MM-DD'));
    }
    if (this.filter.end_date) {
      params.append(URLSearchParamsMapping.end_date, this.filter.end_date.format('YYYY-MM-DD'));
    }

    if (this.sort.key !== DEFAULT_SORT_KEY) {
      params.append(URLSearchParamsMapping.key, this.sort.key);
    }
    if (this.sort.order !== DEFAULT_SORT_ORDER) {
      params.append(URLSearchParamsMapping.order, this.sort.order);
    }

    if (this.pagination.page !== DEFAULT_PAGE) {
      params.append(URLSearchParamsMapping.page, String(this.pagination.page));
    }
    if (this.pagination.per_page !== DEFAULT_PER_PAGE) {
      params.append(URLSearchParamsMapping.per_page, String(this.pagination.per_page));
    }

    // 見栄え悪いので '%2C' は ',' に戻す
    return params.toString().replace(/%2C/g, ',');
  }

  /**
   * URLパラメータから検索条件を生成する
   * @param search
   */
  static fromURLSearchParams(search: string): GmbReviewSearchCondition {
    const condition = new GmbReviewSearchCondition();
    let { filter, sort, pagination } = condition;
    const params = new URLSearchParams(search);

    const store = params.get(URLSearchParamsMapping.store);
    if (store) {
      if (store === 'all' || store === 'my_store') {
        // すべての店舗もしくは担当店舗の場合
        filter = filter.set('store', store);
      } else if (store.match(/^\d+$/)) {
        // グループの場合
        filter = filter.set('store', parseInt(store, 10));
      }
    }

    const store_ids = params.get(URLSearchParamsMapping.store_ids) || 'all';
    if (store_ids === 'all') {
      filter = filter.setStoreIds(Set<number>(), true);
    } else {
      const values = store_ids.split(',');
      const storeIds = values.filter((v) => v.match(/^\d+$/)).map((v) => parseInt(v, 10));
      filter = filter.setStoreIds(Set(storeIds), false);
    }

    filter = filter.set(
      'no_comment',
      parseBooleanParam(params.get(URLSearchParamsMapping.no_comment), DEFAULT_NO_COMMENT_VALUE),
    );
    filter = filter.set(
      'has_comment',
      parseBooleanParam(params.get(URLSearchParamsMapping.has_comment), DEFAULT_HAS_COMMENT_VALUE),
    );
    filter = filter.set('todo', parseBooleanParam(params.get(URLSearchParamsMapping.todo), DEFAULT_TODO_VALUE));
    filter = filter.set('done', parseBooleanParam(params.get(URLSearchParamsMapping.done), DEFAULT_DONE_VALUE));
    filter = filter.set(
      'not_replied',
      parseBooleanParam(params.get(URLSearchParamsMapping.not_replied), DEFAULT_NOT_REPLIED_VALUE),
    );

    filter = filter.set(
      'show_closed_store',
      parseBooleanParam(params.get(URLSearchParamsMapping.show_closed_store), DEFAULT_SHOW_CLOSED_STORE_VALUE),
    );

    const ratings = params.get(URLSearchParamsMapping.ratings);
    if (ratings) {
      const ratingStrs = ratings.split(',');
      const ratingKeys = ratingStrs.filter((v) => v.match(/^[1-5]$/)).map((v) => parseInt(v, 10));
      const value = List(
        [5, 4, 3, 2, 1].map(
          (key) =>
            new StarRatingFilter({
              key: key as StarRating,
              value: ratingKeys.includes(key),
            }),
        ),
      );
      filter = filter.set('ratings', value);
    }

    const startDate = parseDateParameter(params.get(URLSearchParamsMapping.start_date));
    if (startDate) {
      filter = filter.set('start_date', dayjs(startDate));
    }

    const endDate = parseDateParameter(params.get(URLSearchParamsMapping.end_date));
    if (endDate) {
      filter = filter.set('end_date', dayjs(endDate));
    }

    // ソート関連
    const sort_key = params.get(URLSearchParamsMapping.key);
    if (sort_key) {
      if (['date', 'rating'].includes(sort_key)) {
        sort = sort.set('key', sort_key as SortKey);
      }
    }

    const sort_order = params.get(URLSearchParamsMapping.order);
    if (sort_order) {
      if (['asc', 'desc'].includes(sort_order)) {
        sort = sort.set('order', sort_order as SortOrder);
      }
    }

    // ページネーション関連
    pagination = pagination.set('page', parseNumberParameter(params.get(URLSearchParamsMapping.page), DEFAULT_PAGE, 1));
    pagination = pagination.set(
      'per_page',
      parseNumberParameter(params.get(URLSearchParamsMapping.per_page), DEFAULT_PER_PAGE, 1, 100),
    );

    return condition.merge({ filter, sort, pagination });
  }
}
