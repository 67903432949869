import ApiClient from 'ApiClient';

const apiClient = new ApiClient({});

export type GetCompetitorsParams = {
  store_id?: string; // カンマ区切り
  query?: string; // カンマ区切り
  tag?: string; // カンマ区切り
  page?: number;
  limit?: number;
};

export type GetCompetitorsResponse = {
  items: {
    competitor: {
      id: number;
      organization_id: number;
      store_id: number;
      name: string;
      place_id: string;
      past_place_ids: string[];
      tags: string[];
    };
    google_location_summary: {
      id: number;
      place_id: string;
      last_month_owner_image_count: number | null;
      has_more_last_month_owner_image: boolean | null;
      current_month_owner_image_count: number | null;
      has_more_current_month_owner_image: boolean | null;
      total_owner_image_count: number | null;
      has_more_total_owner_image: boolean | null;
      recent_local_post_count: number | null;
      has_more_recent_local_post: boolean | null;
      total_local_post_count: number | null;
      has_more_total_local_post: boolean | null;
      recent_review_count: number | null;
      has_more_recent_review: boolean | null;
      total_review_count: number | null;
      has_more_total_review: boolean | null;
      recent_review_reply_count: number | null;
      total_review_reply_count: number | null;
      recent_average_review_score: number | null;
      total_average_review_score: number | null;
      crawl_status: 'SUCCESS' | 'FAILED' | null;
      data_update_at: string | null;
      create_at: string;
      update_at: string;
    } | null;
  }[];
  google_location_latest_date_update_at: string | null;
  pagination: {
    current_page: number;
    has_next: boolean;
    total_count: number;
    limit: number;
  };
};

export type GetCompetitorResponse = {
  competitor: {
    id: number;
    organization_id: number;
    store_id: number;
    name: string;
    past_place_ids: string[];
    tags: string[];
  };
  google_location_summary: {
    id: number;
    place_id: string;
    last_month_owner_image_count: number | null;
    has_more_last_month_owner_image: boolean | null;
    current_month_owner_image_count: number | null;
    has_more_current_month_owner_image: boolean | null;
    total_owner_image_count: number | null;
    has_more_total_owner_image: boolean | null;
    recent_local_post_count: number | null;
    has_more_recent_local_post: boolean | null;
    total_local_post_count: number | null;
    has_more_total_local_post: boolean | null;
    recent_review_count: number | null;
    has_more_recent_review: boolean | null;
    total_review_count: number | null;
    has_more_total_review: boolean | null;
    recent_review_reply_count: number | null;
    total_review_reply_count: number | null;
    recent_average_review_score: number | null;
    total_average_review_score: number | null;
    crawl_status: 'SUCCESS' | 'FAILED';
    data_update_at: string;
    create_at: string;
    update_at: string;
  };
  google_location: {
    id: string;
    place_id: string;
    kgmid: string | null;
    location: {
      name: string;
      category: string | null;
      sub_categories: string[] | null;
      open_status: string | null;
      address: string;
      business_hours: Record<string, string> | null;
      more_hours: Record<string, Record<string, string>> | null;
      reservation_links: string[] | null;
      website_url: string | null;
      phone_number: string | null;
    };
    attribute: {
      groups: {
        name: string;
        attributes: {
          name: string;
          label: string;
          is_available: boolean;
        }[];
      }[];
    } | null;
    local_post: {
      recent: {
        all: number;
        standard: number;
        event: number;
        offer: number;
        has_more: boolean;
      };
      total: {
        all: number;
        standard: number;
        event: number;
        offer: number;
        has_more: boolean;
      };
    } | null;
    photo: {
      current_month: {
        count: number;
        has_more: boolean;
        categories: {
          category: string;
          count: number;
        }[];
      };
      last_month: {
        count: number;
        has_more: boolean;
        categories: {
          category: string;
          count: number;
        }[];
      };
      total: {
        count: number;
        has_more: boolean;
      };
    } | null;
    review: {
      recent: {
        average_review_score: number;
        review_count: number;
        review_reply_count: number;
        has_more: boolean;
      };
      total: {
        average_review_score: number;
        review_count: number;
        review_reply_count: number;
        has_more: boolean;
      };
    } | null;
    product: {
      gmc: {
        has_product: boolean;
      };
      gbp: {
        has_product: boolean;
      };
    } | null;
    service: {
      items: {
        name: string;
        services: {
          name: string;
          description: string;
          price: string;
        }[];
      }[];
    } | null;
    timestamp: string;
  };
};

export type PutCompetitorParams = {
  id: number | null; // 新規作成時はnull
  organization_id: number;
  store_id: number;
  place_id?: string;
  name?: string;
  past_place_ids?: string[];
  tags?: string[];
};

export type PutCompetitorResponse = {
  id: number;
  organization_id: number;
  store_id: number;
  place_id: string;
  name: string;
  post_place_ids: string[];
  tags: string[];
};

export type CompetitorBatchInsertParams = {
  items: {
    id?: number;
    store_id: number;
    place_id: string;
    name: string;
    tags: string[];
  }[];
};

export type CompetitorBatchInsertResponse = {
  items: {
    id: number;
    organization_id: number;
    store_id: number;
    place_id: string;
    name: string;
    post_place_ids: string[];
    tags: string[];
  }[];
};

export type CompetitorSearchParams = {
  store_id: number;
  keyword: string;
};

export type CompetitorSearchResponse = {
  store_id: number;
  keyword: string;
  items: {
    store_name: string;
    address: string;
    point: {
      lat: number;
      lng: number;
    };
    place_id: string;
  }[];
};

export class CompetitorApi {
  static endpoint = '/competitors';

  /**
   * 競合店舗一覧取得API
   * @param params
   * @returns
   */
  static getCompetitors(params: GetCompetitorsParams) {
    return apiClient.get<GetCompetitorsResponse>(this.endpoint, params);
  }

  /**
   * 競合店舗詳細取得API
   * @param id
   */
  static getCompetitor(id: number) {
    return apiClient.get<GetCompetitorResponse>(`${this.endpoint}/${id}`);
  }

  /**
   * 競合店舗削除API
   * @param id
   */
  static deleteCompetitor(id: number) {
    return apiClient.delete<GetCompetitorsResponse>(`${this.endpoint}/${id}`);
  }

  /**
   * 競合店舗更新API
   * @param id
   * @param params
   */
  static putCompetitor(id: number | null, params: PutCompetitorParams) {
    // 単体の更新APIが未実装なので、一括更新APIを実行する
    const _params = {
      items: [params],
    };
    return apiClient.post<PutCompetitorResponse[]>(`${this.endpoint}:batch_insert`, _params);
  }

  /**
   * 競合店舗一括登録API
   * @param params
   */
  static bulkInsert(params: CompetitorBatchInsertParams) {
    return apiClient.post<CompetitorBatchInsertResponse>(`${this.endpoint}:batch_insert`, params);
  }

  /**
   * 競合店舗の検索API
   * @param params
   */
  static search(params: CompetitorSearchParams) {
    return apiClient.post<CompetitorSearchResponse>(`${this.endpoint}/search`, params);
  }
}
