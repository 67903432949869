import React from 'react';

import styled from 'styled-components';

import { PromotionTopicTypeLabel } from 'models/Domain/Promotion/Promotion';
import { SortTypeOptions } from 'models/Domain/Promotion/PromotionList';
import { ParamsStatus } from 'models/Domain/Promotion/PromotionSearchCondition';
import { Stores } from 'models/Domain/Store';
import { Mixins } from 'style/mixin';

type Props = {
  className?: string;
  filter: ParamsStatus;
  stores: Stores;
};

export const CurrentSearchCondition: React.FC<Props> = ({ className, filter, stores }) => {
  const topicTypeLabel = PromotionTopicTypeLabel[filter.topic_type];
  const sortTypeLabel = SortTypeOptions.find((s) => s.value === filter.sort_type)?.text || '並び替え';

  const selectedStores = stores.filterStoresById(filter.store_ids.toArray()).list;

  return (
    <Wrapper className={className}>
      <div>
        <span>投稿先店舗</span>: {selectedStores.get(0)?.shortName}{' '}
        {selectedStores.size > 1 && `他${selectedStores.size - 1}店舗`}
      </div>
      <div>
        <span>投稿のタイプ</span>: {topicTypeLabel}
      </div>
      {filter.free_word && (
        <div>
          <span>フリーワード検索</span>: {filter.free_word}
        </div>
      )}
      {(filter.start_date || filter.end_date) && (
        <div>
          <span>投稿日</span>: {filter.start_date ? filter.start_date.format('YYYY/MM/DD') : ''} 〜{' '}
          {filter.end_date ? filter.end_date.format('YYYY/MM/DD') : ''}
        </div>
      )}
      <div>
        <span>並び順</span>: {sortTypeLabel}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  ${Mixins.flex('center')}
  gap: 8px;
  border-radius: 5px;
  padding: 8px 16px;
  background: #fff;

  span {
    font-weight: bold;
  }

  @media (max-width: 685px) {
    flex-direction: column;
    align-items: unset;
  }
`;
