import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Dayjs } from 'dayjs';
import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  GbpInsightDownloadApi,
  GbpInsightDownloadStatusCheckApi,
  GbpInsightGraphApi,
  GbpInsightGraphRequestParams,
  GbpInsightSummaryApi,
  GbpInsightSummaryRequestParams,
} from 'ApiClient/GmbApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { AggregateUnit, GbpInsightSearchCondition } from 'models/Domain/GbpInsight/GbpInsightSearchCondition';
import { InsightGraphData, InsightTableData } from 'models/Domain/GbpInsight/Insight';
import { Stores } from 'models/Domain/Store';
import { GmbInsightDownloadModalState } from 'models/Other/GmbInsightDownloadModalState';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { AppActions } from '../app/actions';

import { GbpInsightActions } from './action';

export default function* saga() {
  yield takeLatest(GbpInsightActions.initializePage, initializePage);
  yield takeLatest(GbpInsightActions.getCsvDownload, getCsvDownload);
  yield takeLatest(GbpInsightActions.checkCsvDownloadStatus, checkCsvDownloadStatus);
  yield takeLatest(GbpInsightActions.getInsightGraphData, getInsightGraphData);
  yield takeLatest(GbpInsightActions.getInsightTableData, getInsightTableData);
  yield takeLatest(GbpInsightActions.setSearchCondition, setSearchCondition);
  yield takeLatest(LOCATION_CHANGE, gbpInsightLocationChange);
}

function* initializePage(action: ReturnType<typeof GbpInsightActions.initializePage>) {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  yield put(GbpInsightActions.setIsPreparedPage(true));
  yield put(GbpInsightActions.clearInsightData());
  yield call(fetchInsightData);
  yield put(GbpInsightActions.setIsInitializedSearchCondition(true));
}

function* fetchInsightData() {
  const location: Location = yield select((state: RouterRootState) => state.router.location);

  // ページのURLパラメータから集計条件を復元する
  let searchCondition = GbpInsightSearchCondition.fromURLSearchParams(location.search);

  const group: Group = yield call(getInitialGroup, { group: searchCondition.group });
  searchCondition = searchCondition.setIn(['group'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.group,
    containsClosedStores: searchCondition.show_closed_store,
    onlyGbpConnectedStores: true,
  });

  const currentStoreIds = searchCondition.store_ids;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.changeStoreIds(availableStoreIds, true);
  } else {
    searchCondition = searchCondition.changeStoreIds(
      currentStoreIds.intersect(availableStoreIds),
      currentStoreIds.equals(availableStoreIds),
    );
  }

  // 既存のURLと、集計条件から生成されるURLが異なる場合（不要なパラメータがある場合など）、正しいURLに置き換える
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  // 集計条件を反映して、インサイトデータを取得する
  yield put(GbpInsightActions.setSearchCondition({ searchCondition, updateLocation: false }));
  yield put(GbpInsightActions.setCommittedSearchCondition(searchCondition));
  yield put(GbpInsightActions.getInsightGraphData());
  yield put(GbpInsightActions.getInsightTableData());
}

function* gbpInsightLocationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // PathがGBPインサイトページ以外の場合は何もしない
  if (location.pathname !== Path.gmb.insight) {
    return;
  }

  // GBPインサイトページの初期化処理が完了していない場合は何もしない
  const isPreparedPage: boolean = yield select((state) => state.gbpInsight.isPreparedPage);
  if (!isPreparedPage) {
    return;
  }

  yield call(fetchInsightData);
}

function* setSearchCondition(action: ReturnType<typeof GbpInsightActions.setSearchCondition>) {
  const { searchCondition, updateLocation = true } = action.payload;

  if (updateLocation) {
    const location: Location = yield select((state: RouterRootState) => state.router.location);

    // 既存のURLと、変更後の集計条件から生成されるURLが異なる場合、URLを更新する
    const search = searchCondition.toURLSearchParams();
    if (hasDiffSearch(location.search, search)) {
      const path = `${location.pathname}?${search}`;
      yield put(pushWithOrganizationId(path));
    }
  }
}

/**
 * グラフのための、集計単位ごとに区切られたインサイトデータを取得
 */
function* getInsightGraphData(action: ReturnType<typeof GbpInsightActions.getInsightGraphData>) {
  yield put(GbpInsightActions.setIsLoadingGraphData(true));
  const searchCondition: GbpInsightSearchCondition = yield select((state: State) => state.gbpInsight.searchCondition);

  // 集計期間のグラフデータを取得する
  const graphData: YieldReturn<typeof fetchInsightGraphData> = yield fetchInsightGraphData(
    searchCondition.aggregateUnit,
    searchCondition.startDate,
    searchCondition.endDate,
    searchCondition.store_ids,
  );

  // 比較期間が有効になっている場合、比較期間のグラフデータを取得する
  let comparisonGraphData: InsightGraphData | undefined;
  if (searchCondition.isEnabledComparison) {
    comparisonGraphData = yield fetchInsightGraphData(
      searchCondition.aggregateUnit,
      searchCondition.comparisonStartDate,
      searchCondition.comparisonEndDate,
      searchCondition.store_ids,
    );
  }

  yield put(
    GbpInsightActions.setInsightGraphData([
      graphData ?? new InsightGraphData(),
      comparisonGraphData ?? new InsightGraphData(),
    ]),
  );
  yield put(GbpInsightActions.setIsLoadingGraphData(false));
}

/**
 * インサイトのグラフデータを取得する
 */
async function fetchInsightGraphData(
  aggregateUnit: AggregateUnit,
  fromDate: Dayjs,
  toDate: Dayjs,
  storeIds: Set<number>,
) {
  const params: GbpInsightGraphRequestParams = {
    unit: aggregateUnit,
    from_date: fromDate.format('YYYY-MM-DD'),
    to_date: toDate.format('YYYY-MM-DD'),
  };
  if (storeIds.size > 0) {
    params.store_ids = storeIds.toArray();
  }
  const response: YieldReturn<typeof GbpInsightGraphApi.get> = await GbpInsightGraphApi.get(params);
  if (response.isSuccess) {
    return InsightGraphData.fromJSON(response.data);
  } else {
    toast({
      type: 'error',
      title: 'インサイトの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getInsightTableData(action: ReturnType<typeof GbpInsightActions.getInsightTableData>) {
  yield put(GbpInsightActions.setIsLoadingTableData(true));
  const stores: Stores = yield select((state) => state.store.stores);
  const searchCondition: GbpInsightSearchCondition = yield select((state: State) => state.gbpInsight.searchCondition);

  // 集計期間のサマリーデータを取得する
  const tableData: YieldReturn<typeof fetchInsightTableData> = yield fetchInsightTableData(
    searchCondition.startDate,
    searchCondition.endDate,
    searchCondition.store_ids,
    stores,
  );

  // 比較期間が有効になっている場合、比較期間のサマリーデータを取得する
  let comparisonTableData: InsightTableData | undefined;
  if (searchCondition.isEnabledComparison) {
    comparisonTableData = yield fetchInsightTableData(
      searchCondition.comparisonStartDate,
      searchCondition.comparisonEndDate,
      searchCondition.store_ids,
      stores,
    );
  } else {
    comparisonTableData = new InsightTableData();
  }

  yield put(
    GbpInsightActions.setInsightTableData([
      tableData ?? new InsightTableData(),
      comparisonTableData ?? new InsightTableData(),
    ]),
  );

  yield put(GbpInsightActions.setIsLoadingTableData(false));
}

/**
 * インサイトのサマリーデータを取得する
 */
async function fetchInsightTableData(fromDate: Dayjs, toDate: Dayjs, storeIds: Set<number>, stores: Stores) {
  const params: GbpInsightSummaryRequestParams = {
    from_date: fromDate.format('YYYY-MM-DD'),
    to_date: toDate.format('YYYY-MM-DD'),
  };
  if (storeIds.size > 0) {
    params.store_ids = storeIds.toArray();
  }
  const response: YieldReturn<typeof GbpInsightSummaryApi.get> = await GbpInsightSummaryApi.get(params);
  if (response.isSuccess) {
    const modStores = response.data.stores.map((item) => {
      const store = stores.findStore(item.store_id);
      return { ...item, store: store };
    });
    return InsightTableData.fromJSON({ ...response.data, stores: modStores });
  } else {
    toast({
      type: 'error',
      title: 'インサイトの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* getCsvDownload(action: ReturnType<typeof GbpInsightActions.getCsvDownload>) {
  yield put(AppActions.setLoading(true));
  const downloadModalState: GmbInsightDownloadModalState = yield select(
    (state: State) => state.gbpInsight.downloadModalState,
  );
  const csvDownload: ExecutionArnStatus = yield select((state) => state.gbpInsight.csvDownload);
  const params = downloadModalState.requestParams();
  const response: YieldReturn<typeof GbpInsightDownloadApi.post> = yield GbpInsightDownloadApi.post(params);
  if (response.isSuccess) {
    yield put(GbpInsightActions.setCsvDownload(csvDownload.setExecutionArn(response.data.executionArn)));
  } else {
    yield put(AppActions.setLoading(false));
    toast({
      type: 'error',
      title: 'ファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* checkCsvDownloadStatus(action: ReturnType<typeof GbpInsightActions.checkCsvDownloadStatus>) {
  const csvDownload: ExecutionArnStatus = yield select((state) => state.gbpInsight.csvDownload);
  if (csvDownload.executionArn) {
    const response: YieldReturn<typeof GbpInsightDownloadStatusCheckApi.post> =
      yield GbpInsightDownloadStatusCheckApi.post({ executionArn: csvDownload.executionArn });
    if (response.isSuccess) {
      if (response.data.status === 'RUNNING') {
        // do nothing
      } else if (response.data.status === 'SUCCEEDED') {
        const downloadUrl = response.data.download_url;
        if (downloadUrl) {
          window.location.assign(downloadUrl);
          toast({
            type: 'success',
            title: 'ファイルをダウンロードしました',
          });
        } else {
          toast({
            type: 'info',
            title: String(response.data.message),
            time: 10000,
          });
        }
        const downloadModalState: GmbInsightDownloadModalState = yield select(
          (state: State) => state.gbpInsight.downloadModalState,
        );
        yield put(GbpInsightActions.clearCsvDownload());
        yield clearInterval(action.payload);
        yield put(GbpInsightActions.setDownloadModalState(downloadModalState.changeIsOpen(false)));
        yield put(AppActions.setLoading(false));
      } else {
        yield put(AppActions.setLoading(false));
        yield put(GbpInsightActions.clearCsvDownload());
        toast({
          type: 'error',
          title: String(response.data.message),
          description: '',
          time: 10000,
        });
        yield clearInterval(action.payload);
        yield put(AppActions.setLoading(false));
      }
    } else {
      yield put(AppActions.setLoading(false));
      toast({
        type: 'error',
        title: 'ファイルのダウンロードに失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
  }
}
