import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';
import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { FileContents } from 'models/Domain/FileContent';
import { COLOR } from 'style/color';

interface Props {
  className?: string;
  open?: boolean;
  images: FileContents;
  initialIndex: number;
  displayCommentButton?: boolean;
  onClose: () => void;
  openImageCommentModal?: (imageUrl: string) => void;
}

export const ImageViewerModal: React.FC<Props> = ({
  className,
  open,
  images,
  initialIndex,
  displayCommentButton = true,
  onClose,
  openImageCommentModal,
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const settings = {
    initialSlide: initialIndex,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    dots: true,
    dotsClass: 'slick-dots',
    centerPadding: '100px',
    beforeChange: (_: number, currentSlide: number) => setSelectedImageIndex(currentSlide),
  };

  useEffect(() => {
    setSelectedImageIndex(initialIndex);
  }, [initialIndex]);

  const image = images.list.get(selectedImageIndex);

  if (!image) {
    return null;
  }

  return (
    <Modal className={className} open={open} style={{ backgroundColor: 'transparent', boxShadow: 'initial' }}>
      <Wrapper>
        <Viewer {...settings}>
          {images.list.map((file, i) => (
            <Img src={file.url} key={i} />
          ))}
        </Viewer>
        {image && <Caption>{image.caption}</Caption>}
        {displayCommentButton && openImageCommentModal !== undefined && (
          <OpenInputButton
            onClick={() => {
              onClose();
              openImageCommentModal(image.url);
            }}
          >
            <CommentIcon name='comment outline' />
            <CommentLabel>画像にコメントする</CommentLabel>
          </OpenInputButton>
        )}
        <Close onClick={onClose}>
          <CloseIcon size='large' name='close' />
          <CloseLabel>閉じる</CloseLabel>
        </Close>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  @media (min-width: 415px) {
    margin: 0 40px;
  }
`;

const Viewer = styled(Slider)`
  margin-bottom: 55px;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-arrow {
    &:before {
      font-size: 40px;
    }
    @media (max-width: 414px) {
      display: none !important;
    }
  }

  .slick-prev {
    left: -45px;
  }

  .slick-dots {
    bottom: initial;
    margin-top: 20px;
  }

  .slick-dots > li > button:before {
    color: ${COLOR.DARK_GRAY};
    opacity: initial;
  }

  .slick-dots > li.slick-active > button:before {
    color: ${COLOR.WHITE};
    opacity: initial;
  }
`;

const Caption = styled.div`
  color: ${COLOR.WHITE};
  text-align: center;
  overflow: auto;
  max-height: 54px;
`;

const Close = styled.div`
  width: 115px;
  color: ${COLOR.WHITE};
  margin: 30px auto 0;
  padding: 10px;
  cursor: pointer;
`;

const OpenInputButton = styled.div`
  width: 211px;
  color: ${COLOR.WHITE};
  border: solid 2px ${COLOR.WHITE};
  border-radius: 10px;
  margin: 30px auto 0;
  padding: 10px;
  cursor: pointer;
`;

const CloseIcon = styled(Icon)`
  color: ${COLOR.WHITE};
`;

const CloseLabel = styled.span`
  margin-left: 11px;
  font-size: 16px;
`;

const CommentIcon = styled(Icon)`
  color: ${COLOR.WHITE};
`;

const CommentLabel = styled.span`
  margin-left: 11px;
  font-size: 16px;
`;

const Img = styled.img`
  display: block !important;
  max-height: ${window.innerHeight - 224}px;
  max-width: 100%;
  @media (max-width: 414px) {
    max-width: ${window.innerWidth - 22}px;
  }
  margin: 0 auto;
  width: initial !important;
`;
