import { LOCATION_CHANGE, LocationChangeAction, RouterRootState } from 'connected-react-router';
import { Set } from 'immutable';
import { toast } from 'react-semantic-toasts';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  MapSearchRankConfigApi,
  MapSearchRankConfigCSVCreateApi,
  MapSearchRankConfigCSVStatusApi,
} from 'ApiClient/GmbApi';
import { hasDiffSearch, pushWithOrganizationId, replaceWithOrganizationId } from 'helpers/router';
import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { MapSearchRankConfigData } from 'models/Domain/MapSearchRank/MapSearchRankConfigData';
import { MapSearchRankConfigSearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankConfigSearchCondition';
import { State } from 'modules/reducers';
import { getInitialGroup, getInitialStores, waitForUserAndStoresInitialized } from 'modules/utils';
import { Path } from 'routes';
import { Group } from 'types/Common';

import { MapSearchRankConfigActions as Actions } from './actions';

export default function* saga() {
  yield takeLatest(Actions.initializePage, initializePage);
  yield takeLatest(Actions.commitSearchCondition, commitSearchCondition);
  yield takeLatest(Actions.startCsvDownload, startCsvDownload);
  yield takeLatest(Actions.checkCsvDownloadStatus, checkCsvDownloadStatus);
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

// ページを初期化する
function* initializePage() {
  // ページを表示する条件が整うまで待機
  yield call(waitForUserAndStoresInitialized);

  const isPreparedPage: boolean = yield select((state: State) => state.mapSearchRankConfig.isPreparedPage);
  // 初期化済みの場合は以下の処理を行わない
  if (isPreparedPage) {
    return;
  }

  yield put(Actions.setIsPreparedPage(true));

  // URLパラメータから検索条件を復元する
  yield call(restoreSearchConditionFromURL);

  yield put(Actions.setIsInitializedSearchCondition(true));
}

function* commitSearchCondition(action: ReturnType<typeof Actions.setSearchCondition>) {
  const searchCondition = action.payload;
  const location: Location = yield select((state: RouterRootState) => state.router.location);
  // 既存とURLに差分がある場合、URLの更新を行う
  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(pushWithOrganizationId(path));
  }
  yield put(Actions.setSearchCondition(searchCondition));
}

/**
 * URLパラメータから検索条件を復元する
 */
function* restoreSearchConditionFromURL() {
  const location: Location = yield select((state: State) => state.router.location);

  // URLパラメータから検索条件を復元する
  // URLの置き換え
  let searchCondition = MapSearchRankConfigSearchCondition.fromURLSearchParams(location.search);
  const group: Group = yield call(getInitialGroup, { group: searchCondition.filter.group });
  searchCondition = searchCondition.setIn(['filter', 'group'], group);

  const availableStoreIds: Set<number> = yield call(getInitialStores, {
    group: searchCondition.filter.group,
    containsClosedStores: searchCondition.filter.showClosedStores,
  });

  const currentStoreIds = searchCondition.filter.storeIds;
  if (currentStoreIds.isEmpty()) {
    searchCondition = searchCondition.update('filter', (filter) => filter.setStoreIds(availableStoreIds, true));
  } else {
    searchCondition = searchCondition.update('filter', (filter) =>
      filter.setStoreIds(currentStoreIds.intersect(availableStoreIds), currentStoreIds.equals(availableStoreIds)),
    );
  }

  const search = searchCondition.toURLSearchParams();
  if (hasDiffSearch(location.search, search)) {
    const path = `${location.pathname}?${search}`;
    yield put(replaceWithOrganizationId(path));
    return;
  }

  yield put(Actions.setSearchCondition(searchCondition));

  yield put(Actions.setIsLoading(true));

  yield call(fetchConfigData);
}

function* locationChange(action: LocationChangeAction) {
  const { location } = action.payload;

  // Pathが検索順位監視画面以外の場合は何もしない
  if (location.pathname !== Path.mapSearchRank.config) {
    return;
  }

  // 検索順位監視画面の初期化処理が完了していない場合は何もしない
  const isPreparedPage: boolean = yield select((state: State) => state.mapSearchRankConfig.isPreparedPage);
  if (!isPreparedPage) {
    return;
  }

  yield call(restoreSearchConditionFromURL);
}

function* fetchConfigData() {
  yield put(Actions.setIsLoading(true));
  const searchCondition: MapSearchRankConfigSearchCondition = yield select(
    (state: State) => state.mapSearchRankConfig.searchCondition,
  );
  const params = searchCondition.toRequestParams();
  const response: YieldReturn<typeof MapSearchRankConfigApi.get> = yield MapSearchRankConfigApi.get(params);

  if (response.isSuccess) {
    // 新しいデータをセットする
    const newData = MapSearchRankConfigData.fromJSON(response.data);
    yield put(Actions.setData(newData));
  } else {
    toast({
      type: 'error',
      title: 'Googleマップ検索順位の取得設定できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }

  yield put(Actions.setIsLoading(false));
}

function* startCsvDownload(action: ReturnType<typeof Actions.startCsvDownload>) {
  yield put(Actions.setIsProcessingCsvDownload(true));
  const searchCondition = action.payload;
  const params = searchCondition.toCSVCreateRequestParams();
  const response: YieldReturn<typeof MapSearchRankConfigCSVCreateApi.post> =
    yield MapSearchRankConfigCSVCreateApi.post(params);
  if (response.isSuccess) {
    yield put(Actions.setCsvDownload(new ExecutionArnStatus({ executionArn: response.data.executionArn })));
    yield put(Actions.checkCsvDownloadStatus());
  } else {
    yield put(Actions.setIsProcessingCsvDownload(false));
    toast({
      type: 'error',
      title: 'ファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

function* checkCsvDownloadStatus(action: ReturnType<typeof Actions.checkCsvDownloadStatus>) {
  const csvDownload: ExecutionArnStatus = yield select((state) => state.mapSearchRankConfig.csvDownload);
  if (!csvDownload.executionArn) {
    return;
  }

  const response: YieldReturn<typeof MapSearchRankConfigCSVStatusApi.post> = yield MapSearchRankConfigCSVStatusApi.post(
    {
      executionArn: csvDownload.executionArn,
    },
  );
  if (response.isSuccess) {
    if (response.data.status === 'RUNNING') {
      // 3秒後に再度チェックを行う
      yield delay(3000);
      yield put(Actions.checkCsvDownloadStatus());
    } else if (response.data.status === 'SUCCEEDED') {
      const downloadUrl = response.data.download_url;
      if (downloadUrl) {
        window.location.assign(downloadUrl);
        toast({
          type: 'success',
          title: 'ファイルをダウンロードしました',
        });
      } else {
        toast({
          type: 'info',
          title: String(response.data.message),
          time: 10000,
        });
      }
      yield put(Actions.clearCsvDownload());
      yield put(Actions.setIsProcessingCsvDownload(false));
    } else {
      yield put(Actions.clearCsvDownload());
      toast({
        type: 'error',
        title: String(response.data.message),
        description: '',
        time: 10000,
      });
      yield put(Actions.setIsProcessingCsvDownload(false));
    }
  } else {
    yield put(Actions.setIsProcessingCsvDownload(false));
    toast({
      type: 'error',
      title: 'ファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}
