import React from 'react';

import { Icon as SemanticIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Service } from 'models/Domain/Service/Service';
import { COLOR } from 'style/color';

type Props = {
  service: Service;
  canSort: boolean;
  onChangeIndex?: () => void;
  onDelete: () => void;
  showDivider: boolean;
};

export const ServiceSortItem: React.FC<Props> = ({ service, canSort, onChangeIndex, onDelete, showDivider }) => {
  return (
    <>
      {showDivider && (
        <DividerWrapper>
          {canSort && (
            <SortButton onClick={onChangeIndex}>
              <SortButtonIcon />
            </SortButton>
          )}
          <Divider />
        </DividerWrapper>
      )}
      <Wrapper>
        <FlexContent>
          <Name>{service.name}</Name>
          <DeleteButton onClick={onDelete}>
            <DeleteButtonIcon />
          </DeleteButton>
        </FlexContent>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: 16px;
`;

const FlexContent = styled.div`
  display: flex;
`;

const Name = styled.div`
  flex: 1;
  font-size: 16px;
`;

const ButtonWithIcon = styled(Button).attrs({ priority: 'low' })`
  &&& {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
`;

const SortButton = styled(ButtonWithIcon)`
  &&& {
    color: ${COLOR.CHARCOAL_GRAY};
  }
`;

const DeleteButton = styled(ButtonWithIcon)`
  &&& {
    color: ${COLOR.CHARCOAL_GRAY};
    :hover {
      color: ${COLOR.ERROR};
    }
  }
`;

const StyledIcon = styled(SemanticIcon)`
  &&& {
    line-height: 1;
    height: auto;
  }
`;

const SortButtonIcon = styled(StyledIcon).attrs({ name: 'exchange' })`
  &&& {
    transform: rotate(90deg);
  }
`;
const DeleteButtonIcon = styled(StyledIcon).attrs({ name: 'trash alternate' })``;

const DividerWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const Divider = styled.hr`
  width: 100%;
  margin: 16px 0;
  border-top: 1px solid ${COLOR.GRAY};
  border-bottom: none;
`;
