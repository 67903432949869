import { Dayjs } from 'dayjs';
import { Record as ImmutableRecord } from 'immutable';

import { AggregateUnit } from 'types/Common';

export class DateRangeConfig extends ImmutableRecord<{
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  isEnabledComparison: boolean;
  aggregateUnit: AggregateUnit;
}>({
  startDate: null,
  endDate: null,
  comparisonStartDate: null,
  comparisonEndDate: null,
  isEnabledComparison: false,
  aggregateUnit: 'month',
}) {
  get targetPeriod() {
    if (this.startDate === null || this.endDate === null) {
      return null;
    }
    return {
      start: this.startDate,
      end: this.endDate,
    };
  }

  get comparisonPeriod() {
    if (!this.isEnabledComparison || this.comparisonStartDate === null || this.comparisonEndDate === null) {
      return null;
    }
    return {
      start: this.comparisonStartDate,
      end: this.comparisonEndDate,
    };
  }

  // 集計期間と比較期間が連続しているかどうか
  get isContinuousPeriod() {
    // どちらかがnullの場合はfalseを返す
    if (this.targetPeriod === null || this.comparisonPeriod === null) {
      return false;
    }
    // comparisonPeriod→targetPeriodの順のとき、
    // comparisonPeriodの終了日の翌日がtargetPeriodの開始日と同じ場合は結合できる
    // 例: comparisonPeriod: 2020/01/01〜2020/01/31, targetPeriod: 2020/02/01〜2020/02/29
    // または、targetPeriod→comparisonPeriodの順のとき、
    // targetPeriodの開始日がcomparisonPeriodの終了日の翌日と同じ場合は結合できる
    // 例: targetPeriod: 2020/01/01〜2020/01/31, comparisonPeriod: 2020/02/01〜2020/02/29
    return (
      this.targetPeriod.start.isSame(this.comparisonPeriod.end.add(1, 'day')) ||
      this.targetPeriod.end.isSame(this.comparisonPeriod.start.subtract(1, 'day'))
    );
  }

  // 集計期間と比較期間の開始日、終了日のうち最も古い日付を返す
  get oldestDate() {
    if (this.targetPeriod != null && this.comparisonPeriod != null) {
      return this.targetPeriod.start.isBefore(this.comparisonPeriod.start)
        ? this.targetPeriod.start
        : this.comparisonPeriod.start;
    }
    if (this.targetPeriod != null && this.comparisonPeriod == null) {
      return this.targetPeriod.start;
    }
    if (this.targetPeriod == null && this.comparisonPeriod != null) {
      return this.comparisonPeriod.start;
    }
    return null;
  }

  // 集計期間と比較期間の開始日、終了日のうち最も新しい日付を返す
  get latestDate() {
    if (this.targetPeriod != null && this.comparisonPeriod != null) {
      return this.targetPeriod.end.isAfter(this.comparisonPeriod.end)
        ? this.targetPeriod.end
        : this.comparisonPeriod.end;
    }
    if (this.targetPeriod != null && this.comparisonPeriod == null) {
      return this.targetPeriod.end;
    }
    if (this.targetPeriod == null && this.comparisonPeriod != null) {
      return this.comparisonPeriod.end;
    }
    return null;
  }
}
