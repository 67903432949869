import React from 'react';

import { Loading } from 'components/atoms/Loading';

import { AuthPieceProps } from './AuthPiece';

export default class CustomLoading extends React.Component<AuthPieceProps> {
  render() {
    const { authState } = this.props;
    return <Loading isLoading={authState === 'loading'} />;
  }
}
