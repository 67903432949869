import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import { YearMonth } from 'models/Domain/YearMonth';
import { JSObject } from 'types/Common';

export type Status = 'QUEUED' | 'SUCCEEDED' | 'RUNNING' | 'FAILED';

export class Area extends ImmutableRecord<{
  name: string | null;
  extraInfo?: JSObject;
}>({
  name: null,
  extraInfo: undefined,
}) {
  static fromJSON(data: JSObject) {
    return new Area({
      name: data.name,
      extraInfo: data.extra_info,
    });
  }

  get isStore() {
    return this.extraInfo?.store_id != null;
  }

  get areaName(): string | null {
    // 店舗が指定されていたら店舗名、そうでなければ地域名を返す
    if (this.isStore && this.extraInfo?.store_name) {
      return this.extraInfo.store_name;
    }
    return this.name;
  }
}

export class SearchWord extends ImmutableRecord<{
  name: string;
  extraInfo?: JSObject;
}>({
  name: '',
  extraInfo: undefined,
}) {
  static fromJSON(data: JSObject) {
    return new SearchWord({
      name: data.name,
      extraInfo: data.extra_data,
    });
  }
}

export class Period extends ImmutableRecord<{
  startMonth: YearMonth;
  endMonth: YearMonth;
}>({
  startMonth: new YearMonth(),
  endMonth: new YearMonth(),
}) {
  static fromJSON(data: JSObject) {
    return new Period({
      startMonth: YearMonth.fromString(data.start_month),
      endMonth: YearMonth.fromString(data.end_month),
    });
  }
}

export class GeoTarget extends ImmutableRecord<{
  id: string;
  name: string;
  canonicalName: string;
}>({
  id: '',
  name: '',
  canonicalName: '',
}) {
  static fromJSON(data: JSObject) {
    return new GeoTarget({
      id: data.id,
      name: data.name,
      canonicalName: data.canonical_name,
    });
  }
}

export class Condition extends ImmutableRecord<{
  area: Area;
  searchWord: SearchWord;
  period: Period;
}>({
  area: new Area(),
  searchWord: new SearchWord(),
  period: new Period(),
}) {
  static fromJSON(data: JSObject) {
    return new Condition({
      area: Area.fromJSON(data.area),
      searchWord: SearchWord.fromJSON(data.search_word),
      period: Period.fromJSON(data.period),
    });
  }
}

export class Conditions extends ImmutableRecord<{
  areas: ImmutableSet<Area>;
  searchWords: ImmutableSet<SearchWord>;
  period: Period;
}>({
  areas: ImmutableSet(),
  searchWords: ImmutableSet(),
  period: new Period(),
}) {
  static fromJSON(data: JSObject) {
    return new Conditions({
      areas: ImmutableSet(data.areas.map((item: JSObject) => Area.fromJSON(item))),
      searchWords: ImmutableSet(data.search_words.map((item: JSObject) => SearchWord.fromJSON(item))),
      period: Period.fromJSON(data.period),
    });
  }
}
