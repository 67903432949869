import React from 'react';

import { Prompt as ReactRouterPrompt } from 'react-router-dom';

interface Props {
  when: boolean;
  message: string;
}

/**
 * 画面遷移時に確認ダイアログを表示する
 * react-router-domのPromptに、replaceによるURLの変更で2度表示されるのを防いだもの
 *
 * @param when 画面遷移時に確認ダイアログを表示するか
 * @param message 確認ダイアログに表示する文字列
 * @constructor
 */
export const Prompt: React.FC<Props> = ({ when, message }) => (
  <ReactRouterPrompt
    when={when}
    message={(location, action) => {
      // ページ遷移のPUSHと、パラメータ変更によるREPLACEの両方でダイアログが表示されないように、
      // REPLACEの場合は表示しない（trueを返すことで、ダイアログを表示せずに遷移する）
      return action === 'REPLACE' ? true : message;
    }}
  />
);
