import dayjs, { Dayjs } from 'dayjs';

/**
 * boolean値をURLパラメータ文字列に変換する
 * - true => '1'
 * - false => '0'
 * @param value boolean値
 */
export const booleanToSearchParam = (value: boolean) => String(Number(value)) as '0' | '1';

/**
 * booleanのパラメータを取得する
 * - '1' => true
 * - '0' => false
 * - それ以外 => デフォルト値
 *
 * @param param
 * @param defaultValue デフォルト値
 */
export const parseBooleanParam = (param: string | null, defaultValue = false) => {
  if (param === '1') {
    return true;
  }
  if (param === '0') {
    return false;
  }

  return defaultValue;
};

/**
 * 日付をURLパラメータ文字列に変換する
 * @param value Dayjs
 * @param format string
 */
export const dateToSearchParam = (value: Dayjs, format = 'YYYY-MM-DD'): string => value.format(format);

/**
 * 日時のパラメータを取得する
 * - 'YYYY-MM-DD'形式 => その値
 *
 * @param date
 * @param defaultValue デフォルト値
 */
export const parseDateParameter = (date: string | null, defaultValue = '') => {
  if (!date) {
    return defaultValue;
  }

  const format = 'YYYY-MM-DD';
  // dayjsはフォーマットを行うと2020/02/31は2020/03/02に変換されるので、
  // dayjs経由で文字列に再変換することで、実在する日付かチェックできる
  if (dayjs(date, format).format(format) === date) {
    return date;
  }

  return defaultValue;
};

/**
 * 月のパラメータを取得する
 * - 'YYYY-MM'形式 => その値
 *
 * @param month
 * @param defaultValue デフォルト値
 */
export const parseMonthParameter = (month: string | null, defaultValue = '') => {
  if (!month) {
    return defaultValue;
  }

  const format = 'YYYY-MM';
  if (dayjs(month, format).format(format) === month) {
    return month;
  }

  return defaultValue;
};

/**
 * 数値のパラメータを取得する。最小値、最大値が設定され、範囲内に入っていない場合はデフォルト値が返る
 * @param param
 * @param defaultValue デフォルト値
 * @param min 最小値
 * @param max 最大値
 */
export const parseNumberParameter = (param: string | null, defaultValue: number, min?: number, max?: number) => {
  if (!param) {
    return defaultValue;
  }

  try {
    const num = parseInt(param, 10);
    if (isNaN(num)) {
      return defaultValue;
    }
    if (min !== undefined && num < min) {
      return defaultValue;
    }
    if (max !== undefined && num > max) {
      return defaultValue;
    }

    return num;
  } catch (e) {
    return defaultValue;
  }
};

export const numberComparator = (a: number, b: number) => a - b;
