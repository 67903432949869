import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { JSObject } from 'types/Common';

export class GeneratedPromotion extends ImmutableRecord<{
  body: string;
}>({
  body: '',
}) {
  static fromJSON(data: JSObject) {
    const params = { ...data };
    return new GeneratedPromotion({
      body: params.body,
    });
  }
}

export class GeneratedPromotionList extends ImmutableRecord<{
  items: ImmutableList<GeneratedPromotion>;
  contentsFiltered: boolean;
}>({
  items: ImmutableList(),
  contentsFiltered: false,
}) {
  static fromJSON(data: JSObject) {
    const params = { ...data };
    return new GeneratedPromotionList({
      items: params.items && ImmutableList(params.items.map((item: JSObject) => GeneratedPromotion.fromJSON(item))),
      contentsFiltered: params.contents_field,
    });
  }
}
