import React, { useCallback, useEffect, useState } from 'react';

import { List as ImmutableList, Set } from 'immutable';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { GroupStoreSelect } from 'components/organisms/GroupStoreSelect';
import { PromotionStore } from 'models/Domain/Promotion/Promotion';
import { Stores } from 'models/Domain/Store';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { Group } from 'types/Common';

type Props = {
  className?: string;
  stores: Stores;
  canNotPostGmbPromotionStores: ImmutableList<PromotionStore>;
  selectedStoreIds: ImmutableList<number>;
  setSelectedStoreIds: (v: number[]) => void;
  setSelectedStoreList?: (v: number[]) => void;
  error?: string;
};

type GroupStoreSelectErrors = {
  group?: string;
  store?: string;
} | null;

export const PromotionFormSelectStores: React.FC<Props> = ({
  className,
  stores,
  canNotPostGmbPromotionStores,
  selectedStoreIds,
  setSelectedStoreIds,
  error,
}) => {
  const [selectedStoreList, setSelectedStoreList] = useState<Group>('all');
  const handleOnChangeSelectedStore = useCallback(
    (group: Group, storeIds: Set<number>) => {
      setSelectedStoreIds(storeIds.toArray());
      setSelectedStoreList(group);
    },
    [setSelectedStoreIds, setSelectedStoreList],
  );
  const [errors, setErrors] = useState<GroupStoreSelectErrors>(typeof error === 'string' ? { store: error } : null);

  useEffect(() => {
    if (typeof error === 'string') {
      setErrors({ store: error });
    } else {
      setErrors(null);
    }
  }, [error]);

  return (
    <Wrapper className={className}>
      <GroupStoreSelect
        group={selectedStoreList}
        storeIds={Set(selectedStoreIds)}
        showClosedStores={false}
        showGbpConnectedStoresOnly={true}
        showClosedStoreCheckbox={false}
        showMyStoreOnly={true}
        sizeVariant={'large'}
        onChange={handleOnChangeSelectedStore}
        errors={errors}
      />

      {canNotPostGmbPromotionStores.size > 0 && (
        <div>
          <GmbErrorTitle>以下の店舗はGoogleビジネスプロフィール側の原因により投稿対象にできません</GmbErrorTitle>
          {canNotPostGmbPromotionStores.map((t, idx) => {
            const targetStore = stores.findStore(t.store_id);
            return (
              <GmbErrorTextLink
                to={Path.store.detail.replace(':storeId', String(t.store_id))}
                key={idx}
                target='_blank'
              >
                {targetStore?.fullName}
              </GmbErrorTextLink>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&& {
    > div {
      padding-top: 0;
    }
  }
`;

const GmbErrorTitle = styled.div`
  color: ${COLOR.ERROR};
  font-size: 12px;
`;

const GmbErrorTextLink = styled(Link)`
  font-size: 14px;
  text-decoration: underline;
  &:not(:last-of-type) {
    &:after {
      margin-right: 4px;
      content: ',';
    }
  }
  &:hover {
    text-decoration: underline;
  }
`;
