import React, { useCallback, useEffect, useMemo } from 'react';

import { goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { MenuEditForm } from 'components/pageComponents/Menu/MenuEditForm';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { Menu } from 'models/Domain/Menu/Menu';
import { MenuActions } from 'modules/menu/actions';

export const MenuCreate: React.FC = () => {
  const dispatch = useDispatch();
  const { initializeCreatePage, createMenu } = useMemo(() => bindActionCreators(MenuActions, dispatch), [dispatch]);
  const { menu } = useSelector((state) => state.menu);

  // 複製元のメニューIDがあれば取得
  const location = useLocation<{ baseId: number }>();
  const baseMenuId = location.state?.baseId;

  const handleOnSave = useCallback(
    (menu: Menu) => {
      createMenu(menu);
    },
    [createMenu],
  );

  const handleOnCancel = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  useEffect(() => {
    // baseMenuIdがあれば複製元のメニューを取得して初期値として設定
    initializeCreatePage(baseMenuId);
  }, [baseMenuId, initializeCreatePage]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('メニューグループの新規作成')} />
      <StickyHeader>
        <Title>メニューグループの新規作成</Title>
      </StickyHeader>
      <WideBody>
        <MenuEditForm menu={menu} onSave={handleOnSave} onCancel={handleOnCancel} />
      </WideBody>
    </MainWrapper>
  );
};
