import React, { useCallback, useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { Icon as SemanticUIIcon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { Icon } from 'components/atoms/Icon';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { CompetitorHelp as Help } from 'helpers/ContextHelp';
import { Competitor } from 'models/Domain/Competitor/Competitor';
import { GoogleLocation } from 'models/Domain/GoogleLocation';
import { COLOR } from 'style/color';

import { TagEditModal } from '../Competitor/TagEditModal';

import { LocalPostTable, PhotoTable, ReviewTable } from './CompetitorDetailTable';
import { StoreItemCard } from './StoreItemCard';

type Props = {
  competitor: Competitor;
  onChange: (id: number, value: Competitor) => void;
  onDelete: () => void;
};

export const CompetitorDetailContents = React.memo<Props>(({ competitor, onChange, onDelete }) => {
  const { googleLocation } = competitor;
  const mapUrl = googleLocation?.mapUrl;
  const knowledgePanelUrl = googleLocation?.knowledgePanelUrl;
  const location = googleLocation?.location;
  const attribute = googleLocation?.attribute;
  const service = googleLocation?.service;
  const product = googleLocation?.product;
  const photo = googleLocation?.photo;
  const localPost = googleLocation?.localPost;
  const review = googleLocation?.review;
  const lastUpdate = googleLocation?.timestamp;
  const [showTagEditModal, setShowTagEditModal] = useState(false);

  const showHeader = !!lastUpdate || !mapUrl || !!knowledgePanelUrl;

  const handleOnChangeTags = useCallback(
    (id: number, tags: ImmutableList<string>) => {
      const newCompetitor = competitor.set('tags', tags);
      onChange(id, newCompetitor);
      setShowTagEditModal(false);
    },
    [competitor, onChange],
  );

  const handleOnDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <Wrapper>
      {showHeader && (
        <Header>
          <LastUpdate>{lastUpdate && `最終データ取得日時：${lastUpdate.format('YYYY/MM/DD HH:mm:ss')}`}</LastUpdate>
          <UrlWrapper>
            {mapUrl && <ExternalLink href={mapUrl}>Googleマップを表示</ExternalLink>}
            {knowledgePanelUrl && <ExternalLink href={knowledgePanelUrl}>ナレッジパネルを表示</ExternalLink>}
          </UrlWrapper>
        </Header>
      )}
      <HeadingWrapper>
        <Heading>店舗情報</Heading>
      </HeadingWrapper>
      <LocationContents
        storeName={competitor.name}
        location={location}
        attribute={attribute}
        service={service}
        product={product}
      />
      <TagContents tags={competitor.tags} onClick={() => setShowTagEditModal(true)} />
      <HeadingWrapper>
        <Heading>
          GBP運用状況
          <ContextHelp content={Help.hasMore} />
        </Heading>
      </HeadingWrapper>
      <PhotoTable data={photo} />
      <LocalPostTable data={localPost} />
      <ReviewTable data={review} />
      <DeleteButtonWrapper>
        <Button priority={'low'} negative={true} onClick={handleOnDelete}>
          <SemanticUIIcon name={'trash alternate'} />
          競合店舗を削除する
        </Button>
      </DeleteButtonWrapper>
      <TagEditModal
        isOpen={showTagEditModal}
        competitor={competitor}
        onClose={() => setShowTagEditModal(false)}
        onSave={handleOnChangeTags}
      />
    </Wrapper>
  );
});

type LocationProps = {
  storeName: string;
  location?: GoogleLocation['location'];
  attribute?: GoogleLocation['attribute'];
  service?: GoogleLocation['service'];
  product?: GoogleLocation['product'];
};

const LocationContents = React.memo<LocationProps>(({ storeName, location, attribute, service, product }) => {
  const [showDetail, setShowDetail] = useState(false);
  if (!location) {
    return (
      <StoreItemCardWrapper>
        <StoreItemCard label={'店舗名'}>{storeName}</StoreItemCard>
      </StoreItemCardWrapper>
    );
  }
  const {
    name,
    category,
    subCategories,
    openStatus,
    address,
    businessHours,
    moreHours,
    phoneNumber,
    websiteUrl,
    menuLink,
    reservationLinks,
  } = location;
  return (
    <StoreItemCardWrapper>
      <StoreItemCard label={'店舗名'}>{name}</StoreItemCard>
      <StoreItemCard label={'カテゴリー'}>
        <SubLabel>メインカテゴリー</SubLabel>
        <div>{category}</div>
        <SubLabel>サブカテゴリー</SubLabel>
        {subCategories?.map((subCategory, index) => <div key={index}>{subCategory}</div>)}
      </StoreItemCard>
      {showDetail && (
        <React.Fragment>
          {openStatus && (
            <StoreItemCard label={'営業ステータス'}>
              <div>{openStatus}</div>
            </StoreItemCard>
          )}
          {address && (
            <StoreItemCard label={'所在地'}>
              <div>{address}</div>
            </StoreItemCard>
          )}
          {businessHours && (
            <StoreItemCard label={'営業時間'}>
              {businessHours.businessHoursForDisplay.map((value, index) => {
                return <div key={index}>{value}</div>;
              })}
            </StoreItemCard>
          )}
          {moreHours && (
            <StoreItemCard label={'その他の営業時間'}>
              {moreHours.keySeq().map((name) => {
                const businessHours = moreHours.get(name);
                return (
                  <MoreHoursWrapper key={name}>
                    <MoreHoursLabel>{name}</MoreHoursLabel>
                    {businessHours?.businessHoursForDisplay.map((value, index) => {
                      return <div key={index}>{value}</div>;
                    })}
                  </MoreHoursWrapper>
                );
              })}
            </StoreItemCard>
          )}
          {phoneNumber && (
            <StoreItemCard label={'電話番号'}>
              <div>{phoneNumber}</div>
            </StoreItemCard>
          )}
          {websiteUrl && (
            <StoreItemCard label={'ウェブサイト'}>
              <StyledExternalLink href={websiteUrl} target={'_blank'}>
                <Url>{websiteUrl}</Url>
              </StyledExternalLink>
            </StoreItemCard>
          )}
          {reservationLinks && (
            <StoreItemCard label={'予約リンク'}>
              {reservationLinks.map((url, index) => (
                <div key={index}>
                  <StyledExternalLink href={url} target={'_blank'}>
                    <Url>{url}</Url>
                  </StyledExternalLink>
                </div>
              ))}
            </StoreItemCard>
          )}
          {menuLink && (
            <StoreItemCard label={'メニューリンク'}>
              <StyledExternalLink href={menuLink} target={'_blank'}>
                <Url>{menuLink}</Url>
              </StyledExternalLink>
            </StoreItemCard>
          )}
          {attribute && (
            <StoreItemCard label={'属性'}>
              {attribute.groups.map((group, index) => {
                return (
                  <GroupWrapper key={index}>
                    <GroupLabel>{group.name}</GroupLabel>
                    {group.items.map((item, i) => {
                      return (
                        <div key={i}>
                          <AttributeIcon
                            type={item.isAvailable == null ? 'dash' : item.isAvailable ? 'check' : 'cancel_red'}
                          />
                          <InvisibleText>
                            {item.isAvailable == null ? '-' : item.isAvailable ? '✔︎ ' : '×'}
                          </InvisibleText>
                          {item.name}
                        </div>
                      );
                    })}
                  </GroupWrapper>
                );
              })}
            </StoreItemCard>
          )}
          {service && (
            <StoreItemCard label={'サービス'}>
              {service.groups.map((group, index) => {
                return (
                  <GroupWrapper key={index}>
                    <GroupLabel>{group.name}</GroupLabel>
                    {group.items.map((item, i) => {
                      return <div key={i}>{`${item.name}${item.price ? ` (${item.price})` : ''}`}</div>;
                    })}
                  </GroupWrapper>
                );
              })}
            </StoreItemCard>
          )}
          {product && (
            <>
              <StoreItemCard label={'GBP商品情報'}>{product.hasGbpProducts ? 'あり' : 'なし'}</StoreItemCard>
              <StoreItemCard label={'GMC商品情報'}>{product.hasGmcProducts ? 'あり' : 'なし'}</StoreItemCard>
            </>
          )}
        </React.Fragment>
      )}
      <StoreItemCard onClick={() => setShowDetail((value) => !value)}>
        <ButtonWrapper>
          <Button priority={'low'}>{showDetail ? '詳細を閉じる' : 'もっと見る'}</Button>
        </ButtonWrapper>
      </StoreItemCard>
    </StoreItemCardWrapper>
  );
});

type TagProps = {
  tags: Competitor['tags'];
  onClick: () => void;
};

const TagContents = React.memo<TagProps>(({ tags, onClick }) => {
  return (
    <StoreItemCardWrapper>
      <StoreItemCard label={'タグ'} showEditButton={true} onClick={onClick}>
        {tags.map((tag) => (
          <Tag key={tag}>
            <SemanticUIIcon name='tag' color='grey' />
            {tag}
          </Tag>
        ))}
        {tags.isEmpty() && <div>タグが設定されていません</div>}
      </StoreItemCard>
    </StoreItemCardWrapper>
  );
});

const Wrapper = styled.div``;

const StoreItemCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  place-content: center;
`;

const SubLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${COLOR.GRAY};
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;

const MoreHoursWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const MoreHoursLabel = styled.div`
  font-weight: bold;
`;

const GroupWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const GroupLabel = styled.div`
  font-weight: bold;
`;

const StyledExternalLink = styled(ExternalLink)`
  :link,
  :active,
  :visited {
    text-decoration: none;
  }
  :hover {
    text-decoration: underline ${COLOR.BLACK};
  }
`;

const Url = styled.span`
  color: ${COLOR.BLACK};
  margin-right: 4px;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const LastUpdate = styled.div`
  color: ${COLOR.DARK_GRAY};
`;

const UrlWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const AttributeIcon = styled(Icon)`
  padding: 0 2px 2px 0;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
`;

const InvisibleText = styled.span`
  font-size: 0;
  color: transparent;
`;

const Tag = styled.div`
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  i {
    text-decoration: none;
  }
`;

const DeleteButtonWrapper = styled.div`
  display: grid;
  place-content: center;
  margin-top: 32px;
`;
