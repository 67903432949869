import ApiClient from 'ApiClient';
import { JSObject } from 'types/Common';
import { CommentListPostResponse } from 'types/responses/Check';
import { OfferLatestActivityGetResponse } from 'types/responses/Offer';

const apiClient = new ApiClient({});

const OFFER_PATH = '/offers';

const OFFER_LATEST_PATH = `${OFFER_PATH}/latest`;
const OFFER_DETAIL_PATH = (offerId: number) => `${OFFER_PATH}/${String(offerId)}`;
const OFFER_TIMELINE_PATH = (offerId: number) => `${OFFER_PATH}/${String(offerId)}/timeline`;
const OFFER_DONE_PATH = (offerId: number) => `${OFFER_PATH}/${String(offerId)}/done`;
const OFFER_COMMENT_PATH = (offerId: number) => `${OFFER_PATH}/${String(offerId)}/comments`;
const OFFER_STAMP_PATH = (offerId: number) => `${OFFER_PATH}/${String(offerId)}/stamp`;
const OFFER_IMAGE_COMMENT_PATH = (offerId: number) => `${OFFER_PATH}/${String(offerId)}/image_comment`;
const OFFER_READ_PATH = (offerId: number) => `${OFFER_PATH}/${String(offerId)}/read`;
const OFFER_STORE_PATH = (storeId?: number) => `${OFFER_PATH}/stores${storeId ? `/${String(storeId)}` : ''}`;
const OFFER_STORE_DETAIL_PATH = (storeId: number) => `${OFFER_PATH}/stores/${String(storeId)}`;

// FIXME: レスポンスの中身
export type OfferApiResponse = JSObject;

export class OfferApi {
  static get(offerId: number) {
    return apiClient.get<OfferApiResponse>(OFFER_DETAIL_PATH(offerId));
  }

  static put(offerId: number, params: JSObject) {
    return apiClient.put(OFFER_DETAIL_PATH(offerId), params);
  }
}

// FIXME: レスポンスの中身
export type OfferTimelineApiResponse = JSObject[];

export class OfferTimelineApi {
  static get(offerId: number) {
    return apiClient.get<OfferTimelineApiResponse>(OFFER_TIMELINE_PATH(offerId));
  }
}

export class OfferDoneApi {
  static post(commentId: number) {
    return apiClient.post(OFFER_DONE_PATH(commentId));
  }
}

export class OfferCommentApi {
  static post(commentId: number, params: JSObject) {
    return apiClient.post<CommentListPostResponse>(OFFER_COMMENT_PATH(commentId), params);
  }
}

export class OfferStampApi {
  static post(offerId: number, params: JSObject) {
    return apiClient.post(OFFER_STAMP_PATH(offerId), params);
  }
}

export class OfferImageCommentApi {
  static post(offerId: number, params: JSObject) {
    return apiClient.post(OFFER_IMAGE_COMMENT_PATH(offerId), params);
  }
}

export class OfferLatestApi {
  static get(params: JSObject = {}) {
    return apiClient.get<OfferLatestActivityGetResponse>(OFFER_LATEST_PATH, params);
  }
}

export class OfferReadApi {
  static post(offerId: number) {
    return apiClient.post(OFFER_READ_PATH(offerId));
  }
}

// FIXME: レスポンスの中身
export type OfferStoreApiResponse = JSObject[];

export class OfferStoreApi {
  static get(params?: JSObject, storeId?: number) {
    return apiClient.get<OfferStoreApiResponse>(OFFER_STORE_PATH(storeId), params);
  }

  static post(storeId: number, params: JSObject) {
    return apiClient.post(OFFER_STORE_DETAIL_PATH(storeId), params);
  }
}
