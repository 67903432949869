import React, { useCallback, useState } from 'react';

import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { Arrow } from 'components/atoms/Arrow';
import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { Icon } from 'components/atoms/Icon';
import { MenuItem, MenuSection } from 'models/Domain/Menu/Menu';
import { COLOR } from 'style/color';

import { Input } from '../../atoms/Input';

import { MenuItemModal } from './MenuItemModal';
import { MenuSectionModal } from './MenuSectionModal';

type Props = {
  menuSection: MenuSection;
  onDelete: () => void;
  onChange: (menuSection: MenuSection) => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  showMoveUp: boolean;
  showMoveDown: boolean;
  showErrorMessage: boolean;
};

type HeaderProps = {
  section: MenuSection;
  onChange: (section: MenuSection) => void;
  onDelete: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  showItems: boolean;
  toggleShowItems: () => void;
  showMoveUp: boolean;
  showMoveDown: boolean;
  itemSize: number;
};

const Header: React.FC<HeaderProps> = ({
  section,
  showMoveUp,
  showMoveDown,
  onDelete,
  onChange,
  onMoveUp,
  onMoveDown,
  showItems,
  toggleShowItems,
  itemSize,
}) => {
  const [showSectionModal, setShowSectionModal] = useState<boolean>(false);
  return (
    <StyledCard>
      <FlexWrapper>
        <ArrowContainer onClick={toggleShowItems}>
          <Arrow direction={showItems ? 'down' : 'right'} color={'#393939'} length={16} weight={3} />
        </ArrowContainer>
        <SectionName onClick={() => setShowSectionModal(true)}>{section.name}</SectionName>
        <ItemSize error={itemSize === 0}>アイテム数：{itemSize}件</ItemSize>
        <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
          <Dropdown.Menu direction={'left'}>
            <ActionMenuItem onClick={() => setShowSectionModal(true)}>
              <ActionMenuLabel>編集</ActionMenuLabel>
            </ActionMenuItem>
            {showMoveUp && (
              <ActionMenuItem onClick={onMoveUp}>
                <ActionMenuLabel>上に移動</ActionMenuLabel>
              </ActionMenuItem>
            )}
            {showMoveDown && (
              <ActionMenuItem onClick={onMoveDown}>
                <ActionMenuLabel>下に移動</ActionMenuLabel>
              </ActionMenuItem>
            )}
            <ActionMenuItem onClick={onDelete}>
              <ActionMenuLabel>削除</ActionMenuLabel>
            </ActionMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </FlexWrapper>
      {showSectionModal && (
        <MenuSectionModal
          isOpen={true}
          onClose={() => setShowSectionModal(false)}
          section={section}
          onChange={onChange}
          onDelete={onDelete}
          isEdit={true}
        />
      )}
    </StyledCard>
  );
};

type ItemProps = {
  item: MenuItem;
  onDelete: () => void;
  onChange: (item: MenuItem) => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  showMoveUp: boolean;
  showMoveDown: boolean;
};

const Item: React.FC<ItemProps> = ({ item, onDelete, onChange, onMoveUp, onMoveDown, showMoveUp, showMoveDown }) => {
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  return (
    <StyledCard>
      <FlexWrapper>
        <ItemInfoContainer onClick={() => setShowItemModal(true)}>
          <ItemTextContainer>
            <ItemName>{item.name}</ItemName>
            {item.description != null && <ItemDescription>{item.description}</ItemDescription>}
            {item.displayDietaryRestriction != null && (
              <ItemDietaryRestriction>{item.displayDietaryRestriction}</ItemDietaryRestriction>
            )}
            {item.price != null && <ItemPrice>{item.price.toLocaleString()}</ItemPrice>}
          </ItemTextContainer>
          {item.imageUrl && (
            <ItemImageContainer onClick={() => setShowItemModal(true)}>
              <ImageWrapper>
                <ItemImage src={item.imageUrl} />
              </ImageWrapper>
            </ItemImageContainer>
          )}
        </ItemInfoContainer>
        <ItemMenuContainer>
          <Dropdown trigger={<Trigger>・・・</Trigger>} icon={null}>
            <Dropdown.Menu direction={'left'}>
              <ActionMenuItem onClick={() => setShowItemModal(true)}>
                <ActionMenuLabel>編集</ActionMenuLabel>
              </ActionMenuItem>
              {showMoveUp && (
                <ActionMenuItem onClick={onMoveUp}>
                  <ActionMenuLabel>上に移動</ActionMenuLabel>
                </ActionMenuItem>
              )}
              {showMoveDown && (
                <ActionMenuItem onClick={onMoveDown}>
                  <ActionMenuLabel>下に移動</ActionMenuLabel>
                </ActionMenuItem>
              )}
              <ActionMenuItem onClick={onDelete}>
                <ActionMenuLabel>削除</ActionMenuLabel>
              </ActionMenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </ItemMenuContainer>
      </FlexWrapper>
      {showItemModal && (
        <MenuItemModal
          isOpen={true}
          onClose={() => setShowItemModal(false)}
          item={item}
          onChange={onChange}
          onDelete={onDelete}
          isEdit={true}
        />
      )}
    </StyledCard>
  );
};

export const MenuSectionTable: React.FC<Props> = ({
  menuSection,
  onDelete,
  onChange,
  onMoveUp,
  onMoveDown,
  showMoveUp,
  showMoveDown,
  showErrorMessage,
}) => {
  const [showItems, setShowItems] = useState<boolean>(true);
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const toggleShowItems = useCallback(() => {
    setShowItems((value) => !value);
  }, []);

  const handleOnAddItem = useCallback(
    (item) => {
      const newMenuSection = menuSection.update('items', (items) => items.push(item));
      onChange(newMenuSection);
    },
    [menuSection, onChange],
  );
  const handleOnChangeItem = useCallback(
    (index: number) => (item: MenuItem) => {
      const newMenuSection = menuSection.update('items', (items) => items.set(index, item));
      onChange(newMenuSection);
    },
    [menuSection, onChange],
  );

  const handleOnDeleteItem = useCallback(
    (index: number) => () => {
      const newMenuSection = menuSection.update('items', (items) => items.remove(index));
      onChange(newMenuSection);
    },
    [menuSection, onChange],
  );

  const handleOnChangeItemOrder = useCallback(
    (index: number, newIndex: number) => () => {
      const newMenuSection = menuSection.update('items', (items) => {
        const item = items.get(index)!;
        return items.remove(index).insert(newIndex, item);
      });
      onChange(newMenuSection);
    },
    [menuSection, onChange],
  );

  return (
    <Wrapper>
      <Header
        section={menuSection}
        showItems={showItems}
        onChange={onChange}
        onDelete={onDelete}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
        showMoveUp={showMoveUp}
        showMoveDown={showMoveDown}
        toggleShowItems={toggleShowItems}
        itemSize={menuSection.items.size}
      />
      {showItems &&
        menuSection.items.map((item, index) => (
          <Item
            key={index}
            item={item}
            onChange={handleOnChangeItem(index)}
            onDelete={handleOnDeleteItem(index)}
            onMoveUp={handleOnChangeItemOrder(index, index - 1)}
            onMoveDown={handleOnChangeItemOrder(index, index + 1)}
            showMoveUp={index !== 0}
            showMoveDown={index !== menuSection.items.size - 1}
          />
        ))}
      {showItems && (
        <AddButton onClick={() => setShowItemModal(true)}>
          <AddIcon />
          アイテムを追加する
        </AddButton>
      )}
      {showErrorMessage && menuSection.items.size === 0 && (
        <DummyInput error={'セクションには１つ以上のアイテムを設定してください'} />
      )}
      {showItemModal && (
        <MenuItemModal
          isOpen={true}
          onClose={() => setShowItemModal(false)}
          item={new MenuItem()}
          onChange={handleOnAddItem}
          isEdit={false}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-bottom: 1px solid ${COLOR.LIGHT_GRAY};
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledCard = styled(Card)`
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

const SectionName = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Trigger = styled.div`
  cursor: pointer;
  letter-spacing: -10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${COLOR.GREEN};
  margin-top: -8px;
  transform: rotate(90deg);
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY} !important;
    &:first-of-type {
      border-top: none !important;
    }
  }
`;

const ActionMenuLabel = styled.div`
  padding: 10px;
`;

const ItemName = styled.div`
  font-size: 16px;
`;
const ItemDescription = styled.div`
  color: ${COLOR.DARK_GRAY};
`;
const ItemDietaryRestriction = styled.div`
  font-size: 12px;
  color: ${COLOR.GRAY};
`;
const ItemPrice = styled.div`
  font-weight: bold;

  &:before {
    content: '¥';
  }
`;

const ImageWrapper = styled.div`
  width: 92px;
  height: 92px;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const ItemInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  cursor: pointer;
`;

const ItemTextContainer = styled.div`
  flex: 1;
`;

const ItemImageContainer = styled.div``;

const ItemMenuContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const AddButton = styled(Button).attrs({ priority: 'low' })`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
`;

const AddIcon = styled(Icon).attrs({ type: 'add' })`
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const ItemSize = styled.div<{ error: boolean }>`
  font-size: 14px;
  color: ${({ error }) => (error ? COLOR.ERROR : COLOR.DARK_GRAY)};
`;

const DummyInput = styled(Input)`
  &&& {
    input {
      display: none;
    }
  }
`;
