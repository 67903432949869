import React, { useCallback, useMemo, useState } from 'react';

import { Set as ImmutableSet } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { SearchVolumeCSVDownloadButton } from 'components/pageComponents/SearchVolume/SearchVolumeCSVDownloadButton';
import { SearchVolumeSearchResult } from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import { COLOR } from 'style/color';

import { SearchVolumeDetailGraph } from './SearchVolumeDetailGraph';
import { SearchVolumeDetailTable } from './SearchVolumeDetailTable';

type Props = {
  className?: string;
  isOpen: boolean;
  searchResult: SearchVolumeSearchResult;
  executionId: string;
  onClose: () => void;
};

export const SearchVolumeDetailModal = React.memo<Props>(({ isOpen, searchResult, executionId, onClose }) => {
  const [selectedKeywords, setSelectedKeywords] = useState<ImmutableSet<string>>(
    ImmutableSet(searchResult.item?.keyword ? [searchResult.item.keyword] : []),
  );

  const {
    condition: {
      searchWord,
      period: { startMonth, endMonth },
    },
    geoTarget,
  } = searchResult;

  const periodText = useMemo(() => {
    const start = startMonth.format();
    const end = endMonth.format();
    return start === end ? `${start}` : `${start}〜${end}`;
  }, [startMonth, endMonth]);

  const handleOnSelectKeyword = useCallback((value: string) => {
    setSelectedKeywords((selectedKeywords) =>
      selectedKeywords.has(value) ? selectedKeywords.delete(value) : selectedKeywords.add(value),
    );
  }, []);

  return (
    <StyledModal open={isOpen} size={'fullscreen'}>
      <ModalContent>
        <Wrapper>
          <Title>
            {searchWord.name}
            <AreaNameWrapper>
              <AreaIcon type={'pin_gray'} />
              {geoTarget.canonicalName}
            </AreaNameWrapper>
          </Title>
          <ContentWrapper>
            <Content>
              <Header>
                検索ボリューム<Period>{periodText}</Period>
                <StyledSearchVolumeCSVDownloadButton
                  condition={{
                    execution_id: executionId,
                    // execution_id単位のCSVは、他の検索ワード・検索地点のデータが含まれるので、
                    // モーダルで表示している条件で絞り込む（関連キーワードを含める）
                    contains_related_keywords: true,
                    filter: {
                      areas: [searchResult.condition.area.areaName ?? ''],
                      search_words: [searchResult.condition.searchWord.name],
                    },
                  }}
                />
              </Header>
              <SearchVolumeDetailGraph
                searchResult={searchResult}
                selectedKeywords={selectedKeywords}
                onClickLegend={handleOnSelectKeyword}
              />
              <Header>検索キーワード</Header>
              <SearchVolumeDetailTable
                searchResult={searchResult}
                items={searchResult.items.slice(0, 1)}
                onSelectKeyword={handleOnSelectKeyword}
                selectedKeywords={selectedKeywords}
                showSort={false}
              />
            </Content>
            <Content>
              <Header>
                関連キーワード
                <CountText>{`${searchResult.relatedItems.size.toLocaleString()}件の検索結果`}</CountText>
              </Header>
              <SearchVolumeDetailTable
                searchResult={searchResult}
                items={searchResult.relatedItems}
                onSelectKeyword={handleOnSelectKeyword}
                selectedKeywords={selectedKeywords}
                showSort={true}
              />
            </Content>
          </ContentWrapper>
          <ModalFooter>
            <StyledButton onClick={onClose}>閉じる</StyledButton>
          </ModalFooter>
        </Wrapper>
      </ModalContent>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
  height: 80%;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 32px;
`;

const AreaNameWrapper = styled.span`
  margin-left: 8px;
  font-size: 18px;
  color: ${COLOR.GRAY};
`;

const AreaIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  padding: 0;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  display: flex;
  align-items: baseline;
`;

const Period = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-left: 16px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
  margin-left: auto;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const CountText = styled.span`
  color: ${COLOR.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  margin-left: 16px;
`;

const StyledSearchVolumeCSVDownloadButton = styled(SearchVolumeCSVDownloadButton)`
  margin-left: auto;
  font-size: 14px;
`;
