import React from 'react';

import { List, Set } from 'immutable';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { Redirect } from 'components/atoms/Link';
import { OrganizationFeature } from 'models/Domain/Organization';
import { UserRole } from 'models/Domain/User';
import { State } from 'modules/reducers';

type AuthRouteProps = {
  roles?: UserRole[];
  features?: OrganizationFeature[];
} & React.ComponentProps<typeof Route>;

/**
 * 権限によって表示可否を制御可能なRoute
 * 権限外の場合、トップへリダイレクトする
 * @param roles 画面を表示可能なユーザーの権限の配列
 * @param features 組織が利用可能な機能の配列
 * @param path  画面のパス
 */
export const AuthRoute: React.FC<AuthRouteProps> = ({ roles = [], features = [], ...props }) => {
  const currentUser = useSelector((state: State) => state.app.currentUser);

  const redirectToTop = (from: string | string[] | undefined) => {
    return typeof from === 'string' ? <Redirect from={from} to={'/'} /> : null;
  };

  // roleによる制御がある場合、ユーザのロールが含まれていなければトップに戻る
  if (roles.length > 0 && !roles.includes(currentUser.role)) {
    return redirectToTop(props.path);
  }

  // featuresによる制御がある場合、組織の利用可能な機能に含まれていなければトップに戻る
  // Adminユーザーの場合は、組織に対する機能の利用可否の設定に関わらず、利用可能とする
  if (
    currentUser.role !== UserRole.Admin &&
    features.length > 0 &&
    !Set(features).isSubset(currentUser.organization?.features.enabledFeatures ?? List())
  ) {
    return redirectToTop(props.path);
  }

  return <Route {...props} />;
};
