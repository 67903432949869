import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { List, Set } from 'immutable';
import { useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { Checkbox, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';

import { MapSearchRanksCompetitorsApi } from 'ApiClient/GmbApi';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import {
  AggregateDateRangePicker,
  DateRangeOptionValue,
  getComparisonDateRange,
  getComparisonDateRangeOption,
  getDateRangeFromDateRangeOption,
  getDateRangeOption,
} from 'components/molecules/AggregateDateRangePicker';
import { AggregateUnitGroup } from 'components/molecules/AggregateUnitGroup';
import { CompetitorRegisterModal } from 'components/organisms/CompetitorRegisterModal';
import { AnalysisMemoTable } from 'components/pageComponents/Analysis/AnalysisMemoTable';
import { MapSearchRankCompetitorGraph } from 'components/pageComponents/MapSearchRank/MapSearchRankCompetitorGraph';
import { MapSearchRankCompetitorTable } from 'components/pageComponents/MapSearchRank/MapSearchRankCompetitorTable';
import { MemoDisplaySettings } from 'helpers/graph';
import { useStorage } from 'hooks/useStorage';
import { Competitor } from 'models/Domain/Competitor/Competitor';
import {
  MapSearchRankCompetitorData,
  MapSearchRankCompetitorSearchCondition,
} from 'models/Domain/MapSearchRank/MapSearchRankCompetitor';
import { MemoList, Memo as MemoModel, MemoTagList } from 'models/Domain/Memo/Memo';
import { Store } from 'models/Domain/Store';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';
import { AggregateUnit } from 'types/Common';

import { ComparisonDateRangeOptionValue, dateRangeOptions } from './MapSearchRankSearchCondition';

type Props = {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  configId: number;
  storeId: number;
  storeName: string;
  areaName: string;
  searchWord: string;
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  isEnabledComparison: boolean;
  memoList: MemoList; // 施策メモ
  memoTagList: MemoTagList; // 選択可能なタグの一覧
  memoDisplaySettings: MemoDisplaySettings; // メモの表示設定
  setMemoDisplaySettings: (setting: MemoDisplaySettings) => void; // メモの表示設定が変更された
  onCreateMemo: (memo: MemoModel) => void; // メモの保存ボタンが押された (新規作成)
  onUpdateMemo: (memo: MemoModel) => void; // メモの保存ボタンが押された (更新)
  onDeleteMemo: (memo: MemoModel) => void; // メモの削除ボタンが押された
  isLoadingMemoList: boolean; // 施策メモを読み込み中か
  isLoadingMemoTagList: boolean; // 選択可能なタグを読み込み中か
};

// 集計期間に指定可能な最も新しい日付（前日）
const maxDate = dayjs().startOf('day').subtract(1, 'day');

const fetchCompetitorData = async (searchCondition: MapSearchRankCompetitorSearchCondition) => {
  const response = await MapSearchRanksCompetitorsApi.get(searchCondition.toRequestParams());
  if (response.isSuccess) {
    return MapSearchRankCompetitorData.fromJSON(response.data);
  }
};

const useCompetitorData = (
  searchCondition: MapSearchRankCompetitorSearchCondition,
): { competitorData: MapSearchRankCompetitorData | undefined; isLoading: boolean } => {
  const { data, error } = useSWRImmutable([searchCondition], fetchCompetitorData);
  return { competitorData: data, isLoading: !data && !error };
};

export const MapSearchRankCompetitorModal = React.memo<Props>(
  ({
    className,
    isOpen,
    onClose,
    configId,
    storeId,
    storeName,
    areaName,
    searchWord,
    aggregateUnit: defaultAggregateUnit,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    comparisonStartDate: defaultComparisonStartDate,
    comparisonEndDate: defaultComparisonEndDate,
    isEnabledComparison: defaultIsEnabledComparison,
    memoList,
    memoTagList,
    memoDisplaySettings,
    setMemoDisplaySettings,
    onCreateMemo,
    onUpdateMemo,
    onDeleteMemo,
    isLoadingMemoList,
    isLoadingMemoTagList,
  }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    // 選択している店舗のリスト（色付けの関係で選択解除されたとこにはnullが入る）
    const [selectedStoreList, setSelectedStoreList] = useState<List<string | null>>(List([storeName]));
    // 選択している店舗のセット
    const selectedStores = (selectedStoreList.filter((placeId) => placeId) as List<string>).toSet();
    // 選択中の施策メモID
    const [selectingMemoIds, setSelectingMemoIds] = useState<Set<number>>(Set());

    const [searchConditionContainsAll, setSearchConditionContainsAll] = useStorage(
      'MAP_SEARCH_RANK_COMPETITOR_CONTAINS_ALL',
      false,
    );

    const [committedSearchCondition, setCommittedSearchCondition] = useState(
      MapSearchRankCompetitorSearchCondition.fromJSON({
        aggregateUnit: defaultAggregateUnit,
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        comparisonStartDate: defaultComparisonStartDate,
        comparisonEndDate: defaultComparisonEndDate,
        isEnabledComparison: defaultIsEnabledComparison,
        configId,
        containsAll: searchConditionContainsAll,
      }),
    );
    const [searchCondition, setSearchCondition] = useState(committedSearchCondition);

    const { stores } = useSelector((state: State) => state.store);
    const { currentUser } = useSelector((state: State) => state.app);
    const canRegisterCompetitor = currentUser.canRegisterCompetitor;

    const { aggregateUnit, startDate, endDate, comparisonStartDate, comparisonEndDate, isEnabledComparison } =
      searchCondition.filter;

    const {
      aggregateUnit: committedAggregateUnit,
      startDate: committedStartDate,
      endDate: committedEndDate,
      comparisonStartDate: committedComparisonStartDate,
      comparisonEndDate: committedComparisonEndDate,
      isEnabledComparison: committedIsEnabledComparison,
    } = committedSearchCondition.filter;

    const { competitorData, isLoading } = useCompetitorData(committedSearchCondition);

    useEffect(() => {
      // モーダルを開いたら全店舗を選択状態にするため、初回のcompetitorData取得時に選択済み店舗として設定する
      if (competitorData && !isInitialized) {
        setSelectedStoreList(
          competitorData.tableData.items
            .filter((item) => item.isCompetitor)
            .map((item) => item.placeId)
            .toList(),
        );
        setIsInitialized(true);
      }
    }, [competitorData, isInitialized]);

    // テーブルデータをの順番を基準に色を割り当てる（自店舗は#05ccad）
    const storeColors = useMemo(() => {
      return competitorData?.storeColors(stores, selectedStoreList) ?? {};
    }, [competitorData, selectedStoreList, stores]);

    const [dateRangeOption, setDateRangeOption] = useState<DateRangeOptionValue>(() =>
      getDateRangeOption(dateRangeOptions[aggregateUnit], startDate, endDate, maxDate),
    );
    const [comparisonDateRangeOption, setComparisonDateRangeOption] = useState<ComparisonDateRangeOptionValue>(() =>
      isEnabledComparison && comparisonStartDate && comparisonEndDate
        ? getComparisonDateRangeOption(dateRangeOption, startDate, endDate, comparisonStartDate, comparisonEndDate)
        : '前の期間',
    );

    const aggregateDurationOptions = useMemo(() => dateRangeOptions[aggregateUnit], [aggregateUnit]);

    const handleChangeAggregateUnit = useCallback(
      (value: AggregateUnit) => {
        // 集計期間が変更されたら、期間はそのまま「カスタム」に変更する
        if (searchCondition.filter.aggregateUnit !== value) {
          setDateRangeOption('カスタム');
          const newSearchCondition = searchCondition.setIn(['filter', 'aggregateUnit'], value);
          setSearchCondition(newSearchCondition);
        }
      },
      [searchCondition],
    );

    const handleChangeIsEnabledComparison = useCallback(
      (value: boolean) => {
        let newSearchCondition = searchCondition.setIn(['filter', 'isEnabledComparison'], value);
        if (comparisonDateRangeOption !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(
            searchCondition.filter.startDate,
            searchCondition.filter.endDate,
            comparisonDateRangeOption,
            dateRangeOption,
          );
          newSearchCondition = newSearchCondition.mergeIn(['filter'], {
            comparisonStartDate: csd,
            comparisonEndDate: ced,
          });
        }
        setSearchCondition(newSearchCondition);
      },
      [comparisonDateRangeOption, dateRangeOption, searchCondition],
    );

    const handleChangeDateRangeOption = useCallback(
      (value: DateRangeOptionValue) => {
        setDateRangeOption(value);
        // 集計期間のオプションから開始日〜終了日を取得
        const [sd, ed] = getDateRangeFromDateRangeOption(value, startDate, endDate, maxDate);
        // 設定を反映
        let newSearchCondition = searchCondition.mergeIn(['filter'], {
          startDate: sd,
          endDate: ed,
        });

        // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
        if (comparisonDateRangeOption !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, value);
          newSearchCondition = newSearchCondition.mergeIn(['filter'], {
            comparisonStartDate: csd,
            comparisonEndDate: ced,
          });
        }
        setSearchCondition(newSearchCondition);
      },
      [comparisonDateRangeOption, endDate, searchCondition, startDate],
    );

    const handleChangeComparisonDateRangeOption = useCallback(
      (value: ComparisonDateRangeOptionValue) => {
        setComparisonDateRangeOption(value);

        if (value !== 'カスタム') {
          const [csd, ced] = getComparisonDateRange(startDate, endDate, value, dateRangeOption);
          const newSearchCondition = searchCondition.mergeIn(['filter'], {
            comparisonStartDate: csd,
            comparisonEndDate: ced,
          });
          setSearchCondition(newSearchCondition);
        }
      },
      [dateRangeOption, endDate, searchCondition, startDate],
    );

    const handleChangeDateRange = useCallback(
      (startDate: Date | null, endDate: Date | null) => {
        if (startDate && endDate) {
          const newDateRangeOption = 'カスタム';
          setDateRangeOption(newDateRangeOption);

          const sd = dayjs(startDate);
          const ed = dayjs(endDate);

          let newSearchCondition = searchCondition.mergeIn(['filter'], {
            startDate: sd,
            endDate: ed,
          });

          // 比較期間のタイプに合わせて、比較期間の開始日、終了日も変更する
          if (comparisonDateRangeOption !== 'カスタム') {
            const [csd, ced] = getComparisonDateRange(sd, ed, comparisonDateRangeOption, newDateRangeOption);
            newSearchCondition = newSearchCondition.mergeIn(['filter'], {
              comparisonStartDate: csd,
              comparisonEndDate: ced,
            });
          }
          setSearchCondition(newSearchCondition);
        }
      },
      [comparisonDateRangeOption, searchCondition],
    );

    const handleChangeComparisonDateRange = useCallback(
      (comparisonStartDate: Date | null, comparisonEndDate: Date | null) => {
        setComparisonDateRangeOption('カスタム');
        const csd = dayjs(comparisonStartDate);
        const ced = dayjs(comparisonEndDate);
        const newSearchCondition = searchCondition.mergeIn(['filter'], {
          comparisonStartDate: csd,
          comparisonEndDate: ced,
        });
        setSearchCondition(newSearchCondition);
      },
      [searchCondition],
    );

    const handleSelectStore = useCallback(
      (placeId: string) => {
        let newSelectedStoreList: List<string | null>;
        const currentIndex = selectedStoreList.indexOf(placeId);
        if (currentIndex >= 0) {
          // 対象のプレイスIDがあったとこをnullに置き換える
          newSelectedStoreList = selectedStoreList.splice(currentIndex, 1, null);
        } else {
          // 最初にnullなとこ探して、あったら置き換える、無かったら追加
          const targetIndex = selectedStoreList.indexOf(null);
          if (targetIndex >= 0) {
            newSelectedStoreList = selectedStoreList.splice(targetIndex, 1, placeId);
          } else {
            newSelectedStoreList = selectedStoreList.push(placeId);
          }
        }
        setSelectedStoreList(newSelectedStoreList);
      },
      [selectedStoreList],
    );

    /**
     * 競合店舗未登録の店舗を結果に含めるのオンオフ
     */
    const handleChangeContainsAll = useCallback(() => {
      const newSearchCondition = searchCondition.update('filter', (filter) =>
        filter.update('containsAll', (excludeLessThanCount) => !excludeLessThanCount),
      );
      setSearchCondition(newSearchCondition);
      // ローカルストレージでも保存する
      setSearchConditionContainsAll(newSearchCondition.filter.containsAll);
    }, [searchCondition, setSearchConditionContainsAll]);

    // 競合店舗登録関連
    const [registerCompetitor, setRegisterCompetitor] = useState<{ competitor: Competitor; store: Store } | null>(null);
    const handleOpenRegisterCompetitorModal = useCallback(
      (competitorPlaceId: string, competitorStoreName: string) => {
        const competitor = new Competitor({
          storeId,
          name: competitorStoreName,
          placeId: competitorPlaceId,
          tags: List(),
        });
        const store = stores.findStore(storeId);
        if (!store) {
          return;
        }
        setRegisterCompetitor({ competitor, store });
      },
      [storeId, stores],
    );

    // 「競合店舗を登録する」から登録した競合店舗のプレイスID（競合店舗の判定で利用する）
    const [additionalCompetitorPlaceIds, setAdditionalCompetitorPlaceIds] = useState<Set<string>>(Set());

    const competitorsPagePath = useMemo(() => {
      const searchParams = new URLSearchParams();
      searchParams.set('si', storeId.toString());
      return `${Path.competitors.index}?${searchParams.toString()}`;
    }, [storeId]);

    return (
      <>
        <StyledModal className={className} open={isOpen} onClose={onClose}>
          <ModalContent>
            <Wrapper>
              <Title>
                {storeName} × {searchWord}
                {areaName && (
                  <AreaNameWrapper>
                    <AreaIcon type={'pin_gray'} />
                    {areaName}
                  </AreaNameWrapper>
                )}
              </Title>
              <ContentWrapper>
                <SubTitle>競合店舗との順位比較</SubTitle>
                <DateRangeWrapper>
                  <DateRangeContent>
                    <FlexWrapper>
                      <FlexContent>
                        <Label>集計単位</Label>
                        <AggregateUnitGroup value={aggregateUnit} disableDay onChange={handleChangeAggregateUnit} />
                      </FlexContent>
                      <PeriodContainer>
                        <Label>集計期間</Label>
                        <AggregateDateRangePicker
                          startDate={startDate}
                          endDate={endDate}
                          comparisonStartDate={comparisonStartDate}
                          comparisonEndDate={comparisonEndDate}
                          maxDate={maxDate}
                          isEnabledComparison={isEnabledComparison}
                          dateRangeOption={dateRangeOption}
                          dateRangeOptions={aggregateDurationOptions}
                          comparisonDateRangeOption={comparisonDateRangeOption}
                          onChangeDateRange={handleChangeDateRange}
                          onChangeComparisonDateRange={handleChangeComparisonDateRange}
                          onChangeIsEnabledComparison={handleChangeIsEnabledComparison}
                          onChangeDateRangeOption={handleChangeDateRangeOption}
                          onChangeComparisonDateRangeOption={handleChangeComparisonDateRangeOption}
                        />
                      </PeriodContainer>
                      <FlexContent>
                        <Label>集計対象</Label>
                        <CheckboxInnerContent>
                          <StyledCheckbox
                            onChange={handleChangeContainsAll}
                            checked={searchCondition.filter.containsAll}
                            label='競合店舗未登録の店舗を結果に含める'
                          />
                        </CheckboxInnerContent>
                      </FlexContent>
                    </FlexWrapper>
                    <DateRangeButtonContainer>
                      <CommitButton
                        priority={'high'}
                        onClick={() => setCommittedSearchCondition(searchCondition)}
                        disabled={isLoading}
                      >
                        適用
                      </CommitButton>
                    </DateRangeButtonContainer>
                  </DateRangeContent>
                </DateRangeWrapper>
                <MapSearchRankCompetitorGraph
                  isLoading={isLoading}
                  aggregateUnit={committedAggregateUnit}
                  startDate={committedStartDate}
                  endDate={committedEndDate}
                  comparisonStartDate={committedComparisonStartDate}
                  comparisonEndDate={committedComparisonEndDate}
                  isEnabledComparison={committedIsEnabledComparison}
                  graphData={competitorData?.graphData}
                  storeColors={storeColors}
                  selectedStores={selectedStores}
                  memoList={memoList}
                  memoDisplaySettings={memoDisplaySettings}
                  onClickMemo={(ids) => setSelectingMemoIds(Set(ids))}
                  onClickLegend={handleSelectStore}
                />
                <AnalysisMemoTable
                  memoList={memoList}
                  activeMemoIds={selectingMemoIds}
                  clearActiveMemoIds={() => setSelectingMemoIds(Set())}
                  tags={memoTagList}
                  memoDisplaySettings={memoDisplaySettings}
                  setMemoDisplaySettings={setMemoDisplaySettings}
                  onCreateMemo={onCreateMemo}
                  onUpdateMemo={onUpdateMemo}
                  onDeleteMemo={onDeleteMemo}
                  isLoadingMemoList={isLoadingMemoList}
                  isLoadingMemoTagList={isLoadingMemoTagList}
                  stickyPosition={'Modal'}
                />
                <MapSearchRankCompetitorTable
                  isLoading={isLoading}
                  startDate={committedStartDate}
                  endDate={committedEndDate}
                  comparisonStartDate={committedComparisonStartDate}
                  comparisonEndDate={committedComparisonEndDate}
                  isEnabledComparison={committedIsEnabledComparison}
                  tableData={competitorData?.tableData}
                  stores={stores}
                  storeColors={storeColors}
                  selectedStores={selectedStores}
                  onSelectStore={handleSelectStore}
                  onClickRegisterCompetitor={handleOpenRegisterCompetitorModal}
                  additionalCompetitorPlaceIds={additionalCompetitorPlaceIds}
                  canRegisterCompetitor={canRegisterCompetitor}
                />
              </ContentWrapper>
              <ModalFooter>
                <LinkContainer>
                  競合店舗の追加・削除は
                  <Link to={competitorsPagePath}>
                    <LinkText>こちら</LinkText>
                  </Link>
                  で設定できます。
                </LinkContainer>

                <StyledButton onClick={onClose}>閉じる</StyledButton>
              </ModalFooter>
            </Wrapper>
          </ModalContent>
        </StyledModal>
        {registerCompetitor && (
          <CompetitorRegisterModal
            isOpen={true}
            competitor={registerCompetitor.competitor}
            store={registerCompetitor.store}
            onClose={() => setRegisterCompetitor(null)}
            onSuccess={(competitor) => {
              toast({
                type: 'success',
                title: `${competitor.name} を競合店舗に登録しました`,
                time: 10000,
              });
              setAdditionalCompetitorPlaceIds((additionalCompetitorPlaceIds) =>
                additionalCompetitorPlaceIds.add(competitor.placeId),
              );
            }}
            onFailure={(error) => {
              toast({
                type: 'error',
                title: '競合店舗の登録に失敗しました',
                description: (error as any).message || '',
                time: 10000,
              });
            }}
          />
        )}
      </>
    );
  },
);

const StyledModal = styled(Modal)`
  height: 80%;
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 32px;
`;

const StyledButton = styled(Button)`
  max-width: 150px;
  width: calc(50% - 8px);
  padding: 14px 8px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const AreaNameWrapper = styled.span`
  margin-left: 8px;
  font-size: 16px;
  color: ${COLOR.GRAY};
`;

const AreaIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  padding: 0;
`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 24px;
`;

const DateRangeWrapper = styled.div`
  margin-bottom: 16px;
`;

const DateRangeContent = styled.div`
  margin-top: 16px;
`;

const DateRangeButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CommitButton = styled(Button)`
  &&& {
    height: 32px;
    padding: 0;
    width: 176px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FlexContent = styled.div`
  margin-right: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 7px;
`;

const PeriodContainer = styled(FlexContent)``;

const LinkContainer = styled.div``;

const LinkText = styled.span`
  color: ${COLOR.GREEN};
  cursor: pointer;
  text-decoration: underline;
`;

const InnerContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const CheckboxInnerContent = styled(InnerContent)`
  margin-top: 4px;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& {
    font-size: 12px;
    color: #707070;
    cursor: pointer;
    margin-top: 4px;
  }
`;
