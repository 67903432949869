import React from 'react';

import { List as ImmutableList } from 'immutable';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import Promotion, { PROMOTION_LANGUAGE_LABELS } from 'models/Domain/Promotion/Promotion';
import { POST_STATUS_LABELS, PostStatusSummary, PostStatusType } from 'models/Domain/Promotion/PromotionGroup';
import { Stores } from 'models/Domain/Store';

/**
 * 投稿に紐づいている店舗一覧
 */
export const PromotionInfoDetailStores = React.memo<{
  promotion: Promotion;
  stores: Stores;
  statusSummaries: ImmutableList<PostStatusSummary>;
}>(({ promotion, stores, statusSummaries }) => {
  const firstStore = stores.findStore(promotion.stores.get(0)?.store_id ?? 0);

  if (!firstStore) return null;

  return (
    <Wrapper>
      <Title>GBPへの掲載状況</Title>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <StyledTableHeaderCell>店舗名</StyledTableHeaderCell>
            <StyledTableHeaderCell>掲載ステータス</StyledTableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {statusSummaries.map((summary) => {
            const targetStore = stores.findStore(summary.storeId);
            if (!targetStore) {
              return <React.Fragment key={summary.storeId} />;
            }
            return (
              <TableRow key={summary.storeId}>
                <StyledTableCell>{targetStore.fullName}</StyledTableCell>
                <StyledTableCellFixed>
                  <Popup
                    disabled={summary.totalPostCount === 1}
                    content={
                      <>
                        {summary.postStatuses.map((postStatus) => (
                          <PopupListItem key={postStatus.language}>
                            <PopupListItemLabel>
                              {(PROMOTION_LANGUAGE_LABELS as any)[postStatus.language || '']}
                            </PopupListItemLabel>
                            <PopupListItemValue>
                              <PromotionStatus status={postStatus.status}>
                                {POST_STATUS_LABELS[postStatus.status]}
                              </PromotionStatus>
                            </PopupListItemValue>
                          </PopupListItem>
                        ))}
                      </>
                    }
                    size='small'
                    trigger={
                      <PromotionStatus status={summary.status}>
                        {POST_STATUS_LABELS[summary.status]}
                        {summary.totalPostCount > 1 ? (
                          <>
                            &nbsp;({summary.livePostCount}/{summary.totalPostCount})
                          </>
                        ) : (
                          <></>
                        )}
                      </PromotionStatus>
                    }
                  />
                </StyledTableCellFixed>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
    text-align: left;
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
    font-weight: bold;
    text-align: left;
  }
`;

const StyledTableCellFixed = styled(StyledTableCell)`
  &&& {
    width: 150px;
  }
`;

const PromotionStatus = styled.span<{ status: PostStatusType }>`
  color: ${(props) => {
    const status: PostStatusType = props.status;
    switch (status) {
      case 'LIVE':
        return '#4CB827';
      case 'PROCESSING':
        return '#E6C200';
      case 'REJECTED':
        return '#FE5C5C';
      case 'SCHEDULED':
        return '#2185D0';
      case 'DRAFT':
        return '#2185D0';
    }
  }};
`;

const PopupListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  margin: 2px 0;
`;
const PopupListItemLabel = styled.div``;
const PopupListItemValue = styled.div`
  margin-left: 16px;
`;
