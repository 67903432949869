import actionCreatorFactory from 'typescript-fsa';

import { ExecutionArnStatus } from 'models/Domain/ExecutionArnStatus';
import { MapSearchRankConfigData } from 'models/Domain/MapSearchRank/MapSearchRankConfigData';
import { MapSearchRankConfigSearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankConfigSearchCondition';

const actionCreator = actionCreatorFactory('MapSearchRankConfig');

export const MapSearchRankConfigActions = {
  initializePage: actionCreator('initializePage'),

  setIsLoading: actionCreator<boolean>('setIsLoading'),
  setIsPreparedPage: actionCreator<boolean>('setIsPreparedPage'),
  setIsInitializedSearchCondition: actionCreator<boolean>('setIsInitializedSearchCondition'),

  commitSearchCondition: actionCreator<MapSearchRankConfigSearchCondition>('commitSearchCondition'),
  setSearchCondition: actionCreator<MapSearchRankConfigSearchCondition>('setSearchCondition'),
  setData: actionCreator<MapSearchRankConfigData>('setData'),

  setIsProcessingCsvDownload: actionCreator<boolean>('setIsProcessingCsvDownload'),
  setCsvDownload: actionCreator<ExecutionArnStatus>('setCsvDownload'),
  startCsvDownload: actionCreator<MapSearchRankConfigSearchCondition>('startCsvDownload'),
  clearCsvDownload: actionCreator('clearCsvDownload'),
  checkCsvDownloadStatus: actionCreator('checkCsvDownloadStatus'),
};
