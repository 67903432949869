import React from 'react';

import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { TRANSLATION_LANGUAGE_LABELS, TranslationLanguage } from 'models/Domain/Organization';
import { PromotionGroup } from 'models/Domain/Promotion/PromotionGroup';
import { COLOR } from 'style/color';

type Props = {
  language: TranslationLanguage;
  isLoading: boolean;
  onClickClear: () => void;
  onChangeActive: () => void;
  onChangePromotionGroup: (promotionGroup: PromotionGroup) => void;
  onClickTranslate: () => void;
  onDelete: () => void;
  promotionGroup: PromotionGroup;
  isActive: boolean;
};

export const PromotionFormTranslationHeader: React.FC<Props> = ({
  language,
  isLoading,
  onChangeActive,
  onClickTranslate,
  onClickClear,
  isActive,
}) => {
  const languageLabel = TRANSLATION_LANGUAGE_LABELS[language];

  return (
    <Wrapper>
      <Divider />
      <Header>
        <ButtonWrapper>
          <Title>{languageLabel}</Title>
          <StyledCheckbox toggle checked={isActive} onChange={onChangeActive} />
        </ButtonWrapper>
        <ButtonWrapper>
          {isActive && (
            <ClearButton onClick={onClickClear} disabled={isLoading}>
              クリア
            </ClearButton>
          )}
          <TranslateButton onClick={onClickTranslate} disabled={isLoading}>
            <StyledIcon type={'robot'} disabled={isLoading} />
            翻訳
          </TranslateButton>
        </ButtonWrapper>
      </Header>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const TranslateButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    display: flex;
    align-items: self-end;
  }
`;

const ClearButton = styled(Button).attrs({ negative: true })`
  &&& {
    width: auto;
    padding: 11px 13px;
    display: flex;
    align-items: self-end;
  }
`;

const StyledIcon = styled(Icon)<{ disabled?: boolean }>`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
  padding: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  min-width: 128px;
`;

const Divider = styled.hr`
  border-top: 2px solid ${COLOR.LIGHT_GRAY};
  border-bottom: none;
  margin: 32px 0;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
  &&& {
    input:focus:checked ~ label:before,
    input:checked ~ label:before {
      background-color: #05ccad !important;
    }
  }
`;
