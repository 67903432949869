import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { PromotionGenerateParams } from 'ApiClient/PromotionApi';
import { validateWebsiteUrl } from 'helpers/utils';
import { ValidationResult } from 'types/Common';

export const MAX_CONTENTS_LENGTH = 4000;
export const MAX_KEYWORDS_LENGTH = 200;
export const MAX_TARGETS_LENGTH = 200;
export const MAX_CITE_LENGTH = 1500;

type PromotionGenerateConditionRecord = {
  url: string | null;
  contents: string | null;
  keywords: ImmutableList<string>;
  targets: ImmutableList<string>;
  cite: string;
};

export class PromotionGenerateCondition extends ImmutableRecord<PromotionGenerateConditionRecord>({
  url: null,
  contents: null,
  keywords: ImmutableList<string>(),
  targets: ImmutableList<string>(),
  cite: '',
}) {
  changeUrl(value: string | null) {
    return this.set('url', value);
  }

  changeContents(value: string | null) {
    return this.set('contents', value);
  }

  changeKeywords(value: ImmutableList<string>) {
    return this.set('keywords', value);
  }

  changeTargets(value: ImmutableList<string>) {
    return this.set('targets', value);
  }

  changeCite(value: string) {
    return this.set('cite', value);
  }

  // keywordsの合計文字数
  get totalKeywordsWordCount() {
    return this.keywords.reduce((count, keyword) => count + keyword.length, 0);
  }

  // targetsの合計文字数
  get totalTargetsWordCount() {
    return this.targets.reduce((count, target) => count + target.length, 0);
  }

  isValid() {
    return Object.values(this.validate()).every((result) => result.isValid);
  }

  validate(): Record<keyof PromotionGenerateConditionRecord, ValidationResult> {
    return {
      url: this.validateUrl(),
      contents: this.validateContents(),
      keywords: this.validateKeywords(),
      targets: this.validateTargets(),
      cite: this.validateCite(),
    };
  }

  validateUrl(): ValidationResult {
    let error;
    // 商品説明文がない場合は必須
    if (!this.url && !this.contents) {
      error = '商品URLは必須です';
    } else if (this.url != null && !validateWebsiteUrl(this.url)) {
      error = 'URLの形式が正しくありません';
    }
    return error ? { isValid: false, error } : { isValid: true };
  }

  validateContents(): ValidationResult {
    let error;
    // URLがない場合は必須
    if (!this.url && !this.contents) {
      error = '商品説明文は必須です';
    } else if (this.contents != null && this.contents.length > MAX_CONTENTS_LENGTH) {
      error = `商品説明文は${MAX_CONTENTS_LENGTH}文字以内にしてください。`;
    }
    return error ? { isValid: false, error } : { isValid: true };
  }

  validateKeywords(): ValidationResult {
    let error;
    if (this.totalKeywordsWordCount > MAX_KEYWORDS_LENGTH) {
      error = `キーワードの文字数の合計は${MAX_KEYWORDS_LENGTH}文字以内にしてください`;
    }
    return error ? { isValid: false, error } : { isValid: true };
  }
  validateTargets(): ValidationResult {
    let error;
    if (this.totalTargetsWordCount > MAX_TARGETS_LENGTH) {
      error = `ターゲットの文字数の合計は${MAX_TARGETS_LENGTH}文字以内にしてください`;
    }
    return error ? { isValid: false, error } : { isValid: true };
  }
  validateCite(): ValidationResult {
    let error;
    if (this.cite.length > MAX_CITE_LENGTH) {
      error = `参考文章は${MAX_CITE_LENGTH}文字以内にしてください`;
    }
    return error ? { isValid: false, error } : { isValid: true };
  }

  toGenerateParams(): PromotionGenerateParams {
    return {
      url: this.url || undefined,
      contents: this.contents || undefined,
      keywords: this.keywords.size > 0 ? this.keywords.toArray() : undefined,
      targets: this.targets.size > 0 ? this.targets.toArray() : undefined,
      cite: this.cite || undefined,
    };
  }
}
