import React from 'react';

import { List } from 'immutable';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { GmbAttributeMetadata, GmbAttributeMetadatas } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttribute, GmbAttributes } from 'models/Domain/GmbLocation/GmbAttributes';

export type AttributeListProps = {
  attributes: GmbAttributes;
  attributeMetadatas: GmbAttributeMetadatas;
  // 空の属性のみのグループを表示対象に含めるかどうか
  displayEmptyAttributeGroup: boolean;
};

type AttributePair = [GmbAttribute, GmbAttributeMetadata];

export const AttributeList: React.FC<AttributeListProps> = React.memo(
  ({ attributes, attributeMetadatas, displayEmptyAttributeGroup }) => {
    if (!attributeMetadatas) return null;

    // 表示するグループのみに絞り込む(空の属性グループを表示しないかつ attribute が1件もない場合を除外する)
    const groupedAttributeMetadatas = List(attributeMetadatas.groupByRemovedUrlGroupDisplayName()).filter(
      ([_, attributeMetadataList]) =>
        displayEmptyAttributeGroup ||
        !attributeMetadataList
          .toList()
          .filter((meta) => attributes.find(meta.attributeId))
          .isEmpty(),
    );

    return (
      <Wrapper>
        {groupedAttributeMetadatas.map(([groupName, attributeMetadataList], index) => {
          const displayAttributes = attributeMetadataList
            .toList()
            .map((meta) => [attributes.find(meta.attributeId), meta] as AttributePair)
            .filter(([attribute]) => attribute && attribute.hasValue()) as List<AttributePair>;

          return (
            <React.Fragment key={groupName}>
              {
                // コピペした時にグループごとに改行が入るようにするための <br />
                index > 0 && <br />
              }
              <AttributeGroup>
                <Attribute>
                  <AttributeGroupDisplayName>{groupName}</AttributeGroupDisplayName>
                  {displayAttributes.isEmpty() ? (
                    <>
                      <AttributeIcon type='dash' />
                      <InvisibleText>- </InvisibleText>
                      属性が設定されていません
                    </>
                  ) : (
                    <>
                      {displayAttributes.map(([attribute, attributeMetadata]) => {
                        switch (attributeMetadata.valueType) {
                          case 'ENUM':
                            return (
                              <div key={attribute.attributeId}>
                                <AttributeIcon type='check' />
                                <InvisibleText>✔︎ </InvisibleText>
                                {attributeMetadata.findValueMetadata(attribute.values.get(0) as string)?.displayName}
                              </div>
                            );
                          case 'BOOL':
                            return (
                              <div key={attribute.attributeId}>
                                <AttributeIcon type={attribute.values.get(0) === true ? 'check' : 'cancel_red'} />
                                <InvisibleText>{attribute.values.get(0) === true ? '✔︎ ' : '× '}</InvisibleText>
                                {attributeMetadata.displayName}
                              </div>
                            );
                          case 'REPEATED_ENUM':
                            return (
                              <AttributeRepeatedEnum key={attribute.attributeId}>
                                <AttributeDisplayName>{attributeMetadata.displayName}</AttributeDisplayName>
                                <AttributeRepeatedEnumValues>
                                  {attribute.getSortedRepeatedEnumValues().map(([value, isSetValue]) => (
                                    <AttributeRepeatedEnumValue key={value}>
                                      <AttributeIcon type={isSetValue ? 'check' : 'cancel_red'} />
                                      <InvisibleText>{isSetValue ? '✔︎ ' : '× '} </InvisibleText>
                                      {attributeMetadata.findValueMetadata(value)?.displayName || ''}
                                    </AttributeRepeatedEnumValue>
                                  ))}
                                </AttributeRepeatedEnumValues>
                              </AttributeRepeatedEnum>
                            );
                          default:
                            return <React.Fragment key={attribute.attributeId} />;
                        }
                      })}
                    </>
                  )}
                </Attribute>
              </AttributeGroup>
            </React.Fragment>
          );
        })}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const AttributeGroup = styled.div``;

const Attribute = styled.div`
  margin-top: 16px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const AttributeGroupDisplayName = styled.div`
  font-weight: bold;
`;

const AttributeDisplayName = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const AttributeRepeatedEnum = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 10px;
  padding: 16px 12px;
`;

const AttributeRepeatedEnumValues = styled.div``;

const AttributeRepeatedEnumValue = styled.div`
  margin-right: 18px;
`;

const AttributeIcon = styled(Icon)`
  padding: 0 2px 2px 0;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
`;

const InvisibleText = styled.span`
  font-size: 0;
  color: transparent;
`;
