import { Record } from 'immutable';

import { Offer, Offers } from 'models/Domain/Offer';
import { OtherOffer } from 'models/Domain/OtherOffer';
import { Report } from 'models/Domain/Report';
import { Task } from 'models/Domain/Task';
import { User, UserList } from 'models/Domain/User';
import { JSObject } from 'types/Common';

export enum GmbReviewStatus {
  NoReply = 'no_reply', // 未返信・未対応
  InProgress = 'in_progress', // 対応中
  Done = 'done', // 完了
}

/** オファー詳細で使うユーザなど追加情報の入ったOffer */
export class OfferItem extends Record<{
  offer: Offer;
  task: Task;
  report: Report;
  other_offer: OtherOffer;
  has_authority: boolean;
  readers: UserList;
  user: User;
  other_store_offers: Offers;
}>({
  offer: new Offer(),
  task: new Task(),
  report: new Report(),
  other_offer: new OtherOffer(),
  has_authority: false,
  readers: new UserList(),
  user: new User(),
  other_store_offers: new Offers(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.offer = new Offer(params.offer);
    params.task = new Task(params.task);
    params.report = new Report(params.report);
    params.other_offer = new OtherOffer(params.other_offer);
    params.user = new User(params.user);
    params.other_store_offers = new Offers(params.other_store_offers);
    params.readers = new UserList(params.readers);
    super(params);
  }

  get targetContent() {
    const {
      offer: { offer_type },
      task,
      report,
      other_offer,
    } = this;
    if (offer_type === 'task') {
      return task;
    } else if (offer_type === 'report') {
      return report;
    } else if (offer_type === 'other_offer') {
      return other_offer;
    }
    return task;
  }

  get provisionalHashtag() {
    const { targetContent } = this;
    let tags: string[] = [];
    tags = tags.concat(targetContent.provisionalHashtag);
    return tags;
  }

  get fileContents() {
    const { targetContent } = this;
    return targetContent.content.files;
  }

  get title() {
    const { targetContent } = this;
    return targetContent.name;
  }
}
