import dayjs from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord, List } from 'immutable';

import Error from 'helpers/errorType';
import { JSObject } from 'types/Common';

import { GmbTimePeriodString } from './GmbTimePeriodString';

export const DAYS_OF_WEEK = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'] as const;
export type DayOfWeek = (typeof DAYS_OF_WEEK)[number];

// 時間同士だけでは長さが計れないため見掛けの日付を用意
const TODAY = dayjs().format('YYYY-MM-DD');
const TOMORROW = dayjs().add(1, 'day').format('YYYY-MM-DD');

const MINUTES_IN_DAY = 1440;

export class GmbTimePeriod extends ImmutableRecord<{
  openDay: DayOfWeek;
  openTime: GmbTimePeriodString;
  closeDay: DayOfWeek;
  closeTime: GmbTimePeriodString;
}>({
  openDay: 'MONDAY',
  openTime: new GmbTimePeriodString(),
  closeDay: 'MONDAY',
  closeTime: new GmbTimePeriodString(),
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.openTime = new GmbTimePeriodString(data.openTime);
    params.closeTime = new GmbTimePeriodString(data.closeTime);
    super(params);
  }

  /** openTime ~ closeTime の長さを分単位で返す */
  get range() {
    const openTime = dayjs(`${TODAY} ${this.openTime.value}`);
    const closeTime = dayjs(`${this.isClosingTomorrow ? TOMORROW : TODAY} ${this.closeTime.value}`);
    return closeTime.diff(openTime, 'minute');
  }

  /** 一覧画面の表示用 */
  get periodForDisplay() {
    return `${this.openTime.value} - ${this.closeTime.value}`;
  }

  get validateOpenTime() {
    if (!this.openTime.value) {
      return { isValid: false, error: Error.OPEN_TIME_EMPTY_ERROR };
    }
    if (!this.openTime.isValid) {
      return { isValid: false, error: Error.OPEN_TIME_INVALID_ERROR };
    }
    return { isValid: true, error: '' };
  }

  get validateCloseTime() {
    if (!this.closeTime.value) {
      return { isValid: false, error: Error.CLOSE_TIME_EMPTY_ERROR };
    }
    if (this.openTime.value === this.closeTime.value) {
      return { isValid: false, error: Error.CLOSE_TIME_BEFORE_OPEN_TIME_ERROR };
    }
    if (!this.closeTime.isValid) {
      return { isValid: false, error: Error.CLOSE_TIME_INVALID_ERROR };
    }
    if (!this.isClosingTomorrow && this.closeTime.isBefore(this.openTime)) {
      return { isValid: false, error: Error.CLOSE_TIME_BEFORE_OPEN_TIME_ERROR };
    }
    return { isValid: true, error: '' };
  }

  /** openTime ~ closeTime が2日にまたがっているかどうか */
  get isClosingTomorrow() {
    return this.closeTime.hour - this.openTime.hour < 0;
  }

  get requestParams() {
    return {
      openDay: this.openDay,
      openTime: this.openTime.value,
      closeDay: this.closeDay,
      closeTime: this.closeTime.value,
    };
  }

  /**
   * 時刻を設定する
   *
   * 時刻が翌日にまたがる場合、closeDayはopenDayの次の曜日が設定される
   * @param openTime 開店時刻
   * @param closeTime 閉店時刻
   * @returns
   */
  setTime(openTime: GmbTimePeriodString, closeTime: GmbTimePeriodString) {
    return this.merge({ openTime, closeTime }).setCloseDay();
  }

  /**
   * 開店の曜日を設定する
   *
   * 時刻が翌日にまたがる場合、closeDayはopenDayの次の曜日が設定される
   * @param openDay 開店時刻の曜日
   * @returns
   */
  setOpenDay(openDay: DayOfWeek) {
    return this.merge({ openDay }).setCloseDay();
  }

  /**
   * 閉店の曜日を設定する
   *
   * 閉店の曜日は、開店・閉店時刻および開店の曜日により自動で定まる
   * @returns
   */
  private setCloseDay() {
    const closeDay = this.isClosingTomorrow
      ? DAYS_OF_WEEK[(DAYS_OF_WEEK.indexOf(this.openDay) + 1) % DAYS_OF_WEEK.length]
      : this.openDay;

    return this.set('closeDay', closeDay);
  }
}

export class GmbTimePeriods extends ImmutableRecord<{
  list: ImmutableList<GmbTimePeriod>;
}>({
  list: ImmutableList(),
}) {
  constructor(data = []) {
    const params: JSObject = {};
    params.list = List(data && data.map((period: JSObject) => new GmbTimePeriod(period)));
    super(params);
  }

  get isOpen() {
    return !this.list.isEmpty();
  }

  /** 一覧画面の表示用 */
  get periodsForDisplay() {
    return this.list.isEmpty() ? ['定休日'] : this.list.map((period) => `${period.periodForDisplay}`).toArray();
  }

  get validate() {
    let error;
    this.list.forEach((period) => {
      if (!period.validateOpenTime.isValid) error = period.validateOpenTime;
      if (!period.validateCloseTime.isValid) error = period.validateCloseTime;
    });
    // 合計時間が24時間を超えていないか
    const totalMinutes = this.list.reduce((sum, period) => {
      return sum + period.range;
    }, 0);
    if (totalMinutes > MINUTES_IN_DAY) {
      error = { isValid: false, error: Error.PERIODS_EXCEEDS_24HOURS };
    }
    return error || { isValid: true, error: '' };
  }

  /** 指定された曜日に開店時間オブジェクトを作る */
  addOpenDay(
    dayOfWeek: DayOfWeek,
    openTime = new GmbTimePeriodString('09:00'),
    closeTime = new GmbTimePeriodString('17:00'),
  ) {
    const period = new GmbTimePeriod().setOpenDay(dayOfWeek).setTime(openTime, closeTime);
    return this.set('list', this.list.push(period));
  }

  /** 営業時間の曜日を変更する */
  changeDay(dayOfWeek: DayOfWeek) {
    return this.set(
      'list',
      this.list.map((period) => period.setOpenDay(dayOfWeek)),
    );
  }

  /** 時間を変更する */
  changePeriod(index: number, openTime: GmbTimePeriodString, closeTime: GmbTimePeriodString) {
    return this.updateIn(['list', index], (period: GmbTimePeriod) => period.setTime(openTime, closeTime));
  }

  /** 時間を削除する */
  removePeriod(index: number) {
    return this.set(
      'list',
      this.list.filter((_, idx) => idx !== index),
    );
  }

  /** 定休日にする（営業時間を削除する） */
  closeDay() {
    return this.set('list', List());
  }

  requestParams() {
    return this.list.map((period) => period.requestParams).toArray();
  }
}
