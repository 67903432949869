import { List as ImmutableList } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { InstagramAccount, InstagramBusinessAccount } from 'models/Domain/instagram';

const actionCreator = actionCreatorFactory('Instagram');

export const InstagramActions = {
  setIsLoading: actionCreator<boolean>('setIsLoading'),
  setIsLoadingInstagramAccounts: actionCreator<boolean>('setIsLoadingInstagramAccounts'),
  setIsLoadingInstagramAccount: actionCreator<boolean>('setIsLoadingInstagramAccount'),
  setInstagramAccounts: actionCreator<ImmutableList<InstagramAccount>>('setInstagramAccounts'),
  setInstagramAccount: actionCreator<InstagramAccount>('setInstagramAccount'),
  initializeRegisterPage: actionCreator('initializeRegisterPage'),
  initializeIndexPage: actionCreator('initializeIndexPage'),
  initializeEditPage: actionCreator<number>('initializeEditPage'),
  deleteAccountForIndex: actionCreator<number>('deleteAccountForIndex'),
  deleteAccountForDetail: actionCreator<number>('deleteAccountForDetail'),
  createAccount: actionCreator<InstagramAccount>('createAccount'),
  updateAccount: actionCreator<InstagramAccount>('updateAccount'),
  registerAccounts: actionCreator<ImmutableList<InstagramBusinessAccount>>('registerAccounts'),
  getInstagramAccounts: actionCreator<{ userId: string; userAccessToken: string }>('getInstagramAccounts'),
  setAccountsForRegister: actionCreator<ImmutableList<InstagramBusinessAccount>>('setAccountsForRegister'),
};
