import React from 'react';

import styled from 'styled-components';

type Props = {
  columns?: string[];
  rows?: string[];
  areas: string[][];
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns = [] }: Props) => columns.join(' ')};
  grid-template-rows: ${({ rows = [] }: Props) => rows.join(' ')};
  grid-template-areas: ${({ areas }: Props) => areas.map((area) => `'${area.join(' ')}'`).join(' ')};
  width: 100%;
  height: 100%;
`;

export default React.memo(Grid);
