import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { DatePicker } from 'components/atoms/DatePicker';
import { Input } from 'components/atoms/Input';
import { PullDown } from 'components/atoms/PullDown';
import { TextArea } from 'components/atoms/TextArea';
import { Memo, MemoTag, MemoTagList } from 'models/Domain/Memo/Memo';
import { UserRole } from 'models/Domain/User';
import { COLOR } from 'style/color';
import { SelectOption } from 'types/Common';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  memo: Memo | null;
  tagList: MemoTagList;
  onCreate: (memo: Memo) => void;
  onUpdate: (memo: Memo) => void;
  onDelete: (memo: Memo) => void;
};

export const MemoModal = React.memo<Props>(({ isOpen, onClose, memo, tagList, onCreate, onUpdate, onDelete }) => {
  const [editingMemo, setEditingMemo] = useState<Memo>(memo || new Memo());
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [additionalTags, setAdditionalTags] = useState(ImmutableList<MemoTag>);

  const currentUser = useSelector((state) => state.app.currentUser);
  const userList = useSelector((state) => state.user.userList);

  // memoが変更されたら更新する
  useEffect(() => {
    setEditingMemo(memo || new Memo());
  }, [memo]);

  // 元となるメモがあれば編集モード
  const isEdit = !!memo;

  // 作成モード（isEdit=false）では、全員が編集可能
  // 編集モード（isEdit=true）では、権限がadmin/vmdまたはメモの作成者の場合は編集可能
  const canEdit =
    !isEdit ||
    currentUser.role === UserRole.Admin ||
    currentUser.role === UserRole.Vmd ||
    currentUser.id === memo.createdBy;

  const handleOnChangeStartDate = useCallback((value: Date | null) => {
    setEditingMemo((memo) => memo.set('startDate', value ? dayjs(value).startOf('day') : null));
  }, []);

  const handleOnChangeEndDate = useCallback((value: Date | null) => {
    setEditingMemo((memo) => memo.set('endDate', value ? dayjs(value).endOf('day') : null));
  }, []);

  const handleOnChangeTitle = useCallback((value: string) => {
    setEditingMemo((memo) => memo.set('title', value));
  }, []);

  const handleOnChangeDescription = useCallback((value: string) => {
    setEditingMemo((memo) => memo.set('description', value || ''));
  }, []);

  const handleOnChangeTags = useCallback((value: string[]) => {
    setEditingMemo((memo) => memo.set('tags', ImmutableList(value.map((v) => new MemoTag({ tag: v })))));
  }, []);

  const handleOnClose = useCallback(() => {
    // 閉じる際にリセットする
    setEditingMemo(new Memo());
    setShowErrorMessage(false);
    setAdditionalTags(ImmutableList());
    onClose();
  }, [onClose]);

  const handleOnCreate = useCallback(() => {
    if (!editingMemo.isValid()) {
      setShowErrorMessage(true);
      return;
    }
    onCreate(editingMemo);
    handleOnClose();
  }, [editingMemo, handleOnClose, onCreate]);

  const handleOnUpdate = useCallback(() => {
    if (!editingMemo.isValid()) {
      setShowErrorMessage(true);
      return;
    }
    onUpdate(editingMemo);
    handleOnClose();
  }, [editingMemo, handleOnClose, onUpdate]);

  const handleOnDelete = useCallback(() => {
    if (!window.confirm('本当に削除しますか？')) {
      return;
    }
    onDelete(editingMemo);
    handleOnClose();
  }, [editingMemo, handleOnClose, onDelete]);

  const options: SelectOption<string>[] = tagList.items
    .merge(additionalTags)
    .map((item) => ({ text: item.tag, value: item.tag }))
    .toArray();

  return (
    <Modal open={isOpen}>
      <Modal.Content>
        <TitleContainer canEdit={canEdit}>
          <Title>{isEdit ? '施策メモの編集' : '施策メモの作成'}</Title>
          {!canEdit && <Message>※権限がないため編集できません</Message>}
          {isEdit && canEdit && <DeleteButton onClick={handleOnDelete}>施策メモを削除</DeleteButton>}
        </TitleContainer>
        <FormContainer>
          <ContentWrapper>
            <LabelWrapper>
              <Label>開始日</Label>
              {canEdit && <Required>必須</Required>}
            </LabelWrapper>
            <StyledDatePicker
              dateFormat={'yyyy/MM/dd'}
              selected={editingMemo.startDate?.toDate()}
              onChange={handleOnChangeStartDate}
              selectsStart
              endDate={editingMemo.endDate?.toDate()}
              placeholderText={'日付を選択'}
              isClearable={!!editingMemo.startDate}
              canEdit={canEdit}
            />
            <DummyTextField error={(showErrorMessage && editingMemo.validateStartDate().error) || false} />
          </ContentWrapper>

          <ContentWrapper>
            <LabelWrapper>
              <Label>終了日</Label>
            </LabelWrapper>
            <StyledDatePicker
              dateFormat={'yyyy/MM/dd'}
              selected={editingMemo.endDate?.toDate()}
              onChange={handleOnChangeEndDate}
              selectsEnd
              startDate={editingMemo.startDate?.toDate()}
              placeholderText={'日付を選択'}
              isClearable={!!editingMemo.endDate}
              canEdit={canEdit}
            />
            <DummyTextField error={(showErrorMessage && editingMemo.validateEndDate().error) || false} />
          </ContentWrapper>

          <ContentWrapper>
            <LabelWrapper>
              <Label>タイトル</Label>
              {canEdit && <Required>必須</Required>}
            </LabelWrapper>
            <StyledInput
              value={editingMemo.title ?? ''}
              onChange={handleOnChangeTitle}
              canEdit={canEdit}
              error={(showErrorMessage && editingMemo.validateTitle().error) || false}
            />
          </ContentWrapper>

          <ContentWrapper>
            <LabelWrapper>
              <Label>内容</Label>
            </LabelWrapper>
            <StyledTextArea
              value={editingMemo.description ?? ''}
              onChange={handleOnChangeDescription}
              canEdit={canEdit}
              error={(showErrorMessage && editingMemo.validateDescription().error) || false}
            />
          </ContentWrapper>

          <ContentWrapper>
            <LabelWrapper>
              <Label>タグ</Label>
            </LabelWrapper>
            <CustomPullDown
              multiple={true}
              closeOnChange={false}
              allowAdditions={true}
              value={editingMemo.tags.map((tag) => tag.tag).toArray()}
              options={options}
              placeholder='タグを選択もしくは作成してください'
              onChange={handleOnChangeTags}
              onAddItem={(v) => {
                setAdditionalTags(additionalTags.push(new MemoTag({ tag: v, count: 1 })));
              }}
            />
            <DummyTextField error={(showErrorMessage && editingMemo.validateTags().error) || false} />
          </ContentWrapper>

          {isEdit && (
            <ContentWrapper>
              <LabelWrapper>
                <Label>作成者{canEdit && '（変更できません）'}</Label>
              </LabelWrapper>
              <StyledInput
                value={(editingMemo.createdBy && userList.findUser(editingMemo.createdBy)?.fullName) || ''}
                canEdit={false}
              />
            </ContentWrapper>
          )}
        </FormContainer>

        <ButtonContainer>
          <CancelButton onClick={handleOnClose}>閉じる</CancelButton>
          {canEdit && (
            <SaveButton
              onClick={isEdit ? handleOnUpdate : handleOnCreate}
              disabled={showErrorMessage && !editingMemo.isValid()}
            >
              保存する
            </SaveButton>
          )}
        </ButtonContainer>
      </Modal.Content>
    </Modal>
  );
});

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 16px 0;
`;

const TitleContainer = styled.div<{ canEdit: boolean }>`
  display: flex;
  border-bottom: 1px solid ${COLOR.GRAY};
  margin-bottom: 24px;
  align-items: center;
  justify-content: ${({ canEdit }) => (canEdit ? 'space-between' : 'flex-start')};
  gap: 8px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
`;

const ContentWrapper = styled.div``;

const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Required = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.ERROR};
  color: ${COLOR.WHITE};
  border-radius: 4px;
  font-size: 12px;
  padding: 1px 6px;
  margin-left: 8px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: 150px;
    height: 42px;
    padding: 0;
  }
`;
const DeleteButton = styled(StyledButton).attrs({ priority: 'low', negative: true })``;
const SaveButton = styled(StyledButton).attrs({ priority: 'high' })``;
const CancelButton = styled(StyledButton)``;

const StyledInput = styled(Input)<{ canEdit: boolean }>`
  &&& {
    ${({ canEdit }) => !canEdit && `pointer-events: none;`}
    width: 100%;
  }
`;
const StyledTextArea = styled(TextArea)<{ canEdit: boolean }>`
  &&& {
    ${({ canEdit }) => !canEdit && `pointer-events: none;`}
    width: 100%;
  }
`;

const StyledDatePicker = styled(DatePicker)<{ canEdit: boolean }>`
  ${({ canEdit }) => !canEdit && `pointer-events: none;`}

  .react-datepicker-popper {
    z-index: 10; /* デフォルトのz-index: 1では他のコンポーネントが上に表示されてしまう */
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    height: 54px;
    padding: 0 16px;
    font-family: monospace !important;
  }
`;

const Message = styled.div`
  color: ${COLOR.DARK_GRAY};
`;

const DummyTextField = styled(Input)`
  &&& {
    input {
      display: none;
    }
  }
`;

const CustomPullDown = styled(PullDown)`
  &&& {
    margin-top: 16px;
  }
`;
