import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { StickyHeader } from 'components/atoms/StickyHeader';
import SingleRegister from 'components/pageComponents/UserRegister/SingleRegister';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { UserActions } from 'modules/user/actions';

export const UserRegister: React.FC = () => {
  const dispatch = useDispatch();
  const newUser = useSelector((state) => state.user.newUser);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('スタッフ登録')} />
      <StickyHeader title='スタッフ登録' />
      <Body>
        <SingleRegister
          newUser={newUser}
          changeNewUser={(v) => dispatch(UserActions.changeNewUser(v))}
          registerNewUser={() => dispatch(UserActions.registerNewUser())}
          uploadNewUserImage={(v) => dispatch(UserActions.uploadNewUserImage(v))}
        />
      </Body>
    </MainWrapper>
  );
};
