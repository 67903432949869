import React, { useMemo, useState } from 'react';

import { Dayjs } from 'dayjs';
import { List as ImmutableList, Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { GuideOverlay } from 'components/molecules/GuideOverlay';
import { GraphDownloadModal } from 'components/organisms/GraphDownloadModal';
import { GraphSettingsModal } from 'components/organisms/GraphSettingsModal';
import { MemoDisplaySettings, getMemoData } from 'helpers/graph';
import { GraphSettings } from 'models/Domain/GraphSettings';
import {
  MapSearchRankCompetitorGraphData,
  MapSearchRankCompetitorGraphItem,
} from 'models/Domain/MapSearchRank/MapSearchRankCompetitor';
import { AggregateUnit } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';
import { MemoList } from 'models/Domain/Memo/Memo';
import { COLOR } from 'style/color';

import { ButtonWrapper, GraphWrapper, LoadingWrapper, StyledButton, Title, Wrapper } from '../MapSearchRankGraph';

import { MapSearchRankCompetitorGraph as Graph } from './Graph';

type Props = {
  className?: string;
  isLoading: boolean;
  aggregateUnit: AggregateUnit;
  startDate: Dayjs;
  endDate: Dayjs;
  comparisonStartDate: Dayjs | null;
  comparisonEndDate: Dayjs | null;
  isEnabledComparison: boolean;
  graphData: MapSearchRankCompetitorGraphData | undefined;
  storeColors: { [key: string]: string };
  selectedStores: ImmutableSet<string>;
  memoList: MemoList;
  memoDisplaySettings: MemoDisplaySettings;
  onClickMemo: (ids: number[]) => void;
  onClickLegend?: (placeId: string) => void;
};

export const MapSearchRankCompetitorGraph = React.memo<Props>(
  ({
    className,
    isLoading,
    isEnabledComparison,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    graphData,
    aggregateUnit,
    storeColors,
    selectedStores,
    memoList,
    memoDisplaySettings,
    onClickMemo,
    onClickLegend,
  }) => {
    // ダウンロードモーダル関連
    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

    // 設定モーダル
    const [isOpenSettingsModal, setIsOpenSettingsModal] = useState<boolean>(false);
    const [graphSettings, setGraphSettings] = useState(new GraphSettings({ min: 'auto', max: 'auto' }));

    const filteredGraphItems: ImmutableList<MapSearchRankCompetitorGraphItem> = useMemo(() => {
      // テーブルで選択されていなければ表示しない
      return graphData?.items.filter((item) => selectedStores.contains(item.placeId)) ?? ImmutableList();
    }, [graphData, selectedStores]);

    const memoData = useMemo(
      () => getMemoData(memoList, startDate, endDate, aggregateUnit, memoDisplaySettings.displayToGraph),
      [startDate, endDate, aggregateUnit, memoDisplaySettings.displayToGraph, memoList],
    );

    return (
      <Wrapper className={className}>
        <Title>掲載順位</Title>
        <GraphWrapper>
          <Graph
            aggregateUnit={aggregateUnit}
            startDate={startDate}
            endDate={endDate}
            comparisonStartDate={comparisonStartDate}
            comparisonEndDate={comparisonEndDate}
            isEnabledComparison={isEnabledComparison}
            graphData={graphData}
            storeColors={storeColors}
            selectedStores={selectedStores}
            onClickLegend={onClickLegend}
            onClickMemo={onClickMemo}
            memoData={memoData}
            graphSettings={graphSettings}
          />
          {isLoading && (
            <StyledLoadingWrapper>
              <Loader active={isLoading} size={'big'} inline={true} inverted={false} />
            </StyledLoadingWrapper>
          )}
          {!isLoading && filteredGraphItems.isEmpty() && <GuideOverlay>表示するデータを選択してください</GuideOverlay>}
          <ButtonWrapper>
            <StyledButton onClick={() => setIsOpenSettingsModal(true)}>グラフの表示設定</StyledButton>
            <StyledButton onClick={() => setIsOpenDownloadModal(true)}>グラフをダウンロード</StyledButton>
          </ButtonWrapper>
        </GraphWrapper>
        <GraphDownloadModal
          isOpen={isOpenDownloadModal}
          fileName={'searchRank'}
          onClose={() => setIsOpenDownloadModal(false)}
        >
          <Graph
            aggregateUnit={aggregateUnit}
            startDate={startDate}
            endDate={endDate}
            comparisonStartDate={comparisonStartDate}
            comparisonEndDate={comparisonEndDate}
            isEnabledComparison={isEnabledComparison}
            graphData={graphData}
            storeColors={storeColors}
            selectedStores={selectedStores}
            graphSettings={graphSettings}
          />
        </GraphDownloadModal>
        {isOpenSettingsModal && (
          <GraphSettingsModal
            settings={graphSettings}
            isOpen={isOpenSettingsModal}
            onClose={() => setIsOpenSettingsModal(false)}
            onChange={setGraphSettings}
          />
        )}
      </Wrapper>
    );
  },
);

export const StyledLoadingWrapper = styled(LoadingWrapper)`
  background: ${COLOR.GRAY};
`;
