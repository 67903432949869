import React, { useCallback, useMemo } from 'react';

import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { FormContent } from 'components/atoms/FormContent';
import { Icon } from 'components/atoms/Icon';
import { TranslationLanguage } from 'models/Domain/Organization';
import { PromotionGroup, PromotionTranslationData } from 'models/Domain/Promotion/PromotionGroup';
import { Stores } from 'models/Domain/Store';

import { PromotionFormTranslationHeader } from './PromotionFormTranslationHeader';
import { PromotionFormTranslationItem } from './PromotionFormTranslationItem';

type Props = {
  className?: string;
  translationLanguages: TranslationLanguage[];
  canAddTranslationLanguage: boolean;
  loadingLanguages: ImmutableSet<TranslationLanguage>;
  onChangePromotionGroup: (promotionGroup: PromotionGroup) => void;
  translatePost: (language: TranslationLanguage) => void;
  showErrorMessage: boolean;
  stores: Stores;
  promotionGroup: PromotionGroup;
};

export const PromotionFormTranslation: React.FC<Props> = ({
  translationLanguages,
  loadingLanguages,
  translatePost,
  showErrorMessage,
  stores,
  canAddTranslationLanguage,
  promotionGroup,
  onChangePromotionGroup,
}) => {
  const { promotion } = promotionGroup;

  const handleChangeLanguage = useCallback(
    (language: TranslationLanguage) => {
      // 子投稿に対象の言語を含むか
      const childPost = promotionGroup.findChild(language);
      if (childPost) {
        // すでに選択されている言語の場合は削除する。タイトルまたは本文が入力されている場合は、削除していいか確認する
        const hasValue = !!childPost.title || !!childPost.body;
        if (hasValue && !window.confirm('選択された言語の投稿が削除されますが、よろしいですか？')) {
          return;
        }
        onChangePromotionGroup(promotionGroup.deleteChild(language));
      } else {
        // 選択されていない言語の場合は追加する。日本語の投稿のcall_to_actionをコピーする
        const callToAction = promotionGroup.promotion.call_to_action;
        onChangePromotionGroup(
          promotionGroup.addChild(new PromotionTranslationData({ language: language, call_to_action: callToAction })),
        );
      }
    },
    [onChangePromotionGroup, promotionGroup],
  );

  // 翻訳対象の言語を選択可能な言語に絞り込む
  const selectableLanguages = useMemo(() => {
    if (canAddTranslationLanguage) {
      // 追加が可能な場合は組織に設定されている言語全てを表示
      return translationLanguages;
    } else {
      // 追加が不可能な場合は子投稿に存在する言語のみ表示
      const childrenLanguages = promotion.translations?.childrenLanguages.toSet() ?? ImmutableSet();
      return translationLanguages.filter((language) => childrenLanguages.has(language));
    }
  }, [canAddTranslationLanguage, translationLanguages, promotion.translations?.childrenLanguages]);

  const translateSelectedLanguages = useCallback(() => {
    if (promotionGroup.children.some((childPost) => !!childPost.title || !!childPost.body)) {
      if (!window.confirm('入力済みの内容が上書きされますが、よろしいですか？')) {
        return;
      }
    }
    let newPromotionGroup = promotionGroup;
    selectableLanguages.forEach((language) => {
      if (!newPromotionGroup.findChild(language)) {
        const callToAction = newPromotionGroup.promotion.call_to_action;
        newPromotionGroup = newPromotionGroup.addChild(
          new PromotionTranslationData({ language: language, call_to_action: callToAction }),
        );
      }
    });
    onChangePromotionGroup(newPromotionGroup);
    newPromotionGroup.languages.forEach((language) => {
      translatePost(language);
    });
  }, [onChangePromotionGroup, promotionGroup, selectableLanguages, translatePost]);

  const handleClickClear = useCallback(
    (language: TranslationLanguage) => {
      const childPost = promotionGroup.findChild(language)?.changeValue('title', '').changeValue('body', '');
      if (childPost) {
        onChangePromotionGroup(promotionGroup.setChild(language, childPost));
      }
    },
    [onChangePromotionGroup, promotionGroup],
  );

  const handleClickTranslate = useCallback(
    (language: TranslationLanguage) => {
      const childPost = promotionGroup.findChild(language);
      if (childPost) {
        const hasValue = !!childPost.title || !!childPost.body;
        if (hasValue && !window.confirm('入力済みの内容が上書きされますが、よろしいですか？')) {
          return;
        }
      } else {
        handleChangeLanguage(language);
      }
      translatePost(language);
    },
    [handleChangeLanguage, promotionGroup, translatePost],
  );

  // 選択可能な言語がない場合は表示しない
  if (selectableLanguages.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <FormContent
        name={'多言語対応'}
        informationText={
          '日本語の投稿に続けて選択した言語の投稿が連続で表示されるようになります。翻訳した文章を自動で生成することが可能です。'
        }
        additionalContents={
          <ButtonWrapper>
            {/* 選択可能な言語が複数なければ表示しない */}
            {selectableLanguages.length > 1 && (
              <TranslateButton onClick={translateSelectedLanguages} disabled={loadingLanguages.size > 0}>
                <StyledIcon type={'robot'} disabled={loadingLanguages.size > 0} />
                すべて翻訳
              </TranslateButton>
            )}
          </ButtonWrapper>
        }
      >
        {selectableLanguages.map((language) => {
          const childPost = promotionGroup.findChild(language);
          return (
            <>
              <PromotionFormTranslationHeader
                key={language}
                language={language}
                onChangeActive={() => handleChangeLanguage(language)}
                onClickClear={() => handleClickClear(language)}
                isLoading={loadingLanguages.has(language)}
                onChangePromotionGroup={onChangePromotionGroup}
                onClickTranslate={() => handleClickTranslate(language)}
                onDelete={() => handleChangeLanguage(language)}
                promotionGroup={promotionGroup}
                isActive={promotionGroup.languages.includes(language)}
              />
              {childPost && (
                <PromotionFormTranslationItem
                  key={language}
                  language={language}
                  translationPost={childPost}
                  isLoading={loadingLanguages.has(language)}
                  onChangePromotionGroup={onChangePromotionGroup}
                  showErrorMessage={showErrorMessage}
                  stores={stores}
                  promotionGroup={promotionGroup}
                />
              )}
            </>
          );
        })}
      </FormContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
  align-items: center;
`;
const TranslateButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    display: flex;
    align-items: self-end;
  }
`;

const StyledIcon = styled(Icon)<{ disabled?: boolean }>`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
  padding: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
