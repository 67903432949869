import React, { useMemo } from 'react';

import styled from 'styled-components';

import { MemoIconData } from 'helpers/graph';
import { CompositeOmoInsightSummaryItem } from 'models/Domain/OmoInsight/InsightSummary';
import { CompositeOMOInsightGraphData } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { ProductStatusSummary } from 'models/Domain/OmoInsight/ProductStatusSummary';
import { COLOR } from 'style/color';

import { InsightSummary } from './InsightSummary';
import { Performance } from './Performance';
import { ProductStatus } from './ProductStatus';

type Props = {
  className?: string;
  insight: CompositeOmoInsightSummaryItem;
  productStatus: ProductStatusSummary;
  graphData: CompositeOMOInsightGraphData;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
};

export const OmoInsightItem = React.memo<Props>(
  ({ className, insight, productStatus, graphData, memoData, onClickMemo }) => {
    const productStatusGraphData = useMemo(() => graphData.toProductStatusGraphData(), [graphData]);
    const performanceGraphData = useMemo(() => graphData.toPerformanceGraphData(), [graphData]);

    return (
      <Wrapper className={className}>
        <Name>{insight.name}</Name>
        <InsightSummary insight={insight} activePrograms={productStatusGraphData.activePrograms} />
        <ProductStatus
          productStatus={productStatus}
          graphData={productStatusGraphData}
          memoData={memoData}
          onClickMemo={onClickMemo}
        />
        <Performance insight={insight} graphData={performanceGraphData} memoData={memoData} onClickMemo={onClickMemo} />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 64px;
`;

const Name = styled.h2`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 8px;
  background: ${COLOR.BACKGROUND};
  padding-bottom: 8px;
  position: sticky;
`;
