import React from 'react';

import { Dayjs } from 'dayjs';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { Link } from 'components/atoms/Link';
import { ReviewStatusLabel } from 'components/atoms/ReviewStatus';
import { Stars } from 'components/atoms/Stars';
import { GmbReviewStatus, Review } from 'models/Domain/GmbReview';
import { Store } from 'models/Domain/Store';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const GmbReviewCard: React.FC<{
  className?: string;
  store: Store;
  review: Review;
  status: GmbReviewStatus;
  updateAt: Dayjs;
  isDeleted: boolean;
}> = ({ className, store, review, status, updateAt, isDeleted }) => {
  return (
    <Wrapper>
      <Link
        to={{
          pathname: Path.gmb.reviewDetail.replace(':reviewId', String(review.reviewId)),
          state: { source: Path.gmb.review },
        }}
      >
        <ReviewCard className={className} isDeleted={isDeleted}>
          <ReviewWrapper>
            <ReviewStatusWrapper>
              <ReviewStatusLabel status={status} />
              {isDeleted && (
                <ReviewDeleted>
                  <DeleteIcon />
                  削除されたクチコミ
                </ReviewDeleted>
              )}
            </ReviewStatusWrapper>
            <CreateDate>{updateAt.fromNow()}</CreateDate>
          </ReviewWrapper>
          <ReviewWrapper>
            <ReviewerName>{review.reviewer.name}</ReviewerName>
            <ReviewStars starRating={review.starRating} />
          </ReviewWrapper>
          <ReviewText>{review.comment || 'このユーザーはコメントは書かず、評価だけ残しました。'}</ReviewText>
          <StoreStatus>{store.fullName}</StoreStatus>
        </ReviewCard>
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  border-top: 1px solid ${COLOR.GRAY};
  &:first-of-type {
    border-top: 0;
  }
`;

const ReviewCard = styled(Card)<{ isDeleted: boolean }>`
  background: ${({ isDeleted }) => (isDeleted ? '#f0f0f0' : COLOR.WHITE)};

  &::after {
    content: '';
    display: block;
    clear: both;
  }
`;

const ReviewWrapper = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
`;

const ReviewStars = styled(Stars)`
  top: -1px;
  display: inline-block;
  margin-right: 8px;
`;

const ReviewerName = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

const ReviewText = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const CreateDate = styled.div`
  color: ${COLOR.GRAY};
  margin-right: 4px;
  @media (max-width: 414px) {
    margin-right: 0;
  }
`;

const StoreStatus = styled.div`
  font-size: 12px;
  color: ${COLOR.GRAY};
`;

const ReviewStatusWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ReviewDeleted = styled.div`
  color: ${COLOR.DARK_GRAY};
`;

const DeleteIcon = styled(Icon).attrs({ name: 'trash alternate outline' })`
  &&& {
    height: auto;
    margin-right: 4px;
  }
`;
