import { List, Set } from 'immutable';
import { call, select } from 'redux-saga/effects';

import { sleep } from 'helpers/utils';
import { Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { User } from 'models/Domain/User';
import { Group } from 'types/Common';

import { GmbCategories } from '../models/Domain/GmbLocation/GmbCategories';

import { State } from './reducers';

export function* waitForUserAndStoresInitialized() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const currentUser: User = yield select((state: State) => state.app.currentUser);
    const stores: Stores = yield select((state: State) => state.store.stores);
    const storeLists: StoreLists = yield select((state: State) => state.storeList.storeLists);
    if (currentUser.id !== 0 && stores.initialized && storeLists.initialized) {
      return;
    }

    yield sleep(10);
  }
}

export function* waitForCategoriesInitialized() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const categories: GmbCategories = yield select((state: State) => state.gmb.categoryList);
    if (categories.list.size > 0) {
      return;
    }

    yield sleep(10);
  }
}

export function* getInitialStores({
  group,
  containsClosedStores,
  onlyGbpConnectedStores,
  onlyMyStore,
}: {
  group?: Group;
  containsClosedStores?: boolean;
  onlyGbpConnectedStores?: boolean;
  onlyMyStore?: boolean;
}) {
  yield call(waitForUserAndStoresInitialized);

  const currentUser: User = yield select((state: State) => state.app.currentUser);
  const stores: Stores = yield select((state: State) => state.store.stores);
  const storeLists: StoreLists = yield select((state: State) => state.storeList.storeLists);

  const myStores: List<number> = currentUser.isSvUser
    ? currentUser.managing_stores
    : currentUser.isMemberUser
      ? currentUser.stores
      : stores.list.map((store) => store.id);

  let filteredStoreLists: StoreLists = storeLists;

  // showGbpConnectedStoresOnlyフラグがtrueのとき、GBP連携済み店舗を含まないグループを表示しない
  if (onlyGbpConnectedStores) {
    filteredStoreLists = filteredStoreLists.filterByIncludingConnectedGmbStore(stores);
  }

  // showMyStoreOnlyフラグがtrueのとき、店舗スタッフ・SVは所属/管理店舗以外の店舗を表示しない
  if (onlyMyStore) {
    filteredStoreLists = filteredStoreLists.filterByMyStore(myStores);
  }

  let targetFilterStores = stores;
  if (typeof group === 'number') {
    // グループIDの場合
    const storelist = filteredStoreLists.findStoreList(group);
    targetFilterStores = targetFilterStores.filterStoresById(storelist ? storelist.stores.toArray() : []);
  } else if (group === 'my_store') {
    // 所属店舗(店舗ユーザー)、管理店舗(SVユーザー)の場合
    targetFilterStores = targetFilterStores.filterStoresById(myStores.toArray());
  }
  // 閉店店舗を表示しないための絞り込み
  if (!containsClosedStores) {
    targetFilterStores = targetFilterStores.filterByUnclosed();
  }
  // GBP連携済みのみを表示するための絞り込み
  if (onlyGbpConnectedStores) {
    targetFilterStores = targetFilterStores.filterByIsConnectedGmb();
  }
  // 管理/所属店舗のみ表示するための絞り込み
  if (onlyMyStore) {
    targetFilterStores = targetFilterStores.filterStoresById(myStores.toArray());
  }
  return Set(targetFilterStores.list.map((target) => target.id).toArray());
}

export function* getInitialGroup({ group }: { group?: Group }) {
  yield call(waitForUserAndStoresInitialized);

  const currentUser: User = yield select((state: State) => state.app.currentUser);
  const storeLists: StoreLists = yield select((state: State) => state.storeList.storeLists);

  const defaultGroup = currentUser.isSvUser || currentUser.isMemberUser ? 'my_store' : 'all';
  if (group === null || group === 'my_store') {
    // groupの条件が入っていない場合もしくは'my_store'の場合、
    // デフォルト設定で上書きする
    return defaultGroup;
  }
  if (typeof group === 'number' && !!storeLists.findStoreList(group)) {
    // groupが存在するグループIDの場合は、その値を返す
    return group;
  } else if (group === 'all') {
    // groupが'all'の場合は、'all'を返す
    return 'all';
  } else {
    // それ以外の場合は、currentUserのroleによるデフォルト値（'my_store' or 'all'）を返す
    return defaultGroup;
  }
}
