import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import {
  BatchUpdateMenuSummaryParams,
  GetMenuResponse,
  GetMenuSummaryResponse,
  PostMenuParams,
  PutMenuParams,
} from 'ApiClient/MenuApi';

export const DietaryRestrictions = ['HALAL', 'KOSHER', 'VEGETARIAN', 'VEGAN', 'ORGANIC'] as const;
export type DietaryRestriction = (typeof DietaryRestrictions)[number];

export const MAX_MENU_NAME_LENGTH = 140;
export const MAX_MENU_SECTION_NAME_LENGTH = 140;
export const MAX_MENU_ITEM_NAME_LENGTH = 140;
export const MAX_MENU_ITEM_DESCRIPTION_LENGTH = 1000;

export const dietaryRestrictionLabels = {
  HALAL: 'ハラール',
  KOSHER: 'コーシャ',
  VEGETARIAN: 'ベジタリアン',
  VEGAN: 'ビーガン',
  ORGANIC: 'オーガニック',
} satisfies Record<DietaryRestriction, string>;

export class MenuSummaryItem extends ImmutableRecord<{
  id: number;
  storeIds: ImmutableList<number> | null;
  groupIds: ImmutableList<number> | null;
  name: string;
  applyToGbp: boolean;
  syncedToGbp: boolean | null;
  isDeleted: boolean;
  createAt: Dayjs | null;
  updateAt: Dayjs | null;
}>({
  id: 0,
  storeIds: ImmutableList(),
  groupIds: ImmutableList(),
  name: '',
  applyToGbp: true,
  syncedToGbp: null,
  isDeleted: false,
  createAt: null,
  updateAt: null,
}) {
  static fromJSON(data: GetMenuSummaryResponse['items'][number]) {
    return new MenuSummaryItem({
      id: data.id,
      storeIds: data.store_ids ? ImmutableList(data.store_ids) : null,
      groupIds: data.group_ids ? ImmutableList(data.group_ids) : null,
      name: data.name,
      applyToGbp: data.apply_to_gbp,
      syncedToGbp: data.synced_to_gbp,
      isDeleted: data.is_deleted,
      createAt: data.create_at ? dayjs(data.create_at) : null,
      updateAt: data.update_at ? dayjs(data.update_at) : null,
    });
  }

  toUpdateParams(): BatchUpdateMenuSummaryParams['items'][number] {
    return {
      id: this.id,
      store_ids: this.storeIds?.toArray() ?? null,
      group_ids: this.groupIds?.toArray() ?? null,
      name: this.name,
      apply_to_gbp: this.applyToGbp,
    };
  }
}

export class MenuSummaryCondition extends ImmutableRecord<{
  organizationId: number;
  storeIds: ImmutableList<number> | null;
  groupIds: ImmutableList<number> | null;
  isIncludedDeactivateGbp: boolean | null;
}>({
  organizationId: 0,
  storeIds: null,
  groupIds: null,
  isIncludedDeactivateGbp: true,
}) {
  static fromJSON(data: GetMenuSummaryResponse['conditions']) {
    return new MenuSummaryCondition({
      organizationId: data.organization_id,
      storeIds: data.store_ids ? ImmutableList(data.store_ids) : null,
      groupIds: data.group_ids ? ImmutableList(data.group_ids) : null,
      isIncludedDeactivateGbp: data.is_included_deactivate_gbp ?? true,
    });
  }
}

export class MenuSummaryItemList extends ImmutableRecord<{ list: ImmutableList<MenuSummaryItem> }>({
  list: ImmutableList(),
}) {
  static fromJSON(data: GetMenuSummaryResponse['items']) {
    return new MenuSummaryItemList({
      list: ImmutableList(data.map((item) => MenuSummaryItem.fromJSON(item))),
    });
  }

  get size() {
    return this.list.size;
  }

  toggleApplyToGbp(index: number) {
    return this.updateIn(['list', index, 'applyToGbp'], (value) => !value);
  }

  removeItem(index: number) {
    return this.update('list', (list) => list.remove(index));
  }
}

export class MenuSummary extends ImmutableRecord<{
  condition: MenuSummaryCondition;
  items: MenuSummaryItemList;
}>({
  condition: new MenuSummaryCondition(),
  items: new MenuSummaryItemList(),
}) {
  static fromJSON(data: GetMenuSummaryResponse) {
    return new MenuSummary({
      condition: MenuSummaryCondition.fromJSON(data.conditions),
      items: MenuSummaryItemList.fromJSON(data.items),
    });
  }

  /** GBPに反映されてないアイテムのリスト */
  get itemsNotSyncedToGbp() {
    return this.items.update('list', (list) => list.filter((item) => item.syncedToGbp === false));
  }

  /** 削除済みでないアイテムのリスト */
  get itemsNotDeleted() {
    return this.items.update('list', (list) => list.filter((item) => item.isDeleted === false));
  }

  toUpdateParams(): BatchUpdateMenuSummaryParams {
    return {
      items: this.items.list.map((item) => item.toUpdateParams()).toArray(),
    };
  }
}

export class Menu extends ImmutableRecord<{
  id: number | null;
  name: string;
  applyToGbp: boolean;
  sections: ImmutableList<MenuSection>;
  storeIds: ImmutableList<number> | null;
  groupIds: ImmutableList<number> | null;
  createAt: Dayjs | null;
  updateAt: Dayjs | null;
}>({
  id: null,
  name: '',
  applyToGbp: true,
  sections: ImmutableList(),
  storeIds: null,
  groupIds: null,
  createAt: null,
  updateAt: null,
}) {
  static fromJSON(data: GetMenuResponse) {
    return new Menu({
      id: data.id,
      name: data.name,
      applyToGbp: data.apply_to_gbp,
      sections: ImmutableList(data.sections.map((section: any) => MenuSection.fromJSON(section))),
      storeIds: data.store_ids ? ImmutableList(data.store_ids) : null,
      groupIds: data.group_ids ? ImmutableList(data.group_ids) : null,
      createAt: data.create_at ? dayjs(data.create_at) : null,
      updateAt: data.update_at ? dayjs(data.update_at) : null,
    });
  }

  changeName(name: string) {
    return this.set('name', name);
  }

  validateName() {
    if (this.name.length <= 0) {
      return { isValid: false, error: `メニュー名は必須です` };
    }
    if (this.name.length > MAX_MENU_NAME_LENGTH) {
      return { isValid: false, error: `メニュー名は${MAX_MENU_NAME_LENGTH}文字以内で入力してください` };
    }
    return { isValid: true };
  }

  toCreateParams(): PostMenuParams {
    return {
      store_ids: this.storeIds?.toArray() ?? null,
      group_ids: this.groupIds?.toArray() ?? null,
      name: this.name,
      apply_to_gbp: this.applyToGbp,
      sections: this.sections
        .map((section) => ({
          name: section.name,
          items: section.items
            .map((item) => ({
              name: item.name,
              description: item.description,
              price: item.price,
              dietary_restrictions: item.dietaryRestrictions?.toArray() ?? null,
              image_url: item.imageUrl,
            }))
            .toArray(),
        }))
        .toArray(),
    };
  }

  // 複製する（idや更新日時はすべてnullになる）
  clone() {
    return new Menu({
      id: null,
      name: this.name,
      applyToGbp: this.applyToGbp,
      sections: this.sections.map(
        (section) =>
          new MenuSection({
            id: null,
            name: section.name,
            items: section.items.map(
              (item) =>
                new MenuItem({
                  id: null,
                  name: item.name,
                  description: item.description,
                  price: item.price,
                  dietaryRestrictions: item.dietaryRestrictions,
                  imageUrl: item.imageUrl,
                }),
            ),
          }),
      ),
      storeIds: this.storeIds,
      groupIds: this.groupIds,
      createAt: null,
      updateAt: null,
    });
  }

  toUpdateParams(): PutMenuParams {
    return {
      id: this.id,
      store_ids: this.storeIds?.toArray() ?? null,
      group_ids: this.groupIds?.toArray() ?? null,
      name: this.name,
      apply_to_gbp: this.applyToGbp,
      sections: this.sections
        .map((section) => ({
          id: section.id ?? null,
          name: section.name,
          items: section.items
            .map((item) => ({
              id: item.id ?? null,
              name: item.name,
              description: item.description,
              price: item.price,
              dietary_restrictions: item.dietaryRestrictions?.toArray() ?? null,
              image_url: item.imageUrl,
            }))
            .toArray(),
        }))
        .toArray(),
    };
  }
}

export class MenuSection extends ImmutableRecord<{
  id: number | null;
  name: string;
  items: ImmutableList<MenuItem>;
}>({
  id: null,
  name: '',
  items: ImmutableList(),
}) {
  static fromJSON(data: GetMenuResponse['sections'][number]) {
    return new MenuSection({
      id: data.id ?? null,
      name: data.name,
      items: ImmutableList(data.items.map((item) => MenuItem.fromJSON(item))),
    });
  }

  changeName(name: string) {
    return this.set('name', name);
  }

  validateName() {
    if (this.name.length <= 0) {
      return { isValid: false, error: `セクション名は必須です` };
    }
    if (this.name.length > MAX_MENU_SECTION_NAME_LENGTH) {
      return { isValid: false, error: `セクション名は${MAX_MENU_SECTION_NAME_LENGTH}文字以内で入力してください` };
    }
    return { isValid: true };
  }
}

export class MenuItem extends ImmutableRecord<{
  id: number | null;
  name: string;
  description: string | null;
  price: number | null;
  dietaryRestrictions: ImmutableList<DietaryRestriction> | null;
  imageUrl: string | null;
}>({
  id: null,
  name: '',
  description: null,
  price: null,
  dietaryRestrictions: null,
  imageUrl: null,
}) {
  static fromJSON(data: GetMenuResponse['sections'][number]['items'][number]) {
    return new MenuItem({
      id: data.id ?? null,
      name: data.name,
      description: data.description ?? null,
      price: data.price ?? null,
      dietaryRestrictions: data.dietary_restrictions
        ? ImmutableList(data.dietary_restrictions as DietaryRestriction[])
        : null,
      imageUrl: data.image_url ?? null,
    });
  }

  changeName(value: string) {
    return this.set('name', value);
  }

  changePrice(value: number | null) {
    return this.set('price', value);
  }

  changeDescription(value: string | null) {
    return this.set('description', value);
  }

  get displayDietaryRestriction() {
    return this.dietaryRestrictions
      ?.map((restriction) => dietaryRestrictionLabels[restriction])
      .filter((label) => label)
      .join('・');
  }

  validateName() {
    if (this.name.length <= 0) {
      return { isValid: false, error: `商品名は必須です` };
    }
    if (this.name.length > MAX_MENU_ITEM_NAME_LENGTH) {
      return { isValid: false, error: `商品名は${MAX_MENU_ITEM_NAME_LENGTH}文字以内で入力してください` };
    }
    return { isValid: true };
  }

  validatePrice() {
    if (this.price != null && this.price < 0) {
      return { isValid: false, error: `価格は0以上で入力してください` };
    }
    return { isValid: true };
  }

  validateDescription() {
    if (this.description != null && this.description.length > MAX_MENU_ITEM_DESCRIPTION_LENGTH) {
      return { isValid: false, error: `説明文は${MAX_MENU_ITEM_DESCRIPTION_LENGTH}文字以内で入力してください` };
    }
    return { isValid: true };
  }

  isValid() {
    return this.validateName().isValid && this.validatePrice().isValid && this.validateDescription().isValid;
  }
}
