import axios from 'axios';

import { FaasApiClient } from 'ApiClient';
import { Status } from 'models/Domain/SearchVolume';
import { JSObject } from 'types/Common';

const faasApiClient = new FaasApiClient({});

const ADS_PATH = '/ads';

const SEARCH_VOLUMES_PATH = `${ADS_PATH}/search_volumes`;

export type Area = {
  name: string;
  extra_info?: JSObject;
};

export type SearchWord = {
  name: string;
  extra_info?: JSObject;
};

export type Period = {
  start_month: string;
  end_month: string;
};

export type GetSearchVolumesParams = {
  page?: number;
  limit?: number;
};

export type GetSearchVolumesResponse = {
  items: {
    user_id: number;
    date: string;
    execution_id: string;
    conditions: {
      areas: Area[];
      search_words: SearchWord[];
      period: Period;
    };
    status: Status;
  }[];
  pagination: {
    limit: number;
    current_page: number;
    has_next: true;
    total_count: number;
  };
};
export type SearchVolumesStartParams = {
  conditions: {
    areas: Area[];
    search_words: SearchWord[];
    period: Period;
  };
};
export type SearchVolumesStartResponse = {
  execution_id: string;
};
export type SearchVolumesStatusParams = {
  executionId: string;
};

export type SearchVolumesStatusResponse = {
  status: Status;
  date: string;
  user_id: number;
  conditions: {
    areas: Area[];
    search_words: SearchWord[];
    period: Period;
  };
  result: {
    items: {
      condition: {
        area: Area;
        search_word: SearchWord;
        area_name_for_search: string | null;
      };
      geo_target: {
        id: string;
        name: string;
        canonical_name: string;
      };
      url: string | null;
    }[];
  };
};

export type DeleteSearchVolumesParams = {
  executionId: string;
};

export type SearchVolumeData = {
  status: 'SUCCESS' | 'FAILED';
  ts: string;
  condition: {
    area: Area;
    search_word: SearchWord;
  };
  geo_target: {
    id: string;
    name: string;
    canonical_name: string;
  };
  items:
    | {
        keyword: string;
        metrics: {
          competition: string | null;
          monthly_search_volumes: { period: string; value: number }[] | null;
        };
      }[]
    | null;
};

export type SearchVolumesCsvStartParams = {
  execution_id: string;
  contains_related_keywords?: boolean;
  filter?: {
    areas?: string[];
    search_words: string[];
  };
};

export type SearchVolumesCsvStartResponse = {
  executionArn: string;
};

export type SearchVolumesCsvStatusParams = {
  executionArn: string;
};

export type SearchVolumesCsvStatustResponse =
  | {
      status: 'SUCCEEDED';
      download_url: string;
      message: '';
    }
  | {
      status: 'RUNNING' | 'FAILED';
      download_url: null;
      message: string;
    };

export class SearchVolumesApi {
  static get(params: GetSearchVolumesParams) {
    return faasApiClient.get<GetSearchVolumesResponse>(`${SEARCH_VOLUMES_PATH}`, params);
  }

  static start(params: SearchVolumesStartParams) {
    return faasApiClient.post<SearchVolumesStartResponse>(`${SEARCH_VOLUMES_PATH}/start`, params);
  }

  static status(params: SearchVolumesStatusParams) {
    return faasApiClient.post<SearchVolumesStatusResponse>(`${SEARCH_VOLUMES_PATH}/status`, params);
  }

  static delete(params: DeleteSearchVolumesParams) {
    return faasApiClient.delete(`${SEARCH_VOLUMES_PATH}/${params.executionId}`);
  }

  static getData(url: string) {
    return axios.get<SearchVolumeData>(url, { cache: true });
  }

  static csvStart(params: SearchVolumesCsvStartParams) {
    return faasApiClient.post<SearchVolumesCsvStartResponse>(`${SEARCH_VOLUMES_PATH}/csv/start`, params);
  }

  static csvStatus(params: SearchVolumesCsvStatusParams) {
    return faasApiClient.post<SearchVolumesCsvStatustResponse>(`${SEARCH_VOLUMES_PATH}/csv/status`, params);
  }
}
