import React from 'react';

import styled from 'styled-components';

// import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { OptionalToggle } from 'components/atoms/OptionalToggle';
import { GmbAttributeMetadata } from 'models/Domain/GmbAttributeMetadatas';
import { GmbAttribute } from 'models/Domain/GmbLocation/GmbAttributes';

type Props = {
  className?: string;
  attributeMetadatas: GmbAttributeMetadata;
  attribute: GmbAttribute;
  toggle: (value: string | boolean) => void;
};

export const AttributeRepeatedEnum = React.memo<Props>(({ className, attributeMetadatas, attribute, toggle }) => {
  return (
    <EnumWrapper className={className}>
      <DisplayName>{attributeMetadatas.displayName}</DisplayName>
      <FlexWrapper>
        {attributeMetadatas.valueMetadata.map((meta, idx) => (
          <OptionalWrapper key={idx}>
            <OptionalToggle
              onClick={() => toggle(meta.value)}
              label={meta.displayName}
              checked={attribute.getRepeatedEnumValue(meta.value)}
            />
          </OptionalWrapper>
        ))}
      </FlexWrapper>
    </EnumWrapper>
  );
});

const EnumWrapper = styled.div`
  margin-bottom: 8px;
  padding: 8px;
  background: #f2f2f2;
  border-radius: 5px;
`;

const DisplayName = styled.div`
  font-size: 13px;
  line-height: 1.5;
`;

const OptionalWrapper = styled.div`
  margin: 4px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
