import React from 'react';

import { List } from 'immutable';
import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';

export interface CheckLocation {
  text: string;
  value: string;
  checked: boolean;
}

export type CheckLocationList = List<CheckLocation>;

export const ImportModal = React.memo<{
  isOpen: boolean;
  checkLocationList: CheckLocationList;
  checkedList: string[];
  onClose: () => void;
  onImport: () => void;
}>(({ isOpen, onClose, onImport, checkLocationList, checkedList }) => {
  let createdList: CheckLocationList = List();
  checkLocationList.forEach((location) => {
    if (checkedList.indexOf(location.value) >= 0) {
      createdList = createdList.push(location);
    }
  });
  return (
    <Modal size='small' open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Content scrolling>
        <MessageWrapper>
          <div>以下の{createdList.size}店舗が新規作成されます</div>
          {createdList.map((location, idx) => (
            <StoreWrapper key={idx}>・{location.text}</StoreWrapper>
          ))}
        </MessageWrapper>
      </Modal.Content>
      <Modal.Actions>
        <ButtonWrapper>
          <CloseButton onClick={() => onClose()}>キャンセル</CloseButton>
          <ApplyButton priority='high' onClick={() => onImport()}>
            適用
          </ApplyButton>
        </ButtonWrapper>
      </Modal.Actions>
    </Modal>
  );
});

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonCommonStyle = css`
  width: fit-content;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
    margin-left: 20px;
  }
`;

const StoreWrapper = styled.div`
  margin-right: 16px;
`;

const MessageWrapper = styled.div`
  margin-top: 20px;
  &:first-of-type {
    margin-top: 0;
  }
`;
