import React from 'react';

import styled from 'styled-components';

import { StepManual, StepManualHeader, StepManualMain, StepManualTitle } from 'components/organisms/StepManual';

export const Manual = React.memo(() => {
  return (
    <Wrapper>
      <StepManual>
        <StepManualTitle>競合店舗をまとめて更新するには</StepManualTitle>
        <StepManualHeader step={1} title={'競合店舗を検索'} />
        <StepManualMain>競合を設定したい店舗とキーワードを入力し、「検索」ボタンをクリックします</StepManualMain>
        <StepManualHeader step={2} title={'競合店舗を選択'} />
        <StepManualMain>
          店舗ごとに競合店舗の候補となる店舗が表示されます。競合店舗として設定したい店舗のチェックボックスをオンにします
        </StepManualMain>
        <StepManualHeader step={3} title={'タグを設定'} />
        <StepManualMain>
          登録したい競合店舗に一括で設定するタグを入力してください。タグはあとから店舗ごとに変更できます
        </StepManualMain>
        <StepManualHeader step={4} title={'競合店舗の登録'} />
        <StepManualMain>
          「登録」ボタンをクリックすると、それぞれの店舗に選択された競合店舗が設定されます
        </StepManualMain>
      </StepManual>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 16px;
`;
