import React from 'react';

import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';

export const GmbLatlngImportModal = React.memo<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}>(({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Content>
        <div>
          <div>
            Googleビジネスプロフィールから緯度経度を取り込みますか？
            <br />
            STORECASTの店舗に設定中の緯度経度は破棄されます
          </div>
        </div>
      </Modal.Content>
      <ButtonWrapper>
        <CloseButton onClick={() => onClose()}>キャンセル</CloseButton>
        <ApplyButton priority='high' onClick={() => onSubmit()}>
          取り込む
        </ApplyButton>
      </ButtonWrapper>
    </Modal>
  );
});

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px;
`;

const ButtonCommonStyle = css`
  width: fit-content;
`;

const CloseButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
  }
`;

const ApplyButton = styled(Button)`
  &&& {
    ${ButtonCommonStyle}
    margin-left: 20px;
  }
`;
