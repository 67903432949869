import React from 'react';

import { Icon, Label } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { CheckBox } from 'components/atoms/CheckBox';
import { Link } from 'components/atoms/Link';
import { ResizeImage } from 'components/atoms/ResizeImage';
import { createAlbumListIndexPath } from 'helpers/path';
import Image, { mapCategoriesToLabels } from 'models/Domain/Image/Image';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Props = {
  image: Image;
  checked: boolean;
  showCheckbox?: boolean;
  onChangeCheck?: (image: Image) => void;
};

export const AlbumColumn: React.FC<Props> = ({ image, checked, showCheckbox, onChangeCheck }) => {
  // 写真詳細ページのパス
  const detailPagePath = Path.album.image.replace(':imageId', String(image.image_resource_id));
  const canSelect = !image.stores.filter((store) => store.is_connected_gmb).isEmpty();
  const categoryLabels = mapCategoriesToLabels({ categories: image.gbp_categories });
  return (
    <Wrapper>
      <Link to={detailPagePath}>
        <GridColumn>
          <Thumbnail src={image.url} />
          {image.published_gmb_stores_count !== 0 && (
            <GmbStats>
              <GmbPublishedStores>
                <GmbIcon src={'/images/icon_gmb_white.svg'} />
              </GmbPublishedStores>
            </GmbStats>
          )}
        </GridColumn>
      </Link>
      {showCheckbox && (
        <CheckBoxWrapper>
          <CustomCheckBox
            disabled={!canSelect}
            checked={checked}
            onChange={(e: any) => {
              if (onChangeCheck) {
                onChangeCheck(image);
              }
            }}
          />
          {!canSelect && <SelectWarning>店舗がGBP連携されていないため選択できません</SelectWarning>}
        </CheckBoxWrapper>
      )}
      <ImageStatus>
        <ImageStatusStoresLabel>
          <ImageStatusStoreNames>{image.storeNamesLabel}</ImageStatusStoreNames>
          {image.stores_count > 1 && <ImageStatusStoreCount> (全{image.stores_count}店舗)</ImageStatusStoreCount>}
        </ImageStatusStoresLabel>
        <ImageStatusLabel>{image.formatCreateAt}</ImageStatusLabel>
        {(categoryLabels?.length > 0 || image.tags?.size > 0) && (
          <ImageStatusLabel>
            {categoryLabels && categoryLabels.length > 0 && (
              <CategoryLabelsContainer>
                {categoryLabels?.map((category, index) => (
                  <ImageCategoryLabel key={index}>{category.text}</ImageCategoryLabel>
                ))}
              </CategoryLabelsContainer>
            )}

            {image.tags && image.tags.size > 0 && (
              <TagsContainer>
                <Icon name='tags' size='small' />
                {image.tags.map((tag, index) => (
                  <Link key={tag} to={createAlbumListIndexPath({ tag })}>
                    {tag}
                    {index < image.tags.size - 1 && '、'}
                  </Link>
                ))}
              </TagsContainer>
            )}
          </ImageStatusLabel>
        )}
      </ImageStatus>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin: 5px;
`;

const GridColumn = styled.div`
  position: relative;
  padding: 0 !important;
  width: 250px;
  height: 145px;
  margin-bottom: 12px;
  cursor: pointer;
  &:before {
    content: '';
    float: left;
    padding-top: 100%; /* initial ratio of 1:1*/
  }
  @media (max-width: 875px) {
    width: calc(((100vw - 320px) / 2) - 32px);
    height: calc((((100vw - 320px) / 2) - 32px) * 0.5625); /* 16:9 */
  }
  @media (max-width: 600px) {
    width: calc(50vw - 36px);
    height: calc((50vw - 36px) * 0.5625); /* 16:9 */
  }
`;

const Thumbnail = styled(ResizeImage).attrs(() => ({
  width: 500, //最大幅500pxにリサイズ
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const GmbStats = styled.div`
  position: absolute;
  width: 100%;
  right: 8px;
  bottom: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
`;

const GmbPublishedStores = styled.div`
  height: 30px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  border: 1px solid ${COLOR.LIGHT_GRAY};
  display: flex;
  align-items: center;
`;

const GmbIcon = styled.img`
  &&& {
    width: 24px;
    height: 24px;
  }
`;

const ImageStatus = styled.div`
  min-height: 74px;
`;

const ImageStatusLabelCss = css`
  min-height: 21px;
  width: 250px;
  font-size: 12px;
  @media (max-width: 875px) {
    width: calc(((100vw - 320px) / 2) - 32px);
  }
  @media (max-width: 600px) {
    width: calc(50vw - 36px);
  }
  display: flex;
  align-items: center;
`;

const ImageCategoryLabel = styled(Label)`
  &&& {
    font-size: 10px;
  }
`;

const ImageStatusLabel = styled.div`
  ${ImageStatusLabelCss};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ImageStatusStoresLabel = styled.div`
  ${ImageStatusLabelCss};
  font-size: 12px;
`;

const ImageStatusStoreNames = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

const ImageStatusStoreCount = styled.div`
  flex: 1 0 auto;
  overflow: hidden;
  white-space: pre;
`;

const SelectWarning = styled(Label).attrs(() => ({
  color: 'white',
  pointing: 'below',
}))`
  &&& {
    display: none;
    position: absolute;
    top: -36px;
    left: 2px;
    width: 242px;
    z-index: 10;

    background: ${COLOR.WHITE};
    border: 1px solid ${COLOR.ERROR};
    color: ${COLOR.ERROR};

    ::before {
      left: 7%;
    }
  }
`;

const CheckBoxWrapper = styled.div`
  :hover {
    ${SelectWarning} {
      display: block;
    }
  }
`;
const CustomCheckBox = styled(CheckBox)`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    padding: 9px;
    cursor: pointer;
  }
`;

export const DummyAlbumColumn = styled(Wrapper)`
  margin-top: 0;
  margin-bottom: 0;
  width: 250px;
`;

export const CategoryLabelsContainer = styled.div`
  margin-right: 8px;
`;

export const TagsContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
