import dayjs, { Dayjs } from 'dayjs';
import { List as ImmutableList, Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import { CheckPostRuleParams, PatchInstagramParams, PostInstagramParams } from 'ApiClient/InstagramApi';
import ErrorType from 'helpers/errorType';
import { validateWebsiteUrl } from 'helpers/utils';
import { JSObject, assertNever } from 'types/Common';

import { PromotionActionTypeKey } from './Promotion/Promotion';

export const PERMALINK_ACTION_URL = '{{permalink}}';
export type ActionUrlType = 'instagram' | 'url' | 'none';

export class FacebookProfile extends ImmutableRecord<{
  id: string;
  name: string;
}>({
  id: '',
  name: '',
}) {
  static fromJSON(data: JSObject): FacebookProfile {
    return new FacebookProfile({
      id: data.id,
      name: data.name,
    });
  }
}

export class FacebookAccount extends ImmutableRecord<{
  userId: string;
  accessToken: string;
  grantedScopes: ImmutableSet<string>;
}>({
  userId: '',
  accessToken: '',
  grantedScopes: ImmutableSet<string>(),
}) {
  static fromJSON(data: JSObject): FacebookAccount {
    return new FacebookAccount({
      userId: data.userID,
      accessToken: data.accessToken,
      grantedScopes: ImmutableSet(data.grantedScopes.split(',')),
    });
  }

  hasAuthority(scopes: ImmutableSet<string>) {
    return this.grantedScopes.isSuperset(scopes);
  }
}

export class InstagramBusinessAccount extends ImmutableRecord<{
  userId: string;
  name: string;
  userName: string;
  profilePictureUrl?: string;
  pageId: string;
  pageAccessToken: string;
  scopes: ImmutableSet<string>;
}>({
  userId: '',
  name: '',
  userName: '',
  profilePictureUrl: undefined,
  pageId: '',
  pageAccessToken: '',
  scopes: ImmutableSet(),
}) {
  static fromJSON(data: {
    instagram_user_id: string;
    instagram_name: string;
    instagram_username: string;
    instagram_profile_picture_url?: string;
    page_id: string;
    page_access_token: string;
    scopes: string[];
  }): InstagramBusinessAccount {
    return new InstagramBusinessAccount({
      userId: data.instagram_user_id,
      name: data.instagram_name,
      userName: data.instagram_username,
      profilePictureUrl: data.instagram_profile_picture_url,
      pageId: data.page_id,
      pageAccessToken: data.page_access_token,
      scopes: ImmutableSet(data.scopes),
    });
  }
}

export class InstagramAccount extends ImmutableRecord<{
  id: number;
  userId: string;
  name: string;
  userName: string;
  pageId: string;
  pageAccessToken: string;
  scopes: ImmutableList<string>;
  postRule?: InstagramPostRule;
  isDeleted: boolean;
  isValid: boolean;
  createAt?: Dayjs;
  updateAt?: Dayjs;
}>({
  id: 0,
  userId: '',
  name: '',
  userName: '',
  pageId: '',
  pageAccessToken: '',
  scopes: ImmutableList(),
  postRule: undefined,
  isDeleted: false,
  isValid: true,
  createAt: undefined,
  updateAt: undefined,
}) {
  static fromJSON(data: JSObject): InstagramAccount {
    return new InstagramAccount({
      id: data.id,
      userId: data.ig_user_id,
      name: data.ig_name,
      userName: data.ig_username,
      pageId: data.page_id,
      pageAccessToken: data.page_access_token,
      scopes: ImmutableList(data.scopes),
      postRule: data.post_rule ? InstagramPostRule.fromJSON(data.post_rule) : new InstagramPostRule(),
      isDeleted: data.is_deleted ?? false,
      isValid: data.is_valid == null ? true : data.is_valid,
      createAt: data.create_at ? dayjs(data.create_at) : undefined,
      updateAt: data.update_at ? dayjs(data.update_at) : undefined,
    });
  }

  // 投稿対象店舗設定が存在するか
  get hasPostTarget(): boolean {
    if (!this.postRule) {
      return false;
    }
    return this.postRule.targets.size > 0;
  }

  // アカウントの更新に適切な値かどうか
  get isValidRule(): boolean {
    return this.postRule?.isValid ?? true;
  }

  // アカウント作成パラメータに変換
  get createParams(): PostInstagramParams {
    return {
      ig_user_id: this.userId,
      ig_name: this.name,
      ig_username: this.userName,
      page_id: this.pageId,
      page_access_token: this.pageAccessToken,
      scopes: this.scopes.toArray(),
    };
  }

  // アカウント作成パラメータに変換
  get updateParams(): PatchInstagramParams {
    // リストがnullか空ならnullを、そうでなければarrayに変換した値を返す
    const getArrayValue = <T>(value: ImmutableList<T> | null) => {
      return value == null || value.isEmpty() ? null : value.toArray();
    };
    return {
      post_rule: {
        exclude_words: this.postRule?.excludeWords?.toArray(),
        body_length: this.postRule?.bodyLength,
        targets:
          this.postRule?.targets
            .map((target) => ({
              id: target.id,
              group_ids: getArrayValue(target.groupIds),
              // store_idsはgroup_idsに値があるときは設定しない
              store_ids: getArrayValue(target.groupIds) ? null : getArrayValue(target.storeIds),
              match_words: getArrayValue(target.matchWords),
              exclude_words: getArrayValue(target.excludeWords),
              action_type: target?.actionType ?? 'ACTION_TYPE_UNSPECIFIED',
              action_url: target?.actionUrl ?? null,
            }))
            .toArray() ?? [],
      },
    };
  }

  getCheckPostRuleParams(caption: string): CheckPostRuleParams {
    return {
      caption,
      post_rule: {
        account_id: this.id,
        ...this.updateParams.post_rule,
      },
    };
  }
}

export type KeywordRuleType = 'match' | 'exclude';

export class InstagramPostTargetKeywordRuleItem extends ImmutableRecord<{
  type: KeywordRuleType;
  word: string;
}>({
  type: 'match',
  word: '',
}) {}

export class InstagramPostTargetKeywordRule extends ImmutableRecord<{
  items: ImmutableList<InstagramPostTargetKeywordRuleItem>;
}>({
  items: ImmutableList(),
}) {
  static fromJSON(data: { matchWords?: string[]; excludeWords?: string[] }): InstagramPostTargetKeywordRule {
    const { matchWords, excludeWords } = data;
    let items = ImmutableList<InstagramPostTargetKeywordRuleItem>();
    if (matchWords != null) {
      items = items.concat(
        ImmutableList(matchWords.map((word) => new InstagramPostTargetKeywordRuleItem({ type: 'match', word }))),
      );
    }
    if (excludeWords != null) {
      items = items.concat(
        ImmutableList(excludeWords.map((word) => new InstagramPostTargetKeywordRuleItem({ type: 'exclude', word }))),
      );
    }
    return new InstagramPostTargetKeywordRule({ items });
  }

  get matchWords() {
    return this.items.filter((item) => item.type === 'match' && item.word).map((item) => item.word);
  }

  get excludeWords() {
    return this.items.filter((item) => item.type === 'exclude' && item.word).map((item) => item.word);
  }

  get isValid() {
    const words = this.items.map((item) => item.word).filter((x) => x);
    return words.size === ImmutableSet(words).size;
  }
}

export class InstagramPostTarget extends ImmutableRecord<{
  id: number | null;
  groupIds: ImmutableList<number> | null;
  storeIds: ImmutableList<number> | null;
  keywordRule: InstagramPostTargetKeywordRule;
  actionType: PromotionActionTypeKey | null;
  actionUrl: string | null;
  position?: number;
  createAt?: Dayjs;
  updateAt?: Dayjs;
}>({
  id: null,
  groupIds: null,
  storeIds: null,
  keywordRule: new InstagramPostTargetKeywordRule(),
  actionType: null,
  actionUrl: null,
  position: undefined,
  createAt: undefined,
  updateAt: undefined,
}) {
  static fromJSON(data: JSObject): InstagramPostTarget {
    return new InstagramPostTarget({
      id: data.id || null,
      groupIds: data.group_ids ? ImmutableList(data.group_ids) : null,
      storeIds: data.store_ids ? ImmutableList(data.store_ids) : null,
      keywordRule: InstagramPostTargetKeywordRule.fromJSON({
        matchWords: data.match_words,
        excludeWords: data.exclude_words,
      }),
      actionType: data.action_type ?? null,
      actionUrl: data.action_url ?? null,
      position: data.position ? data.position : undefined,
      createAt: data.create_at ? dayjs(data.create_at) : undefined,
      updateAt: data.update_at ? dayjs(data.update_at) : undefined,
    });
  }

  get matchWords() {
    return this.keywordRule.matchWords;
  }

  get excludeWords() {
    return this.keywordRule.excludeWords;
  }

  // actionUrlのバリデーション
  get validateWebsiteUrl(): string | undefined {
    // URLを設定しない場合バリデーションOK
    if (this.actionType !== 'LEARN_MORE') {
      return undefined;
    }
    // Instagramのリンクを利用する場合はバリデーションOK
    if (this.actionUrl === PERMALINK_ACTION_URL) {
      return undefined;
    }
    // URLが入力されていて、形式が適切かどうか
    if (!this.actionUrl || !validateWebsiteUrl(this.actionUrl)) {
      return ErrorType.WEBSITE_URL_ERROR;
    }
    return undefined;
  }

  // 更新可能な値かどうか
  get isValid(): boolean {
    return this.validateWebsiteUrl === undefined && this.keywordRule.isValid;
  }

  setActionType(value: ActionUrlType) {
    switch (value) {
      case 'instagram':
        return this.merge({ actionType: 'LEARN_MORE', actionUrl: PERMALINK_ACTION_URL });
      case 'url':
        return this.merge({ actionType: 'LEARN_MORE', actionUrl: null });
      case 'none':
        return this.merge({ actionType: 'ACTION_TYPE_UNSPECIFIED', actionUrl: null });
      default:
        return assertNever(value);
    }
  }

  setActionUrl(value: string | null) {
    return this.merge({ actionUrl: value });
  }
}

export class InstagramPostRule extends ImmutableRecord<{
  id: number | null;
  excludeWords: ImmutableList<string> | null;
  bodyLength: number | null;
  targets: ImmutableList<InstagramPostTarget>;
  createAt?: Dayjs;
  updateAt?: Dayjs;
}>({
  id: null,
  excludeWords: null,
  bodyLength: null,
  targets: ImmutableList(),
  createAt: undefined,
  updateAt: undefined,
}) {
  static fromJSON(data: JSObject): InstagramPostRule {
    return new InstagramPostRule({
      id: data.id || null,
      excludeWords: data.exclude_words ? ImmutableList(data.exclude_words) : null,
      bodyLength: data.body_length ?? null,
      targets: data.targets
        ? ImmutableList(data.targets.map((target: JSObject) => InstagramPostTarget.fromJSON(target)))
        : ImmutableList(),
      createAt: data.create_at ? dayjs(data.create_at) : undefined,
      updateAt: data.update_at ? dayjs(data.update_at) : undefined,
    });
  }

  // 更新可能な値かどうか
  get isValid(): boolean {
    // すべての投稿対象が有効
    return this.targets.every((target) => target.isValid);
  }
}
