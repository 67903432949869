import React from 'react';

import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { StickyHeader, Title, Wrapper } from 'components/atoms/StickyHeader';
import { Tab } from 'components/atoms/Tab';
import { useScrollDirection } from 'hooks/useScrollDirection';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const InsightHeader = React.memo<{ selected: 'all' | 'store' | 'detail' }>(({ selected }) => {
  const isUp = useScrollDirection();

  return (
    <>
      <StickyHeader>
        <Title>GBPインサイト (旧)</Title>
      </StickyHeader>
      <TabContainer isShowMenu={isUp}>
        <TabContent>
          <StyledLink to={Path.gmb.insight}>
            <Tab active={selected === 'all'}>集計</Tab>
          </StyledLink>
          <StyledLink to={Path.gmb.insightStore}>
            <Tab active={selected === 'store'}>店舗比較</Tab>
          </StyledLink>
        </TabContent>
      </TabContainer>
    </>
  );
});

const TabContainer = styled(Wrapper)`
  &&& {
    top: 72px;
    background-color: ${COLOR.BACKGROUND};
    border: solid ${COLOR.LIGHT_GRAY};
    border-width: 0 0 2px 0;
    @media (max-width: 600px) {
      top: ${(props) => (props.isShowMenu ? '132px' : '72px')};
      border: none;
      padding: 0;
    }
  }
`;

const TabContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledLink = styled(Link)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;
