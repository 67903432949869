import React from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { PromotionPreview } from 'components/molecules/PromotionPreview';
import { PromotionGroup } from 'models/Domain/Promotion/PromotionGroup';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

interface Props {
  className?: string;
  open: boolean;
  promotionGroup: PromotionGroup;
  store: Store;
  onSubmit: () => void;
  onClose: () => void;
}

export const PromotionPreviewModal: React.FC<Props> = ({
  className,
  open,
  onSubmit,
  onClose,
  store,
  promotionGroup,
}) => {
  const promotion = promotionGroup.promotion;
  return (
    <StyledModal className={className} open={open} style={{ backgroundColor: 'transparent', boxShadow: 'initial' }}>
      <Modal.Content>
        <Wrapper>
          <TitleWrapper>
            <Title>{`投稿を${promotion.isScheduled ? '予約' : '公開'}してよろしいですか？`}</Title>
          </TitleWrapper>
          {promotion.isScheduled && promotion.scheduledAt && (
            <ScheduledAtWrapper>
              <StyledIcon type='calendar' />
              <ScheduledAtText>{promotion.scheduledAt.format('YYYY/MM/DD HH:mm')}</ScheduledAtText>
            </ScheduledAtWrapper>
          )}
          <StyledPromotionPreview promotion={promotion} store={store} />
          {promotionGroup.children.map((childPost) => (
            <StyledPromotionPreview
              key={childPost.language}
              promotion={promotion}
              store={store}
              childPost={childPost}
            />
          ))}
        </Wrapper>
        <BottomContent>
          <ButtonsWrapper>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
            <SubmitButton onClick={onSubmit} priority='high'>
              {promotion.isScheduled ? '予約する' : '投稿する'}
            </SubmitButton>
          </ButtonsWrapper>
        </BottomContent>
      </Modal.Content>
    </StyledModal>
  );
};

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 53px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${COLOR.BLACK};
`;

const StyledPromotionPreview = styled(PromotionPreview)`
  margin: 16px 0;
`;

const BottomContent = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    width: 150px;
    margin-right: 16px;
  }
`;

const SubmitButton = styled(Button)`
  &&& {
    width: 150px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-top: 16px;

  @media (min-width: 601px) {
    margin-left: auto;
  }
`;

const StyledModal = styled(Modal)`
  &&& {
    width: auto;
  }
`;

const ScheduledAtWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  width: 16px;
  padding: 10px 0;
  height: 38px;
  margin-right: 8px;
`;

const ScheduledAtText = styled.div`
  color: #757575;
  line-height: 37px;
`;
