import { List } from 'immutable';
import actionCreatorFactory from 'typescript-fsa';

import { Competitor } from 'models/Domain/Competitor/Competitor';
import { CompetitorRegisterSearchCondition } from 'models/Domain/Competitor/CompetitorRegisterSearchCondition';
import { CompetitorSearchResult } from 'models/Domain/Competitor/CompetitorSearchResult';

const actionCreator = actionCreatorFactory('CompetitorRegister');

export const CompetitorRegisterActions = {
  initializePage: actionCreator('initializePage'),
  setIsLoading: actionCreator<boolean>('setIsLoading'),
  setSearchCondition: actionCreator<CompetitorRegisterSearchCondition>('setSearchCondition'),
  setFilterWords: actionCreator<List<string>>('setFilterWords'),
  setTags: actionCreator<List<string>>('setTags'),
  setSearchResults: actionCreator<List<CompetitorSearchResult>>('setSearchResults'),
  setSelectedCompetitors: actionCreator<List<Competitor>>('setSelectedCompetitors'),
  searchCompetitors: actionCreator<CompetitorRegisterSearchCondition>('searchCompetitors'),
  bulkInsertCompetitors: actionCreator('bulkInsertCompetitors'),
  clearState: actionCreator('clearState'),
  setRegisteredCompetitors: actionCreator<List<Competitor>>('setRegisteredCompetitor'),
};
