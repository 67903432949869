import React, { useCallback, useMemo, useState } from 'react';

import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import styled from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { ContextHelp } from 'components/molecules/ContextHelp';
import {
  SortTriangle,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import {
  SearchVolumeSearchResult,
  SearchVolumeSearchResults,
  SortCondition,
  SortKey,
} from 'models/Domain/SearchVolume/SearchVolumeSearchResult';
import {
  SearchVolumeSearchStatus,
  SearchVolumeSearchStatusItem,
} from 'models/Domain/SearchVolume/SearchVolumeSearchStatus';
import { YearMonth } from 'models/Domain/YearMonth';
import { COLOR } from 'style/color';

type Props = {
  className?: string;
  searchStatus: SearchVolumeSearchStatus;
  searchResults: SearchVolumeSearchResults;
  onClickSearchResult: (searchResult: SearchVolumeSearchResult) => void;
};

export const SearchVolumeResultTable = React.memo<Props>(
  ({ className, searchStatus, searchResults, onClickSearchResult }) => {
    const [sortCondition, setSortCondition] = useState<SortCondition>(new SortCondition());

    // 検索条件の期間の終了月を使用する
    const latestMonth = searchStatus.conditions.period.endMonth;

    // 条件に合わせてデータを並び替え
    const sortedItems = useMemo(
      () => searchStatus.getSortedItems(sortCondition.key, sortCondition.order, latestMonth, searchResults),
      [searchStatus, sortCondition.key, sortCondition.order, latestMonth, searchResults],
    );

    const handleOnChangeSort = useCallback(
      (sortKey: SortKey) => {
        let newSortCondition: SortCondition;
        if (sortCondition.key === sortKey) {
          newSortCondition = new SortCondition({
            key: sortKey,
            order: sortCondition.order === 'desc' ? 'asc' : 'desc',
          });
        } else {
          switch (sortKey) {
            // sortKeyが変更されたとき、文字列系は昇順、数値系は降順で並び替える
            case 'searchWord':
            case 'inputArea':
            case 'searchArea':
              newSortCondition = new SortCondition({ key: sortKey, order: 'asc' });
              break;
            default:
              newSortCondition = new SortCondition({ key: sortKey, order: 'desc' });
          }
        }
        setSortCondition(newSortCondition);
      },
      [sortCondition],
    );

    const handleOnClickSearchResult = useCallback(
      (searchResult: SearchVolumeSearchResult) => {
        onClickSearchResult(searchResult);
      },
      [onClickSearchResult],
    );

    return (
      <Wrapper className={className}>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <StyledTableHeaderCell />
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('searchWord')}>
                <Sort sortKey={'searchWord'} sortCondition={sortCondition} />
                検索ワード
                <ContextHelp header={'検索ワード'} content={'検索のキーワード'} />
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('inputArea')}>
                <Sort sortKey={'inputArea'} sortCondition={sortCondition} />
                指定検索地点
                <ContextHelp header={'指定検索地点'} content={'指定された検索地点'} />
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('searchArea')}>
                <Sort sortKey={'searchArea'} sortCondition={sortCondition} />
                実際の検索地点
                <ContextHelp
                  header={'実際の検索地点'}
                  content={
                    '検索ボリュームを実際に取得した地点。Googleで指定可能な地域ではない場合には、指定より広い範囲で検索されます'
                  }
                />
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('average')}>
                <Sort sortKey={'average'} sortCondition={sortCondition} />
                月間平均検索数
                <ContextHelp header={'月間平均検索数'} content={'集計期間内の月間の平均検索ボリューム'} />
              </StyledSortableTableHeaderCell>
              {latestMonth && (
                <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('latest')}>
                  <Sort sortKey={'latest'} sortCondition={sortCondition} />
                  {latestMonth.format()}
                  <ContextHelp header={'最新データ'} content={'集計期間内の最新の月の検索ボリューム'} />
                </StyledSortableTableHeaderCell>
              )}
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('momRatio')}>
                <Sort sortKey={'momRatio'} sortCondition={sortCondition} />
                前月比
                <ContextHelp header={'前月比'} content={'集計期間内の最新の月の前月からの検索ボリュームの変化率'} />
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('threeMomRatio')}>
                <Sort sortKey={'threeMomRatio'} sortCondition={sortCondition} />
                3ヶ月前比
                <ContextHelp header={'3ヶ月前比'} content={'集計期間内の最新の月の3ヶ前からの検索ボリュームの変化率'} />
              </StyledSortableTableHeaderCell>
              <StyledSortableTableHeaderCell onClick={() => handleOnChangeSort('twelveMomRatio')}>
                <Sort sortKey={'twelveMomRatio'} sortCondition={sortCondition} />
                前年同月比
                <ContextHelp
                  header={'前年同月比'}
                  content={'集計期間内の最新の月の1年前の月からの検索ボリュームの変化率'}
                />
              </StyledSortableTableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {sortedItems.map((item, index) => (
              <SearchVolumeTableRow
                key={index}
                item={item}
                latestMonth={latestMonth}
                result={item.url ? searchResults.getData(item.url) : undefined}
                searchStatus={searchStatus}
                onClickSearchResult={handleOnClickSearchResult}
              />
            ))}
            {searchStatus.items.isEmpty() && (
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Content>
                    <LoaderWrapper>
                      <Loader active={searchStatus.items.isEmpty()} size='big' inline />
                    </LoaderWrapper>
                  </Content>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Wrapper>
    );
  },
);

export const SearchVolumeTableRow = React.memo<{
  className?: string;
  item: SearchVolumeSearchStatusItem;
  latestMonth: YearMonth;
  result: SearchVolumeSearchResult | undefined;
  onClickSearchResult: (searchResult: SearchVolumeSearchResult) => void;
  searchStatus: SearchVolumeSearchStatus;
}>(({ item, latestMonth, result, searchStatus, onClickSearchResult }) => {
  const {
    condition: {
      area,
      searchWord,
      period: { startMonth, endMonth },
    },
  } = item;

  const areasText = area.areaName;
  const searchWordsText = searchWord.name;

  const metrics = result?.item?.metrics;

  const momRatio = metrics?.getGrowthRatio(latestMonth, 1);
  const threeMomRatio = metrics?.getGrowthRatio(latestMonth, 3);
  const twelveMomRatio = metrics?.getGrowthRatio(latestMonth, 12);
  const average = metrics?.getAverage(startMonth, endMonth);

  const handleOnClickSearchResult = useCallback(() => {
    if (result) {
      onClickSearchResult(result);
    }
  }, [onClickSearchResult, result]);

  const status = useMemo(() => {
    if (searchStatus.status === 'FAILED') {
      return 'FAILED';
    } else if (result && result.status === 'SUCCESS') {
      return 'SUCCESS';
    } else {
      return 'RUNNING';
    }
  }, [result, searchStatus.status]);

  return (
    <StyledTableRow onClick={handleOnClickSearchResult} hasDetail={result != null}>
      <StatusCell>
        <IconWrapper>
          {status === 'SUCCESS' ? (
            <StyledIcon type={'status_active'} />
          ) : status === 'FAILED' ? (
            <StyledIcon type={'status_error'} />
          ) : (
            <LoaderWrapper>
              <Loader active={true} size={'mini'} inline />
            </LoaderWrapper>
          )}
        </IconWrapper>
      </StatusCell>
      <StyledTableCell>{searchWordsText}</StyledTableCell>
      <StyledTableCell>{areasText}</StyledTableCell>
      <StyledTableCell>{result?.geoTarget.canonicalName ?? ''}</StyledTableCell>
      <NumberCell>
        <Number value={average != null ? Math.floor(average) : null} />
        <SparklinesWrapper>
          <Sparklines
            style={{ verticalAlign: 'middle' }}
            data={metrics?.monthlySearchVolumes.map((item) => item.value ?? 0).toArray()}
            svgWidth={60}
            svgHeight={30}
            min={0}
          >
            <SparklinesLine style={{ strokeWidth: 3, stroke: '#458a6a', fill: '#E2F3EB', fillOpacity: 0.4 }} />
            <SparklinesReferenceLine type={'avg'} />
          </Sparklines>
        </SparklinesWrapper>
      </NumberCell>
      <NumberCell>
        <Number value={metrics?.getDataByMonth(latestMonth)} />
      </NumberCell>
      <NumberCell>
        <Diff value={momRatio}>
          <Rate value={momRatio} />
        </Diff>
      </NumberCell>
      <NumberCell>
        <Diff value={threeMomRatio}>
          <Rate value={threeMomRatio} />
        </Diff>
      </NumberCell>
      <NumberCell>
        <Diff value={twelveMomRatio}>
          <Rate value={twelveMomRatio} />
        </Diff>
      </NumberCell>
    </StyledTableRow>
  );
});

const Sort = React.memo<{ sortKey: SortKey; sortCondition: SortCondition }>(({ sortKey, sortCondition }) => {
  return (
    <SortWrapper>
      {sortKey === sortCondition?.key && <Triangle isSortOrderDesc={sortCondition.order === 'desc'} />}
    </SortWrapper>
  );
});

const Number = ({ value }: { value: number | null | undefined }) => {
  return <>{value == null ? 'ー' : value.toLocaleString()}</>;
};

const Rate = ({ value }: { value: number | null | undefined }) => {
  return (
    <>
      {value == null
        ? 'ー'
        : `${(value * 100).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}%`}
    </>
  );
};

const Wrapper = styled.div``;

const StyledTableRow = styled(TableRow).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'hasDetail',
})<{
  hasDetail: boolean;
}>`
  cursor: ${({ hasDetail }) => (hasDetail ? 'pointer' : 'auto')};
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const StyledSortableTableHeaderCell = styled(SortableTableHeaderCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
    font-weight: bold;
  }
`;

const Triangle = styled(SortTriangle)`
  display: inline-block;
  vertical-align: middle;
`;

const StatusCell = styled(StyledTableCell)``;

const NumberCell = styled(StyledTableCell)`
  &&& {
    font-family: monospace;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
  }
`;

const SortWrapper = styled.div`
  display: inline-block;
  width: 16px;
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

const LoaderWrapper = styled.div``;

const SparklinesWrapper = styled.div`
  display: inline-block;
  width: 60px;
  margin-left: 8px;
`;

// valueが0より大きければ「↑」をつけて緑色、0より小さければ「↓」をつけて赤色、変化がなければ灰色で表示する
const Diff = styled.div.attrs<{ value: number | null | undefined }, { isUp: boolean; isDown: boolean }>(
  ({ value }) => ({
    isUp: value != null && value > 0,
    isDown: value != null && value < 0,
  }),
)<{
  value: number | null | undefined;
}>`
  color: ${({ isUp, isDown, value }) =>
    isUp ? COLOR.GREEN : isDown ? COLOR.RED : value != null ? COLOR.DARK_GRAY : COLOR.BLACK};
  &:after {
    content: '${({ isUp, isDown, value }) => (isUp ? '↑' : isDown ? '↓' : value != null ? '→' : '')}';
    margin-left: 4px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${COLOR.DARK_GRAY};
`;
