import React, { useEffect, useState } from 'react';

import { Set } from 'immutable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Checkbox, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ErrorLabel } from 'components/atoms/Label';
import { GmbReviewFilter } from 'components/pageComponents/GmbReview/GmbReviewFilter';
import { FilterStatus } from 'models/Domain/GmbReview/GmbReviewSearchCondition';
import { AppActions } from 'modules/app/actions';
import { GmbActions } from 'modules/gmb/actions';
import { State } from 'modules/reducers';

type TargetColumnsType =
  | 'code'
  | 'store_name'
  | 'status'
  | 'reviewer_display_name'
  | 'comment'
  | 'star_rating'
  | 'create_time'
  | 'update_time'
  | 'replied_by'
  | 'review_reply_comment'
  | 'review_reply_update_time'
  | 'is_deleted'
  | 'delete_date';

const targetColumns: { value: TargetColumnsType; text: string }[] = [
  { value: 'code', text: '店舗コード' },
  { value: 'store_name', text: '店舗名' },
  { value: 'status', text: '対応状態' },
  { value: 'reviewer_display_name', text: '投稿者' },
  { value: 'comment', text: 'コメント' },
  { value: 'star_rating', text: '評価' },
  { value: 'create_time', text: '投稿時間' },
  { value: 'update_time', text: '更新時間' },
  { value: 'replied_by', text: '返信者' },
  { value: 'review_reply_comment', text: '返信内容' },
  { value: 'review_reply_update_time', text: '返信時間' },
  { value: 'is_deleted', text: '削除済み' },
  { value: 'delete_date', text: '削除日' },
];

type GmbReviewDownloadModalProps = {
  open: boolean;
  filter: FilterStatus;
  onClose: () => void;
};

export const GmbReviewDownloadModal: React.FC<GmbReviewDownloadModalProps> = ({
  open,
  filter: initialFilter,
  onClose,
}) => {
  const [filter, setFilter] = useState<FilterStatus>(initialFilter);
  useEffect(() => {
    setFilter(initialFilter);
  }, [initialFilter]);
  const [selectedTargetColumns, setSelectedTargetColumns] = useState<Set<TargetColumnsType>>(
    Set(targetColumns.map(({ value }) => value)),
  );
  const dispatch = useDispatch();
  const { csvDownload } = useSelector(
    (state: State) => ({
      csvDownload: state.gmb.csvDownload,
    }),
    shallowEqual,
  );

  const startDownload = () => {
    dispatch(GmbActions.getReviewCsvDownload(filter.getCsvDownloadRequestParams(selectedTargetColumns.toArray())));
  };

  useEffect(() => {
    // CSVダウンロード処理の実行中、3秒おきに進捗を取得する
    if (csvDownload.executionArn) {
      const intervalId = window.setInterval(() => {
        dispatch(GmbActions.checkReviewCsvDownloadStatus(intervalId));
      }, 3000);

      // 戻るボタンなどで遷移した場合にタイマーを停止
      return () => {
        window.clearInterval(intervalId);
        // 再度ダウンロードした場合に以前のExecutionArnが参照されないように削除
        dispatch(GmbActions.clearCsvDownload());
        dispatch(AppActions.setLoading(false));
      };
    }
  }, [csvDownload, dispatch]);

  const changeFilter = (chengedfilter: FilterStatus) => {
    setFilter(chengedfilter);
  };

  const changeDownloadStatus = (value: TargetColumnsType) => {
    if (!selectedTargetColumns.has(value)) {
      setSelectedTargetColumns(selectedTargetColumns.add(value));
    } else {
      setSelectedTargetColumns(selectedTargetColumns.delete(value));
    }
  };

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Modal.Content>
        <Title>CSVダウンロード</Title>
        <SubTitle>ダウンロード対象</SubTitle>
        <StyledGmbReviewFilter filter={filter} onChangeFilter={changeFilter} disableCommitButton={true} />
        <SubTitle>取得情報</SubTitle>
        <FlexWrapper>
          {targetColumns.map((targetColumn, idx) => (
            <CheckLabel
              key={idx}
              onChange={() => changeDownloadStatus(targetColumn.value)}
              checked={selectedTargetColumns.has(targetColumn.value)}
              label={targetColumn.text}
            />
          ))}
        </FlexWrapper>
        {selectedTargetColumns.size === 0 && <ErrorLabel pointing>取得情報を選択してください</ErrorLabel>}

        <ButtonWrapper>
          <CancelButton onClick={() => onClose()}>キャンセル</CancelButton>
          <DownloadButton
            priority='high'
            disabled={!filter.isValid() || selectedTargetColumns.size === 0}
            onClick={() => {
              startDownload();
            }}
          >
            ダウンロード
          </DownloadButton>
        </ButtonWrapper>
      </Modal.Content>
    </Modal>
  );
};

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const StyledGmbReviewFilter = styled(GmbReviewFilter)`
  padding-left: 0;
  padding-right: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const CancelButton = styled(Button)``;

const DownloadButton = styled(Button)`
  margin-left: 16px;
`;

const CheckLabel = styled(Checkbox)`
  &&& {
    font-size: 14px;
    color: #707070;
    margin: 20px 33px 0 0;
    cursor: pointer;
  }
`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 14px;

  /* identical to box height, or 21px */
  color: #000000;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
