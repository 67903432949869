import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { StickyHeader } from 'components/atoms/StickyHeader';
import SingleRegister from 'components/pageComponents/StoreRegister/SingleRegister';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { StoreActions } from 'modules/store/actions';

export const StoreRegister: React.FC = () => {
  const dispatch = useDispatch();
  const registerStore = useSelector((state) => state.store.registerStore);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('店舗登録')} />
      <StickyHeader title='店舗登録' />
      <Body>
        <SingleRegister
          registerStore={registerStore}
          changeRegisterStore={(v) => dispatch(StoreActions.changeRegisterStore(v))}
          registerSingleStore={() => dispatch(StoreActions.registerSingleStore())}
        />
      </Body>
    </MainWrapper>
  );
};
