import { SortKey, SortOrder } from 'models/Domain/Memo/MemoSearchCondition';

import ApiClient from './index';

const apiClient = new ApiClient({});

const MEMO_API_ENDPOINT = '/memos';

export type GetMemoParams = {
  start_date?: string;
  end_date?: string;
  query?: string;
  tags?: string;
  sort_key?: SortKey;
  sort_order?: SortOrder;
  limit?: number;
  page?: number;
};

export type GetMemoResponse = {
  items: {
    id: number;
    organization_id: number;
    start_date: string;
    end_date: string | null;
    title: string;
    description: string | null;
    created_by: number;
    create_at: string;
    update_at: string;
    tags: {
      id: number;
      tag: string;
    }[];
  }[];
  pagination: {
    current_page: number;
    has_next: boolean;
    total_count: number;
    limit: number;
  };
};

export type PostMemoParams = {
  start_date: string | null;
  end_date: string | null;
  title: string;
  description: string | null;
  tags: {
    tag: string;
  }[];
};

export type PostMemoResponse = {
  id: number;
  organization_id: number;
  start_date: string;
  end_date: string | null;
  title: string;
  description: string | null;
  created_by: number;
  create_at: string;
  update_at: string;
  tags: {
    id: number;
    tag: string;
  }[];
};

export type PatchMemoParams = {
  start_date: string | null;
  end_date: string | null;
  title: string;
  description: string | null;
  tags: {
    tag: string;
  }[];
};

export type PatchMemoResponse = {
  id: number;
  organization_id: number;
  start_date: string;
  end_date: string | null;
  title: string;
  description: string | null;
  created_by: number;
  create_at: string;
  update_at: string;
  tags: {
    id: number;
    tag: string;
  }[];
};

export type GetMemoTagResponse = {
  items: {
    tag: {
      id: number;
      tag: string;
    };
    count: number;
  }[];
};

export class MemoApi {
  static get(params: GetMemoParams) {
    return apiClient.get<GetMemoResponse>(`${MEMO_API_ENDPOINT}`, params);
  }

  static post(params: PostMemoParams) {
    return apiClient.post<PostMemoResponse>(`${MEMO_API_ENDPOINT}`, params);
  }

  static patch(id: number, params: PatchMemoParams) {
    return apiClient.patch<PatchMemoResponse>(`${MEMO_API_ENDPOINT}/${id}`, params);
  }

  static delete(id: number) {
    return apiClient.delete(`${MEMO_API_ENDPOINT}/${id}`);
  }

  static getTag() {
    return apiClient.get<GetMemoTagResponse>(`${MEMO_API_ENDPOINT}:tag`);
  }
}
