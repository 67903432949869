import { Record } from 'immutable';

export class MapSearchRankConfigImportResult extends Record<{
  status: 'SUCCEEDED' | 'FAILED';
  message: string;
}>({
  status: 'SUCCEEDED',
  message: '',
}) {
  get isSuccess() {
    return this.status === 'SUCCEEDED';
  }
}
