import React, { useCallback } from 'react';

import { Tab, TabProps } from 'semantic-ui-react';

import { PromotionTopicType } from 'models/Domain/Promotion/Promotion';

const typeOptions: { [key in PromotionTopicType]: number } = {
  ALERT: -1, // COVID-19の最新情報はタブに表示しなくなったため使われない値に設定
  STANDARD: 0,
  OFFER: 1,
  EVENT: 2,
};

type PromotionTopicTypeTabProps = {
  selectedTopicType: PromotionTopicType;
  onChangeTab: (type: PromotionTopicType) => void;
};

export const PromotionTopicTypeTab: React.FC<PromotionTopicTypeTabProps> = (props) => {
  const { selectedTopicType, onChangeTab } = props;

  const activeIndex = typeOptions[selectedTopicType];

  const handleOnTabChange = useCallback(
    (e: any, data: TabProps) => {
      if (data.activeIndex !== activeIndex) {
        const selectedOption = Object.entries(typeOptions).find(([_, index]) => index === data.activeIndex);
        if (selectedOption) {
          const selectedTopicType = selectedOption[0];
          onChangeTab(selectedTopicType as PromotionTopicType);
        }
      }
    },
    [activeIndex, onChangeTab],
  );

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      onTabChange={handleOnTabChange}
      activeIndex={activeIndex}
      panes={[{ menuItem: '最新情報' }, { menuItem: '特典' }, { menuItem: 'イベント' }]}
    />
  );
};
