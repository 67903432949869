import React from 'react';

import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { ImageCommentOrderIcon } from 'components/molecules/ImageCommentOrderIcon';
import { NameLabel } from 'components/molecules/NameLabel';
import { OfferTimeline } from 'models/Composite/OfferTimeline';
import { COLOR } from 'style/color';

interface Props {
  className?: string;
  open?: boolean;
  timeline: OfferTimeline;
  onClose: () => void;
  openImageCommentModal: (imageUrl: string) => void;
}

export const ImageCommentViewerModal: React.FC<Props> = ({
  className,
  open,
  timeline,
  onClose,
  openImageCommentModal,
}) => {
  const { offer_image_comment, user } = timeline;

  return (
    <Modal className={className} open={open} style={{ backgroundColor: 'transparent', boxShadow: 'initial' }}>
      <Wrapper>
        <ImgArea>
          <ImgWrapper>
            <Img src={offer_image_comment.image_url} />
            {offer_image_comment.sortedImageComments().map((image_comment, idx) => {
              return (
                <NumberIcon
                  key={idx}
                  x={image_comment.xAxisPercent}
                  y={image_comment.yAxisPercent}
                  order_num={idx + 1}
                />
              );
            })}
          </ImgWrapper>
        </ImgArea>
        {offer_image_comment.sortedImageComments().map((image_comment) => {
          return (
            <ImageCommentWrapper key={image_comment.id}>
              <ImageCommentOrderIcon order_num={image_comment.order_num} />
              <ImageCommentText>{image_comment.text}</ImageCommentText>
            </ImageCommentWrapper>
          );
        })}
        <CreateUser profileImageUrl={user.profile_image_url} userName={user.fullName} />
        <CreateDate>{timeline.create_at.fromNow()}</CreateDate>
        <OpenInputButton
          onClick={() => {
            onClose();
            openImageCommentModal(offer_image_comment.image_url);
          }}
        >
          <CommentIcon name='comment outline' />
          <CommentLabel>画像にコメントする</CommentLabel>
        </OpenInputButton>
        <Close onClick={onClose}>
          <CloseIcon size='large' name='close' />
          <CloseLabel>閉じる</CloseLabel>
        </Close>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  @media (min-width: 415px) {
    margin: 0 40px;
  }
`;

const Close = styled.div`
  width: 115px;
  color: ${COLOR.WHITE};
  margin: 30px auto 0;
  padding: 10px;
  cursor: pointer;
`;

const OpenInputButton = styled.div`
  width: 211px;
  color: ${COLOR.WHITE};
  border: solid 2px ${COLOR.WHITE};
  border-radius: 10px;
  margin: 30px auto 0;
  padding: 10px;
  cursor: pointer;
`;

const CloseIcon = styled(Icon)`
  color: ${COLOR.WHITE};
`;

const CloseLabel = styled.span`
  margin-left: 11px;
  font-size: 16px;
`;

const CommentIcon = styled(Icon)`
  color: ${COLOR.WHITE};
`;

const CommentLabel = styled.span`
  margin-left: 11px;
  font-size: 16px;
`;

const ImgArea = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-height: ${window.innerHeight - 224}px;
  max-width: ${window.innerWidth - 114}px;
  @media (max-width: 414px) {
    max-width: ${window.innerWidth - 22}px;
  }
  margin: 0 auto;
  width: initial !important;
`;

const Img = styled.img`
  display: block !important;
  max-height: ${window.innerHeight - 224}px;
  max-width: ${window.innerWidth - 114}px;
  @media (max-width: 414px) {
    max-width: ${window.innerWidth - 22}px;
  }
  width: initial !important;
`;

const ImageCommentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
`;

const ImageCommentText = styled.p`
  margin: 4px 0 0 8px;
  font-size: 14px;
  color: ${COLOR.WHITE};
`;

const NumberIcon = styled(ImageCommentOrderIcon)<{ x: number; y: number }>`
  position: absolute;
  left: calc(${(props) => props.x}% - 12px);
  top: calc(${(props) => props.y}% - 12px);
  z-index: 99;
`;

const CreateUser = styled(NameLabel)`
  color: ${COLOR.WHITE};
  justify-content: flex-end;
`;

const CreateDate = styled.div`
  text-align: right;
  color: ${COLOR.WHITE};
`;
