import React, { useState } from 'react';

import { Divider, Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { CheckBoxButton } from 'components/atoms/CheckBox';
import { Input } from 'components/atoms/Input';
import StoreList from 'models/Domain/StoreList';
import { StoreState } from 'modules/store/reducers';
import { COLOR } from 'style/color';

type Props = { storeList: StoreList; setStoreList: (v: StoreList) => void } & Pick<StoreState, 'stores'>;

export const StoreListEditForm = React.memo<Props>(({ storeList, stores, setStoreList }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const errors = storeList.validate();
  return (
    <Form>
      <Form.Field required>
        <label>グループ名</label>
        <Input
          value={storeList.name}
          error={errors.name}
          onChange={(value: string) => setStoreList(storeList.setName(value))}
        />
      </Form.Field>

      <Divider />

      <Form.Field required>
        <label>店舗</label>
        <SearchInputWrapper>
          <Input
            value={searchValue}
            onChange={(value: string) => setSearchValue(value)}
            icon='search'
            iconPosition='left'
            placeholder='店舗の絞り込み検索ができます'
          />
          <ClearIcon name='close' size='large' onClick={() => setSearchValue('')} />
        </SearchInputWrapper>
        <StoresWrapper>
          {stores.filterStores(searchValue).list.map((store, index) => {
            const checked = storeList.stores.includes(store.id);
            return (
              <CheckBoxButtonWrapper key={index}>
                <CheckBoxButton
                  id={`store_list_${store.id}`}
                  checked={checked}
                  text={store.fullName}
                  onChange={() => setStoreList(storeList.toggleStores(store.id))}
                />
              </CheckBoxButtonWrapper>
            );
          })}
        </StoresWrapper>
      </Form.Field>
    </Form>
  );
});

const StoresWrapper = styled.div`
  margin-top: 30px;
`;

const CheckBoxButtonWrapper = styled.div`
  margin-top: 15px;
`;

const SearchInputWrapper = styled.div`
  position: relative;
`;

const ClearIcon = styled(Icon)`
  &&& {
    position: absolute;
    right: 0;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: ${COLOR.GRAY};
    cursor: pointer;
  }
`;
