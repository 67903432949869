import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { PopupIcon } from 'components/molecules/PopupIcon';
import { MemoIconData } from 'helpers/graph';
import { PRODUCT_STATUSES, ProductStatusStatus, Program } from 'models/Domain/OmoInsight';
import { CompositeProductStatusGraphData } from 'models/Domain/OmoInsight/OmoInsightGraphData';
import { ProductStatusSummary } from 'models/Domain/OmoInsight/ProductStatusSummary';
import { COLOR } from 'style/color';

import { PROGRAM_LABELS } from '../index';

import { ProductStatusGraph } from './ProductStatusGraph';
import { ProductStatusProgramList } from './ProductStatusProgramList';
import { ProductStatusStatusList } from './ProductStatusStatusList';

type Props = {
  className?: string;
  productStatus: ProductStatusSummary;
  graphData: CompositeProductStatusGraphData;
  memoData?: MemoIconData[];
  onClickMemo?: (ids: number[]) => void;
};

export const ProductStatus = React.memo<Props>(({ className, productStatus, graphData, memoData, onClickMemo }) => {
  // 有効な掲載プログラム名のリストを加工しやすくするためにstring[]に変換
  const activePrograms = graphData.activePrograms;

  // 選択しているプログラム
  const [selectedProgram, selectProgram] = useState<Program | null>(activePrograms[0] ?? null);
  // productStatusの値が変更された時に、
  // 選択されたプログラムがない場合 or 有効なプログラムでない場合、有効なプログラムの中で最初のプログラムを選択する
  useEffect(() => {
    if (selectedProgram !== null && activePrograms.includes(selectedProgram)) {
      return;
    }

    const firstProgram = activePrograms[0] ?? null;
    if (selectedProgram !== firstProgram) {
      selectProgram(firstProgram);
    }
  }, [activePrograms, productStatus, selectProgram, selectedProgram]);

  // 表示対象のステータスのリスト
  const [selectedStatuses, setSelectedStatuses] = useState<ProductStatusStatus[]>([...PRODUCT_STATUSES]);
  const toggleStatus = useCallback((target: ProductStatusStatus) => {
    setSelectedStatuses((prev) => {
      return PRODUCT_STATUSES.filter((status) => {
        if (prev.includes(status)) {
          return status !== target;
        }
        return status === target;
      });
    });
  }, []);

  // 選択されたプログラムの商品ステータスのグラフデータ
  const productStatusGraphData = useMemo(
    () => graphData.getPorductStatusGraphData(selectedProgram, false),
    [graphData, selectedProgram],
  );
  const comparisonProductStatusGraphData = useMemo(
    () => graphData.getPorductStatusGraphData(selectedProgram, true),
    [graphData, selectedProgram],
  );

  const selectedProgramLabel = selectedProgram ? PROGRAM_LABELS[selectedProgram] : '';

  return (
    <Wrapper className={className}>
      <Header>
        <Title>
          商品数
          <StyledPopupIcon header='商品数' content='Googleマーチャントセンターにおいて有効になっている商品数' />
        </Title>
        <UpdateAt>{productStatus.updateAt.format('YYYY/MM/DD HH:mm')}時点</UpdateAt>
      </Header>
      {activePrograms.length === 0 ? (
        <Message>表示するデータがありません</Message>
      ) : (
        <>
          <StatsContainer>
            <ProductStatusProgramList
              productStatus={productStatus}
              selectedProgram={selectedProgram}
              selectProgram={selectProgram}
              activePrograms={activePrograms}
            />
            {selectedProgram && (
              <>
                <SubTitle>{selectedProgramLabel}</SubTitle>
                <ProductStatusStatusList
                  data={productStatus.products[selectedProgram]}
                  selectedStatuses={selectedStatuses}
                  toggleStatus={toggleStatus}
                />
              </>
            )}
          </StatsContainer>

          <GraphContainer>
            <SubTitle>商品数の推移</SubTitle>
            {/* FIXME: 本来は集計期間のグラフを表示するべきだが、空のデータの場合はitemsが生成されないので表示していない */}
            {/* 集計期間のデータが１つもないときでも検索条件をもとに空のデータを生成するようにしたい */}
            {productStatusGraphData.length > 0 ? (
              <ProductStatusGraph
                data={productStatusGraphData}
                statuses={selectedStatuses}
                memoData={memoData}
                onClickMemo={onClickMemo}
              />
            ) : (
              <Message>表示するデータがありません</Message>
            )}
          </GraphContainer>
          {graphData.hasComparisonData() && (
            <GraphContainer>
              <SubTitle>商品数の推移（比較期間）</SubTitle>
              {/* FIXME: 本来は比較期間のグラフを表示するべきだが、空のデータの場合はitemsが生成されないので表示していない */}
              {/* 比較期間のデータが１つもないときでも検索条件をもとに空のデータを生成するようにしたい */}
              {graphData.comparison && graphData.comparison.items.size > 0 ? (
                <ProductStatusGraph
                  data={comparisonProductStatusGraphData}
                  statuses={selectedStatuses}
                  memoData={memoData}
                  onClickMemo={onClickMemo}
                />
              ) : (
                <Message>表示するデータがありません</Message>
              )}
            </GraphContainer>
          )}
        </>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 64px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 18px;
`;

const UpdateAt = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-size: 12px;
  margin-left: 16px;
`;

const Message = styled.div`
  font-size: 14px;
  color: ${COLOR.DARK_GRAY};
  width: 100%;
  text-align: center;
  padding: 24px 0;
`;

const StyledPopupIcon = styled(PopupIcon)`
  margin-left: 4px;
`;
const StatsContainer = styled.div`
  margin-bottom: 16px;
`;

const GraphContainer = styled.div`
  padding: 16px;
`;

const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 16px;
`;
