import React from 'react';

import { transparentize } from 'polished';
import { ReferenceLineProps, LegendProps as _LegendProps } from 'recharts';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

type LegendProps = _LegendProps & { inactiveStats?: string[]; showInactiveLegend?: boolean };

// 非活性のグラフの透明度
const INACTIVE_TRANSPARENCY = 0.8 as const;

export const legendWrapperStyle = { top: '16px', left: '32px', right: '32px' } as const;

type Props = LegendProps & {
  reverse?: boolean;
};

// Legendのcontentに指定する
export const renderLegend: React.FC<Props> = ({ reverse = false, ...props }) => {
  const { payload, onMouseEnter, onMouseLeave, onClick, formatter, showInactiveLegend = true } = props;
  if (!payload) {
    return null;
  }
  const sortedPayload = reverse ? payload.slice().reverse() : payload;
  return (
    <Ul>
      {sortedPayload.map((entry: any, index: number) => {
        if (!entry.dataKey) {
          return null;
        }
        const isActive = !entry.inactive;
        if (!isActive && !showInactiveLegend) {
          return null;
        }
        return (
          <Li
            key={`item-${index}`}
            isActive={isActive}
            isComparison={`${entry.dataKey}`.startsWith('comparison')}
            color={entry.color}
            onMouseEnter={(e) => isActive && onMouseEnter && onMouseEnter(entry, index, e)}
            onMouseLeave={(e) => onMouseLeave && onMouseLeave(entry, index, e)}
            onClick={(e) => onClick && onClick(entry, index, e)}
          >
            {formatter ? formatter(entry.value, entry, index) : entry.value}
          </Li>
        );
      })}
    </Ul>
  );
};

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
`;

const Li = styled.li<{ isActive: boolean; isComparison?: boolean; color: string }>`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${({ color, isActive }) => (isActive ? color : transparentize(INACTIVE_TRANSPARENCY, color))};
  position: relative;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  :hover {
    color: ${({ color, isActive }) => transparentize(isActive ? 0.0 : 0.4, color)};
  }
  :before {
    content: '';
    border-top: 2px ${({ isComparison }) => (isComparison ? 'dotted' : 'solid')};
    width: 1em;
  }
`;

type MemoIconProps = ReferenceLineProps & { title?: string; content?: string; onClick?: () => void };

export const MemoIcon = (props: MemoIconProps) => {
  const { viewBox, title, content, onClick } = props;
  if (!viewBox?.x || !viewBox?.y) {
    return null;
  }
  return (
    <MemoIconWrapper x={viewBox.x - 12} y={viewBox.y - 24} width={24} height={24}>
      <Popup
        basic
        trigger={
          <MemoIconPopupTrigger onClick={onClick}>
            <MemoIconImg />
          </MemoIconPopupTrigger>
        }
      >
        {title && <PopupLabel>{title}</PopupLabel>}
        {content && <PopupContent>{content}</PopupContent>}
      </Popup>
    </MemoIconWrapper>
  );
};

const MemoIconWrapper = styled.foreignObject``;

const MemoIconPopupTrigger = styled.div`
  width: 24px;
  height: 24px;
  padding: 0 4px;
  cursor: pointer;
  border-radius: 12px;
  :hover {
    background: #ccc6;
  }
`;

const MemoIconImg = styled.img.attrs({ src: '/images/icon_comment.svg' })`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PopupLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const PopupContent = styled.div`
  font-size: 12px;
  white-space: break-spaces;
`;
