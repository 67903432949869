import React from 'react';

import styled from 'styled-components';

import { ContextHelp } from 'components/molecules/ContextHelp';
import { RatingStar } from 'components/molecules/Rating';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import { CompetitorHelp as Help } from 'helpers/ContextHelp';
import { GoogleLocation } from 'models/Domain/GoogleLocation';
import { COLOR } from 'style/color';

type Frequency = 'High' | 'Medium' | 'Low' | 'None';

const getFrequency = (value: number | null | undefined): Frequency => {
  return !value ? 'None' : value < 5 ? 'Low' : value < 10 ? 'Medium' : 'High';
};

const getCellColor = (value: number | null | undefined) => {
  switch (getFrequency(value)) {
    case 'High':
      return '#f4cccc';
    case 'Medium':
      return '#fce5cd';
    case 'Low':
      return '#d9ead3';
    case 'None':
      return 'none';
  }
};

type PhotoProps = {
  data?: GoogleLocation['photo'];
};

export const PhotoTable = React.memo<PhotoProps>(({ data }) => {
  const currentMonthCount = data?.currentMonth?.count;
  const lastMonthCount = data?.lastMonth?.count;
  const totalCount = data?.total?.count;
  const currentMonthHasMore = (currentMonthCount != null && data?.currentMonth?.hasMore) ?? false;
  const lastMonthHasMore = (lastMonthCount != null && data?.lastMonth?.hasMore) ?? false;
  const totalHasMore = (totalCount != null && data?.total?.hasMore) ?? false;
  const CELL_WIDTH = '25%';
  return (
    <TableWrapper>
      <TableLabel>写真</TableLabel>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <LabelHeaderCell style={{ width: CELL_WIDTH }}>項目名</LabelHeaderCell>
            <ValueHeaderCell style={{ width: CELL_WIDTH }}>
              今月
              <ContextHelp content={Help.frequency} />
            </ValueHeaderCell>
            <ValueHeaderCell style={{ width: CELL_WIDTH }}>
              先月
              <ContextHelp content={Help.frequency} />
            </ValueHeaderCell>
            <ValueHeaderCell style={{ width: CELL_WIDTH }}>全期間</ValueHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>写真追加数</StyledTableCell>
            <ValueCell color={getCellColor(currentMonthCount)}>
              <Value hasMore={currentMonthHasMore}>{currentMonthCount?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
            <ValueCell color={getCellColor(lastMonthCount)}>
              <Value hasMore={lastMonthHasMore}>{lastMonthCount?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
            <ValueCell>
              <Value hasMore={totalHasMore}>{totalCount?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableWrapper>
  );
});

type LocalPostProps = {
  data?: GoogleLocation['localPost'];
};

export const LocalPostTable = React.memo<LocalPostProps>(({ data }) => {
  const recentAll = data?.recent?.all;
  const totalAll = data?.total?.all;
  const recentStandard = data?.recent?.standard;
  const totalStandard = data?.total?.standard;
  const recentEvent = data?.recent?.event;
  const totalEvent = data?.total?.event;
  const recentOffer = data?.recent?.offer;
  const totalOffer = data?.total?.offer;
  const recentHasMore = (recentAll != null && data?.recent?.hasMore) ?? false;
  const totalHasMore = (totalAll != null && data?.total?.hasMore) ?? false;
  const CELL_WIDTH = '33%';
  return (
    <TableWrapper>
      <TableLabel>投稿</TableLabel>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <LabelHeaderCell style={{ width: CELL_WIDTH }}>項目名</LabelHeaderCell>
            <ValueHeaderCell style={{ width: CELL_WIDTH }}>
              直近１ヶ月
              <ContextHelp content={Help.frequency} />
            </ValueHeaderCell>
            <ValueHeaderCell style={{ width: CELL_WIDTH }}>全期間</ValueHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>投稿数（合計）</StyledTableCell>
            <ValueCell color={getCellColor(recentAll)}>
              <Value hasMore={recentHasMore}>{recentAll?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
            <ValueCell>
              <Value hasMore={totalHasMore}>{totalAll?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>投稿数（最新情報）</StyledTableCell>
            <ValueCell color={getCellColor(recentStandard)}>
              <Value>{recentStandard ?? <EmptyValue />}</Value>
            </ValueCell>
            <ValueCell>
              <Value>{totalStandard?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>投稿数（イベント）</StyledTableCell>
            <ValueCell color={getCellColor(recentEvent)}>
              <Value>{recentEvent?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
            <ValueCell>
              <Value>{totalEvent?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>投稿数（クーポン）</StyledTableCell>
            <ValueCell color={getCellColor(recentOffer)}>
              <Value>{recentOffer?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
            <ValueCell>
              <Value>{totalOffer?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableWrapper>
  );
});

type ReviewProps = {
  data?: GoogleLocation['review'];
};

export const ReviewTable = React.memo<ReviewProps>(({ data }) => {
  const recentReviewCount = data?.recent?.reviewCount;
  const totalReviewCount = data?.total?.reviewCount;
  const recentReviewReplyCount = data?.recent?.reviewReplyCount;
  const totalReviewReplyCount = data?.total?.reviewReplyCount;
  const recentAverageReviewScore = data?.recent?.averageReviewScore;
  const totalAverageReviewScore = data?.total?.averageReviewScore;
  const recentReviewHasMore = (recentReviewCount != null && data?.recent?.hasMore) ?? false;
  const totalReviewHasMore = (totalReviewCount != null && data?.total?.hasMore) ?? false;
  const recentReviewReplyHasMore = (recentReviewReplyCount != null && data?.recent?.hasMore) ?? false;
  const totalReviewReplyHasMore = (totalReviewCount != null && data?.total?.hasMore) ?? false;
  const CELL_WIDTH = '33%';
  return (
    <TableWrapper>
      <TableLabel>クチコミ</TableLabel>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <LabelHeaderCell style={{ width: CELL_WIDTH }}>項目名</LabelHeaderCell>
            <ValueHeaderCell style={{ width: CELL_WIDTH }}>
              直近１ヶ月
              <ContextHelp content={Help.frequency} />
            </ValueHeaderCell>
            <ValueHeaderCell style={{ width: CELL_WIDTH }}>全期間</ValueHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>クチコミ数</StyledTableCell>
            <ValueCell style={{ background: getCellColor(recentReviewCount) }}>
              <Value hasMore={recentReviewHasMore}>{recentReviewCount?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
            <ValueCell>
              <Value hasMore={totalReviewHasMore}>{totalReviewCount?.toLocaleString() ?? <EmptyValue />}</Value>
            </ValueCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>クチコミ返信数</StyledTableCell>
            <ValueCell style={{ background: getCellColor(recentReviewReplyCount) }}>
              <Value hasMore={recentReviewReplyHasMore}>
                {recentReviewReplyCount?.toLocaleString() ?? <EmptyValue />}
              </Value>
            </ValueCell>
            <ValueCell>
              <Value hasMore={totalReviewReplyHasMore}>
                {totalReviewReplyCount?.toLocaleString() ?? <EmptyValue />}
              </Value>
            </ValueCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>平均評価</StyledTableCell>
            <ValueCell>
              <Value>{recentAverageReviewScore?.toFixed(1) ?? <EmptyValue />}</Value>
              {recentAverageReviewScore != null && <RatingStar rating={recentAverageReviewScore} />}
            </ValueCell>
            <ValueCell>
              <Value>{totalAverageReviewScore?.toFixed(1) ?? <EmptyValue />}</Value>
              {totalAverageReviewScore != null && <RatingStar rating={totalAverageReviewScore} />}
            </ValueCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableWrapper>
  );
});

const TableLabel = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledTableRow = styled(TableRow)``;

const TableWrapper = styled.div`
  margin-bottom: 32px;
  position: relative;
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    padding: 8px 16px;
  }
`;

const LabelHeaderCell = styled(StyledTableHeaderCell)`
  &&& {
    text-align: left;
  }
`;

const ValueHeaderCell = styled(StyledTableHeaderCell)`
  &&& {
    text-align: right;
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    padding: 8px 16px;
    font-weight: bold;
  }
`;

const ValueCell = styled(StyledTableCell)<{ color?: string }>`
  &&& {
    text-align: right;
    font-family: monospace !important;
    font-weight: normal;
    background: ${({ color }) => color || 'none'};
  }
`;

const Value = styled.span<{ hasMore?: boolean | null }>`
  display: inline-block;
  white-space: pre-wrap;
  &:after {
    margin-left: 2px;
    content: '+';
    visibility: ${({ hasMore = false }) => (hasMore ? 'visible' : 'hidden')};
  }
`;

const EmptyValue = styled.span`
  &:before {
    content: 'ー';
    color: ${COLOR.GRAY};
  }
`;
