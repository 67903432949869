import { Record, Set } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { MapSearchRankGraphData, MapSearchRankTableData } from 'models/Domain/MapSearchRank/MapSearchRank';
import { MapSearchRankCompetitorAverageData } from 'models/Domain/MapSearchRank/MapSearchRankCompetitorAverage';
import { MapSearchRankSearchCondition } from 'models/Domain/MapSearchRank/MapSearchRankSearchCondition';

import { MapSearchRankActions } from './action';

export class MapSearchRankState extends Record<{
  isPreparedPage: boolean;
  searchCondition: MapSearchRankSearchCondition;
  isInitializedSearchCondition: boolean;
  isLoadingGraphData: boolean;
  isLoadingTableData: boolean;
  tableData: MapSearchRankTableData;
  graphData: MapSearchRankGraphData;
  competitorAverageData: MapSearchRankCompetitorAverageData;
  isSelectedAverage: boolean;
  selectedConfigIds: Set<number>;
  selectedTags: Set<string>;
  needsUpdateCompetitorAverageData: boolean;
}>({
  isPreparedPage: false,
  searchCondition: new MapSearchRankSearchCondition(),
  isInitializedSearchCondition: false,
  isLoadingGraphData: false,
  isLoadingTableData: false,
  isSelectedAverage: true,
  selectedConfigIds: Set(),
  selectedTags: Set(),
  tableData: new MapSearchRankTableData(),
  graphData: new MapSearchRankGraphData(),
  competitorAverageData: new MapSearchRankCompetitorAverageData(),
  needsUpdateCompetitorAverageData: true,
}) {}

export const mapSearchRankReducer = reducerWithInitialState(new MapSearchRankState())
  .case(MapSearchRankActions.setIsPreparedPage, (state, payload) => {
    return state.set('isPreparedPage', payload);
  })
  .case(MapSearchRankActions.setSearchCondition, (state, payload) => {
    return state.set('searchCondition', payload);
  })
  .case(MapSearchRankActions.setIsInitializedSearchCondition, (state, payload) => {
    return state.set('isInitializedSearchCondition', payload);
  })
  .case(MapSearchRankActions.setIsLoadingGraphData, (state, payload) => {
    return state.set('isLoadingGraphData', payload);
  })
  .case(MapSearchRankActions.setIsLoadingTableData, (state, payload) => {
    return state.set('isLoadingTableData', payload);
  })
  .case(MapSearchRankActions.setIsSelectedAverage, (state, payload) => {
    return state.set('isSelectedAverage', payload);
  })
  .case(MapSearchRankActions.setSelectedConfigIds, (state, payload) => {
    return state.set('selectedConfigIds', payload);
  })
  .case(MapSearchRankActions.setSelectedTags, (state, payload) => {
    return state.set('selectedTags', payload);
  })
  .case(MapSearchRankActions.setTableData, (state, payload) => {
    return state.set('tableData', payload);
  })
  .case(MapSearchRankActions.setGraphData, (state, payload) => {
    return state.set('graphData', payload);
  })
  .case(MapSearchRankActions.setCompetitorAverageData, (state, payload) => {
    return state.set('competitorAverageData', payload);
  })
  .case(MapSearchRankActions.setNeedsUpdateCompetitorAverageData, (state, payload) => {
    return state.set('needsUpdateCompetitorAverageData', payload);
  });
