import dayjs, { Dayjs } from 'dayjs';
import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export type InventoryStatus = 'valid' | 'expiring_soon' | 'expired';
export type InventoryAvailability = 'in_stock' | 'out_of_stock' | 'on_display_to_order';

const LOCAL_INVENTORY_VALID_DAYS = 14; // 有効期限の日数(最終更新からの経過日数)

export interface EffectiveDateRecord {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export class EffectiveDate extends Record<EffectiveDateRecord>({
  startDate: null,
  endDate: null,
}) {
  static fromJSON(data: JSObject = {}) {
    const startDateStr = data.start_date;
    const endDateStr = data.end_date;
    return new EffectiveDate({
      startDate: startDateStr ? dayjs(startDateStr) : null,
      endDate: endDateStr ? dayjs(endDateStr) : null,
    });
  }

  /**
   * 開始日を変更する
   * @param startDate
   */
  changeStartDate(startDate: Dayjs | null) {
    return this.set('startDate', startDate);
  }

  /**
   * 終了日を変更する
   * @param endDate
   */
  changeEndDate(endDate: Dayjs | null) {
    return this.set('endDate', endDate);
  }

  /**
   * 更新用のパラメータに変換する
   */
  updateParams() {
    if (this.startDate == null && this.endDate == null) {
      return null;
    }
    return {
      start_date: this.startDate ? this.startDate.format() : null,
      end_date: this.endDate ? this.endDate.format() : null,
    };
  }

  /**
   * 開始日と終了日が空か
   */
  isEmpty(): boolean {
    return this.startDate == null && this.endDate == null;
  }

  /**
   * 開始日のバリデーション
   */
  validateStartDate(): JSObject {
    const errors: JSObject = {};
    if (this.startDate == null && this.endDate != null) {
      errors.name = '開始日が指定されていません';
    }
    return errors;
  }

  /**
   * 終了日のバリデーション
   */
  validateEndDate(): JSObject {
    const errors: JSObject = {};
    if (this.startDate != null && this.endDate == null) {
      errors.name = '終了日が指定されていません';
    } else if (this.startDate != null && this.endDate != null && this.endDate.isBefore(this.startDate)) {
      errors.name = '終了日は開始日より後の日付を指定してください';
    }
    return errors;
  }
}

export interface InventoryRecord {
  merchantId: string;
  offerId: string;
  storeId: number;
  price: number | null;
  quantity: number | null;
  availability: InventoryAvailability | null;
  salePrice: number | null;
  salePriceEffectiveDate: EffectiveDate | null;
  appliedToGmcAt: Dayjs | null;
  createAt: Dayjs | null;
  updateAt: Dayjs | null;
}

export class Inventory extends Record<InventoryRecord>({
  merchantId: '',
  offerId: '',
  storeId: 0,
  price: null,
  quantity: null,
  availability: null,
  salePrice: null,
  salePriceEffectiveDate: null,
  appliedToGmcAt: null,
  createAt: null,
  updateAt: null,
}) {
  static fromJSON(data: JSObject = {}): Inventory {
    const {
      merchant_id: merchantId,
      store_id: storeId,
      inventory: {
        id,
        price,
        quantity,
        availability,
        sale_price: salePrice,
        sale_price_effective_date: salePriceEffectiveDate,
      },
      applied_to_gmc_at: appliedToGmcAt,
      create_at: createAt,
      update_at: updateAt,
    } = data;
    return new Inventory({
      merchantId,
      offerId: id,
      storeId,
      price: price ?? null,
      quantity: quantity ?? null,
      availability: availability ?? null,
      salePrice: salePrice ?? null,
      salePriceEffectiveDate: salePriceEffectiveDate ? EffectiveDate.fromJSON(salePriceEffectiveDate) : null,
      appliedToGmcAt: appliedToGmcAt ? dayjs(appliedToGmcAt) : null,
      createAt: createAt ? dayjs(createAt) : null,
      updateAt: updateAt ? dayjs(updateAt) : null,
    });
  }

  /**
   * 更新用パラメータに変換する
   */
  updateParams() {
    return {
      merchant_id: this.merchantId,
      offer_id: this.offerId,
      store_id: this.storeId,
      inventory: {
        quantity: this.quantity,
        price: this.price,
        availability: this.availability,
        sale_price: this.salePrice,
        sale_price_effective_date: this.salePriceEffectiveDate?.updateParams() ?? null,
      },
    };
  }

  get key(): string {
    return `${this.merchantId}_${this.storeId}_${this.offerId}`;
  }

  /**
   * GMC更新日時から有効期限を取得する
   */
  get expirationDate(): Dayjs | null {
    return this.appliedToGmcAt?.add(LOCAL_INVENTORY_VALID_DAYS, 'day') ?? null;
  }

  /**
   * 有効期限までの日数のテキスト（あと○日）を取得する）
   */
  get daysUntilExpirationText(): string | null {
    if (!this.appliedToGmcAt || !this.expirationDate) {
      return null;
    }
    const diff = Math.ceil(this.expirationDate.diff(dayjs(), 'day', true));
    return diff > 0 ? `あと${diff}日` : '有効期限切れ';
  }

  /**
   * 有効期限のステータスを返す
   */
  get status(): InventoryStatus | null {
    if (!this.appliedToGmcAt || !this.expirationDate) {
      return null;
    }
    // 有効期限が3日後以降であれば"valid"、3日以内なら"expiring_soon"、過ぎていれば"expired"
    const diff = Math.ceil(this.expirationDate.diff(dayjs(), 'day', true));
    return diff > 3 ? 'valid' : diff > 0 ? 'expiring_soon' : 'expired';
  }

  /**
   * 在庫状況を変更する
   * @param availability
   */
  changeAvailability(availability: InventoryAvailability | null) {
    return this.set('availability', availability);
  }

  /**
   * 在庫数を変更する
   * @param quantity
   */
  changeQuantity(quantity: number | null) {
    return this.set('quantity', quantity);
  }

  /**
   * 価格を変更する
   * @param price
   */
  changePrice(price: number | null) {
    return this.set('price', price);
  }

  /**
   * セール価格を変更する
   * @param salePrice
   */
  changeSalePrice(salePrice: number | null) {
    return this.set('salePrice', salePrice);
  }

  /**
   * セール価格の開始日を変更する
   * @param startDate
   */
  changeSalePriceStartDate(startDate: Dayjs | null) {
    return this.update('salePriceEffectiveDate', (effectiveDate: EffectiveDate | null) => {
      return (effectiveDate ?? new EffectiveDate()).changeStartDate(startDate) ?? null;
    });
  }

  /**
   * セール期間の終了日を変更する
   * @param endDate
   */
  changeSalePriceEndDate(endDate: Dayjs | null) {
    return this.update('salePriceEffectiveDate', (effectiveDate: EffectiveDate | null) => {
      return (effectiveDate ?? new EffectiveDate()).changeEndDate(endDate);
    });
  }

  /**
   * 有効期限を更新する
   */
  updateExpirationDate(date: Dayjs = dayjs()) {
    // 最終更新時刻を現在時刻にすることで有効期限を表示を更新する（実際にAPIで更新されたら置き換えられる）
    return this.set('appliedToGmcAt', date);
  }

  /**
   * 在庫数のバリデーション
   */
  validateQuantity() {
    const errors: JSObject = {};
    if (this.availability && this.quantity == null) {
      errors.name = '店頭在庫数は必須です';
    }
    if (this.quantity && this.quantity < 0) {
      errors.name = '店頭在庫数は0以上を入力してください';
    }
    return errors;
  }

  /**
   * 店頭価格のバリデーション
   */
  validatePrice() {
    const errors: JSObject = {};
    if (this.price && this.price < 0) {
      errors.name = '店頭価格は0以上を入力してください';
    }
    return errors;
  }

  /**
   * セール価格のバリデーション
   */
  validateSalePrice() {
    const errors: JSObject = {};
    if (this.salePrice && this.salePrice < 0) {
      errors.name = 'セール価格は0以上を入力してください';
    } else if (this.salePrice == null && this.salePriceEffectiveDate && !this.salePriceEffectiveDate.isEmpty()) {
      errors.name = 'セール価格を設定してください';
    } else if (this.price && this.salePrice && this.price < this.salePrice) {
      errors.name = '店頭価格より低い値を設定してください';
    } else if (this.price == null && this.salePrice != null) {
      errors.name = '店頭価格を設定してください';
    }
    return errors;
  }

  /**
   * セール開始日のバリデーション
   */
  validateStartDate() {
    return this.salePriceEffectiveDate ? this.salePriceEffectiveDate.validateStartDate() : {};
  }

  /**
   * セール終了日のバリデーション
   */
  validateEndDate() {
    return this.salePriceEffectiveDate ? this.salePriceEffectiveDate.validateEndDate() : {};
  }

  /**
   * 在庫情報のバリデーション
   */
  validate() {
    return [
      { type: 'quantity', error: this.validateQuantity() },
      { type: 'price', error: this.validatePrice() },
      { type: 'salePrice', error: this.validateSalePrice() },
      { type: 'startDate', error: this.validateStartDate() },
      { type: 'endDate', error: this.validateEndDate() },
    ];
  }

  /**
   * 在庫情報が有効か
   */
  get isValid(): boolean {
    const validates = this.validate();
    return validates.filter((target) => target.error.name).length === 0;
  }

  /**
   * 在庫情報をクリアする（merchantId, storeId, productId以外の情報を削除）
   */
  clear() {
    return this.merge({
      price: null,
      quantity: null,
      availability: null,
      salePrice: null,
      salePriceEffectiveDate: null,
      appliedToGmcAt: null,
      createAt: null,
      updateAt: null,
    });
  }
}
